import sha256 from "sha256";
import jwtDecode from "jwt-decode";
import cuid from "cuid";
import uuid from "uuid";

import { API_URL, COMMON_LOGIN_API_URL, PORTALE_SERVICE_ID, USER_WRITE_REST } from "../config";
import { retry } from "../utils/callWithRetry";
import { getRestCall, postRestCall, putRestCall } from "../utils/rest-api-call";
import UserWriteTypes from "../assets/thrift/user-write/user-write_types";

import { translateMessage } from "../i18n/index";
import { getUserProfile } from "../utils/state-utils/get-user-profile";

function trimAndLower(id) {
    return id != null ? id.trim().toLowerCase() : id;
}

export function getAuthWithAppName(auth, appName = "PORTALE") {
    const loginAuth = auth.loginAuth ? auth.loginAuth : auth;

    let authWithAppName = new UserWriteTypes.Auth({
        securityToken: loginAuth.securityToken,
        id: loginAuth.id,
        appName: appName
    });

    authWithAppName.securityToken = loginAuth.accessToken ? loginAuth.accessToken : loginAuth.securityToken;
    authWithAppName.accessToken = loginAuth.securityToken ? loginAuth.securityToken : loginAuth.accessToken;
    authWithAppName.refreshToken = loginAuth.refreshToken;

    addAllMissingProperties(authWithAppName, loginAuth);

    return authWithAppName;

    function addAllMissingProperties(newAuth, oldAuth) {
        for (let key in oldAuth) {
            if (oldAuth.hasOwnProperty(key) && !newAuth.hasOwnProperty(key)) {
                newAuth[key] = oldAuth[key];
            }
        }
    }
}

export function callWithRetry(
    thriftClient,
    fn,
    auth,
    request,
    refreshToken,
    dispatch,
    callback,
    codes,
    displayError = false,
    displaySuccess = false
) {
    return retry(
        logout,
        thriftClient,
        fn,
        auth,
        request,
        refreshToken,
        dispatch,
        callback,
        codes,
        displayError,
        displaySuccess
    );
}

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_RESET = "LOGIN_RESET";
export const REFRESH_SUCCESS = "REFRESH_SUCCESS";

export function loginWithSha(appName, userId, userPwdSha) {
    return async dispatch => {
        dispatch({
            type: LOGIN_START
        });
        userId = trimAndLower(userId);

        try {
            const { digest, token } = await getToken(userId, userPwdSha);

            const loginAuth = {
                securityToken: token,
                id: userId,
                appName,
                refreshToken: digest,
                ssoLogin: false
            };

            dispatch({
                type: LOGIN_SUCCESS,
                payload: { loginAuth }
            });
        } catch (e) {
            dispatch({
                type: LOGIN_ERROR,
                payload: { userId },
                error:
                    e.code === "401"
                        ? {
                              code: e.code,
                              message: translateMessage("c-reset-password-success.loginFailed")
                          }
                        : e
            });
        }
    };
}

export function loginWithSession(sessionId) {
    return async dispatch => {
        dispatch({
            type: LOGIN_START
        });

        try {
            const result = await fetch(`${API_URL}/token/refresh?token=${sessionId}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-App-Name": "PORTALE",
                    "X-App-Version": "1.0",
                    "X-Request-Id": cuid(),
                    "X-Correlation-Id": uuid()
                },
                credentials: "include"
            });
            const res = await result.json();
            const { sub } = jwtDecode(res.applicationToken);
            dispatch(
                sharedLogin({
                    token: res.applicationToken,
                    id: sub,
                    idToken: res.idToken,
                    securityToken: res.applicationToken,
                    loginMethod: "tsid"
                })
            );
        } catch (error) {
            dispatch({
                type: LOGIN_ERROR,
                payload: {},
                error
            });
        }
    };
}

export function resetLogin() {
    return {
        type: LOGIN_RESET
    };
}

export const RESET_PWD_START = "RESET_PWD_START";
export const RESET_PWD_SUCCESS = "RESET_PWD_SUCCESS";
export const RESET_PWD_ERROR = "RESET_PWD_ERROR";
export const RESET_PWD_RESET = "RESET_PWD_RESET";

export function resetPassword(id, token, password, appName) {
    return async (dispatch, getState) => {
        dispatch({
            type: RESET_PWD_START
        });

        id = trimAndLower(id);
        const request = {
            sha256UserPassword: sha256(id + password)
        };

        try {
            await putRestCall(
                `${USER_WRITE_REST}/users/${id}/password/reset/${token}`,
                null,
                null,
                request,
                dispatch,
                undefined,
                { id: id }
            );

            dispatch({
                type: RESET_PWD_SUCCESS
            });
        } catch (e) {
            dispatch({
                type: RESET_PWD_ERROR,
                error: e
            });
        }
    };
}

export const LOGOUT = "LOGOUT";
export function logout() {
    return async dispatch => {
        sessionStorage.clear();

        dispatch({
            type: LOGOUT
        });

        window.location.href = `${COMMON_LOGIN_API_URL}/v1/logout?serviceId=${PORTALE_SERVICE_ID}`;
    };
}

export const UPDATE_USER_PASSWORD_START = "UPDATE_USER_PASSWORD_START";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const UPDATE_USER_PASSWORD_ERROR = "UPDATE_USER_PASSWORD_ERROR";
export const UPDATE_USER_PASSWORD_RESET = "UPDATE_USER_PASSWORD_RESET";

export function updateUserPassword(auth, expiredPassword, newPassword) {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_USER_PASSWORD_START
        });
        let userId = trimAndLower(auth.loginAuth.id);
        const userProfile = getUserProfile(getState());

        try {
            const getTokenResult = await getToken(userId, sha256(userId + expiredPassword));
            auth.loginAuth.securityToken = getTokenResult.token;

            await updatePassword(auth, userId, newPassword, dispatch, userProfile);

            dispatch({
                type: UPDATE_USER_PASSWORD_SUCCESS
            });
        } catch (e) {
            e.code !== "403.1"
                ? dispatch({
                      type: UPDATE_USER_PASSWORD_ERROR,
                      error: {
                          code: e.code,
                          message: translateMessage("c-reset-password-error.wrong.password")
                      }
                  })
                : updatePassword(auth, userId, newPassword, dispatch, userProfile);
        }
    };
}

async function updatePassword(auth, userId, psw, dispatch, userProfile) {
    const sha256UserPassword = sha256(userId + psw);

    const request = { sha256UserPassword };

    await putRestCall(
        `${USER_WRITE_REST}/users/${userId}/password`,
        getAuthWithAppName(auth),
        null,
        request,
        dispatch,
        auth.refreshToken,
        userProfile
    );
}

export function resetUpdateUserPassword() {
    return dispatch => {
        dispatch({
            type: UPDATE_USER_PASSWORD_RESET
        });
    };
}

export async function getToken(userId, sha256UserPassword) {
    const { nonce } = await getRestCall(`${API_URL}/login/agyo/nonce`, null, {
        userId
    });

    const digest = sha256(sha256UserPassword + nonce);

    const { token } = await postRestCall(
        `${API_URL}/login/agyo`,
        null,
        null,
        {
            id: userId,
            digest
        },
        undefined,
        undefined,
        { id: userId }
    );

    return { digest, token };
}

export const EXPIRING_PASSWORD_WARN = "EXPIRING_PASSWORD_WARN";

export function expiringPasswordWarn(expiringDate) {
    return {
        type: EXPIRING_PASSWORD_WARN,
        payload: { expiringDate }
    };
}

export const sharedLogin = data => {
    const decodedIdToken = data.idToken && jwtDecode(data.idToken);

    return dispatch =>
        dispatch({
            type: LOGIN_SUCCESS,
            payload: {
                loginAuth: {
                    securityToken: data.token,
                    refreshToken: data.refreshToken,
                    id: data.id,
                    idToken: data.idToken,
                    appName: "PORTALE",
                    locale: decodedIdToken && decodedIdToken.locale,
                    ssoLogin: false,
                    method: data.loginMethod
                }
            }
        });
};
