import styled from "styled-components";

export const StyledLink = styled.button`
    border: none;
    background: none;
    color: rgb(0, 144, 209);
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    text-align: right;
    letter-spacing: 0px;
    line-height: 24px;
    cursor: pointer;
    padding: 0px;
`;
