import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";

import { SpinnerContainer } from "./styled";

/**
 * Component that either renders a centered spinner
 * or renders its children based on the value of the
 * loading prop.
 */
export const Spinner = ({ children, fullPage, loading, spinnerSize }) =>
    loading ? (
        <SpinnerContainer fullPage={fullPage} size={spinnerSize}>
            <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
        </SpinnerContainer>
    ) : (
        children
    );

Spinner.propTypes = {
    children: PropTypes.any.isRequired,
    /** If true, displays the spinner in a top-level, 100vw 100vh container */
    fullPage: PropTypes.bool,
    /** if true, displays the spinner, otherwise displays the children */
    loading: PropTypes.bool,
    /** size of the spinner image */
    spinnerSize: PropTypes.oneOf(["default", "large", "small"])
};

Spinner.defaultProps = {
    spinnerSize: "large"
};
