import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useHistory, useParams } from "react-router";

import { certificationInfo } from "../../../actions/certification";
import { findOwnManagedConnections } from "../../../actions/connections/read";
import { fetchItemServices, resetFetchItemServices } from "../../../actions/services/service-subscription-v2";
import { LinkListPagination, LinkElement } from "../../../components/connections-new";
import { errorBoundary } from "../../../components/general/error-boundary";
import { Spinner } from "../../../components/general/spinner";
import { listItems } from "../../../actions/registry/read";

export const ManagedHistory = ({
    agyoServices,
    certification,
    certificationInfo,
    companies,
    fetchItemServices,
    findOwnManagedConnections,
    findOwnManagedConnectionsStatus,
    listItemsResult,
    listItems,
    managedConnectionsDeleted,
    managedConnectionsDeletedTotalElements,
    resetFetchItemServices,
    user
}) => {
    const [page, setPage] = useState(0);

    const history = useHistory();
    const { item } = useParams();

    useEffect(() => {
        let filter = { deleted: true };
        filter.managerIds = [item];

        findOwnManagedConnections(filter, { page });
    }, [findOwnManagedConnections, item, page]);

    useEffect(() => {
        let managedOfManagedIds = managedConnectionsDeleted.map(v => v.managedId);

        if (managedOfManagedIds && managedOfManagedIds.length > 0) {
            listItems(managedOfManagedIds, 6, 0);
        }
    }, [listItems, managedConnectionsDeleted]);

    useEffect(() => {
        let managedOfManagedIds = managedConnectionsDeleted.map(v => v.managedId);

        if (managedOfManagedIds && managedOfManagedIds.length > 0) {
            fetchItemServices(true, managedOfManagedIds);
        }
        return () => {
            resetFetchItemServices();
        };
    }, [fetchItemServices, managedConnectionsDeleted, resetFetchItemServices]);

    managedConnectionsDeleted = managedConnectionsDeleted
        ? managedConnectionsDeleted.map((o, i) => {
              const item = listItemsResult
                  ? listItemsResult.find(i => {
                        return i.item.base.uuid === o.managedUuid;
                    })
                  : null;
              const identifier = item ? item.item.base.identifier : null;
              const { taxId, vatNumber } = identifier ? identifier : {};
              return { ...o, taxId, vatNumber };
          })
        : [];

    return (
        <>
            <Spinner loading={findOwnManagedConnectionsStatus.started}>
                <div>
                    {managedConnectionsDeleted.map((link, i) => (
                        <LinkElement
                            agyoServices={agyoServices}
                            certification={certification}
                            description={link.managedDescription}
                            displayAddConnection={false}
                            getCertification={id => certificationInfo(id)}
                            history={history}
                            itemId={item}
                            link={link}
                            key={"deleted-link" + i}
                            roles={user ? user.user.roles : []}
                            settingsPageEnabled={true}
                            statusLabel={
                                companies.data[link.managedId]
                                    ? companies.data[link.managedId].item.base.status.status
                                    : ""
                            }
                            id={link.managedId}
                            vatNumber={link.vatNumber}
                            taxId={link.taxId}
                        />
                    ))}
                </div>
                <LinkListPagination
                    onChange={setPage}
                    page={page}
                    totalLinks={managedConnectionsDeletedTotalElements}
                />
            </Spinner>
        </>
    );
};

const mapStateToProps = state => ({
    agyoServices: state.services.agyoServices.services,
    companies: state.companies,
    certification: state.certification,
    findOwnManagedConnectionsStatus: state.connectionsNew.read.findOwnManagedConnections.status,
    managedConnectionsDeleted: state.connectionsNew.read.findOwnManagedConnections.content,
    managedConnectionsDeletedTotalElements: state.connectionsNew.read.findOwnManagedConnections.totalElements,
    listItemsResult: state.registry.read.listItems.items || [],
    user: state.user
});

ManagedHistory.propTypes = {
    agyoServices: PropTypes.array,
    certification: PropTypes.object.isRequired,
    certificationInfo: PropTypes.func,
    companies: PropTypes.object.isRequired,
    fetchItemServices: PropTypes.func.isRequired,
    findOwnManagedConnections: PropTypes.func.isRequired,
    findOwnManagedConnectionsStatus: PropTypes.object.isRequired,
    loadCompany: PropTypes.func,
    managedConnectionsDeleted: PropTypes.array,
    managedConnectionsDeletedTotalElements: PropTypes.number,
    listItems: PropTypes.func.isRequired,
    listItemsResult: PropTypes.array,
    resetFetchItemServices: PropTypes.func.isRequired
};

export default errorBoundary(
    connect(mapStateToProps, {
        certificationInfo,
        findOwnManagedConnections,
        listItems,
        fetchItemServices,
        resetFetchItemServices
    })(ManagedHistory)
);
