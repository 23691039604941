import React, { useContext, useMemo, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { FormattedMessage, useIntl } from "react-intl";
import { translateMessage } from "../../../i18n";

import { Button } from "@ts-digital/vrc";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

import { useOutsideClickRef } from "../../../hooks/use-outside-click";

import Drawer from "../../general/drawer";
import { UserTable } from "./user-table";

import { UserStatusCell } from "./user-status-cell";
import { UserOptionsCell } from "./user-options-cell";
import { TextCell } from "./user-text-cell";
import { UserNameCell } from "./user-name-cell";

import SelectCompanyDrawerContent from "./select-company-drawer";
import { UserDetailsDrawerContent } from "./user-details-drawer";

import { hasOwnerRole } from "../../../utils/get-roles";

import { UsersManagegementContext } from "../../../user-context";

import { useFeature } from "../../../hooks/use-feature";

import {
    Description,
    CompanyTitleContainer,
    CompanyDescriptionContainer,
    PlusIcon,
    Header,
    CompanyDetailsContainer,
    ModalContentContainer
} from "./styled";
import { CreateButtonContainer } from "./user-table/styled";
import Modal from "../../general/modal";
import LoadingButton from "../../general/loading-button";
import { useParams } from "react-router-dom";

export const UsersManageTable = ({
    company,
    companiesData,
    companiesStatus,
    fetchItemServices,
    getCompanies,
    getCompaniesResetCallback,
    history,
    isUserWithTooManyItems,
    itemServices,
    itemServicesStatus,
    listUsers,
    loading,
    loggedUserId,
    match,
    onDeleteRoles,
    onDeleteInvitation,
    onEditRoles,
    onInviteAgain,
    users,
    usersStatus,
    rolesSummary,
    fetchRolesSummary,
    fetchRolesSummaryReset,
    rolesSummaryStatus
}) => {
    const [item, setItem] = useState({});
    const [multiSelectFilterOptions, setMultiSelectFilterOptions] = useState([]);
    const [companiesDrawerOpen, setCompaniesDrawerOpen] = useState(false);
    const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);
    const [validateModalOpen, setValidateModalOpen] = useState(false);
    const [manageConnectionsModalOpen, setManageConnectionsModalOpen] = useState(false);
    const [selectedUserDetails, setSelectedUserDetails] = useState(null);
    const [appIds, setAppIds] = useState([]);
    const [featureCodes, setFeatureCodes] = useState([]);

    const { item: itemId } = useParams();

    const { currentItem, setCurrentItem, setUserCreationFlowFirstStep } = useContext(UsersManagegementContext);

    const showCompaniesDrawerRef = useOutsideClickRef(() => companiesDrawerOpen && setCompaniesDrawerOpen(false));
    const showDetailsDrawerRef = useOutsideClickRef(() => detailsDrawerOpen && setDetailsDrawerOpen(false));

    const intl = useIntl();

    const enableInvitation = useFeature("ENABLE_INVITATIONS");

    const getCompanyStatus = () => company.base.status.status;
    const isCompanyPending = ["PENDING", "UNVERIFIABLE"].includes(getCompanyStatus());
    const isCompanyValidated = getCompanyStatus() === "VALIDATED";
    const isCompanyCertified = company.base.status.certificationStatus === "CERTIFIED";

    const isStudio = company.base.details.classifier === "STUDIO";

    useEffect(() => {
        getCompanies("", 0, 4);
        return () => {
            getCompaniesResetCallback();
        };
    }, [getCompanies, getCompaniesResetCallback]);

    useEffect(() => {
        if (!currentItem) {
            setCurrentItem(company.base.uuid);
        }
    }, [company.base, currentItem, setCurrentItem]);

    useEffect(() => {
        let item = companiesData.find(c => c.itemUuid === currentItem);

        if (!item && currentItem === company.base.uuid) {
            item = {
                itemId: company.base.id,
                itemUuid: company.base.uuid,
                description: company.base.details.description,
                taxId: company.base.identifier.taxId,
                vatNumber: company.base.identifier.vatNumber,
                classifier: company.base.details.classifier
            };
        }

        currentItem ? setItem(item || {}) : setItem(company);
    }, [companiesData, company, currentItem, setItem]);

    useEffect(() => {
        if (item.itemId && !itemServices[item.itemId] && !itemServicesStatus.started) {
            fetchItemServices(true, [item.itemId]);
        }
    }, [fetchItemServices, item.itemId, itemServices, itemServicesStatus]);

    useEffect(() => {
        if (
            item.itemId &&
            item.itemUuid &&
            ((!usersStatus.started && !usersStatus.ended) || (usersStatus.ended && users && !users[item.itemUuid]))
        ) {
            listUsers(item.itemId, item.itemUuid, 0, 10, [], [], ["P"], null, null, null, null, null, true);
        }
    }, [
        item.itemId,
        item.itemUuid,
        company.base.id,
        company.base.uuid,
        listUsers,
        usersStatus.started,
        usersStatus.ended,
        users
    ]);

    const onSelectCompany = useCallback(
        value => {
            setCompaniesDrawerOpen(false);
            setCurrentItem(value);
        },
        [setCurrentItem]
    );

    useEffect(() => {
        if (item.itemId && itemServices && Object.keys(itemServices).length > 0) {
            if (itemServices[item.itemId]) {
                const serviceOptions = itemServices[item.itemId].reduce((services, ele) => {
                    if (!ele.agyoService.system) {
                        if (services.findIndex(service => service.value === ele.agyoService.appId) === -1) {
                            services.push({
                                value: ele.agyoService.appId,
                                label: ele.agyoService.appDescription,
                                featureCode: null
                            });
                        }
                        if (
                            ele.agyoService.featureCode &&
                            services.findIndex(service => service.label === ele.agyoService.description) === -1
                        ) {
                            services.push({
                                value: ele.agyoService.appId + "+" + ele.agyoService.featureCode,
                                label: ele.agyoService.description,
                                featureCode: ele.agyoService.featureCode
                            });
                        }
                    }
                    return services;
                }, []);

                serviceOptions.sort(function (a, b) {
                    if (a.value < b.value) {
                        return -1;
                    }
                    if (a.value > b.value) {
                        return 1;
                    }
                    return 0;
                });

                setMultiSelectFilterOptions(serviceOptions);
            }
        }
    }, [itemServices, item.itemId]);

    const onCheckUserDetails = useCallback(
        value => {
            setSelectedUserDetails(value.uuid);
            setDetailsDrawerOpen(true);
        },
        [setSelectedUserDetails, setDetailsDrawerOpen]
    );

    const sortFullName = useMemo(() => {
        return (a, b) => {
            if (a.values.fullName.toLowerCase() < b.values.fullName.toLowerCase()) {
                return -1;
            }
            if (a.values.fullName.toLowerCase() > b.values.fullName.toLowerCase()) {
                return 1;
            }
            return 0;
        };
    }, []);

    const isLoggedUser = useCallback(
        selectedUserId => {
            return loggedUserId === selectedUserId;
        },
        [loggedUserId]
    );

    const tableColumns = useMemo(
        () => [
            {
                accessor: "fullName",
                Header: <FormattedMessage id="general.name-surname" />,
                Cell: props => <UserNameCell {...props} />,
                sortType: sortFullName,
                minLength: 0
            },
            {
                accessor: "email",
                Header: <FormattedMessage id="general.email" />,
                Cell: props => <TextCell {...props} />,
                sortType: "basic",
                minLength: 0
            },
            {
                accessor: "state",
                Header: <FormattedMessage id="general.state" />,
                Cell: props => <UserStatusCell {...props} />,
                disableFilters: true,
                sortType: "basic",
                sortInverted: true,
                width: 100
            },
            {
                accessor: "role",
                Header: <FormattedMessage id="general.user-role" />,
                Cell: props => <TextCell {...props} />,
                disableFilters: true,
                disableSortBy: true,
                width: 50
            },
            {
                accessor: "roleLevel",
                Header: <FormattedMessage id="general.role-level" />,
                Cell: props => <TextCell {...props} />,
                disableFilters: true,
                disableSortBy: true,
                width: 100
            },

            {
                accessor: "actions",
                Header: "",
                Cell: props => (
                    <UserOptionsCell
                        itemId={company.base.id}
                        onCheckUserDetails={onCheckUserDetails}
                        onDeleteRoles={onDeleteRoles}
                        onDeleteInvitation={onDeleteInvitation}
                        onEditRoles={onEditRoles}
                        onInviteAgain={onInviteAgain}
                        {...props}
                    />
                ),
                disableFilters: true,
                disableSortBy: true,
                width: 50
            }
        ],
        [
            company.base.id,
            onCheckUserDetails,
            onDeleteRoles,
            onDeleteInvitation,
            onEditRoles,
            onInviteAgain,
            sortFullName
        ]
    );

    const tableData = useMemo(() => {
        return users && users[currentItem]
            ? users[currentItem].users
                  .filter(u => {
                      if (appIds.length === 0 && featureCodes.length === 0) {
                          return true;
                      }

                      return u.roles.some(r => appIds.includes(r.appId) || featureCodes.includes(r.featureCode));
                  })
                  .map(user => {
                      const isOwner = hasOwnerRole(item.itemId, user.roles);

                      return {
                          fullName: `${user.profile.firstName} ${user.profile.lastName}`,
                          email: user.profile.tsid || user.profile.id,
                          state: user.status.active,
                          role: translateMessage(`c-registry-user.users-manage-${user.labels.role}`),
                          actions: { uuid: user.profile.uuid, companyId: currentItem, id: user.profile.id },
                          companyId: item.itemId,
                          roleLevel: translateMessage(`c-registry-user.role-level-${user.labels.roleLevel}`),
                          isOwner: isOwner,
                          isLoggedUser: isLoggedUser(user.profile.id),
                          tsUserCode: user.profile.tsUserCode,
                          tsid: user.profile.tsid
                      };
                  })
            : [];
    }, [currentItem, users, item.itemId, appIds, featureCodes, isLoggedUser]);

    const onMultiSelectFilterChange = useCallback(value => {
        let appIds = [];
        let featureCodes = [];
        value.forEach(val => {
            if (val.value.includes("+")) {
                featureCodes.push(val.featureCode);
            } else {
                appIds.push(val.value);
            }
        });
        setAppIds(appIds);
        setFeatureCodes(featureCodes);
    }, []);

    const companyLabel =
        item.classifier === "STUDIO"
            ? intl.formatMessage({ id: "c-registry-user.company-title-studio" })
            : intl.formatMessage({ id: "c-registry-user.company-title-company" });

    const addUserButtonString = enableInvitation ? "general.invite-new-user" : "general.create-or-add-new-user";
    const addButton = type => (
        <Button
            onClick={() => {
                // If the company is not validated
                if (isCompanyPending) {
                    return setValidateModalOpen(true);
                }

                // If the company is not certified yet, display the manage connection modal
                if (isCompanyValidated && !isCompanyCertified) {
                    return setManageConnectionsModalOpen(true);
                }
                setUserCreationFlowFirstStep("insertEmail");
                history.push(`/${match.params.item}/registry/user/create`);
            }}
            kind={type}
            data-cy={"manage-users-add"}
            data-gainsight-track={"manage-users-add"}
        >
            {isCompanyValidated && !isCompanyCertified && isStudio ? (
                <>{intl.formatMessage({ id: "v-user-creation-flow.title.manage-connections" })}</>
            ) : (
                <>
                    <PlusIcon icon={faPlus} /> {intl.formatMessage({ id: addUserButtonString })}
                </>
            )}
        </Button>
    );

    return (
        <>
            <Header>
                <CompanyDetailsContainer>
                    <CompanyTitleContainer>{companyLabel}</CompanyTitleContainer>
                    <CompanyDescriptionContainer>{item.description}</CompanyDescriptionContainer>
                    <Description>
                        {intl.formatMessage({ id: "c-registry-user.manage-company-description" })}
                    </Description>
                </CompanyDetailsContainer>
                <CreateButtonContainer>
                    {isCompanyValidated && !isCompanyCertified && !isStudio ? null : addButton("primary")}
                </CreateButtonContainer>
            </Header>
            <UserTable
                columns={tableColumns}
                data={tableData}
                loading={loading}
                useTopPagination={true}
                enableFilters={true}
                onMultiSelectFilterChange={onMultiSelectFilterChange}
                multiSelectFilterOptions={multiSelectFilterOptions}
                isCompanyCertified={isCompanyCertified}
            />

            <Drawer
                ref={showCompaniesDrawerRef}
                title={<FormattedMessage id="c-registry-user.change-company.drawer.title" />}
                subtitle={<FormattedMessage id="c-registry-user.change-company.drawer.subtitle" />}
                content={
                    <SelectCompanyDrawerContent
                        companies={companiesData}
                        onCompanySelect={onSelectCompany}
                        isUserWithTooManyItems={isUserWithTooManyItems}
                        loading={companiesStatus.started}
                        isOpen={companiesDrawerOpen}
                        flow="list-user"
                    />
                }
                onClose={() => setCompaniesDrawerOpen(false)}
                isOpen={companiesDrawerOpen}
            />

            <Drawer
                ref={showDetailsDrawerRef}
                title={<FormattedMessage id="c-registry-user.user-details.drawer.title" />}
                subtitle={<FormattedMessage id="c-registry-user.user-details.drawer.subtitle" />}
                newComponent={true}
                content={
                    <UserDetailsDrawerContent
                        selectedUser={
                            users &&
                            users[currentItem] &&
                            users[currentItem].users.filter(user => user.profile.uuid === selectedUserDetails)[0]
                        }
                        isOpen={detailsDrawerOpen}
                        onEditRoles={onEditRoles}
                        isOwner={
                            users &&
                            users[currentItem] &&
                            users[currentItem].users.filter(user => user.profile.uuid === selectedUserDetails)[0]
                                ? hasOwnerRole(
                                      item.itemId,
                                      users[currentItem].users.filter(
                                          user => user.profile.uuid === selectedUserDetails
                                      )[0].roles
                                  )
                                : false
                        }
                        isLoggedUser={
                            users &&
                            users[currentItem] &&
                            users[currentItem].users.filter(user => user.profile.uuid === selectedUserDetails)[0]
                                ? isLoggedUser(
                                      users[currentItem].users.filter(
                                          user => user.profile.uuid === selectedUserDetails
                                      )[0].profile.id
                                  )
                                : false
                        }
                        rolesSummary={rolesSummary}
                        fetchRolesSummary={fetchRolesSummary}
                        fetchRolesSummaryReset={fetchRolesSummaryReset}
                        rolesSummaryStatus={rolesSummaryStatus}
                        currentCompay={company}
                    />
                }
                onClose={() => setDetailsDrawerOpen(false)}
                isOpen={detailsDrawerOpen}
            />

            <Modal
                footer={[
                    <Button kind="secondary" onClick={() => setValidateModalOpen(false)}>
                        Annulla
                    </Button>,
                    <LoadingButton
                        loading={false}
                        onClick={() => {
                            history.push(`/${itemId}/company/validate`);
                        }}
                    >
                        {intl.formatMessage({
                            id: "general.validate"
                        })}
                    </LoadingButton>
                ]}
                onCancel={() => setValidateModalOpen(false)}
                visible={validateModalOpen}
                style={{ top: "20%" }}
                width="600px"
                title={intl.formatMessage({
                    id: "c-registry-user.company.modal-validate-company.title"
                })}
            >
                <ModalContentContainer>
                    {intl.formatMessage({
                        id: "c-registry-user.company.modal-validate-company.description"
                    })}
                </ModalContentContainer>
            </Modal>

            <Modal
                footer={[
                    <Button kind="secondary" onClick={() => setManageConnectionsModalOpen(false)}>
                        Annulla
                    </Button>,
                    <LoadingButton
                        loading={false}
                        onClick={() => {
                            history.push(`/${itemId}/connections/managed`);
                        }}
                    >
                        {intl.formatMessage({
                            id: "c-registry-user.company.modal-manage-connection.continue"
                        })}
                    </LoadingButton>
                ]}
                onCancel={() => setManageConnectionsModalOpen(false)}
                visible={manageConnectionsModalOpen}
                style={{ top: "20%" }}
                width="600px"
                title={intl.formatMessage({
                    id: "c-registry-user.company.modal-manage-connection.title"
                })}
            >
                <ModalContentContainer>
                    {intl.formatMessage({
                        id: "c-registry-user.company.modal-manage-connection.description"
                    })}
                </ModalContentContainer>
            </Modal>
        </>
    );
};

UsersManageTable.propTypes = {
    company: PropTypes.any,
    companiesData: PropTypes.arrayOf(
        PropTypes.shape({
            itemId: PropTypes.string.isRequired,
            itemUuid: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            taxId: PropTypes.string.isRequired,
            vatNumber: PropTypes.string.isRequired,
            classifier: PropTypes.string.isRequired
        })
    ),
    companiesStatus: PropTypes.shape({
        started: PropTypes.bool,
        ended: PropTypes.bool,
        error: PropTypes.bool
    }),
    fetchItemServices: PropTypes.func.isRequired,
    getCompanies: PropTypes.func.isRequired,
    getCompaniesResetCallback: PropTypes.func.isRequired,
    history: PropTypes.any,
    isUserWithTooManyItems: PropTypes.bool,
    itemServices: PropTypes.any,
    itemServicesStatus: PropTypes.any,
    listUsers: PropTypes.any,
    loading: PropTypes.bool,
    loggedUserId: PropTypes.string,
    match: PropTypes.any,
    onDeleteRoles: PropTypes.func.isRequired,
    onDeleteInvitation: PropTypes.func.isRequired,
    onEditRoles: PropTypes.func.isRequired,
    onInviteAgain: PropTypes.func.isRequired,
    users: PropTypes.any,
    usersStatus: PropTypes.any
};

export default UsersManageTable;
