/* eslint-disable */
//
// Autogenerated by Thrift Compiler (0.9.3)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
var thrift = require('browser-thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;

var apiCommon_ttypes = require('./apiCommon_types')
var apiAuth_ttypes = require('./apiAuth_types')


var ttypes = module.exports = {};
DownloadFilledDocumentRequest = module.exports.DownloadFilledDocumentRequest = function(args) {
  this.signerId = null;
  if (args) {
    if (args.signerId !== undefined && args.signerId !== null) {
      this.signerId = args.signerId;
    }
  }
};
DownloadFilledDocumentRequest.prototype = {};
DownloadFilledDocumentRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.signerId = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DownloadFilledDocumentRequest.prototype.write = function(output) {
  output.writeStructBegin('DownloadFilledDocumentRequest');
  if (this.signerId !== null && this.signerId !== undefined) {
    output.writeFieldBegin('signerId', Thrift.Type.I64, 1);
    output.writeI64(this.signerId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DownloadFilledDocumentResponse = module.exports.DownloadFilledDocumentResponse = function(args) {
  this.document = null;
  if (args) {
    if (args.document !== undefined && args.document !== null) {
      this.document = new apiCommon_ttypes.Document(args.document);
    }
  }
};
DownloadFilledDocumentResponse.prototype = {};
DownloadFilledDocumentResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.document = new apiCommon_ttypes.Document();
        this.document.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DownloadFilledDocumentResponse.prototype.write = function(output) {
  output.writeStructBegin('DownloadFilledDocumentResponse');
  if (this.document !== null && this.document !== undefined) {
    output.writeFieldBegin('document', Thrift.Type.STRUCT, 1);
    this.document.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DownloadSignedDocumentRequest = module.exports.DownloadSignedDocumentRequest = function(args) {
  this.signerId = null;
  if (args) {
    if (args.signerId !== undefined && args.signerId !== null) {
      this.signerId = args.signerId;
    }
  }
};
DownloadSignedDocumentRequest.prototype = {};
DownloadSignedDocumentRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.signerId = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DownloadSignedDocumentRequest.prototype.write = function(output) {
  output.writeStructBegin('DownloadSignedDocumentRequest');
  if (this.signerId !== null && this.signerId !== undefined) {
    output.writeFieldBegin('signerId', Thrift.Type.I64, 1);
    output.writeI64(this.signerId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DownloadSignedDocumentResponse = module.exports.DownloadSignedDocumentResponse = function(args) {
  this.document = null;
  if (args) {
    if (args.document !== undefined && args.document !== null) {
      this.document = new apiCommon_ttypes.Document(args.document);
    }
  }
};
DownloadSignedDocumentResponse.prototype = {};
DownloadSignedDocumentResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.document = new apiCommon_ttypes.Document();
        this.document.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DownloadSignedDocumentResponse.prototype.write = function(output) {
  output.writeStructBegin('DownloadSignedDocumentResponse');
  if (this.document !== null && this.document !== undefined) {
    output.writeFieldBegin('document', Thrift.Type.STRUCT, 1);
    this.document.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProvisioningStatusRequest = module.exports.ProvisioningStatusRequest = function(args) {
  this.signerId = null;
  if (args) {
    if (args.signerId !== undefined && args.signerId !== null) {
      this.signerId = args.signerId;
    }
  }
};
ProvisioningStatusRequest.prototype = {};
ProvisioningStatusRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.signerId = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProvisioningStatusRequest.prototype.write = function(output) {
  output.writeStructBegin('ProvisioningStatusRequest');
  if (this.signerId !== null && this.signerId !== undefined) {
    output.writeFieldBegin('signerId', Thrift.Type.I64, 1);
    output.writeI64(this.signerId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProvisioningStatusResponse = module.exports.ProvisioningStatusResponse = function(args) {
  this.status = null;
  this.statusDescription = null;
  if (args) {
    if (args.status !== undefined && args.status !== null) {
      this.status = args.status;
    }
    if (args.statusDescription !== undefined && args.statusDescription !== null) {
      this.statusDescription = args.statusDescription;
    }
  }
};
ProvisioningStatusResponse.prototype = {};
ProvisioningStatusResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.status = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.statusDescription = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProvisioningStatusResponse.prototype.write = function(output) {
  output.writeStructBegin('ProvisioningStatusResponse');
  if (this.status !== null && this.status !== undefined) {
    output.writeFieldBegin('status', Thrift.Type.STRING, 1);
    output.writeString(this.status);
    output.writeFieldEnd();
  }
  if (this.statusDescription !== null && this.statusDescription !== undefined) {
    output.writeFieldBegin('statusDescription', Thrift.Type.STRING, 2);
    output.writeString(this.statusDescription);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SignerDataResponse = module.exports.SignerDataResponse = function(args) {
  this.formData = null;
  if (args) {
    if (args.formData !== undefined && args.formData !== null) {
      this.formData = new apiCommon_ttypes.FormData(args.formData);
    }
  }
};
SignerDataResponse.prototype = {};
SignerDataResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.formData = new apiCommon_ttypes.FormData();
        this.formData.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SignerDataResponse.prototype.write = function(output) {
  output.writeStructBegin('SignerDataResponse');
  if (this.formData !== null && this.formData !== undefined) {
    output.writeFieldBegin('formData', Thrift.Type.STRUCT, 1);
    this.formData.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SignerDataRequest = module.exports.SignerDataRequest = function(args) {
  this.signerId = null;
  if (args) {
    if (args.signerId !== undefined && args.signerId !== null) {
      this.signerId = args.signerId;
    }
  }
};
SignerDataRequest.prototype = {};
SignerDataRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.signerId = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SignerDataRequest.prototype.write = function(output) {
  output.writeStructBegin('SignerDataRequest');
  if (this.signerId !== null && this.signerId !== undefined) {
    output.writeFieldBegin('signerId', Thrift.Type.I64, 1);
    output.writeI64(this.signerId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ListSignerRequest = module.exports.ListSignerRequest = function(args) {
  this.cfAzienda = null;
  this.serviceName = null;
  if (args) {
    if (args.cfAzienda !== undefined && args.cfAzienda !== null) {
      this.cfAzienda = args.cfAzienda;
    }
    if (args.serviceName !== undefined && args.serviceName !== null) {
      this.serviceName = args.serviceName;
    }
  }
};
ListSignerRequest.prototype = {};
ListSignerRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.cfAzienda = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.serviceName = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ListSignerRequest.prototype.write = function(output) {
  output.writeStructBegin('ListSignerRequest');
  if (this.cfAzienda !== null && this.cfAzienda !== undefined) {
    output.writeFieldBegin('cfAzienda', Thrift.Type.STRING, 1);
    output.writeString(this.cfAzienda);
    output.writeFieldEnd();
  }
  if (this.serviceName !== null && this.serviceName !== undefined) {
    output.writeFieldBegin('serviceName', Thrift.Type.STRING, 2);
    output.writeString(this.serviceName);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ListSignerResponse = module.exports.ListSignerResponse = function(args) {
  this.signers = null;
  this.totalFRM = null;
  this.remainingFRM = null;
  this.totalFSM = null;
  this.remainingFSM = null;
  if (args) {
    if (args.signers !== undefined && args.signers !== null) {
      this.signers = Thrift.copyList(args.signers, [apiCommon_ttypes.SignerRef]);
    }
    if (args.totalFRM !== undefined && args.totalFRM !== null) {
      this.totalFRM = args.totalFRM;
    }
    if (args.remainingFRM !== undefined && args.remainingFRM !== null) {
      this.remainingFRM = args.remainingFRM;
    }
    if (args.totalFSM !== undefined && args.totalFSM !== null) {
      this.totalFSM = args.totalFSM;
    }
    if (args.remainingFSM !== undefined && args.remainingFSM !== null) {
      this.remainingFSM = args.remainingFSM;
    }
  }
};
ListSignerResponse.prototype = {};
ListSignerResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.signers = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new apiCommon_ttypes.SignerRef();
          elem6.read(input);
          this.signers.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I64) {
        this.totalFRM = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I64) {
        this.remainingFRM = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I64) {
        this.totalFSM = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I64) {
        this.remainingFSM = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ListSignerResponse.prototype.write = function(output) {
  output.writeStructBegin('ListSignerResponse');
  if (this.signers !== null && this.signers !== undefined) {
    output.writeFieldBegin('signers', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.signers.length);
    for (var iter7 in this.signers)
    {
      if (this.signers.hasOwnProperty(iter7))
      {
        iter7 = this.signers[iter7];
        iter7.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.totalFRM !== null && this.totalFRM !== undefined) {
    output.writeFieldBegin('totalFRM', Thrift.Type.I64, 2);
    output.writeI64(this.totalFRM);
    output.writeFieldEnd();
  }
  if (this.remainingFRM !== null && this.remainingFRM !== undefined) {
    output.writeFieldBegin('remainingFRM', Thrift.Type.I64, 3);
    output.writeI64(this.remainingFRM);
    output.writeFieldEnd();
  }
  if (this.totalFSM !== null && this.totalFSM !== undefined) {
    output.writeFieldBegin('totalFSM', Thrift.Type.I64, 4);
    output.writeI64(this.totalFSM);
    output.writeFieldEnd();
  }
  if (this.remainingFSM !== null && this.remainingFSM !== undefined) {
    output.writeFieldBegin('remainingFSM', Thrift.Type.I64, 5);
    output.writeI64(this.remainingFSM);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};
