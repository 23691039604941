import React, { useState, createContext } from "react";
import { useQuery } from "./hooks/useQuery";

const getIsEmbed = val => ["yes", "true", "1", ""].includes(val);

export const EmbedContext = createContext({ isEmbed: false });

export const EmbedProvider = ({ children }) => {
    const query = useQuery();
    const [isEmbed] = useState(getIsEmbed(query.get("embed")));

    return <EmbedContext.Provider value={{ isEmbed }}>{children}</EmbedContext.Provider>;
};
