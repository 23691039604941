import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import sha256 from "sha256";

import { activateUser, resetActivateUser } from "../../actions/user";
import Logo from "../../components/general/logo";
import UserActivationForm from "../../components/user-activation-form";
import { useFeedbackPage } from "../../hooks/use-feedback-page";
import { Container, ContentContainer, LogoContainer } from "./styled";

export const UserActivationWithPassword = ({ activateUser, activationStatus, history, match, resetActivateUser }) => {
    useFeedbackPage(match.url, history, activationStatus, resetActivateUser);

    const handleSubmit = useCallback(
        ({ password }) => {
            const params = new URLSearchParams(window.location.href.split("?")[1]);
            const userId = decodeURIComponent(match.params.userId);
            const sha256UserPassword = sha256(userId + password);
            activateUser(userId, params.get("activationToken"), sha256UserPassword);
        },
        [activateUser, match.params.userId]
    );

    return (
        <Container>
            <ContentContainer>
                <LogoContainer>
                    <Logo height={115} withTs={true} />
                </LogoContainer>
                <UserActivationForm loading={activationStatus.started} onSubmit={handleSubmit} />
            </ContentContainer>
        </Container>
    );
};

UserActivationWithPassword.propTypes = {
    activateUser: PropTypes.func.isRequired,
    activationStatus: PropTypes.shape({
        ended: PropTypes.bool,
        error: PropTypes.bool
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            userId: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    resetActivateUser: PropTypes.func.isRequired
};

export default connect(state => ({ activationStatus: state.activateUser }), { activateUser, resetActivateUser })(
    UserActivationWithPassword
);
