import React from "react";
import PropTypes from "prop-types";

import { Radio } from "antd";

import { RadioButton, SharingSettingsContainer, SharingSettingsTitle } from "./styled";

const RadioGroup = Radio.Group;

const ConnectionSettingsSharing = ({
    firstRadioLabel,
    isExtensionActive,
    isVisible,
    onChange,
    secondRadioLabel,
    selectedValue,
    title,
    isArcService,
    readonly = false
}) => {
    return (
        isVisible && (
            <SharingSettingsContainer>
                <SharingSettingsTitle>{title}</SharingSettingsTitle>
                <RadioGroup value={selectedValue} onChange={e => onChange(e.target.value)}>
                    <RadioButton disabled={!isExtensionActive && !isArcService && !readonly} value={true}>
                        {firstRadioLabel}
                    </RadioButton>
                    <RadioButton disabled={false} value={false}>
                        {secondRadioLabel}
                    </RadioButton>
                </RadioGroup>
            </SharingSettingsContainer>
        )
    );
};

ConnectionSettingsSharing.propTypes = {
    firstRadioLabel: PropTypes.any,
    isExtensionActive: PropTypes.bool,
    isVisible: PropTypes.bool,
    onChange: PropTypes.func,
    secondRadioLabel: PropTypes.any,
    selectedValue: PropTypes.bool,
    title: PropTypes.any,
    readonly: PropTypes.bool
};

export default ConnectionSettingsSharing;
