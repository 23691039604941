export const SHOW_OVERFLOW = "SHOW_OVERFLOW";
export const HIDE_OVERFLOW = "HIDE_OVERFLOW";

export function displayInfoToast(message, title) {
    return dispatch => displayToast(dispatch, "info", message, title);
}

export function displaySuccessToast(message, title) {
    return dispatch => displayToast(dispatch, "success", message, title);
}

export function displayErrorToast(message, title) {
    return dispatch => displayToast(dispatch, "error", message, title);
}

export function displayWarningToast(message, title) {
    return dispatch => displayToast(dispatch, "warning", message, title);
}

function displayToast(dispatch, type, message, title) {
    dispatch({
        type: SHOW_OVERFLOW,
        payload: {
            type,
            title,
            message
        }
    });
}

export function hideToast() {
    return dispatch => {
        dispatch({
            type: HIDE_OVERFLOW
        });
    };
}
