import React, { Component } from "react";
import PropTypes from "prop-types";

import { Collapse } from "antd";
import { isNil } from "ramda";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";

import { createManagerLink } from "../../actions/connections/write";
import { findOwnManagedConnections } from "../../actions/connections/read";
import { getCompanies } from "../../actions/companies";
import { fetchItemServices } from "../../actions/services/service-subscription-v2";
import { ConnectionForm, InfoBox, StepperAction } from "../../components/connections-create";

import CreationLayout from "../../components/connections-create/creation-layout";
import { errorBoundary } from "../../components/general/error-boundary";

import { getConnectableServices } from "../../utils/state-utils/services";

import "./style.css";

const Panel = Collapse.Panel;

class ConnectionsCreateExistingCompany extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: 0,
            showDisplayResult: false
        };
    }

    static propTypes = {
        agyoServices: PropTypes.array.isRequired,
        company: PropTypes.object.isRequired,
        companyStatus: PropTypes.object.isRequired,
        creatingLink: PropTypes.bool.isRequired,
        creatingLinkEnded: PropTypes.bool.isRequired,
        getCompanies: PropTypes.func.isRequired,
        linkCreationFailed: PropTypes.bool.isRequired,
        managerId: PropTypes.string.isRequired,
        selectedCompany: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        users: PropTypes.array.isRequired
    };

    static defaultProps = {
        users: []
    };

    componentDidMount() {
        const { fetchItemServices, findOwnManagedConnections, getCompanies, itemServicesLoaded, match } = this.props;

        getCompanies(null, 0, 1, null, [match.params.companyId]);
        findOwnManagedConnections(
            {
                active: true,
                deleted: false,
                managerIds: [match.params.item],
                managedIds: [match.params.companyId],
                unpaged: true
            },
            { page: 0, size: 10 }
        );

        if (!itemServicesLoaded) {
            fetchItemServices(true, match.params.item);
        }
    }

    componentDidUpdate(prevProps) {
        const { creatingLinkEnded, fetchItemServices, itemServicesLoaded, itemServicesStatus, match } = this.props;

        if (!itemServicesLoaded && !itemServicesStatus.started && !itemServicesStatus.ended) {
            fetchItemServices(true, match.params.item);
        }

        if (!prevProps.creatingLinkEnded && creatingLinkEnded) {
            this.setState({ showDisplayResult: true });
        }
    }

    handleNext(stepSize = 1) {
        const { activeKey } = this.state;
        if (activeKey < 2) {
            this.setState({
                activeKey: activeKey + stepSize <= 2 ? activeKey + stepSize : 2
            });
        }
    }

    handlePrevious = () => {
        const { activeKey } = this.state;

        this.setState({ activeKey: activeKey - 1 });
    };

    handleSubmit = connection => {
        const { createManagerLink, managerId, selectedCompany } = this.props;

        createManagerLink(
            managerId,
            selectedCompany.base.id,
            connection.appId,
            connection.featureCode,
            connection.role,
            connection.serviceId,
            connection.techUsers
        );
    };

    render() {
        const { activeKey, showDisplayResult } = this.state;
        const {
            agyoServices,
            agyoServicesLoading,
            company,
            companyStatus,
            creatingLink,
            findConnectionsStatus,
            selectedCompany,
            users
        } = this.props;

        const techUsers = users
            .filter(u => u.user.profile.type === "TECNICA" && u.user.status.active)
            .map(u => ({
                authId: u.user.profile.authId,
                id: u.user.profile.id,
                description: u.user.profile.description
            }));

        return (
            <CreationLayout
                connectedCompany={selectedCompany.base}
                creationSuccess={showDisplayResult}
                loading={companyStatus.started && findConnectionsStatus.started && !companyStatus.ended}
            >
                <Collapse accordion bordered={false} activeKey={[String(activeKey)]}>
                    <Panel header={<FormattedMessage id="connections.create.step-1.name" />} key="0">
                        <InfoBox selectedCompany={selectedCompany.base} />
                        <StepperAction next={() => this.handleNext()} />
                    </Panel>
                    <Panel header={<FormattedMessage id="connections.create.step-4.name" />} key="1">
                        <ConnectionForm
                            disabled={creatingLink}
                            loadingServices={agyoServicesLoading}
                            onCancel={this.handlePrevious}
                            onSubmit={this.handleSubmit}
                            services={agyoServices.filter(
                                s => (company && company.base.details.classifier === "STUDIO") || s.appId === "PRIVACY"
                            )}
                            techUsers={techUsers}
                        />
                    </Panel>
                </Collapse>
            </CreationLayout>
        );
    }
}

function mapStateToProps(state, props) {
    const findConnectionsStatus = state.connectionsNew.read.findOwnManagedConnections.status;
    let agyoServices = [];
    if (findConnectionsStatus.ended) {
        agyoServices = getConnectableServices(state, props.match.params.item, props.match.params.companyId).filter(
            s => s.active || state.services.active.services.includes(s.id)
        );
    }

    const serviceCategories = agyoServices.reduce((acc, curr) => {
        if (acc.findIndex(s => s.appId === curr.appId) === -1) {
            acc.push({
                appId: curr.appId,
                appDescription: curr.appDescription
            });
        }

        return acc;
    }, []);

    const services = serviceCategories.map(app => ({
        appId: app.appId,
        appDescription: app.appDescription,
        features: agyoServices.filter(s => s.appId === app.appId)
    }));

    return {
        agyoServices: services,
        agyoServicesLoading: state.services.agyoServices.status.started,
        company: state.companies.data[props.match.params.item]
            ? state.companies.data[props.match.params.item].item
            : {},
        companyStatus: state.companies.total.status,
        creatingLink: state.connectionsNew.write.createLink.status.started,
        creatingLinkEnded: state.connectionsNew.write.createLink.status.ended,
        findConnectionsStatus: state.connectionsNew.read.findOwnManagedConnections.status,
        itemServicesStatus: state.services.itemServices.status,
        itemServicesLoaded: !isNil(state.services.itemServices[props.match.params.item]),
        linkCreationFailed: state.connectionsNew.write.createLink.status.error,
        managerId: state.companies.data[props.match.params.item]
            ? state.companies.data[props.match.params.item].item.base.id
            : "",
        selectedCompany: state.companies.data[props.match.params.companyId]
            ? state.companies.data[props.match.params.companyId].item
            : {},
        user: state.user.user,
        users: state.users.users
    };
}

const actions = {
    createManagerLink,
    fetchItemServices,
    findOwnManagedConnections,
    getCompanies
};

const composedHoc = compose(connect(mapStateToProps, actions), errorBoundary);

export default composedHoc(ConnectionsCreateExistingCompany);
