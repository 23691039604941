import React from "react";
import { connect } from "react-redux";
import { errorBoundary } from "../../../components/general/error-boundary";
import { AGRO_URL } from "../../../config";

import { loadCompany } from "../../../actions/company";

import { extractUuid } from "../../../utils/tokenUtils";
import MigratingChildApp from "../../portale-child-app/migrating";
import { useFeature } from "../../../hooks/use-feature";

const AgroView = ({ auth, item, userProfile }) => {
    const enableChildApp = useFeature("AGRO_CHILD_APP");

    return (
        <MigratingChildApp
            customLaunchData={{ appName: "AGRO" }}
            iframeConfigs={{
                id: "agro",
                onLoad: iframeRef => {
                    iframeRef.contentWindow.postMessage(
                        {
                            auth: {
                                securityToken: auth.securityToken,
                                refreshToken: auth.refreshToken,
                                appName: "AGRO",
                                userId: extractUuid(auth.securityToken)
                            },
                            user: {
                                email: userProfile.id,
                                tsId: userProfile.tsid,
                                firstName: userProfile.firstName,
                                lastName: userProfile.lastName
                            },
                            itemId: item.uuid,
                            oldId: item.id,
                            language: userProfile.language
                        },
                        AGRO_URL
                    );
                },
                title: "agro"
            }}
            migrated={enableChildApp}
            url={AGRO_URL}
        />
    );
};

const actions = {
    loadCompany
};

const mapStateToProps = state => {
    return {
        auth: state.auth.loginAuth,
        item: state.company.base,
        userProfile: state.user.user.profile
    };
};

export default errorBoundary(connect(mapStateToProps, actions)(AgroView));
