export const getNcsBasePath = (selectedItemId, dashboardItemId) => {
    let base = "/notifications-center";

    if (dashboardItemId) {
        base = `/${dashboardItemId}/notifications-center`;
    }
    if (selectedItemId) {
        return `${base}/${selectedItemId}`;
    }

    return base;
};
