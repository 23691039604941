import styled from "styled-components";

export const FilterContainer = styled.div`
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    // grid-gap: 16px;
    display: flex;
    gap: 24px;
`;

export const LabelContainer = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
`;

export const SelectContainer = styled.div`
    min-width: 250px;
`;
