import {
    GET_NCS_PREFERENCES_LIST_START,
    GET_NCS_PREFERENCES_LIST_ERROR,
    GET_NCS_PREFERENCES_LIST_SUCCESS
} from "../../../actions/ncs/read";

import { UPDATE_NCS_SINGLE_PREFERENCE_START } from "../../../actions/ncs/write";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    },
    data: []
};

export const preferences = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_NCS_PREFERENCES_LIST_START:
            return {
                ...state,
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_NCS_PREFERENCES_LIST_SUCCESS:
            return {
                ...state,
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                data: payload
            };
        case GET_NCS_PREFERENCES_LIST_ERROR:
            return {
                ...state,
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case UPDATE_NCS_SINGLE_PREFERENCE_START:
            return {
                ...state,
                data: state.data.map(application => {
                    const { appCode, preferences } = application;
                    return appCode === payload.appCode
                        ? {
                              ...application,
                              preferences: preferences.map(preference => {
                                  return preference.type === payload.templateType
                                      ? {
                                            ...preference,
                                            channels: preference.channels.map(channel => ({
                                                ...channel,
                                                enabled: !!payload.channels.find(ch => channel.type === ch)
                                            }))
                                        }
                                      : preference;
                              })
                          }
                        : application;
                })
            };
        default:
            return state;
    }
};
