/**
 * Given a JSON, checks whether it conforms to the jsonrpc specifications.
 * If the object's shape does not respect the specifications, an exception will be thrown.
 *
 * @param {object} json The input JSON
 */
export function validateJsonAsRpc(json) {
    if (!json.jsonrpc || json.jsonrpc !== "2.0") {
        const ex = { message: "Missing or invalid jsonrpc parameter" };
        throw ex;
    }

    if (!json.method) {
        const ex = { message: "Missing or invalid method parameter" };
        throw ex;
    }

    return json;
}
