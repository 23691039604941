import React from "react";

import { Tooltip } from "antd";
import { FormattedMessage, FormattedHTMLMessage, useIntl } from "react-intl";
import { TextField } from "@ts-digital/arc/antd";
import { form } from "@ts-digital/conv-redux-form";

import { passwordSchema } from "../../utils/form-schema/password";
import LoadingButton from "../general/loading-button";
import { FormContainer } from "./styled";

export const UserActivationForm = ({ handleSubmit, loading }) => {
    const { formatMessage } = useIntl();

    return (
        <FormContainer>
            <Tooltip
                title={<FormattedHTMLMessage id="general.form.password-requirements-tooltip" />}
                placement="right"
                overlayStyle={{ maxWidth: "600px" }}
                trigger={["focus"]}
            >
                <TextField label={formatMessage({ id: "general.password" })} name="password" type="password" />
            </Tooltip>
            <TextField
                label={formatMessage({ id: "general.confirm-password" })}
                name="confirmPassword"
                type="password"
            />
            <LoadingButton loading={loading} onClick={handleSubmit}>
                <FormattedMessage id="c-user-activation-form.activate-account" />
            </LoadingButton>
        </FormContainer>
    );
};

const formDefinition = {
    form: "user-activation-form",
    schema: passwordSchema
};

export default form(formDefinition)(UserActivationForm);
