import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import PreferencesItem from "../preferences-item";

import { Container, Title, List, ListColumn, ListHeader, ListHeaderItem, ActionColumn } from "./styled";

import { NCS_ARRAY_CHANNELS } from "../../../config";

export const PreferencesList = ({ appCode, preferences, groupId, itemId }) => {
    return (
        <Container>
            <Title>
                <FormattedMessage id={`c-notifications-center.${appCode.toLowerCase()}.name`} />
            </Title>
            {preferences.length > 0 && (
                <List>
                    <ListHeader>
                        {NCS_ARRAY_CHANNELS.filter(channel => channel.visible).map(({ name, type }) => (
                            <ListColumn key={`channel-header-${type}`}>
                                <ListHeaderItem>{name}</ListHeaderItem>
                            </ListColumn>
                        ))}
                        <ActionColumn />
                    </ListHeader>

                    {preferences
                        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                        .map((preference, index) => (
                            <PreferencesItem
                                key={`preference-${appCode}-${index}`}
                                {...preference}
                                groupId={groupId}
                                itemId={itemId}
                            />
                        ))}
                </List>
            )}
        </Container>
    );
};

PreferencesList.propTypes = {
    appCode: PropTypes.string.isRequired,
    preferences: PropTypes.array.isRequired,
    groupId: PropTypes.string,
    itemId: PropTypes.string
};

export default PreferencesList;
