import { combineReducers } from "redux";

import { findOwnManagedConnections, findOwnManagerConnections, getConnection, linkedItems } from "./read";
import { createConnectionAndItem, createLink, deleteLink } from "./write";
import { connectionsData } from "./export";

const read = combineReducers({
    connectionsData,
    findOwnManagedConnections,
    findOwnManagerConnections,
    getConnection,
    linkedItems
});

const write = combineReducers({
    createConnectionAndItem,
    createLink,
    deleteLink
});

export default combineReducers({ read, write });
