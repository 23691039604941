import React from "react";
import PropTypes from "prop-types";

import styled, { css } from "styled-components";
import { FormattedMessage } from "react-intl";

export const Container = styled.div`
    background-color: #eb0057;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding-right: 12px;
    color: ${props => props.theme.colors.background.white};
    position: relative;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
        content: "";
        position: absolute;
        display: block;
        border-color: #eb0057 #eb0057 #eb0057 transparent;
        border-style: solid;
        border-width: 11px;
        left: -22px;
        top: 0;
    }

    ${props =>
        !props.show &&
        css`
            display: none;
        `}
`;

const NewFlag = ({ show }) => (
    <Container show={show}>
        <FormattedMessage id="general.releasenotes" />
    </Container>
);

NewFlag.propTypes = {
    show: PropTypes.bool
};

NewFlag.defaulProps = {
    show: false
};

export default NewFlag;
