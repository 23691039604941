import { fieldRequired, fieldLength } from "@ts-digital/agyo-validations";
import combineValidators from "@ts-digital/agyo-validations/lib/utils/combineValidators";

import { addressSchema } from "./address";
import { translateMessage, translateMessageWithParams } from "../../i18n";

export const officeSchema = {
    type: "object",
    properties: {
        ...addressSchema.properties,
        description: {
            type: "string",
            validate: desc =>
                combineValidators(
                    fieldRequired(translateMessage("general.form.field-required")),
                    fieldLength(
                        translateMessageWithParams("general.form.field-length-range", { min: 1, max: 255 }),
                        1,
                        255
                    )
                )(desc)
        },
        street: {
            // Rimane per mantenere la retrocompatibilità con la form degli Intermediari
            type: "string",
            validate: desc =>
                combineValidators(
                    fieldRequired(translateMessage("general.form.field-required")),
                    fieldLength(
                        translateMessageWithParams("general.form.field-length-range", { min: 1, max: 255 }),
                        1,
                        255
                    )
                )(desc)
        },
        govCode: {
            type: "string"
        }
    }
};
