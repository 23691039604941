import { SIGN_API_READ_URL } from "../../config";

import SigninApiRead from "../../assets/thrift/sign-api/SigninApiRead";
import SignApiReadTypes from "../../assets/thrift/sign-api/apiCommon_types";

import thrift from "browser-thrift";
import { isNil } from "ramda";

const signinReadUrl = new URL(SIGN_API_READ_URL);
const signinReadClient = thrift.createXHRClient(
    SigninApiRead,
    thrift.createXHRConnection(signinReadUrl.hostname, 443, {
        useCors: true,
        path: signinReadUrl.pathname,
        https: signinReadUrl.protocol === "https:"
    })
);

export async function asyncSignerValidation(values, props) {
    let initialVlaues = props.initialValues;

    const request = new SignApiReadTypes.SignerRef({
        id: isNil(values.signerId) ? null : values.signerId,
        cfAzienda: initialVlaues.cfAzienda,
        cfUtente: values.signerFiscalCode,
        certType: initialVlaues.certType,
        serviceName: initialVlaues.serviceName
    });

    return new Promise(async (resolve, reject) => {
        let signerFiscalCode;
        if (!isNil(values)) {
            const result = await signinReadClient.signerExist(request);
            if (result) {
                signerFiscalCode = "Firmatario già registrato.";
            }
        }

        if (signerFiscalCode !== undefined) {
            reject({ signerFiscalCode: signerFiscalCode });
        } else {
            resolve();
        }
    });
}
