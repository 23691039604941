import React, { useState } from "react";
import PropTypes from "prop-types";

import { includes } from "ramda";

import { isAppAvailable } from "../../utils/app-availability";
import ServiceCard from "../../components/general/service-card";
import SignatureComponent from "../signature/signature/index";
import CbCr from "./cbcr";
import { ServiceCardsContainer } from "../general/service-card/styled";

const Fulfilments = ({
    activateService,
    company,
    match,
    services: { aFiscaliIva, active, categories, cbCr, agyoServices },
    user,
    getWaitingList,
    getWaitingListStatus,
    subscribeWaitinglist,
    waitinglist,
    waitinglistSubscribeStatus,
    deleteWaitinglist,
    waitinglistDeleteStatus
}) => {
    const [selectedCard, setSelectedCard] = useState(0);
    const [sendIvaIsActive, setSendIvaIsActive] = useState(false);

    const handleActivateService = ({ activateService, company }) => {
        activateService(company.base.id, "A-FISCALI-IVA-FLOW", {
            sendIva: "true"
        });
        setSendIvaIsActive(true);
    };

    const roles = user.user && user.user.roles ? user.user.roles : [];

    let isCbcrActiveInAgyo = agyoServices.services.some(service => service.id === "CBCR-FLOW" && service.active);
    let isAdempimentiActiveInAgyo = agyoServices.services.some(
        service => service.id === "A-FISCALI-IVA-FLOW" && service.active
    );

    const servicesConfig = [
        {
            title: "c-fulfilments.send",
            description: "c-fulfilments.send-description",
            active: aFiscaliIva.sendIva || sendIvaIsActive,
            disable: !isAdempimentiActiveInAgyo,
            display:
                (selectedCard === 0 || selectedCard === 1) &&
                isAppAvailable(
                    company.base.id,
                    roles,
                    "A-FISCALI-IVA-FLOW",
                    active.availableToUser,
                    categories.data["TEL"],
                    active.services
                ),
            showActiveButton: sendIvaIsActive ? false : !aFiscaliIva.sendIva,
            onActive: () => handleActivateService({ activateService, company }),
            serviceInfo: agyoServices.services.filter(ele => ele.id === "A-FISCALI-IVA-FLOW")[0]
        },
        {
            title: "c-fulfilments.sign",
            description: "c-fulfilments.sign.description",
            active: aFiscaliIva.signatureIva,
            disable: !isAdempimentiActiveInAgyo,
            display:
                (selectedCard === 0 || selectedCard === 2) &&
                isAppAvailable(
                    company.base.id,
                    roles,
                    "A-FISCALI-IVA-FLOW",
                    active.availableToUser,
                    categories.data["TEL"],
                    active.services
                ),
            tooltip: "c-fulfilments.mass-sign.notes",
            showContactToBuy: true,
            showConfigureButton: aFiscaliIva.signatureIva,
            onConfigure: () => setSelectedCard(2),
            onClose: () => setSelectedCard(0),
            expanded: selectedCard === 2,
            expandedComponent: (
                <SignatureComponent match={match} servicesType={"A-FISCALI-IVA-FLOW"} signatureType={"FRM"} />
            ),
            serviceInfo: agyoServices.services.filter(ele => ele.id === "A-FISCALI-IVA-FLOW")[0]
        },
        {
            title: "c-fulfilments.agyo-fulfilments-title",
            description: "c-fulfilments.agyo-fulfilments-description",
            active: aFiscaliIva.multiSendIva,
            disable: !isAdempimentiActiveInAgyo,
            display:
                (selectedCard === 0 || selectedCard === 3) &&
                isAppAvailable(
                    company.base.id,
                    roles,
                    "A-FISCALI-IVA-FLOW",
                    active.availableToUser,
                    categories.data["TEL"],
                    active.services
                ),
            showContactToBuy: true,
            serviceInfo: agyoServices.services.filter(ele => ele.id === "A-FISCALI-IVA-FLOW")[0]
        },
        {
            title: "c-fulfilments.cbcr-title",
            description: "c-fulfilments.cbcr-description",
            active: includes("CBCR-FLOW", active.services),
            disable: !isCbcrActiveInAgyo,
            display:
                (selectedCard === 0 || selectedCard === 4) &&
                isAppAvailable(
                    company.base.id,
                    roles,
                    "CBCR-FLOW",
                    active.availableToUser,
                    categories.data["TEL"],
                    active.services
                ),
            expanded: selectedCard === 4,
            expandedComponent: <CbCr cbCr={cbCr} />,
            serviceInfo: agyoServices.services.filter(ele => ele.id === "CBCR-FLOW")[0]
        },
        {
            title: "c-fulfilments.multisend-title",
            description: "c-fulfilments.multisend-description",
            active: aFiscaliIva.multiSendIva,
            disable: !isAdempimentiActiveInAgyo,
            display:
                aFiscaliIva.multiSendIva === "true" &&
                (selectedCard === 0 || selectedCard === 5) &&
                isAppAvailable(
                    company.base.id,
                    roles,
                    "CBCR-FLOW",
                    active.availableToUser,
                    categories.data["TEL"],
                    active.services
                ),
            serviceInfo: agyoServices.services.filter(ele => ele.id === "CBCR-FLOW")[0]
        }
    ];

    return (
        aFiscaliIva &&
        active.services && (
            <ServiceCardsContainer expanded={selectedCard > 0}>
                {servicesConfig.map((service, key) => (
                    <ServiceCard
                        key={key}
                        active={service.active}
                        disable={service.disable}
                        expanded={service.expanded}
                        expandedComponent={service.expandedComponent}
                        title={service.title}
                        description={service.description}
                        display={service.display}
                        button={service.button}
                        tooltip={service.tooltip}
                        showActiveButton={service.showActiveButton}
                        onActive={service.onActive}
                        onConfigure={service.onConfigure}
                        onClose={service.onClose}
                        showBuyButton={service.showBuyButton}
                        showContactToBuy={service.showContactToBuy}
                        showConfigureButton={service.showConfigureButton}
                        serviceInfo={service.serviceInfo}
                        companyId={company.base.id}
                        getWaitingList={getWaitingList}
                        getWaitingListStatus={getWaitingListStatus}
                        waitinglist={waitinglist}
                        subscribeWaitinglist={subscribeWaitinglist}
                        deleteWaitinglist={deleteWaitinglist}
                        waitinglistDeleteStatus={waitinglistDeleteStatus}
                        waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                        user={user}
                    />
                ))}
            </ServiceCardsContainer>
        )
    );
};

Fulfilments.propTypes = {
    activateService: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    services: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    getWaitingList: PropTypes.func,
    waitinglist: PropTypes.array,
    subscribeWaitinglist: PropTypes.func,
    waitinglistSubscribeStatus: PropTypes.object
};

export default Fulfilments;
