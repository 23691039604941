import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@ts-digital/vrc";
import { faKey as faKeySolid } from "@fortawesome/free-solid-svg-icons";
import { faKey as faKeyRegular } from "@fortawesome/pro-regular-svg-icons";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { Table } from "../../../general/table";
import { CheckRow } from "../../../general/table/check-row";
import { StatusCell } from "./status-cell";
import { ActionCell } from "./action-cell";
import { ManagegFiltersBar } from "./managed-filters";

import {
    Container,
    Content,
    Title,
    Description,
    TitleWrapper,
    TableWrapper,
    SubDescription,
    Actions,
    WarningIcon
} from "./styled";

export const ConfigureManagedItemsPersonal = ({
    loading,
    cctConfigManaged,
    page,
    onPageChange,
    onDisableService,
    onEnableService,
    onSelectRow,
    onSelectAllRow,
    onRefresh,
    onFilterChange,
    selectedRows,
    filters
}) => {
    cctConfigManaged = cctConfigManaged || { configs: [], totalElements: 0, totalPages: 0 };
    const pageSize = 10;
    const { totalPages, configs } = cctConfigManaged;
    const config = configs && configs[0] ? configs[0] : {};
    const ownerId = config ? config.ownerId : "";

    configs.forEach(config => {
        config.checked = selectedRows && selectedRows.includes(config.itemId);
    });

    const selectedAll = configs.length === selectedRows.length && selectedRows.length !== 0;

    const tableColumns = useMemo(
        () => [
            {
                accessor: "selected",
                Header: <CheckRow row={{ original: { checked: selectedAll } }} onChange={onSelectAllRow} />,
                Cell: props => <CheckRow row={props.row} onChange={onSelectRow} />,
                width: 32
            },
            {
                accessor: "itemId",
                Header: <FormattedMessage id="c-cct-provisioning.managed.columns.itemTaxId" />
            },
            { accessor: "itemDescription", Header: <FormattedMessage id="general.ragione-sociale" /> },
            {
                accessor: "active",
                Header: <FormattedMessage id="general.stato" />,
                Cell: StatusCell
            },
            {
                accessor: "enable",
                Header: <FormattedMessage id="general.action" />,
                Cell: props => (
                    <ActionCell
                        action="enable"
                        row={props.row.original}
                        onEnable={(ownerId, itemId) => onEnableService(ownerId, [itemId])}
                        onDisable={(ownerId, itemId) => onDisableService(ownerId, [itemId])}
                    />
                )
            }
        ],
        [onDisableService, onEnableService, onSelectRow, onSelectAllRow, selectedAll]
    );
    return (
        <Container>
            <Content>
                <TitleWrapper>
                    <Title>
                        <FormattedMessage id="c-cct-item-services-section-title" />
                    </Title>
                </TitleWrapper>
                <TitleWrapper>
                    <Description>
                        <FormattedMessage id="c-cct-configure-managed-items-personal-title" />
                    </Description>
                </TitleWrapper>
                <TitleWrapper>
                    <SubDescription>
                        <FormattedMessage id="c-cct-item-managed-items-personal-subtitle" />
                        <br />
                        <WarningIcon icon={faExclamationCircle} />
                        <FormattedHTMLMessage id="c-cct-item-managed-items-registry-warning" />
                    </SubDescription>
                </TitleWrapper>
                <ManagegFiltersBar
                    loading={loading}
                    onFilterChange={onFilterChange}
                    onRefresh={onRefresh}
                    options={[
                        { value: "", filterType: "any", label: <FormattedMessage id="c-cct-status.filter.any" /> },
                        {
                            value: true,
                            filterType: "active",
                            label: (
                                <FormattedMessage id={`c-cct-configure-managed-items-personal-columns.filter.active`} />
                            )
                        },
                        {
                            value: false,
                            filterType: "active",
                            label: (
                                <FormattedMessage
                                    id={`c-cct-configure-managed-items-personal-columns.filter.not-active`}
                                />
                            )
                        }
                    ]}
                    filters={filters}
                />
                <TitleWrapper>
                    <Actions>
                        <Button
                            size="small"
                            disabled={selectedRows.length === 0}
                            kind="tertiary"
                            to="configure-managed-items-personal/"
                            onClick={() => onEnableService(ownerId, selectedRows)}
                        >
                            <FontAwesomeIcon icon={faKeyRegular} />{" "}
                            <FormattedMessage
                                id={"c-cct-configure-managed-items-personal-columns.actions.enable-all"}
                            />
                        </Button>
                        <Button
                            size="small"
                            disabled={selectedRows.length === 0}
                            kind="tertiary"
                            to="configure-managed-items-personal/"
                            onClick={() => onDisableService(ownerId, selectedRows)}
                        >
                            <FontAwesomeIcon icon={faKeySolid} />{" "}
                            <FormattedMessage
                                id={"c-cct-configure-managed-items-personal-columns.actions.disable-all"}
                            />
                        </Button>
                    </Actions>
                </TitleWrapper>
                <TableWrapper>
                    <Table
                        columns={tableColumns}
                        data={configs}
                        loading={loading}
                        onPageChange={onPageChange}
                        page={page}
                        pageSize={pageSize}
                        totalPages={totalPages}
                    />
                </TableWrapper>
            </Content>
        </Container>
    );
};

ConfigureManagedItemsPersonal.propTypes = {
    loading: PropTypes.bool,
    cctConfigManaged: PropTypes.object,
    onPageChange: PropTypes.func.isRequired,
    onEnableService: PropTypes.func.isRequired,
    onSelectRow: PropTypes.func.isRequired,
    onSelectAllRow: PropTypes.func.isRequired,
    onDisableService: PropTypes.func.isRequired,
    onRefresh: PropTypes.func.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    page: PropTypes.number
};

export default ConfigureManagedItemsPersonal;
