import styled from "styled-components";

export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    & button {
        margin: 5px;
        font-weight: 600;
    }
`;

export const TableTitle = styled.div`
    padding: 8px;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
`;

export const SetSmartRdcButtonContainer = styled.div`
    width: 350px;
    padding-bottom: 16px;
`;

export const CheckContainer = styled.td`
    text-align: center;
    color: rgb(0, 144, 209);

    & svg {
        margin-left: 8px;
    }
`;

export const NumberSelectedContainer = styled.div`
    color: rgb(21, 41, 53);
`;
