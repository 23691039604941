import React from "react";
import PropTypes from "prop-types";

import { includes } from "ramda";
import { FormattedMessage } from "react-intl";

import { isAppAvailable } from "../../utils/app-availability";
import ServiceCard from "../../components/general/service-card";
import { ServiceCardsContainer } from "../general/service-card/styled";

class Privacy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCard: 0
        };
    }

    static propTypes = {
        company: PropTypes.object.isRequired,
        services: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired
    };

    renderCards() {
        const {
            company,
            services: { active, agyoServices, categories },
            user,
            getWaitingList,
            getWaitingListStatus,
            subscribeWaitinglist,
            deleteWaitinglist,
            waitinglistDeleteStatus,
            waitinglist,
            waitinglistSubscribeStatus
        } = this.props;

        const roles = user.user && user.user.roles ? user.user.roles : [];

        const { selectedCard } = this.state;
        let category = {
            title: "Privacy"
        };

        let isActiveInAgyo = agyoServices.services.some(service => service.id === "PRIVACY-FLOW" && service.active);
        return active.services ? (
            <ServiceCardsContainer expanded={selectedCard > 0}>
                <ServiceCard
                    disable={!isActiveInAgyo}
                    ribbonActive={!isActiveInAgyo}
                    ribbon={{ text: "In arrivo", color: "grey" }}
                    buyLink={
                        "https://www.teamsystem.com/store/adempimenti/agyo-privacy/prezzi/?utm_medium=referral&utm_source=app-agyo&utm_campaign=agyo-privacy_sitelink-piattaforma"
                    }
                    packageInfo={{
                        active: includes("PRIVACY-FLOW", active.services)
                    }}
                    button={{ active: false }}
                    display={
                        (selectedCard === 0 || selectedCard === 1) &&
                        isAppAvailable(
                            company.base.id,
                            roles,
                            "PRIVACY-FLOW",
                            active.availableToUser,
                            categories.data["PRIVACY"],
                            active.services
                        )
                    }
                    expanded={false}
                    title={<FormattedMessage id="c-services-view.PRIVACY.name" />}
                    description="L'applicazione in Agyo per gestire la privacy secondo il Regolamento Europeo sulla Privacy 2016/679."
                    category={category}
                    serviceInfo={agyoServices.services.filter(ele => ele.id === "PRIVACY-FLOW")[0]}
                    companyId={company.base.id}
                    getWaitingList={getWaitingList}
                    getWaitingListStatus={getWaitingListStatus}
                    waitinglist={waitinglist}
                    subscribeWaitinglist={subscribeWaitinglist}
                    deleteWaitinglist={deleteWaitinglist}
                    waitinglistDeleteStatus={waitinglistDeleteStatus}
                    waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                    user={user}
                />
            </ServiceCardsContainer>
        ) : null;
    }
    render() {
        return <div className="c-privacy">{this.renderCards()}</div>;
    }
}

export default Privacy;
