import styled from "styled-components";

export const MeteringCard = styled.div`
    background-color: #fff;
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
`;

export const MeteringCardContent = styled.div`
    padding: 16px 24px;
`;

export const MeteringCardAction = styled.div`
    padding: 8px 24px;
`;
