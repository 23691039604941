import React from "react";

import { BUSINESS_INFORMATION_WEBAPP_URL } from "../../../config";
import PortaleChildApp from "../../portale-child-app";

const BusinessInformationView = () => {
    return <PortaleChildApp url={`${BUSINESS_INFORMATION_WEBAPP_URL}`} />;
};

export default BusinessInformationView;
