import styled from "styled-components";

export const InitialsSpan = styled.span`
    border-radius: 50%;
    background: white;
    color: #005075;
    border: 1px solid #0090d1;
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 40px;
    margin-right: 8px;
`;
