import { API_URL } from "../../config";
import { getRestCall } from "../../utils/rest-api-call";
import { getUserProfile } from "../../utils/state-utils/get-user-profile";
import { getAuthWithAppName } from "../auth";

export const GET_WORKSPACE_PERMISSIONS_START = "GET_WORKSPACE_PERMISSIONS_START";
export const GET_WORKSPACE_PERMISSIONS_SUCCESS = "GET_WORKSPACE_PERMISSIONS_SUCCESS";
export const GET_WORKSPACE_PERMISSIONS_ERROR = "GET_WORKSPACE_PERMISSIONS_ERROR";

export const getWorkspacePermissions = (userId, workspaceId) => async (dispatch, getState) => {
    dispatch({ type: GET_WORKSPACE_PERMISSIONS_START });
    try {
        const auth = getAuthWithAppName(getState().auth);
        const res = await getRestCall(
            `${API_URL}/services/workspace-permissions/${userId}/${workspaceId}`,
            auth,
            {},
            dispatch,
            auth.refreshToken,
            getUserProfile(getState())
        );
        dispatch({ type: GET_WORKSPACE_PERMISSIONS_SUCCESS, payload: res });
    } catch (e) {
        dispatch({ type: GET_WORKSPACE_PERMISSIONS_ERROR, error: e });
    }
};
