import React, { useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Action, Label, Actions, CheckboxContainer } from "./styled";
import moment from "moment";
import { Usage } from "../../../views/metering/billing/usage";
import { History } from "../../../views/metering/billing/history";
import { DatePicker, Checkbox } from "antd";
import { Searchbar } from "../../../views/metering/billing/searchbar";

const { RangePicker } = DatePicker;

export const Billing = () => {
    const [filter, setFilter] = useState({
        from: moment.utc().startOf("year").add(10, "days").startOf("day"),
        to: moment().utc().endOf("day"),
        item: ""
    });
    const [viewOldBillingCycles, setViewOldBillingCycles] = useState(false);

    const handlePeriodChange = useCallback(
        (dates, period) => {
            if (!period || period.length < 2) {
                return;
            }
            const newFrom = moment.utc(period[0], "DD/M/YYYY").startOf("day");
            const newTo = moment.utc(period[1], "DD/M/YYYY").endOf("day");
            if (newFrom.isSame(filter.from, "day") && newTo.isSame(filter.to, "day")) {
                return;
            }
            setFilter({
                ...filter,
                from: newFrom,
                to: newTo
            });
        },
        [filter]
    );

    const handleSearch = useCallback(
        item => {
            setFilter({ ...filter, item });
        },
        [filter]
    );

    const handleError = useCallback(() => {
        setFilter({ ...filter, item: "" });
    }, [filter]);

    const handleViewOldBillingCycles = useCallback(event => {
        setViewOldBillingCycles(event.target.checked);
    }, []);

    return (
        <>
            <Usage />
            <Actions>
                <Action>
                    <Searchbar onSearch={handleSearch} onError={handleError} />
                </Action>
                <Action grow={2} />
                <Action grow={0.5}>
                    <Label>
                        <FormattedMessage id="metering.services.invoices.period.label" />
                    </Label>
                    <RangePicker
                        value={[filter.from, filter.to]}
                        size="large"
                        onChange={handlePeriodChange}
                        format="DD/MM/YYYY"
                    />
                    <CheckboxContainer>
                        <Checkbox onChange={handleViewOldBillingCycles} value={viewOldBillingCycles}>
                            Visualizza vecchi cicli di fatturazione
                        </Checkbox>
                    </CheckboxContainer>
                </Action>
            </Actions>
            <History
                from={filter.from.format()}
                to={filter.to.format()}
                filteredItem={filter.item}
                viewOldBillingCycles={viewOldBillingCycles}
            />
        </>
    );
};
