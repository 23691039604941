import React, { Component } from "react";
import PropTypes from "prop-types";
import { isNil } from "ramda";

import { FormattedMessage, injectIntl } from "react-intl";

import { Table, Modal, Button, Input } from "antd";

import CreateOfficeForm from "../create/form";
import IntermediaryRow from "./row";
import InfoIntermediary from "../info";

const Search = Input.Search;

const locale = {
    emptyText: <FormattedMessage id="general.no-result" />
};
const columns = [
    {
        title: "general.creation-date",
        dataIndex: "base.status.createdAt",
        key: "base.status.createdAt",
        width: "40%"
    },
    {
        title: "general.description",
        dataIndex: "base.details.description",
        key: "base.details.description"
    },
    {
        title: "general.action"
    }
];

class Intermediaries extends Component {
    static propTypes = {
        company: PropTypes.object.isRequired,
        createOffice: PropTypes.func.isRequired,
        createOfficeStatus: PropTypes.object.isRequired,
        deleteOffice: PropTypes.func.isRequired,
        deleteOfficeStatus: PropTypes.object.isRequired,
        enableCreation: PropTypes.bool.isRequired,
        intl: PropTypes.any.isRequired,
        updateOffice: PropTypes.func.isRequired,
        updateOfficeStatus: PropTypes.object.isRequired
    };

    constructor() {
        super();
        this.state = {
            mAddIntermediary: false,
            mDeleteIntermediary: false,
            intermediarySelect: null,
            searchText: ""
        };
    }

    componentDidUpdate(prevProps) {
        const { createOfficeStatus, deleteOfficeStatus, updateOfficeStatus } = this.props;

        if (prevProps.createOfficeStatus.started && createOfficeStatus.ended) {
            this.setState({ mAddOffice: false });
        }

        if (prevProps.updateOfficeStatus.started && updateOfficeStatus.ended) {
            this.setState({ mEditOffice: false });
        }

        if (prevProps.deleteOfficeStatus.started && deleteOfficeStatus.ended) {
            this.setState({ mDeleteOffice: false });
        }
    }

    handleClose = modalToClose => {
        this.setState({ [modalToClose]: false });
    };

    getData() {
        const { company } = this.props;
        const { searchText } = this.state;

        let intermediaryData = [];

        let data =
            searchText !== ""
                ? company.layers.filter(function (lists) {
                      return lists.base.details.description.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
                  })
                : company.layers;

        if (!isNil(data)) {
            data.forEach((value, index) => {
                if (value.base.details.classifier === "INTERMEDIARY" && value.base.status.active) {
                    value.key = index;
                    intermediaryData.push(value);
                }
            });
        }
        return intermediaryData;
    }

    deleteIntermediary(intermediarySelect) {
        const { company, deleteOffice } = this.props;
        deleteOffice(company.base.id, intermediarySelect.base.id);
    }

    createOffice(e) {
        const { company, createOffice } = this.props;
        e.classifier = "INTERMEDIARY";
        createOffice(company.base.id, e);
    }

    updateOffice(e) {
        const { company, updateOffice } = this.props;
        const { officeSelect } = this.state;
        e.classifier = "INTERMEDIARY";
        updateOffice(company.base.id, officeSelect.base.id, e);
    }

    renderModalAddIntermediary() {
        const { createOfficeStatus, enableCreation, intl } = this.props;

        let addModalContent = enableCreation ? (
            <CreateOfficeForm
                errorMsg={createOfficeStatus.errorInfo ? createOfficeStatus.errorInfo.message : undefined}
                onSubmit={e => this.createOffice(e)}
                isLoading={createOfficeStatus.started && !createOfficeStatus.ended}
                title={<FormattedMessage id="c-registry-office.modal.create.subtitle" />}
            />
        ) : (
            intl.formatMessage({
                id: "c-registry-view-intermediary-disabled-creation"
            })
        );

        return (
            <div>
                <Modal
                    footer={null}
                    onCancel={() => this.handleClose("mAddIntermediary")}
                    style={{ top: "20%" }}
                    title={<FormattedMessage id="c-registry-office.modal.create.add-intermediaries" />}
                    visible={this.state.mAddIntermediary}
                >
                    {addModalContent}
                </Modal>
            </div>
        );
    }

    renderModalDeleteIntermediary() {
        const { deleteOfficeStatus, intl } = this.props;
        const { intermediarySelect } = this.state;
        return intermediarySelect !== null ? (
            <div>
                <Modal
                    cancelText={<FormattedMessage id="general.annulla" />}
                    okText={<FormattedMessage id="general.delete" />}
                    onCancel={() => this.handleClose("mDeleteIntermediary")}
                    visible={this.state.mDeleteIntermediary}
                    onOk={() => this.deleteIntermediary(intermediarySelect)}
                    style={{ top: "20%" }}
                    title={<FormattedMessage id="c-registry-office.modal.create.delete-intermediaries" />}
                >
                    <p>
                        {intl.formatMessage({
                            id: "c-registry-office.modal.create.delete-question"
                        }) + intermediarySelect.base.details.description}
                    </p>
                    <p style={{ color: "red" }}>
                        {deleteOfficeStatus.errorInfo ? deleteOfficeStatus.errorInfo.message : undefined}
                    </p>
                </Modal>
            </div>
        ) : null;
    }

    renderModalInfoIntermediary() {
        const { intermediarySelect } = this.state;
        return intermediarySelect !== null ? (
            <div>
                <Modal
                    footer={null}
                    onCancel={() => this.handleClose("mInfoIntermediary")}
                    style={{ top: "20%" }}
                    title={<FormattedMessage id="c-registry-office.modal.create.intermediary-info" />}
                    visible={this.state.mInfoIntermediary}
                >
                    <InfoIntermediary info={intermediarySelect} type="intermediary" />
                </Modal>
            </div>
        ) : null;
    }

    renderIntermediaries() {
        const { company, intl } = this.props;
        let isEnable =
            company.base.status.status !== "VALIDATED"
                ? {
                      status: false,
                      color: "rgba(0,0,0,.25)",
                      background: "#f7f7f7"
                  }
                : { status: true, color: "#FFF", background: "#11B7FF" };
        return (
            <div>
                <div className="table-operations" style={{ display: "flex" }}>
                    <Button
                        disabled={company.base.status.status !== "VALIDATED"}
                        type="primary"
                        style={{ margin: "20px" }}
                        size="large"
                        onClick={() => this.setState({ mAddIntermediary: true })}
                    >
                        <FormattedMessage id="general.add" />
                    </Button>
                    <Search
                        enterButton
                        style={{ margin: "20px" }}
                        size="large"
                        id="search-intermediary"
                        placeholder={intl.formatMessage({
                            id: "c-registry-office.modal.create.intermediary-search"
                        })}
                        onSearch={value => this.setState({ searchText: value })}
                    />
                </div>
                <Table
                    dataSource={this.getData()}
                    locale={locale}
                    style={{
                        borderRadius: "5px",
                        background: "#fff",
                        margin: "20px",
                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 1px"
                    }}
                >
                    {columns.map((column, index) => (
                        <Table.Column
                            dataIndex={column.dataIndex}
                            key={index}
                            render={(text, row) => (
                                <IntermediaryRow
                                    value={row}
                                    onClick={row => this.onClick(row)}
                                    text={text}
                                    column={column}
                                    isEnable={isEnable}
                                    openDelete={() =>
                                        this.setState({
                                            mDeleteIntermediary: true,
                                            intermediarySelect: row
                                        })
                                    }
                                    openInfo={() =>
                                        this.setState({
                                            mInfoIntermediary: true,
                                            intermediarySelect: row
                                        })
                                    }
                                />
                            )}
                            title={<FormattedMessage id={column.title} />}
                        />
                    ))}
                </Table>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderIntermediaries()}
                {this.renderModalAddIntermediary()}
                {this.renderModalDeleteIntermediary()}
                {this.renderModalInfoIntermediary()}
            </div>
        );
    }
}
export default injectIntl(Intermediaries);
