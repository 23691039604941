import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import {
    Title,
    Description,
    ServicesContainer,
    ConfigureStudioButtonContainer,
    SmartInfoContainer,
    FlexContainer,
    StatusIcon,
    ConfigureButtonContainer
} from "../styled";
import { NewCctServiceRow } from "../new-cct-service-row";
import ProvisioningStatusMessage from "../provisioning-status-message";
import LinkButton from "../../../general/link-button";
import { useFeature } from "../../../../hooks/use-feature";
import ElevioInfoCircle from "../../../elevio-info-circle";

const NewCctFlowSection = ({ cctConfig, companyStatus, itemApps, itemType, provisioningInfo }) => {
    const cctSmartEnabled = useFeature("CCT_SMART");

    const canItemCertify = ["UNVERIFIABLE", "AWAITING_UPLOAD", "REJECTED"].includes(companyStatus);
    const isItemCertified = companyStatus === "CERTIFIED";
    const isItemCertifying = [
        "AWAITING_APPROVAL",
        "REJECTED_PENDING_VALIDATE",
        "UNVERIFIABLE_PENDING_VALIDATE"
    ].includes(companyStatus);

    const newCctFlowApps = Object.values(itemApps.data).filter(a => a.cctSpecsConfig.newFlow);
    const isStudio = itemType === "STUDIO";
    const configurationButtonText = isStudio ? "c-new-cct-service-row.configure-studio" : "general.configures";

    const showSmartCctInfo = isStudio || (!isStudio && provisioningInfo && provisioningInfo.status === "initialized");

    return (
        <>
            <Title>
                <FontAwesomeIcon icon={faBuilding} /> <FormattedMessage id="cct-configure-digital-archive" />
            </Title>
            <Description>
                <FormattedHTMLMessage id="cct-service-row-description" />
            </Description>
            <ProvisioningStatusMessage
                canItemCertify={canItemCertify}
                isItemCertified={isItemCertified}
                isItemCertifying={isItemCertifying}
                isItemValidated={companyStatus === "VALIDATED"}
                provisioningInfo={provisioningInfo}
            />
            <ServicesContainer>
                {newCctFlowApps.map(app => (
                    <NewCctServiceRow
                        key={app.id}
                        isItemProvisioned={provisioningInfo.status === "validated"}
                        itemType={itemType}
                        rowTitle={`apps.${app.id}.title`}
                        specs={cctConfig.specs}
                        name={app.cctSpecsConfig.specs}
                    />
                ))}
            </ServicesContainer>
            {cctSmartEnabled && showSmartCctInfo ? (
                <FlexContainer>
                    <SmartInfoContainer>
                        <StatusIcon icon={faInfoCircle} color="#005075" margintop="3px" />
                        <Description>
                            <FormattedHTMLMessage id="cct-smart-info" />
                            <ElevioInfoCircle articleId="654" />
                        </Description>
                    </SmartInfoContainer>
                    <ConfigureStudioButtonContainer>
                        <LinkButton
                            to="edit-cct/configure-item"
                            disabled={!isItemCertified}
                            dataGainsightTrack={
                                isStudio
                                    ? "archive-configure-provisioning-studio"
                                    : "archive-configure-provisioning-company"
                            }
                        >
                            <FormattedMessage id={configurationButtonText} />
                        </LinkButton>
                    </ConfigureStudioButtonContainer>
                    {isStudio && (
                        <ConfigureStudioButtonContainer>
                            <LinkButton
                                to="edit-cct/configure-managed-items"
                                disabled={provisioningInfo.status !== "validated"}
                                data-gainsight-track={"archive-configure-provisioning-managed"}
                            >
                                <FormattedMessage id={"c-new-cct-service-row.configure-managed"} />
                            </LinkButton>
                        </ConfigureStudioButtonContainer>
                    )}
                </FlexContainer>
            ) : (
                <ConfigureButtonContainer>
                    <LinkButton
                        to="edit-cct/configure-item"
                        disabled={!isItemCertified}
                        dataGainsightTrack={
                            isStudio
                                ? "archive-configure-provisioning-studio"
                                : "archive-configure-provisioning-company"
                        }
                    >
                        <FormattedMessage id={configurationButtonText} />
                    </LinkButton>
                </ConfigureButtonContainer>
            )}
        </>
    );
};

NewCctFlowSection.propTypes = {
    cctConfig: PropTypes.shape({
        specs: PropTypes.object
    }),
    itemApps: PropTypes.object,
    itemType: PropTypes.oneOf(["BUILDING", "COMPANY", "STUDIO"]),
    provisioningInfo: PropTypes.object
};

export default NewCctFlowSection;
