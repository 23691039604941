const spotlightPartyType = {
    company: "COMPANY",
    governmentAgency: "GOVERNMENT_AGENCY",
    person: "PERSON",
    association: "ASSOCIATION"
};

export function translateClassifierToSpotlight(classifier) {
    switch (classifier) {
        case "COMPANY":
        case "STUDIO":
        case "BUILDING":
            return spotlightPartyType.company;
        case "PERSON":
            return spotlightPartyType.person;
        case "ASSOCIATION":
            return spotlightPartyType.association;
        default:
            console.error("Unhandled classifier", classifier);
            return classifier;
    }
}
