import { ONE_TRUST_ENABLED } from "../config";

/**
 * Reads the current state of all optanon-enabled script tags and checks if they are enabled.
 * Returns an object having the OneTrust categories as keys and their respective activation status (boolean) as value.
 */
export function getCurrentCookieConsents() {
    if (ONE_TRUST_ENABLED !== "true") {
        return { C0001: true, C0002: true, C0003: true, C0004: true, C0005: true, C0006: true };
    }

    const defaultCookieConsents = { C0001: true, C0002: false, C0003: false, C0004: false, C0005: false, C0006: false };
    const documentScripts = [...document.querySelectorAll("script")];

    // Matches all classnames that cookiePro uses for categorization
    const cookieProClassNameFormat = /optanon-category-(?:C\d{4}-?)+/;

    return documentScripts
        .filter(
            script =>
                script.getAttribute("class") &&
                script.getAttribute("class").match(cookieProClassNameFormat) &&
                script.getAttribute("type") !== "text/plain"
        )
        .reduce((consents, node) => {
            node.getAttribute("class")
                .split("-")
                .slice(2)
                .forEach(c => (consents[c] = true));
            return consents;
        }, defaultCookieConsents);
}

/**
 * @returns The cookie consents represented as a CSV
 */
export function getCurrentCookieConsentsCsv() {
    return Object.entries(getCurrentCookieConsents())
        .filter(([_, value]) => value === true)
        .map(([key]) => key)
        .join(",");
}
