import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import UploadField from "../../manage-data/company/validate/upload-field";
import GenerateContract from "../../general/generate-contract";
import { FormattedMessage } from "react-intl";
import { ACT_OF_ENTRUSTMENT_NAME } from "../../../config";

import "./style.css";
class DownloadUpload extends React.Component {
    static propTypes = {
        actOfEntrustment: PropTypes.string,
        actOfEntrustmentStatus: PropTypes.object,
        className: PropTypes.string,
        companies: PropTypes.object,
        connection: PropTypes.object,
        connectionId: PropTypes.string,
        companyDescription: PropTypes.string,
        generateActOfEntrustment: PropTypes.func,
        generateActOfEntrustmentReset: PropTypes.func,
        isDisplayed: PropTypes.bool,
        uploadCert: PropTypes.func,
        user: PropTypes.object,
        uploading: PropTypes.bool
    };

    constructor() {
        super();
        this.state = {
            fileList: []
        };
    }

    async handleUpload() {
        const { connectionId, companyDescription, uploadCert } = this.props;

        uploadCert(connectionId, companyDescription, this.state.fileList[0].fileContent);
    }

    createAddress(company) {
        return company
            ? company.details.addresses[0].streetName +
                  " " +
                  company.details.addresses[0].streetNumber +
                  ", " +
                  company.details.addresses[0].zipCode +
                  " " +
                  company.details.addresses[0].city +
                  " " +
                  company.details.addresses[0].province
            : "";
    }

    render() {
        const {
            actOfEntrustment,
            actOfEntrustmentStatus,
            className,
            isDisplayed,
            companies,
            connection,
            generateActOfEntrustment,
            generateActOfEntrustmentReset,
            uploading
        } = this.props;
        const { fileList } = this.state;

        let manager = companies.data[connection.managerId].item.base;
        let managed;
        if (companies.data[connection.managedId]) {
            managed = companies.data[connection.managedId].item.base;
        }
        let managedPerson = managed.details.classifier === "PERSON";
        let managedBuilding = managed.details.classifier === "BUILDING";
        let managedAssociation = managed.details.classifier === "ASSOCIATION";
        let managedPersonDesc = `${managed.details.firstName} ${managed.details.lastName}`;

        let address = this.createAddress(manager);
        let addressManaged = this.createAddress(managed);

        return (
            isDisplayed && (
                <div className={`c-download-upload ${className}`}>
                    <div className="col-conf">
                        <GenerateContract
                            actOfEntrustment={actOfEntrustment}
                            actOfEntrustmentStatus={actOfEntrustmentStatus}
                            docData={{
                                managerCf: manager.identifier.taxId,
                                managerPIva: manager.identifier.vatNumber,
                                managerDesc: manager.details.description,
                                managerAddress: address,
                                managedDesc: managed
                                    ? managedPerson
                                        ? managedPersonDesc
                                        : managed.details.description
                                    : "",
                                managedCf: managed ? managed.identifier.taxId : "",
                                managedPIva:
                                    managed && (managedPerson || managedBuilding || managedAssociation)
                                        ? ""
                                        : managed.identifier.vatNumber,
                                managedAddress: addressManaged
                            }}
                            docName={ACT_OF_ENTRUSTMENT_NAME}
                            generateActOfEntrustment={generateActOfEntrustment}
                            generateActOfEntrustmentReset={generateActOfEntrustmentReset}
                        />
                        <div className="c-connection-settings-actions-button">
                            <UploadField
                                onChange={file => this.setState({ fileList: file })}
                                loading={uploading}
                                value={fileList}
                            />
                        </div>
                        <div className="c-connection-settings-actions-button">
                            <Button
                                type="primary"
                                onClick={this.handleUpload.bind(this)}
                                disabled={this.state.fileList.length === 0}
                                loading={uploading}
                            >
                                {uploading ? (
                                    <FormattedMessage id="general.send-in-progress" />
                                ) : (
                                    <FormattedMessage id="general.send-validation-request" />
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            )
        );
    }
}

export default DownloadUpload;
