import React from "react";

import { FormattedMessage } from "react-intl";

import { Container } from "./styled";

export const CctStatusCell = ({ cell: { value } }) => {
    if (value) {
        return (
            <Container>
                <FormattedMessage id={`c-cct-status-${value}`} />
            </Container>
        );
    } else {
        return "-";
    }
};
