import React from "react";
import PropTypes from "prop-types";

import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Maintenance from "../../components/maintenance";

const CustomRoute = props => {
    const { auth, component, loggingOut, maintenance, authenticatedRoute, ...rest } = props;

    const Component = component;
    return (
        <Route
            {...rest}
            render={routeProps => {
                if (maintenance) {
                    return <Maintenance {...routeProps} />;
                }

                if (authenticatedRoute && !loggingOut && (!auth.loginAuth.securityToken || auth.status.error)) {
                    return <Redirect to={`/login?redirectTo=${routeProps.location.pathname}`} />;
                }

                return <Component {...routeProps} />;
            }}
        />
    );
};

CustomRoute.propTypes = {
    maintenance: PropTypes.bool,
    authenticatedRoute: PropTypes.bool
};

CustomRoute.defaultProps = {
    authenticatedRoute: false
};

const mapStateToProps = state => ({
    auth: state.auth,
    loggingOut: state.loggingOut,
    maintenance: state.maintenance
});

export default connect(mapStateToProps, null)(CustomRoute);
