import React from "react";
import { connect } from "react-redux";

import { CYBER_APP_ID } from "../../../config";
import PortaleChildApp from "../../portale-child-app";

const CyberView = ({ workspaceId }) => {
    return (
        <PortaleChildApp
            appId="CYBER"
            serviceId="cybersecurity-and-trust:cyber:app"
            oneFrontAppId={CYBER_APP_ID}
            tenantId={workspaceId}
        />
    );
};

const mapStateToProps = state => ({
    workspaceId: state.services.workspaceId.data ? state.services.workspaceId.data.id : null
});

export default connect(mapStateToProps)(CyberView);
