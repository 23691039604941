import styled from "styled-components";

export const OfficesCreateContainer = styled.div`
    background-color: #ffffff;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;

export const Title = styled.div`
    margin: 20px;
    font-size: 24px;
    font-weight: 400;
    color: #005075;
`;

export const Subtitle = styled.div`
    margin: 20px;
    line-height: 0.1em;
`;
