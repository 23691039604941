import { Card } from "../../components/metering/card";
import styled from "styled-components";
import { StandardTabs } from "@ts-digital/arc/antd";
import PortaleChildApp from "../portale-child-app";

export const RootContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const MeteringCard = styled(Card)`
    margin-top: 16px;
`;

export const Tabs = styled(StandardTabs)`
    padding: 16px;
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const Title = styled.h2`
    color: #005075;
    margin: 0 auto;
    text-transform: uppercase;
`;

export const StyledChildApp = styled(PortaleChildApp)`
    position: relative;
    width: 100%;
    flex: 1;
`;
