import { EmailValidator } from "commons-validator-js";
import { fieldRequired, fieldLength, isTaxId, isValidTaxId } from "@ts-digital/agyo-validations";
import combineValidators from "@ts-digital/agyo-validations/lib/utils/combineValidators";
import { translateMessage } from "../../i18n";

export const downloadCctPdf = {
    type: "object",
    properties: {
        balanceSheetDate: {
            type: "string",
            validate: combineValidators(
                fieldRequired(translateMessage("general.form.field-required")),
                fieldLength(translateMessage("general.form.field-required"), 10, 10)
            )
        },
        name: { type: "string", validate: fieldRequired(translateMessage("general.form.field-required")) },
        surname: { type: "string", validate: fieldRequired(translateMessage("general.form.field-required")) },
        taxId: {
            type: "string",
            validate: combineValidators(
                fieldRequired(translateMessage("general.form.field-required")),
                (value, formValues) => {
                    formValues.taxRegion = "IT";
                    formValues.classifier = "PERSON";
                    return isTaxId("taxRegion", "classifier", translateMessage)(value, formValues);
                },
                (value, formValues) => isValidTaxId(translateMessage("general.taxid.wrong"), value, formValues)
            )
        },
        birthPlace: { type: "string", validate: fieldRequired(translateMessage("general.form.field-required")) },
        birthDate: { type: "string", validate: fieldRequired(translateMessage("general.form.field-required")) },
        smart: { type: "boolean" },
        email: {
            type: "string",
            validate: combineValidators(
                fieldRequired(translateMessage("general.form.field-required")),
                value =>
                    !new EmailValidator().isValid(value ? value : "") &&
                    translateMessage("general.form.email-required"),
                (value, formValues) =>
                    value === formValues.currentUserId &&
                    translateMessage("c-create-personal-user.select-different-email")
            )
        },
        isPec: { type: "boolean" },
        gender: { type: "string", validate: fieldRequired(translateMessage("general.form.field-required")) },
        country: { type: "string", validate: fieldRequired(translateMessage("general.form.field-required")) }
    }
};
