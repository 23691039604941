import styled, { css } from "styled-components";

import { TS_DIGITAL_IMAGES } from "../../../../config";

import lemonMilkFont from "../../LEMONMILK-Regular.otf";
import lemonMilkFontBold from "../../LEMONMILK-Bold.otf";
import lemonMilkFontMedium from "../../LEMONMILK-Medium.otf";

export const Container = styled.div`
    @font-face {
        font-family: "LemonMilk";
        src: url(${lemonMilkFont}) format("woff");
        font-style: normal;
        font-weight: 400;
        font-display: Roboto;
    }

    @font-face {
        font-family: "LemonMilkBold";
        src: url(${lemonMilkFontBold}) format("woff");
        font-style: normal;
        font-weight: bold;
        font-display: Roboto;
    }

    @font-face {
        font-family: "LemonMilkMedium";
        src: url(${lemonMilkFontMedium}) format("woff");
        font-style: normal;
        font-weight: 600;
        font-display: Roboto;
    }

    background-color: #1f92d0;
    color: white;
    padding: 24px 40px;
    background-image: url(${TS_DIGITAL_IMAGES}talks-banner/neon.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 70%;
    position: relative;
    display: flex;
    flex-direction: column;

    @media (max-width: 1300px) {
        padding: 16px 32px;
    }

    @media (max-width: 1260px) {
        padding: 8px 24px;
    }
`;

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1279px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const Title = styled.div`
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-family: "LemonMilkBold", Fallback, sans-serif;
    font-weight: bold;
    line-height: 1;

    @media (max-width: 1139px) {
        font-size: 24px;
    }

    @media (max-width: 950px) {
        font-size: 16px;
    }
`;

export const Subtitle = styled.div`
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-family: "LemonMilk", Fallback, sans-serif;
    line-height: 1;
    font-weight: normal;
    margin-bottom: 16px;
`;

export const WhenContainer = styled.div`
    grid-gap: 16px;
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-family: "LemonMilk", Fallback, sans-serif;
    font-weight: 600;
    letter-spacing: 0.17px;
    line-height: 24px;
    flex-shrink: 0;
    width: fit-content;

    @media (max-width: 1100px) {
        font-size: 16px;
    }

    @media (max-width: 886px) {
        font-size: 12px;
    }
`;

export const UnstyledA = styled.a`
    font-style: initial;
    color: white;
    text-decoration: initial;
    background-color: initial;
    outline: none;
    cursor: pointer;

    ${props =>
        props.disabled &&
        css`
            pointer-events: none;
            cursor: not-allowed;
        `}

    @media (max-width: 1279px) {
        align-self: flex-end;
    }
`;
