import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { compose } from "redux";
import { FormattedMessage } from "react-intl";

import "../style.css";

import {
    getConfig,
    tspayOnboarding,
    tspayResendConfirmEmail,
    resetTsPayOnboarding
} from "../../../actions/services/tspay";
import { getItemApps } from "../../../actions/services/app";

import TsPayConfig from "../../../components/ts-pay";
import { errorBoundary } from "../../../components/general/error-boundary";
import PageTitle from "../../../components/general/page-title";

import { Container } from "./styled";

const TsPayView = ({
    agyoServices,
    company,
    configError,
    configLoaded,
    configLoading,
    getConfig,
    getItemApps,
    history,
    match,
    itemApps,
    services,
    tspayOnboarding,
    resetTsPayOnboarding,
    tspayOnboardingStarted,
    tspayOnboardingEnded,
    tspayOnboardingSessionUrl,
    tsPayStatus,
    tsPayOnboardingSessions,
    tspayResendConfirmEmail,
    tspayResendConfirmEmailLoading,
    user
}) => {
    const itemId = company.base.uuid;
    const oldItemId = match.params.item;

    useEffect(() => {
        !itemApps.status.ended && !itemApps.status.started && getItemApps(oldItemId, user.profile.id);
    }, [oldItemId, itemApps, getItemApps, user]);

    const handleTsPayGetConfig = useCallback(
        itemId => {
            getConfig(itemId);
        },
        [getConfig]
    );

    useEffect(() => {
        if (!configLoading && !configLoaded && !configError) {
            handleTsPayGetConfig(itemId);
        }
    }, [configLoaded, configLoading, configError, handleTsPayGetConfig, itemId]);

    useEffect(() => {
        if (tspayOnboardingEnded && tspayOnboardingSessionUrl) {
            window.open(tspayOnboardingSessionUrl, "_blank");
            resetTsPayOnboarding();
        }
    }, [resetTsPayOnboarding, tspayOnboardingSessionUrl, tspayOnboardingEnded]);

    const handleTsPayOnBoarding = useCallback(
        itemId => {
            tspayOnboarding(itemId);
        },
        [tspayOnboarding]
    );

    const handleTsPayResendEmail = useCallback(
        itemId => {
            tspayResendConfirmEmail(itemId);
        },
        [tspayResendConfirmEmail]
    );

    const isTsPayActiveInAgyo = agyoServices.services.some(service => service.id === "TSPAY" && service.active);
    const isTsPayAvailableToUser =
        services.itemApps.data["TSPAY"] &&
        services.itemApps.data["TSPAY"].services.find(x => x.availableToUser && (x.activeForItem || x.active)) &&
        true;

    const isEbsAvailableToUser =
        services.itemApps.data["EBS"] &&
        services.itemApps.data["EBS"].services.find(x => x.availableToUser && (x.activeForItem || x.active)) &&
        true;

    const isServiceActive = (isTsPayActiveInAgyo && isTsPayAvailableToUser) || isEbsAvailableToUser;

    return (
        <div className="service-view">
            <PageTitle title={<FormattedMessage id="c-services-view.TSPAY.name" />} onBack={() => history.goBack()} />
            <Container>
                <TsPayConfig
                    isServiceActive={isServiceActive}
                    tsPayOnboarding={handleTsPayOnBoarding}
                    tspayOnboardingStarted={tspayOnboardingStarted}
                    itemId={itemId}
                    tsPayStatus={tsPayStatus}
                    tsPayGetConfig={handleTsPayGetConfig}
                    getConfigLoading={configLoading}
                    tsPayOnboardingSessions={tsPayOnboardingSessions}
                    tspayResendConfirmEmail={handleTsPayResendEmail}
                    tspayResendConfirmEmailLoading={tspayResendConfirmEmailLoading}
                />
            </Container>
        </div>
    );
};

TsPayView.propTypes = {
    agyoServices: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    configError: PropTypes.bool.isRequired,
    configLoading: PropTypes.bool.isRequired,
    configLoaded: PropTypes.bool.isRequired,
    itemApps: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    services: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => ({
    agyoServices: state.services.agyoServices,
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    configError: state.tspay.tspayConfig.status.error,
    configLoading: state.tspay.tspayConfig.status.started,
    configLoaded: state.tspay.tspayConfig.status.ended,
    itemApps: state.services.itemApps,
    services: state.services,
    tspayOnboardingStarted: state.tspay.tspayOnboarding.status.started,
    tspayOnboardingEnded: state.tspay.tspayOnboarding.status.ended,
    tspayOnboardingSessionUrl: state.tspay.tspayOnboarding.sessionUrl,
    tsPayStatus: state.tspay.tspayConfig.apikeyStatus,
    tsPayOnboardingSessions: state.tspay.tspayConfig.onboardingSessions,
    tspayResendConfirmEmailLoading: state.tspay.tspayResend.status.started,
    user: state.user.user
});

const actions = {
    getConfig,
    tspayOnboarding,
    resetTsPayOnboarding,
    getItemApps,
    tspayResendConfirmEmail
};

const composedHoc = compose(connect(mapStateToProps, actions), errorBoundary);

export default composedHoc(TsPayView);
