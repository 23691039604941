import React from "react";
import PropTypes from "prop-types";

import { Container, Tab, TabUnderline } from "./styled";

const ProgressiveTab = ({ tabs, selectedTab, fullWidth, newComponent = false }) => {
    return (
        <Container data-cy={"progressive-tab-container"}>
            {tabs.map((tab, index) => (
                <Tab
                    active={selectedTab === index}
                    completed={selectedTab > index}
                    disabled={selectedTab < index}
                    fullWidth={fullWidth}
                    key={index}
                    data-cy={`progressive-tab-${index}`}
                    newComponent={newComponent}
                >
                    {`${index + 1}. `}
                    {tab.content}
                </Tab>
            ))}
            <TabUnderline />
        </Container>
    );
};

ProgressiveTab.propTypes = {
    fullWidth: PropTypes.bool,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            content: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        })
    ),
    selectedTab: PropTypes.number
};

ProgressiveTab.defaultProps = {
    fullWidth: true,
    selectedTab: 0
};

export default ProgressiveTab;
