const sessionStorageKey = "showFatturazione2Links";

if (window.location.href.indexOf("f2l=true") !== -1) {
    window.sessionStorage.setItem(sessionStorageKey, true);
}
if (window.location.href.indexOf("f2l=false") !== -1) {
    window.sessionStorage.setItem(sessionStorageKey, false);
}

export function isFatturazione2LinksEnabled() {
    return JSON.parse(window.sessionStorage.getItem(sessionStorageKey));
}
