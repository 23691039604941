import React from "react";
import PropTypes from "prop-types";

import { Form, Radio, Select, Spin } from "antd";
import { equals } from "ramda";
import { FormattedMessage } from "react-intl";

import validateFields from "../../../utils/connection-validations/fields-validations";
import StepperAction from "../stepper-action";
import "./style.css";

const Option = Select.Option;

function serviceRoles(serviceId) {
    switch (serviceId) {
        case "SDI-FLOW":
        case "B2B-PASSIVE-FLOW":
        case "PRIVACY-FLOW":
        case "CRT":
        case "NSO":
            return ["READ", "READ_WRITE"];
        default:
            return ["READ_WRITE"];
    }
}

class ConnectionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            techKeys: [],
            values: {
                appId: props.services && props.services[0] ? props.services[0].appId : "",
                featureCode:
                    props.services &&
                    props.services[0] &&
                    props.services[0].features.length === 1 &&
                    props.services[0].features[0].featureCode
                        ? props.services[0].features[0].featureCode
                        : "",
                role:
                    props.services && props.services[0] && props.services[0].features.length === 1
                        ? serviceRoles(props.services[0].features[0].id)[0]
                        : undefined,
                serviceId:
                    props.services && props.services[0] && props.services[0].features.length === 1
                        ? props.services[0].features[0].id
                        : "",
                techUsers: props.techUsers && props.techUsers.length === 1 ? [props.techUsers[0].id] : []
            },
            meta: {
                appId: { touched: false, error: false },
                featureCode: { touched: false, error: false },
                serviceId: { touched: false, error: false },
                role: { touched: false, error: false },
                techUsers: { touched: false, error: false }
            }
        };
    }

    static propTypes = {
        disabled: PropTypes.bool,
        loadingServices: PropTypes.bool,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        services: PropTypes.arrayOf(
            PropTypes.shape({
                appId: PropTypes.string,
                appDescription: PropTypes.string,
                features: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.string,
                        featureCode: PropTypes.string,
                        description: PropTypes.string
                    })
                )
            })
        ),
        techUsers: PropTypes.array
    };

    static defaultProps = {
        disabled: false,
        loadingServices: false,
        onValuesChanged: connection => {},
        services: [],
        techUsers: []
    };

    componentDidUpdate(prevProps) {
        const { services, loadingServices, techUsers } = this.props;
        const { values, meta } = this.state;

        if ((prevProps.loadingServices && !loadingServices) || services.length !== prevProps.services.length) {
            this.setState({
                values: { ...values, appId: services[0].appId }
            });
        }

        if (!equals(techUsers, prevProps.techUsers) && techUsers.length === 1) {
            this.setState({
                values: { ...values, techUsers: [techUsers[0].id] }
            });
        }
        const actualRoles = serviceRoles(values.serviceId);
        if (values.serviceId && actualRoles.length === 1 && values.role !== actualRoles[0]) {
            this.setState({
                values: {
                    ...values,
                    role: actualRoles[0]
                },
                meta: {
                    ...meta,
                    role: { touched: false, error: false }
                }
            });
        }
    }

    // If the specified field has been touched and is in an error state, return the error message
    getFieldHelp(field) {
        const { meta } = this.state;

        return meta[field].touched && meta[field].error && <FormattedMessage id={`c-connection-form.${field}.empty`} />;
    }

    /* If the specified field has been touched and is in an error state
     ** return 'error'. Otherwise, return ''.
     */
    getFieldStatus(field) {
        const { meta } = this.state;

        return meta[field].touched && meta[field].error ? "error" : "";
    }

    onFieldChange(newValues) {
        const { values } = this.state;

        this.setState({ values: { ...values, ...newValues } });
        this.validateForm({ ...values, ...newValues });
    }

    validateForm(connection = this.state.values) {
        const { meta } = this.state;

        const validationErrors = validateFields(connection);

        Object.entries(validationErrors).forEach(entry => {
            meta[entry[0]].error = entry[1];
        });

        this.setState({ meta });

        return validationErrors;
    }

    onFieldTouched = field => {
        const { meta } = this.state;
        meta[field].touched = true;
        meta[field].error = false;

        this.setState({
            meta: {
                ...meta,
                role: { touched: false, error: false }
            }
        });
    };

    handleSubmit = () => {
        const { onSubmit } = this.props;
        const { values } = this.state;

        Object.keys(values).forEach(this.onFieldTouched);

        const formValidation = this.validateForm();

        if (Object.values(formValidation).every(v => v === false)) {
            const mappedTechUsers = values.techUsers.map(t => ({
                userId: t,
                type: "TECH"
            }));
            onSubmit({ ...values, techUsers: mappedTechUsers });
        }
    };

    render() {
        const { disabled, loadingServices, onCancel, services, techUsers } = this.props;
        const { meta, values } = this.state;

        let features = [];

        if (values.appId !== "") {
            features = services.find(s => s.appId === values.appId).features;
        }

        return (
            <div className="c-connection-form">
                <Spin spinning={loadingServices}>
                    <Form.Item
                        help={this.getFieldHelp("appId")}
                        label={<FormattedMessage id="c-connection-form.appId.label" />}
                        layout="vertical"
                        validateStatus={this.getFieldStatus("appId")}
                    >
                        <Radio.Group
                            disabled={disabled}
                            value={values.appId}
                            onChange={e => {
                                this.onFieldTouched("appId");
                            }}
                        >
                            <table className="t-service">
                                <thead>
                                    <tr>
                                        {services.map(s => (
                                            <td key={s.appDescription}>{s.appDescription}</td>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {services.map(s => (
                                            <td key={s.appId}>
                                                <Radio
                                                    className="service-radio"
                                                    onClick={() => {
                                                        if (s.appId !== values.appId) {
                                                            const serviceId =
                                                                s.features && s.features.length === 1
                                                                    ? s.features[0].id
                                                                    : "";

                                                            const featureCode =
                                                                s.features.length === 1 && s.features[0].featureCode
                                                                    ? s.features[0].featureCode
                                                                    : "";

                                                            this.onFieldChange({
                                                                appId: s.appId,
                                                                featureCode: featureCode,
                                                                role: undefined,
                                                                serviceId: serviceId
                                                            });
                                                        }
                                                    }}
                                                    value={s.appId}
                                                />
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        help={this.getFieldHelp("featureCode")}
                        label={<FormattedMessage id="c-connection-form.featureCode.label" />}
                        layout="vertical"
                        validateStatus={this.getFieldStatus("featureCode")}
                    >
                        <Radio.Group
                            disabled={disabled}
                            onChange={() => {
                                this.onFieldTouched("featureCode");
                                this.onFieldTouched("serviceId");
                            }}
                            value={this.state.values ? this.state.values.featureCode : ""}
                        >
                            {features.map(s => (
                                <Radio
                                    className="feature-code-radio"
                                    key={s.id}
                                    onClick={e => {
                                        if (s.id !== values.serviceId) {
                                            this.onFieldChange({
                                                role: undefined,
                                                featureCode: s.featureCode ? s.featureCode : "",
                                                serviceId: s.id
                                            });
                                        }
                                    }}
                                    value={s.featureCode ? s.featureCode : ""}
                                >
                                    {s.description}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>
                    <div className="roles-container">
                        <Form.Item
                            help={this.getFieldHelp("role")}
                            label={<FormattedMessage id="c-connection-form.role.label" />}
                            layout="vertical"
                            validateStatus={this.getFieldStatus("role")}
                        >
                            <Select
                                className="roles-select"
                                disabled={disabled}
                                value={values.role}
                                onBlur={() => {
                                    this.onFieldTouched("role");
                                }}
                                onChange={value => {
                                    this.onFieldChange({ role: value });
                                }}
                                placeholder={<FormattedMessage id="c-connection-form.role.placeholder" />}
                            >
                                {values.serviceId &&
                                    serviceRoles(values.serviceId).map(r => (
                                        <Option key={`${values.serviceId}.${r}`} value={r}>
                                            <FormattedMessage id={`c-connection-form.role.${r}`} />
                                        </Option>
                                    ))}
                            </Select>
                            <div className="helper-text">
                                <FormattedMessage id={`c-connection-form.role.helper-text`} />
                            </div>
                        </Form.Item>
                        <Form.Item
                            className="tech-users-select-container"
                            label={<FormattedMessage id="c-connection-form.techUsers.label" />}
                            layout="vertical"
                        >
                            <Select
                                className="tech-users-select"
                                disabled={disabled}
                                value={values.techUsers}
                                mode="multiple"
                                onBlur={() => {
                                    this.onFieldTouched("techUsers");
                                }}
                                onChange={value => {
                                    this.onFieldChange({
                                        techUsers: value
                                    });
                                }}
                                meta={meta.techUsers}
                                placeholder={<FormattedMessage id="c-connection-form.techUsers.placeholder" />}
                            >
                                {techUsers.map(u => (
                                    <Option key={u.id} value={u.id}>
                                        {u.description} ({u.id})
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <StepperAction
                        renderPrev={true}
                        prev={onCancel}
                        next={this.handleSubmit}
                        nextButtonProps={{
                            disabled: disabled,
                            loading: disabled,
                            text: <FormattedMessage id="general.end" />
                        }}
                        prevButtonProps={{
                            disabled: disabled
                        }}
                    />
                </Spin>
            </div>
        );
    }
}

export default ConnectionForm;
