import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@ts-digital/vrc";

export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ServiceTitle = styled.div`
    display: inline-flex;
    align-items: center;
`;

export const Title = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 24px;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.23px;
    line-height: 24px;
    margin-bottom: 32px;
`;

export const Description = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 14px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 24px;
    margin-bottom: 24px;
`;

export const CredentialMessageContainer = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 14px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 24px;
    margin-bottom: 24px;
`;

export const Divider = styled.div`
    background-color: ${props => props.theme.colors.primary.columbiaBlue};
    margin-top: 32px;
    margin-bottom: 32px;
    height: 1px;
    width: 100%;
`;

export const ConfigureButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    & > :last-child {
        margin-left: 32px;
    }
`;

export const ConfigureStudioButtonContainer = styled.div`
    & > :last-child {
        margin-left: 16px;
    }

    & button {
        min-width: 220px;
    }
`;

export const SmartInfoContainer = styled.div`
    display: flex;
`;

export const FlexContainer = styled.div`
    display: flex;
    margin-top: 24px;

    justify-content: space-between;
`;

export const ServicesContainer = styled.div`
    background-color: ${props => props.theme.colors.background.whiteSmoke};
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 14px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 24px;
    display: grid;
    align-items: center;
    padding: 16px;
    grid-gap: 16px;
`;

export const StatusMessageContainer = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 14px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 24px;
    margin-bottom: 24px;
`;

export const StatusIcon = styled(FontAwesomeIcon)`
    color: ${props => props.color};
    font-size: 16px;
    text-align: center;
    width: 40px;
    margin-right: 8px;
    margin-top: ${props => props.margintop};
`;

export const StandardCctPassword = styled.div`
    display: flex;
    flex-direction: row;
    grid-column-start: 2;
    grid-column-end: 3;
    align-items: center;
    justify-content: center;

    & > span {
        padding: 0px 16px;
    }
`;

export const ActiveStatusDot = styled.div`
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: ${props => (props.active ? props.theme.colors.primary.richElectricBlue : "transparent")};
    border: 1px solid
        ${props => (props.active ? props.theme.colors.primary.richElectricBlue : props.theme.colors.text.cadet)};
    margin-right: 16px;
`;

export const CustomButton = styled(Button)`
    width: 100%;
    height: 100%;
`;

export const AsideImage = styled.img`
    max-width: 100%;
    height: auto;
    display: none;

    @media (min-width: 1200px) {
        display: block;
        margin: 96px 0 0 -16px;
    }
`;

export const DaysLeftContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ResetCredentialButtonContainer = styled.div`
    flex-shrink: 0;
`;

export const CredentialWarningIcon = styled(FontAwesomeIcon)`
    color: #fdb927;
    font-size: 16px;
    text-align: center;
    width: 40px;
    margin-right: 8px;
`;

export const Hyperlink = styled.span`
    color: ${props => props.theme.colors.primary.richElectricBlue};
    font-size: 16px;
    font-family: Roboto;
    font-weight: normal;
    text-decoration: underline;
    letter-spacing: 0.44px;
    line-height: 24px;
    margin-right: 8px;
    margin-left: 8px;
`;
