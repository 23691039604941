import React from "react";

import { Button } from "@ts-digital/vrc";

import { FormattedMessage } from "react-intl";

import {
    Content,
    Container,
    CompanyType,
    CompanyDescription,
    FiscalDataContainer,
    FiscalDataHeader,
    FiscalDataData,
    FiscalDataRow,
    TitleContainer
} from "./styled";

export const CompanyListElement = ({ buttonLabel, company, gainsightTrackAttribute, onCompanySelect }) => {
    return (
        <Container>
            <TitleContainer>
                <CompanyDescription>{company.description}</CompanyDescription>
                <CompanyType>
                    <FormattedMessage id={"c-manage-data.company.card." + company.classifier.toLowerCase()} />
                </CompanyType>
            </TitleContainer>
            <Content>
                <FiscalDataContainer>
                    <FiscalDataRow>
                        <FiscalDataHeader>
                            <FormattedMessage id="general.partita-iva" />:
                        </FiscalDataHeader>{" "}
                        <FiscalDataData>{company.vatNumber}</FiscalDataData>
                    </FiscalDataRow>
                    <FiscalDataRow>
                        <FiscalDataHeader>
                            <FormattedMessage id="general.codice-fiscale" />:
                        </FiscalDataHeader>{" "}
                        <FiscalDataData>{company.taxId}</FiscalDataData>
                    </FiscalDataRow>
                </FiscalDataContainer>

                <Button
                    kind="tertiary"
                    data-cy={"manage-users-company-selected"}
                    onClick={() => onCompanySelect(company.itemUuid)}
                    data-gainsight-track={gainsightTrackAttribute}
                >
                    <FormattedMessage id={buttonLabel} />
                </Button>
            </Content>
        </Container>
    );
};
