import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import PropTypes from "prop-types";
import { TALKS_URL_AZIENDA, TALKS_URL_STUDIO, TS_DIGITAL_IMAGES } from "../../../../config";

import { ButtonContainer, CloseButton, Container, Subtitle, Title, UnstyledA, WhenContainer } from "./styled";

import { GhostButton, GradientImage } from "../../styled";

const TalksAdvertisingBanner = ({ hasStudio, onClose, partecipateTalks, talksLinkEnabled }) => {
    return (
        <div>
            <Container>
                <div>
                    <Title>{hasStudio ? "Lo Scenario delle Professioni" : "Nuovi Scenari per le Imprese"}</Title>
                    <Subtitle>
                        {hasStudio ? (
                            "Oggi e domani: gli scenari economici, tecnologici e sociali."
                        ) : (
                            <>
                                Made in Italy: le nuove sfide e opportunità da comprendere,
                                <br />
                                affrontare e sfruttare al meglio.
                            </>
                        )}
                    </Subtitle>
                    <WhenContainer>
                        {/**
                            This version does not currently have a date to display,
                            for later versions if you need to show a date use the following lines.
                        */}
                        {/* <div>18 Ottobre 2022</div>
                        <div>Dalle ore 12.30</div> */}
                    </WhenContainer>
                </div>
                <ButtonContainer>
                    <UnstyledA
                        href={hasStudio ? TALKS_URL_STUDIO : TALKS_URL_AZIENDA}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => partecipateTalks(hasStudio)}
                        disabled={!talksLinkEnabled}
                    >
                        <GhostButton disabled={!talksLinkEnabled}>Rivivi l'evento</GhostButton>
                    </UnstyledA>
                </ButtonContainer>
                <CloseButton onClick={() => onClose()} className="on-partecipate-talks">
                    <FontAwesomeIcon icon={faTimes} />
                </CloseButton>
                <img
                    style={{ maxWidth: "250px" }}
                    src={
                        hasStudio
                            ? `${TS_DIGITAL_IMAGES}talks-banner/STUDIO_loghi.png`
                            : `${TS_DIGITAL_IMAGES}talks-banner/COMPANY_loghi.png`
                    }
                    alt="talks_loghi"
                />
            </Container>
            <GradientImage height="20px" />
        </div>
    );
};

TalksAdvertisingBanner.propTypes = {
    hasStudio: PropTypes.bool,
    onClose: PropTypes.func,
    partecipateTalks: PropTypes.func,
    talksLinkEnabled: PropTypes.bool
};

export default TalksAdvertisingBanner;
