import React, { useEffect, useCallback, useReducer, useState } from "react";
import PropTypes from "prop-types";

import { Form } from "react-final-form";
import { FormattedMessage } from "react-intl";

import PageTitle from "../general/page-title";
import ProgressiveTab from "../general/progressive-tab";
import InsertEmail from "./insert-email";
import SelectCompanies from "./select-companies";
import SetPermissions from "./set-permissions";
import { Container, TitleContainer } from "./styled";
import SuccessModal from "./success-modal";
import ErrorModal from "./error-modal";

import { useFeature } from "../../hooks/use-feature";

const UserCreationFlowComponent = ({
    assignableRoles,
    createOrAddRolesToUser,
    checkUserExistence,
    doesUserExist,
    getUserV3,
    listItems,
    onBack,
    resetFetchAssignableRoles,
    resetUserExists,
    selectedItem,
    selectedUser,
    successModalBackButtonLabel,
    userCreationFlowFirstStep,
    userId,
    currentCompany,
    users,
    isStudio,
    workspaceId
}) => {
    let [initialStep, initialTab, tabsRangeStart] = [0, 0, 0];

    if (isStudio) {
        if (userCreationFlowFirstStep === "selectCompanies") {
            [initialStep, initialTab, tabsRangeStart] = [1, 0, 1];
        } else if (userCreationFlowFirstStep === "setPermission") {
            [initialStep, initialTab, tabsRangeStart] = [2, 1, 1];
        }
    }

    const [step, setStep] = useState(initialStep);
    const [tab, setTab] = useState(initialTab);

    const enableInvitation = useFeature("ENABLE_INVITATIONS");

    const [aggregatedData, updateData] = useReducer(
        (data, update) => {
            const updatedData = { ...data };
            Object.entries(update).forEach(([key, value]) => (updatedData[key] = value));

            return updatedData;
        },
        { selectedItems: selectedUser && selectedItem ? [selectedItem] : [], email: selectedUser }
    );

    useEffect(() => {
        if (selectedUser) {
            updateData({ selectedItems: selectedItem ? [selectedItem] : [], email: selectedUser });
        }
    }, [selectedItem, selectedUser]);

    const goBack = useCallback(() => {
        if (tab === 0) {
            onBack();
        } else {
            setStep(step - 1);
            setTab(tab - 1);
        }
    }, [onBack, step, tab]);

    useEffect(() => {
        if (((isStudio && step === 2) || (!isStudio && step === 1)) && aggregatedData.selectedItems) {
            if (
                (!assignableRoles.roles ||
                    !aggregatedData.selectedItems
                        .map(item => item.itemId)
                        .every(id => Object.keys(assignableRoles.roles).includes(id))) &&
                !assignableRoles.status.started
            ) {
                let selectedItemsIdsCall = [];
                aggregatedData.selectedItems.forEach(selectedItem =>
                    !Object.keys(assignableRoles.roles).includes(selectedItem.itemId)
                        ? selectedItemsIdsCall.push(selectedItem.itemId)
                        : null
                );

                assignableRoles.call(selectedItemsIdsCall, false);
            }
        }
    }, [aggregatedData, step, assignableRoles, isStudio]);

    useEffect(() => {
        if (step === 1) {
            resetFetchAssignableRoles();
        }
    }, [resetFetchAssignableRoles, step]);

    useEffect(() => {
        if (
            ["setPermission", "selectCompanies"].includes(userCreationFlowFirstStep) &&
            ((!getUserV3.status.started && !getUserV3.status.ended) ||
                (getUserV3.user && getUserV3.user.profile.id !== selectedUser))
        ) {
            getUserV3.call(selectedUser);
        }
    }, [getUserV3, userCreationFlowFirstStep, selectedUser]);

    const onRolesSubmit = useCallback(
        (roles, localServices) => {
            const localSericesPermissions = Object.entries(localServices)
                .filter(([_, value]) => value)
                .map(([key]) => key);

            updateData({ roles });
            createOrAddRolesToUser.call(
                aggregatedData.email,
                roles,
                aggregatedData.name,
                aggregatedData.surname,
                enableInvitation,
                localSericesPermissions
            );
        },
        [createOrAddRolesToUser, aggregatedData, enableInvitation]
    );

    const [currentCompanyData, setCurrentCompanyData] = useState({
        itemId: currentCompany.base.id,
        itemUuid: currentCompany.base.uuid,
        description: currentCompany.base.details.description,
        taxId: currentCompany.base.identifier.taxId,
        vatNumber: currentCompany.base.identifier.vatNumber,
        classifier: currentCompany.base.details.classifier,
        alreadyAssigned: false,
        certified: currentCompany.base.status.certificationStatus,
        hasNoActiveServices: false,
        workspaceId
    });

    useEffect(() => {
        setCurrentCompanyData({
            itemId: currentCompany.base.id,
            itemUuid: currentCompany.base.uuid,
            description: currentCompany.base.details.description,
            taxId: currentCompany.base.identifier.taxId,
            vatNumber: currentCompany.base.identifier.vatNumber,
            classifier: currentCompany.base.details.classifier,
            alreadyAssigned: false,
            certified: currentCompany.base.status.certificationStatus,
            hasNoActiveServices: false,
            workspaceId
        });
    }, [setCurrentCompanyData, workspaceId, currentCompany]);

    useEffect(() => {
        updateData({
            selectedItems: [currentCompanyData]
        });
    }, [currentCompanyData, listItems]);

    const formStep = {
        content: (
            <FormattedMessage
                id={`v-user-creation-flow.steps.insert-email.tab-title${enableInvitation ? ".invite" : ""}`}
            />
        ),
        component: (
            <Form
                initialValues={{
                    email: aggregatedData.email,
                    name: aggregatedData.name,
                    surname: aggregatedData.surname
                }}
                userId={userId}
                onSubmit={v => {
                    if (doesUserExist) {
                        v.name = getUserV3.user.profile.firstName;
                        v.surname = getUserV3.user.profile.lastName;
                    }

                    updateData(v);
                    setStep(step + 1);
                    setTab(tab + 1);
                }}
                component={InsertEmail}
                getUserV3={getUserV3}
                checkUserExistence={checkUserExistence}
                doesUserExist={doesUserExist}
                goBack={goBack}
                resetUserExists={resetUserExists}
                currentCompany={currentCompany}
                users={users}
            />
        )
    };

    const selectCompaniesStep = {
        content: <FormattedMessage id="v-user-creation-flow.steps.select-items.tab-title" />,
        component: (
            <SelectCompanies
                initialSelectedItems={aggregatedData.selectedItems.filter(
                    i => listItems.page.find(l => l.itemUuid === i.itemUuid) && !i.alreadyAssigned
                )}
                listItems={listItems}
                userEmail={aggregatedData.email || selectedUser}
                goBack={goBack}
                onSubmit={selectedItems => {
                    updateData({ selectedItems });
                    setStep(step + 1);
                    setTab(tab + 1);
                }}
                comingFromAddRoles={userCreationFlowFirstStep === "selectCompanies"}
                currentCompanyData={currentCompanyData}
            />
        )
    };

    const setPermissionsStep = {
        content: <FormattedMessage id="v-user-creation-flow.steps.set-permissions.tab-title" />,
        component: (
            <SetPermissions
                assignableRoles={assignableRoles}
                goBack={goBack}
                onSubmit={onRolesSubmit}
                selectedItems={aggregatedData.selectedItems}
                submitting={createOrAddRolesToUser.status.started}
                userEmail={aggregatedData.email}
                comingFromAddRoles={userCreationFlowFirstStep === "selectCompanies"}
                comingFromSetRoles={userCreationFlowFirstStep === "setPermission"}
                currentCompanyData={currentCompanyData}
                isStudio={isStudio}
            />
        )
    };

    const tabs = isStudio
        ? [formStep, selectCompaniesStep, setPermissionsStep].slice(tabsRangeStart)
        : [formStep, setPermissionsStep].slice(tabsRangeStart);

    return (
        <>
            <Container>
                <PageTitle
                    title={
                        ["setPermission", "selectCompanies"].includes(userCreationFlowFirstStep) ? (
                            <TitleContainer>
                                <FormattedMessage
                                    id="v-user-creation-flow.title.setPermission"
                                    values={{
                                        name:
                                            getUserV3.user &&
                                            getUserV3.user.profile &&
                                            getUserV3.user.profile.firstName &&
                                            getUserV3.user.profile.firstName.toUpperCase(),
                                        surname:
                                            getUserV3.user &&
                                            getUserV3.user.profile &&
                                            getUserV3.user.profile.lastName &&
                                            getUserV3.user.profile.lastName.toUpperCase()
                                    }}
                                />
                            </TitleContainer>
                        ) : (
                            <TitleContainer>
                                <FormattedMessage
                                    id={
                                        enableInvitation
                                            ? "v-user-creation-flow.title.invite"
                                            : "v-user-creation-flow.title"
                                    }
                                />
                            </TitleContainer>
                        )
                    }
                    subtitle={
                        <FormattedMessage
                            id={
                                enableInvitation
                                    ? "v-user-creation-flow.subtitle.invite"
                                    : "v-user-creation-flow.subtitle"
                            }
                        />
                    }
                    onBack={onBack}
                ></PageTitle>
                <ProgressiveTab fullWidth={false} selectedTab={tab} tabs={tabs} newComponent={true} />
                {/* Display curent tab based on the step */}
                {tabs[tab].component}
            </Container>
            <SuccessModal
                backButtonLabel={successModalBackButtonLabel}
                onBack={onBack}
                visible={createOrAddRolesToUser.status.ended}
                enableInvitation={enableInvitation}
            />
            <ErrorModal
                onCancel={onBack}
                onRetry={() =>
                    createOrAddRolesToUser.call(
                        aggregatedData.email,
                        aggregatedData.roles,
                        aggregatedData.name,
                        aggregatedData.surname
                    )
                }
                visible={createOrAddRolesToUser.status.error}
            />
        </>
    );
};

UserCreationFlowComponent.propTypes = {
    assignableRoles: PropTypes.shape({
        call: PropTypes.func.isRequired,
        roles: PropTypes.objectOf(
            PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    appId: PropTypes.string,
                    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
                    children: PropTypes.arrayOf(
                        PropTypes.shape({
                            name: PropTypes.string.isRequired,
                            appId: PropTypes.string,
                            featureCode: PropTypes.string.isRequired,
                            permissions: PropTypes.arrayOf(PropTypes.string).isRequired
                        })
                    )
                })
            )
        ).isRequired,
        status: PropTypes.shape({
            started: PropTypes.bool.isRequired,
            ended: PropTypes.bool.isRequired,
            error: PropTypes.bool.isRequired
        }).isRequired
    }).isRequired,
    createOrAddRolesToUser: PropTypes.shape({
        call: PropTypes.func.isRequired,
        status: PropTypes.shape({
            started: PropTypes.bool.isRequired,
            ended: PropTypes.bool.isRequired,
            error: PropTypes.bool.isRequired
        }).isRequired
    }).isRequired,
    checkUserExistence: PropTypes.shape({
        call: PropTypes.func.isRequired,
        status: PropTypes.shape({
            started: PropTypes.bool.isRequired,
            ended: PropTypes.bool.isRequired,
            error: PropTypes.bool.isRequired
        }).isRequired
    }).isRequired,
    doesUserExist: PropTypes.bool,
    listItems: PropTypes.shape({
        call: PropTypes.func.isRequired,
        page: PropTypes.arrayOf(
            PropTypes.shape({
                classifier: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                itemId: PropTypes.string.isRequired,
                itemUuid: PropTypes.string.isRequired,
                taxId: PropTypes.string.isRequired,
                vatNumber: PropTypes.string.isRequired
            })
        ).isRequired,
        meta: PropTypes.shape({
            page: PropTypes.number,
            size: PropTypes.number,
            totalItems: PropTypes.number
        }).isRequired,
        status: PropTypes.shape({
            started: PropTypes.bool.isRequired,
            ended: PropTypes.bool.isRequired,
            error: PropTypes.bool.isRequired
        }).isRequired
    }).isRequired,
    onBack: PropTypes.func.isRequired,
    resetFetchAssignableRoles: PropTypes.func.isRequired,
    resetUserExists: PropTypes.func.isRequired,
    selectedUser: PropTypes.string,
    successModalBackButtonLabel: PropTypes.any.isRequired
};

export default UserCreationFlowComponent;
