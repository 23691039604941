import React from "react";

import { connect } from "react-redux";
import { compose } from "redux";

import { logout, resetUpdateUserPassword, updateUserPassword } from "../../actions/auth";
import { editUser, editUserReset } from "../../actions/company/users";
import { displayErrorToast, displaySuccessToast } from "../../actions/overflowNotification";
import { loadUser } from "../../actions/user";
import { errorBoundary } from "../../components/general/error-boundary";
import { Spinner } from "../../components/general/spinner";
import EditUserData from "../../components/manage-data/user/edit";

const EditUserDetailsView = ({
    auth,
    user,
    editUser,
    editUserReset,
    loadUser,
    updateUserPassword,
    updateUserPswStatus,
    resetUpdateUserPassword,
    userEditStatus,
    history,
    displaySuccessToast,
    displayErrorToast
}) => {
    return (
        <Spinner loading={!user.user}>
            <EditUserData
                auth={auth}
                editUser={editUser}
                editUserReset={editUserReset}
                loadUser={loadUser}
                updateUserPsw={updateUserPassword}
                updateUserPswStatus={updateUserPswStatus}
                user={user.user || {}}
                userEditStatus={userEditStatus}
                backFun={history.goBack}
                displaySuccessToast={displaySuccessToast}
                displayErrorToast={displayErrorToast}
                resetUpdateUserPassword={resetUpdateUserPassword}
            />
        </Spinner>
    );
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        user: state.user,
        updateUserPswStatus: state.password.update.status || {},
        userEditStatus: state.manageUser.editUser.status
    };
}

const mapDispatchToProps = {
    logout,
    editUser,
    editUserReset,
    loadUser,
    updateUserPassword,
    resetUpdateUserPassword,
    displaySuccessToast,
    displayErrorToast
};

const composedHoc = compose(errorBoundary, connect(mapStateToProps, mapDispatchToProps));

export default composedHoc(EditUserDetailsView);
