import { fieldRequired, fieldLength, isInteger } from "@ts-digital/agyo-validations";
import combineValidators from "@ts-digital/agyo-validations/lib/utils/combineValidators";
import { translateMessage, translateMessageWithParams } from "../../i18n";

export const addressSchema = {
    type: "object",
    properties: {
        streetName: {
            type: "string",
            validate: desc =>
                combineValidators(
                    fieldRequired(translateMessage("general.form.field-required")),
                    fieldLength(
                        translateMessageWithParams("general.form.field-length-range", { min: 1, max: 255 }),
                        1,
                        255
                    )
                )(desc)
        },
        streetNumber: {
            type: "string",
            validate: desc =>
                combineValidators(
                    fieldRequired(translateMessage("general.form.field-required")),
                    fieldLength(
                        translateMessageWithParams("general.form.field-length-range", { min: 1, max: 255 }),
                        1,
                        255
                    )
                )(desc)
        },
        city: {
            type: "string",
            validate: desc =>
                combineValidators(
                    fieldRequired(translateMessage("general.form.field-required")),
                    fieldLength(
                        translateMessageWithParams("general.form.field-length-range", { min: 1, max: 255 }),
                        1,
                        255
                    )
                )(desc)
        },
        province: {
            type: "string",

            validate: (desc, formValues) =>
                combineValidators(
                    fieldRequired(translateMessage("general.form.field-required")),
                    "IT" === formValues.country
                        ? fieldLength(
                              translateMessageWithParams("general.form.field-exact-length", { length: 2 }),
                              2,
                              2
                          )
                        : fieldLength(
                              translateMessageWithParams("general.form.field-length-range", { min: 1, max: 5 }),
                              1,
                              5
                          )
                )(desc, formValues)
        },
        country: {
            type: "string",
            validate: desc =>
                combineValidators(
                    fieldRequired(translateMessage("general.form.field-required")),
                    fieldLength(
                        translateMessageWithParams("general.form.field-length-range", { min: 1, max: 255 }),
                        1,
                        255
                    )
                )(desc),
            onChange: changeContext => {
                changeContext.actionCreators.change("province", "");
            }
        },
        zipCode: {
            type: "string",
            validate: combineValidators(
                fieldRequired(translateMessage("general.form.field-required")),
                (desc, formValues) => {
                    if (formValues.country === "IT") {
                        return isInteger(translateMessage("general.form.numbers-only"))(desc);
                    }
                },
                (desc, formValues) => {
                    if (formValues.country === "IT") {
                        fieldLength(
                            translateMessageWithParams("general.form.field-exact-length", { length: 5 }),
                            5,
                            5
                        )(desc);
                    }
                }
            )
        }
    }
};
