import styled from "styled-components";

export const InformativeContainer = styled.div`
    margin-bottom: 20px;
    margin-top: 15px;
    font-weight: bold;
    color: #000;

    & a {
        text-decoration: underline;
        text-decoration-color: #000;
        color: #000;
    }
`;

export const Label = styled.span`
    color: #000;
    font-weight: bold;
`;
