import React from "react";
import { displayErrorToast } from "../../../overflowNotification";
import { FormattedMessage } from "react-intl";
import { getRestCall } from "../../../../utils/rest-api-call";
import { METERING_API_URL } from "../../../../config";
import { getUserProfile } from "../../../../utils/state-utils/get-user-profile";
import { getAuthWithAppName } from "../../../auth";

export const GET_BILLING_ITEMS_START = "METERING_GET_BILLING_ITEMS_START";
export const GET_BILLING_ITEMS_END = "METERING_GET_BILLING_ITEMS_END";
export const GET_BILLING_ITEMS_SUCCESS = "METERING_GET_BILLING_ITEMS_SUCCESS";
export const GET_BILLING_ITEMS_ERROR = "METERING_GET_BILLING_ITEMS_ERROR";

export const getBillingItemsStart = () => ({
    type: GET_BILLING_ITEMS_START
});

export const getBillingItemsEnd = () => ({
    type: GET_BILLING_ITEMS_END
});

export const getBillingItemsSuccess = items => ({
    type: GET_BILLING_ITEMS_SUCCESS,
    payload: {
        items
    }
});

export const getBillingItemsError = error => ({
    type: GET_BILLING_ITEMS_ERROR,
    payload: {
        error
    }
});

export const getBillingItems = (item, userId) => async (dispatch, getState) => {
    dispatch(getBillingItemsStart());
    try {
        const auth = getAuthWithAppName(getState().auth);
        dispatch(
            getBillingItemsSuccess(
                await getRestCall(
                    `${METERING_API_URL}/api/v1/usage/invoicing/${item}/items`,
                    auth,
                    { userId },
                    dispatch,
                    auth.refreshToken,
                    getUserProfile(getState()),
                    item
                )
            )
        );
    } catch (error) {
        const displayError = displayErrorToast(<FormattedMessage id="metering.usage.items.billing.error" />);
        displayError(dispatch);
        dispatch(getBillingItemsError(error));
    }
    dispatch(getBillingItemsEnd());
};
