function detectDeviceType() {
    let w = window;
    let d = document;
    let documentElement = d.documentElement;
    let body = d.getElementsByTagName("body")[0];
    let widthScreen = w.innerWidth || documentElement.clientWidth || body.clientWidth;

    let deviceType = "";
    //TABLET
    if (navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) {
        if (widthScreen > 481 && widthScreen < 769) {
            deviceType = "turnTablet";
        } else if (widthScreen < 481) {
            deviceType = "badResolution";
        } else {
            deviceType = "desktop";
        }
    } else if (widthScreen < 768) {
        deviceType = "badResolution";
    } else {
        deviceType = "desktop";
    }

    return deviceType;
}

export default detectDeviceType;
