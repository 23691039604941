import React, { Component } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";

import { createOffice } from "../../../actions/company/offices";

import { errorBoundary } from "../../../components/general/error-boundary";
import OfficeForm from "../../../components/office-form";

import { ENABLE_GOV_CODE } from "../../../config";
import { OfficesCreateContainer, Subtitle, Title } from "./styled";

export class OfficesCreate extends Component {
    constructor() {
        super();
        this.state = {
            officeCreated: false
        };
    }

    static propTypes = {
        company: PropTypes.object.isRequired,
        createOffice: PropTypes.func.isRequired,
        createOfficeStatus: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired
    };

    componentDidUpdate(prevProps) {
        const { createOfficeStatus } = this.props;

        if (prevProps.createOfficeStatus.started && createOfficeStatus.ended && !createOfficeStatus.error) {
            this.setState({ officeCreated: true });
        }
    }

    createOffice(data) {
        const { company, createOffice } = this.props;
        data.classifier = "OFFICE";
        createOffice(company.base.id, data);
    }

    render() {
        const { company, createOfficeStatus, history, match } = this.props;
        const { officeCreated } = this.state;

        return officeCreated ? (
            <Redirect to={`/${match.params.item}/registry/office/create/success`} />
        ) : (
            <OfficesCreateContainer>
                <Title>
                    <FormattedMessage id="c-registry-office.modal.create.title" />
                </Title>
                <Subtitle>
                    <FormattedMessage id="c-registry-office.modal.create.subtitle" />
                </Subtitle>
                <OfficeForm
                    errorMsg={createOfficeStatus.error && createOfficeStatus.errorInfo.message}
                    isGovCodeEnabled={company.layers && company.layers.length > 0 && ENABLE_GOV_CODE === "enable"}
                    isLoading={createOfficeStatus.started}
                    onCancel={() => history.goBack()}
                    onSubmit={data => this.createOffice(data)}
                    submitButtonLabel={<FormattedMessage id="general.save" />}
                    unavailableGovCodes={
                        company.layers
                            ? company.layers.reduce((acc, currentOffice) => {
                                  if (currentOffice.base.identifier.govCode !== null) {
                                      acc.push(currentOffice.base.identifier.govCode);
                                  }
                                  return acc;
                              }, [])
                            : []
                    }
                />
            </OfficesCreateContainer>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        company: state.companies.data[props.match.params.item]
            ? state.companies.data[props.match.params.item].item
            : {},
        createOfficeStatus: state.offices.createOffice.status
    };
};

const composedHoc = compose(connect(mapStateToProps, { createOffice }), errorBoundary);

export default composedHoc(OfficesCreate);
