import styled from "styled-components";
export const ActiveStatusDot = styled.div`
    height: 10px;
    width: 10px;
    border-radius: 100%;
    margin-top: 2px;
    background-color: ${props => (props.active ? props.theme.colors.primary.richElectricBlue : "transparent")};
    border: 1px solid
        ${props => (props.active ? props.theme.colors.primary.richElectricBlue : props.theme.colors.text.cadet)};
    margin-right: 16px;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
`;
