import { fieldLength, fieldRequired } from "@ts-digital/agyo-validations";

import combineValidators from "@ts-digital/agyo-validations/lib/utils/combineValidators";

import { addressSchema } from "./address";
import { companyAdditionalDataSchema } from "./company-additional-data";
import { translateMessage, translateMessageWithParams } from "../../i18n";

export const companyProfileEditSchema = {
    type: "object",
    properties: {
        privacyFlag: {
            type: "boolean"
        },
        ...addressSchema.properties,
        ...companyAdditionalDataSchema.properties,
        description: {
            type: "string",
            validate: desc =>
                combineValidators(
                    fieldRequired(translateMessage("general.form.field-required")),
                    fieldLength(
                        translateMessageWithParams("general.form.field-length-range", { min: 1, max: 255 }),
                        1,
                        255
                    )
                )(desc)
        },
        taxRegime: {
            type: "string"
        }
    }
};
