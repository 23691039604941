import { WAITINGLIST_READ_URL } from "../../config";
import { getRestCall } from "../../utils/rest-api-call";
import { getUserProfile } from "../../utils/state-utils/get-user-profile";
import { getAuthWithAppName } from "../auth";

if (!WAITINGLIST_READ_URL) {
    throw new Error("Undefined env WAITINGLIST_READ_URL");
}

export const GET_WAITINGLIST_START = "GET_WAITINGLIST_START";
export const GET_WAITINGLIST_ERROR = "GET_WAITINGLIST_ERROR";
export const GET_WAITINGLIST_SUCCESS = "GET_WAITINGLIST_SUCCESS";

export function getWaitingList(itemId) {
    return async (dispatch, getState) => {
        dispatch({
            type: GET_WAITINGLIST_START,
            payload: { itemId }
        });

        const requestUrl = `${WAITINGLIST_READ_URL}items/${itemId}`;

        try {
            const auth = getAuthWithAppName(getState().auth);
            const response = await getRestCall(
                requestUrl,
                auth,
                null,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                undefined,
                false
            );

            dispatch({
                type: GET_WAITINGLIST_SUCCESS,
                payload: {
                    waitinglist: response[itemId]
                }
            });
        } catch (e) {
            dispatch({
                type: GET_WAITINGLIST_ERROR,
                payload: { error: e, itemId }
            });
        }
    };
}

export const WAITINGLIST_RESET = "WAITINGLIST_RESET";

export function resetWaitinglist() {
    return dispatch => dispatch({ type: WAITINGLIST_RESET });
}
