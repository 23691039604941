import { API_URL } from "../config";
import { getRestCall } from "../utils/rest-api-call";
import { getUserProfile } from "../utils/state-utils/get-user-profile";
import { getAuthWithAppName } from "./auth";

export const FETCH_ASSIGNABLE_ROLES_START = "FETCH_ASSIGNABLE_ROLES_START";
export const FETCH_ASSIGNABLE_ROLES_SUCCESS = "FETCH_ASSIGNABLE_ROLES_SUCCESS";
export const FETCH_ASSIGNABLE_ROLES_ERROR = "FETCH_ASSIGNABLE_ROLES_ERROR";
export const FETCH_ASSIGNABLE_ROLES_RESET = "FETCH_ASSIGNABLE_ROLES_RESET";

export const fetchAssignableRoles =
    (itemIds, getOnlyNonEmptyAssignableRoles = false) =>
    async (dispatch, getState) => {
        dispatch({ type: "FETCH_ASSIGNABLE_ROLES_START", payload: { itemIds } });

        const auth = getAuthWithAppName(getState().auth);

        try {
            const assignableRoles = await getRestCall(
                `${API_URL}/roles/assignable-roles`,
                auth,
                { itemIds, getOnlyNonEmptyAssignableRoles },
                dispatch,
                auth.refreshToken,
                getUserProfile(getState())
            );

            dispatch({ type: "FETCH_ASSIGNABLE_ROLES_SUCCESS", payload: assignableRoles });
        } catch (e) {
            dispatch({ type: "FETCH_ASSIGNABLE_ROLES_ERROR", payload: { itemIds }, error: { e } });
        }
    };

export const resetFetchAssignableRoles = () => dispatch => dispatch({ type: "FETCH_ASSIGNABLE_ROLES_RESET" });

export const FETCH_ROLES_SUMMARY_START = "FETCH_ROLES_SUMMARY_START";
export const FETCH_ROLES_SUMMARY_SUCCESS = "FETCH_ROLES_SUMMARY_SUCCESS";
export const FETCH_ROLES_SUMMARY_ERROR = "FETCH_ROLES_SUMMARY_ERROR";
export const FETCH_ROLES_SUMMARY_RESET = "FETCH_ROLES_SUMMARY_RESET";

export const fetchRolesSummary =
    (userId, page = 0, size = 10, itemFullText, workspaceOwnerId) =>
    async (dispatch, getState) => {
        dispatch({ type: "FETCH_ROLES_SUMMARY_START", payload: { userId } });

        const auth = getAuthWithAppName(getState().auth);

        try {
            const rolesSummary = await getRestCall(
                `${API_URL}/roles/${userId}/summary`,
                auth,
                { itemFullText, page, size, workspaceOwnerId },
                dispatch,
                auth.refreshToken,
                getUserProfile(getState())
            );
            dispatch({ type: "FETCH_ROLES_SUMMARY_SUCCESS", payload: { page, rolesSummary, size, userId } });
        } catch (e) {
            dispatch({ type: "FETCH_ROLES_SUMMARY_ERROR", payload: { userId } });
        }
    };

export const fetchRolesSummaryReset = () => dispatch => dispatch({ type: "FETCH_ROLES_SUMMARY_RESET" });
