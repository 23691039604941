import React, { useEffect, useState } from "react";

import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { faPen, faCity, faUser } from "@fortawesome/pro-solid-svg-icons";
import { faBuilding, faBriefcase } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FormattedMessage } from "react-intl";

import Modal from "../../../general/modal";
import LoadingButton from "../../../general/loading-button";
import { Button } from "@ts-digital/vrc";

import {
    AddLogoText,
    ButtonFormContainer,
    CompanyIconContainer,
    CompanyIcon,
    CircularButton,
    EditIcon,
    ModalContainer,
    LoadingContainer,
    Logo,
    LogoForm,
    UploadContainer
} from "./styled";
import { UploadForm } from "./components/upload-form";

export const EditableItemLogo = ({
    classifier,
    disabled,
    handleDeleteLogo,
    handleUploadLogo,
    logoEdit,
    logoUrl,
    isLoading,
    uuid,
    uploadLogoStatus,
    resetDeleteLogo,
    resetUploadLogo,
    deleteLogoStatus,
    user
}) => {
    const [modalOpen, setModalOpen] = useState(logoEdit);
    const [base64LogoUploaded, setBase64LogoUploaded] = useState(null);
    const [controlsEnabled, setControlsEnabled] = useState(true);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [deleteConfirmState, setDeleteConfirmState] = useState(false);

    useEffect(() => {
        if (
            (!uploadLogoStatus.started && uploadLogoStatus.ended && !uploadLogoStatus.error) ||
            (!deleteLogoStatus.started && deleteLogoStatus.ended && !deleteLogoStatus.error)
        ) {
            if (uploadLogoStatus.ended) {
                resetUploadLogo();
            }
            if (deleteLogoStatus.ended) {
                setBase64LogoUploaded(null);
                resetDeleteLogo();
            }
            setModalOpen(false);
            setControlsEnabled(true);
            setSaveEnabled(false);
            setDeleteConfirmState(false);
        } else if (
            (!deleteLogoStatus.started && !deleteLogoStatus.ended && deleteLogoStatus.error) ||
            (!uploadLogoStatus.started && !uploadLogoStatus.ended && uploadLogoStatus.error)
        ) {
            setControlsEnabled(true);
        }
    }, [
        uploadLogoStatus.started,
        uploadLogoStatus.ended,
        uploadLogoStatus.error,
        deleteLogoStatus.started,
        deleteLogoStatus.ended,
        deleteLogoStatus.error,
        resetDeleteLogo,
        resetUploadLogo,
        base64LogoUploaded
    ]);

    const defaultIcon = {
        STUDIO: faBriefcase,
        COMPANY: faBuilding,
        PERSON: faUser,
        BUILDING: faCity
    };

    const popupImage = base64LogoUploaded === null ? logoUrl : base64LogoUploaded;

    const modalTitle = deleteConfirmState
        ? "logo-upload-modal.confirm-delete.title"
        : logoUrl
        ? "logo-upload-modal.title-edit"
        : "logo-upload-modal.title";

    return (
        <>
            {!isLoading && logoUrl ? (
                <>
                    <LogoForm src={logoUrl.startsWith("http") ? logoUrl + "?" + performance.now() : logoUrl} />
                    <CircularButton
                        loadedImg={true}
                        onClick={() => setModalOpen(true)}
                        data-gainsight-track={"logo-flow-B-edit-editform"}
                    >
                        <EditIcon icon={faPen} />
                    </CircularButton>
                </>
            ) : (
                <UploadContainer editable={!disabled}>
                    {isLoading ? (
                        <LoadingContainer>
                            <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
                        </LoadingContainer>
                    ) : (
                        <CompanyIconContainer
                            onClick={() => (!disabled ? setModalOpen(true) : null)}
                            data-gainsight-track={!disabled ? "logo-flow-B-add-editform" : null}
                        >
                            {!disabled ? (
                                <AddLogoText>
                                    <FormattedMessage id={"logo.add-logo-text"} />
                                </AddLogoText>
                            ) : (
                                <CompanyIcon icon={defaultIcon[classifier]} />
                            )}
                        </CompanyIconContainer>
                    )}
                </UploadContainer>
            )}

            <Modal
                centered={true}
                closable={controlsEnabled ? true : false}
                visible={modalOpen}
                title={<FormattedMessage id={modalTitle} />}
                onCancel={() => {
                    if (controlsEnabled) {
                        setModalOpen(false);
                        setDeleteConfirmState(false);
                        setBase64LogoUploaded(logoUrl);
                    }
                }}
                width="350px"
                paddingBottom="30px"
                footer={[
                    <Button
                        key={1}
                        kind="secondary"
                        onClick={() => {
                            setSaveEnabled(false);
                            setModalOpen(false);
                            setDeleteConfirmState(false);
                            setBase64LogoUploaded(logoUrl);
                        }}
                        disabled={!controlsEnabled}
                    >
                        <FormattedMessage id={"general.annulla"} />
                    </Button>,
                    <LoadingButton
                        key={2}
                        loading={uploadLogoStatus.started || deleteLogoStatus.started}
                        onClick={() => {
                            if (deleteConfirmState) {
                                handleDeleteLogo(uuid);
                            } else {
                                handleUploadLogo(uuid, base64LogoUploaded);
                            }
                            setControlsEnabled(false);
                        }}
                        disabled={!saveEnabled}
                        data-gainsight-track={deleteConfirmState ? "logo-flow-delete" : "logo-flow-upload"}
                    >
                        <FormattedMessage id={deleteConfirmState ? "remove" : "general.save"} />
                    </LoadingButton>
                ]}
            >
                <ModalContainer>
                    {popupImage ? (
                        <Logo src={popupImage.startsWith("http") ? popupImage + "?" + performance.now() : popupImage} />
                    ) : (
                        <UploadContainer>
                            <CompanyIconContainer>
                                <AddLogoText>
                                    <FormattedMessage id={"logo.add-logo-modal-text"} />
                                </AddLogoText>
                            </CompanyIconContainer>
                        </UploadContainer>
                    )}
                    {!deleteConfirmState && (
                        <ButtonFormContainer>
                            <UploadForm
                                onChange={base64 => {
                                    setBase64LogoUploaded(base64);
                                    setSaveEnabled(true);
                                }}
                                onRemove={() => {
                                    setDeleteConfirmState(true);
                                    setSaveEnabled(true);
                                }}
                                removeEnabled={logoUrl !== null && logoUrl !== undefined && logoUrl !== ""}
                                controlsEnabled={controlsEnabled}
                            />
                        </ButtonFormContainer>
                    )}
                </ModalContainer>
            </Modal>
        </>
    );
};
