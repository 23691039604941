import styled from "styled-components";

export const FooterDiv = styled.div`
    background: #d9e3ee;
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;

    & .anticon {
        color: #4693ce;
    }

    & .c-sideMenu-menu {
        background: none !important;
    }
`;

export const PrivacyMenuContainer = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    float: left;
`;

export const CardContainer = styled.div`
    text-align: start;
    font-size: 14px;

    display: grid;
    grid-template-columns: 100%;

    @media screen and (min-width: 1024px) {
        grid-template-columns: 50% 50%;
    }

    @media screen and (min-width: 1458px) {
        grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
    }
`;

export const CompanyCard = styled.div`
    display: flex;
    flex-direction: column;
    margin: 8px;

    background-color: white;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;

    opacity: 0;
    animation: slideUp 0.3s ease, fadeIn 0.2s linear forwards;
    animation-delay: ${props => `${props.index < 12 ? props.index * 65 : 0}ms`};
    transition: transform 0.5s ease, box-shadow 0.5s ease;

    &:hover {
        transform: translateY(-2px);
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    }

    @keyframes slideUp {
        0% {
            transform: translateY(16px);
        }
        100% {
            transform: translateY(0px);
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

export const CardHeader = styled.div`
    display: grid;
    grid-template-columns: auto 40px 16px;
    margin: 16px 24px;
    font-size: 16px;
    vertical-align: center;
`;

export const CardTitle = styled.div`
    font-weight: 600;
`;

export const CardDescription = styled.div`
    display: grid;
    margin: auto 24px;
    grid-template-columns: 120px auto;
`;

export const CardButtons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    margin-top: 16px;
    border-top: 1px solid rgb(62, 143, 204);
    font-weight: 600;
    color: rgb(62, 143, 204);

    & > * {
        padding: 0 8px;
    }
`;

export const SettingsIcon = styled.span`
    color: #1890ff;

    &:hover {
        cursor: pointer;
    }
`;
