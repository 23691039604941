import { REGISTRY_READ_REST, API_URL } from "../config";
import { getAuthWithAppName } from "./auth.js";
import { getRestCall } from "../utils/rest-api-call";
import { getUserProfile } from "../utils/state-utils/get-user-profile";
import { displayErrorToast } from "./overflowNotification";

export const LOAD_COMPANY_START = "LOAD_COMPANY_START";
export const LOAD_COMPANY_SUCCESS = "LOAD_COMPANY_SUCCESS";
export const LOAD_COMPANY_ERROR = "LOAD_COMPANY_ERROR";
export const LOAD_COMPANY_RESET = "LOAD_COMPANY_RESET";

export function loadCompanyWithCredentials(auth, itemId, full = false) {
    return async (dispatch, getState) => {
        const packageType = full ? "FULL" : "BASE";

        if (getState().company.status.started) {
            return;
        }

        dispatch({
            type: LOAD_COMPANY_START,
            payload: packageType
        });

        let authObj = getAuthWithAppName(auth);
        authObj.accessToken = authObj.securityToken;

        try {
            const result = await getRestCall(
                `${REGISTRY_READ_REST}/items/${itemId}`,
                authObj,
                {
                    packageType: packageType
                },
                dispatch,
                authObj.refreshToken,
                getUserProfile(getState()),
                itemId
            );

            dispatch({
                type: LOAD_COMPANY_SUCCESS,
                payload: result.item
            });
        } catch (err) {
            dispatch({
                type: LOAD_COMPANY_ERROR,
                error: { code: err.code, message: err.message },
                payload: { itemId }
            });

            if (err.code !== "404") {
                displayErrorToast(err.message)(dispatch);
            }
        }
    };
}

export function loadCompany(itemId, full = false) {
    return async (dispatch, getState) => {
        return loadCompanyWithCredentials(getState().auth, itemId, full)(dispatch, getState);
    };
}

export const GET_PRIVACY_PREFERENCES_START = "GET_PRIVACY_PREFERENCES_START";
export const GET_PRIVACY_PREFERENCES_SUCCESS = "GET_PRIVACY_PREFERENCES_SUCCESS";
export const GET_PRIVACY_PREFERENCES_ERROR = "GET_PRIVACY_PREFERENCES_ERROR";

export function getPrivacyPreferences(itemId) {
    return async (dispatch, getState) => {
        dispatch({ type: GET_PRIVACY_PREFERENCES_START, payload: { itemId } });

        try {
            const auth = getAuthWithAppName(getState().auth);
            const result = await getRestCall(
                `${API_URL}/items/${itemId}/privacy`,
                auth,
                undefined,
                dispatch,
                auth.refreshToken
            );

            dispatch({ type: GET_PRIVACY_PREFERENCES_SUCCESS, payload: { [itemId]: result } });
        } catch (e) {
            if (e.code === "404") {
                dispatch({ type: GET_PRIVACY_PREFERENCES_SUCCESS, payload: { [itemId]: {} } });
                return;
            }

            dispatch({ type: GET_PRIVACY_PREFERENCES_ERROR, error: e, payload: { itemId } });
        }
    };
}

export function resetCompanyLoad() {
    return dispatch => {
        dispatch({
            type: LOAD_COMPANY_RESET
        });
    };
}
