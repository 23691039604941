import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import "./style.css";

class StatusTag extends React.Component {
    static propTypes = {
        circle: PropTypes.oneOf(["green", "orange", "red", "grey"]),
        label: PropTypes.string,
        style: PropTypes.oneOf(["green", "orange", "red", "grey"])
    };

    static defaultProps = {
        style: "green"
    };

    render() {
        const { circle, label, style } = this.props;

        return label ? (
            <div className={`status-label status-label-${style}`}>
                {circle ? <i className={`fas fa-circle circle-size item-status-${circle}`} /> : null}
                <FormattedMessage id={label} />
            </div>
        ) : (
            <div />
        );
    }
}

export default StatusTag;
