import styled from "styled-components";

export const Container = styled.div`
    border-radius: 0px;
    border: 1px solid rgb(201, 217, 232);
    width: 100%;

    margin: 8px;
`;

export const CompanyDescription = styled.div`
    color: rgb(0, 80, 117);
    font-family: Cairo;
    font-size: 24px;
    font-weight: 600;
    height: 32px;
    letter-spacing: 0.18px;
    line-height: 32px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 8px 8px 8px 16px;
`;

export const FiscalDataContainer = styled.div`
    color: rgb(21, 41, 53);
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 16px;

    display: table;
    width: auto;
`;

export const FiscalDataHeader = styled.div`
    display: table-column;
    float: left;

    width: 100px;
`;

export const FiscalDataData = styled.div`
    display: table-column;
    float: left;

    font-weight: bold;
`;

export const FiscalDataRow = styled.div`
    display: table-row;

    width: auto;
    clear: both;

    height: 20px;
`;

export const Content = styled.div`
    display: flex;

    justify-content: space-between;

    margin: 8px 8px 8px 16px;
`;

export const CompanyType = styled.div`
    color: rgb(13, 108, 128);
    font-family: Cairo;
    font-size: 12px;
    font-weight: 600;
    height: 23px;
    letter-spacing: 0px;
    text-align: center;

    margin-top: 16px;
    margin-right: 24px;
    padding: 0 8px 0 8px;
    border-radius: 6px;
    border: 1px solid rgb(13, 108, 128);
`;

export const TitleContainer = styled.div`
    display: flex;

    justify-content: space-between;
`;
