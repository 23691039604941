import {
    GET_BILLING_ITEMS_START,
    GET_BILLING_ITEMS_END,
    GET_BILLING_ITEMS_SUCCESS,
    GET_BILLING_ITEMS_ERROR
} from "../../../../actions/metering/billing/items";

const defaultBillingState = {
    status: { loading: false, error: null },
    data: []
};

export const itemsReducer = (state = defaultBillingState, { type, payload }) => {
    switch (type) {
        case GET_BILLING_ITEMS_START: {
            return {
                ...state,
                status: { ...state.status, loading: true }
            };
        }
        case GET_BILLING_ITEMS_END: {
            return {
                ...state,
                status: { ...state.status, loading: false }
            };
        }
        case GET_BILLING_ITEMS_SUCCESS: {
            const { items } = payload;
            return { ...state, data: items };
        }
        case GET_BILLING_ITEMS_ERROR: {
            const { error } = payload;
            return {
                ...state,
                status: { ...state.status, error }
            };
        }
        default: {
            return state;
        }
    }
};
