import React from "react";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl";
import { Button } from "@ts-digital/vrc";

import { form } from "@ts-digital/conv-redux-form";
import { Field } from "redux-form";

import { ncsChannelEmail } from "../../../../utils/form-schema/ncs";

import TextInput from "../../../general/form-text-input";

import { ButtonContainer, FieldContainer, FormContent } from "../styled";

export const AddEmail = ({ handleSubmit, status }) => {
    const { formatMessage } = useIntl();

    return (
        <form onSubmit={handleSubmit}>
            <FormContent>
                <FieldContainer>
                    <Field
                        id="name-field"
                        name="name"
                        component={TextInput}
                        label={formatMessage({ id: "c-notifications-center.channels.email.addRecipient.name.label" })}
                        placeholder={formatMessage({
                            id: "c-notifications-center.channels.email.addRecipient.name.placeholder"
                        })}
                        disabled={status.updating}
                        required={true}
                    />
                </FieldContainer>
                <FieldContainer>
                    <Field
                        id="value-field"
                        name="value"
                        component={TextInput}
                        label={formatMessage({ id: "general.email" })}
                        placeholder={formatMessage({
                            id: "c-notifications-center.channels.email.addRecipient.email.placeholder"
                        })}
                        disabled={status.updating}
                        required={true}
                    />
                </FieldContainer>
                <ButtonContainer>
                    <Button type="submit" kind="primary" disabled={status.updating}>
                        <FormattedMessage id="general.add" />
                    </Button>
                </ButtonContainer>
            </FormContent>
        </form>
    );
};

AddEmail.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    status: PropTypes.object.isRequired
};

const formDefinition = {
    form: "ncs-add-email",
    schema: ncsChannelEmail,
    enableReinitialize: true
};

export default form(formDefinition)(AddEmail);
