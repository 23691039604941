import React, { Component } from "react";
import { Popover } from "antd";
import PropTypes from "prop-types";
import { isNil } from "ramda";
import { injectIntl } from "react-intl";

import User from "../../manage-data/user";

import Initials from "./user-initials";
import UserCardTitle from "./user-card-title";
import PopupContent from "./popup-content";

import { TopInitials } from "./styled";

class UserBadge extends Component {
    static propTypes = {
        auth: PropTypes.object.isRequired,
        className: PropTypes.string.isRequired,
        copyTokenToast: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        company: PropTypes.object,
        resetCompanyLoad: PropTypes.func,
        showNotification: PropTypes.bool,
        editUser: PropTypes.func.isRequired,
        dashboardView: PropTypes.bool,
        users: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state = {
            mModifyDataUser: false,
            popoverVisible: false
        };
    }

    handleClose = value => {
        this.setState({ [value]: false });
    };

    handleModifyUserClick = () => {
        this.setState({ mModifyDataUser: true });
        this.hidePopover();
    };

    handleVisibleChange = popoverVisible => {
        this.setState({ popoverVisible });
    };

    hidePopover = () => {
        this.setState({
            popoverVisible: false
        });
    };

    render() {
        const {
            auth,
            className,
            copyTokenToast,
            logout,
            editUser,
            loadUser,
            updateUserPsw,
            updateUserPswStatus,
            userEditStatus,
            resetCompanyLoad,
            dashboardView,
            intl,
            showNotification,
            company,
            user,
            users
        } = this.props;

        const { popoverVisible } = this.state;

        return !isNil(user.user) ? (
            <div style={{ marginRight: 24, cursor: "pointer" }}>
                <Popover
                    title={<UserCardTitle auth={auth} logout={logout} />}
                    content={
                        <PopupContent
                            auth={auth}
                            copyTokenToast={copyTokenToast}
                            resetCompanyLoad={resetCompanyLoad}
                            dashboardView={dashboardView}
                            user={user}
                            intl={intl}
                            showNotification={showNotification}
                            company={company}
                            handleModifyUserClick={() => this.handleModifyUserClick()}
                            handleCloseModal={() => this.hidePopover()}
                            users={users}
                        />
                    }
                    placement="bottomLeft"
                    overlayClassName="headerPopOver"
                    arrowPointAtCenter
                    visible={popoverVisible}
                    onVisibleChange={this.handleVisibleChange}
                >
                    <div className={className}>
                        <TopInitials>
                            <Initials name={`${user.user.profile.firstName} ${user.user.profile.lastName}`} />
                        </TopInitials>
                        <span>{`${user.user.profile.firstName} ${user.user.profile.lastName}`}</span>
                    </div>
                </Popover>

                <User
                    auth={auth}
                    editUser={editUser}
                    isOpen={this.state.mModifyDataUser}
                    handleClose={() => this.handleClose("mModifyDataUser")}
                    loadUser={loadUser}
                    updateUserPsw={updateUserPsw}
                    updateUserPswStatus={updateUserPswStatus}
                    user={user.user}
                    userEditStatus={userEditStatus}
                />
            </div>
        ) : null;
    }
}

export default injectIntl(UserBadge);
