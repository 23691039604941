import {
    CREATE_OFFICE_ERROR,
    CREATE_OFFICE_RESET,
    CREATE_OFFICE_START,
    CREATE_OFFICE_SUCCESS,
    UPDATE_OFFICE_ERROR,
    UPDATE_OFFICE_RESET,
    UPDATE_OFFICE_START,
    UPDATE_OFFICE_SUCCESS,
    DISABLE_OFFICE_ERROR,
    DISABLE_OFFICE_RESET,
    DISABLE_OFFICE_START,
    DISABLE_OFFICE_SUCCESS
} from "../../actions/company/offices";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function createOffice(state = defaultState, { type, error }) {
    switch (type) {
        case CREATE_OFFICE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case CREATE_OFFICE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                }
            };
        case CREATE_OFFICE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case CREATE_OFFICE_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export function updateOffice(state = defaultState, { type, error }) {
    switch (type) {
        case UPDATE_OFFICE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case UPDATE_OFFICE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                }
            };
        case UPDATE_OFFICE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case UPDATE_OFFICE_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export function deleteOffice(state = defaultState, { type, error }) {
    switch (type) {
        case DISABLE_OFFICE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case DISABLE_OFFICE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                }
            };
        case DISABLE_OFFICE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case DISABLE_OFFICE_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}
