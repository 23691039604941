import React, { useEffect } from "react";

import { Button } from "@ts-digital/vrc";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { connect } from "react-redux";

import { getListConsent, updateTermConsent } from "../../actions/user-consents";
import { useFeature } from "../../hooks/use-feature";
import { consentNeedsUpdate } from "../../utils/consent-utils";

import { StyledModal } from "./styled";

export const GeneralTermsModal = ({ consents, getListConsent, updateTermConsent }) => {
    const generalTermsModalActive = useFeature("GENERAL_TERMS_MODAL");

    useEffect(() => {
        generalTermsModalActive && getListConsent();
    }, [getListConsent, generalTermsModalActive]);

    const updateConsent = (accepted, term) => {
        updateTermConsent(term, {
            accepted,
            version: consents[term].latestVersion
        });
    };

    const footer = (
        <Button
            kind="primary"
            style={{ textTransform: "uppercase" }}
            onClick={() => updateConsent(true, "CORE_GENERAL_TERMS")}
        >
            <FormattedMessage id="root.general-terms-modal.ok" />
        </Button>
    );

    return (
        <StyledModal
            closable={false}
            title={<FormattedHTMLMessage id="root.general-terms-modal.title" />}
            visible={generalTermsModalActive && consentNeedsUpdate(consents, "CORE_GENERAL_TERMS")}
            maxWidth="1040px"
            footer={footer}
        >
            <p>
                <FormattedHTMLMessage id="root.general-terms-modal.content" />
            </p>
        </StyledModal>
    );
};

const mapStateToProps = state => ({
    consents: state.userConsents.terms
});

export default connect(mapStateToProps, { getListConsent, updateTermConsent })(GeneralTermsModal);
