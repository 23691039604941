import React from "react";
import { METERING_API_URL } from "../../../../config";
import { getRestCall } from "../../../../utils/rest-api-call";
import { displayErrorToast } from "../../../overflowNotification";
import { FormattedMessage } from "react-intl";
import { getUserProfile } from "../../../../utils/state-utils/get-user-profile";
import { getAuthWithAppName } from "../../../auth";

export const GET_BILLING_USAGE_DETAILS_START = "METERING_GET_BILLING_USAGE_DETAILS_START";
export const GET_BILLING_USAGE_DETAILS_END = "METERING_GET_BILLING_USAGE_DETAILS_END";
export const GET_BILLING_USAGE_DETAILS_SUCCESS = "METERING_GET_BILLING_USAGE_DETAILS_SUCCESS";
export const GET_BILLING_USAGE_DETAILS_ERROR = "METERING_GET_BILLING_USAGE_DETAILS_ERROR";

export const getBillingUsageDetailsStart = id => ({
    type: GET_BILLING_USAGE_DETAILS_START,
    payload: {
        id
    }
});

export const getBillingUsageDetailsEnd = id => ({
    type: GET_BILLING_USAGE_DETAILS_END,
    payload: {
        id
    }
});

export const getBillingUsageDetailsSuccess = (id, details, size) => ({
    type: GET_BILLING_USAGE_DETAILS_SUCCESS,
    payload: {
        id,
        details,
        size
    }
});

export const getBillingUsageDetailsError = (id, error) => ({
    type: GET_BILLING_USAGE_DETAILS_ERROR,
    payload: {
        id,
        error
    }
});

export const getBillingUsageDetails =
    (userId, item, managed, itemsPerPage, page, from, to, sortBy, viewOldBillingCycles) =>
    async (dispatch, getState) => {
        dispatch(getBillingUsageDetailsStart(managed));
        try {
            const auth = getAuthWithAppName(getState().auth);
            const { details, size } = await getRestCall(
                `${METERING_API_URL}/api/v1/usage/invoicing/${item}/details`,
                auth,
                {
                    managed,
                    itemsPerPage,
                    page,
                    userId,
                    from,
                    to,
                    sortBy: sortBy && sortBy.key && sortBy.order && `${sortBy.key}.${sortBy.order}`,
                    viewOldBillingCycles
                },
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                item
            );
            dispatch(getBillingUsageDetailsSuccess(managed, details, size));
        } catch (error) {
            const displayError = displayErrorToast(<FormattedMessage id="metering.usage.detail.billing.error" />);
            displayError(dispatch);
            dispatch(getBillingUsageDetailsError(managed, error));
        }
        dispatch(getBillingUsageDetailsEnd(managed));
    };
