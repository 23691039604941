import { combineReducers } from "redux";
import { statisticsReducer } from "./statistics";
import { detailsReducer } from "./details";
import { subscriptionsReducer } from "./subscriptions";
import { itemsReducer } from "./items";

export const billingReducer = combineReducers({
    statistics: statisticsReducer,
    details: detailsReducer,
    subscriptions: subscriptionsReducer,
    items: itemsReducer
});
