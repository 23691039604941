import React from "react";
import PropTypes from "prop-types";

import { FormattedHTMLMessage } from "react-intl";
import { Checkbox } from "@ts-digital/vrc";

import { RDC_VADEMECUM_URL } from "../../../../../../config";

import {
    BalanceSheetDateAlert,
    Description,
    ElevioLink,
    ModalContent,
    SmartCheckboxContainer,
    SmartCheckboxLabelContainer,
    VademecumContainer,
    RdcData,
    RdcDataLabels,
    RdcDescription,
    RdcField
} from "./styled";

export const SmartConfirmModal = ({ rdcData, enableConfirmButton, modalConfirmDisabled }) => {
    return (
        <ModalContent>
            <Description>
                <FormattedHTMLMessage id="c-cct-massive-set-smart-rdc-confirm-modal.description" />
                <ElevioLink data-elevio-inline={"654"} target="_blank">
                    <FormattedHTMLMessage id="cct-smart-more-info" />
                </ElevioLink>
            </Description>
            <SmartCheckboxContainer>
                <Checkbox
                    name={"smartConsent"}
                    onChange={val => {
                        enableConfirmButton(!val);
                    }}
                    checked={!modalConfirmDisabled}
                />
                <SmartCheckboxLabelContainer>
                    <FormattedHTMLMessage id="c-cct-provisioning-download-pdf-form.archive-smart-declare" />
                </SmartCheckboxLabelContainer>
            </SmartCheckboxContainer>
            <RdcDescription>
                <FormattedHTMLMessage id="c-cct-massive-set-smart-rdc-confirm-modal.rdc-description" />

                <VademecumContainer>
                    <FormattedHTMLMessage id="c-cct-provisioning-download-pdf-form.rdc-subtitle" />
                    <a href={RDC_VADEMECUM_URL} target="_blank" rel="noopener noreferrer">
                        <FormattedHTMLMessage id="c-cct-provisioning-download-pdf-form.rdc-link" />
                    </a>
                </VademecumContainer>
            </RdcDescription>
            <RdcData>
                <RdcField>
                    <RdcDataLabels>
                        <FormattedHTMLMessage id="general.name" />:
                    </RdcDataLabels>
                    {rdcData.name}
                </RdcField>
                <RdcField>
                    <RdcDataLabels>
                        <FormattedHTMLMessage id="general.lastname" />:
                    </RdcDataLabels>
                    {rdcData.surname}
                </RdcField>
                <RdcField>
                    <RdcDataLabels>
                        <FormattedHTMLMessage id="general.codice-fiscale" />:
                    </RdcDataLabels>
                    {rdcData.taxId}
                </RdcField>
                <RdcField>
                    <RdcDataLabels>
                        <FormattedHTMLMessage id="general.email" />:
                    </RdcDataLabels>
                    {rdcData.email}
                </RdcField>
            </RdcData>
            <BalanceSheetDateAlert>
                <FormattedHTMLMessage id="c-cct-massive-set-smart-rdc-confirm-modal.date-alert" />
            </BalanceSheetDateAlert>
        </ModalContent>
    );
};

SmartConfirmModal.propTypes = {
    /** rdc data */
    rdcData: PropTypes.object,
    /** confirm button and smart checkbox status */
    enableConfirmButton: PropTypes.func,
    /** call for enable/disabled confirm button with checkbox */
    modalConfirmDisabled: PropTypes.bool
};

export default SmartConfirmModal;
