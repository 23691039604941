import styled, { css } from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-auto-columns: minmax(min-content, max-content);
    grid-auto-flow: column;
`;

export const Tab = styled.div`
    background-color: ${props => props.theme.colors.text.gainsboro};
    color: ${props => props.theme.colors.text.cadet};
    font-size: 14px;
    font-family: Cairo;
    font-weight: 600;
    text-align: center;
    padding: 8px 42px;
    cursor: pointer;
    border: 1px solid transparent;

    ${props =>
        props.isFirst &&
        css`
            border-radius: 20px 0 0 20px;
        `}

    ${props =>
        props.isLast &&
        css`
            border-radius: 0 20px 20px 0;
        `}

    ${props =>
        props.selected &&
        css`
            color: ${props => props.theme.colors.primary.darkImperialBlue};
            background-color: ${props => props.theme.colors.secondary.diamond};
            border: 1px solid ${props => props.theme.colors.primary.richElectricBlue};
        `}

    :hover {
        color: ${props => props.theme.colors.primary.darkImperialBlue};
        background-color: ${props => props.theme.colors.secondary.diamond};
    }
`;
