import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../styled";

const disabledStatuses = ["uploaded", "validated", "initialized", "pending", "correction"];

export const ContractUploadCell = ({
    cell: { value },
    isManaged,
    onClick,
    row: {
        original: { itemCertified, status }
    }
}) => {
    const disabled = (!isManaged && itemCertified !== "CERTIFIED") || disabledStatuses.includes(status);

    return (
        <CustomButton
            kind="tertiary"
            disabled={disabled}
            onClick={() => {
                onClick(value);
            }}
            data-gainsight-track={"archive-upload-cta"}
        >
            <FormattedMessage id="general.upload" />
        </CustomButton>
    );
};

ContractUploadCell.propTypes = {
    /** Current cell data */
    cell: PropTypes.shape({ value: PropTypes.any }).isRequired,
    /** Set true if item is managed */
    isManaged: PropTypes.bool,
    /** Rdc contract status */
    status: PropTypes.string,
    /** Button click callback */
    onClick: PropTypes.func.isRequired
};

ContractUploadCell.defaultProps = {
    isManaged: false
};
