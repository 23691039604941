import styled from "styled-components";

export const CompanyName = styled.div`
    font-family: Cairo;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.18px;
    color: #152935;
`;

export const CompanyWrapper = styled.div`
    display: grid;
    grid-template-columns: 42px 1fr;
    grid-gap: 8px;
    align-items: center;
`;

export const Container = styled.div`
    padding: 8px 16px;
    border-top: 1px solid #c9d9e8;

    & button {
        width: 100%;
    }
`;

export const Info = styled.span`
    font-family: Roboto;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: #152935;
    margin-right: 8px;
`;

export const SecondButtonWrapper = styled.div`
    margin-top: 8px;
`;

export const UserIcon = styled.div`
    font-size: 24px;
    color: #005075;

    display: inline-flex;
    justify-content: center;
    align-items: center;
`;

export const Status = styled.div`
    font-family: Roboto;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: #5a6872;
`;

export const Uuid = styled.div`
    font-family: Roboto;
    font-size: 8px;
    line-height: 1;
    letter-spacing: 0.4px;
    color: #5a6872;
`;
