import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";

import { Col, Layout, Row } from "antd";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { useHistory } from "react-router";

import { loadCompany } from "../../../actions/company";
import { getPreferencesList } from "../../../actions/ncs/read";
import PageTitle from "../../../components/general/page-title";
import { Spinner } from "../../../components/general/spinner";
import PreferencesEmpty from "../../../components/notifications-center/preferences-empty";
import PreferencesList from "../../../components/notifications-center/preferences-list";
import Sidebar from "../../../components/notifications-center/sidebar";
import { getNcsBasePath } from "../../../utils/route";

import { Description, PageContainer, PageContent, Title } from "./styled.js";

export const PreferencesView = ({
    applications,
    applicationsLoading,
    groupId,
    itemId,
    match: { params },
    user,
    getPreferencesList,
    loadCompany
}) => {
    const history = useHistory();

    const { item, selectedItemId } = params;

    useEffect(() => {
        if (selectedItemId && !itemId) {
            loadCompany(selectedItemId);
        }
    }, [itemId, selectedItemId, loadCompany]);

    const getPreferences = useCallback(() => {
        if (!user.status.started && user.user) {
            if (selectedItemId && itemId && groupId) {
                getPreferencesList(user.user.profile.ncsId, itemId, groupId);
            }

            if (!selectedItemId) {
                getPreferencesList(user.user.profile.ncsId);
            }
        }
    }, [user.user, user.status, groupId, itemId, selectedItemId, getPreferencesList]);

    useEffect(() => {
        getPreferences();
    }, [getPreferences]);

    return (
        <Layout>
            <PageTitle
                title={<FormattedMessage id="c-notifications-center.title1" />}
                onBack={() => history.push(`${getNcsBasePath(null, item)}`)}
            />
            <PageContainer>
                <Spinner loading={!user.user || applicationsLoading || (selectedItemId && !itemId && !groupId)}>
                    <PageContent>
                        <Row gutter={32}>
                            <Col xs={{ span: 10, offset: 1 }} xl={{ span: 5, offset: 0 }}>
                                <Sidebar />
                            </Col>
                            <Col
                                xs={{ span: 22, offset: 1 }}
                                xl={{ span: 19, offset: 0 }}
                                xxl={{ span: 16, offset: 1 }}
                            >
                                {selectedItemId && (
                                    <>
                                        <Title>
                                            <FormattedMessage id="c-notifications-center.preferences.title" />
                                        </Title>
                                        <Description>
                                            <FormattedMessage id="c-notifications-center.preferences.description" />
                                        </Description>
                                    </>
                                )}

                                {applications.length === 0 && <PreferencesEmpty />}
                                {applications.length > 0 &&
                                    applications
                                        .sort((a, b) => (a.appCode.toLowerCase() > b.appCode.toLowerCase() ? 1 : -1))
                                        .map(({ appCode, preferences }) => (
                                            <PreferencesList
                                                key={`application-${appCode}`}
                                                appCode={appCode}
                                                preferences={preferences}
                                                groupId={groupId}
                                                itemId={itemId}
                                            />
                                        ))}
                            </Col>
                        </Row>
                    </PageContent>
                </Spinner>
            </PageContainer>
        </Layout>
    );
};

PreferencesView.propTypes = {
    applications: PropTypes.array,
    applicationsLoading: PropTypes.bool,
    groupId: PropTypes.string,
    itemId: PropTypes.string,
    user: PropTypes.object.isRequired,
    getPreferencesList: PropTypes.func.isRequired,
    loadCompany: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
    const selectedCompany = state.companies.data[props.match.params.selectedItemId]
        ? state.companies.data[props.match.params.selectedItemId].item.base
        : {};

    return {
        applications: state.ncs.preferences.data,
        applicationsLoading: state.ncs.preferences.status.started,
        groupId: selectedCompany.ncsId,
        itemId: selectedCompany.id,
        user: state.user
    };
};

export default connect(mapStateToProps, {
    getPreferencesList,
    loadCompany
})(PreferencesView);
