import styled from "styled-components";

export const Container = styled.div`
    background-color: ${props => props.theme.colors.background.white};
    min-height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 72px;
`;

export const Content = styled.div`
    margin: 0px 136px;
`;

export const TabsContainer = styled.div`
    width: 100%;

    & > div {
        margin-left: 136px;
        overflow-x: auto;
    }

    // The provided Tabs components stops the columbia blue bottom border exactly at the width of the last tab.
    // We need the border to cover the entire width of the page, whether there are enough tabs or not.
    &:after {
        border-bottom: 1px solid ${props => props.theme.colors.primary.columbiaBlue};
        // content and display are necessary to show the element
        content: "";
        display: block;
        // 1 pixel up from the bottom to cover up the existing (but too short) border
        position: relative;
        bottom: 1px;
    }
`;

export const UserDataContainer = styled.div`
    margin-left: 136px;
    margin-top: 48px;
    margin-bottom: 48px;
`;
