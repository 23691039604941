import thrift from "browser-thrift";
import { is } from "ramda";

import SignApiRead from "../assets/thrift/sign-api/SigninApiRead";
import SignApiReadTypes from "../assets/thrift/sign-api/apiRead_types";
import { SIGN_API_READ_URL } from "../config";
import { callWithRetry, getAuthWithAppName } from "./auth";

const signApiReadUrl = new URL(SIGN_API_READ_URL);
const signApiReadClient = thrift.createXHRClient(
    SignApiRead,
    thrift.createXHRConnection(signApiReadUrl.hostname, 443, {
        useCors: true,
        path: signApiReadUrl.pathname,
        https: signApiReadUrl.protocol === "https:"
    })
);

export const DOWNLOAD_FILLED_DOCUMENT_START = "DOWNLOAD_FILLED_DOCUMENT_START";
export const DOWNLOAD_FILLED_DOCUMENT_SUCCESS = "DOWNLOAD_FILLED_DOCUMENT_SUCCESS";
export const DOWNLOAD_FILLED_DOCUMENT_ERROR = "DOWNLOAD_FILLED_DOCUMENT_ERROR";

export function downloadFilledDocument(signerId) {
    return (dispatch, getState) => {
        dispatch({
            type: DOWNLOAD_FILLED_DOCUMENT_START
        });

        const request = new SignApiReadTypes.DownloadFilledDocumentRequest({
            signerId: signerId
        });
        const auth = getAuthWithAppName(getState().auth);
        signApiReadClient.downloadFilledDocument(auth, request).then(result => {
            if (is(Error, result)) {
                dispatch({
                    type: DOWNLOAD_FILLED_DOCUMENT_ERROR,
                    error: result
                });
            } else {
                dispatch({
                    type: DOWNLOAD_FILLED_DOCUMENT_SUCCESS,
                    payload: result
                });
            }
        });
    };
}

export const GET_SIGNER_DATA_START = "GET_SIGNER_DATA_START";
export const GET_SIGNER_DATA_SUCCESS = "GET_SIGNER_DATA_SUCCESS";
export const GET_SIGNER_DATA_ERROR = "GET_SIGNER_DATA_ERROR";

export function getSignerData(signerId) {
    return (dispatch, getState) => {
        dispatch({
            type: GET_SIGNER_DATA_START
        });

        const request = new SignApiReadTypes.SignerDataRequest({
            signerId: signerId
        });
        const auth = getAuthWithAppName(getState().auth);
        signApiReadClient.getSignerData(auth, request).then(result => {
            if (is(Error, result)) {
                dispatch({
                    type: GET_SIGNER_DATA_ERROR,
                    error: result
                });
            } else {
                dispatch({
                    type: GET_SIGNER_DATA_SUCCESS,
                    payload: result
                });
            }
        });
    };
}

export const LIST_SIGNERS_START = "LIST_SIGNERS_START";
export const LIST_SIGNERS_SUCCESS = "LIST_SIGNERS_SUCCESS";
export const LIST_SIGNERS_ERROR = "LIST_SIGNERS_ERROR";

export function listSigners(fiscalCode, serviceName) {
    return (dispatch, getState) => {
        dispatch({
            type: LIST_SIGNERS_START
        });

        const request = new SignApiReadTypes.ListSignerRequest({
            cfAzienda: fiscalCode,
            serviceName: serviceName
        });

        const auth = getAuthWithAppName(getState().auth);
        callWithRetry(
            signApiReadClient,
            signApiReadClient.listSigners,
            auth,
            request,
            auth.refreshToken,
            dispatch,
            (error, result) => {
                if (error !== null) {
                    dispatch({
                        type: LIST_SIGNERS_ERROR,
                        error: error
                    });
                } else {
                    dispatch({
                        type: LIST_SIGNERS_SUCCESS,
                        payload: result
                    });
                }
            }
        );
    };
}
