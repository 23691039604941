import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LinkListTitle, LinkListPagination, LinkElement } from "../../../components/connections-new";
import { Spinner } from "../../../components/general/spinner";

import { findOwnManagerConnections } from "../../../actions/connections/read";
import { fetchItemServices, resetFetchItemServices } from "../../../actions/services/service-subscription-v2";
import { deleteLink } from "../../../actions/connections/write";
import { certificationInfo } from "../../../actions/certification";
import { listItems } from "../../../actions/registry/read";

import { equals } from "ramda";
import { LinkContainer } from "./styled.js";

class ConnectionsManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            filter: {},
            modalConnectionWarningVisible: false,
            selectedItem: undefined
        };
    }

    static propTypes = {
        agyoServices: PropTypes.array,
        certification: PropTypes.object,
        certificationInfo: PropTypes.func,
        company: PropTypes.object,
        deleteLink: PropTypes.func,
        fetchItemServices: PropTypes.func,
        findOwnManagerConnections: PropTypes.func,
        findOwnManagerConnectionsStatus: PropTypes.object,
        history: PropTypes.object,
        itemServices: PropTypes.object,
        managedConnections: PropTypes.array,
        managedConnectionsTotalElements: PropTypes.number,
        managerConnections: PropTypes.array,
        managerConnectionsTotalElements: PropTypes.number,
        resetFetchItemServices: PropTypes.func.isRequired,
        listItemsResult: PropTypes.array
    };

    componentDidMount() {
        this.fetchAllConnections();
    }

    componentDidUpdate(prevProps) {
        const { company, fetchItemServices, findOwnManagerConnectionsStatus, managerConnections, listItems } =
            this.props;

        let oldManagedOfManagerIds = prevProps.managerConnections.map(v => v.managedId);
        let managedOfManagerIds = managerConnections.map(v => v.managedId);
        let managerOfManagedIds = managerConnections.map(v => v.managerId);

        if (
            findOwnManagerConnectionsStatus.ended &&
            prevProps.findOwnManagerConnectionsStatus.started &&
            !equals(oldManagedOfManagerIds, managedOfManagerIds)
        ) {
            managedOfManagerIds.push(company.base.id);
            fetchItemServices(true, managedOfManagerIds);
            listItems(managerOfManagedIds, 6, 0);
        }
    }

    componentWillUnmount() {
        const { resetFetchItemServices } = this.props;
        resetFetchItemServices();
    }

    fetchAllConnections() {
        const params = {
            page: 0
        };

        const ids = this.getMyCompaniesIds();

        let filterManager = { deleted: false };

        filterManager.managedIds = ids;
        this.setState({ filter: filterManager, selectedItem: undefined });

        this.fetchConnection(filterManager, params);
    }

    getMyCompaniesIds() {
        const { company } = this.props;

        let ids = [];
        if (company !== undefined && company.base !== undefined && company.base.id !== undefined) {
            ids.push(company.base.id);
        }

        return ids;
    }

    fetchConnection = (filter, params) => {
        const { findOwnManagerConnections } = this.props;

        findOwnManagerConnections(filter, params);
    };

    onChangePage = pageNumber => {
        const { filter } = this.state;
        this.setState({ page: pageNumber });

        const params = {
            page: pageNumber
        };

        this.fetchConnection(filter, params);
    };

    onDeleteConnection = id => {
        const { deleteLink } = this.props;

        deleteLink(id);
    };

    render() {
        const {
            agyoServices,
            certification,
            certificationInfo,
            findOwnManagerConnectionsStatus,
            history,
            managerConnections,
            managerConnectionsTotalElements,
            match,
            itemServices,
            listItemsResult
        } = this.props;

        const { page } = this.state;

        let connections = managerConnections;
        let totalConnections = managerConnectionsTotalElements;

        connections = connections
            ? connections.map((o, i) => {
                  const item = listItemsResult
                      ? listItemsResult.find(i => {
                            return i.item.base.uuid === o.managerUuid;
                        })
                      : null;
                  const identifier = item ? item.item.base.identifier : null;
                  const { taxId, vatNumber } = identifier ? identifier : {};
                  return { ...o, taxId, vatNumber };
              })
            : [];

        return (
            <Spinner loading={findOwnManagerConnectionsStatus.started}>
                <LinkContainer>
                    <LinkListTitle />
                    <div>
                        {connections.map((link, i) => (
                            <LinkElement
                                agyoServices={agyoServices}
                                certification={certification}
                                description={link.managerDescription}
                                displayAddConnection={false}
                                getCertification={id => certificationInfo(id)}
                                history={history}
                                itemId={match.params.item}
                                itemServices={itemServices}
                                link={link}
                                key={"list-item" + i}
                                onDeleteConnection={this.onDeleteConnection}
                                settingsPageEnabled={false}
                                statusLabel={""}
                                id={link.managerId}
                                vatNumber={link.vatNumber}
                                taxId={link.taxId}
                            />
                        ))}
                    </div>
                    <LinkListPagination onChange={this.onChangePage} page={page} totalLinks={totalConnections} />
                </LinkContainer>
            </Spinner>
        );
    }
}

const mapStateToProps = (state, props) => ({
    agyoServices: state.services.agyoServices.services,
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    //GESTORI
    managerConnections: state.connectionsNew.read.findOwnManagerConnections.content,
    managerConnectionsTotalElements: state.connectionsNew.read.findOwnManagerConnections.totalElements,
    findOwnManagerConnectionsStatus: state.connectionsNew.read.findOwnManagerConnections.status,
    itemServices: state.services.itemServices,
    listItemsResult: state.registry.read.listItems.items || [],
    //CERTIFICATI
    certification: state.certification
});

export default connect(mapStateToProps, {
    certificationInfo,
    deleteLink,
    fetchItemServices,
    findOwnManagerConnections,
    resetFetchItemServices,
    listItems
})(ConnectionsManager);
