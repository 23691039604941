import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import moment from "moment";

import { Popover } from "antd";
import { Button, Modal } from "@ts-digital/vrc";
import LoadingButton from "../../general/loading-button";
import { isItemAdmin } from "../../../utils/get-roles";
import { PopupContent } from "./popover-content";

import { StyledPopOverTitle, ModalTextContainer, ModalButtonsContainer, AddTextDiv } from "./styled";

const WaitingListButton = ({
    appName,
    buttonKind,
    companyId,
    deleteWaitinglist,
    getWaitingList,
    getWaitingListStatus,
    waitingListed,
    waitingListedCreatedBy,
    waitingListedCreatedAt,
    subscribeWaitinglist,
    waitinglistSubscribeStatus,
    waitinglistDeleteStatus,
    buttonSize,
    user
}) => {
    const [visible, setVisible] = useState(false);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [popupType, setPopupType] = useState("subscribe");

    useEffect(() => {
        getWaitingList(companyId);
    }, [companyId, getWaitingList]);

    useEffect(() => {
        if (waitinglistSubscribeStatus.ended && !waitinglistSubscribeStatus.error) {
            getWaitingList(companyId);
            setVisible(false);
        }
    }, [waitinglistSubscribeStatus.ended, waitinglistSubscribeStatus.error, companyId, getWaitingList]);

    useEffect(() => {
        if (waitinglistDeleteStatus.ended && !waitinglistDeleteStatus.error) {
            getWaitingList(companyId);
            setVisible(false);
        }
    }, [waitinglistDeleteStatus.ended, waitinglistDeleteStatus.error, companyId, getWaitingList]);

    const handleSubscribeWaitlist = (id, serviceId) => {
        subscribeWaitinglist(id, serviceId);
    };

    const handleUnsubscribeWaitlist = (id, serviceId) => {
        deleteWaitinglist(id, serviceId);
    };

    const handleOpenPopup = type => {
        setPopupType(type);
        setVisible(true);
    };

    let buttonDiv = <></>;

    if (isItemAdmin(companyId, user)) {
        buttonDiv = waitingListed ? (
            <Popover
                title={
                    <StyledPopOverTitle>
                        <FormattedMessage id="general.waitinglist.manage-request.maintitle" />
                    </StyledPopOverTitle>
                }
                content={
                    <PopupContent
                        created_at={moment(waitingListedCreatedAt).format("DD MMMM YYYY - HH:mm")}
                        created_by={waitingListedCreatedBy}
                        handleUnsubscribe={() => {
                            handleOpenPopup("unsubscribe");
                        }}
                        user={user}
                        companyId={companyId}
                    />
                }
                placement="top"
                arrowPointAtCenter
                visible={popoverVisible}
                onVisibleChange={setPopoverVisible}
                overlayClassName="waiting-list-popver"
            >
                <Button kind={buttonKind}>
                    <FormattedMessage id="general.waitinglist.manage" />
                </Button>
            </Popover>
        ) : (
            <LoadingButton
                onClick={() => handleOpenPopup("subscribe")}
                variant={buttonKind}
                kind={buttonKind}
                size={buttonSize}
                loading={getWaitingListStatus && getWaitingListStatus.started}
            >
                {getWaitingListStatus && getWaitingListStatus.started ? (
                    <FormattedMessage id="general.service.loading" />
                ) : (
                    <AddTextDiv>
                        <FormattedMessage id="general.waitinglist.add" />
                    </AddTextDiv>
                )}
            </LoadingButton>
        );
    }

    return (
        <>
            <Modal
                visible={visible}
                title={
                    popupType === "subscribe" ? (
                        <FormattedMessage id="general.waitinglist.confirm-request" />
                    ) : (
                        <FormattedMessage id="general.waitinglist.confirm-request-remove" />
                    )
                }
                onClose={() => setVisible(false)}
                type="primary"
                footer={
                    <ModalButtonsContainer>
                        <Button kind="secondary" onClick={() => setVisible(false)} size="small">
                            <FormattedMessage id="general.annulla" />
                        </Button>
                        <LoadingButton
                            onClick={() => {
                                popupType === "subscribe"
                                    ? handleSubscribeWaitlist(companyId, appName)
                                    : handleUnsubscribeWaitlist(companyId, appName);
                            }}
                            size="small"
                            loading={waitinglistSubscribeStatus.started || waitinglistDeleteStatus.started}
                        >
                            <FormattedMessage id="general.confirm" />
                        </LoadingButton>
                    </ModalButtonsContainer>
                }
            >
                <ModalTextContainer>
                    {popupType === "subscribe" ? (
                        <FormattedMessage id="general.waitinglist.modal-text" />
                    ) : (
                        <FormattedMessage id="general.waitinglist.modal-text-remove" />
                    )}
                </ModalTextContainer>
            </Modal>
            {buttonDiv}
        </>
    );
};

WaitingListButton.propTypes = {
    companyId: PropTypes.string.isRequired,
    getWaitingList: PropTypes.func,
    waitinglist: PropTypes.array,
    subscribeWaitinglist: PropTypes.func,
    waitinglistSubscribeStatus: PropTypes.object,
    waitinglistDeleteStatus: PropTypes.object,
    waitingListed: PropTypes.bool,
    waitingListedCreatedBy: PropTypes.string,
    waitingListedCreatedAt: PropTypes.string,
    buttonSize: PropTypes.string,
    appName: PropTypes.string,
    buttonKind: PropTypes.string,
    deleteWaitinglist: PropTypes.func,
    user: PropTypes.object
};

WaitingListButton.defaultProps = {
    buttonKind: "primary"
};

export default WaitingListButton;
