import { API_URL } from "../../config";
import { getAuthWithAppName } from "../auth.js";
import { getRestCall, postRestCall } from "../../utils/rest-api-call";
import { getUserProfile } from "../../utils/state-utils/get-user-profile";

export const GET_TSPAY_CONFIG_START = "GET_TSPAY_CONFIG_START";
export const GET_TSPAY_CONFIG_SUCCESS = "GET_TSPAY_CONFIG_SUCCESS";
export const GET_TSPAY_CONFIG_ERROR = "GET_TSPAY_CONFIG_ERROR";

export function getConfig(itemId) {
    return async (dispatch, getState) => {
        dispatch({
            type: GET_TSPAY_CONFIG_START
        });

        let authObj = getAuthWithAppName(getState().auth);
        authObj.accessToken = authObj.securityToken;

        try {
            const result = await getRestCall(
                `${API_URL}/services/tspay/${itemId}`,
                authObj,
                null,
                dispatch,
                authObj.refreshToken,
                getUserProfile(getState()),
                itemId
            );

            dispatch({
                type: GET_TSPAY_CONFIG_SUCCESS,
                payload: result
            });
        } catch (err) {
            dispatch({
                type: GET_TSPAY_CONFIG_ERROR,
                error: { code: err.code, message: err.message },
                payload: { itemId }
            });
        }
    };
}

export const TSPAY_RESEND_CONFIRM_START = "TSPAY_RESEND_CONFIRM_START";
export const TSPAY_RESEND_CONFIRM_SUCCESS = "TSPAY_RESEND_CONFIRM_SUCCESS";
export const TSPAY_RESEND_CONFIRM_ERROR = "TSPAY_RESEND_CONFIRM_ERROR";
export const TSPAY_RESEND_CONFIRM_RESET = "TSPAY_RESEND_CONFIRM_RESET";

export function tspayResendConfirmEmail(itemId) {
    return async (dispatch, getState) => {
        dispatch({
            type: TSPAY_RESEND_CONFIRM_START
        });

        let authObj = getAuthWithAppName(getState().auth);
        authObj.accessToken = authObj.securityToken;

        try {
            const result = await postRestCall(
                `${API_URL}/services/tspay/resend/${itemId}`,
                authObj,
                null,
                dispatch,
                authObj.refreshToken,
                getUserProfile(getState()),
                itemId
            );

            dispatch({
                type: TSPAY_RESEND_CONFIRM_SUCCESS,
                payload: result
            });
        } catch (err) {
            dispatch({
                type: TSPAY_RESEND_CONFIRM_ERROR,
                error: { code: err.code, message: err.message },
                payload: { itemId }
            });
        }
    };
}

export function resetTsPayResend() {
    return dispatch => {
        dispatch({
            type: TSPAY_RESEND_CONFIRM_RESET
        });
    };
}

export const TSPAY_ONBOARDING_START = "TSPAY_ONBOARDING_START";
export const TSPAY_ONBOARDING_SUCCESS = "TSPAY_ONBOARDING_SUCCESS";
export const TSPAY_ONBOARDING_ERROR = "TSPAY_ONBOARDING_ERROR";
export const TSPAY_ONBOARDING_RESET = "TSPAY_ONBOARDING_RESET";

export function tspayOnboarding(itemId) {
    return async (dispatch, getState) => {
        dispatch({
            type: TSPAY_ONBOARDING_START
        });

        let authObj = getAuthWithAppName(getState().auth);
        authObj.accessToken = authObj.securityToken;

        try {
            const result = await postRestCall(
                `${API_URL}/services/tspay/onboarding/${itemId}`,
                authObj,
                null,
                dispatch,
                authObj.refreshToken,
                getUserProfile(getState()),
                itemId
            );

            dispatch({
                type: TSPAY_ONBOARDING_SUCCESS,
                payload: result
            });
        } catch (err) {
            dispatch({
                type: TSPAY_ONBOARDING_ERROR,
                error: { code: err.code, message: err.message },
                payload: { itemId }
            });
        }
    };
}

export function resetTsPayOnboarding() {
    return dispatch => {
        dispatch({
            type: TSPAY_ONBOARDING_RESET
        });
    };
}

export const TS_PAY_INFO = "TS_PAY_INFO";

export function tsPayInfo(redirectFun) {
    return {
        type: TS_PAY_INFO,
        payload: { redirectFun: redirectFun }
    };
}
