import React from "react";
import PropTypes from "prop-types";

import { Spin } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import InfiniteScroll from "react-infinite-scroller";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Button } from "@ts-digital/vrc";

import Notification from "../notification/compact-notification";

import "./style.css";
import DateTime from "../datetime";

import { NotificationsContainer, ButtonContainer, EmptyNotification } from "./styled";

const NotificationList = ({
    displayListButton,
    fullWidth,
    hasMoreNotifications,
    isLoading,
    notifications,
    onLoadNotifications,
    onNotificationAccept,
    onNotificationReject,
    onReadNotification,
    onUnreadNotification
}) => {
    const { formatMessage } = useIntl();
    const params = useParams();

    let displayedNotifications = notifications;
    if (!fullWidth) {
        displayedNotifications = notifications.slice(0, 4);
    }

    return (
        <NotificationsContainer>
            <InfiniteScroll
                hasMore={hasMoreNotifications}
                initialLoad={false}
                loader={<Spin key="loader" spinning={true} />}
                loadMore={page => {
                    onLoadNotifications(page);
                }}
                className="c-notification-list"
                useWindow={false}
                threshold={500}
            >
                <Spin spinning={isLoading}>
                    {notifications.length > 0 ? (
                        displayedNotifications.map((notification, index) => (
                            <React.Fragment key={index}>
                                <Notification
                                    accepted={notification.accepted}
                                    fullWidth={fullWidth}
                                    id={notification.id}
                                    date={<DateTime value={notification.createdAt} />}
                                    onClick={() => {
                                        if (
                                            !notification.readStatus &&
                                            (notification.type !== "REQUEST" ||
                                                (notification.type === "REQUEST" && notification.accepted) ||
                                                notification.rejected)
                                        ) {
                                            onReadNotification(notification.id);
                                        }
                                    }}
                                    onAccept={() => onNotificationAccept(notification.id)}
                                    onReject={() => onNotificationReject(notification.id)}
                                    onChangeReadStatus={(needsInteraction, read) => {
                                        if (!needsInteraction && !read) {
                                            onReadNotification(notification.id);
                                        } else if (!needsInteraction) {
                                            onUnreadNotification(notification.id);
                                        }
                                    }}
                                    type={notification.type}
                                    read={notification.readStatus}
                                    rejected={notification.rejected}
                                    notification={notification}
                                >
                                    {formatMessage(
                                        {
                                            id: `notification.${notification.name}`
                                        },
                                        {
                                            sender: notification.requesterName,
                                            senderId: notification.requesterId,
                                            recipient: notification.recipientName,
                                            recipientId: notification.recipientId,
                                            ...Object.entries(notification.extraValues).reduce((acc, v) => {
                                                acc[v[0]] = formatMessage({
                                                    id: v[1]
                                                });
                                                return acc;
                                            }, {})
                                        }
                                    )}
                                </Notification>
                            </React.Fragment>
                        ))
                    ) : (
                        <EmptyNotification>
                            <FormattedMessage id="c-notification-list.empty-list" />
                        </EmptyNotification>
                    )}
                </Spin>
                {displayListButton && (
                    <ButtonContainer>
                        <Link to={`/${params.item}/notifications`}>
                            <Button kind="primary">
                                <FormattedMessage id="c-notification-list.list-button" />
                            </Button>
                        </Link>
                    </ButtonContainer>
                )}
            </InfiniteScroll>
        </NotificationsContainer>
    );
};

NotificationList.propTypes = {
    displayListButton: PropTypes.bool,
    fullWidth: PropTypes.bool,
    notifications: PropTypes.array,
    onReadNotification: PropTypes.func,
    onNotificationAccept: PropTypes.func,
    onNotificationReject: PropTypes.func,
    onUnreadNotification: PropTypes.func,
    onLoadNotifications: PropTypes.func
};

NotificationList.defaultProps = {
    displayListButton: true,
    fullWidth: false,
    hasMoreNotifications: false,
    notifications: [],
    onLoadNotifications: page => {}
};

export default NotificationList;
