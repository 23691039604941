import styled from "styled-components";

export const IconContainer = styled.span`
    &,
    &._elevio_underline.ctxUnderline {
        margin-left: 16px;
        font-size: 16px;
        color: #0090d1;
        border-bottom: none;
        cursor: pointer;
    }
`;
