import React from "react";
import PropTypes from "prop-types";

import { SelectedItemsCell, TotalItemsCell } from "./styled";
import { FormattedMessage } from "react-intl";

const TableFooter = ({ selectedCount, totalItems }) => (
    <>
        <SelectedItemsCell data-cy="selected-items-total-container">
            <b>
                <FormattedMessage
                    id="v-user-creation-flow.steps.set-permissions.selected-modal.table-companies"
                    values={{ count: selectedCount }}
                />
            </b>
        </SelectedItemsCell>
        <TotalItemsCell colSpan={2} data-cy="items-total-container">
            <b>
                <FormattedMessage
                    id="v-user-creation-flow.steps.set-permissions.selected-modal.table-companies-total"
                    values={{ count: totalItems }}
                />
            </b>
        </TotalItemsCell>
    </>
);

TableFooter.propTypes = {
    selectedCount: PropTypes.number,
    totalItems: PropTypes.number
};

TableFooter.defaultProps = {
    selectedCount: 0,
    totalItems: 0
};

export default TableFooter;
