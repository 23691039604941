import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, Button } from "antd";
import { FormattedHTMLMessage, injectIntl } from "react-intl";

class ExpenseReportsForm extends Component {
    static propTypes = {
        activateAppNoteSpese: PropTypes.func.isRequired,
        config: PropTypes.object.isRequired,
        handleClose: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        const { config } = this.props;
        this.state = {
            config: config
        };
    }

    onChange(event, valueToUptade) {
        this.setState({
            [valueToUptade]: event.target.value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { activateAppNoteSpese, handleClose } = this.props;
        const { config } = this.state;
        let extraData = config;
        activateAppNoteSpese(extraData);
        handleClose();
    }

    renderForm() {
        const FormItem = Form.Item;
        const { config, intl } = this.props;
        return (
            <div>
                <span>
                    <FormattedHTMLMessage id="c-expense-report.config-text" />
                </span>
                <br />
                <br />
                <Form onSubmit={e => this.handleSubmit(e)}>
                    <FormItem style={{ marginBottom: "0px" }}>
                        <Input
                            placeholder={intl.formatMessage({
                                id: "general.ragione-sociale"
                            })}
                            defaultValue={config.description}
                            readOnly="true"
                        />
                        <Input
                            placeholder={intl.formatMessage({
                                id: "general.company-fiscal-code"
                            })}
                            defaultValue={config.taxId}
                            readOnly="true"
                        />
                        <Input
                            placeholder={intl.formatMessage({
                                id: "general.zipCode"
                            })}
                            defaultValue={config.zipCode}
                            readOnly="true"
                            style={{ width: "12%" }}
                        />
                        <Input
                            placeholder={intl.formatMessage({
                                id: "general.city"
                            })}
                            defaultValue={config.city}
                            readOnly="true"
                            style={{ width: "62%", marginLeft: "11px" }}
                        />
                        <Input
                            placeholder={intl.formatMessage({
                                id: "general.province"
                            })}
                            defaultValue={config.province}
                            readOnly="true"
                            style={{ width: "10%", marginLeft: "11px" }}
                        />
                        <Input
                            placeholder={intl.formatMessage({
                                id: "general.country"
                            })}
                            defaultValue={config.country}
                            readOnly="true"
                            style={{ width: "10%", marginLeft: "11px" }}
                        />
                        <Input
                            placeholder={intl.formatMessage({
                                id: "general.address"
                            })}
                            defaultValue={config.street}
                            readOnly="true"
                        />
                        <Input
                            placeholder={intl.formatMessage({
                                id: "general.name"
                            })}
                            defaultValue={config.firstName}
                            readOnly="true"
                            style={{ width: "49%" }}
                        />
                        <Input
                            placeholder={intl.formatMessage({
                                id: "general.lastname"
                            })}
                            defaultValue={config.lastName}
                            readOnly="true"
                            style={{ width: "49%", marginLeft: "11px" }}
                        />
                        <Input
                            placeholder={intl.formatMessage({
                                id: "general.email"
                            })}
                            defaultValue={config.id}
                            readOnly="true"
                        />
                    </FormItem>
                    <div style={{ padding: "20px 0px" }}>
                        <Button htmlType="submit" style={{ float: "right" }}>
                            <FormattedHTMLMessage id="c-expense-report.send-email" />
                        </Button>
                    </div>
                </Form>
            </div>
        );
    }

    render() {
        return <div>{this.renderForm()}</div>;
    }
}

export default injectIntl(ExpenseReportsForm);
