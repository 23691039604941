import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { Layout } from "antd";
import { connect } from "react-redux";
import { compose } from "redux";

import { logout, updateUserPassword } from "../../actions/auth";
import { editUser } from "../../actions/company/users";
import { loadUser } from "../../actions/user";
import { getNotificationsList, getNotificationsStatus } from "../../actions/ncs/read";
import {
    deleteSingleNotification,
    deleteNotificationsList,
    updateSingleNotification,
    updateNotificationsList
} from "../../actions/ncs/write";

import NotificationsCenterView from "../notifications-center";
import LayoutHeaderComponent from "../../components/manage-data/user/new-user-modal/layout-header-component";
import { errorBoundary } from "../../components/general/error-boundary";
import { copyTokenToast } from "../../actions/toastNotifications";

const MultiCompanyNotificationsCenterView = ({
    auth,
    editUser,
    notificationsExist,
    notificationsList,
    notificationsListStatus,
    deleteSingleNotification,
    deleteNotificationsList,
    getNotificationsList,
    getNotificationsStatus,
    updateSingleNotification,
    updateNotificationsList,
    location,
    logout,
    loadUser,
    updateUserPassword,
    updateUserPswStatus,
    user,
    userEditStatus
}) => {
    const getNcsNotificationsList = useCallback(() => {
        if (user.user) {
            getNotificationsList(user.user.profile.ncsId);
        }
    }, [getNotificationsList, user]);

    const getNcsNotificationsExist = useCallback(() => {
        if (user.user) {
            getNotificationsStatus(user.user.profile.ncsId);
        }
    }, [getNotificationsStatus, user]);

    return (
        <>
            <LayoutHeaderComponent
                auth={auth}
                user={user}
                logout={logout}
                display={true}
                editUser={editUser}
                loadUser={loadUser}
                notificationsList={notificationsList}
                notificationsListStatus={notificationsListStatus}
                notificationsExist={notificationsExist}
                getNotifications={getNcsNotificationsList}
                getNotificationsExist={getNcsNotificationsExist}
                updateNotificationsList={request => updateNotificationsList(user.user.profile.ncsId, request)}
                updateSingleNotification={(notificationId, request) =>
                    updateSingleNotification(user.user.profile.ncsId, notificationId, request)
                }
                deleteSingleNotification={notificationId =>
                    deleteSingleNotification(user.user.profile.ncsId, notificationId)
                }
                deleteNotificationsList={() => deleteNotificationsList(user.user.profile.ncsId)}
                updateUserPsw={updateUserPassword}
                updateUserPswStatus={updateUserPswStatus}
                userEditStatus={userEditStatus}
                copyTokenToast={copyTokenToast}
            />
            <Layout.Content className="Root-content">
                <NotificationsCenterView location={location} />
            </Layout.Content>
        </>
    );
};

MultiCompanyNotificationsCenterView.propTypes = {
    auth: PropTypes.object,
    deleteSingleNotification: PropTypes.func.isRequired,
    deleteNotificationsList: PropTypes.func.isRequired,
    getNotificationsList: PropTypes.func.isRequired,
    getNotificationsStatus: PropTypes.func.isRequired,
    updateSingleNotification: PropTypes.func.isRequired,
    updateNotificationsList: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    notificationsExist: PropTypes.bool.isRequired,
    notificationsList: PropTypes.array,
    notificationsListStatus: PropTypes.object,
    user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        notificationsExist: state.ncs.notifications.exist,
        notificationsList: state.ncs.notifications.data,
        notificationsListStatus: state.ncs.notifications.status,
        user: state.user,
        updateUserPswStatus: state.password.update.status || {},
        userEditStatus: state.manageUser.editUser.status || {}
    };
}

const mapDispatchToProps = {
    logout,
    editUser,
    deleteSingleNotification,
    deleteNotificationsList,
    getNotificationsList,
    getNotificationsStatus,
    updateSingleNotification,
    updateNotificationsList,
    loadUser,
    updateUserPassword,
    copyTokenToast
};

const composedHoc = compose(errorBoundary, connect(mapStateToProps, mapDispatchToProps));

export default composedHoc(MultiCompanyNotificationsCenterView);
