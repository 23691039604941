import styled from "styled-components";

import { TS_DIGITAL_IMAGES } from "../../../../config";

export const PageContainer = styled.div`
    background-color: ${props => props.theme.colors.background.white};
    padding-top: 24px;
    min-height: 80vh;

    display: flex;
    flex-direction: column;

    @media (min-width: 1280px) {
        background-image: url(${TS_DIGITAL_IMAGES}provisioning-aside.svg);
        background-repeat: no-repeat;
        background-position: right top;
    }

    @media (min-width: 1200px) {
        padding-top: 24px;
    }
`;
