import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { compose } from "redux";

import { listEditableUsers } from "../../../actions/company/editableUsers";

import UsersTech from "../../../components/registry/users-tech";
import { errorBoundary } from "../../../components/general/error-boundary";
import "../style.css";
import PageTitle from "../../../components/general/page-title";

class ApiKeysView extends React.Component {
    static propTypes = {
        company: PropTypes.object.isRequired,
        listEditableUsers: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        companies: PropTypes.array
    };

    render() {
        const { company, listEditableUsers, resetUser, user, companies } = this.props;
        const editableUsers = companies.data[company.base.uuid].editableUsers;
        return (
            <div className="registry-view">
                <PageTitle
                    title={<FormattedMessage id="general.applications" />}
                    onBack={() => this.props.history.goBack()}
                    subtitle={<FormattedMessage id="c-registry-view-api-key" />}
                />
                <UsersTech
                    company={company}
                    listEditableUsers={listEditableUsers}
                    resetUser={resetUser}
                    user={user.user}
                    users={editableUsers}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    company: state.company,
    user: state.user,
    companies: state.companies
});

const actions = {
    listEditableUsers
};

const composedHoc = compose(connect(mapStateToProps, actions), errorBoundary);

export default composedHoc(ApiKeysView);
