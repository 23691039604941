import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import ContractUpload from "../../../../../components/validate-company/contract-upload";

import { SubtitleContainer, SelectDocumentLabelContainer, DocumentSizeInfoMessage, ModalBodyContainer } from "./styled";

export const UploadPdf = ({ isLoading, fileTypeError, selectedFile, handleSelectFile }) => {
    return (
        <ModalBodyContainer>
            <SubtitleContainer>
                <FormattedMessage id="c-cct-provisioning-upload-pdf-form.subtitle" />
            </SubtitleContainer>
            <div>
                <SelectDocumentLabelContainer>
                    <FormattedMessage id="general.select-file" />
                </SelectDocumentLabelContainer>
                <DocumentSizeInfoMessage>
                    <FormattedMessage id="c-cct-provisioning-upload-pdf-form.file-size-info" />
                </DocumentSizeInfoMessage>
            </div>
            <ContractUpload
                alignment="center"
                disabled={isLoading}
                error={fileTypeError}
                onFileChanged={handleSelectFile}
                selectedFiles={selectedFile && [selectedFile]}
                uploadButtonMessage="c-cct-provisioning-upload-pdf-form.selectfile"
            />
        </ModalBodyContainer>
    );
};

UploadPdf.propTypes = {
    handleSelectFile: PropTypes.func,
    selectedFile: PropTypes.object,
    fileTypeError: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired
};

export default UploadPdf;
