import React from "react";
import { PropTypes } from "prop-types";
import { Tooltip } from "antd";
import { MeteringSegment } from "./styled";

export const Segment = ({ className, percent, tooltipPlacement, tooltipText, variant }) => (
    <Tooltip placement={tooltipPlacement} title={tooltipText}>
        <MeteringSegment className={className} percent={percent} variant={variant} />
    </Tooltip>
);

Segment.propTypes = {
    className: PropTypes.string,
    tooltipPlacement: PropTypes.string,
    percent: PropTypes.number,
    tooltipText: PropTypes.node,
    variant: PropTypes.oneOf(["primary", "secondary", "error"])
};

Segment.defaultProps = {
    variant: "primary",
    tooltipPlacement: "top"
};
