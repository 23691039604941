import styled from "styled-components";

export const ContractDataFormContainer = styled.form`
    display: grid;
    grid-template-columns: 100%;
    justify-items: left;
    margin: 0px 110px 0px 70px;

    @media screen and (min-width: 1024px) {
        grid-template-columns: 50% 50%;
    }

    @media screen and (min-width: 1458px) {
        grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
    }

    > div {
        width: 75%;
    }
`;

export const ConsentDataContainer = styled.div`
    margin: 0px 110px 0px 70px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0px 110px 0px 70px;
`;
