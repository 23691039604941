import styled from "styled-components";
import { Select } from "@ts-digital/vrc";

export const OverallPermissionsSectionTitle = styled.div`
    color: rgb(0, 80, 117);
    font-size: 20px;
    font-family: Cairo;
    font-weight: normal;
    letter-spacing: 0.15px;
    line-height: 24px;
    margin-top: 48px;
    margin-bottom: 24px;
`;

export const OverallPermissionsSectionSubtitle = styled.div`
    color: rgb(21, 41, 53);
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 16px;
`;

export const OverallPermissionsSelectAndButtonContainer = styled.div`
    display: flex;
    grid-gap: 16px;
`;

export const OverallPermissionsSectionSelect = styled(Select)`
    max-width: 300px;
    width: 100%;
    flex-shrink: 0;
`;

export const CollapseContainer = styled.div`
    margin-top: 40px;
    margin-bottom: 40px;
`;
