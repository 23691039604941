/* eslint-disable */
//
// Autogenerated by Thrift Compiler (0.9.3)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
var thrift = require('browser-thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;

var apiCommon_ttypes = require('./apiCommon_types')
var apiAuth_ttypes = require('./apiAuth_types')


var ttypes = require('./apiWrite_types');
//HELPER FUNCTIONS AND STRUCTURES

SigninApiWrite_health_args = function(args) {
};
SigninApiWrite_health_args.prototype = {};
SigninApiWrite_health_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiWrite_health_args.prototype.write = function(output) {
  output.writeStructBegin('SigninApiWrite_health_args');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiWrite_health_result = function(args) {
  this.success = null;
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
  }
};
SigninApiWrite_health_result.prototype = {};
SigninApiWrite_health_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRING) {
        this.success = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiWrite_health_result.prototype.write = function(output) {
  output.writeStructBegin('SigninApiWrite_health_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRING, 0);
    output.writeString(this.success);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiWrite_preRegister_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new apiAuth_ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.PreRegistrationRequest(args.request);
    }
  }
};
SigninApiWrite_preRegister_args.prototype = {};
SigninApiWrite_preRegister_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new apiAuth_ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.PreRegistrationRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiWrite_preRegister_args.prototype.write = function(output) {
  output.writeStructBegin('SigninApiWrite_preRegister_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiWrite_preRegister_result = function(args) {
  this.success = null;
  this.validationException = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof apiCommon_ttypes.ValidationException) {
    this.validationException = args;
    return;
  }
  if (args instanceof apiCommon_ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof apiAuth_ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.PreRegistrationResponse(args.success);
    }
    if (args.validationException !== undefined && args.validationException !== null) {
      this.validationException = args.validationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
SigninApiWrite_preRegister_result.prototype = {};
SigninApiWrite_preRegister_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.PreRegistrationResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.validationException = new apiCommon_ttypes.ValidationException();
        this.validationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new apiCommon_ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new apiAuth_ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiWrite_preRegister_result.prototype.write = function(output) {
  output.writeStructBegin('SigninApiWrite_preRegister_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.validationException !== null && this.validationException !== undefined) {
    output.writeFieldBegin('validationException', Thrift.Type.STRUCT, 1);
    this.validationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 2);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 3);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiWrite_createPdf_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new apiAuth_ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.CreatePdfRequest(args.request);
    }
  }
};
SigninApiWrite_createPdf_args.prototype = {};
SigninApiWrite_createPdf_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new apiAuth_ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.CreatePdfRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiWrite_createPdf_args.prototype.write = function(output) {
  output.writeStructBegin('SigninApiWrite_createPdf_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiWrite_createPdf_result = function(args) {
  this.success = null;
  this.validationException = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof apiCommon_ttypes.ValidationException) {
    this.validationException = args;
    return;
  }
  if (args instanceof apiCommon_ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof apiAuth_ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.CreatePdfResponse(args.success);
    }
    if (args.validationException !== undefined && args.validationException !== null) {
      this.validationException = args.validationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
SigninApiWrite_createPdf_result.prototype = {};
SigninApiWrite_createPdf_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.CreatePdfResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.validationException = new apiCommon_ttypes.ValidationException();
        this.validationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new apiCommon_ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new apiAuth_ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiWrite_createPdf_result.prototype.write = function(output) {
  output.writeStructBegin('SigninApiWrite_createPdf_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.validationException !== null && this.validationException !== undefined) {
    output.writeFieldBegin('validationException', Thrift.Type.STRUCT, 1);
    this.validationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 2);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 3);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiWrite_uploadSignedPdf_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new apiAuth_ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.UploadSignedPdfRequest(args.request);
    }
  }
};
SigninApiWrite_uploadSignedPdf_args.prototype = {};
SigninApiWrite_uploadSignedPdf_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new apiAuth_ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.UploadSignedPdfRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiWrite_uploadSignedPdf_args.prototype.write = function(output) {
  output.writeStructBegin('SigninApiWrite_uploadSignedPdf_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiWrite_uploadSignedPdf_result = function(args) {
  this.success = null;
  this.validationException = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof apiCommon_ttypes.ValidationException) {
    this.validationException = args;
    return;
  }
  if (args instanceof apiCommon_ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof apiAuth_ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.UploadSignedPdfResponse(args.success);
    }
    if (args.validationException !== undefined && args.validationException !== null) {
      this.validationException = args.validationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
SigninApiWrite_uploadSignedPdf_result.prototype = {};
SigninApiWrite_uploadSignedPdf_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.UploadSignedPdfResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.validationException = new apiCommon_ttypes.ValidationException();
        this.validationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new apiCommon_ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new apiAuth_ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiWrite_uploadSignedPdf_result.prototype.write = function(output) {
  output.writeStructBegin('SigninApiWrite_uploadSignedPdf_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.validationException !== null && this.validationException !== undefined) {
    output.writeFieldBegin('validationException', Thrift.Type.STRUCT, 1);
    this.validationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 2);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 3);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiWrite_assignSigner_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new apiAuth_ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.AssignSignerRequest(args.request);
    }
  }
};
SigninApiWrite_assignSigner_args.prototype = {};
SigninApiWrite_assignSigner_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new apiAuth_ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.AssignSignerRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiWrite_assignSigner_args.prototype.write = function(output) {
  output.writeStructBegin('SigninApiWrite_assignSigner_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiWrite_assignSigner_result = function(args) {
  this.success = null;
  this.validationException = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof apiCommon_ttypes.ValidationException) {
    this.validationException = args;
    return;
  }
  if (args instanceof apiCommon_ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof apiAuth_ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.AssignSignerResponse(args.success);
    }
    if (args.validationException !== undefined && args.validationException !== null) {
      this.validationException = args.validationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
SigninApiWrite_assignSigner_result.prototype = {};
SigninApiWrite_assignSigner_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.AssignSignerResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.validationException = new apiCommon_ttypes.ValidationException();
        this.validationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new apiCommon_ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new apiAuth_ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiWrite_assignSigner_result.prototype.write = function(output) {
  output.writeStructBegin('SigninApiWrite_assignSigner_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.validationException !== null && this.validationException !== undefined) {
    output.writeFieldBegin('validationException', Thrift.Type.STRUCT, 1);
    this.validationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 2);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 3);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiWrite_resetPin_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new apiAuth_ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.ResetPinRequest(args.request);
    }
  }
};
SigninApiWrite_resetPin_args.prototype = {};
SigninApiWrite_resetPin_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new apiAuth_ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.ResetPinRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiWrite_resetPin_args.prototype.write = function(output) {
  output.writeStructBegin('SigninApiWrite_resetPin_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiWrite_resetPin_result = function(args) {
  this.success = null;
  this.validationException = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof apiCommon_ttypes.ValidationException) {
    this.validationException = args;
    return;
  }
  if (args instanceof apiCommon_ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof apiAuth_ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.ResetPinResponse(args.success);
    }
    if (args.validationException !== undefined && args.validationException !== null) {
      this.validationException = args.validationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
SigninApiWrite_resetPin_result.prototype = {};
SigninApiWrite_resetPin_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.ResetPinResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.validationException = new apiCommon_ttypes.ValidationException();
        this.validationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new apiCommon_ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new apiAuth_ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiWrite_resetPin_result.prototype.write = function(output) {
  output.writeStructBegin('SigninApiWrite_resetPin_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.validationException !== null && this.validationException !== undefined) {
    output.writeFieldBegin('validationException', Thrift.Type.STRUCT, 1);
    this.validationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 2);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 3);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiWrite_withdraw_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new apiAuth_ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.WithdrawRequest(args.request);
    }
  }
};
SigninApiWrite_withdraw_args.prototype = {};
SigninApiWrite_withdraw_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new apiAuth_ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.WithdrawRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiWrite_withdraw_args.prototype.write = function(output) {
  output.writeStructBegin('SigninApiWrite_withdraw_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiWrite_withdraw_result = function(args) {
  this.success = null;
  this.validationException = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof apiCommon_ttypes.ValidationException) {
    this.validationException = args;
    return;
  }
  if (args instanceof apiCommon_ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof apiAuth_ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.WithdrawResponse(args.success);
    }
    if (args.validationException !== undefined && args.validationException !== null) {
      this.validationException = args.validationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
SigninApiWrite_withdraw_result.prototype = {};
SigninApiWrite_withdraw_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.WithdrawResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.validationException = new apiCommon_ttypes.ValidationException();
        this.validationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new apiCommon_ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new apiAuth_ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiWrite_withdraw_result.prototype.write = function(output) {
  output.writeStructBegin('SigninApiWrite_withdraw_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.validationException !== null && this.validationException !== undefined) {
    output.writeFieldBegin('validationException', Thrift.Type.STRUCT, 1);
    this.validationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 2);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 3);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiWrite_cancel_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new apiAuth_ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.CancelRequest(args.request);
    }
  }
};
SigninApiWrite_cancel_args.prototype = {};
SigninApiWrite_cancel_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new apiAuth_ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.CancelRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiWrite_cancel_args.prototype.write = function(output) {
  output.writeStructBegin('SigninApiWrite_cancel_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiWrite_cancel_result = function(args) {
  this.success = null;
  this.validationException = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof apiCommon_ttypes.ValidationException) {
    this.validationException = args;
    return;
  }
  if (args instanceof apiCommon_ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof apiAuth_ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.CancelResponse(args.success);
    }
    if (args.validationException !== undefined && args.validationException !== null) {
      this.validationException = args.validationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
SigninApiWrite_cancel_result.prototype = {};
SigninApiWrite_cancel_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.CancelResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.validationException = new apiCommon_ttypes.ValidationException();
        this.validationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new apiCommon_ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new apiAuth_ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiWrite_cancel_result.prototype.write = function(output) {
  output.writeStructBegin('SigninApiWrite_cancel_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.validationException !== null && this.validationException !== undefined) {
    output.writeFieldBegin('validationException', Thrift.Type.STRUCT, 1);
    this.validationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 2);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 3);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiWriteClient = exports.Client = function(output, pClass) {
    this.output = output;
    this.pClass = pClass;
    this._seqid = 0;
    this._reqs = {};
};
SigninApiWriteClient.prototype = {};
SigninApiWriteClient.prototype.seqid = function() { return this._seqid; }
SigninApiWriteClient.prototype.new_seqid = function() { return this._seqid += 1; }
SigninApiWriteClient.prototype.health = function(callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_health();
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_health();
  }
};

SigninApiWriteClient.prototype.send_health = function() {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('health', Thrift.MessageType.CALL, this.seqid());
  var args = new SigninApiWrite_health_args();
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

SigninApiWriteClient.prototype.recv_health = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new SigninApiWrite_health_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('health failed: unknown result');
};
SigninApiWriteClient.prototype.preRegister = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_preRegister(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_preRegister(auth, request);
  }
};

SigninApiWriteClient.prototype.send_preRegister = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('preRegister', Thrift.MessageType.CALL, this.seqid());
  var args = new SigninApiWrite_preRegister_args();
  args.auth = auth;
  args.request = request;
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

SigninApiWriteClient.prototype.recv_preRegister = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new SigninApiWrite_preRegister_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.validationException) {
    return callback(result.validationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('preRegister failed: unknown result');
};
SigninApiWriteClient.prototype.createPdf = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_createPdf(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_createPdf(auth, request);
  }
};

SigninApiWriteClient.prototype.send_createPdf = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('createPdf', Thrift.MessageType.CALL, this.seqid());
  var args = new SigninApiWrite_createPdf_args();
  args.auth = auth;
  args.request = request;
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

SigninApiWriteClient.prototype.recv_createPdf = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new SigninApiWrite_createPdf_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.validationException) {
    return callback(result.validationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('createPdf failed: unknown result');
};
SigninApiWriteClient.prototype.uploadSignedPdf = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_uploadSignedPdf(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_uploadSignedPdf(auth, request);
  }
};

SigninApiWriteClient.prototype.send_uploadSignedPdf = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('uploadSignedPdf', Thrift.MessageType.CALL, this.seqid());
  var args = new SigninApiWrite_uploadSignedPdf_args();
  args.auth = auth;
  args.request = request;
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

SigninApiWriteClient.prototype.recv_uploadSignedPdf = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new SigninApiWrite_uploadSignedPdf_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.validationException) {
    return callback(result.validationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('uploadSignedPdf failed: unknown result');
};
SigninApiWriteClient.prototype.assignSigner = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_assignSigner(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_assignSigner(auth, request);
  }
};

SigninApiWriteClient.prototype.send_assignSigner = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('assignSigner', Thrift.MessageType.CALL, this.seqid());
  var args = new SigninApiWrite_assignSigner_args();
  args.auth = auth;
  args.request = request;
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

SigninApiWriteClient.prototype.recv_assignSigner = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new SigninApiWrite_assignSigner_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.validationException) {
    return callback(result.validationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('assignSigner failed: unknown result');
};
SigninApiWriteClient.prototype.resetPin = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_resetPin(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_resetPin(auth, request);
  }
};

SigninApiWriteClient.prototype.send_resetPin = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('resetPin', Thrift.MessageType.CALL, this.seqid());
  var args = new SigninApiWrite_resetPin_args();
  args.auth = auth;
  args.request = request;
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

SigninApiWriteClient.prototype.recv_resetPin = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new SigninApiWrite_resetPin_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.validationException) {
    return callback(result.validationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('resetPin failed: unknown result');
};
SigninApiWriteClient.prototype.withdraw = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_withdraw(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_withdraw(auth, request);
  }
};

SigninApiWriteClient.prototype.send_withdraw = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('withdraw', Thrift.MessageType.CALL, this.seqid());
  var args = new SigninApiWrite_withdraw_args();
  args.auth = auth;
  args.request = request;
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

SigninApiWriteClient.prototype.recv_withdraw = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new SigninApiWrite_withdraw_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.validationException) {
    return callback(result.validationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('withdraw failed: unknown result');
};
SigninApiWriteClient.prototype.cancel = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_cancel(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_cancel(auth, request);
  }
};

SigninApiWriteClient.prototype.send_cancel = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('cancel', Thrift.MessageType.CALL, this.seqid());
  var args = new SigninApiWrite_cancel_args();
  args.auth = auth;
  args.request = request;
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

SigninApiWriteClient.prototype.recv_cancel = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new SigninApiWrite_cancel_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.validationException) {
    return callback(result.validationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('cancel failed: unknown result');
};
SigninApiWriteProcessor = exports.Processor = function(handler) {
  this._handler = handler
}
SigninApiWriteProcessor.prototype.process = function(input, output) {
  var r = input.readMessageBegin();
  if (this['process_' + r.fname]) {
    return this['process_' + r.fname].call(this, r.rseqid, input, output);
  } else {
    input.skip(Thrift.Type.STRUCT);
    input.readMessageEnd();
    var x = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN_METHOD, 'Unknown function ' + r.fname);
    output.writeMessageBegin(r.fname, Thrift.MessageType.EXCEPTION, r.rseqid);
    x.write(output);
    output.writeMessageEnd();
    output.flush();
  }
}

SigninApiWriteProcessor.prototype.process_health = function(seqid, input, output) {
  var args = new SigninApiWrite_health_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.health.length === 0) {
    Q.fcall(this._handler.health)
      .then(function(result) {
        var result = new SigninApiWrite_health_result({success: result});
        output.writeMessageBegin("health", Thrift.MessageType.REPLY, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("health", Thrift.MessageType.EXCEPTION, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.health(function (err, result) {
      if (err == null) {
        var result = new SigninApiWrite_health_result((err != null ? err : {success: result}));
        output.writeMessageBegin("health", Thrift.MessageType.REPLY, seqid);
      } else {
        var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("health", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
}

SigninApiWriteProcessor.prototype.process_preRegister = function(seqid, input, output) {
  var args = new SigninApiWrite_preRegister_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.preRegister.length === 2) {
    Q.fcall(this._handler.preRegister, args.auth, args.request)
      .then(function(result) {
        var result = new SigninApiWrite_preRegister_result({success: result});
        output.writeMessageBegin("preRegister", Thrift.MessageType.REPLY, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        if (err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
          var result = new SigninApiWrite_preRegister_result(err);
          output.writeMessageBegin("preRegister", Thrift.MessageType.REPLY, seqid);
        } else {
          var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("preRegister", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.preRegister(args.auth, args.request, function (err, result) {
      if (err == null || err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
        var result = new SigninApiWrite_preRegister_result((err != null ? err : {success: result}));
        output.writeMessageBegin("preRegister", Thrift.MessageType.REPLY, seqid);
      } else {
        var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("preRegister", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
}

SigninApiWriteProcessor.prototype.process_createPdf = function(seqid, input, output) {
  var args = new SigninApiWrite_createPdf_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.createPdf.length === 2) {
    Q.fcall(this._handler.createPdf, args.auth, args.request)
      .then(function(result) {
        var result = new SigninApiWrite_createPdf_result({success: result});
        output.writeMessageBegin("createPdf", Thrift.MessageType.REPLY, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        if (err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
          var result = new SigninApiWrite_createPdf_result(err);
          output.writeMessageBegin("createPdf", Thrift.MessageType.REPLY, seqid);
        } else {
          var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("createPdf", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.createPdf(args.auth, args.request, function (err, result) {
      if (err == null || err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
        var result = new SigninApiWrite_createPdf_result((err != null ? err : {success: result}));
        output.writeMessageBegin("createPdf", Thrift.MessageType.REPLY, seqid);
      } else {
        var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("createPdf", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
}

SigninApiWriteProcessor.prototype.process_uploadSignedPdf = function(seqid, input, output) {
  var args = new SigninApiWrite_uploadSignedPdf_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.uploadSignedPdf.length === 2) {
    Q.fcall(this._handler.uploadSignedPdf, args.auth, args.request)
      .then(function(result) {
        var result = new SigninApiWrite_uploadSignedPdf_result({success: result});
        output.writeMessageBegin("uploadSignedPdf", Thrift.MessageType.REPLY, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        if (err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
          var result = new SigninApiWrite_uploadSignedPdf_result(err);
          output.writeMessageBegin("uploadSignedPdf", Thrift.MessageType.REPLY, seqid);
        } else {
          var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("uploadSignedPdf", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.uploadSignedPdf(args.auth, args.request, function (err, result) {
      if (err == null || err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
        var result = new SigninApiWrite_uploadSignedPdf_result((err != null ? err : {success: result}));
        output.writeMessageBegin("uploadSignedPdf", Thrift.MessageType.REPLY, seqid);
      } else {
        var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("uploadSignedPdf", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
}

SigninApiWriteProcessor.prototype.process_assignSigner = function(seqid, input, output) {
  var args = new SigninApiWrite_assignSigner_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.assignSigner.length === 2) {
    Q.fcall(this._handler.assignSigner, args.auth, args.request)
      .then(function(result) {
        var result = new SigninApiWrite_assignSigner_result({success: result});
        output.writeMessageBegin("assignSigner", Thrift.MessageType.REPLY, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        if (err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
          var result = new SigninApiWrite_assignSigner_result(err);
          output.writeMessageBegin("assignSigner", Thrift.MessageType.REPLY, seqid);
        } else {
          var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("assignSigner", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.assignSigner(args.auth, args.request, function (err, result) {
      if (err == null || err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
        var result = new SigninApiWrite_assignSigner_result((err != null ? err : {success: result}));
        output.writeMessageBegin("assignSigner", Thrift.MessageType.REPLY, seqid);
      } else {
        var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("assignSigner", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
}

SigninApiWriteProcessor.prototype.process_resetPin = function(seqid, input, output) {
  var args = new SigninApiWrite_resetPin_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.resetPin.length === 2) {
    Q.fcall(this._handler.resetPin, args.auth, args.request)
      .then(function(result) {
        var result = new SigninApiWrite_resetPin_result({success: result});
        output.writeMessageBegin("resetPin", Thrift.MessageType.REPLY, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        if (err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
          var result = new SigninApiWrite_resetPin_result(err);
          output.writeMessageBegin("resetPin", Thrift.MessageType.REPLY, seqid);
        } else {
          var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("resetPin", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.resetPin(args.auth, args.request, function (err, result) {
      if (err == null || err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
        var result = new SigninApiWrite_resetPin_result((err != null ? err : {success: result}));
        output.writeMessageBegin("resetPin", Thrift.MessageType.REPLY, seqid);
      } else {
        var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("resetPin", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
}

SigninApiWriteProcessor.prototype.process_withdraw = function(seqid, input, output) {
  var args = new SigninApiWrite_withdraw_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.withdraw.length === 2) {
    Q.fcall(this._handler.withdraw, args.auth, args.request)
      .then(function(result) {
        var result = new SigninApiWrite_withdraw_result({success: result});
        output.writeMessageBegin("withdraw", Thrift.MessageType.REPLY, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        if (err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
          var result = new SigninApiWrite_withdraw_result(err);
          output.writeMessageBegin("withdraw", Thrift.MessageType.REPLY, seqid);
        } else {
          var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("withdraw", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.withdraw(args.auth, args.request, function (err, result) {
      if (err == null || err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
        var result = new SigninApiWrite_withdraw_result((err != null ? err : {success: result}));
        output.writeMessageBegin("withdraw", Thrift.MessageType.REPLY, seqid);
      } else {
        var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("withdraw", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
}

SigninApiWriteProcessor.prototype.process_cancel = function(seqid, input, output) {
  var args = new SigninApiWrite_cancel_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.cancel.length === 2) {
    Q.fcall(this._handler.cancel, args.auth, args.request)
      .then(function(result) {
        var result = new SigninApiWrite_cancel_result({success: result});
        output.writeMessageBegin("cancel", Thrift.MessageType.REPLY, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        if (err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
          var result = new SigninApiWrite_cancel_result(err);
          output.writeMessageBegin("cancel", Thrift.MessageType.REPLY, seqid);
        } else {
          var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("cancel", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.cancel(args.auth, args.request, function (err, result) {
      if (err == null || err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
        var result = new SigninApiWrite_cancel_result((err != null ? err : {success: result}));
        output.writeMessageBegin("cancel", Thrift.MessageType.REPLY, seqid);
      } else {
        var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("cancel", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
}
