/**
 * Given a query string, returns an object containing each parameter and its value.
 * All values will be of type string.
 * If the provided query string is invalid (any value == false) undefined will be returned.
 *
 * Example:
 * queryString: '?test=value&test2=value2'
 * result: { test: 'value', test2: 'value2' }
 *
 * @param {string} queryString The queryString, including '?' as the first character
 * @throws {Error} If the provided queryString does not start with '?'
 */
export const getRequestParams = queryString => {
    if (!queryString) {
        return undefined;
    }

    if (queryString[0] !== "?") {
        throw new Error("Invalid queryString: missing character '?' at the start of the string");
    }

    return (
        queryString &&
        queryString
            .substring(1)
            .split("&")
            .reduce((paramObject, currentParam) => {
                const [key, value] = currentParam.split("=");
                paramObject[key] = value;
                return paramObject;
            }, {})
    );
};
