import jwt from "jsonwebtoken";
import moment from "moment";

import { expiringPasswordWarn, EXPIRING_PASSWORD_WARN } from "../actions/auth";

const actions = ["LOGIN_SUCCESS"];
const warnDays = 15;

const pwdExpiration = store => next => async action => {
    if (
        actions.includes(action.type) &&
        store.getState().timedNotifications.toast.filter(tn => tn.type === EXPIRING_PASSWORD_WARN).length === 0
    ) {
        try {
            const decoded = jwt.decode(action.payload.loginAuth.securityToken);

            if (decoded.passwordExpirationDate !== undefined) {
                const expirationDate = moment(decoded.passwordExpirationDate);
                const currentDate = moment(new Date().getTime());

                if (
                    expirationDate.diff(currentDate, "days") <= warnDays &&
                    expirationDate.diff(currentDate, "days") >= 0
                ) {
                    store.dispatch(expiringPasswordWarn(expirationDate.diff(currentDate, "days")));
                }
            }
        } catch (err) {
            console.error("Caught an exception!", err);
        }
    }
    next(action);
};

export default pwdExpiration;
