import { combineReducers } from "redux";

import { auth, loggingOut } from "./auth";
import { company, privacyPreferences } from "./company";
import { reducer as formReducer } from "redux-form";
import {
    activateUser,
    createOrAddRolesToUser,
    deleteUserInvitation,
    getUserInfo,
    removeRolesFromUser,
    updateUserLanguage,
    user,
    userExists
} from "./user";
import tspay from "./tspay";
import { contract, pec } from "./contract";
import manageLogo from "./manage-logo";

import { active, activated, agyoServices, itemServices } from "./services/service-subscription";
import { shareActivated, shareDisabled, sharedItemServices } from "./services/service-extension";
import {
    getCctConfig,
    saveCctConfig,
    verifyCct,
    provisioningInfo,
    getCctConfigManaged,
    enableCctConfigManaged,
    disableCctConfigManaged,
    generateContract,
    managedProvisioning,
    provisioningContract,
    initSmart,
    extendSmart
} from "./services/cct";
import { provisioningData } from "./services/provisioning-export";
import aFiscaliIva from "./services/a-fiscali-iva";
import cbCr from "./services/cbcr";
import invoicing from "./services/invoicing";
import sdi from "./services/sdi";
import { categories } from "./services/categories";
import { data, total, createItem, pagination, updateItem, totalRoleAdd, homePage, dataStudio } from "./companies";
import noteSpese from "./services/note-spese";
import connectionsNew from "./connections-new";
import notification from "./notification";
import gdprlog from "./services/gdprlog";
import { releasenotes } from "./releasenotes";
import { itemApps, workspaceId } from "./services/app";
import userConsents from "./user-consents";
import waitinglist from "./waitinglist";
import ncs from "./ncs";
import roles from "./roles";
import { workspacePermissions } from "./services/workspace-permissions";

import { createOffice, deleteOffice, updateOffice } from "./company/offices";
import {
    addRoles,
    createUser,
    editUser,
    editUserV3,
    getUserV3,
    removeRoles,
    removeThenAddRoles,
    resendEmail,
    users,
    usersv3
} from "./company/users";
import { actOfEntrustment, certificationInfo, tscontract, uploadAtto, uploadCert } from "./certification";
import { createPdf, uploadSignedPdf, assignSigner, resetPin, cancel } from "./sign-api-write";
import { downloadFilledDocument, getSignerData, listSigners } from "./sign-api-read";
import { reset, update } from "./reset-password";
import registry from "./registry";
import { meteringReducer } from "./metering";
import { maintenance } from "./config";
import { overflow, toast } from "./timed-notifications";

const certification = combineReducers({
    actOfEntrustment,
    certificationInfo,
    tscontract,
    uploadAtto,
    uploadCert
});

const password = combineReducers({
    reset,
    update
});

const signApiWrite = combineReducers({
    createPdf,
    uploadSignedPdf,
    assignSigner,
    resetPin,
    cancel
});

const signApiRead = combineReducers({
    downloadFilledDocument,
    getSignerData,
    listSigners
});

const cct = combineReducers({
    generateContract,
    getCctConfig,
    getCctConfigManaged,
    enableCctConfigManaged,
    disableCctConfigManaged,
    saveCctConfig,
    verifyCct,
    managedProvisioning,
    provisioningData,
    provisioningInfo,
    provisioningContract,
    initSmart,
    extendSmart
});

const servicesSubscription = combineReducers({
    activated,
    itemServices
});

const servicesExtension = combineReducers({
    shareActivated,
    shareDisabled,
    sharedItemServices
});

const services = combineReducers({
    active,
    aFiscaliIva,
    agyoServices,
    categories,
    cbCr,
    cct,
    gdprlog,
    itemServices,
    noteSpese,
    servicesSubscription,
    servicesExtension,
    invoicing,
    sdi,
    itemApps,
    workspaceId,
    workspacePermissions
});

const companies = combineReducers({
    data,
    total,
    createItem,
    pagination,
    updateItem,
    totalRoleAdd,
    homePage,
    dataStudio
});

const contracts = combineReducers({
    contract,
    pec
});

const manageUser = combineReducers({
    addRoles,
    createOrAddRolesToUser,
    createUser,
    deleteUserInvitation,
    editUser,
    editUserV3,
    removeRoles,
    removeRolesFromUser,
    removeThenAddRoles,
    resendEmail,
    roles,
    updateUserLanguage
});

const offices = combineReducers({
    createOffice,
    deleteOffice,
    updateOffice
});

const timedNotifications = combineReducers({
    overflow,
    toast
});

const reducersSchema = {
    activateUser,
    auth,
    certification,
    companies,
    company,
    connectionsNew,
    contracts,
    form: formReducer,
    getUserInfo,
    getUserV3,
    loggingOut,
    maintenance,
    manageLogo,
    manageUser,
    metering: meteringReducer,
    ncs,
    notification,
    offices,
    password,
    privacyPreferences,
    registry,
    releasenotes,
    services,
    signApiRead,
    signApiWrite,
    timedNotifications,
    toast,
    tspay,
    user,
    userConsents,
    userExists,
    users,
    usersv3,
    waitinglist
};

export default reducersSchema;
