import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { UsageBar } from "../../../../../components/metering/usage-bar";
import { Segment } from "../../../../../components/metering/usage-bar/segment";
import { Credit } from "../credit";
import { Text } from "./text";
import { useEffect } from "react";
import { useState } from "react";
import { FirstRowContainer, UsageContainer, FlexContainer, CreditContainer } from "./styled";
import { Upgrade } from "../../upgrade";

export const Subscription = ({ capacity, sent, received, total, credit }) => {
    const [creditAvailable, setCreditAvailable] = useState(false);
    const [overused, setOverused] = useState(false);
    const [consumed, setConsumed] = useState(0);
    const [sentPercentage, setSentPercentage] = useState(0);
    const [receivedPercentage, setReceivedPercentage] = useState(0);

    useEffect(() => {
        setCreditAvailable(credit.expireDate && moment().isBefore(credit.expireDate) && credit.capacity > 0);
    }, [credit]);

    useEffect(() => {
        const consumed = creditAvailable ? total - credit.usage : total;
        setConsumed(consumed);
        setOverused(consumed > capacity);
        let sentPercentage = 0;
        let receivedPercentage = 0;
        if (capacity) {
            sentPercentage = (sent / capacity) * 100;
            receivedPercentage = (received / capacity) * 100;
        }
        setSentPercentage(sentPercentage > 100 ? 100 : sentPercentage);
        setReceivedPercentage(receivedPercentage > 100 ? 100 : receivedPercentage);
    }, [capacity, credit, creditAvailable, received, sent, total]);

    return (
        <FlexContainer>
            <UsageContainer>
                <FirstRowContainer>
                    <Text
                        consumed={consumed}
                        capacity={capacity}
                        usagePercentage={
                            sentPercentage + receivedPercentage > 100 ? 100 : sentPercentage + receivedPercentage
                        }
                    />
                    <Upgrade />
                </FirstRowContainer>
                <UsageBar>
                    <Segment
                        variant={overused ? "error" : "primary"}
                        percent={sentPercentage}
                        tooltipText={
                            !overused && (
                                <FormattedMessage
                                    id="metering.billing.invoices.sent.tooltip"
                                    values={{
                                        consumed: sent,
                                        percentage: sentPercentage
                                    }}
                                />
                            )
                        }
                    />
                    {!overused && receivedPercentage > 0 && (
                        <Segment
                            variant="secondary"
                            percent={receivedPercentage}
                            tooltipText={
                                <FormattedMessage
                                    id="metering.billing.invoices.received.tooltip"
                                    values={{
                                        consumed: received,
                                        percentage: receivedPercentage
                                    }}
                                />
                            }
                        />
                    )}
                </UsageBar>
            </UsageContainer>
            {creditAvailable && (
                <CreditContainer>
                    <Credit capacity={credit.capacity} usage={credit.usage} />
                </CreditContainer>
            )}
        </FlexContainer>
    );
};

Subscription.propTypes = {
    capacity: PropTypes.number.isRequired,
    orders: PropTypes.number.isRequired,
    invoices: PropTypes.number.isRequired,
    credit: PropTypes.shape({
        capacity: PropTypes.number.isRequired,
        expireDate: PropTypes.string.isRequired,
        usage: PropTypes.number.isRequired
    }).isRequired
};

Subscription.defaultProps = {
    capacity: 0,
    orders: 0,
    invoices: 0,
    credit: {
        capacity: 0,
        expireDate: new Date(),
        usage: 0
    }
};
