import React from "react";
import PropTypes from "prop-types";

import { Select, Form } from "antd";

const FormSelectWithLabel = ({
    className,
    defaultValue,
    input,
    label,
    meta: { touched, error },
    children,
    placeholder,
    showSearch,
    ...custom
}) => (
    <div className={className}>
        <Form.Item colon={false} help={touched && error} label={label} validateStatus={touched && error ? "error" : ""}>
            <Select
                defaultValue={defaultValue}
                placeholder={placeholder}
                showSearch={showSearch}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                {...custom}
                {...input}
                value={input.value === "" ? undefined : input.value} // placeholder is only shown when value === undefined
            >
                {children}
            </Select>
        </Form.Item>
    </div>
);

FormSelectWithLabel.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.array.isRequired]),
    className: PropTypes.string,
    input: PropTypes.object,
    label: PropTypes.any,
    meta: PropTypes.object.isRequired,
    showSearch: PropTypes.bool
};

FormSelectWithLabel.defaultProps = {
    input: {
        onChange: () => {}
    },
    meta: {},
    showSearch: true
};

export default FormSelectWithLabel;
