import { getAuthWithAppName } from "../auth";
import { REGISTRY_WRITE_REST } from "../../config";
import { deleteRestCall, postRestCall, putRestCall } from "../../utils/rest-api-call";
import { getUserProfile } from "../../utils/state-utils/get-user-profile";

export const CREATE_OFFICE_START = "CREATE_OFFICE_START";
export const CREATE_OFFICE_SUCCESS = "CREATE_OFFICE_SUCCESS";
export const CREATE_OFFICE_ERROR = "CREATE_OFFICE_ERROR";
export const CREATE_OFFICE_RESET = "CREATE_OFFICE_RESET";

export function createOffice(fiscalCode, officeData) {
    return async (dispatch, getState) => {
        dispatch({
            type: CREATE_OFFICE_START
        });

        let address = {
            streetName: officeData.streetName,
            streetNumber: officeData.streetNumber,
            city: officeData.city,
            province: officeData.province,
            country: officeData.country,
            zipCode: officeData.zipCode,
            types: ["REGISTERED_OFFICE"]
        };

        let details = {
            classifier: officeData.classifier,
            description: officeData.description,
            addresses: [address]
        };

        let identifier = {
            govCode: officeData.govCode,
            taxId: fiscalCode
        };

        let base = {
            identifier: identifier,
            details: details
        };

        let office = {
            base: base
        };

        let request = {
            item: office,
            parentId: fiscalCode
        };

        try {
            const auth = getAuthWithAppName(getState().auth);
            await postRestCall(
                `${REGISTRY_WRITE_REST}/layers`,
                auth,
                null,
                request,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                fiscalCode,
                true
            );
            dispatch({
                type: CREATE_OFFICE_SUCCESS,
                payload: request
            });
        } catch (e) {
            dispatch({
                type: CREATE_OFFICE_ERROR,
                error: e
            });
        }
    };
}

export function createOfficeReset() {
    return dispatch => {
        dispatch({
            type: CREATE_OFFICE_RESET
        });
    };
}

export const UPDATE_OFFICE_START = "UPDATE_OFFICE_START";
export const UPDATE_OFFICE_SUCCESS = "UPDATE_OFFICE_SUCCESS";
export const UPDATE_OFFICE_ERROR = "UPDATE_OFFICE_ERROR";
export const UPDATE_OFFICE_RESET = "UPDATE_OFFICE_RESET";

export function updateOffice(fiscalCode, officeId, officeData) {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_OFFICE_START
        });

        let address = {
            streetName: officeData.streetName,
            streetNumber: officeData.streetNumber,
            city: officeData.city,
            province: officeData.province,
            country: officeData.country,
            zipCode: officeData.zipCode,
            types: ["REGISTERED_OFFICE"]
        };

        let details = {
            classifier: "OFFICE",
            description: officeData.description,
            addresses: [address]
        };

        let identifier = {
            govCode: officeData.govCode,
            taxId: fiscalCode
        };

        let base = {
            identifier: identifier,
            details: details
        };

        let office = {
            base: base
        };

        let authObj = getAuthWithAppName(getState().auth);
        authObj.accessToken = authObj.securityToken;

        try {
            await putRestCall(
                `${REGISTRY_WRITE_REST}/layers/${officeId}`,
                authObj,
                null,
                office,
                dispatch,
                authObj.refreshToken,
                getUserProfile(getState()),
                fiscalCode
            );
            dispatch({
                type: UPDATE_OFFICE_SUCCESS,
                payload: { item: office, layerId: officeId }
            });
        } catch (e) {
            dispatch({
                type: UPDATE_OFFICE_ERROR,
                error: e
            });
        }
    };
}

export function updateOfficeReset() {
    return dispatch => {
        dispatch({
            type: UPDATE_OFFICE_RESET
        });
    };
}

export const DISABLE_OFFICE_START = "DISABLE_OFFICE_START";
export const DISABLE_OFFICE_SUCCESS = "DISABLE_OFFICE_SUCCESS";
export const DISABLE_OFFICE_ERROR = "DISABLE_OFFICE_ERROR";
export const DISABLE_OFFICE_RESET = "DISABLE_OFFICE_RESET";

export function deleteOffice(companyId, layerId) {
    return async (dispatch, getState) => {
        dispatch({
            type: DISABLE_OFFICE_START
        });

        const authObj = getAuthWithAppName(getState().auth);

        try {
            await deleteRestCall(
                `${REGISTRY_WRITE_REST}/layers/${layerId}`,
                authObj,
                null,
                null,
                dispatch,
                authObj.refreshToken,
                getUserProfile(getState()),
                companyId
            );

            dispatch({
                type: DISABLE_OFFICE_SUCCESS,
                payload: { companyId, layerId }
            });
        } catch (e) {
            dispatch({
                type: DISABLE_OFFICE_ERROR,
                error: e
            });
        }
    };
}

export function disableOfficeReset() {
    return dispatch => {
        dispatch({
            type: DISABLE_OFFICE_RESET
        });
    };
}
