import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/pro-regular-svg-icons";
import { FormattedMessage } from "react-intl";

import { TS_DIGITAL_IMAGES } from "../../../../config";
import { Container, EmptyImage, EmptyMessage, Suggestion } from "./styled";

const EmptyNotification = () => (
    <Container>
        <EmptyImage src={`${TS_DIGITAL_IMAGES}empty_notification.svg`} />
        <EmptyMessage>
            <FormattedMessage id="no-notification" />
        </EmptyMessage>
        <Suggestion>
            <FontAwesomeIcon icon={faRedo} /> <FormattedMessage id="refresh-notification-suggestion" />
        </Suggestion>
    </Container>
);

export default EmptyNotification;
