import {
    CREATE_MANAGER_LINK_START,
    DELETE_LINK_START,
    CREATE_MANAGER_LINK_ERROR,
    DELETE_LINK_ERROR,
    CREATE_MANAGER_LINK_SUCCESS,
    DELETE_LINK_SUCCESS,
    CREATE_CONNECTION_WITH_ITEM_SUCCESS,
    CREATE_CONNECTION_WITH_ITEM_START,
    CREATE_CONNECTION_WITH_ITEM_ERROR,
    CREATE_CONNECTION_WITH_ITEM_RESET
} from "../../actions/connections/write";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {}
    }
};

export function createLink(previousState = defaultState, { type, payload }) {
    switch (type) {
        case CREATE_MANAGER_LINK_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false,
                    errorInfo: {}
                }
            };
        case CREATE_MANAGER_LINK_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: payload.error
                }
            };
        case CREATE_MANAGER_LINK_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true,
                    errorInfo: {}
                }
            };
        default:
            return previousState;
    }
}

export function deleteLink(previousState = defaultState, { type, payload }) {
    switch (type) {
        case DELETE_LINK_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false,
                    errorInfo: {}
                }
            };
        case DELETE_LINK_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: payload.error
                }
            };
        case DELETE_LINK_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true,
                    errorInfo: {}
                }
            };
        default:
            return previousState;
    }
}

export function createConnectionAndItem(previousState = defaultState, { type, payload }) {
    switch (type) {
        case CREATE_CONNECTION_WITH_ITEM_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false,
                    errorInfo: {}
                }
            };
        case CREATE_CONNECTION_WITH_ITEM_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: payload.error
                }
            };
        case CREATE_CONNECTION_WITH_ITEM_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true,
                    errorInfo: {}
                }
            };
        case CREATE_CONNECTION_WITH_ITEM_RESET:
            return {
                ...defaultState
            };
        default:
            return previousState;
    }
}
