import React, { forwardRef } from "react";

import CloseButton from "./CloseButton";

import { Spinner } from "../spinner";

import {
    Overlay,
    DrawerContainer,
    Container,
    Content,
    Header,
    HeaderTitle,
    HeaderSubTitle,
    HeaderWrapperContainer,
    CloseButtonContainer
} from "./styled";
import { CloseIcon } from "./CloseButton/styled";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

const Drawer = forwardRef(({ isOpen, onClose, title, subtitle, content, loading, newComponent = false }, ref) => {
    return (
        <Overlay isOpen={isOpen}>
            <DrawerContainer isOpen={isOpen}>
                <Container isOpen={isOpen} ref={ref}>
                    {newComponent ? null : <CloseButton onClick={onClose} />}
                    <Header>
                        {newComponent ? <div style={{ width: "40px" }}></div> : null}
                        <HeaderWrapperContainer>
                            <HeaderTitle newComponent={newComponent}>{title}</HeaderTitle>
                            {subtitle && <HeaderSubTitle newComponent={newComponent}>{subtitle}</HeaderSubTitle>}
                        </HeaderWrapperContainer>
                        {newComponent ? (
                            <CloseButtonContainer onClick={onClose}>
                                <CloseIcon icon={faTimes} />
                            </CloseButtonContainer>
                        ) : null}
                    </Header>
                    <Spinner loading={loading}>
                        <Content newComponent={newComponent}>{content}</Content>
                    </Spinner>
                </Container>
            </DrawerContainer>
        </Overlay>
    );
});

export default Drawer;
