import { deleteRestCall, postRestCall } from "../../utils/rest-api-call";
import { API_URL } from "../../config";
import { getAuthWithAppName } from "../auth";
import { getUserProfile } from "../../utils/state-utils/get-user-profile";

export const ACTIVATE_SHARE_SERVICE_START = "ACTIVATE_SHARE_SERVICE_START";
export const ACTIVATE_SHARE_SERVICE_ERROR = "ACTIVATE_SHARE_SERVICE_ERROR";
export const ACTIVATE_SHARE_SERVICE_SUCCESS = "ACTIVATE_SHARE_SERVICE_SUCCESS";

export const DISABLE_SHARE_SERVICE_START = "DISABLE_SHARE_SERVICE_START";
export const DISABLE_SHARE_SERVICE_ERROR = "DISABLE_SHARE_SERVICE_ERROR";
export const DISABLE_SHARE_SERVICE_SUCCESS = "DISABLE_SHARE_SERVICE_SUCCESS";

const ACTIVATE_EXTENSION_EVENTS = {
    start: ACTIVATE_SHARE_SERVICE_START,
    success: ACTIVATE_SHARE_SERVICE_SUCCESS,
    error: ACTIVATE_SHARE_SERVICE_ERROR
};

const DELETE_EXTENSION_EVENTS = {
    start: DISABLE_SHARE_SERVICE_START,
    success: DISABLE_SHARE_SERVICE_SUCCESS,
    error: DISABLE_SHARE_SERVICE_ERROR
};

const NEW_EXTENSION_ENDPOINT = "service-extension";
const OLD_EXTENSION_ENDPOINT = "service-subscription";

export const getExtendServiceMethod =
    (newExtensionService, itemId, ownerId, serviceName, extraData) => async (dispatch, getState) => {
        dispatch({
            type: ACTIVATE_EXTENSION_EVENTS.start,
            payload: { itemId, ownerId, serviceName, extraData }
        });

        const request = {
            id: itemId,
            ownerId: ownerId,
            serviceId: serviceName,
            extraData
        };

        try {
            const auth = getAuthWithAppName(getState().auth);
            await postRestCall(
                `${API_URL}/${newExtensionService ? NEW_EXTENSION_ENDPOINT : OLD_EXTENSION_ENDPOINT}`,
                auth,
                {},
                request,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                ownerId,
                true,
                true
            );

            dispatch({
                type: ACTIVATE_EXTENSION_EVENTS.success,
                payload: {
                    agyoService: getState().services.agyoServices.services.find(s => s.id === serviceName),
                    extraData,
                    itemId,
                    ownerId,
                    serviceName
                }
            });
        } catch (e) {
            dispatch({
                type: ACTIVATE_EXTENSION_EVENTS.error,
                payload: { serviceName, extraData },
                error: e
            });
        }
    };

export const getDeleteServiceExtensionMethod =
    (newExtensionService, itemId, ownerId, serviceName, extraData) => async (dispatch, getState) => {
        dispatch({
            type: DELETE_EXTENSION_EVENTS.start,
            payload: { itemId, ownerId, serviceName, extraData }
        });

        const request = {
            id: itemId,
            ownerId: ownerId,
            serviceId: serviceName,
            extraData
        };

        try {
            const auth = getAuthWithAppName(getState().auth);
            await deleteRestCall(
                `${API_URL}/${newExtensionService ? NEW_EXTENSION_ENDPOINT : OLD_EXTENSION_ENDPOINT}`,
                auth,
                {},
                request,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                itemId,
                true,
                true
            );

            dispatch({
                type: DELETE_EXTENSION_EVENTS.success,
                payload: {
                    agyoService: getState().services.agyoServices.services.find(s => s.id === serviceName),
                    extraData,
                    itemId,
                    ownerId,
                    serviceName
                }
            });
        } catch (e) {
            dispatch({
                type: DELETE_EXTENSION_EVENTS.error,
                payload: { serviceName, extraData },
                error: e
            });
        }
    };
