import { API_URL } from "../../config";
import { getAuthWithAppName } from "../auth.js";

import { putRestCall, getRestCall } from "../../utils/rest-api-call";
import { getUserProfile } from "../../utils/state-utils/get-user-profile";

export const GET_SDI_CONFIG_START = "GET_SDI_CONFIG_START";
export const GET_SDI_CONFIG_SUCCESS = "GET_SDI_CONFIG_SUCCESS";
export const GET_SDI_CONFIG_ERROR = "GET_SDI_CONFIG_ERROR";

export const LIST_SDI_CONFIG_START = "LIST_SDI_CONFIG_START";
export const LIST_SDI_CONFIG_SUCCESS = "LIST_SDI_CONFIG_SUCCESS";
export const LIST_SDI_CONFIG_ERROR = "LIST_SDI_CONFIG_ERROR";

export const UPDATE_SDI_CONFIG_START = "UPDATE_SDI_CONFIG_START";
export const UPDATE_SDI_CONFIG_SUCCESS = "UPDATE_SDI_CONFIG_SUCCESS";
export const UPDATE_SDI_CONFIG_ERROR = "UPDATE_SDI_CONFIG_ERROR";

export const SDI_CONFIG_RESET = "SDI_CONFIG_RESET";

export const getSdiConfig = itemId => {
    return async (dispatch, getState) => {
        dispatch({
            type: GET_SDI_CONFIG_START,
            payload: { itemId }
        });

        let authObj = getAuthWithAppName(getState().auth);
        authObj.accessToken = authObj.securityToken;

        try {
            const result = await getRestCall(
                `${API_URL}/sdi/config/${itemId}`,
                authObj,
                null,
                dispatch,
                authObj.refreshToken,
                getUserProfile(getState())
            );
            dispatch({
                type: GET_SDI_CONFIG_SUCCESS,
                payload: result
            });
        } catch (err) {
            dispatch({
                type: GET_SDI_CONFIG_ERROR,
                error: err
            });
        }
    };
};

export const listSdiConfigs = (itemIds, ownerId, items = 1000) => {
    return async (dispatch, getState) => {
        dispatch({
            type: LIST_SDI_CONFIG_START,
            payload: { itemIds: itemIds, ownerId: ownerId }
        });

        let authObj = getAuthWithAppName(getState().auth);
        authObj.accessToken = authObj.securityToken;

        try {
            const result = await getRestCall(
                `${API_URL}/sdi/configs`,
                authObj,
                { itemIds: itemIds, pageNumber: 0, itemsPerPage: items },
                dispatch,
                authObj.refreshToken,
                getUserProfile(getState())
            );

            dispatch({
                type: LIST_SDI_CONFIG_SUCCESS,
                payload: {
                    configs: result.configs.filter(config => config.status.active && config.ownerId === ownerId)
                }
            });
        } catch (err) {
            dispatch({
                type: LIST_SDI_CONFIG_ERROR,
                error: err
            });
        }
    };
};

export const updateConfigs = configs => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_SDI_CONFIG_START,
            payload: { configs }
        });

        let authObj = getAuthWithAppName(getState().auth);
        authObj.accessToken = authObj.securityToken;

        try {
            await putRestCall(
                `${API_URL}/sdi/configs`,
                authObj,
                null,
                configs,
                dispatch,
                authObj.refreshToken,
                getUserProfile(getState())
            );
            dispatch({
                type: UPDATE_SDI_CONFIG_SUCCESS
            });
        } catch (err) {
            dispatch({
                type: UPDATE_SDI_CONFIG_ERROR,
                error: err
            });
        }
    };
};

export function resetSdiConfigs() {
    return {
        type: SDI_CONFIG_RESET
    };
}
