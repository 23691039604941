import styled from "styled-components";

export const ContentContainer = styled.div`
    margin-top: 32px;
    padding-left: 20px;
`;

export const TableContainer = styled.div`
    margin: 30px 0px;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
