import React, { useState } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { translateMessage } from "../../../../i18n";

import { Select, Typography } from "@ts-digital/vrc";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
import { faSort, faSortDown, faSortUp } from "@fortawesome/pro-regular-svg-icons";

import MultiSelect from "../../../general/custom-multi-select";
import { Pagination } from "../../../general/table/pagination";
import { PlaceholderRows } from "../../../general/table/placeholder-rows";
import {
    PaginationContainer,
    TableStyles,
    FilterCell,
    HeaderCell,
    Cell,
    FooterRow,
    SortIconContainer,
    ComboContainer,
    LabelContainer,
    ServicesMultiSelectContainer,
    RolesSelectContainer
} from "./styled";
import { TextFilter } from "../../../general/table/text-filter";

export const UserTable = ({
    columns,
    data,
    enableFilters,
    loading,
    multiSelectFilterOptions,
    onMultiSelectFilterChange,
    usePag = true,
    useFooter,
    footerContent,
    isCompanyCertified
}) => {
    const [rolesFilterValue, setRolesFilterValue] = useState("");

    const defaultColumn = React.useMemo(
        () => ({
            disableFilters: !enableFilters,
            Filter: () => <></>
        }),
        [enableFilters]
    );

    const rolesOptions = [
        {
            value: "",
            description: translateMessage(`c-registry-user.users-manage-all`)
        },
        {
            value: "admin",
            description: translateMessage(`c-registry-user.users-manage-admin`)
        },
        {
            value: "owner",
            description: translateMessage(`c-registry-user.users-manage-owner`)
        },
        {
            value: "employee",
            description: translateMessage(`c-registry-user.users-manage-employee`)
        },
        {
            value: "manager",
            description: translateMessage(`c-registry-user.users-manage-manager`)
        }
    ];

    const {
        getTableProps,
        headerGroups,
        prepareRow,
        page,
        pageCount,
        gotoPage,
        setFilter,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            defaultColumn: defaultColumn,
            initialState: {
                pageSize: 10,
                pageIndex: 0
            },
            manualFilters: false,
            data
        },
        useFilters,
        useSortBy,
        usePagination
    );

    return (
        <>
            {isCompanyCertified ? (
                <ComboContainer>
                    <ServicesMultiSelectContainer>
                        <LabelContainer>
                            <FormattedMessage id="c-registry-user.apps-filter" />
                        </LabelContainer>
                        <MultiSelect
                            options={multiSelectFilterOptions}
                            onChange={val => {
                                onMultiSelectFilterChange(val);
                            }}
                            placeholder={translateMessage("c-registry-user.combo-placeholder")}
                            closeMenuOnSelect={true}
                        />
                    </ServicesMultiSelectContainer>
                    <RolesSelectContainer>
                        <LabelContainer>
                            <FormattedMessage id="c-registry-user.roles-filter" />
                        </LabelContainer>
                        <Select
                            onChange={val => {
                                setFilter("role", val);
                                setRolesFilterValue(val);
                            }}
                            options={rolesOptions}
                            placeholder={translateMessage("c-registry-user.combo-placeholder")}
                            value={rolesFilterValue}
                        />
                    </RolesSelectContainer>
                </ComboContainer>
            ) : null}

            <TableStyles>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr key={`header-container-${index}`} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <HeaderCell
                                        {...column.getHeaderProps()}
                                        width={column.width}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                    >
                                        <div>
                                            <Typography variant="buttonsAndCtas">{column.render("Header")}</Typography>
                                            {column.canSort && (
                                                <SortIconContainer
                                                    icon={
                                                        column.isSorted
                                                            ? column.isSortedDesc
                                                                ? faSortDown
                                                                : faSortUp
                                                            : faSort
                                                    }
                                                />
                                            )}
                                        </div>
                                    </HeaderCell>
                                ))}
                            </tr>
                        ))}
                        {headerGroups.map((headerGroup, index) => (
                            <tr key={`filter-container-${index}`}>
                                {headerGroup.headers.map((column, i) => {
                                    return column.canFilter ? (
                                        <FilterCell key={`filter-container-${i}`}>
                                            <TextFilter
                                                key={`filter-container-${i}`}
                                                onFilterSubmit={e => {
                                                    column.setFilter(e);
                                                }}
                                                column={column}
                                                alwaysActive={true}
                                            />
                                        </FilterCell>
                                    ) : enableFilters ? (
                                        <FilterCell key={`filter-container-${i}`}></FilterCell>
                                    ) : null;
                                })}
                            </tr>
                        ))}
                    </thead>
                    {useFooter && (
                        <FooterRow>
                            <tr>{footerContent}</tr>
                        </FooterRow>
                    )}
                    <tbody>
                        {loading ? (
                            <PlaceholderRows columnCount={columns.length} pageSize={pageSize} />
                        ) : (
                            page.map(
                                (row, i) =>
                                    prepareRow(row) || (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return (
                                                    <Cell {...cell.getCellProps()}>
                                                        <div>{cell.render("Cell")}</div>
                                                    </Cell>
                                                );
                                            })}
                                        </tr>
                                    )
                            )
                        )}
                    </tbody>
                </table>
            </TableStyles>
            {usePag && (
                <PaginationContainer>
                    <Pagination currentPage={pageIndex} onPageChange={gotoPage} totalPages={pageCount} />
                </PaginationContainer>
            )}
        </>
    );
};

UserTable.propTypes = {
    /** Column definitions for the table. Must be memoized */
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            /** The key under which the column's data can be found or a function to be used to retrieve the value from the dataset */
            accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
            /** Unique Id for the column. Only required if accessor is a function */
            id: PropTypes.string,
            /** If specified, the column will be treated as a column group */
            columns: PropTypes.array,
            /** A boolean value (or a function returning a boolean value) used to specify if the column should be visible */
            show: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
            /** Used to specify this column's header cell's react component */
            Header: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
            /** Used to specify this column's cells' react component */
            Cell: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        })
    ).isRequired,
    /** The table's dataset. Must be memoized */
    data: PropTypes.array.isRequired,
    /** If true, the table's content will be replaced by a loading placeholder */
    loading: PropTypes.bool,
    /** Whether the pagination element should be displayed. Defaults to true */
    usePag: PropTypes.bool,
    /** Whether the pagination element should be displayed also on top of the table. Defaults to false */
    useTopPagination: PropTypes.bool
};
