/* eslint-disable */
//
// Autogenerated by Thrift Compiler (0.9.3)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
var thrift = require('browser-thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;

var apiCommon_ttypes = require('./apiCommon_types')
var apiAuth_ttypes = require('./apiAuth_types')


var ttypes = module.exports = {};
PreRegistrationResponse = module.exports.PreRegistrationResponse = function(args) {
  this.document = null;
  if (args) {
    if (args.document !== undefined && args.document !== null) {
      this.document = new apiCommon_ttypes.DocumentInfo(args.document);
    }
  }
};
PreRegistrationResponse.prototype = {};
PreRegistrationResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.document = new apiCommon_ttypes.DocumentInfo();
        this.document.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PreRegistrationResponse.prototype.write = function(output) {
  output.writeStructBegin('PreRegistrationResponse');
  if (this.document !== null && this.document !== undefined) {
    output.writeFieldBegin('document', Thrift.Type.STRUCT, 1);
    this.document.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PreRegistrationRequest = module.exports.PreRegistrationRequest = function(args) {
  this.signerRef = null;
  this.formData = null;
  if (args) {
    if (args.signerRef !== undefined && args.signerRef !== null) {
      this.signerRef = new apiCommon_ttypes.SignerRef(args.signerRef);
    }
    if (args.formData !== undefined && args.formData !== null) {
      this.formData = new apiCommon_ttypes.FormData(args.formData);
    }
  }
};
PreRegistrationRequest.prototype = {};
PreRegistrationRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.signerRef = new apiCommon_ttypes.SignerRef();
        this.signerRef.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.formData = new apiCommon_ttypes.FormData();
        this.formData.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PreRegistrationRequest.prototype.write = function(output) {
  output.writeStructBegin('PreRegistrationRequest');
  if (this.signerRef !== null && this.signerRef !== undefined) {
    output.writeFieldBegin('signerRef', Thrift.Type.STRUCT, 1);
    this.signerRef.write(output);
    output.writeFieldEnd();
  }
  if (this.formData !== null && this.formData !== undefined) {
    output.writeFieldBegin('formData', Thrift.Type.STRUCT, 2);
    this.formData.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

CreatePdfResponse = module.exports.CreatePdfResponse = function(args) {
  this.document = null;
  if (args) {
    if (args.document !== undefined && args.document !== null) {
      this.document = new apiCommon_ttypes.DocumentInfo(args.document);
    }
  }
};
CreatePdfResponse.prototype = {};
CreatePdfResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.document = new apiCommon_ttypes.DocumentInfo();
        this.document.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CreatePdfResponse.prototype.write = function(output) {
  output.writeStructBegin('CreatePdfResponse');
  if (this.document !== null && this.document !== undefined) {
    output.writeFieldBegin('document', Thrift.Type.STRUCT, 1);
    this.document.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

CreatePdfRequest = module.exports.CreatePdfRequest = function(args) {
  this.signerRef = null;
  this.formData = null;
  if (args) {
    if (args.signerRef !== undefined && args.signerRef !== null) {
      this.signerRef = new apiCommon_ttypes.SignerRef(args.signerRef);
    }
    if (args.formData !== undefined && args.formData !== null) {
      this.formData = new apiCommon_ttypes.FormData(args.formData);
    }
  }
};
CreatePdfRequest.prototype = {};
CreatePdfRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.signerRef = new apiCommon_ttypes.SignerRef();
        this.signerRef.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.formData = new apiCommon_ttypes.FormData();
        this.formData.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CreatePdfRequest.prototype.write = function(output) {
  output.writeStructBegin('CreatePdfRequest');
  if (this.signerRef !== null && this.signerRef !== undefined) {
    output.writeFieldBegin('signerRef', Thrift.Type.STRUCT, 1);
    this.signerRef.write(output);
    output.writeFieldEnd();
  }
  if (this.formData !== null && this.formData !== undefined) {
    output.writeFieldBegin('formData', Thrift.Type.STRUCT, 2);
    this.formData.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UploadSignedPdfResponse = module.exports.UploadSignedPdfResponse = function(args) {
  this.document = null;
  if (args) {
    if (args.document !== undefined && args.document !== null) {
      this.document = new apiCommon_ttypes.DocumentInfo(args.document);
    }
  }
};
UploadSignedPdfResponse.prototype = {};
UploadSignedPdfResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.document = new apiCommon_ttypes.DocumentInfo();
        this.document.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UploadSignedPdfResponse.prototype.write = function(output) {
  output.writeStructBegin('UploadSignedPdfResponse');
  if (this.document !== null && this.document !== undefined) {
    output.writeFieldBegin('document', Thrift.Type.STRUCT, 1);
    this.document.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UploadSignedPdfRequest = module.exports.UploadSignedPdfRequest = function(args) {
  this.signerId = null;
  this.file = null;
  if (args) {
    if (args.signerId !== undefined && args.signerId !== null) {
      this.signerId = args.signerId;
    }
    if (args.file !== undefined && args.file !== null) {
      this.file = args.file;
    }
  }
};
UploadSignedPdfRequest.prototype = {};
UploadSignedPdfRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.signerId = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.file = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UploadSignedPdfRequest.prototype.write = function(output) {
  output.writeStructBegin('UploadSignedPdfRequest');
  if (this.signerId !== null && this.signerId !== undefined) {
    output.writeFieldBegin('signerId', Thrift.Type.I64, 1);
    output.writeI64(this.signerId);
    output.writeFieldEnd();
  }
  if (this.file !== null && this.file !== undefined) {
    output.writeFieldBegin('file', Thrift.Type.STRING, 2);
    output.writeString(this.file);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AssignSignerRequest = module.exports.AssignSignerRequest = function(args) {
  this.signerRef = null;
  this.cfAzienda = null;
  this.pin = null;
  if (args) {
    if (args.signerRef !== undefined && args.signerRef !== null) {
      this.signerRef = new apiCommon_ttypes.SignerRef(args.signerRef);
    }
    if (args.cfAzienda !== undefined && args.cfAzienda !== null) {
      this.cfAzienda = args.cfAzienda;
    }
    if (args.pin !== undefined && args.pin !== null) {
      this.pin = args.pin;
    }
  }
};
AssignSignerRequest.prototype = {};
AssignSignerRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.signerRef = new apiCommon_ttypes.SignerRef();
        this.signerRef.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.cfAzienda = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.pin = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AssignSignerRequest.prototype.write = function(output) {
  output.writeStructBegin('AssignSignerRequest');
  if (this.signerRef !== null && this.signerRef !== undefined) {
    output.writeFieldBegin('signerRef', Thrift.Type.STRUCT, 1);
    this.signerRef.write(output);
    output.writeFieldEnd();
  }
  if (this.cfAzienda !== null && this.cfAzienda !== undefined) {
    output.writeFieldBegin('cfAzienda', Thrift.Type.STRING, 2);
    output.writeString(this.cfAzienda);
    output.writeFieldEnd();
  }
  if (this.pin !== null && this.pin !== undefined) {
    output.writeFieldBegin('pin', Thrift.Type.STRING, 3);
    output.writeString(this.pin);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AssignSignerResponse = module.exports.AssignSignerResponse = function(args) {
  this.status = null;
  if (args) {
    if (args.status !== undefined && args.status !== null) {
      this.status = args.status;
    }
  }
};
AssignSignerResponse.prototype = {};
AssignSignerResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.status = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AssignSignerResponse.prototype.write = function(output) {
  output.writeStructBegin('AssignSignerResponse');
  if (this.status !== null && this.status !== undefined) {
    output.writeFieldBegin('status', Thrift.Type.STRING, 1);
    output.writeString(this.status);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ResetPinRequest = module.exports.ResetPinRequest = function(args) {
  this.signerId = null;
  this.pin = null;
  this.puk = null;
  this.savePin = null;
  if (args) {
    if (args.signerId !== undefined && args.signerId !== null) {
      this.signerId = args.signerId;
    }
    if (args.pin !== undefined && args.pin !== null) {
      this.pin = args.pin;
    }
    if (args.puk !== undefined && args.puk !== null) {
      this.puk = args.puk;
    }
    if (args.savePin !== undefined && args.savePin !== null) {
      this.savePin = args.savePin;
    }
  }
};
ResetPinRequest.prototype = {};
ResetPinRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.signerId = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.pin = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.puk = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.BOOL) {
        this.savePin = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ResetPinRequest.prototype.write = function(output) {
  output.writeStructBegin('ResetPinRequest');
  if (this.signerId !== null && this.signerId !== undefined) {
    output.writeFieldBegin('signerId', Thrift.Type.I64, 1);
    output.writeI64(this.signerId);
    output.writeFieldEnd();
  }
  if (this.pin !== null && this.pin !== undefined) {
    output.writeFieldBegin('pin', Thrift.Type.STRING, 2);
    output.writeString(this.pin);
    output.writeFieldEnd();
  }
  if (this.puk !== null && this.puk !== undefined) {
    output.writeFieldBegin('puk', Thrift.Type.STRING, 3);
    output.writeString(this.puk);
    output.writeFieldEnd();
  }
  if (this.savePin !== null && this.savePin !== undefined) {
    output.writeFieldBegin('savePin', Thrift.Type.BOOL, 4);
    output.writeBool(this.savePin);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ResetPinResponse = module.exports.ResetPinResponse = function(args) {
  this.result = null;
  if (args) {
    if (args.result !== undefined && args.result !== null) {
      this.result = args.result;
    }
  }
};
ResetPinResponse.prototype = {};
ResetPinResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.result = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ResetPinResponse.prototype.write = function(output) {
  output.writeStructBegin('ResetPinResponse');
  if (this.result !== null && this.result !== undefined) {
    output.writeFieldBegin('result', Thrift.Type.STRING, 1);
    output.writeString(this.result);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

WithdrawRequest = module.exports.WithdrawRequest = function(args) {
  this.signerId = null;
  if (args) {
    if (args.signerId !== undefined && args.signerId !== null) {
      this.signerId = args.signerId;
    }
  }
};
WithdrawRequest.prototype = {};
WithdrawRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.signerId = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

WithdrawRequest.prototype.write = function(output) {
  output.writeStructBegin('WithdrawRequest');
  if (this.signerId !== null && this.signerId !== undefined) {
    output.writeFieldBegin('signerId', Thrift.Type.I64, 1);
    output.writeI64(this.signerId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

WithdrawResponse = module.exports.WithdrawResponse = function(args) {
  this.result = null;
  if (args) {
    if (args.result !== undefined && args.result !== null) {
      this.result = args.result;
    }
  }
};
WithdrawResponse.prototype = {};
WithdrawResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.result = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

WithdrawResponse.prototype.write = function(output) {
  output.writeStructBegin('WithdrawResponse');
  if (this.result !== null && this.result !== undefined) {
    output.writeFieldBegin('result', Thrift.Type.STRING, 1);
    output.writeString(this.result);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

CancelRequest = module.exports.CancelRequest = function(args) {
  this.signerId = null;
  if (args) {
    if (args.signerId !== undefined && args.signerId !== null) {
      this.signerId = args.signerId;
    }
  }
};
CancelRequest.prototype = {};
CancelRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.signerId = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CancelRequest.prototype.write = function(output) {
  output.writeStructBegin('CancelRequest');
  if (this.signerId !== null && this.signerId !== undefined) {
    output.writeFieldBegin('signerId', Thrift.Type.I64, 1);
    output.writeI64(this.signerId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

CancelResponse = module.exports.CancelResponse = function(args) {
  this.result = null;
  if (args) {
    if (args.result !== undefined && args.result !== null) {
      this.result = args.result;
    }
  }
};
CancelResponse.prototype = {};
CancelResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.result = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CancelResponse.prototype.write = function(output) {
  output.writeStructBegin('CancelResponse');
  if (this.result !== null && this.result !== undefined) {
    output.writeFieldBegin('result', Thrift.Type.STRING, 1);
    output.writeString(this.result);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};
