import React from "react";

import { PECMANAGER_URL } from "../../../config";
import PortaleChildApp from "../../portale-child-app";

const PecManagerView = () => {
    return <PortaleChildApp url={PECMANAGER_URL} />;
};

export default PecManagerView;
