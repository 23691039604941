import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    text-align: center;
    justify-content: left;
    flex-wrap: wrap;

    & .title {
        margin: 10px;
    }
`;
