import React from "react";
import PropTypes from "prop-types";

import { Container } from "./styled";

const LoaderItem = ({ height, width, margin }) => {
    return <Container height={height} width={width} margin={margin}></Container>;
};

LoaderItem.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string
};

export default LoaderItem;
