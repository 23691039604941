/* eslint-disable */
//
// Autogenerated by Thrift Compiler (0.9.3)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
var thrift = require('browser-thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = module.exports = {};
if (typeof com === 'undefined') {
  com = {};
}
if (typeof com.teamsystem === 'undefined') {
  com.teamsystem = {};
}
if (typeof com.teamsystem.hub === 'undefined') {
  com.teamsystem.hub = {};
}
if (typeof com.teamsystem.hub.b2b === 'undefined') {
  com.teamsystem.hub.b2b = {};
}
if (typeof com.teamsystem.hub.b2b.digital === 'undefined') {
  com.teamsystem.hub.b2b.digital = {};
}
if (typeof com.teamsystem.hub.b2b.digital.comm === 'undefined') {
  com.teamsystem.hub.b2b.digital.comm = {};
}
if (typeof com.teamsystem.hub.b2b.digital.comm.sign === 'undefined') {
  com.teamsystem.hub.b2b.digital.comm.sign = {};
}
if (typeof com.teamsystem.hub.b2b.digital.comm.sign.thrift === 'undefined') {
  com.teamsystem.hub.b2b.digital.comm.sign.thrift = {};
}
if (typeof com.teamsystem.hub.b2b.digital.comm.sign.thrift.common === 'undefined') {
  com.teamsystem.hub.b2b.digital.comm.sign.thrift.common = {};
}
com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.DocumentInfo = module.exports.DocumentInfo = function(args) {
  this.docId = null;
  this.contextId = null;
  this.metadata = null;
  this.owner = null;
  if (args) {
    if (args.docId !== undefined && args.docId !== null) {
      this.docId = args.docId;
    }
    if (args.contextId !== undefined && args.contextId !== null) {
      this.contextId = args.contextId;
    }
    if (args.metadata !== undefined && args.metadata !== null) {
      this.metadata = new ttypes.Metadata(args.metadata);
    }
    if (args.owner !== undefined && args.owner !== null) {
      this.owner = new ttypes.SignerRef(args.owner);
    }
  }
};
com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.DocumentInfo.prototype = {};
com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.DocumentInfo.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.docId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.contextId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.metadata = new ttypes.Metadata();
        this.metadata.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.owner = new ttypes.SignerRef();
        this.owner.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.DocumentInfo.prototype.write = function(output) {
  output.writeStructBegin('DocumentInfo');
  if (this.docId !== null && this.docId !== undefined) {
    output.writeFieldBegin('docId', Thrift.Type.STRING, 1);
    output.writeString(this.docId);
    output.writeFieldEnd();
  }
  if (this.contextId !== null && this.contextId !== undefined) {
    output.writeFieldBegin('contextId', Thrift.Type.STRING, 2);
    output.writeString(this.contextId);
    output.writeFieldEnd();
  }
  if (this.metadata !== null && this.metadata !== undefined) {
    output.writeFieldBegin('metadata', Thrift.Type.STRUCT, 3);
    this.metadata.write(output);
    output.writeFieldEnd();
  }
  if (this.owner !== null && this.owner !== undefined) {
    output.writeFieldBegin('owner', Thrift.Type.STRUCT, 4);
    this.owner.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.Document = module.exports.Document = function(args) {
  this.docId = null;
  this.metadata = null;
  this.content = null;
  if (args) {
    if (args.docId !== undefined && args.docId !== null) {
      this.docId = args.docId;
    }
    if (args.metadata !== undefined && args.metadata !== null) {
      this.metadata = new ttypes.Metadata(args.metadata);
    }
    if (args.content !== undefined && args.content !== null) {
      this.content = args.content;
    }
  }
};
com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.Document.prototype = {};
com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.Document.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.docId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.metadata = new ttypes.Metadata();
        this.metadata.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.content = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.Document.prototype.write = function(output) {
  output.writeStructBegin('Document');
  if (this.docId !== null && this.docId !== undefined) {
    output.writeFieldBegin('docId', Thrift.Type.STRING, 2);
    output.writeString(this.docId);
    output.writeFieldEnd();
  }
  if (this.metadata !== null && this.metadata !== undefined) {
    output.writeFieldBegin('metadata', Thrift.Type.STRUCT, 3);
    this.metadata.write(output);
    output.writeFieldEnd();
  }
  if (this.content !== null && this.content !== undefined) {
    output.writeFieldBegin('content', Thrift.Type.STRING, 4);
    output.writeString(this.content);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.Metadata = module.exports.Metadata = function(args) {
  this.name = null;
  this.size = null;
  this.timestamp = null;
  this.annotation = null;
  this.category = null;
  this.format = null;
  if (args) {
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.size !== undefined && args.size !== null) {
      this.size = args.size;
    }
    if (args.timestamp !== undefined && args.timestamp !== null) {
      this.timestamp = args.timestamp;
    }
    if (args.annotation !== undefined && args.annotation !== null) {
      this.annotation = args.annotation;
    }
    if (args.category !== undefined && args.category !== null) {
      this.category = args.category;
    }
    if (args.format !== undefined && args.format !== null) {
      this.format = args.format;
    }
  }
};
com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.Metadata.prototype = {};
com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.Metadata.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I64) {
        this.size = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I64) {
        this.timestamp = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.annotation = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.category = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRING) {
        this.format = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.Metadata.prototype.write = function(output) {
  output.writeStructBegin('Metadata');
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 1);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.size !== null && this.size !== undefined) {
    output.writeFieldBegin('size', Thrift.Type.I64, 2);
    output.writeI64(this.size);
    output.writeFieldEnd();
  }
  if (this.timestamp !== null && this.timestamp !== undefined) {
    output.writeFieldBegin('timestamp', Thrift.Type.I64, 4);
    output.writeI64(this.timestamp);
    output.writeFieldEnd();
  }
  if (this.annotation !== null && this.annotation !== undefined) {
    output.writeFieldBegin('annotation', Thrift.Type.STRING, 6);
    output.writeString(this.annotation);
    output.writeFieldEnd();
  }
  if (this.category !== null && this.category !== undefined) {
    output.writeFieldBegin('category', Thrift.Type.STRING, 7);
    output.writeString(this.category);
    output.writeFieldEnd();
  }
  if (this.format !== null && this.format !== undefined) {
    output.writeFieldBegin('format', Thrift.Type.STRING, 9);
    output.writeString(this.format);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.ExtraData = module.exports.ExtraData = function(args) {
  this.status = null;
  this.statusDescription = null;
  this.nome = null;
  this.cognome = null;
  if (args) {
    if (args.status !== undefined && args.status !== null) {
      this.status = args.status;
    }
    if (args.statusDescription !== undefined && args.statusDescription !== null) {
      this.statusDescription = args.statusDescription;
    }
    if (args.nome !== undefined && args.nome !== null) {
      this.nome = args.nome;
    }
    if (args.cognome !== undefined && args.cognome !== null) {
      this.cognome = args.cognome;
    }
  }
};
com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.ExtraData.prototype = {};
com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.ExtraData.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.status = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.statusDescription = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.nome = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.cognome = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.ExtraData.prototype.write = function(output) {
  output.writeStructBegin('ExtraData');
  if (this.status !== null && this.status !== undefined) {
    output.writeFieldBegin('status', Thrift.Type.STRING, 1);
    output.writeString(this.status);
    output.writeFieldEnd();
  }
  if (this.statusDescription !== null && this.statusDescription !== undefined) {
    output.writeFieldBegin('statusDescription', Thrift.Type.STRING, 2);
    output.writeString(this.statusDescription);
    output.writeFieldEnd();
  }
  if (this.nome !== null && this.nome !== undefined) {
    output.writeFieldBegin('nome', Thrift.Type.STRING, 3);
    output.writeString(this.nome);
    output.writeFieldEnd();
  }
  if (this.cognome !== null && this.cognome !== undefined) {
    output.writeFieldBegin('cognome', Thrift.Type.STRING, 4);
    output.writeString(this.cognome);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.SignerRef = module.exports.SignerRef = function(args) {
  this.id = null;
  this.cfAzienda = null;
  this.cfUtente = null;
  this.certType = null;
  this.serviceName = null;
  this.extradata = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.cfAzienda !== undefined && args.cfAzienda !== null) {
      this.cfAzienda = args.cfAzienda;
    }
    if (args.cfUtente !== undefined && args.cfUtente !== null) {
      this.cfUtente = args.cfUtente;
    }
    if (args.certType !== undefined && args.certType !== null) {
      this.certType = args.certType;
    }
    if (args.serviceName !== undefined && args.serviceName !== null) {
      this.serviceName = args.serviceName;
    }
    if (args.extradata !== undefined && args.extradata !== null) {
      this.extradata = new ttypes.ExtraData(args.extradata);
    }
  }
};
com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.SignerRef.prototype = {};
com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.SignerRef.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.id = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.cfAzienda = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.cfUtente = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.certType = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.serviceName = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.extradata = new ttypes.ExtraData();
        this.extradata.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.SignerRef.prototype.write = function(output) {
  output.writeStructBegin('SignerRef');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.I64, 1);
    output.writeI64(this.id);
    output.writeFieldEnd();
  }
  if (this.cfAzienda !== null && this.cfAzienda !== undefined) {
    output.writeFieldBegin('cfAzienda', Thrift.Type.STRING, 2);
    output.writeString(this.cfAzienda);
    output.writeFieldEnd();
  }
  if (this.cfUtente !== null && this.cfUtente !== undefined) {
    output.writeFieldBegin('cfUtente', Thrift.Type.STRING, 3);
    output.writeString(this.cfUtente);
    output.writeFieldEnd();
  }
  if (this.certType !== null && this.certType !== undefined) {
    output.writeFieldBegin('certType', Thrift.Type.STRING, 4);
    output.writeString(this.certType);
    output.writeFieldEnd();
  }
  if (this.serviceName !== null && this.serviceName !== undefined) {
    output.writeFieldBegin('serviceName', Thrift.Type.STRING, 5);
    output.writeString(this.serviceName);
    output.writeFieldEnd();
  }
  if (this.extradata !== null && this.extradata !== undefined) {
    output.writeFieldBegin('extradata', Thrift.Type.STRUCT, 6);
    this.extradata.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.FormData = module.exports.FormData = function(args) {
  this.pin = null;
  this.savePin = null;
  this.signerFirstName = null;
  this.signerLastName = null;
  this.signerFiscalCode = null;
  this.signerFiscalCountry = null;
  this.signerSex = null;
  this.signerBirthCity = null;
  this.signerBirthCountry = null;
  this.signerBirthDate = null;
  this.signerBirthDistrict = null;
  this.signerCity = null;
  this.signerCountry = null;
  this.signerDistrict = null;
  this.signerCap = null;
  this.signerAddress = null;
  this.signerEmail = null;
  this.signerDocumentIssuer = null;
  this.signerDocumentNumber = null;
  this.signerDocumentEndDate = null;
  this.signerDocumentType = null;
  this.cellNumber = null;
  if (args) {
    if (args.pin !== undefined && args.pin !== null) {
      this.pin = args.pin;
    }
    if (args.savePin !== undefined && args.savePin !== null) {
      this.savePin = args.savePin;
    }
    if (args.signerFirstName !== undefined && args.signerFirstName !== null) {
      this.signerFirstName = args.signerFirstName;
    }
    if (args.signerLastName !== undefined && args.signerLastName !== null) {
      this.signerLastName = args.signerLastName;
    }
    if (args.signerFiscalCode !== undefined && args.signerFiscalCode !== null) {
      this.signerFiscalCode = args.signerFiscalCode;
    }
    if (args.signerFiscalCountry !== undefined && args.signerFiscalCountry !== null) {
      this.signerFiscalCountry = args.signerFiscalCountry;
    }
    if (args.signerSex !== undefined && args.signerSex !== null) {
      this.signerSex = args.signerSex;
    }
    if (args.signerBirthCity !== undefined && args.signerBirthCity !== null) {
      this.signerBirthCity = args.signerBirthCity;
    }
    if (args.signerBirthCountry !== undefined && args.signerBirthCountry !== null) {
      this.signerBirthCountry = args.signerBirthCountry;
    }
    if (args.signerBirthDate !== undefined && args.signerBirthDate !== null) {
      this.signerBirthDate = args.signerBirthDate;
    }
    if (args.signerBirthDistrict !== undefined && args.signerBirthDistrict !== null) {
      this.signerBirthDistrict = args.signerBirthDistrict;
    }
    if (args.signerCity !== undefined && args.signerCity !== null) {
      this.signerCity = args.signerCity;
    }
    if (args.signerCountry !== undefined && args.signerCountry !== null) {
      this.signerCountry = args.signerCountry;
    }
    if (args.signerDistrict !== undefined && args.signerDistrict !== null) {
      this.signerDistrict = args.signerDistrict;
    }
    if (args.signerCap !== undefined && args.signerCap !== null) {
      this.signerCap = args.signerCap;
    }
    if (args.signerAddress !== undefined && args.signerAddress !== null) {
      this.signerAddress = args.signerAddress;
    }
    if (args.signerEmail !== undefined && args.signerEmail !== null) {
      this.signerEmail = args.signerEmail;
    }
    if (args.signerDocumentIssuer !== undefined && args.signerDocumentIssuer !== null) {
      this.signerDocumentIssuer = args.signerDocumentIssuer;
    }
    if (args.signerDocumentNumber !== undefined && args.signerDocumentNumber !== null) {
      this.signerDocumentNumber = args.signerDocumentNumber;
    }
    if (args.signerDocumentEndDate !== undefined && args.signerDocumentEndDate !== null) {
      this.signerDocumentEndDate = args.signerDocumentEndDate;
    }
    if (args.signerDocumentType !== undefined && args.signerDocumentType !== null) {
      this.signerDocumentType = args.signerDocumentType;
    }
    if (args.cellNumber !== undefined && args.cellNumber !== null) {
      this.cellNumber = args.cellNumber;
    }
  }
};
com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.FormData.prototype = {};
com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.FormData.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.pin = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.BOOL) {
        this.savePin = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.signerFirstName = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.signerLastName = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.signerFiscalCode = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.signerFiscalCountry = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.signerSex = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.signerBirthCity = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRING) {
        this.signerBirthCountry = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.STRING) {
        this.signerBirthDate = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.STRING) {
        this.signerBirthDistrict = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.STRING) {
        this.signerCity = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.STRING) {
        this.signerCountry = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 14:
      if (ftype == Thrift.Type.STRING) {
        this.signerDistrict = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 15:
      if (ftype == Thrift.Type.STRING) {
        this.signerCap = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 16:
      if (ftype == Thrift.Type.STRING) {
        this.signerAddress = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 17:
      if (ftype == Thrift.Type.STRING) {
        this.signerEmail = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 18:
      if (ftype == Thrift.Type.STRING) {
        this.signerDocumentIssuer = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 19:
      if (ftype == Thrift.Type.STRING) {
        this.signerDocumentNumber = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 20:
      if (ftype == Thrift.Type.STRING) {
        this.signerDocumentEndDate = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 21:
      if (ftype == Thrift.Type.STRING) {
        this.signerDocumentType = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 22:
      if (ftype == Thrift.Type.STRING) {
        this.cellNumber = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.FormData.prototype.write = function(output) {
  output.writeStructBegin('FormData');
  if (this.pin !== null && this.pin !== undefined) {
    output.writeFieldBegin('pin', Thrift.Type.STRING, 1);
    output.writeString(this.pin);
    output.writeFieldEnd();
  }
  if (this.savePin !== null && this.savePin !== undefined) {
    output.writeFieldBegin('savePin', Thrift.Type.BOOL, 2);
    output.writeBool(this.savePin);
    output.writeFieldEnd();
  }
  if (this.signerFirstName !== null && this.signerFirstName !== undefined) {
    output.writeFieldBegin('signerFirstName', Thrift.Type.STRING, 3);
    output.writeString(this.signerFirstName);
    output.writeFieldEnd();
  }
  if (this.signerLastName !== null && this.signerLastName !== undefined) {
    output.writeFieldBegin('signerLastName', Thrift.Type.STRING, 4);
    output.writeString(this.signerLastName);
    output.writeFieldEnd();
  }
  if (this.signerFiscalCode !== null && this.signerFiscalCode !== undefined) {
    output.writeFieldBegin('signerFiscalCode', Thrift.Type.STRING, 5);
    output.writeString(this.signerFiscalCode);
    output.writeFieldEnd();
  }
  if (this.signerFiscalCountry !== null && this.signerFiscalCountry !== undefined) {
    output.writeFieldBegin('signerFiscalCountry', Thrift.Type.STRING, 6);
    output.writeString(this.signerFiscalCountry);
    output.writeFieldEnd();
  }
  if (this.signerSex !== null && this.signerSex !== undefined) {
    output.writeFieldBegin('signerSex', Thrift.Type.STRING, 7);
    output.writeString(this.signerSex);
    output.writeFieldEnd();
  }
  if (this.signerBirthCity !== null && this.signerBirthCity !== undefined) {
    output.writeFieldBegin('signerBirthCity', Thrift.Type.STRING, 8);
    output.writeString(this.signerBirthCity);
    output.writeFieldEnd();
  }
  if (this.signerBirthCountry !== null && this.signerBirthCountry !== undefined) {
    output.writeFieldBegin('signerBirthCountry', Thrift.Type.STRING, 9);
    output.writeString(this.signerBirthCountry);
    output.writeFieldEnd();
  }
  if (this.signerBirthDate !== null && this.signerBirthDate !== undefined) {
    output.writeFieldBegin('signerBirthDate', Thrift.Type.STRING, 10);
    output.writeString(this.signerBirthDate);
    output.writeFieldEnd();
  }
  if (this.signerBirthDistrict !== null && this.signerBirthDistrict !== undefined) {
    output.writeFieldBegin('signerBirthDistrict', Thrift.Type.STRING, 11);
    output.writeString(this.signerBirthDistrict);
    output.writeFieldEnd();
  }
  if (this.signerCity !== null && this.signerCity !== undefined) {
    output.writeFieldBegin('signerCity', Thrift.Type.STRING, 12);
    output.writeString(this.signerCity);
    output.writeFieldEnd();
  }
  if (this.signerCountry !== null && this.signerCountry !== undefined) {
    output.writeFieldBegin('signerCountry', Thrift.Type.STRING, 13);
    output.writeString(this.signerCountry);
    output.writeFieldEnd();
  }
  if (this.signerDistrict !== null && this.signerDistrict !== undefined) {
    output.writeFieldBegin('signerDistrict', Thrift.Type.STRING, 14);
    output.writeString(this.signerDistrict);
    output.writeFieldEnd();
  }
  if (this.signerCap !== null && this.signerCap !== undefined) {
    output.writeFieldBegin('signerCap', Thrift.Type.STRING, 15);
    output.writeString(this.signerCap);
    output.writeFieldEnd();
  }
  if (this.signerAddress !== null && this.signerAddress !== undefined) {
    output.writeFieldBegin('signerAddress', Thrift.Type.STRING, 16);
    output.writeString(this.signerAddress);
    output.writeFieldEnd();
  }
  if (this.signerEmail !== null && this.signerEmail !== undefined) {
    output.writeFieldBegin('signerEmail', Thrift.Type.STRING, 17);
    output.writeString(this.signerEmail);
    output.writeFieldEnd();
  }
  if (this.signerDocumentIssuer !== null && this.signerDocumentIssuer !== undefined) {
    output.writeFieldBegin('signerDocumentIssuer', Thrift.Type.STRING, 18);
    output.writeString(this.signerDocumentIssuer);
    output.writeFieldEnd();
  }
  if (this.signerDocumentNumber !== null && this.signerDocumentNumber !== undefined) {
    output.writeFieldBegin('signerDocumentNumber', Thrift.Type.STRING, 19);
    output.writeString(this.signerDocumentNumber);
    output.writeFieldEnd();
  }
  if (this.signerDocumentEndDate !== null && this.signerDocumentEndDate !== undefined) {
    output.writeFieldBegin('signerDocumentEndDate', Thrift.Type.STRING, 20);
    output.writeString(this.signerDocumentEndDate);
    output.writeFieldEnd();
  }
  if (this.signerDocumentType !== null && this.signerDocumentType !== undefined) {
    output.writeFieldBegin('signerDocumentType', Thrift.Type.STRING, 21);
    output.writeString(this.signerDocumentType);
    output.writeFieldEnd();
  }
  if (this.cellNumber !== null && this.cellNumber !== undefined) {
    output.writeFieldBegin('cellNumber', Thrift.Type.STRING, 22);
    output.writeString(this.cellNumber);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.ValidationException = module.exports.ValidationException = function(args) {
  Thrift.TException.call(this, "com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.ValidationException")
  this.name = "com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.ValidationException"
  this.code = null;
  this.message = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Thrift.inherits(com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.ValidationException, Thrift.TException);
com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.ValidationException.prototype.name = 'ValidationException';
com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.ValidationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.ValidationException.prototype.write = function(output) {
  output.writeStructBegin('ValidationException');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.GenericError = module.exports.GenericError = function(args) {
  Thrift.TException.call(this, "com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.GenericError")
  this.name = "com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.GenericError"
  this.code = null;
  this.message = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Thrift.inherits(com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.GenericError, Thrift.TException);
com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.GenericError.prototype.name = 'GenericError';
com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.GenericError.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

com.teamsystem.hub.b2b.digital.comm.sign.thrift.common.GenericError.prototype.write = function(output) {
  output.writeStructBegin('GenericError');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};
