import get from "lodash/get";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { CRAM_FRONT_WEBAPP_URL } from "../../../config";
import PortaleChildApp from "../../portale-child-app";

class CramFrontWebapp extends Component {
    static propTypes = {
        auth: PropTypes.object
    };

    render() {
        return (
            <PortaleChildApp
                url={CRAM_FRONT_WEBAPP_URL}
                useOldLaunchDataFormat={true}
                hideElevioAndUservoice={true}
                customLaunchData={{ refreshToken: get(this.props.auth, "loginAuth.refreshToken") }}
            />
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(CramFrontWebapp);
