import React, { useEffect } from "react";

import { Tooltip } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { faFileCsv, faSpinner } from "@fortawesome/pro-regular-svg-icons";

import { downloadCsv, formatCell } from "../../../../utils/download";
import { IconContainer, TitleContainer, Description } from "../styled";

export const ConfigureManagedItemsTitle = ({
    provisioningData,
    displayErrorToast,
    downloadProvisioningData,
    resetDownloadProvisioningData
}) => {
    const { formatMessage } = useIntl();

    useEffect(() => {
        if (provisioningData.status.ended) {
            const conn = provisioningData.payload.data.map(c => {
                return {
                    [formatMessage({
                        id: `general.taxId`
                    })]: formatCell(c.itemId),
                    [formatMessage({
                        id: `general.description`
                    })]: formatCell(c.itemDesc),
                    [formatMessage({
                        id: `general.stato.richiesta`
                    })]: formatCell(formatMessage({ id: `c-cct-status-${c.status}` })),
                    [formatMessage({
                        id: `general.creation-date`
                    })]: formatCell(c.statusDetails.createdAt),
                    [formatMessage({
                        id: `general.modify-date`
                    })]: formatCell(c.statusDetails.updatedAt),
                    [formatMessage({
                        id: `c-cct-provisioning.rejected`
                    })]: formatCell(c.description)
                };
            });

            let header = Object.keys(conn[0]).join(",");
            let values = conn.map(p => Object.values(p).join(",")).join("\n");

            let csvContent = header + "\n" + values;
            downloadCsv(csvContent, "provisioning.csv");
            resetDownloadProvisioningData();
        }
    }, [provisioningData, formatMessage, resetDownloadProvisioningData]);

    useEffect(() => {
        if (provisioningData.status.error) {
            displayErrorToast(formatMessage({ id: "c-link-list.download-connections.error" }));
            resetDownloadProvisioningData();
        }
    }, [provisioningData.status, displayErrorToast, formatMessage, resetDownloadProvisioningData]);

    return (
        <TitleContainer>
            <Description>
                <FormattedMessage id="c-configure-managed-items.title" />
                {downloadProvisioningData && (
                    <Tooltip title={formatMessage({ id: "c-configure-managed-items-downloads.title" })}>
                        <IconContainer
                            spin={provisioningData.status.started}
                            icon={provisioningData.status.started ? faSpinner : faFileCsv}
                            onClick={() => downloadProvisioningData()}
                        />
                    </Tooltip>
                )}
            </Description>
        </TitleContainer>
    );
};

ConfigureManagedItemsTitle.defaultProps = {
    provisioningData: { status: {} }
};
