import React from "react";
import PropTypes from "prop-types";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@ts-digital/vrc";

import { Header, Title, ButtonContainer, SubTitle, TitleContainer, RightButtonContainer } from "./styled";

const PageTitle = ({
    onRightButtonClick,
    title,
    subtitle = "",
    onBack,
    rightButtonTitle,
    rightButtonKind = "secondary",
    rightButtonIcon
}) => (
    <Header>
        <ButtonContainer>
            {onBack && (
                <Button size="large" kind={"tertiary"} onClick={onBack}>
                    <FontAwesomeIcon icon={faArrowLeft} /> <FormattedMessage id="general.back" />
                </Button>
            )}
        </ButtonContainer>
        <TitleContainer>
            <Title>{title}</Title>
            {subtitle && <SubTitle>{subtitle}</SubTitle>}
        </TitleContainer>
        <RightButtonContainer>
            {onRightButtonClick && (
                <Button kind={rightButtonKind} onClick={onRightButtonClick}>
                    {rightButtonIcon} {rightButtonTitle}
                </Button>
            )}
        </RightButtonContainer>
    </Header>
);

PageTitle.propTypes = {
    goToHistory: PropTypes.func,
    title: PropTypes.any.isRequired,
    subtitle: PropTypes.any,
    onBack: PropTypes.func,
    rightButtonKind: PropTypes.oneOf(["secondary", "tertiary"]),
    rightButtonIcon: PropTypes.object
};

export default PageTitle;
