import {
    GET_TSPAY_CONFIG_START,
    GET_TSPAY_CONFIG_SUCCESS,
    GET_TSPAY_CONFIG_ERROR,
    TSPAY_ONBOARDING_SUCCESS
} from "../../actions/services/tspay.js";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export default function tspay(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_TSPAY_CONFIG_START:
            return {
                status: {
                    ...defaultState.status,
                    started: true
                }
            };
        case GET_TSPAY_CONFIG_SUCCESS:
            return {
                status: {
                    ...defaultState.status,
                    ended: true
                },
                apikeyStatus: payload.status,
                onboardingSessions: payload.onboardingSessions
            };
        case GET_TSPAY_CONFIG_ERROR:
            return {
                status: {
                    ...defaultState.status,
                    error: true,
                    errorInfo: { code: error.code, message: error.message, itemId: payload.itemId }
                }
            };
        //update ottimistico quando lancio l'onboarding
        case TSPAY_ONBOARDING_SUCCESS:
            return {
                status: {
                    ...defaultState.status,
                    ended: true
                },
                apikeyStatus: "ONBOARDING",
                onboardingSessions: [
                    {
                        error: "",
                        sessionKey: "",
                        sessionUrl: "",
                        state: "",
                        userId: ""
                    }
                ]
            };
        default:
            return state;
    }
}
