import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";

import "../style.css";

import GdprLog from "../../../components/gdpr-log";
import { getGdprLogRoleAssociation, setGdprLogRoleAssociation } from "../../../actions/services/gdprlog";
import { companyPec } from "../../../actions/contract";
import { errorBoundary } from "../../../components/general/error-boundary";
import PageTitle from "../../../components/general/page-title";

import { getWaitingList } from "../../../actions/waitinglist/read";
import { subscribeWaitinglist, deleteWaitinglist } from "../../../actions/waitinglist/write";

class GdprLogView extends React.Component {
    static propTypes = {
        company: PropTypes.object.isRequired,
        companyPec: PropTypes.func.isRequired,
        history: PropTypes.object,
        services: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        users: PropTypes.object.isRequired,
        usersGdprEnabled: PropTypes.object.isRequired,
        getGdprLogRoleAssociation: PropTypes.func.isRequired,
        setGdprLogRoleAssociation: PropTypes.func.isRequired,

        emailPec: PropTypes.object.isRequired
    };

    componentDidMount() {
        const { getGdprLogRoleAssociation, company, companyPec } = this.props;
        companyPec(company);
        getGdprLogRoleAssociation(company.base.id);
    }
    render() {
        const {
            company,
            history,
            services,
            user,
            users,
            usersGdprEnabled,
            setGdprLogRoleAssociation,
            emailPec,
            getWaitingList,
            getWaitingListStatus,
            subscribeWaitinglist,
            deleteWaitinglist,
            waitinglistDeleteStatus,
            waitinglist,
            waitinglistSubscribeStatus
        } = this.props;
        return (
            <div className="service-view">
                <PageTitle title={<FormattedMessage id="gdpr.card.services.title" />} onBack={() => history.goBack()} />
                <GdprLog
                    company={company}
                    services={services}
                    listUsersGdprEnabled={usersGdprEnabled}
                    setGdprLogRoleAssociation={setGdprLogRoleAssociation}
                    user={user}
                    users={users}
                    emailPec={emailPec}
                    getWaitingList={getWaitingList}
                    getWaitingListStatus={getWaitingListStatus}
                    subscribeWaitinglist={subscribeWaitinglist}
                    deleteWaitinglist={deleteWaitinglist}
                    waitinglistDeleteStatus={waitinglistDeleteStatus}
                    waitinglist={waitinglist}
                    waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    services: state.services,
    user: state.user,
    users: state.users,
    usersGdprEnabled: state.services.gdprlog,
    emailPec: state.contracts.pec,
    waitinglist: state.waitinglist.read.getWaitingList.waitinglist,
    waitinglistSubscribeStatus: state.waitinglist.write.subscribeWaitinglist.status,
    waitinglistDeleteStatus: state.waitinglist.write.deleteWaitinglist.status,
    getWaitingListStatus: state.waitinglist.read.getWaitingList.status
});

const actions = {
    companyPec,
    getGdprLogRoleAssociation,
    setGdprLogRoleAssociation,
    getWaitingList,
    subscribeWaitinglist,
    deleteWaitinglist
};

const composedHoc = compose(connect(mapStateToProps, actions), errorBoundary);

export default composedHoc(GdprLogView);
