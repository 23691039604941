import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedHTMLMessage } from "react-intl";
import { Button, Icon, Upload } from "antd";

export class UploadField extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        onChange: PropTypes.func,
        value: PropTypes.array
    };

    handleRemove(file) {
        const { onChange } = this.props;
        onChange([]);
    }

    handleBeforeUpload(file) {
        const { onChange } = this.props;

        var fileContent = "";
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = evt => {
            fileContent = evt.target.result;
            fileContent = fileContent.substring(fileContent.indexOf("base64,") + 7, fileContent.length);

            onChange([{ name: file.name, uid: file.uid, fileContent }]);
        };

        return false;
    }

    render() {
        const { value } = this.props;

        return (
            <div>
                <Upload
                    accept="application/pdf"
                    beforeUpload={file => this.handleBeforeUpload(file)}
                    onRemove={file => this.handleRemove(file)}
                    fileList={value}
                    multiple={false}
                >
                    <Button disabled={value.length > 0}>
                        <Icon type="upload" /> <FormattedHTMLMessage id="c-upload-field.choose-file" />
                    </Button>
                </Upload>
            </div>
        );
    }
}

export default UploadField;
