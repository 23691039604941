import { NOTIFICATION_WRITE_URL } from "../../config";
import { postRestCall } from "../../utils/rest-api-call";
import { getUserProfile } from "../../utils/state-utils/get-user-profile";
import { getAuthWithAppName } from "../auth";

if (!NOTIFICATION_WRITE_URL) {
    throw new Error("Undefined env NOTIFICATION_WRITE_URL");
}

export const ACCEPT_NOTIFICATION_START = "ACCEPT_NOTIFICATION_START";
export const ACCEPT_NOTIFICATION_ERROR = "ACCEPT_NOTIFICATION_ERROR";
export const ACCEPT_NOTIFICATION_SUCCESS = "ACCEPT_NOTIFICATION_SUCCESS";

export function acceptNotification(notificationId, note) {
    return async (dispatch, getState) => {
        dispatch({
            type: ACCEPT_NOTIFICATION_START,
            payload: { notificationId, note }
        });

        const request = { notification: { notificationId, note } };
        const requestUrl = NOTIFICATION_WRITE_URL + "/notification/accept";

        try {
            const auth = getAuthWithAppName(getState().auth);
            const response = await postRestCall(
                requestUrl,
                auth,
                null,
                request,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                undefined,
                true
            );

            dispatch({
                type: ACCEPT_NOTIFICATION_SUCCESS,
                payload: response
            });
        } catch (e) {
            dispatch({
                type: ACCEPT_NOTIFICATION_ERROR,
                payload: { error: e, notificationId }
            });
        }
    };
}

export const REJECT_NOTIFICATION_START = "REJECT_NOTIFICATION_START";
export const REJECT_NOTIFICATION_ERROR = "REJECT_NOTIFICATION_ERROR";
export const REJECT_NOTIFICATION_SUCCESS = "REJECT_NOTIFICATION_SUCCESS";

export function rejectNotification(notificationId, note) {
    return async (dispatch, getState) => {
        dispatch({
            type: REJECT_NOTIFICATION_START,
            payload: { notificationId, note }
        });

        const request = { notification: { notificationId, note } };
        const requestUrl = NOTIFICATION_WRITE_URL + "/notification/reject";

        try {
            const auth = getAuthWithAppName(getState().auth);
            const response = await postRestCall(
                requestUrl,
                auth,
                null,
                request,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                undefined,
                true
            );

            dispatch({
                type: REJECT_NOTIFICATION_SUCCESS,
                payload: response
            });
        } catch (e) {
            dispatch({
                type: REJECT_NOTIFICATION_ERROR,
                payload: { error: e, notificationId }
            });
        }
    };
}

export const READ_NOTIFICATION_START = "READ_NOTIFICATION_START";
export const READ_NOTIFICATION_ERROR = "READ_NOTIFICATION_ERROR";
export const READ_NOTIFICATION_SUCCESS = "READ_NOTIFICATION_SUCCESS";

export function readNotification(notificationId, note) {
    return async (dispatch, getState) => {
        dispatch({
            type: READ_NOTIFICATION_START,
            payload: { notificationId, note }
        });

        const request = { notification: { notificationId, note } };
        const requestUrl = NOTIFICATION_WRITE_URL + "/notification/read";

        try {
            const auth = getAuthWithAppName(getState().auth);
            const response = await postRestCall(
                requestUrl,
                auth,
                null,
                request,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                undefined,
                true
            );

            dispatch({
                type: READ_NOTIFICATION_SUCCESS,
                payload: response
            });
        } catch (e) {
            dispatch({
                type: READ_NOTIFICATION_ERROR,
                payload: { error: e, notificationId }
            });
        }
    };
}

export const UNREAD_NOTIFICATION_START = "UNREAD_NOTIFICATION_START";
export const UNREAD_NOTIFICATION_ERROR = "UNREAD_NOTIFICATION_ERROR";
export const UNREAD_NOTIFICATION_SUCCESS = "UNREAD_NOTIFICATION_SUCCESS";

export function markNotificationAsUnread(notificationId, note) {
    return async (dispatch, getState) => {
        dispatch({
            type: UNREAD_NOTIFICATION_START,
            payload: { notificationId, note }
        });

        const request = { notification: { notificationId, note } };
        const requestUrl = NOTIFICATION_WRITE_URL + "/notification/unread";

        try {
            const auth = getAuthWithAppName(getState().auth);
            const response = await postRestCall(
                requestUrl,
                auth,
                null,
                request,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                undefined,
                true
            );

            dispatch({
                type: UNREAD_NOTIFICATION_SUCCESS,
                payload: response
            });
        } catch (e) {
            dispatch({
                type: UNREAD_NOTIFICATION_ERROR,
                payload: { error: e, notificationId }
            });
        }
    };
}
