import React from "react";
import PropTypes from "prop-types";
import { FilterContainer, LabelContainer, SelectContainer } from "./styled";
import { FormattedMessage, useIntl } from "react-intl";
import MultiSelect from "../../../general/custom-multi-select";
import SearchBar from "../../../general/search-bar";

const Filters = ({ currentFilters, onFiltersChange }) => {
    const intl = useIntl();

    return (
        <FilterContainer>
            <SelectContainer>
                <SearchBar
                    label={intl.formatMessage({
                        id: "v-user-creation-flow.steps.select-items.filters.searchbar.label"
                    })}
                    placeholder={intl.formatMessage({
                        id: "v-user-creation-flow.steps.select-items.filters.searchbar.placeholder"
                    })}
                    onSearch={v => onFiltersChange({ fullText: v })}
                    value={currentFilters.fullText}
                    data-cy="item-searchbar"
                />
            </SelectContainer>
            <div>
                <LabelContainer>
                    <FormattedMessage id="v-user-creation-flow.steps.select-items.filters.classifier.label" />
                </LabelContainer>
                <SelectContainer>
                    <MultiSelect
                        options={[
                            {
                                label: intl.formatMessage({
                                    id: "general.classifiers.BUILDING"
                                }),
                                value: "BUILDING"
                            },
                            {
                                label: intl.formatMessage({
                                    id: "general.classifiers.COMPANY"
                                }),
                                value: "COMPANY"
                            },
                            {
                                label: intl.formatMessage({
                                    id: "general.classifiers.PERSON"
                                }),
                                value: "PERSON"
                            },
                            {
                                label: intl.formatMessage({
                                    id: "general.classifiers.STUDIO"
                                }),
                                value: "STUDIO"
                            },
                            {
                                label: intl.formatMessage({
                                    id: "general.classifiers.ASSOCIATION"
                                }),
                                value: "ASSOCIATION"
                            }
                        ]}
                        onChange={values => {
                            const selectedClassifiers = values.map(v => v.value);
                            onFiltersChange({ classifiers: selectedClassifiers });
                        }}
                        value={currentFilters.classifiers}
                        data-cy="company-type-select"
                    />
                </SelectContainer>
            </div>
        </FilterContainer>
    );
};

Filters.propTypes = {
    currentFilters: PropTypes.shape({
        fullText: PropTypes.string,
        classifiers: PropTypes.arrayOf(PropTypes.string)
    }),
    onFiltersChange: PropTypes.func
};

export default Filters;
