import React, { Component } from "react";
import PropTypes from "prop-types";

import { Modal } from "antd";
import { FormattedHTMLMessage } from "react-intl";

import "./style.css";

export class ModalGdprContract extends Component {
    static propTypes = {
        stateModalGdprContract: PropTypes.bool,
        handleCloseGdprContract: PropTypes.func,
        contractUrl: PropTypes.string
    };

    render() {
        const { stateModalGdprContract, handleCloseGdprContract, contractUrl } = this.props;

        return (
            <Modal
                title={<FormattedHTMLMessage id="gdpr.sign-contract" />}
                visible={stateModalGdprContract}
                onCancel={handleCloseGdprContract}
                footer={null}
                width="calc(80vw + 40px)"
                style={{ top: "50px", animationName: "initial" }}
            >
                <iframe
                    title={<FormattedHTMLMessage id="gdpr.sign-contract" />}
                    name="GDPR"
                    style={{ width: "80vw", height: "calc(100vh - 180px)" }}
                    src={contractUrl}
                    frameBorder="0"
                    marginWidth="0"
                    marginHeight="0"
                />
            </Modal>
        );
    }
}
