import styled from "styled-components";

export const PageContainer = styled.div`
    background-color: ${props => props.theme.colors.background.white};
    padding: 24px 0;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 1200px) {
        padding: 32px 24px;
    }
`;

export const PageContent = styled.div`
    width: 100%;
    align-self: flex-start;
`;

export const Title = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 34px;
    font-family: Cairo;
    font-weight: 600;
    line-height: 40px;
    margin: 24px 0 16px;

    @media (min-width: 1200px) {
        margin: 0 0 16px;
    }
`;

export const Description = styled.div`
    color: ${props => props.theme.colors.primary.yankeesBlue};
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    margin: 0 0 64px;
`;
