import React from "react";
import PropTypes from "prop-types";

import { useParams } from "react-router";

import BottomMenu from "./bottom-menu";
import { BottomMenuContainer, Container, TopMenuContainer } from "./styled";
import { TopMenu } from "./top-menu";
import "./style.css";

const SideMenu = ({ isStudio, meteringStatus, meteringPermissions, services, user, lastReleaseNoteDate, isPerson }) => {
    const params = useParams();

    return (
        <Container>
            <TopMenuContainer>
                <TopMenu
                    isStudio={isStudio}
                    isPerson={isPerson}
                    itemId={params.item}
                    meteringStatus={meteringStatus}
                    meteringPermissions={meteringPermissions}
                    selectedMenuItem={params.menuItem}
                    services={services}
                    user={user}
                />
            </TopMenuContainer>
            <BottomMenuContainer>
                <BottomMenu itemId={params.item} lastReleaseNoteDate={lastReleaseNoteDate} services={services} />
            </BottomMenuContainer>
        </Container>
    );
};

SideMenu.propTypes = {
    isStudio: PropTypes.bool,
    meteringStatus: PropTypes.shape({
        enabled: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.any
    }).isRequired,
    meteringPermissions: PropTypes.object.isRequired,
    services: PropTypes.shape({
        active: PropTypes.shape({
            availableToUser: PropTypes.object
        })
    }).isRequired,
    user: PropTypes.object.isRequired,
    lastReleaseNoteDate: PropTypes.object,
    isPerson: PropTypes.bool
};

export default SideMenu;
