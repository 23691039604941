import React from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "@ts-digital/vrc";

import Initials from "../../../general/user-initials";
import { faCheckCircle, faClock } from "@fortawesome/free-solid-svg-icons";

import { RolesSection } from "./roles-section";

import {
    CompanyDescription,
    CompanyDescriptionContainer,
    Description,
    Divider,
    EditButtonContainer,
    StatusContainer,
    StatusIcon,
    Title,
    TitleContainer,
    TitleColumn,
    TitleRow
} from "./styled";

export const UserDetailsDrawerContent = ({
    companyDescription,
    companyLabel,
    onEditRoles,
    isLoggedUser,
    isOpen,
    isOwner,
    selectedUser
}) => {
    return selectedUser ? (
        <>
            <TitleContainer>
                <TitleColumn>
                    <TitleRow>
                        <Initials
                            size={"80px"}
                            name={`${selectedUser.profile.firstName} ${selectedUser.profile.lastName}`}
                            fontSize={"40px"}
                        />
                    </TitleRow>
                </TitleColumn>
                <TitleColumn>
                    <TitleRow>
                        <Title>{`${selectedUser.profile.firstName} ${selectedUser.profile.lastName}`}</Title>
                    </TitleRow>
                    <TitleRow>
                        <Description>{`${selectedUser.profile.id}`}</Description>
                    </TitleRow>
                    <TitleRow>
                        <StatusContainer>
                            <StatusIcon
                                icon={selectedUser.status.active ? faCheckCircle : faClock}
                                active={selectedUser.status.active.toString()}
                            />
                            <FormattedMessage
                                id={`c-registry-user.user-${selectedUser.status.active ? "active" : "not-active"}`}
                            />
                        </StatusContainer>
                    </TitleRow>
                </TitleColumn>
            </TitleContainer>
            <Divider />
            <CompanyDescriptionContainer>
                <Description>{companyLabel}</Description>
                <CompanyDescription>{companyDescription}</CompanyDescription>
            </CompanyDescriptionContainer>
            <Divider />
            <RolesSection isDrawerOpen={isOpen} enhancedRoles={selectedUser.enhancedRoles} />
            <EditButtonContainer>
                <Button
                    kind="primary"
                    data-cy={"manage-users-drawer-edit"}
                    onClick={() => onEditRoles(selectedUser.profile.id)}
                    disabled={isOwner || isLoggedUser}
                    data-gainsight-track={"user-list-edit-user-drawer"}
                >
                    <FormattedMessage id="general.edit-users" />
                </Button>
            </EditButtonContainer>
        </>
    ) : (
        <></>
    );
};
