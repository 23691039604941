import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { getAllCompanies } from "../../../actions/companies";
import { loadUser } from "../../../actions/user";

import { StyledLabel, StyledSelect } from "./styled";

const { Option } = StyledSelect;

export const CompaniesSelect = ({
    auth,
    companies,
    companiesStatus,
    defaultValue,
    user,
    getAllCompanies,
    handleChange,
    loadUser
}) => {
    const [options, setOptions] = useState([]);
    const [defaultOption, setDefaultOptions] = useState();

    useEffect(() => {
        if (companies.length === 0 && !companiesStatus.started) {
            getAllCompanies();
        }
    }, [companies, companiesStatus, getAllCompanies]);

    useEffect(() => {
        if (companies.length > 0 && !companiesStatus.started) {
            const companiesOptions = companies.map(company => {
                return {
                    id: company.id,
                    description: (company.details && company.details.description) || ""
                };
            });

            setOptions(companiesOptions);
        }
    }, [companies, defaultValue, companiesStatus]);

    useEffect(() => {
        setDefaultOptions(defaultValue && companies.map(option => option.id).find(id => id === defaultValue));
    }, [defaultValue, companies]);

    return (
        <>
            {options.length > 0 && (
                <>
                    <StyledLabel>
                        <FormattedMessage id="c-notifications-center.companiesSelect.label" />
                    </StyledLabel>
                    <StyledSelect
                        defaultValue={defaultOption}
                        onChange={handleChange}
                        placeholder={<FormattedMessage id="c-notifications-center.companiesSelect.placeholder" />}
                        size={"large"}
                    >
                        {options.map(option => (
                            <Option key={`select-option-${option.id}`} value={option.id}>
                                {option.description}
                            </Option>
                        ))}
                    </StyledSelect>
                </>
            )}
        </>
    );
};

CompaniesSelect.propTypes = {
    auth: PropTypes.object.isRequired,
    companies: PropTypes.array,
    companiesStatus: PropTypes.object,
    defaultValue: PropTypes.string,
    user: PropTypes.object,
    getAllCompanies: PropTypes.func.isRequired,
    handleChange: PropTypes.func,
    loadUser: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        auth: state.auth.loginAuth,
        companies:
            state.companies && state.companies.totalRoleAdd.items
                ? Object.values(state.companies.totalRoleAdd.items).map(i => i.item.base)
                : [],
        companiesStatus: state.companies.totalRoleAdd.status,
        user: state.user
    };
}

export default connect(mapStateToProps, {
    getAllCompanies,
    loadUser
})(CompaniesSelect);
