import React from "react";
import PropTypes from "prop-types";

import { Select, Form } from "antd";

const FormSelectWithAddon = ({
    input,
    label,
    meta: { touched, error },
    children,
    onSelectChange,
    showSearch,
    ...custom
}) => (
    <Form.Item help={touched && error} validateStatus={touched && error ? "error" : ""}>
        <div className="selectWrap">
            <span className="labelForm">{label}</span>
            <Select
                defaultValue={custom.defaultValue}
                placeholder={label}
                children={children}
                showSearch={showSearch}
                className="selectInput"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                {...custom}
                {...input}
                onChange={e => {
                    onSelectChange && onSelectChange(e);
                    input.onChange(e);
                }}
            />
        </div>
    </Form.Item>
);

FormSelectWithAddon.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.array.isRequired]),
    input: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    meta: PropTypes.object.isRequired,
    onSelectChange: PropTypes.func,
    showSearch: PropTypes.bool
};

FormSelectWithAddon.defaultProps = {
    input: {
        onChange: () => {}
    },
    meta: {},
    showSearch: true
};

export default FormSelectWithAddon;
