import styled from "styled-components";

export const Title = styled.div`
    color: rgb(0, 80, 117);
    font-family: Cairo;
    font-size: 34px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;

    margin-bottom: 8px;
`;

export const Description = styled.div`
    color: rgb(21, 41, 53);
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    height: 48px;
    letter-spacing: 0.44px;
    line-height: 24px;
`;

export const TitleContainer = styled.div``;

export const SearchInputContainer = styled.div`
    margin-top: 16px;
    margin-bottom: 16px;
`;

export const PaginationContainer = styled.div`
    margin-bottom: 16px;

    display: flex;
    justify-content: flex-end;
`;

export const CompaniesContainer = styled.div``;
