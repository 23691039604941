import styled from "styled-components";

import { Menu } from "antd";

export const ReleaseNoteLink = styled.a`
    color: hsla(0, 0%, 100%, 0.65);

    &:hover {
        color: #fff;
    }
`;

export const PrivacyLink = styled.a`
    margin-left: 3px;
`;

export const MenuItemDisabled = styled(Menu.Item)`
    &.ant-menu-item-disabled {
        cursor: auto;
    }
`;
