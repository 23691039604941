import { combineReducers } from "redux";
import { logos } from "./logos";

import {
    DELETE_LOGO_ERROR,
    DELETE_LOGO_RESET,
    DELETE_LOGO_START,
    DELETE_LOGO_SUCCESS
} from "../../actions/manage-logo/index";

import {
    UPLOAD_LOGO_START,
    UPLOAD_LOGO_ERROR,
    UPLOAD_LOGO_SUCCESS,
    UPLOAD_LOGO_RESET
} from "../../actions/manage-logo/index";

import { GET_LOGO_ERROR, GET_LOGO_RESET, GET_LOGO_START, GET_LOGO_SUCCESS } from "../../actions/manage-logo/index";

const defaultState = {
    started: false,
    ended: false,
    error: false,
    errorInfo: { message: "" }
};

export const deleteLogo = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case DELETE_LOGO_START:
            return { ...defaultState, started: true };
        case DELETE_LOGO_ERROR:
            return { ...defaultState, error: true, errorInfo: error };
        case DELETE_LOGO_SUCCESS:
            return { ...defaultState, ended: true };
        case DELETE_LOGO_RESET:
            return { ...defaultState };
        default:
            return state;
    }
};

export const uploadLogo = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case UPLOAD_LOGO_START:
            return { ...defaultState, started: true };
        case UPLOAD_LOGO_ERROR:
            return { ...defaultState, error: true, errorInfo: error };
        case UPLOAD_LOGO_SUCCESS:
            return { ...defaultState, ended: true };
        case UPLOAD_LOGO_RESET:
            return { ...defaultState };
        default:
            return state;
    }
};

export const getLogo = (state = defaultState, { type, error }) => {
    switch (type) {
        case GET_LOGO_START:
            return { ...defaultState, started: true };
        case GET_LOGO_ERROR:
            return { ...defaultState, error: true, errorInfo: error };
        case GET_LOGO_SUCCESS:
            return { ...defaultState, ended: true };
        case GET_LOGO_RESET:
            return { ...defaultState };
        default:
            return state;
    }
};

export default combineReducers({ getLogo, logos, uploadLogo, deleteLogo });
