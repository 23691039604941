import React from "react";
import PropTypes from "prop-types";

import { FormattedDate } from "react-intl";

const DateTime = ({ displayTime, value }) => {
    return displayTime ? (
        <FormattedDate value={value} year="2-digit" month="2-digit" day="2-digit" hour="2-digit" minute="2-digit" />
    ) : (
        <FormattedDate value={value} year="2-digit" month="2-digit" day="2-digit" />
    );
};

DateTime.propTypes = {
    displayTime: PropTypes.bool,
    value: PropTypes.any
};

DateTime.defaultProps = {
    displayTime: true,
    value: 0
};

export default DateTime;
