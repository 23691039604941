import React from "react";
import PropTypes from "prop-types";

import { includes } from "ramda";
import { connect } from "react-redux";
import { compose } from "redux";

import "../style.css";

import { activateService } from "../../../actions/services/service-subscription";
import { getSdiConfig, listSdiConfigs, updateConfigs, resetSdiConfigs } from "../../../actions/services/sdi";

import Billing from "../../../components/billing";
import { FormattedMessage } from "react-intl";
import { errorBoundary } from "../../../components/general/error-boundary";
import PageTitle from "../../../components/general/page-title";

export class BillingView extends React.Component {
    static propTypes = {
        activateService: PropTypes.func.isRequired,
        updateConfigs: PropTypes.func.isRequired,
        company: PropTypes.object.isRequired,
        getSdiConfig: PropTypes.func.isRequired,
        history: PropTypes.object,
        listSdiConfigs: PropTypes.func.isRequired,
        resetSdiConfigs: PropTypes.func.isRequired,
        services: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired
    };

    componentDidMount() {
        const { company, getSdiConfig, listSdiConfigs, resetSdiConfigs, services } = this.props;
        if (services.active.services) {
            if (includes("SDI-FLOW", services.active.services)) {
                resetSdiConfigs();
                if (company.base.details.classifier === "COMPANY") {
                    listSdiConfigs([company.base.id], company.base.id);
                } else {
                    getSdiConfig(company.base.id);
                }
            }
        }
    }

    render() {
        const { activateService, updateConfigs, company, history, services, user } = this.props;
        return (
            <div className="service-view">
                <PageTitle title={<FormattedMessage id="c-services-view.EIP.name" />} onBack={() => history.goBack()} />
                <Billing
                    activateService={activateService}
                    updateConfigs={updateConfigs}
                    company={company}
                    services={services}
                    user={user}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    services: state.services,
    user: state.user
});

const actions = {
    activateService,
    getSdiConfig,
    listSdiConfigs,
    resetSdiConfigs,
    updateConfigs
};

const composedHoc = compose(connect(mapStateToProps, actions), errorBoundary);

export default composedHoc(BillingView);
