import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../components/general/modal";

export const StyledModal = styled(Modal)`
    max-width: ${props => props.maxWidth};

    .ant-modal-content {
        margin: 8px;
        border-radius: 0px;
    }

    .ant-modal-header {
        padding: 0;
        border-bottom: 1px solid ${props => props.theme.colors.primary.columbiaBlue};
    }

    .ant-modal-title {
        color: ${props => props.theme.colors.primary.darkImperialBlue};
        font-size: 24px;
        font-family: Cairo;
        font-weight: 600;
        line-height: 80px;
        height: 80px;
        padding-left: 24px;
    }

    .ant-modal-body {
        font-size: 16px;
        font-family: Roboto;
        line-height: 24px;
        padding: 24px 32px;
        padding-bottom: 60px;
        min-height: 430px;
    }

    .ant-modal-footer {
        padding: 8px;
        background-color: ${props => props.theme.colors.background.whiteSmoke};
        border: none;
        display: flex;
        justify-content: flex-end;
    }
`;

export const Icon = styled(FontAwesomeIcon)`
    font-size: 24px;
    color: ${props => props.theme.colors.primary.richElectricBlue};
`;
