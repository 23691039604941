export default {
    //general
    "general.ts": "TeamSystem",
    "general.notifications": "Notificaciones",
    "general.update": "Actualizar",
    "general.select": "Seleccione",
    "general.stato": "Estado",
    "general.codice-fiscale": "Código de Identificación Fiscal",
    "general.partita-iva": "Número de identificación Fiscal",
    "general.discover": "Descubres",
    "general.dashboard": "Dashboard",
    "general.connections": "Conexiones",
    "general.users": "Utilidades",
    "general.applications": "Aplicaciones",
    "general.offices": "Oficinas",
    "general.anagrafica": "Registro",
    "general.cookie": "Cookie",
    "general.servicesTab": "Pestaña de servicios",
    "general.informations": "Información",
    "general.quit": "Salir",
    "general.user.common": "Usuario",
    "general.edit-profile": "Editar perfil",
    "general.linked": "Conectado",
    "general.not-linked": "No conectado",
    "general.CF": "CIF",
    "general.PIVA": "NIF",
    "general.edit": "Editar",
    "general.copyAuthToken": "Copiar token de usuario",
    "general.back": "Regresar",

    //profile
    "profile.header.tsID_state": "Estado TSID: ",
    "profile.header.email": "Email: ",
    "profile.header.tsId": "TsId: ",

    /*Company status */
    "general.status.VALIDATED": "VALIDADO",
    "general.status.UNVERIFIABLE": "NO VALIDADO",
    "general.status.UNVERIFIABLE_PENDING_VALIDATE": "ESPERANDO VALIDACIÓN",
    "general.status.REJECTED": "RECHAZADO",
    "general.status.REJECTED_PENDING_VALIDATE": "ESPERANDO VALIDACIÓN",

    /*Company certification status */
    "general.status.CERTIFIED": "CERTIFICADO",
    "general.status.AWAITING_UPLOAD": "ESPERANDO RECERTIFICACION",
    "general.status.AWAITING_APPROVAL": "CERTIFICACIÓN EN ESPERA DE VERIFICACIÓN",

    //notifications
    "notifications-settings": "Configuración de notificaciones",
    "no-notification": "Sin notificación",
    "refresh-notification-suggestion": "Para ver nuevas notificaciones intenta actualizar la sección",
    "mark-all-as-read": "Marcar todo como leido",
    "mark-as-read": "Marcar como leído",
    "mark-as-unread": "Marcar como no leído",
    "remove-all": "Eliminar todo",
    remove: "Eliminar",
    "notifications-web-loading": "Cargando...",

    "notifications.title": "Solicitudes de conexión",
    "c-notification-list.empty-list": "No hay solicitudes de conexión.",
    "c-notification-list.list-button": "Ver todas las solicitudes de conexión",

    "notification.NEW_MANAGER_LINK_NOTIFICATION_REQUEST":
        "La empresa {sender} ({senderId}) solicitó poder administrar su servicio {service} con el permiso {permission}. ¿Acepta la solicitud?",
    "notification.NEW_MANAGER_LINK_NOTIFICATION_REQUEST.title": "Solicitud de conexión",
    "notification.NEW_MANAGER_LINK_NOTIFICATION_ACCEPT": "Su solicitud de conexión con {sender} ha sido aceptada.",
    "notification.NEW_MANAGER_LINK_NOTIFICATION_ACCEPT.title": "Conexión aceptada",
    "notification.NEW_MANAGER_LINK_NOTIFICATION_REJECT": "Su solicitud de conexión con {sender} ha sido rechazada.",
    "notification.NEW_MANAGER_LINK_NOTIFICATION_REJECT.title": "Conexión denegada",
    "notification.CONNECTION_REMOVED_NOTIFICATION_REQUEST":
        "Se ha interrumpido la conexión entre {sender} ({senderId}) y {recipient} ({recipientId}) para el servicio {service}.",
    "notification.CONNECTION_REMOVED_NOTIFICATION_REQUEST.title": "Notificación de conexión eliminada",
    "notification.permission.WRITE": "escritura",
    "notification.permission.READ_WRITE": "leyendo y escribiendo",
    "notification.permission.READ": "leyendo",
    "notification.permission.SHARE": "intercambio",
    "c-notification.status-icon.tooltip.unread": "Marcar como leído",
    "c-notification.status-icon.tooltip.read": "marcar como no leído",
    "c-notification.status-icon.tooltip.needsInteraction": "Acción requerida en la notificación",
    "c-notification.status.accepted": "Petición aceptada",
    "c-notification.status.rejected": "Solicitud rechazada",

    //pre-login
    "v-pre-login.title": "Iniciar sesión o registrarse en TeamSystem Digital",
    "v-pre-login.login-or-sign-up": "Iniciar sesión o registrarse",
    "v-pre-login.privacy-policy": "Política de privacidad",
    "v-pre-login.cookie": "Cookie",
    "v-pre-login.copyright": "© {year} TeamSystem Service",
    "v-pre-login.advertising.intuitive.title":
        "La plataforma <highlight> sencilla y sostenible</highlight> para la gestión de procesos digitales.",
    "v-pre-login.advertising.intuitive.description":
        "Los servicios de TS Digital lo ayudan a elevar su negocio y mantenerse constantemente actualizado sobre las nuevas regulaciones.",

    "logo.add-logo-text": "+ LOGO",
    "logo.add-logo-modal-text": "LOGO",

    //invitation-prelogin
    "v-invitation-prelogin.login-button-text": "Inicia sesión o regístrate",
    "v-invitation-prelogin.hi": "Hola,",
    "v-invitation-prelogin.subtitle": "Inicia sesión en TS Digital o regístrate con el TeamSystem ID",
    "v-invitation-prelogin.description":
        "El TeamSystem ID es una <b>cuenta de usuario única</b> que te permite acceder a varios programas de TeamSystem de forma segura, sin tener que recordar diferentes credenciales de inicio de sesión.",
    "v-invitation-prelogin.info":
        "<b>Atención:</b> tu cuenta sólo será válida si utilizas la siguiente dirección de correo electrónico:",
    "v-invitation-prelogin.tabs.already-have-tsid": "¿Tienes TeamSystem ID?",
    "v-invitation-prelogin.tabs.not-have-tsid": "¿No tienes TeamSystem ID?",
    "v-invitation-prelogin.already-have-tsid.title": "Inicia sesión y comienza a trabajar",
    "v-invitation-prelogin.already-have-tsid.step1.subtitle": "Inicia sesión con tu TeamSystem ID",
    "v-invitation-prelogin.already-have-tsid.step1.body-copy":
        "Introduce tus credenciales e inicia sesión con tu TeamSystem ID. Recuerda utilizar el siguiente correo electrónico: <b>{email}</b>. Si tu TeamSystem ID no está asociado al correo electrónico indicado, deberás crear uno nuevo",
    "v-invitation-prelogin.already-have-tsid.step2.subtitle": "Accede a TS Digital",
    "v-invitation-prelogin.already-have-tsid.step2.body-copy":
        "Una vez hayas accedido, <b>serás redirigido a tu cuenta de TS Digital</b> donde podrás empezar a trabajar y a colaborar con tus compañeros.",
    "v-invitation-prelogin.not-have-tsid.title": "Regístrate y comienza a trabajar",
    "v-invitation-prelogin.not-have-tsid.step1.subtitle": "Registra tu TeamSystem ID",
    "v-invitation-prelogin.not-have-tsid.step1.body-copy":
        "Accede a la página de TeamSystem ID y haz clic en <b>“¿No tienes TeamSystem ID? Regístrate“</b> y sigue el proceso para registrarte. Recuerda utilizar el siguiente correo electrónico para registrarte: <b>{email}</b>.",
    "v-invitation-prelogin.not-have-tsid.step2.subtitle": "Accede a TS Digital",
    "v-invitation-prelogin.not-have-tsid.step2.body-copy":
        "Realiza el primer acceso con tu nuevo TeamSystem ID, <b>y serás redirigido automáticamente a tu nueva cuenta {appName}</b> donde encontrarás todo lo que necesitas para trabajar y colaborar con tus compañeros",
    "v-invitation-prelogin.not-have-tsid.step2.body-copy.appName.digitalBox": "de Digital Box",
    "v-invitation-prelogin.not-have-tsid.step2.body-copy.appName.portale": "TS Digital",

    //company-dashboard
    "c-company-dashboard.section-title.my-apps": "Mis aplicaciones",
    "c-company-dashboard.section-title.suggested-apps": "Te pueden interesar",
    "c-company-dashboard.section-title.other-apps": "Otros productos en cloud",

    "c-manage-data.company.search.title": "¿Para qué empresa quieres trabajar?",
    "c-manage-data.company.search.subtitle": "Haga clic en {icon} para seleccionar la empresa",
    "c-manage-data.search-placeholder": "Busque por NIF o CIF o nombre de la empresa y luego presione enter",
    "c-manage-data.user-edit-button.popover.manage": "Ir a la lista de empresas",

    "c-manage-data.add-company-button": "Crear nueva empresa",
    "c-manage-data.company.card.status": "Estado de la empresa:",
    "c-manage-data.company.card.person-status": "Estado:",
    "c-manage-data.company.card.taxId": "CIF:",
    "c-manage-data.company.card.vatNumber": "NIF:",
    "c-manage-data.company.card.type": "Tipología:",
    "c-manage-data.company.card.company": "AZIENDA",
    "c-manage-data.company.card.studio": "STUDIO",
    "c-manage-data.company.card.building": "CONDOMINIO",
    "c-manage-data.company.card.person": "PERSONA",
    "c-manage-data.company.card.association": "ASSOCIAZIONE",
    "c-manage-data.company.card.editIcon": "Edita los datos de tu empresa",
    "c-manage-data.company.card.modifyIcon": "Ver datos de la empresa",
    "c-manage-data.company.card.notificationsIcon": "Configurar notificaciones",
    "c-manage-data.company.card.person-editIcon": "Cambiar sus datos personales",
    "c-manage-data.company.card.person-modifyIcon": "Ver datos personales",
    "c-manage-data.company.modal.edit.title": "Modificar los datos personales de la empresa",
    "c-manage-data.company.modal.display.title": "Ver los datos personales de la empresa",
    "c-manage-data.company.page.companyCount": "{start}-{end} de {total} empresas",
    //contracts
    "general.roles.OWNR": "Proprietario",
    "general.roles.LEGL": "Representante legal",
    "general.roles.PROS": "Asesor",

    //connections
    "c-connection-action-bar.title": "Conexiones",
    "c-link-list.add-connection.title": "Mis conexiones",

    "general.uservoice.accept-cookie-to-vote": "Para dejarnos su opinión, inicie sesión y active las cookies de perfil"
};
