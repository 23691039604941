import {
    ACTIVATE_SERVICE_START,
    ACTIVATE_SERVICE_ERROR,
    ACTIVATE_SERVICE_SUCCESS,
    FETCH_ACTIVE_SERVICE_START,
    FETCH_ACTIVE_SERVICE_ERROR,
    FETCH_ACTIVE_SERVICE_SUCCESS,
    ACTIVATE_SERVICE_RESET,
    FETCH_ACTIVE_SERVICE_RESET
} from "../../actions/services/service-subscription";

import { VERIFY_CCT_SUCCESS } from "../../actions/services/cct";

import {
    FETCH_ITEM_SERVICES_START,
    FETCH_ITEM_SERVICES_ERROR,
    FETCH_ITEM_SERVICES_SUCCESS,
    FETCH_ITEM_SERVICES_RESET,
    FETCH_AGYO_SERVICES_START,
    FETCH_AGYO_SERVICES_ERROR,
    FETCH_AGYO_SERVICES_SUCCESS
} from "../../actions/services/service-subscription-v2";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function activated(state = defaultState, { type, payload, error }) {
    switch (type) {
        case ACTIVATE_SERVICE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case ACTIVATE_SERVICE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case ACTIVATE_SERVICE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case ACTIVATE_SERVICE_RESET:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: false
                }
            };
        default:
            return state;
    }
}
export function active(
    state = { ...defaultState, loadedItem: undefined, services: [], availableToUser: {} },
    { type, payload, error }
) {
    switch (type) {
        case FETCH_ACTIVE_SERVICE_START:
            return {
                availableToUser: {},
                status: {
                    started: true,
                    error: false,
                    ended: false
                },
                services: state.loadedItem === payload.itemId ? state.services : [],
                loadedItem: state.loadedItem
            };
        case FETCH_ACTIVE_SERVICE_SUCCESS:
            let availableToUser =
                payload.services !== undefined
                    ? payload.services.reduce((a, b) => {
                          var key = b.agyoService.id;
                          a[key] = b.availableToUser;
                          return a;
                      }, {})
                    : {};

            if (!availableToUser["GDPR-LOG"]) {
                availableToUser["GDPR-LOG"] = true;
            }

            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                services: Object.keys(availableToUser),
                availableToUser,
                loadedItem: payload.itemId
            };
        case ACTIVATE_SERVICE_SUCCESS:
            return {
                ...state,
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                services: [...state.services, payload.serviceId]
            };
        case FETCH_ACTIVE_SERVICE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case VERIFY_CCT_SUCCESS:
            return {
                ...state,
                services: [...state.services, "CCT-FLOW"]
            };
        case FETCH_ACTIVE_SERVICE_RESET:
            return { ...defaultState, services: [], availableToUser: {} };
        default:
            return state;
    }
}

const itemServicesDefaultState = {
    ...defaultState,
    services: {}
};

export function itemServices(state = itemServicesDefaultState, { type, payload }) {
    switch (type) {
        case FETCH_ITEM_SERVICES_START:
            return {
                ...state,
                status: {
                    ...defaultState.status,
                    started: true
                }
            };
        case FETCH_ITEM_SERVICES_SUCCESS:
            return {
                status: {
                    ...defaultState.status,
                    ended: true
                },
                services: {
                    ...state.services,
                    ...payload.result.itemServices
                }
            };
        case FETCH_ITEM_SERVICES_ERROR:
            return {
                ...state,
                status: {
                    ...defaultState.status,
                    error: true,
                    errorInfo: {
                        message: payload.error
                    }
                }
            };
        case FETCH_ITEM_SERVICES_RESET:
            return {
                ...state,
                status: {
                    ...defaultState.status
                }
            };
        default:
            return state;
    }
}

export function agyoServices(state = defaultState, { type, payload }) {
    switch (type) {
        case FETCH_AGYO_SERVICES_START:
            return {
                services: [],
                ...state,
                status: {
                    ...defaultState.status,
                    started: true
                }
            };
        case FETCH_AGYO_SERVICES_ERROR:
            return {
                ...state,
                status: {
                    ...defaultState.status,
                    error: true,
                    errorInfo: payload.error
                }
            };
        case FETCH_AGYO_SERVICES_SUCCESS:
            return {
                status: {
                    ...defaultState.status,
                    ended: true
                },
                services: payload
            };
        default:
            return state;
    }
}
