import styled, { css } from "styled-components";

export const TableStyles = styled.div`
    table {
        border-collapse: collapse;
        width: 100%;
    }

    thead  {
        border-bottom: 1px solid ${props => props.theme.colors.text.quickSilver};
    }

    td,
    th {
        height: 40px;
    }
`;

export const Cell = styled.td`
    color: ${props => props.theme.colors.text.yankeesBlue};
    padding: 0px;

    & > div {
        align-items: center;
        display: flex;
        height: 40px;
        padding: 1px 8px;
        padding-bottom: 0px;
        border-bottom: 1px solid ${props => props.theme.colors.text.quickSilver};
    }
`;

export const HeaderCell = styled.th`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    padding: 0px;
    width: ${props => props.width};
    & > div {
        align-items: center;
        border: 1px solid rgba(0, 0, 0, 0);
        display: flex;
        height: 40px;
        padding: 0px 8px;
    }
`;

export const FilterCell = styled.th`
    background-color: ${props => props.theme.colors.background.whiteSmoke};
    height: 40px;
    padding: 0px;

    & div {
        margin: 0px;
    }

    & input {
        border: 1px rgba(0, 0, 0, 0);
        margin: 0px;
    }
`;

export const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const FooterRow = styled.tfoot`
    background: rgb(222, 240, 247);
    border-radius: 0px;
`;

export const Row = styled.tr`
    ${props => {
        if (props.isExpanded && props.isTopLevel) {
            return css`
                background-color: ${props => props.theme.colors.background.azureishWhite};
            `;
        }
    }}

    ${props => {
        if (props.highlightFirstRow && props.rowNumber === 0) {
            return css`
                background-color: #e4eaf0;
            `;
        }
    }}
`;
