import React from "react";

import { FormattedMessage } from "react-intl";

import { Container } from "./styled";

const PreferencesEmpty = () => (
    <Container>
        <FormattedMessage id="c-notifications-center.emptyPreferences" />
    </Container>
);

export default PreferencesEmpty;
