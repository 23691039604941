import { EDIT_ROLES_SUCCESS } from "../../actions/company/users";
import {
    FIND_ITEM_ERROR,
    FIND_ITEM_RESET,
    FIND_ITEM_START,
    FIND_ITEM_SUCCESS,
    GET_HAS_STUDIO_ERROR,
    GET_HAS_STUDIO_RESET,
    GET_HAS_STUDIO_START,
    GET_HAS_STUDIO_SUCCESS,
    LIST_ITEM_ERROR,
    LIST_ITEM_START,
    LIST_ITEM_SUCCESS,
    LIST_ITEMS_ROLE_CREATION_ERROR,
    LIST_ITEMS_ROLE_CREATION_RESET,
    LIST_ITEMS_ROLE_CREATION_START,
    LIST_ITEMS_ROLE_CREATION_SUCCESS
} from "../../actions/registry/read";

import { keyBy } from "lodash";

const defaultState = {
    status: {
        started: false,
        ended: false,
        error: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function findItems(previousState = defaultState, { type, payload, error }) {
    switch (type) {
        case FIND_ITEM_START:
            return {
                ...previousState,
                status: { ...defaultState.status, started: true }
            };
        case FIND_ITEM_ERROR:
            return {
                status: {
                    ...defaultState.status,
                    error: true,
                    errorInfo: error
                }
            };
        case FIND_ITEM_SUCCESS:
            return {
                status: { ...defaultState.status, ended: true },
                ...payload
            };
        case FIND_ITEM_RESET:
            return {
                ...defaultState
            };
        default:
            return previousState;
    }
}

export function listItems(previousState = defaultState, { type, payload }) {
    switch (type) {
        case LIST_ITEM_START:
            return { status: { ...defaultState.status, started: true } };
        case LIST_ITEM_ERROR:
            return {
                status: {
                    ...defaultState.status,
                    error: true,
                    errorInfo: { ...payload }
                }
            };
        case LIST_ITEM_SUCCESS:
            return {
                status: { ...defaultState.status, ended: true },
                ...payload
            };
        default:
            return previousState;
    }
}

const listItemsRoleCreationDefaultState = { ...defaultState, content: [], meta: {} };

export function listItemsRoleCreation(state = listItemsRoleCreationDefaultState, { type, payload, error }) {
    switch (type) {
        case LIST_ITEMS_ROLE_CREATION_START:
            return {
                ...state,
                status: {
                    ...defaultState.status,
                    started: true
                }
            };
        case LIST_ITEMS_ROLE_CREATION_ERROR:
            return {
                ...state,
                status: {
                    ...defaultState.status,
                    error: true,
                    errorInfo: error
                }
            };
        case LIST_ITEMS_ROLE_CREATION_SUCCESS:
            return {
                ...state,
                status: {
                    ...defaultState.status,
                    ended: true
                },
                ...payload
            };
        case LIST_ITEMS_ROLE_CREATION_RESET:
            return {
                ...listItemsRoleCreationDefaultState
            };
        case EDIT_ROLES_SUCCESS:
            const workspaceEnabled = keyBy(payload.localWorkspaces, "workspaceId");
            // So for each local workspace that is edited, we need to find the correct entery in this array and modify the `localServiceEnabled` key
            const newState = {
                ...state,
                content: state.content.map(it => {
                    if (workspaceEnabled[it.workspaceId]) {
                        // if something should be changed
                        return {
                            ...it,
                            localServiceEnabled: workspaceEnabled[it.workspaceId].enabled
                        };
                    }
                    return { ...it };
                })
            };
            return newState;
        default:
            return state;
    }
}

export function getHasStudio(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_HAS_STUDIO_START:
            return {
                ...state,
                status: {
                    ...defaultState.status,
                    started: true
                }
            };
        case GET_HAS_STUDIO_ERROR:
            return {
                ...state,
                status: {
                    ...defaultState.status,
                    error: true,
                    errorInfo: error
                }
            };
        case GET_HAS_STUDIO_SUCCESS:
            return {
                ...state,
                status: {
                    ...defaultState.status,
                    ended: true
                },
                ...payload
            };
        case GET_HAS_STUDIO_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}
