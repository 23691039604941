import React from "react";
import { PropTypes } from "prop-types";
import { MeteringBarContainer } from "./styled";

export const UsageBar = ({ children, className }) => (
    <MeteringBarContainer className={className}>{children}</MeteringBarContainer>
);

UsageBar.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};
