import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { Col, Row } from "antd";
import { Table } from "../../../general/table";
import SearchBar from "../../../general/search-bar";

import { Container, DescriptionCell, TableCell, TableContainer } from "./styled";
import { FormattedMessage, useIntl } from "react-intl";
import TableFooter from "./table-footer";

const SelectedCompanies = ({ selectedItems, totalCompanies }) => {
    const intl = useIntl();

    const [page, setPage] = useState(0);
    const [tableData, setTableData] = useState(selectedItems);
    const [currentFilter, setCurrentFilter] = useState("");

    useEffect(() => {
        setTableData(selectedItems);
    }, [selectedItems]);

    const onFiltersChange = filterValue => {
        setTableData(
            selectedItems.filter(
                ele =>
                    ele.description.toLowerCase().includes(filterValue.toLowerCase()) ||
                    ele.vatNumber.includes(filterValue) ||
                    ele.itemId.includes(filterValue)
            )
        );
        setCurrentFilter(filterValue);
        setPage(0);
    };

    const columns = useMemo(
        () => [
            {
                accessor: "description",
                Header: <FormattedMessage id="v-user-creation-flow.steps.select-items.table-header.item-description" />,
                Cell: props => (
                    <TableCell>
                        <DescriptionCell>{props.cell.value}</DescriptionCell>
                    </TableCell>
                ),
                width: 400
            },
            {
                accessor: "vatNumber",
                Header: <FormattedMessage id="v-user-creation-flow.steps.select-items.table-header.vat-number" />,
                Cell: props => <TableCell {...props}>{props.row.original.vatNumber || "-"}</TableCell>
            },
            {
                accessor: "taxId",
                Header: <FormattedMessage id="v-user-creation-flow.steps.select-items.table-header.tax-id" />,
                Cell: props => <TableCell {...props}>{props.cell.value}</TableCell>
            }
        ],
        []
    );

    return (
        <Container data-cy="selected-companies-step">
            <Row>
                <Col>
                    <SearchBar
                        label={intl.formatMessage({
                            id: "v-user-creation-flow.steps.select-items.filters.searchbar.label"
                        })}
                        placeholder={intl.formatMessage({
                            id: "v-user-creation-flow.steps.select-items.filters.searchbar.placeholder"
                        })}
                        onSearch={filterValue => onFiltersChange(filterValue)}
                        value={currentFilter}
                        data-cy="item-searchbar"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TableContainer>
                        <Table
                            columns={columns}
                            data={tableData}
                            page={page}
                            onPageChange={newPage => setPage(newPage)}
                            paginationType="AUTO"
                            usePagination={true}
                            pageSize={6}
                            useFooter={true}
                            footerContent={<TableFooter selectedCount={tableData.length} totalItems={totalCompanies} />}
                            data-cy="selected-companies-table"
                        />
                    </TableContainer>
                </Col>
            </Row>
        </Container>
    );
};

SelectedCompanies.propTypes = {
    selectedItems: PropTypes.arrayOf(
        PropTypes.shape({
            classifier: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            itemId: PropTypes.string.isRequired,
            itemUuid: PropTypes.string.isRequired,
            taxId: PropTypes.string.isRequired,
            vatNumber: PropTypes.string.isRequired
        })
    ).isRequired,
    totalCompanies: PropTypes.number.isRequired
};

export default SelectedCompanies;
