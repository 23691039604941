import styled from "styled-components";
import Modal from "../general/modal";

export const Container = styled.div`
    background-color: ${props => props.theme.colors.background.white};
    min-height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 72px;
`;

export const SectionTitle = styled.div`
    color: rgb(90, 104, 114);
    font-size: 20px;
    font-family: Cairo;
    font-weight: normal;
    letter-spacing: 0.15px;
    line-height: 24px;
`;

export const Subtitle = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 14px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 24px;
`;

export const Title = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 24px;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 32px;
`;

export const Footer = styled.div`
    width: 100%;
    height: 72px;
    background: rgb(242, 245, 248);
    box-shadow: 0px -2px 8px 0px rgba(21, 41, 53, 0.32);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
`;

export const SidebarTitle = styled.div`
    color: rgb(90, 104, 114);
    font-size: 20px;
    font-family: Cairo;
    font-weight: normal;
    letter-spacing: 0.15px;
    line-height: 24px;
`;

export const StyledModal = styled(Modal)`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.ant-modal {
        top: 0;
    }

    .ant-modal-content {
        height: fit-content;
    }
`;

export const ModalContent = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 24px;
`;

export const TitleContainer = styled.div`
    white-space: nowrap;
`;

export const GrowthableContainer = styled.div`
    flex: 1;
`;
