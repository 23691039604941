import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";

import { Button, StandardTabs, Tab } from "@ts-digital/vrc";
import { connect } from "react-redux";
import { generatePath, Route, useHistory, useParams, useRouteMatch } from "react-router";

import { listItemsRoleCreation } from "../../actions/registry/read";
import { getWorkspaceId } from "../../actions/services/app";
import { getWorkspacePermissions } from "../../actions/services/workspace-permissions";
import { getUserV3 } from "../../actions/company/users";
import { removeRoles, resetRemoveRoles } from "../../actions/user";
import PageTitle from "../../components/general/page-title";
import UserData from "../../components/selected-user/user-data";
import { Container, Content, Description, Divider, TabsContainer, TabsWrapper, UserDataContainer } from "./styled";
import EditUser from "./edit";
import UserSummary from "./user-summary";
import Modal from "../../components/general/modal";
import LoadingButton from "../../components/general/loading-button";
import { displayErrorToast, displaySuccessToast } from "../../actions/overflowNotification";
import ItemList from "./list-items";

const actions = ["edit", "summary"];

function getActionIndex(action) {
    return actions.findIndex(a => a === action) || 0;
}

const SelectedUserView = ({
    displayErrorToast,
    displaySuccessToast,
    getUserV3,
    getUserStatus,
    removeRoles,
    removeRolesFromUserStatus,
    resetRemoveRoles,
    selectedUserProfile,
    selectedUserStatus,
    company,
    workspaceId,
    getWorkspaceId,
    listItemsRoleCreation,
    listItems,
    getWorkspacePermissions,
    workspacePermissions
}) => {
    const history = useHistory();
    const { action, item, selectedUserId } = useParams();
    const { path } = useRouteMatch();
    const [selectedTab, setSelectedTab] = useState(0);

    const [itemForRemoval, setItemForRemoval] = useState();
    const [removalModalOpen, setRemovalModalOpen] = useState(false);

    useEffect(() => {
        setSelectedTab(getActionIndex(action));
    }, [action]);

    useEffect(() => {
        selectedUserId && getUserV3(selectedUserId);
    }, [getUserV3, selectedUserId]);

    const handleTabChange = useCallback(
        index => {
            const targetAction = actions[index];
            const targetPath = generatePath(path, { item, selectedUserId, action: targetAction });
            history.replace(targetPath);
        },
        [history, item, selectedUserId, path]
    );

    const removeUser = useCallback(() => {
        if (itemForRemoval && selectedUserProfile) {
            removeRoles(itemForRemoval, selectedUserProfile.id);
            setRemovalModalOpen(false);

            // If on the user edit page, the user must be sent back to the user list
            if (action === "edit") {
                history.push(`/${item}/registry/user`);
            }
        }
    }, [action, history, removeRoles, selectedUserProfile, item, itemForRemoval]);

    const handleRemoveUser = useCallback(i => {
        setRemovalModalOpen(true);
        setItemForRemoval(i);
    }, []);

    useEffect(() => {
        if (removeRolesFromUserStatus.ended) {
            displaySuccessToast(
                <FormattedMessage id="c-registry-user.remove-roles.toast-success.content" />,
                <FormattedMessage id="c-registry-user.remove-roles.toast-success.title" />
            );
            resetRemoveRoles();
        }
    }, [displaySuccessToast, removeRolesFromUserStatus.ended, resetRemoveRoles]);

    useEffect(() => {
        if (removeRolesFromUserStatus.error) {
            displayErrorToast(
                <FormattedMessage id="c-registry-user.remove-roles.toast-error.content" />,
                <FormattedMessage id="c-registry-user.remove-roles.toast-error.title" />
            );
            resetRemoveRoles();
        }
    }, [displayErrorToast, removeRolesFromUserStatus.error, resetRemoveRoles]);

    useEffect(() => {
        getWorkspaceId(company.base.uuid);
    }, [getWorkspaceId, company.base.uuid]);

    useEffect(() => {
        if (workspaceId && selectedUserProfile.id) {
            // Get workspace permissons for the current selected item
            getWorkspacePermissions(selectedUserProfile.uuid, workspaceId);
        }
    }, [getWorkspacePermissions, selectedUserProfile, workspaceId]);

    const [currentCompanyData, setCurrentCompanyData] = useState({
        itemId: company.base.id,
        itemUuid: company.base.uuid,
        description: company.base.details.description,
        taxId: company.base.identifier.taxId,
        vatNumber: company.base.identifier.vatNumber,
        classifier: company.base.details.classifier,
        alreadyAssigned: false,
        certified: company.base.status.certificationStatus,
        hasNoActiveServices: false,
        workspaceId,
        localServices: workspacePermissions
    });

    useEffect(() => {
        setCurrentCompanyData({
            itemId: company.base.id,
            itemUuid: company.base.uuid,
            description: company.base.details.description,
            taxId: company.base.identifier.taxId,
            vatNumber: company.base.identifier.vatNumber,
            classifier: company.base.details.classifier,
            alreadyAssigned: false,
            certified: company.base.status.certificationStatus,
            hasNoActiveServices: false,
            workspaceId,
            localServices: workspacePermissions
        });
    }, [setCurrentCompanyData, workspaceId, company, workspacePermissions]);

    const [activeItem, setActiveItem] = useState({
        id: currentCompanyData.itemId,
        uuid: currentCompanyData.itemUuid,
        description: currentCompanyData.description,
        workspaceId: currentCompanyData.workspaceId,
        localServices: currentCompanyData.localServices,
        classifier: currentCompanyData.classifier
    });

    useEffect(() => {
        setActiveItem({
            id: currentCompanyData.itemId,
            uuid: currentCompanyData.itemUuid,
            description: currentCompanyData.description,
            workspaceId: currentCompanyData.workspaceId,
            localServices: currentCompanyData.localServices,
            classifier: currentCompanyData.classifier
        });
    }, [currentCompanyData]);

    const listItemsCall = useCallback(
        (fullText, classifiers, page, pageSize) =>
            listItemsRoleCreation(
                fullText,
                classifiers,
                selectedUserId,
                page,
                pageSize,
                false,
                true,
                false,
                false,
                currentCompanyData.itemUuid
            ),
        [currentCompanyData.itemUuid, listItemsRoleCreation, selectedUserId]
    );

    useEffect(() => {
        listItemsCall(null, null, 0, 10);
    }, [listItemsCall, currentCompanyData.itemUuid]);

    const listItemsProp = useMemo(
        () => ({ call: listItemsCall, status: listItems.status, page: listItems.content, meta: listItems.meta }),
        [listItemsCall, listItems]
    );

    const isCurrentCompanyStudio = company.base.details.classifier === "STUDIO";

    return (
        <>
            <Modal
                type={"danger"}
                visible={removalModalOpen}
                footer={
                    <>
                        <Button colorScheme={"ghost"} kind="secondary" onClick={() => setRemovalModalOpen(false)}>
                            <FormattedMessage id="general.annulla" />
                        </Button>
                        <LoadingButton
                            colorScheme={"alert"}
                            loading={removeRolesFromUserStatus.started}
                            onClick={removeUser}
                            data-gainsight-track={"confirm-delete-from-edit-or-summary"}
                        >
                            <FormattedMessage id="c-registry-user.options.delete" />
                        </LoadingButton>
                    </>
                }
                onCancel={() => {
                    setRemovalModalOpen(false);
                }}
                width={"450px"}
                title={<FormattedMessage id="c-registry-user.remove-roles.modal-title" />}
            >
                <FormattedMessage
                    id="c-registry-user.remove-roles.modal-content"
                    values={{ email: selectedUserProfile.id }}
                />
            </Modal>
            <PageTitle
                title={<FormattedMessage id="general.edit-users" />}
                subtitle={<FormattedMessage id="c-edit-user.page.subtitle" />}
                onBack={() => history.goBack()}
            />
            <Container>
                <UserDataContainer>
                    <UserData
                        active={selectedUserStatus.active}
                        email={selectedUserProfile.id}
                        loading={getUserStatus.started}
                        fullName={`${selectedUserProfile.firstName} ${selectedUserProfile.lastName}`}
                    />
                    <Divider />
                    <Description>
                        <FormattedHTMLMessage id="c-edit-user.page.desc" values={{ user: selectedUserProfile.id }} />
                    </Description>
                    <ItemList
                        activeItem={activeItem}
                        onItemSelected={setActiveItem}
                        items={listItemsProp}
                        currentCompanyData={currentCompanyData}
                        isStudio={isCurrentCompanyStudio}
                    />
                </UserDataContainer>
                <TabsWrapper>
                    <TabsContainer>
                        <StandardTabs activeIndex={selectedTab} size="small" onChange={handleTabChange}>
                            <Tab>
                                <FormattedMessage id="c-edit-user.page.edit.tab" />
                            </Tab>
                            <Tab>
                                <FormattedMessage id="c-edit-user.page.recap.tab" />
                            </Tab>
                        </StandardTabs>
                    </TabsContainer>
                    <Content>
                        <Route
                            path="/:item/registry/user/:selectedUserId/edit"
                            render={props => (
                                <EditUser
                                    {...props}
                                    selectedItem={activeItem}
                                    onRemoveUser={handleRemoveUser}
                                    listItemsProp={listItemsProp}
                                    currentCompanyData={currentCompanyData}
                                />
                            )}
                        />
                        <Route
                            path="/:item/registry/user/:selectedUserId/summary"
                            render={props => (
                                <UserSummary
                                    {...props}
                                    onRemoveUser={handleRemoveUser}
                                    currentCompanyData={currentCompanyData}
                                />
                            )}
                        />
                    </Content>
                </TabsWrapper>
            </Container>
        </>
    );
};

const mapStateToProps = (state, props) => ({
    getUserStatus: state.getUserV3.status,
    removeRolesFromUserStatus: state.manageUser.removeRolesFromUser.status,
    selectedUserProfile: (state.getUserV3.user && state.getUserV3.user.profile) || {},
    selectedUserStatus: (state.getUserV3.user && state.getUserV3.user.status) || {},
    listItems: state.registry.read.listItemsRoleCreation,
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    workspaceId: state.services.workspaceId.data ? state.services.workspaceId.data.id : null,
    workspacePermissions: state.services.workspacePermissions.data
        ? state.services.workspacePermissions.data.enabled
        : false
});

export default connect(mapStateToProps, {
    displayErrorToast,
    displaySuccessToast,
    getUserV3,
    removeRoles,
    resetRemoveRoles,
    getWorkspaceId,
    listItemsRoleCreation,
    getWorkspacePermissions
})(SelectedUserView);
