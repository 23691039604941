import { EmailValidator } from "commons-validator-js";
import { isAlphanumeric } from "validator";
import { clone, isNil } from "ramda";

import { fiscalCodeControl } from "../check-data";
import { monthForFiscalCodeControl } from "../constant";

function ottieniConsonanti(str) {
    return str.replace(/[^BCDFGHJKLMNPQRSTVWXYZ]/gi, "");
}

function ottieniVocali(str) {
    return str.replace(/[^AEIOU]/gi, "");
}

function calcolaCognome(cognome) {
    var codiceCognome = ottieniConsonanti(cognome);
    codiceCognome += ottieniVocali(cognome);
    codiceCognome += "XXX";
    codiceCognome = codiceCognome.substr(0, 3);

    return codiceCognome.toUpperCase();
}

function calcolaNome(nome) {
    var codiceNome = ottieniConsonanti(nome);
    if (codiceNome.length >= 4) {
        codiceNome = codiceNome.charAt(0) + codiceNome.charAt(2) + codiceNome.charAt(3);
    } else {
        codiceNome += ottieniVocali(nome);
        codiceNome += "XXX";
        codiceNome = codiceNome.substr(0, 3);
    }
    return codiceNome.toUpperCase();
}

export function signerValidation(values) {
    const fields = [
        "signerFirstName",
        "signerLastName",
        "signerFiscalCode",
        "signerFiscalCountry",
        "signerSex",
        "signerBirthCity",
        "signerBirthDistrict",
        "signerBirthCountry",
        "signerBirthDate",
        "signerCity",
        "signerCountry",
        "signerCap",
        "signerAddress",
        "signerEmail",
        "signerDocumentIssuer",
        "signerDocumentNumber",
        "signerDocumentEndDate",
        "signerDocumentType",
        "cellNumber",
        "pin",
        "confermaPin",
        "certType",
        "savePin"
    ];

    const errors = {};
    var letterNoAccent = /^[a-zA-Z\s]*$/;
    var numLetterAccent = /^[A-Za-z0-9 _]*[A-Za-z\u00C0-\u017F']+[A-Za-z0-9 _]*$/;
    var letterAccent = /^[a-zA-Z\s\u00C0-\u017F']*$/;
    let cfDay = values.signerFiscalCode !== undefined ? values.signerFiscalCode.substr(9, 2) : null;
    let cfPaese = values.signerFiscalCode !== undefined ? values.signerFiscalCode.substr(11, 4) : null;
    let cfMonth = values.signerFiscalCode !== undefined ? values.signerFiscalCode.substr(8, 1) : null;
    let cfName = values.signerFiscalCode !== undefined ? values.signerFiscalCode.substr(3, 3) : null;
    let cfSurname = values.signerFiscalCode !== undefined ? values.signerFiscalCode.substr(0, 3) : null;
    var d = new Date(values.signerBirthDate);
    let day = values.signerBirthDate !== undefined ? (d.getDate() < 10 ? "0" + d.getDate() : d.getDate()) : null;
    let month = values.signerBirthDate !== undefined ? d.getMonth() + 1 : null;
    let codicePaese = values.signerBirthCountry !== undefined ? values.signerBirthCountry : null;
    let cName = values.signerFirstName !== undefined ? calcolaNome(values.signerFirstName) : null;
    let cSurname = values.signerLastName !== undefined ? calcolaCognome(values.signerLastName) : null;

    let index;

    let fieldsList = clone(fields);
    if (values.certType === "FSM") {
        index = 20;
        fieldsList.splice(index, 1);
        index = 19;
        fieldsList.splice(index, 1);
    }

    fieldsList
        .map(field => {
            if (isNil(values[field])) {
                return (errors[field] = "Valore non valido");
            }
            return "";
        })
        .filter(x => x);

    if (
        !fiscalCodeControl(values.signerFiscalCode) &&
        isNil(errors["signerFiscalCode"]) &&
        errors["signerFiscalCode"] !== ""
    ) {
        errors["signerFiscalCode"] = "Valore non valido";
    }

    if (!letterNoAccent.test(values.signerDocumentIssuer))
        errors["signerDocumentIssuer"] = "Deve contenere solo lettere non accentate";

    if (!numLetterAccent.test(values.signerAddress)) errors["signerAddress"] = "Deve contenere solo lettere e numeri";

    if (!letterAccent.test(values.signerBirthCity)) errors["signerBirthCity"] = "Deve contenere solo lettere";

    if (!letterAccent.test(values.signerCity)) errors["signerCity"] = "Deve contenere solo lettere";

    if (values.certType === "FRM") {
        if (values.pin !== undefined && values.pin.length < 8) {
            errors["pin"] = "Il PIN deve essere di almeno 8 caratteri.";
        }
        if (values.pin !== undefined && !isAlphanumeric(values.pin)) {
            errors["pin"] = "Deve contenere solo numeri o lettere";
        }
        if (values.pin !== undefined && values.confermaPin !== undefined && values.pin !== values.confermaPin) {
            errors["confermaPin"] = "PIN diverso";
        }
    }

    if (!values.signerCap || values.signerCap < 5) {
        errors["signerCap"] = "Il valore deve avere 5 caratteri";
    }

    if (values.signerEmail !== undefined && !new EmailValidator().isValid(values.signerEmail)) {
        errors["signerEmail"] = "Valore non valido ";
    }

    if (
        values.signerFiscalCode !== undefined &&
        values.signerSex === "M" &&
        values.signerBirthDate !== undefined &&
        cfDay.toString() !== day.toString()
    ) {
        errors["signerFiscalCode"] = "Codice fiscale e dati inseriti non corrispondenti";
    }

    if (
        values.signerFiscalCode !== undefined &&
        values.signerBirthDate !== undefined &&
        cfMonth !== monthForFiscalCodeControl[month - 1]
    ) {
        errors["signerFiscalCode"] = "Codice fiscale e dati inseriti non corrispondenti";
    }

    if (values.signerBirthCountry !== undefined && values.signerBirthCountry !== null) {
        if (codicePaese !== "ZXXX" && codicePaese !== cfPaese) {
            errors["signerFiscalCode"] = "Codice fiscale e dati inseriti non corrispondenti";
        }
    }
    if (values.signerFiscalCode !== undefined && values.signerFirstName !== undefined && cName !== cfName) {
        errors["signerFiscalCode"] = "Codice fiscale e dati inseriti non corrispondenti";
    }

    if (values.signerFiscalCode !== undefined && values.signerLastName !== undefined && cSurname !== cfSurname) {
        errors["signerFiscalCode"] = "Codice fiscale e dati inseriti non corrispondenti";
    }
    if (
        values.signerSex === "F" &&
        values.signerBirthDate !== undefined &&
        values.signerFiscalCode !== undefined &&
        cfDay.toString() !== (40 + parseInt(day, 10)).toString()
    ) {
        errors["signerFiscalCode"] = "Codice fiscale e dati inseriti non corrispondenti";
    }

    return errors;
}
