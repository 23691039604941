import React from "react";
import PropTypes from "prop-types";

import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

import Initials from "../user-initials";
import SectionTitle from "../section-title";
import { Container, CompanyWrapper, CompanyName, CopyIcon, Status, Info } from "./styled";
import { Tooltip } from "antd";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { ENV } from "../../../../config";

export const UserInfo = ({ auth, copyTokenToast, user, intl, company }) => {
    const isLinkedWithTsId = user && user.user.profile.tsid !== null && user.user.profile.tsid !== undefined;

    const ssoStatus = isLinkedWithTsId ? (
        <span>
            <FormattedMessage id="general.linked"></FormattedMessage>&nbsp;
            <FontAwesomeIcon icon={faCheckCircle} color="#09822a" />
        </span>
    ) : (
        <span>
            <FormattedMessage id="general.not-linked"></FormattedMessage>&nbsp;
            <FontAwesomeIcon icon={faExclamationCircle} color="#fdb927" />
        </span>
    );

    const title = (
        <span>
            <FormattedHTMLMessage id="general.user.common" />{" "}
            {ENV !== "prod" && (
                <CopyToClipboard text={auth.loginAuth.securityToken} onCopy={() => copyTokenToast()}>
                    <Tooltip title={<FormattedHTMLMessage id="general.copyAuthToken" />}>
                        <CopyIcon icon={faCopy} />
                    </Tooltip>
                </CopyToClipboard>
            )}
        </span>
    );

    return (
        <Container>
            <SectionTitle
                editable={true}
                linkTo={company ? `/${company.base.id}/edit-user-details` : `/user/edit`}
                title={title}
                defaultButtonLabel={"general.edit-profile"}
            />

            <CompanyWrapper>
                <Initials name={`${user.user.profile.firstName} ${user.user.profile.lastName}`} />
                <div>
                    <CompanyName>{`${user.user.profile.firstName} ${user.user.profile.lastName}`}</CompanyName>
                    <Status>
                        <FormattedHTMLMessage id="profile.header.tsID_state" />
                        {ssoStatus}
                    </Status>
                    <Info>
                        <FormattedMessage id="profile.header.email" />
                        {user.user.profile.id}
                    </Info>
                    {user.user.profile.tsid && user.user.profile.tsid !== user.user.profile.id && (
                        <Info>
                            <FormattedMessage id="profile.header.tsId" />
                            {user.user.profile.tsid}
                        </Info>
                    )}
                </div>
            </CompanyWrapper>
        </Container>
    );
};

UserInfo.propTypes = {
    user: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    showNotification: PropTypes.bool,
    company: PropTypes.object,
    handleModifyUserClick: PropTypes.func.isRequired,
    handleCloseModal: PropTypes.func.isRequired
};

export default UserInfo;
