import styled from "styled-components";

export const Container = styled.div`
    background-color: ${props => props.theme.colors.background.white};
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const Content = styled.div`
    margin: 24px;
`;

export const Title = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 20px;
    font-family: Cairo;
    font-weight: normal;
    letter-spacing: 0.18px;
    line-height: 24px;

    margin-left: 24px;
    margin-top: 24px;
`;

export const Description = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 34px;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.25px;
    line-height: 40px;
    margin-left: 24px;
`;

export const TitleWrapper = styled.div`
    display: flex;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 2;

    padding: 5px;
    padding-top: 100px;
`;
