import {
    ACTIVATE_SHARE_SERVICE_START,
    ACTIVATE_SHARE_SERVICE_ERROR,
    ACTIVATE_SHARE_SERVICE_SUCCESS,
    DISABLE_SHARE_SERVICE_START,
    DISABLE_SHARE_SERVICE_ERROR,
    DISABLE_SHARE_SERVICE_SUCCESS
} from "../../actions/services/service-extension";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

const itemServicesDefaultState = {
    ...defaultState,
    services: {}
};

export function shareActivated(state = defaultState, { type, payload, error }) {
    switch (type) {
        case ACTIVATE_SHARE_SERVICE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case ACTIVATE_SHARE_SERVICE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case ACTIVATE_SHARE_SERVICE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}

export function shareDisabled(state = defaultState, { type, payload, error }) {
    switch (type) {
        case DISABLE_SHARE_SERVICE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case DISABLE_SHARE_SERVICE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case DISABLE_SHARE_SERVICE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}

export function sharedItemServices(state = itemServicesDefaultState, { type, payload }) {
    switch (type) {
        case ACTIVATE_SHARE_SERVICE_SUCCESS:
            let previousServices = state.services[payload.itemId] ? state.services[payload.itemId] : [];

            return {
                ...state,
                services: {
                    ...state.services,
                    [payload.itemId]: [
                        ...previousServices,
                        {
                            agyoService: payload.agyoService,
                            ownerId: payload.ownerId,
                            owner: payload.ownerId === payload.itemId,
                            availableToUser: true
                        }
                    ]
                }
            };
        case DISABLE_SHARE_SERVICE_SUCCESS:
            previousServices = state.services[payload.itemId] ? state.services[payload.itemId] : [];
            return {
                ...state,
                services: {
                    ...state.services,
                    [payload.itemId]: previousServices.filter(s => s.agyoService.id !== payload.serviceName)
                }
            };
        default:
            return state;
    }
}
