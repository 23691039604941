import { useCallback, useEffect, useState } from "react";

function hideElevio() {
    if (window._elev) {
        window._elev.setSettings({ hideLauncher: true });
    }
}

function hideUservoice() {
    const uservoiceLauncher = document.querySelector(".uv-icon.uv-bottom-right");
    if (uservoiceLauncher) {
        uservoiceLauncher.style.visibility = "hidden";
    }
}

function showElevio() {
    if (window._elev) {
        window._elev.setSettings({ hideLauncher: false });
    }
}

function showUservoice() {
    const uservoiceLauncher = document.querySelector(".uv-icon.uv-bottom-right");
    if (uservoiceLauncher) {
        uservoiceLauncher.style.visibility = "visible";
    }
}

/**
 * Provides method to control the Elevio widget.
 * The currently available methods are:
 * - hideWidget: hides the elevio and uservoice widgets
 * - showWidget: shows the elevio and uservoice widgets
 *
 * On component unmount the visibility of the widgets is restored.
 */
function useElevioControls() {
    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        return () => {
            showElevio();
            showUservoice();
        };
    }, []);

    useEffect(() => {
        if (hidden) {
            hideElevio();
            hideUservoice();
        } else {
            showElevio();
            showUservoice();
        }
    }, [hidden]);

    const hideWidget = useCallback(() => setHidden(true), []);
    const showWidget = useCallback(() => setHidden(false), []);

    return { hideWidget, showWidget };
}

export default useElevioControls;
