import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useRouteMatch } from "react-router";
import { useIntl } from "react-intl";

import { isNil } from "ramda";

import { Table, Button, Input, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import UserTechRow from "./row";

const Search = Input.Search;

const locale = {
    emptyText: <FormattedMessage id="general.no-result" />
};

const columns = [
    {
        title: <FormattedMessage id="general.description" />,
        dataIndex: "profile.description",
        key: "profile.description",
        align: "left"
    },
    {
        title: <FormattedMessage id="general.id" />,
        dataIndex: "profile.id",
        key: "profile.id",
        align: "left"
    },
    {
        title: <FormattedMessage id="general.creation-datetime" />,
        dataIndex: "status.creationDate",
        key: "status.creationDate",
        align: "right"
    },
    {
        title: <FormattedMessage id="general.action" />,
        dataIndex: "profile.lastName",
        key: "profile.lastName",
        align: "left"
    }
];

export const UsersTech = ({ company, listEditableUsers, user, users }) => {
    const [searchText, setSearchText] = useState("");
    const { formatMessage } = useIntl();
    const match = useRouteMatch();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        listEditableUsers(company.base.uuid);
    }, [listEditableUsers, company.base.uuid]);

    useEffect(() => {
        setLoading(users.status === undefined || users.status.started);
    }, [users.status]);

    useEffect(() => {
        var userComponent = [];
        let data =
            searchText !== ""
                ? users.editableUsers.filter(function (lists) {
                      return (
                          lists.user.profile.id.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
                          (lists.user.profile.description &&
                              lists.user.profile.description.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
                      );
                  })
                : users.editableUsers;

        if (!isNil(data)) {
            userComponent = data
                .filter(v => v.user.profile.type === "TECNICA")
                .map(m => {
                    const creationDate = new Date(m.user.status.createdAt);

                    return {
                        ...m.user,
                        status: {
                            ...m.status,
                            creationDate: creationDate.toLocaleString("it-IT", {
                                day: "numeric",
                                month: "numeric",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit"
                            })
                        }
                    };
                });
        }
        setData(userComponent.sort((a, b) => a.profile.id.localeCompare(b.profile.id)));
    }, [searchText, users.editableUsers]);

    const companyValidated = company.base.status.status === "VALIDATED";
    const isEnable = companyValidated
        ? { status: true, color: "#FFF", background: "#11B7FF" }
        : {
              status: false,
              color: "rgba(0,0,0,.25)",
              background: "#f7f7f7"
          };
    let enableShareRoles = -1;

    if (user && user.roles) {
        enableShareRoles = user.roles.findIndex(x => x.resourceId === company.base.id && x.actionKey === "OWNER");
    }

    enableShareRoles = companyValidated && enableShareRoles !== -1 && company.base.details.classifier === "STUDIO";

    const addTitle = formatMessage({
        id: "general.add"
    });

    const placeholder = formatMessage({
        id: "c-registry-user-tech.search.placeholder"
    });

    const addUserNotPermittedMsg = formatMessage({
        id: "c-registry-user-tech.add.not-permitted-not-validated"
    });
    return (
        <div>
            <div className="table-operations" style={{ display: "flex" }}>
                <Tooltip title={companyValidated ? "" : addUserNotPermittedMsg}>
                    <Button
                        href={`#${match.params.item}/registry/apikey/create`}
                        type="primary"
                        style={{ margin: "20px" }}
                        size="large"
                        disabled={!companyValidated}
                    >
                        {addTitle}
                    </Button>
                </Tooltip>

                <Search
                    enterButton
                    size="large"
                    style={{ margin: "20px" }}
                    placeholder={placeholder}
                    onSearch={value => setSearchText(value)}
                />
            </div>
            <Table
                dataSource={data}
                locale={locale}
                rowKey={value => value.profile.id}
                loading={loading}
                style={{
                    borderRadius: "5px",
                    background: "#fff",
                    margin: "20px",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 1px"
                }}
            >
                {columns.map((column, index) => (
                    <Table.Column
                        dataIndex={column.dataIndex}
                        key={index}
                        render={(text, row) => (
                            <UserTechRow
                                value={row}
                                onClick={row => this.onClick(row)}
                                text={text}
                                column={column}
                                isEnable={isEnable}
                                isEditable={enableShareRoles}
                            />
                        )}
                        title={column.title}
                        align={column.align}
                    />
                ))}
            </Table>
        </div>
    );
};

UsersTech.propTypes = {
    company: PropTypes.object.isRequired,
    listEditableUsers: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    users: PropTypes.object
};

UsersTech.defaultProps = {
    users: { editableUsers: [] }
};

export default UsersTech;
