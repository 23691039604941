import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { loginWithSession } from "../../actions/auth";
import useParams from "../../hooks/use-params";
import { Spinner } from "./../../components/general/spinner";

function Jump({ loginWithSession, loginStatus }) {
    const params = useParams();
    const history = useHistory();
    const token = params.get("token");
    const match = useRouteMatch();

    useEffect(() => {
        loginWithSession(token);
    }, [token, loginWithSession]);

    useEffect(() => {
        if (loginStatus.ended) {
            history.push(`/${match.params[0] || ""}`);
        }
    }, [history, loginStatus, match.params]);

    if (!token) {
        history.replace("/");
        return <></>;
    }

    return (
        <Spinner loading={true}>
            <></>
        </Spinner>
    );
}

function mapStateToProps(state) {
    return {
        loginStatus: state.auth.status
    };
}

export default connect(mapStateToProps, { loginWithSession })(Jump);
