import React, { useCallback, useContext, useEffect, useMemo } from "react";

import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { getUserV3 } from "../../actions/company/users";
import { createOrAddRolesToUser, resetCreateOrAddRolesToUser } from "../../actions/user";
import { listItemsRoleCreation, listItemsRoleCreationReset } from "../../actions/registry/read";
import { fetchAssignableRoles, resetFetchAssignableRoles } from "../../actions/roles";
import { resetUserExists, userExists } from "../../actions/user";
import UserCreationFlowComponent from "../../components/user-creation-flow";
import { UsersManagegementContext } from "../../user-context";
import { getWorkspaceId } from "../../actions/services/app";

const UserCreationFlow = ({
    assignableRoles,
    createOrAddRolesToUser,
    createOrAddRolesToUserStatus,
    doesUserExist,
    fetchAssignableRoles,
    fetchAssignableRolesStatus,
    getUserV3,
    listItems,
    listItemsRoleCreation,
    listItemsRoleCreationReset,
    resetFetchAssignableRoles,
    resetUserExists,
    userExists,
    user,
    users,
    getUserV3StateResult,
    userId,
    resetCreateOrAddRolesToUser,
    userExistsStatus,
    company,
    workspaceId,
    getWorkspaceId
}) => {
    const history = useHistory();
    const { currentItem, setCurrentItem, selectedUser, setSelectedUser, userCreationFlowFirstStep } =
        useContext(UsersManagegementContext);

    useEffect(() => {
        return () => {
            if (selectedUser) {
                setCurrentItem();
            }
            if (setSelectedUser !== undefined) {
                setSelectedUser();
            }
        };
    }, [setSelectedUser, setCurrentItem, selectedUser]);

    const location = useLocation();

    const isInMulticompanyView = location.pathname === "/user/create";

    const handleBack = useCallback(() => {
        if (isInMulticompanyView) {
            return history.push("/");
        } else {
            if (userCreationFlowFirstStep === "setPermission") {
                return history.push("/" + location.pathname.split("/")[1] + "/registry/user");
            } else {
                return history.goBack();
            }
        }
    }, [history, isInMulticompanyView, location.pathname, userCreationFlowFirstStep]);

    const checkUserExistence = useCallback(email => userExists(email), [userExists]);
    const listItemsCall = useCallback(
        (fullText, classifiers, targetUser, page, pageSize) =>
            listItemsRoleCreation(
                fullText,
                classifiers,
                targetUser,
                page,
                pageSize,
                false,
                true,
                false,
                false,
                company.base.uuid
            ),
        [listItemsRoleCreation, company.base.uuid]
    );
    const listItemsProp = useMemo(
        () => ({ call: listItemsCall, status: listItems.status, page: listItems.content, meta: listItems.meta }),
        [listItemsCall, listItems]
    );

    useEffect(() => () => listItemsRoleCreationReset(), [listItemsRoleCreationReset]);
    useEffect(() => () => resetUserExists(), [resetUserExists]);

    const createOrAddRolesToUserCall = useCallback(
        (email, roles, firstName, lastName, enableInvitation, localServices) => {
            createOrAddRolesToUser(email, roles, firstName, lastName, enableInvitation, localServices);
        },
        [createOrAddRolesToUser]
    );
    const createOrAddRolesToUserProp = {
        call: createOrAddRolesToUserCall,
        status: createOrAddRolesToUserStatus
    };

    const assignableRolesProp = useMemo(
        () => ({ call: fetchAssignableRoles, status: fetchAssignableRolesStatus, roles: assignableRoles }),
        [assignableRoles, fetchAssignableRoles, fetchAssignableRolesStatus]
    );

    useEffect(() => () => resetCreateOrAddRolesToUser(), [resetCreateOrAddRolesToUser]);
    useEffect(() => () => resetFetchAssignableRoles(), [resetFetchAssignableRoles]);

    useEffect(() => {
        if (userCreationFlowFirstStep === "setPermission") {
            resetFetchAssignableRoles();
        }
    }, [resetFetchAssignableRoles, userCreationFlowFirstStep]);

    const selectedItem = useMemo(() => {
        const itemData = listItems.content.find(i => i.itemUuid === currentItem);

        if (!itemData) {
            return undefined;
        }

        return itemData;
    }, [currentItem, listItems]);

    useEffect(() => {
        getWorkspaceId(company.base.uuid);
    }, [getWorkspaceId, company.base.uuid]);

    return (
        <UserCreationFlowComponent
            assignableRoles={assignableRolesProp}
            createOrAddRolesToUser={createOrAddRolesToUserProp}
            checkUserExistence={{ call: checkUserExistence, status: userExistsStatus }}
            doesUserExist={doesUserExist}
            listItems={listItemsProp}
            getUserV3={{ call: getUserV3, ...getUserV3StateResult }}
            onBack={handleBack}
            resetUserExists={resetUserExists}
            selectedItem={selectedItem}
            selectedUser={selectedUser}
            userCreationFlowFirstStep={userCreationFlowFirstStep}
            successModalBackButtonLabel={
                <FormattedMessage
                    id={
                        isInMulticompanyView
                            ? "v-user-creation-flow.modals.buttons.return-to-item-list"
                            : "v-user-creation-flow.modals.buttons.return-to-user-management"
                    }
                />
            }
            userId={userId}
            userWhoGivesPermissions={user}
            resetFetchAssignableRoles={resetFetchAssignableRoles}
            currentCompany={company}
            users={users}
            isStudio={company.base.details.classifier === "STUDIO"}
            workspaceId={workspaceId}
        />
    );
};

const actions = {
    createOrAddRolesToUser,
    fetchAssignableRoles,
    getUserV3,
    listItemsRoleCreation,
    listItemsRoleCreationReset,
    resetFetchAssignableRoles,
    resetCreateOrAddRolesToUser,
    resetUserExists,
    userExists,
    getWorkspaceId
};
const mapStateToProps = (state, props) => ({
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    assignableRoles: state.manageUser.roles.assignableRoles,
    createOrAddRolesToUserStatus: state.manageUser.createOrAddRolesToUser,
    doesUserExist: state.userExists.exists,
    fetchAssignableRolesStatus: state.manageUser.roles.fetchAssignableRolesStatus,
    listItems: state.registry.read.listItemsRoleCreation,
    loadedItems: Object.values(state.companies.data),
    user: state.user.user,
    users: state.users.users,
    getUserV3StateResult: state.getUserV3,
    userId: state.auth.loginAuth.id,
    userExistsStatus: state.userExists.status,
    workspaceId: state.services.workspaceId.data ? state.services.workspaceId.data.id : null
});

export default connect(mapStateToProps, actions)(UserCreationFlow);
