import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Title = styled.div`
    color: rgb(0, 80, 117);
    font-family: Cairo;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
`;

export const Description = styled.div`
    color: #98aab3;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`;

export const TitleContainer = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
    padding: 16px;
`;

export const TitleColumn = styled.div`
    display: table-cell;
    padding-left: 20px;

    vertical-align: middle;
`;

export const TitleRow = styled.div`
    display: table-row;
`;

export const StatusIcon = styled(FontAwesomeIcon)`
    color: ${props => (props.active === "true" ? "rgb(0, 170, 0)" : "rgb(253, 185, 39)")};
    font-size: 16px;
    text-align: center;
    width: 40px;
    margin-right: 8px;
    margin-top: ${props => props.margintop};
`;

export const Divider = styled.div`
    background: rgb(201, 217, 232);
    border-radius: 0px;
    height: 1px;
    margin: 24px;
`;

export const CompaniesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const SingleCompanyContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 9px;
`;

export const CompanyDescriptionContainer = styled.div`
    padding: 0 16px;
`;

export const CompanyDescription = styled.div`
    color: #004e75;
    font-family: Cairo;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const EditButtonContainer = styled.div`
    padding-right: 16px;
    text-align: right;
`;
