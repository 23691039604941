import React, { useState } from "react";
import PropTypes from "prop-types";

import { Badge, Popover } from "antd";

import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartNetwork } from "@fortawesome/pro-regular-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";

import { ENABLE_WEB_NOTIFICATIONS } from "../../config";
import { useOutsideClickRef } from "../../hooks/use-outside-click";
import { isItemAdmin } from "../../utils/get-roles";
import NotificationsBadge from "../notifications-badge";
import NotificationDrawer from "../notification-drawer";
import NotificationList from "../notification-list";
import { TitleContainer, NotificationBadge } from "./styled";
import UserBadge from "./user-badge";

import "./style.css";

const Header = ({
    auth,
    copyTokenToast,
    user,
    company,
    getNotifications,
    getNotificationsExist,
    deleteSingleNotification,
    deleteNotificationsList,
    updateSingleNotification,
    updateNotificationsList,
    logout,
    notifications,
    notificationsCenterLink = "/notifications-center",
    notificationsExist,
    notificationsList,
    notificationsListStatus,
    onNotificationAccept,
    onNotificationReject,
    onReadNotification,
    onUnreadNotification,
    unreadNotifications,
    resetCompanyLoad,
    showNotification,
    editUser,
    loadUser,
    updateUserPsw,
    updateUserPswStatus,
    userEditStatus,
    dashboardView,
    users
}) => {
    const [showNotificationList, setShowNotificationList] = useState(false);

    const notificationsDrawerRef = useOutsideClickRef(() => showNotificationList && setShowNotificationList(false));

    return (
        <div className="c-header">
            <NotificationBadge
                showNotification={showNotification}
                isItemAdmin={company && company.base && isItemAdmin(company.base.id, user)}
            >
                <Popover
                    overlayClassName="custom-notifications-popover"
                    placement="bottom"
                    title={
                        <TitleContainer>
                            <FormattedMessage id="notifications.title" />
                        </TitleContainer>
                    }
                    content={
                        <NotificationList
                            isLoading={false}
                            notifications={notifications}
                            onNotificationAccept={onNotificationAccept}
                            onNotificationReject={onNotificationReject}
                            onReadNotification={onReadNotification}
                            onUnreadNotification={onUnreadNotification}
                        />
                    }
                    trigger="hover"
                >
                    <Badge
                        className="badge"
                        dot={unreadNotifications !== undefined && unreadNotifications > 0}
                        offset={[0, 7]}
                    >
                        <span className="fa-layers fa-fw bell-icon">
                            <FontAwesomeIcon icon={faChartNetwork} transform="down-2 left-1" />
                            <FontAwesomeIcon icon={faBell} transform="shrink-6 up-4 right-6 down-2" />
                        </span>
                    </Badge>
                </Popover>
            </NotificationBadge>
            {ENABLE_WEB_NOTIFICATIONS && (
                <NotificationsBadge
                    onClick={() => setShowNotificationList(!showNotificationList)}
                    active={showNotificationList}
                    exist={notificationsExist}
                    getNotificationsExist={getNotificationsExist}
                />
            )}
            <UserBadge
                auth={auth}
                className="user-header user-badge"
                copyTokenToast={copyTokenToast}
                user={user}
                logout={logout}
                company={company}
                resetCompanyLoad={resetCompanyLoad}
                showNotification={showNotification}
                editUser={editUser}
                loadUser={loadUser}
                updateUserPsw={updateUserPsw}
                updateUserPswStatus={updateUserPswStatus}
                userEditStatus={userEditStatus}
                dashboardView={dashboardView}
                users={users}
            />
            {ENABLE_WEB_NOTIFICATIONS && (
                <NotificationDrawer
                    ref={notificationsDrawerRef}
                    notifications={notificationsList}
                    notificationsListStatus={notificationsListStatus}
                    notificationsCenterLink={notificationsCenterLink}
                    show={showNotificationList}
                    deleteSingleNotification={deleteSingleNotification}
                    deleteNotificationsList={deleteNotificationsList}
                    getNotifications={getNotifications}
                    onClose={() => {
                        setShowNotificationList(false);
                    }}
                    updateNotificationsList={updateNotificationsList}
                    updateSingleNotification={updateSingleNotification}
                />
            )}
        </div>
    );
};

Header.propTypes = {
    auth: PropTypes.object.isRequired,
    company: PropTypes.object,
    copyTokenToast: PropTypes.func.isRequired,
    getNotifications: PropTypes.func,
    getNotificationsExist: PropTypes.func,
    deleteSingleNotification: PropTypes.func,
    deleteNotificationsList: PropTypes.func,
    updateSingleNotification: PropTypes.func,
    updateNotificationsList: PropTypes.func,
    logout: PropTypes.func.isRequired,
    notifications: PropTypes.array,
    notificationsCenterLink: PropTypes.string,
    notificationsExist: PropTypes.bool.isRequired,
    notificationsList: PropTypes.array,
    notificationsListStatus: PropTypes.object,
    onNotificationAccept: PropTypes.func,
    onNotificationReject: PropTypes.func,
    onReadNotification: PropTypes.func,
    onUnreadNotification: PropTypes.func,
    resetCompanyLoad: PropTypes.func,
    user: PropTypes.object.isRequired,
    showNotification: PropTypes.bool,
    location: PropTypes.object,
    editUser: PropTypes.func.isRequired,
    dashboardView: PropTypes.bool,
    users: PropTypes.object,
    onClose: PropTypes.func
};

export default Header;
