import React, { Component } from "react";
import PropTypes from "prop-types";
import PanelHeader from "../panel-header";
import { Collapse } from "antd";

import "./style.css";

const Panel = Collapse.Panel;

// Necessario per poter avere Panel creati con logiche condivise, in quanto
// Collapse non renderizza correttamente Collapse.Panel se non è figlio diretto
// https://github.com/react-component/collapse/issues/73#issuecomment-323626120
export function getPanel(panel, index, disabled) {
    /* riceve in ingresso un oggetto di tipo AccordionStep */
    const { title, subtitle, children } = panel.props;

    return (
        <Panel
            header={title || subtitle ? <PanelHeader disabled={disabled} title={title} subtitle={subtitle} /> : null}
            key={String(index)}
            showArrow={false}
            disabled={true}
            className={disabled ? "stepper-disabled-panel" : ""}
        >
            {React.Children.map(
                children,
                child => React.cloneElement(child, { disabled: disabled }) // propagate disabled prop to content
            )}
        </Panel>
    );
}

export class AccordionStep extends Component {
    static propTypes = {
        title: PropTypes.any, // titolo dello step
        subtitle: PropTypes.any, // sottotitolo dello step
        disabled: PropTypes.bool // visualizzazione "grigia" del componente
    };
}
