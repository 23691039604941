import React from "react";
import PropTypes from "prop-types";

import { Button } from "@ts-digital/vrc";
import { useIntl } from "react-intl";

import { HeaderContainer, InfoContainer, LogoutContainer } from "./styled";

export const UserCardTitle = ({ logout }) => {
    const { formatHTMLMessage, formatMessage } = useIntl();

    return (
        <HeaderContainer>
            <InfoContainer>{formatHTMLMessage({ id: "general.informations" })}</InfoContainer>
            <LogoutContainer>
                <Button
                    onClick={() => {
                        logout();
                    }}
                >
                    {formatMessage({ id: "general.quit" })}
                </Button>
            </LogoutContainer>
        </HeaderContainer>
    );
};

UserCardTitle.propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired
};

export default UserCardTitle;
