import React from "react";
import { useEffect } from "react";

import { connect } from "react-redux";
import { Route, Switch } from "react-router";

import { loadUser } from "../../actions/user";
import { Spinner } from "../../components/general/spinner";
import { ENABLE_WEB_NOTIFICATIONS } from "../../config";
import Cookie from "../cookie";
import CreateItem from "../create-item";
import CreateItemSuccess from "../create-item/success";
import itemCreate from "../item-create";
import ItemCreateSuccess from "../item-create/success";
import MulticompanyCreateUserSuccess from "../multicompany-create-user-success";
import MultiCompanyEditView from "../multicompany-edit-user";
import MultiCompanyNotificationsCenterView from "../multicompany-notifications-center";
import Root from "../root";

const AuthenticatedRoutes = ({ loadUser, loggingOut, user, userId, userStatus }) => {
    useEffect(() => {
        if (!user && !userStatus.started && !userStatus.error && !loggingOut) {
            loadUser(userId);
        }
    }, [loadUser, loggingOut, user, userId, userStatus]);

    return (
        <Spinner fullPage={true} loading={!user || userStatus.started}>
            <Switch>
                <Route path="/user/create/success" component={MulticompanyCreateUserSuccess} />
                <Route exact path="/user/edit" component={MultiCompanyEditView} />
                <Route exact path="/createItem" component={CreateItem} />
                <Route exact path="/createItem/success" component={CreateItemSuccess} />
                <Route exact path="/itemCreate" component={itemCreate} />
                <Route exact path="/itemCreate/success" component={ItemCreateSuccess} />

                <Route exact path="/cookiepolicy" component={Cookie} />

                {ENABLE_WEB_NOTIFICATIONS ? (
                    <Route path="/notifications-center" component={MultiCompanyNotificationsCenterView} />
                ) : null}
                <Route path="/:item?(/:menuItem?)?" component={Root} />
            </Switch>
        </Spinner>
    );
};

const mapStateToProps = state => ({
    loggingOut: state.loggingOut,
    user: state.user.user,
    userId: state.auth.loginAuth.id,
    userStatus: state.user.status
});

export default connect(mapStateToProps, { loadUser })(AuthenticatedRoutes);
