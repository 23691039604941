import styled from "styled-components";

export const TotalItemsCell = styled.td`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 14px;
    font-family: Roboto;
    line-height: 16px;
    font-weight: normal;

    text-align: right;
    padding-right: 16px;
`;

export const SelectedItemsCell = styled.td`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 14px;
    font-family: Roboto;
    line-height: 16px;
    font-weight: normal;

    padding: 0px 8px;
`;
