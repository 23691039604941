import React, { useEffect } from "react";
import { connect } from "react-redux";
import { errorBoundary } from "../../../components/general/error-boundary";
import { NIUMABONUS_URL } from "../../../config";

import { loadCompany } from "../../../actions/company";
import { fetchItemServices } from "../../../actions/services/service-subscription-v2";

import { Spinner } from "../../../components/general/spinner";
import useElevioControls from "../../../hooks/use-elevio-controls";
import { getCurrentCookieConsents } from "../../../utils/cookie-consent-utils";
import { extractUuid } from "../../../utils/tokenUtils";
import { useFeature } from "../../../hooks/use-feature";
import MigratingChildApp from "../../portale-child-app/migrating";

const NiumaBonusView = ({ auth, fetchItemServices, item, loadCompany, niumaBonusService, owner, userProfile }) => {
    const enableChildApp = useFeature("BONUS_CHILD_APP");

    // Niuma provides their own elevio widget, so we must disable ours
    const { hideWidget } = useElevioControls();

    useEffect(() => {
        if (!enableChildApp) {
            hideWidget();
        }
    }, [enableChildApp, hideWidget]);

    useEffect(() => {
        if (!niumaBonusService) {
            fetchItemServices(true, item.id);
        }
    }, [auth, fetchItemServices, item.id, niumaBonusService]);

    useEffect(() => {
        if (!owner && niumaBonusService) {
            loadCompany(niumaBonusService.owner);
        }
    }, [loadCompany, niumaBonusService, owner]);

    const handleFrameLoad = iframeRef => {
        iframeRef.contentWindow.postMessage(
            {
                cookieConsents: getCurrentCookieConsents(),
                auth: {
                    securityToken: auth.securityToken,
                    refreshToken: auth.refreshToken,
                    appName: "NIUMBABONUS",
                    userId: extractUuid(auth.securityToken)
                },
                user: {
                    email: userProfile.id,
                    tsId: userProfile.tsid,
                    firstName: userProfile.firstName,
                    lastName: userProfile.lastName
                },
                itemId: item.uuid,
                ownerId: owner && owner.uuid,
                language: userProfile.language
            },
            NIUMABONUS_URL
        );
    };

    return (
        <Spinner loading={!niumaBonusService || !owner}>
            <MigratingChildApp
                iframeConfigs={{
                    data: {},
                    id: "niumabonus",
                    title: "niumaBonus",
                    onLoad: handleFrameLoad
                }}
                url={NIUMABONUS_URL}
                customLaunchData={{ ownerId: owner && owner.uuid, oldUserId: userProfile.id }}
                hideElevioAndUservoice={true}
                migrated={enableChildApp}
            />
        </Spinner>
    );
};

const actions = {
    fetchItemServices,
    loadCompany
};

const mapStateToProps = (state, props) => {
    const itemId = props.match.params.item;
    const niumaBonusService =
        state.services.itemServices.services[itemId] &&
        state.services.itemServices.services[itemId].filter(s => s.agyoService.id === "NIUMABONUS")[0];

    const ownerId = niumaBonusService && niumaBonusService.ownerId;
    return {
        auth: state.auth.loginAuth,
        item: state.companies.data[itemId] && state.companies.data[itemId].item.base,
        owner: state.companies.data[ownerId] && state.companies.data[ownerId].item.base,
        niumaBonusService: niumaBonusService,
        userProfile: state.user.user.profile
    };
};

export default errorBoundary(connect(mapStateToProps, actions)(NiumaBonusView));
