import React from "react";
import PropTypes from "prop-types";
import { Button } from "@ts-digital/arc/antd";
import { StyledButtonTable, BorderedDiv, StyledTextContainer, DateTextContainer } from "./styled";
import { FormattedHTMLMessage } from "react-intl";
import { checkAdminRole, checkOwnerRole } from "../../../../utils/get-roles";

export const PopupContent = ({ created_by, created_at, handleUnsubscribe, user, companyId }) => {
    let viewDeleteCta =
        checkOwnerRole(companyId, user) || (checkAdminRole(companyId, user) && created_by === user.user.profile.id);

    return (
        <>
            <StyledTextContainer>
                <div>
                    <b>
                        <FormattedHTMLMessage id="general.waitinglist.manage-request.title" />
                    </b>
                    <br />
                    {created_by}
                </div>
                <DateTextContainer>
                    <b>
                        <FormattedHTMLMessage id="general.waitinglist.manage-request.date" />
                    </b>
                    <br />
                    {created_at}
                </DateTextContainer>
            </StyledTextContainer>
            {viewDeleteCta && (
                <>
                    <BorderedDiv />
                    <StyledButtonTable>
                        <Button size="small" variant="secondary" onClick={() => handleUnsubscribe()}>
                            <FormattedHTMLMessage id="general.waitinglist.manage-request.remove" />
                        </Button>
                    </StyledButtonTable>
                </>
            )}
        </>
    );
};

PopupContent.propTypes = {
    created_by: PropTypes.string,
    created_at: PropTypes.string,
    handleUnsubscribe: PropTypes.func
};

export default PopupContent;
