import React from "react";

import { connect } from "react-redux";
import { compose } from "redux";

import { resetUserInfo } from "../../actions/user";
import SuccessPage from "../../components/manage-data/user/new-user-modal/success-page";
import { errorBoundary } from "../../components/general/error-boundary";

const EditUserSuccessView = ({ match, resetUserInfo }) => (
    <SuccessPage
        editMode={true}
        redirectPath={`/${match.params.item}/registry/user`}
        resetUserInfo={resetUserInfo}
        techUser={false}
    />
);

const actions = { resetUserInfo };

const composedHoc = compose(
    errorBoundary,
    connect(() => ({}), actions)
);

export default composedHoc(EditUserSuccessView);
