import React from "react";

import get from "lodash.get";
import { connect } from "react-redux";

import { CONSOLE_FATTURAZIONE_20_URL } from "../../../../config";
import PortaleChildApp from "../../../portale-child-app";
import { errorBoundary } from "../../../../components/general/error-boundary";

const ConsoleFatturazioneChildApp = ({ services, isLoading }) => {
    return (
        <PortaleChildApp
            url={CONSOLE_FATTURAZIONE_20_URL}
            hideElevioAndUservoice={true}
            customLaunchData={{
                selectedCompanyCanUseSmartInvoice: get(services, "invoicing.getInvoicingConfig.hasFatturaSmartEnabled")
            }}
            useOldLaunchDataFormat={true}
            isLoading={isLoading}
        />
    );
};

const mapStateToProps = state => ({
    services: state.services,
    isLoading: state.services.invoicing.getInvoicingConfig.status.started
});

export default errorBoundary(connect(mapStateToProps)(ConsoleFatturazioneChildApp));
