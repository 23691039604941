import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import { Button, Container, LoadingButton } from "./styled";

export const StepButtons = ({
    displayNext,
    displayPrev,
    loading,
    nextDisabled,
    nextLabel,
    onNext,
    onPrev,
    prevDisabled,
    prevLabel
}) => (
    <Container>
        {displayPrev && (
            <Button disabled={prevDisabled} onClick={onPrev} variant="secondary">
                {prevLabel}
            </Button>
        )}
        {displayNext && (
            <LoadingButton disabled={nextDisabled} loading={loading} onClick={onNext} variant="primary">
                {nextLabel}
            </LoadingButton>
        )}
    </Container>
);

StepButtons.propTypes = {
    displayNext: PropTypes.bool,
    displayPrev: PropTypes.bool,
    loading: PropTypes.bool,
    nextDisabled: PropTypes.bool,
    nextLabel: PropTypes.any,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
    prevDisabled: PropTypes.bool,
    prevLabel: PropTypes.any
};

StepButtons.defaultProps = {
    displayNext: true,
    displayPrev: true,
    loading: false,
    nextDisabled: false,
    nextLabel: <FormattedMessage id="general.forward" />,
    onNext: () => {},
    onPrev: () => {},
    prevDisabled: false,
    prevLabel: <FormattedMessage id="general.back" />
};
