import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import { TS_DIGITAL_IMAGES } from "../../../config";

const cities = [
    "bologna",
    "campobasso",
    "catania",
    "ferrara",
    "milano",
    "napoli",
    "pesaro",
    "pescara",
    "roma",
    "torino"
];

const Container = styled.div`
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-image: url(${TS_DIGITAL_IMAGES}login-backgrounds/${cities[
        Math.floor(Math.random() * cities.length)
    ]}.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const ChildrenContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > * {
        z-index: 1;
    }
`;

const AnimatedBackground = ({ children }) => {
    return (
        <Container>
            <ChildrenContainer>{children}</ChildrenContainer>
        </Container>
    );
};

AnimatedBackground.propTypes = {
    children: PropTypes.any
};

export default AnimatedBackground;
