import React from "react";
import PropTypes from "prop-types";

import { compose } from "redux";
import { connect } from "react-redux";
import { TabPane } from "@ts-digital/arc/antd";
import { FormattedMessage } from "react-intl";

import { CardContent } from "../../components/metering/card";
import { MeteringCard, Tabs, StyledChildApp, RootContainer } from "./styled";
import { Billing } from "../../components/metering/billing";
import { METERING_CONSUMPTIONS_DASHBOARD_URL } from "../../config";
import { useFeature } from "../../hooks/use-feature";

export const BasicMetering = ({ invoicing, privacy, userId, companyName, firstName, lastName, classifier }) => {
    const useDecoupledDashboard = useFeature("DECOUPLED_CONSUMPTIONS_DASHBOARD");

    return (
        <RootContainer>
            {useDecoupledDashboard ? (
                <StyledChildApp
                    url={METERING_CONSUMPTIONS_DASHBOARD_URL}
                    customLaunchData={{ userId, companyName, firstName, lastName, studio: classifier === "STUDIO" }}
                    useOldLaunchDataFormat={true}
                />
            ) : (
                <>
                    <Tabs>
                        {invoicing && (
                            <TabPane key="billing" tab={<FormattedMessage id="metering.services.billing" />}>
                                <MeteringCard>
                                    <CardContent>
                                        <Billing />
                                    </CardContent>
                                </MeteringCard>
                            </TabPane>
                        )}
                        {privacy && (
                            <TabPane disabled key="privacy" tab={<FormattedMessage id="metering.services.privacy" />} />
                        )}
                    </Tabs>
                </>
            )}
        </RootContainer>
    );
};

BasicMetering.propTypes = {
    invoicing: PropTypes.bool.isRequired,
    privacy: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    invoicing: state.metering.permissions.services,
    privacy: state.metering.permissions.services.privacy,
    userId: state.auth.loginAuth.id,
    companyName: state.company.base.details.description,
    firstName: state.company.base.details.firstName,
    lastName: state.company.base.details.lastName,
    classifier: state.company.base.details.classifier
});

const enhanceComponent = compose(connect(mapStateToProps));

export const Metering = enhanceComponent(BasicMetering);
