/* eslint-disable */
//
// Autogenerated by Thrift Compiler (0.11.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
"use strict";

var thrift = require('browser-thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = module.exports = {};
var Auth = module.exports.Auth = function(args) {
  this.id = null;
  this.securityToken = null;
  this.appName = null;
  this.requestId = null;
  this.correlationId = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.securityToken !== undefined && args.securityToken !== null) {
      this.securityToken = args.securityToken;
    }
    if (args.appName !== undefined && args.appName !== null) {
      this.appName = args.appName;
    }
    if (args.requestId !== undefined && args.requestId !== null) {
      this.requestId = args.requestId;
    }
    if (args.correlationId !== undefined && args.correlationId !== null) {
      this.correlationId = args.correlationId;
    }
  }
};
Auth.prototype = {};
Auth.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.securityToken = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.appName = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.requestId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.correlationId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Auth.prototype.write = function(output) {
  output.writeStructBegin('Auth');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.securityToken !== null && this.securityToken !== undefined) {
    output.writeFieldBegin('securityToken', Thrift.Type.STRING, 2);
    output.writeString(this.securityToken);
    output.writeFieldEnd();
  }
  if (this.appName !== null && this.appName !== undefined) {
    output.writeFieldBegin('appName', Thrift.Type.STRING, 3);
    output.writeString(this.appName);
    output.writeFieldEnd();
  }
  if (this.requestId !== null && this.requestId !== undefined) {
    output.writeFieldBegin('requestId', Thrift.Type.STRING, 4);
    output.writeString(this.requestId);
    output.writeFieldEnd();
  }
  if (this.correlationId !== null && this.correlationId !== undefined) {
    output.writeFieldBegin('correlationId', Thrift.Type.STRING, 5);
    output.writeString(this.correlationId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthenticationException = module.exports.AuthenticationException = function(args) {
  Thrift.TException.call(this, "AuthenticationException");
  this.name = "AuthenticationException";
  this.code = null;
  this.message = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Thrift.inherits(AuthenticationException, Thrift.TException);
AuthenticationException.prototype.name = 'AuthenticationException';
AuthenticationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthenticationException.prototype.write = function(output) {
  output.writeStructBegin('AuthenticationException');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GenericError = module.exports.GenericError = function(args) {
  Thrift.TException.call(this, "GenericError");
  this.name = "GenericError";
  this.code = null;
  this.message = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Thrift.inherits(GenericError, Thrift.TException);
GenericError.prototype.name = 'GenericError';
GenericError.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GenericError.prototype.write = function(output) {
  output.writeStructBegin('GenericError');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserException = module.exports.UserException = function(args) {
  Thrift.TException.call(this, "UserException");
  this.name = "UserException";
  this.code = null;
  this.message = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Thrift.inherits(UserException, Thrift.TException);
UserException.prototype.name = 'UserException';
UserException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserException.prototype.write = function(output) {
  output.writeStructBegin('UserException');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ResourceResponse = module.exports.ResourceResponse = function(args) {
  this.id = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
  }
};
ResourceResponse.prototype = {};
ResourceResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ResourceResponse.prototype.write = function(output) {
  output.writeStructBegin('ResourceResponse');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Preferences = module.exports.Preferences = function(args) {
  this.language = null;
  if (args) {
    if (args.language !== undefined && args.language !== null) {
      this.language = args.language;
    }
  }
};
Preferences.prototype = {};
Preferences.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.language = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Preferences.prototype.write = function(output) {
  output.writeStructBegin('Preferences');
  if (this.language !== null && this.language !== undefined) {
    output.writeFieldBegin('language', Thrift.Type.STRING, 3);
    output.writeString(this.language);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var User = module.exports.User = function(args) {
  this.email = null;
  this.firstName = null;
  this.lastName = null;
  this.preferences = null;
  this.description = null;
  if (args) {
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.firstName !== undefined && args.firstName !== null) {
      this.firstName = args.firstName;
    }
    if (args.lastName !== undefined && args.lastName !== null) {
      this.lastName = args.lastName;
    }
    if (args.preferences !== undefined && args.preferences !== null) {
      this.preferences = new ttypes.Preferences(args.preferences);
    }
    if (args.description !== undefined && args.description !== null) {
      this.description = args.description;
    }
  }
};
User.prototype = {};
User.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.firstName = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.lastName = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.preferences = new ttypes.Preferences();
        this.preferences.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.description = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.prototype.write = function(output) {
  output.writeStructBegin('User');
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 1);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.firstName !== null && this.firstName !== undefined) {
    output.writeFieldBegin('firstName', Thrift.Type.STRING, 2);
    output.writeString(this.firstName);
    output.writeFieldEnd();
  }
  if (this.lastName !== null && this.lastName !== undefined) {
    output.writeFieldBegin('lastName', Thrift.Type.STRING, 3);
    output.writeString(this.lastName);
    output.writeFieldEnd();
  }
  if (this.preferences !== null && this.preferences !== undefined) {
    output.writeFieldBegin('preferences', Thrift.Type.STRUCT, 4);
    this.preferences.write(output);
    output.writeFieldEnd();
  }
  if (this.description !== null && this.description !== undefined) {
    output.writeFieldBegin('description', Thrift.Type.STRING, 5);
    output.writeString(this.description);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Role = module.exports.Role = function(args) {
  this.appId = null;
  this.resourceId = null;
  this.actionKey = null;
  this.featureCode = null;
  if (args) {
    if (args.appId !== undefined && args.appId !== null) {
      this.appId = args.appId;
    }
    if (args.resourceId !== undefined && args.resourceId !== null) {
      this.resourceId = args.resourceId;
    }
    if (args.actionKey !== undefined && args.actionKey !== null) {
      this.actionKey = args.actionKey;
    }
    if (args.featureCode !== undefined && args.featureCode !== null) {
      this.featureCode = args.featureCode;
    }
  }
};
Role.prototype = {};
Role.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.appId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.resourceId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.actionKey = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.featureCode = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Role.prototype.write = function(output) {
  output.writeStructBegin('Role');
  if (this.appId !== null && this.appId !== undefined) {
    output.writeFieldBegin('appId', Thrift.Type.STRING, 1);
    output.writeString(this.appId);
    output.writeFieldEnd();
  }
  if (this.resourceId !== null && this.resourceId !== undefined) {
    output.writeFieldBegin('resourceId', Thrift.Type.STRING, 2);
    output.writeString(this.resourceId);
    output.writeFieldEnd();
  }
  if (this.actionKey !== null && this.actionKey !== undefined) {
    output.writeFieldBegin('actionKey', Thrift.Type.STRING, 3);
    output.writeString(this.actionKey);
    output.writeFieldEnd();
  }
  if (this.featureCode !== null && this.featureCode !== undefined) {
    output.writeFieldBegin('featureCode', Thrift.Type.STRING, 4);
    output.writeString(this.featureCode);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RoleRequest = module.exports.RoleRequest = function(args) {
  this.role = null;
  this.userId = null;
  if (args) {
    if (args.role !== undefined && args.role !== null) {
      this.role = new ttypes.Role(args.role);
    }
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
  }
};
RoleRequest.prototype = {};
RoleRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.role = new ttypes.Role();
        this.role.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RoleRequest.prototype.write = function(output) {
  output.writeStructBegin('RoleRequest');
  if (this.role !== null && this.role !== undefined) {
    output.writeFieldBegin('role', Thrift.Type.STRUCT, 1);
    this.role.write(output);
    output.writeFieldEnd();
  }
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 2);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RolesRequest = module.exports.RolesRequest = function(args) {
  this.roles = null;
  this.userId = null;
  if (args) {
    if (args.roles !== undefined && args.roles !== null) {
      this.roles = Thrift.copyList(args.roles, [ttypes.Role]);
    }
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
  }
};
RolesRequest.prototype = {};
RolesRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.roles = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new ttypes.Role();
          elem6.read(input);
          this.roles.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RolesRequest.prototype.write = function(output) {
  output.writeStructBegin('RolesRequest');
  if (this.roles !== null && this.roles !== undefined) {
    output.writeFieldBegin('roles', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.roles.length);
    for (var iter7 in this.roles)
    {
      if (this.roles.hasOwnProperty(iter7))
      {
        iter7 = this.roles[iter7];
        iter7.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 2);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CreateUserRequest = module.exports.CreateUserRequest = function(args) {
  this.user = null;
  this.roles = null;
  this.sha256UserPassword = null;
  if (args) {
    if (args.user !== undefined && args.user !== null) {
      this.user = new ttypes.User(args.user);
    }
    if (args.roles !== undefined && args.roles !== null) {
      this.roles = Thrift.copyList(args.roles, [ttypes.Role]);
    }
    if (args.sha256UserPassword !== undefined && args.sha256UserPassword !== null) {
      this.sha256UserPassword = args.sha256UserPassword;
    }
  }
};
CreateUserRequest.prototype = {};
CreateUserRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.user = new ttypes.User();
        this.user.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size8 = 0;
        var _rtmp312;
        this.roles = [];
        var _etype11 = 0;
        _rtmp312 = input.readListBegin();
        _etype11 = _rtmp312.etype;
        _size8 = _rtmp312.size;
        for (var _i13 = 0; _i13 < _size8; ++_i13)
        {
          var elem14 = null;
          elem14 = new ttypes.Role();
          elem14.read(input);
          this.roles.push(elem14);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.sha256UserPassword = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CreateUserRequest.prototype.write = function(output) {
  output.writeStructBegin('CreateUserRequest');
  if (this.user !== null && this.user !== undefined) {
    output.writeFieldBegin('user', Thrift.Type.STRUCT, 1);
    this.user.write(output);
    output.writeFieldEnd();
  }
  if (this.roles !== null && this.roles !== undefined) {
    output.writeFieldBegin('roles', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.roles.length);
    for (var iter15 in this.roles)
    {
      if (this.roles.hasOwnProperty(iter15))
      {
        iter15 = this.roles[iter15];
        iter15.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.sha256UserPassword !== null && this.sha256UserPassword !== undefined) {
    output.writeFieldBegin('sha256UserPassword', Thrift.Type.STRING, 3);
    output.writeString(this.sha256UserPassword);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UpdateUserRequest = module.exports.UpdateUserRequest = function(args) {
  this.user = null;
  this.id = null;
  if (args) {
    if (args.user !== undefined && args.user !== null) {
      this.user = new ttypes.User(args.user);
    }
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
  }
};
UpdateUserRequest.prototype = {};
UpdateUserRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.user = new ttypes.User();
        this.user.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateUserRequest.prototype.write = function(output) {
  output.writeStructBegin('UpdateUserRequest');
  if (this.user !== null && this.user !== undefined) {
    output.writeFieldBegin('user', Thrift.Type.STRUCT, 1);
    this.user.write(output);
    output.writeFieldEnd();
  }
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 2);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var DeleteUserRequest = module.exports.DeleteUserRequest = function(args) {
  this.userId = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
  }
};
DeleteUserRequest.prototype = {};
DeleteUserRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DeleteUserRequest.prototype.write = function(output) {
  output.writeStructBegin('DeleteUserRequest');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UpdateUserPreferencesRequest = module.exports.UpdateUserPreferencesRequest = function(args) {
  this.userId = null;
  this.language = null;
  this.lastCompanyLoaded = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.language !== undefined && args.language !== null) {
      this.language = args.language;
    }
    if (args.lastCompanyLoaded !== undefined && args.lastCompanyLoaded !== null) {
      this.lastCompanyLoaded = args.lastCompanyLoaded;
    }
  }
};
UpdateUserPreferencesRequest.prototype = {};
UpdateUserPreferencesRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.language = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.lastCompanyLoaded = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateUserPreferencesRequest.prototype.write = function(output) {
  output.writeStructBegin('UpdateUserPreferencesRequest');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.language !== null && this.language !== undefined) {
    output.writeFieldBegin('language', Thrift.Type.STRING, 2);
    output.writeString(this.language);
    output.writeFieldEnd();
  }
  if (this.lastCompanyLoaded !== null && this.lastCompanyLoaded !== undefined) {
    output.writeFieldBegin('lastCompanyLoaded', Thrift.Type.STRING, 3);
    output.writeString(this.lastCompanyLoaded);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ApiKey = module.exports.ApiKey = function(args) {
  this.description = null;
  if (args) {
    if (args.description !== undefined && args.description !== null) {
      this.description = args.description;
    }
  }
};
ApiKey.prototype = {};
ApiKey.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.description = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ApiKey.prototype.write = function(output) {
  output.writeStructBegin('ApiKey');
  if (this.description !== null && this.description !== undefined) {
    output.writeFieldBegin('description', Thrift.Type.STRING, 1);
    output.writeString(this.description);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CreateApiKeyRequest = module.exports.CreateApiKeyRequest = function(args) {
  this.apiKey = null;
  this.roles = null;
  this.skipNotification = null;
  this.emailRecipient = null;
  if (args) {
    if (args.apiKey !== undefined && args.apiKey !== null) {
      this.apiKey = new ttypes.ApiKey(args.apiKey);
    }
    if (args.roles !== undefined && args.roles !== null) {
      this.roles = Thrift.copyList(args.roles, [ttypes.Role]);
    }
    if (args.skipNotification !== undefined && args.skipNotification !== null) {
      this.skipNotification = args.skipNotification;
    }
    if (args.emailRecipient !== undefined && args.emailRecipient !== null) {
      this.emailRecipient = args.emailRecipient;
    }
  }
};
CreateApiKeyRequest.prototype = {};
CreateApiKeyRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.apiKey = new ttypes.ApiKey();
        this.apiKey.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size16 = 0;
        var _rtmp320;
        this.roles = [];
        var _etype19 = 0;
        _rtmp320 = input.readListBegin();
        _etype19 = _rtmp320.etype;
        _size16 = _rtmp320.size;
        for (var _i21 = 0; _i21 < _size16; ++_i21)
        {
          var elem22 = null;
          elem22 = new ttypes.Role();
          elem22.read(input);
          this.roles.push(elem22);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.BOOL) {
        this.skipNotification = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.emailRecipient = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CreateApiKeyRequest.prototype.write = function(output) {
  output.writeStructBegin('CreateApiKeyRequest');
  if (this.apiKey !== null && this.apiKey !== undefined) {
    output.writeFieldBegin('apiKey', Thrift.Type.STRUCT, 1);
    this.apiKey.write(output);
    output.writeFieldEnd();
  }
  if (this.roles !== null && this.roles !== undefined) {
    output.writeFieldBegin('roles', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.roles.length);
    for (var iter23 in this.roles)
    {
      if (this.roles.hasOwnProperty(iter23))
      {
        iter23 = this.roles[iter23];
        iter23.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.skipNotification !== null && this.skipNotification !== undefined) {
    output.writeFieldBegin('skipNotification', Thrift.Type.BOOL, 3);
    output.writeBool(this.skipNotification);
    output.writeFieldEnd();
  }
  if (this.emailRecipient !== null && this.emailRecipient !== undefined) {
    output.writeFieldBegin('emailRecipient', Thrift.Type.STRING, 4);
    output.writeString(this.emailRecipient);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CreateApiKeyResponse = module.exports.CreateApiKeyResponse = function(args) {
  this.id = null;
  this.secret = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.secret !== undefined && args.secret !== null) {
      this.secret = args.secret;
    }
  }
};
CreateApiKeyResponse.prototype = {};
CreateApiKeyResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.secret = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CreateApiKeyResponse.prototype.write = function(output) {
  output.writeStructBegin('CreateApiKeyResponse');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.secret !== null && this.secret !== undefined) {
    output.writeFieldBegin('secret', Thrift.Type.STRING, 2);
    output.writeString(this.secret);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UpdateApiKeyRequest = module.exports.UpdateApiKeyRequest = function(args) {
  this.apiKey = null;
  this.id = null;
  if (args) {
    if (args.apiKey !== undefined && args.apiKey !== null) {
      this.apiKey = new ttypes.ApiKey(args.apiKey);
    }
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
  }
};
UpdateApiKeyRequest.prototype = {};
UpdateApiKeyRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.apiKey = new ttypes.ApiKey();
        this.apiKey.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateApiKeyRequest.prototype.write = function(output) {
  output.writeStructBegin('UpdateApiKeyRequest');
  if (this.apiKey !== null && this.apiKey !== undefined) {
    output.writeFieldBegin('apiKey', Thrift.Type.STRUCT, 1);
    this.apiKey.write(output);
    output.writeFieldEnd();
  }
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 2);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var DeleteApiKeyRequest = module.exports.DeleteApiKeyRequest = function(args) {
  this.id = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
  }
};
DeleteApiKeyRequest.prototype = {};
DeleteApiKeyRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DeleteApiKeyRequest.prototype.write = function(output) {
  output.writeStructBegin('DeleteApiKeyRequest');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var EnableRequest = module.exports.EnableRequest = function(args) {
  this.userId = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
  }
};
EnableRequest.prototype = {};
EnableRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

EnableRequest.prototype.write = function(output) {
  output.writeStructBegin('EnableRequest');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var DisableRequest = module.exports.DisableRequest = function(args) {
  this.userId = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
  }
};
DisableRequest.prototype = {};
DisableRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DisableRequest.prototype.write = function(output) {
  output.writeStructBegin('DisableRequest');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ActivateRequest = module.exports.ActivateRequest = function(args) {
  this.userId = null;
  this.activationToken = null;
  this.sha256UserPassword = null;
  this.appName = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.activationToken !== undefined && args.activationToken !== null) {
      this.activationToken = args.activationToken;
    }
    if (args.sha256UserPassword !== undefined && args.sha256UserPassword !== null) {
      this.sha256UserPassword = args.sha256UserPassword;
    }
    if (args.appName !== undefined && args.appName !== null) {
      this.appName = args.appName;
    }
  }
};
ActivateRequest.prototype = {};
ActivateRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.activationToken = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.sha256UserPassword = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.appName = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ActivateRequest.prototype.write = function(output) {
  output.writeStructBegin('ActivateRequest');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.activationToken !== null && this.activationToken !== undefined) {
    output.writeFieldBegin('activationToken', Thrift.Type.STRING, 2);
    output.writeString(this.activationToken);
    output.writeFieldEnd();
  }
  if (this.sha256UserPassword !== null && this.sha256UserPassword !== undefined) {
    output.writeFieldBegin('sha256UserPassword', Thrift.Type.STRING, 3);
    output.writeString(this.sha256UserPassword);
    output.writeFieldEnd();
  }
  if (this.appName !== null && this.appName !== undefined) {
    output.writeFieldBegin('appName', Thrift.Type.STRING, 4);
    output.writeString(this.appName);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UpdatePasswordRequest = module.exports.UpdatePasswordRequest = function(args) {
  this.userId = null;
  this.sha256UserPassword = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.sha256UserPassword !== undefined && args.sha256UserPassword !== null) {
      this.sha256UserPassword = args.sha256UserPassword;
    }
  }
};
UpdatePasswordRequest.prototype = {};
UpdatePasswordRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.sha256UserPassword = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdatePasswordRequest.prototype.write = function(output) {
  output.writeStructBegin('UpdatePasswordRequest');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.sha256UserPassword !== null && this.sha256UserPassword !== undefined) {
    output.writeFieldBegin('sha256UserPassword', Thrift.Type.STRING, 2);
    output.writeString(this.sha256UserPassword);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ResetPasswordRequest = module.exports.ResetPasswordRequest = function(args) {
  this.userId = null;
  this.resetPasswordToken = null;
  this.sha256UserPassword = null;
  this.appName = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.resetPasswordToken !== undefined && args.resetPasswordToken !== null) {
      this.resetPasswordToken = args.resetPasswordToken;
    }
    if (args.sha256UserPassword !== undefined && args.sha256UserPassword !== null) {
      this.sha256UserPassword = args.sha256UserPassword;
    }
    if (args.appName !== undefined && args.appName !== null) {
      this.appName = args.appName;
    }
  }
};
ResetPasswordRequest.prototype = {};
ResetPasswordRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.resetPasswordToken = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.sha256UserPassword = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.appName = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ResetPasswordRequest.prototype.write = function(output) {
  output.writeStructBegin('ResetPasswordRequest');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.resetPasswordToken !== null && this.resetPasswordToken !== undefined) {
    output.writeFieldBegin('resetPasswordToken', Thrift.Type.STRING, 2);
    output.writeString(this.resetPasswordToken);
    output.writeFieldEnd();
  }
  if (this.sha256UserPassword !== null && this.sha256UserPassword !== undefined) {
    output.writeFieldBegin('sha256UserPassword', Thrift.Type.STRING, 3);
    output.writeString(this.sha256UserPassword);
    output.writeFieldEnd();
  }
  if (this.appName !== null && this.appName !== undefined) {
    output.writeFieldBegin('appName', Thrift.Type.STRING, 4);
    output.writeString(this.appName);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ResendWelcomeEmailRequest = module.exports.ResendWelcomeEmailRequest = function(args) {
  this.userId = null;
  this.emailRecipient = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.emailRecipient !== undefined && args.emailRecipient !== null) {
      this.emailRecipient = args.emailRecipient;
    }
  }
};
ResendWelcomeEmailRequest.prototype = {};
ResendWelcomeEmailRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.emailRecipient = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ResendWelcomeEmailRequest.prototype.write = function(output) {
  output.writeStructBegin('ResendWelcomeEmailRequest');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.emailRecipient !== null && this.emailRecipient !== undefined) {
    output.writeFieldBegin('emailRecipient', Thrift.Type.STRING, 2);
    output.writeString(this.emailRecipient);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var SendForgotPasswordRequest = module.exports.SendForgotPasswordRequest = function(args) {
  this.userId = null;
  this.emailRecipient = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.emailRecipient !== undefined && args.emailRecipient !== null) {
      this.emailRecipient = args.emailRecipient;
    }
  }
};
SendForgotPasswordRequest.prototype = {};
SendForgotPasswordRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.emailRecipient = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SendForgotPasswordRequest.prototype.write = function(output) {
  output.writeStructBegin('SendForgotPasswordRequest');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.emailRecipient !== null && this.emailRecipient !== undefined) {
    output.writeFieldBegin('emailRecipient', Thrift.Type.STRING, 2);
    output.writeString(this.emailRecipient);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ttypes.VERSION = '2.4.0';
