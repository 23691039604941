/* eslint-disable */
//
// Autogenerated by Thrift Compiler (0.11.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
"use strict";

var thrift = require('browser-thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = module.exports = {};
var Header = module.exports.Header = function(args) {
  this.accessToken = null;
  this.appName = null;
  this.requestId = null;
  this.correlationId = null;
  if (args) {
    if (args.accessToken !== undefined && args.accessToken !== null) {
      this.accessToken = args.accessToken;
    }
    if (args.appName !== undefined && args.appName !== null) {
      this.appName = args.appName;
    }
    if (args.requestId !== undefined && args.requestId !== null) {
      this.requestId = args.requestId;
    }
    if (args.correlationId !== undefined && args.correlationId !== null) {
      this.correlationId = args.correlationId;
    }
  }
};
Header.prototype = {};
Header.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.accessToken = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.appName = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.requestId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.correlationId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Header.prototype.write = function(output) {
  output.writeStructBegin('Header');
  if (this.accessToken !== null && this.accessToken !== undefined) {
    output.writeFieldBegin('accessToken', Thrift.Type.STRING, 1);
    output.writeString(this.accessToken);
    output.writeFieldEnd();
  }
  if (this.appName !== null && this.appName !== undefined) {
    output.writeFieldBegin('appName', Thrift.Type.STRING, 2);
    output.writeString(this.appName);
    output.writeFieldEnd();
  }
  if (this.requestId !== null && this.requestId !== undefined) {
    output.writeFieldBegin('requestId', Thrift.Type.STRING, 3);
    output.writeString(this.requestId);
    output.writeFieldEnd();
  }
  if (this.correlationId !== null && this.correlationId !== undefined) {
    output.writeFieldBegin('correlationId', Thrift.Type.STRING, 4);
    output.writeString(this.correlationId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AgyoService = module.exports.AgyoService = function(args) {
  this.id = null;
  this.description = null;
  this.appId = null;
  this.appDescription = null;
  this.active = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.description !== undefined && args.description !== null) {
      this.description = args.description;
    }
    if (args.appId !== undefined && args.appId !== null) {
      this.appId = args.appId;
    }
    if (args.appDescription !== undefined && args.appDescription !== null) {
      this.appDescription = args.appDescription;
    }
    if (args.active !== undefined && args.active !== null) {
      this.active = args.active;
    }
  }
};
AgyoService.prototype = {};
AgyoService.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.description = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.appId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.appDescription = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.BOOL) {
        this.active = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AgyoService.prototype.write = function(output) {
  output.writeStructBegin('AgyoService');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.description !== null && this.description !== undefined) {
    output.writeFieldBegin('description', Thrift.Type.STRING, 2);
    output.writeString(this.description);
    output.writeFieldEnd();
  }
  if (this.appId !== null && this.appId !== undefined) {
    output.writeFieldBegin('appId', Thrift.Type.STRING, 3);
    output.writeString(this.appId);
    output.writeFieldEnd();
  }
  if (this.appDescription !== null && this.appDescription !== undefined) {
    output.writeFieldBegin('appDescription', Thrift.Type.STRING, 4);
    output.writeString(this.appDescription);
    output.writeFieldEnd();
  }
  if (this.active !== null && this.active !== undefined) {
    output.writeFieldBegin('active', Thrift.Type.BOOL, 5);
    output.writeBool(this.active);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ItemService = module.exports.ItemService = function(args) {
  this.agyoService = null;
  this.ownerId = null;
  this.owner = null;
  this.availableToUser = null;
  if (args) {
    if (args.agyoService !== undefined && args.agyoService !== null) {
      this.agyoService = new ttypes.AgyoService(args.agyoService);
    }
    if (args.ownerId !== undefined && args.ownerId !== null) {
      this.ownerId = args.ownerId;
    }
    if (args.owner !== undefined && args.owner !== null) {
      this.owner = args.owner;
    }
    if (args.availableToUser !== undefined && args.availableToUser !== null) {
      this.availableToUser = args.availableToUser;
    }
  }
};
ItemService.prototype = {};
ItemService.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.agyoService = new ttypes.AgyoService();
        this.agyoService.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.ownerId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.BOOL) {
        this.owner = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.BOOL) {
        this.availableToUser = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ItemService.prototype.write = function(output) {
  output.writeStructBegin('ItemService');
  if (this.agyoService !== null && this.agyoService !== undefined) {
    output.writeFieldBegin('agyoService', Thrift.Type.STRUCT, 1);
    this.agyoService.write(output);
    output.writeFieldEnd();
  }
  if (this.ownerId !== null && this.ownerId !== undefined) {
    output.writeFieldBegin('ownerId', Thrift.Type.STRING, 2);
    output.writeString(this.ownerId);
    output.writeFieldEnd();
  }
  if (this.owner !== null && this.owner !== undefined) {
    output.writeFieldBegin('owner', Thrift.Type.BOOL, 3);
    output.writeBool(this.owner);
    output.writeFieldEnd();
  }
  if (this.availableToUser !== null && this.availableToUser !== undefined) {
    output.writeFieldBegin('availableToUser', Thrift.Type.BOOL, 4);
    output.writeBool(this.availableToUser);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ItemServicesRequest = module.exports.ItemServicesRequest = function(args) {
  this.itemIds = null;
  if (args) {
    if (args.itemIds !== undefined && args.itemIds !== null) {
      this.itemIds = Thrift.copyList(args.itemIds, [null]);
    }
  }
};
ItemServicesRequest.prototype = {};
ItemServicesRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.SET) {
        var _size0 = 0;
        var _rtmp34;
        this.itemIds = [];
        var _etype3 = 0;
        _rtmp34 = input.readSetBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = input.readString();
          this.itemIds.push(elem6);
        }
        input.readSetEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ItemServicesRequest.prototype.write = function(output) {
  output.writeStructBegin('ItemServicesRequest');
  if (this.itemIds !== null && this.itemIds !== undefined) {
    output.writeFieldBegin('itemIds', Thrift.Type.SET, 1);
    output.writeSetBegin(Thrift.Type.STRING, this.itemIds.length);
    for (var iter7 in this.itemIds)
    {
      if (this.itemIds.hasOwnProperty(iter7))
      {
        iter7 = this.itemIds[iter7];
        output.writeString(iter7);
      }
    }
    output.writeSetEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AgyoServicesResponse = module.exports.AgyoServicesResponse = function(args) {
  this.agyoServices = null;
  if (args) {
    if (args.agyoServices !== undefined && args.agyoServices !== null) {
      this.agyoServices = Thrift.copyList(args.agyoServices, [ttypes.AgyoService]);
    }
  }
};
AgyoServicesResponse.prototype = {};
AgyoServicesResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size8 = 0;
        var _rtmp312;
        this.agyoServices = [];
        var _etype11 = 0;
        _rtmp312 = input.readListBegin();
        _etype11 = _rtmp312.etype;
        _size8 = _rtmp312.size;
        for (var _i13 = 0; _i13 < _size8; ++_i13)
        {
          var elem14 = null;
          elem14 = new ttypes.AgyoService();
          elem14.read(input);
          this.agyoServices.push(elem14);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AgyoServicesResponse.prototype.write = function(output) {
  output.writeStructBegin('AgyoServicesResponse');
  if (this.agyoServices !== null && this.agyoServices !== undefined) {
    output.writeFieldBegin('agyoServices', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.agyoServices.length);
    for (var iter15 in this.agyoServices)
    {
      if (this.agyoServices.hasOwnProperty(iter15))
      {
        iter15 = this.agyoServices[iter15];
        iter15.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ItemServicesResponse = module.exports.ItemServicesResponse = function(args) {
  this.itemServices = null;
  if (args) {
    if (args.itemServices !== undefined && args.itemServices !== null) {
      this.itemServices = Thrift.copyMap(args.itemServices, [Thrift.copyList, ttypes.ItemService]);
    }
  }
};
ItemServicesResponse.prototype = {};
ItemServicesResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.MAP) {
        var _size16 = 0;
        var _rtmp320;
        this.itemServices = {};
        var _ktype17 = 0;
        var _vtype18 = 0;
        _rtmp320 = input.readMapBegin();
        _ktype17 = _rtmp320.ktype;
        _vtype18 = _rtmp320.vtype;
        _size16 = _rtmp320.size;
        for (var _i21 = 0; _i21 < _size16; ++_i21)
        {
          var key22 = null;
          var val23 = null;
          key22 = input.readString();
          var _size24 = 0;
          var _rtmp328;
          val23 = [];
          var _etype27 = 0;
          _rtmp328 = input.readListBegin();
          _etype27 = _rtmp328.etype;
          _size24 = _rtmp328.size;
          for (var _i29 = 0; _i29 < _size24; ++_i29)
          {
            var elem30 = null;
            elem30 = new ttypes.ItemService();
            elem30.read(input);
            val23.push(elem30);
          }
          input.readListEnd();
          this.itemServices[key22] = val23;
        }
        input.readMapEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ItemServicesResponse.prototype.write = function(output) {
  output.writeStructBegin('ItemServicesResponse');
  if (this.itemServices !== null && this.itemServices !== undefined) {
    output.writeFieldBegin('itemServices', Thrift.Type.MAP, 1);
    output.writeMapBegin(Thrift.Type.STRING, Thrift.Type.LIST, Thrift.objectLength(this.itemServices));
    for (var kiter31 in this.itemServices)
    {
      if (this.itemServices.hasOwnProperty(kiter31))
      {
        var viter32 = this.itemServices[kiter31];
        output.writeString(kiter31);
        output.writeListBegin(Thrift.Type.STRUCT, viter32.length);
        for (var iter33 in viter32)
        {
          if (viter32.hasOwnProperty(iter33))
          {
            iter33 = viter32[iter33];
            iter33.write(output);
          }
        }
        output.writeListEnd();
      }
    }
    output.writeMapEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GenericError = module.exports.GenericError = function(args) {
  Thrift.TException.call(this, "GenericError");
  this.name = "GenericError";
  this.code = null;
  this.message = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Thrift.inherits(GenericError, Thrift.TException);
GenericError.prototype.name = 'GenericError';
GenericError.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GenericError.prototype.write = function(output) {
  output.writeStructBegin('GenericError');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthenticationException = module.exports.AuthenticationException = function(args) {
  Thrift.TException.call(this, "AuthenticationException");
  this.name = "AuthenticationException";
  this.code = null;
  this.message = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Thrift.inherits(AuthenticationException, Thrift.TException);
AuthenticationException.prototype.name = 'AuthenticationException';
AuthenticationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthenticationException.prototype.write = function(output) {
  output.writeStructBegin('AuthenticationException');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ttypes.VERSION = '2.0.0';
