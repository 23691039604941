import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { translateMessage } from "../../../../i18n";

import SearchInputBar from "../../../general/search-input-filter";

import { Spinner } from "../../../general/spinner";

import { Pagination } from "antd";

import { CompanyListElement } from "./company-element";

import chunk from "lodash.chunk";
import { UsersManagegementContext } from "../../../../user-context";

import {
    CompaniesContainer,
    Description,
    PaginationContainer,
    SearchInputContainer,
    Title,
    TitleContainer
} from "./styled";

const companyPageSize = 4;

const SelectCompanyDrawerContent = ({
    companies,
    flow,
    isOpen,
    isUserWithTooManyItems,
    loading,
    onCompanySelect,
    user
}) => {
    const { setUserCreationFlowFirstStep } = useContext(UsersManagegementContext);
    const [fullText, setFullText] = useState("");
    const [page, setPage] = useState(0);

    const totalCompanies = fullText
        ? companies.filter(ele => ele.description.toLowerCase().includes(fullText.toLowerCase()))
        : companies;

    let paginatedItems = useMemo(() => chunk(totalCompanies, companyPageSize), [totalCompanies]);

    let companyList = [];
    if (paginatedItems[page] && paginatedItems[page].length > 0) {
        companyList = paginatedItems[page].map(company => {
            return (
                <CompanyListElement
                    key={company.itemUuid}
                    buttonLabel={
                        !user || user.roles.some(r => r.resourceUuid === company.itemUuid)
                            ? "general.selectOne"
                            : "c-registry-user.change-company.drawer.item.set-permissions"
                    }
                    company={company}
                    onCompanySelect={company => {
                        onCompanySelect(company);
                        setUserCreationFlowFirstStep("setPermission");
                    }}
                    gainsightTrackAttribute={
                        !user || user.roles.some(r => r.resourceUuid === company.itemUuid)
                            ? flow === "list-user"
                                ? "list-user-change-company-from-drawer"
                                : "edit-user-change-company-from-drawer"
                            : "registry-user-set-permission-company-from-drawer"
                    }
                />
            );
        });
    }

    const totalItems = fullText ? totalCompanies.length : totalCompanies.length;

    useEffect(() => {
        if (!isUserWithTooManyItems && isOpen) {
            setPage(0);
        }
    }, [fullText, isUserWithTooManyItems, isOpen]);

    return (
        <>
            <TitleContainer>
                <Title>
                    <FormattedMessage id="c-registry-user.change-company.drawer.description-title" />
                </Title>
                <Description>
                    <FormattedMessage id="c-registry-user.change-company.drawer.description" />
                </Description>
            </TitleContainer>
            <SearchInputContainer>
                <SearchInputBar
                    value={fullText}
                    onSearch={value => {
                        setFullText(value);
                    }}
                    onRefresh={() => {}}
                    loading={false}
                    label={<FormattedMessage id="c-registry-user.change-company.drawer.search-label" />}
                    showRefresh={false}
                    placeholder={translateMessage("c-registry-user.change-company.drawer.search-placeholder")}
                    cypressid={"manage-drawer-company-search"}
                    width={"520px"}
                />
            </SearchInputContainer>
            <PaginationContainer>
                {!isUserWithTooManyItems && (
                    <Pagination
                        defaultCurrent={1}
                        current={page + 1}
                        total={totalItems}
                        style={{ marginTop: "10px" }}
                        showTotal={(total, range) => {
                            return `${total} ${translateMessage("general.companies")}`;
                        }}
                        defaultPageSize={companyPageSize}
                        pageSize={companyPageSize}
                        showSizeChanger={false}
                        onShowSizeChange={() => {}}
                        onChange={(page, pageSize) => {
                            setPage(page - 1);
                        }}
                    />
                )}
            </PaginationContainer>
            <div>
                <Spinner loading={loading}>
                    <CompaniesContainer>{companyList}</CompaniesContainer>
                </Spinner>
            </div>
        </>
    );
};

SelectCompanyDrawerContent.propTypes = {
    companies: PropTypes.arrayOf(
        PropTypes.shape({
            itemId: PropTypes.string.isRequired,
            itemUuid: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            taxId: PropTypes.string.isRequired,
            vatNumber: PropTypes.string.isRequired,
            classifier: PropTypes.string.isRequired
        })
    ),
    isOpen: PropTypes.bool,
    isUserWithTooManyItems: PropTypes.bool,
    loading: PropTypes.bool,
    onCompanySelect: PropTypes.func.isRequired,
    user: PropTypes.any
};

export default SelectCompanyDrawerContent;
