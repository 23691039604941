import React from "react";
import PropTypes from "prop-types";

import { faBell as faBellRegular } from "@fortawesome/pro-regular-svg-icons";
import { Badge } from "antd";

import { useInterval } from "../../hooks/use-interval";

import { NotificationDrawerIconContainer, NotificationDrawerIcon } from "./styled";

import { TIMER_FETCH_WEB_NOTIFICATIONS_STATUS } from "../../config";

export const NotificationsBadge = ({ active, exist, getNotificationsExist, onClick }) => {
    useInterval(getNotificationsExist, TIMER_FETCH_WEB_NOTIFICATIONS_STATUS);

    return (
        <NotificationDrawerIconContainer onClick={onClick} active={active}>
            <Badge dot={exist}>
                <NotificationDrawerIcon icon={faBellRegular} />
            </Badge>
        </NotificationDrawerIconContainer>
    );
};

NotificationsBadge.propTypes = {
    active: PropTypes.bool.isRequired,
    exist: PropTypes.bool.isRequired,
    getNotificationsExist: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired
};

export default NotificationsBadge;
