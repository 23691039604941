import {
    GET_ITEM_APPS_START,
    GET_ITEM_APPS_ERROR,
    GET_ITEM_APPS_SUCCESS,
    GET_WORKSPACE_START,
    GET_WORKSPACE_ERROR,
    GET_WORKSPACE_SUCCESS
} from "../../../actions/services/app";
const defaultState = {
    status: { started: false, ended: false, error: false, errorInfo: {} },
    data: {}
};

export const itemApps = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_ITEM_APPS_START:
            return {
                ...defaultState,
                status: {
                    ...defaultState.status,
                    started: true
                }
            };
        case GET_ITEM_APPS_ERROR:
            return {
                ...defaultState,
                status: {
                    ...defaultState.status,
                    error: true,
                    errorInfo: error
                }
            };
        case GET_ITEM_APPS_SUCCESS:
            return {
                status: {
                    ...defaultState.status,
                    ended: true
                },
                data: payload
            };
        default:
            return state;
    }
};

export const workspaceId = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_WORKSPACE_START:
            return {
                ...defaultState,
                status: {
                    ...defaultState.status,
                    started: true
                }
            };
        case GET_WORKSPACE_ERROR:
            return {
                ...defaultState,
                status: {
                    ...defaultState.status,
                    error: true,
                    errorInfo: error
                }
            };
        case GET_WORKSPACE_SUCCESS:
            return {
                status: {
                    ...defaultState.status,
                    ended: true
                },
                data: {
                    id: payload !== undefined ? payload : null
                }
            };
        default:
            return state;
    }
};
