import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Description = styled.div`
    color: rgb(21, 41, 53);
    font-family: Cairo;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.11px;
    line-height: 24px;
`;

export const CompanyTitleContainer = styled.div`
    color: rgb(90, 104, 114);
    font-family: Cairo;
    font-size: 14px;
    font-weight: 600;
`;

export const CompanyDescriptionContainer = styled.div`
    color: rgb(0, 80, 117);
    font-family: Cairo;
    font-size: 34px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 16px;
`;

export const CompanyDetailsContainer = styled.div`
    flex: 1;
`;

export const PlusIcon = styled(FontAwesomeIcon)`
    margin-right: 5px;
`;

export const Header = styled.div`
    display: flex;
    gap: 24px;
`;

export const ModalContentContainer = styled.div``;
