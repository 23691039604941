import styled from "styled-components";

import FontAwesomeIcon from "../../../general/fontawesome-icon";

export const Container = styled.div`
    font-size: 20px;
`;

export const IconWithMargin = styled(FontAwesomeIcon)`
    margin: 0px 15px;
`;
