import styled from "styled-components";
import { Button } from "@ts-digital/vrc";

export const Header = styled.div`
    height: 72px;
    box-shadow: 0px -2px 8px 0px rgba(21, 41, 53, 0.24);
    position: relative;

    color: rgb(21, 41, 53);
    font-size: 16px;
    font-family: Roboto;
    font-weight: 500;
    letter-spacing: 0.2px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
`;

export const HeaderButton = styled(Button)`
    &:disabled {
        pointer-events: none;
    }
`;

export const NotificationsContainer = styled.div`
    overflow-y: scroll;
    height: calc(100vh - 136px);
`;

export const NotificationsContent = styled.div`
    position: relative;
    height: 100%;
`;
