import styled from "styled-components";

export const InvoicesTextContainer = styled.div`
    margin-bottom: 0;
    height: 16px;
    line-height: 16px;
`;

export const FlexContainer = styled.div`
    display: flex;
    width: 100%;
    & > :not(:last-child) {
        padding-right: 16px;
    }
`;

export const SubscriptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    & > :not(:last-child) {
        padding-bottom: 16px;
    }
`;

export const UsageContainer = styled.div`
    flex-grow: 4;
`;

export const CreditContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;
