import { API_URL } from "../../config";

import { getRestCall } from "../../utils/rest-api-call";
import { getUserProfile } from "../../utils/state-utils/get-user-profile";
import { getAuthWithAppName } from "../auth";

export const LIST_EDITABLE_USERS_START = "LIST_EDITABLE_USERS_START";
export const LIST_EDITABLE_USERS_SUCCESS = "LIST_EDITABLE_USERS_SUCCESS";
export const LIST_EDITABLE_USERS_ERROR = "LIST_EDITABLE_USERS_ERROR";

export function listEditableUsers(itemId) {
    return async (dispatch, getState) => {
        dispatch({
            type: LIST_EDITABLE_USERS_START,
            payload: itemId
        });

        try {
            const auth = getAuthWithAppName(getState().auth);
            const users = await getRestCall(
                `${API_URL}/items/${itemId}/editableUsers?page=0&size=150`,
                auth,
                {},
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                itemId
            );

            dispatch({
                type: LIST_EDITABLE_USERS_SUCCESS,
                payload: users
            });
        } catch (error) {
            dispatch({
                type: LIST_EDITABLE_USERS_ERROR,
                error: error
            });
        }
    };
}
