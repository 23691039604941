import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { Container, SearchIconContainer, StyledInput } from "./styled";

export const TextFilter = ({ column, onFilterSubmit, alwaysActive }) => {
    const { id, minLength, searchPlaceholder } = column;
    const [value, setValue] = useState("");

    useEffect(() => {
        if (column) {
            setValue(column.filterValue);
        }
    }, [column]);

    const active = alwaysActive || (value !== undefined && (value.length > minLength || value === "") ? true : false);
    return (
        <Container>
            <StyledInput
                id={id}
                name={id}
                placeholder={searchPlaceholder ? searchPlaceholder : "Search"}
                onChange={e => setValue(e.target.value)}
                value={value}
                onKeyPress={e => {
                    if (e.key === "Enter" && active) {
                        column.setFilter(value);
                        onFilterSubmit(value);
                    }
                }}
            />
            <SearchIconContainer
                active={active.toString()}
                icon={faSearch}
                onClick={() => {
                    if (active) {
                        column.setFilter(value);
                        onFilterSubmit(value);
                    }
                }}
            />
        </Container>
    );
};

TextFilter.propTypes = {
    /** Callback invoked when the input's search icon is clicked or when enter is pressed while focused */
    onFilterSubmit: PropTypes.func.isRequired,
    /** Props given by the table's render function */
    column: PropTypes.any.isRequired
};
