import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { translateMessage } from "../../../i18n";

import { Table } from "../../general/table";
import ExpanderCell from "../../general/table/expander-cell";
import ItemActionsCell from "./item-actions-cell";
import ItemClassifierIcon from "./item-classifier-icon";
import ServiceStatusCell from "./service-status-cell";

import { FormattedHTMLMessage } from "react-intl";

import { Tooltip } from "antd";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { InfoIcon, ItemDescription, ItemsRolesTableFooter } from "./styled";

const ItemsRolesTable = ({
    data,
    loading,
    onDeleteUserFromItem,
    onEditApp,
    onEditUser,
    onFiltersChange,
    onPageChange,
    page,
    preloadAssignableRoles,
    totalItems,
    totalPages
}) => {
    const [filters, updateFilters] = useReducer((currentFilters, update) => {
        return { ...currentFilters, ...update };
    }, []);

    const handleExpansion = useCallback(
        (expanding, row) => row.depth === 0 && expanding && preloadAssignableRoles(row.original.itemId),
        [preloadAssignableRoles]
    );

    const handleFilterSubmit = useCallback((value, columnId) => {
        updateFilters({ [columnId]: value });
    }, []);

    useEffect(() => {
        onFiltersChange(filters);
    }, [filters, onFiltersChange]);

    const tableColumns = useMemo(
        () => [
            {
                accessor: "description",
                Header: <FormattedMessage id="c-user-summary.items-roles-table.headers.description" />,
                Cell: ({ value, row }) => (
                    <ExpanderCell row={row} onExpansion={handleExpansion}>
                        <ItemDescription>
                            {row.original.itemClassifier && (
                                <>
                                    <ItemClassifierIcon classifier={row.original.itemClassifier} />
                                    <FormattedMessage id={`general.classifiers.${row.original.itemClassifier}`} />:{" "}
                                </>
                            )}
                            {value}
                        </ItemDescription>
                    </ExpanderCell>
                ),
                minLength: 3,
                searchPlaceholder: translateMessage(`c-user-summary.items-roles-table.headers.search-placeholder`),
                width: "468px"
            },
            {
                accessor: "roleLabel",
                disableFilters: true,
                Header: <FormattedMessage id="general.user-role" />,
                Cell: ({ value }) => <FormattedMessage id={`c-registry-user.users-manage-${value}`} />
            },
            {
                accessor: "serviceStatus",
                disableFilters: true,
                Header: <FormattedMessage id="c-user-roles-table.table.header.serviceStatus" />,
                Cell: ServiceStatusCell
            },
            {
                accessor: "serviceActivationDate",
                disableFilters: true,
                Header: (
                    <>
                        <FormattedMessage id="c-user-summary.items-roles-table.headers.activation-date" />
                        <Tooltip
                            placement="bottom"
                            title={<FormattedHTMLMessage id="c-user-roles-table.table.header.activationDate.tooltip" />}
                        >
                            <InfoIcon icon={faInfoCircle} />
                        </Tooltip>
                    </>
                )
            },
            {
                id: "actions",
                Cell: ({ row }) => (
                    <ItemActionsCell
                        onDelete={() => onDeleteUserFromItem(row.original.itemId)}
                        onEditItem={() => onEditUser(row.original.itemId)}
                        onEditApp={() =>
                            onEditApp(
                                row.original.itemId,
                                row.original.appId,
                                row.original.roleLabel,
                                row.subRows.reduce((acc, subRow) => {
                                    acc[subRow.original.featureCode] = subRow.original.roleLabel;
                                    return acc;
                                }, {})
                            )
                        }
                        row={row}
                    />
                ),
                width: "80px"
            }
        ],
        [handleExpansion, onDeleteUserFromItem, onEditApp, onEditUser]
    );

    return (
        <Table
            columns={tableColumns}
            data={data}
            enableFilters={true}
            footerContent={
                <ItemsRolesTableFooter colSpan={5}>
                    <FormattedMessage id="v-user-creation-flow.steps.select-items.table-footer.total-items" />
                    {`: ${totalItems}`}
                </ItemsRolesTableFooter>
            }
            loading={loading}
            onFilterSubmit={handleFilterSubmit}
            onPageChange={onPageChange}
            page={page}
            totalPages={totalPages}
            subRowsKeys={["apps", "features"]}
            useFooter={true}
        />
    );
};

ItemsRolesTable.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string.isRequired,
            itemClassifier: PropTypes.oneOf(["BUILDING", "COMPANY", "PERSON", "STUDIO"]).isRequired,
            itemId: PropTypes.string.isRequired,
            roleLabel: PropTypes.string.isRequired,
            serviceStatus: PropTypes.string,
            serviceActivationDate: PropTypes.string
        })
    ),
    loading: PropTypes.bool,
    onDeleteUserFromItem: PropTypes.func.isRequired,
    onEditApp: PropTypes.func.isRequired,
    onEditUser: PropTypes.func.isRequired,
    onFiltersChange: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number,
    /** Used to preload an item's assignableRoles when its row is expanded */
    preloadAssignableRoles: PropTypes.func.isRequired,
    totalItems: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired
};

export default ItemsRolesTable;
