import { getAuthWithAppName } from "../auth.js";
import { API_URL } from "../../config";

import { getRestCall } from "../../utils/rest-api-call.js";
import { getUserProfile } from "../../utils/state-utils/get-user-profile.js";

export const FETCH_INVOICING_CONFIG_START = "FETCH_INVOICING_CONFIG_START";
export const FETCH_INVOICING_CONFIG_ERROR = "FETCH_INVOICING_CONFIG_ERROR";
export const FETCH_INVOICING_CONFIG_SUCCESS = "FETCH_INVOICING_CONFIG_SUCCESS";

export function getInvoicingConfig(itemId) {
    return async (dispatch, getState) => {
        dispatch({
            type: FETCH_INVOICING_CONFIG_START
        });

        const extractedAuth = getAuthWithAppName(getState().auth);

        try {
            const response = await getRestCall(
                `${API_URL}/services/invoicing/${itemId}`,
                extractedAuth,
                {},
                dispatch,
                extractedAuth.refreshToken,
                getUserProfile(getState()),
                itemId
            );

            dispatch({ type: FETCH_INVOICING_CONFIG_SUCCESS, payload: response });
        } catch (e) {
            dispatch({ type: FETCH_INVOICING_CONFIG_ERROR, error: e, payload: { itemId } });
        }
    };
}
