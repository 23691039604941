import styled from "styled-components";

export const FormContainer = styled.form`
    .ant-input-group-addon {
        width: 100px;
    }
`;

export const LoadingButtonContainer = styled.div`
    text-align: right;
`;

export const ErrorContainer = styled.div`
    color: red;
`;
