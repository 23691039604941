import {
    GET_NOTIFICATION_STATUS_ERROR,
    GET_NOTIFICATION_STATUS_SUCCESS,
    GET_NOTIFICATION_LIST_START,
    GET_NOTIFICATION_LIST_SUCCESS,
    GET_NOTIFICATION_LIST_ERROR
} from "../../../actions/ncs/read";
import {
    DELETE_NOTIFICATIONS_LIST_START,
    DELETE_NOTIFICATIONS_LIST_ERROR,
    DELETE_SINGLE_NOTIFICATION_START,
    DELETE_SINGLE_NOTIFICATION_ERROR,
    UPDATE_NCS_NOTIFICATIONS_LIST_START,
    UPDATE_NCS_NOTIFICATIONS_LIST_ERROR,
    UPDATE_NCS_SINGLE_NOTIFICATION_START,
    UPDATE_NCS_SINGLE_NOTIFICATION_ERROR
} from "../../../actions/ncs/write";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    },
    data: [],
    exist: false
};

export const getNotificationsUnread = notifications => {
    return !!notifications.find(n => !n.read);
};

export function notifications(state = defaultState, { type, payload, error }) {
    let notifications;

    switch (type) {
        case GET_NOTIFICATION_STATUS_ERROR:
            return {
                ...state,
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code }
                }
            };
        case GET_NOTIFICATION_STATUS_SUCCESS:
            return {
                ...state,
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                exist: payload.exist
            };
        case GET_NOTIFICATION_LIST_START:
            return {
                ...state,
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_NOTIFICATION_LIST_SUCCESS:
            return {
                ...state,
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                data: payload.notifications,
                exist: getNotificationsUnread(payload.notifications)
            };
        case GET_NOTIFICATION_LIST_ERROR:
            return {
                ...state,
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case DELETE_NOTIFICATIONS_LIST_START:
            return {
                ...state,
                data: [],
                exist: false
            };
        case DELETE_NOTIFICATIONS_LIST_ERROR:
            return {
                ...state,
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                },
                data: payload.prevNotifications,
                exist: getNotificationsUnread(payload.prevNotifications)
            };
        case DELETE_SINGLE_NOTIFICATION_START:
            notifications = state.data.filter(n => n.id !== payload.notificationId);
            return {
                ...state,
                data: notifications,
                exist: getNotificationsUnread(notifications)
            };
        case DELETE_SINGLE_NOTIFICATION_ERROR:
            return {
                ...state,
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                },
                data: payload.prevNotifications,
                exist: getNotificationsUnread(payload.prevNotifications)
            };
        case UPDATE_NCS_NOTIFICATIONS_LIST_START:
            notifications = state.data.map(notification => ({ ...notification, ...payload.request }));
            return {
                ...state,
                data: notifications,
                exist: getNotificationsUnread(notifications)
            };
        case UPDATE_NCS_NOTIFICATIONS_LIST_ERROR:
            return {
                ...state,
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                },
                data: payload.prevNotifications,
                exist: getNotificationsUnread(payload.prevNotifications)
            };
        case UPDATE_NCS_SINGLE_NOTIFICATION_START:
            notifications = state.data.map(notification => {
                return notification.id === payload.notificationId
                    ? {
                          ...notification,
                          ...payload.request
                      }
                    : notification;
            });
            return {
                ...state,
                data: notifications,
                exist: getNotificationsUnread(notifications)
            };
        case UPDATE_NCS_SINGLE_NOTIFICATION_ERROR:
            return {
                ...state,
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                },
                data: payload.prevNotifications,
                exist: getNotificationsUnread(payload.prevNotifications)
            };
        default:
            return state;
    }
}
