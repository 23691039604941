import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

const UserNotEnabled = ({ company, userStatus }) => {
    const intl = useIntl();

    const companyError =
        company.base.status.status === "VALIDATED" ? "" : intl.formatMessage({ id: "general.company.not-active" });

    let disabledError = "";
    if (userStatus !== "confirmed") {
        switch (userStatus) {
            case "disabled":
                disabledError = intl.formatMessage({ id: "gdprlog.text1" });
                break;
            case "unconfirmed":
                disabledError = intl.formatMessage({ id: "gdprlog.text1" });
                break;
            default:
                disabledError = intl.formatMessage({ id: "gdprlog.text3" });
        }
    }

    return companyError !== "" || disabledError !== "" ? (
        <div className="user-not-enable">
            <div>
                <i
                    className="fa fa-exclamation-triangle"
                    style={{
                        fontSize: "70px",
                        marginTop: "20%",
                        color: "#F44336"
                    }}
                />
                <br />
                <br />
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                    <FormattedMessage id="gdprlog.impossibile-use" />
                </span>
                <br />
                <span>{companyError + disabledError}</span>
            </div>
        </div>
    ) : null;
};

export default UserNotEnabled;
