import { API_URL } from "../../../config";
import { getRestCall, putRestCall, postRestCall } from "../../../utils/rest-api-call";
import { getUserProfile } from "../../../utils/state-utils/get-user-profile";
import { getAuthWithAppName } from "../../auth";
import { downloadBase64 } from "../../../utils/download";

export const FETCH_CCT_PROVISIONING_INFO_START = "FETCH_CCT_PROVISIONING_INFO_START";
export const FETCH_CCT_PROVISIONING_INFO_SUCCESS = "FETCH_CCT_PROVISIONING_INFO_SUCCESS";
export const FETCH_CCT_PROVISIONING_INFO_ERROR = "FETCH_CCT_PROVISIONING_INFO_ERROR";
export const FETCH_CCT_PROVISIONING_INFO_RESET = "FETCH_CCT_PROVISIONING_INFO_RESET";

export const fetchCctProvisioningInfo = itemId => {
    return async (dispatch, getState) => {
        dispatch({ type: FETCH_CCT_PROVISIONING_INFO_START, payload: { itemId } });
        const extractedAuth = getAuthWithAppName(getState().auth);
        try {
            const info = await getRestCall(
                `${API_URL}/services/cct/${itemId}/provisioning`,
                extractedAuth,
                {},
                dispatch,
                extractedAuth.refreshToken,
                getUserProfile(getState()),
                itemId
            );

            dispatch({ type: FETCH_CCT_PROVISIONING_INFO_SUCCESS, payload: { itemId, info } });
        } catch (e) {
            if (e.status === 404) {
                dispatch({ type: FETCH_CCT_PROVISIONING_INFO_SUCCESS, payload: { itemId, info: {} } });
                return;
            }
            dispatch({ type: FETCH_CCT_PROVISIONING_INFO_ERROR, payload: { itemId }, error: e });
        }
    };
};

export const FETCH_CCT_PROVISIONING_INFO_MANAGED_START = "FETCH_CCT_PROVISIONING_INFO_MANAGED_START";
export const FETCH_CCT_PROVISIONING_INFO_MANAGED_SUCCESS = "FETCH_CCT_PROVISIONING_INFO_MANAGED_SUCCESS";
export const FETCH_CCT_PROVISIONING_INFO_MANAGED_ERROR = "FETCH_CCT_PROVISIONING_INFO_MANAGED_ERROR";
export const FETCH_CCT_PROVISIONING_INFO_MANAGED_RESET = "FETCH_CCT_PROVISIONING_INFO_MANAGED_RESET";

export const fetchCctProvisioningInfoManaged = (itemId, appId, page = 0, size = 10, filters) => {
    return async (dispatch, getState) => {
        const auth = getAuthWithAppName(getState().auth);
        dispatch({ type: FETCH_CCT_PROVISIONING_INFO_MANAGED_START, payload: { auth, itemId, appId } });

        try {
            const managedItemsInfo = await getRestCall(
                `${API_URL}/services/cct/${itemId}/provisioning/managed`,
                auth,
                { appId, page, size, ...filters },
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                itemId
            );

            if (filters && filters.managedIds) {
                const emptyValues = filters.managedIds.reduce((list, id) => {
                    list[id] = {};
                    return list;
                }, {});

                managedItemsInfo.data = {
                    ...emptyValues,
                    ...managedItemsInfo.data
                };
            }

            dispatch({ type: FETCH_CCT_PROVISIONING_INFO_MANAGED_SUCCESS, payload: managedItemsInfo });
        } catch (e) {
            dispatch({ type: FETCH_CCT_PROVISIONING_INFO_MANAGED_ERROR, payload: { itemId }, error: e });
        }
    };
};

export const GENERATE_CCT_PROVISIONING_CONTRACT_START = "GENERATE_CCT_PROVISIONING_CONTRACT_START";
export const GENERATE_CCT_PROVISIONING_CONTRACT_SUCCESS = "GENERATE_CCT_PROVISIONING_CONTRACT_SUCCESS";
export const GENERATE_CCT_PROVISIONING_CONTRACT_ERROR = "GENERATE_CCT_PROVISIONING_CONTRACT_ERROR";
export const GENERATE_CCT_PROVISIONING_CONTRACT_RESET = "GENERATE_CCT_PROVISIONING_CONTRACT_RESET";

export const generateCctProvisioningContract = (itemId, pdfRequest) => {
    return async (dispatch, getState) => {
        dispatch({ type: GENERATE_CCT_PROVISIONING_CONTRACT_START, payload: { itemId, pdfRequest } });

        const extractedAuth = getAuthWithAppName(getState().auth);
        try {
            const info = await putRestCall(
                `${API_URL}/services/cct/${itemId}/provisioning/contract`,
                extractedAuth,
                {},
                pdfRequest,
                dispatch,
                extractedAuth.refreshToken,
                getUserProfile(getState()),
                itemId
            );
            downloadBase64(info, "cctContract.pdf");
            dispatch({ type: GENERATE_CCT_PROVISIONING_CONTRACT_SUCCESS, payload: { itemId, info } });
        } catch (e) {
            dispatch({ type: GENERATE_CCT_PROVISIONING_CONTRACT_ERROR, payload: { itemId, pdfRequest }, error: e });
        }
    };
};

export const INITIALIZE_MANAGED_PROVISIONING_START = "INITIALIZE_MANAGED_PROVISIONING_START";
export const INITIALIZE_MANAGED_PROVISIONING_SUCCESS = "INITIALIZE_MANAGED_PROVISIONING_SUCCESS";
export const INITIALIZE_MANAGED_PROVISIONING_ERROR = "INITIALIZE_MANAGED_PROVISIONING_ERROR";
export const INITIALIZE_MANAGED_PROVISIONING_RESET = "INITIALIZE_MANAGED_PROVISIONING_RESET";

export const initializeManagedProvisioning = (itemId, managedId, pdfRequest) => {
    return async (dispatch, getState) => {
        dispatch({ type: INITIALIZE_MANAGED_PROVISIONING_START, payload: { itemId, managedId, pdfRequest } });

        const extractedAuth = getAuthWithAppName(getState().auth);
        try {
            const pdf = await postRestCall(
                `${API_URL}/services/cct/${itemId}/provisioning/managed`,
                extractedAuth,
                { managedItem: managedId },
                pdfRequest,
                dispatch,
                extractedAuth.refreshToken,
                getUserProfile(getState()),
                itemId,
                true
            );
            downloadBase64(pdf, `cctContract_${managedId}.pdf`);
            dispatch({ type: INITIALIZE_MANAGED_PROVISIONING_SUCCESS, payload: { itemId, managedId, pdfRequest } });
        } catch (e) {
            dispatch({
                type: INITIALIZE_MANAGED_PROVISIONING_ERROR,
                payload: { itemId, managedId, pdfRequest },
                error: e
            });
        }
    };
};

export function resetGenerateCctManagedProvisioningContract() {
    return {
        type: INITIALIZE_MANAGED_PROVISIONING_RESET
    };
}

export function resetGenerateCctProvisioningContract() {
    return {
        type: GENERATE_CCT_PROVISIONING_CONTRACT_RESET
    };
}

export const UPLOAD_CCT_PROVISIONING_CONTRACT_START = "UPLOAD_CCT_PROVISIONING_CONTRACT_START";
export const UPLOAD_CCT_PROVISIONING_CONTRACT_SUCCESS = "UPLOAD_CCT_PROVISIONING_CONTRACT_SUCCESS";
export const UPLOAD_CCT_PROVISIONING_CONTRACT_ERROR = "UPLOAD_CCT_PROVISIONING_CONTRACT_ERROR";
export const UPLOAD_CCT_PROVISIONING_CONTRACT_RESET = "UPLOAD_CCT_PROVISIONING_CONTRACT_RESET";

export const uploadCctProvisioningContract = (itemId, pdfRequest, managedItem) => {
    return async (dispatch, getState) => {
        dispatch({ type: UPLOAD_CCT_PROVISIONING_CONTRACT_START, payload: { itemId } });

        const query = managedItem ? { managedItem } : {};
        const extractedAuth = getAuthWithAppName(getState().auth);
        try {
            const info = await postRestCall(
                `${API_URL}/services/cct/${itemId}/provisioning/contract`,
                extractedAuth,
                query,
                { content: pdfRequest },
                dispatch,
                extractedAuth.refreshToken,
                getUserProfile(getState()),
                itemId
            );
            dispatch({
                type: UPLOAD_CCT_PROVISIONING_CONTRACT_SUCCESS,
                payload: { itemId: managedItem ? managedItem : itemId, info }
            });
        } catch (e) {
            dispatch({
                type: UPLOAD_CCT_PROVISIONING_CONTRACT_ERROR,
                payload: { itemId: managedItem ? managedItem : itemId },
                error: e
            });
        }
    };
};

export function resetUploadCctProvisioningContract() {
    return {
        type: UPLOAD_CCT_PROVISIONING_CONTRACT_RESET
    };
}

export const INITIALIZE_CCT_SMART_PROVISIONING_START = "INITIALIZE_CCT_SMART_PROVISIONING_START";
export const INITIALIZE_CCT_SMART_PROVISIONING_SUCCESS = "INITIALIZE_CCT_SMART_PROVISIONING_SUCCESS";
export const INITIALIZE_CCT_SMART_PROVISIONING_ERROR = "INITIALIZE_CCT_SMART_PROVISIONING_ERROR";
export const INITIALIZE_CCT_SMART_PROVISIONING_RESET = "INITIALIZE_CCT_SMART_PROVISIONING_RESET";

export const initializeSmartProvisioning = (itemId, rdcData, balanceSheetDate) => {
    return async (dispatch, getState) => {
        dispatch({ type: INITIALIZE_CCT_SMART_PROVISIONING_START, payload: { itemId, rdcData, balanceSheetDate } });

        const extractedAuth = getAuthWithAppName(getState().auth);
        try {
            const res = await postRestCall(
                `${API_URL}/services/cct/${itemId}/provisioning/smart`,
                extractedAuth,
                {},
                { balanceSheetDate, rdcData },
                dispatch,
                extractedAuth.refreshToken,
                getUserProfile(getState()),
                itemId
            );

            dispatch({ type: INITIALIZE_CCT_SMART_PROVISIONING_SUCCESS, payload: { itemId, res } });
        } catch (e) {
            dispatch({ type: INITIALIZE_CCT_SMART_PROVISIONING_ERROR, payload: { itemId, rdcData }, error: e });
        }
    };
};

export function resetInitializeSmartProvisioning() {
    return {
        type: INITIALIZE_CCT_SMART_PROVISIONING_RESET
    };
}

export const EXTEND_CCT_SMART_PROVISIONING_START = "EXTEND_CCT_SMART_PROVISIONING_START";
export const EXTEND_CCT_SMART_PROVISIONING_SUCCESS = "EXTEND_CCT_SMART_PROVISIONING_SUCCESS";
export const EXTEND_CCT_SMART_PROVISIONING_ERROR = "EXTEND_CCT_SMART_PROVISIONING_ERROR";
export const EXTEND_CCT_SMART_PROVISIONING_RESET = "EXTEND_CCT_SMART_PROVISIONING_RESET";

export const extendSmartProvisioning = (itemId, managedIds, balanceSheetDate) => {
    return async (dispatch, getState) => {
        dispatch({ type: EXTEND_CCT_SMART_PROVISIONING_START, payload: { managedIds } });

        const extractedAuth = getAuthWithAppName(getState().auth);
        try {
            const res = await postRestCall(
                `${API_URL}/services/cct/${itemId}/provisioning/smart/extend`,
                extractedAuth,
                {},
                { managedIds, balanceSheetDate },
                dispatch,
                extractedAuth.refreshToken,
                getUserProfile(getState()),
                itemId
            );

            dispatch({ type: EXTEND_CCT_SMART_PROVISIONING_SUCCESS, payload: { managedIds, res } });
        } catch (e) {
            dispatch({ type: EXTEND_CCT_SMART_PROVISIONING_ERROR, payload: { managedIds }, error: e });
        }
    };
};

export function resetExtendSmartProvisioning() {
    return {
        type: EXTEND_CCT_SMART_PROVISIONING_RESET
    };
}
