import React from "react";
import PropTypes from "prop-types";

import { Field } from "redux-form";
import { field } from "@ts-digital/conv-redux-form";

import { Checkbox } from "@ts-digital/vrc";
import { CheckboxContainer } from "./styled";

export const WrappedCheckbox = ({ input, label, ...props }) => (
    <CheckboxContainer>
        <Checkbox label={label} checked={input.value} {...input} {...props} />
    </CheckboxContainer>
);

const CheckboxField = props => {
    return <Field {...props} component={WrappedCheckbox} />;
};

Checkbox.propTypes = { name: PropTypes.string.isRequired };

export default field({ type: "boolean" })(CheckboxField);
