import React, { useState } from "react";
import PropTypes from "prop-types";

import { includes } from "ramda";
import { Modal } from "antd";
import { injectIntl } from "react-intl";

import ServiceCard from "../../components/general/service-card";
import { isAppAvailable } from "../../utils/app-availability";
import ExpenseReportsForm from "./form";
import { ServiceCardsContainer } from "../general/service-card/styled";

const ExpenseReports = ({
    activateAppNoteSpese,
    company,
    intl,
    services: { active, agyoServices, categories },
    user,
    getWaitingList,
    getWaitingListStatus,
    subscribeWaitinglist,
    waitinglist,
    waitinglistSubscribeStatus,
    waitinglistDeleteStatus,
    deleteWaitinglist
}) => {
    const [expenseReportsVisible, setExpenseReportsVisible] = useState(false);

    let isActiveInAgyo = agyoServices.services.some(service => service.id === "NOTESPESE-FLOW" && service.active);

    const roles = user.user && user.user.roles ? user.user.roles : [];
    let companyData = company.base;

    const servicesConfig = [
        {
            active: includes("NOTESPESE-FLOW", active.services),
            disable: !isActiveInAgyo,
            display: isAppAvailable(
                company.base.id,
                roles,
                "NOTESPESE-FLOW",
                active.availableToUser,
                categories.data["EXP"],
                active.services
            ),
            title: "c-services-view-expense-reports",
            description: "c-expense-report.description",
            showActivateSubscriptionButton: true,
            onActivateSubscription: () => setExpenseReportsVisible(true),
            serviceInfo: agyoServices.services.filter(ele => ele.id === "NOTESPESE-FLOW")[0]
        }
    ];

    return (
        <div>
            {active.services && (
                <ServiceCardsContainer expanded={false}>
                    {servicesConfig.map((service, key) => (
                        <ServiceCard
                            key={key}
                            active={service.active}
                            disable={service.disable}
                            expanded={service.expanded}
                            expandedComponent={service.expandedComponent}
                            title={service.title}
                            description={service.description}
                            display={service.display}
                            button={service.button}
                            tooltip={service.tooltip}
                            showActiveButton={service.showActiveButton}
                            onActive={service.onActive}
                            onConfigure={service.onConfigure}
                            onActivateSubscription={service.onActivateSubscription}
                            showBuyButton={service.showBuyButton}
                            showContactToBuy={service.showContactToBuy}
                            showConfigureButton={service.showConfigureButton}
                            showActivateSubscriptionButton={service.showActivateSubscriptionButton}
                            serviceInfo={service.serviceInfo}
                            companyId={company.base.id}
                            getWaitingList={getWaitingList}
                            getWaitingListStatus={getWaitingListStatus}
                            waitinglist={waitinglist}
                            subscribeWaitinglist={subscribeWaitinglist}
                            waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                            waitinglistDeleteStatus={waitinglistDeleteStatus}
                            deleteWaitinglist={deleteWaitinglist}
                            user={user}
                        />
                    ))}
                </ServiceCardsContainer>
            )}
            {user.profile && (
                <Modal
                    footer={null}
                    onCancel={() => setExpenseReportsVisible(false)}
                    visible={expenseReportsVisible}
                    style={{ top: "20%" }}
                    width="600px"
                    title={intl.formatMessage({
                        id: "c-expense-report.activation"
                    })}
                >
                    <ExpenseReportsForm
                        activateAppNoteSpese={activateAppNoteSpese}
                        config={{
                            description: companyData.details.description,
                            taxId: companyData.identifier.taxId,
                            zipCode: companyData.details.addresses[0].zipCode,
                            city: companyData.details.addresses[0].city,
                            province: companyData.details.addresses[0].province,
                            country: companyData.details.addresses[0].country,
                            street: companyData.details.addresses[0].streetName,
                            firstName: user.profile.firstName,
                            lastName: user.profile.lastName,
                            id: user.profile.id
                        }}
                        handleClose={() => setExpenseReportsVisible(false)}
                    />
                </Modal>
            )}
        </div>
    );
};

ExpenseReports.propTypes = {
    activateAppNoteSpese: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
    services: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    getWaitingList: PropTypes.func,
    waitinglist: PropTypes.array,
    subscribeWaitinglist: PropTypes.func,
    waitinglistSubscribeStatus: PropTypes.object
};

export default injectIntl(ExpenseReports);
