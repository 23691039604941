import styled from "styled-components";

export const PaginationContainer = styled.div`
    & a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;

        color: inherit;
        cursor: inherit;
        transition: inherit;

        &:hover {
            color: inherit;
        }
    }

    & ul {
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0;
    }

    & li {
        color: ${props => props.theme.colors.primary.richElectricBlue};
        cursor: pointer;
        display: flex;
        width: 32px;
        height: 32px;

        align-items: center;
        justify-content: center;
        transition: color 0.1s ease;

        &:hover {
            color: ${props => props.theme.colors.primary.cyanProgress};
        }
    }

    & li.disabled {
        color: ${props => props.theme.colors.text.quickSilver};
        cursor: default;
        transition: none;
    }

    & li.selected a {
        background-color: ${props => props.theme.colors.secondary.azure};
        border-radius: 2px;

        width: 16px;
        height: 16px;
    }
`;
