/* eslint-disable */
//
// Autogenerated by Thrift Compiler (0.11.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
"use strict";

var thrift = require('browser-thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = require('./user-write_types');
//HELPER FUNCTIONS AND STRUCTURES

var UserWriteApi_createUser_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.CreateUserRequest(args.request);
    }
  }
};
UserWriteApi_createUser_args.prototype = {};
UserWriteApi_createUser_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.CreateUserRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_createUser_args.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_createUser_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_createUser_result = function(args) {
  this.success = null;
  this.userException = null;
  this.authenticationException = null;
  this.genericError = null;
  if (args instanceof ttypes.UserException) {
    this.userException = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.ResourceResponse(args.success);
    }
    if (args.userException !== undefined && args.userException !== null) {
      this.userException = args.userException;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
  }
};
UserWriteApi_createUser_result.prototype = {};
UserWriteApi_createUser_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.ResourceResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.userException = new ttypes.UserException();
        this.userException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_createUser_result.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_createUser_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.userException !== null && this.userException !== undefined) {
    output.writeFieldBegin('userException', Thrift.Type.STRUCT, 1);
    this.userException.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 3);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_updateUser_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.UpdateUserRequest(args.request);
    }
  }
};
UserWriteApi_updateUser_args.prototype = {};
UserWriteApi_updateUser_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.UpdateUserRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_updateUser_args.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_updateUser_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_updateUser_result = function(args) {
  this.success = null;
  this.userException = null;
  this.authenticationException = null;
  this.genericError = null;
  if (args instanceof ttypes.UserException) {
    this.userException = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.ResourceResponse(args.success);
    }
    if (args.userException !== undefined && args.userException !== null) {
      this.userException = args.userException;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
  }
};
UserWriteApi_updateUser_result.prototype = {};
UserWriteApi_updateUser_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.ResourceResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.userException = new ttypes.UserException();
        this.userException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_updateUser_result.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_updateUser_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.userException !== null && this.userException !== undefined) {
    output.writeFieldBegin('userException', Thrift.Type.STRUCT, 1);
    this.userException.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 3);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_deleteUser_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.DeleteUserRequest(args.request);
    }
  }
};
UserWriteApi_deleteUser_args.prototype = {};
UserWriteApi_deleteUser_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.DeleteUserRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_deleteUser_args.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_deleteUser_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_deleteUser_result = function(args) {
  this.success = null;
  this.userException = null;
  this.authenticationException = null;
  this.genericError = null;
  if (args instanceof ttypes.UserException) {
    this.userException = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.ResourceResponse(args.success);
    }
    if (args.userException !== undefined && args.userException !== null) {
      this.userException = args.userException;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
  }
};
UserWriteApi_deleteUser_result.prototype = {};
UserWriteApi_deleteUser_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.ResourceResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.userException = new ttypes.UserException();
        this.userException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_deleteUser_result.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_deleteUser_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.userException !== null && this.userException !== undefined) {
    output.writeFieldBegin('userException', Thrift.Type.STRUCT, 1);
    this.userException.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 3);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_createApiKey_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.CreateApiKeyRequest(args.request);
    }
  }
};
UserWriteApi_createApiKey_args.prototype = {};
UserWriteApi_createApiKey_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.CreateApiKeyRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_createApiKey_args.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_createApiKey_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_createApiKey_result = function(args) {
  this.success = null;
  this.userException = null;
  this.authenticationException = null;
  this.genericError = null;
  if (args instanceof ttypes.UserException) {
    this.userException = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.CreateApiKeyResponse(args.success);
    }
    if (args.userException !== undefined && args.userException !== null) {
      this.userException = args.userException;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
  }
};
UserWriteApi_createApiKey_result.prototype = {};
UserWriteApi_createApiKey_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.CreateApiKeyResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.userException = new ttypes.UserException();
        this.userException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_createApiKey_result.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_createApiKey_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.userException !== null && this.userException !== undefined) {
    output.writeFieldBegin('userException', Thrift.Type.STRUCT, 1);
    this.userException.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 3);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_deleteApiKey_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.DeleteApiKeyRequest(args.request);
    }
  }
};
UserWriteApi_deleteApiKey_args.prototype = {};
UserWriteApi_deleteApiKey_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.DeleteApiKeyRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_deleteApiKey_args.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_deleteApiKey_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_deleteApiKey_result = function(args) {
  this.success = null;
  this.userException = null;
  this.authenticationException = null;
  this.genericError = null;
  if (args instanceof ttypes.UserException) {
    this.userException = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.ResourceResponse(args.success);
    }
    if (args.userException !== undefined && args.userException !== null) {
      this.userException = args.userException;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
  }
};
UserWriteApi_deleteApiKey_result.prototype = {};
UserWriteApi_deleteApiKey_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.ResourceResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.userException = new ttypes.UserException();
        this.userException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_deleteApiKey_result.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_deleteApiKey_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.userException !== null && this.userException !== undefined) {
    output.writeFieldBegin('userException', Thrift.Type.STRUCT, 1);
    this.userException.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 3);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_updateUserPreferences_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.UpdateUserPreferencesRequest(args.request);
    }
  }
};
UserWriteApi_updateUserPreferences_args.prototype = {};
UserWriteApi_updateUserPreferences_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.UpdateUserPreferencesRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_updateUserPreferences_args.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_updateUserPreferences_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_updateUserPreferences_result = function(args) {
  this.success = null;
  this.userException = null;
  this.authenticationException = null;
  this.genericError = null;
  if (args instanceof ttypes.UserException) {
    this.userException = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.ResourceResponse(args.success);
    }
    if (args.userException !== undefined && args.userException !== null) {
      this.userException = args.userException;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
  }
};
UserWriteApi_updateUserPreferences_result.prototype = {};
UserWriteApi_updateUserPreferences_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.ResourceResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.userException = new ttypes.UserException();
        this.userException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_updateUserPreferences_result.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_updateUserPreferences_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.userException !== null && this.userException !== undefined) {
    output.writeFieldBegin('userException', Thrift.Type.STRUCT, 1);
    this.userException.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 3);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_enableUser_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.EnableRequest(args.request);
    }
  }
};
UserWriteApi_enableUser_args.prototype = {};
UserWriteApi_enableUser_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.EnableRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_enableUser_args.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_enableUser_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_enableUser_result = function(args) {
  this.userException = null;
  this.authenticationException = null;
  this.genericError = null;
  if (args instanceof ttypes.UserException) {
    this.userException = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args) {
    if (args.userException !== undefined && args.userException !== null) {
      this.userException = args.userException;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
  }
};
UserWriteApi_enableUser_result.prototype = {};
UserWriteApi_enableUser_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.userException = new ttypes.UserException();
        this.userException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_enableUser_result.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_enableUser_result');
  if (this.userException !== null && this.userException !== undefined) {
    output.writeFieldBegin('userException', Thrift.Type.STRUCT, 1);
    this.userException.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 3);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_disableUser_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.DisableRequest(args.request);
    }
  }
};
UserWriteApi_disableUser_args.prototype = {};
UserWriteApi_disableUser_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.DisableRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_disableUser_args.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_disableUser_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_disableUser_result = function(args) {
  this.userException = null;
  this.authenticationException = null;
  this.genericError = null;
  if (args instanceof ttypes.UserException) {
    this.userException = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args) {
    if (args.userException !== undefined && args.userException !== null) {
      this.userException = args.userException;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
  }
};
UserWriteApi_disableUser_result.prototype = {};
UserWriteApi_disableUser_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.userException = new ttypes.UserException();
        this.userException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_disableUser_result.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_disableUser_result');
  if (this.userException !== null && this.userException !== undefined) {
    output.writeFieldBegin('userException', Thrift.Type.STRUCT, 1);
    this.userException.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 3);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_activateUser_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.ActivateRequest(args.request);
    }
  }
};
UserWriteApi_activateUser_args.prototype = {};
UserWriteApi_activateUser_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.ActivateRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_activateUser_args.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_activateUser_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_activateUser_result = function(args) {
  this.userException = null;
  this.genericError = null;
  if (args instanceof ttypes.UserException) {
    this.userException = args;
    return;
  }
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args) {
    if (args.userException !== undefined && args.userException !== null) {
      this.userException = args.userException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
  }
};
UserWriteApi_activateUser_result.prototype = {};
UserWriteApi_activateUser_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.userException = new ttypes.UserException();
        this.userException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_activateUser_result.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_activateUser_result');
  if (this.userException !== null && this.userException !== undefined) {
    output.writeFieldBegin('userException', Thrift.Type.STRUCT, 1);
    this.userException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 2);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_updatePassword_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.UpdatePasswordRequest(args.request);
    }
  }
};
UserWriteApi_updatePassword_args.prototype = {};
UserWriteApi_updatePassword_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.UpdatePasswordRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_updatePassword_args.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_updatePassword_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_updatePassword_result = function(args) {
  this.userException = null;
  this.authenticationException = null;
  this.genericError = null;
  if (args instanceof ttypes.UserException) {
    this.userException = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args) {
    if (args.userException !== undefined && args.userException !== null) {
      this.userException = args.userException;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
  }
};
UserWriteApi_updatePassword_result.prototype = {};
UserWriteApi_updatePassword_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.userException = new ttypes.UserException();
        this.userException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_updatePassword_result.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_updatePassword_result');
  if (this.userException !== null && this.userException !== undefined) {
    output.writeFieldBegin('userException', Thrift.Type.STRUCT, 1);
    this.userException.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 3);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_resetPassword_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.ResetPasswordRequest(args.request);
    }
  }
};
UserWriteApi_resetPassword_args.prototype = {};
UserWriteApi_resetPassword_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.ResetPasswordRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_resetPassword_args.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_resetPassword_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_resetPassword_result = function(args) {
  this.userException = null;
  this.genericError = null;
  if (args instanceof ttypes.UserException) {
    this.userException = args;
    return;
  }
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args) {
    if (args.userException !== undefined && args.userException !== null) {
      this.userException = args.userException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
  }
};
UserWriteApi_resetPassword_result.prototype = {};
UserWriteApi_resetPassword_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.userException = new ttypes.UserException();
        this.userException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_resetPassword_result.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_resetPassword_result');
  if (this.userException !== null && this.userException !== undefined) {
    output.writeFieldBegin('userException', Thrift.Type.STRUCT, 1);
    this.userException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 2);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_resendWelcomeEmail_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.ResendWelcomeEmailRequest(args.request);
    }
  }
};
UserWriteApi_resendWelcomeEmail_args.prototype = {};
UserWriteApi_resendWelcomeEmail_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.ResendWelcomeEmailRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_resendWelcomeEmail_args.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_resendWelcomeEmail_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_resendWelcomeEmail_result = function(args) {
  this.userException = null;
  this.authenticationException = null;
  this.genericError = null;
  if (args instanceof ttypes.UserException) {
    this.userException = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args) {
    if (args.userException !== undefined && args.userException !== null) {
      this.userException = args.userException;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
  }
};
UserWriteApi_resendWelcomeEmail_result.prototype = {};
UserWriteApi_resendWelcomeEmail_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.userException = new ttypes.UserException();
        this.userException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_resendWelcomeEmail_result.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_resendWelcomeEmail_result');
  if (this.userException !== null && this.userException !== undefined) {
    output.writeFieldBegin('userException', Thrift.Type.STRUCT, 1);
    this.userException.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 3);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_sendForgotPasswordEmail_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.SendForgotPasswordRequest(args.request);
    }
  }
};
UserWriteApi_sendForgotPasswordEmail_args.prototype = {};
UserWriteApi_sendForgotPasswordEmail_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.SendForgotPasswordRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_sendForgotPasswordEmail_args.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_sendForgotPasswordEmail_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_sendForgotPasswordEmail_result = function(args) {
  this.userException = null;
  this.authenticationException = null;
  this.genericError = null;
  if (args instanceof ttypes.UserException) {
    this.userException = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args) {
    if (args.userException !== undefined && args.userException !== null) {
      this.userException = args.userException;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
  }
};
UserWriteApi_sendForgotPasswordEmail_result.prototype = {};
UserWriteApi_sendForgotPasswordEmail_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.userException = new ttypes.UserException();
        this.userException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_sendForgotPasswordEmail_result.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_sendForgotPasswordEmail_result');
  if (this.userException !== null && this.userException !== undefined) {
    output.writeFieldBegin('userException', Thrift.Type.STRUCT, 1);
    this.userException.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 3);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_addRole_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.RoleRequest(args.request);
    }
  }
};
UserWriteApi_addRole_args.prototype = {};
UserWriteApi_addRole_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.RoleRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_addRole_args.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_addRole_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_addRole_result = function(args) {
  this.userException = null;
  this.authenticationException = null;
  this.genericError = null;
  if (args instanceof ttypes.UserException) {
    this.userException = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args) {
    if (args.userException !== undefined && args.userException !== null) {
      this.userException = args.userException;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
  }
};
UserWriteApi_addRole_result.prototype = {};
UserWriteApi_addRole_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.userException = new ttypes.UserException();
        this.userException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_addRole_result.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_addRole_result');
  if (this.userException !== null && this.userException !== undefined) {
    output.writeFieldBegin('userException', Thrift.Type.STRUCT, 1);
    this.userException.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 3);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_addRoles_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.RolesRequest(args.request);
    }
  }
};
UserWriteApi_addRoles_args.prototype = {};
UserWriteApi_addRoles_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.RolesRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_addRoles_args.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_addRoles_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_addRoles_result = function(args) {
  this.userException = null;
  this.authenticationException = null;
  this.genericError = null;
  if (args instanceof ttypes.UserException) {
    this.userException = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args) {
    if (args.userException !== undefined && args.userException !== null) {
      this.userException = args.userException;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
  }
};
UserWriteApi_addRoles_result.prototype = {};
UserWriteApi_addRoles_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.userException = new ttypes.UserException();
        this.userException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_addRoles_result.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_addRoles_result');
  if (this.userException !== null && this.userException !== undefined) {
    output.writeFieldBegin('userException', Thrift.Type.STRUCT, 1);
    this.userException.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 3);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_removeRole_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.RoleRequest(args.request);
    }
  }
};
UserWriteApi_removeRole_args.prototype = {};
UserWriteApi_removeRole_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.RoleRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_removeRole_args.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_removeRole_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_removeRole_result = function(args) {
  this.userException = null;
  this.authenticationException = null;
  this.genericError = null;
  if (args instanceof ttypes.UserException) {
    this.userException = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args) {
    if (args.userException !== undefined && args.userException !== null) {
      this.userException = args.userException;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
  }
};
UserWriteApi_removeRole_result.prototype = {};
UserWriteApi_removeRole_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.userException = new ttypes.UserException();
        this.userException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_removeRole_result.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_removeRole_result');
  if (this.userException !== null && this.userException !== undefined) {
    output.writeFieldBegin('userException', Thrift.Type.STRUCT, 1);
    this.userException.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 3);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_removeRoles_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.RolesRequest(args.request);
    }
  }
};
UserWriteApi_removeRoles_args.prototype = {};
UserWriteApi_removeRoles_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.RolesRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_removeRoles_args.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_removeRoles_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_removeRoles_result = function(args) {
  this.userException = null;
  this.authenticationException = null;
  this.genericError = null;
  if (args instanceof ttypes.UserException) {
    this.userException = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args) {
    if (args.userException !== undefined && args.userException !== null) {
      this.userException = args.userException;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
  }
};
UserWriteApi_removeRoles_result.prototype = {};
UserWriteApi_removeRoles_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.userException = new ttypes.UserException();
        this.userException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_removeRoles_result.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_removeRoles_result');
  if (this.userException !== null && this.userException !== undefined) {
    output.writeFieldBegin('userException', Thrift.Type.STRUCT, 1);
    this.userException.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 3);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_health_args = function(args) {
};
UserWriteApi_health_args.prototype = {};
UserWriteApi_health_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_health_args.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_health_args');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApi_health_result = function(args) {
  this.success = null;
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
  }
};
UserWriteApi_health_result.prototype = {};
UserWriteApi_health_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRING) {
        this.success = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserWriteApi_health_result.prototype.write = function(output) {
  output.writeStructBegin('UserWriteApi_health_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRING, 0);
    output.writeString(this.success);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserWriteApiClient = exports.Client = function(output, pClass) {
    this.output = output;
    this.pClass = pClass;
    this._seqid = 0;
    this._reqs = {};
};
UserWriteApiClient.prototype = {};
UserWriteApiClient.prototype.seqid = function() { return this._seqid; };
UserWriteApiClient.prototype.new_seqid = function() { return this._seqid += 1; };
UserWriteApiClient.prototype.createUser = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_createUser(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_createUser(auth, request);
  }
};

UserWriteApiClient.prototype.send_createUser = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('createUser', Thrift.MessageType.CALL, this.seqid());
  var params = {
    auth: auth,
    request: request
  };
  var args = new UserWriteApi_createUser_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

UserWriteApiClient.prototype.recv_createUser = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new UserWriteApi_createUser_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.userException) {
    return callback(result.userException);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('createUser failed: unknown result');
};
UserWriteApiClient.prototype.updateUser = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_updateUser(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_updateUser(auth, request);
  }
};

UserWriteApiClient.prototype.send_updateUser = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('updateUser', Thrift.MessageType.CALL, this.seqid());
  var params = {
    auth: auth,
    request: request
  };
  var args = new UserWriteApi_updateUser_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

UserWriteApiClient.prototype.recv_updateUser = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new UserWriteApi_updateUser_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.userException) {
    return callback(result.userException);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('updateUser failed: unknown result');
};
UserWriteApiClient.prototype.deleteUser = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_deleteUser(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_deleteUser(auth, request);
  }
};

UserWriteApiClient.prototype.send_deleteUser = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('deleteUser', Thrift.MessageType.CALL, this.seqid());
  var params = {
    auth: auth,
    request: request
  };
  var args = new UserWriteApi_deleteUser_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

UserWriteApiClient.prototype.recv_deleteUser = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new UserWriteApi_deleteUser_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.userException) {
    return callback(result.userException);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('deleteUser failed: unknown result');
};
UserWriteApiClient.prototype.createApiKey = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_createApiKey(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_createApiKey(auth, request);
  }
};

UserWriteApiClient.prototype.send_createApiKey = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('createApiKey', Thrift.MessageType.CALL, this.seqid());
  var params = {
    auth: auth,
    request: request
  };
  var args = new UserWriteApi_createApiKey_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

UserWriteApiClient.prototype.recv_createApiKey = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new UserWriteApi_createApiKey_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.userException) {
    return callback(result.userException);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('createApiKey failed: unknown result');
};
UserWriteApiClient.prototype.deleteApiKey = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_deleteApiKey(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_deleteApiKey(auth, request);
  }
};

UserWriteApiClient.prototype.send_deleteApiKey = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('deleteApiKey', Thrift.MessageType.CALL, this.seqid());
  var params = {
    auth: auth,
    request: request
  };
  var args = new UserWriteApi_deleteApiKey_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

UserWriteApiClient.prototype.recv_deleteApiKey = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new UserWriteApi_deleteApiKey_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.userException) {
    return callback(result.userException);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('deleteApiKey failed: unknown result');
};
UserWriteApiClient.prototype.updateUserPreferences = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_updateUserPreferences(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_updateUserPreferences(auth, request);
  }
};

UserWriteApiClient.prototype.send_updateUserPreferences = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('updateUserPreferences', Thrift.MessageType.CALL, this.seqid());
  var params = {
    auth: auth,
    request: request
  };
  var args = new UserWriteApi_updateUserPreferences_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

UserWriteApiClient.prototype.recv_updateUserPreferences = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new UserWriteApi_updateUserPreferences_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.userException) {
    return callback(result.userException);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('updateUserPreferences failed: unknown result');
};
UserWriteApiClient.prototype.enableUser = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_enableUser(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_enableUser(auth, request);
  }
};

UserWriteApiClient.prototype.send_enableUser = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('enableUser', Thrift.MessageType.CALL, this.seqid());
  var params = {
    auth: auth,
    request: request
  };
  var args = new UserWriteApi_enableUser_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

UserWriteApiClient.prototype.recv_enableUser = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new UserWriteApi_enableUser_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.userException) {
    return callback(result.userException);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  callback(null);
};
UserWriteApiClient.prototype.disableUser = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_disableUser(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_disableUser(auth, request);
  }
};

UserWriteApiClient.prototype.send_disableUser = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('disableUser', Thrift.MessageType.CALL, this.seqid());
  var params = {
    auth: auth,
    request: request
  };
  var args = new UserWriteApi_disableUser_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

UserWriteApiClient.prototype.recv_disableUser = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new UserWriteApi_disableUser_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.userException) {
    return callback(result.userException);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  callback(null);
};
UserWriteApiClient.prototype.activateUser = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_activateUser(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_activateUser(request);
  }
};

UserWriteApiClient.prototype.send_activateUser = function(request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('activateUser', Thrift.MessageType.CALL, this.seqid());
  var params = {
    request: request
  };
  var args = new UserWriteApi_activateUser_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

UserWriteApiClient.prototype.recv_activateUser = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new UserWriteApi_activateUser_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.userException) {
    return callback(result.userException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  callback(null);
};
UserWriteApiClient.prototype.updatePassword = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_updatePassword(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_updatePassword(auth, request);
  }
};

UserWriteApiClient.prototype.send_updatePassword = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('updatePassword', Thrift.MessageType.CALL, this.seqid());
  var params = {
    auth: auth,
    request: request
  };
  var args = new UserWriteApi_updatePassword_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

UserWriteApiClient.prototype.recv_updatePassword = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new UserWriteApi_updatePassword_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.userException) {
    return callback(result.userException);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  callback(null);
};
UserWriteApiClient.prototype.resetPassword = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_resetPassword(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_resetPassword(request);
  }
};

UserWriteApiClient.prototype.send_resetPassword = function(request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('resetPassword', Thrift.MessageType.CALL, this.seqid());
  var params = {
    request: request
  };
  var args = new UserWriteApi_resetPassword_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

UserWriteApiClient.prototype.recv_resetPassword = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new UserWriteApi_resetPassword_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.userException) {
    return callback(result.userException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  callback(null);
};
UserWriteApiClient.prototype.resendWelcomeEmail = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_resendWelcomeEmail(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_resendWelcomeEmail(auth, request);
  }
};

UserWriteApiClient.prototype.send_resendWelcomeEmail = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('resendWelcomeEmail', Thrift.MessageType.CALL, this.seqid());
  var params = {
    auth: auth,
    request: request
  };
  var args = new UserWriteApi_resendWelcomeEmail_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

UserWriteApiClient.prototype.recv_resendWelcomeEmail = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new UserWriteApi_resendWelcomeEmail_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.userException) {
    return callback(result.userException);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  callback(null);
};
UserWriteApiClient.prototype.sendForgotPasswordEmail = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_sendForgotPasswordEmail(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_sendForgotPasswordEmail(auth, request);
  }
};

UserWriteApiClient.prototype.send_sendForgotPasswordEmail = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('sendForgotPasswordEmail', Thrift.MessageType.CALL, this.seqid());
  var params = {
    auth: auth,
    request: request
  };
  var args = new UserWriteApi_sendForgotPasswordEmail_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

UserWriteApiClient.prototype.recv_sendForgotPasswordEmail = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new UserWriteApi_sendForgotPasswordEmail_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.userException) {
    return callback(result.userException);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  callback(null);
};
UserWriteApiClient.prototype.addRole = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_addRole(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_addRole(auth, request);
  }
};

UserWriteApiClient.prototype.send_addRole = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('addRole', Thrift.MessageType.CALL, this.seqid());
  var params = {
    auth: auth,
    request: request
  };
  var args = new UserWriteApi_addRole_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

UserWriteApiClient.prototype.recv_addRole = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new UserWriteApi_addRole_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.userException) {
    return callback(result.userException);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  callback(null);
};
UserWriteApiClient.prototype.addRoles = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_addRoles(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_addRoles(auth, request);
  }
};

UserWriteApiClient.prototype.send_addRoles = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('addRoles', Thrift.MessageType.CALL, this.seqid());
  var params = {
    auth: auth,
    request: request
  };
  var args = new UserWriteApi_addRoles_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

UserWriteApiClient.prototype.recv_addRoles = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new UserWriteApi_addRoles_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.userException) {
    return callback(result.userException);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  callback(null);
};
UserWriteApiClient.prototype.removeRole = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_removeRole(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_removeRole(auth, request);
  }
};

UserWriteApiClient.prototype.send_removeRole = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('removeRole', Thrift.MessageType.CALL, this.seqid());
  var params = {
    auth: auth,
    request: request
  };
  var args = new UserWriteApi_removeRole_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

UserWriteApiClient.prototype.recv_removeRole = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new UserWriteApi_removeRole_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.userException) {
    return callback(result.userException);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  callback(null);
};
UserWriteApiClient.prototype.removeRoles = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_removeRoles(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_removeRoles(auth, request);
  }
};

UserWriteApiClient.prototype.send_removeRoles = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('removeRoles', Thrift.MessageType.CALL, this.seqid());
  var params = {
    auth: auth,
    request: request
  };
  var args = new UserWriteApi_removeRoles_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

UserWriteApiClient.prototype.recv_removeRoles = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new UserWriteApi_removeRoles_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.userException) {
    return callback(result.userException);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  callback(null);
};
UserWriteApiClient.prototype.health = function(callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_health();
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_health();
  }
};

UserWriteApiClient.prototype.send_health = function() {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('health', Thrift.MessageType.CALL, this.seqid());
  var args = new UserWriteApi_health_args();
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

UserWriteApiClient.prototype.recv_health = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new UserWriteApi_health_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('health failed: unknown result');
};
var UserWriteApiProcessor = exports.Processor = function(handler) {
  this._handler = handler;
}
;
UserWriteApiProcessor.prototype.process = function(input, output) {
  var r = input.readMessageBegin();
  if (this['process_' + r.fname]) {
    return this['process_' + r.fname].call(this, r.rseqid, input, output);
  } else {
    input.skip(Thrift.Type.STRUCT);
    input.readMessageEnd();
    var x = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN_METHOD, 'Unknown function ' + r.fname);
    output.writeMessageBegin(r.fname, Thrift.MessageType.EXCEPTION, r.rseqid);
    x.write(output);
    output.writeMessageEnd();
    output.flush();
  }
}
;
UserWriteApiProcessor.prototype.process_createUser = function(seqid, input, output) {
  var args = new UserWriteApi_createUser_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.createUser.length === 2) {
    Q.fcall(this._handler.createUser.bind(this._handler), args.auth, args.request)
      .then(function(result) {
        var result_obj = new UserWriteApi_createUser_result({success: result});
        output.writeMessageBegin("createUser", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
          result = new UserWriteApi_createUser_result(err);
          output.writeMessageBegin("createUser", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("createUser", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.createUser(args.auth, args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
        result_obj = new UserWriteApi_createUser_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("createUser", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("createUser", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
UserWriteApiProcessor.prototype.process_updateUser = function(seqid, input, output) {
  var args = new UserWriteApi_updateUser_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.updateUser.length === 2) {
    Q.fcall(this._handler.updateUser.bind(this._handler), args.auth, args.request)
      .then(function(result) {
        var result_obj = new UserWriteApi_updateUser_result({success: result});
        output.writeMessageBegin("updateUser", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
          result = new UserWriteApi_updateUser_result(err);
          output.writeMessageBegin("updateUser", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("updateUser", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.updateUser(args.auth, args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
        result_obj = new UserWriteApi_updateUser_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("updateUser", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("updateUser", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
UserWriteApiProcessor.prototype.process_deleteUser = function(seqid, input, output) {
  var args = new UserWriteApi_deleteUser_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.deleteUser.length === 2) {
    Q.fcall(this._handler.deleteUser.bind(this._handler), args.auth, args.request)
      .then(function(result) {
        var result_obj = new UserWriteApi_deleteUser_result({success: result});
        output.writeMessageBegin("deleteUser", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
          result = new UserWriteApi_deleteUser_result(err);
          output.writeMessageBegin("deleteUser", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("deleteUser", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.deleteUser(args.auth, args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
        result_obj = new UserWriteApi_deleteUser_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("deleteUser", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("deleteUser", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
UserWriteApiProcessor.prototype.process_createApiKey = function(seqid, input, output) {
  var args = new UserWriteApi_createApiKey_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.createApiKey.length === 2) {
    Q.fcall(this._handler.createApiKey.bind(this._handler), args.auth, args.request)
      .then(function(result) {
        var result_obj = new UserWriteApi_createApiKey_result({success: result});
        output.writeMessageBegin("createApiKey", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
          result = new UserWriteApi_createApiKey_result(err);
          output.writeMessageBegin("createApiKey", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("createApiKey", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.createApiKey(args.auth, args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
        result_obj = new UserWriteApi_createApiKey_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("createApiKey", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("createApiKey", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
UserWriteApiProcessor.prototype.process_deleteApiKey = function(seqid, input, output) {
  var args = new UserWriteApi_deleteApiKey_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.deleteApiKey.length === 2) {
    Q.fcall(this._handler.deleteApiKey.bind(this._handler), args.auth, args.request)
      .then(function(result) {
        var result_obj = new UserWriteApi_deleteApiKey_result({success: result});
        output.writeMessageBegin("deleteApiKey", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
          result = new UserWriteApi_deleteApiKey_result(err);
          output.writeMessageBegin("deleteApiKey", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("deleteApiKey", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.deleteApiKey(args.auth, args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
        result_obj = new UserWriteApi_deleteApiKey_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("deleteApiKey", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("deleteApiKey", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
UserWriteApiProcessor.prototype.process_updateUserPreferences = function(seqid, input, output) {
  var args = new UserWriteApi_updateUserPreferences_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.updateUserPreferences.length === 2) {
    Q.fcall(this._handler.updateUserPreferences.bind(this._handler), args.auth, args.request)
      .then(function(result) {
        var result_obj = new UserWriteApi_updateUserPreferences_result({success: result});
        output.writeMessageBegin("updateUserPreferences", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
          result = new UserWriteApi_updateUserPreferences_result(err);
          output.writeMessageBegin("updateUserPreferences", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("updateUserPreferences", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.updateUserPreferences(args.auth, args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
        result_obj = new UserWriteApi_updateUserPreferences_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("updateUserPreferences", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("updateUserPreferences", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
UserWriteApiProcessor.prototype.process_enableUser = function(seqid, input, output) {
  var args = new UserWriteApi_enableUser_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.enableUser.length === 2) {
    Q.fcall(this._handler.enableUser.bind(this._handler), args.auth, args.request)
      .then(function(result) {
        var result_obj = new UserWriteApi_enableUser_result({success: result});
        output.writeMessageBegin("enableUser", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
          result = new UserWriteApi_enableUser_result(err);
          output.writeMessageBegin("enableUser", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("enableUser", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.enableUser(args.auth, args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
        result_obj = new UserWriteApi_enableUser_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("enableUser", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("enableUser", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
UserWriteApiProcessor.prototype.process_disableUser = function(seqid, input, output) {
  var args = new UserWriteApi_disableUser_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.disableUser.length === 2) {
    Q.fcall(this._handler.disableUser.bind(this._handler), args.auth, args.request)
      .then(function(result) {
        var result_obj = new UserWriteApi_disableUser_result({success: result});
        output.writeMessageBegin("disableUser", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
          result = new UserWriteApi_disableUser_result(err);
          output.writeMessageBegin("disableUser", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("disableUser", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.disableUser(args.auth, args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
        result_obj = new UserWriteApi_disableUser_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("disableUser", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("disableUser", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
UserWriteApiProcessor.prototype.process_activateUser = function(seqid, input, output) {
  var args = new UserWriteApi_activateUser_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.activateUser.length === 1) {
    Q.fcall(this._handler.activateUser.bind(this._handler), args.request)
      .then(function(result) {
        var result_obj = new UserWriteApi_activateUser_result({success: result});
        output.writeMessageBegin("activateUser", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.UserException || err instanceof ttypes.GenericError) {
          result = new UserWriteApi_activateUser_result(err);
          output.writeMessageBegin("activateUser", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("activateUser", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.activateUser(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.UserException || err instanceof ttypes.GenericError) {
        result_obj = new UserWriteApi_activateUser_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("activateUser", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("activateUser", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
UserWriteApiProcessor.prototype.process_updatePassword = function(seqid, input, output) {
  var args = new UserWriteApi_updatePassword_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.updatePassword.length === 2) {
    Q.fcall(this._handler.updatePassword.bind(this._handler), args.auth, args.request)
      .then(function(result) {
        var result_obj = new UserWriteApi_updatePassword_result({success: result});
        output.writeMessageBegin("updatePassword", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
          result = new UserWriteApi_updatePassword_result(err);
          output.writeMessageBegin("updatePassword", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("updatePassword", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.updatePassword(args.auth, args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
        result_obj = new UserWriteApi_updatePassword_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("updatePassword", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("updatePassword", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
UserWriteApiProcessor.prototype.process_resetPassword = function(seqid, input, output) {
  var args = new UserWriteApi_resetPassword_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.resetPassword.length === 1) {
    Q.fcall(this._handler.resetPassword.bind(this._handler), args.request)
      .then(function(result) {
        var result_obj = new UserWriteApi_resetPassword_result({success: result});
        output.writeMessageBegin("resetPassword", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.UserException || err instanceof ttypes.GenericError) {
          result = new UserWriteApi_resetPassword_result(err);
          output.writeMessageBegin("resetPassword", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("resetPassword", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.resetPassword(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.UserException || err instanceof ttypes.GenericError) {
        result_obj = new UserWriteApi_resetPassword_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("resetPassword", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("resetPassword", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
UserWriteApiProcessor.prototype.process_resendWelcomeEmail = function(seqid, input, output) {
  var args = new UserWriteApi_resendWelcomeEmail_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.resendWelcomeEmail.length === 2) {
    Q.fcall(this._handler.resendWelcomeEmail.bind(this._handler), args.auth, args.request)
      .then(function(result) {
        var result_obj = new UserWriteApi_resendWelcomeEmail_result({success: result});
        output.writeMessageBegin("resendWelcomeEmail", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
          result = new UserWriteApi_resendWelcomeEmail_result(err);
          output.writeMessageBegin("resendWelcomeEmail", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("resendWelcomeEmail", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.resendWelcomeEmail(args.auth, args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
        result_obj = new UserWriteApi_resendWelcomeEmail_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("resendWelcomeEmail", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("resendWelcomeEmail", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
UserWriteApiProcessor.prototype.process_sendForgotPasswordEmail = function(seqid, input, output) {
  var args = new UserWriteApi_sendForgotPasswordEmail_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.sendForgotPasswordEmail.length === 2) {
    Q.fcall(this._handler.sendForgotPasswordEmail.bind(this._handler), args.auth, args.request)
      .then(function(result) {
        var result_obj = new UserWriteApi_sendForgotPasswordEmail_result({success: result});
        output.writeMessageBegin("sendForgotPasswordEmail", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
          result = new UserWriteApi_sendForgotPasswordEmail_result(err);
          output.writeMessageBegin("sendForgotPasswordEmail", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("sendForgotPasswordEmail", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.sendForgotPasswordEmail(args.auth, args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
        result_obj = new UserWriteApi_sendForgotPasswordEmail_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("sendForgotPasswordEmail", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("sendForgotPasswordEmail", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
UserWriteApiProcessor.prototype.process_addRole = function(seqid, input, output) {
  var args = new UserWriteApi_addRole_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.addRole.length === 2) {
    Q.fcall(this._handler.addRole.bind(this._handler), args.auth, args.request)
      .then(function(result) {
        var result_obj = new UserWriteApi_addRole_result({success: result});
        output.writeMessageBegin("addRole", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
          result = new UserWriteApi_addRole_result(err);
          output.writeMessageBegin("addRole", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("addRole", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.addRole(args.auth, args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
        result_obj = new UserWriteApi_addRole_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("addRole", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("addRole", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
UserWriteApiProcessor.prototype.process_addRoles = function(seqid, input, output) {
  var args = new UserWriteApi_addRoles_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.addRoles.length === 2) {
    Q.fcall(this._handler.addRoles.bind(this._handler), args.auth, args.request)
      .then(function(result) {
        var result_obj = new UserWriteApi_addRoles_result({success: result});
        output.writeMessageBegin("addRoles", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
          result = new UserWriteApi_addRoles_result(err);
          output.writeMessageBegin("addRoles", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("addRoles", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.addRoles(args.auth, args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
        result_obj = new UserWriteApi_addRoles_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("addRoles", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("addRoles", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
UserWriteApiProcessor.prototype.process_removeRole = function(seqid, input, output) {
  var args = new UserWriteApi_removeRole_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.removeRole.length === 2) {
    Q.fcall(this._handler.removeRole.bind(this._handler), args.auth, args.request)
      .then(function(result) {
        var result_obj = new UserWriteApi_removeRole_result({success: result});
        output.writeMessageBegin("removeRole", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
          result = new UserWriteApi_removeRole_result(err);
          output.writeMessageBegin("removeRole", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("removeRole", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.removeRole(args.auth, args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
        result_obj = new UserWriteApi_removeRole_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("removeRole", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("removeRole", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
UserWriteApiProcessor.prototype.process_removeRoles = function(seqid, input, output) {
  var args = new UserWriteApi_removeRoles_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.removeRoles.length === 2) {
    Q.fcall(this._handler.removeRoles.bind(this._handler), args.auth, args.request)
      .then(function(result) {
        var result_obj = new UserWriteApi_removeRoles_result({success: result});
        output.writeMessageBegin("removeRoles", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
          result = new UserWriteApi_removeRoles_result(err);
          output.writeMessageBegin("removeRoles", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("removeRoles", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.removeRoles(args.auth, args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.UserException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
        result_obj = new UserWriteApi_removeRoles_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("removeRoles", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("removeRoles", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
UserWriteApiProcessor.prototype.process_health = function(seqid, input, output) {
  var args = new UserWriteApi_health_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.health.length === 0) {
    Q.fcall(this._handler.health.bind(this._handler))
      .then(function(result) {
        var result_obj = new UserWriteApi_health_result({success: result});
        output.writeMessageBegin("health", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("health", Thrift.MessageType.EXCEPTION, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.health(function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined')) {
        result_obj = new UserWriteApi_health_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("health", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("health", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
