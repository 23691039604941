import React, { useState } from "react";

import { FormattedMessage } from "react-intl";

import { Button } from "@ts-digital/vrc";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Spin } from "antd";
import { Input } from "@ts-digital/vrc";

import {
    MarginIcon,
    LoadingIcon,
    UpdateSearchContainer,
    SearchTitleContainer,
    UpdateContainer,
    SearchIcon,
    SearchIconContainer
} from "./styled";

export const SearchInputBar = ({
    value,
    label,
    onSearch,
    onRefresh,
    loading,
    placeholder,
    cypressid,
    showRefresh,
    width
}) => {
    const [textValue, setTextValue] = useState(value);

    return (
        <>
            <UpdateSearchContainer>
                {showRefresh && (
                    <UpdateContainer>
                        <Button
                            kind="tertiary"
                            onClick={() => {
                                onRefresh();
                            }}
                            data-cy={cypressid ? cypressid + "_refresh" : null}
                        >
                            <Spin indicator={<LoadingIcon type="loading" />} spinning={loading} />
                            <MarginIcon icon={faRedo} />
                            <FormattedMessage id="general.update" />
                        </Button>
                    </UpdateContainer>
                )}
                <div>
                    <SearchTitleContainer>
                        {label ? label : <FormattedMessage id="general.users-search" />}
                    </SearchTitleContainer>
                    <Input
                        style={{ width: width ? width : "300px" }}
                        value={textValue}
                        onChange={value => {
                            setTextValue(value);
                        }}
                        onKeyPress={e => {
                            if (e.key === "Enter") onSearch(e.target.value ? e.target.value : null);
                        }}
                        placeholder={placeholder}
                        data-cy={cypressid ? cypressid + "_txt" : null}
                    />
                </div>
                <SearchIconContainer onClick={() => onSearch(textValue)}>
                    <SearchIcon icon={faSearch} data-cy={cypressid ? cypressid + "_img" : null} />
                </SearchIconContainer>
            </UpdateSearchContainer>
        </>
    );
};

export default SearchInputBar;
