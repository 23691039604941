import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { compose } from "redux";

import { errorBoundary } from "../../../components/general/error-boundary";
import { CONSOLE_AGYOLOG } from "../../../config";
import { injectIntl } from "react-intl";
import "./style.css";
import { useFeature } from "../../../hooks/use-feature";
import MigratingChildApp from "../../portale-child-app/migrating";
import UserNotEnabled from "./user-not-enabled";

const GdprlogView = ({ auth, company, language, servicesGdprLog }) => {
    const enableChildApp = useFeature("GDPR_LOG_CHILD_APP");

    const userStatus = servicesGdprLog && servicesGdprLog.config && servicesGdprLog.config.userStatus;
    const user = servicesGdprLog && servicesGdprLog.config && servicesGdprLog.config.user;

    const iframeSetup = useCallback(
        iframeRef => {
            let accessKey = {
                id: user && user.agyoId,
                language,
                token: auth.loginAuth.securityToken,
                companyIds: user && user.companyId
            };
            iframeRef.contentWindow.postMessage(JSON.stringify(accessKey), CONSOLE_AGYOLOG);
        },
        [auth.loginAuth.securityToken, language, user]
    );

    return (
        <div className="c-gdprlog-view">
            {company.base.status.status === "VALIDATED" && userStatus === "confirmed" ? (
                <MigratingChildApp
                    iframeConfigs={{
                        className: "iframe",
                        onLoad: iframeSetup,
                        data: {},
                        id: "gdprlog",
                        title: "Gdprlog"
                    }}
                    migrated={enableChildApp}
                    url={CONSOLE_AGYOLOG}
                />
            ) : (
                <UserNotEnabled company={company} userStatus={userStatus} />
            )}
        </div>
    );
};

GdprlogView.propTypes = {
    addSteps: PropTypes.func,
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    servicesGdprLog: PropTypes.object.isRequired
};

function mapStateToProps(state, props) {
    return {
        auth: state.auth,
        company: state.companies.data[props.match.params.item]
            ? state.companies.data[props.match.params.item].item
            : {},
        language: state.user.user ? state.user.user.profile.language : undefined,
        servicesGdprLog: state.services.gdprlog
    };
}

const composedHoc = compose(connect(mapStateToProps), errorBoundary, injectIntl);

export default composedHoc(GdprlogView);
