import React from "react";
import PropTypes from "prop-types";

import { FormattedHTMLMessage } from "react-intl";

import CreateForm from "../create-form";
import { Description } from "./styled";

const Create = ({ companies, initialValues, onCompanyCreation, onCancel }) => {
    return (
        <>
            <Description>
                <FormattedHTMLMessage id="c-company-create-modal.title" />
            </Description>
            <CreateForm
                errorMsg={companies.createItem.status.error && companies.createItem.status.errorInfo.message}
                initialValues={initialValues}
                isLoading={companies.createItem.status.started}
                onCancel={onCancel}
                onSubmit={onCompanyCreation}
            />
        </>
    );
};

Create.propTypes = {
    onCompanyCreation: PropTypes.func,
    onCancel: PropTypes.func,
    visible: PropTypes.bool
};

export default Create;
