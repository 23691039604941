import React from "react";
import PropTypes from "prop-types";

import { Form, Input } from "antd";

/**
 * Form input text with an addon (small grey label) on its left.
 * Any unrecognized prop will be propagated to the input component.
 */
const FormInputWithAddon = ({ addOn, input, label, labelCol, meta: { touched, error }, wrapperCol, ...custom }) => (
    <Form.Item
        hasFeedback={true}
        help={touched && error}
        validateStatus={touched && error ? "error" : ""}
        wrapperCol={wrapperCol}
        labelCol={labelCol}
    >
        <Input addonBefore={addOn ? addOn : label} placeholder={label} {...input} {...custom} />
    </Form.Item>
);

FormInputWithAddon.propTypes = {
    /**
     * The text for the addon. If undefined, the value
     * of label will be used
     */
    addOn: PropTypes.any,
    /**
     * Props to be passed down to the actual input field.
     * Allows for easy integration with redux-form
     */
    input: PropTypes.object.isRequired,
    /**
     * Text used as input placeholder and, if addon is not defined,
     * for addon text
     */
    label: PropTypes.string,
    labelCol: PropTypes.object,
    /**
     * Meta information about the field.
     * Allows for easy integration with redux-form
     */
    meta: PropTypes.shape({
        error: PropTypes.string, // Error message, displayed only if touched==true
        touched: PropTypes.bool // Whether the field has been interacted with
    }).isRequired,
    wrapperCol: PropTypes.object
};

export default FormInputWithAddon;
