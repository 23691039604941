import styled from "styled-components";

export const Container = styled.div`
    margin: 16px 8px;
    position: relative;
`;

export const CompanyInfoContainer = styled.div`
    background-color: #fff;
    padding: 20px;
    border-bottom: 2px solid #0090d1;
`;

export const ActiveCategoriesContainer = styled.div`
    background-color: #fff;
    padding-top: 40px;
    padding-bottom: 40px;
`;

export const TsLogoContainer = styled.div`
    text-align: center;
`;

export const Title = styled.div`
    height: 26px;
    font-family: Cairo;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.18;
    text-align: center;
    text-transform: uppercase;
    color: #005075;
    margin-bottom: 32px;
`;

export const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 384px));
    grid-gap: 32px;
    justify-content: center;
    margin: 0 24px;
`;

export const SuggestedCategoriesContainer = styled.div`
    background-color: #f2f5f8;
    padding-top: 40px;
    padding-bottom: 40px;
`;

export const CloudAppsContainer = styled.div`
    background-color: #fff;
    padding-top: 40px;
    padding-bottom: 40px;
`;

export const TalksAdvertisingBannerWrapper = styled.div`
    position: sticky;
    margin-top: 24px;
    bottom: -16px;
    left: 0;
    width: 100%;
    z-index: 999;
`;
