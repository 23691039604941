import styled from "styled-components";

export const Header = styled.div`
    flex-grow: 0;
    font-weight: bold;
    padding: 8px 24px;

    border-bottom: 2px solid #0090d1;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: Cairo;
    font-size: 25px;
    letter-spacing: 0.15px;
    color: #005075;
`;

export const Content = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    height: 90%;
`;

export const List = styled.div`
    background-color: #fff;
    flex: 1;
    overflow: auto;
    padding: 8px;
`;

export const NotificationContainer = styled.div`
    margin: 16px;
    flex: 2;
    overflow: auto;
`;

export const NotificationBox = styled.div`
    margin: 5px;
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
`;
