import styled from "styled-components";
import { Button } from "@ts-digital/vrc";

export const Container = styled.div`
    height: 100%;
    display: flex;
`;

export const CustomButton = styled(Button)`
    width: 120px;
    height: 100%;
`;
