import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCog } from "@fortawesome/pro-regular-svg-icons";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@ts-digital/vrc";
import moment from "moment";

import {
    Title,
    Description,
    CredentialMessageContainer,
    DaysLeftContainer,
    CredentialWarningIcon,
    ResetCredentialButtonContainer,
    ServicesContainer,
    ConfigureButtonContainer,
    Hyperlink
} from "../styled";
import CctSpec from "../cct-spec";
import LinkButton from "../../../general/link-button";
import { CCT_CONSOLE } from "../../../../config";

const OldCctFlowSection = ({ cctConfig, cctUser, handleModalOpen, itemApps, provisioningInfo }) => {
    const oldCctFlowApps = Object.values(itemApps.data).filter(a => a.cctSpecsConfig.oldFlow);

    return (
        <>
            <Title>
                <FontAwesomeIcon icon={faUser} /> <FormattedHTMLMessage id="cct-connect-your-archive-credentials" />
            </Title>
            <Description>
                <FormattedMessage id="cct-exist-archive-credentials-description" />
            </Description>
            {cctConfig.passwordExpDate > 0 && moment(cctConfig.passwordExpDate).diff(moment(), "days") <= 5 && (
                <CredentialMessageContainer>
                    <DaysLeftContainer>
                        <span>
                            <CredentialWarningIcon icon={faExclamationCircle} />{" "}
                            {moment(cctConfig.passwordExpDate).isBefore(moment()) ? (
                                <FormattedMessage id="cct-expired-archive-credentials-message" />
                            ) : (
                                <FormattedMessage
                                    id="cct-exp-date-days-left"
                                    values={{ daysLeft: moment(cctConfig.passwordExpDate).fromNow() }}
                                />
                            )}
                        </span>
                        <ResetCredentialButtonContainer>
                            <a href={`${CCT_CONSOLE}ResetPswd.asp`} target="_blank" rel="noopener noreferrer">
                                <Hyperlink>
                                    <FormattedMessage id="cct-reset-archive-credentials" />
                                </Hyperlink>
                            </a>
                        </ResetCredentialButtonContainer>
                    </DaysLeftContainer>
                </CredentialMessageContainer>
            )}
            <ServicesContainer>
                {(!provisioningInfo || !provisioningInfo.status) &&
                    oldCctFlowApps
                        .filter(a => a.cctSpecsConfig.newFlow)
                        .map(app => (
                            <CctSpec
                                key={app.id}
                                id={`apps.${app.id}.title`}
                                specs={cctConfig.specs}
                                name={app.cctSpecsConfig.specs}
                            />
                        ))}
                {oldCctFlowApps
                    .filter(a => !a.cctSpecsConfig.newFlow)
                    .map(app => (
                        <CctSpec
                            key={app.id}
                            id={`apps.${app.id}.title`}
                            specs={cctConfig.specs}
                            name={app.cctSpecsConfig.specs}
                        />
                    ))}
            </ServicesContainer>
            <ConfigureButtonContainer>
                <Button size="medium" kind="tertiary" onClick={() => handleModalOpen()}>
                    <FontAwesomeIcon icon={faCog} />{" "}
                    <FormattedMessage id={cctUser ? "c-cct-edit-user-set.link" : "cct-connect-ts-digital-archive"} />
                </Button>
                <LinkButton to="edit-cct/configure-item-services" disabled={!cctUser}>
                    <FormattedMessage id="general.configures" />
                </LinkButton>
            </ConfigureButtonContainer>
        </>
    );
};

OldCctFlowSection.propTypes = {
    cctConfig: PropTypes.shape({
        specs: PropTypes.object
    }),
    cctUser: PropTypes.object,
    itemApps: PropTypes.object,
    handleModalOpen: PropTypes.func,
    provisioningInfo: PropTypes.object
};

export default OldCctFlowSection;
