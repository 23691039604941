import TextField from "@ts-digital/arc/antd/TextField";
import styled from "styled-components";

export const StyledInput = styled(TextField)`
    input {
        border: 1px solid #c9d9e8 !important;
        background-color: #f2f5f8 !important;
        height: 40px !important;
        border-radius: initial !important;

        :hover {
            border: 1px solid rgb(0, 144, 209) !important;
            outline: none !important;
        }

        :focus {
            outline: none !important;
            border: 1px solid rgb(0, 144, 209) !important;
            box-shadow: none !important;
        }
    }
`;

export const Label = styled.div`
    color: rgb(21, 41, 53);
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 16px;
`;

export const ErrorMessage = styled.div`
    color: ${props => props.theme.colors.status.maximumRed};
    height: 24px;
    margin-bottom: 16px;
`;
