import React from "react";
import PropTypes from "prop-types";
import { Button } from "@ts-digital/arc/antd";
import { StyledButtonTable, BorderedDiv, StyledHeader } from "./styled";
import { Link } from "react-router-dom";
import { FormattedHTMLMessage } from "react-intl";
import UserInfo from "../user-info";
import CompanyInfo from "../company-info";

export const PopupContent = ({
    auth,
    copyTokenToast,
    resetCompanyLoad,
    dashboardView,
    user,
    intl,
    showNotification,
    company,
    handleModifyUserClick,
    handleCloseModal,
    users
}) => {
    return (
        <StyledHeader>
            <UserInfo
                auth={auth}
                copyTokenToast={copyTokenToast}
                user={user}
                intl={intl}
                showNotification={showNotification}
                company={company}
                handleModifyUserClick={() => handleModifyUserClick()}
                handleCloseModal={() => handleCloseModal()}
            />

            {dashboardView && (
                <>
                    <CompanyInfo
                        user={user}
                        company={company}
                        handleCloseModal={() => handleCloseModal()}
                        showNotification={showNotification}
                        users={users}
                    />
                    <BorderedDiv />
                    <StyledButtonTable>
                        <Link to={"/"}>
                            <Button variant="primary" onClick={() => resetCompanyLoad()}>
                                <FormattedHTMLMessage id="c-manage-data.user-edit-button.popover.manage" />
                            </Button>
                        </Link>
                    </StyledButtonTable>
                </>
            )}
        </StyledHeader>
    );
};

PopupContent.propTypes = {
    company: PropTypes.object,
    resetCompanyLoad: PropTypes.func,
    dashboardView: PropTypes.bool,
    user: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    showNotification: PropTypes.bool,
    handleModifyUserClick: PropTypes.func.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    listUsers: PropTypes.func,
    users: PropTypes.object
};

export default PopupContent;
