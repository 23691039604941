import React, { Component } from "react";
import PropTypes from "prop-types";

import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import { ConnectionsActionBar } from "../../components/connections-new";
import { errorBoundary } from "../../components/general/error-boundary";
import { isAdmin } from "../../utils/app-availability";
import { Container } from "./styled";

import ConnectionsManaged from "./managed";
import ConnectionsManager from "./manager";
import ConnectionsManagedHistory from "./managedHistory";

export class ConnessioniView extends Component {
    static propTypes = {
        company: PropTypes.object,
        history: PropTypes.object,
        itemServices: PropTypes.array.isRequired,
        match: PropTypes.object.isRequired,
        user: PropTypes.object
    };

    static defaultProps = { user: {} };

    componentDidMount() {
        const { company, history, match, user } = this.props;

        if (!user.user || !user.user.roles || !isAdmin(user.user.roles, company.base.id)) {
            history.push(`/${match.params.item}/dashboard`);
        }
    }

    render() {
        const { company, itemServices, match } = this.props;

        let isStudio = company.base.details.classifier === "STUDIO" || itemServices.includes("PRIVACY-FLOW");

        return match.params.type === undefined && isStudio ? (
            <Redirect to={`/${match.params.item}/connections/managed`} />
        ) : match.params.type === undefined && !isStudio ? (
            <Redirect to={`/${match.params.item}/connections/manager`} />
        ) : match.params.type === "manager" && match.params.history ? (
            <Redirect to={`/${match.params.item}/connections/manager`} />
        ) : (
            <Container>
                <ConnectionsActionBar isStudio={isStudio} />
                <Route
                    exact
                    path={`/:item/connections/managed`} // aziende di cui gestisco i servizi
                    component={ConnectionsManaged}
                />
                <Route
                    exact
                    path={`/:item/connections/managed/history`} // aziende di cui gestivo i servizi
                    component={ConnectionsManagedHistory}
                />
                <Route
                    exact
                    path={`/:item/connections/manager`} // aziende che gestiscono i miei servizi
                    component={ConnectionsManager}
                />
            </Container>
        );
    }
}

const mapStateToProps = (state, props) => ({
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    itemServices: state.services.active.services || [],
    user: state.user
});

const composedHoc = compose(connect(mapStateToProps), errorBoundary);

export default composedHoc(ConnessioniView);
