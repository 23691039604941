import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";

import { PaginationContainer } from "./styled";

/**
 * Pagination component following the style specified in Vapor's design guidelines (https://vapor.mondora.com/9wvncgrsz/p/90c521/b/b0699)
 * The underlying component is react-paginate (https://www.npmjs.com/package/react-paginate)
 */
export const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    return (
        <PaginationContainer>
            <ReactPaginate
                forcePage={currentPage}
                previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
                marginPagesDisplayed={1}
                nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
                onPageChange={({ selected }) => onPageChange(selected)}
                pageCount={totalPages}
                pageRangeDisplayed={3}
            />
        </PaginationContainer>
    );
};

Pagination.propTypes = {
    /** Current page number, starting from 0 */
    currentPage: PropTypes.number.isRequired,
    /** Total number of available pages */
    totalPages: PropTypes.number.isRequired,
    /** Callback invoked on page change, receives the selected page as its only argument */
    onPageChange: PropTypes.func.isRequired
};
