import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const UserEmail = styled.div`
    color: rgb(90, 104, 114);
    font-size: 14px;
    font-family: Cairo;
    font-weight: 600;
    line-height: 24px;
    min-width: 300px;
`;

export const FullName = styled.div`
    color: rgb(0, 80, 117);
    font-size: 34px;
    font-family: Cairo;
    font-weight: 600;
    line-height: 40px;
`;

export const InitialsContainer = styled.div`
    flex-shrink: 0;
`;

export const Status = styled.div`
    color: rgb(21, 41, 53);
    font-size: 14px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 24px;
`;

export const StatusContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const StatusIcon = styled(FontAwesomeIcon)`
    color: ${props =>
        props.active === "true" ? props.theme.colors.status.laSalleGreen : props.theme.colors.status.crayola};
    font-size: 16px;
    text-align: center;
    width: 40px;
    margin-right: 8px;
    margin-top: ${props => props.margintop};
`;

export const UserDescriptionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    grid-gap: 16px;
    flex-direction: column;
`;

export const UserInfoContainer = styled.div`
    width: 100%;
`;

export const UserInfoWrapper = styled.div`
    display: flex;
    grid-gap: 24px;
`;
