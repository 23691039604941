import { css } from "styled-components";

export const small = css`
    height: 140px;
    width: 140px;

    @media (max-width: 1919px) {
        width: 120px;
        height: 120px;
    }
`;

export const medium = css`
    height: 160px;
    width: 160px;

    @media (max-width: 1439px) {
        width: 120px;
        height: 120px;
    }

    @media (min-width: 1440px) and (max-width: 1919px) {
        width: 140px;
        height: 140px;
    }
`;

export const large = css`
    height: 180px;
    width: 180px;

    @media (max-width: 1439px) {
        width: 140px;
        height: 140px;
    }

    @media (min-width: 1440px) and (max-width: 1919px) {
        width: 160px;
        height: 160px;
    }
`;
