import React, { useCallback, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";

import { Spin } from "antd";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Button } from "@ts-digital/vrc";
import { faRedo } from "@fortawesome/free-solid-svg-icons";

import { listUsers } from "../../../../actions/company/users";
import { ConfigureItem } from "../../../../components/storage/cct/provisioning/configure-item";
import UploadPdf from "../../../../components/storage/cct/provisioning/upload-pdf-form";
import {
    fetchCctProvisioningInfo,
    generateCctProvisioningContract,
    resetGenerateCctProvisioningContract,
    resetUploadCctProvisioningContract,
    uploadCctProvisioningContract
} from "../../../../actions/services/cct/provisioning";

import {
    Container,
    Content,
    Title,
    Description,
    TitleWrapper,
    ContentWrapper,
    TableUpdateButton,
    LoadingIcon,
    RedoIcon
} from "../styled";

import Modal from "../../../../components/general/modal/";

import PageTitle from "../../../../components/general/page-title";

export const ConfigureItemView = ({
    configError,
    configLoaded,
    configLoading,
    fetchCctProvisioningInfo,
    generateContractStatus,
    history,
    item,
    match,
    provisioningContractStatus,
    provisioningInfo,
    resetGenerateCctProvisioningContract,
    resetUploadCctProvisioningContract,
    uploadCctProvisioningContract,
    provisioningInfoStatus,
    isCctActive
}) => {
    useEffect(() => {
        if (!configLoading && !configLoaded && !configError && isCctActive) {
            fetchCctProvisioningInfo(item.base.id);
        }
    }, [configLoaded, configLoading, configError, fetchCctProvisioningInfo, item.base.id, isCctActive]);

    useEffect(() => {
        if (generateContractStatus.ended) {
            resetGenerateCctProvisioningContract();
        }
    }, [generateContractStatus, resetGenerateCctProvisioningContract]);

    useEffect(() => {
        if (provisioningContractStatus.ended) {
            setUploadModalOpen(false);
            resetUploadCctProvisioningContract();
        }
    }, [provisioningContractStatus, resetUploadCctProvisioningContract]);

    const [fileTypeError, setFileTypeError] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [fileBase64, setFileBase64] = useState("");

    const handleSelectFile = useCallback((base64, file, error) => {
        setFileTypeError(error);
        setFileBase64(base64);
        setSelectedFile(file);
    }, []);

    const [isUploadModalOpen, setUploadModalOpen] = useState(false);

    const itemDescription = item.base && item.base.details.description;
    const itemTaxId = item.base && item.base.identifier.taxId;

    useEffect(() => {
        setFileBase64("");
        setSelectedFile(undefined);
    }, [itemTaxId]);

    const formInitialValues = useMemo(() => {
        const itemInfo = provisioningInfo || {};
        const selectedItemHolder = itemInfo.holder || {};

        return {
            balanceSheetDate: itemInfo.balanceSheetDate,
            birthPlace: selectedItemHolder.birthPlace,
            country: selectedItemHolder.country,
            email: selectedItemHolder.email,
            gender: selectedItemHolder.gender,
            isPec: selectedItemHolder.isPec,
            name: selectedItemHolder.name,
            surname: selectedItemHolder.surname,
            birthDate: selectedItemHolder.birthDate,
            taxId: selectedItemHolder.taxId
        };
    }, [provisioningInfo]);

    return (
        <>
            <PageTitle
                title={<FormattedMessage id="c-services-view.ARC.name" />}
                subtitle={<FormattedMessage id="c-cct-item-services-section-subtitle" />}
                onBack={() => history.goBack()}
            />
            <Container>
                <Content>
                    <TitleWrapper>
                        <Title>
                            <FormattedMessage id="c-cct-title" />
                        </Title>
                    </TitleWrapper>
                    <TitleWrapper>
                        <Description>
                            <>
                                <FormattedMessage id="c-cct-provisioning-configure-item.table-title" />
                                <TableUpdateButton
                                    kind="tertiary"
                                    onClick={() => {
                                        fetchCctProvisioningInfo(item.base.id);
                                    }}
                                >
                                    <Spin
                                        indicator={<LoadingIcon type="loading" />}
                                        spinning={provisioningInfoStatus}
                                    />
                                    <RedoIcon icon={faRedo} />
                                    <FormattedMessage id="general.update" />
                                </TableUpdateButton>
                            </>
                        </Description>
                    </TitleWrapper>
                    <ContentWrapper>
                        <ConfigureItem
                            itemDescription={itemDescription}
                            itemTaxId={itemTaxId}
                            holderTaxId={formInitialValues.taxId}
                            holderDescription={`${formInitialValues.name ? formInitialValues.name : ""} ${
                                formInitialValues.surname ? formInitialValues.surname : ""
                            }`}
                            onDoneButtonClick={() => {
                                history.push(`/${match.params.item}/services/storage/edit-cct`);
                            }}
                            onDownloadClick={() => {
                                history.push(
                                    `/${match.params.item}/services/storage/edit-cct/generate-document/${match.params.item}`
                                );
                            }}
                            onUploadClick={() => setUploadModalOpen(true)}
                            provisioningStatus={(provisioningInfo && provisioningInfo.status) || null}
                            provisioningStatusMessage={provisioningInfo && provisioningInfo.statusMessage}
                            onTableUpdate={() => {
                                fetchCctProvisioningInfo(item.base.id);
                            }}
                            configLoading={provisioningInfoStatus}
                            isActive={provisioningInfo && provisioningInfo.active}
                            toBeFixed={provisioningInfo && provisioningInfo.rdcToBeFixed}
                            rejectedMsg={provisioningInfo && provisioningInfo.description}
                            toBeFixedMsg={provisioningInfo && provisioningInfo.rdcToBeFixedDesc}
                        />
                        <Modal
                            centered={true}
                            closable={true}
                            visible={isUploadModalOpen}
                            width="504px"
                            title={<FormattedMessage id="c-cct-provisioning-upload-pdf-form.title" />}
                            onCancel={() => setUploadModalOpen(false)}
                            footer={[
                                <Button kind="secondary" onClick={() => setUploadModalOpen(false)}>
                                    <FormattedMessage id="general.annulla" />
                                </Button>,
                                <Button
                                    data-gainsight-track={"archive-contract-uploaded-cta"}
                                    onClick={() => {
                                        uploadCctProvisioningContract(match.params.item, fileBase64);
                                    }}
                                >
                                    <FormattedMessage id="c-cct-provisioning-upload-pdf-form.send-document" />
                                </Button>
                            ]}
                        >
                            <UploadPdf
                                handleSelectFile={handleSelectFile}
                                fileTypeError={fileTypeError}
                                isLoading={provisioningContractStatus.started && !provisioningContractStatus.ended}
                                itemDescription={itemDescription}
                                selectedFile={selectedFile}
                            />
                        </Modal>
                    </ContentWrapper>
                </Content>
            </Container>
        </>
    );
};

ConfigureItemView.propTypes = {
    // Passed down from Route
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,

    // Obtained from redux state
    itemFiscalCode: PropTypes.string,
    provisioningInfo: PropTypes.object
};

const mapStateToProps = (state, { match }) => {
    const provisioningInfoArray = state.services.cct.provisioningInfo.provisioningInfo;

    return {
        configError: state.services.cct.getCctConfig.status.error,
        configLoaded: state.services.cct.getCctConfig.status.ended,
        configLoading: state.services.cct.getCctConfig.status.started,
        generateContractStatus: state.services.cct.generateContract.status,
        item: state.companies.data[match.params.item] ? state.companies.data[match.params.item].item : {},
        provisioningContractStatus: state.services.cct.provisioningContract.status,
        provisioningInfo: provisioningInfoArray[match.params.item],
        provisioningStatus:
            (provisioningInfoArray[match.params.item] && provisioningInfoArray[match.params.item].status) || null,
        provisioningInfoStatus: state.services.cct.provisioningInfo.isLoading,
        user: state.user.user,
        users: state.users,
        isCctActive:
            state.services.active.services.includes("CCT-FLOW") && !state.services.active.services.includes("ARC")
    };
};

export default connect(mapStateToProps, {
    fetchCctProvisioningInfo,
    generateCctProvisioningContract,
    listUsers,
    resetGenerateCctProvisioningContract,
    resetUploadCctProvisioningContract,
    uploadCctProvisioningContract
})(ConfigureItemView);
