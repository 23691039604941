import styled from "styled-components";

export const TitleContainer = styled.span`
    margin: 10px;
    font-size: 25px;
`;

export const IconContainer = styled.span`
    margin-left: 20px;
    font-size: 25px;
`;

export const ServiceName = styled.div`
    font-family: Cairo;
    font-size: 25px;
    letter-spacing: 0.15px;
    color: #005075;
`;

export const Header = styled.div`
    border-bottom: 2px solid #0090d1;
    background-color: #fff;
    display: flex;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

export const ButtonContainer = styled.div`
    position: absolute;
    left: 20px;
`;
