import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 56px;
    margin: 24px;
`;

export const ContentContainer = styled.div`
    max-width: 466px;
`;

export const UserExistsMessageContainer = styled.div`
    display: flex;
    margin-top: 8px;
    gap: 10px;

    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 14px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 24px;
`;

export const UserExistsMessageIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 20px;
    padding-top: 5px;
`;

export const ConfirmContainer = styled.div`
    margin-top: 24px;
`;

export const EditContainer = styled.div`
    margin-top: 8px;
`;

export const InsertNewUserContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
`;

export const InsertNewUserDataContainer = styled.div`
    display: flex;
    gap: 21px;
`;

export const EditEmailButton = styled.button`
    border: none;
    color: #008fd6;
    padding: 10px 0;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    background: none;
    cursor: pointer;
`;
