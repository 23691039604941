import {
    WAITINGLIST_SUBSCRIBE_START,
    WAITINGLIST_SUBSCRIBE_ERROR,
    WAITINGLIST_SUBSCRIBE_SUCCESS,
    WAITINGLIST_DELETE_START,
    WAITINGLIST_DELETE_ERROR,
    WAITINGLIST_DELETE_SUCCESS
} from "../../actions/waitinglist/write";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {}
    },
    result: {}
};

export function subscribeWaitinglist(state = defaultState, { type, payload }) {
    switch (type) {
        case WAITINGLIST_SUBSCRIBE_START:
            return {
                ...defaultState,
                status: {
                    ...defaultState.status,
                    started: true
                }
            };
        case WAITINGLIST_SUBSCRIBE_ERROR:
            return {
                ...state,
                status: {
                    ...state.status,
                    started: false,
                    error: true,
                    errorInfo: payload.error
                }
            };
        case WAITINGLIST_SUBSCRIBE_SUCCESS:
            return {
                ...state,
                status: {
                    ...state.status,
                    started: false,
                    ended: true
                },
                result: payload
            };
        default:
            return state;
    }
}

export function deleteWaitinglist(state = defaultState, { type, payload }) {
    switch (type) {
        case WAITINGLIST_DELETE_START:
            return {
                ...defaultState,
                status: {
                    ...defaultState.status,
                    started: true
                }
            };
        case WAITINGLIST_DELETE_ERROR:
            return {
                ...state,
                status: {
                    ...state.status,
                    started: false,
                    error: true,
                    errorInfo: payload.error
                }
            };
        case WAITINGLIST_DELETE_SUCCESS:
            return {
                ...state,
                status: {
                    ...state.status,
                    started: false,
                    ended: true
                },
                result: payload
            };
        default:
            return state;
    }
}
