import React from "react";
import PropTypes from "prop-types";

import { faTimes } from "@fortawesome/pro-regular-svg-icons";

import { Icon, StyledModal } from "./styled";

const Modal = ({ children, type, ...props }) => {
    return (
        <StyledModal
            closeIcon={<Icon icon={faTimes} />}
            maskStyle={{
                background: "rgba(0, 80, 117, 0.32)"
            }}
            type={type}
            width={props.maxWidth ? null : props.width} //ignore static width if maxWidth has been defined
            {...props}
        >
            {children}
        </StyledModal>
    );
};

Modal.propTypes = {
    children: PropTypes.element.isRequired,
    closeable: PropTypes.bool,
    maxWidth: PropTypes.string,
    type: PropTypes.oneOf(["danger", undefined]),
    visible: PropTypes.bool,
    width: PropTypes.string
};

export default Modal;
