import { MAINTENANCE } from "../actions/config";

export function maintenance(state = false, { type, payload }) {
    switch (type) {
        case MAINTENANCE:
            return payload;
        default:
            return state;
    }
}
