import React from "react";
import PropTypes from "prop-types";

import { faBriefcase, faBuilding, faCity, faUser, faUserFriends } from "@fortawesome/pro-regular-svg-icons";
import { IconContainer, Icon, IconCircle } from "./styled";

const classifierIcons = {
    STUDIO: faBriefcase,
    COMPANY: faBuilding,
    PERSON: faUser,
    BUILDING: faCity,
    ASSOCIATION: faUserFriends
};

const ItemClassifierIcon = ({ classifier }) => (
    <IconContainer>
        <IconCircle>
            <Icon icon={classifierIcons[classifier]} />
        </IconCircle>
    </IconContainer>
);

ItemClassifierIcon.propTypes = {
    classifier: PropTypes.oneOf(["BUILDING", "COMPANY", "PERSON", "STUDIO"])
};

export default ItemClassifierIcon;
