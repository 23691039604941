import { LOGIN_START, LOGIN_ERROR, LOGIN_SUCCESS, LOGIN_RESET, LOGOUT, REFRESH_SUCCESS } from "../actions/auth";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    },
    loginAuth: {
        securityToken: null,
        id: null,
        appName: null,
        refreshToken: null
    },
    tokenVerified: false,
    loggingOut: false
};

export function auth(state = defaultState, { type, payload, error }) {
    switch (type) {
        case LOGIN_START:
            return {
                ...defaultState,
                status: {
                    started: true,
                    error: false,
                    ended: false
                },
                loginAuth: state.loginAuth
            };
        case LOGIN_SUCCESS:
        case REFRESH_SUCCESS:
            return {
                ...state,
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload,
                loginAuth: {
                    ...payload.loginAuth,
                    // Some logic seems to expect an accessToken key
                    accessToken: payload.loginAuth.securityToken || payload.loginAuth.accessToken
                }
            };
        case LOGIN_ERROR:
            return {
                ...state,
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: error
                },
                loginAuth: {
                    ...state.loginAuth,
                    id: payload.userId
                }
            };
        case LOGIN_RESET:
            return {
                ...defaultState
            };
        case LOGOUT:
            return { ...defaultState };
        default:
            return state;
    }
}

// Separated from auth due to redux-persist problems
export function loggingOut(state = false, { type }) {
    switch (type) {
        case LOGOUT:
            return true;
        default:
            return state;
    }
}
