import styled from "styled-components";
import { Collapse } from "antd";

export const FeaturesTable = styled.table`
    margin-left: 100px;
`;

export const FeaturesTableTh = styled.th`
    padding-right: 100px;
    padding-bottom: 16px;
`;

export const FeaturesTableHeaderRow = styled.tr`
    color: rgb(0, 80, 117);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    height: 16px;
    line-height: 16px;
`;

export const FeaturesTableContentRow = styled.tr`
    color: rgb(21, 41, 53);
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0.25px;
    line-height: 24px;
`;

export const FeaturesTableContentTd = styled.td`
    border-radius: 0px;
    box-shadow: inset 0px -1px 0px 0px rgb(157, 166, 173);
    padding-bottom: 8px;
    padding-top: 8px;
`;

export const PanelHeaderDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const PanelStyled = styled(Collapse.Panel)`
    & .ant-collapse-header {
        color: rgb(0, 80, 117) !important;
        font-family: Cairo !important;
    }
`;

export const CollapseStyled = styled(Collapse)`
    & .ant-collapse-content,
    &.ant-collapse {
        border: none !important;
    }
`;
