import React from "react";
import PropTypes from "prop-types";

import { Field } from "redux-form";
import { field } from "@ts-digital/conv-redux-form";
import { Select } from "antd";

import { countryValueWithCodice } from "@ts-digital/agyo-validations/lib/common/constants";
import { CustomFormItem, CustomSelect } from "../styled";

export const WrappedCombo = ({ input, label, meta, disabled, placeholder }) => {
    const Option = Select.Option;
    const countryOptions = countryValueWithCodice.map(country => (
        <Option key={country.id} value={country.id}>
            {country.name}
        </Option>
    ));

    return (
        <CustomFormItem
            label={label}
            validateStatus={meta.touched && meta.error ? "error" : ""}
            help={meta.touched && meta.error ? meta.error : ""}
            colon={false}
        >
            <CustomSelect
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={val => {
                    input.onChange(val);
                }}
                showSearch
                value={input.value || undefined}
                disabled={disabled}
                placeholder={placeholder}
            >
                {countryOptions}
            </CustomSelect>
        </CustomFormItem>
    );
};

const CountryCombo = props => {
    return <Field {...props} component={WrappedCombo} />;
};

CountryCombo.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string
};

export default field({ type: "string" })(CountryCombo);
