import React, { Component } from "react";
import PropTypes from "prop-types";

import { Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserShield, faUser } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@ts-digital/arc/antd";
import { FormattedMessage } from "react-intl";

import { TableContainer, TableTitleContainer } from "./styled";

export class ItemTable extends Component {
    static propTypes = {
        buttonLabel: PropTypes.string.isRequired,
        className: PropTypes.string,
        data: PropTypes.arrayOf(
            PropTypes.shape({
                description: PropTypes.string,
                id: PropTypes.string,
                key: PropTypes.string,
                vatNumber: PropTypes.string,
                taxId: PropTypes.string
            })
        ),
        disabled: PropTypes.bool,
        disabledRows: PropTypes.arrayOf(PropTypes.string),
        extraColumns: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.node,
                dataIndex: PropTypes.string,
                key: PropTypes.string
            })
        ),
        loading: PropTypes.bool,
        onButtonClick: PropTypes.func.isRequired,
        selectedRows: PropTypes.arrayOf(PropTypes.string),
        title: PropTypes.string.isRequired,
        userRoles: PropTypes.arrayOf(
            PropTypes.shape({
                appId: PropTypes.string,
                featureCode: PropTypes.string,
                resourceId: PropTypes.string,
                actionKey: PropTypes.string
            })
        )
    };

    static defaultProps = {
        data: [],
        disabled: false,
        disabledRows: [],
        extraColumns: [],
        loading: false,
        selectedRows: [],
        userRoles: []
    };

    columns = [
        {
            title: <FormattedMessage id="c-manage-data.multicompany-create.company" />,
            dataIndex: "description",
            key: "description"
        },
        {
            title: <FormattedMessage id="c-manage-data.multicompany-create.piva" />,
            dataIndex: "vatNumber",
            key: "vatNumber"
        },
        {
            title: <FormattedMessage id="c-manage-data.multicompany-create.fiscal-code" />,
            dataIndex: "taxId",
            key: "taxId"
        },
        {
            title: "",
            key: "admin",
            render: (text, record) => (
                <FontAwesomeIcon
                    icon={this.isItemAdmin(record.id) ? faUserShield : faUser}
                    title={this.isItemAdmin(record.id) ? "Admin" : "User"}
                />
            )
        }
    ];

    isItemAdmin(itemId) {
        const { userRoles } = this.props;

        return userRoles.find(
            r =>
                !r.appId &&
                !r.featureCode &&
                r.resourceId === itemId &&
                (r.actionKey === "ADMIN" || r.actionKey === "OWNER")
        );
    }

    render() {
        const {
            buttonLabel,
            className,
            data,
            disabled,
            disabledRows,
            extraColumns,
            loading,
            onButtonClick,
            selectedRows,
            title
        } = this.props;

        return (
            <div className={className}>
                <TableTitleContainer>
                    <h2>
                        <FormattedMessage id={title} />
                    </h2>
                    <Button disabled={disabled} onClick={onButtonClick} variant="secondary" size="small">
                        <FormattedMessage id={buttonLabel} />
                    </Button>
                </TableTitleContainer>
                <TableContainer>
                    <Table
                        className="companyTable"
                        columns={[...this.columns, ...extraColumns]}
                        dataSource={data}
                        loading={loading}
                        pagination={false}
                        rowSelection={{
                            selectedRowKeys: [...disabledRows, ...selectedRows]
                        }}
                        selections={false}
                    />
                </TableContainer>
            </div>
        );
    }
}
