export const customStyles = {
    container: styles => {
        return {
            ...styles,
            outline: "none",
            backgroundColor: "#f2f5f8",
            border: "1px solid #c9d9e8",
            minHeight: "40px",

            ":hover": {
                border: "1px solid #1890ff",
                borderRadius: 0,
                outline: "none",
                boxShadow: "none"
            }
        };
    },
    control: styles => {
        return {
            ...styles,
            backgroundColor: "transparent",
            borderRadius: 0,
            boxShadow: "none",
            border: "none"
        };
    },
    placeholder: styles => {
        return {
            ...styles,
            color: "#9DA6AD"
        };
    },
    dropdownIndicator: styles => {
        return {
            ...styles,
            color: "#152935",
            ":hover": {
                color: "#152935"
            }
        };
    },
    menu: provided => ({
        ...provided,
        borderRadius: "none"
    }),
    multiValue: styles => {
        return {
            ...styles,
            backgroundColor: "#def0f7"
        };
    },
    multiValueLabel: styles => ({
        ...styles,
        color: " #005075"
    }),
    multiValueRemove: styles => ({
        ...styles,
        color: " #005075",
        ":hover": {
            backgroundColor: "transparent"
        }
    }),
    option: styles => {
        return {
            ...styles,
            color: "#152935",

            ":hover": {
                ...styles[":active"]
            },

            ":active": {
                ...styles[":active"]
            }
        };
    }
};
