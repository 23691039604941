import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import "../style.css";

import Budget from "../../../components/budget";
import { errorBoundary } from "../../../components/general/error-boundary";
import FontawesomeIcon from "../../../components/general/fontawesome-icon";
import { IconContainer, TitleContainer } from "../styled";

import { getWaitingList } from "../../../actions/waitinglist/read";
import { subscribeWaitinglist, deleteWaitinglist } from "../../../actions/waitinglist/write";

export const BudgetView = ({
    company,
    services,
    getWaitingList,
    getWaitingListStatus,
    subscribeWaitinglist,
    deleteWaitinglist,
    waitinglistDeleteStatus,
    waitinglist,
    waitinglistSubscribeStatus
}) => (
    <div>
        <IconContainer>
            <FontawesomeIcon name="key" solid={true} />
        </IconContainer>
        <TitleContainer>
            <FormattedMessage id="c-services-view.BDG.name" />
        </TitleContainer>
        <Budget
            services={services}
            company={company}
            getWaitingList={getWaitingList}
            getWaitingListStatus={getWaitingListStatus}
            subscribeWaitinglist={subscribeWaitinglist}
            deleteWaitinglist={deleteWaitinglist}
            waitinglistDeleteStatus={waitinglistDeleteStatus}
            waitinglist={waitinglist}
            waitinglistSubscribeStatus={waitinglistSubscribeStatus}
        />
    </div>
);

BudgetView.propTypes = {
    services: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => ({
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    services: state.services,
    waitinglist: state.waitinglist.read.getWaitingList.waitinglist,
    waitinglistSubscribeStatus: state.waitinglist.write.subscribeWaitinglist.status,
    waitinglistDeleteStatus: state.waitinglist.write.deleteWaitinglist.status,
    getWaitingListStatus: state.waitinglist.read.getWaitingList.status
});

const composedHoc = compose(
    connect(mapStateToProps, { getWaitingList, subscribeWaitinglist, deleteWaitinglist }),
    errorBoundary
);

export default composedHoc(BudgetView);
