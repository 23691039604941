/* eslint-disable */
//
// Autogenerated by Thrift Compiler (0.11.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
"use strict";

var thrift = require('browser-thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = module.exports = {};
var Auth = module.exports.Auth = function(args) {
  this.id = null;
  this.securityToken = null;
  this.appName = null;
  this.requestId = null;
  this.correlationId = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.securityToken !== undefined && args.securityToken !== null) {
      this.securityToken = args.securityToken;
    }
    if (args.appName !== undefined && args.appName !== null) {
      this.appName = args.appName;
    }
    if (args.requestId !== undefined && args.requestId !== null) {
      this.requestId = args.requestId;
    }
    if (args.correlationId !== undefined && args.correlationId !== null) {
      this.correlationId = args.correlationId;
    }
  }
};
Auth.prototype = {};
Auth.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.securityToken = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.appName = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.requestId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.correlationId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Auth.prototype.write = function(output) {
  output.writeStructBegin('Auth');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.securityToken !== null && this.securityToken !== undefined) {
    output.writeFieldBegin('securityToken', Thrift.Type.STRING, 2);
    output.writeString(this.securityToken);
    output.writeFieldEnd();
  }
  if (this.appName !== null && this.appName !== undefined) {
    output.writeFieldBegin('appName', Thrift.Type.STRING, 3);
    output.writeString(this.appName);
    output.writeFieldEnd();
  }
  if (this.requestId !== null && this.requestId !== undefined) {
    output.writeFieldBegin('requestId', Thrift.Type.STRING, 4);
    output.writeString(this.requestId);
    output.writeFieldEnd();
  }
  if (this.correlationId !== null && this.correlationId !== undefined) {
    output.writeFieldBegin('correlationId', Thrift.Type.STRING, 5);
    output.writeString(this.correlationId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthenticationException = module.exports.AuthenticationException = function(args) {
  Thrift.TException.call(this, "AuthenticationException");
  this.name = "AuthenticationException";
  this.code = null;
  this.message = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Thrift.inherits(AuthenticationException, Thrift.TException);
AuthenticationException.prototype.name = 'AuthenticationException';
AuthenticationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthenticationException.prototype.write = function(output) {
  output.writeStructBegin('AuthenticationException');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GenericError = module.exports.GenericError = function(args) {
  Thrift.TException.call(this, "GenericError");
  this.name = "GenericError";
  this.code = null;
  this.message = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Thrift.inherits(GenericError, Thrift.TException);
GenericError.prototype.name = 'GenericError';
GenericError.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GenericError.prototype.write = function(output) {
  output.writeStructBegin('GenericError');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ContractException = module.exports.ContractException = function(args) {
  Thrift.TException.call(this, "ContractException");
  this.name = "ContractException";
  this.code = null;
  this.message = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Thrift.inherits(ContractException, Thrift.TException);
ContractException.prototype.name = 'ContractException';
ContractException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ContractException.prototype.write = function(output) {
  output.writeStructBegin('ContractException');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Company = module.exports.Company = function(args) {
  this.vatNumber = null;
  this.taxId = null;
  this.resellerVatNumber = null;
  if (args) {
    if (args.vatNumber !== undefined && args.vatNumber !== null) {
      this.vatNumber = args.vatNumber;
    }
    if (args.taxId !== undefined && args.taxId !== null) {
      this.taxId = args.taxId;
    }
    if (args.resellerVatNumber !== undefined && args.resellerVatNumber !== null) {
      this.resellerVatNumber = args.resellerVatNumber;
    }
  }
};
Company.prototype = {};
Company.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.vatNumber = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.taxId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.resellerVatNumber = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Company.prototype.write = function(output) {
  output.writeStructBegin('Company');
  if (this.vatNumber !== null && this.vatNumber !== undefined) {
    output.writeFieldBegin('vatNumber', Thrift.Type.STRING, 1);
    output.writeString(this.vatNumber);
    output.writeFieldEnd();
  }
  if (this.taxId !== null && this.taxId !== undefined) {
    output.writeFieldBegin('taxId', Thrift.Type.STRING, 2);
    output.writeString(this.taxId);
    output.writeFieldEnd();
  }
  if (this.resellerVatNumber !== null && this.resellerVatNumber !== undefined) {
    output.writeFieldBegin('resellerVatNumber', Thrift.Type.STRING, 3);
    output.writeString(this.resellerVatNumber);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Status = module.exports.Status = function(args) {
  this.code = null;
  this.description = null;
  this.type = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.description !== undefined && args.description !== null) {
      this.description = args.description;
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
  }
};
Status.prototype = {};
Status.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.description = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.type = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Status.prototype.write = function(output) {
  output.writeStructBegin('Status');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.description !== null && this.description !== undefined) {
    output.writeFieldBegin('description', Thrift.Type.STRING, 2);
    output.writeString(this.description);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.STRING, 3);
    output.writeString(this.type);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RegistryStatus = module.exports.RegistryStatus = function(args) {
  this.companyCode = null;
  this.installationCode = null;
  this.environmentCode = null;
  this.uri = null;
  if (args) {
    if (args.companyCode !== undefined && args.companyCode !== null) {
      this.companyCode = args.companyCode;
    }
    if (args.installationCode !== undefined && args.installationCode !== null) {
      this.installationCode = args.installationCode;
    }
    if (args.environmentCode !== undefined && args.environmentCode !== null) {
      this.environmentCode = args.environmentCode;
    }
    if (args.uri !== undefined && args.uri !== null) {
      this.uri = args.uri;
    }
  }
};
RegistryStatus.prototype = {};
RegistryStatus.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.companyCode = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.installationCode = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.environmentCode = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.uri = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RegistryStatus.prototype.write = function(output) {
  output.writeStructBegin('RegistryStatus');
  if (this.companyCode !== null && this.companyCode !== undefined) {
    output.writeFieldBegin('companyCode', Thrift.Type.STRING, 1);
    output.writeString(this.companyCode);
    output.writeFieldEnd();
  }
  if (this.installationCode !== null && this.installationCode !== undefined) {
    output.writeFieldBegin('installationCode', Thrift.Type.STRING, 2);
    output.writeString(this.installationCode);
    output.writeFieldEnd();
  }
  if (this.environmentCode !== null && this.environmentCode !== undefined) {
    output.writeFieldBegin('environmentCode', Thrift.Type.STRING, 3);
    output.writeString(this.environmentCode);
    output.writeFieldEnd();
  }
  if (this.uri !== null && this.uri !== undefined) {
    output.writeFieldBegin('uri', Thrift.Type.STRING, 4);
    output.writeString(this.uri);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Error = module.exports.Error = function(args) {
  this.code = null;
  this.description = null;
  this.hResult = null;
  this.source = null;
  this.targetSite = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.description !== undefined && args.description !== null) {
      this.description = args.description;
    }
    if (args.hResult !== undefined && args.hResult !== null) {
      this.hResult = args.hResult;
    }
    if (args.source !== undefined && args.source !== null) {
      this.source = args.source;
    }
    if (args.targetSite !== undefined && args.targetSite !== null) {
      this.targetSite = args.targetSite;
    }
  }
};
Error.prototype = {};
Error.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.description = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.hResult = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.source = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.targetSite = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Error.prototype.write = function(output) {
  output.writeStructBegin('Error');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.description !== null && this.description !== undefined) {
    output.writeFieldBegin('description', Thrift.Type.STRING, 2);
    output.writeString(this.description);
    output.writeFieldEnd();
  }
  if (this.hResult !== null && this.hResult !== undefined) {
    output.writeFieldBegin('hResult', Thrift.Type.STRING, 3);
    output.writeString(this.hResult);
    output.writeFieldEnd();
  }
  if (this.source !== null && this.source !== undefined) {
    output.writeFieldBegin('source', Thrift.Type.STRING, 4);
    output.writeString(this.source);
    output.writeFieldEnd();
  }
  if (this.targetSite !== null && this.targetSite !== undefined) {
    output.writeFieldBegin('targetSite', Thrift.Type.STRING, 6);
    output.writeString(this.targetSite);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var VerifyStatusRequest = module.exports.VerifyStatusRequest = function(args) {
  this.company = null;
  if (args) {
    if (args.company !== undefined && args.company !== null) {
      this.company = new ttypes.Company(args.company);
    }
  }
};
VerifyStatusRequest.prototype = {};
VerifyStatusRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.company = new ttypes.Company();
        this.company.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

VerifyStatusRequest.prototype.write = function(output) {
  output.writeStructBegin('VerifyStatusRequest');
  if (this.company !== null && this.company !== undefined) {
    output.writeFieldBegin('company', Thrift.Type.STRUCT, 1);
    this.company.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var VerifyStatusResponse = module.exports.VerifyStatusResponse = function(args) {
  this.status = null;
  this.registryStatus = null;
  this.error = null;
  if (args) {
    if (args.status !== undefined && args.status !== null) {
      this.status = new ttypes.Status(args.status);
    }
    if (args.registryStatus !== undefined && args.registryStatus !== null) {
      this.registryStatus = new ttypes.RegistryStatus(args.registryStatus);
    }
    if (args.error !== undefined && args.error !== null) {
      this.error = new ttypes.Error(args.error);
    }
  }
};
VerifyStatusResponse.prototype = {};
VerifyStatusResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.status = new ttypes.Status();
        this.status.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.registryStatus = new ttypes.RegistryStatus();
        this.registryStatus.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.error = new ttypes.Error();
        this.error.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

VerifyStatusResponse.prototype.write = function(output) {
  output.writeStructBegin('VerifyStatusResponse');
  if (this.status !== null && this.status !== undefined) {
    output.writeFieldBegin('status', Thrift.Type.STRUCT, 1);
    this.status.write(output);
    output.writeFieldEnd();
  }
  if (this.registryStatus !== null && this.registryStatus !== undefined) {
    output.writeFieldBegin('registryStatus', Thrift.Type.STRUCT, 2);
    this.registryStatus.write(output);
    output.writeFieldEnd();
  }
  if (this.error !== null && this.error !== undefined) {
    output.writeFieldBegin('error', Thrift.Type.STRUCT, 3);
    this.error.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Failure = module.exports.Failure = function(args) {
  this.code = null;
  this.message = null;
  this.severity = null;
  this.propertyName = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
    if (args.severity !== undefined && args.severity !== null) {
      this.severity = args.severity;
    }
    if (args.propertyName !== undefined && args.propertyName !== null) {
      this.propertyName = args.propertyName;
    }
  }
};
Failure.prototype = {};
Failure.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.severity = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.propertyName = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Failure.prototype.write = function(output) {
  output.writeStructBegin('Failure');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  if (this.severity !== null && this.severity !== undefined) {
    output.writeFieldBegin('severity', Thrift.Type.STRING, 3);
    output.writeString(this.severity);
    output.writeFieldEnd();
  }
  if (this.propertyName !== null && this.propertyName !== undefined) {
    output.writeFieldBegin('propertyName', Thrift.Type.STRING, 4);
    output.writeString(this.propertyName);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyPECRequest = module.exports.CompanyPECRequest = function(args) {
  this.companyCode = null;
  this.vatNumber = null;
  this.taxId = null;
  if (args) {
    if (args.companyCode !== undefined && args.companyCode !== null) {
      this.companyCode = args.companyCode;
    }
    if (args.vatNumber !== undefined && args.vatNumber !== null) {
      this.vatNumber = args.vatNumber;
    }
    if (args.taxId !== undefined && args.taxId !== null) {
      this.taxId = args.taxId;
    }
  }
};
CompanyPECRequest.prototype = {};
CompanyPECRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.companyCode = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.vatNumber = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.taxId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyPECRequest.prototype.write = function(output) {
  output.writeStructBegin('CompanyPECRequest');
  if (this.companyCode !== null && this.companyCode !== undefined) {
    output.writeFieldBegin('companyCode', Thrift.Type.STRING, 1);
    output.writeString(this.companyCode);
    output.writeFieldEnd();
  }
  if (this.vatNumber !== null && this.vatNumber !== undefined) {
    output.writeFieldBegin('vatNumber', Thrift.Type.STRING, 2);
    output.writeString(this.vatNumber);
    output.writeFieldEnd();
  }
  if (this.taxId !== null && this.taxId !== undefined) {
    output.writeFieldBegin('taxId', Thrift.Type.STRING, 3);
    output.writeString(this.taxId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CompanyPECResponse = module.exports.CompanyPECResponse = function(args) {
  this.status = null;
  this.error = null;
  this.validationFailures = null;
  this.vatNumber = null;
  this.taxId = null;
  this.pec = null;
  if (args) {
    if (args.status !== undefined && args.status !== null) {
      this.status = new ttypes.Status(args.status);
    }
    if (args.error !== undefined && args.error !== null) {
      this.error = new ttypes.Error(args.error);
    }
    if (args.validationFailures !== undefined && args.validationFailures !== null) {
      this.validationFailures = Thrift.copyList(args.validationFailures, [ttypes.Failure]);
    }
    if (args.vatNumber !== undefined && args.vatNumber !== null) {
      this.vatNumber = args.vatNumber;
    }
    if (args.taxId !== undefined && args.taxId !== null) {
      this.taxId = args.taxId;
    }
    if (args.pec !== undefined && args.pec !== null) {
      this.pec = args.pec;
    }
  }
};
CompanyPECResponse.prototype = {};
CompanyPECResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.status = new ttypes.Status();
        this.status.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.error = new ttypes.Error();
        this.error.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.validationFailures = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new ttypes.Failure();
          elem6.read(input);
          this.validationFailures.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.vatNumber = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.taxId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.pec = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CompanyPECResponse.prototype.write = function(output) {
  output.writeStructBegin('CompanyPECResponse');
  if (this.status !== null && this.status !== undefined) {
    output.writeFieldBegin('status', Thrift.Type.STRUCT, 1);
    this.status.write(output);
    output.writeFieldEnd();
  }
  if (this.error !== null && this.error !== undefined) {
    output.writeFieldBegin('error', Thrift.Type.STRUCT, 2);
    this.error.write(output);
    output.writeFieldEnd();
  }
  if (this.validationFailures !== null && this.validationFailures !== undefined) {
    output.writeFieldBegin('validationFailures', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRUCT, this.validationFailures.length);
    for (var iter7 in this.validationFailures)
    {
      if (this.validationFailures.hasOwnProperty(iter7))
      {
        iter7 = this.validationFailures[iter7];
        iter7.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.vatNumber !== null && this.vatNumber !== undefined) {
    output.writeFieldBegin('vatNumber', Thrift.Type.STRING, 4);
    output.writeString(this.vatNumber);
    output.writeFieldEnd();
  }
  if (this.taxId !== null && this.taxId !== undefined) {
    output.writeFieldBegin('taxId', Thrift.Type.STRING, 5);
    output.writeString(this.taxId);
    output.writeFieldEnd();
  }
  if (this.pec !== null && this.pec !== undefined) {
    output.writeFieldBegin('pec', Thrift.Type.STRING, 6);
    output.writeString(this.pec);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ttypes.VERSION = '1.1.0';
