import React from "react";
import PropTypes from "prop-types";

import { Button, Row, Col } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { Field, reduxForm } from "redux-form";

import { signerValidation } from "../../../../utils/signer-validations/fields-validation";
import * as normalizeField from "../../../../utils/normalize-form";
import FormInput from "../../../general/form-input";

function validate(values) {
    return signerValidation(values);
}

const FormAssignedSigner = ({ handleSubmit }) => {
    const { formatMessage } = useIntl();

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col span={23}>
                        <Field
                            name="signerFiscalCode"
                            component={FormInput}
                            label={formatMessage({
                                id: "general.codice-fiscale"
                            })}
                            normalize={normalizeField.normalizeFiscalCode}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={23}>
                        <Field
                            name="pin"
                            component={FormInput}
                            label={formatMessage({
                                id: "general.pin"
                            })}
                            type="password"
                        />
                    </Col>
                </Row>

                <Button htmlType="submit" style={{ marginLeft: "76%" }} type="primary">
                    <FormattedMessage id="general.send-request" />
                </Button>
            </form>
        </div>
    );
};

FormAssignedSigner.propTypes = {
    handleSubmit: PropTypes.func
};

export default reduxForm({
    form: "form-assigned-signer",
    validate
})(FormAssignedSigner);
