import React, { Component } from "react";
import PropTypes from "prop-types";
import { Searchbar as ArcSearchbar } from "@ts-digital/arc/antd";
import { compose } from "redux";
import { connect } from "react-redux";
import { getBillingItems } from "../../../../actions/metering/billing/items";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { Label } from "../../../../components/metering/billing/styled";

export class BasicSearchbar extends Component {
    static propTypes = {
        item: PropTypes.string.isRequired,
        userId: PropTypes.string.isRequired,
        getBillingItems: PropTypes.func.isRequired,
        onSearch: PropTypes.func,
        onError: PropTypes.func,
        items: PropTypes.array.isRequired
    };

    constructor(props) {
        super(props);
        this.state = { value: "", error: null };
        this.onSearch = this.onSearch.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const { getBillingItems, item, userId } = this.props;
        getBillingItems(item, userId);
    }

    onSearch({ datasourceKey, value }) {
        const { onSearch, onError } = this.props;
        if (!value) {
            this.setState({ error: null, value: "" });
        } else if (datasourceKey) {
            this.setState({ error: null, value });
            onSearch(datasourceKey);
        } else {
            onError();
            this.setState({
                error: <FormattedMessage id="metering.usage.invoicing.searchbar.error" />,
                value
            });
        }
    }

    onChange(value) {
        this.setState({ value });
    }

    onBlur() {
        const { items, onError } = this.props;
        const { value } = this.state;
        if (!value || items.find(item => item.value.toLowerCase() === value.toLowerCase())) {
            if (!value) {
                onError();
            }
            this.setState({ error: null });
        } else {
            onError();
            this.setState({
                error: <FormattedMessage id="metering.usage.invoicing.searchbar.error" />
            });
        }
    }

    render() {
        const { error, value } = this.state;
        const { items } = this.props;
        return items.length <= 1 ? null : (
            <>
                <Label>
                    <FormattedMessage id="metering.services.invoices.searchbar.label" />
                </Label>
                <ArcSearchbar
                    placeholder={<FormattedMessage id="metering.services.searchbar.placeholder" />}
                    onSearch={this.onSearch}
                    onBlur={this.onBlur}
                    value={value}
                    onChange={this.onChange}
                    datasource={items.map(item => {
                        item.label = item.value;
                        return item;
                    })}
                    error={error}
                />
            </>
        );
    }
}

const mapStateToProps = (state, props) => ({
    item: props.match.params.item,
    userId: state.user.user.profile.id,
    items: state.metering.billing.items.data
});

const enhanceComponent = compose(withRouter, connect(mapStateToProps, { getBillingItems }));

export const Searchbar = enhanceComponent(BasicSearchbar);
