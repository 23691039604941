import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { Button, Popover, Card } from "antd";

import AppLink from "../../general/app-link";
import FontawesomeIcon from "../../general/fontawesome-icon";
import "./style.css";

const AppCard = ({
    appId,
    appEnabled,
    category, // {}
    detailLink,
    display, // controllo per visualizzare la card
    isActive,
    isCompanyValidated
}) =>
    display && (
        <div className="c-app-card">
            <Card>
                <div className="content">
                    {!isActive || !appEnabled ? (
                        <Popover
                            content={
                                <FormattedMessage
                                    id={
                                        isCompanyValidated ? "general.service.not-active" : "general.company.not-active"
                                    }
                                />
                            }
                            placement="left"
                        >
                            <i
                                className="fas fa-info-circle"
                                style={{
                                    position: "absolute",
                                    right: "10px",
                                    marginTop: "-10px",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                    color: "#595959"
                                }}
                            />
                        </Popover>
                    ) : null}

                    <span className={"apps-icon"} style={{ background: category.color }}>
                        <FontawesomeIcon color="#FFF" name={category.icon} size="40px" solid={true} />
                    </span>

                    <div className="iconServiziCard">
                        <h3>
                            <FormattedMessage id={`c-services-view.${appId}.name`} />
                        </h3>
                        <FormattedMessage id={`apps.${appId}.quickdesc`} />
                    </div>
                    {detailLink && !isActive ? (
                        <Link to={detailLink}>
                            <Button type="primary">
                                <FormattedHTMLMessage id="general.discover" />
                            </Button>
                        </Link>
                    ) : (
                        <AppLink serviceId={appId}>
                            <Button disabled={!isActive || !appEnabled} type="primary">
                                <FormattedHTMLMessage id="general.accedi" />
                            </Button>
                        </AppLink>
                    )}
                </div>
            </Card>
        </div>
    );

AppCard.defaultProps = {
    category: {
        icon: "fas fa-exclamation-circle",
        color: "#F00"
    },
    detailLink: ""
};

AppCard.propTypes = {
    category: PropTypes.object.isRequired,
    detailLink: PropTypes.string,
    isActive: PropTypes.bool
};

export default AppCard;
