import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import { FormattedHTMLMessage } from "react-intl";

import { ErrorIcon } from "./styled";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

export const ContractWarningCell = ({
    row: {
        original: { status, toBeFixed, rejectedMsg, toBeFixedMsg }
    }
}) => {
    return (
        <>
            {status === "rejected" && (
                <Tooltip
                    title={
                        <>
                            <b>
                                <FormattedHTMLMessage id={"c-cct-provisioning.rejected"} />
                            </b>
                            <br />
                            <br />
                            {rejectedMsg}
                        </>
                    }
                >
                    <ErrorIcon icon={faExclamationCircle} error={"true"} />
                </Tooltip>
            )}
            {status !== "rejected" && toBeFixed && (
                <Tooltip
                    title={
                        <>
                            <b>
                                <FormattedHTMLMessage id={"general.attention"} />
                            </b>
                            <br />
                            <br />
                            <FormattedHTMLMessage
                                id={"c-cct-provisioning.rettifica"}
                                values={{
                                    errMsg: toBeFixedMsg !== undefined && toBeFixedMsg !== "" ? `: ${toBeFixedMsg}` : ""
                                }}
                            />
                        </>
                    }
                >
                    <ErrorIcon icon={faExclamationCircle} />
                </Tooltip>
            )}
        </>
    );
};

ContractWarningCell.propTypes = {
    /** Rdc contract status */
    status: PropTypes.string,
    /** Rdc contract to be fixed status */
    toBeFixed: PropTypes.bool,
    /** Error message for reject contract */
    rejectedMsg: PropTypes.string,
    /** Error message for toBeFixed contract */
    toBeFixedMsg: PropTypes.string
};
