import styled from "styled-components";

export const StyledPopOverTitle = styled.div`
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 16px;
`;

export const ModalTextContainer = styled.div`
    width: 500px;
    margin-top: 16px;
    margin-bottom: 16px;
`;

export const ModalButtonsContainer = styled.div`
    text-align: right;
    width: 100%;

    & > * {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 5px;
    }
`;

export const AddTextDiv = styled.div`
    white-space: nowrap;
`;
