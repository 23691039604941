import React from "react";

import { Container } from "./styled";

import Initials from "../../../general/user-initials";

export const UserNameCell = ({ cell: { value } }) => {
    return (
        <Container>
            <Initials size={"22px"} name={value} />
            {value}
        </Container>
    );
};
