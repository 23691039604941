import React, { useEffect } from "react";
import useElevioControls from "../../../hooks/use-elevio-controls";
import PortaleChildApp from "../../portale-child-app";

const AntiMoneyLaunderingView = () => {
    const { hideWidget } = useElevioControls();

    useEffect(() => {
        hideWidget();
    }, [hideWidget]);

    return <PortaleChildApp appId="AML" serviceId="TS420" />;
};

export default AntiMoneyLaunderingView;
