import React from "react";
import PropTypes from "prop-types";

import { Field } from "redux-form";
import { field } from "@ts-digital/conv-redux-form";
import { FormattedMessage } from "react-intl";

import { StyledSwitch, SwitchContainer } from "../styled";

export const WrappedCheckbox = ({ input, disabled }) => (
    <SwitchContainer>
        <StyledSwitch
            onChange={evt => {
                input.onChange(evt);
            }}
            disabled={disabled}
        />
        <span style={{ marginLeft: 8 }}>
            <FormattedMessage id="general.pec" />
        </span>
    </SwitchContainer>
);

const PecCheck = props => {
    return <Field {...props} component={WrappedCheckbox} />;
};

PecCheck.propTypes = { name: PropTypes.string.isRequired, disabled: PropTypes.bool };

export default field({ type: "boolean" })(PecCheck);
