import React, { useState } from "react";
import PropTypes from "prop-types";

import { FormattedMessage, useIntl } from "react-intl";

import ISO8601DayField from "@ts-digital/arc/antd/ISO8601DayField";
import ItalianProvinceField from "@ts-digital/arc/antd/ItalianProvinceField";
import TextField from "@ts-digital/arc/antd/TextField";
import { SelectStringField } from "@ts-digital/arc/antd/SelectField";

import { ENABLE_ATECO_CODE } from "../../../../config";
import { legalForms, liquidationState, soleShareholder } from "../../../../utils/constant";
import { normalizeEconomicActivity } from "../../../../utils/normalize-form";

import { AdditionalDataTitle, CenterColumn, IconContainer, LeftColumn, RightColumn, CustomColumn } from "./styled";
import MonthDaySelect from "../month-day-select";

const DataAdditional = ({ disabled }) => {
    const [open, setOpen] = useState(false);
    const intl = useIntl();

    return (
        <>
            <AdditionalDataTitle onClick={() => setOpen(!open)} open={open}>
                <IconContainer type={open ? "down" : "right"} />
                <FormattedMessage id="general.additionalData" />
            </AdditionalDataTitle>
            <LeftColumn open={open}>
                <ItalianProvinceField
                    allowClear={true}
                    disabled={disabled}
                    label={<FormattedMessage id="general.cciaa" />}
                    name="cciaa"
                    showSearch={true}
                />
            </LeftColumn>
            <CenterColumn open={open}>
                <TextField
                    disabled={disabled}
                    name={"rea"}
                    label={<FormattedMessage id="general.rea" />}
                    size="default"
                />
            </CenterColumn>
            <RightColumn open={open}>
                <TextField
                    disabled={disabled}
                    name={"capitalStock"}
                    label={<FormattedMessage id="general.capitalStock" />}
                    size="default"
                />
            </RightColumn>
            <LeftColumn open={open}>
                <SelectStringField
                    allowClear={true}
                    disabled={disabled}
                    getOptionKeyFromValue={value => {
                        return value === "" ? undefined : value;
                    }}
                    label={<FormattedMessage id="general.liquidationState" />}
                    name="liquidationState"
                    options={liquidationState.map(p => {
                        return {
                            value: p.id,
                            label: p.name,
                            searchMatcherString: p.name
                        };
                    })}
                    showSearch={true}
                />
            </LeftColumn>
            <CenterColumn open={open}>
                <SelectStringField
                    allowClear={true}
                    disabled={disabled}
                    getOptionKeyFromValue={value => {
                        return value === "" ? undefined : value;
                    }}
                    label={<FormattedMessage id="general.soleShareholder" />}
                    name="soleShareholder"
                    options={soleShareholder.map(p => {
                        return {
                            value: p.id,
                            label: p.name,
                            searchMatcherString: p.name
                        };
                    })}
                />
            </CenterColumn>
            <RightColumn open={open && ENABLE_ATECO_CODE}>
                <TextField
                    disabled={disabled}
                    name={"economicActivity"}
                    label={<FormattedMessage id="general.economicActivity" />}
                    size="default"
                    placeholder={intl.formatMessage({ id: "c-edit-item.form.placeholder.economicActivity" })}
                    normalize={normalizeEconomicActivity}
                />
            </RightColumn>
            <LeftColumn open={open}>
                <TextField
                    disabled={disabled}
                    name={"registerDescription"}
                    label={<FormattedMessage id="general.registerDescription" />}
                    size="default"
                />
            </LeftColumn>
            <CenterColumn open={open}>
                <TextField
                    disabled={disabled}
                    name={"registerCode"}
                    label={<FormattedMessage id="general.registerCode" />}
                    size="default"
                />
            </CenterColumn>
            <RightColumn open={open}>
                <ItalianProvinceField
                    allowClear={true}
                    disabled={disabled}
                    name={"registerProvince"}
                    label={<FormattedMessage id="general.registerProvince" />}
                    showSearch={true}
                />
            </RightColumn>
            <LeftColumn open={open}>
                <ISO8601DayField
                    disabled={disabled}
                    displayFormat={"DD/MM/YYYY"}
                    name={"registerRegistrationDate"}
                    label={<FormattedMessage id="general.registerRegistrationDate" />}
                    size="default"
                />
            </LeftColumn>
            <CustomColumn open={open} gridColumnStart={3} gridColumnEnd={5}>
                <MonthDaySelect
                    disabled={disabled}
                    name="balanceSheetDate"
                    label={<FormattedMessage id="c-cct-provisioning-download-pdf-form.fields.balanceSheetDate" />}
                />
            </CustomColumn>
            <LeftColumn open={open}>
                <SelectStringField
                    allowClear={true}
                    disabled={disabled}
                    getOptionKeyFromValue={value => {
                        return value === "" ? undefined : value;
                    }}
                    label={<FormattedMessage id="general.legalForm" />}
                    name="legalForm"
                    options={legalForms.map(legalFormCode => {
                        const description = intl.formatMessage({ id: `item.legalForm.${legalFormCode}` });
                        return {
                            value: legalFormCode,
                            label: `${legalFormCode} - ${description}`,
                            searchMatcherString: `${legalFormCode} - ${description}`
                        };
                    })}
                    showSearch={true}
                />
            </LeftColumn>
        </>
    );
};

DataAdditional.propTypes = {
    disabled: PropTypes.bool
};

export default DataAdditional;
