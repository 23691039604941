import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { compose } from "redux";
import { connect } from "react-redux";
import { getBillingUsageDetails } from "../../../../../actions/metering/billing/details";
import { NestedTable, FooterContainer } from "./styled";
import { Button } from "@ts-digital/arc/antd";
import { Pagination } from "antd";
import { getBillingUsageDetailsCsv } from "../../../../../actions/metering/billing/details/csv";
import { withRouter } from "react-router-dom";
import { useState } from "react";
import { useCallback } from "react";
import moment from "moment";

const detailsColumns = [
    {
        title: <FormattedMessage id="metering.billing.table.details.invoiceNumber" />,
        dataIndex: "invoiceNumber",
        key: "invoiceNumber",
        align: "center",
        sorter: true
    },
    {
        title: <FormattedMessage id="metering.billing.table.details.date" />,
        dataIndex: "timestamp",
        key: "timestamp",
        align: "center",
        sorter: true,
        render: value => `${moment.utc(value).format("DD/MM/YYYY - HH:mm")}`
    },
    {
        title: <FormattedMessage id="metering.billing.table.details.type" />,
        dataIndex: "sent",
        key: "sent",
        align: "center",
        sorter: true,
        render: value => <FormattedMessage id={`metering.billing.table.details.type.${value ? "sent" : "received"}`} />
    },
    {
        title: <FormattedMessage id="metering.billing.table.details.documentId" />,
        dataIndex: "hubId",
        key: "hubId",
        align: "center"
    },
    {
        title: <FormattedMessage id="metering.billing.table.details.flow" />,
        dataIndex: "flow",
        key: "flow",
        align: "center",
        render: value =>
            value === "SELFSEND" ? <FormattedMessage id="metering.billing.table.details.flow.selfsend" /> : value
    }
];

export const BasicDetails = ({
    details,
    loading,
    from,
    to,
    userId,
    item,
    managed,
    getBillingUsageDetails,
    viewOldBillingCycles,
    getBillingUsageDetailsCsv,
    size
}) => {
    const [sortBy, setSortBy] = useState({
        key: "timestamp",
        order: "desc"
    });
    const [page, setPage] = useState(0);

    useEffect(() => {
        getBillingUsageDetails(userId, item, managed, 10, page, from, to, sortBy, viewOldBillingCycles);
    }, [from, getBillingUsageDetails, item, managed, page, sortBy, to, userId, viewOldBillingCycles]);

    const handlePageChange = useCallback((newPage, itemsPerPage) => {
        setPage(newPage - 1);
    }, []);

    const handleTableChange = useCallback(
        (pagination, filters, sorter) => {
            const { columnKey, order } = sorter;
            if (sortBy && sortBy.key === columnKey && sortBy.order === order) {
                return;
            }
            setSortBy(columnKey && order ? { key: columnKey, order } : { key: "", order: "" });
        },
        [sortBy]
    );

    const handleCsvDownload = useCallback(() => {
        getBillingUsageDetailsCsv(userId, item, managed, from, to, viewOldBillingCycles);
    }, [from, getBillingUsageDetailsCsv, item, managed, to, userId, viewOldBillingCycles]);

    const renderFooter = useCallback(
        () => (
            <FooterContainer>
                <Button variant="secondary" onClick={handleCsvDownload}>
                    <FormattedMessage id="metering.billing.history.details.csv.download" />
                </Button>
                <Pagination pageSize={10} total={size} onChange={handlePageChange} />
            </FooterContainer>
        ),
        [handleCsvDownload, handlePageChange, size]
    );

    return (
        <NestedTable
            columns={detailsColumns}
            dataSource={details}
            loading={loading}
            onChange={handleTableChange}
            footer={renderFooter}
            pagination={false}
        />
    );
};

BasicDetails.propTypes = {
    details: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    item: PropTypes.string.isRequired,
    managed: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    getBillingUsageDetails: PropTypes.func.isRequired,
    getBillingUsageDetailsCsv: PropTypes.func.isRequired,
    viewOldBillingCycles: PropTypes.bool.isRequired
};

const mapStateToProps = (state, props) => {
    const itemData = state.metering.billing.details[props.managed] || {};
    return {
        details: itemData.details || [],
        size: itemData.size || 0,
        loading: (itemData.status && itemData.status.loading) || false
    };
};

const enhanceComponent = compose(
    withRouter,
    connect(mapStateToProps, { getBillingUsageDetails, getBillingUsageDetailsCsv })
);

export const Details = enhanceComponent(BasicDetails);
