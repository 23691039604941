import styled, { keyframes } from "styled-components";

export const slideOut = () => keyframes`
    from {
        transform: translateX(0px);
    }
    to {
        transform: translateX(100%);
    }
`;

export const slideIn = () => keyframes`
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0px);
    }
`;

export const DrawerContainer = styled.div`
    top: 64px;
    right: 0;

    position: absolute;
    height: calc(100vh - 64px);

    visibility: ${props => (!props.isOpen ? "hidden" : "visible")};
`;

export const Container = styled.div`
    position: relative;
    width: 600px;
    height: calc(100vh - 64px);
    background-color: white;
    box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.5);
    animation: ${props => (props.isOpen ? slideIn() : slideOut())} 1s both;
    visibility: ${props => (!props.isOpen ? "hidden" : "visible")};
    transition: visibility 1s;
`;

export const Header = styled.div`
    height: 48px;
    box-sizing: border-box;
    box-shadow: inset 0px -4px 0px 0px rgb(0, 144, 209);
    display: flex;
    align-items: center;
`;

export const HeaderWrapperContainer = styled.div`
    flex: 1;
    text-align: center;
`;

export const HeaderTitle = styled.div`
    color: rgb(0, 144, 209);
    ${props => (props.newComponent ? "font-size: 16px" : "font-size: 14px")}
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0px;
    ${props => (props.newComponent ? "line-height: 20px" : "")}
`;

export const HeaderSubTitle = styled.div`
    color: rgb(90, 104, 114);
    font-family: Roboto;
    ${props => (props.newComponent ? "font-size: 12px" : "font-size: 10px")}
    font-weight: normal;
    height: 16px;
    letter-spacing: 0.33px;
    line-height: 16px;
`;

export const CloseButtonContainer = styled.div`
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const Content = styled.div`
    ${props =>
        !props.newComponent
            ? `
                padding-top: 24px;
                padding-right: 24px;
                padding-left: 24px;
        `
            : ""}

    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 136px);
`;

export const Overlay = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: ${props => (!props.isOpen ? "hidden" : "visible")};
    z-index: 2;
`;
