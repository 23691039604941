import { callWithRetry, getAuthWithAppName } from "../auth";
import { A_FISCALI_IVA_API_URL } from "../../config";

import aFiscaliIvaConfig from "../../assets/thrift/a-fiscali-iva/AFiscaliIvaFlowConfig";
import aFiscaliIvaTypes from "../../assets/thrift/a-fiscali-iva/a-fiscali-iva-flow-config_types";

import thrift from "browser-thrift";

const aFiscaliIvaUrl = new URL(A_FISCALI_IVA_API_URL);
const aFiscaliIvaClient = thrift.createXHRClient(
    aFiscaliIvaConfig,
    thrift.createXHRConnection(aFiscaliIvaUrl.hostname, 443, {
        useCors: true,
        path: aFiscaliIvaUrl.pathname,
        https: aFiscaliIvaUrl.protocol === "https:"
    })
);

export const ADEMPIMENTI_FISCALI_CONFIG_START = "ADEMPIMENTI_FISCALI_CONFIG_START";
export const ADEMPIMENTI_FISCALI_CONFIG_ERROR = "ADEMPIMENTI_FISCALI_CONFIG_ERROR";
export const ADEMPIMENTI_FISCALI_CONFIG_SUCCESS = "ADEMPIMENTI_FISCALI_CONFIG_SUCCESS";
export const ADEMPIMENTI_FISCALI_CONFIG_RESET = "ADEMPIMENTI_FISCALI_CONFIG_RESET";

export function getAdempimentiConfig(fiscalCode) {
    return (dispatch, getState) => {
        dispatch({
            type: ADEMPIMENTI_FISCALI_CONFIG_START
        });

        const request = new aFiscaliIvaTypes.AFiscaliIvaData({
            id: fiscalCode
        });
        const codes = ["401"];

        const auth = getAuthWithAppName(getState().auth);
        callWithRetry(
            aFiscaliIvaClient,
            aFiscaliIvaClient.getConfig,
            auth,
            request,
            auth.refreshToken,
            dispatch,
            (error, response) => {
                if (error !== null) {
                    dispatch({
                        type: ADEMPIMENTI_FISCALI_CONFIG_ERROR,
                        error: error
                    });
                } else {
                    dispatch({
                        type: ADEMPIMENTI_FISCALI_CONFIG_SUCCESS,
                        payload: response
                    });
                }
            },
            codes
        );
    };
}

export function resetAdempimentiConfig() {
    return {
        type: ADEMPIMENTI_FISCALI_CONFIG_RESET
    };
}
