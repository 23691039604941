import { API_URL } from "../../../config";
import { getAuthWithAppName } from "../../auth";

import { getRestCall } from "../../../utils/rest-api-call";
import { getUserProfile } from "../../../utils/state-utils/get-user-profile";

export const DOWNLOAD_PROVISIONING_DATA_START = "DOWNLOAD_PROVISIONING_DATA_START";
export const DOWNLOAD_PROVISIONING_DATA_SUCCESS = "DOWNLOAD_PROVISIONING_DATA_SUCCESS";
export const DOWNLOAD_PROVISIONING_DATA_ERROR = "DOWNLOAD_PROVISIONING_DATA_ERROR";
export const DOWNLOAD_PROVISIONING_DATA_RESET = "DOWNLOAD_PROVISIONING_DATA_RESET";

export function downloadProvisioningData(managerId) {
    return async (dispatch, getState) => {
        dispatch({ type: DOWNLOAD_PROVISIONING_DATA_START });
        const extractedAuth = getAuthWithAppName(getState().auth);
        try {
            const result = await getRestCall(
                `${API_URL}/services/cct/${managerId}/provisioning/csv`,
                extractedAuth,
                {},
                dispatch,
                extractedAuth.refreshToken,
                getUserProfile(getState()),
                managerId
            );

            dispatch({
                type: DOWNLOAD_PROVISIONING_DATA_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: DOWNLOAD_PROVISIONING_DATA_ERROR,
                error: e
            });
        }
    };
}
export function resetDownloadProvisioningData() {
    return dispatch => {
        dispatch({
            type: DOWNLOAD_PROVISIONING_DATA_RESET
        });
    };
}
