import {
    DOWNLOAD_FILLED_DOCUMENT_START,
    DOWNLOAD_FILLED_DOCUMENT_SUCCESS,
    DOWNLOAD_FILLED_DOCUMENT_ERROR,
    GET_SIGNER_DATA_START,
    GET_SIGNER_DATA_SUCCESS,
    GET_SIGNER_DATA_ERROR,
    LIST_SIGNERS_START,
    LIST_SIGNERS_SUCCESS,
    LIST_SIGNERS_ERROR
} from "../actions/sign-api-read.js";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function downloadFilledDocument(state = defaultState, { type, payload }) {
    switch (type) {
        case DOWNLOAD_FILLED_DOCUMENT_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case DOWNLOAD_FILLED_DOCUMENT_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case DOWNLOAD_FILLED_DOCUMENT_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: payload.code, message: payload.message }
                }
            };
        default:
            return state;
    }
}

export function getSignerData(state = defaultState, { type, payload }) {
    switch (type) {
        case GET_SIGNER_DATA_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_SIGNER_DATA_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_SIGNER_DATA_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: payload.code, message: payload.message }
                }
            };
        default:
            return state;
    }
}

export function listSigners(state = defaultState, { type, payload, error }) {
    switch (type) {
        case LIST_SIGNERS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case LIST_SIGNERS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case LIST_SIGNERS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
