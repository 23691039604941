/* eslint-disable */
//
// Autogenerated by Thrift Compiler (0.11.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
"use strict";

var thrift = require('browser-thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = require('./agyo-contract-api_types');
//HELPER FUNCTIONS AND STRUCTURES

var AgyoContractApi_verifyStatus_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.VerifyStatusRequest(args.request);
    }
  }
};
AgyoContractApi_verifyStatus_args.prototype = {};
AgyoContractApi_verifyStatus_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.VerifyStatusRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AgyoContractApi_verifyStatus_args.prototype.write = function(output) {
  output.writeStructBegin('AgyoContractApi_verifyStatus_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AgyoContractApi_verifyStatus_result = function(args) {
  this.success = null;
  this.contractException = null;
  this.authenticationException = null;
  this.genericError = null;
  if (args instanceof ttypes.ContractException) {
    this.contractException = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.VerifyStatusResponse(args.success);
    }
    if (args.contractException !== undefined && args.contractException !== null) {
      this.contractException = args.contractException;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
  }
};
AgyoContractApi_verifyStatus_result.prototype = {};
AgyoContractApi_verifyStatus_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.VerifyStatusResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.contractException = new ttypes.ContractException();
        this.contractException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AgyoContractApi_verifyStatus_result.prototype.write = function(output) {
  output.writeStructBegin('AgyoContractApi_verifyStatus_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.contractException !== null && this.contractException !== undefined) {
    output.writeFieldBegin('contractException', Thrift.Type.STRUCT, 1);
    this.contractException.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 3);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AgyoContractApi_getCompanyPEC_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.CompanyPECRequest(args.request);
    }
  }
};
AgyoContractApi_getCompanyPEC_args.prototype = {};
AgyoContractApi_getCompanyPEC_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.CompanyPECRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AgyoContractApi_getCompanyPEC_args.prototype.write = function(output) {
  output.writeStructBegin('AgyoContractApi_getCompanyPEC_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AgyoContractApi_getCompanyPEC_result = function(args) {
  this.success = null;
  this.contractException = null;
  this.authenticationException = null;
  this.genericError = null;
  if (args instanceof ttypes.ContractException) {
    this.contractException = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.CompanyPECResponse(args.success);
    }
    if (args.contractException !== undefined && args.contractException !== null) {
      this.contractException = args.contractException;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
  }
};
AgyoContractApi_getCompanyPEC_result.prototype = {};
AgyoContractApi_getCompanyPEC_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.CompanyPECResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.contractException = new ttypes.ContractException();
        this.contractException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AgyoContractApi_getCompanyPEC_result.prototype.write = function(output) {
  output.writeStructBegin('AgyoContractApi_getCompanyPEC_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.contractException !== null && this.contractException !== undefined) {
    output.writeFieldBegin('contractException', Thrift.Type.STRUCT, 1);
    this.contractException.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 3);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AgyoContractApi_health_args = function(args) {
};
AgyoContractApi_health_args.prototype = {};
AgyoContractApi_health_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AgyoContractApi_health_args.prototype.write = function(output) {
  output.writeStructBegin('AgyoContractApi_health_args');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AgyoContractApi_health_result = function(args) {
  this.success = null;
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
  }
};
AgyoContractApi_health_result.prototype = {};
AgyoContractApi_health_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRING) {
        this.success = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AgyoContractApi_health_result.prototype.write = function(output) {
  output.writeStructBegin('AgyoContractApi_health_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRING, 0);
    output.writeString(this.success);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AgyoContractApiClient = exports.Client = function(output, pClass) {
    this.output = output;
    this.pClass = pClass;
    this._seqid = 0;
    this._reqs = {};
};
AgyoContractApiClient.prototype = {};
AgyoContractApiClient.prototype.seqid = function() { return this._seqid; };
AgyoContractApiClient.prototype.new_seqid = function() { return this._seqid += 1; };
AgyoContractApiClient.prototype.verifyStatus = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_verifyStatus(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_verifyStatus(auth, request);
  }
};

AgyoContractApiClient.prototype.send_verifyStatus = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('verifyStatus', Thrift.MessageType.CALL, this.seqid());
  var params = {
    auth: auth,
    request: request
  };
  var args = new AgyoContractApi_verifyStatus_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

AgyoContractApiClient.prototype.recv_verifyStatus = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AgyoContractApi_verifyStatus_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.contractException) {
    return callback(result.contractException);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('verifyStatus failed: unknown result');
};
AgyoContractApiClient.prototype.getCompanyPEC = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_getCompanyPEC(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_getCompanyPEC(auth, request);
  }
};

AgyoContractApiClient.prototype.send_getCompanyPEC = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('getCompanyPEC', Thrift.MessageType.CALL, this.seqid());
  var params = {
    auth: auth,
    request: request
  };
  var args = new AgyoContractApi_getCompanyPEC_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

AgyoContractApiClient.prototype.recv_getCompanyPEC = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AgyoContractApi_getCompanyPEC_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.contractException) {
    return callback(result.contractException);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('getCompanyPEC failed: unknown result');
};
AgyoContractApiClient.prototype.health = function(callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_health();
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_health();
  }
};

AgyoContractApiClient.prototype.send_health = function() {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('health', Thrift.MessageType.CALL, this.seqid());
  var args = new AgyoContractApi_health_args();
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

AgyoContractApiClient.prototype.recv_health = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AgyoContractApi_health_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('health failed: unknown result');
};
var AgyoContractApiProcessor = exports.Processor = function(handler) {
  this._handler = handler;
}
;
AgyoContractApiProcessor.prototype.process = function(input, output) {
  var r = input.readMessageBegin();
  if (this['process_' + r.fname]) {
    return this['process_' + r.fname].call(this, r.rseqid, input, output);
  } else {
    input.skip(Thrift.Type.STRUCT);
    input.readMessageEnd();
    var x = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN_METHOD, 'Unknown function ' + r.fname);
    output.writeMessageBegin(r.fname, Thrift.MessageType.EXCEPTION, r.rseqid);
    x.write(output);
    output.writeMessageEnd();
    output.flush();
  }
}
;
AgyoContractApiProcessor.prototype.process_verifyStatus = function(seqid, input, output) {
  var args = new AgyoContractApi_verifyStatus_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.verifyStatus.length === 2) {
    Q.fcall(this._handler.verifyStatus.bind(this._handler), args.auth, args.request)
      .then(function(result) {
        var result_obj = new AgyoContractApi_verifyStatus_result({success: result});
        output.writeMessageBegin("verifyStatus", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.ContractException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
          result = new AgyoContractApi_verifyStatus_result(err);
          output.writeMessageBegin("verifyStatus", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("verifyStatus", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.verifyStatus(args.auth, args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.ContractException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
        result_obj = new AgyoContractApi_verifyStatus_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("verifyStatus", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("verifyStatus", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AgyoContractApiProcessor.prototype.process_getCompanyPEC = function(seqid, input, output) {
  var args = new AgyoContractApi_getCompanyPEC_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.getCompanyPEC.length === 2) {
    Q.fcall(this._handler.getCompanyPEC.bind(this._handler), args.auth, args.request)
      .then(function(result) {
        var result_obj = new AgyoContractApi_getCompanyPEC_result({success: result});
        output.writeMessageBegin("getCompanyPEC", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.ContractException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
          result = new AgyoContractApi_getCompanyPEC_result(err);
          output.writeMessageBegin("getCompanyPEC", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("getCompanyPEC", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.getCompanyPEC(args.auth, args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.ContractException || err instanceof ttypes.AuthenticationException || err instanceof ttypes.GenericError) {
        result_obj = new AgyoContractApi_getCompanyPEC_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("getCompanyPEC", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("getCompanyPEC", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AgyoContractApiProcessor.prototype.process_health = function(seqid, input, output) {
  var args = new AgyoContractApi_health_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.health.length === 0) {
    Q.fcall(this._handler.health.bind(this._handler))
      .then(function(result) {
        var result_obj = new AgyoContractApi_health_result({success: result});
        output.writeMessageBegin("health", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("health", Thrift.MessageType.EXCEPTION, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.health(function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined')) {
        result_obj = new AgyoContractApi_health_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("health", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("health", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
