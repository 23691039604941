import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFeature } from "../../../hooks/use-feature";
import "./style.css";

import { Icon, Pagination, Spin } from "antd";

import BottomMenu from "../../side-menu/bottom-menu";

import { FormattedMessage, useIntl } from "react-intl";
import { CardContainer, FooterDiv, PrivacyMenuContainer } from "./styled";

import { getTags } from "../../../utils/releasenotes";
import ItemCard from "./item-card";
import { Chip } from "../../storage/cct/provisioning/configure-managed-items/cct-filters/styled";

const Company = ({
    changePage,
    companies,
    getCompanies,
    getStudioCompanies,
    searchText,
    isUserWithTooManyItems,
    user,
    getLastReleaseNoteDate,
    lastReleaseNoteDate,
    listUsers,
    storePage
}) => {
    const intl = useIntl();

    useEffect(() => {
        getLastReleaseNoteDate(getTags(null));
    }, [getLastReleaseNoteDate]);

    useEffect(() => {
        if (
            !companies.homePage.searchText &&
            (companies.pagination[0] === undefined || companies.pagination[0].content.length === 0)
        ) {
            getStudioCompanies(null, 0, 30);
        }
    }, [companies.pagination, companies.homePage.searchText, getStudioCompanies, isUserWithTooManyItems]);

    useEffect(() => {
        if (
            !isUserWithTooManyItems &&
            (companies.pagination[0] === undefined || companies.pagination[0].content.length === 0) &&
            !companies.homePage.searchText
        ) {
            getCompanies(null, 0, 30);
            storePage(30, 0, "");
        }
    }, [companies.pagination, companies.homePage.searchText, getCompanies, isUserWithTooManyItems, storePage]);

    useEffect(() => {
        if (
            companies.homePage.currentPage !== companies.pagination.currentPage ||
            companies.homePage.pageSize !== companies.pagination.size
        ) {
            getCompanies(companies.homePage.searchText, companies.homePage.currentPage, companies.homePage.pageSize);
            storePage(companies.homePage.pageSize, companies.homePage.currentPage, companies.homePage.searchText);
        }
    }, [
        companies.homePage.currentPage,
        companies.homePage.pageSize,
        companies.homePage.searchText,
        companies.pagination.currentPage,
        companies.pagination.size,
        getCompanies,
        storePage
    ]);

    let companiesData = [];
    const studioItems = Object.values(companies.dataStudio.items).sort((a, b) =>
        a.item.base.details.description.toLowerCase().localeCompare(b.item.base.details.description.toLowerCase())
    );
    const companiesDataStudio = studioItems.map(value => (
        <ItemCard item={value.item} key={value.item.base.id} listUsers={listUsers} user={user} users={value.users} />
    ));

    if (companies.pagination[companies.pagination.currentPage]) {
        // The filter ensures that if a company appears more than once in the data array, it is only rendered once
        Object.values(companies.pagination[companies.pagination.currentPage].content).forEach(value => {
            companiesData.push(
                <ItemCard
                    item={companies.data[value].item}
                    key={companies.data[value].item.base.id}
                    listUsers={listUsers}
                    user={user}
                    users={companies.data[value].users}
                />
            );
        });
    }

    const showTotal = (total, range) => {
        let start = range[0];
        let end = range[1];

        return intl.formatMessage({ id: "c-manage-data.company.page.companyCount" }, { start, end, total });
    };

    const totalItems = [
        ...new Set(
            user.roles
                .filter(role => {
                    return role.resourceId !== "REGISTERED";
                })
                .map(role => role.resourceId)
        )
    ].length;

    const [showStudio, setShowStudio] = useState(false);
    const [selected, setSelected] = useState(true);

    const handleShowStudio = () => {
        setShowStudio(true);
        setSelected(false);
    };

    const handleShowAllCompanies = () => {
        setShowStudio(false);
        setSelected(true);
    };

    if (!companies.homePage.searchText && isUserWithTooManyItems) {
        companiesData = [];
    }

    const studioListView = useFeature("STUDIOLIST_VIEW");

    return (
        <div className="row-responsive">
            <div className="c-search-response">
                <span className="c-search-title">
                    {!isUserWithTooManyItems ? (
                        <div>
                            <FormattedMessage id="c-manage-data.company.search.title" />
                            <br />
                            <FormattedMessage
                                id="c-manage-data.company.search.subtitle"
                                values={{
                                    icon: <Icon type="right-circle" />
                                }}
                            />
                        </div>
                    ) : (
                        <div>
                            <FormattedMessage
                                id="c-manage-data.company.search.isUserWithTooManyItems.title"
                                values={{
                                    totalItems: <b>{totalItems}</b>
                                }}
                            />
                            <br />
                            <FormattedMessage
                                id="c-manage-data.company.search.subtitle"
                                values={{
                                    icon: <Icon type="right-circle" />
                                }}
                            />
                            {!companies.homePage.searchText &&
                                companies.dataStudio.totalStudio > 0 &&
                                studioListView && (
                                    <div>
                                        <FormattedMessage
                                            id={
                                                companies.dataStudio.totalStudio > 1
                                                    ? "c-manage-data.getStudioTotal"
                                                    : "c-manage-data.getOneStudio"
                                            }
                                            values={
                                                companies.dataStudio.totalStudio > 1
                                                    ? {
                                                          totalStudio: <b> {companies.dataStudio.totalStudio}</b>
                                                      }
                                                    : null
                                            }
                                        />
                                        <div className="container-aziende">
                                            <CardContainer>{companiesDataStudio}</CardContainer>
                                        </div>
                                    </div>
                                )}
                        </div>
                    )}
                </span>
            </div>

            <div className="container-aziende">
                {!isUserWithTooManyItems &&
                    companies.dataStudio.totalStudio > 0 &&
                    !companies.homePage.searchText &&
                    studioListView && (
                        <div className="chip-show">
                            <Chip onClick={handleShowAllCompanies} selected={selected === true}>
                                <FormattedMessage id="c-manage-data.totalItems-button-text" />
                            </Chip>
                            <Chip onClick={handleShowStudio} selected={selected === false}>
                                <FormattedMessage id="c-manage-data.onlyStudio-button-text" />
                            </Chip>
                        </div>
                    )}
                {companies.total.status.started ? (
                    <div>
                        <Spin size="large" />
                    </div>
                ) : Number(companies.total.totalItems) === 0 && searchText ? (
                    <div className="c-search-notfound">
                        <FormattedMessage
                            id="c-manage-data.company.search.notfound"
                            values={{
                                searchText: searchText
                            }}
                        />
                    </div>
                ) : (
                    <div>
                        {showStudio && !companies.homePage.searchText && studioListView ? (
                            <CardContainer>{companiesDataStudio}</CardContainer>
                        ) : (
                            <CardContainer>{companiesData}</CardContainer>
                        )}
                    </div>
                )}
            </div>
            <FooterDiv>
                <PrivacyMenuContainer>
                    <BottomMenu isOpen={true} lastReleaseNoteDate={lastReleaseNoteDate} />
                </PrivacyMenuContainer>
                {!isUserWithTooManyItems && !showStudio ? (
                    <Pagination
                        defaultCurrent={1}
                        current={companies.pagination.currentPage + 1}
                        total={companies.pagination.total}
                        style={{ marginTop: "10px" }}
                        showTotal={showTotal}
                        defaultPageSize={30}
                        pageSize={companies.pagination.size}
                        showSizeChanger={true}
                        pageSizeOptions={["30", "60", "120"]}
                        onShowSizeChange={(current, size) => {
                            // aggiorno con la pagesize nuova
                            getCompanies(searchText, 0, size);
                            storePage(size, 0, searchText);
                        }}
                        onChange={(page, pageSize) => {
                            // alla paginazione tolgo uno alle pagine perchè ma la myitems ha pagine con indice zero
                            if (
                                companies.pagination[page - 1] === undefined ||
                                companies.pagination[page - 1].content.length === 0
                            ) {
                                getCompanies(searchText, page - 1, pageSize);
                            } else {
                                changePage(page - 1);
                            }
                            storePage(pageSize, page - 1, searchText);
                        }}
                    />
                ) : (
                    ""
                )}
            </FooterDiv>
        </div>
    );
};

Company.propTypes = {
    changePage: PropTypes.func.isRequired,
    companies: PropTypes.object.isRequired,
    getCompanies: PropTypes.func.isRequired,
    getStudioCompanies: PropTypes.func.isRequired,
    searchText: PropTypes.string.isRequired,
    isUserWithTooManyItems: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    getLastReleaseNoteDate: PropTypes.func.isRequired,
    lastReleaseNoteDate: PropTypes.object,
    listUsers: PropTypes.func.isRequired
};

export default Company;
