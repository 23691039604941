import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

import { Field } from "redux-form";
import { FormTitle, DataContainer, InputContainer } from "../styled";

import LoadingButton from "../../../../../components/general/loading-button";
import TextInput from "../../../../../components/general/form-text-input";

import { form } from "@ts-digital/conv-redux-form";

import { userDataForm } from "../../../../../utils/form-schema/user-data";

const UserDataForm = ({ disabled, handleSubmit, loading }) => {
    const { formatMessage } = useIntl();

    return (
        <form onSubmit={handleSubmit}>
            <FormTitle>
                <FormattedMessage id="c-manage-data.edit-details.profile-title" />
            </FormTitle>
            <DataContainer>
                <InputContainer>
                    <Field
                        name="firstName"
                        component={TextInput}
                        label={formatMessage({ id: "general.name" })}
                        placeholder={formatMessage({ id: "general.name" })}
                        props={{
                            disabled: disabled
                        }}
                    />
                </InputContainer>

                <InputContainer>
                    <Field
                        name="lastName"
                        component={TextInput}
                        label={formatMessage({ id: "general.lastname" })}
                        placeholder={formatMessage({ id: "general.lastname" })}
                        props={{
                            disabled: disabled
                        }}
                    />
                </InputContainer>

                <LoadingButton htmlType="submit" disabled={disabled} loading={loading} variant="primary" label="">
                    <FormattedMessage id="general.save" />
                </LoadingButton>
            </DataContainer>
        </form>
    );
};

UserDataForm.propTypes = {
    disabled: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    style: PropTypes.object,
    success: PropTypes.bool
};

const formDefinition = {
    form: "user-data-form",
    schema: userDataForm,
    enableReinitialize: true
};

export default form(formDefinition)(UserDataForm);
