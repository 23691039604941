import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { SPID_WEBAPP_URL } from "../../../config";
import PortaleChildApp from "../../portale-child-app";

class SpidWebapp extends Component {
    static propTypes = {
        auth: PropTypes.object
    };

    render() {
        return <PortaleChildApp url={SPID_WEBAPP_URL} hideElevioAndUservoice={false} />;
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(SpidWebapp);
