import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const IconContainer = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    height: 40px;
    width: 48px;

    color: ${props => props.theme.colors.primary.richElectricBlue};
`;

export const IconCircle = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;

    border: 1px solid ${props => props.theme.colors.primary.richElectricBlue};
    border-radius: 50%;
`;

export const Icon = styled(FontAwesomeIcon)`
    font-size: 14px;
`;
