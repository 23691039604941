import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import { Checkbox } from "@ts-digital/vrc";

import { Container } from "./styled";

const isPopoverEnabled = (displayPopover, checkboxDisabled) => {
    switch (displayPopover) {
        case "always":
            return true;
        case "disabled":
            return checkboxDisabled;
        case "never":
        default:
            return false;
    }
};

export const CheckRow = ({ checked, disabled, displayPopover, onChange, popoverContent, row }) => {
    return isPopoverEnabled(displayPopover, disabled) ? (
        <Tooltip placement="bottomLeft" title={popoverContent}>
            <Container>
                <Checkbox
                    name={`${row.original.itemId}_checkbox`}
                    checked={checked || row.original.checked}
                    disabled={disabled}
                    onChange={newCheckedVal => onChange(newCheckedVal, row)}
                />
            </Container>
        </Tooltip>
    ) : (
        <Checkbox
            name={`${row.original.itemId}_checkbox`}
            checked={checked || row.original.checked}
            disabled={disabled}
            onChange={newCheckedVal => onChange(newCheckedVal, row)}
        />
    );
};

CheckRow.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    displayPopover: PropTypes.oneOf(["always", "disabled", "never"]),
    row: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    popoverContent: PropTypes.node
};

CheckRow.defaultProps = {
    displayPopover: "never"
};
