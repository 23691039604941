import styled from "styled-components";

export const Container = styled.div`
    background-color: #fff;
    padding: 16px;
    max-width: 504px;
    min-height: 500px;
    margin-top: 16px;
    margin-right: auto;
    margin-bottom: 96px;
    margin-left: auto;
    box-shadow: 0px 4px 16px 0px rgba(21, 41, 53, 0.32);
    overflow: scroll;
    ::-webkit-scrollbar {
        display: none;
        scrollbar-width: none;
    }
`;

export const Title = styled.div`
    color: rgb(0, 80, 117);
    font-size: 34px;
    font-family: Cairo;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 32px;
`;

export const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
`;

export const Logo = styled.img`
    max-width: 208px;
`;
