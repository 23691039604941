import {
    GET_PERMISSIONS_START,
    GET_PERMISSIONS_SUCCESS,
    GET_PERMISSIONS_END,
    GET_PERMISSIONS_ERROR
} from "../../../actions/metering/permissions";
import { ENABLE_METERING } from "../../../config";

const defaultState = {
    status: {
        loading: false,
        error: false,
        enabled: ENABLE_METERING === "enable"
    },
    services: {}
};

export const permissionsReducer = (state = defaultState, { type, payload }) => {
    switch (type) {
        case GET_PERMISSIONS_START: {
            return {
                ...state,
                status: { ...state.status, loading: true, error: false }
            };
        }
        case GET_PERMISSIONS_SUCCESS: {
            const { permissions } = payload;
            return {
                ...state,
                services: permissions
            };
        }
        case GET_PERMISSIONS_END: {
            return {
                ...state,
                status: { ...state.status, loading: false }
            };
        }
        case GET_PERMISSIONS_ERROR: {
            const { error } = payload;
            return {
                ...state,
                status: { ...state.status, error }
            };
        }
        default: {
            return state;
        }
    }
};
