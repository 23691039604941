import React from "react";
import PropTypes from "prop-types";

import "./style.css";

const PanelHeader = ({ disabled, title, subtitle }) => (
    <div className={`c-accordion-panel-header ${disabled ? "disabled" : ""}`}>
        <h1>{title}</h1>
        {subtitle}
    </div>
);

PanelHeader.propTypes = {
    disabled: PropTypes.bool,
    title: PropTypes.any,
    subtitle: PropTypes.any
};

export default PanelHeader;
