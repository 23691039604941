import { parseRoles, roleComparer } from "../get-roles";

// funzione di utilità che verifica se un utente è manager
// se viene passato il parametro app, lo verifica per quella app specifica; altrimenti lo verifica per una qualsiasi app di quella company
export function isCompanyManager(itemid, userRoles, app) {
    const managerRoles = userRoles.filter(element => {
        return element.resourceId === itemid && element.actionKey === "MANAGER" && (app ? element.appId === app : true);
    });

    return managerRoles && managerRoles.length > 0;
}

// funzione di utilità per la maschera di selezione ruoli che verifica se l'utente è admin dell'azienda
export function isItemAdmin(elementid, parsedUserRoles) {
    return parsedUserRoles.indexOf(elementid) !== -1;
}

// funzione di utilità per la maschera di selezione ruoli che verifica se l'utente ha almeno un ruolo per l'azienda
export function checkAppRoles(itemid, app, userRoles) {
    // verifico che per quell'utente, e quell'applicativo sia presente almeno un ruolo
    const appRoles = userRoles.filter(element => {
        return element.appId === app && element.resourceId === itemid;
    });

    return appRoles && appRoles.length > 0;
}

// funzione di utilità per la maschera di selezione ruoli che verifica se un dato servizio è abilitato per l'azienda
export function checkItemActiveServices(itemServices, service) {
    let serviceEnabled = false;

    if (itemServices) {
        Object.values(itemServices).forEach(function (itemservice) {
            if (itemservice.agyoService) {
                if (itemservice.agyoService.appId === service) {
                    serviceEnabled = true;
                }
            }
        });
    }

    return serviceEnabled;
}

// funzione di utilità per la maschera di selezione ruoli che verifica lo stato del check a colonna in base ai permessi impostati nella tabella
export function utilsCheckColumnChecked(app, selectedCompanies, itemServices, customRoles, parsedUserRoles, userRoles) {
    let foundSelected = false;
    let notFoundSelected = false;

    if (app === "ADMIN") {
        selectedCompanies
            .map(company => company.id)
            .forEach(companyId => {
                // controlla se admin checkbox disabled perchè è owner
                const checkAdminCbDisabled = customRoles[companyId] && customRoles[companyId].includes("OWNER");

                // controlla se l'utente vede o meno il check admin ( checkbox not visible )
                const checkAdminCbEnabled = isItemAdmin(companyId, parsedUserRoles);

                if (checkAdminCbEnabled && !checkAdminCbDisabled) {
                    // controlla se il ruolo è impostato
                    if (customRoles[companyId] && customRoles[companyId].includes(app)) {
                        foundSelected = true;
                    } else {
                        notFoundSelected = true;
                    }
                }
            });
    } else if (app === "MANAGER") {
        selectedCompanies
            .map(company => company.id)
            .forEach(companyId => {
                // controlla se manager checkbox disabled perchè è owner
                const checkManagerCbDisabled =
                    customRoles[companyId] &&
                    (customRoles[companyId].includes("OWNER") || customRoles[companyId].includes("ADMIN"));

                // controlla se l'utente vede o meno il check admin ( checkbox not visible )
                const checkManagerCbEnabled = isCompanyManager(companyId, userRoles, undefined);

                if (checkManagerCbEnabled && !checkManagerCbDisabled) {
                    // controlla se il ruolo è impostato
                    if (customRoles[companyId] && customRoles[companyId].includes(app)) {
                        foundSelected = true;
                    } else {
                        notFoundSelected = true;
                    }
                }
            });
    } else if (app !== "ADMIN") {
        selectedCompanies
            .map(company => company.id)
            .forEach(companyId => {
                // controlla se ho ruoli sull'applicativo ( caso di checkbox non visibile )
                const checkVisible =
                    isItemAdmin(companyId, parsedUserRoles) || checkAppRoles(companyId, app, userRoles);

                // controlla se il check è disabilitato perchè è flaggato admin
                const checkDisabledBecouseAdminChecked =
                    customRoles[companyId] &&
                    (customRoles[companyId].includes("ADMIN") || customRoles[companyId].includes("OWNER"));

                // disabilito servizio se disabilitato per azienda
                const checkDisabledBecouseAppNotEnabled = !checkItemActiveServices(itemServices[companyId], app);

                if (checkVisible && !checkDisabledBecouseAdminChecked && !checkDisabledBecouseAppNotEnabled) {
                    // controlla se il ruolo è impostato
                    if (customRoles[companyId] && customRoles[companyId].includes(app)) {
                        foundSelected = true;
                    } else {
                        notFoundSelected = true;
                    }
                }
            });
    }

    if (foundSelected && notFoundSelected) {
        return "indeterminate";
    } else if (foundSelected && !notFoundSelected) {
        return true;
    } else if (!foundSelected && notFoundSelected) {
        return false;
    } else if (!foundSelected && !notFoundSelected) {
        return null;
    }
}

// funzione che recupera il diff tra i ruoli pre esistenti e quelli impostati in fase di modifica dei ruoli utente
// roles: custom roles che escono dalla maschera
// userRoles: ruoli utente che sta modificando
// userInfoRoles: ruoli dell'utente che si sta modificando
// selectedItemIds: lista degli id delle aziende per le quali stanno modificando i ruoli
export function getRolesDiff(roles, userRoles, userInfoRoles, selectedItemIds) {
    const parsedNewRoles = parseRoles(roles, userRoles);
    const oldRoles = userInfoRoles.filter(oldrole => selectedItemIds.includes(oldrole.resourceId));

    let verifiedManagerRoles = [];
    let itemsToNotRemove = [];
    parsedNewRoles.forEach(newRole => {
        // verifico tra tutti i ruoli che sto aggiungendo
        if (
            // i ruoli per cui l'utente che sto modificando è manager
            oldRoles.some(
                oldRole =>
                    oldRole.resourceId === newRole.resourceId &&
                    oldRole.appId === newRole.appId &&
                    oldRole.actionKey === "MANAGER"
            ) &&
            // in questi casi verifico se io che sto modificando non lo sono ( quindi ho un ruolo "inferiore")
            !userRoles.some(
                role =>
                    newRole.resourceId === role.resourceId &&
                    newRole.appId === role.appId &&
                    role.actionKey === "MANAGER"
            )
        ) {
            // in questo caso non considero la nuova aggiunta, saranno i casi che non devo nemmeno rimuovere
            itemsToNotRemove.push(newRole);
        } else {
            verifiedManagerRoles.push(newRole);
        }
    });

    // check diff tra i ruoli utente attuali e quelli impostati nella maschera
    const onlyInNewRoles = roleComparer(verifiedManagerRoles, oldRoles);
    const onlyInOldRoles = roleComparer(oldRoles, verifiedManagerRoles);

    return {
        onlyInNewRoles: onlyInNewRoles,
        onlyInOldRoles: onlyInOldRoles.filter(
            role =>
                !itemsToNotRemove.some(
                    roleToNotRemove =>
                        role.resourceId === roleToNotRemove.resourceId && role.appId === roleToNotRemove.appId
                )
        )
    };
}
