import styled from "styled-components";

export const Title = styled.div`
    height: 28px;
    font-family: Cairo;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    color: ${props => props.theme.colors.primary.darkImperialBlue};
`;

export const SubTitle = styled.div`
    color: ${props => props.theme.colors.primary.cadet};
    font-size: 12px;
    font-family: Roboto;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.4px;
    line-height: 12px;
`;

export const Header = styled.div`
    border-bottom: 2px solid ${props => props.theme.colors.primary.richElectricBlue};
    background-color: ${props => props.theme.colors.background.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 8px;
`;

export const TitleContainer = styled.div`
    text-align: center;
    flex-basis: 33%;
`;

export const ButtonContainer = styled.div`
    flex-grow: 1;
    flex-basis: 33%;
`;

export const RightButtonContainer = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    flex-basis: 33%;
    padding-right: 12px;
`;
