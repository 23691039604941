import styled from "styled-components";
import { Upload } from "antd";

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    & button:first-child {
        margin-right: 10px;
    }
    & button {
        width: 150px;
    }
`;

export const BottomContainer = styled.div`
    text-align: center;
`;

export const ErrorString = styled.div`
    color: red;
`;

export const CustomUpload = styled(Upload)`
    .ant-upload-list-item:hover .ant-upload-list-item-info {
        background-color: ${props => props.theme.colors.background.whiteSmoke};
    }

    .ant-upload-list-item-card-actions > a > i {
        color: ${props => props.theme.colors.status.maximumRed};
    }
`;
