import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const PageContainer = styled.div`
    background-color: ${props => props.theme.colors.background.white};
    padding: 24px 0;
    min-height: 80vh;

    @media (min-width: 1200px) {
        padding: 24px 24px;
    }
`;

export const Wrapper = styled.div`
    padding: 80px 0;
`;

export const AsideImage = styled.img`
    max-width: 100%;
    margin: 96px 0 0;
`;

export const Title = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 24px;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.23px;
    line-height: 24px;
    margin-bottom: 56px;
`;

export const TitleIcon = styled(FontAwesomeIcon)`
    margin-right: 16px;
`;

export const Description = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 14px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 24px;
    margin-bottom: 32px;
`;

export const Separator = styled.div`
    border-radius: 0px;
    background-color: ${props => props.theme.colors.primary.columbiaBlue};
    height: 1px;
    width: 100%;
    margin-top: 80px;
    margin-bottom: 80px;
`;

export const SelectAndButtonContainer = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const SelectContainer = styled.div`
    width: 500px;
    margin-right: 16px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const ButtonIcon = styled(FontAwesomeIcon)`
    margin-right: 8px;
`;
