import React, { useEffect } from "react";

import { Tooltip } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { faFileCsv, faSpinner } from "@fortawesome/pro-regular-svg-icons";

import ElevioElements from "../../elevio-elements";
import { downloadCsv, formatCell } from "../../../utils/download";
import { IconContainer, TitleContainer } from "./styled";

export const LinkListTitle = ({
    agyoServices,
    connectionData,
    displayErrorToast,
    downloadConnectionsData,
    resetDownloadConnectionsData
}) => {
    const { formatMessage } = useIntl();

    useEffect(() => {
        if (connectionData.status.ended) {
            const conn = connectionData.payload.map(c => {
                return {
                    [formatMessage({
                        id: `general.vatNumber`
                    })]: formatCell(c.vatNumber),
                    [formatMessage({
                        id: `general.taxId`
                    })]: formatCell(c.taxId),
                    [formatMessage({
                        id: `general.description`
                    })]: formatCell(c.description),
                    [formatMessage({
                        id: `c-registry-user.info.service`
                    })]: formatCell(c.service),
                    [formatMessage({
                        id: `general.creation-date`
                    })]: formatCell(c.createdAt),
                    [formatMessage({
                        id: `general.modify-date`
                    })]: formatCell(c.modifiedAt),
                    [formatMessage({
                        id: `general.stato`
                    })]: formatCell(formatMessage({ id: `c-connection-list.status.${c.status}` })),
                    [formatMessage({
                        id: `c-cct-provisioning.rejected`
                    })]: formatCell(c.errorMessage)
                };
            });

            let header = Object.keys(conn[0]).join(",");
            let values = conn.map(p => Object.values(p).join(",")).join("\n");

            let csvContent = header + "\n" + values;
            downloadCsv(csvContent, "connections.csv");
            resetDownloadConnectionsData();
        }
    }, [agyoServices, connectionData, formatMessage, resetDownloadConnectionsData]);

    useEffect(() => {
        if (connectionData.status.error) {
            displayErrorToast(formatMessage({ id: "c-link-list.download-connections.error" }));
            resetDownloadConnectionsData();
        }
    }, [connectionData.status, displayErrorToast, formatMessage, resetDownloadConnectionsData]);

    return (
        <TitleContainer>
            <ElevioElements idArticle="233" />
            <FormattedMessage id="c-link-list.add-connection.title" />
            {downloadConnectionsData && (
                <Tooltip title={formatMessage({ id: "c-link-list.download-connections.title" })}>
                    <IconContainer
                        spin={connectionData.status.started}
                        icon={connectionData.status.started ? faSpinner : faFileCsv}
                        onClick={() => downloadConnectionsData()}
                    />
                </Tooltip>
            )}
        </TitleContainer>
    );
};

LinkListTitle.defaultProps = {
    connectionData: { status: {} }
};
