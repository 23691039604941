import axios from "axios";

import { maintenance } from "../actions/config";
import { CONFIG_URL } from "../config";

const actions = ["LOAD_USER_SUCCESS", "persist/PERSIST", "SELECT_COMPANY"];

const config = store => next => async action => {
    if (actions.includes(action.type)) {
        try {
            const result = await axios.get(CONFIG_URL);
            store.dispatch(maintenance(result.data.maintenance));
        } catch (err) {
            console.error("Caught an exception!", err);
        }
    }
    next(action);
};

export default config;
