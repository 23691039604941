import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import { compose } from "redux";

import { generateContract, generateContractReset, uploadCert, uploadCertReset } from "../../actions/certification";
import { getPrivacyPreferences } from "../../actions/company";
import { AccordionStepper } from "../../components/general/accordion-stepper";
import { AccordionStep } from "../../components/general/accordion-stepper/accordion-step";
import { errorBoundary } from "../../components/general/error-boundary";
import { Spinner } from "../../components/general/spinner";
import ContractDataForm from "../../components/validate-company/contract-data-form";
import ContractGenerate from "../../components/validate-company/contract-generate";
import ContractUpload from "../../components/validate-company/contract-upload";
import { downloadBase64 } from "../../utils/download";
import { isItemAdmin } from "../../utils/get-roles";
import { statusCompany, certificationStatus } from "../../utils/constant";
import i18nEnglish from "../../i18n/en.js";
import i18nSpanish from "../../i18n/es.js";
import i18nItalian from "../../i18n/it.js";

import "./style.css";
import { ContractUploadContainer } from "./styled";

// Esportata anche questa classe per poterla usare nel test senza connect
export const ValidateCompany = ({
    certification,
    company,
    contractStatus,
    document,
    generateContract,
    generateContractReset,
    getPrivacyPreferences,
    history,
    match,
    privacyPreferences,
    privacyPreferencesLoading,
    uploadCert,
    uploadCertReset,
    user
}) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedFile, setSelectedFile] = useState({ file: undefined, base64: "" });
    const [data, setData] = useState({});
    const [date] = useState(moment());
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        if (!isItemAdmin(company.base.id, user)) {
            history.push("/");
        }
    }, [company.base.id, history, user]);

    useEffect(() => {
        uploadCertReset();
    }, [uploadCertReset]);

    useEffect(() => {
        return () => generateContractReset();
    }, [generateContractReset]);

    useEffect(() => {
        if (!privacyPreferences) {
            getPrivacyPreferences(match.params.item);
        }
    }, [getPrivacyPreferences, match.params.item, privacyPreferences]);

    useEffect(() => {
        if (contractStatus.ended) {
            setCurrentStep(2);
            downloadBase64(document, "tsdigitalContract.pdf");
        }
    }, [contractStatus, document, generateContractReset]);

    useEffect(() => {
        const isContractRejected =
            company &&
            company.base.status &&
            (company.base.status.status === statusCompany.REJECTED ||
                (company.base.status.status === statusCompany.VALIDATED &&
                    company.base.status.certificationStatus === certificationStatus.AWAITING_UPLOAD));

        if (privacyPreferences && privacyPreferences.itemId) {
            setInitialValues({
                documentDate: !isContractRejected ? privacyPreferences.documentDate : moment(),
                documentPlace: privacyPreferences.documentPlace,
                firstName: privacyPreferences.firstName,
                lastName: privacyPreferences.lastName,
                role: privacyPreferences.role,
                notificationConsent: privacyPreferences.notificationConsent,
                thirdPartyNotificationConsent: privacyPreferences.thirdPartyNotificationConsent
            });
            setCurrentStep(2);
        }
    }, [privacyPreferences, company]);

    const handleBack = useCallback(() => {
        setCurrentStep(currentStep - 1);
    }, [currentStep]);

    const handleEdit = useCallback(() => {
        setCurrentStep(0);
    }, []);

    const handleConsentDataSubmit = useCallback(
        data => {
            setCurrentStep(currentStep + 1);
            setData(data);
        },
        [currentStep]
    );

    const handleGeneration = useCallback(() => {
        const taxId = company.base.identifier.taxId ? company.base.identifier.taxId : "";
        const piva = company.base.identifier.vatNumber ? company.base.identifier.vatNumber : "";
        const itemDescription =
            company.base.details.classifier === "PERSON"
                ? `${company.base.details.firstName} ${company.base.details.lastName}`
                : company.base.details.description || "";
        const taxRegion = company.base.identifier.taxRegion ? company.base.identifier.taxRegion : "";
        let corporateOfficeTranslationKey = `general.roles.${data.role}`;

        let i18nFile;
        switch (taxRegion) {
            case "ES":
                i18nFile = i18nSpanish;
                break;
            case "EN":
                i18nFile = i18nEnglish;
                break;
            default:
                i18nFile = i18nItalian;
                break;
        }
        const corporateOffice = i18nFile[corporateOfficeTranslationKey];

        const address = company.base.details.addresses[0];
        generateContract(company.base.id, {
            ...data,
            ...address,
            name: data.firstName,
            surname: data.lastName,
            corporateOffice,
            taxId,
            pIva: piva,
            description: itemDescription,
            date: data.documentDate,
            place: data.documentPlace,
            notificationConsent: data.notificationConsent,
            thirdPartyNotificationConsent: data.thirdPartyNotificationConsent
        });
    }, [company, data, generateContract]);

    const handleSelectFile = useCallback((base64, file, error) => {
        setSelectedFile({ file, base64, error });
    }, []);

    const handleUpload = useCallback(() => {
        if (!selectedFile.error) {
            const description = company.base.details.description != null ? company.base.details.description : "";
            uploadCert(company.base.id, description, selectedFile.base64);
        }
    }, [company, selectedFile, uploadCert]);

    const ended = certification.uploadCert.status.started === false && certification.uploadCert.status.ended;

    return ended ? (
        <Redirect to={`/${match.params.item}/company/validate/success`} />
    ) : (
        <Spinner loading={privacyPreferencesLoading}>
            <AccordionStepper
                abortLabel="general.edit"
                className="v-validate-company"
                backRedirectUrl={`/${match.params.item}/dashboard`}
                currentStep={currentStep}
                onConfirm={handleUpload}
                onLastBack={handleEdit}
                submitDisabled={!selectedFile || !selectedFile.file || !selectedFile.base64 || selectedFile.error}
                submitting={certification.uploadCert.status.started}
                submitLabel="v-validate-company.stepper.submit"
            >
                <AccordionStep
                    title={<FormattedMessage id="v-validate-company.step.insert-data.title" />}
                    subtitle={
                        <div>
                            <p>
                                <FormattedMessage id="v-validate-company.step.insert-data.subtitle" />
                            </p>
                            <p>
                                <FormattedMessage id="v-validate-company.step.data-consent.subtitle" />
                            </p>
                        </div>
                    }
                    disabled={false}
                >
                    <ContractDataForm
                        initialValues={{
                            firstName: user.user.profile.firstName,
                            lastName: user.user.profile.lastName,
                            companyName: company.base.details.description || "",
                            documentPlace: company.base.details.addresses[0].city,
                            documentDate: date,
                            ...initialValues
                        }}
                        onSubmit={handleConsentDataSubmit}
                    />
                </AccordionStep>
                <AccordionStep
                    title={<FormattedMessage id="v-validate-company.step.generate-document.title" />}
                    subtitle={<FormattedMessage id="v-validate-company.step.generate-document.subtitle" />}
                    disabled={false}
                >
                    <ContractGenerate
                        fillPdfStatus={contractStatus}
                        handleBack={handleBack}
                        onGenerate={handleGeneration}
                    />
                </AccordionStep>
                <AccordionStep
                    title={<FormattedMessage id="v-validate-company.step.upload-contract.title" />}
                    subtitle={<FormattedMessage id="v-validate-company.step.upload-contract.subtitle" />}
                    disabled={false}
                >
                    <ContractUploadContainer>
                        <ContractUpload
                            error={selectedFile.error}
                            onFileChanged={handleSelectFile}
                            selectedFiles={selectedFile.file && [selectedFile.file]}
                            uploadButtonMessage="c-validate-company-contract-upload.upload-contract"
                        />
                    </ContractUploadContainer>
                </AccordionStep>
            </AccordionStepper>
        </Spinner>
    );
};

ValidateCompany.propTypes = {
    certification: PropTypes.object,
    company: PropTypes.object.isRequired,
    document: PropTypes.string,
    contractStatus: PropTypes.object.isRequired,
    generateContract: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    uploadCert: PropTypes.func.isRequired,
    uploadCertReset: PropTypes.func,
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => ({
    certification: state.certification,
    document: state.certification.tscontract.data,
    contractStatus: state.certification.tscontract.status,
    company: state.companies.data[props.match.params.item].item,
    privacyPreferences: state.privacyPreferences.preferences[props.match.params.item],
    privacyPreferencesLoading: state.privacyPreferences.status.started,
    user: state.user
});

const actions = { getPrivacyPreferences, generateContract, generateContractReset, uploadCert, uploadCertReset };

const composedHoc = compose(connect(mapStateToProps, actions), errorBoundary);

export default composedHoc(ValidateCompany);
