// import {lingueValues} from "constants/constants.js";

/**************************************
 Controllo del Codice Fiscale
 ***************************************/

export function fiscalCodeControl(fiscalCode = "") {
    var validi, i, s, set1, set2, setpari, setdisp;
    if (fiscalCode === "") {
        return false;
    }
    fiscalCode = fiscalCode.toUpperCase();
    // Controllo la lunghezza del CF

    if (fiscalCode.length !== 16) return false;
    validi = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    // Controllo che tutti i caratteri del CF siano corretti
    for (i = 0; i < 16; i++) {
        if (validi.indexOf(fiscalCode.charAt(i)) === -1) return false;
    }
    set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
    setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
    s = 0;
    for (i = 1; i <= 13; i += 2) s += setpari.indexOf(set2.charAt(set1.indexOf(fiscalCode.charAt(i))));
    for (i = 0; i <= 14; i += 2) s += setdisp.indexOf(set2.charAt(set1.indexOf(fiscalCode.charAt(i))));
    if (s % 26 !== fiscalCode.charCodeAt(15) - "A".charCodeAt(0)) return false;
    return true;
}

/*****************************************
 Controllo della Partita I.V.A.
 ******************************************/

export function vatNumberControl(vatNumber) {
    var i, c, s;
    if (vatNumber === "") return false;
    if (vatNumber.length !== 11) return false;

    var validi = "0123456789";

    for (i = 0; i < 11; i++) {
        if (validi.indexOf(vatNumber.charAt(i)) === -1) return false;
    }
    s = 0;
    for (i = 0; i <= 9; i += 2) s += vatNumber.charCodeAt(i) - "0".charCodeAt(0);
    for (i = 1; i <= 9; i += 2) {
        c = 2 * (vatNumber.charCodeAt(i) - "0".charCodeAt(0));
        if (c > 9) c = c - 9;
        s += c;
    }
    if ((10 - (s % 10)) % 10 !== vatNumber.charCodeAt(10) - "0".charCodeAt(0)) return false;

    return true;
}

// /* get language from user data */
// export function getLanguageFromUser (user) {
//     let lingua = lingueValues.find(L => {
//         return L.lingua === user.loggedUser.lingua || L.id === user.loggedUser.lingua
//     });
//     return lingua.id || "it-IT";
// }
//
// /* get sedeLegale or sede from loggedUser data */
// export function getSede (loggedUser) {
//     return loggedUser.azienda.sedeLegale || loggedUser.azienda.sede;
// }
//
// /* pdf validator by header: %PDF-1.0 ... %PDF-1.7 */
// export function pdfValidator (fileContent, pdfPrefix = "%PDF", arrPdfVersions = ["1.0", "1.1", "1.2", "1.3", "1.4", "1.5", "1.6", "1.7"]) {
//     if (fileContent) {
//         let decodedContent = window.atob(fileContent);
//         let pdfVersion = decodedContent.substring(0,8);
//         let arrPdf = pdfVersion.split("-");
//         return arrPdf[0] === pdfPrefix && arrPdfVersions.indexOf(arrPdf[1]) > -1;
//     }
// }

export function obligatoryParameters(company, type) {
    switch (type) {
        case "RF":
            if (company.base.details.economics.taxRegime == null) {
                return false;
            }
            break;
        case "ALL":
            if (company.base.identifier.taxId === null) {
                return false;
            }
            if (company.base.identifier.vatNumber === null) {
                return false;
            }
            if (company.base.details.description === null) {
                return false;
            }
            if (company.base.details.addresses[0].zipCode === null) {
                return false;
            }
            if (company.base.details.addresses[0].city === null) {
                return false;
            }
            if (company.base.details.addresses[0].province === null) {
                return false;
            }
            if (company.base.details.addresses[0].country === null) {
                return false;
            }
            if (company.base.details.addresses[0].streetName === null) {
                return false;
            }
            return true;
        default:
            return true;
    }
}
