import styled from "styled-components";

export const ModalContent = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 16px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 24px;
`;

export const ElevioLink = styled.a`
    border-bottom: none !important;

    & span {
        color: #0090d1;
        cursor: pointer;
        margin-left: 10px;
    }
`;

export const Description = styled.div`
    margin-bottom: 24px;
`;

export const SmartCheckboxContainer = styled.div`
    display: flex;
    margin-bottom: 24px;
    align-items: baseline;
`;

export const SmartCheckboxLabelContainer = styled.span`
    flex: 1;
    padding: 0px 8px;
`;

export const VademecumContainer = styled.div`
    margin-top: 24px;
`;

export const RdcDescription = styled.div`
    margin-bottom: 24px;
`;

export const RdcData = styled.div`
    margin-bottom: 24px;
`;

export const RdcField = styled.div`
    display: flex;
`;

export const BalanceSheetDateAlert = styled.div``;

export const RdcDataLabels = styled.div`
    margin-right: 10px;
    font-weight: bold;
`;
