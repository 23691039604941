import styled from "styled-components";

import ItalianProvinceField from "@ts-digital/arc/antd/ItalianProvinceField";
import TextField from "@ts-digital/arc/antd/TextField";

export const LeftColumn = styled.div`
    grid-column-start: 1;
    grid-column-end: 3;
    margin: 0 20px 5px 0;
`;

export const CentralColumn = styled.div`
    grid-column-start: 3;
    grid-column-end: 4;
    margin: 0 10px 5px 0;
`;

export const RightColumn = styled.div`
    grid-column-start: 4;
    grid-column-end: 6;
    margin: 0 0 5px 10px;
`;

export const ItalianProvinceFieldHideable = styled(ItalianProvinceField)`
    display: ${props => props.hide && "none !important"};
`;

export const TextFieldHideable = styled(TextField)`
    display: ${props => props.hide && "none !important"};
`;
