import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";

class ElevioElements extends Component {
    static propTypes = {
        idArticle: PropTypes.string
    };

    handleMouseEnter = () => {
        this.elevioHtmlElement.click();
    };

    render() {
        const { idArticle } = this.props;
        return (
            <span
                data-elevio-inline={idArticle}
                data-elevio-style="nothing"
                onMouseEnter={this.handleMouseEnter}
                ref={r => (this.elevioHtmlElement = r)}
            >
                <Icon
                    type="info-circle"
                    theme="filled"
                    style={{
                        fontSize: "20px",
                        margin: "0 10px",
                        color: "rgb(247, 172, 30)",
                        fontWeight: "bold"
                    }}
                />
            </span>
        );
    }
}

export default ElevioElements;
