export function extractRoles(token) {
    return decodeToken(token).actions;
}

function decodeToken(token) {
    const encodedToken = token.split(".")[1];
    const decodedToken = atob(atob(btoa(encodedToken)));
    return JSON.parse(decodedToken);
}

export function extractUuid(token) {
    return JSON.parse(atob(token.split(".")[1])).uid;
}
