import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { fieldRequired } from "@ts-digital/agyo-validations";
import combineValidators from "@ts-digital/agyo-validations/lib/utils/combineValidators";
import { Button } from "@ts-digital/vrc";
import { EmailValidator } from "commons-validator-js";
import { Field, useFormState } from "react-final-form";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";

import { useFeature } from "../../../hooks/use-feature";

import TextInput from "../../general/form-text-input";
import LoadingButton from "../../general/loading-button";
import { Footer, GrowthableContainer, SectionTitle, Subtitle, Title } from "../styled";
import {
    ConfirmContainer,
    Container,
    ContentContainer,
    EditContainer,
    EditEmailButton,
    InsertNewUserContainer,
    InsertNewUserDataContainer,
    UserExistsMessageContainer,
    UserExistsMessageIcon
} from "./styled";

const doesUserHasRolesInCurrentCompany = (users, userEmail) => {
    return users.some(u => u.user.profile.email === userEmail);
};

const InsertEmail = ({
    checkUserExistence,
    doesUserExist,
    userId,
    goBack,
    handleSubmit,
    resetUserExists,
    getUserV3,
    currentCompany,
    users
}) => {
    const intl = useIntl();
    const formState = useFormState();
    const enableInvitation = useFeature("ENABLE_INVITATIONS");

    const checkUserExistenceCall = useCallback(
        () => checkUserExistence.call(formState.values.email),
        [checkUserExistence, formState.values.email]
    );

    const emailInstertedIsTheUserEmail = formState.values.email === userId;

    return (
        <Container data-cy="insert-email-step">
            <div>
                <Title data-cy="step-title">
                    <FormattedMessage
                        id={
                            enableInvitation
                                ? "v-user-creation-flow.steps.insert-email.title.invite"
                                : "v-user-creation-flow.steps.insert-email.title"
                        }
                    />
                </Title>
                <Subtitle data-cy="step-subtitle">
                    <FormattedHTMLMessage
                        id={
                            enableInvitation
                                ? "v-user-creation-flow.steps.insert-email.subtitle.invite"
                                : "v-user-creation-flow.steps.insert-email.subtitle"
                        }
                        values={{
                            studio: currentCompany.base.details.description
                        }}
                    />
                </Subtitle>
            </div>
            <ContentContainer>
                <Field
                    name="email"
                    validate={combineValidators(
                        fieldRequired(intl.formatMessage({ id: "general.form.field-required" })),
                        value =>
                            !new EmailValidator().isValid(value ? value : "") &&
                            intl.formatMessage({ id: "general.form.email-required" })
                    )}
                    disabled={doesUserExist !== undefined}
                    label={intl.formatMessage({ id: "general.email" })}
                    component={TextInput}
                    required={true}
                    onKeyPress={e => {
                        if (e.key === "Enter" && formState.valid && !emailInstertedIsTheUserEmail) {
                            getUserV3.call(formState.values.email);
                            checkUserExistenceCall();
                        }
                    }}
                    data-cy="email-input"
                />
                {!emailInstertedIsTheUserEmail &&
                    (doesUserExist === undefined ? (
                        <ConfirmContainer>
                            <LoadingButton
                                loading={checkUserExistence.status.started}
                                onClick={e => {
                                    checkUserExistenceCall();
                                    getUserV3.call(formState.values.email);
                                }}
                                disabled={!formState.valid}
                                data-cy="verify-email-button"
                            >
                                <FormattedMessage id="v-user-creation-flow.steps.insert-email.buttons.verify-email" />
                            </LoadingButton>
                        </ConfirmContainer>
                    ) : (
                        <EditContainer>
                            <EditEmailButton
                                onClick={() => resetUserExists()}
                                kind="tetiary"
                                data-cy="edit-email-button"
                            >
                                <FormattedMessage id="v-user-creation-flow.steps.insert-email.buttons.edit-email" />
                            </EditEmailButton>
                        </EditContainer>
                    ))}

                {doesUserExist &&
                    !emailInstertedIsTheUserEmail &&
                    !doesUserHasRolesInCurrentCompany(users, formState.values.email) && (
                        <UserExistsMessageContainer data-cy="user-exists-message">
                            <UserExistsMessageIcon icon={faInfoCircle} />
                            <span>
                                <FormattedMessage id="v-user-creation-flow.steps.insert-email.user-exists" />
                            </span>
                        </UserExistsMessageContainer>
                    )}
                {doesUserExist &&
                    !emailInstertedIsTheUserEmail &&
                    doesUserHasRolesInCurrentCompany(users, formState.values.email) && (
                        <UserExistsMessageContainer data-cy="user-exists-message">
                            <UserExistsMessageIcon icon={faInfoCircle} />
                            <span>
                                <FormattedMessage id="v-user-creation-flow.steps.insert-email.user-has-roles" />
                            </span>
                        </UserExistsMessageContainer>
                    )}
                {emailInstertedIsTheUserEmail && (
                    <UserExistsMessageContainer data-cy="user-exists-message">
                        <UserExistsMessageIcon icon={faInfoCircle} />
                        <span>
                            <FormattedMessage id="v-user-creation-flow.steps.insert-email.user-wants-to-edit-itself" />
                        </span>
                    </UserExistsMessageContainer>
                )}
                {doesUserExist === false && (
                    <InsertNewUserContainer>
                        <SectionTitle>
                            <FormattedMessage id="v-user-creation-flow.steps.insert-email.insert-user-data" />
                        </SectionTitle>
                        <InsertNewUserDataContainer>
                            <GrowthableContainer>
                                <Field
                                    name="name"
                                    validate={fieldRequired(intl.formatMessage({ id: "general.form.field-required" }))}
                                    label={intl.formatMessage({ id: "general.name" })}
                                    component={TextInput}
                                    required={true}
                                    data-cy="name-input"
                                />
                            </GrowthableContainer>
                            <GrowthableContainer>
                                <Field
                                    name="surname"
                                    validate={fieldRequired(intl.formatMessage({ id: "general.form.field-required" }))}
                                    label={intl.formatMessage({ id: "general.lastName" })}
                                    component={TextInput}
                                    required={true}
                                    data-cy="surname-input"
                                />
                            </GrowthableContainer>
                        </InsertNewUserDataContainer>
                    </InsertNewUserContainer>
                )}
            </ContentContainer>
            <Footer>
                <Button kind="secondary" onClick={() => goBack()} style={{ marginRight: 16 }} data-cy="go-back-button">
                    <FormattedMessage id="general.annulla" />
                </Button>
                <Button
                    disabled={
                        doesUserExist === undefined ||
                        !formState.valid ||
                        getUserV3.status.started ||
                        doesUserHasRolesInCurrentCompany(users, formState.values.email)
                    }
                    onClick={handleSubmit}
                    data-cy="next-step-button"
                >
                    <FormattedMessage id="v-user-creation-flow.buttons.next-step" />
                </Button>
            </Footer>
        </Container>
    );
};

InsertEmail.propTypes = {
    checkUserExistence: PropTypes.shape({
        call: PropTypes.func.isRequired,
        status: PropTypes.shape({
            started: PropTypes.bool.isRequired,
            ended: PropTypes.bool.isRequired,
            error: PropTypes.bool.isRequired
        }).isRequired
    }).isRequired,
    doesUserExist: PropTypes.bool,
    goBack: PropTypes.func.isRequired,
    resetUserExists: PropTypes.func.isRequired,
    // Injected by Form
    handleSubmit: PropTypes.func.isRequired
};

export default InsertEmail;
