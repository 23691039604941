import React from "react";

import { connect } from "react-redux";
import { useParams } from "react-router";

import { errorBoundary } from "../../../components/general/error-boundary";
import { getCurrentCookieConsents } from "../../../utils/cookie-consent-utils";
import { SPEND_MANAGMENT } from "../../../config";
import MigratingChildApp from "../../portale-child-app/migrating";
import { useFeature } from "../../../hooks/use-feature";

const SpendManagementView = ({ auth, language, items }) => {
    const enableChildApp = useFeature("HABBLE_CHILD_APP");

    const { item } = useParams();
    const itemUuid = items[item].item.base.uuid;

    const handleFrameLoad = iframeRef => {
        iframeRef.contentWindow.postMessage(
            {
                cookieConsents: getCurrentCookieConsents(),
                auth: {
                    securityToken: auth.securityToken,
                    refreshToken: auth.refreshToken,
                    appName: "HBLE"
                },
                itemId: item,
                itemUuid: itemUuid,
                language
            },
            SPEND_MANAGMENT
        );
    };

    return (
        <MigratingChildApp
            iframeConfigs={{
                onLoad: handleFrameLoad,
                data: {
                    cookieConsents: getCurrentCookieConsents(),
                    auth: {
                        securityToken: auth.securityToken,
                        refreshToken: auth.refreshToken,
                        appName: "HBLE"
                    },
                    itemId: item,
                    itemUuid: itemUuid,
                    language
                },
                id: "habble",
                title: "Habble"
            }}
            url={SPEND_MANAGMENT}
            customLaunchData={{ appName: "HBLE", oldItemId: item }}
            migrated={enableChildApp}
        />
    );
};

const mapStateToProps = state => ({
    auth: state.auth.loginAuth,
    language: state.user.user.profile.language,
    items: state.companies.data
});

export default errorBoundary(connect(mapStateToProps)(SpendManagementView));
