import React from "react";
import PropTypes from "prop-types";

import { faCheckCircle, faPauseCircle } from "@fortawesome/pro-solid-svg-icons";
import { FormattedMessage } from "react-intl";
import { StatusIcon, StatusIconContainer } from "./styled";

const statusIcons = {
    active: faCheckCircle,
    notActive: faPauseCircle
};

const ServiceStatusCell = ({ value }) => {
    return value ? (
        <>
            <StatusIconContainer>
                <StatusIcon icon={statusIcons[value]} status={value} />
            </StatusIconContainer>
            <FormattedMessage id={`c-user-roles-table.description.${value}`} />
        </>
    ) : null;
};

ServiceStatusCell.propTypes = {
    /** Cell value, provided by the table */
    value: PropTypes.string
};

export default ServiceStatusCell;
