import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";

import { IconContainer } from "./styled";

const ElevioInfoCircle = ({ articleId, className }) => (
    <IconContainer data-elevio-inline={articleId} className={className}>
        <FontAwesomeIcon icon={faInfoCircle} />
    </IconContainer>
);

ElevioInfoCircle.propTypes = {
    articleId: PropTypes.string.isRequired
};

export default ElevioInfoCircle;
