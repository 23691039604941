import React from "react";

import { Icon, Button } from "antd";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import "./style.css";

const DisplayResult = () => {
    const params = useParams();

    return (
        <div className="connection-create-display-result">
            <div className="wrapper">
                <Icon type="check-circle-o" />
                <p className="title">
                    <FormattedMessage id="c-connections-create.success-message" />
                </p>
                <p className="message-content">
                    <FormattedMessage id="c-connections-create.success-message-subtitle" />
                </p>
                <Link to={`/${params.item}/connections`}>
                    <Button type="primary">
                        <FormattedMessage id="c-connections-create.success-message-back" />
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default DisplayResult;
