import styled from "styled-components";

const handleSize = spinnerSize => {
    switch (spinnerSize) {
        case "small":
            return "14px";
        case "default":
            return "24px";
        case "large":
        default:
            return "40px";
    }
};

export const SpinnerContainer = styled.div`
    align-items: center;
    display: flex;
    position: ${props => (props.fullPage ? "absolute" : "initial")};
    height: ${props => (props.fullPage ? "100vh" : "100%")};
    width: ${props => (props.fullPage ? "100vw" : "100%")};
    top: 0;
    left: 0;
    justify-content: center;
    color: rgb(0, 144, 209);
    font-size: ${props => handleSize(props.spinnerSize)};
`;
