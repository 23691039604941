import styled from "styled-components";

export const CompanyCard = styled.div`
    display: flex;
    flex-direction: column;
    margin: 8px;

    background-color: white;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;

    opacity: 0;
    animation: slideUp 0.3s ease, fadeIn 0.2s linear forwards;
    animation-delay: ${props => `${props.index < 12 ? props.index * 65 : 0}ms`};
    transition: transform 0.5s ease, box-shadow 0.5s ease;

    &:hover {
        transform: translateY(-2px);
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    }

    @keyframes slideUp {
        0% {
            transform: translateY(16px);
        }
        100% {
            transform: translateY(0px);
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

export const CardHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    margin: 16px 24px;
    font-size: 16px;
    vertical-align: center;
`;

export const CardTitle = styled.div`
    font-weight: 600;
`;

export const CardIcons = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const CardDescription = styled.div`
    display: grid;
    margin: auto 24px;
    grid-template-columns: 120px auto;
`;

export const CardButtons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    margin-top: 16px;
    border-top: 1px solid rgb(62, 143, 204);
    font-weight: 600;
    color: rgb(62, 143, 204);

    & > * {
        padding: 0 8px;
    }
`;

export const SettingsIcon = styled.span`
    color: #1890ff;
    text-align: center;
    margin: 0 8px;

    &:hover {
        cursor: pointer;
    }

    &:first-of-type {
        margin-left: 0;
    }

    &:last-of-type {
        margin-right: 0;
    }
`;
