import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Button, StandardTabs, Tab } from "@ts-digital/vrc";
import { connect } from "react-redux";
import { generatePath, Route, useHistory, useParams, useRouteMatch } from "react-router";

import { getUserV3 } from "../../actions/company/users";
import { removeRoles, resetRemoveRoles } from "../../actions/user";
import PageTitle from "../../components/general/page-title";
import UserData from "../../components/selected-user-old/user-data"; // Old Component
import { Container, Content, TabsContainer, UserDataContainer } from "./styled";
import EditUser from "./edit";
import UserSummary from "./user-summary";
import Modal from "../../components/general/modal";
import LoadingButton from "../../components/general/loading-button";
import { displayErrorToast, displaySuccessToast } from "../../actions/overflowNotification";

const actions = ["edit", "summary"];

function getActionIndex(action) {
    return actions.findIndex(a => a === action) || 0;
}

const SelectedUserView = ({
    displayErrorToast,
    displaySuccessToast,
    getUserV3,
    getUserStatus,
    removeRoles,
    removeRolesFromUserStatus,
    resetRemoveRoles,
    selectedUserProfile,
    selectedUserStatus
}) => {
    const history = useHistory();
    const { action, item, selectedUserId } = useParams();
    const { path } = useRouteMatch();
    const [selectedTab, setSelectedTab] = useState(0);

    const [itemForRemoval, setItemForRemoval] = useState();
    const [removalModalOpen, setRemovalModalOpen] = useState(false);

    useEffect(() => {
        setSelectedTab(getActionIndex(action));
    }, [action]);

    useEffect(() => {
        selectedUserId && getUserV3(selectedUserId);
    }, [getUserV3, selectedUserId]);

    const handleTabChange = useCallback(
        index => {
            const targetAction = actions[index];
            const targetPath = generatePath(path, { item, selectedUserId, action: targetAction });
            history.replace(targetPath);
        },
        [history, item, selectedUserId, path]
    );

    const removeUser = useCallback(() => {
        if (itemForRemoval && selectedUserProfile) {
            removeRoles(itemForRemoval, selectedUserProfile.id);
            setRemovalModalOpen(false);

            // If on the user edit page, the user must be sent back to the user list
            if (action === "edit") {
                history.push(`/${item}/registry/user`);
            }
        }
    }, [action, history, removeRoles, selectedUserProfile, item, itemForRemoval]);

    const handleRemoveUser = useCallback(i => {
        setRemovalModalOpen(true);
        setItemForRemoval(i);
    }, []);

    useEffect(() => {
        if (removeRolesFromUserStatus.ended) {
            displaySuccessToast(
                <FormattedMessage id="c-registry-user.remove-roles.toast-success.content" />,
                <FormattedMessage id="c-registry-user.remove-roles.toast-success.title" />
            );
            resetRemoveRoles();
        }
    }, [displaySuccessToast, removeRolesFromUserStatus.ended, resetRemoveRoles]);

    useEffect(() => {
        if (removeRolesFromUserStatus.error) {
            displayErrorToast(
                <FormattedMessage id="c-registry-user.remove-roles.toast-error.content" />,
                <FormattedMessage id="c-registry-user.remove-roles.toast-error.title" />
            );
            resetRemoveRoles();
        }
    }, [displayErrorToast, removeRolesFromUserStatus.error, resetRemoveRoles]);

    return (
        <>
            <Modal
                type={"danger"}
                visible={removalModalOpen}
                footer={
                    <>
                        <Button colorScheme={"ghost"} kind="secondary" onClick={() => setRemovalModalOpen(false)}>
                            <FormattedMessage id="general.annulla" />
                        </Button>
                        <LoadingButton
                            colorScheme={"alert"}
                            loading={removeRolesFromUserStatus.started}
                            onClick={removeUser}
                            data-gainsight-track={"confirm-delete-from-edit-or-summary"}
                        >
                            <FormattedMessage id="c-registry-user.options.delete" />
                        </LoadingButton>
                    </>
                }
                onCancel={() => {
                    setRemovalModalOpen(false);
                }}
                width={"450px"}
                title={<FormattedMessage id="c-registry-user.remove-roles.modal-title" />}
            >
                <FormattedMessage
                    id="c-registry-user.remove-roles.modal-content"
                    values={{ email: selectedUserProfile.id }}
                />
            </Modal>
            <PageTitle
                title={<FormattedMessage id="general.edit-users" />}
                subtitle={<FormattedMessage id="c-edit-user.page.subtitle" />}
                onBack={() => history.goBack()}
            />
            <Container>
                <UserDataContainer>
                    <UserData
                        active={selectedUserStatus.active}
                        email={selectedUserProfile.id}
                        loading={getUserStatus.started}
                        fullName={`${selectedUserProfile.firstName} ${selectedUserProfile.lastName}`}
                    />
                </UserDataContainer>
                <TabsContainer>
                    <StandardTabs activeIndex={selectedTab} size="small" onChange={handleTabChange}>
                        <Tab>
                            <FormattedMessage id="c-edit-user.page.edit.tab" />
                        </Tab>
                        <Tab>
                            <FormattedMessage id="c-edit-user.page.recap.tab" />
                        </Tab>
                    </StandardTabs>
                </TabsContainer>
                <Content>
                    <Route
                        path="/:item/registry/user/:selectedUserId/edit"
                        render={props => <EditUser {...props} onRemoveUser={handleRemoveUser} />}
                    />
                    <Route
                        path="/:item/registry/user/:selectedUserId/summary"
                        render={props => <UserSummary {...props} onRemoveUser={handleRemoveUser} />}
                    />
                </Content>
            </Container>
        </>
    );
};

const mapStateToProps = state => ({
    getUserStatus: state.getUserV3.status,
    removeRolesFromUserStatus: state.manageUser.removeRolesFromUser.status,
    selectedUserProfile: (state.getUserV3.user && state.getUserV3.user.profile) || {},
    selectedUserStatus: (state.getUserV3.user && state.getUserV3.user.status) || {}
});

export default connect(mapStateToProps, {
    displayErrorToast,
    displaySuccessToast,
    getUserV3,
    removeRoles,
    resetRemoveRoles
})(SelectedUserView);
