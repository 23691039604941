import React, { useState } from "react";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoginButton } from "@ts-digital/digital-login-component";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import CopyrightFooter from "../../components/copyright-footer";
import ContentSwitcher from "../../components/general/content-switcher";
import { COMMON_LOGIN_URL, TS_DIGITAL_IMAGES } from "../../config";
import { getRequestParams } from "../../utils/get-request-params";
import AlreadyHaveTsid from "./instructions/already-have-tsid";
import NotHaveTsid from "./instructions/not-have-tsid";
import {
    AdvertisingBox,
    AdvertisingContainer,
    Container,
    ContentWrapper,
    Description,
    EmailWrapper,
    Header,
    InfoContainer,
    InfoIcon,
    LoginContainer,
    Logo,
    LogoContainer,
    Sidebar,
    Subtitle,
    Title
} from "./styled";

const serviceNameById = {
    PORTALE: "v-invitation-prelogin.not-have-tsid.step2.body-copy.appName.portale",
    DigitalBox: "v-invitation-prelogin.not-have-tsid.step2.body-copy.appName.digitalBox"
};

const InvitationPrelogin = ({ auth, location: { search } }) => {
    const { email, firstName, lastName, serviceId, redirectTo } = getRequestParams(search) || { redirectTo: "/" };
    const decodedEmail = decodeURIComponent(email);

    const [selectedTab, setSelectedTab] = useState(0);

    const { formatMessage } = useIntl();

    const token = auth && auth.loginAuth && auth.loginAuth.securityToken;
    const appName = formatMessage({ id: serviceNameById[serviceId] || serviceNameById["PORTALE"] }); // Set the default appName

    const tabs = [
        <FormattedMessage id="v-invitation-prelogin.tabs.already-have-tsid" />,
        <FormattedMessage id="v-invitation-prelogin.tabs.not-have-tsid" />
    ];

    if (token) {
        return <Redirect to={redirectTo} />;
    }

    return (
        <Container>
            <Header>
                <FormattedMessage id="general.ts" />
            </Header>
            <ContentWrapper>
                <Sidebar>
                    <LogoContainer>
                        <Logo src={`${TS_DIGITAL_IMAGES}prelogin/logo-digital.svg`} alt="ts digital logo" />
                    </LogoContainer>
                    <LoginContainer>
                        <Title>
                            <FormattedMessage id="v-invitation-prelogin.hi" />
                            {firstName && lastName && (
                                <>
                                    <br />
                                    {firstName} {lastName}
                                </>
                            )}
                        </Title>
                        <Subtitle>
                            <FormattedMessage id="v-invitation-prelogin.subtitle" />
                        </Subtitle>
                        <Description>
                            <FormattedMessage
                                id="v-invitation-prelogin.description"
                                values={{
                                    b: chunks => <b>{chunks}</b>
                                }}
                            />
                        </Description>
                        <InfoContainer>
                            <InfoIcon>
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </InfoIcon>
                            <div>
                                <FormattedMessage
                                    id="v-invitation-prelogin.info"
                                    values={{
                                        b: chunks => <b>{chunks}</b>
                                    }}
                                />{" "}
                                <br />
                                <EmailWrapper>
                                    <b>{decodedEmail}</b>
                                </EmailWrapper>
                            </div>
                        </InfoContainer>
                        <LoginButton
                            displayedAppName="TeamSystem Digital"
                            mode={"redirect"}
                            buttonText={<FormattedMessage id="v-invitation-prelogin.login-button-text" />}
                            userEmail={email}
                            flowType="completeInvitation"
                            loginUrl={COMMON_LOGIN_URL}
                            loginMethod="TSID"
                            appId={serviceId || "PORTALE"}
                        />
                    </LoginContainer>
                    <CopyrightFooter />
                </Sidebar>
                <AdvertisingContainer>
                    <AdvertisingBox backgroundImage={`${TS_DIGITAL_IMAGES}invitation-prelogin/humans.png`}>
                        <div>
                            <ContentSwitcher selectedTab={selectedTab} tabs={tabs} onChange={setSelectedTab} />
                        </div>
                        {selectedTab === 0 && <AlreadyHaveTsid email={decodedEmail} />}
                        {selectedTab === 1 && <NotHaveTsid email={decodedEmail} appName={appName} />}
                    </AdvertisingBox>
                </AdvertisingContainer>
            </ContentWrapper>
        </Container>
    );
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        password: state.password
    };
}

export default connect(mapStateToProps)(InvitationPrelogin);
