import React, { Component } from "react";
import PropTypes from "prop-types";

export default class OfficeRow extends Component {
    static propTypes = {
        column: PropTypes.object.isRequired,
        isEnable: PropTypes.object.isRequired,
        onClick: PropTypes.func,
        openDelete: PropTypes.func,
        openEdit: PropTypes.func,
        openInfo: PropTypes.func,
        value: PropTypes.object
    };

    onDocumentClick() {
        const { value, onClick } = this.props;
        if (onClick) {
            onClick(value);
        }
    }

    renderMapMessage() {
        const { text, column, openDelete, openEdit, openInfo, isEnable, value } = this.props;

        let isMainOffice = value.base.id.endsWith("-000");
        let isGovCodeLinked = value.base.identifier.govCode ? true : false;

        if (column.type === "date") {
            return <div>{new Date(Number(text)).toLocaleDateString()}</div>;
        }
        if (column.type === "string") {
            return <div>{text}</div>;
        } else {
            return (
                <div
                    style={{
                        cursor: isEnable.status ? "pointer" : "",
                        fontSize: "20px"
                    }}
                >
                    <i
                        className="fa fa-info-circle"
                        onClick={isEnable.status ? openInfo : null}
                        style={{
                            margin: "0px 15px",
                            color: isEnable.status ? "" : isEnable.color
                        }}
                    />
                    <i
                        className="fa fa-pencil-alt"
                        onClick={isEnable.status && !value.base.inCreation ? openEdit : null}
                        style={{
                            margin: "0px 15px",
                            color: isEnable.status && !value.base.inCreation ? "" : isEnable.color
                        }}
                    />
                    <i
                        className="fa fa-trash"
                        onClick={
                            isEnable.status && !value.base.inCreation && !isGovCodeLinked && !isMainOffice
                                ? openDelete
                                : null
                        }
                        style={{
                            margin: "0px 15px",
                            color:
                                isEnable.status && !value.base.inCreation && !isGovCodeLinked && !isMainOffice
                                    ? "#e57373"
                                    : isEnable.color
                        }}
                    />
                </div>
            );
        }
    }

    render() {
        return <div>{this.renderMapMessage()}</div>;
    }
}
