import React from "react";
import { getRestCall } from "../../../../utils/rest-api-call";
import { METERING_API_URL } from "../../../../config";
import { displayErrorToast } from "../../../overflowNotification";
import { FormattedMessage } from "react-intl";
import { getUserProfile } from "../../../../utils/state-utils/get-user-profile";
import { getAuthWithAppName } from "../../../auth";

export const GET_BILLING_SUBSCRIPTIONS_START = "METERING_GET_BILLING_SUBSCRIPTIONS_START_";
export const GET_BILLING_SUBSCRIPTIONS_END = "METERING_GET_BILLING_SUBSCRIPTIONS_END";
export const GET_BILLING_SUBSCRIPTIONS_SUCCESS = "METERING_GET_BILLING_SUBSCRIPTIONS_SUCCESS";
export const GET_BILLING_SUBSCRIPTIONS_ERROR = "METERING_GET_BILLING_SUBSCRIPTIONS_ERROR";

export const getBillingSubscriptionsStart = () => ({
    type: GET_BILLING_SUBSCRIPTIONS_START
});

export const getBillingSubscriptionsEnd = () => ({
    type: GET_BILLING_SUBSCRIPTIONS_END
});

export const getBillingSubscriptionsSuccess = subscriptions => ({
    type: GET_BILLING_SUBSCRIPTIONS_SUCCESS,
    payload: {
        subscriptions
    }
});

export const getBillingSubscriptionsError = error => ({
    type: GET_BILLING_SUBSCRIPTIONS_ERROR,
    payload: {
        error
    }
});

export const getBillingSubscriptions = (item, userId) => async (dispatch, getState) => {
    dispatch(getBillingSubscriptionsStart());
    try {
        const auth = getAuthWithAppName(getState().auth);
        const { subscriptions } = await getRestCall(
            `${METERING_API_URL}/api/v1/usage/invoicing/${item}/subscriptions`,
            auth,
            { userId },
            dispatch,
            auth.refreshToken,
            getUserProfile(getState()),
            item
        );
        dispatch(getBillingSubscriptionsSuccess(subscriptions));
    } catch (error) {
        const displayError = displayErrorToast(<FormattedMessage id="metering.usage.subscriptions.billing.error" />);
        displayError(dispatch);
        dispatch(getBillingSubscriptionsError(error));
    }
    dispatch(getBillingSubscriptionsEnd());
};
