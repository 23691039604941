import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TitleContainer = styled.div`
    background-color: rgb(240, 240, 240);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    padding: 5px 20px;
`;

export const IconContainer = styled(FontAwesomeIcon)`
    cursor: pointer;
    margin-left: 10px;
`;
