import React from "react";
import PropTypes from "prop-types";
import { Button } from "@ts-digital/arc/antd";

import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import {
    WarningContainer,
    MessageContainer,
    MessageUriUnderline,
    TsUserContainer,
    TsUserProfileContainer,
    TsUserProfileFullname,
    TsUserProfileMail,
    TsDescriptionContainer
} from "../styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { TSID_PROFILE_URL } from "../../../../../config";

import { Initials } from "../../../../general/user-initials";

const TsIdInformation = ({ user }) => {
    const fullname = user.profile.firstName + " " + user.profile.lastName;
    const isLinkedWithTsId = user && user.profile.tsid !== null && user.profile.tsid !== undefined;

    const openProfileTab = () => {
        window.open(TSID_PROFILE_URL, "_blank").focus();
    };

    return (
        <div>
            {!isLinkedWithTsId && (
                <WarningContainer>
                    <FontAwesomeIcon icon={faExclamationTriangle} color="#fdb927" size="xl" />
                    <MessageContainer>
                        <b>
                            <FormattedMessage id={"general.attention"} />
                            :&nbsp;
                        </b>
                        <FormattedMessage id={"c-manage-data.edit-details.profile-warning"} />
                        &nbsp;
                        <MessageUriUnderline>
                            <FormattedHTMLMessage id={"c-manage-data.edit-details.profile-open-info"} />
                        </MessageUriUnderline>
                    </MessageContainer>
                </WarningContainer>
            )}

            {isLinkedWithTsId && (
                <div>
                    <TsDescriptionContainer>
                        <FormattedHTMLMessage id={"c-manage-data.edit-details.profile-description"} />
                    </TsDescriptionContainer>
                    <TsUserContainer>
                        <Initials name={fullname} size={"40px"} />
                        <TsUserProfileContainer>
                            <TsUserProfileFullname>
                                {user.profile.firstName} {user.profile.lastName}
                            </TsUserProfileFullname>
                            {isLinkedWithTsId ? (
                                <TsUserProfileMail>{user.profile.tsid}</TsUserProfileMail>
                            ) : (
                                <TsUserProfileMail>
                                    <FormattedMessage id="profile.header.tsID_state" />
                                    &nbsp;
                                    <FormattedMessage id="general.not-linked" />
                                </TsUserProfileMail>
                            )}
                        </TsUserProfileContainer>
                        {isLinkedWithTsId && (
                            <Button onClick={() => openProfileTab()} key={"personal-area"} variant="secondary">
                                <b>
                                    <FormattedMessage id="c-manage-data.edit-details.personal-area" />
                                </b>
                            </Button>
                        )}
                    </TsUserContainer>
                </div>
            )}
        </div>
    );
};

TsIdInformation.propTypes = {
    user: PropTypes.object
};

export default TsIdInformation;
