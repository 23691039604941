import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { compose } from "redux";
import { getFormValues } from "redux-form";

import { ENABLE_ITEM_COUNTRIES } from "../../../../config";
import { FormattedMessage, useIntl } from "react-intl";
import { classifierItemTypes } from "../../../../utils/constant";
import { SelectStringField } from "@ts-digital/arc/antd/SelectField";
import { form } from "@ts-digital/conv-redux-form";
import { companyProfileCreateSchema } from "../../../../utils/form-schema/company-profile-create";
import * as normalizeField from "../../../../utils/normalize-form";

import CountryField from "@ts-digital/arc/antd/CountryField";
import TextField from "@ts-digital/arc/antd/TextField";

import DataAddress from "../data-address";

import {
    CentralColumns,
    DataContainer,
    ErrorMsg,
    FormTitle,
    Button,
    LeftColumns,
    LeftCentralColumns,
    RightColumns,
    LoadingButtonContainer,
    TextFieldHideable
} from "./styled";

export const CreateForm = ({
    cancelButtonText,
    errorMsg,
    formValues,
    handleCancel,
    handleSubmit,
    isGestita,
    isLoading,
    submitButtonText
}) => {
    const { formatMessage } = useIntl();

    return (
        <>
            {!isGestita && (
                <FormTitle>
                    <FormattedMessage id="general.personalData" />
                </FormTitle>
            )}
            <DataContainer>
                {!isGestita && (
                    <LeftCentralColumns>
                        <SelectStringField
                            disabled={isLoading}
                            getOptionKeyFromValue={value => {
                                return value === "" ? undefined : value;
                            }}
                            name="classifier"
                            options={classifierItemTypes.map(p => {
                                return {
                                    value: p.id,
                                    label: formatMessage({
                                        id: "c-manage-data-company-create.classifier." + p.name
                                    }),
                                    searchMatcherString: p.name
                                };
                            })}
                            label={<FormattedMessage id="general.type" />}
                        />
                    </LeftCentralColumns>
                )}
                <LeftCentralColumns>
                    <TextField
                        disabled={isLoading}
                        name={"description"}
                        label={<FormattedMessage id="general.ragione-sociale" />}
                    />
                </LeftCentralColumns>
                <LeftColumns>
                    <CountryField
                        disabled={isLoading}
                        name="taxRegion"
                        enabledCountries={ENABLE_ITEM_COUNTRIES}
                        label={<FormattedMessage id="general.taxRegion" />}
                    />
                </LeftColumns>
                <CentralColumns>
                    <TextField
                        disabled={isLoading}
                        label={<FormattedMessage id="general.partita-iva" />}
                        name={"vatNumber"}
                        normalize={normalizeField.normalizeVatNumber}
                    />
                </CentralColumns>
                <RightColumns>
                    <TextFieldHideable
                        disabled={isLoading}
                        hide={formValues.taxRegion !== "IT"}
                        label={<FormattedMessage id="general.codice-fiscale" />}
                        name={"taxId"}
                        normalize={normalizeField.normalizeFiscalCode}
                    />
                </RightColumns>
                <DataAddress disabled={isLoading} formValues={formValues} />
            </DataContainer>

            <LoadingButtonContainer>
                <ErrorMsg>
                    {errorMsg && (
                        <FormattedMessage
                            id="general.error"
                            values={{
                                errMsg: errorMsg
                            }}
                        />
                    )}
                </ErrorMsg>
                {handleCancel && (
                    <Button loading={isLoading} onClick={handleCancel} variant="secondary">
                        {cancelButtonText}
                    </Button>
                )}
                <Button htmlType="submit" loading={isLoading} onClick={handleSubmit} variant="primary">
                    {submitButtonText}
                </Button>
            </LoadingButtonContainer>
        </>
    );
};

CreateForm.propTypes = {
    cancelButtonText: PropTypes.node,
    errorMsg: PropTypes.any,
    formValues: PropTypes.object,
    handleCancel: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    isGestita: PropTypes.bool,
    isLoading: PropTypes.bool,
    submitButtonText: PropTypes.node
};

CreateForm.defaultProps = {
    formValues: {},
    submitButtonText: <FormattedMessage id="general.save" />
};

const formDefinition = {
    asyncFields: ["taxId", "vatNumber"],
    form: "form-create-company",
    schema: companyProfileCreateSchema,
    enableReinitialize: true
};

function mapStateToProps(state) {
    return {
        formValues: getFormValues("form-create-company")(state)
    };
}

const composedHoc = compose(connect(mapStateToProps, {}), form(formDefinition));

export default composedHoc(CreateForm);
