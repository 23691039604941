import React, { Component } from "react";
import PropTypes from "prop-types";

import { Row, Col, Icon } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import { Link, Redirect } from "react-router-dom";
import { Button } from "@ts-digital/arc/antd/Button";

import "./style.css";

class SuccessPage extends Component {
    static propTypes = {
        redirectPath: PropTypes.string.isRequired,
        editMode: PropTypes.bool,
        resetUserInfo: PropTypes.func,
        resetUserRolesManage: PropTypes.func,
        intl: PropTypes.any.isRequired,
        techUser: PropTypes.bool
    };

    state = {
        redirect: false
    };

    componentDidMount() {
        const { resetUserInfo } = this.props;
        resetUserInfo();
    }

    redirect() {
        const { editMode, resetUserRolesManage, techUser } = this.props;

        // nel caso di modalità di modifica resetto lo stato redux
        if (editMode && !techUser) {
            resetUserRolesManage();
        }

        this.setState({ redirect: true });
    }

    render() {
        const { redirectPath, editMode, techUser } = this.props;
        const { redirect } = this.state;

        const message = editMode
            ? "c-manage-data.multicompany-create.user_success_edit"
            : techUser
            ? "c-manage-data.multicompany-tech-create.backfromsuccess"
            : "c-manage-data.multicompany-create.user_success";

        if (redirect) {
            return <Redirect to={redirectPath} />;
        } else {
            return (
                <div className="c-user-success">
                    <div className="content">
                        <Row justify="center" type="flex">
                            <Icon type="check-circle" className="check-icon" />
                        </Row>
                        <Row justify="center" type="flex">
                            <Col>
                                <h1>
                                    <FormattedMessage id="c-manage-data.multicompany-create.congratulations" />
                                </h1>
                            </Col>
                        </Row>
                        <Row justify="center" type="flex">
                            <Col>
                                <FormattedMessage id={message} />
                            </Col>
                        </Row>
                        <Row justify="center" type="flex">
                            <Link to={redirectPath}>
                                <Button className="back-button" variant="primary">
                                    <FormattedMessage id={"c-manage-data.multicompany-create.backfromsuccess"} />
                                </Button>
                            </Link>
                        </Row>
                    </div>
                </div>
            );
        }
    }
}

export default injectIntl(SuccessPage);
