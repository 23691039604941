import React from "react";

import { LoginButton } from "@ts-digital/digital-login-component";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router";

import { COMMON_LOGIN_URL, PORTALE_SERVICE_ID, TS_DIGITAL_IMAGES } from "../../../config";
import {
    AdvertisingContainer,
    Container,
    ContentWrapper,
    Header,
    LoginContainer,
    Logo,
    LogoContainer,
    Sidebar,
    Title
} from "./styled";
import { getRequestParams } from "../../../utils/get-request-params";
import Advertising from "../../../components/advertising";
import CopyrightFooter from "../../../components/copyright-footer";

const PreLogin = ({ auth, location: { search } }) => {
    const { redirectTo } = getRequestParams(search) || { redirectTo: "/" };

    const token = auth && auth.loginAuth && auth.loginAuth.securityToken;

    if (token) {
        return <Redirect to={redirectTo} />;
    }

    return (
        <Container>
            <Header>
                <FormattedMessage id="general.ts" />
            </Header>
            <ContentWrapper>
                <Sidebar>
                    <LogoContainer>
                        <Logo src={`${TS_DIGITAL_IMAGES}prelogin/logo-digital.svg`} alt="ts digital logo" />
                    </LogoContainer>
                    <LoginContainer>
                        <Title>
                            <FormattedMessage id="v-pre-login.title" />
                        </Title>
                        <LoginButton
                            displayedAppName="TeamSystem Digital"
                            mode={"redirect"}
                            buttonText="Accedi"
                            loginUrl={COMMON_LOGIN_URL}
                            loginMethod={"TSID"}
                            appId={PORTALE_SERVICE_ID}
                        />
                    </LoginContainer>
                    <CopyrightFooter />
                </Sidebar>
                <AdvertisingContainer>
                    <Advertising />
                </AdvertisingContainer>
            </ContentWrapper>
        </Container>
    );
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        password: state.password
    };
}

export default connect(mapStateToProps)(PreLogin);
