import styled from "styled-components";
import { Button } from "@ts-digital/vrc";
import { Icon } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Container = styled.div`
    background-color: ${props => props.theme.colors.background.white};
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 80vh;
`;

export const Content = styled.div`
    margin: 24px;
`;

export const Title = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 20px;
    font-family: Cairo;
    font-weight: normal;
    letter-spacing: 0.18px;
    line-height: 24px;
    text-transform: uppercase;

    margin-left: 24px;
    margin-top: 24px;
`;

export const Description = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 34px;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.25px;
    line-height: 40px;
    margin-left: 24px;

    width: 100%;
`;

export const TitleWrapper = styled.div`
    display: flex;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 2;

    margin-top: 40px;
    margin-right: 24px;
    margin-left: 24px;
`;

export const TableUpdateButton = styled(Button)`
    float: right;
`;

export const LoadingIcon = styled(Icon)`
    margin-right: 10px;
`;

export const RedoIcon = styled(FontAwesomeIcon)`
    margin-right: 5px;
`;

export const TitleContainer = styled.div`
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
`;

export const IconContainer = styled(FontAwesomeIcon)`
    cursor: pointer;
    margin-left: 10px;
`;
