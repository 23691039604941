import { Button } from "@ts-digital/vrc";
import styled from "styled-components";

export const ActionButton = styled(Button)`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    height: 38px;
    width: 40px;
    margin: 1px 0px;

    color: ${props => props.theme.colors.primary.richElectricBlue};

    // override FontAwesomeIcon's default font-size (16px)
    & > span {
        font-size: 14px;
    }
`;

export const AppLevelEditButton = styled(ActionButton)`
    align-self: flex-end;
`;

// Makes the button behave correctly with antd's Tooltip
AppLevelEditButton.__ANT_BUTTON = true;

export const AppLevelButtonContainer = styled.span`
    margin-left: 40px;
`;
