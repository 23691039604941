import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Name = styled.div`
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #005075;
`;

export const Company = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 0 10px;
    width: calc(50% - 40px);
`;

export const Info = styled.div`
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #152935;
`;

export const NotificationWrapper = styled.div`
    width: 50%;
`;

export const NotificationContainer = styled.div`
    float: ${props => (props.tsPay ? "right" : "none")};
    width: ${props => (props.tsPay ? "400px" : "100%")};
    border: 1px solid ${props => (props.tsPay ? "#005075" : "#fdb927")};
    background-color: #fff;
    border-bottom: 4px solid ${props => (props.tsPay ? "#005075" : "#fdb927")};
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
`;

export const InfoContainer = styled.div`
    width: 100%;
`;

export const NotificationDescription = styled.div`
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
`;

export const NotificationIconContainer = styled.div`
    font-size: 24px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => (props.tsPay ? "#005075" : "#fdb927")};
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const CloseIcon = styled(FontAwesomeIcon)`
    color: black;
    font-size: 16px;
    text-align: center;
    width: 40px;
`;

export const TsPayInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
