import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";

import { FormattedMessage, useIntl } from "react-intl";

import { Field } from "redux-form";
import { form } from "@ts-digital/conv-redux-form";
import { passwordSchema } from "../../../../../utils/form-schema/password";

import LoadingButton from "../../../../../components/general/loading-button";
import TextInput from "../../../../../components/general/form-text-input";

import { FormTitle, DataContainer, InputContainer } from "../styled";

export const ChangePasswordForm = ({ disabled, handleSubmit, loading }) => {
    const { formatMessage } = useIntl();

    return (
        <form onSubmit={handleSubmit}>
            <FormTitle>
                <FormattedMessage id="c-change-password.title" />
            </FormTitle>

            <DataContainer>
                <InputContainer>
                    <Field
                        name="oldPassword"
                        component={TextInput}
                        label={formatMessage({ id: "c-reset-password.expiredPassword.label" })}
                        placeholder={formatMessage({
                            id: "c-reset-password.expiredPassword.placeholder"
                        })}
                        type="password"
                        props={{
                            disabled: disabled
                        }}
                    />
                </InputContainer>

                <Tooltip
                    title={
                        <div>
                            <div>
                                <FormattedMessage id={"c-change-password.confirm.tooltip1"} />
                            </div>
                            <div>
                                <FormattedMessage id={"c-change-password.confirm.tooltip2"} />
                            </div>
                            <div>
                                <FormattedMessage id={"c-change-password.confirm.tooltip3"} />
                            </div>
                            <div>
                                <FormattedMessage id={"c-change-password.confirm.tooltip4"} />
                            </div>
                            <div>
                                <FormattedMessage id={"c-change-password.confirm.tooltip5"} />
                            </div>
                        </div>
                    }
                    placement="top"
                    overlayStyle={{ maxWidth: "600px" }}
                    trigger={["focus"]}
                >
                    <InputContainer>
                        <Field
                            name="password"
                            component={TextInput}
                            label={formatMessage({ id: "c-reset-password.newPassword.label" })}
                            placeholder={formatMessage({
                                id: "c-reset-password.newPassword.label"
                            })}
                            type="password"
                            props={{
                                disabled: disabled
                            }}
                        />
                    </InputContainer>
                </Tooltip>

                <InputContainer>
                    <Field
                        name="confirmPassword"
                        component={TextInput}
                        label={formatMessage({ id: "c-reset-password.confirmNewPassword.label" })}
                        placeholder={formatMessage({
                            id: "c-reset-password.confirmNewPassword.label"
                        })}
                        type="password"
                        props={{
                            disabled: disabled
                        }}
                    />
                </InputContainer>

                <LoadingButton htmlType="submit" disabled={disabled} loading={loading} variant="primary" label="">
                    <FormattedMessage id="c-reset-password.save.label" />
                </LoadingButton>
            </DataContainer>
        </form>
    );
};

ChangePasswordForm.propTypes = {
    disabled: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool
};

const formDefinition = {
    form: "change-password-form",
    schema: passwordSchema
};

const formDef = form(formDefinition);

export default formDef(ChangePasswordForm);
