import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@ts-digital/vrc";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
`;

export const InputContainer = styled.div`
    flex-grow: 1;
`;

export const SearchButton = styled(Button)`
    flex-grow: 0;
    flex-shrink: 1;
    min-width: 40px;

    align-self: flex-end;
    margin-bottom: 8px;

    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
`;

export const SearchIcon = styled(FontAwesomeIcon)`
    //3d adds a 24% opacity to the hex color
    filter: drop-shadow(1px 1px 0px ${props => `${props.theme.colors.text.yankeesBlue}3d`});
    color: ${props => props.theme.colors.background.whiteSmoke};
    font-size: 16px;
    font-weight: normal;
    height: 15px;
    letter-spacing: 0px;
    line-height: 15px;
    text-align: center;
    width: 16px;
`;
