import styled from "styled-components";
import { Collapse } from "antd";

export const Features = styled.div`
    flex: 1;
`;

export const FeaturesHeaderCell = styled.div`
    flex: 1;
    padding: 8px;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #1f292e;
`;

export const FeaturesHeader = styled.div`
    border-bottom: 1px solid #b9c8d0;
    padding: 0 32px 0 32px;
    display: flex;
`;

export const FeaturesRow = styled.div`
    color: #1f292e;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    padding: 0 32px 0 32px;
`;

export const FeaturesTableContentTd = styled.td`
    padding: 8px;
    flex: 1;
`;

export const PanelHeaderDiv = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 32px;
    align-items: center;
`;

export const PanelStyled = styled(Collapse.Panel)`
    & .ant-collapse-header {
        color: rgb(0, 80, 117) !important;
        font-family: Cairo !important;
    }
`;

export const CollapseTitle = styled.div`
    font-family: Cairo;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;c
    color: #004E75;
`;

export const CollapseRole = styled.div`
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #68777d;
`;

export const CollapseStyled = styled(Collapse)`
    & .ant-collapse-content,
    &.ant-collapse {
        border: none !important;
    }
    & .ant-collapse-content-box {
        padding: 0 !important;
        display: flex;
    }
`;
