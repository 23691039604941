import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Logo = styled.img`
    width: 100px;
    height: 100px;

    object-fit: contain;

    display: ${props => (props.hidden ? "none" : "inline;")};
`;

export const SpinContainer = styled.div`
    width: 100px;
    height: 100px;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    color: ${props => (props.editable ? "#5a6872" : "#005075")};
    font-size: 40px;
    background-color: ${props => (props.editable ? "#d8d8d9" : "#b9e6f6")};
    cursor: ${props => (props.editable ? "pointer" : "auto")};
    :hover {
        border: ${props => (props.editable ? "2px solid #005075" : "none")};
        color: #005075;
        background-color: #b9e6f6;
    }
    border-radius: 50%;

    display: ${props => (props.hidden ? "none" : "inherit;")};
`;

export const UserIcon = styled.div`
    width: 100px;
    height: 100px;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    color: ${props => (props.editable ? "#5a6872" : "#005075")};
    font-size: 40px;
    background-color: ${props => (props.editable ? "#d8d8d9" : "#b9e6f6")};
    cursor: ${props => (props.editable ? "pointer" : "auto")};
    :hover {
        border: ${props => (props.editable ? "2px solid #005075" : "none")};
        color: #005075;
        background-color: #b9e6f6;
    }
    border-radius: 50%;

    display: ${props => (props.hidden ? "none" : "inherit;")};
`;

export const CircularButton = styled.button`
    height: 25px;
    width: 25px;
    position: relative;
    top: 70px;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fdb927;
    font-size: 16px;
    color: #ffffff;
    border-radius: 50%;
    border: none;
    letter-spacing: 0.2px;
    line-height: 15px;
    font-family: Roboto;
    font-weight: 500;
    cursor: pointer;
    :hover {
        background: #00c3ea;
    }
`;

export const PhotoIcon = styled(FontAwesomeIcon)`
    filter: drop-shadow(0px 1px 0 rgba(21, 41, 53, 0.24));
    height: 15px;
`;

export const AddLogoTextDiv = styled.div`
    word-spacing: 60px;
    font-size: 25px;
    line-height: 1;
    text-align: center;
`;
