import React, { useState } from "react";

import { useFeature } from "../../../../hooks/use-feature";

import { FormattedMessage } from "react-intl";

import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AddLogoTextDiv, CircularButton, PhotoIcon, Logo, SpinContainer, UserIcon } from "./styled";

import { faCamera, faPen } from "@fortawesome/pro-solid-svg-icons";

export const ItemLogo = ({ editable, itemId, history, url, isLoading, defaultLogo, userNcsid }) => {
    const [imgLoading, setImgLoading] = useState(true);

    const editLogoEnabled = useFeature("ADD_LOGO");

    return (
        <>
            <SpinContainer
                editable={editable}
                hidden={(url && !imgLoading) || (!isLoading && !imgLoading) || (!isLoading && !url)}
            >
                <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
            </SpinContainer>

            <Logo
                hidden={imgLoading || !url}
                src={url && url.startsWith("http") ? url + "?" + performance.now() : url}
                onLoad={() => setImgLoading(false)}
            />

            <UserIcon
                editable={editable}
                hidden={!!(isLoading || url)}
                onClick={() => (editLogoEnabled && editable ? history.push(`/${itemId}/edit/editlogo`) : null)}
                data-gainsight-track={"logo-flow-B-dashboard"}
            >
                {editLogoEnabled && editable ? (
                    <AddLogoTextDiv>
                        <FormattedMessage id={"logo.add-logo-text"} />
                    </AddLogoTextDiv>
                ) : (
                    <FontAwesomeIcon icon={defaultLogo} />
                )}
            </UserIcon>

            {editLogoEnabled && editable && url && (
                <CircularButton onClick={() => history.push(`/${itemId}/edit/editlogo`)}>
                    <PhotoIcon icon={url ? faPen : faCamera} />
                </CircularButton>
            )}
        </>
    );
};
