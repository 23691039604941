import React from "react";
import PropTypes from "prop-types";

import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import StatusIcon from "../status-icon";
import { Container, TitleContainer, Title, Date, StatusIconContainer } from "./styled";

const CompactNotification = ({
    accepted,
    date,
    id,
    type,
    onChangeReadStatus,
    onClick,
    read,
    rejected,
    notification
}) => {
    const params = useParams();

    const handleNotificationStatus = notification => {
        let notificationStatus = null;
        if (notification.accepted) {
            notificationStatus = <FormattedMessage id="c-notification.status.accepted" />;
        } else if (notification.rejected) {
            notificationStatus = <FormattedMessage id="c-notification.status.rejected" />;
        }
        return notificationStatus;
    };

    return (
        <Link onClick={onClick} to={`/${params.item}/notifications/${id}`} className={"link"}>
            <Container>
                <TitleContainer>
                    <Title>
                        <FormattedMessage id={`notification.${notification.name}.title`} />
                    </Title>
                    <Date>{date}</Date>
                </TitleContainer>
                <div>
                    <FormattedMessage
                        id={`notification.${notification.name}`}
                        values={{
                            sender: notification.requesterName,
                            senderId: notification.requesterId,
                            recipient: notification.recipientName,
                            recipientId: notification.recipientId,
                            ...Object.entries(notification.extraValues).reduce((acc, v) => {
                                acc[v[0]] = <FormattedMessage id={v[1]} />;
                                return acc;
                            }, {})
                        }}
                    />
                </div>
                <br />
                <div>{handleNotificationStatus(notification)}</div>
                <StatusIconContainer>
                    <StatusIcon
                        onClick={onChangeReadStatus}
                        needsInteraction={type === "REQUEST" && !accepted && !rejected}
                        read={read}
                    />
                </StatusIconContainer>
            </Container>
        </Link>
    );
};

CompactNotification.propTypes = {
    accepted: PropTypes.bool,
    date: PropTypes.element,
    onChangeReadStatus: PropTypes.func,
    onClick: PropTypes.func,
    read: PropTypes.bool,
    rejected: PropTypes.bool,
    type: PropTypes.string.isRequired,
    notification: PropTypes.object
};

CompactNotification.defaultProps = {
    acceptedOrRejected: false,
    fullWidth: false,
    onClick: () => {}
};

export default CompactNotification;
