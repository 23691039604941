import React from "react";
import PropTypes from "prop-types";

import { includes } from "ramda";
import { compose } from "redux";
import { connect } from "react-redux";

import "../style.css";

import { getAdempimentiConfig, resetAdempimentiConfig } from "../../../actions/services/a-fiscali-iva";
import { getCbCrConfig } from "../../../actions/services/cbcr";
import { activateService } from "../../../actions/services/service-subscription";
import { listSigners } from "../../../actions/sign-api-read";
import { getWaitingList } from "../../../actions/waitinglist/read";
import { subscribeWaitinglist, deleteWaitinglist } from "../../../actions/waitinglist/write";

import Fulfilments from "../../../components/fulfilments";
import { FormattedMessage } from "react-intl";
import { errorBoundary } from "../../../components/general/error-boundary";
import PageTitle from "../../../components/general/page-title";

class FulfilmentsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCard: 0
        };
    }

    static propTypes = {
        activateService: PropTypes.func.isRequired,
        company: PropTypes.object.isRequired,
        getAdempimentiConfig: PropTypes.func.isRequired,
        getCbCrConfig: PropTypes.func.isRequired,
        history: PropTypes.object,
        listSigners: PropTypes.func.isRequired,
        match: PropTypes.object.isRequired,
        resetAdempimentiConfig: PropTypes.func.isRequired,
        services: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired
    };

    componentDidMount() {
        const { company, getAdempimentiConfig, getCbCrConfig, listSigners, resetAdempimentiConfig, services } =
            this.props;
        if (services.active.services) {
            if (includes("A-FISCALI-IVA-FLOW", services.active.services)) {
                resetAdempimentiConfig();
                getAdempimentiConfig(company.base.id);
                listSigners(company.base.id, "A-FISCALI-IVA-FLOW");
            }
            if (includes("CBCR-FLOW", services.active.services)) {
                getCbCrConfig(company.base.id, company.base.id);
            }
        }
    }

    render() {
        const {
            activateService,
            company,
            history,
            listSigners,
            match,
            services,
            user,
            waitinglist,
            waitinglistSubscribeStatus,
            getWaitingList,
            getWaitingListStatus,
            subscribeWaitinglist,
            deleteWaitinglist,
            waitinglistDeleteStatus
        } = this.props;
        return (
            <div className="service-view">
                <PageTitle title={<FormattedMessage id="c-services-view.TEL.name" />} onBack={() => history.goBack()} />
                <Fulfilments
                    activateService={activateService}
                    company={company}
                    listSigners={listSigners}
                    match={match}
                    services={services}
                    user={user}
                    waitinglist={waitinglist}
                    waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                    getWaitingList={getWaitingList}
                    getWaitingListStatus={getWaitingListStatus}
                    subscribeWaitinglist={subscribeWaitinglist}
                    deleteWaitinglist={deleteWaitinglist}
                    waitinglistDeleteStatus={waitinglistDeleteStatus}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    services: state.services,
    user: state.user,
    waitinglist: state.waitinglist.read.getWaitingList.waitinglist,
    waitinglistSubscribeStatus: state.waitinglist.write.subscribeWaitinglist.status,
    waitinglistDeleteStatus: state.waitinglist.write.deleteWaitinglist.status,
    getWaitingListStatus: state.waitinglist.read.getWaitingList.status
});

const actions = {
    activateService,
    getAdempimentiConfig,
    getCbCrConfig,
    listSigners,
    resetAdempimentiConfig,
    getWaitingList,
    subscribeWaitinglist,
    deleteWaitinglist
};

const composedHoc = compose(connect(mapStateToProps, actions), errorBoundary);

export default composedHoc(FulfilmentsView);
