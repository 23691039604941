import {
    GET_RELEASENOTES_DATE_START,
    GET_RELEASENOTES_DATE_ERROR,
    GET_RELEASENOTES_DATE_SUCCESS
} from "../actions/releasenotes";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    },
    lastReleaseNoteDate: null,
    tags: []
};

export function releasenotes(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_RELEASENOTES_DATE_START:
            return {
                ...state,
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_RELEASENOTES_DATE_SUCCESS:
            return {
                ...state,
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                lastReleaseNoteDate: payload.date,
                tags: payload.tags
            };
        case GET_RELEASENOTES_DATE_ERROR:
            return {
                ...state,
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { message: error }
                }
            };
        default:
            return state;
    }
}
