import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const Logo = styled.img`
    object-fit: contain;
    width: 150px;
    height: 150px;
`;

export const LogoForm = styled.img`
    object-fit: contain;
    width: 150px;
    height: 150px;
`;

export const LoadingContainer = styled.label`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

export const CircularButton = styled.button`
    height: 30px;
    width: 30px;
    position: relative;
    top: -30px;
    right: ${props => (props.loadedImg ? "-130px" : "-100px")};
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fdb927;
    font-size: 16px;
    color: #ffffff;
    border-radius: 50%;
    border: none;
    letter-spacing: 0.2px;
    line-height: 20px;
    font-family: Roboto;
    font-weight: 500;
    cursor: pointer;
    :hover {
        background: #00c3ea;
    }
`;

export const EditIcon = styled(FontAwesomeIcon)`
    height: 20px;
    filter: drop-shadow(0px 1px 0 rgba(21, 41, 53, 0.24));
`;

export const UploadContainer = styled.div`
    width: 150px;
    height: 150px;

    color: ${props => (props.editable ? "#5a6872" : "#005075")};
    font-size: 40px;
    background-color: ${props => (props.editable ? "#d8d8d9" : "#b9e6f6")};
    cursor: ${props => (props.editable ? "pointer" : "auto")};
    :hover {
        border: ${props => (props.editable ? "2px solid #005075" : "none")};
        color: #005075;
        background-color: #b9e6f6;
    }
    border-radius: 50%;

    display: inherit;
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

export const CompanyIconContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

export const CompanyIcon = styled(FontAwesomeIcon)`
    height: 60px !important;
    width: 60px !important;
`;

export const ButtonFormContainer = styled.div`
    margin-top: 20px;
`;

export const AddLogoText = styled.div`
    word-spacing: 60px;
    line-height: 1;
    text-align: center;
`;
