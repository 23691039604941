/**
 * Metodo per verificare se l'utente loggato può visualizzare la category dei servizi.
 * @param {*} companyId
 * @param {*} roles
 * @param {*} category categories.data[category]
 * @param {*} availableToUser services.active.availableToUser
 * @param {*} services services.active.services
 * @returns true se almeno un'app della category è visualizzabile dall'utente
 */
export function isCategoryAvailable(companyId, roles, category, availableToUser, services) {
    return (
        category &&
        category.services.reduce(
            (acc, current) => isAppAvailable(companyId, roles, current.id, availableToUser, category, services) || acc,
            false
        )
    );
}

/**
 * Metodo per verificare se l'utente loggato può visualizzare l'app.
 * @param {*} companyId
 * @param {*} roles
 * @param {*} serviceId id dell'app
 * @param {*} availableToUser services.active.availableToUser
 * @param {*} category categories.data[category]
 * @param {*} services services.active.services
 * @returns true se il servizio è visualizzabile dall'utente
 */
export function isAppAvailable(companyId, roles, serviceId, availableToUser, category, services) {
    let categoryService = category && category.services && category.services.filter(s => s.id === serviceId)[0];
    return (
        ((availableToUser.hasOwnProperty(serviceId) ? availableToUser[serviceId] : false) &&
            services.filter(s => s === serviceId).length > 0) ||
        (isAdmin(roles, companyId) && categoryService && categoryService.active)
    );
}

/******************************************
 * Metodo per verificare se l'utente loggato è amministratore dell'azienda.
 ******************************************/
export function isAdmin(roles, companyId) {
    return roles.filter(v => v.resourceId === companyId && v.appId == null).length > 0;
}
