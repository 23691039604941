import React from "react";

import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Button } from "@ts-digital/arc/antd/Button";

import FontawesomeIcon from "../../components/general/fontawesome-icon";
import { Container, MessageContainer } from "./styled";

export const UserActivationSuccess = ({ match }) => {
    return (
        <Container>
            <FontawesomeIcon color="#4da920bf" name="check-circle" solid={false} size="60px" />
            <MessageContainer>
                <FormattedMessage
                    id="v-user-activation-success.message"
                    values={{ user: decodeURIComponent(match.params.userId) }}
                />
            </MessageContainer>
            <Link to="/login">
                <Button type="primary">
                    <FormattedMessage id="general.back-to-home" />
                </Button>
            </Link>
        </Container>
    );
};
