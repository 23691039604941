import { CONNECTION_READ_API } from "../../config";
import { getAuthWithAppName } from "../auth";

import { getRestCall } from "../../utils/rest-api-call";
import { getUserProfile } from "../../utils/state-utils/get-user-profile";

export const FIND_OWN_MANAGED_CONNECTIONS_START = "FIND_OWN_MANAGED_CONNECTIONS_START";
export const FIND_OWN_MANAGED_CONNECTIONS_SUCCESS = "FIND_OWN_MANAGED_CONNECTIONS_SUCCESS";
export const FIND_OWN_MANAGED_CONNECTIONS_ERROR = "FIND_OWN_MANAGED_CONNECTIONS_ERROR";
export const FIND_OWN_MANAGED_CONNECTIONS_RESET = "FIND_OWN_MANAGED_CONNECTIONS_RESET";

export function findOwnManagedConnections(filter, params) {
    return async (dispatch, getState) => {
        dispatch({ type: FIND_OWN_MANAGED_CONNECTIONS_START });

        const urlParams = {
            ...filter,
            page: params.page,
            size: params.size ? params.size : 5,
            sort: "id,desc"
        };

        try {
            const auth = getAuthWithAppName(getState().auth);
            const result = await getRestCall(
                `${CONNECTION_READ_API}/connections/managed`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                filter.managerIds[0],
                false
            );

            dispatch({
                type: FIND_OWN_MANAGED_CONNECTIONS_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: FIND_OWN_MANAGED_CONNECTIONS_ERROR,
                error: e
            });
        }
    };
}

export function findOwnManagedConnectionsReset() {
    return dispatch => {
        dispatch({
            type: FIND_OWN_MANAGED_CONNECTIONS_RESET
        });
    };
}

export const ARE_LINKED_ITEMS_START = "ARE_LINKED_ITEMS_START";
export const ARE_LINKED_ITEMS_SUCCESS = "ARE_LINKED_ITEMS_SUCCESS";
export const ARE_LINKED_ITEMS_ERROR = "ARE_LINKED_ITEMS_ERROR";

export function areLinkedItems(managerId, ids) {
    return async (dispatch, getState) => {
        dispatch({ type: ARE_LINKED_ITEMS_START });

        const urlParams = {
            managerIds: [managerId],
            managedIds: ids,
            deleted: false,
            page: 0,
            size: 5,
            sort: "id,desc"
        };

        try {
            const auth = getAuthWithAppName(getState().auth);
            const result = await getRestCall(
                `${CONNECTION_READ_API}/connections/managed`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                managerId,
                false
            );

            dispatch({
                type: ARE_LINKED_ITEMS_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: ARE_LINKED_ITEMS_ERROR,
                error: e
            });
        }
    };
}

export const FIND_OWN_MANAGER_CONNECTIONS_START = "FIND_OWN_MANAGER_CONNECTIONS_START";
export const FIND_OWN_MANAGER_CONNECTIONS_SUCCESS = "FIND_OWN_MANAGER_CONNECTIONS_SUCCESS";
export const FIND_OWN_MANAGER_CONNECTIONS_ERROR = "FIND_OWN_MANAGER_CONNECTIONS_ERROR";
export const FIND_OWN_MANAGER_CONNECTIONS_RESET = "FIND_OWN_MANAGER_CONNECTIONS_RESET";

export function findOwnManagerConnections(filter, params) {
    return async (dispatch, getState) => {
        dispatch({ type: FIND_OWN_MANAGER_CONNECTIONS_START });

        const urlParams = {
            ...filter,
            page: params.page,
            size: 5
        };

        try {
            const auth = getAuthWithAppName(getState().auth);
            const result = await getRestCall(
                `${CONNECTION_READ_API}/connections/manager`,
                auth,
                urlParams,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                filter.managedIds[0],
                false
            );

            dispatch({
                type: FIND_OWN_MANAGER_CONNECTIONS_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: FIND_OWN_MANAGER_CONNECTIONS_ERROR,
                error: e
            });
        }
    };
}

export function findOwnManagerConnectionsReset() {
    return dispatch => dispatch({ type: FIND_OWN_MANAGER_CONNECTIONS_RESET });
}

export const GET_CONNECTION_START = "GET_CONNECTION_START";
export const GET_CONNECTION_SUCCESS = "GET_CONNECTION_SUCCESS";
export const GET_CONNECTION_ERROR = "GET_CONNECTION_ERROR";

export function getConnection(id) {
    return async (dispatch, getState) => {
        dispatch({ type: GET_CONNECTION_START });

        try {
            const auth = getAuthWithAppName(getState().auth);
            const result = await getConnectionRestCall(auth, id, dispatch, getUserProfile(getState()));

            dispatch({
                type: GET_CONNECTION_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: GET_CONNECTION_ERROR,
                error: e
            });
        }
    };
}

export async function getConnectionRestCall(auth, id, dispatch, userProfile) {
    return await getRestCall(
        `${CONNECTION_READ_API}/connections/${id}`,
        getAuthWithAppName(auth),
        {},
        dispatch,
        auth.refreshToken,
        userProfile,
        undefined,
        false
    );
}
