import {
    GET_COMPANIES_START,
    GET_COMPANIES_ERROR,
    GET_COMPANIES_SUCCESS,
    GET_COMPANIES_RESET,
    GET_COMPANIES_CHANGE_PAGE,
    GET_COMPANIES_START_ADDROLESELECTION,
    GET_COMPANIES_SUCCESS_ADDROLESELECTION,
    GET_COMPANIES_ERROR_ADDROLESELECTION,
    GET_ALL_COMPANIES_ADDROLESELECTION_START,
    GET_ALL_COMPANIES_ADDROLESELECTION_SUCCESS,
    GET_ALL_COMPANIES_ADDROLESELECTION_ERROR,
    GET_STUDIO_COMPANIES_START,
    GET_STUDIO_COMPANIES_SUCCESS,
    GET_STUDIO_COMPANIES_ERROR,
    CREATE_ITEM_START,
    CREATE_ITEM_ERROR,
    CREATE_ITEM_SUCCESS,
    CREATE_ITEM_RESET,
    UPDATE_ITEM_START,
    UPDATE_ITEM_ERROR,
    UPDATE_ITEM_SUCCESS,
    UPDATE_ITEM_RESET,
    STORE_PAGE
} from "../actions/companies.js";

import {
    UPDATE_OFFICE_START,
    UPDATE_OFFICE_SUCCESS,
    UPDATE_OFFICE_ERROR,
    UPDATE_OFFICE_RESET,
    CREATE_OFFICE_SUCCESS,
    DISABLE_OFFICE_SUCCESS
} from "../actions/company/offices.js";

import { LOAD_COMPANY_SUCCESS } from "../actions/company.js";

import { UPLOAD_CERT_SUCCESS } from "../actions/certification";

import { findIndex } from "lodash";
import { LIST_ITEM_SUCCESS } from "../actions/registry/read.js";

import { LIST_USERS_SUCCESS } from "../actions/company/users.js";
import {
    LIST_EDITABLE_USERS_SUCCESS,
    LIST_EDITABLE_USERS_START,
    LIST_EDITABLE_USERS_ERROR
} from "../actions/company/editableUsers.js";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    },
    items: {}
};

/*
 * Le chiavi data, selectedCompany, pagination sono state aggiunte per riorganizzare lo stato.
 * data: i dati degli item che sono stati caricati nella storia e vengono cachati.
 * selectedCompany: l'id dell'item corrente
 * pagination: i dati relativi all'ultima pagina visualizzata
 *
 * Utilizzare questi per gli sviluppi futuri, in  previsione di eliminare i duplicati.
 */
export function data(state = {}, { type, payload }) {
    switch (type) {
        case GET_COMPANIES_SUCCESS:
            const itemsByUuid = Object.values(payload.items).reduce((acc, curr) => {
                acc[curr.item.base.uuid] = curr;
                return acc;
            }, {});
            return {
                ...state,
                ...payload.items,
                ...itemsByUuid
            };
        case LOAD_COMPANY_SUCCESS:
            const itemData = {
                item: payload,
                users:
                    state[payload.base.id] && state[payload.base.id].users
                        ? { ...state[payload.base.id].users }
                        : undefined,
                editableUsers:
                    state[payload.base.id] && state[payload.base.id].editableUsers
                        ? { ...state[payload.base.id].editableUsers }
                        : undefined
            };
            return {
                ...state,
                [payload.base.id]: { ...itemData },
                [payload.base.uuid]: { ...itemData }
            };
        case UPLOAD_CERT_SUCCESS:
            return {
                ...state,
                [payload.companyId]: {
                    item: {
                        base: {
                            ...state[payload.companyId].item.base,
                            status: {
                                ...state[payload.companyId].item.base.status,
                                status:
                                    state[payload.companyId].item.base.status.status === "VALIDATED"
                                        ? "VALIDATED"
                                        : "UNVERIFIABLE_PENDING_VALIDATE",
                                certificationStatus: "AWAITING_APPROVAL"
                            }
                        }
                    }
                }
            };
        case LIST_USERS_SUCCESS:
            const newItemData = payload.reduce((acc, curr) => {
                // TODO - necessario per poter gestire lo stato doppio [CF/UUIDv4],
                // senza questo veniva aggiornato solamente in versione UUIDv4.
                // Il portale tuttavia ragiona ancora in gran parte in versione CF è quindi
                // necessario gestire anche questo caso.
                // Da togliere al passaggio in versione UUIDv4 perchè da item.base.id non arriverà
                // più il CF ma l'UUIDv4.
                const fiscalCode = state[curr.itemId].item.base.id;

                return {
                    ...acc,
                    [curr.itemId]: {
                        item: state[curr.itemId] && state[curr.itemId].item ? { ...state[curr.itemId].item } : {},
                        users: curr.res,
                        editableUsers:
                            state[curr.itemId] && state[curr.itemId].item ? { ...state[curr.itemId].editableUsers } : {}
                    },
                    [fiscalCode]: {
                        item: state[curr.itemId] && state[curr.itemId].item ? { ...state[curr.itemId].item } : {},
                        users: curr.res,
                        editableUsers:
                            state[curr.itemId] && state[curr.itemId].item ? { ...state[curr.itemId].editableUsers } : {}
                    }
                };
            }, {});
            return {
                ...state,
                ...newItemData
            };
        case LIST_EDITABLE_USERS_START:
            return {
                ...state,
                [payload.itemId]: {
                    item: state[payload.itemId] && state[payload.itemId].item ? { ...state[payload.itemId].item } : {},
                    users:
                        state[payload.itemId] && state[payload.itemId].users ? { ...state[payload.itemId].users } : {},
                    editableUsers:
                        state[payload.itemId] && state[payload.itemId].editableUsers
                            ? {
                                  ...state[payload.itemId].editableUsers,
                                  status: {
                                      started: true,
                                      error: false,
                                      ended: false
                                  }
                              }
                            : {}
                }
            };
        case LIST_EDITABLE_USERS_ERROR:
            return {
                ...state,
                [payload.itemId]: {
                    item: state[payload.itemId] && state[payload.itemId].item ? { ...state[payload.itemId].item } : {},
                    users:
                        state[payload.itemId] && state[payload.itemId].users ? { ...state[payload.itemId].users } : {},
                    editableUsers:
                        state[payload.itemId] && state[payload.itemId].editableUsers
                            ? {
                                  ...state[payload.itemId].editableUsers,
                                  status: {
                                      started: false,
                                      error: true,
                                      ended: false,
                                      errorInfo: {
                                          code: "",
                                          message: ""
                                      }
                                  }
                              }
                            : {}
                }
            };
        case LIST_EDITABLE_USERS_SUCCESS:
            return {
                ...state,
                [payload.itemId]: {
                    item: state[payload.itemId] && state[payload.itemId].item ? { ...state[payload.itemId].item } : {},
                    users:
                        state[payload.itemId] && state[payload.itemId].users ? { ...state[payload.itemId].users } : {},
                    editableUsers: {
                        editableUsers: payload.res.editableUsers,
                        status: {
                            started: false,
                            error: false,
                            ended: true
                        }
                    }
                }
            };
        case CREATE_OFFICE_SUCCESS:
            const layers = state[payload.parentId].item.layers ? [...state[payload.parentId].item.layers] : [];
            return {
                ...state,
                [payload.parentId]: {
                    item: {
                        ...state[payload.parentId].item,
                        layers: [
                            {
                                base: {
                                    ...payload.item.base,
                                    id: "Non disponibile",
                                    inCreation: true,
                                    status: {
                                        active: true,
                                        createdAt: new Date(),
                                        modifiedAt: new Date()
                                    },
                                    details: {
                                        ...payload.item.base.details,
                                        addresses: [
                                            {
                                                ...payload.item.base.details.addresses[0],
                                                fullAddress:
                                                    payload.item.base.details.addresses[0].streetName +
                                                    " " +
                                                    payload.item.base.details.addresses[0].streetNumber +
                                                    ", " +
                                                    payload.item.base.details.addresses[0].city +
                                                    " (" +
                                                    payload.item.base.details.addresses[0].province +
                                                    ") " +
                                                    payload.item.base.details.addresses[0].zipCode +
                                                    " - " +
                                                    payload.item.base.details.addresses[0].country
                                            }
                                        ]
                                    },
                                    identifier: {
                                        ...payload.item.base.identifier,
                                        govCode: payload.item.base.identifier.govCode
                                    }
                                }
                            },
                            ...layers
                        ]
                    }
                }
            };
        case UPDATE_OFFICE_SUCCESS:
            let companyId = payload.item.base.identifier.taxId;
            let index = findIndex(state[companyId].item.layers, v => {
                return v.base.id === payload.layerId;
            });

            let replaceOffice = {
                ...state[companyId].item.layers[index],
                base: {
                    ...state[companyId].item.layers[index].base,
                    details: {
                        ...state[companyId].item.layers[index].base.details,
                        addresses: [payload.item.base.details.addresses[0]],
                        description: payload.item.base.details.description
                    },
                    identifier: {
                        ...state[companyId].item.layers[index].base.identifier,
                        govCode: payload.item.base.identifier.govCode
                    }
                }
            };

            let newLayers = state[companyId].item.layers;
            newLayers.splice(index, 1, replaceOffice);
            return {
                ...state,
                [companyId]: {
                    item: {
                        ...state[companyId].item,
                        layers: newLayers
                    }
                }
            };
        case DISABLE_OFFICE_SUCCESS:
            let idx = findIndex(state[payload.companyId].item.layers, v => {
                return v.base.id === payload.layerId;
            });
            state[payload.companyId].item.layers[idx].base.status.active = false;

            return {
                ...state
            };
        case CREATE_ITEM_SUCCESS:
        case UPDATE_ITEM_SUCCESS:
            return {
                ...state,
                [payload.item.base.id]: {
                    ...state[payload.item.base.id],
                    item: payload.item
                }
            };
        default:
            return state;
    }
}

const defaultPagination = {
    size: 30,
    total: 0,
    currentPage: 0,
    0: { content: [] }
};

export function pagination(state = defaultPagination, { type, payload }) {
    switch (type) {
        case GET_COMPANIES_SUCCESS:
            // se cambiano size o numero totale di elementi lo stato viene pulito
            return payload.size !== state.size || Number(payload.totalItems) !== state.total
                ? {
                      ...defaultPagination,
                      currentPage: payload.page,
                      size: payload.size,
                      total: Number(payload.totalItems),
                      [payload.page]: {
                          content: Object.keys(payload.items)
                      }
                  }
                : {
                      ...state,
                      currentPage: payload.page,
                      size: payload.size,
                      total: Number(payload.totalItems),
                      [payload.page]: {
                          content: Object.keys(payload.items)
                      }
                  };
        case GET_COMPANIES_CHANGE_PAGE:
            return { ...state, currentPage: payload };
        default:
            return state;
    }
}

export function total(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_COMPANIES_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_COMPANIES_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_COMPANIES_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case GET_COMPANIES_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}
export function createItem(state = defaultState, { type, payload, error }) {
    switch (type) {
        case CREATE_ITEM_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case CREATE_ITEM_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case CREATE_ITEM_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case CREATE_ITEM_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}
export function updateItem(state = defaultState, { type, payload, error }) {
    switch (type) {
        case UPDATE_ITEM_START:
        case UPDATE_OFFICE_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case UPDATE_ITEM_SUCCESS:
        case UPDATE_OFFICE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case UPDATE_ITEM_ERROR:
        case UPDATE_OFFICE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case UPDATE_ITEM_RESET:
        case UPDATE_OFFICE_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

const totalRoleAddDefaultState = {
    ...defaultState,
    displayedPage: []
};

export function totalRoleAdd(state = totalRoleAddDefaultState, { type, payload, error }) {
    switch (type) {
        case GET_ALL_COMPANIES_ADDROLESELECTION_START:
        case GET_COMPANIES_START_ADDROLESELECTION:
            return {
                ...state,
                status: {
                    started: true,
                    error: false,
                    ended: false
                },
                displayedPage: state.displayedPage
            };
        case GET_COMPANIES_SUCCESS_ADDROLESELECTION:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                items: { ...state.items, ...payload.items },
                totalItems: payload.totalItems,
                displayedPage: Object.keys(payload.items)
            };
        case GET_ALL_COMPANIES_ADDROLESELECTION_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                items: { ...payload.items },
                totalItems: payload.totalItems,
                displayedPage: state.displayedPage
            };
        case LIST_ITEM_SUCCESS:
            return {
                ...state,
                items: {
                    ...state.items,
                    ...payload.items.reduce((itemsObject, item) => {
                        itemsObject[item.item.base.id] = item;
                        return itemsObject;
                    }, {})
                }
            };
        case GET_ALL_COMPANIES_ADDROLESELECTION_ERROR:
        case GET_COMPANIES_ERROR_ADDROLESELECTION:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case GET_COMPANIES_RESET:
            return totalRoleAddDefaultState;
        default:
            return state;
    }
}
export function dataStudio(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_STUDIO_COMPANIES_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                },
                items: {},
                totalStudio: 0
            };
        case GET_STUDIO_COMPANIES_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                items: {
                    ...payload.items
                },
                totalStudio: Object.keys(payload.items).length
            };
        case GET_STUDIO_COMPANIES_ERROR:
            return {
                ...state,
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
const defaultHome = { pageSize: 30, currentPage: 0, searchText: "" };

export function homePage(state = defaultHome, { type, payload }) {
    switch (type) {
        case STORE_PAGE:
            return payload;
        default:
            return state;
    }
}
