import styled from "styled-components";

export const Card = styled.div`
    box-shadow: 0 2px 8px 0 rgba(21, 41, 53, 0.32);
    background-color: #fff;
    min-height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
`;

export const CardContent = styled.div`
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 8px;
`;

export const CardActionsBar = styled.div`
    width: 100%;
    height: 48px;
    background-color: #f2f5f8;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

export const Logo = styled.img`
    width: 70%;
    margin: 0 auto;
`;

export const Description = styled.div`
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    letter-spacing: 0.25px;
    text-align: left;
    color: #5a6872;
    margin: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const NewFlagContainer = styled.div`
    position: absolute;
    top: 8px;
    right: 0px;
`;
