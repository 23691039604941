import { translateMessage } from "../i18n";

/**
 * Build status to display edit roles table
 * @param {Array} assignableRoles
 * @param {Array} enhancedRoles
 * @returns
 */
export const buildFormStatus = (assignableRoles, enhancedRoles, enableInactivePermissions = false) => {
    const status = {};
    if (enhancedRoles["*"]) {
        status.overallPermissions = [
            {
                role: enhancedRoles["*"].role,
                createdAt: enhancedRoles["*"].createdAt,
                permissions: assignableRoles.find(a => !a.appId).permissions
            }
        ];
        status.activePermissions = assignableRoles
            .filter(
                assignableRole =>
                    assignableRole.appId &&
                    (assignableRole.assignable || assignableRole.children.some(child => child.assignable))
            )
            .map(r => {
                const c = r.children.map(c => {
                    let rol = {};
                    if (enhancedRoles["*"].subService && enhancedRoles["*"].subService.length > 0) {
                        rol = enhancedRoles["*"].subService.find(
                            subService => subService.featureCode === c.featureCode
                        );
                    }

                    return {
                        ...c,
                        ...rol,
                        role: enhancedRoles["*"].role,
                        createdAt: enhancedRoles["*"].createdAt
                    };
                });

                return {
                    ...r,
                    toggle: enhancedRoles["*"].role !== undefined,
                    role: enhancedRoles["*"].role,
                    createdAt: enhancedRoles["*"].createdAt,
                    children: c
                };
            });
        status.inactivePermissions = [];
    } else {
        status.overallPermissions = [
            {
                permissions:
                    (assignableRoles.find(a => !a.appId) && assignableRoles.find(a => !a.appId).permissions) || []
            }
        ];

        status.activePermissions = assignableRoles
            .filter(assignableRole => enhancedRoles[assignableRole.appId])
            .map(r => {
                let currentRoleAssignable = true;

                const c = r.children.map(c => {
                    let rol = {};
                    if (enhancedRoles[r.appId].subService && enhancedRoles[r.appId].subService.length > 0) {
                        rol = enhancedRoles[r.appId].subService.find(
                            subService => subService.featureCode === c.featureCode
                        );

                        if (rol) {
                            currentRoleAssignable =
                                currentRoleAssignable &&
                                (r.children
                                    .find(child => rol.featureCode === child.featureCode)
                                    .permissions.includes(rol.role.toUpperCase()) ||
                                    // misura temporanea per gestire i casi employee preesistenti in fase di modifica
                                    (rol.role.toUpperCase() === "EMPLOYEE" &&
                                        r.children
                                            .find(child => rol.featureCode === child.featureCode)
                                            .permissions.indexOf(rol.role.toUpperCase()) === -1));
                        }
                    }

                    return { ...c, ...rol };
                });

                let mappedRole;
                if (c.length === 0) {
                    mappedRole = enhancedRoles[r.appId].role;
                } else {
                    if (!currentRoleAssignable) {
                        mappedRole = "uneditableMultipleRoles";
                    } else {
                        mappedRole = enhancedRoles[r.appId].role;
                    }
                }

                // misura temporanea per gestire i casi employee preesistenti in fase di modifica
                if (mappedRole.toUpperCase() === "EMPLOYEE" && r.permissions.indexOf(mappedRole.toUpperCase()) === -1) {
                    r.permissions.push(mappedRole.toUpperCase());
                }

                return {
                    ...r,
                    toggle: mappedRole !== undefined,
                    role: mappedRole,
                    createdAt: enhancedRoles[r.appId].createdAt,
                    children: c
                };
            });

        status.inactivePermissions = assignableRoles
            .filter(assignableRole => {
                return (
                    assignableRole.appId !== null &&
                    !enhancedRoles[assignableRole.appId] &&
                    (assignableRole.assignable || assignableRole.children.some(c => c.assignable))
                );
            })
            .map(r => {
                const c = r.children.filter(c => c.assignable);
                return { ...r, toggle: enableInactivePermissions, children: c };
            });
    }

    return status;
};

/**
 * Map form status to a role array
 * @param {Object} values
 * @param {string} itemId
 * @returns
 */
export const mapFormToRoles = (values, itemId, assignableRoles) => {
    const overall = values.overallPermissions.find(v => {
        return v.role && (v.role.toUpperCase() === "ADMIN" || v.role.toUpperCase() === "EMPLOYEE");
    });
    if (overall) {
        return [
            { appId: undefined, featureCode: undefined, actionKey: overall.role.toUpperCase(), resourceId: itemId }
        ];
    }

    return Object.values(values)
        .flat()
        .reduce((acc, current) => {
            const assignableRole = assignableRoles.find(a => a.appId === current.appId);
            if (current.role !== undefined && current.role !== "noRole" && current.role !== "multipleRoles") {
                if (assignableRole.assignable) {
                    acc.push({
                        appId: current.appId,
                        actionKey: current.role.toUpperCase(),
                        resourceId: itemId
                    });
                }
            }

            if (current.children && current.children.length > 0 && !acc.find(a => a.appId === current.appId)) {
                acc = acc.concat(
                    current.children
                        .filter(c => {
                            return (
                                c.role &&
                                c.role !== "noRole" &&
                                assignableRole.children.find(a => a.featureCode === c.featureCode).assignable
                            );
                        })
                        .map(s => {
                            return {
                                appId: s.appId,
                                featureCode: s.featureCode,
                                actionKey: s.role.toUpperCase(),
                                resourceId: itemId
                            };
                        })
                );
            }

            return acc;
        }, []);
};

/**
 * Get default values for permissions form
 * @param {Array} assignableRoles
 * @returns
 */
export const getDefaultValues = assignableRoles => {
    let newValues = buildFormStatus(assignableRoles, {});

    newValues.inactivePermissions = newValues.inactivePermissions.map(i => {
        let child = [];
        if (i.children.length > 0) {
            child = i.children.map(c => {
                return { ...c, role: "noRole" };
            });
        }

        return { ...i, toggle: true, role: "noRole", children: [...child] };
    });

    return newValues;
};

/**
 * Calcola lo stato di disabilitato della prima combo di selezione dei ruoli aziendali
 * @param {string} activeItemId
 * @param {boolean} propagateOwnRolesMap
 * @param {array} itemOverallPermission
 * @returns
 */
export const getOverallItemRolesDisabled = (activeItemId, propagateOwnRolesMapFlag, itemOverallPermission) => {
    // la combo è disabilitata come gli altri controlli nel caso in cui ho flaggato per impostare i miei ruoli
    let disabled = propagateOwnRolesMapFlag;

    // nel caso di operazione massiva disabilito la combo se non ho i rouli per impostarli globalmente su tutte
    if (activeItemId === "all" && itemOverallPermission.indexOf("ADMIN") === -1) {
        disabled = true;
    }

    return disabled;
};

export const clearPermissions = (permissions, userIsAdminForAllCompanies, userIsManagerForAllCompanies) => {
    // caso in cui l'utente è admin per tutte le aziende selezionate, ritorno i permessi abilitati per il servizio/sottoservizio
    if (userIsAdminForAllCompanies) {
        return permissions.slice();
    }
    // caso in cui l'utente è manager per tutte le aziende selezionate, non mostro admin / employee
    else if (userIsManagerForAllCompanies) {
        return permissions.filter(role => role !== "ADMIN" && role !== "EMPLOYEE");
    }
    // caso misto, mostro solo admin
    else {
        return permissions.filter(role => role !== "MANAGER" && role !== "EMPLOYEE");
    }
};

/**
 * Map all assignableRoles of all selected items to updated value
 * for massive 'all' items tab
 * @param {Object} updateValues
 * @param {Boolean} resetRoles
 * @returns
 */
export const mapAllSelectedItemsAssignableRoles = (updatedValues, resetRoles) => {
    const isUserAdminForAllCompanies = userIsAdminForAllCompanies(updatedValues);
    const isUserManagerForAllCompanies = userIsManagerForAllCompanies(updatedValues);
    const resetRoleValue = resetRoles ? "noRole" : undefined;

    // se ho caricato tutte le aziende negli updateValues; calcolo i dati relativi a tutte le aziende
    let allSelectedItemsRoles = updatedValues["all"] || {
        inactivePermissions: [],
        activePermissions: [],
        overallPermissions: [
            {
                permissions: []
            }
        ]
    };
    // variabile per pulire gli overall roles se ho admin su tutte le aziende
    let clearOverallRoles = false;

    // scorro tutte le aziende caricate
    for (const [itemId, appRoles] of Object.entries(updatedValues)) {
        // controllo se ho i permessi di admin sul tutte le aziende
        if (
            appRoles.overallPermissions &&
            appRoles.overallPermissions[0].permissions.length > 0 &&
            appRoles.overallPermissions[0].permissions.indexOf("ADMIN") !== -1
        ) {
            // controllo se l'utente no ha i ruoli
            allSelectedItemsRoles.overallPermissions = [Object.assign({}, ...appRoles.overallPermissions)];
        } else {
            // se anche una sola non ha admin tolgo la possibilità dell'impostazione dei ruoli a livello aziendale
            clearOverallRoles = true;
        }

        // per ogni azienda caricata scorro tutti i ruoli
        appRoles.inactivePermissions.forEach(appRole => {
            // se non c'è ancora un ruolo per quell'azienda lo inserisco nei ruoli globali
            if (!allSelectedItemsRoles.inactivePermissions.find(r => r.appId === appRole.appId)) {
                // inserisco un contatore anche per i feature code associati
                //allSelectedItemsRoles.inactivePermissions[appRole.appId].children.forEach(
                appRole.children.forEach(appFeatureCode => (appFeatureCode.companiesCount = [itemId]));

                allSelectedItemsRoles.inactivePermissions.push({
                    appId: appRole.appId,
                    assignable: appRole.assignable,
                    children: appRole.children.map(a => {
                        return { ...a, role: resetRoleValue };
                    }),
                    name: appRole.name,
                    permissions: clearPermissions(
                        appRole.permissions,
                        isUserAdminForAllCompanies,
                        isUserManagerForAllCompanies
                    ),
                    toggle: appRole.toggle,
                    companiesCount: appRole.permissions.length > 0 ? [itemId] : [],
                    role: resetRoleValue
                });
            }
            // se c'è già inserisco un contatore a livello di servizio e verifico i feature code
            else {
                let existingAppRoles = allSelectedItemsRoles.inactivePermissions.find(r => r.appId === appRole.appId);

                // aggiungo ai ruoli per app dell'item all ruoli eventualmente mancanti
                existingAppRoles.permissions = clearPermissions(
                    [...new Set(existingAppRoles.permissions.concat(appRole.permissions))],
                    isUserAdminForAllCompanies,
                    isUserManagerForAllCompanies
                );

                // metto il contatore a livello di servizio
                if (!existingAppRoles.companiesCount.includes(itemId) && appRole.permissions.length > 0) {
                    existingAppRoles.companiesCount.push(itemId);
                }

                // inserisco un contatore anche per i feature code associati
                appRole.children.forEach(appFeatureCode => {
                    let existingAppFeatureCodes = existingAppRoles.children.find(
                        f => f.featureCode === appFeatureCode.featureCode
                    );

                    // se il featureCode è già registrato aumento il contatore
                    if (existingAppFeatureCodes) {
                        if (!existingAppFeatureCodes.companiesCount.includes(itemId)) {
                            existingAppFeatureCodes.companiesCount.push(itemId);

                            // aggiungo ai ruoli per featurecode eventualmente mancanti
                            existingAppFeatureCodes.permissions = clearPermissions(
                                [...new Set(existingAppFeatureCodes.permissions.concat(appFeatureCode.permissions))],
                                isUserAdminForAllCompanies,
                                isUserManagerForAllCompanies
                            );
                        }
                    }
                    // se il featureCode non era ancora stato registrato, lo registro
                    else {
                        existingAppRoles.children.push({
                            appId: appFeatureCode.appId,
                            assignable: appFeatureCode.assignable,
                            featureCode: appFeatureCode.featureCode,
                            name: appFeatureCode.name,
                            permissions: clearPermissions(
                                appFeatureCode.permissions,
                                isUserAdminForAllCompanies,
                                isUserManagerForAllCompanies
                            ),
                            companiesCount: [itemId],
                            role: resetRoleValue
                        });
                    }
                });
            }
        });
    }

    // se mentre scorrevo le aziende ne ho trovata almeno una per la quale non sono admin
    // tolgo la possibilità di impostare massivamente i ruoli di admin
    if (clearOverallRoles) {
        allSelectedItemsRoles.overallPermissions = [
            {
                permissions: []
            }
        ];
    }

    return allSelectedItemsRoles;
};

/**
 * Build myroles status for permissions form
 * @param {Array} assignableRoles
 * @param {Array} enhancedRoles
 * @returns
 */
export const getMyRolesValues = assignableRoles => {
    let newValues = buildFormStatus(assignableRoles, {});

    newValues = getHighestRolesFromUpdatedValues(newValues);

    return newValues;
};

/**
 * Get highest assignale roles relative of updatedRoles
 * @param {Object} updatedValues
 * @param {boolean} massiveOperation
 * @returns
 */
export const getHighestRolesFromUpdatedValues = (updatedValues, massiveOperation) => {
    let newValues = updatedValues;

    // nel caso in cui l'utente abbia ruoli globali sull'azienda, OWNER/ADMIN/EMPLOYEE
    if (newValues.overallPermissions && newValues.overallPermissions[0].permissions.length > 0) {
        // imposto il ruolo più alto disponibile del mio utente
        if (newValues.overallPermissions[0].permissions.indexOf("ADMIN") !== -1) {
            newValues.overallPermissions[0].role = "admin";
        } else if (newValues.overallPermissions[0].permissions.indexOf("EMPLOYEE") !== -1) {
            newValues.overallPermissions[0].role = "employee";
        }

        // imposto gli inactive permissions
        if (newValues.overallPermissions[0].role) {
            newValues.inactivePermissions = newValues.inactivePermissions.map(i => {
                let child = [];
                if (i.children.length > 0) {
                    child = i.children.map(c => {
                        return { ...c, role: newValues.overallPermissions[0].role };
                    });
                }

                return { ...i, role: newValues.overallPermissions[0].role, children: [...child] };
            });
        }
    }
    // caso in cui sono MANAGER ( non ho ruoli globali )
    else {
        // propago il manager per i servizi per cui ho ruoli
        newValues.inactivePermissions = newValues.inactivePermissions.map(i => {
            let child = [];
            if (i.children.length > 0) {
                child = i.children.map(c => {
                    return {
                        ...c,
                        role:
                            c.permissions.indexOf("MANAGER") !== -1 ? "manager" : massiveOperation ? "admin" : undefined
                    };
                });
            }

            return {
                ...i,
                role: i.permissions.indexOf("MANAGER") !== -1 ? "manager" : massiveOperation ? "admin" : undefined,
                children: [...child],
                toggle: true
            };
        });
    }

    return newValues;
};

/**
 * return true if in the final step of create user we are in the massive case
 * @param {Number} itemId
 * @returns
 */
export const isMassiveCase = itemId => itemId === "all";

/**
 * get the company ids with which we have permissions
 * @param {Object} updatedValues
 * @returns
 */
export const getCompaniesIds = updatedValues => Object.keys(updatedValues).filter(id => !isMassiveCase(id));

/**
 * return true if the user who wants to add an other users in the final step of create user is an admin
 * @param {Object} updatedValues
 * @param {Number} itemId
 * @returns
 */
export const userIsAdminForThatCompany = (updatedValues, itemId) =>
    updatedValues.hasOwnProperty(itemId)
        ? updatedValues[itemId].overallPermissions[0].permissions.includes("ADMIN") && !isMassiveCase(itemId)
        : false;

/**
 * return true if the user who wants to add an other users in the final step of create user is a manager
 * @param {Object} updatedValues
 * @param {Number} itemId
 * @returns
 */
export const userIsManagerForThatCompany = (updatedValues, itemId) =>
    !userIsAdminForThatCompany(updatedValues, itemId) && !isMassiveCase(itemId);

/**
 * return true if the user who wants to add an other users in the final step of create user is an admin for all companies
 * @param {Object} updatedValues
 * @param {Array} companiesIds
 * @returns
 */
export const userIsAdminForAllCompanies = updatedValues =>
    getCompaniesIds(updatedValues).every(id => userIsAdminForThatCompany(updatedValues, id));

/**
 * return true if the user who wants to add an other users in the final step of create user is a manager for all companies
 * @param {Object} updatedValues
 * @param {Array} companiesIds
 * @returns
 */
export const userIsManagerForAllCompanies = updatedValues =>
    getCompaniesIds(updatedValues).every(id => userIsManagerForThatCompany(updatedValues, id));

/**
 * return true if the select in the final step of create user should be disabled
 * @param {string} companyCurrentOverallRoles
 * @param {Boolean} propagateOwnRolesMapIsSet
 * @returns
 */
export const servicesPermissionSelectIsDisabled = (companyCurrentOverallRoles, propagateOwnRolesMapIsSet) =>
    (companyCurrentOverallRoles !== "multipleRoles" && companyCurrentOverallRoles !== undefined) ||
    propagateOwnRolesMapIsSet;

/**
 * return true if the "apply to services" button in the final step of create user should be disabled
 * @param {string} companyCurrentOverallRoles
 * @param {string} companyCurrentServicesRoles
 * @param {Boolean} overallRolesAreSet
 * @param {Boolean} servicesRolesAreSet
 * @param {Boolean} propagateOwnRolesMapIsSet
 * @param {Boolean} userIsAdmin
 * @returns
 */
export const applyButtonIsDisabled = (
    companyCurrentOverallRoles,
    companyCurrentServicesRoles,
    overallRolesAreSet,
    servicesRolesAreSet,
    propagateOwnRolesMapIsSet,
    userIsAdmin
) =>
    (companyCurrentServicesRoles === "multipleRoles" &&
        (companyCurrentOverallRoles === "multipleRoles" || !userIsAdmin)) ||
    overallRolesAreSet ||
    servicesRolesAreSet ||
    propagateOwnRolesMapIsSet;

/**
 * return the selects permission options
 * @param {Object} updatedValues
 * @param {Number} itemId
 * @returns
 */
export const getPermissionsSelectOptions = (updatedValues, itemId) => {
    const massiveCase = isMassiveCase(itemId);
    const userIsAdminForAllCompanies_ = userIsAdminForAllCompanies(updatedValues);
    const userIsManagerForAllCompanies_ = userIsManagerForAllCompanies(updatedValues);

    const options = [
        {
            description: translateMessage(`c-registry-user.users-manage-multipleRoles`),
            value: "multipleRoles"
        },
        {
            description: translateMessage(`c-registry-user.users-manage-noRole`),
            value: "noRole"
        }
    ];

    if ((massiveCase && userIsAdminForAllCompanies_) || userIsAdminForThatCompany(updatedValues, itemId)) {
        options.splice(
            1,
            0,
            ...updatedValues[itemId].overallPermissions[0].permissions.map(p => ({
                description: translateMessage(`c-registry-user.users-manage-${p.toLowerCase()}`),
                value: p && p.toLowerCase()
            }))
        );
    } else if ((massiveCase && userIsManagerForAllCompanies_) || userIsManagerForThatCompany(updatedValues, itemId)) {
        options.splice(1, 0, {
            description: translateMessage(`c-registry-user.users-manage-manager`),
            value: "manager"
        });
    } else if (massiveCase && !userIsAdminForAllCompanies_ && !userIsManagerForAllCompanies_) {
        options.splice(1, 0, {
            description: translateMessage(`c-registry-user.users-manage-admin`),
            value: "admin"
        });
    }

    return options;
};

/**
 * return true if has at least one role selected for selectedItems
 * @param {Object} updatedValues
 * @param {Object} selectedItems
 * @param {Object} assignableRoles
 * @returns {Boolean}
 */
export const hasRolesSelected = (updatedValues, selectedItems, assignableRoles, localServices) => {
    const rolesAsString = [];
    Object.keys(updatedValues).forEach(element => {
        // escludo l'item particolare 'all' delle azioni massive dalla creazione dell'utente
        if (element !== "all") {
            rolesAsString.push(
                mapFormToRoles(
                    updatedValues[element],
                    selectedItems.find(i => i.itemId === element).itemUuid,
                    assignableRoles.roles[element]
                )
            );
        }
    });

    const localSericesPermissions = Object.entries(localServices)
        .filter(([_, value]) => value)
        .map(([key]) => key);

    return (
        (rolesAsString.length > 0 && rolesAsString.some(itemRoles => itemRoles.length > 0)) ||
        localSericesPermissions.length > 0
    );
};

/**
 * return the current service role for a certain company in the massive case in the user creation final step
 * @param {Number} itemId
 * @returns
 */
export const getMassiveServicesRole = (updatedValues, itemId, massiveCurrentServicseRoles) => {
    if (
        userIsAdminForAllCompanies(updatedValues) ||
        isMassiveCase(itemId) ||
        massiveCurrentServicseRoles === "noRole"
    ) {
        return massiveCurrentServicseRoles;
    } else {
        return userIsManagerForThatCompany(updatedValues, itemId) ? "manager" : "admin";
    }
};

/**
 * return the inactive permissions for a certain company in the massive case in the user creation final step
 * @param {Number} itemId
 * @returns
 */
export const getMassiveInactivePermissions = (updatedValues, itemId, massiveCurrentServicseRoles) => {
    if (userIsAdminForAllCompanies(updatedValues) || massiveCurrentServicseRoles === "noRole") {
        return massiveCurrentServicseRoles;
    } else {
        return updatedValues[itemId].inactivePermissions[0] &&
            updatedValues[itemId].inactivePermissions[0].permissions.includes("ADMIN")
            ? "admin"
            : "manager";
    }
};
