const defaultStatus = {
    started: false,
    ended: false,
    error: false,
    errorInfo: {}
};

/**
 * Returns a reducer which handles a typical async call status flow.
 *
 * @param {string} startAction start action type
 * @param {string} successAction success action type
 * @param {string} errorAction error action type
 * @param {string} resetAction reset action type, defaults to RESET_ALL
 */
export const genericStatusReducer =
    (startAction, successAction, errorAction, resetAction = "RESET_ALL") =>
    (state = defaultStatus, { type, error }) => {
        switch (type) {
            case startAction:
                return {
                    ...defaultStatus,
                    started: true
                };
            case successAction:
                return {
                    ...defaultStatus,
                    ended: true
                };
            case errorAction:
                return {
                    ...defaultStatus,
                    error: true,
                    errorInfo: error
                };
            case resetAction:
                return defaultStatus;
            default:
                return state;
        }
    };
