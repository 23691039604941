import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100% - 64px);
    box-shadow: 0 2px 4px 0 rgba(21, 41, 53, 0.32);
    border-top: 1px solid #5a6872;
`;

export const BottomMenuContainer = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

export const TopMenuContainer = styled.div`
    flex: 1;
`;
