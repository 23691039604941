import React from "react";
import PropTypes from "prop-types";

import { Field } from "redux-form";
import Form from "antd/lib/form";
import { field } from "@ts-digital/conv-redux-form";
import { Select } from "antd";
import moment from "moment";
import { FormattedMessage } from "react-intl";

const { Option } = Select;

export const WrappedComboboxes = ({ input, label, meta, disabled }) => {
    let daysOptions = [];
    const convert = timestamp => {
        return moment.utc(timestamp).format("YYYY-MM-DD");
    };
    const selectedDate = input.value === 0 ? 0 : convert(input.value);
    const monthOptions = moment.months().map((month, index) => {
        const monthNumber = index + 1;
        const key = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;

        return (
            <Option key={key} value={key}>
                {month.charAt(0).toUpperCase() + month.slice(1)}
            </Option>
        );
    });

    let curMonth = selectedDate && selectedDate.length > 2 ? selectedDate.split("-")[1] : null;

    if (curMonth) {
        // selecting 2019 where feb has 28 days
        const numberOfDays = moment("2019-" + curMonth, "YYYY-MM").daysInMonth();

        let daysArray = [];
        for (let i = 0; i < numberOfDays; i++) {
            daysArray.push((i + 1).toString());
        }

        daysArray.forEach(day => {
            daysOptions.push(
                <Option key={day} value={day}>
                    {day}
                </Option>
            );
        });
    } else {
        curMonth = (new Date().getMonth() + 1).toString();
        curMonth = curMonth.length === 1 ? "0" + curMonth : curMonth;
    }

    return (
        <Form.Item
            label={label}
            colon={false}
            validateStatus={meta.touched && meta.error ? "error" : ""}
            help={meta.touched && meta.error ? meta.error : ""}
        >
            <Select
                disabled={disabled}
                style={{ display: "inline-block", width: 140 }}
                placeholder={<FormattedMessage id="general.month" />}
                onChange={val => {
                    const dateString = `${new Date().getFullYear().toString()}-${val}-01T00:00:00Z`;
                    input.onChange(moment(dateString).utc());
                }}
                value={
                    selectedDate.length >= 7 ? selectedDate.split("-")[1] : selectedDate.length === 5 ? null : undefined
                }
            >
                {monthOptions}
            </Select>
            <Select
                disabled={disabled}
                placeholder={<FormattedMessage id="general.day" />}
                style={{ marginLeft: 5, width: 140, display: "inline-block" }}
                value={
                    selectedDate.length === 10
                        ? selectedDate.split("-")[2]
                        : selectedDate.length === 7
                        ? null
                        : undefined
                }
                onChange={val => {
                    const dateString = `${new Date().getFullYear().toString()}-${selectedDate.split("-")[1]}-${
                        val.length === 1 ? "0" + val : val
                    }`;
                    input.onChange(moment(`${dateString}T00:00:00Z`).utc());
                }}
            >
                {daysOptions}
            </Select>
        </Form.Item>
    );
};

const MonthDaySelect = props => {
    return <Field {...props} component={WrappedComboboxes} />;
};

MonthDaySelect.propTypes = { name: PropTypes.string.isRequired, label: PropTypes.object };

export default field({ type: "string" })(MonthDaySelect);
