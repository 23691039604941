import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const Container = styled.div`
    height: 48px;
    width: 48px;
    position: absolute;
    top: 0;
    left: -48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 0.2px;
    line-height: 15px;
    font-family: Roboto;
    font-weight: 500;
    cursor: pointer;

    :hover {
        background: #00c3ea;
    }
`;

export const CloseIcon = styled(FontAwesomeIcon)`
    filter: drop-shadow(0px 1px 0 rgba(21, 41, 53, 0.24));
    color: #008fd6;
`;
