import React, { Component } from "react";
import PropTypes from "prop-types";
import { includes } from "ramda";
import { Tag } from "antd";

const style = {
    cursor: "pointer"
};

export default class SignatureInfoRow extends Component {
    static propTypes = {
        onClick: PropTypes.func,
        text: PropTypes.string,
        value: PropTypes.object
    };

    onDocumentClick() {
        const { value, onClick } = this.props;
        if (onClick) {
            onClick(value);
        }
    }

    renderMapMessage() {
        const { text } = this.props;
        if (text === "FINAL_SUCCESS" || text === "ASSIGNED_SUCCESS") {
            return (
                <Tag onClick={() => this.onDocumentClick()} color="green">
                    {"Attivo"}
                </Tag>
            );
        }
        if (text === "SUBMISSION_SUCCESS") {
            return (
                <Tag onClick={() => this.onDocumentClick()} color="orange">
                    {"In attesa"}
                </Tag>
            );
        }
        if (text === "PDF_GENERATED") {
            return (
                <Tag onClick={() => this.onDocumentClick()} color="yellow">
                    {"Generato"}
                </Tag>
            );
        }
        if (text === "WITHDRAW_SUCCESS") {
            return (
                <Tag onClick={() => this.onDocumentClick()} color="grey">
                    {"Revocato"}
                </Tag>
            );
        }
        if (text === "ASSIGNED_WITHDRAW_SUCCESS") {
            return (
                <Tag onClick={() => this.onDocumentClick()} color="grey">
                    {"Revocato"}
                </Tag>
            );
        }
        if (includes("SUBMISSION_ERROR_", text) || includes("FINAL_ERROR_", text)) {
            return (
                <Tag onClick={() => this.onDocumentClick()} color="red">
                    {"Errore"}
                </Tag>
            );
        }
        return (
            <div type="user" onClick={() => this.onDocumentClick()} style={style}>
                {text}
            </div>
        );
    }

    render() {
        return <div>{this.renderMapMessage()}</div>;
    }
}
