import {
    GET_BILLING_USAGE_STATISTICS_START,
    GET_BILLING_USAGE_STATISTICS_END,
    GET_BILLING_USAGE_STATISTICS_SUCCESS,
    GET_BILLING_USAGE_STATISTICS_ERROR
} from "../../../../actions/metering/billing/statistics";

const defaultBillingState = {
    status: { loading: false, error: null },
    data: [],
    size: 0
};

export const statisticsReducer = (state = defaultBillingState, { type, payload }) => {
    switch (type) {
        case GET_BILLING_USAGE_STATISTICS_START: {
            return {
                ...state,
                status: { ...state.status, loading: true }
            };
        }
        case GET_BILLING_USAGE_STATISTICS_END: {
            return {
                ...state,
                status: { ...state.status, loading: false }
            };
        }
        case GET_BILLING_USAGE_STATISTICS_SUCCESS: {
            const { statistics, size } = payload;
            return {
                ...state,
                data: statistics,
                size
            };
        }
        case GET_BILLING_USAGE_STATISTICS_ERROR: {
            const { error } = payload;
            return {
                ...state,
                status: { ...state.status, error }
            };
        }
        default: {
            return state;
        }
    }
};
