import React from "react";

import { CDC_CONSOLE } from "../../../config";
import PortaleChildApp from "../../portale-child-app";

const DigitalBoxView = () => {
    return <PortaleChildApp url={`${CDC_CONSOLE}#/?integrated=true`} />;
};

export default DigitalBoxView;
