import React from "react";

import { ANALYTICS_WEBAPP_URL } from "../../../config";
import PortaleChildApp from "../../portale-child-app";

const AnalyticsView = () => {
    return <PortaleChildApp url={ANALYTICS_WEBAPP_URL} />;
};

export default AnalyticsView;
