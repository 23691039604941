import React from "react";
import PropTypes from "prop-types";
import { Button } from "@ts-digital/vrc";
import { FormattedMessage } from "react-intl";

import { OptionsTrigger, Icon } from "./styled";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Popover } from "antd";

export const ContractOptionsCell = ({
    cell: { value },
    onChangeRdc,
    row: {
        original: { status, isActive, toBeFixed }
    }
}) => {
    let disabled = status !== "validated";

    let visibleChangeRdc = false;
    if ((status === "validated" || isActive === true) && status !== "uploaded") {
        visibleChangeRdc = true;
        disabled = false;
    }

    //TODO Enable button annulla when backend functionality for rollback will be ready
    /*let visibleUndo = false;
    let disabledUndo = false;
    if (status === "downloaded" && isActive === true) {
        visibleUndo = true;
    }
    if (toBeFixed === true) {
        disabledUndo = true;
    }*/

    return (
        <Popover
            placement="topRight"
            trigger="click"
            content={
                <>
                    {visibleChangeRdc && (
                        <Button
                            kind="tertiary"
                            onClick={() => {
                                onChangeRdc(value);
                            }}
                            disabled={status === "downloaded" && isActive === true}
                            data-gainsight-track={"archive-change-rdc-cta"}
                        >
                            <FormattedMessage id="c-cct-provisioning-upload-pdf-form.change-rdc" />
                        </Button>
                    )}
                    {
                        //TODO Enable button annulla when backend functionality for rollback will be ready
                        /*<br />
                    {visibleUndo && (
                        <Button disabled={disabledUndo} kind="tertiary">
                            Annulla Operazione
                        </Button>
                    )}*/
                    }
                </>
            }
        >
            <OptionsTrigger kind={"tertiary"} size={"small"} disabled={disabled}>
                <Icon icon={faEllipsisV} />
            </OptionsTrigger>
        </Popover>
    );
};

ContractOptionsCell.propTypes = {
    /** Current cell data */
    cell: PropTypes.shape({ value: PropTypes.any }).isRequired,
    /** Rdc contract active status */
    isActive: PropTypes.bool,
    /** Rdc contract validation status */
    status: PropTypes.string,
    /** Button click callback */
    onChangeRdc: PropTypes.func.isRequired
};
