import { API_URL } from "../../config";
import { getAuthWithAppName } from "../auth";

import { getRestCall } from "../../utils/rest-api-call";
import { getUserProfile } from "../../utils/state-utils/get-user-profile";

export const DOWNLOAD_CONNECTIONS_DATA_START = "DOWNLOAD_CONNECTIONS_DATA_START";
export const DOWNLOAD_CONNECTIONS_DATA_SUCCESS = "DOWNLOAD_CONNECTIONS_DATA_SUCCESS";
export const DOWNLOAD_CONNECTIONS_DATA_ERROR = "DOWNLOAD_CONNECTIONS_DATA_ERROR";
export const DOWNLOAD_CONNECTIONS_DATA_RESET = "DOWNLOAD_CONNECTIONS_DATA_RESET";

export function downloadConnectionsData(managerId) {
    return async (dispatch, getState) => {
        dispatch({ type: DOWNLOAD_CONNECTIONS_DATA_START });

        const auth = getAuthWithAppName(getState().auth);

        try {
            const result = await getRestCall(
                `${API_URL}/connections/${managerId}`,
                auth,
                {},
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                managerId,
                false
            );

            dispatch({
                type: DOWNLOAD_CONNECTIONS_DATA_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: DOWNLOAD_CONNECTIONS_DATA_ERROR,
                error: e
            });
        }
    };
}
export function resetDownloadConnectionsData() {
    return dispatch => {
        dispatch({
            type: DOWNLOAD_CONNECTIONS_DATA_RESET
        });
    };
}
