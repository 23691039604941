import { API_URL } from "../config";
import { COMMON_LOGIN_API_URL } from "../config";
import { REFRESH_SUCCESS, getAuthWithAppName } from "../actions/auth";
import axios from "axios";
import cuid from "cuid";
import uuid from "uuid/v4";

let refreshingToken = false;
let tokenRefreshPromise;

async function refreshTsIdToken(refreshToken) {
    const headers = {
        "Content-Type": "application/json",
        "X-App-Name": "PORTALE",
        "X-App-Version": "1.0",
        "X-Request-Id": cuid(),
        "X-Correlation-Id": uuid()
    };

    const response = await axios.request({
        url: `${COMMON_LOGIN_API_URL}/login/tsid/v3/refresh`,
        method: "POST",
        data: JSON.stringify({ refreshToken }),
        headers,
        withCredentials: true
    });

    if (response.status !== 200) {
        throw new Error("Something went wrong while refreshing TS ID token.");
    }

    return {
        refreshToken: response.data.refreshToken,
        accessToken: response.data.accessToken
    };
}

async function refreshViaCookie() {
    const res = await fetch(`${API_URL}/token/refresh`, {
        method: "POST",
        credentials: "include"
    });

    if (res.status >= 399) {
        throw new Error("Something went wrong while refreshing via token");
    }

    return await res.json();
}

async function refreshTokenAndDispatchEvent(auth, dispatch) {
    let response;
    if (auth.refreshToken) {
        response = await refreshTsIdToken(auth.refreshToken);
    } else {
        response = await refreshViaCookie();
    }

    const token = response.accessToken || response.applicationToken;
    dispatch({
        type: REFRESH_SUCCESS,
        payload: {
            loginAuth: {
                ...auth,
                securityToken: token,
                id: auth.id,
                appName: auth.appName,
                refreshToken: response.refreshToken,
                tsId: auth.tsId,
                tsLogin: auth.tsLogin
            }
        }
    });

    return response;
}

export async function refreshToken(auth, dispatch) {
    try {
        if (!refreshingToken) {
            refreshingToken = true;
            tokenRefreshPromise = refreshTokenAndDispatchEvent(getAuthWithAppName(auth, auth.appName), dispatch);
        }
        const response = await tokenRefreshPromise;
        refreshingToken = false;
        return response.accessToken || response.applicationToken;
    } catch (e) {
        console.error(e);
        refreshingToken = false;
    }
}
