import {
    TSPAY_RESEND_CONFIRM_START,
    TSPAY_RESEND_CONFIRM_SUCCESS,
    TSPAY_RESEND_CONFIRM_ERROR,
    TSPAY_RESEND_CONFIRM_RESET
} from "../../actions/services/tspay.js";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export default function tspayResend(state = defaultState, { type, payload, error }) {
    switch (type) {
        case TSPAY_RESEND_CONFIRM_START:
            return {
                status: {
                    ...defaultState.status,
                    started: true
                }
            };
        case TSPAY_RESEND_CONFIRM_SUCCESS:
            return {
                status: {
                    ...defaultState.status,
                    ended: true
                },
                ...payload
            };
        case TSPAY_RESEND_CONFIRM_ERROR:
            return {
                status: {
                    ...defaultState.status,
                    error: true,
                    errorInfo: { code: error.code, message: error.message, itemId: payload.itemId }
                }
            };
        case TSPAY_RESEND_CONFIRM_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}
