import styled from "styled-components";

export const ChildrenContainer = styled.div`
    margin-left: ${props => (!props.canExpand ? "32px" : "0px")};
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    padding-left: ${props => props.depth * 40}px;
`;

export const ExpansionIconContainer = styled.span`
    color: ${props => props.theme.colors.primary.richElectricBlue};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: normal;
    height: 32px;
    letter-spacing: 0px;
    line-height: 15px;
    width: 32px;
`;
