import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { FormattedMessage } from "react-intl";

import { translateMessage } from "../../../../../../i18n";

import { Button } from "@ts-digital/vrc";

import { BottomContainer, ButtonContainer, CustomUpload, ErrorString } from "./styled";

export const UploadForm = ({ controlsEnabled, onChange, onRemove, removeEnabled }) => {
    const [errorString, setErrorString] = useState("");

    const handleFileSelection = useCallback(
        file => {
            let error = "";
            if (
                !file.type.startsWith("image/") ||
                file.type.includes("ico") ||
                file.type.includes("svg") ||
                file.type.includes("tiff")
            ) {
                error = translateMessage("c-manage-data.edit-details.wrong-format");
            } else if (file.size > 1000000) {
                error = translateMessage("c-manage-data.edit-details.too-large-image");
            }
            var reader = new FileReader();
            reader.onload = evt => {
                const fileContent = evt.target.result;
                if (error === "") {
                    onChange(fileContent);

                    setErrorString(error);
                } else {
                    setErrorString(error);
                }
            };
            reader.readAsDataURL(file);
            return false;
        },
        [onChange]
    );

    const infoString = (
        <>
            <FormattedMessage id={"c-manage-data.edit-details.max-large-image"} />
            <br />
            <FormattedMessage id={"c-manage-data.edit-details.accepted-format"} />
        </>
    );

    return (
        <BottomContainer>
            <ButtonContainer>
                <CustomUpload
                    accept="image/*"
                    beforeUpload={handleFileSelection}
                    showUploadList={false}
                    disabled={!controlsEnabled}
                >
                    <Button kind="tertiary" disabled={!controlsEnabled}>
                        <FontAwesomeIcon icon={faCloudUpload} />
                        {"  "}
                        <FormattedMessage id={"c-manage-data.edit-details.upload-logo-button-text"} />
                    </Button>
                </CustomUpload>
                <Button
                    disabled={!controlsEnabled || !removeEnabled}
                    kind="tertiary"
                    onClick={() => {
                        if (errorString !== "") {
                            setErrorString("");
                        }
                        onRemove();
                    }}
                >
                    <FontAwesomeIcon icon={faTrashAlt} />
                    {"  "}
                    <FormattedMessage id={"c-manage-data.edit-details.delete-logo-button-text"} />
                </Button>
            </ButtonContainer>
            {infoString}
            {errorString !== "" ? <ErrorString>{errorString}</ErrorString> : null}
        </BottomContainer>
    );
};

UploadForm.propTypes = {
    onChange: PropTypes.func
};
