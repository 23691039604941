import styled from "styled-components";

export const Iframe = styled.iframe`
    border: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
`;
