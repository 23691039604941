import {
    LOAD_COMPANY_START,
    LOAD_COMPANY_ERROR,
    LOAD_COMPANY_SUCCESS,
    LOAD_COMPANY_RESET,
    GET_PRIVACY_PREFERENCES_START,
    GET_PRIVACY_PREFERENCES_SUCCESS,
    GET_PRIVACY_PREFERENCES_ERROR
} from "../actions/company.js";

import { UPLOAD_CERT_SUCCESS } from "../actions/certification";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function company(state = defaultState, { type, payload, error }) {
    switch (type) {
        case LOAD_COMPANY_START:
            return {
                status: {
                    ...defaultState.status,
                    started: true
                }
            };
        case LOAD_COMPANY_SUCCESS:
            return {
                status: {
                    ...defaultState.status,
                    ended: true
                },
                ...payload
            };
        case LOAD_COMPANY_ERROR:
            return {
                status: {
                    ...defaultState.status,
                    error: true,
                    errorInfo: { code: error.code, message: error.message, itemId: payload.itemId }
                }
            };
        case LOAD_COMPANY_RESET:
            return {
                ...defaultState
            };
        case UPLOAD_CERT_SUCCESS:
            if (state.base && state.base.id === payload.companyId) {
                return {
                    ...state,
                    base: {
                        ...state.base,
                        status: {
                            ...state.base.status,
                            status: "UNVERIFIABLE_PENDING_VALIDATE"
                        }
                    }
                };
            }

            return state;
        default:
            return state;
    }
}

export function privacyPreferences(state = { ...defaultState, preferences: {} }, { type, payload, error }) {
    switch (type) {
        case GET_PRIVACY_PREFERENCES_START:
            return {
                ...state,
                status: {
                    ...defaultState.status,
                    started: true
                }
            };
        case GET_PRIVACY_PREFERENCES_SUCCESS:
            return {
                status: {
                    ...defaultState.status,
                    ended: true
                },
                preferences: { ...state.preferences, ...payload }
            };
        case GET_PRIVACY_PREFERENCES_ERROR:
            return {
                ...state,
                status: {
                    ...defaultState.status,
                    error: true,
                    errorInfo: error
                }
            };
        default:
            return state;
    }
}
