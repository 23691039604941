import {
    GET_NOTIFICATION_ERROR,
    GET_NOTIFICATION_START,
    GET_NOTIFICATION_SUCCESS,
    LIST_NOTIFICATIONS_ERROR,
    LIST_NOTIFICATIONS_START,
    LIST_NOTIFICATIONS_SUCCESS,
    RESET_NOTIFICATIONS
} from "../../actions/notification/read";
import {
    ACCEPT_NOTIFICATION_SUCCESS,
    REJECT_NOTIFICATION_SUCCESS,
    READ_NOTIFICATION_START,
    UNREAD_NOTIFICATION_START
} from "../../actions/notification/write";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {}
    }
};

export function getNotification(state = defaultState, { type, payload }) {
    switch (type) {
        case GET_NOTIFICATION_START:
            return {
                status: {
                    ...defaultState.status,
                    started: true
                }
            };
        case GET_NOTIFICATION_ERROR:
            return {
                status: {
                    ...state.status,
                    started: false,
                    error: true,
                    errorInfo: payload.error
                }
            };
        case GET_NOTIFICATION_SUCCESS:
            return {
                status: {
                    ...defaultState.status,
                    ended: true
                }
            };
        case RESET_NOTIFICATIONS:
            return defaultState;
        default:
            return state;
    }
}

const defaultListState = {
    status: {
        ...defaultState.status,
        loadingPages: []
    },
    list: [],
    page: 0,
    pageSize: 0
};

export function listNotifications(state = defaultListState, { type, payload }) {
    switch (type) {
        case LIST_NOTIFICATIONS_START:
            return {
                ...state,
                status: {
                    ...defaultListState.status,
                    loadingPages: [...state.status.loadingPages, payload.page],
                    started: true
                }
            };
        case LIST_NOTIFICATIONS_ERROR:
            return {
                ...state,
                status: {
                    ...defaultListState.status,
                    loadingPages: state.status.loadingPages.filter(p => p !== payload.page),
                    error: true,
                    errorInfo: payload.error
                }
            };
        case LIST_NOTIFICATIONS_SUCCESS:
            return {
                status: {
                    ...defaultListState.status,
                    loadingPages: state.status.loadingPages.filter(p => p !== payload.page),
                    ended: true
                },
                list: [...new Set([...state.list, ...payload.notifications.map(n => n.id)])],
                page: payload.page,
                pageSize: payload.size,
                totalNotifications: payload.totalItems,
                unreadNotifications: payload.unreadNotifications
            };
        case READ_NOTIFICATION_START:
        case ACCEPT_NOTIFICATION_SUCCESS:
        case REJECT_NOTIFICATION_SUCCESS:
            return {
                ...state,
                unreadNotifications: state.unreadNotifications === 0 ? 0 : state.unreadNotifications - 1
            };
        case UNREAD_NOTIFICATION_START:
            return {
                ...state,
                unreadNotifications: state.unreadNotifications + 1
            };
        case RESET_NOTIFICATIONS:
            return defaultListState;
        default:
            return state;
    }
}

export function results(state = {}, { type, payload }) {
    switch (type) {
        case GET_NOTIFICATION_SUCCESS:
            return {
                ...state,
                [payload.notification.id]: payload.notification
            };
        case LIST_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                ...payload.notifications.reduce((acc, curr) => {
                    acc[curr.id] = curr;
                    return acc;
                }, {})
            };
        case READ_NOTIFICATION_START:
            if (
                state[payload.notificationId].type === "INFO" ||
                state[payload.notificationId].accepted ||
                state[payload.notificationId].rejected
            ) {
                return {
                    ...state,
                    [payload.notificationId]: {
                        ...state[payload.notificationId],
                        readStatus: true
                    }
                };
            }

            return state;
        case UNREAD_NOTIFICATION_START:
            return {
                ...state,
                [payload.notificationId]: {
                    ...state[payload.notificationId],
                    readStatus: false
                }
            };
        case ACCEPT_NOTIFICATION_SUCCESS:
            return {
                ...state,
                [payload.id]: {
                    ...state[payload.id],
                    readStatus: true,
                    accepted: true
                }
            };
        case REJECT_NOTIFICATION_SUCCESS:
            return {
                ...state,
                [payload.id]: {
                    ...state[payload.id],
                    readStatus: true,
                    rejected: true
                }
            };
        case RESET_NOTIFICATIONS:
            return {};
        default:
            return state;
    }
}
