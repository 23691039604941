export const GAINSIGHT_SMART_PROVISIONING = "GAINSIGHT_SMART_PROVISIONING";

export const trackSmartProvisioning = (ownerId, itemId) => dispatch => {
    dispatch({ type: GAINSIGHT_SMART_PROVISIONING, details: { itemId, ownerId } });
};

export const GAINSIGHT_EDIT_SMART_PROVISIONING = "GAINSIGHT_EDIT_SMART_PROVISIONING";

export const trackSmartProvisioningEdit = (ownerId, itemId) => dispatch => {
    dispatch({ type: GAINSIGHT_EDIT_SMART_PROVISIONING, details: { itemId, ownerId } });
};

export const GAINSIGHT_EDIT_OLD_PROVISIONING = "GAINSIGHT_EDIT_OLD_PROVISIONING";

export const trackOldProvisioningEdit = (ownerId, itemId) => dispatch => {
    dispatch({ type: GAINSIGHT_EDIT_OLD_PROVISIONING, details: { itemId, ownerId } });
};

export const GAINSIGHT_OLD_PROVISIONING = "GAINSIGHT_OLD_PROVISIONING";

export const trackOldProvisioning = (ownerId, itemId) => dispatch => {
    dispatch({ type: GAINSIGHT_OLD_PROVISIONING, details: { itemId, ownerId } });
};

export const GAINSIGHT_SWITCH_TO_SMART_PROVISIONING = "GAINSIGHT_SWITCH_TO_SMART_PROVISIONING";

export const trackSwitchToSmartProvisioning = (ownerId, itemId) => dispatch => {
    dispatch({ type: GAINSIGHT_SWITCH_TO_SMART_PROVISIONING, details: { itemId, ownerId } });
};

export const GAINSIGHT_SHOW_PROVISIONING_HOW_TO_VIDEO = "GAINSIGHT_SHOW_PROVISIONING_HOW_TO_VIDEO";

export const showHowtoVideo = () => dispatch => {
    dispatch({ type: GAINSIGHT_SHOW_PROVISIONING_HOW_TO_VIDEO });
};

export const GAINSIGHT_ESM_CONTACT_US = "GAINSIGHT_ESM_CONTACT_US";

export const esmContactUs = () => dispatch => {
    dispatch({ type: GAINSIGHT_ESM_CONTACT_US });
};

export const GAINSIGHT_ESM_OPEN_APP = "GAINSIGHT_ESM_OPEN_APP";

export const esmOpenApp = () => dispatch => {
    dispatch({ type: GAINSIGHT_ESM_OPEN_APP });
};

export const GAINSIGHT_ESM_ACTIVATE = "GAINSIGHT_ESM_ACTIVATE";

export const esmActivate = () => dispatch => {
    dispatch({ type: GAINSIGHT_ESM_ACTIVATE });
};

export const GAINSIGHT_ESM_DISCOVER = "GAINSIGHT_ESM_DISCOVER";

export const esmDiscover = () => dispatch => {
    dispatch({ type: GAINSIGHT_ESM_DISCOVER });
};

export const GAINSIGHT_PARTECIPATE_TALKS = "GAINSIGHT_PARTECIPATE_TALKS";

export const partecipateTalks = hasStudio => dispatch => {
    dispatch({ type: GAINSIGHT_PARTECIPATE_TALKS, details: { hasStudio } });
};
