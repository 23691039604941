import { CONNECTION_WRITE_API, API_URL } from "../../config";
import { postRestCall, deleteRestCall } from "../../utils/rest-api-call";
import { getUserProfile } from "../../utils/state-utils/get-user-profile";
import { getAuthWithAppName } from "../auth";

export const CREATE_MANAGER_LINK_START = "CREATE_MANAGER_LINK_START";
export const CREATE_MANAGER_LINK_SUCCESS = "CREATE_MANAGER_LINK_SUCCESS";
export const CREATE_MANAGER_LINK_ERROR = "CREATE_MANAGER_LINK_ERROR";

export function createManagerLink(managerId, managedId, appId, feature, role, serviceId, userRoles = []) {
    return async (dispatch, getState) => {
        dispatch({
            type: CREATE_MANAGER_LINK_START,
            payload: { managerId, managedId, feature }
        });

        const request = {
            requesterId: managerId,
            recipientId: managedId,
            appId: appId,
            featureCode: feature !== "" ? feature : null,
            permission: role,
            serviceId: serviceId,
            userRoles: userRoles
        };

        try {
            const auth = getAuthWithAppName(getState().auth);
            await postRestCall(
                `${CONNECTION_WRITE_API}/link/manager`,
                auth,
                {},
                request,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                managerId,
                true
            );

            dispatch({
                type: CREATE_MANAGER_LINK_SUCCESS,
                payload: { managerId, managedId, feature }
            });
        } catch (e) {
            dispatch({
                type: CREATE_MANAGER_LINK_ERROR,
                payload: { e, managerId, managedId, feature }
            });
        }
    };
}

export const DELETE_LINK_START = "DELETE_LINK_START";
export const DELETE_LINK_SUCCESS = "DELETE_LINK_SUCCESS";
export const DELETE_LINK_ERROR = "DELETE_LINK_ERROR";

export function deleteLink(connectionId, context) {
    return async (dispatch, getState) => {
        dispatch({
            type: DELETE_LINK_START,
            payload: { connectionId }
        });

        try {
            const auth = getAuthWithAppName(getState().auth);
            await deleteRestCall(
                `${CONNECTION_WRITE_API}/link/${connectionId}`,
                auth,
                {},
                {},
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                undefined,
                true,
                true
            );

            dispatch({
                type: DELETE_LINK_SUCCESS,
                payload: {
                    connectionId,
                    deleteActionContext: context
                }
            });
        } catch (e) {
            dispatch({
                type: DELETE_LINK_ERROR,
                payload: { e, connectionId }
            });
        }
    };
}

export const CREATE_CONNECTION_WITH_ITEM_START = "CREATE_CONNECTION_WITH_ITEM_START";
export const CREATE_CONNECTION_WITH_ITEM_ERROR = "CREATE_CONNECTION_WITH_ITEM_ERROR";
export const CREATE_CONNECTION_WITH_ITEM_SUCCESS = "CREATE_CONNECTION_WITH_ITEM_SUCCESS";
export const CREATE_CONNECTION_WITH_ITEM_RESET = "CREATE_CONNECTION_WITH_ITEM_RESET";

export function createConnectionAndItem(connection, item) {
    return async (dispatch, getState) => {
        dispatch({
            type: CREATE_CONNECTION_WITH_ITEM_START,
            payload: { connection, item }
        });

        let managedId = "";

        const auth = getAuthWithAppName(getState().auth);

        try {
            const res = await postRestCall(
                `${API_URL}/connections/item`,
                auth,
                {},
                item,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                undefined
            );

            managedId = res.itemId;
        } catch (e) {
            dispatch({
                type: CREATE_CONNECTION_WITH_ITEM_ERROR,
                payload: { connection, item },
                error: e
            });

            return;
        }

        try {
            await postRestCall(
                `${API_URL}/connections`,
                auth,
                {},
                { ...connection, managedId: managedId },
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                undefined
            );

            dispatch({
                type: CREATE_CONNECTION_WITH_ITEM_SUCCESS,
                payload: { connection, item }
            });
        } catch (e) {
            dispatch({
                type: CREATE_CONNECTION_WITH_ITEM_ERROR,
                payload: { connection, item },
                error: e
            });
        }
    };
}

export function createConnectionAndItemReset() {
    return dispatch => dispatch({ type: CREATE_CONNECTION_WITH_ITEM_RESET });
}
