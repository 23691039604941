import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import { buildFormStatus } from "../../utils/roles-mapper";
import EditRolesModal from "./edit-roles-modal";
import ItemsRolesTable from "./items-roles-table";
import { AddPermissionsButton, AddPermissionsLink } from "./styled";

const UserSummary = ({
    assignableRoles,
    currentPage,
    currentPageData,
    loadingAssignableRoles,
    loadingTableItems,
    onAddToAnotherItem,
    onDeleteUserFromItem,
    onRoleEditConfirm,
    onEditUser,
    onPageChange,
    onTableFiltersChange,
    preloadAssignableRoles,
    submittingRoleEdit,
    selectedUserEnhancedRoles,
    totalItems,
    totalPages
}) => {
    const { item } = useParams();

    const [roleEditModalOpen, setRoleEditModalOpen] = useState(false);
    const [roleEditModalData, setRoleEditModalData] = useState({});

    const handleEditApp = useCallback((itemId, appId, appRole, featureRoles) => {
        setRoleEditModalOpen(true);
        setRoleEditModalData({ itemId, appId, appRole, featureRoles });
    }, []);

    const rolesToEdit = useMemo(() => {
        const formStatus =
            assignableRoles[roleEditModalData.itemId] &&
            buildFormStatus(
                assignableRoles[roleEditModalData.itemId],
                selectedUserEnhancedRoles[roleEditModalData.itemId],
                true
            );

        return formStatus ? [...formStatus.activePermissions, ...formStatus.inactivePermissions] : [];
    }, [assignableRoles, roleEditModalData, selectedUserEnhancedRoles]);

    return (
        <>
            <AddPermissionsLink to={`/${item}/registry/user/create`}>
                <AddPermissionsButton
                    onClick={() => onAddToAnotherItem()}
                    data-gainsight-track={"user-summary-add-roles"}
                >
                    <FontAwesomeIcon icon={faPlusCircle} /> <FormattedMessage id="c-user-summary.add-permissions" />
                </AddPermissionsButton>
            </AddPermissionsLink>
            <ItemsRolesTable
                data={currentPageData}
                loading={loadingTableItems}
                onDeleteUserFromItem={onDeleteUserFromItem}
                onEditApp={handleEditApp}
                onEditUser={onEditUser}
                onFiltersChange={onTableFiltersChange}
                onPageChange={onPageChange}
                page={currentPage}
                preloadAssignableRoles={preloadAssignableRoles}
                totalItems={totalItems}
                totalPages={totalPages}
            />
            <EditRolesModal
                appToEdit={roleEditModalData.appId}
                loadingData={loadingAssignableRoles && !assignableRoles[roleEditModalData.itemId]}
                onClose={() => setRoleEditModalOpen(false)}
                onConfirm={newValues => onRoleEditConfirm(newValues, roleEditModalData.itemId)}
                rolesToEdit={rolesToEdit}
                submitting={submittingRoleEdit}
                visible={roleEditModalOpen}
            />
        </>
    );
};

UserSummary.propTypes = {
    /** List of assignable roles, indexed by itemId */
    assignableRoles: PropTypes.objectOf(
        PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                appId: PropTypes.string,
                permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
                children: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string.isRequired,
                        appId: PropTypes.string,
                        featureCode: PropTypes.string.isRequired,
                        permissions: PropTypes.arrayOf(PropTypes.string).isRequired
                    })
                )
            })
        )
    ).isRequired,
    /** The current page number */
    currentPage: PropTypes.number,
    /** Data to be displayed in the current page */
    currentPageData: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string.isRequired,
            itemClassifier: PropTypes.oneOf(["BUILDING", "COMPANY", "PERSON", "STUDIO"]).isRequired,
            itemId: PropTypes.string.isRequired,
            roleLabel: PropTypes.string.isRequired,
            serviceStatus: PropTypes.string,
            serviceActivationDate: PropTypes.string
        })
    ),
    /** Indicates if the assignable roles are still being loaded */
    loadingAssignableRoles: PropTypes.bool,
    /** Indicates if the current page is still being loaded */
    loadingTableItems: PropTypes.bool,
    /** Invoked when the user wants to add the selected user to an item other than one that is present in the table */
    onAddToAnotherItem: PropTypes.func.isRequired,
    /** Invoked when the user must be deleted from an item. Invoked with the itemId as the only param */
    onDeleteUserFromItem: PropTypes.func.isRequired,
    /** Invoked when the role edits have been completed and must be saved. Invoked with the updated roles and the itemId as params */
    onRoleEditConfirm: PropTypes.func.isRequired,
    /**
     * Invoked when any of the tables' filters change.
     * Receives the updated filters as the only param.
     *
     * Filter format: { [columnId1]: value, [columnId2]: value, ... }
     */
    onTableFilterChange: PropTypes.func.isRequired,
    /** Invoked when the item-level edit button is pressed in the table */
    onEditUser: PropTypes.func.isRequired,
    /** Invoked when the table page has been changed. Receives the new page as the only param */
    onPageChange: PropTypes.func.isRequired,
    /** Used to preload the assignable roles for a specific item when expanding the item's row. Invoked with the itemId as the only param */
    preloadAssignableRoles: PropTypes.func.isRequired,
    /** Indicates if the role edit is being submitted */
    submittingRoleEdit: PropTypes.bool,
    /** Indicates the total amount of items available */
    totalItems: PropTypes.number.isRequired,
    /** Indicates the amount of available pages */
    totalPages: PropTypes.number.isRequired
};

export default UserSummary;
