import React from "react";
import PropTypes from "prop-types";

import { Button } from "@ts-digital/vrc";
import { Link } from "react-router-dom";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";

import NewFlag from "../new-flag";
import { Card, CardContent, Description, CardActionsBar, NewFlagContainer, Logo } from "./styled";
import { TS_DIGITAL_IMAGES } from "../../../config";

const LinkHandler = ({ children, configRoute = {} }) =>
    configRoute.external ? (
        <a href={configRoute.to} target="_blank" rel="noopener noreferrer">
            {children}
        </a>
    ) : (
        <Link to={configRoute.to}>{children}</Link>
    );

const SuggestedAppCard = ({ esmDiscover, name, isNew, configRoute }) => {
    return (
        <Card key={name}>
            <NewFlagContainer>
                <NewFlag show={isNew} />
            </NewFlagContainer>
            <CardContent>
                <Logo src={`${TS_DIGITAL_IMAGES}apps-logo/LOGO_Digital_${name}.png`} alt={name} />
                <Description>
                    <FormattedHTMLMessage
                        id={`dashboard.appcard.${name}.shortdescription`}
                        defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    />
                </Description>
            </CardContent>
            <CardActionsBar>
                {configRoute && (
                    <LinkHandler configRoute={configRoute}>
                        <Button
                            size="small"
                            onClick={() => {
                                if (name === "ESM") {
                                    esmDiscover();
                                }
                            }}
                        >
                            <FormattedMessage id="general.discover" />
                        </Button>
                    </LinkHandler>
                )}
            </CardActionsBar>
        </Card>
    );
};

SuggestedAppCard.propTypes = {
    esmDiscover: PropTypes.func,
    name: PropTypes.string,
    configRoute: PropTypes.object,
    isNew: PropTypes.bool
};

SuggestedAppCard.defaultProps = {
    isNew: false
};

export default SuggestedAppCard;
