import { API_URL } from "../config";
import { getRestCall, postRestCall } from "../utils/rest-api-call";
import { getUserProfile } from "../utils/state-utils/get-user-profile";

export const GET_TERM_CONSENT_START = "GET_TERM_CONSENT_START";
export const GET_TERM_CONSENT_ERROR = "GET_TERM_CONSENT_ERROR";
export const GET_TERM_CONSENT_SUCCESS = "GET_TERM_CONSENT_SUCCESS";

export function getTermConsent(term) {
    return async (dispatch, getState) => {
        dispatch({ type: GET_TERM_CONSENT_START, payload: { term } });

        const { auth, profile, uuid } = getDataFromState(getState());

        try {
            const res = await getRestCall(
                `${API_URL}/consents/${uuid}/${term}`,
                auth,
                {},
                dispatch,
                auth.refreshToken,
                profile
            );

            dispatch({ type: GET_TERM_CONSENT_SUCCESS, payload: { [term]: res } });
        } catch (e) {
            dispatch({ type: GET_TERM_CONSENT_ERROR, payload: { term }, error: e });
        }
    };
}

export const GET_CONSENT_LIST_START = "GET_CONSENT_LIST_START";
export const GET_CONSENT_LIST_ERROR = "GET_CONSENT_LIST_ERROR";
export const GET_CONSENT_LIST_SUCCESS = "GET_CONSENT_LIST_SUCCESS";

export function getListConsent() {
    return async (dispatch, getState) => {
        dispatch({ type: GET_CONSENT_LIST_START, payload: {} });

        const { auth, profile, uuid } = getDataFromState(getState());

        try {
            const res = await getRestCall(
                `${API_URL}/consents/${uuid}`,
                auth,
                {},
                dispatch,
                auth.refreshToken,
                profile
            );

            dispatch({ type: GET_CONSENT_LIST_SUCCESS, payload: res });
        } catch (e) {
            dispatch({ type: GET_CONSENT_LIST_ERROR, payload: {}, error: e });
        }
    };
}

export const UPDATE_TERM_CONSENT_START = "UPDATE_TERM_CONSENT_START";
export const UPDATE_TERM_CONSENT_SUCCESS = "UPDATE_TERM_CONSENT_SUCCESS";
export const UPDATE_TERM_CONSENT_ERROR = "UPDATE_TERM_CONSENT_ERROR";

export const updateTermConsent = (term, consent) => {
    return async (dispatch, getState) => {
        dispatch({ type: UPDATE_TERM_CONSENT_START, payload: { term, consent } });

        const { auth, profile, uuid } = getDataFromState(getState());

        try {
            await postRestCall(
                `${API_URL}/consents/${uuid}/${term}`,
                auth,
                {},
                consent,
                dispatch,
                auth.refreshToken,
                profile
            );

            dispatch({ type: UPDATE_TERM_CONSENT_SUCCESS, payload: { term, consent } });
        } catch (e) {
            dispatch({ type: UPDATE_TERM_CONSENT_ERROR, payload: { term, consent }, error: e });
        }
    };
};

const getDataFromState = state => {
    const auth = state.auth.loginAuth;
    const profile = getUserProfile(state);
    const uuid = profile.uuid;

    return { auth, profile, uuid };
};
