import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import { Table } from "../../../../general/table";
import { CctStatusCell } from "../configure-managed-items/cct-status-cell";
import { ContractGenerateCell } from "../contract-generate-cell";
import { ContractUploadCell } from "../contract-upload-cell";
import { ContractOptionsCell } from "../contract-options-cell";
import { ContractWarningCell } from "../contract-warning-cell";
import { FiscalCodeCell } from "../fiscal-code-cell";
import { TableContainer } from "./styled";

export const ConfigureItem = ({
    configLoading,
    itemDescription,
    itemTaxId,
    holderTaxId,
    holderDescription,
    onDownloadClick,
    onUploadClick,
    onTableUpdate,
    provisioningStatus,
    provisioningStatusMessage,
    toBeFixed,
    isActive,
    rejectedMsg,
    toBeFixedMsg
}) => {
    const columns = useMemo(
        () => [
            {
                accessor: "fiscalCode",
                Header: <FormattedMessage id="general.company-fiscal-code" />,
                Cell: props => <FiscalCodeCell {...props} />
            },
            { accessor: "description", Header: <FormattedMessage id="general.ragione-sociale" /> },
            { accessor: "rdc_cf", Header: <FormattedMessage id="general.rdc-fiscal-code" /> },
            { accessor: "rdc_desc", Header: <FormattedMessage id="c-cct-provisioning.managed.columns.holderName" /> },
            {
                accessor: "warning",
                Header: <FormattedMessage id="general.warning" />,
                Cell: props => <ContractWarningCell {...props} />
            },
            {
                accessor: "status",
                Cell: CctStatusCell,
                Header: <FormattedMessage id="general.stato-richiesta" />
            },
            {
                accessor: "generate",
                Header: <FormattedMessage id="general.action" />,
                Cell: props => <ContractGenerateCell {...props} onClick={onDownloadClick} />
            },
            {
                accessor: "upload",
                Cell: props => <ContractUploadCell {...props} onClick={onUploadClick} />
            },
            {
                accessor: "options",
                Header: "",
                Cell: props => <ContractOptionsCell onChangeRdc={onDownloadClick} {...props} />
            }
        ],
        [onDownloadClick, onUploadClick]
    );

    const data = useMemo(
        () => [
            {
                fiscalCode: itemTaxId,
                description: itemDescription,
                rdc_cf: holderTaxId ? holderTaxId : "",
                rdc_desc: holderDescription,
                itemCertified: "CERTIFIED",
                status: provisioningStatus,
                statusMessage: provisioningStatusMessage,
                toBeFixed: toBeFixed,
                rejectedMsg: rejectedMsg,
                toBeFixedMsg: toBeFixedMsg,
                isActive: isActive
            }
        ],
        [
            itemDescription,
            itemTaxId,
            provisioningStatus,
            provisioningStatusMessage,
            toBeFixed,
            isActive,
            holderDescription,
            holderTaxId,
            rejectedMsg,
            toBeFixedMsg
        ]
    );

    return (
        <>
            <TableContainer>
                <Table
                    rowKey="fiscalCode"
                    columns={columns}
                    data={data}
                    usePagination={false}
                    loading={configLoading}
                />
            </TableContainer>
        </>
    );
};

ConfigureItem.propTypes = {
    /** Current status of loading for cct config */
    configLoading: PropTypes.bool,
    /**  Description of the selected item */
    itemDescription: PropTypes.string.isRequired,
    /**  Tax Id of the selected item */
    itemTaxId: PropTypes.string.isRequired,
    /** Callback invoked when the 'Done' button located below the table is clicked */
    onDoneButtonClick: PropTypes.func.isRequired,
    /** Callback invoked when the download button is clicked */
    onDownloadClick: PropTypes.func.isRequired,
    /** Callback invoked when the upload button is clicked */
    onUploadClick: PropTypes.func.isRequired,
    /** Callback invoked when the update button is clicked */
    onTableUpdate: PropTypes.func.isRequired,
    /** Current status of the item's provisioning process */
    provisioningStatus: PropTypes.string
};
