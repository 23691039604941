import React from "react";

import { Spin } from "antd";

import { ConnectionListLink } from "../../connections-new";
import DisplayResult from "../connection-display-result";

const CreationLayout = ({ children, connectedCompany, creationSuccess, loading }) =>
    creationSuccess ? (
        <DisplayResult
            companyId={connectedCompany.identifier.taxId}
            companyName={connectedCompany.details.description}
        />
    ) : (
        <div className="connections-create">
            <Spin spinning={loading}>
                <ConnectionListLink />
                <div className="step-container">{children}</div>
            </Spin>
        </div>
    );

CreationLayout.defaultProps = {
    connectedCompany: { details: {}, identifier: {} }
};

export default CreationLayout;
