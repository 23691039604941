import React from "react";
import PropTypes from "prop-types";

import { includes } from "ramda";
import { connect } from "react-redux";
import { compose } from "redux";

import { errorBoundary } from "../../../components/general/error-boundary";
import { CONSOLE_PEOPLEAPP } from "../../../config";
import { getCurrentCookieConsents } from "../../../utils/cookie-consent-utils";

import "./style.css";
import MigratingChildApp from "../../portale-child-app/migrating";
import { useFeature } from "../../../hooks/use-feature";
import UserNotEnabled from "./user-not-enabled";

const PeopleAppView = ({ auth, company, language, peopleAppConfig }) => {
    const enableChildApp = useFeature("PEOPLEAPP_CHILD_APP");

    return (
        <div className="c-peopleapp-view">
            {company.base.status.status === "VALIDATED" && includes("PEOPLEAPP", peopleAppConfig.services) ? (
                <MigratingChildApp
                    iframeConfigs={{
                        data: {
                            cookieConsents: getCurrentCookieConsents(),
                            id: auth.loginAuth.id,
                            language,
                            token: auth.loginAuth.securityToken,
                            aziendaCF: company.base.id
                        },
                        id: "peopleApp",
                        title: "Console"
                    }}
                    url={CONSOLE_PEOPLEAPP}
                    customLaunchData={{ aziendaCF: company.base.id }}
                    migrated={enableChildApp}
                />
            ) : (
                <UserNotEnabled company={company} peopleAppConfig={peopleAppConfig} />
            )}
        </div>
    );
};

PeopleAppView.propTypes = {
    addSteps: PropTypes.func,
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    peopleAppConfig: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => ({
    auth: state.auth,
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    language: state.user.user ? state.user.user.profile.language : undefined,
    peopleAppConfig: state.services.active
});

const composedHoc = compose(connect(mapStateToProps), errorBoundary);

export default composedHoc(PeopleAppView);
