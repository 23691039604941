import { GET_CATEGORIES_START, GET_CATEGORIES_ERROR, GET_CATEGORIES_SUCCESS } from "../../actions/services/categories";

const defaultState = {
    status: { started: false, ended: false, error: false, errorInfo: {} },
    data: {}
};

export const categories = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_CATEGORIES_START:
            return {
                ...defaultState,
                status: {
                    ...defaultState.status,
                    started: true
                }
            };
        case GET_CATEGORIES_ERROR:
            return {
                ...defaultState,
                status: {
                    ...defaultState.status,
                    error: true,
                    errorInfo: error
                }
            };
        case GET_CATEGORIES_SUCCESS:
            return {
                status: {
                    ...defaultState.status,
                    error: true,
                    errorInfo: error
                },
                data: payload
            };
        default:
            return state;
    }
};
