import React from "react";
import Select, { components } from "react-select";
import { customStyles } from "./custom-styles";
import { Checkbox } from "@ts-digital/vrc";

import { translateMessage } from "../../../i18n";

import { OptionContainer, OptionChildrenContainer, PlusFiltersLabel, SelectedOption } from "./styled";

const Option = props => {
    const padding = props.data.featureCode ? { paddingLeft: "25px" } : {};

    const content = (
        <div style={{ ...padding }}>
            <OptionContainer>
                <Checkbox name={props.data.value} onChange={() => {}} checked={props.isSelected} />
                <OptionChildrenContainer>{props.children}</OptionChildrenContainer>
            </OptionContainer>
        </div>
    );

    return <components.Option {...props} children={content} isSelected={false} />;
};

const Control = ({ children, ...props }) => {
    const value = props.getValue();

    let filtersLabel = "";
    if (value.length && value.length > 1) {
        filtersLabel = `+ ${value.length - 1} ${translateMessage("general.filters")}`;
    }

    return (
        <components.Control {...props}>
            {value[0] ? <SelectedOption>{value[0].label}</SelectedOption> : children[0]}
            <PlusFiltersLabel>{filtersLabel}</PlusFiltersLabel>
            {children[1]}
        </components.Control>
    );
};

export const MultiSelect = ({ options, selectedOptions, onChange, placeholder }) => {
    return (
        <Select
            styles={customStyles}
            defaultValue={selectedOptions}
            value={selectedOptions}
            onChange={onChange}
            options={options}
            placeholder={placeholder}
            closeMenuOnSelect={true}
            isMulti={true}
            components={{ Control, Option }}
            hideSelectedOptions={false}
        />
    );
};

export default MultiSelect;
