import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { Button } from "@ts-digital/vrc";

import "./style.css";
import { TitleContainer, Title, Date, Content, ButtonsContainer } from "./styled";

const DetailedNotification = ({
    accepted,
    accepting,
    id,
    date,
    title,
    content,
    displayActions,
    notification,
    onAccept,
    onReject,
    rejected,
    rejecting
}) => {
    const handleNotificationStatus = notification => {
        let notificationStatus = null;
        if (notification.accepted) {
            notificationStatus = <FormattedMessage id="c-notification.status.accepted" />;
        } else if (notification.rejected) {
            notificationStatus = <FormattedMessage id="c-notification.status.rejected" />;
        }
        return notificationStatus;
    };

    return (
        <div className="c-detailed-notification">
            {content ? (
                <>
                    <TitleContainer>
                        <Title>{title}</Title>
                        <Date>{date}</Date>
                    </TitleContainer>
                    <Content>{content}</Content>
                    <br />
                    <div>{handleNotificationStatus(notification)}</div>
                </>
            ) : (
                <FormattedMessage id="c-detailed-notification.no-notification-selected" />
            )}
            {displayActions && content && !accepted && !rejected && (
                <ButtonsContainer>
                    <Button
                        kind="secondary"
                        disabled={accepting || rejecting}
                        loading={rejecting}
                        onClick={() => onReject(id)}
                    >
                        <FormattedMessage id="c-notification.action-reject" />
                    </Button>
                    <Button
                        kind="primary"
                        disabled={accepting || rejecting}
                        loading={accepting}
                        onClick={() => onAccept(id)}
                    >
                        <FormattedMessage id="c-notification.action-accept" />
                    </Button>
                </ButtonsContainer>
            )}
        </div>
    );
};

DetailedNotification.propTypes = {
    accepted: PropTypes.bool,
    id: PropTypes.string,
    date: PropTypes.element,
    title: PropTypes.any,
    content: PropTypes.any,
    displayActions: PropTypes.bool,
    loading: PropTypes.bool,
    onAccept: PropTypes.func,
    onReject: PropTypes.func,
    rejected: PropTypes.bool,
    notification: PropTypes.object
};

DetailedNotification.defaultProps = {
    displayActions: false,
    onAccept: id => {},
    onReject: id => {}
};

export default DetailedNotification;
