import styled from "styled-components";

export const AsideImage = styled.img`
    max-width: 100%;
    height: auto;
    display: none;

    @media (min-width: 1200px) {
        display: block;
        margin: 96px 0 0 -24px;
    }
`;
