import React from "react";
import PropTypes from "prop-types";

import { Pagination } from "antd";

import { PaginationContainer } from "./styled";

export const LinkListPagination = ({ onChange, page, totalLinks }) => (
    <PaginationContainer>
        <Pagination
            current={page + 1}
            hideOnSinglePage={true}
            onChange={page => onChange(page - 1)}
            pageSize={5}
            total={totalLinks}
        />
    </PaginationContainer>
);

LinkListPagination.propTypes = {
    onChange: PropTypes.func.isRequired,
    page: PropTypes.number,
    totalLinks: PropTypes.number
};

LinkListPagination.defaultProps = {
    page: 0,
    totalLinks: 0
};
