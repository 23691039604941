import React from "react";
import { callWithRetry, getAuthWithAppName } from "../auth";
import { API_URL, GDPR_LOG_CONFIG_URL } from "../../config";

import gdprLogConfig from "../../assets/thrift/gdprlog-config/GdprLogConfig";
import gdprLogTypes from "../../assets/thrift/gdprlog-config/gdprlog_types";

import thrift from "browser-thrift";
import { getRestCall } from "../../utils/rest-api-call";
import { getUserProfile } from "../../utils/state-utils/get-user-profile";
import { displayErrorToast, displaySuccessToast } from "../overflowNotification";
import { FormattedMessage } from "react-intl";

const gdprLogUrl = new URL(GDPR_LOG_CONFIG_URL);

const gdprLogClient = thrift.createXHRClient(
    gdprLogConfig,
    thrift.createXHRConnection(gdprLogUrl.hostname, 443, {
        useCors: true,
        path: gdprLogUrl.pathname,
        https: gdprLogUrl.protocol === "https:"
    })
);

export const GDPR_LOG_SET_RESPONSIBILITY_START = "GDPR_LOG_SET_RESPONSIBILITY_START";
export const GDPR_LOG_SET_RESPONSIBILITY_ERROR = "GDPR_LOG_SET_RESPONSIBILITY_ERROR";
export const GDPR_LOG_SET_RESPONSIBILITY_SUCCESS = "GDPR_LOG_SET_RESPONSIBILITY_SUCCESS";

export const GDPR_LOG_GET_RESPONSIBILITY_START = "GDPR_LOG_GET_RESPONSIBILITY_START";
export const GDPR_LOG_GET_RESPONSIBILITY_ERROR = "GDPR_LOG_GET_RESPONSIBILITY_ERROR";
export const GDPR_LOG_GET_RESPONSIBILITY_SUCCESS = "GDPR_LOG_GET_RESPONSIBILITY_SUCCESS";

/**
 * esegue l'rpc thrift che imposta l'utente come responsabile per la visione dei log GDPR.
 * Questa assunzione di responsabilità è un'autocertificazione e può essere eseguita da più soggetti
 * @param auth (dati di autenticazione del soggetto)
 * @param extraData contiene un elenco di codici fiscali di aziende (ma ne basta uno)
 * @returns {function(*=)}
 */
export function setGdprLogRoleAssociation(listSelected, recipientData) {
    return (dispatch, getState) => {
        dispatch({
            type: GDPR_LOG_SET_RESPONSIBILITY_START
        });
        const request = new gdprLogTypes.SetRoleRequest({
            roleList: listSelected,
            recipient: recipientData
        });

        const auth = getAuthWithAppName(getState().auth);
        callWithRetry(
            gdprLogClient,
            gdprLogClient.setGdprLogRoleAssociation,
            auth,
            request,
            auth.refreshToken,
            dispatch,
            (error, response) => {
                if (error !== null) {
                    dispatch({
                        type: GDPR_LOG_SET_RESPONSIBILITY_ERROR,
                        error: error
                    });
                    displayErrorToast(<FormattedMessage id="gdprlog.general-error" />)(dispatch);
                } else {
                    dispatch({
                        type: GDPR_LOG_SET_RESPONSIBILITY_SUCCESS,
                        payload: response
                    });

                    const email = response.pec;
                    displaySuccessToast(<FormattedMessage id="gdpr.set-role-association.success" values={{ email }} />)(
                        dispatch
                    );
                }
            }
        );
    };
}

export function getGdprLogRoleAssociation(itemId) {
    return async (dispatch, getState) => {
        dispatch({ type: GDPR_LOG_GET_RESPONSIBILITY_START });
        try {
            const auth = getAuthWithAppName(getState().auth);
            const res = await getRestCall(
                `${API_URL}/services/gdpr-config/${itemId}`,
                auth,
                {},
                dispatch,
                auth.refreshToken,
                getUserProfile(getState())
            );
            dispatch({ type: GDPR_LOG_GET_RESPONSIBILITY_SUCCESS, payload: res });
        } catch (e) {
            dispatch({ type: GDPR_LOG_GET_RESPONSIBILITY_ERROR, error: e });
        }
    };
}
