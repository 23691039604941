import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { faBell, faUser, faCog } from "@fortawesome/pro-regular-svg-icons";
import { Button } from "@ts-digital/vrc";
import { Col, Layout, Row } from "antd";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import PageTitle from "../../../components/general/page-title";
import CompaniesSelect from "../../../components/notifications-center/companies-select";
import Sidebar from "../../../components/notifications-center/sidebar";

import { getNcsBasePath } from "../../../utils/route";

import {
    ButtonContainer,
    ButtonIcon,
    Description,
    PageContainer,
    SelectAndButtonContainer,
    SelectContainer,
    Separator,
    Title,
    TitleIcon,
    Wrapper
} from "./styled";

export const DashboardView = ({ company, match: { params } }) => {
    const history = useHistory();
    const [companySelected, setCompanySelected] = useState(false);
    const { item } = params;

    useEffect(() => {
        if (item && company) {
            setCompanySelected(item);
        }
    }, [company, item]);

    const handleCompanyButtonClick = useCallback(() => {
        history.push(`${getNcsBasePath(companySelected, item)}/preferences`);
    }, [companySelected, item, history]);

    const handleBackButtonClick = useCallback(() => {
        const targetLink = item ? `/${item}/dashboard` : "/";
        history.push(targetLink);
    }, [item, history]);

    return (
        <Layout>
            <PageTitle
                title={<FormattedMessage id="c-notifications-center.title1" />}
                onBack={() => handleBackButtonClick()}
            />
            <PageContainer>
                <Row gutter={32}>
                    <Col xs={0} xl={5}>
                        <Sidebar />
                    </Col>
                    <Col xs={{ span: 22, offset: 1 }} xl={{ span: 16, offset: 1 }}>
                        <Wrapper>
                            <Title>
                                <TitleIcon icon={faBell} />
                                <FormattedMessage id="c-notifications-center.homepage.items-preferences-title" />
                            </Title>
                            <SelectAndButtonContainer>
                                <SelectContainer>
                                    <CompaniesSelect
                                        defaultValue={item}
                                        handleChange={value => setCompanySelected(value)}
                                    />
                                </SelectContainer>
                                <Button
                                    kind="primary"
                                    disabled={!companySelected}
                                    onClick={() => handleCompanyButtonClick()}
                                >
                                    <FormattedMessage id="c-notifications-center.homepage.items-preferences-button" />
                                </Button>
                            </SelectAndButtonContainer>
                            <Separator />
                            <Title>
                                <TitleIcon icon={faUser} />
                                <FormattedMessage id="c-notifications-center.homepage.personal-preferences-title" />
                            </Title>
                            <Description>
                                <FormattedMessage id="c-notifications-center.homepage.personal-preferences-description" />
                            </Description>
                            <ButtonContainer>
                                <Link to={`${getNcsBasePath(null, item)}/preferences`}>
                                    <Button kind="tertiary">
                                        <ButtonIcon icon={faCog} />
                                        <FormattedMessage id="c-notifications-center.homepage.items-preferences-button" />
                                    </Button>
                                </Link>
                            </ButtonContainer>
                        </Wrapper>
                    </Col>
                </Row>
            </PageContainer>
        </Layout>
    );
};

DashboardView.propTypes = {
    company: PropTypes.object
};

const mapStateToProps = (state, props) => ({
    company: state.companies.data[props.match.params.item] && state.companies.data[props.match.params.item].item.base
});

export default connect(mapStateToProps, null)(DashboardView);
