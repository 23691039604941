import React from "react";
import PropTypes from "prop-types";

import { Menu } from "antd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { faChartNetwork, faUsersCog } from "@fortawesome/pro-regular-svg-icons";

import { isAdmin } from "../../../utils/app-availability";
import { DISABLE_USER_LIST_NOTOWNER } from "../../../config";
import { hasAdminRole, hasManagerRoles } from "../../../utils/get-roles";
import { MenuIcon } from "../menu-icon";
import { useFeature } from "../../../hooks/use-feature";

export const TopMenu = ({
    isStudio,
    isPerson,
    itemId,
    meteringStatus,
    meteringPermissions,
    selectedMenuItem,
    user
}) => {
    const newCustomerManagement = useFeature("NEW_CUSTOMER_MANAGEMENT");
    const newIntermediary = useFeature("NEW_INTERMEDIARY");
    const newIntermediaryPreviewList = useFeature("LIST_NEW_INTERMEDIARY_PREVIEW") || "";
    const meteringServices = meteringPermissions && meteringPermissions.services ? meteringPermissions.services : {};
    const menuMeteringActive = Object.values(meteringServices).some(val => val !== false);

    const newIntermediaryEnabled =
        newIntermediary ||
        newIntermediaryPreviewList
            .split(",")
            .map(it => it.split(";")[0])
            .includes(itemId);

    return (
        <Menu className="c-sideMenu-menu" selectedKeys={[selectedMenuItem || "dashboard"]} mode="inline" theme="dark">
            <Menu.Item key="dashboard">
                <Link to={`/${itemId}/dashboard`}>
                    <MenuIcon iconName="home" offset="-2" />
                    <span>
                        <FormattedMessage id="general.dashboard" />
                    </span>
                </Link>
            </Menu.Item>
            {isPerson ||
            (DISABLE_USER_LIST_NOTOWNER === "true" &&
                !hasAdminRole(itemId, user.user.roles) &&
                !hasManagerRoles(itemId, user)) ? null : (
                <Menu.SubMenu
                    key="subRegistry"
                    title={
                        <div>
                            <MenuIcon iconName="building" />
                            <span>
                                <FormattedMessage id="general.anagrafica" />
                            </span>
                        </div>
                    }
                    data-cy="subRegistry"
                >
                    <Menu.Item key="/registry/user" data-cy="user">
                        <Link to={`/${itemId}/registry/user`}>
                            <MenuIcon iconName="users" />
                            <FormattedMessage id="general.users" data-gainsight-track={"userManagementFlow-v2"} />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/registry/apikey" data-cy="apiKey">
                        <Link to={`/${itemId}/registry/apikey`}>
                            <MenuIcon iconName="id-badge" />
                            <FormattedMessage id="general.applications" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/registry/office" data-cy="office">
                        <Link to={`/${itemId}/registry/office`}>
                            <MenuIcon iconName="industry" />
                            <FormattedMessage id="general.offices" />
                        </Link>
                    </Menu.Item>
                    {isStudio && !newIntermediaryEnabled && (
                        <Menu.Item key="/registry/intermediary" data-cy="intermediary">
                            <Link to={`/${itemId}/registry/intermediary`}>
                                <MenuIcon iconName="id-card-alt" />
                                <FormattedMessage id="general.intermediari" />
                            </Link>
                        </Menu.Item>
                    )}
                    {newIntermediaryEnabled && (
                        <Menu.Item key="/intermediary" data-cy="intermediary">
                            <Link to={`/${itemId}/intermediary`}>
                                <MenuIcon iconName="id-card-alt" />
                                <FormattedMessage id="general.intermediari" />
                            </Link>
                        </Menu.Item>
                    )}
                </Menu.SubMenu>
            )}
            {isAdmin(user.user.roles, itemId) && (
                <Menu.Item key="connections">
                    <Link to={`/${itemId}/connections`}>
                        <MenuIcon iconObject={faChartNetwork} offset="-2" />
                        <span>
                            <FormattedMessage id="general.connections" />
                        </span>
                    </Link>
                </Menu.Item>
            )}
            {isAdmin(user.user.roles, itemId) && newCustomerManagement && (
                <Menu.Item key="customer-management">
                    <Link to={`/${itemId}/customer-management`}>
                        <MenuIcon iconObject={faUsersCog} offset="-2" />
                        <span>
                            <FormattedMessage id="general.customer-management" />
                        </span>
                    </Link>
                </Menu.Item>
            )}
            {meteringStatus.enabled && !meteringStatus.loading && !meteringStatus.error && menuMeteringActive && (
                <Menu.Item key="metering">
                    <Link to={`/${itemId}/metering`}>
                        <MenuIcon iconName="tachometer-alt" offset="-2" />
                        <span>
                            <FormattedMessage id="c-services-view-metering" />
                        </span>
                    </Link>
                </Menu.Item>
            )}
        </Menu>
    );
};

TopMenu.propTypes = {
    isStudio: PropTypes.bool,
    isPerson: PropTypes.bool,
    itemId: PropTypes.string.isRequired,
    meteringStatus: PropTypes.shape({
        enabled: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.any
    }).isRequired,
    meteringPermissions: PropTypes.object,
    selectedMenuItem: PropTypes.string,
    services: PropTypes.object,
    user: PropTypes.object.isRequired
};
