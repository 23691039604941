import styled from "styled-components";
import { Table } from "antd";

export const NestedTable = styled(Table)`
    & .ant-table-placeholder {
        padding: 8px;
    }
    & .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
        background: #e4eaf0;
    }
    & .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters {
        background: #e4eaf0;
    }
    & .ant-table-thead > tr > th.ant-table-align-center {
        background: #e4eaf0;
    }
`;

export const FooterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
