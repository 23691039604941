import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import uuid from "uuid";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { Container, InputContainer, SearchButton, SearchIcon } from "./styled";
import { Input } from "@ts-digital/vrc";

const SearchBar = ({
    disabled,
    error,
    errorMessage,
    id = uuid(),
    label,
    onBlur,
    onFocus,
    onSearch,
    placeholder,
    searchOnEnter,
    ...rest
}) => {
    const [value, setValue] = useState("");

    const handleKeyPress = useCallback(
        e => {
            if (e.key === "Enter" && searchOnEnter) {
                onSearch(value);
            }
        },
        [onSearch, searchOnEnter, value]
    );

    return (
        <Container {...rest}>
            <InputContainer>
                <Input
                    disabled={disabled}
                    error={error}
                    errorMessage={errorMessage}
                    id={id}
                    label={label}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onKeyPress={handleKeyPress}
                    placeholder={placeholder}
                    onChange={value => setValue(value)}
                    value={value}
                />
            </InputContainer>
            <SearchButton kind="primary" onClick={() => onSearch(value)} tabIndex={-1}>
                <SearchIcon icon={faSearch} />
            </SearchButton>
        </Container>
    );
};

SearchBar.defaultProps = {
    searchOnEnter: true
};

SearchBar.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.node,
    id: PropTypes.string,
    label: PropTypes.node,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
    searchOnEnter: PropTypes.bool
};

export default SearchBar;
