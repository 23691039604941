import { SIGN_API_WRITE_URL } from "../config";
import { getAuthWithAppName, callWithRetry } from "./auth";

import SignApiWrite from "../assets/thrift/sign-api/SigninApiWrite";
import SignApiWriteTypes from "../assets/thrift/sign-api/apiWrite_types";
import thrift from "browser-thrift";

const signApiWriteUrl = new URL(SIGN_API_WRITE_URL);
const signApiWriteClient = thrift.createXHRClient(
    SignApiWrite,
    thrift.createXHRConnection(signApiWriteUrl.hostname, 443, {
        useCors: true,
        path: signApiWriteUrl.pathname,
        https: signApiWriteUrl.protocol === "https:"
    })
);

export const CREATE_PDF_START = "CREATE_PDF_START";
export const CREATE_PDF_SUCCESS = "CREATE_PDF_SUCCESS";
export const CREATE_PDF_ERROR = "CREATE_PDF_ERROR";

export function createPdf(signerRef, formData) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: CREATE_PDF_START
            });

            const request = new SignApiWriteTypes.CreatePdfRequest({
                signerRef: signerRef,
                formData: formData
            });
            const auth = getAuthWithAppName(getState().auth);
            callWithRetry(
                signApiWriteClient,
                signApiWriteClient.createPdf,
                auth,
                request,
                auth.refreshToken,
                dispatch,
                (error, result) => {
                    if (error !== null) {
                        dispatch({
                            type: CREATE_PDF_ERROR,
                            error: error
                        });
                        reject();
                    } else {
                        dispatch({
                            type: CREATE_PDF_SUCCESS,
                            payload: result
                        });
                        resolve();
                    }
                }
            );
        });
    };
}

export const CREATE_PDF_RESET = "CREATE_PDF_RESET";

export function resetCreatePdf() {
    return {
        type: CREATE_PDF_RESET
    };
}

export const UPLOAD_SIGNED_PDF_START = "UPLOAD_SIGNED_PDF_START";
export const UPLOAD_SIGNED_PDF_SUCCESS = "UPLOAD_SIGNED_PDF_SUCCESS";
export const UPLOAD_SIGNED_PDF_ERROR = "UPLOAD_SIGNED_PDF_ERROR";

export function uploadSignedPdf(signerId, file) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: UPLOAD_SIGNED_PDF_START
            });

            const request = new SignApiWriteTypes.UploadSignedPdfRequest({
                signerId: signerId,
                file: file
            });
            const auth = getAuthWithAppName(getState().auth);
            callWithRetry(
                signApiWriteClient,
                signApiWriteClient.uploadSignedPdf,
                auth,
                request,
                auth.refreshToken,
                dispatch,
                (error, result) => {
                    if (error !== null) {
                        dispatch({
                            type: UPLOAD_SIGNED_PDF_ERROR,
                            error: error
                        });
                        reject();
                    } else {
                        dispatch({
                            type: UPLOAD_SIGNED_PDF_SUCCESS,
                            payload: result
                        });
                        resolve();
                    }
                }
            );
        });
    };
}

export const ASSIGN_SIGNER_START = "ASSIGN_SIGNER_START";
export const ASSIGN_SIGNER_SUCCESS = "ASSIGN_SIGNER_SUCCESS";
export const ASSIGN_SIGNER_ERROR = "ASSIGN_SIGNER_ERROR";

export function assignSigner(signerRef, cfAzienda, pin) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: ASSIGN_SIGNER_START
            });

            const request = new SignApiWriteTypes.AssignSignerRequest({
                signerRef: signerRef,
                cfAzienda: cfAzienda,
                pin: pin
            });

            const auth = getAuthWithAppName(getState().auth);
            signApiWriteClient
                .assignSigner(auth, request)
                .then(result => {
                    dispatch({
                        type: ASSIGN_SIGNER_SUCCESS,
                        payload: result
                    });
                    resolve();
                })
                .catch(err => {
                    dispatch({
                        type: ASSIGN_SIGNER_ERROR,
                        error: err
                    });
                    reject();
                });
        });
    };
}

export const ASSIGN_SIGNER_RESET = "ASSIGN_SIGNER_RESET";

export function resetAssignSigner() {
    return {
        type: ASSIGN_SIGNER_RESET
    };
}

export const RESET_PIN_START = "RESET_PIN_START";
export const RESET_PIN_SUCCESS = "RESET_PIN_SUCCESS";
export const RESET_PIN_ERROR = "RESET_PIN_ERROR";

export function resetPin(signerId, pin, puk, savePin) {
    return (dispatch, getState) => {
        dispatch({
            type: RESET_PIN_START
        });

        const request = new SignApiWriteTypes.ResetPinRequest({
            signerId: signerId,
            pin: pin,
            puk: puk,
            savePin
        });
        const auth = getAuthWithAppName(getState().auth);
        callWithRetry(
            signApiWriteClient,
            signApiWriteClient.resetPin,
            auth,
            request,
            auth.refreshToken,
            dispatch,
            (error, result) => {
                if (error !== null) {
                    dispatch({
                        type: RESET_PIN_ERROR,
                        error: error
                    });
                } else {
                    dispatch({
                        type: RESET_PIN_SUCCESS,
                        payload: result
                    });
                }
            }
        );
    };
}

export const RESET_PIN_RESET = "RESET_PIN_RESET";

export function resetStateResetPin() {
    return {
        type: RESET_PIN_RESET
    };
}

export const CANCEL_START = "CANCEL_START";
export const CANCEL_SUCCESS = "CANCEL_SUCCESS";
export const CANCEL_ERROR = "CANCEL_ERROR";

export function cancel(signerId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: CANCEL_START
            });

            const request = new SignApiWriteTypes.CancelRequest({
                signerId: signerId
            });
            const auth = getAuthWithAppName(getState().auth);
            signApiWriteClient
                .cancel(auth, request)
                .then(result => {
                    dispatch({
                        type: CANCEL_SUCCESS,
                        payload: result
                    });
                    resolve();
                })
                .catch(err => {
                    dispatch({
                        type: CANCEL_ERROR,
                        error: err
                    });
                    reject();
                });
        });
    };
}
