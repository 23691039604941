import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { compose } from "redux";
import { FormattedMessage } from "react-intl";

import "../style.css";

import { resetCctConfig, verifyCCTcredentials } from "../../../actions/services/cct";
import { getCctConfig, saveCctConfig } from "../../../actions/services/cct";
import { fetchCctProvisioningInfo } from "../../../actions/services/cct/provisioning";
import { mergeStatus } from "../../../utils/state-utils/get-merged-status";

import Storage from "../../../components/storage";
import { errorBoundary } from "../../../components/general/error-boundary";
import PageTitle from "../../../components/general/page-title";

const StorageView = ({
    agyoServices,
    cctConfig,
    company,
    configError,
    configLoaded,
    configLoading,
    credentialsStatus,
    fetchCctProvisioningInfo,
    getCctConfig,
    history,
    isCctActive,
    match,
    provisioningInfo,
    resetCctConfig,
    saveCctConfig,
    services,
    userRoles,
    verifyCCTcredentials
}) => {
    const itemId = match.params.item;

    useEffect(() => {
        resetCctConfig();
    }, [resetCctConfig]);

    useEffect(() => {
        if (!configLoading && !configLoaded && !configError && isCctActive) {
            fetchCctProvisioningInfo(itemId);
            getCctConfig(itemId, itemId);
        }
    }, [configLoaded, configLoading, configError, fetchCctProvisioningInfo, getCctConfig, isCctActive, itemId]);

    const handleCctConfigChange = useCallback(
        specs => {
            saveCctConfig(itemId, itemId, specs);
        },
        [itemId, saveCctConfig]
    );

    const handleCredentialsChange = useCallback(
        (cctUser, cctPass) => {
            verifyCCTcredentials(cctUser, cctPass, itemId, itemId);
        },
        [itemId, verifyCCTcredentials]
    );

    return (
        <div className="service-view">
            <PageTitle title={<FormattedMessage id="c-services-view.ARC.name" />} onBack={() => history.goBack()} />
            <span>
                <Storage
                    activeServices={services.active.services}
                    agyoServices={agyoServices}
                    availableServices={services.active.availableToUser}
                    categories={services.categories}
                    cctConfig={cctConfig}
                    companyStatus={mergeStatus(company.base.status)}
                    credentialsChangeStatus={credentialsStatus}
                    itemId={itemId}
                    itemType={company.base.details.classifier}
                    onCredentialsChange={handleCredentialsChange}
                    onConfigChange={handleCctConfigChange}
                    provisioningInfoLoading={provisioningInfo.isLoading}
                    provisioningInfo={provisioningInfo.provisioningInfo[itemId]}
                    userRoles={userRoles}
                    verifyCCTcredentials={verifyCCTcredentials}
                />
            </span>
        </div>
    );
};

StorageView.propTypes = {
    agyoServices: PropTypes.object.isRequired,
    cctConfig: PropTypes.object.isRequired,
    configLoaded: PropTypes.bool.isRequired,
    configLoading: PropTypes.bool.isRequired,
    credentialsStatus: PropTypes.object.isRequired,
    getCctConfig: PropTypes.func.isRequired,
    isCctActive: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    resetCctConfig: PropTypes.func.isRequired,
    saveCctConfig: PropTypes.func.isRequired,
    services: PropTypes.object.isRequired,
    userRoles: PropTypes.array.isRequired,
    verifyCCTcredentials: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => ({
    agyoServices: state.services.agyoServices,
    cctConfig: state.services.cct.getCctConfig,
    provisioningInfo: state.services.cct.provisioningInfo,
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    configError: state.services.cct.getCctConfig.status.error,
    configLoading: state.services.cct.getCctConfig.status.started,
    configLoaded: state.services.cct.getCctConfig.status.ended,
    credentialsStatus: state.services.cct.verifyCct.status,
    isCctActive: state.services.active.services.includes("CCT-FLOW") && !state.services.active.services.includes("ARC"),
    services: state.services,
    userRoles: (state.user.user && state.user.user.roles) || []
});

const actions = {
    fetchCctProvisioningInfo,
    getCctConfig,
    resetCctConfig,
    saveCctConfig,
    verifyCCTcredentials
};

const composedHoc = compose(connect(mapStateToProps, actions), errorBoundary);

export default composedHoc(StorageView);
