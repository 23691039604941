import { DELETE_LOGO_SUCCESS } from "../../actions/manage-logo/index";
import { GET_LOGO_SUCCESS } from "../../actions/manage-logo/index";
import { UPLOAD_LOGO_SUCCESS } from "../../actions/manage-logo/index";

const defaultState = {};

export const logos = (state = defaultState, { type, payload }) => {
    switch (type) {
        case GET_LOGO_SUCCESS:
        case UPLOAD_LOGO_SUCCESS:
            return { ...state, [payload.itemId]: payload.logoUrl };
        case DELETE_LOGO_SUCCESS:
            return { ...state, [payload.itemId]: "" };
        default:
            return state;
    }
};
