import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { isNil } from "ramda";
import { FormattedMessage, useIntl } from "react-intl";
import { Icon, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { faBell } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { canModifyData, hasAdminOwnerUser, isItemAdmin } from "../../../../utils/get-roles";
import { mergeStatus } from "../../../../utils/state-utils/get-merged-status";

import { ENABLE_WEB_NOTIFICATIONS } from "../../../../config";

import { CompanyCard, CardHeader, CardDescription, CardButtons, CardTitle, CardIcons, SettingsIcon } from "./styled";

const ItemCard = ({ item, listUsers, user, users }) => {
    const intl = useIntl();

    const [editable, setEditable] = useState();

    useEffect(() => {
        if (editable === undefined && users && users.users) {
            !hasAdminOwnerUser(item.base.id, users) ? setEditable(true) : setEditable(false);
        }
    }, [editable, item.base.id, users]);

    useEffect(() => {
        if (canModifyData(user, item.base.id)) {
            setEditable(true);
        } else {
            listUsers(true, true, item.base.uuid);
        }
    }, [user, item.base.id, item.base.uuid, listUsers]);

    const itemStatus = mergeStatus(item.base.status);

    let isValidated =
        ![
            "VALIDATED",
            "UNVERIFIABLE_PENDING_VALIDATE",
            "REJECTED_PENDING_VALIDATE",
            "CERTIFIED",
            "AWAITING_APPROVAL"
        ].includes(itemStatus) && isItemAdmin(item.base.id, { user })
            ? { visible: "true", color: "#F44336" }
            : { visible: "none", color: "#34495e" };

    let statusColor = { color: "#494a4c" };
    if (!isNil(item.base.status.status)) {
        statusColor = item.base.status.status.includes("PENDING")
            ? { color: "#f4a836" }
            : item.base.status.status.includes("VALIDATED")
            ? { color: "#494a4c" }
            : { color: "#F44336" };
    }

    let companyType = item.base.details.classifier;
    if (intl.messages[`c-manage-data.company.card.${item.base.details.classifier.toLowerCase()}`]) {
        companyType = intl.formatMessage({
            id: `c-manage-data.company.card.${item.base.details.classifier.toLowerCase()}`
        });
    }

    return (
        <CompanyCard key={item.base.id} index={item.index}>
            <CardHeader>
                <CardTitle>
                    {item.base.details.classifier === "PERSON"
                        ? `${item.base.details.firstName} ${item.base.details.lastName}`
                        : item.base.details.description}
                </CardTitle>
                <CardIcons>
                    <SettingsIcon>
                        <Link
                            to={`/${item.base.id}/company/validate`}
                            style={{
                                display: isValidated.visible,
                                color: isValidated.color
                            }}
                        >
                            <Icon type="safety" />
                        </Link>
                    </SettingsIcon>
                    {ENABLE_WEB_NOTIFICATIONS && (
                        <Tooltip title={<FormattedMessage id="c-manage-data.company.card.notificationsIcon" />}>
                            <SettingsIcon>
                                <Link to={`/notifications-center/${item.base.id}/preferences`}>
                                    <FontAwesomeIcon icon={faBell} />
                                </Link>
                            </SettingsIcon>
                        </Tooltip>
                    )}
                    <Tooltip
                        title={
                            <FormattedMessage
                                id={
                                    item.base.details.classifier === "PERSON"
                                        ? `c-manage-data.company.card.person-${editable ? "editIcon" : "modifyIcon"}`
                                        : `c-manage-data.company.card.${editable ? "editIcon" : "modifyIcon"}`
                                }
                                defaultMessage={""}
                            />
                        }
                    >
                        <SettingsIcon>
                            <Link to={`/${item.base.id}/edit`}>
                                <Icon type={editable ? "form" : "file-text"} />
                            </Link>
                        </SettingsIcon>
                    </Tooltip>
                </CardIcons>
            </CardHeader>
            <CardDescription>
                <FormattedMessage
                    id={
                        item.base.details.classifier === "PERSON"
                            ? "c-manage-data.company.card.person-status"
                            : "c-manage-data.company.card.status"
                    }
                />
                <strong style={{ color: statusColor.color }}>
                    <FormattedMessage id={`general.status.${mergeStatus(item.base.status)}`} />
                </strong>
                <FormattedMessage id="c-manage-data.company.card.taxId" />
                <strong>{item.base.identifier.taxId}</strong>
                {item.base.details.classifier !== "PERSON" ? (
                    <>
                        <FormattedMessage id="c-manage-data.company.card.vatNumber" />
                        <strong>{item.base.identifier.vatNumber}</strong>
                    </>
                ) : (
                    <></>
                )}
                <FormattedMessage id={"c-manage-data.company.card.type"} />
                <strong>{companyType}</strong>
            </CardDescription>
            <Link to={`/${item.base.id}/dashboard`}>
                <CardButtons>
                    <FormattedMessage id="general.select" />
                    <Icon type="right-circle" />
                </CardButtons>
            </Link>
        </CompanyCard>
    );
};

ItemCard.propTypes = {
    item: PropTypes.object.isRequired,
    listUsers: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    users: PropTypes.object
};

export default ItemCard;
