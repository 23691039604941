import {
    ACCEPT_NOTIFICATION_START,
    ACCEPT_NOTIFICATION_ERROR,
    ACCEPT_NOTIFICATION_SUCCESS,
    REJECT_NOTIFICATION_ERROR,
    REJECT_NOTIFICATION_START,
    REJECT_NOTIFICATION_SUCCESS,
    READ_NOTIFICATION_ERROR,
    READ_NOTIFICATION_START,
    READ_NOTIFICATION_SUCCESS,
    UNREAD_NOTIFICATION_ERROR,
    UNREAD_NOTIFICATION_START,
    UNREAD_NOTIFICATION_SUCCESS
} from "../../actions/notification/write";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {}
    },
    result: {}
};

export function acceptNotification(state = defaultState, { type, payload }) {
    switch (type) {
        case ACCEPT_NOTIFICATION_START:
            return {
                ...defaultState,
                status: {
                    ...defaultState.status,
                    started: true
                }
            };
        case ACCEPT_NOTIFICATION_ERROR:
            return {
                ...state,
                status: {
                    ...state.status,
                    started: false,
                    error: true,
                    errorInfo: payload.error
                }
            };
        case ACCEPT_NOTIFICATION_SUCCESS:
            return {
                ...state,
                status: {
                    ...state.status,
                    started: false,
                    ended: true
                },
                result: payload
            };
        default:
            return state;
    }
}

export function rejectNotification(state = defaultState, { type, payload }) {
    switch (type) {
        case REJECT_NOTIFICATION_START:
            return {
                ...defaultState,
                status: {
                    ...defaultState.status,
                    started: true
                }
            };
        case REJECT_NOTIFICATION_ERROR:
            return {
                ...state,
                status: {
                    ...state.status,
                    started: false,
                    error: true,
                    errorInfo: payload.error
                }
            };
        case REJECT_NOTIFICATION_SUCCESS:
            return {
                ...state,
                status: {
                    ...state.status,
                    started: false,
                    ended: true
                },
                result: payload
            };
        default:
            return state;
    }
}

export function readNotification(state = defaultState, { type, payload }) {
    switch (type) {
        case READ_NOTIFICATION_START:
            return {
                ...defaultState,
                started: true
            };
        case READ_NOTIFICATION_ERROR:
            return {
                ...state,
                started: false,
                error: true,
                errorInfo: payload.error
            };
        case READ_NOTIFICATION_SUCCESS:
            return {
                ...state,
                started: false,
                ended: true,
                result: payload
            };
        default:
            return state;
    }
}

export function unreadNotification(state = defaultState, { type, payload }) {
    switch (type) {
        case UNREAD_NOTIFICATION_START:
            return {
                ...defaultState,
                started: true
            };
        case UNREAD_NOTIFICATION_ERROR:
            return {
                ...state,
                started: false,
                error: true,
                errorInfo: payload.error
            };
        case UNREAD_NOTIFICATION_SUCCESS:
            return {
                ...state,
                started: false,
                ended: true,
                result: payload
            };
        default:
            return state;
    }
}
