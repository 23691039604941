import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import {
    CollapseStyled,
    FeaturesTable,
    FeaturesTableContentRow,
    FeaturesTableContentTd,
    FeaturesTableHeaderRow,
    FeaturesTableTh,
    PanelHeaderDiv,
    PanelStyled
} from "./styled";

export const RolesSection = ({ enhancedRoles, isDrawerOpen }) => {
    const [activeKey, setActiveKey] = useState("");

    useEffect(() => {
        if (isDrawerOpen) {
            setActiveKey("");
        }
    }, [isDrawerOpen]);

    return (
        <CollapseStyled
            activeKey={activeKey}
            data-cy="user-details-drawer"
            onChange={key => {
                setActiveKey(key);
            }}
        >
            {enhancedRoles &&
                Object.keys(enhancedRoles).map(appId => (
                    <PanelStyled
                        data-cy={`user-details-drawer-roles-${appId}`}
                        disabled={enhancedRoles[appId].subService.length === 0}
                        header={
                            <PanelHeaderDiv>
                                {enhancedRoles[appId].description === "ADMIN" ||
                                enhancedRoles[appId].description === "OWNER" ||
                                enhancedRoles[appId].description === "EMPLOYEE" ? (
                                    <FormattedMessage
                                        data-cy={`user-details-drawer-header-${appId}-desc`}
                                        id={`c-registry-user.users-manage-company${enhancedRoles[appId].role}`}
                                    />
                                ) : (
                                    <>
                                        <div data-cy={`user-details-drawer-header-${appId}-desc`}>
                                            {enhancedRoles[appId].description}
                                        </div>
                                        <FormattedMessage
                                            data-cy={`user-details-drawer-roles-${appId}-role`}
                                            id={`c-registry-user.users-manage-${enhancedRoles[appId].role}`}
                                        />
                                    </>
                                )}
                            </PanelHeaderDiv>
                        }
                        key={appId}
                        showArrow={enhancedRoles[appId].subService.length > 0}
                    >
                        <FeaturesTable>
                            <thead>
                                <FeaturesTableHeaderRow>
                                    <FeaturesTableTh>
                                        <FormattedMessage id="general.featurecode-name" />
                                    </FeaturesTableTh>
                                    <FeaturesTableTh>
                                        <FormattedMessage id="general.user-role" />
                                    </FeaturesTableTh>
                                </FeaturesTableHeaderRow>
                            </thead>

                            <tbody>
                                {enhancedRoles[appId].subService.map(feature => (
                                    <FeaturesTableContentRow key={feature.featureCode}>
                                        <FeaturesTableContentTd
                                            data-cy={`user-details-drawer-content-${appId}-${feature.featureCode}-desc`}
                                        >
                                            {feature.description}
                                        </FeaturesTableContentTd>
                                        <FeaturesTableContentTd
                                            data-cy={`user-details-drawer-content-${appId}-${feature.featureCode}-role`}
                                        >
                                            <FormattedMessage id={`c-registry-user.users-manage-${feature.role}`} />
                                        </FeaturesTableContentTd>
                                    </FeaturesTableContentRow>
                                ))}
                            </tbody>
                        </FeaturesTable>
                    </PanelStyled>
                ))}
        </CollapseStyled>
    );
};
