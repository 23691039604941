import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import PortaleChildApp from "../../portale-child-app";
import { DRAG_URL } from "../../../config";

class DragWebapp extends Component {
    static propTypes = {
        auth: PropTypes.object
    };

    render() {
        return <PortaleChildApp url={DRAG_URL} hideElevioAndUservoice={false} />;
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(DragWebapp);
