import { LOGIN_SUCCESS } from "../actions/auth";
import {
    GAINSIGHT_EDIT_OLD_PROVISIONING,
    GAINSIGHT_EDIT_SMART_PROVISIONING,
    GAINSIGHT_ESM_ACTIVATE,
    GAINSIGHT_ESM_CONTACT_US,
    GAINSIGHT_ESM_DISCOVER,
    GAINSIGHT_ESM_OPEN_APP,
    GAINSIGHT_OLD_PROVISIONING,
    GAINSIGHT_PARTECIPATE_TALKS,
    GAINSIGHT_SHOW_PROVISIONING_HOW_TO_VIDEO,
    GAINSIGHT_SMART_PROVISIONING,
    GAINSIGHT_SWITCH_TO_SMART_PROVISIONING
} from "../actions/gainsightEvents";

export const trackEvents = () => next => action => {
    const track = window.aptrinsic
        ? (event, details) => window.aptrinsic("track", `PORTALE:${event}`, details)
        : () => null;

    switch (action.type) {
        case GAINSIGHT_SMART_PROVISIONING:
            track("SMART_PROVISIONING", action.details);
            break;
        case GAINSIGHT_EDIT_SMART_PROVISIONING:
            track("EDIT_SMART_PROVISIONING", action.details);
            break;
        case GAINSIGHT_OLD_PROVISIONING:
            track("OLD_PROVISIONING", action.details);
            break;
        case GAINSIGHT_EDIT_OLD_PROVISIONING:
            track("EDIT_OLD_PROVISIONING", action.details);
            break;
        case GAINSIGHT_SWITCH_TO_SMART_PROVISIONING:
            track("SWITCH_TO_SMART_PROVISIONING");
            break;
        case GAINSIGHT_SHOW_PROVISIONING_HOW_TO_VIDEO:
            track("SHOW_PROVISIONING_HOW_TO_VIDEO");
            break;
        case GAINSIGHT_ESM_CONTACT_US:
            track("ESM_CONTACT_US");
            break;
        case GAINSIGHT_ESM_OPEN_APP:
            track("ESM_OPEN_APP");
            break;
        case GAINSIGHT_ESM_ACTIVATE:
            track("ESM_ACTIVATE");
            break;
        case GAINSIGHT_ESM_DISCOVER:
            track("ESM_DISCOVER");
            break;
        case GAINSIGHT_PARTECIPATE_TALKS:
            track("PARTECIPATE_TALKS", action.details);
            break;
        case LOGIN_SUCCESS:
            track("LOGIN_ON_DIGITAL", { loginType: "tsid" });
            break;
        default: {
            break;
        }
    }

    return next(action);
};
