import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TableStyles = styled.div`
    table {
        border-collapse: collapse;
        width: 100%;
    }

    thead  {
        border-bottom: 1px solid ${props => props.theme.colors.text.quickSilver};
    }

    td,
    th {
        height: 40px;
    }
`;

export const Cell = styled.td`
    color: ${props => props.theme.colors.text.yankeesBlue};
    padding: 0px;

    & > div {
        align-items: center;
        display: flex;
        height: 40px;
        padding: 1px 9px;
        padding-bottom: 0px;
        border-bottom: 1px solid ${props => props.theme.colors.text.quickSilver};
    }
`;

export const HeaderCell = styled.th`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    padding: 0px;
    width: ${props => props.width};
    & > div {
        align-items: center;
        border: 1px solid rgba(0, 0, 0, 0);
        display: flex;
        height: 40px;
        padding: 0px 8px;
    }
`;

export const FilterCell = styled.th`
    background-color: ${props => props.theme.colors.background.whiteSmoke};
    height: 40px;
    padding: 0px;

    & div {
        margin: 0px;
    }

    & input {
        border: 1px rgba(0, 0, 0, 0);
        margin: 0px;
    }
`;

export const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const FooterRow = styled.tfoot`
    background: rgb(222, 240, 247);
    border-radius: 0px;
`;

export const SortIconContainer = styled(FontAwesomeIcon)`
    cursor: pointer;
    margin-left: 5px;
    font-size: 16px;
`;

export const ComboContainer = styled.div`
    display: flex;
`;

export const RolesSelectContainer = styled.div`
    width: 300px;

    margin-left: 20px;
`;

export const ServicesMultiSelectContainer = styled.div`
    width: 250px;
`;

export const LabelContainer = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 8px;
`;

export const CreateButtonContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
