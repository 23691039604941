import React from "react";

import { FormattedMessage } from "react-intl";

import { NEW_COOKIE_POLICY_URL } from "../../config";
import { A, Copyright } from "./styled";

const CopyrightFooter = () => {
    return (
        <Copyright>
            <FormattedMessage
                id="v-pre-login.copyright"
                values={{
                    year: new Date().getFullYear()
                }}
            />
            <span>|</span>
            <span>
                <A
                    href="https://tc.teamsystem.com/InformativaPrivacyTSDigital.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FormattedMessage id="v-pre-login.privacy-policy" />
                </A>{" "}
                ·{" "}
                <A href={NEW_COOKIE_POLICY_URL} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="v-pre-login.cookie" />
                </A>
            </span>
        </Copyright>
    );
};

export default CopyrightFooter;
