import React, { useEffect, useMemo, useReducer, useState, useCallback } from "react";
import PropTypes from "prop-types";

import { Button, Checkbox } from "@ts-digital/vrc";
import { Table } from "../../general/table";
import { CheckRow } from "../../general/table/check-row";

import { translateMessage } from "../../../i18n";

import { Footer, Subtitle, Title } from "../styled";
import { CompanyDescription, Container, TableCell } from "./styled";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import TableFooter from "./table-footer";
import Filters from "./filters";

const SelectCompanies = ({
    comingFromAddRoles,
    goBack,
    initialSelectedItems,
    listItems: { call: loadItemList, status: itemListLoadingStatus, page: itemList, meta: itemListMeta },
    onSubmit,
    userEmail,
    currentCompanyData
}) => {
    const [page, setPage] = useState(0);
    const [selectedItems, setSelectedItems] = useState(initialSelectedItems || []);
    const [filters, updateFilters] = useReducer(
        (currentFilters, update) => {
            const updatedFilters = { ...currentFilters };
            Object.entries(update).forEach(([key, value]) => (updatedFilters[key] = value));
            return updatedFilters;
        },
        { pageSize: 10 }
    );

    useEffect(() => {
        loadItemList(
            filters.fullText,
            filters.classifiers,
            userEmail,
            0,
            10,
            false,
            false,
            false,
            false,
            currentCompanyData.itemUuid
        );
    }, [filters, loadItemList, userEmail, currentCompanyData.itemUuid]);

    const handleFilterSubmit = useCallback(
        (value, columnId) => {
            updateFilters({ ...filters, [columnId]: value, fullText: value });
        },
        [updateFilters, filters]
    );

    useEffect(() => {
        setSelectedItems([
            // Keeping the current studio selected
            { ...currentCompanyData },
            // Selecting the rest of items
            ...initialSelectedItems.filter(
                d =>
                    !d.alreadyAssigned &&
                    d.certified &&
                    !d.hasNoActiveServices &&
                    d.itemId !== currentCompanyData.itemId
            )
        ]);
    }, [currentCompanyData, initialSelectedItems]);

    const data = itemList || [];

    let indexOfStudio = data.findIndex(obj => obj.itemId === currentCompanyData.itemId);

    // Move the object to the first position if found
    if (indexOfStudio !== -1) {
        // Splice the array to move the object to the first position
        data.unshift(data.splice(indexOfStudio, 1)[0]);
    } else {
        // Always keep the current studio on the table
        data.unshift(currentCompanyData);
    }

    const columns = useMemo(
        () => [
            {
                disableFilters: true,
                accessor: "selected",
                Header: props => (
                    <Checkbox
                        name="headerCheckbox"
                        checked={
                            // We do >1 because with the new flow, current studio will be always selected, and we want user
                            // to be able to masive select all other companies without a problem
                            selectedItems.length > 1 ||
                            selectedItems.length ===
                                props.data.filter(d => !d.alreadyAssigned && d.certified && !d.hasNoActiveServices)
                                    .length
                        }
                        disabled={props.data.length === 0}
                        indeterminate={selectedItems.length > 1 && selectedItems.length < props.data.length}
                        onChange={newCheckedValue => {
                            if (newCheckedValue) {
                                setSelectedItems([
                                    // Keeping the current studio selected
                                    { ...currentCompanyData },
                                    // Selecting the rest of items
                                    ...props.data.filter(
                                        d =>
                                            !d.alreadyAssigned &&
                                            d.certified &&
                                            !d.hasNoActiveServices &&
                                            d.itemId !== currentCompanyData.itemId
                                    )
                                ]);
                            } else {
                                // We clear the selected items but we keep the current studio selected
                                setSelectedItems([{ ...currentCompanyData }]);
                            }
                        }}
                    />
                ),
                Cell: props => (
                    <CheckRow
                        checked={selectedItems.findIndex(item => item.itemId === props.row.original.itemId) > -1}
                        disabled={
                            props.row.original.itemId === currentCompanyData.itemId ||
                            props.row.original.alreadyAssigned ||
                            !props.row.original.certified ||
                            props.row.original.hasNoActiveServices
                        }
                        displayPopover="disabled"
                        row={props.row}
                        onChange={checked => {
                            if (props.row.original.itemId === currentCompanyData.itemId) return;
                            const updatedSelection = [...selectedItems];
                            const itemId = props.row.original.itemId;
                            if (checked) {
                                updatedSelection.push(props.row.original);
                            } else {
                                updatedSelection.splice(
                                    updatedSelection.findIndex(item => item.itemId === itemId),
                                    1
                                );
                            }
                            setSelectedItems(updatedSelection);
                        }}
                        popoverContent={
                            <FormattedMessage
                                id={
                                    props.row.original.alreadyAssigned
                                        ? "v-user-creation-flow.steps.select-items.disabledRow"
                                        : !props.row.original.certified
                                        ? "v-user-creation-flow.steps.select-items.disabledRow-notCertified"
                                        : props.row.original.hasNoActiveServices
                                        ? "v-user-creation-flow.steps.select-items.disabledRow-noActiveServices"
                                        : "v-user-creation-flow.steps.select-items.disabled"
                                }
                            />
                        }
                    />
                ),
                width: "30px"
            },
            {
                searchPlaceholder: translateMessage(`v-user-creation-flow.steps.select-items.search-placeholder`),
                minLength: 3,
                accessor: "description",
                Header: <FormattedMessage id="v-user-creation-flow.steps.select-items.table-header.item-description" />,
                Cell: props => (
                    <TableCell>
                        <CompanyDescription>{props.cell.value}</CompanyDescription>
                    </TableCell>
                ),
                width: "300px"
            },
            {
                searchPlaceholder: translateMessage(`v-user-creation-flow.steps.select-items.search-placeholder`),
                minLength: 3,
                accessor: "vatNumber",
                Header: <FormattedMessage id="v-user-creation-flow.steps.select-items.table-header.vat-number" />,
                Cell: props => <TableCell {...props}>{props.row.original.vatNumber || "-"}</TableCell>
            },
            {
                searchPlaceholder: translateMessage(`v-user-creation-flow.steps.select-items.search-placeholder`),
                minLength: 3,
                accessor: "taxId",
                Header: <FormattedMessage id="v-user-creation-flow.steps.select-items.table-header.tax-id" />,
                Cell: props => <TableCell {...props}>{props.cell.value}</TableCell>
            },
            {
                disableFilters: true,
                accessor: "classifier",
                Header: <FormattedMessage id="v-user-creation-flow.steps.select-items.table-header.classifier" />,
                Cell: props => (
                    <TableCell {...props}>
                        <FormattedMessage id={`general.classifiers.${props.row && props.row.original.classifier}`} />
                    </TableCell>
                )
            },
            {
                disableFilters: true,
                accessor: "isWorkspaceConnected",
                Header: <FormattedMessage id="v-user-creation-flow.steps.select-items.table-header.connected" />,
                Cell: props => (
                    <TableCell {...props}>
                        {props.row && props.row.original.classifier === "STUDIO" ? (
                            "-"
                        ) : (
                            <FormattedMessage id={props.cell.value ? "general.yes" : "general.no"} />
                        )}
                    </TableCell>
                )
            }
        ],
        [selectedItems, currentCompanyData]
    );

    return (
        <Container data-cy="select-companies-step">
            <div>
                <Title data-cy="step-title">
                    <FormattedMessage id="v-user-creation-flow.steps.select-items.title" />
                </Title>
                <Subtitle data-cy="step-subtitle">
                    <FormattedHTMLMessage
                        id="v-user-creation-flow.steps.select-items.subtitle"
                        values={{ userEmail }}
                    />
                </Subtitle>
            </div>

            <Filters
                currentFilters={filters}
                onFiltersChange={filterUpdate => {
                    updateFilters({ ...filterUpdate });
                    setPage(0);
                }}
            />

            <Table
                loading={itemListLoadingStatus.started}
                columns={columns}
                data={data}
                useFooter={true}
                page={page}
                footerContent={
                    <TableFooter
                        selectedCount={selectedItems.length}
                        totalItems={itemListMeta && itemListMeta.totalItems}
                    />
                }
                onPageChange={newPage => setPage(newPage)}
                data-cy="companies-table"
                paginationType="AUTO"
                enableFilters={true}
                onFilterSubmit={handleFilterSubmit}
                highlightFirstRow={data.some(it => it.itemId === currentCompanyData.itemId)}
            />

            <Footer>
                <Button kind="secondary" onClick={() => goBack()} style={{ marginRight: 16 }} data-cy="go-back-button">
                    <FormattedMessage id="general.goBack" />
                </Button>
                <Button
                    disabled={selectedItems.length <= 0}
                    onClick={() => onSubmit(selectedItems)}
                    data-cy="next-step-button"
                    data-gainsight-track={
                        selectedItems && selectedItems.length > 1
                            ? "user-creation-flow-progress-step2-to-3-multiple" +
                              (comingFromAddRoles ? "-add-roles" : "")
                            : "user-creation-flow-progress-step2-to-3-single" + (comingFromAddRoles ? "-add-roles" : "")
                    }
                >
                    <FormattedMessage id="v-user-creation-flow.buttons.next-step" />
                </Button>
            </Footer>
        </Container>
    );
};

SelectCompanies.propTypes = {
    goBack: PropTypes.func.isRequired,
    initialSelectedItems: PropTypes.any,
    listItems: PropTypes.shape({
        call: PropTypes.func.isRequired,
        page: PropTypes.arrayOf(
            PropTypes.shape({
                classifier: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                itemId: PropTypes.string.isRequired,
                taxId: PropTypes.string.isRequired,
                vatNumber: PropTypes.string.isRequired
            })
        ).isRequired,
        meta: PropTypes.shape({
            page: PropTypes.number,
            size: PropTypes.number,
            totalItems: PropTypes.number
        }).isRequired,
        status: PropTypes.shape({
            started: PropTypes.bool.isRequired,
            ended: PropTypes.bool.isRequired,
            error: PropTypes.bool.isRequired
        }).isRequired
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    userEmail: PropTypes.string.isRequired
};

export default SelectCompanies;
