import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const UserEmail = styled.div`
    color: #566b76;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;

export const FullName = styled.div`
    color: #004e75;
    font-family: Cairo;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
`;

export const InitialsContainer = styled.div`
    flex-shrink: 0;
`;

export const Status = styled.div`
    overflow: hidden;
    color: #1f292e;
    text-overflow: ellipsis;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const StatusContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const StatusIcon = styled(FontAwesomeIcon)`
    color: ${props =>
        props.active === "true" ? props.theme.colors.status.laSalleGreen : props.theme.colors.status.crayola};
    font-size: 16px;
    text-align: center;
    width: 40px;
    margin-top: ${props => props.margintop};
`;

export const UserDescriptionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    grid-gap: 16px;
    flex-direction: column;
`;

export const UserInfoContainer = styled.div``;

export const UserInfoWrapper = styled.div`
    display: flex;
    gap: 24px;
    width: 100%;
    align-items: center;
    overflow: hidden;
`;
