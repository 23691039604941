import { TS_DIGITAL_IMAGES } from "../../../config";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const defaultVideoConfig = {
    autoPlay: true,
    loop: true,
    muted: true,
    controls: false
};

export const appDetails = {
    adempimenti: {
        name: "TEL",
        img: `${TS_DIGITAL_IMAGES}TEL.png`,
        videoConfig: defaultVideoConfig,
        title: "apps.TEL.title",
        subtitle: "apps.TEL.subtitle",
        boxes: [
            {
                title: "apps.TEL.box1.title",
                desc: "apps.TEL.box1.desc"
            },
            {
                title: "apps.TEL.box2.title",
                desc: "apps.TEL.box2.desc"
            },
            {
                title: "apps.TEL.box3.title",
                desc: "apps.TEL.box3.desc"
            }
        ],
        contactName: "tsdigitalfiscallink"
    },
    fatturazione: {
        name: "EIP",
        video: `${TS_DIGITAL_IMAGES}video/EIP.mp4`,
        videoConfig: defaultVideoConfig,
        buyLink:
            "https://www.teamsystem.com/store/fatturazione-elettronica/ts-digital-invoice/prezzi/?utm_source=Portale-TS-Digital&utm_medium=referral&utm_campaign=software-experience",
        title: "apps.EIP.title",
        subtitle: "apps.EIP.subtitle",
        boxes: [
            {
                title: "apps.EIP.box1.title",
                desc: "apps.EIP.box1.desc"
            },
            {
                title: "apps.EIP.box2.title",
                desc: "apps.EIP.box2.desc"
            },
            {
                title: "apps.EIP.box3.title",
                desc: "apps.EIP.box3.desc"
            },
            {
                title: "apps.EIP.box4.title",
                desc: "apps.EIP.box4.desc"
            }
        ],
        contactName: "tsdigitalinvoice"
    },
    firma: {
        name: "SIG",
        video: `${TS_DIGITAL_IMAGES}video/SIG.mp4`,
        videoConfig: defaultVideoConfig,
        buyLink:
            "https://www.teamsystem.com/store/firma-digitale/ts-digital-sign/Prezzi/?utm_source=Portale-TS-Digital&utm_medium=referral&utm_campaign=software-experience",
        title: "apps.SIG.title",
        subtitle: "apps.SIG.subtitle",
        boxes: [
            {
                title: "apps.SIG.box1.title",
                desc: "apps.SIG.box1.desc"
            },
            {
                title: "apps.SIG.box2.title",
                desc: "apps.SIG.box2.desc"
            },
            {
                title: "apps.SIG.box3.title",
                desc: "apps.SIG.box3.desc"
            },
            {
                title: "apps.SIG.box4.title",
                desc: "apps.SIG.box4.desc"
            }
        ],
        contactName: "tsdigitalsign"
    },
    storage: {
        name: "ARC",
        img: `${TS_DIGITAL_IMAGES}ARC.png`,
        videoConfig: defaultVideoConfig,
        buyLink:
            "https://www.teamsystem.com/store/fatturazione-e-contabilita/ts-digital-bank-link/prezzi/?utm_source=Portale-TS-Digital&utm_medium=referral&utm_campaign=software-experience",
        title: "apps.ARC.title",
        subtitle: "apps.ARC.subtitle",
        boxes: [
            {
                title: "apps.ARC.box1.title",
                desc: "apps.ARC.box1.desc"
            },
            {
                title: "apps.ARC.box2.title",
                desc: "apps.ARC.box2.desc"
            },
            {
                title: "apps.ARC.box3.title",
                desc: "apps.ARC.box3.desc"
            },
            {
                title: "apps.ARC.box4.title",
                desc: "apps.ARC.box4.desc"
            }
        ],
        contactName: "tsdigitalarchive"
    },
    accounting: {
        name: "EBS",
        // img: `${TS_DIGITAL_IMAGES}EBS.svg`,
        videoConfig: defaultVideoConfig,
        title: "apps.EBS.title",
        subtitle: "apps.EBS.subtitle",
        boxes: [
            {
                title: "apps.EBS.box1.title",
                desc: "apps.EBS.box1.desc"
            },
            {
                title: "apps.EBS.box2.title",
                desc: "apps.EBS.box2.desc"
            },
            {
                title: "apps.EBS.box3.title",
                desc: "apps.EBS.box3.desc"
            },
            {
                title: "apps.EBS.box4.title",
                desc: "apps.EBS.box4.desc"
            }
        ],
        contactName: "tsdigitalbanklink"
    },
    "console-digitale": {
        name: "DBX",
        video: `${TS_DIGITAL_IMAGES}video/DBX.mp4`,
        videoConfig: defaultVideoConfig,
        title: "apps.DBX.title",
        subtitle: "apps.DBX.subtitle",
        boxes: [
            {
                title: "apps.DBX.box1.title",
                desc: "apps.DBX.box1.desc"
            },
            {
                title: "apps.DBX.box2.title",
                desc: "apps.DBX.box2.desc"
            },
            {
                title: "apps.DBX.box3.title",
                desc: "apps.DBX.box3.desc"
            },
            {
                title: "apps.DBX.box4.title",
                desc: "apps.DBX.box4.desc"
            }
        ],
        contactName: "tsdigitalbox"
    },
    gdprlog: {
        name: "GDPR-LOG",
        title: "apps.GDPR-LOG.title",
        videoConfig: defaultVideoConfig,
        subtitle: "apps.GDPR-LOG.subtitle",
        boxes: [
            {
                title: "apps.GDPR-LOG.box1.title",
                desc: "apps.GDPR-LOG.box1.desc"
            },
            {
                title: "apps.GDPR-LOG.box2.title",
                desc: "apps.GDPR-LOG.box2.desc"
            },
            {
                title: "apps.GDPR-LOG.box3.title",
                desc: "apps.GDPR-LOG.box3.desc"
            }
        ],
        contactName: "tsdigitalgdprlog"
    },
    invoicetrading: {
        name: "EIT",
        video: `${TS_DIGITAL_IMAGES}video/EIT.mp4`,
        videoConfig: defaultVideoConfig,
        title: "apps.EIT.title",
        subtitle: "apps.EIT.subtitle",
        boxes: [
            {
                title: "apps.EIT.box1.title",
                desc: "apps.EIT.box1.desc"
            },
            {
                title: "apps.EIT.box2.title",
                desc: "apps.EIT.box2.desc"
            },
            {
                title: "apps.EIT.box3.title",
                desc: "apps.EIT.box3.desc"
            }
        ],
        disclaimer: "apps.EIT.disclaimer",
        contactName: "tsdigitalinvoicetrading",
        disclaimerIcon: faInfoCircle,
        disclaimerIconColor: "#005075"
    },
    spendmanagement: {
        name: "ESM",
        video: `${TS_DIGITAL_IMAGES}video/ESM.mp4`,
        videoConfig: defaultVideoConfig,
        title: "apps.ESM.title",
        subtitle: "apps.ESM.subtitle",
        boxes: [
            {
                title: "apps.ESM.box1.title",
                desc: "apps.ESM.box1.desc"
            },
            {
                title: "apps.ESM.box2.title",
                desc: "apps.ESM.box2.desc"
            },
            {
                title: "apps.ESM.box3.title",
                desc: "apps.ESM.box3.desc"
            },
            {
                title: "apps.ESM.box4.title",
                desc: "apps.ESM.box4.desc"
            }
        ],
        disclaimer: "apps.ESM.disclaimer",
        contactName: "enterprisespendmanagement"
    },
    creditrisk: {
        name: "CRAM",
        video: `${TS_DIGITAL_IMAGES}video/CRAM.mp4`,
        videoConfig: defaultVideoConfig,
        buyLink: "https://www.teamsystem.com/store/check-up-impresa/prezzi/",
        title: "apps.CRAM.title",
        subtitle: "apps.CRAM.subtitle",
        boxes: [
            {
                title: "apps.CRAM.box1.title",
                desc: "apps.CRAM.box1.desc"
            },
            {
                title: "apps.CRAM.box2.title",
                desc: "apps.CRAM.box2.desc"
            },
            {
                title: "apps.CRAM.box3.title",
                desc: "apps.CRAM.box3.desc"
            },
            {
                title: "apps.CRAM.box4.title",
                desc: "apps.CRAM.box4.desc"
            },
            {
                title: "apps.CRAM.box5.title",
                desc: "apps.CRAM.box5.desc"
            },
            {
                title: "apps.CRAM.box6.title",
                desc: "apps.CRAM.box6.desc"
            }
        ],
        contactName: "creditriskassestmentandmanagement"
    },
    checkuppro: {
        name: "CRAM",
        video: `${TS_DIGITAL_IMAGES}video/CRAM_PRO.mp4`,
        videoConfig: {
            autoPlay: true,
            controls: true,
            loop: true,
            muted: false
        },
        title: "apps.CRAM_PRO.title",
        subtitle: "apps.CRAM_PRO.subtitle",
        boxes: [
            {
                title: "apps.CRAM_PRO.box1.title",
                desc: "apps.CRAM_PRO.box1.desc"
            },
            {
                title: "apps.CRAM_PRO.box2.title",
                desc: "apps.CRAM_PRO.box2.desc"
            },
            {
                title: "apps.CRAM_PRO.box3.title",
                desc: "apps.CRAM_PRO.box3.desc"
            },
            {
                title: "apps.CRAM_PRO.box4.title",
                desc: "apps.CRAM_PRO.box4.desc"
            },
            {
                title: "apps.CRAM_PRO.box5.title",
                desc: "apps.CRAM_PRO.box5.desc"
            },
            {
                title: "apps.CRAM_PRO.box6.title",
                desc: "apps.CRAM_PRO.box6.desc"
            }
        ],
        contactName: "checkuppro"
    },
    agro: {
        name: "AGRO",
        video: `${TS_DIGITAL_IMAGES}video/AGRO.mp4`,
        videoConfig: defaultVideoConfig,
        title: "apps.AGRO.title",
        subtitle: "apps.AGRO.subtitle",
        boxes: [
            {
                title: "apps.AGRO.box1.title",
                desc: "apps.AGRO.box1.desc"
            },
            {
                title: "apps.AGRO.box2.title",
                desc: "apps.AGRO.box2.desc"
            },
            {
                title: "apps.AGRO.box3.title",
                desc: "apps.AGRO.box3.desc"
            },
            {
                title: "apps.AGRO.box4.title",
                desc: "apps.AGRO.box4.desc"
            },
            {
                title: "apps.AGRO.box5.title",
                desc: "apps.AGRO.box5.desc"
            }
        ],
        contactName: "adempimentiagroalimentare"
    },
    niumabonus: {
        name: "NIUMABONUS",
        video: `${TS_DIGITAL_IMAGES}video/NIUMABONUS.mp4`,
        videoConfig: defaultVideoConfig,
        title: "apps.NIUMABONUS.title",
        subtitle: "apps.NIUMABONUS.subtitle",
        boxes: [
            {
                title: "apps.NIUMABONUS.box1.title",
                desc: "apps.NIUMABONUS.box1.desc"
            },
            {
                title: "apps.NIUMABONUS.box2.title",
                desc: "apps.NIUMABONUS.box2.desc"
            },
            {
                title: "apps.NIUMABONUS.box3.title",
                desc: "apps.NIUMABONUS.box3.desc"
            },
            {
                title: "apps.NIUMABONUS.box4.title",
                desc: "apps.NIUMABONUS.box4.desc"
            }
        ],
        contactName: "ts-ecobonus"
    },
    spid: {
        name: "SPID",
        video: `${TS_DIGITAL_IMAGES}video/SPID.mp4`,
        videoConfig: defaultVideoConfig,
        title: "apps.SPID.title",
        subtitle: "apps.SPID.subtitle",
        boxes: [
            {
                title: "apps.SPID.box1.title",
                desc: "apps.SPID.box1.desc"
            },
            {
                title: "apps.SPID.box2.title",
                desc: "apps.SPID.box2.desc"
            },
            {
                title: "apps.SPID.box3.title",
                desc: "apps.SPID.box3.desc"
            }
        ],
        contactName: "spid"
    },
    tspay: {
        name: "TSPAY",
        video: `${TS_DIGITAL_IMAGES}video/TSPAY.mp4`,
        videoConfig: defaultVideoConfig,
        title: "apps.TSPAY.title",
        subtitle: "apps.TSPAY.subtitle",
        boxes: [
            {
                title: "apps.TSPAY.box1.title",
                desc: "apps.TSPAY.box1.desc"
            },
            {
                title: "apps.TSPAY.box2.title",
                desc: "apps.TSPAY.box2.desc"
            },
            {
                title: "apps.TSPAY.box3.title",
                desc: "apps.TSPAY.box3.desc"
            }
        ],
        contactName: "ts-pay"
    },
    businessinformation: {
        name: "BIS",
        video: `${TS_DIGITAL_IMAGES}video/BIS.mp4`,
        videoConfig: defaultVideoConfig,
        buyLink: "https://www.teamsystem.com/store/business-information/prezzi/",
        title: "apps.BIS.title",
        subtitle: "apps.BIS.subtitle",
        boxes: [
            {
                title: "apps.BIS.box1.title",
                desc: "apps.BIS.box1.desc"
            },
            {
                title: "apps.BIS.box2.title",
                desc: "apps.BIS.box2.desc"
            },
            {
                title: "apps.BIS.box3.title",
                desc: "apps.BIS.box3.desc"
            }
        ],
        contactName: "business-information"
    },
    dragandtag: {
        name: "DRAG",
        video: `${TS_DIGITAL_IMAGES}video/DRAG.mp4`,
        videoConfig: defaultVideoConfig,
        title: "apps.DRAG.title",
        subtitle: "apps.DRAG.subtitle",
        boxes: [
            {
                title: "apps.DRAG.box1.title",
                desc: "apps.DRAG.box1.desc"
            },
            {
                title: "apps.DRAG.box2.title",
                desc: "apps.DRAG.box2.desc"
            },
            {
                title: "apps.DRAG.box3.title",
                desc: "apps.DRAG.box3.desc"
            },
            {
                title: "apps.DRAG.box4.title",
                desc: "apps.DRAG.box4.desc"
            }
        ],
        contactName: "drag-and-tag"
    },
    deeptier: {
        name: "SCF",
        video: `${TS_DIGITAL_IMAGES}video/SCF.mp4`,
        videoConfig: defaultVideoConfig,
        title: "apps.SCF.title",
        subtitle: "apps.SCF.subtitle",
        boxes: [
            {
                title: "apps.SCF.box1.title",
                desc: "apps.SCF.box1.desc"
            },
            {
                title: "apps.SCF.box2.title",
                desc: "apps.SCF.box2.desc"
            },
            {
                title: "apps.SCF.box3.title",
                desc: "apps.SCF.box3.desc"
            },
            {
                title: "apps.SCF.box4.title",
                desc: "apps.SCF.box4.desc"
            }
        ],
        contactName: "scf-core",
        disableContactUs: true
    },
    cyber: {
        name: "CYBER",
        video: `${TS_DIGITAL_IMAGES}video/CYBER.mp4`,
        title: "apps.CYBER.title",
        videoConfig: defaultVideoConfig,
        subtitle: "apps.CYBER.subtitle",
        boxes: [
            {
                title: "apps.CYBER.box1.title",
                desc: "apps.CYBER.box1.desc"
            },
            {
                title: "apps.CYBER.box2.title",
                desc: "apps.CYBER.box2.desc"
            },
            {
                title: "apps.CYBER.box3.title",
                desc: "apps.CYBER.box3.desc"
            }
        ],
        contactName: "cyber",
        buyLink: "https://www.teamsystem.com/store/ts-cybersecurity/",
        disableContactUs: true
    }
};
