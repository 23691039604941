import React, { Component } from "react";
import PropTypes from "prop-types";

import { Spin } from "antd";
import axios from "axios";
import get from "lodash.get";
import Postmate from "postmate";
import { connect } from "react-redux";
import { compose } from "redux";

import { errorBoundary } from "../../../../components/general/error-boundary";
import {
    API_URL,
    CONSOLE_FATTURAZIONE_20_URL,
    CONSOLE_FATTURAZIONE_URL,
    DISABLE_ELEVIO_FOR_FATTURAZIONE
} from "../../../../config";
import { getCurrentCookieConsents } from "../../../../utils/cookie-consent-utils";
import "./style.css";

export const isNewConsoleEnabled = (companyId, companiesList) =>
    companiesList.includes(companyId) || companiesList.includes("ENABLE_FATTURAZIONE_20_FOR_ALL_COMPANIES");

class ConsoleFatturazionePostmate extends Component {
    static propTypes = {
        auth: PropTypes.object,
        company: PropTypes.object,
        services: PropTypes.object
    };

    state = { isAppLoaded: false };

    hideElevioLauncher() {
        if (window._elev) {
            window._elev.setSettings({ hideLauncher: true });
        }
    }

    showElevioLauncher() {
        if (window._elev) {
            window._elev.setSettings({ hideLauncher: false });
        }
    }

    hideUservoiceLauncher() {
        const uservoiceLauncher = document.querySelector(".uv-icon.uv-bottom-right");
        if (uservoiceLauncher) {
            uservoiceLauncher.style.visibility = "hidden";
        }
    }

    showUservoiceLauncher() {
        const uservoiceLauncher = document.querySelector(".uv-icon.uv-bottom-right");
        if (uservoiceLauncher) {
            uservoiceLauncher.style.visibility = "visible";
        }
    }

    showElevioWidget() {
        this.showElevioLauncher();
        this.showUservoiceLauncher();
    }

    hideElevioWidget() {
        this.hideElevioLauncher();
        this.hideUservoiceLauncher();
    }

    // Unmounting the new Fatturazione the ts-portale should show its Elevio
    // launcer and its UserVoice icon again
    componentWillUnmount() {
        this.showElevioWidget();
    }

    async componentDidMount() {
        const { auth, company, services } = this.props;

        let configs = null;
        try {
            configs = await axios.get(`${API_URL}/fatturazione/configs`);
        } catch (err) {
            console.error("Cannot fetch fatturazione configs");
        }
        const companyId = get(company, "base.id");
        // Authenticate child
        if (configs && isNewConsoleEnabled(companyId, configs.data)) {
            // New console is enabled

            // Fatturazione has its own Elevio launcher and its own UserVoice
            // icon, so it's necessary to hide the ts-portale ones
            if (DISABLE_ELEVIO_FOR_FATTURAZIONE === "true") {
                this.hideElevioWidget();
            }

            // Initialize child
            const child = await new Postmate({
                container: this.appContainer,
                url: CONSOLE_FATTURAZIONE_20_URL
            });

            child.call("loginWithAccessToken", {
                cookieConsents: getCurrentCookieConsents(),
                userId: get(auth, "loginAuth.id"),
                accessToken: get(auth, "loginAuth.securityToken"),
                refreshToken: get(auth, "loginAuth.refreshToken"),
                selectedCompanyId: companyId,
                selectedCompanyUuid: get(company, "base.uuid"),
                selectedCompanyDescription: get(company, "base.details.description"),
                selectedCompanyClassifier: get(company, "base.details.classifier"),
                selectedCompanyCanUseSmartInvoice: get(services, "invoicing.getInvoicingConfig.hasFatturaSmartEnabled"),
                taxId: get(company, "base.identifier.taxId"),
                vatNumber: get(company, "base.identifier.vatNumber")
            });
        } else {
            // New console is not enabled
            // Initialize child
            const child = await new Postmate({
                container: this.appContainer,
                url: CONSOLE_FATTURAZIONE_URL
            });

            child.call("loginWithAuth", {
                auth: auth.loginAuth,
                companyId: companyId,
                isNewConsoleEnabled: false
            });
        }

        // Set app as loaded (which stops rendering the spinner)
        this.setState({ isAppLoaded: true });
    }

    render() {
        return (
            <div className="c-ConsoleFatturazione">
                {/* Render spinner if app is not loaded */}
                {!this.state.isAppLoaded ? (
                    <div className="c-ConsoleFatturazione-spinner">
                        <Spin size="large" />
                    </div>
                ) : null}

                {/* Render embedded app container */}
                <div
                    ref={el => (this.appContainer = el)}
                    style={{
                        visibility: this.state.isAppLoaded ? "initial" : "hidden"
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    auth: state.auth,
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    services: state.services
});

const composedHoc = compose(connect(mapStateToProps), errorBoundary);

export default composedHoc(ConsoleFatturazionePostmate);
