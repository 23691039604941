import React from "react";

import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { Layout } from "antd";
import { useHistory } from "react-router";

import "./style.css";
import PageTitle from "../general/page-title";

const CookiePolicy = () => {
    const history = useHistory();

    return (
        <Layout>
            <Layout.Content className="Root-content">
                <div className="cookie-policy-container">
                    <PageTitle
                        title={<FormattedMessage id="c-cookie-policy.title1" />}
                        onBack={() => history.goBack()}
                    />
                    <br />
                    <h2 className="subtitle">
                        <FormattedHTMLMessage id="c-cookie-policy.subtitle1" />
                    </h2>
                    <div>
                        <i className="fas fa-cookie-bite cookie-icon" />
                        <span className="content cookie-content">
                            <FormattedHTMLMessage id="c-cookie-policy.text1" />
                        </span>
                    </div>
                    <br />
                    <span className="content">
                        <FormattedHTMLMessage id="c-cookie-policy.text2" />
                    </span>
                    <br />
                    <h2 className="subtitle">
                        <FormattedHTMLMessage id="c-cookie-policy.subtitle2" />
                    </h2>
                    <br />
                    <span className="content">
                        <FormattedHTMLMessage id="c-cookie-policy.text3" />
                    </span>
                    <br />
                    <table>
                        <tbody>
                            <tr>
                                <td className="blue-cell" />
                                <td className="blue-cell">
                                    <span>
                                        <FormattedHTMLMessage id="c-cookie-policy.table1" />
                                    </span>
                                </td>
                                <td className="blue-cell">
                                    <span>
                                        <FormattedHTMLMessage id="c-cookie-policy.table2" />
                                    </span>
                                </td>
                                <td className="blue-cell">
                                    <span>
                                        <FormattedHTMLMessage id="c-cookie-policy.table3" />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="blue-cell headercol">
                                    <span>
                                        <FormattedHTMLMessage id="c-cookie-policy.table4" />
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <FormattedHTMLMessage id="c-cookie-policy.table5" />
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <FormattedHTMLMessage id="c-cookie-policy.table6" />
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <FormattedHTMLMessage id="c-cookie-policy.table7" />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="blue-cell">
                                    <span>
                                        <FormattedHTMLMessage id="c-cookie-policy.table8" />
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <FormattedHTMLMessage id="c-cookie-policy.table9" />
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <FormattedHTMLMessage id="c-cookie-policy.table10" />
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <FormattedHTMLMessage id="c-cookie-policy.table11" />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="blue-cell">
                                    <span>
                                        <FormattedHTMLMessage id="c-cookie-policy.table12" />
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <FormattedHTMLMessage id="c-cookie-policy.table13" />
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <FormattedHTMLMessage id="c-cookie-policy.table14" />
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <FormattedHTMLMessage id="c-cookie-policy.table15" />
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <h2 className="subtitle">
                        <FormattedHTMLMessage id="c-cookie-policy.subtitle3" />
                    </h2>
                    <br />
                    <ul>
                        <li>
                            <b>
                                <FormattedHTMLMessage id="c-cookie-policy.cookietext1" />
                            </b>
                        </li>
                    </ul>
                    <br />
                    <div className="content">
                        <span>
                            <FormattedHTMLMessage id="c-cookie-policy.cookietext2" />
                        </span>
                        <br />
                        <br />
                        <a
                            href="https://support.google.com/analytics/answer/181881?hl=it"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            https://support.google.com/analytics/answer/181881?hl=it
                        </a>
                        <br />
                        <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">
                            https://tools.google.com/dlpage/gaoptout
                        </a>
                        <br />
                        <br />
                        <span>
                            <FormattedHTMLMessage id="c-cookie-policy.cookietext3" />
                        </span>
                    </div>
                </div>
            </Layout.Content>
        </Layout>
    );
};

export default CookiePolicy;
