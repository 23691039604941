import { AGYO_CONTRACT_API_URL } from "../config";
import { getAuthWithAppName, callWithRetry } from "./auth.js";
import AgyoContractApi from "../assets/thrift/contract/AgyoContractApi";
import { Company, VerifyStatusRequest, CompanyPECRequest } from "../assets/thrift/contract/agyo-contract-api_types";

import thrift from "browser-thrift";

const agyoContractApiUrl = new URL(AGYO_CONTRACT_API_URL);
const agyoContractApiClient = thrift.createXHRClient(
    AgyoContractApi,
    thrift.createXHRConnection(agyoContractApiUrl.hostname, 443, {
        useCors: true,
        path: agyoContractApiUrl.pathname,
        https: agyoContractApiUrl.protocol === "https:"
    })
);

export const VERIFY_STATUS_START = "VERIFY_STATUS_START";
export const VERIFY_STATUS_SUCCESS = "VERIFY_STATUS_SUCCESS";
export const VERIFY_STATUS_ERROR = "VERIFY_STATUS_ERROR";

export function verifyStatus(companyFiscalCode, companyVatNumber) {
    return async (dispatch, getState) => {
        dispatch({
            type: VERIFY_STATUS_START
        });

        const companyData = new Company({
            vatNumber: companyVatNumber,
            taxId: companyFiscalCode
            //,resellerVatCode: "TeamSystem"
        });
        const requestData = new VerifyStatusRequest({
            company: companyData
        });

        try {
            const verifyStatusResponse = await agyoContractApiClient.verifyStatus(
                getAuthWithAppName(getState().auth),
                requestData
            );
            return new Promise((resolve, reject) => {
                dispatch({
                    type: VERIFY_STATUS_SUCCESS
                });

                //status code = 1, ho l'uri da aprire, torno per aprire la popup per il censimento
                //status code = 0, non devo chiamare myprivacy; tutto censito
                //status code = -1, errore per qualche motivo; probabilmente mancano dati di input
                let response = {
                    code: verifyStatusResponse.status.code,
                    uri: null
                };
                if (verifyStatusResponse.status.code === "1") {
                    response.uri = verifyStatusResponse.registryStatus.uri;
                }

                resolve(response);
            });
        } catch (err) {
            dispatch({
                type: VERIFY_STATUS_ERROR,
                error: "Error during verifyStatus call"
            });
        }
    };
}

export const COMPANY_PEC_START = "COMPANY_PEC_START";
export const COMPANY_PEC_SUCCESS = "COMPANY_PEC_SUCCESS";
export const COMPANY_PEC_ERROR = "COMPANY_PEC_ERROR";

export function companyPec(company) {
    return (dispatch, getState) => {
        dispatch({
            type: COMPANY_PEC_START
        });
        const requestData = new CompanyPECRequest({
            vatNumber: company.base.identifier.vatNumber,
            taxId: company.base.identifier.taxId
        });

        const auth = getAuthWithAppName(getState().auth);
        callWithRetry(
            agyoContractApiClient,
            agyoContractApiClient.getCompanyPEC,
            auth,
            requestData,
            auth.refreshToken,
            dispatch,
            (error, result) => {
                if (error !== null) {
                    dispatch({
                        type: COMPANY_PEC_ERROR,
                        error: error
                    });
                } else {
                    if (result.status.code === "0") {
                        dispatch({
                            type: COMPANY_PEC_SUCCESS,
                            payload: result.pec
                        });
                    } else {
                        dispatch({
                            type: COMPANY_PEC_ERROR,
                            error: result.status.code + " - " + result.status.description
                        });
                    }
                }
            },
            ["401"]
        );
    };
}
