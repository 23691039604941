import React from "react";
import PropTypes from "prop-types";

import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router";

import CompaniesSelect from "../companies-select";

import { AsideImage } from "./styled";

import { getNcsBasePath } from "../../../utils/route";

import { TS_DIGITAL_IMAGES } from "../../../config";

export const Sidebar = () => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const { item, selectedItemId } = useParams();

    if (selectedItemId) {
        return (
            <CompaniesSelect
                defaultValue={selectedItemId}
                handleChange={value => {
                    history.push(`${getNcsBasePath(value, item)}/preferences`);
                }}
            />
        );
    }

    return (
        <AsideImage
            src={`${TS_DIGITAL_IMAGES}notifications-center/notifications-center-aside.png`}
            alt={formatMessage({
                id: "c-notifications-center.title1"
            })}
        />
    );
};

Sidebar.propTypes = {
    itemId: PropTypes.string
};

export default Sidebar;
