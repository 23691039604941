import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "antd";

export const UpdateSearchContainer = styled.div`
    display: flex;
`;

export const UpdateContainer = styled.div`
    margin-right: 8px;
    padding-bottom: 10px;
    padding-top: 18px;
`;

export const SearchTitleContainer = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 4px;
`;

export const MarginIcon = styled(FontAwesomeIcon)`
    margin-right: 4px;
`;

export const LoadingIcon = styled(Icon)`
    margin-right: 8px;
`;

export const SearchIconContainer = styled.div`
    margin-top: 20px;
    background-color: ${props => props.theme.colors.primary.richElectricBlue};
    min-width: 40px;
    text-align: center;
    height: 40px;
    padding-top: 8px;

    background: linear-gradient(309deg, var(--brand-primary-shade-medium), var(--brand-primary));
    background-color: var(--brand-primary);
    color: var(--white);
`;

export const SearchIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
    font-size: 16px;
    height: 16px;
    width: 16px;

    transition: 0.1s color ease;

    &:hover {
        color: ${props => props.theme.colors.primary.cyanProgress};
    }
`;
