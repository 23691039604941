import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "./style.css";
import { FormattedMessage, injectIntl } from "react-intl";
import FontAwesome from "react-fontawesome";
import { Button, Modal, Upload, Icon, Spin } from "antd";
import { countryValueWithCodice } from "@ts-digital/agyo-validations/lib/common/constants";

import { signerStatus } from "../../../../utils/decode-message";
import { signatureStatus } from "../../../../utils/constant";

import EditSigner from "../edit";
import ResetPin from "../reset-pin";

const Dragger = Upload.Dragger;

class SignatureInfo extends Component {
    constructor() {
        super();
        this.state = {
            mEditSigner: false,
            mResetPin: false,
            mUploadPdf: false,
            mDeleteSigner: false,
            fileList: [],
            uploading: false,
            uploadFileContent: null
        };
    }

    static propTypes = {
        cancel: PropTypes.func.isRequired,
        cancelSigner: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
        documentFilled: PropTypes.object.isRequired,
        editAction: PropTypes.func.isRequired,
        handleCloseEdit: PropTypes.func.isRequired,
        handleCloseInfo: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func,
        listSigners: PropTypes.func.isRequired,
        mEditSigner: PropTypes.bool.isRequired,
        mShowInfo: PropTypes.bool.isRequired,
        resetPin: PropTypes.func.isRequired,
        resetStateResetPin: PropTypes.func.isRequired,
        servicesType: PropTypes.string.isRequired,
        signApiWrite: PropTypes.object,
        signatureType: PropTypes.string.isRequired,
        signer: PropTypes.object,
        signerData: PropTypes.object,
        uploadSignedPdf: PropTypes.func.isRequired
    };

    handleClose = value => {
        this.setState({ [value]: false });
    };

    handleDownloadFilledPdf() {
        const { documentFilled } = this.props;
        let element = document.createElement("a");
        element.setAttribute("href", `data:application/pdf;base64,${documentFilled.document.content}`);
        element.setAttribute("target", "_blank");
        element.setAttribute("download", "Pdf_compilato.pdf");
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        return null;
    }

    handleResetPin(dataReset) {
        const { resetPin, signer } = this.props;
        resetPin(signer.id.toString(), dataReset.pin, dataReset.puk, dataReset.savePin);
    }

    resetStateResetPin(dataReset) {
        const { resetStateResetPin } = this.props;
        resetStateResetPin();
        this.handleClose("mResetPin");
    }

    handleUpload() {
        const { company, handleCloseInfo, listSigners, uploadSignedPdf, servicesType, signer } = this.props;
        const { uploadFileContent } = this.state;

        uploadSignedPdf(signer.id.toString(), uploadFileContent).then(() => {
            handleCloseInfo();
            this.setState({ mUploadPdf: false });
            listSigners(company.base.id, servicesType);
        });
    }

    handleCancel() {
        const { cancel, company, listSigners, handleCloseInfo, signer, servicesType } = this.props;
        cancel(signer.id.toString()).then(() => {
            handleCloseInfo();
            this.setState({ mDeleteSigner: false });
            listSigners(company.base.id, servicesType);
        });
    }

    renderModalResetPin() {
        const { mResetPin } = this.state;
        const { signApiWrite } = this.props;
        return (
            <div>
                <Modal
                    footer={null}
                    onCancel={() => this.handleClose("mResetPin")}
                    visible={mResetPin}
                    style={{ top: "20%" }}
                    width="600px"
                    title={<FormattedMessage id="c-signature.pin-reset" />}
                >
                    {!signApiWrite.resetPin.status.ended && !signApiWrite.resetPin.status.error ? (
                        <div>
                            <p style={{ fontSize: "14px" }}>
                                {<FormattedMessage id="c-signature.pin-reset-description" />}
                            </p>
                            <br />
                            <ResetPin onSubmit={e => this.handleResetPin(e)} />
                        </div>
                    ) : signApiWrite.resetPin.status.error ? (
                        <div style={{ textAlign: "center" }}>
                            <Icon type="check-circle" style={{ fontSize: "30px", color: "#f44336" }} />
                            <br />
                            <p style={{ fontSize: "16px" }}>
                                <FormattedMessage id="c-signature.pin-reset-error" />
                            </p>
                            <br />
                            <Button onClick={() => this.resetStateResetPin()}>
                                <FormattedMessage id="general.retry" />
                            </Button>
                        </div>
                    ) : (
                        <div style={{ textAlign: "center" }}>
                            <Icon type="check-circle" style={{ fontSize: "30px", color: "#00a854" }} />
                            <br />
                            <p style={{ fontSize: "16px" }}>
                                <FormattedMessage id="c-signature.pin-edit-ok" />
                            </p>
                            <br />
                            <Button onClick={() => this.resetStateResetPin()}>
                                <FormattedMessage id="general.close" />
                            </Button>
                        </div>
                    )}
                </Modal>
            </div>
        );
    }

    renderModalDeleteSigner() {
        const { mDeleteSigner } = this.state;
        const { signer, cancelSigner } = this.props;
        let id = signer !== null ? signer.cfUtente : "";
        return (
            <div>
                <Modal
                    cancelText={<FormattedMessage id="general.annulla" />}
                    okText={<FormattedMessage id="general.delete" />}
                    onCancel={() => this.handleClose("mDeleteSigner")}
                    visible={mDeleteSigner}
                    onOk={() => this.handleCancel()}
                    style={{ top: "20%" }}
                    width="600px"
                    title={<FormattedMessage id="general.certificate-delete" />}
                >
                    {!cancelSigner.status.started ? (
                        <p>
                            <FormattedMessage id="c-signature.delete-certificate-signer" />
                            {id}
                        </p>
                    ) : (
                        <div
                            style={{
                                height: "100px",
                                paddingTop: "35px",
                                textAlign: "center"
                            }}
                        >
                            <Spin size="large" />
                        </div>
                    )}
                </Modal>
            </div>
        );
    }

    renderModalEditSigner() {
        const { signerData, mEditSigner, handleCloseEdit, handleSubmit, signatureType, signer } = this.props;
        let birthDate =
            signerData.formData !== undefined ? new Date(Number(signerData.formData.signerBirthDate)) : null;
        let documentEndDate =
            signerData.formData !== undefined ? new Date(Number(signerData.formData.signerDocumentEndDate)) : null;

        let cfPaese = signerData.formData !== undefined ? signerData.formData.signerFiscalCode.substr(11, 4) : "";
        let index = signerData.formData !== undefined ? countryValueWithCodice.findIndex(x => x.id === cfPaese) : -1;
        let signerBirthCountryCode =
            index === -1
                ? signerData.formData !== undefined
                    ? signerData.formData.signerBirthCountry === "IT"
                        ? "ZXXX"
                        : ""
                    : ""
                : countryValueWithCodice[index].id;
        let initialValues = {
            ...signerData.formData,
            signerBirthCountry: signerBirthCountryCode,
            signerType: signer !== null ? signer.certType : "FRM",
            signerBirthDate: moment(birthDate),
            signerDocumentEndDate: moment(documentEndDate),
            pin: "",
            confermaPin: ""
        };

        return mEditSigner ? (
            <div>
                <div className="SignatureInfo-edit-signer">
                    <EditSigner
                        initialValues={initialValues}
                        handleClose={handleCloseEdit}
                        onSubmit={handleSubmit}
                        signatureType={signatureType}
                    />
                </div>
            </div>
        ) : null;
    }

    renderModalUploadPdf() {
        const { mUploadPdf, uploading } = this.state;
        const { signApiWrite } = this.props;
        const props = {
            beforeUpload: file => {
                var fileContent = "";
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = evt => {
                    fileContent = evt.target.result;
                    fileContent = fileContent.substring(fileContent.indexOf("base64,") + 7, fileContent.length);
                    this.setState({ uploadFileContent: fileContent });
                };
                this.setState(({ fileList }) => ({
                    fileList: [...fileList, file]
                }));
                return false;
            },
            multiple: false,
            fileList: []
        };
        return (
            <div>
                <Modal
                    footer={null}
                    onCancel={() => this.handleClose("mUploadPdf")}
                    visible={mUploadPdf}
                    style={{ top: "20%" }}
                    width="600px"
                    title={<FormattedMessage id="c-signature.send-pdf" />}
                >
                    {!signApiWrite.uploadSignedPdf.status.started ? (
                        <div>
                            <div style={{ marginTop: 16, height: 180 }}>
                                <Dragger {...props}>
                                    <p className="ant-upload-drag-icon">
                                        {this.state.fileList.length === 0 ? (
                                            <Icon type="inbox" />
                                        ) : (
                                            <Icon type="file-text" />
                                        )}
                                    </p>
                                    {this.state.fileList.length === 0 ? null : (
                                        <Icon
                                            className="SignatureInfo-upload-close"
                                            onClick={() => this.setState({ fileList: [] })}
                                            type="close"
                                        />
                                    )}
                                    {this.state.fileList.length === 0 ? (
                                        <p className="ant-upload-text">
                                            <FormattedMessage id="c-signature.instruction" />
                                        </p>
                                    ) : (
                                        <p className="ant-upload-text">{this.state.fileList[0].name}</p>
                                    )}
                                    {this.state.fileList.length === 0 ? (
                                        <p className="ant-upload-hint">
                                            <FormattedMessage id="c-signature.instruction2" />
                                        </p>
                                    ) : null}
                                </Dragger>
                            </div>
                            <Button
                                className="upload-demo-start"
                                type="primary"
                                onClick={() => this.handleUpload()}
                                disabled={this.state.fileList.length === 0 || this.state.uploadFileContent === null}
                                loading={uploading}
                                style={{ marginLeft: "83%", marginTop: "35px" }}
                            >
                                {uploading ? (
                                    <FormattedMessage id="general.send-in-progress" />
                                ) : (
                                    <FormattedMessage id="general.send-pdf" />
                                )}
                            </Button>
                        </div>
                    ) : (
                        <div
                            style={{
                                height: "150px",
                                paddingTop: "65px",
                                textAlign: "center"
                            }}
                        >
                            <Spin size="large" />
                        </div>
                    )}
                </Modal>
            </div>
        );
    }

    renderInfo() {
        const { signer, editAction, intl } = this.props;
        let infoStateSigner = signerStatus(signer.extradata.status);
        let certType =
            signer.certType === "FRM"
                ? intl.formatMessage({
                      id: "c-signature.remote-mass-sign"
                  })
                : intl.formatMessage({
                      id: "c-signature.remote-secure-sign"
                  });
        let signerNameSurname = signer.extradata.nome + " " + signer.extradata.cognome;
        let stateColor;
        if (infoStateSigner !== null) {
            stateColor = infoStateSigner.color;
        }
        let editSignature =
            signer.extradata.status === signatureStatus.PDF_GENERATED ||
            signer.extradata.status === signatureStatus.SUBMISSION_ERROR ||
            signer.extradata.status === signatureStatus.FINAL_ERROR ||
            signer.extradata.status === signatureStatus.WITHDRAW_SUCCESS ||
            signer.extradata.status === signatureStatus.ASSIGNED_WITHDRAW_SUCCESS ||
            signer.extradata.status.indexOf(signatureStatus.SUBMISSION_ERROR) !== -1;
        let downloadPDF =
            signer.extradata.status === signatureStatus.PDF_GENERATED ||
            signer.extradata.status === signatureStatus.SUBMISSION_ERROR ||
            signer.extradata.status === signatureStatus.FINAL_ERROR ||
            signer.extradata.status === signatureStatus.WITHDRAW_SUCCESS ||
            signer.extradata.status === signatureStatus.ASSIGNED_WITHDRAW_SUCCESS ||
            signer.extradata.status.indexOf(signatureStatus.SUBMISSION_ERROR) !== -1;
        let loadPDF =
            signer.extradata.status === signatureStatus.PDF_GENERATED ||
            signer.extradata.status === signatureStatus.SUBMISSION_ERROR ||
            signer.extradata.status === signatureStatus.FINAL_ERROR ||
            signer.extradata.status === signatureStatus.WITHDRAW_SUCCESS ||
            signer.extradata.status === signatureStatus.ASSIGNED_WITHDRAW_SUCCESS ||
            signer.extradata.status.indexOf(signatureStatus.SUBMISSION_ERROR) !== -1;
        let restPIN =
            signer.extradata.status === signatureStatus.ASSIGNED_SUCCESS ||
            signer.extradata.status === signatureStatus.FINAL_SUCCESS;
        let deleteSignature =
            signer.extradata.status === signatureStatus.PDF_GENERATED ||
            signer.extradata.status === signatureStatus.SUBMISSION_ERROR ||
            signer.extradata.status === signatureStatus.FINAL_ERROR ||
            signer.extradata.status === signatureStatus.WITHDRAW_SUCCESS ||
            signer.extradata.status === signatureStatus.ASSIGNED_WITHDRAW_SUCCESS ||
            signer.extradata.status.indexOf(signatureStatus.SUBMISSION_ERROR) !== -1;
        if (signer.extradata.status === signatureStatus.WITHDRAW_SUCCESS) {
            downloadPDF = false;
            loadPDF = false;
            restPIN = false;
            deleteSignature = false;
        }
        if (
            signer.extradata.status === signatureStatus.WITHDRAW_SUCCESS &&
            signer.serviceName === "A-FISCALI-IVA-FLOW"
        ) {
            downloadPDF = false;
            loadPDF = false;
            restPIN = false;
            deleteSignature = true;
        }
        if (signer.extradata.status === signatureStatus.ASSIGNED_WITHDRAW_SUCCESS) {
            editSignature = false;
            downloadPDF = false;
            loadPDF = false;
            restPIN = false;
        }

        return (
            <div style={{ float: "right", width: "45%", margin: "20px " }}>
                <p style={{ fontSize: "16px" }}>
                    <FormattedMessage id="c-signature.certificate-desc" />
                </p>
                <div
                    style={{
                        borderRadius: "5px",
                        padding: "25px",
                        border: "2px solid #e9e9e9",
                        height: "58vh",
                        overflow: "auto"
                    }}
                >
                    <FontAwesome
                        name="certificate"
                        style={{
                            fontSize: "30px",
                            color: stateColor,
                            marginTop: "-10px"
                        }}
                    />
                    <span style={{ marginLeft: "10px", fontSize: "16px" }}>
                        {intl.formatMessage({
                            id: "c-signature.certificate-of"
                        }) + certType}
                    </span>{" "}
                    <br />
                    {signerNameSurname} <FormattedMessage id="general.codice-fiscale" />: {signer.cfUtente} <br />
                    <br />
                    <div>
                        {editSignature ? (
                            <Button icon="edit" onClick={editAction} style={{ margin: "0px 5px" }}>
                                <FormattedMessage id="general.edit" />
                            </Button>
                        ) : null}
                        {downloadPDF ? (
                            <Button
                                icon="download"
                                onClick={() => this.handleDownloadFilledPdf()}
                                style={{ margin: "0px 5px" }}
                            >
                                <FormattedMessage id="general.download-pdf" />
                            </Button>
                        ) : null}
                        {loadPDF ? (
                            <Button
                                icon="upload"
                                onClick={() => this.setState({ mUploadPdf: true })}
                                style={{ margin: "0px 5px" }}
                            >
                                <FormattedMessage id="general.upload-pdf" />
                            </Button>
                        ) : null}
                        {restPIN ? (
                            <Button
                                icon="lock"
                                onClick={() => this.setState({ mResetPin: true })}
                                style={{ margin: "0px 5px" }}
                            >
                                <FormattedMessage id="c-signature.pin-reset" />
                            </Button>
                        ) : null}
                        {deleteSignature ? (
                            <Button
                                onClick={() => this.setState({ mDeleteSigner: true })}
                                icon="delete"
                                style={{ margin: "0px 5px" }}
                            >
                                <FormattedMessage id="general.delete" />
                            </Button>
                        ) : null}
                    </div>{" "}
                    <br />
                    {this.renderFormData()}
                </div>
            </div>
        );
    }

    renderFormData() {
        const { signer, signerData, intl } = this.props;

        let certType =
            signer.certType === "FRM"
                ? intl.formatMessage({
                      id: "c-signature.remote-mass-sign"
                  })
                : intl.formatMessage({
                      id: "c-signature.remote-secure-sign"
                  });
        let infoStateSigner = signerStatus(signer.extradata.status);

        return signerData.formData !== undefined ? (
            <div>
                <span style={{ fontWeight: "bold" }}>
                    {" "}
                    <FormattedMessage id="general.certificate" />
                </span>
                <br />
                {intl.formatMessage({
                    id: "c-registry-user.info.type"
                }) + certType}
                <br />
                {intl.formatMessage({
                    id: "c-registry-user.info.state"
                })}{" "}
                <span style={{ color: infoStateSigner.color }}>{infoStateSigner.message}</span>{" "}
                {" " + signer.extradata.statusDescription}
                <br />
                <br />
                <span style={{ fontWeight: "bold" }}>
                    <FormattedMessage id="c-signature.signer-desc" />
                </span>
                <br />
                {intl.formatMessage({
                    id: "c-registry-user.info.name-surname"
                }) +
                    signerData.formData.signerFirstName +
                    " " +
                    signerData.formData.signerLastName}
                <br />
                {intl.formatMessage({
                    id: "c-registry-user.info.fiscal-code"
                }) +
                    signerData.formData.signerFiscalCountry +
                    " " +
                    signerData.formData.signerFiscalCode}
                <br />
                {intl.formatMessage({
                    id: "c-registry-user.info.gender"
                }) + signerData.formData.signerSex}
                <br />
                {intl.formatMessage({
                    id: "c-registry-user.info.place-of-birth"
                })}
                {signerData.formData.signerBirthCity} {signerData.formData.signerBirthDistrict}{" "}
                {signerData.formData.signerBirthCountry}
                <br />
                {intl.formatMessage({
                    id: "c-registry-user.info.date-of-birth"
                }) + new Date(Number(signerData.formData.signerBirthDate))}
                <br />
                <br />
                <span style={{ fontWeight: "bold" }}>
                    <FormattedMessage id="general.address" />
                </span>
                {signerData.formData.signerAddress} {signerData.formData.signerCity}
                {" - "}
                {signerData.formData.signerCap}
                {" - "}
                {signerData.formData.signerCountry}
                <br />
                <br />
                <span style={{ fontWeight: "bold" }}>
                    <FormattedMessage id="general.contacts" />
                </span>
                <br />
                {intl.formatMessage({
                    id: "c-registry-user.info.email"
                }) + signerData.formData.signerEmail}
                <br />
                {intl.formatMessage({
                    id: "c-registry-user.info.phonenumber"
                }) + signerData.formData.cellNumber}
                <br />
                <br />
                <span style={{ fontWeight: "bold" }}>
                    <FormattedMessage id="c-certificate.document-info" />
                </span>
                <br />
                {intl.formatMessage({
                    id: "c-registry-user.info.document-type"
                }) + signerData.formData.signerDocumentType}
                <br />
                {intl.formatMessage({
                    id: "c-registry-user.info.released-by"
                }) + signerData.formData.signerDocumentIssuer}
                <br />
                {intl.formatMessage({
                    id: "c-registry-user.info.document-number"
                }) + signerData.formData.signerDocumentNumber}
                <br />
                {intl.formatMessage({
                    id: "c-registry-user.info.expire-date"
                }) + new Date(Number(signerData.formData.signerDocumentEndDate))}
                <br />
            </div>
        ) : null;
    }

    render() {
        const { mShowInfo } = this.props;
        return (
            <div>
                {mShowInfo ? this.renderInfo() : null}
                {this.renderModalResetPin()}
                {this.renderModalUploadPdf()}
                {this.renderModalDeleteSigner()}
                {this.renderModalEditSigner()}
            </div>
        );
    }
}

export default injectIntl(SignatureInfo);
