import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";

import { ArrowContainer, ChildrenContainer, Header } from "./styled";

const Collapse = props => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        props.isOpen && setIsOpen(props.isOpen);
    }, [props.isOpen]);

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Header onClick={() => setIsOpen(!isOpen)}>
                <ArrowContainer isOpen={isOpen}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </ArrowContainer>
                {props.header}
            </Header>
            <ChildrenContainer isOpen={isOpen}>{props.children}</ChildrenContainer>
        </div>
    );
};

Collapse.propTypes = {
    children: PropTypes.any,
    header: PropTypes.any,
    isOpen: PropTypes.bool
};

export default Collapse;
