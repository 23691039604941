import styled from "styled-components";

export const Container = styled.div`
    background-color: ${props => props.theme.colors.background.white};
    min-height: 100%;
    display: flex;
    flex-direction: row;
`;

export const Content = styled.div`
    margin: 24px;
`;

export const TabsWrapper = styled.div`
    flex: 1;
`;

export const TabsContainer = styled.div`
    width: 100%;

    // The provided Tabs components stops the columbia blue bottom border exactly at the width of the last tab.
    // We need the border to cover the entire width of the page, whether there are enough tabs or not.
    &:after {
        border-bottom: 1px solid ${props => props.theme.colors.primary.columbiaBlue};
        // content and display are necessary to show the element
        content: "";
        display: block;
        // 1 pixel up from the bottom to cover up the existing (but too short) border
        position: relative;
        bottom: 1px;
    }
`;

export const UserDataContainer = styled.div`
    min-width: 329px;
    max-width: 329px;
    box-sizing: border-box;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-right: 1px solid #b9c8d0;
    align-items: center;
    background: #f5f8fa;
`;

export const Divider = styled.div`
    width: 100%;
    border-bottom: 1px solid #d8d8d9;
`;

export const Description = styled.div`
    width: 100%;
    color: #566b76;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    word-break: break-word;
`;
