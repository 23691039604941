import styled from "styled-components";

export const ConnectionService = styled.div`
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
`;

const statusColor = {
    loading: "#f2f5f8",
    active: "#92cf72",
    "not-active": "#f44336",
    "not-allowed": "#f9ce3d"
};

export const ConnectionServiceStatus = styled.div`
    color: ${props => statusColor[props.status]};
    font-size: 10px;
`;
