import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CreateForm from "../../manage-data/company/create-form";

import "./style.css";

class ConnectionsCreateCompanyInfo extends Component {
    static propTypes = {
        auth: PropTypes.object,
        isLoading: PropTypes.bool,
        onCreate: PropTypes.func,
        onSubmit: PropTypes.func,
        selectedCompany: PropTypes.object
    };

    static defaultProps = {
        selectedCompany: null
    };

    render() {
        const { isLoading, onSubmit, auth } = this.props;
        const initialValues = {
            country: "IT",
            taxRegion: "IT",
            token: auth.loginAuth.securityToken
        };
        return (
            <div className="company-create-step">
                <CreateForm
                    initialValues={initialValues}
                    isGestita
                    isLoading={isLoading}
                    onSubmit={onSubmit}
                    submitButtonText={<FormattedMessage id="general.continue" />}
                />
            </div>
        );
    }
}

export default ConnectionsCreateCompanyInfo;
