import React from "react";
import PropTypes from "prop-types";

import TextField from "@ts-digital/arc/antd/TextField";
import { SelectStringField } from "@ts-digital/arc/antd/SelectField";
import { form } from "@ts-digital/conv-redux-form";
import { FormattedMessage, useIntl } from "react-intl";

import LoadingButton from "../../../../components/general/loading-button";
import { officeSchema } from "../../../../utils/form-schema/office";
import { provinceValues } from "../../../../utils/constant";

import { FormContainer, LoadingButtonContainer, ErrorContainer } from "./styled";

export const CreateOfficeForm = ({ buttonText, errorMsg, handleSubmit, isLoading, title }) => {
    const { formatMessage } = useIntl();

    return (
        <FormContainer onSubmit={handleSubmit}>
            <p>{title}</p> <br />
            <TextField
                addonBefore={<FormattedMessage id="c-registry-office.modal.create.field.description" />}
                name="description"
                placeholder={formatMessage({
                    id: "c-registry-office.modal.create.field.placeholder.description"
                })}
            />
            <TextField
                addonBefore={<FormattedMessage id="c-registry-office.modal.create.field.street" />}
                name="street"
                placeholder={formatMessage({
                    id: "c-registry-office.modal.create.field.placeholder.street"
                })}
            />
            <TextField
                addonBefore={<FormattedMessage id="c-registry-office.modal.create.field.city" />}
                name="city"
                placeholder={formatMessage({
                    id: "c-registry-office.modal.create.field.placeholder.city"
                })}
            />
            <SelectStringField
                getOptionKeyFromValue={value => {
                    return value === "" ? undefined : value;
                }}
                name="province"
                options={provinceValues.map(p => {
                    return {
                        value: p.id,
                        label: p.name
                    };
                })}
                placeholder={formatMessage({
                    id: "c-registry-office.modal.create.field.placeholder.province"
                })}
            />
            <TextField
                addonBefore={<FormattedMessage id="c-registry-office.modal.create.field.zipCode" />}
                name="zipCode"
                placeholder={formatMessage({
                    id: "c-registry-office.modal.create.field.placeholder.zipCode"
                })}
            />
            <SelectStringField
                getOptionKeyFromValue={value => {
                    return value === "" ? undefined : value;
                }}
                name="country"
                options={[{ value: "IT", label: "IT" }]}
                placeholder={formatMessage({
                    id: "c-registry-office.modal.create.field.placeholder.country"
                })}
            />
            <ErrorContainer>{errorMsg}</ErrorContainer>
            <LoadingButtonContainer>
                <LoadingButton htmlType="submit" loading={isLoading} type="primary">
                    {buttonText}
                </LoadingButton>
            </LoadingButtonContainer>
        </FormContainer>
    );
};

CreateOfficeForm.propTypes = {
    buttonText: PropTypes.any,
    errorMsg: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    title: PropTypes.any
};

CreateOfficeForm.defaultProps = {
    isLoading: false
};

const formDefinition = {
    form: "office-form",
    schema: officeSchema
};

const formDef = form(formDefinition);

export default formDef(CreateOfficeForm);
