import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@ts-digital/vrc";

export const ListColumn = styled.div`
    width: 48px;
    margin-left: 48px;
    display: flex;
    justify-content: center;
`;

export const Item = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ItemContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-start;
    padding: 24px 0;
    border-bottom: 1px solid #c9d9e8;
`;

export const Name = styled.div`
    justify-content: flex-start;
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-family: Cairo;
    font-size: 20px;
    font-weight: regular;
    letter-spacing: 0.15px;
    line-height: 24px;
`;

export const ChannelsContainer = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const SwitchContainer = styled.div`
    width: 48px;
    margin-left: 48px;
    display: flex;
    justify-content: center;
`;

export const ActionColumn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
`;

export const Icon = styled(FontAwesomeIcon)`
    font-size: 16px;
`;

export const Configure = styled.div`
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    padding: 8px 0;

    > * {
        color: ${props => props.theme.colors.primary.richElectricBlue};
    }
`;

export const ConfigureTrigger = styled(Button)`
    &:disabled {
        pointer-events: none;
    }
`;
