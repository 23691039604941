import styled from "styled-components";

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 2;

    margin-top: 20px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    grid-gap: 16px;
    justify-content: flex-end;
`;

export const SelectCompanyContainer = styled.div`
    display: flex;
`;

export const CompanyTitleContainer = styled.div`
    color: rgb(90, 104, 114);
    font-family: Cairo;
    font-size: 14px;
    font-weight: 600;
    height: 24px;
    line-height: 24px;
    margin-top: 24px;
    text-transform: uppercase;
`;

export const CompanyDescriptionContainer = styled.div`
    color: rgb(0, 80, 117);
    font-family: Cairo;
    font-size: 34px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 16px;
`;

export const Footer = styled.div`
    width: 100%;
    height: 72px;
    background: rgb(242, 245, 248);
    box-shadow: 0px -2px 8px 0px rgba(21, 41, 53, 0.32);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 16px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
`;
