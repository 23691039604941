import React from "react";
import { Route, Switch } from "react-router-dom";

import ChannelsView from "./channels";
import DashboardView from "./dashboard";
import PreferencesView from "./preferences";

import { errorBoundary } from "../../components/general/error-boundary";

const NotificationsCenterView = () => {
    return (
        <Switch>
            <Route exact path="/:item?/notifications-center" component={DashboardView} />
            <Route exact path="/:item?/notifications-center/:selectedItemId?/preferences" component={PreferencesView} />
            <Route
                exact
                path="/:item?/notifications-center/:selectedItemId?/channels/:appCode/:templateType/:channelType"
                component={ChannelsView}
            />
        </Switch>
    );
};

export default errorBoundary(NotificationsCenterView);
