import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { Typography, Button } from "@ts-digital/vrc";
import { FormattedMessage } from "react-intl";

import { ErrorMessageContainer, UploadFileButtonWrapper, CustomUpload } from "./styled";

const ContractUpload = ({ disabled, error, maxFileSize, selectedFiles, onFileChanged, uploadButtonMessage }) => {
    const handleFileRemoval = useCallback(() => {
        onFileChanged("", undefined);
    }, [onFileChanged]);

    const handleFileSelection = useCallback(
        file => {
            let error = file.type !== "application/pdf" || file.size > maxFileSize;

            var reader = new FileReader();
            reader.onload = evt => {
                const fileContent = evt.target.result;
                const base64 = fileContent.split(",")[1];
                onFileChanged(base64, file, error);
            };
            reader.readAsDataURL(file);
            return false;
        },
        [maxFileSize, onFileChanged]
    );

    return (
        <>
            <CustomUpload
                accept="application/pdf"
                beforeUpload={handleFileSelection}
                disabled={disabled}
                fileList={selectedFiles}
                onRemove={handleFileRemoval}
            >
                <UploadFileButtonWrapper>
                    <Button kind="secondary" disabled={disabled}>
                        <FormattedMessage id={uploadButtonMessage} />
                    </Button>
                </UploadFileButtonWrapper>
            </CustomUpload>
            {error && (
                <ErrorMessageContainer>
                    <Typography variant="descriptive">
                        <FormattedMessage id="c-cct-provisioning-download-pdf-form.format-error" />
                    </Typography>
                </ErrorMessageContainer>
            )}
        </>
    );
};

ContractUpload.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    maxFileSize: PropTypes.number,
    /**
     * Callback invoked on file changed. Receives the file's base64, the file object and an error boolean as parameters.
     * If error is true, the file type or size do not match the required ones.
     */
    onFileChanged: PropTypes.func.isRequired,
    selectedFiles: PropTypes.array,
    uploadButtonMessage: PropTypes.string.isRequired
};

ContractUpload.defaultProps = {
    alignment: "left",
    disabled: false,
    maxFileSize: 5242880
};

export default ContractUpload;
