import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ItemDescription = styled.span`
    text-overflow: ellipsis;
    display: block;
    max-width: 400px;
    overflow: hidden;
    white-space: nowrap;
`;

export const InfoIcon = styled(FontAwesomeIcon)`
    margin-left: 8px;
`;
