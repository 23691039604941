export const countryValues = [
    { id: "IT", name: "Italia" },
    { id: "FR", name: "Francia" },
    { id: "GB", name: "Regno Unito" },
    { id: "DE", name: "Germania" }
];

export const statusCompany = {
    NO_STATUS: null,
    UNVERIFIABLE: "UNVERIFIABLE",
    PENDING_VERIFY: "PENDING_VERIFY",
    VERIFIED: "VERIFIED",
    VERIFIED_PENDING_VALIDATE: "VERIFIED_PENDING_VALIDATE",
    UNVERIFIABLE_PENDING_VALIDATE: "UNVERIFIABLE_PENDING_VALIDATE",
    VALIDATED: "VALIDATED",
    REJECTED: "REJECTED",
    REJECTED_PENDING_VALIDATE: "REJECTED_PENDING_VALIDATE"
};

export const certificationStatus = {
    NO_STATUS: null,
    CERTIFIED: "CERTIFIED",
    AWAITING_UPLOAD: "AWAITING_UPLOAD",
    AWAITING_APPROVAL: "AWAITING_APPROVAL"
};

export const signatureStatus = {
    PDF_GENERATED: "PDF_GENERATED",
    SUBMISSION_SUCCESS: "SUBMISSION_SUCCESS",
    FINAL_SUCCESS: "FINAL_SUCCESS",
    ASSIGNED_SUCCESS: "ASSIGNED_SUCCESS",
    ASSIGNED_WITHDRAW_SUCCESS: "ASSIGNED_WITHDRAW_SUCCESS",
    WITHDRAW_SUCCESS: "WITHDRAW_SUCCESS",
    FINAL_ERROR: "FINAL_ERROR",
    SUBMISSION_ERROR: "SUBMISSION_ERROR"
};

export const signerDocumentTypes = [
    { id: "1", name: "Carta d'identità" },
    { id: "2", name: "Patente di guida" },
    { id: "3", name: "Passaporto" },
    { id: "4", name: "Porto d'armi" },
    { id: "5", name: "Tessera di riconoscimento postale" },
    { id: "6", name: "Tessera rilasciata da PA" }
];

export const monthForFiscalCodeControl = ["A", "B", "C", "D", "E", "H", "L", "M", "P", "R", "S", "T"];

export const liquidationState = [
    { id: "LN", name: "Non in liquidazione" },
    { id: "LS", name: "In liquidazione" }
];

export const soleShareholder = [
    { id: "SU", name: "Socio unico" },
    { id: "SM", name: "Più soci" }
];

export const taxRegimeValues = [
    { id: "RF01", name: " RF01 - Regime Ordinario" },
    {
        id: "RF02",
        name: " RF02 - Regime contribuenti minimi (art.1, c.96-117, L. 244/07)"
    },
    {
        id: "RF03",
        name: " RF03 - Regime delle nuove iniziative produttive (art.13, L. 388/00)"
    },
    {
        id: "RF04",
        name: " RF04 - Agricoltura e attività connesse e pesca (artt.34 e 34-bis, DPR 633/72)"
    },
    {
        id: "RF05",
        name: " RF05 - Vendita sali e tabacchi (art.74, c.1, DPR. 633/72)"
    },
    {
        id: "RF06",
        name: " RF06 - Commercio dei fiammiferi (art.74, c.1, DPR  633/72)"
    },
    { id: "RF07", name: " RF07 - Editoria (art.74, c.1, DPR  633/72)" },
    {
        id: "RF08",
        name: " RF08 - Gestione di servizi di telefonia pubblica (art.74, c.1, DPR 633/72)"
    },
    {
        id: "RF09",
        name: " RF09 - Rivendita di documenti di trasporto pubblico e di sosta (art.74, c.1, DPR  633/72)"
    },
    {
        id: "RF10",
        name: " RF10 - Intrattenimenti, giochi e altre attività di cui alla tariffa allegata al DPR 640/72 (art.74, c.6, DPR 633/72)"
    },
    {
        id: "RF11",
        name: " RF11 - Agenzie di viaggi e turismo (art.74-ter, DPR 633/72)"
    },
    { id: "RF12", name: " RF12 - Agriturismo (art.5, c.2, L. 413/91)" },
    {
        id: "RF13",
        name: " RF13 - Vendite a domicilio (art.25-bis, c.6, DPR  600/73)"
    },
    {
        id: "RF14",
        name: " RF14 - Rivendita di beni usati, oggetti d’arte, d’antiquariato o da collezione (art.36, DL 41/95)"
    },
    {
        id: "RF15",
        name: " RF15 - Agenzie di vendite all’asta di oggetti d’arte, antiquariato o da collezione (art.40-bis, DL 41/95)"
    },
    { id: "RF16", name: " RF16 - IVA per cassa P.A. (art.6, c.5, DPR 633/72)" },
    { id: "RF17", name: " RF17 - IVA per cassa (art. 32-bis, DL 83/2012)" },
    { id: "RF18", name: " RF18 - Altro" },
    {
        id: "RF19",
        name: " RF19 - Regime forfettario (art.1, c.54-89, L. 190/2014)"
    }
];

export const provinceValues = [
    { id: "AG", name: "Agrigento" },
    { id: "AL", name: "Alessandria" },
    { id: "AN", name: "Ancona" },
    { id: "AO", name: "Aosta" },
    { id: "AR", name: "Arezzo" },
    { id: "AP", name: "Ascoli Piceno" },
    { id: "AT", name: "Asti" },
    { id: "AV", name: "Avellino" },
    { id: "BA", name: "Bari" },
    { id: "BT", name: "Barletta-Andria-Trani" },
    { id: "BL", name: "Belluno" },
    { id: "BN", name: "Benevento" },
    { id: "BG", name: "Bergamo" },
    { id: "BI", name: "Biella" },
    { id: "BO", name: "Bologna" },
    { id: "BZ", name: "Bolzano" },
    { id: "BS", name: "Brescia" },
    { id: "BR", name: "Brindisi" },
    { id: "CA", name: "Cagliari" },
    { id: "CL", name: "Caltanissetta" },
    { id: "CB", name: "Campobasso" },
    { id: "CI", name: "Carbonia-Iglesias" },
    { id: "CE", name: "Caserta" },
    { id: "CT", name: "Catania" },
    { id: "CZ", name: "Catanzaro" },
    { id: "CH", name: "Chieti" },
    { id: "CO", name: "Como" },
    { id: "CS", name: "Cosenza" },
    { id: "CR", name: "Cremona" },
    { id: "KR", name: "Crotone" },
    { id: "CN", name: "Cuneo" },
    { id: "EN", name: "Enna" },
    { id: "FM", name: "Fermo" },
    { id: "FE", name: "Ferrara" },
    { id: "FI", name: "Firenze" },
    { id: "FG", name: "Foggia" },
    { id: "FC", name: "Forlì-Cesena" },
    { id: "FR", name: "Frosinone" },
    { id: "GE", name: "Genova" },
    { id: "GO", name: "Gorizia" },
    { id: "GR", name: "Grosseto" },
    { id: "IM", name: "Imperia" },
    { id: "IS", name: "Isernia" },
    { id: "SP", name: "La Spezia" },
    { id: "AQ", name: "L'Aquila" },
    { id: "LT", name: "Latina" },
    { id: "LE", name: "Lecce" },
    { id: "LC", name: "Lecco" },
    { id: "LI", name: "Livorno" },
    { id: "LO", name: "Lodi" },
    { id: "LU", name: "Lucca" },
    { id: "MC", name: "Macerata" },
    { id: "MN", name: "Mantova" },
    { id: "MS", name: "Massa-Carrara" },
    { id: "MT", name: "Matera" },
    { id: "ME", name: "Messina" },
    { id: "MI", name: "Milano" },
    { id: "MO", name: "Modena" },
    { id: "MB", name: "Monza e della Brianza" },
    { id: "NA", name: "Napoli" },
    { id: "NO", name: "Novara" },
    { id: "NU", name: "Nuoro" },
    { id: "OT", name: "Olbia-Tempio" },
    { id: "OR", name: "Oristano" },
    { id: "PD", name: "Padova" },
    { id: "PA", name: "Palermo" },
    { id: "PR", name: "Parma" },
    { id: "PV", name: "Pavia" },
    { id: "PG", name: "Perugia" },
    { id: "PU", name: "Pesaro e Urbino" },
    { id: "PE", name: "Pescara" },
    { id: "PC", name: "Piacenza" },
    { id: "PI", name: "Pisa" },
    { id: "PT", name: "Pistoia" },
    { id: "PN", name: "Pordenone" },
    { id: "PZ", name: "Potenza" },
    { id: "PO", name: "Prato" },
    { id: "RG", name: "Ragusa" },
    { id: "RA", name: "Ravenna" },
    { id: "RC", name: "Reggio Calabria" },
    { id: "RE", name: "Reggio Emilia" },
    { id: "RI", name: "Rieti" },
    { id: "RN", name: "Rimini" },
    { id: "RM", name: "Roma" },
    { id: "RO", name: "Rovigo" },
    { id: "SA", name: "Salerno" },
    { id: "VS", name: "Medio Campidano" },
    { id: "SS", name: "Sassari" },
    { id: "SV", name: "Savona" },
    { id: "SI", name: "Siena" },
    { id: "SR", name: "Siracusa" },
    { id: "SO", name: "Sondrio" },
    { id: "TA", name: "Taranto" },
    { id: "TE", name: "Teramo" },
    { id: "TR", name: "Terni" },
    { id: "TO", name: "Torino" },
    { id: "OG", name: "Ogliastra" },
    { id: "TP", name: "Trapani" },
    { id: "TN", name: "Trento" },
    { id: "TV", name: "Treviso" },
    { id: "TS", name: "Trieste" },
    { id: "UD", name: "Udine" },
    { id: "VA", name: "Varese" },
    { id: "VE", name: "Venezia" },
    { id: "VB", name: "Verbano-Cusio-Ossola" },
    { id: "VC", name: "Vercelli" },
    { id: "VR", name: "Verona" },
    { id: "VV", name: "Vibo Valentia" },
    { id: "VI", name: "Vicenza" },
    { id: "VT", name: "Viterbo" }
];

export const classifierItemTypes = [
    { id: "STUDIO", name: "STUDIO" },
    { id: "COMPANY", name: "COMPANY" }
];

export const taxRegionValue = [{ id: "ZXXX", code: "IT" }];

export const storageServices = ["CCT-FLOW"];
export const privacyServices = ["PRIVACY-FLOW"];
export const expenseReportServices = ["NOTESPESE-FLOW"];
export const cdcServices = ["DIGITALBOX"];
export const billingServices = ["SDI-FLOW", "B2B-PASSIVE-FLOW", "ERCV", "SDI"];
export const adempimentiServices = ["CBCR-FLOW", "A-FISCALI-IVA-FLOW"];
export const signServices = ["SIGNATURE-FLOW"];
export const gdprServices = ["GDPR-LOG"];
export const peopleAppServices = ["PEOPLEAPP"];
export const invoiceTradingServices = ["EIT"];
export const budgetServices = ["BDG"];

export const serviceColors = {
    TEL: "#00bcd4",
    ARC: "#0083c3",
    EBS: "#607d8b",
    EIP: "#f7ac1e",
    SIG: "#c5765a",
    "GDPR-LOG": "#f44336",
    EXP: "#673ab7",
    PRIVACY: "#1dec7e",
    DBX: "#e9397a",
    PPL: "#e9d784",
    EIT: "#607d8b",
    BDG: "#1dec7e"
};

export const serviceIcons = {
    ARC: "archive",
    DBX: "paste",
    EIP: "file-alt",
    "GDPR-LOG": "list-ul",
    PPL: "paste",
    PRIVACY: "key",
    SIG: "pencil-alt",
    TEL: "paper-plane",
    EXP: "sticky-note",
    EBS: "globe",
    BDG: "key",
    EIT: "key"
};

// numero di aziende oltre le quali un'utenza viene tratta in modo diverso
export const largeUserSize = 2000;

export const b2bCode = [
    { id: "0ZCQR4A", name: "0ZCQR4A" },
    { id: "32C3O8A", name: "32C3O8A" },
    { id: "3B100M4", name: "3B100M4" },
    { id: "47DXY98", name: "47DXY98" },
    { id: "5K65D7Y", name: "5K65D7Y" },
    { id: "7PHNQ6A", name: "7PHNQ6A" },
    { id: "85GHHN7", name: "85GHHN7" },
    { id: "8C9X0IZ", name: "8C9X0IZ" },
    { id: "94QLT54", name: "94QLT54" },
    { id: "9OLXUZV", name: "9OLXUZV" },
    { id: "BYPX6BP", name: "BYPX6BP" },
    { id: "CPMRDH0", name: "CPMRDH0" },
    { id: "GUNWXG7", name: "GUNWXG7" },
    { id: "GXREHPP", name: "GXREHPP" },
    { id: "HNLUZ94", name: "HNLUZ94" },
    { id: "IAGHDRP", name: "IAGHDRP" },
    { id: "JOCOG96", name: "JOCOG96" },
    { id: "KNKW6RR", name: "KNKW6RR" },
    { id: "KT40LX8", name: "KT40LX8" },
    { id: "LOR6W44", name: "LOR6W44" },
    { id: "M2DC2YS", name: "M2DC2YS" },
    { id: "MABLSEE", name: "MABLSEE" },
    { id: "OHHROL9", name: "OHHROL9" },
    { id: "P7E1N9A", name: "P7E1N9A" },
    { id: "QCE7L4G", name: "QCE7L4G" },
    { id: "URJOD79", name: "URJOD79" },
    { id: "V4MGS7T", name: "V4MGS7T" },
    { id: "XT9NYC1", name: "XT9NYC1" },
    { id: "YPNDW0N", name: "YPNDW0N" }
];

export const legalForms = [
    "AA",
    "AC",
    "AE",
    "AF",
    "AI",
    "AL",
    "AM",
    "AN",
    "AP",
    "AR",
    "AS",
    "AT",
    "AU",
    "AZ",
    "CC",
    "CE",
    "CF",
    "CI",
    "CL",
    "CM",
    "CN",
    "CO",
    "CR",
    "CS",
    "CZ",
    "DI",
    "EC",
    "ED",
    "EE",
    "EI",
    "EL",
    "EM",
    "EN",
    "EP",
    "ER",
    "ES",
    "FI",
    "FO",
    "GE",
    "IC",
    "ID",
    "IF",
    "IR",
    "LL",
    "MA",
    "OC",
    "OO",
    "OS",
    "PA",
    "PC",
    "PF",
    "PS",
    "RC",
    "RR",
    "RS",
    "SA",
    "SC",
    "SD",
    "SE",
    "SF",
    "SG",
    "SI",
    "SL",
    "SM",
    "SN",
    "SO",
    "SP",
    "SR",
    "SS",
    "ST",
    "SU",
    "SV",
    "SZ",
    "XX"
];

export const connectionsWithoutService = ["DIGITALBOX-BP", "PAYROLL"];
