import React from "react";

import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";

import { errorBoundary } from "../../../components/general/error-boundary";
import FontawesomeIcon from "../../../components/general/fontawesome-icon";
import { IconContainer, TitleContainer } from "../styled";
import InvoiceTrading from "../../../components/invoice-trading";

import { getWaitingList } from "../../../actions/waitinglist/read";
import { subscribeWaitinglist, deleteWaitinglist } from "../../../actions/waitinglist/write";

const InvoiceTradingView = ({
    company,
    services,
    getWaitingList,
    getWaitingListStatus,
    subscribeWaitinglist,
    deleteWaitinglist,
    waitinglistDeleteStatus,
    waitinglist,
    waitinglistSubscribeStatus
}) => (
    <div>
        <IconContainer>
            <FontawesomeIcon name="key" solid={true} />
        </IconContainer>
        <TitleContainer>
            <FormattedMessage id="c-services-view.EIT.name" />
        </TitleContainer>
        <InvoiceTrading
            company={company}
            services={services}
            getWaitingList={getWaitingList}
            getWaitingListStatus={getWaitingListStatus}
            subscribeWaitinglist={subscribeWaitinglist}
            deleteWaitinglist={deleteWaitinglist}
            waitinglistDeleteStatus={waitinglistDeleteStatus}
            waitinglist={waitinglist}
            waitinglistSubscribeStatus={waitinglistSubscribeStatus}
        />
    </div>
);

const mapStateToProps = (state, props) => ({
    services: state.services,
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    waitinglist: state.waitinglist.read.getWaitingList.waitinglist,
    waitinglistSubscribeStatus: state.waitinglist.write.subscribeWaitinglist.status,
    waitinglistDeleteStatus: state.waitinglist.write.deleteWaitinglist.status,
    getWaitingListStatus: state.waitinglist.read.getWaitingList.status
});

const composedHoc = compose(
    connect(mapStateToProps, { getWaitingList, subscribeWaitinglist, deleteWaitinglist }),
    errorBoundary
);

export default composedHoc(InvoiceTradingView);
