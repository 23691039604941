import React, { Component } from "react";
import PropTypes from "prop-types";

import { Redirect } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { isNil } from "ramda";
import moment from "moment";
import { Layout } from "antd";

import { updateItem, resetUpdateItem } from "../../actions/companies";
import { listUsers } from "../../actions/company/users";

import Edit from "../../components/manage-data/company/edit";
import { errorBoundary } from "../../components/general/error-boundary";

import { canModifyData, hasAdminOwnerUser } from "../../utils/get-roles";

import { uploadLogo, resetDeleteLogo, deleteLogo, resetUploadLogo } from "../../actions/manage-logo/index";

class ItemEdit extends Component {
    constructor() {
        super();
        this.state = {
            itemUpdated: false
        };
    }

    static propTypes = {
        company: PropTypes.object.isRequired,
        listUsers: PropTypes.func.isRequired,
        updateItemStatus: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        users: PropTypes.object.isRequired
    };

    componentDidMount() {
        const { company, listUsers, resetUpdateItem } = this.props;
        resetUpdateItem();
        listUsers(true, true, company.base.uuid);
    }

    componentDidUpdate(prevProps) {
        const { updateItemStatus } = this.props;

        if (prevProps.updateItemStatus.started && updateItemStatus.ended && !updateItemStatus.error) {
            this.setState({ itemUpdated: true });
        }
    }

    parseDate(date) {
        if (date === 0) {
            return undefined;
        }
        if (moment(date, "YYYY-MM-DD").isValid()) {
            return moment.utc(date, "YYYY-MM-DD").valueOf();
        }
        return date;
    }

    handleUpdateDataItem(companyData) {
        const { company, deleteLogo, logo, updateItem, uploadLogo } = this.props;

        if (!isNil(company)) {
            let dataToUpdate;

            let address = {
                types: ["REGISTERED_OFFICE"],
                country: companyData.country,
                province: companyData.province,
                city: companyData.city,
                zipCode: companyData.zipCode,
                streetName: companyData.streetName,
                streetNumber: companyData.streetNumber
            };

            dataToUpdate = {
                ...company,
                base: {
                    ...company.base,
                    details: {
                        ...company.base.details,
                        description: companyData.description,
                        firstName: companyData.firstName,
                        lastName: companyData.lastName,
                        addresses: [address],
                        legalForm: companyData.legalForm,
                        economics: {
                            ...company.base.details.economics,
                            taxRegime: companyData.taxRegime,
                            cciaa: companyData.cciaa,
                            rea: companyData.rea,
                            liquidationState: companyData.liquidationState,
                            capitalStock: companyData.capitalStock,
                            soleShareholder: companyData.soleShareholder,
                            balanceSheetDate: this.parseDate(companyData.balanceSheetDate),
                            economicActivities: companyData.economicActivity
                                ? {
                                      mainActivity: {
                                          code: companyData.economicActivity
                                      }
                                  }
                                : undefined
                        },
                        professionalRegister: {
                            ...company.base.details.professionalRegister,
                            description: companyData.registerDescription,
                            code: companyData.registerCode,
                            province: companyData.registerProvince,
                            registrationDate: this.parseDate(companyData.registerRegistrationDate)
                        }
                    }
                },
                preferences: { hidden: companyData.privacyFlag }
            };

            if (logo !== "" && companyData.logo === "") {
                deleteLogo(company.base.uuid);
            } else if (logo !== companyData.logo) {
                uploadLogo(company.base.uuid, companyData.logo);
            }

            updateItem(dataToUpdate);
        }
    }

    render() {
        const {
            company,
            updateItemStatus,
            match,
            user,
            users,
            deleteLogo,
            uploadLogo,
            uploadLogoStatus,
            deleteLogoStatus,
            resetDeleteLogo,
            resetUploadLogo
        } = this.props;
        const { itemUpdated } = this.state;

        let editable = false;

        if (user && company) {
            editable = canModifyData(user, company.base.id) ? true : !hasAdminOwnerUser(company.base.id, users);
        }

        return itemUpdated ? (
            <Redirect to={`/${match.params.item}/edit/success`} />
        ) : (
            <Layout>
                <Layout.Content className="Root-content">
                    <Edit
                        company={company}
                        editable={editable}
                        history={this.props.history}
                        logo={this.props.logo}
                        logoFinishedLoading={this.props.logoFinishedLoading}
                        onCancel={() => this.props.history.push(`/${match.params.item}/dashboard`)}
                        onSubmit={companyData => this.handleUpdateDataItem(companyData)}
                        status={updateItemStatus}
                        handleDeleteLogo={uuid => deleteLogo(uuid)}
                        handleUploadLogo={(uuid, base64) => uploadLogo(uuid, base64)}
                        uploadLogoStatus={uploadLogoStatus}
                        resetDeleteLogo={resetDeleteLogo}
                        resetUploadLogo={resetUploadLogo}
                        deleteLogoStatus={deleteLogoStatus}
                        logoEdit={match.params.editlogo === "editlogo"}
                        user={user}
                    />
                </Layout.Content>
            </Layout>
        );
    }
}

function mapStateToProps(state, props) {
    const selectedItem = state.companies.data[props.match.params.item];

    return {
        companies: state.companies,
        company: selectedItem ? selectedItem.item : {},
        logo: selectedItem ? state.manageLogo.logos[selectedItem.item.base.uuid] : "",
        logoFinishedLoading: state.manageLogo.getLogo.ended,
        uploadLogoStatus: state.manageLogo.uploadLogo,
        deleteLogoStatus: state.manageLogo.deleteLogo,
        updateItemStatus: state.companies.updateItem.status,
        user: state.user.user,
        users: selectedItem && selectedItem.users ? selectedItem.users : {}
    };
}

const composedHoc = compose(
    connect(mapStateToProps, {
        deleteLogo,
        listUsers,
        resetUpdateItem,
        updateItem,
        uploadLogo,
        resetDeleteLogo,
        resetUploadLogo
    }),
    errorBoundary
);

export default composedHoc(ItemEdit);
