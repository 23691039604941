import React from "react";
import PropTypes from "prop-types";

import { Field } from "redux-form";
import { field } from "@ts-digital/conv-redux-form";
import { RadioGroup } from "@ts-digital/vrc";

import { CustomFormItem } from "../styled";

export const WrappedRadio = ({ input, label, meta, disabled }) => {
    const optionsRadio = [
        { label: "Maschio", value: "M", disabled: disabled },
        { label: "Femmina", value: "F", disabled: disabled }
    ];

    return (
        <CustomFormItem
            label={label}
            validateStatus={meta.touched && meta.error ? "error" : ""}
            help={meta.touched && meta.error ? meta.error : ""}
            colon={false}
        >
            <RadioGroup
                name="GenderGroup"
                options={optionsRadio}
                onChange={val => {
                    input.onChange(val);
                }}
                value={input.value}
            />
        </CustomFormItem>
    );
};

const GenderCombo = props => {
    return <Field {...props} component={WrappedRadio} />;
};

GenderCombo.propTypes = { name: PropTypes.string.isRequired, label: PropTypes.string, disabled: PropTypes.bool };

export default field({ type: "string" })(GenderCombo);
