import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    flex-direction: column;
`;

export const LogoContainer = styled.div`
    margin-bottom: 40px;
`;

export const Subtitle = styled.h2`
    margin-bottom: 20px;
`;
