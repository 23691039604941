export const themes = {
    green: {
        highlightword: props => props.theme.colors.status.laSalleGreen,
        circle: {
            background: "#D1EBD5",
            color: "#0e5822",
            descriptionColor: "#5a6872"
        }
    },
    blue: {
        highlightword: "#5B79D4",
        circle: {
            background: "#DDE3F0",
            color: "#2d456e",
            descriptionColor: "#5a6872"
        }
    },
    purple: {
        highlightword: "#7745ED",
        circle: {
            background: "#E2DCF5",
            color: "#383377",
            descriptionColor: "#5a6872"
        }
    }
};
