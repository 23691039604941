import { combineReducers } from "redux";

import {
    GET_TERM_CONSENT_ERROR,
    GET_TERM_CONSENT_START,
    GET_TERM_CONSENT_SUCCESS,
    UPDATE_TERM_CONSENT_START,
    UPDATE_TERM_CONSENT_SUCCESS,
    UPDATE_TERM_CONSENT_ERROR,
    GET_CONSENT_LIST_START,
    GET_CONSENT_LIST_ERROR,
    GET_CONSENT_LIST_SUCCESS
} from "../actions/user-consents";
import { genericStatusReducer } from "../utils/reducer-utils";

const getTermStatus = genericStatusReducer(GET_TERM_CONSENT_START, GET_TERM_CONSENT_SUCCESS, GET_TERM_CONSENT_ERROR);
const updateConsentsStatus = genericStatusReducer(
    UPDATE_TERM_CONSENT_START,
    UPDATE_TERM_CONSENT_SUCCESS,
    UPDATE_TERM_CONSENT_ERROR
);

const getConsentListStatus = genericStatusReducer(
    GET_CONSENT_LIST_START,
    GET_CONSENT_LIST_SUCCESS,
    GET_CONSENT_LIST_ERROR
);

const terms = (state = {}, { type, payload }) => {
    switch (type) {
        case GET_CONSENT_LIST_SUCCESS:
        case GET_TERM_CONSENT_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case UPDATE_TERM_CONSENT_START:
            const currentConsent = state[payload.term] || {};

            return {
                ...state,
                [payload.term]: {
                    ...currentConsent,
                    ...payload.consent
                }
            };
        default:
            return state;
    }
};

export default combineReducers({ getConsentListStatus, getTermStatus, updateConsentsStatus, terms });
