import { isNumeric } from "validator";

const normalizeLength = (value, previousValue, length, upperCase) => {
    if (!value) {
        return value;
    }
    const tmpValue = value;
    if (tmpValue.length <= length) {
        if (upperCase === true) {
            return tmpValue.toUpperCase();
        } else {
            return tmpValue;
        }
    } else {
        return previousValue;
    }
};

export function normalizeCustomLength(length) {
    return (value, previousValue) => {
        return normalizeLength(value, previousValue, length, false);
    };
}

export function normalizeZipCode(value, previousValue, allValues) {
    if (!value || allValues.country !== "IT") {
        return value;
    }
    const onlyNums = value.replace(/[^\d]/g, "");

    if (onlyNums.length <= 5) {
        return onlyNums;
    } else {
        return previousValue.substring(0, 5);
    }
}

export function normalizeCodeNazProv(value, previousValue) {
    if (!value) {
        return value;
    }
    const onlyChars = value.replace(/[^a-zA-Z]/g, "");

    if (onlyChars.length <= 2) {
        return onlyChars.toUpperCase();
    } else {
        return previousValue;
    }
}

export function normalizeTelFax(value, previousValue) {
    if (!value) {
        return value;
    }
    const onlyNums = value.replace(/[^\d]/g, "");
    return normalizeLength(onlyNums, previousValue, 12, false);
}

export function normalizeFiscalCode(value, previousValue) {
    if (!value) {
        return value;
    }
    const tmpValue = value.replace(/([^a-zA-Z0-9])/g, "");
    return isNumeric(tmpValue)
        ? normalizeLength(tmpValue, previousValue, 11, true)
        : normalizeLength(tmpValue, previousValue, 16, true);
}
export function normalizeVatNumber(value, previousValue, allValues) {
    if (!value || allValues.taxRegion !== "IT") {
        return value;
    }
    const onlyNums = value.replace(/[^\d]/g, "");
    return normalizeLength(onlyNums, previousValue, 11, false);
}

export function normalizeEconomicActivity(value, previousValue) {
    return !value.match(/^[0-9.]{0,8}$/) ? previousValue : value;
}
