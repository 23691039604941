import styled from "styled-components";

export const Action = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: ${props => props.grow || 1};
`;

export const Label = styled.span`
    margin-bottom: 8px;
`;

export const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
    width: 100%;
`;

export const CheckboxContainer = styled.div`
    margin-top: 16px;
`;
