import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Card = styled.div`
    box-shadow: 0 2px 8px 0 rgba(21, 41, 53, 0.32);
    background-color: #fff;
    min-height: 260px;
    display: grid;
    grid-template-columns: 1fr 48px;
`;

export const CardContent = styled.div`
    height: 100%;
    overflow: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 8px;
`;

export const Logo = styled.img`
    width: 80%;
    margin: 0 auto;
`;

export const Description = styled.div`
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    letter-spacing: 0.25px;
    text-align: left;
    color: #5a6872;
    margin: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const CardActionsBar = styled.div`
    /* width: 48px; */
    height: 100%;
    background-color: #f2f5f8;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    /* flex-shrink: 0; */

    & > :last-child {
        border-top: 1px solid #c9d9e8;
    }
`;

export const ButtonContainer = styled.div`
    height: 48px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const Icon = styled(FontAwesomeIcon)`
    color: #0090d1;

    &:hover {
        color: #00c3ea;
    }
`;

export const ActionIcon = styled(FontAwesomeIcon)`
    color: ${props => (props.companyStatus !== "CERTIFIED" && props.serviceId === "SPID" ? "#D8D8D9" : "#0090d1")};

    &:hover {
        color: #00c3ea;
    }
`;
