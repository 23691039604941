export default function validateFields(connection) {
    const validationErrors = Object.entries(connection).reduce((acc, entry) => {
        acc[entry[0]] = false;

        if ((entry[1] === "" || entry[1] === undefined) && entry[0] !== "featureCode") {
            acc[entry[0]] = true;
        }

        return acc;
    }, {});

    return validationErrors;
}
