import React, { Component } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import TextField from "@ts-digital/arc/antd/TextField";
import { SelectStringField } from "@ts-digital/arc/antd/SelectField";
import { form } from "@ts-digital/conv-redux-form";

import DataAddress from "../../components/manage-data/company/data-address";

import { officeSchema } from "../../utils/form-schema/office";
import { b2bCode } from "../../utils/constant";

import { Button, ButtonsContainer, FormData, LeftCentralColumn, LeftColumn } from "./styled";

b2bCode.unshift({
    id: "",
    name: "---"
});
const govCodes = b2bCode.map(p => {
    return {
        value: p.id,
        label: p.name,
        searchMatcherString: p.name
    };
});

class OfficeForm extends Component {
    static propTypes = {
        submitButtonLabel: PropTypes.node,
        handleSubmit: PropTypes.func.isRequired,
        isGovCodeEnabled: PropTypes.bool,
        isLoading: PropTypes.bool,
        onCancel: PropTypes.func.isRequired,
        unavailableGovCodes: PropTypes.array
    };

    static defaultProps = {
        isGovCodeEnabled: false,
        unavailableGovCodes: []
    };

    render() {
        const { handleSubmit, isGovCodeEnabled, isLoading, onCancel, submitButtonLabel, unavailableGovCodes } =
            this.props;

        return (
            <form onSubmit={handleSubmit}>
                <FormData>
                    <LeftCentralColumn>
                        <TextField
                            label={<FormattedMessage id="c-registry-office.modal.create.field.description" />}
                            name="description"
                        />
                    </LeftCentralColumn>
                    <DataAddress disabled={false} />
                    {isGovCodeEnabled && (
                        <LeftColumn>
                            <SelectStringField
                                label={<FormattedMessage id="general.govCode" />}
                                name="govCode"
                                options={govCodes.filter(g => !unavailableGovCodes.includes(g.value))}
                                showSearch={true}
                            />
                        </LeftColumn>
                    )}
                </FormData>
                <ButtonsContainer>
                    <Button disabled={isLoading} onClick={onCancel} variant="secondary">
                        <FormattedMessage id="general.annulla" />
                    </Button>

                    <Button loading={isLoading} htmlType="submit" variant="primary">
                        {submitButtonLabel}
                    </Button>
                </ButtonsContainer>
            </form>
        );
    }
}

const formDefinition = {
    form: "office-form",
    schema: officeSchema,
    initialValues: {
        country: "IT"
    }
};

export default form(formDefinition)(OfficeForm);
