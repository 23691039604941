import React, { useState } from "react";
import PropTypes from "prop-types";

import { isAppAvailable } from "../../utils/app-availability";
import ServiceCard from "../../components/general/service-card";
import GdprComponent from "./gdpr";
import { ServiceCardsContainer } from "../general/service-card/styled";

const GdprLog = ({
    services: { active, agyoServices, categories },
    company,
    listUsersGdprEnabled,
    users,
    setGdprLogRoleAssociation,
    emailPec,
    user,
    getWaitingList,
    getWaitingListStatus,
    subscribeWaitinglist,
    deleteWaitinglist,
    waitinglistDeleteStatus,
    waitinglist,
    waitinglistSubscribeStatus
}) => {
    const [selectedCard, setSelectedCard] = useState(0);

    const roles = user.user && user.user.roles ? user.user.roles : [];
    const isActiveInAgyo = agyoServices.services.some(service => service.id === "GDPR-LOG" && service.active);

    const servicesConfig = [
        {
            active: true,
            disable: !isActiveInAgyo,
            display:
                (selectedCard === 0 || selectedCard === 1) &&
                isAppAvailable(
                    company.base.id,
                    roles,
                    "GDPR-LOG",
                    active.availableToUser,
                    categories.data["PRIVACY"],
                    active.services
                ),
            expanded: selectedCard === 1,
            expandedComponent: (
                <GdprComponent
                    company={company}
                    email={emailPec}
                    usersGdprEnabled={listUsersGdprEnabled}
                    gdprLogAssociation={setGdprLogRoleAssociation}
                    users={users}
                />
            ),
            title: "apps.gdpr.title",
            description: "gdpr.card",
            showConfigureButton: true,
            onConfigure: () => setSelectedCard(1),
            onClose: () => setSelectedCard(0),
            serviceInfo: agyoServices.services.filter(ele => ele.id === "GDPR-LOG")[0]
        }
    ];

    return (
        (emailPec.status.ended || emailPec.status.error) && (
            <ServiceCardsContainer expanded={selectedCard === 1}>
                {servicesConfig.map((service, key) => (
                    <ServiceCard
                        key={key}
                        active={service.active}
                        disable={service.disable}
                        expanded={service.expanded}
                        expandedComponent={service.expandedComponent}
                        title={service.title}
                        description={service.description}
                        display={service.display}
                        button={service.button}
                        tooltip={service.tooltip}
                        showActiveButton={service.showActiveButton}
                        onActive={service.onActive}
                        onClose={service.onClose}
                        onConfigure={service.onConfigure}
                        showBuyButton={service.showBuyButton}
                        showContactToBuy={service.showContactToBuy}
                        showConfigureButton={service.showConfigureButton}
                        serviceInfo={service.serviceInfo}
                        companyId={company.base.id}
                        getWaitingList={getWaitingList}
                        getWaitingListStatus={getWaitingListStatus}
                        waitinglist={waitinglist}
                        subscribeWaitinglist={subscribeWaitinglist}
                        waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                        waitinglistDeleteStatus={waitinglistDeleteStatus}
                        deleteWaitinglist={deleteWaitinglist}
                        user={user}
                    />
                ))}
            </ServiceCardsContainer>
        )
    );
};

GdprLog.propTypes = {
    company: PropTypes.object.isRequired,
    services: PropTypes.object.isRequired,
    listUsersGdprEnabled: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
    setGdprLogRoleAssociation: PropTypes.func.isRequired,
    emailPec: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
};

export default GdprLog;
