import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { debounce } from "lodash";
import { connect } from "react-redux";
import { Redirect, useLocation, useParams } from "react-router-dom";

import { loadCompany } from "../../actions/company";
import { getHasStudio } from "../../actions/registry/read";
import { getCategories } from "../../actions/services/categories";
import { fetchAgyoServices } from "../../actions/services/service-subscription-v2";
import BadWidth from "../../components/general/bad-width";
import { Spinner } from "../../components/general/spinner";
import detectDeviceType from "../../utils/detect-device-type";
import { hasAtLeastOneItem } from "../../utils/get-roles";
import CompanyDashboard from "../company-dashboard";
import GeneralTermsModal from "../general-terms-modal";
import PrivacyModal from "../privacy-modal";
import ItemSelectionView from "./item-selection";
import "./style.css";

export const Root = ({
    companies,
    fetchAgyoServices,
    getCategories,
    getHasStudio,
    itemLoadingStatus,
    loadCompany,
    user
}) => {
    const location = useLocation();
    const params = useParams();

    const [device, setDevice] = useState("desktop");
    const [firstLoad, setFirstLoad] = useState(true);

    // Other pages in the project invoke the loadCompany action making so that we cannot rely on
    // itemLoadingStatus.started to detect the selected item's loading status.
    // This boolean is only ever set to true if params.item is changed, and is set back to false once the item
    // has been loaded.
    const [changingItem, setChangingItem] = useState(false);

    useEffect(() => {
        // Debounced to prevent too many events from firing at once, causing performance problems
        const handler = debounce(() => setDevice(detectDeviceType()), 500);
        window.addEventListener("resize", handler);

        return () => window.removeEventListener("resize", handler);
    }, []);

    useEffect(() => {
        fetchAgyoServices();
        getCategories();
        getHasStudio();
    }, [fetchAgyoServices, getCategories, getHasStudio]);

    useEffect(() => {
        if (params.item) {
            loadCompany(params.item, true);
            setChangingItem(true);
        }
    }, [loadCompany, params.item]);

    useEffect(() => {
        if (!itemLoadingStatus.started) {
            setChangingItem(false);
        }
    }, [itemLoadingStatus.started]);

    useEffect(() => {
        setFirstLoad(false);
    }, []);

    // Unsupported device size
    if (device !== "desktop") {
        return <BadWidth template={device} />;
    }

    // The user has access to no item, redirect them to the item creation page
    if (!hasAtLeastOneItem(user) && Object.keys(companies.data).length <= 0) {
        return <Redirect to="/createItem" />;
    }

    // The user only has one item, send them directly to that item's dashboard
    // This should be done only once, to allow a user to manually get to the item selection page if they wish to
    const itemIdsSet = new Set(user.user.roles.map(role => role.resourceId).filter(role => role !== "REGISTERED"));
    if (itemIdsSet.size === 1 && firstLoad && !params.item) {
        return <Redirect to={`/${itemIdsSet.values().next().value}/dashboard`} />;
    }

    return (
        <>
            <GeneralTermsModal />
            <PrivacyModal />
            {!params.item ? (
                <ItemSelectionView />
            ) : (
                <Spinner fullPage={true} loading={!companies.data[params.item]}>
                    <CompanyDashboard
                        isLoadingCompany={changingItem}
                        // location aggiunta per evitare update blocking
                        // vedi https://reacttraining.com/react-router/web/guides/dealing-with-update-blocking
                        location={location}
                    />
                </Spinner>
            )}
        </>
    );
};

Root.propTypes = {
    companies: PropTypes.object.isRequired,
    fetchAgyoServices: PropTypes.func.isRequired,
    getCategories: PropTypes.func.isRequired,
    getHasStudio: PropTypes.func.isRequired,
    loadCompany: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        companies: state.companies,
        itemLoadingStatus: state.company.status,
        notificationsExist: state.ncs.notifications.exist,
        notificationsList: state.ncs.notifications.data,
        notificationsListStatus: state.ncs.notifications.status,
        user: state.user
    };
}

export default connect(mapStateToProps, {
    getCategories,
    getHasStudio,
    fetchAgyoServices,
    loadCompany
})(Root);
