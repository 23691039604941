import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { Modal } from "antd";
import { useIntl } from "react-intl";

import "./style.css";
import ConnectionElement from "./connection-element";

const ConnectionList = ({
    agyoServices,
    certification,
    connectionList,
    focus,
    getCertification,
    history,
    isAllowedToManage,
    itemServices,
    itemServicesEnded,
    settingsPageEnabled,
    onDeleteConnection
}) => {
    const intl = useIntl();
    const showDeleteConfirm = useCallback(
        id => {
            Modal.confirm({
                title: intl.formatMessage({
                    id: "c-connection-list.confirm-deletion.title"
                }),
                okText: intl.formatMessage({ id: "general.yes" }),
                okType: "danger",
                cancelText: intl.formatMessage({ id: "general.no" }),
                onOk() {
                    onDeleteConnection(id);
                },
                content: intl.formatMessage({
                    id: "c-connection-list.confirm-deletion.description"
                })
            });
        },
        [intl, onDeleteConnection]
    );

    return (
        <div className="c-connection-list">
            {connectionList.map(connection => (
                <ConnectionElement
                    agyoServices={agyoServices}
                    certification={certification}
                    connection={connection}
                    focus={focus}
                    getCertification={getCertification}
                    history={history}
                    key={connection.id}
                    itemServicesEnded={itemServicesEnded}
                    services={itemServices}
                    settingsPageEnabled={settingsPageEnabled}
                    showDeleteConfirm={showDeleteConfirm}
                    isAllowedToManage={isAllowedToManage}
                />
            ))}
        </div>
    );
};

ConnectionList.propTypes = {
    agyoServices: PropTypes.array,
    certification: PropTypes.object,
    connectionList: PropTypes.array,
    focus: PropTypes.string,
    getCertification: PropTypes.func.isRequired,
    history: PropTypes.object,
    isAllowedToManage: PropTypes.bool,
    itemServicesEnded: PropTypes.bool,
    itemServices: PropTypes.array,
    settingsPageEnabled: PropTypes.bool,
    onDeleteConnection: PropTypes.func
};

ConnectionList.defaultProps = {
    connectionList: []
};

export default ConnectionList;
