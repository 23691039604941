import styled from "styled-components";

export const Container = styled.div`
    margin: 0px;
    padding: 0px;
    position: relative;

    & .ant-select {
        border: 1px solid ${props => props.theme.colors.background.whiteSmoke};
        transition: border 0.3s ease;

        &:hover {
            border: 1px solid ${props => props.theme.colors.primary.richElectricBlue};
        }
    }

    & .ant-select,
    & .ant-select-selection {
        border-radius: 0px;
        height: 100%;
        width: 100%;
        padding: 0px;
        margin: 0px;
    }

    & .ant-select-selection {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${props => props.theme.colors.background.whiteSmoke};
        border: 0px;
        padding-left: 8px;
    }

    & .ant-select-arrow {
        display: none;
    }

    & .ant-select-selection-selected-value {
        padding-right: 24px;
    }

    .ant-checkbox {
        background: linear-gradient(
            315deg,
            ${props => props.theme.colors.primary.whiteSmoke},
            ${props => props.theme.colors.primary.whiteSmoke}
        ) !important;
        border-color: linear-gradient(
            315deg,
            ${props => props.theme.colors.primary.cyanProgress},
            ${props => props.theme.colors.primary.richElectricBlue}
        ) !important;
    }

    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-indeterminate .ant-checkbox-inner {
        background: linear-gradient(
            315deg,
            ${props => props.theme.colors.primary.cyanProgress},
            ${props => props.theme.colors.primary.richElectricBlue}
        ) !important;
    }
`;
