import React, { Component } from "react";
import PropTypes from "prop-types";

import { includes } from "ramda";
import { connect } from "react-redux";
import { compose } from "redux";

import { errorBoundary } from "../../../components/general/error-boundary";
import { PRIVACY_URL } from "../../../config";

import { FormattedMessage } from "react-intl";

import "./style.css";
import { validateJsonAsRpc } from "../../../utils/parse-json-rpc-string";
import { getCurrentCookieConsents } from "../../../utils/cookie-consent-utils";

class PrivacyView extends Component {
    static propTypes = {
        addSteps: PropTypes.func,
        auth: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
        language: PropTypes.string.isRequired,
        privacyConfig: PropTypes.object.isRequired
    };

    componentDidMount() {
        window.addEventListener("message", this.receiveMessage);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.receiveMessage);
    }

    receiveMessage = event => {
        const { auth, language } = this.props;
        /*let target = event.source;
         let domain = event.origin;*/
        if (event.data === "getToken") {
            document.getElementById("privacy").contentWindow.postMessage(
                {
                    cookieConsents: getCurrentCookieConsents(),
                    auth: auth,
                    language
                },
                PRIVACY_URL
            );
        } else {
            try {
                const json = validateJsonAsRpc(event.data);

                if (json.method === "createConnection") {
                    const { managerId } = json.params;

                    if (managerId) {
                        this.props.history.push(`/${managerId}/connections/create/newCompany`);
                    } else {
                        console.error("Unspecified managerId");
                    }
                }
            } catch (e) {
                console.error("Unrecognized event data", event.data, e);
            }
        }
    };

    renderUserNotEnable() {
        const { company, privacyConfig } = this.props;
        let serviceError = !includes("PRIVACY-FLOW", privacyConfig.services) ? (
            <FormattedMessage id="c-services-view-privacy.not-bought" />
        ) : (
            ""
        );
        let companyError =
            company.base.status.status === "VALIDATED" ? "" : <FormattedMessage id="general.company.not-active" />;
        return (
            <div className="user-not-enable">
                <div>
                    <i
                        className="fa fa-exclamation-triangle"
                        style={{
                            fontSize: "70px",
                            marginTop: "20%",
                            color: "#F44336"
                        }}
                    />
                    <br />
                    <br />
                    <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                        <FormattedMessage id="c-services-view-privacy.impossible-use" />
                    </span>
                    <br />
                    {companyError !== "" && <span>{companyError}</span>}
                    {serviceError !== "" && <span>{serviceError}</span>}
                </div>
            </div>
        );
    }

    render() {
        const { company, privacyConfig } = this.props;

        return company.base.status.status === "VALIDATED" && includes("PRIVACY-FLOW", privacyConfig.services) ? (
            <div className="c-privacy-view">
                <div>
                    <iframe
                        id="privacy"
                        className="iframe"
                        title="Privacy"
                        src={`${PRIVACY_URL}${company.base.id}`}
                        style={{
                            border: "0px",
                            left: "0px",
                            top: "0px",
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            zIndex: "1"
                        }}
                        width="100%"
                    />
                </div>
            </div>
        ) : (
            <div className="c-privacy-view">{this.renderUserNotEnable()}</div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        auth: state.auth,
        company: state.companies.data[props.match.params.item]
            ? state.companies.data[props.match.params.item].item
            : {},
        language: state.user.user ? state.user.user.profile.language : undefined,
        privacyConfig: state.services.active
    };
}

const composedHoc = compose(connect(mapStateToProps), errorBoundary);

export default composedHoc(PrivacyView);
