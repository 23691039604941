import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "@ts-digital/arc/antd/Button";
import TextField from "@ts-digital/arc/antd/TextField";
import ISO8601DayField from "@ts-digital/arc/antd/ISO8601DayField";
import { SelectStringField } from "@ts-digital/arc/antd/SelectField";
import { form } from "@ts-digital/conv-redux-form";
import moment from "moment";

import { contractDataSchema } from "../../../utils/form-schema/contract-data";
import DataConsent from "../data-consent";

import { ButtonContainer, ContractDataFormContainer, ConsentDataContainer } from "./styled";

const roles = [{ id: "OWNR" }, { id: "LEGL" }, { id: "PROS" }];

const momentDate = value => value && moment.utc(value);

const ContractData = ({ disabled, handleSubmit }) => {
    return (
        <Fragment>
            <ContractDataFormContainer>
                <TextField disabled={disabled} name={"firstName"} label={<FormattedMessage id="general.firstName" />} />
                <TextField disabled={disabled} name={"lastName"} label={<FormattedMessage id="general.lastName" />} />

                <SelectStringField
                    disabled={disabled}
                    getOptionKeyFromValue={value => {
                        return value === "" ? undefined : value;
                    }}
                    label={<FormattedMessage id="general.role" />}
                    name="role"
                    options={roles.map(r => {
                        return {
                            value: r.id,
                            label: <FormattedMessage id={`general.roles.${r.id}`} />
                        };
                    })}
                />

                <TextField disabled={disabled} name={"documentPlace"} label={<FormattedMessage id="general.place" />} />
                <ISO8601DayField
                    disabled={disabled}
                    name={"documentDate"}
                    label={<FormattedMessage id="general.date" />}
                    displayFormat="DD-MM-YYYY"
                    normalize={momentDate}
                />
            </ContractDataFormContainer>
            <ConsentDataContainer>
                <DataConsent disabled={disabled} />
            </ConsentDataContainer>
            <ButtonContainer>
                <Button disabled={disabled} onClick={handleSubmit} variant="primary">
                    <FormattedMessage id="general.continue" />
                </Button>
            </ButtonContainer>
        </Fragment>
    );
};

const formDefinition = {
    form: "contract-data-form",
    schema: contractDataSchema,
    enableReinitialize: true
};

export default form(formDefinition)(ContractData);
