import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { TimedToastNotifications } from "@ts-digital/arc/antd/TimedToastNotifications";

import { dismissNotification } from "../../actions/toastNotifications";

import { notificationsTemplate } from "./templates";

export const ToastNotifications = ({ dismissNotification, toast }) => (
    <TimedToastNotifications
        onRemoveNotification={id => dismissNotification(id)}
        notifications={toast.map(tn => {
            return {
                ...notificationsTemplate[tn.type],
                children: notificationsTemplate[tn.type].children({
                    ...tn
                }),
                id: tn.id
            };
        })}
        position={{ top: "45px", right: "20px" }}
    />
);

ToastNotifications.propTypes = {
    dismissNotification: PropTypes.func.isRequired,
    toast: PropTypes.array.isRequired
};

function mapStateToProps(state) {
    return {
        toast: state.timedNotifications.toast
    };
}

export default connect(mapStateToProps, { dismissNotification })(ToastNotifications);
