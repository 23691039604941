import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { compose } from "redux";

import { errorBoundary } from "../../../components/general/error-boundary";
import { TELEMATICI_CONSOLE } from "../../../config";
import { useFeature } from "../../../hooks/use-feature";
import MigratingChildApp from "../../portale-child-app/migrating";

const AdempimentiView = ({ auth, company, language, user }) => {
    const enableChildApp = useFeature("ADEMPIMENTI_CHILD_APP");

    return (
        <MigratingChildApp
            customLaunchData={{ oldUserId: user.profile.id }}
            endpoint={company.base.id}
            iframeConfigs={{
                data: { auth: { ...auth, id: user.profile.id }, language },
                id: "adempimenti",
                title: "Adempimenti"
            }}
            migrated={enableChildApp}
            url={TELEMATICI_CONSOLE}
        />
    );
};

AdempimentiView.propTypes = {
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired
};

function mapStateToProps(state, props) {
    return {
        auth: state.auth,
        company: state.companies.data[props.match.params.item]
            ? state.companies.data[props.match.params.item].item
            : {},
        language: state.user.user ? state.user.user.profile.language : undefined,
        user: state.user.user
    };
}

const composedHoc = compose(connect(mapStateToProps), errorBoundary);

export default composedHoc(AdempimentiView);
