import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const NotificationDrawerIconContainer = styled.div`
    display: inline-block;
    padding: 0 16px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    :hover {
        background-color: #5a6872;
    }

    ${props =>
        props.active &&
        css`
            background-image: linear-gradient(315deg, #00c3ea, #0090d1);
            text-shadow: 1px 1px 0 rgba(21, 41, 53, 0.24);
        `}
`;

export const NotificationDrawerIcon = styled(FontAwesomeIcon)`
    font-size: 24px;
`;
