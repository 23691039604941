import { combineReducers } from "redux";

import { getWaitingList } from "./read";
import { subscribeWaitinglist, deleteWaitinglist } from "./write";

const read = combineReducers({ getWaitingList });
const write = combineReducers({
    subscribeWaitinglist,
    deleteWaitinglist
});

export default combineReducers({ read, write });
