import styled from "styled-components";

import { Radio } from "antd";

export const SharingSettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 20px;
    width: 50%;
`;

export const SharingSettingsTitle = styled.span`
    font-weight: bold;
    margin: 0;
`;

export const RadioButton = styled(Radio)`
    display: block;
    height: 30px;
    line-height: 30px;
`;
