import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
    CollapseStyled,
    Features,
    FeaturesRow,
    FeaturesTableContentTd,
    FeaturesHeader,
    FeaturesHeaderCell,
    PanelHeaderDiv,
    PanelStyled
} from "./styled";

export const RolesSection = ({ enhancedRoles, localServicesEnabled, isDrawerOpen }) => {
    const [activeKey, setActiveKey] = useState("");
    const intl = useIntl();

    useEffect(() => {
        if (isDrawerOpen) {
            setActiveKey("");
        }
    }, [isDrawerOpen]);

    return (
        <CollapseStyled
            activeKey={activeKey}
            data-cy="user-details-drawer"
            onChange={key => {
                setActiveKey(key);
            }}
        >
            {enhancedRoles &&
                Object.keys(enhancedRoles).map(appId => (
                    <PanelStyled
                        data-cy={`user-details-drawer-roles-${appId}`}
                        disabled={enhancedRoles[appId].subService.length === 0}
                        header={
                            <PanelHeaderDiv>
                                {enhancedRoles[appId].description === "ADMIN" ||
                                enhancedRoles[appId].description === "OWNER" ||
                                enhancedRoles[appId].description === "EMPLOYEE" ? (
                                    <FormattedMessage
                                        data-cy={`user-details-drawer-header-${appId}-desc`}
                                        id={`c-registry-user.users-manage-company${enhancedRoles[appId].role}`}
                                    />
                                ) : (
                                    <>
                                        <div data-cy={`user-details-drawer-header-${appId}-desc`}>
                                            {enhancedRoles[appId].description}
                                        </div>
                                        <FormattedMessage
                                            data-cy={`user-details-drawer-roles-${appId}-role`}
                                            id={`c-registry-user.users-manage-${enhancedRoles[appId].role}`}
                                        />
                                    </>
                                )}
                            </PanelHeaderDiv>
                        }
                        key={appId}
                        showArrow={enhancedRoles[appId].subService.length > 0}
                    >
                        <Features data-cy={`user-details-drawer.header`}>
                            <FeaturesHeader>
                                <FeaturesHeaderCell>
                                    <FormattedMessage id="general.featurecode-name" />
                                </FeaturesHeaderCell>
                                <FeaturesHeaderCell>
                                    <FormattedMessage id="general.user-role" />
                                </FeaturesHeaderCell>
                            </FeaturesHeader>
                            <div data-cy={`user-details-drawer.body`}>
                                {enhancedRoles[appId].subService.map(feature => (
                                    <FeaturesRow key={feature.featureCode}>
                                        <FeaturesTableContentTd
                                            data-cy={`user-details-drawer-content-${appId}-${feature.featureCode}-desc`}
                                        >
                                            {feature.description}
                                        </FeaturesTableContentTd>
                                        <FeaturesTableContentTd
                                            data-cy={`user-details-drawer-content-${appId}-${feature.featureCode}-role`}
                                        >
                                            {intl.formatMessage({ id: `c-registry-user.users-manage-${feature.role}` })}
                                        </FeaturesTableContentTd>
                                    </FeaturesRow>
                                ))}
                            </div>
                        </Features>
                    </PanelStyled>
                ))}
            {localServicesEnabled ? (
                <PanelStyled
                    disabled={true}
                    showArrow={false}
                    header={
                        <PanelHeaderDiv>
                            <div>{intl.formatMessage({ id: "c-registry-user.local-services" })}</div>
                            <div>{intl.formatMessage({ id: "c-registry-user.users-manage-manager" })}</div>
                        </PanelHeaderDiv>
                    }
                />
            ) : null}
        </CollapseStyled>
    );
};
