// ADVERTISING DATA STRUCTURE
// title: advertising title
// description: advertising subtitle
// theme: theme color that select theme color defined in theme.js
// weight: priority with which advertising is shown
// circles: array of circle values
// image: image path and position

import { TS_DIGITAL_IMAGES } from "../../config";

export const advertisements = {
    title: "v-pre-login.advertising.intuitive.title",
    description: "v-pre-login.advertising.intuitive.description",
    theme: "blue",
    weight: 2,
    circles: [
        {
            value: "1,5+",
            valueType: "mld",
            description: "fatture gestite",
            size: "large"
        },
        {
            value: "1,5+",
            valueType: "mln",
            description: "aziende attive",
            size: "medium"
        },
        {
            value: "15+",
            description: "applicativi",
            size: "small"
        }
    ],
    image: {
        url: `${TS_DIGITAL_IMAGES}prelogin/Humaaans_Blue.svg`,
        top: "56px",
        right: "-48px"
    }
};
