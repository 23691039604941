import React, { Component } from "react";
import PropTypes from "prop-types";

import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./style.css";

import { Button, Form, Input } from "antd";

import Company from "../../components/manage-data/company";
import CompanyCreateModalConfirm from "./company/create-warning";

import { changePage, getCompanies, storePage, getStudioCompanies } from "../../actions/companies";
import { loadUser } from "../../actions/user";
import { listUsers } from "../../actions/company/users";

import { getLastReleaseNoteDate } from "../../actions/releasenotes";

const Search = Input.Search;

class ManageData extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: props.companies.homePage.searchText || "",
            mAddCompanyConfirm: false
        };
    }

    static propTypes = {
        changePage: PropTypes.func.isRequired,
        companies: PropTypes.object.isRequired,
        getCompanies: PropTypes.func.isRequired,
        getStudioCompanies: PropTypes.func.isRequired,
        getLastReleaseNoteDate: PropTypes.func.isRequired,
        lastReleaseNoteDate: PropTypes.object,
        isUserWithTooManyItems: PropTypes.bool.isRequired,
        intl: PropTypes.any.isRequired,
        listUsers: PropTypes.func.isRequired,
        loadUser: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        storePage: PropTypes.func
    };

    handleClose = value => {
        this.setState({ [value]: false });
    };

    handleSearch = value => {
        const { companies, getCompanies, storePage, getStudioCompanies, isUserWithTooManyItems } = this.props;

        this.setState({ search: value });

        if (value.length > 0 && value.length < 4) {
            return;
        }

        if (isUserWithTooManyItems && value.length === 0) {
            getStudioCompanies(value, 0, companies.pagination.size);
            storePage(companies.pagination.size, 0, value);
        } else {
            getStudioCompanies(value, 0, companies.pagination.size);
            getCompanies(value, 0, companies.pagination.size);
            storePage(companies.pagination.size, 0, value);
        }
    };

    render() {
        const {
            user,
            changePage,
            companies,
            getCompanies,
            getStudioCompanies,
            intl,
            isUserWithTooManyItems,
            getLastReleaseNoteDate,
            lastReleaseNoteDate,
            listUsers,
            storePage
        } = this.props;
        const { mAddCompanyConfirm, search } = this.state;

        return user.user && companies.total ? (
            <div className="c-manage-data">
                <div className="content-container">
                    <div
                        style={{
                            display: "flex",
                            padding: "24px",
                            paddingBottom: 0
                        }}
                    >
                        <Button
                            type="primary"
                            size="large"
                            id="add-azienda"
                            style={{ marginRight: "20px" }}
                            onClick={() => this.setState({ mAddCompanyConfirm: true })}
                        >
                            <FormattedMessage id="c-manage-data.add-company-button" />
                        </Button>
                        <Form.Item
                            className="search-aziende-container"
                            style={{ marginBottom: 0 }}
                            help={
                                search.length > 0 && search.length < 4 ? (
                                    <FormattedMessage id="c-manage-data.search-length-warning" />
                                ) : undefined
                            }
                        >
                            <Search
                                enterButton
                                size="large"
                                id="search-aziende"
                                placeholder={intl.formatMessage({
                                    id: "c-manage-data.search-placeholder"
                                })}
                                onSearch={this.handleSearch}
                                defaultValue={companies.homePage.searchText}
                            />
                        </Form.Item>
                    </div>
                    <Company
                        changePage={page => changePage(page)}
                        companies={companies}
                        getCompanies={getCompanies}
                        getStudioCompanies={getStudioCompanies}
                        searchText={search}
                        isUserWithTooManyItems={isUserWithTooManyItems}
                        user={user.user}
                        getLastReleaseNoteDate={getLastReleaseNoteDate}
                        lastReleaseNoteDate={lastReleaseNoteDate}
                        listUsers={listUsers}
                        storePage={storePage}
                    />
                </div>
                <CompanyCreateModalConfirm
                    handleClose={() => {
                        this.handleClose("mAddCompanyConfirm");
                    }}
                    handleContinue={() => {
                        this.handleClose("mAddCompanyConfirm");
                        this.props.history.push("/itemCreate");
                    }}
                    visibleState={mAddCompanyConfirm}
                />
            </div>
        ) : null;
    }
}

function mapStateToProps(state) {
    return {
        companyStatus: state.companies.updateItem.status,
        lastReleaseNoteDate: state.releasenotes.lastReleaseNoteDate
    };
}

export default connect(mapStateToProps, {
    changePage,
    getCompanies,
    getStudioCompanies,
    getLastReleaseNoteDate,
    listUsers,
    loadUser,
    storePage
})(injectIntl(withRouter(ManageData)));
