import { NOTIFICATION_READ_URL } from "../../config";
import { getRestCall } from "../../utils/rest-api-call";
import { getConnectionRestCall } from "../connections/read";
import { readNotification } from "./write";
import { getUserProfile } from "../../utils/state-utils/get-user-profile";
import { getAuthWithAppName } from "../auth";

if (!NOTIFICATION_READ_URL) {
    throw new Error("Undefined env NOTIFICATION_READ_URL");
}

export const GET_NOTIFICATION_START = "GET_NOTIFICATION_START";
export const GET_NOTIFICATION_ERROR = "GET_NOTIFICATION_ERROR";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";

export function getNotification(notificationId, markAsRead = false) {
    return async (dispatch, getState) => {
        dispatch({
            type: GET_NOTIFICATION_START,
            payload: { notificationId }
        });

        const requestUrl = `${NOTIFICATION_READ_URL}/notifications/${notificationId}`;

        try {
            const auth = getAuthWithAppName(getState().auth);
            const response = await getRestCall(
                requestUrl,
                auth,
                null,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                undefined,
                false
            );

            dispatch({
                type: GET_NOTIFICATION_SUCCESS,
                payload: {
                    notification: await processNotification(auth, dispatch, response.notification)
                }
            });

            if (markAsRead) {
                readNotification(notificationId, "")(dispatch, getState);
            }
        } catch (e) {
            dispatch({
                type: GET_NOTIFICATION_ERROR,
                payload: { error: e, notificationId }
            });
        }
    };
}

export const LIST_NOTIFICATIONS_START = "LIST_NOTIFICATIONS_START";
export const LIST_NOTIFICATIONS_ERROR = "LIST_NOTIFICATIONS_ERROR";
export const LIST_NOTIFICATIONS_SUCCESS = "LIST_NOTIFICATIONS_SUCCESS";

export function listNotifications(recipientId, page = 0, size = 25) {
    return async (dispatch, getState) => {
        const { loadingPages } = getState().notification.read.listNotifications.status;

        if (!loadingPages.includes(page)) {
            dispatch({
                type: LIST_NOTIFICATIONS_START,
                payload: { recipientId, page }
            });

            const getParams = { recipientId, page, size };
            const requestUrl = NOTIFICATION_READ_URL + "/notifications/";

            try {
                const auth = getState().auth.loginAuth;
                const response = await getRestCall(
                    requestUrl,
                    auth,
                    getParams,
                    dispatch,
                    auth.refreshToken,
                    getUserProfile(getState()),
                    recipientId,
                    false
                );

                dispatch({
                    type: LIST_NOTIFICATIONS_SUCCESS,
                    payload: {
                        ...response,
                        notifications: await Promise.all(
                            response.notifications.map(n => processNotification(auth, dispatch, n))
                        ),
                        page,
                        size
                    }
                });
            } catch (e) {
                dispatch({
                    type: LIST_NOTIFICATIONS_ERROR,
                    payload: { error: e, recipientId, page }
                });
            }
        }
    };
}

export const RESET_NOTIFICATIONS = "RESET_NOTIFICATIONS";

export function resetNotifications() {
    return dispatch => dispatch({ type: RESET_NOTIFICATIONS });
}

async function processNotification(auth, dispatch, notification) {
    //TODO spostare dato lato backend
    if (
        notification.name === "NEW_MANAGER_LINK_NOTIFICATION_REQUEST" ||
        notification.name === "CONNECTION_REMOVED_NOTIFICATION_REQUEST"
    ) {
        try {
            const connection = await getConnectionRestCall(auth, notification.resourceId, dispatch);
            notification.extraValues = {
                service: `general.${connection.connections[0].appId}${
                    connection.connections[0].featureCode != null ? "." + connection.connections[0].featureCode : ""
                }`,
                permission: `notification.permission.${connection.connections[0].permission}`
            };
        } catch (e) {
            // Prevents action death when the connection does not exist
            notification.extraValues = {};
        }
    } else {
        notification.extraValues = {};
    }

    return notification;
}
