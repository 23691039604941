export function downloadBase64(base64, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
        //Store Blob in IE
        fetch("data:application/pdf;base64," + base64)
            .then(res => res.blob())
            .then(blob => window.navigator.msSaveOrOpenBlob(blob, filename));
    } else {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = "data:application/pdf;base64," + base64;
        a.download = filename;
        a.click();
        a.remove();
    }
}

export function downloadCsv(content, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
        //Store Blob in IE
        fetch("data:text/csv;charset=utf-8," + content)
            .then(res => res.blob())
            .then(blob => window.navigator.msSaveOrOpenBlob(blob, filename));
    } else {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = "data:text/csv;charset=utf-8," + content;
        a.download = filename;
        a.click();
        a.remove();
    }
}

export function formatCell(value) {
    value = value || "";
    value = value.replace(/"/g, '""'); // Escape every double quote using an additional double quote
    value = "'" + value; // Prepend each cell field with a single quote
    return '"' + value + '"'; // Wrap each cell field in double quotes
}
