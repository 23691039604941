import styled from "styled-components";

import { Modal } from "antd";

import { Button } from "@ts-digital/arc/antd/Button";

export const StyledModal = styled(Modal)`
    text-align: center;

    & .ant-modal-close-x {
        color: #0090d1;
        font-size: 24px;
    }
`;

export const StyledIcon = styled.div`
    margin: 30px;
`;

export const StyledButton = styled(Button)`
    min-width: 200px;
    margin-left: 20px;
    margin-top: 50px;
`;

export const Title = styled.div`
    font-size: 32px;
    text-align: center;
    color: #152935;
    margin-top: 20px;
`;

export const Text = styled.div`
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #000000;
    margin-top: 20px;
`;

export const SubText = styled(Text)`
    font-weight: normal;
`;
