import React, { Component } from "react";
import PropTypes from "prop-types";

import { includes } from "ramda";
import { connect } from "react-redux";
import { compose } from "redux";

import { errorBoundary } from "../../components/general/error-boundary";
import { CDC_CONSOLE } from "../../config";

import { FormattedMessage } from "react-intl";

import "./style.css";
import DigitalBoxView from "../child-apps/digitalbox";
import { useFeature } from "../../hooks/use-feature";

class CdcView extends Component {
    static propTypes = {
        addSteps: PropTypes.func,
        auth: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
        cdcConfig: PropTypes.object.isRequired,
        language: PropTypes.string.isRequired
    };

    componentDidMount() {
        window.addEventListener("message", this.receiveMessage);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.receiveMessage);
    }

    receiveMessage = event => {
        const { auth, company, language } = this.props;
        if (event.data === "getToken") {
            window.frames[0].postMessage(
                {
                    id: auth.loginAuth.id,
                    token: auth.loginAuth.securityToken,
                    aziendaCF: company.base.id,
                    language
                },
                CDC_CONSOLE
            );

            window.top.location.href = CDC_CONSOLE;
        }
    };

    renderUserNotEnable() {
        const { company, cdcConfig } = this.props;
        // TODO verificare se l'abilitazione a DIGITALBOX possa garantire l'accesso all'app della console digitale
        let serviceError = !includes("DIGITALBOX", cdcConfig.services) ? (
            <FormattedMessage id="c-services-view-console-digitale.not-bought" />
        ) : (
            ""
        );
        let companyError =
            company && company.base && company.base.status && company.base.status.status === "VALIDATED" ? (
                ""
            ) : (
                <FormattedMessage id="general.company.not-active" />
            );
        return (
            <div className="user-not-enable">
                <div>
                    <i
                        className="fa fa-exclamation-triangle"
                        style={{
                            fontSize: "70px",
                            marginTop: "20%",
                            color: "#F44336"
                        }}
                    />
                    <br />
                    <br />
                    <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                        <FormattedMessage id="c-services-view-console-digitale.not-bought" />
                    </span>
                    <br />
                    <span>{companyError + serviceError}</span>
                </div>
            </div>
        );
    }

    render() {
        const { company, cdcConfig, history, auth, match, dboxAsChildapp } = this.props;

        if (
            company &&
            company.base &&
            company.base.status &&
            company.base.status.status === "VALIDATED" &&
            includes("DIGITALBOX", cdcConfig.services)
        ) {
            if (dboxAsChildapp) {
                return <DigitalBoxView />;
            } else {
                window.location.href = CDC_CONSOLE;
                return <div />;
            }
        } else {
            // check case of back from digital box to ts digital
            if (Object.keys(company).length === 0 && Object.keys(auth).length > 0) {
                history.push(`/${match.params.item}/dashboard`);
                return <div />;
            } else {
                return <div className="c-cdc-view">{this.renderUserNotEnable()}</div>;
            }
        }
    }
}

const mapStateToProps = (state, props) => ({
    auth: state.auth,
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    cdcConfig: state.services.active,
    language: state.user.user ? state.user.user.profile.language : ""
});

const composedHoc = compose(connect(mapStateToProps), errorBoundary);

export default composedHoc(props => <CdcView {...props} dboxAsChildapp={useFeature("DBOX_AS_CHILDAPP")} />);
