import { isEconomicActivityValid } from "@ts-digital/agyo-validations";

import { translateMessage } from "../../i18n";

export const companyAdditionalDataSchema = {
    type: "object",
    properties: {
        rea: {
            type: "string"
        },
        cciaa: {
            type: "string"
        },
        capitalStock: {
            type: "string"
        },
        liquidationState: {
            type: "string"
        },
        soleShareholder: { type: "string" },
        registerDescription: { type: "string" },
        registerCode: { type: "string" },
        registerProvince: { type: "string" },
        registerRegistrationDate: { type: "string" },
        balanceSheetDate: { type: "string" },
        economicActivity: {
            type: "string",
            validate: val =>
                val.length !== 0 && isEconomicActivityValid(translateMessage("general.form.invalid-data-format"))(val)
        },
        legalForm: {
            type: "string"
        }
    }
};
