import React from "react";

import { includes } from "lodash";
import { FormattedMessage } from "react-intl";

const UserNotEnabled = ({ company, peopleAppConfig }) => {
    // TODO verificare se l'abilitazione a PEOPLEAPP possa garantire l'accesso all'app della console digitale
    let serviceError = !includes("PEOPLEAPP", peopleAppConfig.services) ? (
        <FormattedMessage id="c-services-view-people-app.not-bought" />
    ) : (
        ""
    );
    let companyError =
        company.base.status.status === "VALIDATED" ? "" : <FormattedMessage id="general.company.not-active" />;
    return (
        <div className="user-not-enable">
            <div>
                <i
                    className="fa fa-exclamation-triangle"
                    style={{
                        fontSize: "70px",
                        marginTop: "20%",
                        color: "#F44336"
                    }}
                />
                <br />
                <br />
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                    <FormattedMessage id="c-services-view-people-app.impossible-use" />
                </span>
                <br />
                <span>
                    {companyError} {serviceError}
                </span>
            </div>
        </div>
    );
};

export default UserNotEnabled;
