import React from "react";
import PropTypes from "prop-types";

import { Tooltip } from "antd";
import { FormattedMessage } from "react-intl";

import { ConnectionService, ConnectionServiceStatus } from "./styled";

const ConnectionServiceTitle = ({ circleStatus, displayCircle, serviceName }) => (
    <ConnectionService>
        <div>{serviceName}</div>
        <div>
            {displayCircle && (
                <Tooltip title={<FormattedMessage id={`general.service.${circleStatus}`} defaultMessage={""} />}>
                    <ConnectionServiceStatus status={circleStatus}>
                        <i className={`fas fa-circle`} />
                    </ConnectionServiceStatus>
                </Tooltip>
            )}
        </div>
    </ConnectionService>
);

ConnectionServiceTitle.propTypes = {
    circleStatus: PropTypes.string,
    serviceName: PropTypes.string
};

ConnectionServiceTitle.defaultProps = {
    circleStatus: "loading",
    serviceName: "----"
};

export default ConnectionServiceTitle;
