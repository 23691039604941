import { translateMessage } from "../i18n";

export function signerStatus(type) {
    let color;
    let message;
    switch (type) {
        case "PDF_GENERATED":
        case "SUBMISSION_SUCCESS":
            color = "#FF9800";
            message = "";
            break;
        case "FINAL_SUCCESS":
        case "ASSIGNED_SUCCESS":
            color = "#4CAF50";
            message = "";
            break;
        case "ASSIGNED_WITHDRAW_SUCCESS":
        case "WITHDRAW_SUCCESS":
            color = "#818181";
            message = "";
            break;
        default:
            color = "#F44336";
            message = translateMessage("toast.error");
    }
    return { color, message };
}
