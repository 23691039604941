import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

class InfoDetails extends Component {
    static propTypes = {
        info: PropTypes.object
    };

    render() {
        const { info } = this.props;
        let roles = [];
        if (info.roles !== null) {
            info.roles.forEach(value => {
                roles.push({
                    ...value,
                    actionKey: value.actionKey === "OWNER" ? "master" : "common"
                });
            });
        }

        return (
            info && (
                <div style={{ fontSize: "16px" }}>
                    <span>
                        <FormattedMessage id="c-registry-user.info.id" />
                        <b>{info.profile.id} </b>
                    </span>
                    <br />
                    {info.profile.firstName && (
                        <Fragment>
                            <span>
                                <FormattedMessage id="c-registry-user.info.name" />
                                <b>{info.profile.firstName}</b>
                            </span>
                            <br />
                        </Fragment>
                    )}
                    {info.profile.lastName && (
                        <Fragment>
                            <span>
                                <FormattedMessage id="c-registry-user.info.surname" />
                                <b>{info.profile.lastName}</b>
                            </span>
                            <br />
                        </Fragment>
                    )}

                    <span>
                        <FormattedMessage id="c-registry-user.info.description" />
                        <b>{info.profile.description}</b>
                    </span>
                    <br />
                    <span>
                        <FormattedMessage id="c-registry-user.info.type" />
                        <b>
                            <FormattedMessage id={`general.user.${roles[0].actionKey}`} />
                        </b>
                    </span>
                </div>
            )
        );
    }
}

export default InfoDetails;
