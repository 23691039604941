import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Switch, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/pro-regular-svg-icons";
import LinkButton from "../../../general/link-button";

import { CctSpecContainer, Description, Toggle, ServiceTitle, ConfigureButtonContainer } from "./styled";

export const CctSpecActivateToggle = ({ disabled, id, name, specs, onChange, saveConfigLoading, cctConfigManaged }) => {
    const handleChange = useCallback(
        e => {
            const nameArray = Array.isArray(name) ? name : [name];
            onChange(e, nameArray, id);
        },
        [name, onChange, id]
    );

    cctConfigManaged = cctConfigManaged || { configs: [], totalElements: 0, totalPages: 0 };
    let isActive = false;
    let manageCompanyVisible = false;

    if (Array.isArray(name)) {
        isActive = specs && name.some(n => specs[n] && specs[n].active);
        manageCompanyVisible = name.includes("EIP") && cctConfigManaged.totalElements > 0 && !disabled;
    } else {
        isActive = specs && specs[name] && specs[name].active;
        manageCompanyVisible = name === "EIP" && cctConfigManaged.totalElements > 0 && !disabled;
    }
    return (
        <CctSpecContainer>
            <ServiceTitle>
                <FormattedMessage id={id} />
            </ServiceTitle>
            <Description>
                <FormattedMessage id={"c-cct-item-services-active-service"} />
            </Description>
            <Tooltip
                title={<FormattedMessage id="cct-activate-service-configure-item-disabled-message" />}
                trigger={disabled ? "hover" : ""}
            >
                <Toggle>
                    <Switch
                        checked={isActive}
                        onChange={handleChange}
                        loading={saveConfigLoading}
                        disabled={disabled}
                    />
                </Toggle>
            </Tooltip>
            {manageCompanyVisible ? (
                <ConfigureButtonContainer>
                    <LinkButton
                        size="small"
                        disabled={!isActive}
                        kind="tertiary"
                        to="configure-managed-items-personal/"
                    >
                        <FontAwesomeIcon icon={faCog} />{" "}
                        <FormattedMessage id={"c-cct-item-managed-items-personal-link"} />
                    </LinkButton>
                </ConfigureButtonContainer>
            ) : null}
        </CctSpecContainer>
    );
};

CctSpecActivateToggle.propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
    specs: PropTypes.object,
    onChange: PropTypes.func,
    saveConfigLoading: PropTypes.bool,
    cctConfigManaged: PropTypes.object
};

export default CctSpecActivateToggle;
