import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { Redirect } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";

import SuccessPage from "../../../components/general/accordion-stepper/success-page";
import { errorBoundary } from "../../../components/general/error-boundary";

class OfficesSuccess extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        updateItemStatus: PropTypes.object.isRequired
    };

    render() {
        const { history, match, updateItemStatus } = this.props;

        return updateItemStatus.ended ? (
            <Fragment>
                <SuccessPage
                    onBackButtonClick={() => {
                        history.push(`/${match.params.item}/dashboard`);
                    }}
                    title={"c-offices-success.title"}
                    subtitle={"c-offices-success.subtitle"}
                    buttonText={"c-item-success.button"}
                />
            </Fragment>
        ) : (
            <Redirect to={`/${match.params.item}/dashboard`} />
        );
    }
}

const mapStateToProps = state => {
    return {
        updateItemStatus: state.companies.updateItem.status
    };
};

const composedHoc = compose(connect(mapStateToProps, {}), errorBoundary);

export default composedHoc(OfficesSuccess);
