import React, { useCallback } from "react";

import Elevio from "elevio/lib/react";
import { isNil } from "ramda";
import { useIntl } from "react-intl";

const elevioTranslations = {
    search: "Cerca",
    error: "Ti preghiamo di riprovare",
    sorry: "Spiacente",
    related_articles: "Suggerimenti",
    no_results: "Nessun risultato",
    help: "Help Center",
    more: "Ancora",
    skip: "Salta",
    feedback_improve: "How can it be improved?",
    feedback_helpful: "Ti è stato utile?",
    feedback_type: "Type your feedback here",
    submit: "Submit",
    submit_anon: "Are you sure you want to send this anonymously?",
    unsent_enquiry: "You have an unsent enquiry.",
    take_me_back: "Take me back",
    thankyou: "Grazie",
    discard: "Scarta",
    general: {
        back: "Indietro",
        close: "Chiudi",
        search: "Cerca",
        error: "Ti preghiamo di riprovare",
        sending: "Invio in corso",
        sent: "Inviato",
        more: "Ancora",
        thankyou: "Grazie",
        collapse: "Collassa"
    },
    inline: {
        sorry: "Spiacente",
        article_not_found: "Impossibile trovare il suggerimento"
    },
    loading: {
        loading_article: "Caricamento articoli",
        loading_articles: "Caricamento articles",
        loading_feed: "Caricamento feed",
        loading_status: "Caricamento site status",
        loading_ticket: "Caricamento ticket",
        loading_tickets: "Caricamento tickets",
        reloading_ticket: "Caricamento ticket"
    },
    modules: {
        articles: {
            related_articles: "Related articles",
            similar_articles: "Similar articles",
            view_all: "View all articles",
            no_results: "No results"
        },
        customform: {
            submit: "Invia",
            thankyou: "Grazie"
        },
        rss: {
            read_more: "Leggi ancora"
        },
        status: {
            incident_report: "Incident Report",
            past_incidents: "Past Incidents",
            scheduled_maintenance: "Scheduled Maintenance",
            summary: "Riepilogo",
            unresolved_incidents: "Unresolved Incidents",
            updates: "Aggiornamenti",
            view_full: "Visualizza full status page",
            view_incident: "Visualizza incident report"
        },
        support: {
            create_new_ticket: "Crea un nuovo ticket",
            submit: "Invia ticket",
            describe:
                "Descrivere il quesito qui indicando sempre partita iva o codice fiscale del soggetto per il quale si sta chiedendo supporto.",
            reply_placeholder: "Rispondi a questo ticket ...",
            send_reply: "Invia risposta",
            no_tickets: "Al momento non sono stati aperti ticket",
            back_to_tickets: "Indietro ai tuoi ticket",
            deflect:
                "Prima di inviare un ticket, controlla se la tua domanda ha già avuto risposta nella nostra knowledge base.",
            view_articles: "Visualizza gli articoli di help",
            thankyou: "Grazie, ti ricontatteremo a breve",
            delayed_appearance: "(Potrebbe essere necessario attendere alcuni minuti)",
            subject: "Motivo ",
            message: "Messaggio",
            your_name: "Nome",
            email: "Inserisci l'indirizzo email (non utilizzare indirizzi PEC)",
            surname: "Cognome",
            new: "Nuovo",
            unread: "Non letti",
            open: "Aperto",
            pending: "In sospeso",
            resolved: "Risolto",
            hold: "In attesa",
            solved: "Risolto",
            closed: "Chiuso",
            deleted: "Cancellato"
        }
    }
};

const userVoiceTranslations = {
    contact_menu_label: "Come posso aiutarti?",
    smartvote_menu_label: "Aiutaci a migliorare",
    // Contact form
    contact_title: "Come posso aiutarti?",
    contact_message_placeholder: "Non hai trovato risposta nelle FAQ? Scrivici e ti ricontatteremo",
    contact_skip_instant_answers_button: "Salta alla prossima domanda",
    contact_details_title: "Dettagli aggiuntivi",
    contact_submit_button: "Invia messaggio",
    contact_success_title: "Messaggio inviato!",
    contact_success_body: "Ti terremo aggiornato",
    // SmartVote
    smartvote_title: "Cosa dovremmo migliorare?",
    post_suggestion_menu_label: "Invia la tua idea",
    smartvote_pick_button: "Pick",
    smartvote_subscribe_button: "Pick + Subscribe",
    suggestion_subscribe_title: "Great stuff!",
    suggestion_subscribe_body: "Vuoi aggiornare questa idea?",
    suggestion_subscribed_body: "Ti terremo aggiornato sui progressi della tua proposta.",
    smartvote_success_title: "Your pick",
    // Posting a suggestion
    post_suggestion_title: "Condividi la tua idea",
    post_suggestion_body: "Aiutaci a migliorare il servizio. I tuoi suggerimenti sono importanti",
    post_suggestion_message_placeholder: "Descrivi la tua idea",
    post_suggestion_skip_instant_answers_button: "Ho un'altra idea",
    post_suggestion_details_title: "Ulteriori dettagli",
    suggestion_title_label: "Titolo",
    suggestion_title_placeholder: "Sintetizza il tuo suggerimento",
    suggestion_category_label: "Categoria",
    post_suggestion_submit_button: "Sottoponi la tua idea",
    post_suggestion_success_title: "Grazie!",
    post_suggestion_success_body: "Il tuo suggerimento è stato postato %{link:feedback forum}.",
    // Satisfaction
    satisfaction_message_title: "Perchè hai dato questo voto?",
    satisfaction_message_placeholder: "Inserisci il tuo suggerimento",
    satisfaction_success_title: "Grazie!",
    satisfaction_success_body: "Abbiamo a cuore i tuoi suggerimenti.",
    // Instant Answers
    instant_answers_title: "Può esserti utile uno di questi suggerimenti?",
    instant_answers_related_articles_label: "Articoli correlati",
    instant_answers_related_suggestions_label: "Idee correlate",
    article_pick_button: "Questo risponde alla mia domanda",
    suggestion_subscribe_button: "Lo voglio!",
    contact_confirm_title: "Splendido!!!",
    contact_confirm_body: "Vuoi ancora inviarci un messaggio?",
    post_suggestion_confirm_title: "Confermo.",
    post_suggestion_confirm_body: "Vuoi ancora inviarci la tua idea?",
    instant_answers_success_title: "Grazie!",
    instant_answers_success_body: "Abbiamo a cuore i tuoi suggerimenti.",
    // Misc
    email_address_label: "Your email address",
    email_address_placeholder: "Email address"
};

function initializeUserVoice(userVoiceScript, user) {
    let showWidget;

    if (isNil(UserVoice)) {
        showWidget = true;
    }
    try {
        userVoiceScript.push([
            "set",
            {
                trigger_position: "bottom-right",
                contact_enabled: false,
                mode: "satisfaction",
                locale: "it",
                menu: true,
                smartvote_enabled: false,
                post_suggestion_enabled: false,
                ticket_custom_fields: {
                    Fatture: "Invio fatture"
                },
                trigger_color: "#FFF",
                trigger_background_color: "#FFFFFF",
                strings: userVoiceTranslations
            }
        ]);

        if (user) {
            userVoiceScript.push([
                "identify",
                {
                    email: user.profile.id, // User’s email address
                    name: user.profile.lastName + " " + user.profile.firstName // User’s real name
                }
            ]);
        }

        userVoiceScript.push(["addTrigger"]);
        if (showWidget && user && user.profile.id) {
            userVoiceScript.push(["show"]);
        }
    } catch (e) {
        console.log(e);
    }
}

const UserVoice = ({ user, userVoiceScript }) => {
    const { formatMessage } = useIntl();

    const handleModuleOpen = useCallback(
        moduleId => {
            if (moduleId === 11) {
                try {
                    document.getElementsByClassName("uv-icon")[0].click();
                } catch (e) {
                    alert(
                        formatMessage({
                            id: "general.uservoice.accept-cookie-to-vote"
                        })
                    );
                }
            }
        },
        [formatMessage]
    );

    if (user) {
        window._elev.setUser({
            first_name: user.profile.firstName,
            last_name: user.profile.lastName,
            email: user.profile.id,
            user_hash: user.elevioUserHash
        });
    }

    if (user && userVoiceScript) {
        initializeUserVoice(userVoiceScript, user);
    }

    return (
        <Elevio
            accountId="593910444ac86"
            onLoad={() => {
                if (window._elev) {
                    window._elev.events = { moduleOpened: handleModuleOpen };
                }
            }}
            translations={elevioTranslations}
        />
    );
};

export default UserVoice;
