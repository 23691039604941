import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { AutoComplete, Icon, Input } from "antd";
import { Button } from "@ts-digital/arc/antd";
import { FormattedMessage, FormattedHTMLMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router";

import ConnectionOption from "../connection-option";
import "./style.css";

const Option = AutoComplete.Option;

const mapItemsToOptions = (value, items, linkedItems, itemsLoading, itemsLoaded, intl) => {
    if (value && items && items.length === 0 && !itemsLoading && itemsLoaded) {
        return [
            <Option key="no-res" value="no-res">
                <div>
                    <FormattedMessage id="general.no-result" />
                </div>
            </Option>
        ];
    } else {
        return items.map(item => {
            const itemBase = item.item.base;
            const isValidated = itemBase.status.status === "VALIDATED";
            const validatedMessage = !isValidated
                ? intl.formatMessage({
                      id: "c-connections-filter.bar.disabled"
                  })
                : null;
            return (
                <Option key={itemBase.id} value={itemBase.id} disabled={!isValidated}>
                    <ConnectionOption
                        elementName={
                            itemBase.details.classifier === "PERSON"
                                ? `${itemBase.details.firstName} ${itemBase.details.lastName}`
                                : itemBase.details.description
                        }
                        isLinked={linkedItems.list.includes(itemBase.id)}
                        validatedMessage={validatedMessage}
                        linkedItemsSearchEnded={linkedItems.status.ended}
                        intl={intl}
                    />
                </Option>
            );
        });
    }
};

export const ConnectionFilter = ({
    findItemsStatus,
    handleFullText,
    handleSelect,
    items,
    linkedItems,
    onCreateItem,
    resetFilter
}) => {
    const history = useHistory();
    const params = useParams();
    const intl = useIntl();
    const [value, setValue] = useState("");

    useEffect(() => {
        if (value.length > 0) {
            handleFullText(value);
        } else {
            resetFilter();
        }
    }, [handleFullText, resetFilter, value]);

    const selectCallback = useCallback(
        value => {
            if (value !== "no-res") {
                if (linkedItems.list.includes(value)) {
                    handleSelect(value);
                } else {
                    history.push(`/${params.item}/connections/create/existingCompany/${value}`);
                }
            }
        },
        [handleSelect, history, linkedItems, params.item]
    );

    let errorMessage = "";
    if (value && value.length !== 11 && value.length !== 16) {
        errorMessage = intl.formatMessage({
            id: "c-connections-filter.bar.error"
        });
    }

    const itemsLoading = findItemsStatus.started;
    const itemsLoaded = findItemsStatus.ended || findItemsStatus.success;

    return (
        <div className="c-connections-filter">
            <div className="c-connections-filter-item l">
                <div className="c-connections-filter-item l center">
                    <FormattedHTMLMessage id="c-connections-filter-bar.subtitle" />
                </div>
                <div className="c-connections-filter-item l left">
                    <FormattedMessage id="c-connections-filter-bar.label" />
                </div>
                <AutoComplete
                    dataSource={mapItemsToOptions(value, items, linkedItems, itemsLoading, itemsLoaded, intl)}
                    dropdownClassName="c-connections-autocomplete-dropdown"
                    onSearch={setValue}
                    onSelect={selectCallback}
                    optionLabelProp="value"
                    placeholder={intl.formatMessage({
                        id: "c-connections-filter.bar.placeholder"
                    })}
                    size="large"
                    style={{ width: "100%" }}
                    value={value}
                >
                    <Input suffix={<Icon type="search" className="certain-category-icon" />} />
                </AutoComplete>

                <div className="c-connections-filter-item error">{errorMessage}</div>
                <div className="c-connections-filter-item l center">
                    <FormattedMessage id="c-connections-filter-bar.or" />
                </div>
                <div className="c-connections-filter-item l center">
                    <Button variant="primary" onClick={onCreateItem}>
                        <FormattedMessage id="c-connections-filter-bar.request-connection" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

ConnectionFilter.propTypes = {
    findItemsStatus: PropTypes.object,
    handleFullText: PropTypes.func,
    handleSelect: PropTypes.func,
    items: PropTypes.array,
    linkedItems: PropTypes.object,
    resetFilter: PropTypes.func,
    setWarningModalVisible: PropTypes.func
};

export default ConnectionFilter;
