import React from "react";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Spin } from "antd";

import Routes from "./views";
import "antd/dist/antd.min.css";
import "./index.css";
import store from "./reducers";
import "./utils/toggleFatturazione2Links";
import { persistor } from "./reducers/index";

import { SpinContainer } from "./styled";
import { ThemeProvider } from "@ts-digital/vrc";
import { FeatureContextProvider } from "./feature-context";
import checkBrowser from "check-browser";
import NotSupported from "./components/general/not-supported/not-supported";
import { HashRouter } from "react-router-dom";
import { EmbedProvider } from "./embed-context";

const App = () => {
    const isBrowserAllowed = checkBrowser({
        chrome: true,
        firefox: true,
        safari: true,
        others: false
    });

    return (
        <Provider store={store}>
            <PersistGate
                loading={
                    <SpinContainer>
                        <Spin size="large" />
                    </SpinContainer>
                }
                persistor={persistor}
            >
                {isBrowserAllowed ? (
                    <HashRouter>
                        <EmbedProvider>
                            <ThemeProvider>
                                <FeatureContextProvider>
                                    <Routes />
                                </FeatureContextProvider>
                            </ThemeProvider>
                        </EmbedProvider>
                    </HashRouter>
                ) : (
                    <NotSupported />
                )}
            </PersistGate>
        </Provider>
    );
};

export default App;
