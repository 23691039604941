import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { Col, Layout, Row } from "antd";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

import { getChannelsList } from "../../../actions/ncs/read";

import PageTitle from "../../../components/general/page-title";
import { Spinner } from "../../../components/general/spinner";
import ChannelsEmail from "../../../components/notifications-center/channels-email";
import Sidebar from "../../../components/notifications-center/sidebar";

import { getNcsBasePath } from "../../../utils/route";

import { PageContainer, PageContent, Section, Title } from "./styled.js";

export const ChannelsView = ({
    channels,
    channelsStatus,
    itemId,
    groupId,
    match: { params },
    ncsId,
    preference,
    getChannelsList
}) => {
    const history = useHistory();
    const { appCode, channelType, item, selectedItemId, templateType } = params;

    useEffect(() => {
        if (ncsId) {
            getChannelsList(ncsId, groupId, itemId);
        }
    }, [getChannelsList, groupId, itemId, ncsId]);

    return (
        <>
            {!preference ? (
                <Redirect to={`${getNcsBasePath(selectedItemId, item)}/preferences`} />
            ) : (
                <Layout>
                    <PageTitle
                        title={<FormattedMessage id="c-notifications-center.title1" />}
                        onBack={() => history.push(`${getNcsBasePath(selectedItemId, item)}/preferences`)}
                    />
                    <PageContainer>
                        <Spinner loading={channelsStatus.started}>
                            <PageContent>
                                <Row gutter={32}>
                                    <Col xs={{ span: 10, offset: 1 }} xl={{ span: 5, offset: 0 }}>
                                        <Sidebar itemId={itemId} />
                                    </Col>
                                    <Col
                                        xs={{ span: 22, offset: 1 }}
                                        xl={{ span: 19, offset: 0 }}
                                        xxl={{ span: 16, offset: 1 }}
                                    >
                                        <Title>
                                            <FormattedMessage
                                                id={`c-notifications-center.${appCode.toLowerCase()}.name`}
                                            />
                                            {" - "}
                                            {preference.name}
                                        </Title>
                                        <Section>
                                            {(() => {
                                                switch (channelType) {
                                                    case "EMAIL":
                                                        return (
                                                            <ChannelsEmail
                                                                channel={channels.find(
                                                                    channel =>
                                                                        channel.type === "EMAIL" &&
                                                                        channel.appCode === appCode &&
                                                                        channel.templateType === templateType
                                                                )}
                                                                defaultChannel={channels.find(
                                                                    channel =>
                                                                        channel.type === "EMAIL" &&
                                                                        !channel.appCode &&
                                                                        !channel.templateType
                                                                )}
                                                                preference={preference}
                                                                itemId={itemId}
                                                                groupId={groupId}
                                                            />
                                                        );
                                                    default:
                                                        return null;
                                                }
                                            })()}
                                        </Section>
                                    </Col>
                                </Row>
                            </PageContent>
                        </Spinner>
                    </PageContainer>
                </Layout>
            )}
        </>
    );
};

ChannelsView.propTypes = {
    channels: PropTypes.array.isRequired,
    channelsStatus: PropTypes.shape({
        started: PropTypes.bool,
        updating: PropTypes.bool,
        error: PropTypes.bool,
        ended: PropTypes.bool
    }),
    itemId: PropTypes.string,
    groupId: PropTypes.string,
    ncsId: PropTypes.string.isRequired,
    preference: PropTypes.shape({
        appCode: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    }),
    getChannelsList: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
    const selectedCompany = state.companies.data[props.match.params.selectedItemId]
        ? state.companies.data[props.match.params.selectedItemId].item.base
        : {};
    const appPreferences = state.ncs.preferences.data.find(
        application => application.appCode === props.match.params.appCode
    )
        ? state.ncs.preferences.data.find(application => application.appCode === props.match.params.appCode).preferences
        : [];

    return {
        channels: state.ncs.channels.data,
        channelsStatus: state.ncs.channels.status,
        groupId: selectedCompany.ncsId,
        itemId: selectedCompany.id,
        ncsId: state.user.user.profile.ncsId,
        preference: appPreferences.find(preference => preference.type === props.match.params.templateType)
    };
};

export default connect(mapStateToProps, { getChannelsList })(ChannelsView);
