import { Select } from "@ts-digital/vrc";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Container = styled.div`
    flex: 1;
    display: flex;
    min-height: 100%;
`;

export const CompanySelectionContainer = styled.div`
    display: flex;
    padding: 24px;
    flex-direction: column;
    gap: 24px;
    background: #f5f8fa;
    border-right: 1px solid #b9c8d0;
    width: 428px;
`;

export const EditPermissionContainer = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 24px;
    gap: 24px;
`;

export const FilterTitle = styled.div`
    color: ${props => props.theme.colors.text.diamond};
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
`;

export const ChipsContainer = styled.div`
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
    grid-gap: 16px;
`;

export const Chip = styled.div`
    display: flex;
    align-items: center;
    height: 32px;
    width: fit-content;
    background: ${props => props.theme.colors.secondary.diamond};
    border-radius: 16px;
    border: 1px solid ${props => props.theme.colors.secondary.diamond};
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 14px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.25px;
    padding-left: 8px;
    padding-right: 8px;

    & > :first-child {
        margin-right: 8px;
    }

    :hover {
        border: 1px solid ${props => props.theme.colors.primary.richElectricBlue};
    }
`;

export const ActiveItem = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 16px;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.11px;
    line-height: 24px;
    margin-top: 40px;
    margin-bottom: 32px;
`;

export const OverallPermissionsSelect = styled(Select)`
    max-width: 300px;
    width: 100%;
    flex-shrink: 0;
`;

export const OverallPermissionsSelectLoaderContainer = styled.div`
    max-width: 300px;
    width: 100%;
    flex-shrink: 0;
`;

export const MassiveActionContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 21px;
    align-items: flex-end;
`;

export const TitleAndToggleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const SectionTitle = styled.div`
    color: #1f292e;
    font-size: 16px;
    line-height: 24px;
`;

export const ToggleContainer = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;

    & > :first-child {
        flex-shrink: 0;
    }
`;

export const ToggleLabel = styled.div`
    color: var(--text-default, #1f292e);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const InfoIcon = styled(FontAwesomeIcon)`
    color: rgb(21, 41, 53);
    font-size: 12px;
    text-align: center;
    margin-left: 4px;
`;
