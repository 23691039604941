import React from "react";

import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import moment from "moment";

import {
    ExpiredCctCredentialsMessageContainer,
    LinkContainer,
    ResetCredentialButtonContainer,
    TsPayButtonContainer,
    TsPayWarningContainer
} from "./styled";
import { CCT_CONSOLE } from "../../../config";
import { Hyperlink } from "../../../components/storage/cct/styled";
import { Button } from "@ts-digital/vrc";

export const notificationsTemplate = {
    FORGOT_PASSWORD_SUCCESS: {
        children: () => <FormattedMessage id="v-forgot-password-success-description" />,
        duration: 10000,
        variant: "info"
    },
    UPDATED_VERSION: {
        children: () => (
            <div>
                <FormattedMessage id="c-timed-toast.content" />
                <LinkContainer onClick={() => window.location.reload(true)}>
                    <FormattedMessage id="c-timed-toast.update" />
                </LinkContainer>
            </div>
        ),
        duration: 2147483647,
        variant: "info"
    },
    EXPIRING_PASSWORD_WARN: {
        children: ({ expiringDate }) =>
            expiringDate === 0 ? (
                <FormattedMessage id="c-timed-toast.template.expiringPassword.today" values={{ expiringDate }} />
            ) : expiringDate === 1 ? (
                <FormattedMessage id="c-timed-toast.template.expiringPassword.tomorrow" values={{ expiringDate }} />
            ) : (
                <FormattedMessage id="c-timed-toast.template.expiringPassword" values={{ expiringDate }} />
            ),
        duration: 2147483647,
        variant: "info"
    },
    COPY_AUTH_TOKEN_SUCCESS: {
        children: () => <FormattedMessage id="c-timed-toast.template.copyAuthToken" />,
        duration: 5000,
        variant: "info"
    },
    SERVICE_ACTIVE_SUCCESS: {
        children: ({ service }) => (
            <div>
                <b>
                    <FormattedMessage id={service} />:{` `}
                </b>
                <FormattedMessage id="c-timed-toast.template.cctActiveService" />
            </div>
        ),
        duration: 5000,
        variant: "success"
    },
    SERVICE_DISABLE_SUCCESS: {
        children: ({ service }) => (
            <div>
                <b>
                    <FormattedMessage id={service} />:{` `}
                </b>
                <FormattedMessage id="c-timed-toast.template.cctDisableService" />
            </div>
        ),
        duration: 5000,
        variant: "success"
    },
    ENABLE_CCT_CONFIG_MANAGED_SUCCESS: {
        children: () => (
            <div>
                <b>
                    <FormattedMessage id={"c-services-view.ARC.name"} />:{` `}
                </b>
                <FormattedMessage id="c-timed-toast.template.cctActiveService" />
            </div>
        ),
        duration: 5000,
        variant: "success"
    },
    DISABLE_CCT_CONFIG_MANAGED_SUCCESS: {
        children: () => (
            <div>
                <b>
                    <FormattedMessage id={"c-services-view.ARC.name"} />:{` `}
                </b>
                <FormattedMessage id="c-timed-toast.template.cctDisableService" />
            </div>
        ),
        duration: 5000,
        variant: "success"
    },
    UPDATE_NCS_SINGLE_CHANNEL_SUCCESS: {
        children: () => (
            <div>
                <strong>
                    <FormattedMessage id="c-timed-toast.template.ncs.channels.title" />
                </strong>
                <FormattedMessage id="c-timed-toast.template.ncs.channels.success" />
            </div>
        ),
        duration: 6000,
        variant: "success"
    },
    UPDATE_NCS_SINGLE_CHANNEL_ERROR: {
        children: () => (
            <div>
                <strong>
                    <FormattedMessage id="c-timed-toast.template.ncs.channels.title" />
                </strong>
                <FormattedMessage id="c-timed-toast.template.ncs.channels.error" />
            </div>
        ),
        duration: 6000,
        variant: "error"
    },
    DELETE_NCS_SINGLE_CHANNEL_SUCCESS: {
        children: () => (
            <div>
                <strong>
                    <FormattedMessage id="c-timed-toast.template.ncs.channels.title" />
                </strong>
                <FormattedMessage id="c-timed-toast.template.ncs.channels.success" />
            </div>
        ),
        duration: 6000,
        variant: "success"
    },
    DELETE_NCS_SINGLE_CHANNEL_ERROR: {
        children: () => (
            <div>
                <strong>
                    <FormattedMessage id="c-timed-toast.template.ncs.channels.title" />
                </strong>
                <FormattedMessage id="c-timed-toast.template.ncs.channels.error" />
            </div>
        ),
        duration: 6000,
        variant: "error"
    },
    CCT_CREDENTIALS_EXPIRATION_NOTIFICATION: {
        children: ({ expirationDate }) => {
            const daysLeft = moment(expirationDate).diff(moment(), "days");
            return (
                <ExpiredCctCredentialsMessageContainer>
                    <strong>Attenzione: </strong>
                    {daysLeft <= 5 && daysLeft > 0 && (
                        <FormattedHTMLMessage
                            id="cct-exp-date-days-left-toast-message"
                            values={{ daysLeft: moment(expirationDate).fromNow(true) }}
                        />
                    )}
                    {daysLeft < 0 && <FormattedHTMLMessage id="cct-expired-credential-toast-message" />}
                    <ResetCredentialButtonContainer>
                        <a href={`${CCT_CONSOLE}ResetPswd.asp`} target="_blank" rel="noopener noreferrer">
                            <Hyperlink>
                                <FormattedMessage id="cct-reset-archive-credentials" />
                            </Hyperlink>
                        </a>
                    </ResetCredentialButtonContainer>
                </ExpiredCctCredentialsMessageContainer>
            );
        },
        duration: 5000,
        variant: "warning"
    },
    TSPAY_CONNECTION_REQUEST_SUCCESS: {
        children: () => (
            <div>
                <FormattedMessage id="tspay-toast-confirm" />
            </div>
        ),
        duration: 6000,
        variant: "success"
    },
    CCT_SMART_INITIALIZED: {
        children: () => (
            <div>
                <FormattedMessage id="c-cct-provisioning-download-pdf-form.confirm-toast" />
            </div>
        ),
        duration: 6000,
        variant: "success"
    },
    CCT_SMART_ERROR: {
        children: () => (
            <div>
                <strong>
                    <FormattedMessage id="c-cct-provisioning-download-pdf-form.error-toast-1" />
                </strong>
                <br />
                <FormattedMessage id="c-cct-provisioning-download-pdf-form.error-toast-2" />
            </div>
        ),
        duration: 6000,
        variant: "error"
    },
    TS_PAY_INFO: {
        children: ({ redirectFun }) => (
            <TsPayWarningContainer>
                <strong>
                    <FormattedMessage id="apps.TSPAY.title" />
                    {": "}
                </strong>
                <FormattedMessage id="tspay-configuration-warning-message" />
                <br />
                <TsPayButtonContainer>
                    <Button onClick={() => redirectFun()}>
                        <FormattedMessage id="tspay-configure-button" />
                    </Button>
                </TsPayButtonContainer>
            </TsPayWarningContainer>
        ),
        duration: 6000,
        variant: "info"
    },
    EDIT_ROLES_SUCCESS: {
        children: () => (
            <div>
                <b>
                    <FormattedMessage id="c-user-roles-table.edit.success" />
                </b>
            </div>
        ),
        duration: 5000,
        variant: "success"
    },
    EDIT_ROLES_ERROR: {
        children: () => (
            <div>
                <b>
                    <FormattedMessage id="c-user-roles-table.edit.error" />
                </b>
            </div>
        ),
        duration: 5000,
        variant: "error"
    }
};
