import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import RolesTable from "../roles-table";

import { Container } from "./styled";
import Collapse from "../../collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { Tooltip } from "antd";
import { FormattedHTMLMessage, useIntl } from "react-intl";
import LoadingRolesTable from "../loading-roles-table";
import { ToggleContainer, ToggleLabel } from "../roles-table/styled";
import { Toggle } from "@ts-digital/vrc";

const RolesAccordion = ({ formState, isLoading, onUpdate, selectedItem }) => {
    const [updatedValues, setUpdatedValues] = useState({
        overallPermissions: [{ permissions: [] }],
        activePermissions: [],
        inactivePermissions: []
    });

    const [localServiceEnabled, setLocalServiceEnabled] = useState(selectedItem.localServices);

    useEffect(() => {
        setLocalServiceEnabled(selectedItem.localServices);
    }, [selectedItem]);

    useEffect(() => {
        setUpdatedValues(formState);
    }, [formState]);

    const intl = useIntl();

    useEffect(() => {
        onUpdate(
            {
                overallPermissions: updatedValues.overallPermissions || [{ permissions: [] }],
                activePermissions: updatedValues.activePermissions || [],
                inactivePermissions: updatedValues.inactivePermissions || []
            },
            [{ workspaceId: selectedItem.workspaceId, enabled: localServiceEnabled === true }]
        );
    }, [
        onUpdate,
        updatedValues.inactivePermissions,
        updatedValues.activePermissions,
        updatedValues.overallPermissions,
        selectedItem.workspaceId,
        localServiceEnabled
    ]);

    const onActivePermissionsUpdate = useCallback(
        val => {
            setUpdatedValues({
                inactivePermissions: updatedValues.inactivePermissions,
                activePermissions: val,
                overallPermissions: [{ ...updatedValues.overallPermissions[0], role: "multipleRoles" }]
            });
        },
        [updatedValues.inactivePermissions, updatedValues.overallPermissions]
    );

    const onInactivePermissionsUpdate = useCallback(
        val => {
            setUpdatedValues({
                inactivePermissions: val,
                activePermissions: updatedValues.activePermissions,
                overallPermissions: [{ ...updatedValues.overallPermissions[0], role: "multipleRoles" }]
            });
        },
        [updatedValues.activePermissions, updatedValues.overallPermissions]
    );
    return (
        <Container>
            <div>
                <Collapse
                    header={
                        <div>
                            <FormattedHTMLMessage id="c-registry-user.active-roles" />{" "}
                        </div>
                    }
                    isOpen={true}
                    key="0"
                >
                    {isLoading ? (
                        <LoadingRolesTable
                            showActivationDateColumn={true}
                            showDescriptionColumn={true}
                            showServiceStatusColumn={true}
                        />
                    ) : (
                        <RolesTable
                            onUpdate={onActivePermissionsUpdate}
                            showActivationDateColumn={true}
                            showDescriptionColumn={true}
                            showServiceStatusColumn={true}
                            values={updatedValues.activePermissions}
                        />
                    )}
                    {selectedItem.localServices ? (
                        <ToggleContainer>
                            <Toggle checked={localServiceEnabled} onChange={value => setLocalServiceEnabled(value)} />
                            <ToggleLabel>{intl.formatMessage({ id: "c-registry-user.local-services" })}</ToggleLabel>
                        </ToggleContainer>
                    ) : null}
                </Collapse>
            </div>
            <div>
                {updatedValues.inactivePermissions.length > 0 || !selectedItem.localServices ? (
                    <Collapse
                        header={
                            <div>
                                <FormattedHTMLMessage id="c-registry-user.add-roles" />{" "}
                                <Tooltip
                                    placement="bottom"
                                    title={<FormattedHTMLMessage id="c-registry-user.add-roles.tooltip" />}
                                >
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                </Tooltip>
                            </div>
                        }
                        key="1"
                    >
                        {isLoading ? (
                            <LoadingRolesTable showActivationDateColumn={false} showServiceStatusColumn={true} />
                        ) : (
                            <RolesTable
                                onUpdate={onInactivePermissionsUpdate}
                                showActivationDateColumn={true}
                                showServiceStatusColumn={true}
                                values={updatedValues.inactivePermissions}
                            />
                        )}
                        {selectedItem.localServices ? null : (
                            <ToggleContainer>
                                <Toggle
                                    checked={localServiceEnabled === true}
                                    onChange={value => setLocalServiceEnabled(value)}
                                />
                                <ToggleLabel>
                                    {intl.formatMessage({ id: "c-registry-user.local-services" })}
                                </ToggleLabel>
                            </ToggleContainer>
                        )}
                    </Collapse>
                ) : null}
            </div>
        </Container>
    );
};

RolesAccordion.propTypes = {
    formState: PropTypes.shape({
        activePermissions: PropTypes.array,
        inactivePermissions: PropTypes.array
    }).isRequired
};

export default RolesAccordion;
