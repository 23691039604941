import axios from "axios";
import cuid from "cuid";
import flatten from "flat";
import uuid from "uuid/v4";

import { refreshToken } from "./auth-utils";
import { logout } from "../actions/auth";
import { displayErrorToast, displaySuccessToast } from "../actions/overflowNotification";

import { translateMessage } from "../i18n";

async function performCall(url, token, method, urlParams, body, userId, itemId, userLanguage = "it-IT") {
    let queryString = getQueryString(urlParams);

    queryString = queryString !== "" ? `?${queryString}` : "";

    const headers = {
        Authorization: `Bearer ${token ? token : ""}`,
        "Content-Type": "application/json",
        "X-App-Name": "PORTALE",
        "X-App-Version": "1.0",
        "X-Request-Id": cuid(),
        "X-Correlation-Id": uuid()
    };

    if (userId) {
        headers["X-User-Id"] = userId;
    }

    if (itemId) {
        headers["X-Item-Id"] = itemId;
    }

    if (userLanguage) {
        headers["Accept-Language"] = userLanguage;
    }

    return await axios({
        url: `${url}${queryString}`,
        method: method,
        data: body && JSON.stringify(body),
        headers
    })
        .then(response => {
            return { ...response, ok: true };
        })
        .catch(error => {
            return { ...error.response, ok: false };
        });
}

export async function restApiCall(
    url,
    auth,
    method,
    urlParams,
    body,
    dispatch,
    refreshTokenParam,
    userProfile = {},
    itemId = null,
    displayError = false,
    displaySuccess = false,
    codes = [401],
    displayActivationServiceSuccess = false
) {
    let response = await performCall(
        url,
        auth && auth.securityToken,
        method,
        urlParams,
        body,
        userProfile.id,
        itemId,
        userProfile.language
    );

    if (!response.ok) {
        if (codes.includes(response.status) && auth) {
            let token = "";

            try {
                token = await refreshToken(auth, dispatch);
            } catch (e) {
                logout()(dispatch);
                return;
            }

            response = await performCall(url, token, method, urlParams, body);

            if (!response.ok) {
                if (codes.includes(response.status)) {
                    logout()(dispatch);
                } else {
                    const text = response.data.message;
                    if (displayError) {
                        displayErrorToast(text)(dispatch);
                    }

                    const errResponse = {
                        status: response.status,
                        ...response.data
                    };
                    throw errResponse;
                }
            }
        } else if (response.status === 403 && response.data.code === "403.1") {
            dispatch({
                type: "LOGIN_ERROR",
                error: response.data,
                errorInfo: response.data
            });
        } else {
            const text = response.data.message;
            if (displayError) {
                displayErrorToast(text)(dispatch);
            }

            const errResponse = { status: response.status, ...response.data };
            throw errResponse;
        }
    }

    if (displaySuccess) {
        if (displayActivationServiceSuccess) {
            displaySuccessToast(translateMessage("general.service-activation"))(dispatch);
        } else {
            displaySuccessToast(translateMessage("general.post-rest-success"))(dispatch);
        }
    }

    return response.data;
}

export function headRestCall(
    url,
    auth,
    urlParams,
    dispatch,
    refreshTokenParam,
    userProfile,
    itemId,
    displayError = false,
    displaySuccess = false
) {
    return restApiCall(
        url,
        auth,
        "HEAD",
        urlParams,
        null,
        dispatch,
        refreshTokenParam,
        userProfile,
        itemId,
        displayError,
        displaySuccess
    );
}

export function getRestCall(
    url,
    auth,
    urlParams,
    dispatch,
    refreshTokenParam,
    userProfile,
    itemId,
    displayError = false,
    displaySuccess = false,
    codes = [401]
) {
    return restApiCall(
        url,
        auth,
        "GET",
        urlParams,
        null,
        dispatch,
        refreshTokenParam,
        userProfile,
        itemId,
        displayError,
        displaySuccess,
        codes
    );
}

export function postRestCall(
    url,
    auth,
    urlParams,
    body,
    dispatch,
    refreshTokenParam,
    userProfile,
    itemId,
    displayError = false,
    displaySuccess = false,
    displayActivationServiceSuccess = false
) {
    return restApiCall(
        url,
        auth,
        "POST",
        urlParams,
        body,
        dispatch,
        refreshTokenParam,
        userProfile,
        itemId,
        displayError,
        displaySuccess,
        [401],
        displayActivationServiceSuccess
    );
}

export function deleteRestCall(
    url,
    auth,
    urlParams,
    body,
    dispatch,
    refreshTokenParam,
    userProfile,
    itemId,
    displayError = false,
    displaySuccess = false
) {
    return restApiCall(
        url,
        auth,
        "DELETE",
        urlParams,
        body,
        dispatch,
        refreshTokenParam,
        userProfile,
        itemId,
        displayError,
        displaySuccess
    );
}

export function patchRestCall(
    url,
    auth,
    urlParams,
    body,
    dispatch,
    refreshTokenParam,
    userProfile,
    itemId,
    displayError = true
) {
    return restApiCall(
        url,
        auth,
        "PATCH",
        urlParams,
        body,
        dispatch,
        refreshTokenParam,
        userProfile,
        itemId,
        displayError
    );
}

export function putRestCall(
    url,
    auth,
    urlParams,
    body,
    dispatch,
    refreshTokenParam,
    userProfile,
    itemId,
    displayError = true
) {
    return restApiCall(
        url,
        auth,
        "PUT",
        urlParams,
        body,
        dispatch,
        refreshTokenParam,
        userProfile,
        itemId,
        displayError
    );
}

/**
 * Trasform a nested object in a query string
 * @param params nested object
 */
function getQueryString(params) {
    return params
        ? Object.entries(flatten(params, { safe: true }))
              .filter(([_, value]) => value !== undefined && value !== null)
              .map(key => `${key[0]}=${encodeURIComponent(key[1] + "")}`)
              .join("&")
        : "";
}
