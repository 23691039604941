import React, { useEffect } from "react";

import { Button } from "@ts-digital/vrc";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { connect } from "react-redux";

import { getListConsent, updateTermConsent } from "../../actions/user-consents";
import { PRIVACY_POLICY_URL } from "../../config";
import { useFeature } from "../../hooks/use-feature";
import ProgressiveModal from "../../components/general/progressive-modal";
import { consentNeedsUpdate } from "../../utils/consent-utils";

export const PrivacyModal = ({ consents, getListConsent, updateTermConsent }) => {
    const privacyModalActive = useFeature("PRIVACY_MODAL");

    useEffect(() => {
        privacyModalActive && getListConsent();
    }, [getListConsent, privacyModalActive]);

    const updateConsent = (accepted, term) => {
        updateTermConsent(term, {
            accepted,
            version: consents[term].latestVersion
        });
    };

    const tabs = [
        {
            content: <FormattedMessage id="root.privacy-modal.step0.title" />,
            id: "CORE_PRIVACY"
        },
        {
            content: <FormattedMessage id="root.privacy-modal.step1.title" />,
            id: "CORE_DATA_PROCESSING"
        },
        {
            content: <FormattedMessage id="root.privacy-modal.step2.title" />,
            id: "CORE_MARKETING"
        }
    ];

    let selectedTab = 0;

    if (consentNeedsUpdate(consents, "CORE_PRIVACY")) {
        selectedTab = 0;
    } else if (consentNeedsUpdate(consents, "CORE_DATA_PROCESSING")) {
        selectedTab = 1;
    } else if (consentNeedsUpdate(consents, "CORE_MARKETING")) {
        selectedTab = 2;
    }

    const footer = [];

    if (selectedTab !== 0) {
        footer.push(
            <Button kind="primary" onClick={() => updateConsent(false, tabs[selectedTab].id)} key="modal-deny">
                <FormattedMessage id="general.deny" />
            </Button>
        );
    }

    footer.push(
        <Button kind="primary" onClick={() => updateConsent(true, tabs[selectedTab].id)} key="modal-ok">
            <FormattedMessage id="general.ok" />
        </Button>
    );

    const generalTermsModalVisible = consentNeedsUpdate(consents, "CORE_GENERAL_TERMS");

    return (
        <ProgressiveModal
            visible={
                privacyModalActive &&
                !generalTermsModalVisible &&
                (consentNeedsUpdate(consents, "CORE_PRIVACY") ||
                    consentNeedsUpdate(consents, "CORE_DATA_PROCESSING") ||
                    consentNeedsUpdate(consents, "CORE_MARKETING"))
            }
            tabs={tabs}
            maxWidth="1040px"
            minHeight="430px"
            selectedTab={selectedTab}
            width={null}
            footer={footer}
        >
            <p>
                <FormattedHTMLMessage
                    id={`root.privacy-modal.step${selectedTab}.content`}
                    values={{
                        privacyPolicyUrl: PRIVACY_POLICY_URL,
                        privacyContactEmail: "privacy@teamsystem.com"
                    }}
                />
            </p>
        </ProgressiveModal>
    );
};

const mapStateToProps = state => ({
    corePrivacyConsent: state.userConsents.terms.CORE_PRIVACY,
    consents: state.userConsents.terms
});

export default connect(mapStateToProps, { getListConsent, updateTermConsent })(PrivacyModal);
