import React from "react";
import PropTypes from "prop-types";

import { faTimes } from "@fortawesome/pro-regular-svg-icons";

import { Icon, StyledModal } from "./styled";
import ProgressiveTab from "../progressive-tab";

const ProgressiveModal = ({ children, selectedTab, tabs, visible, ...props }) => {
    return (
        <StyledModal
            selectedTab={selectedTab}
            closable={false}
            closeIcon={<Icon icon={faTimes} />}
            maskStyle={{
                background: "rgba(0, 80, 117, 0.32)"
            }}
            title={<ProgressiveTab tabs={tabs} selectedTab={selectedTab} />}
            width={props.maxWidth ? null : props.width} //ignore static width if maxWidth has been defined
            visible={visible}
            {...props}
        >
            {children}
        </StyledModal>
    );
};

ProgressiveModal.propTypes = {
    children: PropTypes.element.isRequired,
    maxWidth: PropTypes.string,
    selectedTab: PropTypes.number,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            disabled: PropTypes.bool,
            completed: PropTypes.bool,
            active: PropTypes.bool
        })
    ),
    visible: PropTypes.bool,
    width: PropTypes.string
};

export default ProgressiveModal;
