import React from "react";
import PropTypes from "prop-types";

import { Button } from "@ts-digital/vrc";
import { Link } from "react-router-dom";

const LinkButton = ({ children, colorScheme, disabled, kind, onClick, size, to, dataGainsightTrack }) => {
    const button = (
        <Button
            colorScheme={colorScheme}
            disabled={disabled}
            kind={kind}
            onClick={onClick}
            size={size}
            data-gainsight-track={dataGainsightTrack || ""}
        >
            {children}
        </Button>
    );
    return to && !disabled ? <Link to={to}>{button}</Link> : button;
};

LinkButton.propTypes = {
    children: PropTypes.any,
    colorScheme: PropTypes.oneOf(["default", "ghost", "alert"]),
    disabled: PropTypes.bool,
    kind: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
    onClick: PropTypes.func,
    size: PropTypes.oneOf(["small", "medium", "large"]),
    to: PropTypes.string
};

export default LinkButton;
