import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";
import { FormattedHTMLMessage } from "react-intl";

import "./style.css";
class BadWidth extends Component {
    static propTypes = {
        templateType: PropTypes.string
    };

    renderBadRes() {
        return (
            <div>
                <h1
                    style={{
                        textAlign: "center",
                        marginTop: 40,
                        color: "#333"
                    }}
                >
                    <FormattedHTMLMessage id="c-bad-width.enlarge" />
                </h1>
                <div id="macbookpro">
                    <div className="camera" />
                    <div className="ecran">
                        <div className="affichage">
                            <Icon
                                type="check"
                                style={{
                                    fontSize: "60px",
                                    paddingTop: "55px",
                                    paddingLeft: "100px",
                                    color: "#B6CD4B"
                                }}
                            />
                        </div>
                    </div>
                    <div className="clavier2" />
                </div>

                <div id="ipad">
                    <div className="device">
                        <div className="camera" />
                        <div className="screen2">
                            <Icon
                                type="close"
                                style={{
                                    fontSize: "60px",
                                    paddingTop: "55px",
                                    paddingLeft: "25px",
                                    color: "#ED2544"
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div id="iphone_global">
                    <div className="iphone">
                        <div className="white">
                            <Icon
                                type="close"
                                style={{
                                    fontSize: "43px",
                                    paddingTop: "25px",
                                    color: "#ED2544"
                                }}
                            />
                        </div>
                        <div className="button_home" />
                    </div>
                </div>
            </div>
        );
    }

    renderTablet() {
        return (
            <div>
                <h1
                    style={{
                        textAlign: "center",
                        marginTop: 40,
                        color: "#333"
                    }}
                >
                    <FormattedHTMLMessage id="c-bad-width.turn" />
                </h1>
                <div className="scale-wrap">
                    <div className="ipad-frame">
                        <div className="ipad">
                            <div className="display-frame-top">
                                <div className="camera" />
                            </div>
                            <div className="display-frame-center">
                                <div className="frame-side-left" />
                                <div className="display" />
                                <div className="frame-side-right" />
                            </div>
                            <div className="display-frame-bottom">
                                <div className="home-button">
                                    <div className="rounded-square" />
                                </div>
                            </div>
                        </div>
                        <div className="cross-checkmark">
                            <div className="line-1" />
                            <div className="line-2" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { templateType } = this.props;
        return (
            <div
                style={{
                    backgroundColor: "#FFF",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 1000
                }}
            >
                {templateType === "turnTablet" ? this.renderTablet() : this.renderBadRes()}
            </div>
        );
    }
}

export default BadWidth;
