import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { fetchCctProvisioningInfo } from "../../../../actions/services/cct/provisioning";
import {
    getCctConfig,
    saveCctConfig,
    verifyCCTcredentials,
    getCctConfigManaged
} from "../../../../actions/services/cct";
import { serviceActiveToast, serviceDisableToast } from "../../../../actions/toastNotifications";
import { getItemApps } from "../../../../actions/services/app";
import { ConfigureItemServices } from "../../../../components/storage/cct/provisioning/configure-item-services";
import PageTitle from "../../../../components/general/page-title";

export const ConfigureItemServicesView = ({
    isCctActive,
    cctConfig,
    configError,
    configLoaded,
    configLoading,
    getCctConfig,
    saveCctConfig,
    fetchCctProvisioningInfo,
    provisioningInfo,
    verifyCCTcredentials,
    credentialsStatus,
    saveConfigLoading,
    getCctConfigManaged,
    cctConfigManaged,
    history,
    saveConfigError,
    saveConfigEnded,
    serviceActiveToast,
    serviceDisableToast,
    getItemApps,
    item,
    itemApps,
    match,
    user
}) => {
    const [lastSpecsSetted, setlastSpecsSetted] = useState({});
    const itemId = match.params.item;

    useEffect(() => {
        if (!configLoading && !configLoaded && !configError && isCctActive) {
            getCctConfig(itemId, itemId);
            fetchCctProvisioningInfo(item.base.id);
        }
        getCctConfigManaged(itemId, null, null, null);
    }, [
        configLoaded,
        configLoading,
        configError,
        fetchCctProvisioningInfo,
        getCctConfig,
        item.base.id,
        getCctConfigManaged,
        itemId,
        isCctActive
    ]);

    useEffect(() => {
        if (saveConfigEnded && !saveConfigError) {
            if (lastSpecsSetted && lastSpecsSetted.id) {
                if (lastSpecsSetted.active) {
                    serviceActiveToast(lastSpecsSetted.id);
                } else {
                    serviceDisableToast(lastSpecsSetted.id);
                }
            }
        }
    }, [saveConfigError, saveConfigEnded, lastSpecsSetted, serviceActiveToast, serviceDisableToast]);

    useEffect(() => {
        !itemApps.status.ended && !itemApps.status.started && getItemApps(itemId, user.profile.id);
    }, [itemId, itemApps, getItemApps, user]);

    const handleServiceToggled = useCallback(
        (active, services, id) => {
            const specs = services.reduce((specs, service) => ({ ...specs, [service]: active }), {});
            saveCctConfig(itemId, itemId, specs);
            setlastSpecsSetted({ id, active });
        },
        [itemId, saveCctConfig]
    );

    const handleCredentialsChanged = useCallback(
        (cctUser, cctPass) => {
            verifyCCTcredentials(cctUser, cctPass, itemId, itemId);
        },
        [itemId, verifyCCTcredentials]
    );

    return (
        <>
            <PageTitle
                title={<FormattedMessage id="c-services-view.ARC.name" />}
                subtitle={<FormattedMessage id="c-cct-item-services-subtitle" />}
                onBack={() => history.goBack()}
            />
            <ConfigureItemServices
                cctConfig={cctConfig}
                onServiceToggled={handleServiceToggled}
                provisioningInfo={provisioningInfo}
                handleCredentialsChanged={handleCredentialsChanged}
                itemApps={itemApps}
                credentialsChangeLoading={credentialsStatus.started}
                credentialsChangeError={credentialsStatus.error}
                saveConfigLoading={saveConfigLoading}
                cctConfigManaged={cctConfigManaged}
            />
        </>
    );
};

ConfigureItemServicesView.propTypes = {
    // Passed down from Route
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,

    // Obtained from redux state
    provisioningInfo: PropTypes.object
};

const mapStateToProps = (state, { match }) => {
    const provisioningInfoArray = state.services.cct.provisioningInfo.provisioningInfo;

    return {
        isCctActive:
            state.services.active.services.includes("CCT-FLOW") && !state.services.active.services.includes("ARC"),
        cctConfig: state.services.cct.getCctConfig,
        configError: state.services.cct.getCctConfig.status.error,
        configLoaded: state.services.cct.getCctConfig.status.ended,
        configLoading: state.services.cct.getCctConfig.status.started,
        credentialsStatus: state.services.cct.verifyCct.status,
        item: state.companies.data[match.params.item] ? state.companies.data[match.params.item].item : {},
        itemApps: state.services.itemApps,
        provisioningInfo: provisioningInfoArray[match.params.item],
        provisioningStatus:
            (provisioningInfoArray[match.params.item] && provisioningInfoArray[match.params.item].status) || null,
        provisioningInfoStatus: state.services.cct.provisioningInfo.isLoading,
        cctConfigManaged: state.services.cct.getCctConfigManaged.cctConfigManaged,
        saveConfigError: state.services.cct.saveCctConfig.status.error,
        saveConfigEnded: state.services.cct.saveCctConfig.status.ended,
        saveConfigLoading: state.services.cct.saveCctConfig.status.started,
        user: state.user.user
    };
};

export default connect(mapStateToProps, {
    fetchCctProvisioningInfo,
    getCctConfig,
    saveCctConfig,
    verifyCCTcredentials,
    serviceActiveToast,
    serviceDisableToast,
    getCctConfigManaged,
    getItemApps
})(ConfigureItemServicesView);
