import styled, { css } from "styled-components";

export const Container = styled.div`
    margin: 24px;
    gap: 24px;
    display: flex;
    flex-direction: column;
`;

export const TableCell = styled.div`
    ${props => {
        if (props.disabled) {
            return css`
                color: ${props => props.theme.colors.text.quickSilver};
            `;
        }
    }}
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
`;

export const CompanyDescription = styled.div`
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
`;
