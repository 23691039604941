import { applyMiddleware, createStore, compose } from "redux";
import { createLogger } from "redux-logger";
import { persistStore, persistCombineReducers } from "redux-persist";
import thunk from "redux-thunk";
import reducersSchema from "./schema";
import storage from "redux-persist/lib/storage/session";
import { LOGOUT } from "../actions/auth";
import {
    ENABLE_AGYO_STATUS,
    ENABLE_CONSOLE_LOGS,
    ENABLE_APP_VERSION_CHECK,
    ENABLE_PASSWORD_EXPIRATION_WARN
} from "../config";
import conf from "../middleware/config";
import checkVersion from "../middleware/checkVersion";
import passwordExpiration from "../middleware/passwordExpiration";
import { trackEvents } from "../middleware/trackEvents";

const logger = createLogger({ collapsed: true });

const config = {
    key: "primary",
    storage,
    whitelist: ["auth"]
};

const persistedReducer = persistCombineReducers(config, reducersSchema);

const reducer = (state, action) => {
    // on logout, resets the redux state to its default
    if (action.type === LOGOUT) {
        state = { _persist: state._persist };
    }

    return persistedReducer(state, action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunk, trackEvents];

const isRunningTest = "test" === process.env.NODE_ENV;

if (ENABLE_AGYO_STATUS === "enable" && !isRunningTest) {
    console.log("Enable agyo status: ", ENABLE_AGYO_STATUS);

    middlewares.push(conf);
}

if (ENABLE_CONSOLE_LOGS && !isRunningTest) {
    console.log("Enable console logs", ENABLE_CONSOLE_LOGS);

    middlewares.push(logger);
}

if (ENABLE_APP_VERSION_CHECK === "enable" && !isRunningTest) {
    console.log("Enable app version check", ENABLE_APP_VERSION_CHECK);

    middlewares.push(checkVersion);
}

if (ENABLE_PASSWORD_EXPIRATION_WARN === "enable" && !isRunningTest) {
    console.log("Enable password expiration warning", ENABLE_PASSWORD_EXPIRATION_WARN);

    middlewares.push(passwordExpiration);
}

const store = createStore(reducer, composeEnhancers(applyMiddleware(...middlewares)));

export const persistor = persistStore(store);

export default store;
