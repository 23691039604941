import { useEffect, useRef } from "react";

/**
 * Custom hooks which redirects the user to a success/error page depending
 * on the provided status of the call.
 * The success page must be located at current-url/success
 * The error page must be located at current-url/error
 * @param {string} currentUrl
 * @param {object} history History object obtained from react-redux
 * @param {object} callStatus Object containing the status of an async call in the
 * {started, ended, error} format
 * @param {function} resetAction Action creator used to reset the async call's status
 */
export const useFeedbackPage = (currentUrl, history, { ended, error }, resetAction) => {
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (ended && !isFirstRender.current) {
            history.push(`${currentUrl}/success`);
        }
    }, [currentUrl, ended, history]);

    useEffect(() => {
        if (error && !isFirstRender.current) {
            history.push(`${currentUrl}/error`);
        }
    }, [currentUrl, error, history]);

    useEffect(() => {
        resetAction();
        isFirstRender.current = false;
    }, [resetAction]);
};
