import styled from "styled-components";

export const Container = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100vh;
    padding: 20px 0px;
`;

export const ContentContainer = styled.div`
    width: 450px;
`;

export const LogoContainer = styled.div`
    text-align: center;
    padding: 20px 0px;
`;
