import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import { Button, Icon, Form, Row, Col, Radio } from "antd";
import { signerValidation } from "../../../../utils/signer-validations/fields-validation";
import * as normalizeField from "../../../../utils/normalize-form";
import FormInput from "../../../general/form-input";

import { FormattedMessage } from "react-intl";

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

function validate(values) {
    return signerValidation(values);
}
class FormResetSignerPin extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func
    };

    renderForm() {
        const FormItem = Form.Item;
        const { handleSubmit } = this.props;

        const renderAntRadio = ({ input, label, meta: { touched, error }, children, ...custom }) => (
            <FormItem
                hasFeedback={true}
                help={touched && error}
                validateStatus={touched && error ? "error" : ""}
                wrapperCol={{ span: 23 }}
            >
                <div className="selectWrap">
                    <span className="labelForm">{label}</span>
                    <RadioGroup placeholder={label} children={children} className="radioInput" {...custom} {...input} />
                </div>
            </FormItem>
        );

        return (
            <div>
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col span={12}>
                            <Field
                                name="puk"
                                component={FormInput}
                                wrapperCol={{ span: 23 }}
                                label="Puk"
                                type="password"
                            />
                        </Col>
                        <Col span={12}>
                            <Field
                                name="pin"
                                component={FormInput}
                                wrapperCol={{ span: 23 }}
                                label="Pin"
                                type="password"
                                normalize={normalizeField.normalizeCustomLength(8)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Field
                                name="savePin"
                                component={renderAntRadio}
                                label={<FormattedMessage id="general.save-pin" />}
                            >
                                <RadioButton value={true}>
                                    <FormattedMessage id="general.yes" />
                                </RadioButton>
                                <RadioButton
                                    style={{
                                        height: "32px",
                                        paddingTop: "3px"
                                    }}
                                    value={false}
                                >
                                    <FormattedMessage id="general.no" />
                                </RadioButton>
                            </Field>
                        </Col>
                    </Row>
                    <div
                        style={{
                            marginLeft: "20px",
                            position: "absolute",
                            marginTop: "-25px"
                        }}
                    >
                        <span>
                            <FormattedMessage id="c-signature.must-choice" />
                        </span>
                    </div>

                    <Button htmlType="submit" style={{ marginLeft: "83%", marginTop: "45px" }} type="primary">
                        <Icon type="save" /> <FormattedMessage id="general.reset" />
                    </Button>
                </form>
            </div>
        );
    }

    render() {
        return <div>{this.renderForm()}</div>;
    }
}

export default reduxForm({
    form: "form-reset-signer-pin",
    validate
})(FormResetSignerPin);
