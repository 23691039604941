import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { hideToast } from "../../actions/overflowNotification";

import { TimedOverflowNotification } from "@ts-digital/arc/antd/TimedOverflowNotification";

export const TimedOverflow = ({ hideToast, overflow: { title, message, show, type } }) =>
    show && (
        <TimedOverflowNotification
            // max ms value allowed (see at https://stackoverflow.com/questions/3468607/why-does-settimeout-break-for-large-millisecond-delay-values)
            duration={2147483647}
            onClose={hideToast}
            title={title}
            variant={type}
        >
            {message}
        </TimedOverflowNotification>
    );

TimedOverflow.propTypes = {
    hideToast: PropTypes.func.isRequired,
    overflow: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        overflow: state.timedNotifications.overflow
    };
}

export default connect(mapStateToProps, { hideToast })(TimedOverflow);
