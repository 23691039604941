/* eslint-disable */
//
// Autogenerated by Thrift Compiler (0.11.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
"use strict";

var thrift = require('browser-thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = require('./notespese-flow-config_types');
//HELPER FUNCTIONS AND STRUCTURES

var NoteSpeseFlowConfig_health_args = function(args) {
};
NoteSpeseFlowConfig_health_args.prototype = {};
NoteSpeseFlowConfig_health_args.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        input.skip(ftype);
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

NoteSpeseFlowConfig_health_args.prototype.write = function(output) {
    output.writeStructBegin('NoteSpeseFlowConfig_health_args');
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var NoteSpeseFlowConfig_health_result = function(args) {
    this.success = null;
    if (args) {
        if (args.success !== undefined && args.success !== null) {
            this.success = args.success;
        }
    }
};
NoteSpeseFlowConfig_health_result.prototype = {};
NoteSpeseFlowConfig_health_result.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 0:
                if (ftype == Thrift.Type.STRING) {
                    this.success = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 0:
                input.skip(ftype);
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

NoteSpeseFlowConfig_health_result.prototype.write = function(output) {
    output.writeStructBegin('NoteSpeseFlowConfig_health_result');
    if (this.success !== null && this.success !== undefined) {
        output.writeFieldBegin('success', Thrift.Type.STRING, 0);
        output.writeString(this.success);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var NoteSpeseFlowConfig_isActive_args = function(args) {
    this.auth = null;
    this.request = null;
    if (args) {
        if (args.auth !== undefined && args.auth !== null) {
            this.auth = new ttypes.Auth(args.auth);
        }
        if (args.request !== undefined && args.request !== null) {
            this.request = new ttypes.ConfigRequest(args.request);
        }
    }
};
NoteSpeseFlowConfig_isActive_args.prototype = {};
NoteSpeseFlowConfig_isActive_args.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 1:
                if (ftype == Thrift.Type.STRUCT) {
                    this.auth = new ttypes.Auth();
                    this.auth.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.STRUCT) {
                    this.request = new ttypes.ConfigRequest();
                    this.request.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

NoteSpeseFlowConfig_isActive_args.prototype.write = function(output) {
    output.writeStructBegin('NoteSpeseFlowConfig_isActive_args');
    if (this.auth !== null && this.auth !== undefined) {
        output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
        this.auth.write(output);
        output.writeFieldEnd();
    }
    if (this.request !== null && this.request !== undefined) {
        output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
        this.request.write(output);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var NoteSpeseFlowConfig_isActive_result = function(args) {
    this.success = null;
    this.genericError = null;
    this.authenticationException = null;
    this.idNotFoundException = null;
    if (args instanceof ttypes.GenericError) {
        this.genericError = args;
        return;
    }
    if (args instanceof ttypes.AuthenticationException) {
        this.authenticationException = args;
        return;
    }
    if (args instanceof ttypes.IdNotFoundException) {
        this.idNotFoundException = args;
        return;
    }
    if (args) {
        if (args.success !== undefined && args.success !== null) {
            this.success = args.success;
        }
        if (args.genericError !== undefined && args.genericError !== null) {
            this.genericError = args.genericError;
        }
        if (args.authenticationException !== undefined && args.authenticationException !== null) {
            this.authenticationException = args.authenticationException;
        }
        if (args.idNotFoundException !== undefined && args.idNotFoundException !== null) {
            this.idNotFoundException = args.idNotFoundException;
        }
    }
};
NoteSpeseFlowConfig_isActive_result.prototype = {};
NoteSpeseFlowConfig_isActive_result.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 0:
                if (ftype == Thrift.Type.BOOL) {
                    this.success = input.readBool();
                } else {
                    input.skip(ftype);
                }
                break;
            case 1:
                if (ftype == Thrift.Type.STRUCT) {
                    this.genericError = new ttypes.GenericError();
                    this.genericError.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.STRUCT) {
                    this.authenticationException = new ttypes.AuthenticationException();
                    this.authenticationException.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            case 3:
                if (ftype == Thrift.Type.STRUCT) {
                    this.idNotFoundException = new ttypes.IdNotFoundException();
                    this.idNotFoundException.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

NoteSpeseFlowConfig_isActive_result.prototype.write = function(output) {
    output.writeStructBegin('NoteSpeseFlowConfig_isActive_result');
    if (this.success !== null && this.success !== undefined) {
        output.writeFieldBegin('success', Thrift.Type.BOOL, 0);
        output.writeBool(this.success);
        output.writeFieldEnd();
    }
    if (this.genericError !== null && this.genericError !== undefined) {
        output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
        this.genericError.write(output);
        output.writeFieldEnd();
    }
    if (this.authenticationException !== null && this.authenticationException !== undefined) {
        output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
        this.authenticationException.write(output);
        output.writeFieldEnd();
    }
    if (this.idNotFoundException !== null && this.idNotFoundException !== undefined) {
        output.writeFieldBegin('idNotFoundException', Thrift.Type.STRUCT, 3);
        this.idNotFoundException.write(output);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var NoteSpeseFlowConfig_isUsable_args = function(args) {
    this.auth = null;
    this.request = null;
    if (args) {
        if (args.auth !== undefined && args.auth !== null) {
            this.auth = new ttypes.Auth(args.auth);
        }
        if (args.request !== undefined && args.request !== null) {
            this.request = new ttypes.ConfigRequest(args.request);
        }
    }
};
NoteSpeseFlowConfig_isUsable_args.prototype = {};
NoteSpeseFlowConfig_isUsable_args.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 1:
                if (ftype == Thrift.Type.STRUCT) {
                    this.auth = new ttypes.Auth();
                    this.auth.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.STRUCT) {
                    this.request = new ttypes.ConfigRequest();
                    this.request.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

NoteSpeseFlowConfig_isUsable_args.prototype.write = function(output) {
    output.writeStructBegin('NoteSpeseFlowConfig_isUsable_args');
    if (this.auth !== null && this.auth !== undefined) {
        output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
        this.auth.write(output);
        output.writeFieldEnd();
    }
    if (this.request !== null && this.request !== undefined) {
        output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
        this.request.write(output);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var NoteSpeseFlowConfig_isUsable_result = function(args) {
    this.success = null;
    this.genericError = null;
    this.authenticationException = null;
    this.idNotFoundException = null;
    if (args instanceof ttypes.GenericError) {
        this.genericError = args;
        return;
    }
    if (args instanceof ttypes.AuthenticationException) {
        this.authenticationException = args;
        return;
    }
    if (args instanceof ttypes.IdNotFoundException) {
        this.idNotFoundException = args;
        return;
    }
    if (args) {
        if (args.success !== undefined && args.success !== null) {
            this.success = args.success;
        }
        if (args.genericError !== undefined && args.genericError !== null) {
            this.genericError = args.genericError;
        }
        if (args.authenticationException !== undefined && args.authenticationException !== null) {
            this.authenticationException = args.authenticationException;
        }
        if (args.idNotFoundException !== undefined && args.idNotFoundException !== null) {
            this.idNotFoundException = args.idNotFoundException;
        }
    }
};
NoteSpeseFlowConfig_isUsable_result.prototype = {};
NoteSpeseFlowConfig_isUsable_result.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 0:
                if (ftype == Thrift.Type.BOOL) {
                    this.success = input.readBool();
                } else {
                    input.skip(ftype);
                }
                break;
            case 1:
                if (ftype == Thrift.Type.STRUCT) {
                    this.genericError = new ttypes.GenericError();
                    this.genericError.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.STRUCT) {
                    this.authenticationException = new ttypes.AuthenticationException();
                    this.authenticationException.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            case 3:
                if (ftype == Thrift.Type.STRUCT) {
                    this.idNotFoundException = new ttypes.IdNotFoundException();
                    this.idNotFoundException.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

NoteSpeseFlowConfig_isUsable_result.prototype.write = function(output) {
    output.writeStructBegin('NoteSpeseFlowConfig_isUsable_result');
    if (this.success !== null && this.success !== undefined) {
        output.writeFieldBegin('success', Thrift.Type.BOOL, 0);
        output.writeBool(this.success);
        output.writeFieldEnd();
    }
    if (this.genericError !== null && this.genericError !== undefined) {
        output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
        this.genericError.write(output);
        output.writeFieldEnd();
    }
    if (this.authenticationException !== null && this.authenticationException !== undefined) {
        output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
        this.authenticationException.write(output);
        output.writeFieldEnd();
    }
    if (this.idNotFoundException !== null && this.idNotFoundException !== undefined) {
        output.writeFieldBegin('idNotFoundException', Thrift.Type.STRUCT, 3);
        this.idNotFoundException.write(output);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var NoteSpeseFlowConfig_getConfig_args = function(args) {
    this.auth = null;
    this.request = null;
    if (args) {
        if (args.auth !== undefined && args.auth !== null) {
            this.auth = new ttypes.Auth(args.auth);
        }
        if (args.request !== undefined && args.request !== null) {
            this.request = new ttypes.ConfigRequest(args.request);
        }
    }
};
NoteSpeseFlowConfig_getConfig_args.prototype = {};
NoteSpeseFlowConfig_getConfig_args.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 1:
                if (ftype == Thrift.Type.STRUCT) {
                    this.auth = new ttypes.Auth();
                    this.auth.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.STRUCT) {
                    this.request = new ttypes.ConfigRequest();
                    this.request.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

NoteSpeseFlowConfig_getConfig_args.prototype.write = function(output) {
    output.writeStructBegin('NoteSpeseFlowConfig_getConfig_args');
    if (this.auth !== null && this.auth !== undefined) {
        output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
        this.auth.write(output);
        output.writeFieldEnd();
    }
    if (this.request !== null && this.request !== undefined) {
        output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
        this.request.write(output);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var NoteSpeseFlowConfig_getConfig_result = function(args) {
    this.success = null;
    this.genericError = null;
    this.authenticationException = null;
    this.idNotFoundException = null;
    if (args instanceof ttypes.GenericError) {
        this.genericError = args;
        return;
    }
    if (args instanceof ttypes.AuthenticationException) {
        this.authenticationException = args;
        return;
    }
    if (args instanceof ttypes.IdNotFoundException) {
        this.idNotFoundException = args;
        return;
    }
    if (args) {
        if (args.success !== undefined && args.success !== null) {
            this.success = new ttypes.ConfigResponse(args.success);
        }
        if (args.genericError !== undefined && args.genericError !== null) {
            this.genericError = args.genericError;
        }
        if (args.authenticationException !== undefined && args.authenticationException !== null) {
            this.authenticationException = args.authenticationException;
        }
        if (args.idNotFoundException !== undefined && args.idNotFoundException !== null) {
            this.idNotFoundException = args.idNotFoundException;
        }
    }
};
NoteSpeseFlowConfig_getConfig_result.prototype = {};
NoteSpeseFlowConfig_getConfig_result.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 0:
                if (ftype == Thrift.Type.STRUCT) {
                    this.success = new ttypes.ConfigResponse();
                    this.success.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            case 1:
                if (ftype == Thrift.Type.STRUCT) {
                    this.genericError = new ttypes.GenericError();
                    this.genericError.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.STRUCT) {
                    this.authenticationException = new ttypes.AuthenticationException();
                    this.authenticationException.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            case 3:
                if (ftype == Thrift.Type.STRUCT) {
                    this.idNotFoundException = new ttypes.IdNotFoundException();
                    this.idNotFoundException.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

NoteSpeseFlowConfig_getConfig_result.prototype.write = function(output) {
    output.writeStructBegin('NoteSpeseFlowConfig_getConfig_result');
    if (this.success !== null && this.success !== undefined) {
        output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
        this.success.write(output);
        output.writeFieldEnd();
    }
    if (this.genericError !== null && this.genericError !== undefined) {
        output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
        this.genericError.write(output);
        output.writeFieldEnd();
    }
    if (this.authenticationException !== null && this.authenticationException !== undefined) {
        output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
        this.authenticationException.write(output);
        output.writeFieldEnd();
    }
    if (this.idNotFoundException !== null && this.idNotFoundException !== undefined) {
        output.writeFieldBegin('idNotFoundException', Thrift.Type.STRUCT, 3);
        this.idNotFoundException.write(output);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var NoteSpeseFlowConfig_listConfigs_args = function(args) {
    this.auth = null;
    this.request = null;
    if (args) {
        if (args.auth !== undefined && args.auth !== null) {
            this.auth = new ttypes.Auth(args.auth);
        }
        if (args.request !== undefined && args.request !== null) {
            this.request = new ttypes.ListConfigsRequest(args.request);
        }
    }
};
NoteSpeseFlowConfig_listConfigs_args.prototype = {};
NoteSpeseFlowConfig_listConfigs_args.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 1:
                if (ftype == Thrift.Type.STRUCT) {
                    this.auth = new ttypes.Auth();
                    this.auth.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.STRUCT) {
                    this.request = new ttypes.ListConfigsRequest();
                    this.request.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

NoteSpeseFlowConfig_listConfigs_args.prototype.write = function(output) {
    output.writeStructBegin('NoteSpeseFlowConfig_listConfigs_args');
    if (this.auth !== null && this.auth !== undefined) {
        output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
        this.auth.write(output);
        output.writeFieldEnd();
    }
    if (this.request !== null && this.request !== undefined) {
        output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
        this.request.write(output);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var NoteSpeseFlowConfig_listConfigs_result = function(args) {
    this.success = null;
    this.genericError = null;
    this.authenticationException = null;
    this.idNotFoundException = null;
    if (args instanceof ttypes.GenericError) {
        this.genericError = args;
        return;
    }
    if (args instanceof ttypes.AuthenticationException) {
        this.authenticationException = args;
        return;
    }
    if (args instanceof ttypes.IdNotFoundException) {
        this.idNotFoundException = args;
        return;
    }
    if (args) {
        if (args.success !== undefined && args.success !== null) {
            this.success = new ttypes.ListConfigsResponse(args.success);
        }
        if (args.genericError !== undefined && args.genericError !== null) {
            this.genericError = args.genericError;
        }
        if (args.authenticationException !== undefined && args.authenticationException !== null) {
            this.authenticationException = args.authenticationException;
        }
        if (args.idNotFoundException !== undefined && args.idNotFoundException !== null) {
            this.idNotFoundException = args.idNotFoundException;
        }
    }
};
NoteSpeseFlowConfig_listConfigs_result.prototype = {};
NoteSpeseFlowConfig_listConfigs_result.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 0:
                if (ftype == Thrift.Type.STRUCT) {
                    this.success = new ttypes.ListConfigsResponse();
                    this.success.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            case 1:
                if (ftype == Thrift.Type.STRUCT) {
                    this.genericError = new ttypes.GenericError();
                    this.genericError.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.STRUCT) {
                    this.authenticationException = new ttypes.AuthenticationException();
                    this.authenticationException.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            case 3:
                if (ftype == Thrift.Type.STRUCT) {
                    this.idNotFoundException = new ttypes.IdNotFoundException();
                    this.idNotFoundException.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

NoteSpeseFlowConfig_listConfigs_result.prototype.write = function(output) {
    output.writeStructBegin('NoteSpeseFlowConfig_listConfigs_result');
    if (this.success !== null && this.success !== undefined) {
        output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
        this.success.write(output);
        output.writeFieldEnd();
    }
    if (this.genericError !== null && this.genericError !== undefined) {
        output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
        this.genericError.write(output);
        output.writeFieldEnd();
    }
    if (this.authenticationException !== null && this.authenticationException !== undefined) {
        output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
        this.authenticationException.write(output);
        output.writeFieldEnd();
    }
    if (this.idNotFoundException !== null && this.idNotFoundException !== undefined) {
        output.writeFieldBegin('idNotFoundException', Thrift.Type.STRUCT, 3);
        this.idNotFoundException.write(output);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var NoteSpeseFlowConfig_activateAppNoteSpese_args = function(args) {
    this.auth = null;
    this.request = null;
    if (args) {
        if (args.auth !== undefined && args.auth !== null) {
            this.auth = new ttypes.Auth(args.auth);
        }
        if (args.request !== undefined && args.request !== null) {
            this.request = new ttypes.ActivateAppNoteSpeseRequest(args.request);
        }
    }
};
NoteSpeseFlowConfig_activateAppNoteSpese_args.prototype = {};
NoteSpeseFlowConfig_activateAppNoteSpese_args.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 1:
                if (ftype == Thrift.Type.STRUCT) {
                    this.auth = new ttypes.Auth();
                    this.auth.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.STRUCT) {
                    this.request = new ttypes.ActivateAppNoteSpeseRequest();
                    this.request.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

NoteSpeseFlowConfig_activateAppNoteSpese_args.prototype.write = function(output) {
    output.writeStructBegin('NoteSpeseFlowConfig_activateAppNoteSpese_args');
    if (this.auth !== null && this.auth !== undefined) {
        output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
        this.auth.write(output);
        output.writeFieldEnd();
    }
    if (this.request !== null && this.request !== undefined) {
        output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
        this.request.write(output);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var NoteSpeseFlowConfig_activateAppNoteSpese_result = function(args) {
    this.success = null;
    this.genericError = null;
    this.authenticationException = null;
    this.idNotFoundException = null;
    if (args instanceof ttypes.GenericError) {
        this.genericError = args;
        return;
    }
    if (args instanceof ttypes.AuthenticationException) {
        this.authenticationException = args;
        return;
    }
    if (args instanceof ttypes.IdNotFoundException) {
        this.idNotFoundException = args;
        return;
    }
    if (args) {
        if (args.success !== undefined && args.success !== null) {
            this.success = new ttypes.ActivateAppNoteSpeseResponse(args.success);
        }
        if (args.genericError !== undefined && args.genericError !== null) {
            this.genericError = args.genericError;
        }
        if (args.authenticationException !== undefined && args.authenticationException !== null) {
            this.authenticationException = args.authenticationException;
        }
        if (args.idNotFoundException !== undefined && args.idNotFoundException !== null) {
            this.idNotFoundException = args.idNotFoundException;
        }
    }
};
NoteSpeseFlowConfig_activateAppNoteSpese_result.prototype = {};
NoteSpeseFlowConfig_activateAppNoteSpese_result.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 0:
                if (ftype == Thrift.Type.STRUCT) {
                    this.success = new ttypes.ActivateAppNoteSpeseResponse();
                    this.success.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            case 1:
                if (ftype == Thrift.Type.STRUCT) {
                    this.genericError = new ttypes.GenericError();
                    this.genericError.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.STRUCT) {
                    this.authenticationException = new ttypes.AuthenticationException();
                    this.authenticationException.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            case 3:
                if (ftype == Thrift.Type.STRUCT) {
                    this.idNotFoundException = new ttypes.IdNotFoundException();
                    this.idNotFoundException.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

NoteSpeseFlowConfig_activateAppNoteSpese_result.prototype.write = function(output) {
    output.writeStructBegin('NoteSpeseFlowConfig_activateAppNoteSpese_result');
    if (this.success !== null && this.success !== undefined) {
        output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
        this.success.write(output);
        output.writeFieldEnd();
    }
    if (this.genericError !== null && this.genericError !== undefined) {
        output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
        this.genericError.write(output);
        output.writeFieldEnd();
    }
    if (this.authenticationException !== null && this.authenticationException !== undefined) {
        output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
        this.authenticationException.write(output);
        output.writeFieldEnd();
    }
    if (this.idNotFoundException !== null && this.idNotFoundException !== undefined) {
        output.writeFieldBegin('idNotFoundException', Thrift.Type.STRUCT, 3);
        this.idNotFoundException.write(output);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var NoteSpeseFlowConfigClient = exports.Client = function(output, pClass) {
    this.output = output;
    this.pClass = pClass;
    this._seqid = 0;
    this._reqs = {};
};
NoteSpeseFlowConfigClient.prototype = {};
NoteSpeseFlowConfigClient.prototype.seqid = function() { return this._seqid; };
NoteSpeseFlowConfigClient.prototype.new_seqid = function() { return this._seqid += 1; };
NoteSpeseFlowConfigClient.prototype.health = function(callback) {
    this._seqid = this.new_seqid();
    if (callback === undefined) {
        var _defer = Q.defer();
        this._reqs[this.seqid()] = function(error, result) {
            if (error) {
                _defer.reject(error);
            } else {
                _defer.resolve(result);
            }
        };
        this.send_health();
        return _defer.promise;
    } else {
        this._reqs[this.seqid()] = callback;
        this.send_health();
    }
};

NoteSpeseFlowConfigClient.prototype.send_health = function() {
    var output = new this.pClass(this.output);
    output.writeMessageBegin('health', Thrift.MessageType.CALL, this.seqid());
    var args = new NoteSpeseFlowConfig_health_args();
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
};

NoteSpeseFlowConfigClient.prototype.recv_health = function(input,mtype,rseqid) {
    var callback = this._reqs[rseqid] || function() {};
    delete this._reqs[rseqid];
    if (mtype == Thrift.MessageType.EXCEPTION) {
        var x = new Thrift.TApplicationException();
        x.read(input);
        input.readMessageEnd();
        return callback(x);
    }
    var result = new NoteSpeseFlowConfig_health_result();
    result.read(input);
    input.readMessageEnd();

    if (null !== result.success) {
        return callback(null, result.success);
    }
    return callback('health failed: unknown result');
};
NoteSpeseFlowConfigClient.prototype.isActive = function(auth, request, callback) {
    this._seqid = this.new_seqid();
    if (callback === undefined) {
        var _defer = Q.defer();
        this._reqs[this.seqid()] = function(error, result) {
            if (error) {
                _defer.reject(error);
            } else {
                _defer.resolve(result);
            }
        };
        this.send_isActive(auth, request);
        return _defer.promise;
    } else {
        this._reqs[this.seqid()] = callback;
        this.send_isActive(auth, request);
    }
};

NoteSpeseFlowConfigClient.prototype.send_isActive = function(auth, request) {
    var output = new this.pClass(this.output);
    output.writeMessageBegin('isActive', Thrift.MessageType.CALL, this.seqid());
    var params = {
        auth: auth,
        request: request
    };
    var args = new NoteSpeseFlowConfig_isActive_args(params);
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
};

NoteSpeseFlowConfigClient.prototype.recv_isActive = function(input,mtype,rseqid) {
    var callback = this._reqs[rseqid] || function() {};
    delete this._reqs[rseqid];
    if (mtype == Thrift.MessageType.EXCEPTION) {
        var x = new Thrift.TApplicationException();
        x.read(input);
        input.readMessageEnd();
        return callback(x);
    }
    var result = new NoteSpeseFlowConfig_isActive_result();
    result.read(input);
    input.readMessageEnd();

    if (null !== result.genericError) {
        return callback(result.genericError);
    }
    if (null !== result.authenticationException) {
        return callback(result.authenticationException);
    }
    if (null !== result.idNotFoundException) {
        return callback(result.idNotFoundException);
    }
    if (null !== result.success) {
        return callback(null, result.success);
    }
    return callback('isActive failed: unknown result');
};
NoteSpeseFlowConfigClient.prototype.isUsable = function(auth, request, callback) {
    this._seqid = this.new_seqid();
    if (callback === undefined) {
        var _defer = Q.defer();
        this._reqs[this.seqid()] = function(error, result) {
            if (error) {
                _defer.reject(error);
            } else {
                _defer.resolve(result);
            }
        };
        this.send_isUsable(auth, request);
        return _defer.promise;
    } else {
        this._reqs[this.seqid()] = callback;
        this.send_isUsable(auth, request);
    }
};

NoteSpeseFlowConfigClient.prototype.send_isUsable = function(auth, request) {
    var output = new this.pClass(this.output);
    output.writeMessageBegin('isUsable', Thrift.MessageType.CALL, this.seqid());
    var params = {
        auth: auth,
        request: request
    };
    var args = new NoteSpeseFlowConfig_isUsable_args(params);
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
};

NoteSpeseFlowConfigClient.prototype.recv_isUsable = function(input,mtype,rseqid) {
    var callback = this._reqs[rseqid] || function() {};
    delete this._reqs[rseqid];
    if (mtype == Thrift.MessageType.EXCEPTION) {
        var x = new Thrift.TApplicationException();
        x.read(input);
        input.readMessageEnd();
        return callback(x);
    }
    var result = new NoteSpeseFlowConfig_isUsable_result();
    result.read(input);
    input.readMessageEnd();

    if (null !== result.genericError) {
        return callback(result.genericError);
    }
    if (null !== result.authenticationException) {
        return callback(result.authenticationException);
    }
    if (null !== result.idNotFoundException) {
        return callback(result.idNotFoundException);
    }
    if (null !== result.success) {
        return callback(null, result.success);
    }
    return callback('isUsable failed: unknown result');
};
NoteSpeseFlowConfigClient.prototype.getConfig = function(auth, request, callback) {
    this._seqid = this.new_seqid();
    if (callback === undefined) {
        var _defer = Q.defer();
        this._reqs[this.seqid()] = function(error, result) {
            if (error) {
                _defer.reject(error);
            } else {
                _defer.resolve(result);
            }
        };
        this.send_getConfig(auth, request);
        return _defer.promise;
    } else {
        this._reqs[this.seqid()] = callback;
        this.send_getConfig(auth, request);
    }
};

NoteSpeseFlowConfigClient.prototype.send_getConfig = function(auth, request) {
    var output = new this.pClass(this.output);
    output.writeMessageBegin('getConfig', Thrift.MessageType.CALL, this.seqid());
    var params = {
        auth: auth,
        request: request
    };
    var args = new NoteSpeseFlowConfig_getConfig_args(params);
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
};

NoteSpeseFlowConfigClient.prototype.recv_getConfig = function(input,mtype,rseqid) {
    var callback = this._reqs[rseqid] || function() {};
    delete this._reqs[rseqid];
    if (mtype == Thrift.MessageType.EXCEPTION) {
        var x = new Thrift.TApplicationException();
        x.read(input);
        input.readMessageEnd();
        return callback(x);
    }
    var result = new NoteSpeseFlowConfig_getConfig_result();
    result.read(input);
    input.readMessageEnd();

    if (null !== result.genericError) {
        return callback(result.genericError);
    }
    if (null !== result.authenticationException) {
        return callback(result.authenticationException);
    }
    if (null !== result.idNotFoundException) {
        return callback(result.idNotFoundException);
    }
    if (null !== result.success) {
        return callback(null, result.success);
    }
    return callback('getConfig failed: unknown result');
};
NoteSpeseFlowConfigClient.prototype.listConfigs = function(auth, request, callback) {
    this._seqid = this.new_seqid();
    if (callback === undefined) {
        var _defer = Q.defer();
        this._reqs[this.seqid()] = function(error, result) {
            if (error) {
                _defer.reject(error);
            } else {
                _defer.resolve(result);
            }
        };
        this.send_listConfigs(auth, request);
        return _defer.promise;
    } else {
        this._reqs[this.seqid()] = callback;
        this.send_listConfigs(auth, request);
    }
};

NoteSpeseFlowConfigClient.prototype.send_listConfigs = function(auth, request) {
    var output = new this.pClass(this.output);
    output.writeMessageBegin('listConfigs', Thrift.MessageType.CALL, this.seqid());
    var params = {
        auth: auth,
        request: request
    };
    var args = new NoteSpeseFlowConfig_listConfigs_args(params);
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
};

NoteSpeseFlowConfigClient.prototype.recv_listConfigs = function(input,mtype,rseqid) {
    var callback = this._reqs[rseqid] || function() {};
    delete this._reqs[rseqid];
    if (mtype == Thrift.MessageType.EXCEPTION) {
        var x = new Thrift.TApplicationException();
        x.read(input);
        input.readMessageEnd();
        return callback(x);
    }
    var result = new NoteSpeseFlowConfig_listConfigs_result();
    result.read(input);
    input.readMessageEnd();

    if (null !== result.genericError) {
        return callback(result.genericError);
    }
    if (null !== result.authenticationException) {
        return callback(result.authenticationException);
    }
    if (null !== result.idNotFoundException) {
        return callback(result.idNotFoundException);
    }
    if (null !== result.success) {
        return callback(null, result.success);
    }
    return callback('listConfigs failed: unknown result');
};
NoteSpeseFlowConfigClient.prototype.activateAppNoteSpese = function(auth, request, callback) {
    this._seqid = this.new_seqid();
    if (callback === undefined) {
        var _defer = Q.defer();
        this._reqs[this.seqid()] = function(error, result) {
            if (error) {
                _defer.reject(error);
            } else {
                _defer.resolve(result);
            }
        };
        this.send_activateAppNoteSpese(auth, request);
        return _defer.promise;
    } else {
        this._reqs[this.seqid()] = callback;
        this.send_activateAppNoteSpese(auth, request);
    }
};

NoteSpeseFlowConfigClient.prototype.send_activateAppNoteSpese = function(auth, request) {
    var output = new this.pClass(this.output);
    output.writeMessageBegin('activateAppNoteSpese', Thrift.MessageType.CALL, this.seqid());
    var params = {
        auth: auth,
        request: request
    };
    var args = new NoteSpeseFlowConfig_activateAppNoteSpese_args(params);
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
};

NoteSpeseFlowConfigClient.prototype.recv_activateAppNoteSpese = function(input,mtype,rseqid) {
    var callback = this._reqs[rseqid] || function() {};
    delete this._reqs[rseqid];
    if (mtype == Thrift.MessageType.EXCEPTION) {
        var x = new Thrift.TApplicationException();
        x.read(input);
        input.readMessageEnd();
        return callback(x);
    }
    var result = new NoteSpeseFlowConfig_activateAppNoteSpese_result();
    result.read(input);
    input.readMessageEnd();

    if (null !== result.genericError) {
        return callback(result.genericError);
    }
    if (null !== result.authenticationException) {
        return callback(result.authenticationException);
    }
    if (null !== result.idNotFoundException) {
        return callback(result.idNotFoundException);
    }
    if (null !== result.success) {
        return callback(null, result.success);
    }
    return callback('activateAppNoteSpese failed: unknown result');
};
var NoteSpeseFlowConfigProcessor = exports.Processor = function(handler) {
        this._handler = handler;
    }
    ;
NoteSpeseFlowConfigProcessor.prototype.process = function(input, output) {
    var r = input.readMessageBegin();
    if (this['process_' + r.fname]) {
        return this['process_' + r.fname].call(this, r.rseqid, input, output);
    } else {
        input.skip(Thrift.Type.STRUCT);
        input.readMessageEnd();
        var x = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN_METHOD, 'Unknown function ' + r.fname);
        output.writeMessageBegin(r.fname, Thrift.MessageType.EXCEPTION, r.rseqid);
        x.write(output);
        output.writeMessageEnd();
        output.flush();
    }
}
;
NoteSpeseFlowConfigProcessor.prototype.process_health = function(seqid, input, output) {
    var args = new NoteSpeseFlowConfig_health_args();
    args.read(input);
    input.readMessageEnd();
    if (this._handler.health.length === 0) {
        Q.fcall(this._handler.health.bind(this._handler))
            .then(function(result) {
                var result_obj = new NoteSpeseFlowConfig_health_result({success: result});
                output.writeMessageBegin("health", Thrift.MessageType.REPLY, seqid);
                result_obj.write(output);
                output.writeMessageEnd();
                output.flush();
            }, function (err) {
                var result;
                result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("health", Thrift.MessageType.EXCEPTION, seqid);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
            });
    } else {
        this._handler.health(function (err, result) {
            var result_obj;
            if ((err === null || typeof err === 'undefined')) {
                result_obj = new NoteSpeseFlowConfig_health_result((err !== null || typeof err === 'undefined') ? err : {success: result});
                output.writeMessageBegin("health", Thrift.MessageType.REPLY, seqid);
            } else {
                result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("health", Thrift.MessageType.EXCEPTION, seqid);
            }
            result_obj.write(output);
            output.writeMessageEnd();
            output.flush();
        });
    }
};
NoteSpeseFlowConfigProcessor.prototype.process_isActive = function(seqid, input, output) {
    var args = new NoteSpeseFlowConfig_isActive_args();
    args.read(input);
    input.readMessageEnd();
    if (this._handler.isActive.length === 2) {
        Q.fcall(this._handler.isActive.bind(this._handler), args.auth, args.request)
            .then(function(result) {
                var result_obj = new NoteSpeseFlowConfig_isActive_result({success: result});
                output.writeMessageBegin("isActive", Thrift.MessageType.REPLY, seqid);
                result_obj.write(output);
                output.writeMessageEnd();
                output.flush();
            }, function (err) {
                var result;
                if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.IdNotFoundException) {
                    result = new NoteSpeseFlowConfig_isActive_result(err);
                    output.writeMessageBegin("isActive", Thrift.MessageType.REPLY, seqid);
                } else {
                    result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("isActive", Thrift.MessageType.EXCEPTION, seqid);
                }
                result.write(output);
                output.writeMessageEnd();
                output.flush();
            });
    } else {
        this._handler.isActive(args.auth, args.request, function (err, result) {
            var result_obj;
            if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.IdNotFoundException) {
                result_obj = new NoteSpeseFlowConfig_isActive_result((err !== null || typeof err === 'undefined') ? err : {success: result});
                output.writeMessageBegin("isActive", Thrift.MessageType.REPLY, seqid);
            } else {
                result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("isActive", Thrift.MessageType.EXCEPTION, seqid);
            }
            result_obj.write(output);
            output.writeMessageEnd();
            output.flush();
        });
    }
};
NoteSpeseFlowConfigProcessor.prototype.process_isUsable = function(seqid, input, output) {
    var args = new NoteSpeseFlowConfig_isUsable_args();
    args.read(input);
    input.readMessageEnd();
    if (this._handler.isUsable.length === 2) {
        Q.fcall(this._handler.isUsable.bind(this._handler), args.auth, args.request)
            .then(function(result) {
                var result_obj = new NoteSpeseFlowConfig_isUsable_result({success: result});
                output.writeMessageBegin("isUsable", Thrift.MessageType.REPLY, seqid);
                result_obj.write(output);
                output.writeMessageEnd();
                output.flush();
            }, function (err) {
                var result;
                if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.IdNotFoundException) {
                    result = new NoteSpeseFlowConfig_isUsable_result(err);
                    output.writeMessageBegin("isUsable", Thrift.MessageType.REPLY, seqid);
                } else {
                    result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("isUsable", Thrift.MessageType.EXCEPTION, seqid);
                }
                result.write(output);
                output.writeMessageEnd();
                output.flush();
            });
    } else {
        this._handler.isUsable(args.auth, args.request, function (err, result) {
            var result_obj;
            if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.IdNotFoundException) {
                result_obj = new NoteSpeseFlowConfig_isUsable_result((err !== null || typeof err === 'undefined') ? err : {success: result});
                output.writeMessageBegin("isUsable", Thrift.MessageType.REPLY, seqid);
            } else {
                result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("isUsable", Thrift.MessageType.EXCEPTION, seqid);
            }
            result_obj.write(output);
            output.writeMessageEnd();
            output.flush();
        });
    }
};
NoteSpeseFlowConfigProcessor.prototype.process_getConfig = function(seqid, input, output) {
    var args = new NoteSpeseFlowConfig_getConfig_args();
    args.read(input);
    input.readMessageEnd();
    if (this._handler.getConfig.length === 2) {
        Q.fcall(this._handler.getConfig.bind(this._handler), args.auth, args.request)
            .then(function(result) {
                var result_obj = new NoteSpeseFlowConfig_getConfig_result({success: result});
                output.writeMessageBegin("getConfig", Thrift.MessageType.REPLY, seqid);
                result_obj.write(output);
                output.writeMessageEnd();
                output.flush();
            }, function (err) {
                var result;
                if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.IdNotFoundException) {
                    result = new NoteSpeseFlowConfig_getConfig_result(err);
                    output.writeMessageBegin("getConfig", Thrift.MessageType.REPLY, seqid);
                } else {
                    result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("getConfig", Thrift.MessageType.EXCEPTION, seqid);
                }
                result.write(output);
                output.writeMessageEnd();
                output.flush();
            });
    } else {
        this._handler.getConfig(args.auth, args.request, function (err, result) {
            var result_obj;
            if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.IdNotFoundException) {
                result_obj = new NoteSpeseFlowConfig_getConfig_result((err !== null || typeof err === 'undefined') ? err : {success: result});
                output.writeMessageBegin("getConfig", Thrift.MessageType.REPLY, seqid);
            } else {
                result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("getConfig", Thrift.MessageType.EXCEPTION, seqid);
            }
            result_obj.write(output);
            output.writeMessageEnd();
            output.flush();
        });
    }
};
NoteSpeseFlowConfigProcessor.prototype.process_listConfigs = function(seqid, input, output) {
    var args = new NoteSpeseFlowConfig_listConfigs_args();
    args.read(input);
    input.readMessageEnd();
    if (this._handler.listConfigs.length === 2) {
        Q.fcall(this._handler.listConfigs.bind(this._handler), args.auth, args.request)
            .then(function(result) {
                var result_obj = new NoteSpeseFlowConfig_listConfigs_result({success: result});
                output.writeMessageBegin("listConfigs", Thrift.MessageType.REPLY, seqid);
                result_obj.write(output);
                output.writeMessageEnd();
                output.flush();
            }, function (err) {
                var result;
                if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.IdNotFoundException) {
                    result = new NoteSpeseFlowConfig_listConfigs_result(err);
                    output.writeMessageBegin("listConfigs", Thrift.MessageType.REPLY, seqid);
                } else {
                    result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("listConfigs", Thrift.MessageType.EXCEPTION, seqid);
                }
                result.write(output);
                output.writeMessageEnd();
                output.flush();
            });
    } else {
        this._handler.listConfigs(args.auth, args.request, function (err, result) {
            var result_obj;
            if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.IdNotFoundException) {
                result_obj = new NoteSpeseFlowConfig_listConfigs_result((err !== null || typeof err === 'undefined') ? err : {success: result});
                output.writeMessageBegin("listConfigs", Thrift.MessageType.REPLY, seqid);
            } else {
                result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("listConfigs", Thrift.MessageType.EXCEPTION, seqid);
            }
            result_obj.write(output);
            output.writeMessageEnd();
            output.flush();
        });
    }
};
NoteSpeseFlowConfigProcessor.prototype.process_activateAppNoteSpese = function(seqid, input, output) {
    var args = new NoteSpeseFlowConfig_activateAppNoteSpese_args();
    args.read(input);
    input.readMessageEnd();
    if (this._handler.activateAppNoteSpese.length === 2) {
        Q.fcall(this._handler.activateAppNoteSpese.bind(this._handler), args.auth, args.request)
            .then(function(result) {
                var result_obj = new NoteSpeseFlowConfig_activateAppNoteSpese_result({success: result});
                output.writeMessageBegin("activateAppNoteSpese", Thrift.MessageType.REPLY, seqid);
                result_obj.write(output);
                output.writeMessageEnd();
                output.flush();
            }, function (err) {
                var result;
                if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.IdNotFoundException) {
                    result = new NoteSpeseFlowConfig_activateAppNoteSpese_result(err);
                    output.writeMessageBegin("activateAppNoteSpese", Thrift.MessageType.REPLY, seqid);
                } else {
                    result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("activateAppNoteSpese", Thrift.MessageType.EXCEPTION, seqid);
                }
                result.write(output);
                output.writeMessageEnd();
                output.flush();
            });
    } else {
        this._handler.activateAppNoteSpese(args.auth, args.request, function (err, result) {
            var result_obj;
            if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.IdNotFoundException) {
                result_obj = new NoteSpeseFlowConfig_activateAppNoteSpese_result((err !== null || typeof err === 'undefined') ? err : {success: result});
                output.writeMessageBegin("activateAppNoteSpese", Thrift.MessageType.REPLY, seqid);
            } else {
                result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("activateAppNoteSpese", Thrift.MessageType.EXCEPTION, seqid);
            }
            result_obj.write(output);
            output.writeMessageEnd();
            output.flush();
        });
    }
};
