import React from "react";
import PropTypes from "prop-types";

import { Button } from "@ts-digital/vrc";
import { FormattedHTMLMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faPen } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import { SectionTitleContainer, Title } from "./styled";

const SectionTitle = ({ editable, title, linkTo, defaultButtonLabel }) => {
    let buttonLabel = defaultButtonLabel || (editable ? "general.edit" : "general.view");

    return (
        <SectionTitleContainer>
            <Title>{title}</Title>
            <Link to={linkTo}>
                <Button kind="tertiary">
                    <FontAwesomeIcon icon={editable ? faPen : faFile} /> <FormattedHTMLMessage id={buttonLabel} />
                </Button>
            </Link>
        </SectionTitleContainer>
    );
};

SectionTitle.propTypes = {
    editable: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    linkTo: PropTypes.string,
    defaultButtonLabel: PropTypes.string
};

export default SectionTitle;
