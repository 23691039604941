import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";

import { InitialsSpan } from "./styled";

export const Initials = ({ name, loading, size, fontSize, newComponent = false }) => {
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();

    return (
        <InitialsSpan size={size} fontSize={fontSize} newComponent={newComponent}>
            {loading ? <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" /> : initials}
        </InitialsSpan>
    );
};

Initials.propTypes = {
    loading: PropTypes.bool,
    name: PropTypes.string.isRequired,
    fontSize: PropTypes.string,
    size: PropTypes.string.isRequired
};

export default Initials;
