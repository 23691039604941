import React from "react";
import PropTypes from "prop-types";

import "./style.css";

import ConnectionList from "../connection-list";
import StatusTag from "../../general/status-tag";
import AddConnectionButton from "./add-connection-button";
import { FormattedMessage } from "react-intl";

const statusTagMapping = {
    VALIDATED: {
        circle: "green",
        label: "c-item.status.tag.VALIDATED",
        style: "green"
    },
    UNVERIFIABLE: {
        circle: "grey",
        label: "c-item.status.tag.UNVERIFIABLE",
        style: "grey"
    },
    VERIFIED_PENDING_VALIDATE: {
        circle: "orange",
        label: "c-item.status.tag.VERIFIED_PENDING_VALIDATE",
        style: "orange"
    },
    UNVERIFIABLE_PENDING_VALIDATE: {
        circle: "orange",
        label: "c-item.status.tag.UNVERIFIABLE_PENDING_VALIDATE",
        style: "orange"
    },
    REJECTED: {
        circle: "red",
        label: "c-item.status.tag.REJECTED",
        style: "red"
    },
    REJECTED_PENDING_VALIDATE: {
        circle: "orange",
        label: "c-item.status.tag.REJECTED_PENDING_VALIDATE",
        style: "orange"
    }
};

const LinkElement = ({
    agyoServices,
    addConnection,
    certification,
    description,
    displayAddConnection,
    getCertification,
    history,
    itemId,
    link,
    onDeleteConnection,
    itemServices,
    roles,
    settingsPageEnabled,
    statusLabel,
    id,
    taxId,
    vatNumber
}) => {
    return (
        <div className="link-item" key={"list-item"}>
            <div className="item-header">
                <div className="header-item">
                    <div className="header-section">
                        <p className="business-name">{description}</p>
                        <StatusTag {...statusTagMapping[statusLabel]} />
                    </div>
                    <div className="header-section">
                        <FormattedMessage id="general.CF-value" values={{ taxId }} />
                        &nbsp;&nbsp;
                        {vatNumber ? <FormattedMessage id="general.PIVA-value" values={{ vatNumber }} /> : null}
                    </div>
                </div>
                <div className="header-item">
                    <AddConnectionButton display={displayAddConnection} itemId={itemId} managedId={id} />
                </div>
            </div>
            <div className="item-content">
                <ConnectionList
                    agyoServices={agyoServices}
                    certification={certification}
                    connectionList={link.connections}
                    getCertification={getCertification}
                    history={history}
                    itemServices={itemServices && itemServices.services[link.managedId]}
                    itemServicesEnded={itemServices && itemServices.status.ended}
                    onDeleteConnection={onDeleteConnection}
                    settingsPageEnabled={settingsPageEnabled}
                    isAllowedToManage={
                        roles &&
                        roles.find(r => {
                            return r.resourceId === id;
                        }) !== undefined
                    }
                />
            </div>
        </div>
    );
};

LinkElement.propTypes = {
    agyoServices: PropTypes.array,
    addConnection: PropTypes.node,
    certification: PropTypes.object,
    description: PropTypes.string,
    displayAddConnection: PropTypes.bool,
    getCertification: PropTypes.func.isRequired,
    history: PropTypes.object,
    itemId: PropTypes.string.isRequired,
    link: PropTypes.object.isRequired,
    onDeleteConnection: PropTypes.func,
    itemServices: PropTypes.object,
    settingsPageEnabled: PropTypes.bool,
    statusLabel: PropTypes.string,
    taxId: PropTypes.string,
    roles: PropTypes.array
};

export default LinkElement;
