import React from "react";
import PropTypes from "prop-types";

import { CheckIcon, IconCell, SelectedItemsCell, TotalItemsCell } from "./styled";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FormattedMessage } from "react-intl";

const TableFooter = ({ selectedCount, totalItems }) => (
    <>
        <IconCell>{selectedCount > 0 && <CheckIcon icon={faCheck} />}</IconCell>
        <SelectedItemsCell data-cy="selected-items-total-container">
            {selectedCount > 0 && (
                <>
                    <b>{selectedCount}</b>{" "}
                    <FormattedMessage
                        id={
                            selectedCount === 1
                                ? "v-user-creation-flow.steps.select-items.table-footer.selected-item"
                                : "v-user-creation-flow.steps.select-items.table-footer.selected-items"
                        }
                    />
                </>
            )}
        </SelectedItemsCell>
        <TotalItemsCell colSpan={4} data-cy="items-total-container">
            <b>
                <FormattedMessage id="v-user-creation-flow.steps.select-items.table-footer.total-items" />: {totalItems}
            </b>
        </TotalItemsCell>
    </>
);

TableFooter.propTypes = {
    selectedCount: PropTypes.number,
    totalItems: PropTypes.number
};

TableFooter.defaultProps = {
    selectedCount: 0,
    totalItems: 0
};

export default TableFooter;
