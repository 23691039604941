import React, { useState } from "react";
import { OverusedMessage } from "./styled";
import { FormattedHTMLMessage } from "react-intl";
import { useEffect } from "react";

export const Text = ({ consumed, capacity, usagePercentage }) => {
    const [overused, setOverused] = useState(false);
    const [remaining, setRemaining] = useState(capacity - consumed);

    useEffect(() => {
        setOverused(consumed > capacity);
    }, [capacity, consumed]);

    useEffect(() => {
        setRemaining(capacity - consumed);
    }, [capacity, consumed]);

    return overused ? (
        <OverusedMessage>
            <FormattedHTMLMessage
                id="metering.billing.invoices.overused"
                values={{
                    extra: Math.abs(remaining),
                    capacity
                }}
            />
        </OverusedMessage>
    ) : (
        <FormattedHTMLMessage
            id="metering.billing.invoices.remaining"
            values={{
                remaining,
                percentage: usagePercentage.toFixed(2)
            }}
        />
    );
};
