import styled from "styled-components";

import LoadingButton from "../../components/general/loading-button";

export const Button = styled(LoadingButton)`
    min-width: 200px;
    margin-left: 20px;
`;

export const ButtonsContainer = styled.div`
    grid-column-start: 5;
    grid-column-end: 6;
    background-color: #ecf0f5;
    display: flex;
    justify-content: flex-end;
    padding: 30px 20px;
`;

export const LeftCentralColumn = styled.div`
    grid-column-start: 1;
    grid-column-end: 4;
    margin: 0 10px 10px 0;
`;

export const LeftColumn = styled.div`
    grid-column-start: 1;
    grid-column-end: 3;
    margin: 0 20px 10px 0;
`;

export const FormData = styled.div`
    display: grid;
    grid-template-columns:
        calc(100% / 6) calc(100% / 6) calc(100% / 6) calc(100% / 6)
        calc(100% / 6) calc(100% / 6);
    padding: 20px;
    justify-items: stretch;

    & .ant-form-item-label {
        line-height: 20px;
    }

    & .ant-form-item-label label:after {
        content: "";
    }

    & .ant-form-item {
        margin: 0px;
    }
`;
