import {
    CBCR_CONFIG_START,
    CBCR_CONFIG_ERROR,
    CBCR_CONFIG_SUCCESS,
    CBCR_CONFIG_RESET
} from "../../actions/services/cbcr";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export default function cbCr(state = defaultState, { type, payload, error }) {
    switch (type) {
        case CBCR_CONFIG_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case CBCR_CONFIG_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                config: payload
            };
        case CBCR_CONFIG_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case CBCR_CONFIG_RESET:
            return defaultState;

        default:
            return state;
    }
}
