import React from "react";
import PropTypes from "prop-types";

import { Tooltip } from "antd";

import "./style.css";
import { FormattedMessage } from "react-intl";

const CLASS_STATUS_UNREAD = "fas unread";
const CLASS_STATUS_READ = "far read";
const CLASS_STATUS_NEEDS_INTERACTION = "fas needs-interaction";

const StatusIcon = ({ needsInteraction, onClick, read }) => {
    let cssClass = CLASS_STATUS_UNREAD;
    let tooltipTextKey = "unread";

    if (read) {
        cssClass = CLASS_STATUS_READ;
        tooltipTextKey = "read";
    }

    if (needsInteraction) {
        cssClass = CLASS_STATUS_NEEDS_INTERACTION;
        tooltipTextKey = "needsInteraction";
    }

    return (
        <Tooltip
            placement="left"
            title={<FormattedMessage id={`c-notification.status-icon.tooltip.${tooltipTextKey}`} />}
            overlayClassName="c-status-icon-tooltip"
        >
            <i className={`c-status-icon fa-circle ${cssClass}`} onClick={() => onClick(needsInteraction, read)} />
        </Tooltip>
    );
};

StatusIcon.propTypes = {
    needsInteraction: PropTypes.bool,
    onClick: PropTypes.func,
    read: PropTypes.bool
};

StatusIcon.defaultProps = {
    onClick: (needsInteraction, read) => {}
};

export default StatusIcon;
