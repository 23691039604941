import styled from "styled-components";

import LoadingButton from "../../general/loading-button";

export const GenerateContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0px 110px 0px 70px;
`;

export const LoadingButtonStyled = styled(LoadingButton)`
    width: 310px;
`;
