import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { FullPageSpinner } from "@ts-digital/arc/antd";

import { activateUser, resetActivateUser } from "../../actions/user";
import { useFeedbackPage } from "../../hooks/use-feedback-page";

export const UserActivation = ({ activateUser, activationStatus, history, match, resetActivateUser }) => {
    useFeedbackPage(match.url, history, activationStatus, resetActivateUser);

    useEffect(() => {
        const params = new URLSearchParams(window.location.href.split("?")[1]);
        activateUser(match.params.userId, params.get("activationToken"));
    }, [match.params.userId, activateUser]);

    return <FullPageSpinner show={true} />;
};

UserActivation.propTypes = {
    activateUser: PropTypes.func.isRequired,
    activationStatus: PropTypes.shape({
        ended: PropTypes.bool,
        error: PropTypes.bool
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            userId: PropTypes.string.isRequired
        }).isRequired
    }).isRequired
};

export default connect(state => ({ activationStatus: state.activateUser }), { activateUser, resetActivateUser })(
    UserActivation
);
