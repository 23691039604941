import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Button } from "antd";
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from "react-intl";
import ConnectionsSettingsSignatureType from "../../connections-settings/connections-settings-signature-type";
import "./style.css";

const locale = {
    emptyText: <FormattedMessage id="general.no-result" />
};

export class Sdi extends Component {
    static propTypes = {
        updateConfigs: PropTypes.func,
        items: PropTypes.array,
        sdi: PropTypes.object.isRequired,
        handleClose: PropTypes.func
    };

    constructor(props) {
        super(props);
        let dataSource = this.getData(props);

        this.state = {
            dataSource,
            sdi: {
                help: "",
                status: ""
            }
        };
    }

    componentDidUpdate(prevProps) {
        if (!prevProps || !prevProps.items || this.props.items.length !== prevProps.items.length) {
            let dataSource = this.getData(this.props);
            this.setState({
                dataSource
            });
        }
    }

    getData(props) {
        const { sdi, items } = props;
        let data = sdi && sdi.configs ? sdi.configs : {};
        let rows = [];
        for (const config in data) {
            const itemId = data[config].itemId;

            const item = items
                ? items.find(i => {
                      return i.item.base.id === itemId;
                  })
                : null;

            if (item) {
                data[config].itemDescription = item.item.base.details.description;
                rows.push({ ...data[config] });
            }
        }
        return rows;
    }

    handleSubmit() {
        const { dataSource } = this.state;
        const { updateConfigs, sdi, handleClose } = this.props;
        let configs = [];
        dataSource.forEach(config => {
            const { itemId, ownerId, signatureType } = config;
            if (signatureType !== sdi.configs[itemId].signatureType) {
                const extraData = {};
                extraData["signatureType"] = signatureType;
                configs.push({
                    itemId,
                    ownerId,
                    extraData
                });

                sdi.configs[config.itemId].signatureType = config.signatureType;
            }
        });

        if (configs.length > 0) {
            updateConfigs(configs);
        }
        handleClose();
    }

    handleSignatureChange = (record, newValue) => {
        const { dataSource } = this.state;

        dataSource.forEach(config => {
            if (config.itemId === record.itemId && config.ownerId === record.ownerId) {
                config.signatureType = newValue;
            }
        });

        this.setState({
            dataSource
        });
    };

    render() {
        const { intl } = this.props;

        const columns = [
            {
                title: intl.formatMessage({
                    id: "general.codice-fiscale"
                }),
                dataIndex: "itemId",
                rowKey: "itemId"
            },
            {
                title: intl.formatMessage({
                    id: "general.name"
                }),
                dataIndex: "itemDescription",
                key: "itemDescription"
            },
            {
                title: intl.formatMessage({
                    id: "apps.SIG.title"
                }),
                dataIndex: "signatureType",
                render: (value, record) => (
                    <ConnectionsSettingsSignatureType
                        className="c-sdi-action"
                        onChange={newValue => this.handleSignatureChange(record, newValue)}
                        display={true}
                        disabled={false}
                        value={value}
                    />
                )
            }
        ];

        const { dataSource } = this.state;

        return dataSource ? (
            <div className="c-sdi">
                <FormattedHTMLMessage id="c-sdi-config.text" />
                <br />
                <Table
                    columns={columns}
                    loading={dataSource.length === 0}
                    dataSource={dataSource}
                    locale={locale}
                    rowKey={"itemId"}
                    bordered
                />
                <div className="save-button-container">
                    <Button type="secondary" onClick={() => this.handleSubmit()}>
                        <FormattedMessage id="general.save" />
                    </Button>
                </div>
            </div>
        ) : null;
    }
}

export default injectIntl(Sdi);
