import { API_URL } from "../config";

import { getRestCall } from "../utils/rest-api-call";

export const GET_RELEASENOTES_DATE_START = "GET_RELEASENOTES_DATE_START";
export const GET_RELEASENOTES_DATE_ERROR = "GET_RELEASENOTES_DATE_ERROR";
export const GET_RELEASENOTES_DATE_SUCCESS = "GET_RELEASENOTES_DATE_SUCCESS";

export function getLastReleaseNoteDate(tags) {
    return dispatch => {
        dispatch({
            type: GET_RELEASENOTES_DATE_START
        });

        getRestCall(`${API_URL}/releasenotes/latest`, null, {
            tags
        })
            .then(result => {
                if (result.error) {
                    dispatch({
                        type: GET_RELEASENOTES_DATE_ERROR,
                        error: result.message
                    });
                } else {
                    dispatch({
                        type: GET_RELEASENOTES_DATE_SUCCESS,
                        payload: { date: new Date(result.lastDate), tags }
                    });
                }
            })
            .catch(err => {
                dispatch({
                    type: GET_RELEASENOTES_DATE_ERROR,
                    error: err
                });
            });
    };
}
