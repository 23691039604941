import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "antd";

export const Container = styled.div`
    position: relative;

    & input {
        padding-right: 32px;
    }
`;

export const SearchIconContainer = styled(FontAwesomeIcon)`
    color: ${props =>
        props.active === "true" ? props.theme.colors.primary.richElectricBlue : props.theme.colors.primary.cadet};
    cursor: ${props => (props.active === "true" ? "pointer" : "")};
    font-size: 16px;
    height: 16px;
    position: absolute;
    right: 8px;
    top: 12px;
    width: 16px;

    transition: 0.1s color ease;

    &:hover {
        color: ${props => (props.active ? props.theme.colors.primary.cyanProgress : props.theme.colors.primary.cadet)};
    }
`;

export const StyledInput = styled(Input)`
    border: none !important;
    background-color: #f2f5f8 !important;
    height: 40px !important;
    border-radius: initial !important;

    :hover {
        border: none !important;
        outline: none !important;
    }

    :focus {
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
    }
`;
