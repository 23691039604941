import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import ElevioElements from "../../../elevio-elements";
import { Button } from "@ts-digital/vrc";

export class UploadButton extends Component {
    static propTypes = {
        itemId: PropTypes.string.isRequired,
        messageId: PropTypes.string.isRequired,
        elevioId: PropTypes.string.isRequired
    };

    render() {
        const { itemId, messageId, elevioId } = this.props;
        return (
            <div
                style={{
                    display: "inline-block",
                    marginTop: "10px"
                }}
            >
                <Link to={`/${itemId}/company/validate`}>
                    <Button kind="primary">
                        <FormattedMessage id={messageId} />
                    </Button>
                </Link>
                <ElevioElements idArticle={elevioId} />
            </div>
        );
    }
}
export default UploadButton;
