import React from "react";
import PropTypes from "prop-types";

import { useIntl } from "react-intl";

import SearchBar from "../../../components/general/search-bar";
import { Item, ItemsContainer, Container, ClassifierLabel, FlexContainer } from "./styled";
import LoaderItem from "../../../components/general/loader-item";

const ItemList = ({
    activeItem,
    onItemSelected,
    items: { call: loadItemList, status: itemListLoadingStatus, page: itemList },
    currentCompanyData,
    isStudio
}) => {
    const intl = useIntl();
    const studioData = {
        id: currentCompanyData.itemId,
        uuid: currentCompanyData.itemUuid,
        description: currentCompanyData.description,
        workspaceId: currentCompanyData.workspaceId,
        localServices: currentCompanyData.localServices,
        classifier: currentCompanyData.classifier
    };

    const handleSearch = value => {
        onItemSelected(studioData); // we select the studio before we start a new search
        loadItemList(value, null, 0, 10);
    };

    return (
        <Container>
            <FlexContainer>
                <ClassifierLabel>
                    {intl.formatMessage({
                        id: isStudio ? "general.classifiers.STUDIO" : "general.classifiers.COMPANY"
                    })}
                </ClassifierLabel>
                <Item selected={activeItem.id === currentCompanyData.itemId} onClick={() => onItemSelected(studioData)}>
                    {currentCompanyData.description}
                </Item>
            </FlexContainer>
            {isStudio ? (
                <FlexContainer>
                    <ClassifierLabel>
                        {intl.formatMessage({
                            id: "general.clienti"
                        })}
                    </ClassifierLabel>
                    <div>
                        <SearchBar
                            placeholder={intl.formatMessage({
                                id: "v-user-creation-flow.steps.set-permissions.sidebar.search-item-placeholder"
                            })}
                            onSearch={handleSearch}
                            data-cy="item-searchbar"
                        />
                        <ItemsContainer data-cy="items-container">
                            {itemListLoadingStatus.ended
                                ? itemList
                                      .filter(item => {
                                          if (item.itemId === currentCompanyData.itemId) return false;
                                          return true;
                                      })
                                      .map(item => (
                                          <Item
                                              key={`item-list-${item.itemId}`}
                                              selected={activeItem.uuid === item.itemUuid}
                                              onClick={() =>
                                                  onItemSelected({
                                                      id: item.itemId,
                                                      uuid: item.itemUuid,
                                                      description: item.description,
                                                      workspaceId: item.workspaceId,
                                                      localServices: item.localServiceEnabled,
                                                      classifier: item.classifier
                                                  })
                                              }
                                          >
                                              {item.description}
                                          </Item>
                                      ))
                                : Array.from(Array(10).keys()).map(i => (
                                      <LoaderItem key={i} width="100%" height="32px" margin="5px 0" />
                                  ))}
                        </ItemsContainer>
                    </div>
                </FlexContainer>
            ) : null}
        </Container>
    );
};

ItemList.propTypes = {
    activeItem: PropTypes.object.isRequired,
    onItemSelected: PropTypes.func.isRequired,
    selectedItems: PropTypes.arrayOf(
        PropTypes.shape({
            classifier: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            itemId: PropTypes.string.isRequired,
            itemUuid: PropTypes.string.isRequired,
            taxId: PropTypes.string.isRequired,
            vatNumber: PropTypes.string.isRequired
        })
    ).isRequired
};

export default ItemList;
