import {
    VERIFY_CCT_START,
    VERIFY_CCT_ERROR,
    VERIFY_CCT_SUCCESS,
    VERIFY_CCT_RESET,
    FETCH_CCT_CONFIG_START,
    FETCH_CCT_CONFIG_ERROR,
    FETCH_CCT_CONFIG_SUCCESS,
    FETCH_CCT_CONFIG_RESET,
    SAVE_CCT_CONFIG_START,
    SAVE_CCT_CONFIG_SUCCESS,
    SAVE_CCT_CONFIG_ERROR,
    FETCH_CCT_CONFIG_MANAGED_START,
    FETCH_CCT_CONFIG_MANAGED_ERROR,
    FETCH_CCT_CONFIG_MANAGED_SUCCESS,
    ENABLE_CCT_CONFIG_MANAGED_START,
    ENABLE_CCT_CONFIG_MANAGED_ERROR,
    ENABLE_CCT_CONFIG_MANAGED_SUCCESS,
    DISABLE_CCT_CONFIG_MANAGED_START,
    DISABLE_CCT_CONFIG_MANAGED_ERROR,
    DISABLE_CCT_CONFIG_MANAGED_SUCCESS
} from "../../actions/services/cct";
import {
    FETCH_CCT_PROVISIONING_INFO_START,
    FETCH_CCT_PROVISIONING_INFO_ERROR,
    FETCH_CCT_PROVISIONING_INFO_SUCCESS,
    GENERATE_CCT_PROVISIONING_CONTRACT_ERROR,
    GENERATE_CCT_PROVISIONING_CONTRACT_RESET,
    GENERATE_CCT_PROVISIONING_CONTRACT_START,
    GENERATE_CCT_PROVISIONING_CONTRACT_SUCCESS,
    UPLOAD_CCT_PROVISIONING_CONTRACT_START,
    UPLOAD_CCT_PROVISIONING_CONTRACT_SUCCESS,
    UPLOAD_CCT_PROVISIONING_CONTRACT_ERROR,
    UPLOAD_CCT_PROVISIONING_CONTRACT_RESET,
    FETCH_CCT_PROVISIONING_INFO_MANAGED_START,
    FETCH_CCT_PROVISIONING_INFO_MANAGED_ERROR,
    FETCH_CCT_PROVISIONING_INFO_MANAGED_SUCCESS,
    INITIALIZE_MANAGED_PROVISIONING_START,
    INITIALIZE_MANAGED_PROVISIONING_SUCCESS,
    INITIALIZE_MANAGED_PROVISIONING_ERROR,
    INITIALIZE_MANAGED_PROVISIONING_RESET,
    INITIALIZE_CCT_SMART_PROVISIONING_START,
    INITIALIZE_CCT_SMART_PROVISIONING_SUCCESS,
    INITIALIZE_CCT_SMART_PROVISIONING_ERROR,
    INITIALIZE_CCT_SMART_PROVISIONING_RESET,
    EXTEND_CCT_SMART_PROVISIONING_START,
    EXTEND_CCT_SMART_PROVISIONING_SUCCESS,
    EXTEND_CCT_SMART_PROVISIONING_ERROR,
    EXTEND_CCT_SMART_PROVISIONING_RESET
} from "../../actions/services/cct/provisioning";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function verifyCct(state = defaultState, { type, payload, error }) {
    switch (type) {
        case VERIFY_CCT_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case VERIFY_CCT_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case VERIFY_CCT_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case VERIFY_CCT_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}
export function getCctConfig(state = defaultState, { type, payload, error }) {
    switch (type) {
        case FETCH_CCT_CONFIG_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case FETCH_CCT_CONFIG_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case FETCH_CCT_CONFIG_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case VERIFY_CCT_SUCCESS:
            return {
                ...state,
                ...payload,
                active: true,
                cctUser: payload.cctUser
            };
        case SAVE_CCT_CONFIG_START:
            const newSpecs = { ...payload.specs };
            Object.keys(newSpecs).forEach(spec => {
                newSpecs[spec] = { name: spec, active: newSpecs[spec] };
            });
            return {
                ...state,
                specs: {
                    ...state.specs,
                    ...newSpecs
                }
            };
        case FETCH_CCT_CONFIG_RESET:
            return {
                ...defaultState
            };

        default:
            return state;
    }
}

export function getCctConfigManaged(state = defaultState, { type, payload, error }) {
    switch (type) {
        case FETCH_CCT_CONFIG_MANAGED_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case FETCH_CCT_CONFIG_MANAGED_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                cctConfigManaged: payload
            };
        case FETCH_CCT_CONFIG_MANAGED_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case ENABLE_CCT_CONFIG_MANAGED_SUCCESS:
            state.cctConfigManaged.configs.forEach(config => {
                if (payload.ownerId === config.ownerId && payload.itemIds.includes(config.itemId)) {
                    config.active = true;
                }
            });
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                cctConfigManaged: state.cctConfigManaged
            };
        case DISABLE_CCT_CONFIG_MANAGED_SUCCESS:
            state.cctConfigManaged.configs.forEach(config => {
                if (payload.ownerId === config.ownerId && payload.itemIds.includes(config.itemId)) {
                    config.active = false;
                }
            });
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                cctConfigManaged: state.cctConfigManaged
            };

        default:
            return state;
    }
}

export function enableCctConfigManaged(state = defaultState, { type, payload, error }) {
    switch (type) {
        case ENABLE_CCT_CONFIG_MANAGED_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case ENABLE_CCT_CONFIG_MANAGED_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                cctConfigManaged: payload
            };
        case ENABLE_CCT_CONFIG_MANAGED_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}

export function disableCctConfigManaged(state = defaultState, { type, payload, error }) {
    switch (type) {
        case DISABLE_CCT_CONFIG_MANAGED_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case DISABLE_CCT_CONFIG_MANAGED_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                cctConfigManaged: payload
            };
        case DISABLE_CCT_CONFIG_MANAGED_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };

        default:
            return state;
    }
}

export function saveCctConfig(state = defaultState, { type, payload, error }) {
    switch (type) {
        case SAVE_CCT_CONFIG_START:
            return {
                status: {
                    ...defaultState.status,
                    started: true
                }
            };
        case SAVE_CCT_CONFIG_ERROR:
            return {
                status: {
                    ...defaultState.status,
                    error: true,
                    errorInfo: error
                }
            };
        case SAVE_CCT_CONFIG_SUCCESS:
            return {
                status: {
                    ...defaultState.status,
                    ended: true
                }
            };

        default:
            return state;
    }
}

const defaultProvisioningState = {
    hasErrors: false,
    isLoading: false,
    isSaving: false,
    latestError: "",
    loadingCount: 0,
    managedInfoPagination: {
        isLoading: false,
        totalElements: 0,
        pages: {}
    },
    provisioningInfo: {}
};

export function provisioningInfo(state = defaultProvisioningState, { type, payload, error }) {
    switch (type) {
        case FETCH_CCT_PROVISIONING_INFO_START:
            return {
                ...state,
                hasErrors: false,
                isLoading: true,
                loadingCount: state.loadingCount + 1
            };
        case FETCH_CCT_PROVISIONING_INFO_MANAGED_START:
            return {
                ...state,
                managedInfoPagination: {
                    ...state.managedInfoPagination,
                    isLoading: true
                }
            };
        case FETCH_CCT_PROVISIONING_INFO_ERROR:
            return {
                ...state,
                loadingCount: state.loadingCount - 1 >= 0 ? state.loadingCount - 1 : 0,
                isLoading: state.loadingCount - 1 > 0,
                hasErrors: true,
                latestError: error.message
            };
        case FETCH_CCT_PROVISIONING_INFO_MANAGED_ERROR:
            return {
                ...state,
                managedInfoPagination: {
                    ...state.managedInfoPagination,
                    isLoading: false
                }
            };
        case FETCH_CCT_PROVISIONING_INFO_SUCCESS:
            return {
                ...state,
                loadingCount: state.loadingCount - 1 >= 0 ? state.loadingCount - 1 : 0,
                isLoading: state.loadingCount - 1 > 0,
                provisioningInfo: { ...state.provisioningInfo, [payload.itemId]: payload.info }
            };
        case FETCH_CCT_PROVISIONING_INFO_MANAGED_SUCCESS:
            return {
                ...state,
                managedInfoPagination: {
                    isLoading: false,
                    totalElements: payload.totalElements,
                    pages: { ...state.managedInfoPagination.pages, [payload.page]: Object.keys(payload.data) }
                },
                provisioningInfo: { ...state.provisioningInfo, ...payload.data }
            };
        case INITIALIZE_MANAGED_PROVISIONING_SUCCESS:
            const { balanceSheetDate, ...updatedHolder } = payload.pdfRequest;
            const updatedInfo = {
                ...state.provisioningInfo,
                [payload.managedId]: {
                    ...state.provisioningInfo[payload.managedId],
                    balanceSheetDate,
                    holder: {
                        ...state.provisioningInfo[payload.managedId].holder,
                        ...updatedHolder
                    },
                    status: "downloaded"
                }
            };
            return {
                ...state,
                provisioningInfo: updatedInfo
            };
        // optimistic update
        case GENERATE_CCT_PROVISIONING_CONTRACT_SUCCESS:
            return {
                ...state,
                provisioningInfo: {
                    ...state.provisioningInfo,
                    [payload.itemId]: { ...state.provisioningInfo[payload.itemId], status: "downloaded" }
                }
            };
        // optimistic update
        case UPLOAD_CCT_PROVISIONING_CONTRACT_SUCCESS:
            return {
                ...state,
                provisioningInfo: {
                    ...state.provisioningInfo,
                    [payload.itemId]: {
                        ...state.provisioningInfo[payload.itemId],
                        provisioningStatus: "uploaded",
                        status: "uploaded"
                    }
                }
            };
        // optimistic update
        case INITIALIZE_CCT_SMART_PROVISIONING_SUCCESS:
            return {
                ...state,
                provisioningInfo: {
                    ...state.provisioningInfo,
                    [payload.itemId]: {
                        ...state.provisioningInfo[payload.itemId],
                        provisioningStatus: "pending",
                        status: "pending"
                    }
                }
            };
        // optimistic update
        case EXTEND_CCT_SMART_PROVISIONING_SUCCESS:
            const updatedExtendedInfo = payload.managedIds
                .filter(id => state.provisioningInfo[id])
                .reduce(
                    (acc, id) => ({
                        ...acc,
                        [id]: {
                            ...state.provisioningInfo[id],
                            provisioningStatus: "pending",
                            status: "pending"
                        }
                    }),
                    {}
                );

            return {
                ...state,
                provisioningInfo: {
                    ...state.provisioningInfo,
                    ...updatedExtendedInfo
                }
            };
        default:
            return state;
    }
}

export function provisioningContract(state = defaultState, { type, payload, error }) {
    switch (type) {
        case UPLOAD_CCT_PROVISIONING_CONTRACT_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case UPLOAD_CCT_PROVISIONING_CONTRACT_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case UPLOAD_CCT_PROVISIONING_CONTRACT_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case UPLOAD_CCT_PROVISIONING_CONTRACT_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export function generateContract(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GENERATE_CCT_PROVISIONING_CONTRACT_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GENERATE_CCT_PROVISIONING_CONTRACT_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GENERATE_CCT_PROVISIONING_CONTRACT_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case GENERATE_CCT_PROVISIONING_CONTRACT_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

const defaultManagedProvisioningState = { loading: false, ended: false, error: false, errorInfo: {} };

export function managedProvisioning(state = defaultManagedProvisioningState, { type, payload, error }) {
    switch (type) {
        case INITIALIZE_MANAGED_PROVISIONING_START:
            return {
                ...defaultManagedProvisioningState,
                loading: true
            };
        case INITIALIZE_MANAGED_PROVISIONING_SUCCESS:
            return {
                ...state,
                loading: false,
                ended: true
            };
        case INITIALIZE_MANAGED_PROVISIONING_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                errorInfo: { code: error.code, message: error.message }
            };
        case INITIALIZE_MANAGED_PROVISIONING_RESET:
            return {
                ...defaultManagedProvisioningState
            };
        default:
            return state;
    }
}

export function initSmart(state = defaultState, { type, payload, error }) {
    switch (type) {
        case INITIALIZE_CCT_SMART_PROVISIONING_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case INITIALIZE_CCT_SMART_PROVISIONING_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case INITIALIZE_CCT_SMART_PROVISIONING_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case INITIALIZE_CCT_SMART_PROVISIONING_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export function extendSmart(state = defaultState, { type, payload, error }) {
    switch (type) {
        case EXTEND_CCT_SMART_PROVISIONING_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case EXTEND_CCT_SMART_PROVISIONING_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case EXTEND_CCT_SMART_PROVISIONING_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case EXTEND_CCT_SMART_PROVISIONING_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}
