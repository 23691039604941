import React, { Component } from "react";
import PropTypes from "prop-types";

import { forEach } from "ramda";
import { Field, reduxForm } from "redux-form";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Icon, Select, Form, Row, Col, Radio, DatePicker } from "antd";
import { countryValueWithCodice } from "@ts-digital/agyo-validations/lib/common/constants";

import { signerDocumentTypes } from "../../../../utils/constant";
import { signerValidation } from "../../../../utils/signer-validations/fields-validation";
import * as normalizeField from "../../../../utils/normalize-form";
import FormInput from "../../../general/form-input";
import FormSelect from "../../../general/form-select";

function validate(values) {
    return signerValidation(values);
}

class FormEditSigner extends Component {
    static propTypes = {
        handleClose: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func,
        intl: PropTypes.object.isRequired,
        initialValues: PropTypes.object.isRequired,
        signatureType: PropTypes.string
    };

    render() {
        const Option = Select.Option;
        const FormItem = Form.Item;
        const RadioGroup = Radio.Group;
        const RadioButton = Radio.Button;

        const { handleSubmit, handleClose, intl, signatureType } = this.props;

        let countryWithCode = [];
        let countryOptions = [];
        let signerDocTypes = [];

        if (countryValueWithCodice) {
            forEach(u => {
                countryWithCode.push(
                    <Option key={u.id} value={u.id}>
                        {u.name}
                    </Option>
                );
            }, countryValueWithCodice);
        }
        if (countryValueWithCodice) {
            forEach(u => {
                countryOptions.push(
                    <Option key={u.id} value={u.codice}>
                        {u.name}
                    </Option>
                );
            }, countryValueWithCodice);
        }
        if (signerDocumentTypes) {
            forEach(u => {
                signerDocTypes.push(
                    <Option key={u.id} value={u.name}>
                        {u.name}
                    </Option>
                );
            }, signerDocumentTypes);
        }

        const renderAntDatePicker = ({ input, label, meta: { touched, error }, children }) => (
            <FormItem
                hasFeedback={true}
                help={touched && error}
                validateStatus={touched && error ? "error" : ""}
                wrapperCol={{ span: 23 }}
            >
                <div className="selectWrap">
                    <span className="labelForm">{label}</span>
                    <DatePicker
                        placeholder={label}
                        children={children}
                        className="dateInput"
                        value={input.value}
                        onChange={input.onChange}
                    />
                </div>
            </FormItem>
        );

        const renderAntRadio = ({ input, label, meta: { touched, error }, children, ...custom }) => (
            <FormItem
                hasFeedback={true}
                help={touched && error}
                validateStatus={touched && error ? "error" : ""}
                wrapperCol={{ span: 23 }}
            >
                <div className="selectWrap">
                    <span className="labelForm">{label}</span>
                    <RadioGroup placeholder={label} children={children} className="radioInput" {...custom} {...input} />
                </div>
            </FormItem>
        );

        return (
            <div>
                <form onSubmit={handleSubmit} style={{ marginBottom: "30px" }}>
                    <Row>
                        <p style={{ fontWeight: "bold", fontSize: "16px" }}>
                            <FormattedMessage id="c-certificate-create.signer-data" />
                        </p>
                        <Col span={8}>
                            <Field
                                disabled
                                name="signerFirstName"
                                component={FormInput}
                                wrapperCol={{ span: 23 }}
                                label={intl.formatMessage({
                                    id: "general.name"
                                })}
                                normalize={normalizeField.normalizeCustomLength(64)}
                            />
                        </Col>
                        <Col span={8}>
                            <Field
                                disabled
                                name="signerLastName"
                                component={FormInput}
                                wrapperCol={{ span: 23 }}
                                label={intl.formatMessage({
                                    id: "general.lastname"
                                })}
                                normalize={normalizeField.normalizeCustomLength(64)}
                            />
                        </Col>
                        <Col span={8}>
                            <Field
                                disabled
                                name="signerFiscalCode"
                                component={FormInput}
                                wrapperCol={{ span: 23 }}
                                label={intl.formatMessage({
                                    id: "general.codice-fiscale"
                                })}
                                normalize={normalizeField.normalizeFiscalCode}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={8}>
                            <Field
                                disabled
                                name="signerBirthCountry"
                                component={FormSelect}
                                wrapperCol={{ span: 23 }}
                                label={intl.formatMessage({
                                    id: "general.country"
                                })}
                            >
                                {countryWithCode}
                            </Field>
                        </Col>

                        <Col span={7}>
                            <Field
                                name="signerBirthCity"
                                component={FormInput}
                                wrapperCol={{ span: 23 }}
                                label={intl.formatMessage({
                                    id: "general.birth-city"
                                })}
                                normalize={normalizeField.normalizeCustomLength(64)}
                            />
                        </Col>

                        <Col span={5}>
                            <Field
                                disabled
                                name="signerBirthDate"
                                component={renderAntDatePicker}
                                label={intl.formatMessage({
                                    id: "general.birth-date"
                                })}
                            />
                        </Col>
                        <Col span={4}>
                            <Field
                                disabled
                                name="signerSex"
                                component={renderAntRadio}
                                label={intl.formatMessage({
                                    id: "general.gender"
                                })}
                            >
                                <RadioButton value={"M"}>M</RadioButton>
                                <RadioButton
                                    style={{
                                        height: "32px",
                                        paddingTop: "3px"
                                    }}
                                    value={"F"}
                                >
                                    F
                                </RadioButton>
                            </Field>
                        </Col>
                    </Row>

                    <Row>
                        <p style={{ fontWeight: "bold", fontSize: "16px" }}>{"Indirizzo"}</p>
                        <Col span={8}>
                            <Field
                                name="signerAddress"
                                component={FormInput}
                                wrapperCol={{ span: 23 }}
                                label={intl.formatMessage({
                                    id: "general.address"
                                })}
                                normalize={normalizeField.normalizeCustomLength(128)}
                            />
                        </Col>

                        <Col span={8}>
                            <Field
                                name="signerCity"
                                component={FormInput}
                                wrapperCol={{ span: 23 }}
                                label={intl.formatMessage({
                                    id: "general.city"
                                })}
                                normalize={normalizeField.normalizeCustomLength(64)}
                            />
                        </Col>

                        <Col span={8}>
                            <Field
                                name="signerBirthDistrict"
                                component={FormInput}
                                wrapperCol={{ span: 23 }}
                                label={intl.formatMessage({
                                    id: "general.province"
                                })}
                                normalize={normalizeField.normalizeCodeNazProv}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={8}>
                            <Field
                                name="signerCap"
                                component={FormInput}
                                wrapperCol={{ span: 23 }}
                                label={intl.formatMessage({
                                    id: "general.zipCode"
                                })}
                                normalize={normalizeField.normalizeZipCode}
                            />
                        </Col>
                        <Col span={8}>
                            <Field
                                name="signerCountry"
                                component={FormSelect}
                                label={intl.formatMessage({
                                    id: "general.paese"
                                })}
                                normalize={normalizeField.normalizeCustomLength(64)}
                            >
                                {countryOptions}
                            </Field>
                        </Col>
                    </Row>

                    <Row>
                        <p style={{ fontWeight: "bold", fontSize: "16px" }}>
                            <FormattedMessage id="general.contacts" />
                        </p>
                        <Col span={12}>
                            <Field
                                name="cellNumber"
                                component={FormInput}
                                wrapperCol={{ span: 23 }}
                                label={intl.formatMessage({
                                    id: "general.mobile-phone"
                                })}
                                normalize={normalizeField.normalizeTelFax}
                            />
                        </Col>
                        <Col span={12}>
                            <Field
                                name="signerEmail"
                                component={FormInput}
                                wrapperCol={{ span: 23 }}
                                label={intl.formatMessage({
                                    id: "general.email"
                                })}
                                normalize={normalizeField.normalizeCustomLength(128)}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <p style={{ fontWeight: "bold", fontSize: "16px" }}>
                            <FormattedMessage id="c-certificate.document-info" />
                        </p>
                        <Col span={8}>
                            <Field
                                name="signerDocumentType"
                                component={FormSelect}
                                label={intl.formatMessage({
                                    id: "general.tipo"
                                })}
                                normalize={normalizeField.normalizeCustomLength(64)}
                            >
                                {signerDocTypes}
                            </Field>
                        </Col>
                        <Col span={8}>
                            <Field
                                name="signerDocumentNumber"
                                component={FormInput}
                                wrapperCol={{ span: 23 }}
                                label={intl.formatMessage({
                                    id: "general.document-number"
                                })}
                                normalize={normalizeField.normalizeCustomLength(20)}
                            />
                        </Col>

                        <Col span={8}>
                            <Field
                                name="signerDocumentIssuer"
                                component={FormInput}
                                wrapperCol={{ span: 23 }}
                                label={intl.formatMessage({
                                    id: "general.released-by"
                                })}
                                normalize={normalizeField.normalizeCustomLength(64)}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={8}>
                            <Field
                                name="signerDocumentEndDate"
                                component={renderAntDatePicker}
                                label={intl.formatMessage({
                                    id: "general.expire-date"
                                })}
                            />
                        </Col>
                    </Row>
                    {signatureType === "FRM" ? (
                        <div>
                            <Row>
                                <p
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "16px"
                                    }}
                                >
                                    <FormattedMessage id="general.pin-code" />
                                </p>
                                <Col span={12}>
                                    <Field
                                        name="pin"
                                        component={FormInput}
                                        wrapperCol={{ span: 23 }}
                                        label={intl.formatMessage({
                                            id: "general.pin"
                                        })}
                                        type="password"
                                        normalize={normalizeField.normalizeCustomLength(8)}
                                    />
                                </Col>
                                <Col span={12}>
                                    <Field
                                        name="confermaPin"
                                        component={FormInput}
                                        wrapperCol={{ span: 23 }}
                                        label={intl.formatMessage({
                                            id: "general.pin-confirm"
                                        })}
                                        type="password"
                                        normalize={normalizeField.normalizeCustomLength(8)}
                                    />
                                </Col>
                                <Col span={12}>
                                    <div>
                                        <Field
                                            name="savePin"
                                            component={renderAntRadio}
                                            label={intl.formatMessage({
                                                id: "c-certificate.save-pin"
                                            })}
                                        >
                                            <RadioButton value={true}>
                                                <FormattedMessage id="general.yes" />
                                            </RadioButton>
                                            <RadioButton
                                                style={{
                                                    height: "32px",
                                                    paddingTop: "3px"
                                                }}
                                                value={false}
                                            >
                                                <FormattedMessage id="general.no" />
                                            </RadioButton>
                                        </Field>
                                    </div>
                                </Col>
                            </Row>
                            <div>
                                <span style={{ fontSize: "13px" }}>
                                    <FormattedMessage id="c-certificate.confirmation-note" />
                                </span>
                            </div>
                        </div>
                    ) : null}
                    <Button onClick={handleClose} style={{ marginLeft: "80%", float: "left" }}>
                        <FormattedMessage id="general.annulla" />
                    </Button>
                    <Button htmlType="submit" style={{ marginLeft: "10px" }} type="primary">
                        <Icon type="save" /> <FormattedMessage id="general.save" />
                    </Button>
                </form>
            </div>
        );
    }
}

const form = reduxForm({
    form: "form-edit-signer",
    validate
})(FormEditSigner);

export default injectIntl(form);
