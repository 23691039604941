import styled from "styled-components";

import { Select } from "antd";

export const StyledSelect = styled(Select)`
    width: 100%;
`;

export const StyledLabel = styled.div`
    margin-bottom: 16px;
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 16px;
`;
