import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Container = styled.div`
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0.25px;
    line-height: 24px;
    min-width: 90px;
`;

export const StatusIcon = styled(FontAwesomeIcon)`
    color: ${props => (props.active === "true" ? "rgb(0, 170, 0)" : "rgb(253, 185, 39)")};
    font-size: 16px;
    text-align: center;
    width: 40px;
    margin-right: 8px;
    margin-top: ${props => props.margintop};
`;
