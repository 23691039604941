import styled from "styled-components";

export const Container = styled.div`
    border-bottom: 1px solid #c9d9e8;
    border-left-width: 5px;
    border-left-style: solid;
    border-left-color: ${props => (props.priority === "HIGH" ? " #d82829" : "transparent")};
    padding: 8px 16px;
    background-color: ${props => (props.read ? "#fff" : "#f2f5f8")};
`;

export const Content = styled.div`
    display: grid;
    grid-template-columns: 0.5fr 7fr 0.5fr;
    grid-gap: 8px;
`;

export const Title = styled.div`
    color: rgb(21, 41, 53);
    font-size: 16px;
    font-family: Roboto;
    font-weight: 500;
    letter-spacing: 0.2px;
`;

export const Message = styled.div`
    color: rgb(21, 41, 53);
    font-size: 12px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 16px;
`;

export const Date = styled.div`
    color: rgb(90, 104, 114);
    font-size: 12px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.4px;
    align-self: center;
`;

export const NoteLabel = styled.div`
    justify-self: center;
    align-self: center;
    color: rgb(21, 41, 53);
    font-size: 16px;
    font-family: Roboto;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 16px;
`;
export const Note = styled.div`
    color: rgb(21, 41, 53);
    font-size: 12px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.4px;
    align-self: center;
`;

export const PriorityIconContainer = styled.div`
    justify-self: center;
    align-self: center;
    color: #d82829;
`;

export const Avatar = styled.div`
    color: rgb(0, 80, 117);
    font-size: 14px;
    font-family: Cairo;
    font-weight: normal;

    width: 40px;
    height: 40px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(0, 144, 209);
    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
`;

export const ReadFlag = styled.div`
    width: 10px;
    height: 10px;
    border: 2px solid rgb(0, 144, 209);
    justify-self: center;
    align-self: center;
    border-radius: 100%;
    background-color: ${props => (props.read ? "#fff" : "#0090d1")};
`;

export const RemoveButtonContainer = styled.div`
    display: none;
    ${Container}:hover & {
        display: block;
    }
`;
