import React from "react";
import { METERING_API_URL } from "../../../../../config";
import { getRestCall } from "../../../../../utils/rest-api-call";
import { displayErrorToast } from "../../../../overflowNotification";
import { FormattedMessage } from "react-intl";
import { downloadBase64 } from "../../../../../utils/download";
import { getUserProfile } from "../../../../../utils/state-utils/get-user-profile";
import { getAuthWithAppName } from "../../../../auth";

export const GET_BILLING_USAGE_STATISTICS_CSV_START = "GET_BILLING_USAGE_STATISTICS_CSV_START";
export const GET_BILLING_USAGE_STATISTICS_CSV_END = "METERING_GET_BILLING_USAGE_STATISTICS_CSV_END";
export const GET_BILLING_USAGE_STATISTICS_CSV_SUCCESS = "METERING_GET_BILLING_USAGE_STATISTICS_CSV_SUCCESS";
export const GET_BILLING_USAGE_STATISTICS_CSV_ERROR = "METERING_GET_BILLING_USAGE_STATISTICS_CSV_ERROR";

export const getBillingUsageStatisticsCsvStart = () => ({
    type: GET_BILLING_USAGE_STATISTICS_CSV_START
});

export const getBillingUsageStatisticsCsvEnd = () => ({
    type: GET_BILLING_USAGE_STATISTICS_CSV_END
});

export const getBillingUsageStatisticsCsvSuccess = () => ({
    type: GET_BILLING_USAGE_STATISTICS_CSV_SUCCESS
});

export const getBillingUsageStatisticsCsvError = () => ({
    type: GET_BILLING_USAGE_STATISTICS_CSV_ERROR
});

export const getBillingUsageStatisticsCsv =
    (userId, item, from, to, viewOldBillingCycles) => async (dispatch, getState) => {
        dispatch(getBillingUsageStatisticsCsvStart());
        try {
            const auth = getAuthWithAppName(getState().auth);
            const base64Csv = await getRestCall(
                `${METERING_API_URL}/api/v1/usage/invoicing/${item}/statistics/csv`,
                auth,
                {
                    userId,
                    from,
                    to,
                    viewOldBillingCycles
                },
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                item
            );
            downloadBase64(base64Csv, "statistiche.csv");
            dispatch(getBillingUsageStatisticsCsvSuccess());
        } catch (error) {
            const displayError = displayErrorToast(
                <FormattedMessage id="metering.usage.statistics.csv.billing.error" />
            );
            displayError(dispatch);
            dispatch(getBillingUsageStatisticsCsvError());
        }
        dispatch(getBillingUsageStatisticsCsvEnd());
    };
