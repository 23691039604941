import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { Container, CloseIcon } from "./styled";

function CloseButton({ onClick }) {
    return (
        <Container onClick={() => onClick()}>
            <CloseIcon icon={faTimes} />
        </Container>
    );
}

export default CloseButton;
