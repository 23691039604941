import React from "react";
import { connect } from "react-redux";
import { Button } from "@ts-digital/arc/antd";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "antd";
import { METERING_STORE_UPGRADE_ENDPOINT } from "../../../../config";
import { compose } from "redux";
import { withRouter } from "react-router";

export const BasicUpgrade = ({ encryptedVatNumber, ...rest }) => (
    <Tooltip placement="left" title={<FormattedMessage id="metering.usage.upgrade.tooltip" />}>
        <Button
            size="small"
            link
            href={`${METERING_STORE_UPGRADE_ENDPOINT}/Agyo?code=${encryptedVatNumber}`}
            target="_blank"
            {...rest}
        >
            <FormattedMessage id="metering.usage.upgrade" />
        </Button>
    </Tooltip>
);

const mapStateToProps = (state, props) => {
    const selectedCompany = props.match.params.item;
    const selectedCompanyData = state.companies.data[selectedCompany];
    const vatNumber = selectedCompanyData && selectedCompanyData.item.base.identifier.vatNumber;
    return {
        encryptedVatNumber:
            vatNumber &&
            vatNumber
                .split("")
                .reverse()
                .map((character, index) => String.fromCharCode(97 + parseInt(character) + index))
                .join("")
                .toString()
    };
};

const enhanceComponent = compose(withRouter, connect(mapStateToProps));

export const Upgrade = enhanceComponent(BasicUpgrade);
