import { API_URL, USER_WRITE, USER_WRITE_REST } from "../../config";
import { getAuthWithAppName, callWithRetry } from "../auth";

import UserWrite from "../../assets/thrift/user-write/UserWriteApi";
import UserWriteTypes from "../../assets/thrift/user-write/user-write_types";
import { getRestCall, postRestCall, deleteRestCall } from "../../utils/rest-api-call";
import thrift from "browser-thrift";
import { getUserProfile } from "../../utils/state-utils/get-user-profile";

const userWriteUrl = new URL(USER_WRITE);
const userWriteClient = thrift.createXHRClient(
    UserWrite,
    thrift.createXHRConnection(userWriteUrl.hostname, 443, {
        useCors: true,
        path: userWriteUrl.pathname,
        https: userWriteUrl.protocol === "https:"
    })
);

export const LIST_USERS_START = "LIST_USERS_START";
export const LIST_USERS_SUCCESS = "LIST_USERS_SUCCESS";
export const LIST_USERS_ERROR = "LIST_USERS_ERROR";
export const LIST_USERS_RESET = "LIST_USERS_RESET";

export function listUsers(logoutOnAuthError = true, throwOn403, ...itemIds) {
    return async (dispatch, getState) => {
        dispatch({
            type: LIST_USERS_START,
            payload: { itemIds }
        });

        const auth = getAuthWithAppName(getState().auth);
        const listUsersPromises = itemIds.map(id => {
            return listUsersCall(auth, id, dispatch, getState, logoutOnAuthError, throwOn403);
        });

        try {
            const itemUsers = await Promise.all(listUsersPromises);
            dispatch({
                type: LIST_USERS_SUCCESS,
                payload: itemUsers
            });
        } catch (error) {
            dispatch({
                type: LIST_USERS_ERROR,
                error: error
            });
        }
    };
}

function listUsersCall(auth, itemId, dispatch, getState, logoutOnAuthError, throwOn403) {
    return getRestCall(
        `${API_URL}/users`,
        auth,
        { page: 0, size: 150, withTech: true, itemId },
        dispatch,
        auth.refreshToken,
        getUserProfile(getState()),
        itemId,
        false,
        false,
        logoutOnAuthError ? [401] : []
    ).catch(error => {
        if (error.status === 403 && !throwOn403) {
            return { itemId, res: {} };
        }
        throw error;
    });
}

export const CREATE_USER_START = "CREATE_USER_START";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";
export const CREATE_USER_RESET = "CREATE_USER_RESET";

// TODO check for deletion
export function createUser(userData, resource) {
    return async (dispatch, getState) => {
        dispatch({
            type: CREATE_USER_START
        });

        const request = {
            user: userData,
            roles: resource
        };

        try {
            const auth = getState().auth;
            const result = await postRestCall(
                `${USER_WRITE_REST}/users`,
                auth,
                null,
                request,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState())
            );

            dispatch({
                type: CREATE_USER_SUCCESS,
                payload: result
            });
        } catch (e) {
            dispatch({
                type: CREATE_USER_ERROR,
                error: e
            });
        }
    };
}

export function createUserReset() {
    return dispatch => {
        dispatch({
            type: CREATE_USER_RESET
        });
    };
}

export const CREATE_API_KEY_START = "CREATE_API_KEY_START";
export const CREATE_API_KEY_SUCCESS = "CREATE_API_KEY_SUCCESS";
export const CREATE_API_KEY_ERROR = "CREATE_API_KEY_ERROR";
export const CREATE_API_KEY_RESET = "CREATE_API_KEY_RESET";

export function createApiKey(userData, resource) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: CREATE_API_KEY_START
            });

            const apiKey = new UserWriteTypes.ApiKey({
                description: userData.description
            });
            const request = new UserWriteTypes.CreateApiKeyRequest({
                apiKey,
                roles: resource
            });

            let codes = ["401"];
            const auth = getAuthWithAppName(getState().auth);
            callWithRetry(
                userWriteClient,
                userWriteClient.createApiKey,
                auth,
                request,
                auth.refreshToken,
                dispatch,
                (error, result) => {
                    if (error !== null) {
                        dispatch({
                            type: CREATE_API_KEY_ERROR,
                            error: error
                        });
                        reject();
                    } else {
                        dispatch({
                            type: CREATE_API_KEY_SUCCESS,
                            payload: result
                        });
                        resolve();
                    }
                },
                codes
            );
        });
    };
}

export function createApiKeyReset() {
    return dispatch => {
        dispatch({
            type: CREATE_API_KEY_RESET
        });
    };
}

export const EDIT_USER_START = "EDIT_USER_START";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";
export const EDIT_USER_RESET = "EDIT_USER_RESET";

export function editUserReset() {
    return dispatch => {
        dispatch({
            type: EDIT_USER_RESET
        });
    };
}

export function editUser(id, userData) {
    return (dispatch, getState) => {
        dispatch({
            type: EDIT_USER_START
        });

        const user = new UserWriteTypes.User(userData);
        user.preferences = new UserWriteTypes.Preferences({
            language: userData.language
        });
        const request = new UserWriteTypes.UpdateUserRequest({ user, id });
        const auth = getAuthWithAppName(getState().auth);
        callWithRetry(
            userWriteClient,
            userWriteClient.updateUser,
            auth,
            request,
            auth.refreshToken,
            dispatch,
            (error, result) => {
                if (error !== null) {
                    dispatch({
                        type: EDIT_USER_ERROR,
                        error: result
                    });
                } else {
                    dispatch({
                        type: EDIT_USER_SUCCESS,
                        payload: userData
                    });
                }
            }
        );
    };
}

export const ADD_ROLES_START = "ADD_ROLES_START";
export const ADD_ROLES_SUCCESS = "ADD_ROLES_SUCCESS";
export const ADD_ROLES_ERROR = "ADD_ROLES_ERROR";
export const ADD_ROLES_RESET = "ADD_ROLES_RESET";

export function addRoles(userId, roles) {
    return async (dispatch, getState) => {
        dispatch({
            type: ADD_ROLES_START
        });

        let request = {
            userId,
            roles
        };

        try {
            const auth = getAuthWithAppName(getState().auth);
            await postRestCall(
                `${USER_WRITE_REST}/roles`,
                auth,
                null,
                request,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState())
            );

            dispatch({
                type: ADD_ROLES_SUCCESS
            });
        } catch (e) {
            dispatch({
                type: ADD_ROLES_ERROR,
                error: e
            });
        }
    };
}

export const REMOVE_ROLES_START = "REMOVE_ROLES_START";
export const REMOVE_ROLES_SUCCESS = "REMOVE_ROLES_SUCCESS";
export const REMOVE_ROLES_ERROR = "REMOVE_ROLES_ERROR";
export const REMOVE_ROLES_RESET = "REMOVE_ROLES_RESET";

export function removeRoles(userId, roles) {
    return async (dispatch, getState) => {
        dispatch({
            type: REMOVE_ROLES_START
        });

        let request = {
            userId,
            roles
        };

        try {
            const auth = getAuthWithAppName(getState().auth);
            await deleteRestCall(
                `${USER_WRITE_REST}/roles`,
                auth,
                null,
                request,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState())
            );

            dispatch({
                type: REMOVE_ROLES_SUCCESS
            });
        } catch (e) {
            dispatch({
                type: REMOVE_ROLES_ERROR,
                error: e
            });
        }
    };
}

export function addRemoveRolesReset() {
    return dispatch => {
        dispatch({
            type: ADD_ROLES_RESET
        });
        dispatch({
            type: REMOVE_ROLES_RESET
        });
    };
}

export const USER_RESET = "USER_RESET";

export function resetUser() {
    return dispatch => {
        dispatch({
            type: USER_RESET
        });
    };
}

export const USER_RESET_ROLE_MANAGE = "USER_RESET_ROLE_MANAGE";

export function resetUserRolesManage() {
    return dispatch => {
        dispatch({
            type: USER_RESET_ROLE_MANAGE
        });
    };
}

export const REMOVE_THEN_ADD_ROLES_START = "REMOVE_THEN_ADD_ROLES_START";

export function removeThenAddRoles(userId, rolesToAdd, rolesToRemove) {
    return async (dispatch, getState) => {
        if (rolesToRemove && rolesToRemove.length > 0 && rolesToAdd && rolesToAdd.length > 0) {
            dispatch({
                type: REMOVE_THEN_ADD_ROLES_START,
                payload: {
                    addRoles: true,
                    removeRoles: true,
                    userId,
                    rolesToAdd,
                    rolesToRemove
                }
            });
            await removeRoles(userId, rolesToRemove)(dispatch, getState);
            await addRoles(userId, rolesToAdd)(dispatch, getState);
        } else if (rolesToAdd && rolesToAdd.length > 0) {
            dispatch({
                type: REMOVE_THEN_ADD_ROLES_START,
                payload: {
                    addRoles: true,
                    removeRoles: false,
                    userId,
                    rolesToAdd,
                    rolesToRemove
                }
            });
            await addRoles(userId, rolesToAdd)(dispatch, getState);
        } else if (rolesToRemove && rolesToRemove.length > 0) {
            dispatch({
                type: REMOVE_THEN_ADD_ROLES_START,
                payload: {
                    addRoles: false,
                    removeRoles: true,
                    userId,
                    rolesToAdd,
                    rolesToRemove
                }
            });
            await removeRoles(userId, rolesToRemove)(dispatch, getState);
        }
    };
}

export const GET_USER_V3_START = "GET_USER_V3_START";
export const GET_USER_V3_SUCCESS = "GET_USER_V3_SUCCESS";
export const GET_USER_V3_ERROR = "GET_USER_V3_ERROR";
export const GET_USER_V3_RESET = "GET_USER_V3_RESET";

export const getUserV3 = userId => async (dispatch, getState) => {
    dispatch({ type: "GET_USER_V3_START", payload: { userId } });

    const auth = getAuthWithAppName(getState().auth);

    try {
        const user = await getRestCall(
            `${API_URL}/users/${userId}`,
            auth,
            {},
            dispatch,
            auth.refreshToken,
            getUserProfile(getState())
        );
        dispatch({ type: "GET_USER_V3_SUCCESS", payload: { userId, user } });
    } catch (e) {
        dispatch({ type: "GET_USER_V3_ERROR", payload: { userId }, error: { code: e.status, message: userId } });
    }
};

export const getUserV3Reset = () => dispatch => dispatch({ type: "GET_USER_V3_RESET" });

export const LIST_USERS_V3_START = "LIST_USERS_V3_START";
export const LIST_USERS_V3_SUCCESS = "LIST_USERS_V3_SUCCESS";
export const LIST_USERS_V3_ERROR = "LIST_USERS_V3_ERROR";

export function listUsersV3(
    itemId,
    itemUuid,
    page,
    pageSize,
    roles,
    appIds,
    userTypes,
    userId,
    text,
    sortBy,
    orderType,
    featureCodes,
    unpaged
) {
    return async (dispatch, getState) => {
        dispatch({
            type: LIST_USERS_V3_START,
            payload: itemId
        });

        const params = {
            page: page,
            pageSize: pageSize,
            roles: roles || [],
            appIds: appIds || [],
            userTypes: userTypes || [],
            userId: userId,
            text: text,
            sortBy: sortBy,
            orderType: orderType,
            featureCodes: featureCodes || [],
            unpaged: unpaged
        };

        try {
            const auth = getAuthWithAppName(getState().auth);
            const users = await getRestCall(
                `${API_URL}/items/${itemUuid}/users`,
                auth,
                params,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                itemId
            );

            dispatch({
                type: LIST_USERS_V3_SUCCESS,
                payload: users
            });
        } catch (error) {
            dispatch({
                type: LIST_USERS_V3_ERROR,
                error: error
            });
        }
    };
}

export const RESEND_EMAIL_START = "RESEND_EMAIL_START";
export const RESEND_EMAIL_SUCCESS = "RESEND_EMAIL_SUCCESS";
export const RESEND_EMAIL_ERROR = "RESEND_EMAIL_ERROR";
export const RESEND_EMAIL_RESET = "RESEND_EMAIL_RESET";

export function resendWelcomeEmail(userId) {
    return async (dispatch, getState) => {
        dispatch({
            type: RESEND_EMAIL_START,
            payload: { userId }
        });

        try {
            const auth = getAuthWithAppName(getState().auth);
            const resUserId = await postRestCall(
                `${API_URL}/users/${userId}/resendWelcomeEmail`,
                auth,
                {},
                {},
                dispatch,
                auth.refreshToken,
                getUserProfile(getState())
            );

            dispatch({
                type: RESEND_EMAIL_SUCCESS,
                payload: resUserId
            });
        } catch (error) {
            dispatch({
                type: RESEND_EMAIL_ERROR,
                error: error
            });
        }
    };
}

export function resetResendWelcomeEmail() {
    return dispatch => {
        dispatch({
            type: RESEND_EMAIL_RESET
        });
    };
}

export const EDIT_ROLES_START = "EDIT_ROLES_START";
export const EDIT_ROLES_SUCCESS = "EDIT_ROLES_SUCCESS";
export const EDIT_ROLES_ERROR = "EDIT_ROLES_ERROR";
export const EDIT_ROLES_RESET = "EDIT_ROLES_RESET";

export function editRoles(userId, itemId, roles, localWorkspaces, selectedItem) {
    return async (dispatch, getState) => {
        dispatch({
            type: EDIT_ROLES_START,
            payload: { userId }
        });

        const auth = getAuthWithAppName(getState().auth);
        const mappedRoles = roles.length ? { [itemId]: roles } : {};

        try {
            const updatedRoles = await postRestCall(
                `${API_URL}/roles/edit`,
                auth,
                {},
                { userId, roles: mappedRoles, localWorkspaces },
                dispatch,
                auth.refreshToken,
                getUserProfile(getState())
            );

            dispatch({
                type: EDIT_ROLES_SUCCESS,
                payload: { userId, itemId, updatedRoles, localWorkspaces, selectedItem }
            });
        } catch (error) {
            dispatch({
                type: EDIT_ROLES_ERROR,
                error: error
            });
        }
    };
}

export function resetEditRoles() {
    return dispatch => {
        dispatch({
            type: EDIT_ROLES_RESET
        });
    };
}
