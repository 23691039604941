import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch, Form, Select } from "antd";

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-right: 16px;
    padding-top: 16px;

    position: sticky;
    bottom: -15px;
    margin-top: auto;

    background: rgb(242, 245, 248);
    border-radius: 0px;
    box-shadow: 0px -2px 8px 0px rgba(21, 41, 53, 0.32);
    height: 72px;

    & > :first-child {
        margin-right: 16px;
    }
`;

export const LastRow = styled.div`
    margin-bottom: 48px;
`;

export const InfoContainer = styled.div`
    margin-top: 24px;
`;

export const CompanyDataContainer = styled.div`
    display: flex;
    flex-direction: row;

    color: #000;
`;

export const CompanyName = styled.span`
    & span {
        font-weight: 700;
    }
`;

export const CompanyTaxId = styled.span`
    margin-left: 4px;

    & span {
        font-weight: 400;
    }
`;

export const Container = styled.div`
    padding: 30px 46px;
`;

export const FormGrid = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    margin-bottom: 15px;

    & > div:nth-child(odd) {
        padding-right: 20px;
    }

    & > div:nth-child(even) {
        padding-left: 20px;
    }

    & .ant-calendar-picker {
        width: 100%;
    }

    & .ant-select {
        width: 100%;
    }
`;

export const TitleContainer = styled.div`
    text-align: center;
`;

export const SubtitleContainer = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 16px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 24px;
    margin-bottom: 24px;
`;

export const TextFieldContainer = styled.div`
    width: 85%;
`;

export const EmailContainer = styled.div`
    display: inline-flex;
`;

export const VademecumContainer = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 16px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 24px;
    margin-bottom: 24px;
`;

export const BalanceSheetDateContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const BalanceSheetDateInfoContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const BalanceSheetDateIconContainer = styled.div`
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const BalanceSheetDateIcon = styled(FontAwesomeIcon)`
    color: ${props => props.color};
    font-size: 16px;
`;

export const BalanceSheetDateMessage = styled.span`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 16px;
`;

export const SectionTitle = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 34px;
    font-family: Cairo;
    font-weight: 600;
    line-height: 40px;
`;

export const SectionSubtitle = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 24px;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.23px;
    line-height: 24px;
    margin-top: 24px;
    margin-bottom: 24px;
`;

export const SwitchContainer = styled.div`
    height: 40px;
    margin-top: 30px;
    display: flex;
    align-items: center;
`;

export const StyledSwitch = styled(Switch)`
    &.ant-switch {
        background-color: ${props => props.theme.colors.text.cadet};
    }

    &.ant-switch-checked {
        background-image: linear-gradient(
            315deg,
            ${props => props.theme.colors.primary.cyanProgress},
            ${props => props.theme.colors.primary.richElectricBlue}
        );
    }
`;

export const SectionTitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const AutocompleteToggleContainer = styled.div`
    margin-top: 16px;

    & button {
        margin-right: 8px;
    }
`;

export const CustomFormItem = styled(Form.Item)`
    & > .ant-form-item-label {
        font-family: "Roboto";
        font-weight: 500;
        font-size: 14px;
        line-height: 1.72;
        height: 27px;
        margin-bottom: 4px;
    }
`;

export const CustomSelect = styled(Select)`
    &.ant-select {
        width: 100%;
        & .ant-select-selection--single {
            height: 40px;
            width: 100%;
            background: #f2f5f8;
            border: 1px solid #c9d9e8;
            border-radius: 0px;

            & .ant-select-selection__rendered {
                height: 100%;
                line-height: 40px;
            }
        }
    }
`;

export const RegistryFormSubtitleContainer = styled.div`
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.72;
    font-weight: 500;
    color: #152935;
    font-style: italic;
    margin-top: 8px;
`;

export const SmartSetupSwitchContainer = styled.span`
    margin-right: 8px;
`;

export const SmartCheckboxContainer = styled.div`
    display: flex;
`;

export const SmartCheckboxLabelContainer = styled.span`
    flex: 1;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    padding: 0px 8px;
    line-height: 1.5;
`;

export const BalanceSheetDateContainerTable = styled.div`
    width: 100%;
    display: table;
    text-align: center;
`;

export const DaySelectContainer = styled.div`
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    min-width: 260px;
    width: 260px;
`;

export const SetDateButtonContainer = styled.div`
    display: table-cell;
    padding-top: 7px;
    vertical-align: middle;
    text-align: left;
    min-width: 250px;
    padding-left: 10px;

    & button:hover svg {
        ${props => (props.disabled ? "color: #c9d9e8" : "color: white")};
    }
`;

export const CalendarIcon = styled(FontAwesomeIcon)`
    margin-right: 10px;
`;
