import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import { ENABLE_ITEM_COUNTRIES } from "../../../../config";
import TextField from "@ts-digital/arc/antd/TextField";
import CountryField from "@ts-digital/arc/antd/CountryField";
import * as normalizeField from "../../../../utils/normalize-form";

import { LeftColumn, CentralColumn, RightColumn, TextFieldHideable, ItalianProvinceFieldHideable } from "./styled";

class DataAddress extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        formValues: PropTypes.object
    };

    static defaultProps = {
        formValues: {}
    };

    render() {
        const { disabled, formValues } = this.props;

        return (
            <Fragment>
                <LeftColumn>
                    <TextField
                        disabled={disabled}
                        name={"streetName"}
                        label={<FormattedMessage id="general.streetName" />}
                    />
                </LeftColumn>
                <CentralColumn>
                    <TextField
                        disabled={disabled}
                        label={<FormattedMessage id="general.streetNumber" />}
                        name={"streetNumber"}
                    />
                </CentralColumn>
                <RightColumn>
                    <TextField disabled={disabled} name={"city"} label={<FormattedMessage id="general.city" />} />
                </RightColumn>
                <LeftColumn>
                    <CountryField
                        disabled={disabled}
                        name="country"
                        enabledCountries={ENABLE_ITEM_COUNTRIES}
                        label={<FormattedMessage id="general.country" />}
                    />
                </LeftColumn>
                <CentralColumn>
                    <TextField
                        disabled={disabled}
                        label={<FormattedMessage id="general.zipCode" />}
                        name={"zipCode"}
                        normalize={normalizeField.normalizeZipCode}
                        size="default"
                    />
                </CentralColumn>
                <RightColumn>
                    <ItalianProvinceFieldHideable
                        disabled={disabled}
                        label={<FormattedMessage id="general.province" />}
                        name="province"
                        showSearch={true}
                        hide={formValues.country !== "IT"}
                    />
                    <TextFieldHideable
                        disabled={disabled}
                        label={<FormattedMessage id="general.province" />}
                        name="province"
                        size="default"
                        hide={formValues.country === "IT"}
                        normalize={normalizeField.normalizeCustomLength(5)}
                    />
                </RightColumn>
            </Fragment>
        );
    }
}

export default DataAddress;
