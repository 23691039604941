import React from "react";
import { FormattedMessage } from "react-intl";
import { advertisements } from "./advertisements";
import Circle from "./components/circle";

import {
    CirclesContainer,
    Container,
    Description,
    HighlightWord,
    HumanImage,
    HumanImageContainer,
    Title
} from "./styled";
import { themes } from "./theme";

const Advertising = () => {
    return (
        <Container>
            <Title>
                <FormattedMessage
                    id={advertisements.title}
                    values={{
                        highlight: chunks => (
                            <HighlightWord color={themes[advertisements.theme].highlightword}>{chunks}</HighlightWord>
                        )
                    }}
                />
            </Title>
            <Description>
                <FormattedMessage id={advertisements.description} />
            </Description>
            <CirclesContainer>
                {advertisements.circles.map((circle, key) => (
                    <Circle
                        key={key}
                        size={circle.size}
                        value={circle.value}
                        valueType={circle.valueType}
                        description={circle.description}
                        themeColors={themes[advertisements.theme].circle}
                    />
                ))}
            </CirclesContainer>
            <HumanImageContainer right={advertisements.image.right} top={advertisements.image.top}>
                <HumanImage src={advertisements.image.url} />
            </HumanImageContainer>
        </Container>
    );
};

export default Advertising;
