import { API_UPLOAD_URL, CERTIFICATION_API_URL, API_URL } from "../config";
import { getAuthWithAppName, callWithRetry } from "./auth.js";
import { postRestCall } from "../utils/rest-api-call";

import CompanyCertification from "../assets/thrift/company-certification/CompanyCertificationApi";

import thrift from "browser-thrift";
import { getUserProfile } from "../utils/state-utils/get-user-profile";
var httpClient = require("axios");

const certificationInfoUrl = new URL(CERTIFICATION_API_URL);
const certificationInfoClient = thrift.createXHRClient(
    CompanyCertification,
    thrift.createXHRConnection(certificationInfoUrl.hostname, 443, {
        useCors: true,
        path: certificationInfoUrl.pathname,
        https: certificationInfoUrl.protocol === "https:"
    })
);

export const UPLOAD_CERT_START = "UPLOAD_CERT_START";
export const UPLOAD_CERT_SUCCESS = "UPLOAD_CERT_SUCCESS";
export const UPLOAD_CERT_ERROR = "UPLOAD_CERT_ERROR";
export const UPLOAD_CERT_RESET = "UPLOAD_CERT_RESET";

export function uploadCert(id, description, content) {
    return async (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: UPLOAD_CERT_START
            });

            var uploadFileParam = {};
            uploadFileParam.auth = getAuthWithAppName(getState().auth);
            uploadFileParam.id = id;
            uploadFileParam.description = description;
            uploadFileParam.content = content;

            return httpClient
                .post(API_UPLOAD_URL, uploadFileParam)
                .then(response => {
                    if (response.status === 200) {
                        dispatch({
                            type: UPLOAD_CERT_SUCCESS,
                            payload: { ...response, companyId: id }
                        });
                        resolve();
                    } else {
                        dispatch({
                            type: UPLOAD_CERT_ERROR,
                            error: response.message != null ? response.message : "Internal Server Error 500"
                        });
                        reject();
                    }
                })
                .catch(response => {
                    dispatch({
                        type: UPLOAD_CERT_ERROR,
                        error: response.message != null ? response.message : "Internal Server Error 500"
                    });
                    reject();
                });
        });
    };
}

export function uploadCertReset() {
    return async dispatch => {
        dispatch({
            type: UPLOAD_CERT_RESET
        });
    };
}

export const CERTIFICATION_INFO_START = "CERTIFICATION_INFO_START";
export const CERTIFICATION_INFO_ERROR = "CERTIFICATION_INFO_ERROR";
export const CERTIFICATION_INFO_SUCCESS = "CERTIFICATION_INFO_SUCCESS";

export function certificationInfo(companyId) {
    return async (dispatch, getState) => {
        dispatch({
            type: CERTIFICATION_INFO_START
        });
        const idCompany = companyId;

        const auth = getAuthWithAppName(getState().auth);
        callWithRetry(
            certificationInfoClient,
            certificationInfoClient.info,
            auth,
            companyId,
            auth.refreshToken,
            dispatch,
            (error, response) => {
                if (error !== null) {
                    dispatch({
                        type: CERTIFICATION_INFO_ERROR,
                        error: error
                    });
                } else {
                    let resObj = {};
                    resObj[idCompany] = response;

                    dispatch({
                        type: CERTIFICATION_INFO_SUCCESS,
                        payload: resObj
                    });
                }
            }
        );
    };
}

export const UPLOAD_ATTO_START = "UPLOAD_ATTO_START";
export const UPLOAD_ATTO_ERROR = "UPLOAD_ATTO_ERROR";
export const UPLOAD_ATTO_SUCCESS = "UPLOAD_ATTO_SUCCESS";

export function uploadAtto(id, description, content) {
    return async (dispatch, getState) => {
        dispatch({
            type: UPLOAD_ATTO_START
        });

        const auth = getAuthWithAppName(getState().auth);

        var uploadFileParam = {};
        uploadFileParam.auth = auth;
        uploadFileParam.id = id;
        uploadFileParam.description = description;
        uploadFileParam.content = content;
        uploadFileParam.policyType = "CONNECTION";

        try {
            await postRestCall(
                `${API_UPLOAD_URL}`,
                auth,
                {},
                uploadFileParam,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                undefined,
                true,
                true
            );

            dispatch({
                type: UPLOAD_ATTO_SUCCESS
            });
        } catch (e) {
            dispatch({
                type: UPLOAD_ATTO_ERROR
            });

            console.error(e);
        }
    };
}

export const GENERATE_CONTRACT_START = "GENERATE_CONTRACT_START";
export const GENERATE_CONTRACT_ERROR = "GENERATE_CONTRACT_ERROR";
export const GENERATE_CONTRACT_SUCCESS = "GENERATE_CONTRACT_SUCCESS";
export const GENERATE_CONTRACT_RESET = "GENERATE_CONTRACT_RESET";

export function generateContract(id, contractFields) {
    return async (dispatch, getState) => {
        dispatch({
            type: GENERATE_CONTRACT_START
        });

        try {
            const auth = getAuthWithAppName(getState().auth);
            const result = await postRestCall(
                `${API_URL}/items/${id}/contract/generate`,
                auth,
                {},
                contractFields,
                dispatch,
                auth.refreshToken
            );

            dispatch({ type: GENERATE_CONTRACT_SUCCESS, payload: result.contract });
        } catch (e) {
            dispatch({ type: GENERATE_CONTRACT_ERROR, error: e });
        }
    };
}

export const generateContractReset = () => dispatch => dispatch({ type: GENERATE_CONTRACT_RESET });

export const GENERATE_ACT_OF_ENTRUSTMENT_START = "GENERATE_ACT_OF_ENTRUSTMENT_START";
export const GENERATE_ACT_OF_ENTRUSTMENT_ERROR = "GENERATE_ACT_OF_ENTRUSTMENT_ERROR";
export const GENERATE_ACT_OF_ENTRUSTMENT_SUCCESS = "GENERATE_ACT_OF_ENTRUSTMENT_SUCCESS";
export const GENERATE_ACT_OF_ENTRUSTMENT_RESET = "GENERATE_ACT_OF_ENTRUSTMENT_RESET";

export function generateActOfEntrustment(contractFields) {
    return async (dispatch, getState) => {
        dispatch({
            type: GENERATE_ACT_OF_ENTRUSTMENT_START
        });

        const auth = getAuthWithAppName(getState().auth);

        try {
            const result = await postRestCall(
                `${API_URL}/items/actOfEntrustment/generate`,
                auth,
                {},
                contractFields,
                dispatch,
                auth.refreshToken
            );

            dispatch({ type: GENERATE_ACT_OF_ENTRUSTMENT_SUCCESS, payload: result.actOfEntrustment });
        } catch (e) {
            dispatch({ type: GENERATE_ACT_OF_ENTRUSTMENT_ERROR, error: e });
        }
    };
}

export const generateActOfEntrustmentReset = () => dispatch => dispatch({ type: GENERATE_ACT_OF_ENTRUSTMENT_RESET });
