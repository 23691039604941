import { largeUserSize } from "./constant";

import { ENABLE_EDIT_WITHOUT_ADMIN } from "../config";

const resourceBlacklist = ["BACKOFFICE", "REGISTERED", "AGYO"];

export function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export function hasAtLeastOneItem(user) {
    return user.user && user.user.roles.filter(s => !resourceBlacklist.includes(s.resourceId)).length >= 1;
}

/**
 * Given a user's roles, extracts the id of all items on which
 * the user has a role.
 * If the provided roles array is null or undefined an empty array is returned.
 * @param {array} roles a user's roles
 */
export function getItemIdsFromRoles(roles) {
    return roles ? Array.from(new Set(roles.map(r => r.resourceId).filter(id => !resourceBlacklist.includes(id)))) : [];
}

/**
 * Given a user's roles and a list of item ids, returns
 * an object having as keys the id of the item and as values
 * an array of all apps contained in the roles.
 * If a role is global (appId === null) the actionKey is used instead.
 * @param {array} userRoles roles of the user expressed in the
 * { appId, featureCode, resourceId, actionKey } format
 * @param {array} itemIds array of item ids to map
 */
export const mapUserRolesToAppId = (userRoles, itemIds) => {
    return userRoles
        .filter(r => itemIds.includes(r.resourceId))
        .reduce((roles, r) => {
            if (!roles[r.resourceId]) {
                roles[r.resourceId] = [];
            }
            const roleToInclude = r.appId ? r.appId : r.actionKey;
            if (!roles[r.resourceId].includes(roleToInclude)) {
                roles[r.resourceId].push(roleToInclude);
            }
            return roles;
        }, {});
};

export function isUserWithTooManyItems(roles = []) {
    return roles.length >= largeUserSize;
}

/** compara le differenze tra due set di ruoli */
export function roleComparer(role, otherrole) {
    return role.filter(role =>
        otherrole.every(
            other =>
                other.actionKey !== role.actionKey || other.appId !== role.appId || other.resourceId !== role.resourceId
        )
    );
}

export function parseRoles(customRoles, userRoles) {
    return Object.keys(customRoles).flatMap(resourceId => {
        const requestedRoles = customRoles[resourceId];
        const resourceRoles = userRoles
            .filter(
                role =>
                    role.resourceId === resourceId &&
                    (requestedRoles.includes("MANAGER") ? true : role.actionKey !== "MANAGER")
            )
            .map(role => (role.actionKey === "OWNER" ? { ...role, actionKey: "ADMIN" } : role));
        // Propagate all roles
        if (requestedRoles.includes("ADMIN")) {
            return resourceRoles;
        } else {
            return requestedRoles.flatMap(appId => {
                let appRoles = resourceRoles.filter(r => r.appId === appId);

                if (appRoles.length > 0) {
                    return appRoles;
                } else {
                    const actionKeys = getHighestGlobalRoles(resourceRoles);
                    return actionKeys.map(actionKey => ({
                        appId: appId,
                        featureCode: null,
                        resourceId: resourceId,
                        actionKey
                    }));
                }
            });
        }
    });
}

const getHighestGlobalRoles = roles => {
    const globalRoles = roles.filter(r => !r.appId).map(r => r.actionKey);
    if (globalRoles.includes("ADMIN") || globalRoles.includes("OWNER")) {
        return ["ADMIN"];
    } else if (globalRoles.includes("WRITE") && globalRoles.includes("READ")) {
        return ["READ", "WRITE"];
    } else if (globalRoles.includes("WRITE")) {
        return ["WRITE"];
    } else if (globalRoles.includes("READ")) {
        return ["READ"];
    } else {
        return [];
    }
};

export function hasAdminOwnerUser(itemId, users) {
    if (!ENABLE_EDIT_WITHOUT_ADMIN) {
        return true;
    }

    if (!users || !users.users) {
        return true;
    }

    const adminRoles = ["OWNER", "ADMIN"];

    return users.users
        .flatMap(({ user }) => user.roles)
        .some(
            role =>
                role.resourceId === itemId &&
                role.appId === null &&
                role.featureCode === null &&
                adminRoles.includes(role.actionKey)
        );
}

export function canModifyData(user, itemId) {
    const roles = ["OWNER", "ADMIN", "WRITE"];

    return (
        user &&
        user.roles &&
        user.roles.some(
            element => element.appId == null && element.resourceId === itemId && roles.includes(element.actionKey)
        )
    );
}

/* forniti un companyId ed un user restituisce un booleano ad indicare se l'utente è un OWNER o ADMIN aziendale */
export function isItemAdmin(companyId, user) {
    return hasAdminRole(companyId, user.user.roles);
}

export function hasAdminRole(companyId, userRoles = []) {
    const isAdmin = userRoles.some(
        ele => ele.resourceId === companyId && !ele.appId && (ele.actionKey === "ADMIN" || ele.actionKey === "OWNER")
    );

    return isAdmin;
}

export function hasItemAdminRole(companyId, userRoles = []) {
    const isAdmin = userRoles.some(ele => ele.resourceId === companyId && !ele.appId && ele.actionKey === "ADMIN");

    return isAdmin;
}

export function hasOwnerRole(companyId, userRoles = []) {
    const isOwner = userRoles.some(ele => ele.resourceId === companyId && !ele.appId && ele.actionKey === "OWNER");

    return isOwner;
}

export function hasCompanyManagerRole(companyId, userRoles = []) {
    const isManager = userRoles.some(ele => ele.resourceId === companyId && ele.actionKey === "MANAGER");

    return isManager;
}

/* forniti un companyId ed un user restituisce un booleano ad indicare se l'utente è un MANAGER aziendale */
export function hasManagerRoles(companyId, user) {
    const isManager = user.user.roles.some(ele => ele.resourceId === companyId && ele.actionKey === "MANAGER");

    return isManager;
}

/* return true if user has admin role for company */
export function checkAdminRole(companyId, user) {
    const isAdmin = user.user.roles.some(
        ele => ele.resourceId === companyId && !ele.appId && ele.actionKey === "ADMIN"
    );

    return isAdmin;
}

/* return true if user has owner role for owner */
export function checkOwnerRole(companyId, user) {
    const isAdmin = user.user.roles.some(
        ele => ele.resourceId === companyId && !ele.appId && ele.actionKey === "OWNER"
    );

    return isAdmin;
}
