import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import { Container } from "./styled";
import { ActiveStatusDot } from "../styled";

export const CctSpec = ({ id, specs, name }) => (
    <Container>
        <ActiveStatusDot active={specs && name.some(n => specs[n] && specs[n].active)} />
        <FormattedMessage id={id} />
    </Container>
);

CctSpec.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.arrayOf(PropTypes.string),
    specs: PropTypes.object
};

export default CctSpec;
