import React from "react";
import PropTypes from "prop-types";

import { includes } from "ramda";
import { FormattedHTMLMessage, useIntl } from "react-intl";

import ServiceCard from "../../components/general/service-card";
import { ServiceCardsContainer } from "../general/service-card/styled";

const Cdc = ({
    services: { active, agyoServices },
    company,
    getWaitingList,
    getWaitingListStatus,
    subscribeWaitinglist,
    deleteWaitinglist,
    waitinglistDeleteStatus,
    waitinglist,
    waitinglistSubscribeStatus,
    user
}) => {
    const intl = useIntl();

    const category = {
        title: "DigitalBox"
    };

    const isActiveInAgyo = agyoServices.services.some(service => service.id === "DIGITALBOX" && service.active);
    return active.services ? (
        <ServiceCardsContainer expanded={false}>
            <ServiceCard
                disable={!isActiveInAgyo}
                ribbonActive={!isActiveInAgyo}
                ribbon={{
                    text: intl.formatMessage({
                        id: "general.coming-soon"
                    }),
                    color: "grey"
                }}
                packageInfo={{
                    active: includes("DIGITALBOX", active.services),
                    title: intl.formatMessage({
                        id: "general.buy-package"
                    }),
                    text: intl.formatMessage({
                        id: "general.contact-to-buy"
                    })
                }}
                button={{ active: false }}
                display={true}
                expanded={false}
                title={<FormattedHTMLMessage id="c-services-view-cards-container.title" />}
                description={<FormattedHTMLMessage id="c-services-view-cards-container.description" />}
                category={category}
                serviceInfo={agyoServices.services.filter(ele => ele.id === "DIGITALBOX")[0]}
                companyId={company.base.id}
                getWaitingList={getWaitingList}
                getWaitingListStatus={getWaitingListStatus}
                waitinglist={waitinglist}
                subscribeWaitinglist={subscribeWaitinglist}
                deleteWaitinglist={deleteWaitinglist}
                waitinglistDeleteStatus={waitinglistDeleteStatus}
                waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                user={user}
            />
        </ServiceCardsContainer>
    ) : null;
};

Cdc.propTypes = {
    company: PropTypes.object.isRequired,
    services: PropTypes.object.isRequired
};

export default Cdc;
