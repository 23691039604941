import React, { Component } from "react";
import { injectIntl } from "react-intl";

import {
    GAINSIGHT_APP_NAME,
    OTC_INVOICE_TRADING_WEBAPP_URL,
    TSDIGITAL_ENDPOINT,
    TS_DIGITAL_IMAGES
} from "../../../config";
import PortaleChildApp from "../../portale-child-app";

class OtcInvoiceTradingWebapp extends Component {
    render() {
        const { intl } = this.props;

        const piggyBankIconSrc = `${TS_DIGITAL_IMAGES}piggyBankIcon.svg`;

        return (
            <PortaleChildApp
                url={OTC_INVOICE_TRADING_WEBAPP_URL}
                useOldLaunchDataFormat={true}
                hideElevioAndUservoice={true}
                customLaunchData={{
                    useEmbedderGainsight: true,
                    embedderName: GAINSIGHT_APP_NAME,
                    customizations: {
                        links: {
                            invoicesList: TSDIGITAL_ENDPOINT + "#/{{selectedSupplierId}}/apps/fatturazione"
                        },
                        messages: {
                            onboardingManualSendingInstructions: intl.formatHTMLMessage(
                                { id: "apps.EIT.launchData.messages.onboardingManualSendingInstructions" },
                                { piggyBankIcon: piggyBankIconSrc }
                            ),
                            onboardingManualSendingOptionInstructions: intl.formatHTMLMessage(
                                { id: "apps.EIT.launchData.messages.onboardingManualSendingOptionInstructions" },
                                { piggyBankIcon: piggyBankIconSrc }
                            ),
                            manualSendingInstructions: intl.formatHTMLMessage(
                                { id: "apps.EIT.launchData.messages.manualSendingInstructions" },
                                { piggyBankIcon: piggyBankIconSrc }
                            )
                        },
                        appNames: {
                            invoicesList: intl.formatMessage({ id: "apps.EIP.title" })
                        }
                    }
                }}
            />
        );
    }
}

export default injectIntl(OtcInvoiceTradingWebapp);
