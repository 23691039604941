import { callWithRetry, getAuthWithAppName } from "../auth";
import { CBCR_API_URL } from "../../config";

import cbCrConfig from "../../assets/thrift/cbcr-config/CbCrFlowConfig";
import cbCrTypes from "../../assets/thrift/cbcr-config/cbcr-flow-config_types";

import thrift from "browser-thrift";

const cbCrUrl = new URL(CBCR_API_URL);
const cbCrClient = thrift.createXHRClient(
    cbCrConfig,
    thrift.createXHRConnection(cbCrUrl.hostname, 443, {
        useCors: true,
        path: cbCrUrl.pathname,
        https: cbCrUrl.protocol === "https:"
    })
);

export const CBCR_CONFIG_START = "CBCR_CONFIG_START";
export const CBCR_CONFIG_ERROR = "CBCR_CONFIG_ERROR";
export const CBCR_CONFIG_SUCCESS = "CBCR_CONFIG_SUCCESS";
export const CBCR_CONFIG_RESET = "CBCR_CONFIG_RESET";

export function getCbCrConfig(ownerId, itemId) {
    return (dispatch, getState) => {
        dispatch({
            type: CBCR_CONFIG_START
        });

        const request = new cbCrTypes.ConfigRequest({
            ownerId: ownerId,
            itemId: itemId
        });
        const codes = ["401"];

        const auth = getAuthWithAppName(getState().auth);
        callWithRetry(
            cbCrClient,
            cbCrClient.getConfig,
            auth,
            request,
            auth.refreshToken,
            dispatch,
            (error, response) => {
                if (error !== null) {
                    dispatch({
                        type: CBCR_CONFIG_ERROR,
                        error: error
                    });
                } else {
                    dispatch({
                        type: CBCR_CONFIG_SUCCESS,
                        payload: response
                    });
                }
            },
            codes
        );
    };
}

export function resetCbCrConfig() {
    return {
        type: CBCR_CONFIG_RESET
    };
}
