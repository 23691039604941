import styled from "styled-components";

export const InitialsSpan = styled.span`
    border-radius: 50%;
    background: white;
    color: #005075;
    border: 1px solid #0090d1;
    width: ${props => props.size};
    height: ${props => props.size};
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: ${props => props.size};
    ${props => (props.newComponent ? "" : "margin-right: 8px;")}
    font-size: ${props => (props.fontSize ? props.fontSize : "80%")};
`;
