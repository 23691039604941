import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { faCog } from "@fortawesome/pro-regular-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FormattedHTMLMessage } from "react-intl";

import AppLink from "../../general/app-link";
import { Card, CardContent, CardActionsBar, ButtonContainer, Icon, Logo, Description, ActionIcon } from "./styled";
import { TS_DIGITAL_IMAGES } from "../../../config";

const AppCard = ({ esmOpenApp, companyStatus, configRoute, name, hasApp, services, token }) => {
    return (
        <Card key={name}>
            <CardContent>
                <Logo src={`${TS_DIGITAL_IMAGES}apps-logo/LOGO_Digital_${name}.png`} alt={name} />
                <Description>
                    <FormattedHTMLMessage
                        id={`dashboard.appcard.${name}.shortdescription`}
                        defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    />
                </Description>
            </CardContent>
            <CardActionsBar>
                {configRoute ? (
                    <Link to={configRoute}>
                        <ButtonContainer data-gainsight-track={name === "ARC" ? "archive-configure" : ""}>
                            <Icon icon={faCog} />
                        </ButtonContainer>
                    </Link>
                ) : (
                    <div></div>
                )}
                {hasApp ? (
                    <AppLink serviceId={name} token={token} services={services} companyStatus={companyStatus}>
                        <ButtonContainer
                            onClick={() => {
                                if (name === "ESM") {
                                    esmOpenApp();
                                }
                            }}
                        >
                            <ActionIcon icon={faArrowRight} companyStatus={companyStatus} serviceId={name} />
                        </ButtonContainer>
                    </AppLink>
                ) : (
                    <div></div>
                )}
            </CardActionsBar>
        </Card>
    );
};

AppCard.propTypes = {
    esmOpenApp: PropTypes.func,
    name: PropTypes.string,
    companyStatus: PropTypes.object,
    configRoute: PropTypes.string,
    hasApp: PropTypes.bool,
    services: PropTypes.array,
    token: PropTypes.string
};

export default AppCard;
