import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import "./style.css";
import { FormattedMessage } from "react-intl";
import DownloadUpload from "../connections-download-upload";
import ConnectionsSettingsSignatureType from "../connections-settings-signature-type";
import ConnectionSettingsSharing from "../connections-settings-sharing";

class ConnectionSettingsActions extends React.Component {
    static propTypes = {
        actOfEntrustment: PropTypes.string,
        actOfEntrustmentStatus: PropTypes.object,
        companies: PropTypes.object,
        company: PropTypes.object,
        connection: PropTypes.object,
        connectionId: PropTypes.string,
        companyDescription: PropTypes.string,
        generateActOfEntrustment: PropTypes.func,
        generateActOfEntrustmentReset: PropTypes.func,
        serviceSharing: PropTypes.shape({
            share: PropTypes.bool,
            signatureType: PropTypes.string
        }),
        isAllowedToManage: PropTypes.bool,
        isDigitalBoxConnection: PropTypes.bool,
        //check if connection featire code is sdi
        isSdiConnection: PropTypes.bool,
        isSpinning: PropTypes.bool,
        // check if connection feature code is ercv
        isErcvConnection: PropTypes.bool,
        // check if company has coupon 1000
        hasCctProvisioning: PropTypes.bool,
        // check if company has at least one sdi connection
        hasSdiConnection: PropTypes.bool,
        //action
        onServiceShareChanged: PropTypes.func,
        isSelectedCompanyManager: PropTypes.bool,
        managedServiceIsReadOnly: PropTypes.bool,
        onSave: PropTypes.func,
        serviceSharingChanged: PropTypes.bool,
        uploadCert: PropTypes.func,
        user: PropTypes.object,
        uploadingAtto: PropTypes.bool
    };

    static defaultProps = {
        serviceSharing: {
            share: false,
            signatureType: "NO_SIGN"
        }
    };

    handleShareChange = share => {
        const { onServiceShareChanged, serviceSharing } = this.props;
        onServiceShareChanged({ ...serviceSharing, share });
    };

    handleSignatureChange = signatureType => {
        const { onServiceShareChanged, serviceSharing } = this.props;

        onServiceShareChanged({ ...serviceSharing, signatureType });
    };

    render() {
        const {
            actOfEntrustment,
            actOfEntrustmentStatus,
            companies,
            company,
            connection,
            connectionId,
            companyDescription,
            generateActOfEntrustment,
            generateActOfEntrustmentReset,
            hasContract,
            serviceSharing,
            isAllowedToManage,
            isDigitalBoxConnection,
            isExtensionActive,
            isSdiConnection,
            isSelectedCompanyManager,
            isSpinning,
            onSave,
            managedServiceIsReadOnly,
            needsContract,
            serviceSharingChanged,
            uploadCert,
            user,
            uploadingAtto
        } = this.props;

        const sysConnection = connection.approvalType === "SYS";
        const displaySysContract = sysConnection && !hasContract;
        const connectionSharingVisible =
            isAllowedToManage &&
            (isSdiConnection || connection.status.status === "VALIDATED") &&
            !connection.status.deleted;
        const isArcService = ["ARC"].includes(connection.serviceId);

        return (
            isSelectedCompanyManager && (
                <div className="c-connection-settings-actions">
                    {!isSpinning && (
                        <div className="actions-container">
                            <ConnectionSettingsSharing
                                firstRadioLabel={
                                    isDigitalBoxConnection ? (
                                        <FormattedMessage id="c-connections-settings-actions.service-sharing.activate-service" />
                                    ) : (
                                        <FormattedMessage id="c-connections-settings-actions.service-sharing.share-service" />
                                    )
                                }
                                onChange={val => this.handleShareChange(val)}
                                isExtensionActive={isExtensionActive}
                                isVisible={connectionSharingVisible}
                                secondRadioLabel={
                                    isDigitalBoxConnection ? (
                                        <FormattedMessage id="c-connections-settings-actions.service-sharing.deactivate-service" />
                                    ) : (
                                        <FormattedMessage id="c-connections-settings-actions.service-sharing.dont-share-service" />
                                    )
                                }
                                selectedValue={serviceSharing.share}
                                title={<FormattedMessage id="c-connections-settings-actions.service-sharing" />}
                                readonly={managedServiceIsReadOnly}
                                isArcService={isArcService}
                            />
                            <ConnectionsSettingsSignatureType
                                className="settings-action"
                                disabled={!isExtensionActive}
                                display={
                                    isAllowedToManage &&
                                    isSdiConnection &&
                                    isExtensionActive &&
                                    serviceSharing.share &&
                                    connection.status.status === "VALIDATED"
                                }
                                onChange={this.handleSignatureChange}
                                value={serviceSharing.signatureType}
                            />
                            <DownloadUpload
                                actOfEntrustment={actOfEntrustment}
                                actOfEntrustmentStatus={actOfEntrustmentStatus}
                                className="settings-action"
                                company={company}
                                companies={companies}
                                connection={connection}
                                connectionId={connectionId}
                                companyDescription={companyDescription}
                                generateActOfEntrustment={generateActOfEntrustment}
                                generateActOfEntrustmentReset={generateActOfEntrustmentReset}
                                isDisplayed={(isAllowedToManage && needsContract) || displaySysContract}
                                uploadCert={uploadCert}
                                user={user}
                                uploading={uploadingAtto}
                            />
                        </div>
                    )}
                    {connectionSharingVisible && (
                        <div className="save-button-container">
                            <Button
                                disabled={
                                    (!isExtensionActive && !isArcService && !managedServiceIsReadOnly) ||
                                    !serviceSharingChanged
                                }
                                type="primary"
                                onClick={() => onSave()}
                            >
                                <FormattedMessage id="general.save" />
                            </Button>
                        </div>
                    )}
                </div>
            )
        );
    }
}

export default ConnectionSettingsActions;
