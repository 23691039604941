export const mergeStatus = itemStatus => {
    const status = itemStatus.status;
    const certificationStatus = itemStatus.certificationStatus;

    if (itemStatus.externallyValidated) {
        if (certificationStatus !== "CERTIFIED") {
            return "VALIDATED";
        } else {
            return "CERTIFIED";
        }
    } else {
        return status === "VALIDATED" && certificationStatus ? itemStatus.certificationStatus : itemStatus.status;
    }
};
