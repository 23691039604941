import React, { Component } from "react";
import PropTypes from "prop-types";

import { Pagination } from "antd";
import { Searchbar } from "@ts-digital/arc/antd";
import { FormattedMessage } from "react-intl";

import { StepButtons } from "../../../../general/accordion-stepper/step-buttons";
import { AddColumn } from "./add-column";
import { ItemTable } from "./item-table";
import { RemoveColumn } from "./remove-column";
import { SearchbarContainer, TablesContainer, PaginationContainer } from "./styled";

class SelectCompanies extends Component {
    constructor(props) {
        super(props);

        this.state = {
            availableCompanyPage: 1,
            filter: "",
            selectingAll: false,
            selectedItemsPage: 1,
            selectedItems: [...props.initialSelectedItems]
        };
    }

    static propTypes = {
        backDisabled: PropTypes.bool,
        companies: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                details: PropTypes.shape({
                    description: PropTypes.string
                }),
                identifier: PropTypes.shape({
                    taxId: PropTypes.string,
                    vatNumber: PropTypes.string
                }),
                status: PropTypes.shape({
                    status: PropTypes.string
                })
            })
        ),
        companiesPage: PropTypes.arrayOf(PropTypes.string),
        disabled: PropTypes.bool,
        initialSelectedItems: PropTypes.arrayOf(PropTypes.string),
        loadingItems: PropTypes.bool,
        loadingNext: PropTypes.bool,
        onBack: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        selectedItemsLoading: PropTypes.bool,
        totalItems: PropTypes.number,
        userRoles: PropTypes.arrayOf(
            PropTypes.shape({
                appId: PropTypes.string,
                featureCode: PropTypes.string,
                resourceId: PropTypes.string,
                actionKey: PropTypes.string
            })
        )
    };

    static defaultProps = {
        backDisabled: false,
        companies: [],
        companiesPage: [],
        disabled: false,
        initialSelectedItems: [],
        loadingItems: false,
        loadingNext: false,
        selectedItemsLoading: false,
        totalItems: 0,
        userRoles: []
    };

    componentDidUpdate(prevProps) {
        const { companies, initialSelectedItems, selectedItemsLoading, loadingItems } = this.props;
        const { selectingAll } = this.state;

        if (prevProps.loadingItems && !loadingItems) {
            if (selectingAll) {
                this.setState({
                    selectedItems: companies.filter(i => i.status.status === "VALIDATED").map(c => c.id),
                    selectedItemsPage: 1,
                    selectingAll: false
                });
            }
        }

        if (prevProps.selectedItemsLoading && !selectedItemsLoading) {
            this.setState({ selectedItems: [...initialSelectedItems] });
        }
    }

    getDisabledItems() {
        const { companies } = this.props;

        return companies.filter(c => c.status.status !== "VALIDATED").map(c => c.id);
    }

    getPageItems() {
        const { companies, companiesPage } = this.props;
        return companiesPage.map(
            itemId =>
                companies.find(c => c.id === itemId) || {
                    details: {},
                    identifier: {},
                    status: {}
                }
        );
    }

    getSelectedItems() {
        const { companies } = this.props;
        const { selectedItems } = this.state;

        return selectedItems
            .map(
                itemId =>
                    companies.find(c => c.id === itemId) || {
                        details: {},
                        identifier: {},
                        status: {}
                    }
            )
            .filter(i => i !== null);
    }

    handleItemSelection(record) {
        const { selectedItems } = this.state;

        if (!record.selected) {
            this.setState({ selectedItems: [record.id, ...selectedItems] });
        }
    }

    handleItemDeselection(record) {
        const { selectedItems } = this.state;

        this.setState({
            selectedItems: selectedItems.filter(i => i !== record.id)
        });
    }

    handlePageChange = newPage => {
        const { onSearch } = this.props;
        const { filter } = this.state;
        onSearch(filter, newPage - 1, 5);
        this.setState({ availableCompanyPage: newPage });
    };

    handleSearch = ({ value }) => {
        const { onSearch } = this.props;
        onSearch(value, 0, 5);
        this.setState({
            availableCompanyPage: 1,
            filter: value,
            selectedItemsPage: 1
        });
    };

    handleSelectAll = () => {
        const { onSelectAll } = this.props;

        this.setState({ selectingAll: true });
        onSelectAll();
    };

    mapCompaniesToTable(companies) {
        const { selectedItems } = this.state;

        return companies.map(c => ({
            key: c.id,
            description:
                c.details.classifier !== "PERSON"
                    ? c.details.description
                    : `${c.details.firstName} ${c.details.lastName}`,
            id: c.id,
            selected: selectedItems.includes(c.id),
            taxId: c.identifier.taxId,
            validated: c.status.status === "VALIDATED",
            vatNumber: c.identifier.vatNumber || "-"
        }));
    }

    mapSelectedItemsToTable() {
        const { filter, selectedItemsPage } = this.state;

        const lowerCaseFilter = filter.toLowerCase();
        const displayedItems = this.getSelectedItems()
            .filter(i => i.details.description || i.details.firstName)
            .filter(i => {
                const description =
                    i.details.classifier === "PERSON"
                        ? `${i.details.firstName} ${i.details.lastName}`
                        : i.details.description;
                const taxId = `${i.identifier.taxId}` || "";
                const vatNumber = `${i.identifier.vatNumber}` || "";

                return (
                    description.toLowerCase().includes(lowerCaseFilter) ||
                    taxId.toLowerCase().includes(lowerCaseFilter) ||
                    vatNumber.toLowerCase().includes(lowerCaseFilter)
                );
            })
            .slice((selectedItemsPage - 1) * 5, selectedItemsPage * 5);
        return this.mapCompaniesToTable(displayedItems);
    }

    render() {
        const {
            backDisabled,
            disabled,
            loadingItems,
            loadingNext,
            onBack,
            onSubmit,
            selectedItemsLoading,
            totalItems,
            userRoles
        } = this.props;
        const { selectedItemsPage, selectedItems } = this.state;

        const addCol = {
            title: "",
            key: "action",
            render: (text, record) => {
                let tooltip = "c-user-create-modal.select-item";

                if (!record.validated) {
                    tooltip = "c-user-create-modal.not-validated";
                } else if (record.selected) {
                    tooltip = "c-user-create-modal.company-selected";
                }

                return (
                    <AddColumn
                        disabled={record.selected || !record.validated}
                        onClick={() => this.handleItemSelection(record)}
                        tooltip={tooltip}
                    />
                );
            }
        };

        const removeCol = {
            title: "",
            key: "action",
            render: (text, record) => <RemoveColumn onClick={() => this.handleItemDeselection(record)} />
        };

        return (
            <div>
                <SearchbarContainer>
                    <Searchbar
                        disabled={disabled}
                        onSearch={this.handleSearch}
                        placeholder={<FormattedMessage id="c-manage-data.multicompany-create.search-company" />}
                    />
                </SearchbarContainer>
                <TablesContainer>
                    <ItemTable
                        buttonLabel="c-manage-data.multicompany-create.select-all-companies"
                        data={this.mapCompaniesToTable(this.getPageItems())}
                        disabledRows={this.getDisabledItems()}
                        disabled={disabled}
                        extraColumns={[addCol]}
                        loading={loadingItems}
                        onButtonClick={this.handleSelectAll}
                        selectedRows={selectedItems}
                        title={"c-manage-data.multicompany-create.available-companies"}
                        userRoles={userRoles}
                    />
                    <ItemTable
                        buttonLabel="c-manage-data.multicompany-create.deselect-companies"
                        data={this.mapSelectedItemsToTable()}
                        disabled={disabled}
                        extraColumns={[removeCol]}
                        loading={selectedItemsLoading}
                        onButtonClick={() =>
                            this.setState({
                                selectedItems: [],
                                selectedItemsPage: 1
                            })
                        }
                        title="c-manage-data.multicompany-create.selected-companies"
                        userRoles={userRoles}
                    />
                </TablesContainer>
                <PaginationContainer>
                    <div>
                        <Pagination
                            simple
                            defaultCurrent={1}
                            current={totalItems > 0 ? this.state.availableCompanyPage : 0}
                            total={totalItems}
                            defaultPageSize={5}
                            showSizeChanger={false}
                            onChange={this.handlePageChange}
                        />
                    </div>
                    <div>
                        <Pagination
                            simple
                            defaultCurrent={1}
                            current={selectedItems.length > 0 ? selectedItemsPage : 0}
                            total={selectedItems.length}
                            defaultPageSize={5}
                            showSizeChanger={false}
                            onChange={page => {
                                this.setState({
                                    selectedItemsPage: page
                                });
                            }}
                        />
                    </div>
                </PaginationContainer>
                <StepButtons
                    loading={loadingNext}
                    nextDisabled={disabled || selectedItems.length <= 0}
                    onNext={() => onSubmit(this.getSelectedItems())}
                    onPrev={onBack}
                    prevDisabled={disabled || backDisabled}
                />
            </div>
        );
    }
}

export default SelectCompanies;
