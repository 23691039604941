import React from "react";
import PropTypes from "prop-types";
import { StyledLink } from "./styled";

const Link = ({ content, onClick, dataGainsightTrack }) => {
    return (
        <StyledLink onClick={onClick} data-gainsight-track={dataGainsightTrack || ""}>
            {content}
        </StyledLink>
    );
};

Link.propTypes = {
    onClick: PropTypes.func
};

export default Link;
