import React from "react";

import PortaleChildApp from "../../portale-child-app";
import { connect } from "react-redux";

const DeepTierView = ({ selectedItem }) => (
    <PortaleChildApp appId="SCF" serviceId="SCF" deepLinkParameters={{ item_id: selectedItem.base.uuid }} />
);

export default connect((state, props) => ({
    selectedItem: state.companies.data[props.match.params.item]
        ? state.companies.data[props.match.params.item].item
        : {}
}))(DeepTierView);
