import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { compose } from "redux";
import { FormattedMessage } from "react-intl";

import "../style.css";

import Signature from "../../../components/signature";
import PageTitle from "../../../components/general/page-title";

import { getWaitingList } from "../../../actions/waitinglist/read";
import { subscribeWaitinglist, deleteWaitinglist } from "../../../actions/waitinglist/write";

class SignatureView extends React.Component {
    static propTypes = {
        company: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        services: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired
    };

    render() {
        const {
            company,
            history,
            match,
            services,
            user,
            getWaitingList,
            getWaitingListStatus,
            subscribeWaitinglist,
            deleteWaitinglist,
            waitinglistDeleteStatus,
            waitinglist,
            waitinglistSubscribeStatus
        } = this.props;
        return (
            <div className="service-view">
                <PageTitle title={<FormattedMessage id="c-services-view.SIG.name" />} onBack={() => history.goBack()} />
                <Signature
                    company={company}
                    match={match}
                    services={services}
                    user={user}
                    getWaitingList={getWaitingList}
                    getWaitingListStatus={getWaitingListStatus}
                    subscribeWaitinglist={subscribeWaitinglist}
                    deleteWaitinglist={deleteWaitinglist}
                    waitinglistDeleteStatus={waitinglistDeleteStatus}
                    waitinglist={waitinglist}
                    waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    services: state.services,
    user: state.user,
    waitinglist: state.waitinglist.read.getWaitingList.waitinglist,
    waitinglistSubscribeStatus: state.waitinglist.write.subscribeWaitinglist.status,
    waitinglistDeleteStatus: state.waitinglist.write.deleteWaitinglist.status,
    getWaitingListStatus: state.waitinglist.read.getWaitingList.status
});

const composedHoc = compose(connect(mapStateToProps, { getWaitingList, subscribeWaitinglist, deleteWaitinglist }));

export default composedHoc(SignatureView);
