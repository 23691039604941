import React from "react";

import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Button } from "@ts-digital/arc/antd/Button";

import FontawesomeIcon from "../../components/general/fontawesome-icon";
import { Container, MessageContainer } from "./styled";

export const UserActivationError = ({ match }) => {
    return (
        <Container>
            <FontawesomeIcon color="#ff0000a6" name="exclamation-circle" solid={true} size="60px" />
            <MessageContainer>
                <FormattedMessage id="v-user-activation-error.message" />
            </MessageContainer>
            <Link to="/login">
                <Button type="primary">
                    <FormattedMessage id="general.back-to-home" />
                </Button>
            </Link>
        </Container>
    );
};
