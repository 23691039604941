import React, { Component } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import { Modal } from "antd";

class IntermediaryRow extends Component {
    static propTypes = {
        column: PropTypes.object.isRequired,
        isEnable: PropTypes.object.isRequired,
        onClick: PropTypes.func,
        openDelete: PropTypes.func,
        openInfo: PropTypes.func,
        value: PropTypes.object
    };

    constructor() {
        super();
        this.state = {
            mEditIntermediary: false
        };
    }

    onDocumentClick() {
        const { value, onClick } = this.props;
        if (onClick) {
            onClick(value);
        }
    }

    renderModalEditIntermediary() {
        const editModalContent = <FormattedMessage id="c-registry-view-intermediary-disabled-edit" />;

        const editModelTitle = <FormattedMessage id="c-registry-view-intermediary-disabled-edit-title" />;

        return (
            <div>
                <Modal
                    footer={null}
                    onCancel={() =>
                        this.setState({
                            mEditIntermediary: false
                        })
                    }
                    style={{ top: "20%" }}
                    title={editModelTitle}
                    visible={this.state.mEditIntermediary}
                >
                    {editModalContent}
                </Modal>
            </div>
        );
    }

    renderMapMessage() {
        const { text, column, openDelete, openInfo, isEnable } = this.props;

        if (column.title === "general.creation-date") {
            return <div>{new Date(Number(text)).toLocaleString()}</div>;
        }
        if (column.title !== "general.action") {
            return <div>{text}</div>;
        } else {
            return (
                <div
                    style={{
                        cursor: isEnable.status ? "pointer" : "",
                        fontSize: "20px"
                    }}
                >
                    <i
                        className="fa fa-info-circle"
                        onClick={isEnable.status ? openInfo : null}
                        style={{
                            margin: "0px 15px",
                            color: isEnable.status ? "" : isEnable.color
                        }}
                    />
                    <i
                        className="fa fa-pencil-alt"
                        onClick={
                            isEnable.status
                                ? () =>
                                      this.setState({
                                          mEditIntermediary: true
                                      })
                                : null
                        }
                        style={{
                            margin: "0px 15px",
                            color: isEnable.status ? "" : isEnable.color
                        }}
                    />
                    <i
                        className="fa fa-trash"
                        onClick={isEnable.status ? openDelete : null}
                        style={{
                            margin: "0px 15px",
                            color: isEnable.status ? "#e57373" : isEnable.color
                        }}
                    />
                </div>
            );
        }
    }

    render() {
        return (
            <div>
                {this.renderMapMessage()}
                {this.renderModalEditIntermediary()}
            </div>
        );
    }
}

export default IntermediaryRow;
