/* eslint-disable */
//
// Autogenerated by Thrift Compiler (0.11.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
"use strict";

var thrift = require('browser-thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = require('./services-subscription_types');
//HELPER FUNCTIONS AND STRUCTURES

var ServicesSubscription_health_args = function(args) {
};
ServicesSubscription_health_args.prototype = {};
ServicesSubscription_health_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ServicesSubscription_health_args.prototype.write = function(output) {
  output.writeStructBegin('ServicesSubscription_health_args');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ServicesSubscription_health_result = function(args) {
  this.success = null;
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
  }
};
ServicesSubscription_health_result.prototype = {};
ServicesSubscription_health_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRING) {
        this.success = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ServicesSubscription_health_result.prototype.write = function(output) {
  output.writeStructBegin('ServicesSubscription_health_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRING, 0);
    output.writeString(this.success);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ServicesSubscription_agyoServices_args = function(args) {
  this.header = null;
  if (args) {
    if (args.header !== undefined && args.header !== null) {
      this.header = new ttypes.Header(args.header);
    }
  }
};
ServicesSubscription_agyoServices_args.prototype = {};
ServicesSubscription_agyoServices_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.header = new ttypes.Header();
        this.header.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ServicesSubscription_agyoServices_args.prototype.write = function(output) {
  output.writeStructBegin('ServicesSubscription_agyoServices_args');
  if (this.header !== null && this.header !== undefined) {
    output.writeFieldBegin('header', Thrift.Type.STRUCT, 1);
    this.header.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ServicesSubscription_agyoServices_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.AgyoServicesResponse(args.success);
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
ServicesSubscription_agyoServices_result.prototype = {};
ServicesSubscription_agyoServices_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.AgyoServicesResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ServicesSubscription_agyoServices_result.prototype.write = function(output) {
  output.writeStructBegin('ServicesSubscription_agyoServices_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ServicesSubscription_itemServices_args = function(args) {
  this.header = null;
  this.request = null;
  if (args) {
    if (args.header !== undefined && args.header !== null) {
      this.header = new ttypes.Header(args.header);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.ItemServicesRequest(args.request);
    }
  }
};
ServicesSubscription_itemServices_args.prototype = {};
ServicesSubscription_itemServices_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.header = new ttypes.Header();
        this.header.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.ItemServicesRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ServicesSubscription_itemServices_args.prototype.write = function(output) {
  output.writeStructBegin('ServicesSubscription_itemServices_args');
  if (this.header !== null && this.header !== undefined) {
    output.writeFieldBegin('header', Thrift.Type.STRUCT, 1);
    this.header.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ServicesSubscription_itemServices_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.ItemServicesResponse(args.success);
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
ServicesSubscription_itemServices_result.prototype = {};
ServicesSubscription_itemServices_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.ItemServicesResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ServicesSubscription_itemServices_result.prototype.write = function(output) {
  output.writeStructBegin('ServicesSubscription_itemServices_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ServicesSubscriptionClient = exports.Client = function(output, pClass) {
    this.output = output;
    this.pClass = pClass;
    this._seqid = 0;
    this._reqs = {};
};
ServicesSubscriptionClient.prototype = {};
ServicesSubscriptionClient.prototype.seqid = function() { return this._seqid; };
ServicesSubscriptionClient.prototype.new_seqid = function() { return this._seqid += 1; };
ServicesSubscriptionClient.prototype.health = function(callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_health();
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_health();
  }
};

ServicesSubscriptionClient.prototype.send_health = function() {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('health', Thrift.MessageType.CALL, this.seqid());
  var args = new ServicesSubscription_health_args();
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

ServicesSubscriptionClient.prototype.recv_health = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new ServicesSubscription_health_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('health failed: unknown result');
};
ServicesSubscriptionClient.prototype.agyoServices = function(header, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_agyoServices(header);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_agyoServices(header);
  }
};

ServicesSubscriptionClient.prototype.send_agyoServices = function(header) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('agyoServices', Thrift.MessageType.CALL, this.seqid());
  var params = {
    header: header
  };
  var args = new ServicesSubscription_agyoServices_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

ServicesSubscriptionClient.prototype.recv_agyoServices = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new ServicesSubscription_agyoServices_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('agyoServices failed: unknown result');
};
ServicesSubscriptionClient.prototype.itemServices = function(header, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_itemServices(header, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_itemServices(header, request);
  }
};

ServicesSubscriptionClient.prototype.send_itemServices = function(header, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('itemServices', Thrift.MessageType.CALL, this.seqid());
  var params = {
    header: header,
    request: request
  };
  var args = new ServicesSubscription_itemServices_args(params);
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

ServicesSubscriptionClient.prototype.recv_itemServices = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new ServicesSubscription_itemServices_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('itemServices failed: unknown result');
};
var ServicesSubscriptionProcessor = exports.Processor = function(handler) {
  this._handler = handler;
}
;
ServicesSubscriptionProcessor.prototype.process = function(input, output) {
  var r = input.readMessageBegin();
  if (this['process_' + r.fname]) {
    return this['process_' + r.fname].call(this, r.rseqid, input, output);
  } else {
    input.skip(Thrift.Type.STRUCT);
    input.readMessageEnd();
    var x = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN_METHOD, 'Unknown function ' + r.fname);
    output.writeMessageBegin(r.fname, Thrift.MessageType.EXCEPTION, r.rseqid);
    x.write(output);
    output.writeMessageEnd();
    output.flush();
  }
}
;
ServicesSubscriptionProcessor.prototype.process_health = function(seqid, input, output) {
  var args = new ServicesSubscription_health_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.health.length === 0) {
    Q.fcall(this._handler.health.bind(this._handler))
      .then(function(result) {
        var result_obj = new ServicesSubscription_health_result({success: result});
        output.writeMessageBegin("health", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("health", Thrift.MessageType.EXCEPTION, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.health(function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined')) {
        result_obj = new ServicesSubscription_health_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("health", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("health", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
ServicesSubscriptionProcessor.prototype.process_agyoServices = function(seqid, input, output) {
  var args = new ServicesSubscription_agyoServices_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.agyoServices.length === 1) {
    Q.fcall(this._handler.agyoServices.bind(this._handler), args.header)
      .then(function(result) {
        var result_obj = new ServicesSubscription_agyoServices_result({success: result});
        output.writeMessageBegin("agyoServices", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
          result = new ServicesSubscription_agyoServices_result(err);
          output.writeMessageBegin("agyoServices", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("agyoServices", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.agyoServices(args.header, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
        result_obj = new ServicesSubscription_agyoServices_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("agyoServices", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("agyoServices", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
ServicesSubscriptionProcessor.prototype.process_itemServices = function(seqid, input, output) {
  var args = new ServicesSubscription_itemServices_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.itemServices.length === 2) {
    Q.fcall(this._handler.itemServices.bind(this._handler), args.header, args.request)
      .then(function(result) {
        var result_obj = new ServicesSubscription_itemServices_result({success: result});
        output.writeMessageBegin("itemServices", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
          result = new ServicesSubscription_itemServices_result(err);
          output.writeMessageBegin("itemServices", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("itemServices", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.itemServices(args.header, args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException) {
        result_obj = new ServicesSubscription_itemServices_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("itemServices", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("itemServices", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
