import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Row, Col } from "antd";

import StatusTag from "../../general/status-tag";
import InfoDetails from "./info-details";
import UserRolesTable from "./role-table";

import "./style.css";

const statusTagMapping = {
    ACTIVE: {
        circle: "green",
        label: "c-registry-user.info.active",
        style: "green"
    },
    NOTACTIVE: {
        circle: "red",
        label: "c-registry-user.info.notactive",
        style: "red"
    }
};

const UserInfo = ({ userRoles, selectedUser, goBack, agyoServices }) => {
    let statusLabel;

    if (selectedUser) {
        statusLabel = selectedUser.status.active ? "ACTIVE" : "NOTACTIVE";
    }

    const userResources = userRoles.map(val => val.resourceId).filter((item, pos, arr) => arr.indexOf(item) === pos);

    return selectedUser ? (
        <div className="c-user-info">
            <Row className="back-row">
                <Col>
                    <button type="button" className="link-button" onClick={goBack}>
                        <FormattedMessage id="c-registry-user.info.back-to-users" />
                    </button>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={5}>
                    <span>
                        <h2>
                            <FormattedMessage id="c-registry-user.info.detail" />
                        </h2>
                    </span>
                </Col>
                <Col span={4}>
                    <div className="status-tag">
                        <StatusTag {...statusTagMapping[statusLabel]} />
                    </div>
                </Col>
                <Col />
            </Row>
            <Row>
                <Col>
                    <InfoDetails info={selectedUser} />
                </Col>
            </Row>
            <Row gutter={12} className="roles-row">
                <Col span={5}>
                    <span>
                        <h2>
                            <FormattedMessage id="c-registry-user.info.roles" />
                        </h2>
                    </span>
                </Col>
            </Row>
            <Row>
                <Col>
                    <UserRolesTable
                        selectedUser={selectedUser}
                        userResources={userResources}
                        agyoServices={agyoServices}
                    />
                </Col>
            </Row>
        </div>
    ) : (
        <FormattedMessage id="general.service.loading" />
    );
};

UserInfo.propTypes = {
    userRoles: PropTypes.array,
    goBack: PropTypes.func.isRequired,
    selectedUser: PropTypes.object,
    agyoServices: PropTypes.array
};

export default UserInfo;
