import React from "react";
import PropTypes from "prop-types";

import { StyledModal } from "../styled";
import { Button } from "@ts-digital/vrc";
import { FormattedMessage } from "react-intl";

const ErrorModal = ({ onCancel, onRetry, visible }) => {
    return (
        <StyledModal
            closable={false}
            footer={
                <>
                    <Button kind="secondary" onClick={onCancel}>
                        <FormattedMessage id="general.annulla" />
                    </Button>
                    <Button kind="primary" onClick={onRetry}>
                        <FormattedMessage id="general.retry" />
                    </Button>
                </>
            }
            maskStyle={{
                background: "rgba(0, 80, 117, 0.32)"
            }}
            title={<FormattedMessage id="general.simple-error" />}
            type="danger"
            visible={visible}
            width="496px"
        >
            <FormattedMessage id="v-user-creation-flow.modals.content.error" />
        </StyledModal>
    );
};

ErrorModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onRetry: PropTypes.func.isRequired,
    visible: PropTypes.bool
};

ErrorModal.defaultProps = {
    visible: false
};

export default ErrorModal;
