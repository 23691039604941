import React from "react";
import PropTypes from "prop-types";

import { TS_DIGITAL_IMAGES } from "../../config";
import { Container, Logo, LogoContainer, Title } from "./styled";

const LoginModal = ({ children, title }) => {
    return (
        <Container>
            <LogoContainer>
                <Logo src={`${TS_DIGITAL_IMAGES}logo-with-ts-color.svg`} />
            </LogoContainer>
            {title && <Title>{title}</Title>}
            {children}
        </Container>
    );
};

LoginModal.propTypes = {
    children: PropTypes.any,
    title: PropTypes.any
};

export default LoginModal;
