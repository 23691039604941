import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Container, CustomButton } from "./styled";

export const ActionCell = ({ onEnable, onDisable, row }) => {
    let disabled = row && row.active;

    return (
        <Container>
            <CustomButton
                kind="tertiary"
                disabled={disabled}
                onClick={() => {
                    onEnable(row.ownerId, row.itemId);
                }}
            >
                <FormattedMessage id={"c-cct-configure-managed-items-personal-columns.actions.enable"} />
            </CustomButton>
            <CustomButton
                kind="tertiary"
                disabled={!disabled}
                onClick={() => {
                    onDisable(row.ownerId, row.itemId);
                }}
            >
                <FormattedMessage id={"c-cct-configure-managed-items-personal-columns.actions.disable"} />
            </CustomButton>
        </Container>
    );
};

ActionCell.propTypes = {
    action: PropTypes.string,
    row: PropTypes.object,
    onEnable: PropTypes.func.isRequired,
    onDisable: PropTypes.func.isRequired
};
