import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { ContentSwitcher } from "@ts-digital/arc/antd";
import { useHistory, useParams, useLocation } from "react-router";

import ElevioElements from "../../elevio-elements";
import PageTitle from "../../general/page-title";
import { SwitcherContainer } from "./styled";
import { useEmbed } from "../../../hooks/use-embed";

export const ConnectionsActionBar = ({ isStudio }) => {
    const history = useHistory();
    const location = useLocation();
    const params = useParams();

    const { isEmbed } = useEmbed();
    const pathName = useMemo(() => {
        return location.pathname.slice(-1) === "/" ? location.pathname.slice(0, -1) : location.pathname;
    }, [location.pathname]);

    const changeTab = useCallback(
        key => {
            history.push(`/${params.item}/connections/${key}`);
        },
        [history, params.item]
    );

    const titleMessage = params.history
        ? `c-connection-action-bar.${params.history + "."}title`
        : `c-connection-action-bar.title`;

    return (
        <div className="c-connection-switcher">
            <PageTitle
                onRightButtonClick={
                    params.type === "managed" && !params.history
                        ? () => {
                              history.push(`/${params.item}/connections/managed/history`);
                          }
                        : undefined
                }
                item={params.item}
                title={
                    <span>
                        <FormattedMessage id={titleMessage} />
                        <ElevioElements idArticle="216" />
                    </span>
                }
                onBack={
                    isEmbed && pathName === `/${params.item}/connections/managed` ? undefined : () => history.goBack()
                }
                rightButtonTitle={<FormattedMessage id="c-connection-action-bar.history.title" />}
            />
            {!params.history && (
                <SwitcherContainer>
                    {isStudio && (
                        <ContentSwitcher
                            activeKey={`${params.type}`}
                            onChange={changeTab}
                            options={[
                                {
                                    key: "managed",
                                    label: (
                                        <div>
                                            <FormattedMessage id="c-connection-switcher.manager-conn" />
                                        </div>
                                    )
                                },
                                {
                                    key: "manager",
                                    label: (
                                        <div>
                                            <FormattedMessage id="c-connection-switcher.managed-conn" />
                                        </div>
                                    )
                                }
                            ]}
                        />
                    )}
                </SwitcherContainer>
            )}
        </div>
    );
};

ConnectionsActionBar.propTypes = {
    focus: PropTypes.string,
    isStudio: PropTypes.bool
};

ConnectionsActionBar.defaultProps = {
    isStudio: false
};

export default ConnectionsActionBar;
