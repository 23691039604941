import React, { Component } from "react";
import PropTypes from "prop-types";

import { Spin } from "antd";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { compose } from "redux";

import { getNotification, listNotifications } from "../../actions/notification/read";
import {
    acceptNotification,
    readNotification,
    markNotificationAsUnread,
    rejectNotification
} from "../../actions/notification/write";
import { findOwnManagedConnections } from "../../actions/connections/read";

import { errorBoundary } from "../../components/general/error-boundary";
import NotificationList from "../../components/notification-list";
import DetailedNotification from "../../components/notification/detailed-notification";
import DateTime from "../../components/datetime";
import { Header, Content, List, NotificationContainer, NotificationBox } from "./styled";

class NotificationsView extends Component {
    static propTypes = {
        company: PropTypes.object.isRequired,
        connectionsNew: PropTypes.object.isRequired,
        findOwnManagedConnections: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired, // obtained from <Route>
        loadingNotifications: PropTypes.bool.isRequired,
        loadingSelectedNotification: PropTypes.bool.isRequired,
        notifications: PropTypes.array.isRequired,
        selectedCompanyId: PropTypes.string.isRequired,
        selectedNotification: PropTypes.object
    };

    componentDidMount() {
        const { getNotification, match, notifications } = this.props;

        if (
            match.params.id &&
            match.params.id !== "settings" &&
            notifications.filter(e => e.id === match.params.id).length === 0
        ) {
            const selectedNotification = notifications.find(notification => notification.id === match.params.id);
            const readStatus = selectedNotification ? !selectedNotification.readStatus : false;
            getNotification(match.params.id, readStatus);
        }
    }

    componentDidUpdate(prevProps) {
        const { getNotification, history, match, notifications, selectedCompanyId, selectedNotification } = this.props;

        if (
            prevProps.match.params.id !== match.params.id &&
            match.params.id &&
            !selectedNotification.id &&
            notifications.filter(e => e.id === match.params.id).length === 0 &&
            match.params.id !== "settings"
        ) {
            getNotification(match.params.id, selectedNotification.readStatus);
        }

        if (selectedNotification.recipientId && selectedNotification.recipientId !== selectedCompanyId) {
            history.push(`/${match.params.item}/notifications`);
        }
    }

    render() {
        const {
            acceptNotification,
            acceptingNotification,
            hasMoreNotifications,
            listNotifications,
            loadingNotifications,
            loadingSelectedNotification,
            markNotificationAsUnread,
            notifications,
            readNotification,
            rejectNotification,
            rejectingNotification,
            selectedCompanyId,
            selectedNotification
        } = this.props;

        return (
            <div>
                <Header>
                    <FormattedMessage id="c-notifications-view.title" />
                </Header>
                <Content>
                    <List>
                        <NotificationList
                            displayListButton={false}
                            fullWidth={true}
                            isLoading={loadingNotifications}
                            hasMoreNotifications={hasMoreNotifications}
                            onLoadNotifications={page => listNotifications(selectedCompanyId, page, 25)}
                            onReadNotification={id => readNotification(id)}
                            onUnreadNotification={id => markNotificationAsUnread(id)}
                            notifications={notifications}
                        />
                    </List>
                    <NotificationContainer>
                        <NotificationBox>
                            <Spin spinning={loadingSelectedNotification}>
                                <DetailedNotification
                                    accepted={selectedNotification.accepted}
                                    accepting={acceptingNotification}
                                    id={selectedNotification.id}
                                    content={
                                        selectedNotification.id && (
                                            <FormattedMessage
                                                id={`notification.${selectedNotification.name}`}
                                                values={{
                                                    sender: selectedNotification.requesterName,
                                                    senderId: selectedNotification.requesterId,
                                                    recipient: selectedNotification.recipientName,
                                                    recipientId: selectedNotification.recipientId,
                                                    ...Object.entries(selectedNotification.extraValues).reduce(
                                                        (acc, v) => {
                                                            acc[v[0]] = <FormattedMessage id={v[1]} />;
                                                            return acc;
                                                        },
                                                        {}
                                                    )
                                                }}
                                            />
                                        )
                                    }
                                    rejected={selectedNotification.rejected}
                                    rejecting={rejectingNotification}
                                    title={<FormattedMessage id={`notification.${selectedNotification.name}.title`} />}
                                    date={<DateTime value={selectedNotification.createdAt} />}
                                    type={selectedNotification.type}
                                    displayActions={selectedNotification.type === "REQUEST"}
                                    onAccept={id => acceptNotification(id)}
                                    onReject={id => rejectNotification(id)}
                                    notification={selectedNotification}
                                />
                            </Spin>
                        </NotificationBox>
                    </NotificationContainer>
                </Content>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const currentPage = state.notification.read.listNotifications.page;
    const pageSize = state.notification.read.listNotifications.pageSize;
    const totalNotifications = state.notification.read.listNotifications.totalNotifications;

    return {
        company: state.companies.data[props.match.params.item].item,
        connectionsNew: state.connectionsNew.read,
        acceptingNotification: state.notification.write.acceptNotification.status.started,
        hasMoreNotifications: (currentPage + 1) * pageSize < totalNotifications,
        loadingNotifications: state.notification.read.listNotifications.status.started,
        loadingSelectedNotification: state.notification.read.getNotification.status.started,
        notifications: state.notification.read.listNotifications.list.map(id => state.notification.read.results[id]),
        rejectingNotification: state.notification.write.rejectNotification.status.started,
        selectedCompanyId: state.companies.data[props.match.params.item]
            ? state.companies.data[props.match.params.item].item.base.id
            : "",
        selectedNotification: state.notification.read.results[props.match.params.id] || {}
    };
};

const actions = {
    acceptNotification,
    findOwnManagedConnections,
    getNotification,
    listNotifications,
    markNotificationAsUnread,
    readNotification,
    rejectNotification
};

const composedHoc = compose(connect(mapStateToProps, actions), errorBoundary);

export default composedHoc(NotificationsView);
