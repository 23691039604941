import React, { Component } from "react";
import { PropTypes } from "prop-types";

import { MeteringCard, MeteringCardContent, MeteringCardAction } from "./styled";

export class Card extends Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string
    };

    render() {
        const { children, className } = this.props;
        return <MeteringCard className={className}>{children}</MeteringCard>;
    }
}

export class CardContent extends Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string
    };

    render() {
        const { children, className } = this.props;
        return <MeteringCardContent className={className}>{children}</MeteringCardContent>;
    }
}

export class CardAction extends Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string
    };

    render() {
        const { children, className } = this.props;
        return <MeteringCardAction className={className}>{children}</MeteringCardAction>;
    }
}
