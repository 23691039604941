import styled, { css } from "styled-components";

export const Container = styled.div`
    margin-top: 56px;
`;

export const TableContainer = styled.div`
    margin-top: 28px;
    width: 100%;
`;

export const TableCell = styled.div`
    ${props => {
        if (props.disabled) {
            return css`
                color: ${props => props.theme.colors.text.quickSilver};
            `;
        }
    }}
`;

export const DescriptionCell = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
`;
