import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { Col, Row, Tooltip, Spin } from "antd";

import {
    AsideImage,
    ActiveStatusDot,
    CloseIcon,
    Container,
    ConfigureButtonContainer,
    Description,
    Hyperlink,
    InfoIcon,
    InfoLabel,
    LoadingButtonContainer,
    MarginIcon,
    NotifyStatusContainerDiv,
    NotifyStatusText,
    OtherUserOnboardingLabel,
    Service,
    ServiceContainer,
    Title,
    SpinnerContainer,
    StatusIcon,
    StatusMessageContainer
} from "./styled";

import { FormattedMessage } from "react-intl";

import LoadingButton from "../general/loading-button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedHTMLMessage } from "react-intl";
import { faUser, faTimes } from "@fortawesome/pro-regular-svg-icons";
import {
    faExclamationCircle,
    faMinusCircle,
    faClock,
    faRedo,
    faCheckCircle as solidFaCheckCircle
} from "@fortawesome/free-solid-svg-icons";

import { TS_DIGITAL_IMAGES } from "../../config";

const TspayConfig = ({
    getConfigLoading,
    isServiceActive,
    itemId,
    tsPayOnboarding,
    tsPayStatus,
    tspayOnboardingStarted,
    tsPayGetConfig,
    tsPayOnboardingSessions,
    tspayResendConfirmEmail,
    tspayResendConfirmEmailLoading
}) => {
    const [disableButton, setDisableButton] = useState(false);
    const [displayInfoBox, setDisplayInfoBox] = useState(false);
    const [displayInfoBoxLabelId, setDisplayInfoBoxLabelId] = useState("stub");

    const initialStatus = useRef(tsPayStatus);
    useEffect(() => {
        if (initialStatus.current === undefined && tsPayStatus !== undefined) {
            initialStatus.current = tsPayStatus;
        }
        if (initialStatus.current === "ONBOARDING" && tsPayStatus === "TO_BE_CONFIRMED") {
            setDisplayInfoBox(true);
            setDisplayInfoBoxLabelId("tspay-info-onboarding-tobeconfirmed-status");
            initialStatus.current = "TO_BE_CONFIRMED";
        } else if (initialStatus.current === "TO_BE_CONFIRMED" && tsPayStatus === "LINKED") {
            setDisplayInfoBox(true);
            setDisplayInfoBoxLabelId("tspay-info-activated-status");
            initialStatus.current = "LINKED";
        }
    }, [tsPayStatus, displayInfoBox, setDisplayInfoBox, setDisplayInfoBoxLabelId]);

    let messageIcon = faMinusCircle;
    let iconColor;
    let messageId = "tspay-status-not-linked";
    let showRefresh;
    let showConfirmButton;
    let confirmButtonLabel = "tspay-confirm-onboard";
    let sessionUrl;
    let ctaClick;
    let info = null;
    let tooltipTitleId = "false";
    let tooltipId = "false";
    let alreadyOnboardingLabel = null;

    switch (tsPayStatus) {
        case "LINKED":
            // status connected
            messageIcon = solidFaCheckCircle;
            iconColor = "#09822a";
            messageId = "tspay-status-linked";
            showRefresh = false;
            showConfirmButton = false;
            confirmButtonLabel = "";
            tooltipTitleId = "tspay-tooltip-active-title";
            tooltipId = "tspay-tooltip-active";
            break;

        case "ONBOARDING":
            // status onboarding
            messageIcon = faExclamationCircle;
            iconColor = "#fd8727";
            messageId = "tspay-status-onboarding";
            showRefresh = true;
            showConfirmButton = true;
            confirmButtonLabel = "tspay-confirm-onboarding";
            tooltipTitleId = "tspay-tooltip-onboarding-title";

            if (tsPayOnboardingSessions && tsPayOnboardingSessions.length > 0) {
                // caso in cui onboarding l'ho fatto io
                tooltipId = "tspay-tooltip-onboarding";
                sessionUrl = tsPayOnboardingSessions[0].sessionUrl;
                ctaClick = () => {
                    window.open(sessionUrl, "_blank");
                    setDisableButton(true);
                };
            } else {
                // caso in cui onbarding fatto da altro utente
                tooltipId = "tspay-tooltip-onboarding-other-user";
                confirmButtonLabel = "tspay-confirm-new-onboard";
                info = <FormattedHTMLMessage id="tspay-info-new-onboard" />;
                alreadyOnboardingLabel = (
                    <OtherUserOnboardingLabel>
                        <FormattedMessage id="tspay-other-user-info" />
                    </OtherUserOnboardingLabel>
                );
                ctaClick = () => {
                    tsPayOnboarding(itemId);
                    setDisableButton(true);
                };
            }
            break;

        case "TO_BE_CONFIRMED":
            // status to be confirmed
            messageIcon = faClock;
            iconColor = "#fdb927";
            messageId = "tspay-status-to-be-confirmed";
            showRefresh = true;
            showConfirmButton = false;
            confirmButtonLabel = "";
            tooltipTitleId = "tspay-tooltip-tobeconfirmed-title";
            tooltipId = "tspay-tooltip-tobeconfirmed";

            info = (
                <Hyperlink onClick={() => tspayResendConfirmEmail(itemId)}>
                    {tspayResendConfirmEmailLoading && (
                        <SpinnerContainer>
                            <Spin size="small" />
                        </SpinnerContainer>
                    )}
                    <FormattedMessage id="tspay-resend-confirm" />
                </Hyperlink>
            );
            break;

        case "NOT_LINKED":
            // status not connected
            messageIcon = faMinusCircle;
            iconColor = "#d82829";
            messageId = "tspay-status-not-linked";
            showRefresh = false;
            showConfirmButton = true;
            confirmButtonLabel = "tspay-confirm-onboard";

            ctaClick = () => {
                tsPayOnboarding(itemId);
                setDisableButton(true);
            };
            break;

        default:
            messageId = "tspay-status-not-linked";
            iconColor = "#d82829";
    }

    let refreshDiv = <></>;
    if (showRefresh) {
        refreshDiv = (
            <>
                {alreadyOnboardingLabel !== null && alreadyOnboardingLabel}
                <LoadingButton
                    loading={getConfigLoading}
                    kind="tertiary"
                    onClick={() => {
                        tsPayGetConfig(itemId);
                        setDisableButton(false);
                    }}
                >
                    <MarginIcon icon={faRedo} />
                    <FormattedMessage id="general.update" />
                </LoadingButton>
            </>
        );
    } else {
        if (tooltipId === "tspay-tooltip-onboarding-other-user") {
            refreshDiv = (
                <OtherUserOnboardingLabel>
                    <FormattedMessage id="tspay-other-user-info" />
                </OtherUserOnboardingLabel>
            );
        }
    }

    return (
        <div>
            <Row gutter={32}>
                <Col xs={0} xl={5}>
                    <AsideImage src={`${TS_DIGITAL_IMAGES}tspay-aside.png`} />
                </Col>
                <Col xs={{ span: 22, offset: 1 }} xl={{ span: 16, offset: 1 }}>
                    <Container>
                        <Title>
                            <FontAwesomeIcon icon={faUser} /> <FormattedMessage id="tspay-configure" />
                        </Title>
                        <Description>
                            <FormattedHTMLMessage id="tspay-connect-description" />
                        </Description>
                        <StatusMessageContainer>
                            {tooltipId === "false" ? (
                                <StatusIcon icon={messageIcon} color={iconColor} />
                            ) : (
                                <Tooltip
                                    placement="bottom"
                                    title={
                                        <>
                                            <FormattedMessage id={tooltipTitleId} />
                                            <br />
                                            <br />
                                            <FormattedMessage id={tooltipId} />
                                        </>
                                    }
                                >
                                    <StatusIcon icon={messageIcon} color={iconColor} />
                                </Tooltip>
                            )}

                            <FormattedMessage id={messageId} />
                            {refreshDiv}
                        </StatusMessageContainer>
                        <NotifyStatusContainerDiv showDiv={displayInfoBox === false ? "none" : "flex"}>
                            <InfoIcon icon={solidFaCheckCircle} />
                            <NotifyStatusText>
                                <b>
                                    <FormattedMessage id="tspay-status-title" />
                                </b>
                                <FormattedMessage id={displayInfoBoxLabelId} />
                            </NotifyStatusText>
                            <CloseIcon
                                icon={faTimes}
                                onClick={() => {
                                    setDisplayInfoBox(false);
                                }}
                            />
                        </NotifyStatusContainerDiv>
                        <ServiceContainer>
                            <Service>
                                <ActiveStatusDot active={isServiceActive} />
                                <FormattedMessage id="tspay-service-name" />
                            </Service>
                        </ServiceContainer>
                        <ConfigureButtonContainer>
                            <InfoLabel>{info}</InfoLabel>
                            {showConfirmButton && (
                                <LoadingButtonContainer
                                    buttonWidth={confirmButtonLabel === "tspay-confirm-onboarding" ? "300px" : "auto"}
                                >
                                    <LoadingButton
                                        onClick={ctaClick}
                                        disabled={disableButton}
                                        loading={tspayOnboardingStarted}
                                    >
                                        <FormattedMessage id={confirmButtonLabel} />
                                    </LoadingButton>
                                </LoadingButtonContainer>
                            )}
                        </ConfigureButtonContainer>
                    </Container>
                </Col>
            </Row>
        </div>
    );
};

TspayConfig.propTypes = {
    getConfigLoading: PropTypes.bool,
    isServiceActive: PropTypes.bool,
    itemId: PropTypes.string,
    tsPayOnboarding: PropTypes.func,
    tsPayStatus: PropTypes.string,
    tspayOnboardingStarted: PropTypes.bool,
    tsPayGetConfig: PropTypes.func,
    tsPayOnboardingSessions: PropTypes.array,
    tspayResendConfirmEmail: PropTypes.func,
    tspayResendConfirmEmailLoading: PropTypes.bool
};

export default TspayConfig;
