import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const AsideImage = styled.img`
    max-width: 100%;
    height: auto;
    display: none;

    @media (min-width: 1200px) {
        display: block;
        margin: 96px 0 0;
    }
`;

export const Service = styled.div`
    display: flex;
    align-items: center;
`;

export const Container = styled.div`
    padding: 16px;
`;

export const Title = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 24px;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.23px;
    line-height: 24px;
    margin-bottom: 32px;
`;

export const MarginIcon = styled(FontAwesomeIcon)`
    margin-right: 4px;
`;

export const ServiceContainer = styled.div`
    background-color: ${props => props.theme.colors.background.whiteSmoke};
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 14px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 24px;
    display: grid;
    align-items: center;
    padding: 16px;
    grid-gap: 16px;
`;

export const StatusMessageContainer = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 14px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 24px;
    margin-bottom: 24px;

    display: flex;
    align-items: center;

    & > :last-child {
        margin-left: 24px;
    }
`;

export const StatusIcon = styled(FontAwesomeIcon)`
    color: ${props => props.color};
    font-size: 16px;
    text-align: center;
    width: 40px;
    margin-right: 8px;
`;

export const ActiveStatusDot = styled.div`
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: ${props => (props.active ? props.theme.colors.primary.richElectricBlue : "transparent")};
    border: 1px solid
        ${props => (props.active ? props.theme.colors.primary.richElectricBlue : props.theme.colors.text.cadet)};
    margin-right: 16px;
`;

export const ConfigureButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    & > button {
        min-width: 200px;
    }
`;

export const Description = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 14px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 24px;
    margin-bottom: 24px;
`;

export const InfoLabel = styled.div`
    color: rgb(90, 104, 114);
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    height: 72px;
    letter-spacing: 0.25px;
    line-height: 24px;
    width: 65%;
`;

export const OtherUserOnboardingLabel = styled.div`
    color: rgb(0, 80, 117);
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0.25px;
    line-height: 24px;
    margin-left: 12px;
`;

export const NotifyStatusContainerDiv = styled.div`
    display: ${props => props.showDiv};
    height: 96px;
    border: 1px solid #09822a;
    border-radius: 0px;
    padding: 16px;

    justify-content: space-between;

    box-shadow: 0px 0px 3px 0px #333333;
    margin-bottom: 16px;

    & > span {
        margin-left: 16px;
    }
`;

export const InfoIcon = styled(FontAwesomeIcon)`
    color: #09822a;
    font-size: 24px;
    text-align: center;
    width: 64px;
`;

export const CloseIcon = styled(FontAwesomeIcon)`
    color: black;
    font-size: 16px;
    text-align: center;
    width: 40px;
`;

export const NotifyStatusText = styled.div`
    padding-right: 5px;
    padding-left: 5px;
`;

export const Hyperlink = styled.a`
    color: ${props => props.theme.colors.primary.richElectricBlue};
    font-size: 16px;
    font-family: Roboto;
    font-weight: normal;
    text-decoration: underline;
    letter-spacing: 0.44px;
    line-height: 24px;
    margin-right: 8px;
    margin-left: 8px;
    display: flex;
`;

export const SpinnerContainer = styled.div`
    margin-right: 8px;
`;

export const LoadingButtonContainer = styled.div`
    & > button {
        width: ${props => props.buttonWidth};
    }
`;
