import React from "react";
import PropTypes from "prop-types";
import { ActiveStatusDot } from "../../styled";

export const FiscalCodeCell = ({
    cell: { value },
    row: {
        original: { isActive }
    }
}) => {
    return (
        <>
            <ActiveStatusDot active={isActive} />
            {value}
        </>
    );
};

FiscalCodeCell.propTypes = {
    /** Rdc contract as been validated */
    isActive: PropTypes.bool
};
