import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import RolesTable from "../roles-table";

import {
    OverallPermissionsSectionTitle,
    OverallPermissionsSectionSubtitle,
    OverallPermissionsSectionSelect,
    OverallPermissionsSelectAndButtonContainer,
    CollapseContainer
} from "./styled";
import { Button } from "@ts-digital/vrc";
import Collapse from "../../collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { Tooltip } from "antd";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import LoadingRolesTable from "../loading-roles-table";

const RolesAccordion = ({ formState, isLoading, onUpdate }) => {
    const [updatedValues, setUpdatedValues] = useState({
        overallPermissions: [{ permissions: [] }],
        activePermissions: [],
        inactivePermissions: []
    });
    const [overallRole, setOverallRole] = useState("");
    const { formatMessage } = useIntl();

    useEffect(() => {
        setUpdatedValues(formState);
    }, [formState]);

    useEffect(() => {
        setOverallRole(
            updatedValues.overallPermissions[0].role ? updatedValues.overallPermissions[0].role : "multipleRoles"
        );
    }, [updatedValues.overallPermissions]);

    useEffect(() => {
        onUpdate({
            overallPermissions: updatedValues.overallPermissions || [{ permissions: [] }],
            activePermissions: updatedValues.activePermissions || [],
            inactivePermissions: updatedValues.inactivePermissions || []
        });
    }, [
        onUpdate,
        updatedValues.inactivePermissions,
        updatedValues.activePermissions,
        updatedValues.overallPermissions
    ]);

    const onActivePermissionsUpdate = useCallback(
        val => {
            setUpdatedValues({
                inactivePermissions: updatedValues.inactivePermissions,
                activePermissions: val,
                overallPermissions: [{ ...updatedValues.overallPermissions[0], role: "multipleRoles" }]
            });
        },
        [updatedValues.inactivePermissions, updatedValues.overallPermissions]
    );

    const onInactivePermissionsUpdate = useCallback(
        val => {
            setUpdatedValues({
                inactivePermissions: val,
                activePermissions: updatedValues.activePermissions,
                overallPermissions: [{ ...updatedValues.overallPermissions[0], role: "multipleRoles" }]
            });
        },
        [updatedValues.activePermissions, updatedValues.overallPermissions]
    );

    const onOverallPermissionsUpdate = useCallback(() => {
        let iP = updatedValues.inactivePermissions;
        let aP = updatedValues.activePermissions;

        if (["admin", "employee", "noRole"].includes(overallRole)) {
            iP = iP.map(i => {
                let child = [];
                if (i.children.length > 0) {
                    child = i.children.map(c => {
                        return { ...c, role: overallRole };
                    });
                }

                return { ...i, role: overallRole, toggle: overallRole !== "noRole", children: [...child] };
            });

            aP = aP.map(i => {
                let child = [];
                if (i.children.length > 0) {
                    child = i.children.map(c => {
                        return { ...c, role: overallRole };
                    });
                }

                return { ...i, role: overallRole, toggle: overallRole !== "noRole", children: [...child] };
            });
        }

        setUpdatedValues({
            overallPermissions: [{ ...updatedValues.overallPermissions[0], role: overallRole }],
            inactivePermissions: iP,
            activePermissions: aP
        });
    }, [
        updatedValues.overallPermissions,
        updatedValues.inactivePermissions,
        updatedValues.activePermissions,
        overallRole
    ]);

    return (
        <>
            <div>
                <OverallPermissionsSectionTitle>
                    <FormattedMessage id={"c-user-roles-table.description.title"} />
                </OverallPermissionsSectionTitle>
                <OverallPermissionsSectionSubtitle>
                    <FormattedHTMLMessage id="c-registry-user.choose-roles-on-company" />{" "}
                    <Tooltip
                        placement="bottom"
                        title={<FormattedHTMLMessage id="c-registry-user.choose-roles.tooltip" />}
                    >
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </Tooltip>
                </OverallPermissionsSectionSubtitle>
                <OverallPermissionsSelectAndButtonContainer>
                    <OverallPermissionsSectionSelect
                        options={[
                            {
                                description: formatMessage({
                                    id: `c-registry-user.users-manage-multipleRoles`
                                }),
                                value: "multipleRoles"
                            },
                            ...updatedValues.overallPermissions[0].permissions.map(p => ({
                                description: formatMessage({
                                    id: `c-registry-user.users-manage-${p.toLowerCase()}`
                                }),
                                value: p && p.toLowerCase()
                            })),
                            {
                                description: formatMessage({
                                    id: `c-registry-user.users-manage-noRole`
                                }),
                                value: "noRole"
                            }
                        ]}
                        onChange={val => setOverallRole(val)}
                        value={overallRole}
                    />
                    <Button
                        onClick={() => {
                            onOverallPermissionsUpdate();
                        }}
                        data-cy="overall-apply-button"
                        disabled={overallRole === "multipleRoles" || overallRole === "noRole"}
                    >
                        <FormattedMessage id={"c-user-roles-table.button.apply"} />
                    </Button>
                </OverallPermissionsSelectAndButtonContainer>
            </div>
            <CollapseContainer>
                <Collapse
                    header={
                        <div>
                            <FormattedHTMLMessage id="c-registry-user.active-roles" />{" "}
                        </div>
                    }
                    isOpen={true}
                    key="0"
                >
                    {isLoading ? (
                        <LoadingRolesTable
                            showActivationDateColumn={true}
                            showDescriptionColumn={true}
                            showServiceStatusColumn={true}
                        />
                    ) : (
                        <RolesTable
                            onUpdate={onActivePermissionsUpdate}
                            showActivationDateColumn={true}
                            showDescriptionColumn={true}
                            showServiceStatusColumn={true}
                            values={updatedValues.activePermissions}
                        />
                    )}
                </Collapse>
            </CollapseContainer>
            <CollapseContainer>
                {updatedValues.inactivePermissions.length > 0 && (
                    <Collapse
                        header={
                            <div>
                                <FormattedHTMLMessage id="c-registry-user.add-roles" />{" "}
                                <Tooltip
                                    placement="bottom"
                                    title={<FormattedHTMLMessage id="c-registry-user.add-roles.tooltip" />}
                                >
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                </Tooltip>
                            </div>
                        }
                        key="1"
                    >
                        {isLoading ? (
                            <LoadingRolesTable showActivationDateColumn={false} showServiceStatusColumn={true} />
                        ) : (
                            <RolesTable
                                onUpdate={onInactivePermissionsUpdate}
                                showActivationDateColumn={false}
                                showServiceStatusColumn={true}
                                values={updatedValues.inactivePermissions}
                            />
                        )}
                    </Collapse>
                )}
            </CollapseContainer>
        </>
    );
};

RolesAccordion.propTypes = {
    formState: PropTypes.shape({
        activePermissions: PropTypes.array,
        inactivePermissions: PropTypes.array
    }).isRequired
};

export default RolesAccordion;
