import styled from "styled-components";

export const Container = styled.div`
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: #152935;
    padding: 16px;
    border-bottom: solid 1px #c9d9e8;

    :hover {
        background-color: #f2f5f8;
    }
`;
export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Title = styled.div`
    font-family: Cairo;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.18px;
`;

export const Date = styled.div`
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.4px;
    text-align: right;
    color: #5a6872;
`;

export const StatusIconContainer = styled.div`
    text-align: right;
`;
