import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { useRouteMatch } from "react-router";

import AppCard from "../general/app-card";
import { isCategoryAvailable } from "../../utils/app-availability";
import { Container } from "./styled";
import { serviceColors, serviceIcons } from "../../utils/constant";

const appDetailsLinks = {
    EIP: "/fatturazione/details",
    SIG: "/firma/details",
    TEL: "/adempimenti/details"
};

const appEnabledChecks = {
    TEL: services => services.aFiscaliIva.consoleIva,
    SIG: services => services.signature.signatureConsole
};

const hiddenIfUnavailable = ["PPL", "DBX"];

export const Apps = ({ categories, company, services, userRoles }) => {
    const match = useRouteMatch();
    const availableCategories = useMemo(() => Object.values(categories).filter(c => c.hasApp), [categories]);
    let isCompanyValidated = company.base.status.status === "VALIDATED";

    return (
        <Container>
            {availableCategories.map(category => {
                let isActive =
                    isCompanyValidated &&
                    services.active.services.some(activeService =>
                        category.services.map(s => s.id).includes(activeService)
                    );

                const isHiddenIfUnavailable = hiddenIfUnavailable.includes(category.id);
                return (
                    <AppCard
                        appEnabled={appEnabledChecks[category.id] ? appEnabledChecks[category.id](services) : true}
                        appId={category.id}
                        category={{
                            color: serviceColors[category.id],
                            icon: serviceIcons[category.id]
                        }}
                        detailLink={appDetailsLinks[category.id] && `${match.url}${appDetailsLinks[category.id]}`}
                        display={
                            isCategoryAvailable(
                                company.base.id,
                                userRoles,
                                category,
                                services.active.availableToUser,
                                services.active.services
                            ) &&
                            (!isHiddenIfUnavailable || isActive)
                        }
                        key={category.id}
                        isActive={isActive}
                        isCompanyValidated={isCompanyValidated}
                    />
                );
            })}
        </Container>
    );
};

Apps.propTypes = {
    categories: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    services: PropTypes.object.isRequired,
    userRoles: PropTypes.array.isRequired
};

export default Apps;
