import React from "react";
import PropTypes from "prop-types";

import { Container, Tab } from "./styled";

const ContentSwitcher = ({ onChange, selectedTab, tabs }) => {
    return (
        <Container>
            {tabs.map((tab, key) => (
                <Tab
                    onClick={() => onChange(key)}
                    isFirst={key === 0}
                    isLast={key === tabs.length - 1}
                    selected={key === selectedTab}
                    key={`tab_${key}`}
                >
                    {tab}
                </Tab>
            ))}
        </Container>
    );
};

ContentSwitcher.propTypes = {
    onChange: PropTypes.func,
    selectedTab: PropTypes.number,
    tabs: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.string]))
};

export default ContentSwitcher;
