import { fieldRequired } from "@ts-digital/agyo-validations";

import { translateMessage } from "../../i18n";

export const contractDataSchema = {
    type: "object",
    properties: {
        firstName: {
            type: "string",
            validate: fieldRequired(translateMessage("general.form.field-required"))
        },
        lastName: {
            type: "string",
            validate: fieldRequired(translateMessage("general.form.field-required"))
        },
        role: {
            type: "string",
            validate: fieldRequired(translateMessage("general.form.field-required"))
        },
        documentPlace: {
            type: "string",
            validate: fieldRequired(translateMessage("general.form.field-required"))
        },
        documentDate: {
            type: "string",
            validate: fieldRequired(translateMessage("general.form.field-required"))
        },
        notificationConsent: {
            type: "string",
            validate: value => {
                if (value === undefined) {
                    return translateMessage("general.form.field-required");
                }
            }
        },
        thirdPartyNotificationConsent: {
            type: "string",
            validate: value => {
                if (value === undefined) {
                    return translateMessage("general.form.field-required");
                }
            }
        },
        analyticsConsent: {
            type: "string",
            validate: value => {
                if (value === undefined) {
                    return translateMessage("general.form.field-required");
                }
            }
        }
    }
};
