import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Collapse } from "antd";
import { isNil } from "ramda";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";

import { createConnectionAndItem, createConnectionAndItemReset } from "../../actions/connections/write";
import { fetchItemServices } from "../../actions/services/service-subscription-v2";
import { CompanyInfo } from "../../components/connections-create";
import { ConnectionForm } from "../../components/connections-create";
import { errorBoundary } from "../../components/general/error-boundary";

import CreationLayout from "../../components/connections-create/creation-layout";

import { getConnectableServices } from "../../utils/state-utils/services";

import "./style.css";

const Panel = Collapse.Panel;

const ConnectionsCreateNewCompany = ({
    agyoServices,
    agyoServicesLoading,
    auth,
    createConnectionAndItem,
    createConnectionAndItemReset,
    creationSuccessful,
    fetchItemServices,
    isCreating,
    itemServicesStatus,
    itemServicesLoaded,
    manager,
    match,
    techUsers
}) => {
    const [createdItem, setCreatedItem] = useState({});
    const [displayResult, setDisplayResult] = useState(false);
    const [step, setStep] = useState(0);

    const handleItemFormSubmission = useCallback(
        itemData => {
            setCreatedItem({ ...itemData, managerId: manager.base.id });
            setStep(1);
        },
        [manager]
    );

    const handleConnectionFormSubmission = useCallback(
        connection => {
            createConnectionAndItem({ ...connection, managerId: manager.base.id }, createdItem);
        },
        [createConnectionAndItem, createdItem, manager]
    );

    useEffect(() => {
        if (step === 1 && !isCreating && creationSuccessful) {
            setDisplayResult(true);
        }
    }, [creationSuccessful, isCreating, step]);

    useEffect(() => {
        if (!itemServicesStatus.started && !itemServicesStatus.ended && !itemServicesLoaded) {
            fetchItemServices(true, match.params.item);
        }
    }, [fetchItemServices, itemServicesStatus, itemServicesLoaded, match.params.item]);

    // Cleanup connection creation status on unmount
    useEffect(() => () => createConnectionAndItemReset(), [createConnectionAndItemReset]);

    return (
        <CreationLayout connectedCompany={createdItem.base} creationSuccess={displayResult} loading={false}>
            <Collapse accordion bordered={false} activeKey={[String(step)]}>
                <Panel header={<FormattedMessage id="connections.create.step-1.create.name" />} key="0">
                    <div>
                        <CompanyInfo
                            auth={auth}
                            isGestita
                            onSubmit={handleItemFormSubmission}
                            isLoading={isCreating}
                            selectedCompany={createdItem}
                        />
                    </div>
                </Panel>
                <Panel header={<FormattedMessage id="connections.create.step-4.name" />} key="1">
                    <ConnectionForm
                        disabled={isCreating}
                        loadingServices={agyoServicesLoading}
                        onCancel={() => setStep(0)}
                        onSubmit={handleConnectionFormSubmission}
                        services={agyoServices.filter(
                            s => (manager && manager.base.details.classifier === "STUDIO") || s.appId === "PRIVACY"
                        )}
                        techUsers={techUsers}
                    />
                </Panel>
            </Collapse>
        </CreationLayout>
    );
};

ConnectionsCreateNewCompany.propTypes = {
    agyoServices: PropTypes.array.isRequired,
    agyoServicesLoading: PropTypes.bool,
    auth: PropTypes.object.isRequired,
    createConnectionAndItem: PropTypes.func.isRequired,
    createConnectionAndItemReset: PropTypes.func.isRequired,
    creationSuccessful: PropTypes.bool,
    isCreating: PropTypes.bool.isRequired,
    manager: PropTypes.object.isRequired,
    techUsers: PropTypes.array.isRequired
};

function mapStateToProps(state, props) {
    const agyoServices = getConnectableServices(state, props.match.params.item).filter(
        s => s.active || state.services.active.services.includes(s.id)
    );

    const serviceCategories = agyoServices.reduce((acc, curr) => {
        if (acc.findIndex(s => s.appId === curr.appId) === -1) {
            acc.push({
                appId: curr.appId,
                appDescription: curr.appDescription
            });
        }

        return acc;
    }, []);

    const services = serviceCategories.map(app => ({
        appId: app.appId,
        appDescription: app.appDescription,
        features: agyoServices.filter(s => s.appId === app.appId)
    }));

    const techUsers =
        state.users.users &&
        state.users.users
            .filter(u => u.user.profile.type === "TECNICA" && u.user.status.active)
            .map(u => ({
                authId: u.user.profile.authId,
                id: u.user.profile.id,
                description: u.user.profile.description
            }));

    return {
        agyoServices: services,
        agyoServicesLoading: state.services.agyoServices.status.started,
        auth: state.auth,
        creationSuccessful: state.connectionsNew.write.createConnectionAndItem.status.ended,
        isCreating: state.connectionsNew.write.createConnectionAndItem.status.started,
        itemServicesStatus: state.services.itemServices.status,
        itemServicesLoaded: !isNil(state.services.itemServices[props.match.params.item]),
        manager: state.companies.data[props.match.params.item]
            ? state.companies.data[props.match.params.item].item
            : {},
        techUsers: techUsers || []
    };
}

const actions = {
    createConnectionAndItem,
    createConnectionAndItemReset,
    fetchItemServices
};

const composedHoc = compose(connect(mapStateToProps, actions), errorBoundary);

export default composedHoc(ConnectionsCreateNewCompany);
