import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { updatePreference } from "../../../actions/ncs/write";

import { faCog } from "@fortawesome/pro-regular-svg-icons";
import { Popover, Switch } from "antd";
import { useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";

import { getNcsBasePath } from "../../../utils/route";

import {
    ActionColumn,
    ChannelsContainer,
    Configure,
    ConfigureTrigger,
    Icon,
    Item,
    ItemContent,
    ListColumn,
    Name
} from "./styled";

const channelsWithConfigs = ["EMAIL"];

export const PreferencesItem = ({ appCode, channels, groupId, itemId, name, ncsId, type, updatePreference }) => {
    const { formatMessage } = useIntl();
    const { item, selectedItemId } = useParams();

    const [configs, setConfigs] = useState([]);

    useEffect(() => {
        setConfigs(
            channels
                .filter(({ enabled, locked, type }) => channelsWithConfigs.includes(type) && !locked && enabled)
                .map(({ type }) => type)
        );
    }, [channels]);

    const handleChannelUpdate = (channelType, enabled) => {
        const updatedChannels = channels.map(channel => {
            return channel.type === channelType
                ? {
                      ...channel,
                      enabled
                  }
                : channel;
        });

        updatePreference(
            ncsId,
            {
                appCode,
                templateType: type,
                channels: updatedChannels.filter(channel => channel.enabled).map(channel => channel.type),
                ...(groupId && { groupId })
            },
            itemId
        );
    };

    return (
        <Item>
            <ItemContent>
                <Name>{name}</Name>
                <ChannelsContainer>
                    {channels.map(channel => (
                        <ListColumn key={`switch-${appCode}-${type}-${channel.name}`}>
                            <Switch
                                aria-label={formatMessage(
                                    {
                                        id: "c-notifications-center.toggleChannel"
                                    },
                                    {
                                        channel: channel.name,
                                        template: name
                                    }
                                )}
                                size="small"
                                onChange={() => handleChannelUpdate(channel.type, !channel.enabled)}
                                checked={channel.enabled}
                                disabled={channel.locked}
                            />
                        </ListColumn>
                    ))}
                </ChannelsContainer>
            </ItemContent>
            <ActionColumn>
                <Popover
                    placement={"topRight"}
                    trigger="click"
                    content={
                        <>
                            {configs.map(channelType => (
                                <Configure key={`configure-${channelType}`}>
                                    <Link
                                        to={`${getNcsBasePath(
                                            selectedItemId,
                                            item
                                        )}/channels/${appCode}/${type}/${channelType}`}
                                    >
                                        {formatMessage({
                                            id: `c-notifications-center.configureLink.${channelType}`
                                        })}
                                    </Link>
                                </Configure>
                            ))}
                        </>
                    }
                >
                    <ConfigureTrigger kind={"tertiary"} size={"small"} disabled={configs.length === 0}>
                        <Icon icon={faCog} />
                    </ConfigureTrigger>
                </Popover>
            </ActionColumn>
        </Item>
    );
};

PreferencesItem.propTypes = {
    appCode: PropTypes.string.isRequired,
    channels: PropTypes.array.isRequired,
    groupId: PropTypes.string,
    itemId: PropTypes.string,
    name: PropTypes.string.isRequired,
    ncsId: PropTypes.string,
    type: PropTypes.string.isRequired,
    updatePreference: PropTypes.func
};

const mapStateToProps = state => ({
    ncsId: state.user.user.profile.ncsId
});

export default connect(mapStateToProps, {
    updatePreference
})(PreferencesItem);
