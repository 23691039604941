import React from "react";
import PropTypes from "prop-types";

import { Toggle } from "@ts-digital/vrc";

import {
    RowContainer,
    ServiceRow,
    ServiceRows,
    StyledDivider,
    SubServiceRow,
    SubServiceRowTitle,
    SubServicesHeaders,
    TableHeaders,
    ToggleContainer
} from "../roles-table/styled";
import LoaderItem from "../../general/loader-item";

const LoadingRolesTable = ({ rowCount, showActivationDateColumn, showDescriptionColumn, showServiceStatusColumn }) => {
    const rows = [];
    for (let i = 0; i < rowCount; i++) {
        rows.push(
            <RowContainer key={`placeholder-row-${i}`}>
                <ServiceRows
                    showActivationDateColumn={showActivationDateColumn}
                    showDescriptionColumn={showDescriptionColumn}
                    showServiceStatusColumn={showServiceStatusColumn}
                >
                    <ServiceRow>
                        <ToggleContainer>
                            <Toggle checked={false} />
                            <LoaderItem height="16px" width="50%" />
                        </ToggleContainer>
                    </ServiceRow>
                    <ServiceRow>
                        <LoaderItem height="40px" width="100%" />
                    </ServiceRow>
                    {showDescriptionColumn && (
                        <ServiceRow>
                            <LoaderItem height="16px" width="100%" />
                        </ServiceRow>
                    )}
                    {showActivationDateColumn && <LoaderItem height="16px" width="50%" />}
                    {showServiceStatusColumn && <LoaderItem height="16px" width="30%" />}
                </ServiceRows>
                <SubServicesHeaders
                    showActivationDateColumn={showActivationDateColumn}
                    showDescriptionColumn={showDescriptionColumn}
                    showServiceStatusColumn={showServiceStatusColumn}
                >
                    <SubServiceRowTitle>
                        <LoaderItem height="16px" width="50%" />
                    </SubServiceRowTitle>
                    <SubServiceRow>
                        <LoaderItem height="40px" width="100%" />
                    </SubServiceRow>
                </SubServicesHeaders>
            </RowContainer>
        );
    }

    return (
        <>
            <TableHeaders
                showActivationDateColumn={showActivationDateColumn}
                showServiceStatusColumn={showServiceStatusColumn}
            >
                <LoaderItem height="16px" width="100%" />
                <LoaderItem height="16px" width="100%" />
                {showDescriptionColumn && <LoaderItem height="16px" width="100%" />}
                {showActivationDateColumn && <LoaderItem height="16px" width="100%" />}
                {showServiceStatusColumn && <LoaderItem height="16px" width="100%" />}
            </TableHeaders>
            <StyledDivider />
            {rows}
        </>
    );
};

LoadingRolesTable.propTypes = {
    /** Number of placeholder rows to render, defaults to 3 */
    rowCount: PropTypes.number,
    showActivationDateColumn: PropTypes.bool,
    showDescriptionColumn: PropTypes.bool,
    showServiceStatusColumn: PropTypes.bool
};

LoadingRolesTable.defaultProps = {
    rowCount: 3
};

export default LoadingRolesTable;
