import React, { useCallback } from "react";
import PropTypes from "prop-types";
import CctSpecActivateToggle from "../../cct-spec-activate-toggle";

import { FormattedMessage } from "react-intl";

import { Container, Content, Title, Description, TitleWrapper, ContentWrapper } from "./styled";

export const ConfigureItemServices = ({
    cctConfig,
    itemApps,
    onServiceToggled,
    provisioningInfo,
    saveConfigLoading,
    cctConfigManaged
}) => {
    const handleCredentialsServiceToggle = useCallback(
        (active, name, id) => {
            onServiceToggled(active, name, id);
        },
        [onServiceToggled]
    );

    const oldCctFlowApps = Object.values(itemApps.data).filter(a => a.cctSpecsConfig.oldFlow);
    return (
        <Container>
            <Content>
                <TitleWrapper>
                    <Title>
                        <FormattedMessage id="c-cct-item-services-section-title" />
                    </Title>
                </TitleWrapper>
                <TitleWrapper>
                    <Description>
                        <FormattedMessage id="c-cct-item-services-section-description" />
                    </Description>
                </TitleWrapper>
                <ContentWrapper>
                    {(!provisioningInfo || !provisioningInfo.status) &&
                        oldCctFlowApps
                            .filter(a => a.cctSpecsConfig.newFlow)
                            .map(app => (
                                <CctSpecActivateToggle
                                    key={app.id}
                                    disabled={!app.services.some(s => s.activeForItem)}
                                    id={`apps.${app.id}.title`}
                                    specs={cctConfig.specs}
                                    name={app.cctSpecsConfig.specs}
                                    onChange={handleCredentialsServiceToggle}
                                    saveConfigLoading={saveConfigLoading}
                                    cctConfigManaged={cctConfigManaged}
                                />
                            ))}
                    {oldCctFlowApps
                        .filter(a => !a.cctSpecsConfig.newFlow)
                        .map(app => (
                            <CctSpecActivateToggle
                                key={app.id}
                                disabled={!app.services.some(s => s.activeForItem)}
                                id={`apps.${app.id}.title`}
                                specs={cctConfig.specs}
                                name={app.cctSpecsConfig.specs}
                                onChange={handleCredentialsServiceToggle}
                                saveConfigLoading={saveConfigLoading}
                                cctConfigManaged={cctConfigManaged}
                            />
                        ))}
                </ContentWrapper>
            </Content>
        </Container>
    );
};

ConfigureItemServices.propTypes = {
    cctConfig: PropTypes.shape({
        specs: PropTypes.object
    }),
    itemApps: PropTypes.object,
    onServiceToggled: PropTypes.func.isRequired,
    provisioningInfo: PropTypes.object.isRequired
};
