import { EXPIRING_PASSWORD_WARN } from "../actions/auth";
import {
    DISMISS_NOTIFICATION,
    FORGOT_PASSWORD_SUCCESS,
    UPDATED_VERSION,
    COPY_AUTH_TOKEN_SUCCESS,
    SERVICE_ACTIVE_SUCCESS,
    SERVICE_DISABLE_SUCCESS,
    ENABLE_CCT_CONFIG_MANAGED_SUCCESS,
    DISABLE_CCT_CONFIG_MANAGED_SUCCESS,
    CCT_CREDENTIALS_EXPIRATION_NOTIFICATION,
    TSPAY_CONNECTION_REQUEST_SUCCESS,
    CCT_SMART_ERROR,
    CCT_SMART_INITIALIZED
} from "../actions/toastNotifications";
import {
    DELETE_NCS_SINGLE_CHANNEL_SUCCESS,
    DELETE_NCS_SINGLE_CHANNEL_ERROR,
    UPDATE_NCS_SINGLE_CHANNEL_SUCCESS,
    UPDATE_NCS_SINGLE_CHANNEL_ERROR
} from "../actions/ncs/write";
import { HIDE_OVERFLOW, SHOW_OVERFLOW } from "../actions/overflowNotification";
import { TS_PAY_INFO } from "../actions/services/tspay";

import uuid from "uuid/v4";
import { EDIT_ROLES_SUCCESS, EDIT_ROLES_ERROR } from "../actions/company/users";

export function toast(state = [], { type, payload = {} }) {
    switch (type) {
        case EXPIRING_PASSWORD_WARN:
        case FORGOT_PASSWORD_SUCCESS:
        case UPDATED_VERSION:
        case COPY_AUTH_TOKEN_SUCCESS:
        case SERVICE_ACTIVE_SUCCESS:
        case ENABLE_CCT_CONFIG_MANAGED_SUCCESS:
        case DISABLE_CCT_CONFIG_MANAGED_SUCCESS:
        case SERVICE_DISABLE_SUCCESS:
        case CCT_CREDENTIALS_EXPIRATION_NOTIFICATION:
        case TSPAY_CONNECTION_REQUEST_SUCCESS:
        case CCT_SMART_ERROR:
        case CCT_SMART_INITIALIZED:
        case TS_PAY_INFO:
        case EDIT_ROLES_SUCCESS:
        case EDIT_ROLES_ERROR:
            return [{ id: uuid(), type, ...payload }, ...state];
        case DELETE_NCS_SINGLE_CHANNEL_SUCCESS:
        case DELETE_NCS_SINGLE_CHANNEL_ERROR:
        case UPDATE_NCS_SINGLE_CHANNEL_SUCCESS:
        case UPDATE_NCS_SINGLE_CHANNEL_ERROR:
            return [{ id: uuid(), type }, ...state];
        case DISMISS_NOTIFICATION:
            return state.filter(i => i.id !== payload);
        default:
            return state;
    }
}

const defaultState = {
    show: false
};

export function overflow(state = defaultState, { type, payload }) {
    switch (type) {
        case SHOW_OVERFLOW:
            return {
                show: true,
                ...payload
            };
        case HIDE_OVERFLOW:
            return defaultState;
        default:
            return state;
    }
}
