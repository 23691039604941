import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { ChildApp } from "@mondora/microfrontends";

import { COMMON_LOGIN_API_URL } from "../../../config";
import { translateClassifierToSpotlight } from "../../../utils/translate-classifier-to-spotlight";

const ChildAppComponent = ({
    serviceId,
    url,
    childOrigin,
    disableDataPropagation,
    onLaunched,
    suppliedMethods,
    launchData,
    launchingPlaceholder,
    className,
    item,
    managerId,
    deepLink = "/",
    deepLinkParameters = {},
    oneFrontAppId,
    tenantId
}) => {
    const [isLaunching, setIsLaunching] = useState(!disableDataPropagation);
    const [isLoadingUrl, setIsLoadingUrl] = useState(serviceId && !disableDataPropagation ? true : false);
    const [origin, setOrigin] = useState(childOrigin);
    const iframeRef = useRef();

    useEffect(() => {
        if (serviceId && !disableDataPropagation) {
            const fn = async () => {
                const res = await fetch(`${COMMON_LOGIN_API_URL}/registry/services/${serviceId}`);
                const json = await res.json();
                if (res.status >= 400) {
                    return;
                }

                setOrigin(json.item.value);
                setIsLoadingUrl(false);
            };

            fn();
        }
    }, [disableDataPropagation, serviceId]);

    useEffect(() => {
        if (!disableDataPropagation) {
            const conn = new ChildApp({
                iframe: iframeRef.current,
                onLaunched,
                suppliedMethods,
                launchData,
                childOrigin: serviceId ? origin : undefined
            });

            conn.launch().then(setIsLaunching(false));

            return () => setIsLaunching(true);
        }
    }, [disableDataPropagation, origin, isLoadingUrl, launchData, onLaunched, suppliedMethods, serviceId]);

    const itemId = item.base.uuid;
    if (oneFrontAppId) {
        const tenantData = {
            id: tenantId,
            itemId: itemId,
            type: translateClassifierToSpotlight(item.base.details.classifier),
            description: item.base.details.description,
            identifiers: [
                { type: "TAX_ID", country: "IT", value: item.base.identifier.taxId },
                { type: "VAT_NUMBER", country: "IT", value: item.base.identifier.vatNumber }
            ],
            delegable: item.base.details.classifier === "STUDIO",
            countryCode: item.base.identifier.taxRegion
        };
        const ctxObject = {
            tenant: tenantData,
            // This should actually be the selected customer's workspace information
            // However, due to how portale works and the fact that none of the currently integrated OneFront
            // applications are connectable, tenant and workspace will always refer to the same item/workspace
            workspace: item.base.details.classifier === "STUDIO" ? tenantData : null,
            product: {
                id: oneFrontAppId
            }
        };
        deepLinkParameters["ctx"] = btoa(JSON.stringify(ctxObject));
    }

    const state = { ownerId: managerId || itemId, itemId: itemId };
    var deepLinkParameterArray = Object.entries(deepLinkParameters).map(
        ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    );
    deepLinkParameterArray.push("embed=true");

    const queryString = [
        `serviceId=${serviceId}`,
        `state=${btoa(JSON.stringify(state))}`,
        `deepLink=${deepLink}?${encodeURIComponent(deepLinkParameterArray.join("&"))}`
    ].join("&");

    return (
        <>
            {isLaunching || isLoadingUrl ? launchingPlaceholder : null}
            <iframe
                title="child-app"
                className={className}
                src={serviceId ? `${COMMON_LOGIN_API_URL}/jump?${queryString}` : url}
                ref={iframeRef}
            />
        </>
    );
};

ChildApp.propTypes = {
    url: PropTypes.string.isRequired,
    childOrigin: PropTypes.string,
    // Used to disable microfrontends communication
    disableDataPropagation: PropTypes.bool,
    onLaunched: PropTypes.func,
    suppliedMethods: PropTypes.objectOf(PropTypes.func),
    launchData: PropTypes.object,
    launchingPlaceholder: PropTypes.node,
    className: PropTypes.string,
    serviceId: PropTypes.string,
    // The entire Item object
    item: PropTypes.string,
    // Id (V3) of the manager for this service. Used to set ownerId in initialData.
    // If not provided, itemId is used instead
    managerId: PropTypes.string,
    deepLinkParameters: PropTypes.object,
    // Data needed for oneFront applications
    oneFrontAppId: PropTypes.string,
    tenantId: PropTypes.string
};

export default ChildAppComponent;
