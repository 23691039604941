import React, { Component } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { Button, Row, Col, Icon } from "antd";

import "./style.css";

class SuccessPage extends Component {
    static propTypes = {
        onBackButtonClick: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        buttonText: PropTypes.string.isRequired
    };

    render() {
        const { onBackButtonClick, title, subtitle, buttonText } = this.props;

        return (
            <div className="c-user-success">
                <div className="content">
                    <Row justify="center" type="flex">
                        <Icon type="check-circle" className="check-icon" />
                    </Row>
                    <Row justify="center" type="flex">
                        <Col>
                            <h1>
                                <FormattedMessage id={title} />
                            </h1>
                        </Col>
                    </Row>
                    <Row justify="center" type="flex">
                        <Col>
                            <FormattedMessage id={subtitle} />
                        </Col>
                    </Row>
                    <Row justify="center" type="flex">
                        <Button
                            onClick={() => {
                                onBackButtonClick();
                            }}
                            type="primary"
                            className="back-button"
                        >
                            <FormattedMessage id={buttonText} />
                        </Button>
                    </Row>
                </div>
            </div>
        );
    }
}

export default SuccessPage;
