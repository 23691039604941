import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCity, faUser, faBuilding, faBriefcase } from "@fortawesome/pro-light-svg-icons";

import { UserIcon, Container, CompanyWrapper, CompanyName, Info, Status, Uuid } from "./styled";
import { mergeStatus } from "../../../../utils/state-utils/get-merged-status.js";
import { canModifyData, hasAdminOwnerUser } from "../../../../utils/get-roles";
import SectionTitle from "../section-title";

const usersIcon = {
    STUDIO: faBriefcase,
    COMPANY: faBuilding,
    PERSON: faUser,
    BUILDING: faCity
};

export const CompanyInfo = ({ company, user, users }) => {
    const status = mergeStatus(company.base.status);

    const [editable, setEditable] = useState();

    useEffect(() => {
        if (canModifyData(user.user, company.base.id)) {
            setEditable(true);
        } else {
            !hasAdminOwnerUser(company.base.id, users) ? setEditable(true) : setEditable(false);
        }
    }, [user, company.base.id, users]);

    return (
        <Container>
            <SectionTitle
                editable={editable}
                linkTo={`/${company.base.id}/edit`}
                title={
                    <FormattedMessage
                        id={`c-registry-user.info.${
                            company.base.details.classifier === "PERSON" ? "person" : "company"
                        }`}
                    />
                }
            ></SectionTitle>
            <CompanyWrapper>
                <UserIcon>
                    <FontAwesomeIcon icon={usersIcon[company.base.details.classifier]} />
                </UserIcon>
                <div>
                    {company.base.details.classifier === "PERSON" ? (
                        <CompanyName>{`${company.base.details.firstName} ${company.base.details.lastName}`}</CompanyName>
                    ) : (
                        <CompanyName>{company.base.details.description}</CompanyName>
                    )}
                    <Status>
                        <FormattedHTMLMessage id="general.stato" />
                        {": "}
                        <FormattedHTMLMessage id={`general.status.${status}`} />
                    </Status>
                    <Info>
                        <FormattedHTMLMessage id="general.CF" />
                        {`: ${company.base.identifier.taxId}`}
                    </Info>
                    {company.base.details.classifier !== "PERSON" ? (
                        <Info>
                            <FormattedHTMLMessage id="general.PIVA" />
                            {`: ${company.base.identifier.vatNumber}`}
                        </Info>
                    ) : null}
                    <Uuid>ID: {company.base.uuid}</Uuid>
                </div>
            </CompanyWrapper>
        </Container>
    );
};

CompanyInfo.propTypes = {
    company: PropTypes.object.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    showNotification: PropTypes.bool
};

export default CompanyInfo;
