import React from "react";
import PropTypes from "prop-types";

import { Skeleton } from "antd";

import { PlaceholderCellContent } from "./styled";
import { Cell } from "../styled";

export const PlaceholderRows = ({ columnCount, pageSize }) => {
    const rows = [];

    for (let i = 0; i < pageSize; i++) {
        rows.push(
            <tr key={`placeholder-row-${i}`}>
                <Cell colSpan={columnCount}>
                    <PlaceholderCellContent>
                        <Skeleton active={true} paragraph={{ rows: 1, width: "100%" }} title={false} />
                    </PlaceholderCellContent>
                </Cell>
            </tr>
        );
    }

    return <>{rows}</>;
};

PlaceholderRows.propTypes = {
    /** Column width of the table, used to set the placeholder cell's width */
    columnCount: PropTypes.number,
    /** Amount of placeholder rows to be displayed */
    pageSize: PropTypes.number
};
