import React, { useCallback, useContext, useMemo, useState } from "react";

import { connect } from "react-redux";
import chunk from "lodash.chunk";
import { useEffect } from "react";
import { getCompanies } from "../../../actions/companies";
import { fetchAssignableRoles, fetchRolesSummary, fetchRolesSummaryReset } from "../../../actions/roles";
import { UsersManagegementContext } from "../../../user-context";
import { editRoles, resetEditRoles } from "../../../actions/company/users";
import { mapFormToRoles } from "../../../utils/roles-mapper";
import UserSummary from "../../../components/user-summary-old"; // Old Component

const UserSummaryView = ({
    assignableRoles,
    editRoles,
    editUserStatus,
    fetchAssignableRoles,
    fetchAssignableRolesStatus,
    fetchRolesSummary,
    fetchRolesSummaryReset,
    getCompanies,
    history,
    match,
    listItems,
    loadedItems,
    loading,
    onRemoveUser,
    resetEditRoles,
    rolesSummary,
    selectedUserEnhancedRoles,
    selectedUserRoles
}) => {
    const { selectedUserId } = match.params;

    const { setCurrentItem, setSelectedUser, setUserCreationFlowFirstStep } = useContext(UsersManagegementContext);

    const rolesByItemAndAppId = useMemo(() => {
        return selectedUserRoles.reduce((acc, curr) => {
            if (!acc[curr.resourceId]) {
                acc[curr.resourceId] = {};
            }

            if (!acc[curr.resourceId][curr.appId]) {
                acc[curr.resourceId][curr.appId] = [];
            }

            acc[curr.resourceId][curr.appId].push(curr);
            return acc;
        }, {});
    }, [selectedUserRoles]);
    const itemPages = useMemo(
        () =>
            chunk(
                Object.keys(rolesByItemAndAppId).filter(i => i !== "REGISTERED"),
                5
            ),
        [rolesByItemAndAppId]
    );

    const [filters, setFilters] = useState({});
    const [page, setPage] = useState(0);
    const [roleEditModalOpen, setRoleEditModalOpen] = useState(false);

    const handleEditModalConfirm = useCallback(
        (newRoles, itemId) => {
            const itemRoles = assignableRoles[itemId] || [];

            editRoles(
                selectedUserId,
                itemId,
                mapFormToRoles(
                    {
                        overallPermissions: [],
                        inactivePermissions: [],
                        activePermissions: newRoles
                    },
                    itemId,
                    itemRoles
                )
            );
        },
        [assignableRoles, editRoles, selectedUserId]
    );

    useEffect(() => {
        if (roleEditModalOpen && editUserStatus.ended) {
            setRoleEditModalOpen(false);
            resetEditRoles();
        }
    }, [editUserStatus.ended, resetEditRoles, roleEditModalOpen]);

    useEffect(() => {
        const pageData = itemPages[page];
        const missingItems = (pageData && pageData.filter(i => !loadedItems[i])) || [];

        if (missingItems.length > 0) {
            getCompanies(undefined, 0, missingItems.length, false, missingItems);
        }
    }, [getCompanies, listItems, loadedItems, page, itemPages]);

    useEffect(() => {
        const pageNeedsLoading = rolesSummary.userId !== selectedUserId || !rolesSummary.summary.pages[page];
        if (pageNeedsLoading && !rolesSummary.status.started) {
            fetchRolesSummary(selectedUserId, page, 10, filters.description);
        }
    }, [fetchRolesSummary, filters, page, rolesSummary, selectedUserId]);

    const currentPage = useMemo(() => rolesSummary.summary.pages[page] || [], [page, rolesSummary]);
    const preloadAssignableRoles = useCallback(
        itemId => {
            if (!assignableRoles[itemId]) {
                fetchAssignableRoles(itemId);
            }
        },
        [assignableRoles, fetchAssignableRoles]
    );

    const handleAddToAnotherItem = useCallback(() => {
        setCurrentItem("");
        setSelectedUser(selectedUserId);
        setUserCreationFlowFirstStep("selectCompanies");
    }, [selectedUserId, setCurrentItem, setSelectedUser, setUserCreationFlowFirstStep]);

    const handleEditUser = useCallback(
        item => {
            setCurrentItem(item);
            history.replace(`/${match.params.item}/registry/user/${selectedUserId}/edit`);
        },
        [history, match.params.item, selectedUserId, setCurrentItem]
    );

    const handleTableFiltersChange = useCallback(
        filters => {
            fetchRolesSummaryReset();
            setFilters(filters);
            setPage(0);
        },
        [setFilters, fetchRolesSummaryReset]
    );

    return (
        <UserSummary
            assignableRoles={assignableRoles}
            currentPage={page}
            currentPageData={currentPage}
            loadingAssignableRoles={fetchAssignableRolesStatus.started}
            loadingTableItems={loading}
            onAddToAnotherItem={handleAddToAnotherItem}
            onDeleteUserFromItem={onRemoveUser}
            onEditUser={handleEditUser}
            onPageChange={setPage}
            onRoleEditConfirm={handleEditModalConfirm}
            onTableFiltersChange={handleTableFiltersChange}
            preloadAssignableRoles={preloadAssignableRoles}
            selectedUserEnhancedRoles={selectedUserEnhancedRoles}
            submittingRoleEdit={editUserStatus.started}
            totalItems={rolesSummary.summary.totalItems}
            totalPages={Math.ceil(rolesSummary.summary.totalItems / 10)}
        />
    );
};

const mapStateToProps = state => ({
    assignableRoles: state.manageUser.roles.assignableRoles,
    editUserStatus: state.manageUser.editUserV3.status,
    fetchAssignableRolesStatus: state.manageUser.roles.fetchAssignableRolesStatus,
    loadedItems: state.companies.data,
    loading: state.manageUser.roles.summary.status.started || state.manageUser.removeRolesFromUser.status.started,
    rolesSummary: state.manageUser.roles.summary,
    selectedUserEnhancedRoles: (state.getUserV3.user && state.getUserV3.user.enhancedRoles) || [],
    selectedUserRoles: (state.getUserV3.user && state.getUserV3.user.roles) || []
});

const actions = {
    editRoles,
    fetchAssignableRoles,
    fetchRolesSummary,
    fetchRolesSummaryReset,
    getCompanies,
    resetEditRoles
};

export default connect(mapStateToProps, actions)(UserSummaryView);
