import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { includes } from "ramda";
import { FormattedHTMLMessage, useIntl } from "react-intl";

import ServiceCard from "../../components/general/service-card";
import { ServiceCardsContainer } from "../general/service-card/styled";

const category = {
    title: "PeopleApp"
};

const PeopleApp = ({ services: { active, agyoServices } }) => {
    const { formatMessage } = useIntl();

    const isActiveInAgyo = useMemo(
        () => agyoServices.services.some(service => service.id === "PEOPLEAPP" && service.active),
        [agyoServices.services]
    );

    return active.services ? (
        <ServiceCardsContainer expanded={false}>
            <ServiceCard
                disable={!isActiveInAgyo}
                ribbonActive={!isActiveInAgyo}
                ribbon={{ text: "In arrivo", color: "grey" }}
                packageInfo={{
                    active: includes("PEOPLEAPP", active.services),
                    title: formatMessage({
                        id: "general.buy-package"
                    }),
                    text: formatMessage({
                        id: "general.contact-to-buy"
                    })
                }}
                button={{ active: false }}
                display={true}
                expanded={false}
                title={<FormattedHTMLMessage id="c-services-view-cards-container-people-app.title" />}
                description={<FormattedHTMLMessage id="c-services-view-cards-container-people-app.description" />}
                category={category}
            />
        </ServiceCardsContainer>
    ) : null;
};

PeopleApp.propTypes = {
    company: PropTypes.object.isRequired,
    services: PropTypes.object.isRequired
};

export default PeopleApp;
