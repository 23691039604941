import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { compose } from "redux";

import { uploadCertReset } from "../../actions/certification";
import SuccessPage from "../../components/general/accordion-stepper/success-page";
import { errorBoundary } from "../../components/general/error-boundary";

// Esportata anche questa classe per poterla usare nel test senza connect
export class ValidateCompanySuccess extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        uploadCert: PropTypes.object.isRequired,
        uploadCertReset: PropTypes.func.isRequired
    };

    render() {
        const { history, match, uploadCert, uploadCertReset } = this.props;

        const ended = uploadCert.status.started === false && uploadCert.status.ended;

        return ended ? (
            <div>
                <SuccessPage
                    onBackButtonClick={() => {
                        uploadCertReset();
                        history.goBack();
                    }}
                    title={"c-success.message.title.company.validate.success"}
                    subtitle={"c-success.message.subtitle.company.validate.success"}
                    buttonText={"c-success.message.button.company.validate.success"}
                />
            </div>
        ) : (
            <Redirect to={`/${match.params.item}/dashboard`} />
        );
    }
}

const mapStateToProps = state => ({
    uploadCert: state.certification.uploadCert
});

const actions = { uploadCertReset };

const composedHoc = compose(connect(mapStateToProps, actions), errorBoundary);

export default composedHoc(ValidateCompanySuccess);
