import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useIntl, FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { Layout } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

import TsIdInformation from "./tsid-information";
import UserDataForm from "./user-data-form";
import ChangePasswordForm from "./change-password-form";
import PageTitle from "../../../general/page-title";
import { FormContainer, FormTitle } from "./styled";

const FormEditUserData = ({
    auth,
    editUser,
    editUserReset,
    user,
    updateUserPswStatus,
    updateUserPsw,
    resetUpdateUserPassword,
    backFun,
    userEditStatus,
    displaySuccessToast,
    displayErrorToast
}) => {
    const isLinkedWithTsId = user && user.profile.tsid !== null && user.profile.tsid !== undefined;

    const { formatMessage } = useIntl();

    const handleUserUpdate = data => {
        const updatedDetail = {
            ...user.profile,
            ...data
        };

        const updatedUser = {
            email: user.profile.id,
            ...updatedDetail
        };

        editUser(user.profile.id, updatedUser);
    };

    const handlePasswordUpdate = data => {
        updateUserPsw(auth, data.oldPassword, data.password);
    };

    useEffect(() => {
        if (userEditStatus.ended) {
            if (!userEditStatus.error) {
                displaySuccessToast(formatMessage({ id: "c-manage-data.edit-details.name-confirm" }));
            }
        } else if (userEditStatus.error) {
            displayErrorToast(formatMessage({ id: "c-manage-data.edit-details.name-error" }));
        }

        if (userEditStatus.ended || userEditStatus.error) {
            return () => editUserReset();
        }
    }, [
        editUserReset,
        displaySuccessToast,
        displayErrorToast,
        formatMessage,
        userEditStatus.ended,
        userEditStatus.error
    ]);

    useEffect(() => {
        if (updateUserPswStatus.ended) {
            if (!updateUserPswStatus.error) {
                displaySuccessToast(formatMessage({ id: "c-manage-data.edit-details.password-confirm" }));
            }
        } else if (updateUserPswStatus.error) {
            displayErrorToast(
                formatMessage(
                    { id: "c-manage-data.edit-details.password-error" },
                    { errMsg: updateUserPswStatus.errorInfo ? updateUserPswStatus.errorInfo.message : "" }
                )
            );
        }

        if (updateUserPswStatus.ended || updateUserPswStatus.error) {
            return () => resetUpdateUserPassword();
        }
    }, [
        resetUpdateUserPassword,
        displaySuccessToast,
        displayErrorToast,
        formatMessage,
        updateUserPswStatus.ended,
        updateUserPswStatus.error,
        updateUserPswStatus.errorInfo
    ]);

    return (
        <Layout className="Root">
            <Layout.Content className="Root-content">
                <PageTitle
                    title={<FormattedHTMLMessage id="c-manage-data.edit-details.title" />}
                    subtitle={<FormattedHTMLMessage id="c-manage-data.edit-details.subtitle" />}
                    onBack={() => {
                        backFun();
                    }}
                />

                <FormContainer>
                    {isLinkedWithTsId && (
                        <FormTitle size="l">
                            <FormattedMessage id="c-manage-data.edit-details.tsid-title" />
                            &nbsp;
                            <FontAwesomeIcon icon={faCheckCircle} color="#09822A" size="sm" />
                        </FormTitle>
                    )}

                    {!isLinkedWithTsId && (
                        <FormTitle size="l">
                            <FormattedMessage id="c-manage-data.edit-details.main-title" />
                        </FormTitle>
                    )}

                    <TsIdInformation auth={auth} user={user} />

                    {!isLinkedWithTsId && (
                        <div>
                            <UserDataForm
                                initialValues={user ? { ...user.profile } : {}}
                                disabled={userEditStatus.started || isLinkedWithTsId}
                                loading={userEditStatus.started}
                                onSubmit={data => handleUserUpdate(data)}
                            />
                            <ChangePasswordForm
                                disabled={updateUserPswStatus.started || isLinkedWithTsId}
                                loading={updateUserPswStatus.started}
                                onSubmit={data => handlePasswordUpdate(data)}
                            />
                        </div>
                    )}
                </FormContainer>
            </Layout.Content>
        </Layout>
    );
};

FormEditUserData.propTypes = {
    auth: PropTypes.object,
    editUser: PropTypes.func,
    loadUser: PropTypes.func,
    updateUserPsw: PropTypes.func,
    updateUserPswStatus: PropTypes.object,
    user: PropTypes.object,
    displaySuccessToast: PropTypes.func,
    displayErrorToast: PropTypes.func
};

export default FormEditUserData;
