import styled from "styled-components";

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const Message = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 16px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 24px;
    margin-bottom: 24px;
`;

export const Label = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 16px;
`;
