import styled from "styled-components";

export const ErrorMessageContainer = styled.div`
    color: ${props => props.theme.colors.status.maximumRed};
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
`;

export const SubtitleContainer = styled.div`
    color: rgb(21, 41, 53);
    font-size: 16px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 24px;
`;

export const SelectDocumentLabelContainer = styled.div`
    color: rgb(21, 41, 53);
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 16px;
    margin-top: 32px;
`;

export const DocumentSizeInfoMessage = styled.div`
    color: rgb(157, 166, 173);
    font-size: 12px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 12px;
    margin: 8px;
`;

export const ModalBodyContainer = styled.div`
    height: 342px;
`;
