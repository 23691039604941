import React from "react";
import PropTypes from "prop-types";

import { Modal, Button } from "antd";
import { FormattedHTMLMessage, useIntl } from "react-intl";

import "./style.css";

const ModalConnectionWarning = ({ handleClose, handleContinue, visible }) => {
    const { formatMessage } = useIntl();

    return (
        <div className="c-connections-warning">
            <Modal
                title={formatMessage({
                    id: "c-connections-warning.title"
                })}
                visible={visible}
                onCancel={handleClose}
                footer={[
                    <Button key="0" type="ghost" onClick={handleClose}>
                        <FormattedHTMLMessage id="general.annulla" />
                    </Button>,
                    <Button key="1" type="primary" onClick={handleContinue}>
                        <FormattedHTMLMessage id="general.continue" />
                    </Button>
                ]}
            >
                <div className="c-connections-warning-text">
                    <p>
                        <FormattedHTMLMessage id="c-connections-warning.text" />
                    </p>
                </div>
            </Modal>
        </div>
    );
};

ModalConnectionWarning.propTypes = {
    handleClose: PropTypes.func,
    handleContinue: PropTypes.func,
    visible: PropTypes.bool
};

export default ModalConnectionWarning;
