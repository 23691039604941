import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "antd";

export const StyledModal = styled(Modal)`
    max-width: ${props => props.maxWidth};

    .ant-modal-content {
        border-radius: 0px;
    }

    .ant-modal-header {
        padding: 16px 32px;
        border-bottom: 2px solid
            ${props =>
                props.type === "danger"
                    ? props.theme.colors.status.maximumRed
                    : props.theme.colors.primary.richElectricBlue};
    }

    .ant-modal-title {
        color: ${props => props.theme.colors.primary.darkImperialBlue};
        font-size: 24px;
        font-family: Cairo;
        font-weight: 600;
        line-height: 24px;
    }

    .ant-modal-close-x {
        font-size: 24px;
    }

    .ant-modal-body {
        font-size: 16px;
        font-family: Roboto;
        line-height: 24px;
        padding: 24px 32px;
        padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : "60px")};
    }

    .ant-modal-footer {
        padding: 8px;
        background-color: ${props => props.theme.colors.background.whiteSmoke};
        border: none;
    }
`;

export const Icon = styled(FontAwesomeIcon)`
    font-size: 24px;
    color: ${props => props.theme.colors.primary.richElectricBlue};
`;
