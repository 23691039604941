import React, { useCallback, useEffect, useMemo, useState } from "react";

import { isEmpty } from "ramda";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router";
import { getFormValues } from "redux-form";

import { listUsers } from "../../../../actions/company/users";
import { loadCompany } from "../../../../actions/company";
import {
    trackOldProvisioning,
    trackOldProvisioningEdit,
    trackSmartProvisioning,
    trackSmartProvisioningEdit,
    trackSwitchToSmartProvisioning
} from "../../../../actions/gainsightEvents";
import {
    fetchCctProvisioningInfo,
    fetchCctProvisioningInfoManaged,
    generateCctProvisioningContract,
    initializeManagedProvisioning,
    initializeSmartProvisioning,
    resetGenerateCctProvisioningContract,
    resetGenerateCctManagedProvisioningContract,
    resetInitializeSmartProvisioning,
    extendSmartProvisioning,
    resetExtendSmartProvisioning
} from "../../../../actions/services/cct/provisioning";
import { smartCctError, smartCctInitialized } from "../../../../actions/toastNotifications";
import PageTitle from "../../../../components/general/page-title";
import DownloadPdfForm from "../../../../components/storage/cct/provisioning/download-pdf-form";
import { useFeature } from "../../../../hooks/use-feature";
import { hasAdminOwnerUser, canModifyData } from "../../../../utils/get-roles";
import { PageContainer } from "./styled";
import { Spinner } from "../../../../components/general/spinner";

export const GenerateCctDocument = ({
    cctData,
    companyType,
    companyDescription,
    companyLoading,
    companyUuid,
    fetchCctProvisioningInfo,
    fetchCctProvisioningInfoManaged,
    generateCctProvisioningContract,
    initializeManagedProvisioning,
    initializeSmartProvisioning,
    listUsers,
    loadCompany,
    managedItemUsers,
    provisioningInfo,
    rdcPdfFormState,
    resetGenerateCctProvisioningContract,
    resetGenerateCctManagedProvisioningContract,
    resetInitializeSmartProvisioning,
    extendSmartProvisioning,
    resetExtendSmartProvisioning,
    smartCctError,
    smartCctInitialized,
    trackOldProvisioning,
    trackOldProvisioningEdit,
    trackSmartProvisioning,
    trackSmartProvisioningEdit,
    trackSwitchToSmartProvisioning,
    user,
    isCctActive
}) => {
    const history = useHistory();
    const { item, managedItem } = useParams();
    const [formProvisioningInfo, setFormProvisioningInfo] = useState({});

    const itemProvisioningInfo = provisioningInfo.provisioningInfo[item] || {};
    const managedProvisioningInfo = provisioningInfo.provisioningInfo[managedItem] || {};

    useEffect(() => {
        if (!companyType && !companyLoading) {
            loadCompany(managedItem);
        }
    }, [companyType, companyLoading, loadCompany, managedItem]);

    useEffect(() => {
        if (isEmpty(itemProvisioningInfo) && !provisioningInfo.isLoading && isCctActive) {
            fetchCctProvisioningInfo(item);
        }
    });

    useEffect(() => {
        if (
            (cctData.managedProvisioning.ended && !cctData.managedProvisioning.error) ||
            (cctData.generateContract.status.ended && !cctData.generateContract.status.error)
        ) {
            cctData.managedProvisioning.ended
                ? resetGenerateCctManagedProvisioningContract()
                : resetGenerateCctProvisioningContract();
            history.goBack();
        }
    }, [
        cctData.managedProvisioning.ended,
        cctData.managedProvisioning.error,
        cctData.generateContract.status.ended,
        cctData.generateContract.status.error,
        history,
        resetGenerateCctManagedProvisioningContract,
        resetGenerateCctProvisioningContract
    ]);

    useEffect(() => {
        if (
            (cctData.initSmart.status.ended && !cctData.initSmart.status.error) ||
            (cctData.extendSmart.status.ended && !cctData.extendSmart.status.error)
        ) {
            cctData.initSmart.status.ended ? resetInitializeSmartProvisioning() : resetExtendSmartProvisioning();
            smartCctInitialized();
            history.goBack();
        }
    }, [
        cctData.initSmart.status.ended,
        cctData.initSmart.status.error,
        cctData.extendSmart.status.ended,
        cctData.extendSmart.status.error,
        history,
        resetInitializeSmartProvisioning,
        smartCctInitialized,
        resetExtendSmartProvisioning
    ]);

    useEffect(() => {
        if (
            (cctData.initSmart.status.error && !cctData.initSmart.status.ended) ||
            (cctData.extendSmart.status.error && !cctData.extendSmart.status.ended)
        ) {
            cctData.initSmart.status.error ? resetInitializeSmartProvisioning() : resetExtendSmartProvisioning();
            smartCctError();
        }
    }, [
        cctData.initSmart.status.ended,
        cctData.initSmart.status.error,
        cctData.extendSmart.status.error,
        cctData.extendSmart.status.ended,
        resetInitializeSmartProvisioning,
        smartCctError,
        resetExtendSmartProvisioning
    ]);

    useEffect(() => {
        if (
            isEmpty(managedProvisioningInfo) &&
            !provisioningInfo.managedInfoPagination.isLoading &&
            item !== managedItem
        ) {
            fetchCctProvisioningInfoManaged(item, "EIP", 0, 1, { managedIds: [managedItem] });
        } else if (!isEmpty(managedProvisioningInfo)) {
            setFormProvisioningInfo(managedProvisioningInfo);
        }
    }, [
        fetchCctProvisioningInfoManaged,
        item,
        managedItem,
        managedProvisioningInfo,
        provisioningInfo.managedInfoPagination
    ]);

    useEffect(() => {
        if (companyUuid) {
            listUsers(true, true, companyUuid);
        }
    }, [companyUuid, listUsers]);

    const isCompany = companyType === "COMPANY";

    const smartCheckBoxSelected =
        isCompany && managedProvisioningInfo && managedProvisioningInfo.contractType === "PROVISIONING_SMART"
            ? false
            : (rdcPdfFormState && rdcPdfFormState.smart) || false;

    // If the user already had saved holder data, he is editing
    const isEditing = managedProvisioningInfo.holder && Object.keys(managedProvisioningInfo.holder).length > 0;
    const isAlreadySmart = managedProvisioningInfo.contractType !== "PROVISIONING_SMART";

    const cctSmartFlag = useFeature("CCT_SMART");

    let smartIsEnabled = false;

    if (isCompany) {
        const isCoupon1000 = managedProvisioningInfo.couponId === "1000";
        const studioHasSmart = itemProvisioningInfo.contractType === "PROVISIONING_SMART";
        const couponIsInitialized = managedProvisioningInfo.status === "initialized";
        const couponNeedsToBeFixed =
            managedProvisioningInfo.status === "validated" && managedProvisioningInfo.rdcToBeFixed;

        // le aziende (gestite o non) vedono smart solo se il coupon è inizializzato o se è validato ma rdcToBeFixed
        if ((isCoupon1000 || studioHasSmart) && (couponIsInitialized || couponNeedsToBeFixed)) {
            smartIsEnabled = true;
        }
    } else {
        // studio vede sempre smart rdc
        smartIsEnabled = true;
    }

    const formInitialValues = useMemo(() => {
        const itemInfo = formProvisioningInfo;
        const holder = formProvisioningInfo.holder || {};

        let smartInitial = false;
        if (smartIsEnabled) {
            smartInitial =
                formProvisioningInfo.smart !== undefined
                    ? formProvisioningInfo.smart
                    : itemInfo.contractType === "PROVISIONING_SMART";
        }

        return {
            balanceSheetDate: itemInfo.balanceSheetDate,
            birthPlace: holder.birthPlace,
            country: holder.country,
            email: holder.email,
            gender: holder.gender,
            isPec: holder.isPec || false,
            name: holder.name,
            surname: holder.surname,
            birthDate: holder.birthDate,
            taxId: holder.taxId,
            smart: smartInitial
        };
    }, [formProvisioningInfo, smartIsEnabled]);

    const trackProvisioning = useCallback(
        isSmartProvisioning => {
            if (isSmartProvisioning) {
                if (!isEditing) {
                    trackSmartProvisioning(item, managedItem);
                } else if (isAlreadySmart) {
                    trackSwitchToSmartProvisioning(item, managedItem);
                } else {
                    trackSmartProvisioningEdit(item, managedItem);
                }
            } else {
                if (!isEditing) {
                    trackOldProvisioning(item, managedItem);
                } else {
                    trackOldProvisioningEdit(item, managedItem);
                }
            }
        },
        [
            isAlreadySmart,
            isEditing,
            item,
            managedItem,
            trackOldProvisioning,
            trackOldProvisioningEdit,
            trackSmartProvisioning,
            trackSmartProvisioningEdit,
            trackSwitchToSmartProvisioning
        ]
    );

    if (provisioningInfo.provisioningInfo[managedItem] !== undefined && !managedProvisioningInfo.id) {
        return <Redirect to={`/${item}/services/storage/edit-cct`} />;
    }

    return (
        <Spinner loading={companyLoading || provisioningInfo.managedInfoPagination.isLoading}>
            <div>
                <PageTitle
                    title={<FormattedMessage id="cct-generate-document.title" />}
                    subtitle={<FormattedMessage id="cct-generate-document.subtitle" />}
                    onBack={() => history.goBack()}
                />
                <PageContainer>
                    <DownloadPdfForm
                        initialValues={formInitialValues}
                        onSubmit={values => {
                            // Gainsight tracking
                            trackProvisioning(values.smart);

                            if (item !== managedItem) {
                                //caso gestite
                                if (values.smart) {
                                    // flusso smart
                                    extendSmartProvisioning(item, [managedItem], rdcPdfFormState.balanceSheetDate);
                                } else {
                                    // flusso normale
                                    initializeManagedProvisioning(item, managedItem, values);
                                }
                            } else {
                                //caso studio
                                if (values.smart) {
                                    // flusso smart
                                    initializeSmartProvisioning(item, values, values.balanceSheetDate);
                                } else {
                                    // flusso normale
                                    generateCctProvisioningContract(item, values);
                                }
                            }
                        }}
                        displayAutoCompleteToggle={item !== managedItem}
                        isAdmin={canModifyData(user, managedItem) || !hasAdminOwnerUser(managedItem, managedItemUsers)}
                        isLoading={
                            cctData.managedProvisioning.loading ||
                            cctData.generateContract.status.started ||
                            cctData.initSmart.status.started ||
                            cctData.extendSmart.status.started
                        }
                        itemTaxId={managedItem}
                        itemDescription={
                            (managedProvisioningInfo && managedProvisioningInfo.description) || companyDescription
                        }
                        onAutoCompleteToggleChange={(toggled, smartSelected) => {
                            if (toggled) {
                                setFormProvisioningInfo({
                                    ...itemProvisioningInfo,
                                    balanceSheetDate: rdcPdfFormState.balanceSheetDate,
                                    smart: smartSelected
                                });
                            } else {
                                setFormProvisioningInfo({
                                    balanceSheetDate: rdcPdfFormState.balanceSheetDate,
                                    smart: smartSelected
                                });
                            }
                        }}
                        smartSelected={smartCheckBoxSelected}
                        smartIsEnabled={cctSmartFlag && smartIsEnabled}
                        stateBalanceSheetDate={rdcPdfFormState && rdcPdfFormState.balanceSheetDate}
                    />
                </PageContainer>
            </div>
        </Spinner>
    );
};

const mapStateToProps = (state, props) => {
    const selectedCompany = state.companies.data[props.match.params.managedItem];

    return {
        companyType: selectedCompany && selectedCompany.item.base && selectedCompany.item.base.details.classifier,
        companyDescription:
            selectedCompany && selectedCompany.item.base && selectedCompany.item.base.details.description,
        companyLoading: state.company.status.started,
        companyUuid: selectedCompany && selectedCompany.item.base && selectedCompany.item.base.uuid,
        managedItemUsers: state.users,
        cctData: state.services.cct,
        provisioningInfo: state.services.cct.provisioningInfo,
        rdcPdfFormState: getFormValues("download-cct-pdf-form")(state),
        user: state.user.user,
        isCctActive:
            state.services.active.services.includes("CCT-FLOW") && !state.services.active.services.includes("ARC")
    };
};

export default connect(mapStateToProps, {
    fetchCctProvisioningInfo,
    fetchCctProvisioningInfoManaged,
    generateCctProvisioningContract,
    initializeManagedProvisioning,
    initializeSmartProvisioning,
    listUsers,
    loadCompany,
    resetGenerateCctProvisioningContract,
    resetGenerateCctManagedProvisioningContract,
    resetInitializeSmartProvisioning,
    extendSmartProvisioning,
    resetExtendSmartProvisioning,
    smartCctError,
    smartCctInitialized,
    trackOldProvisioning,
    trackOldProvisioningEdit,
    trackSmartProvisioning,
    trackSmartProvisioningEdit,
    trackSwitchToSmartProvisioning
})(GenerateCctDocument);
