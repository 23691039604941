import React from "react";
import PropTypes from "prop-types";

import { Button } from "@ts-digital/vrc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faChevronDoubleUp, faLevelUpAlt } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "antd";

import {
    Container,
    Content,
    Avatar,
    Title,
    Message,
    Note,
    PriorityIconContainer,
    Date,
    ReadFlag,
    NoteLabel,
    RemoveButtonContainer
} from "./styled";

const priorityIcon = priority => {
    switch (priority) {
        case "MEDIUM":
            return faLevelUpAlt;
        case "HIGH":
        default:
            return faChevronDoubleUp;
    }
};

const SingleNotification = ({
    title,
    message,
    date,
    note,
    priority = "LOW",
    read,
    deleteSingleNotification,
    updateSingleNotification,
    id
}) => {
    let today = moment.utc();
    const formattedDate = today.diff(date, "days") > 2 ? moment(date).format("D MMM YYYY") : moment(date).fromNow();

    const readTooltipId = read ? "mark-as-unread" : "mark-as-read";

    return (
        <Container priority={priority} read={read}>
            <Content>
                <Avatar>TSD</Avatar>
                <div>
                    <Title>{title}</Title>
                    <Message>{message}</Message>
                </div>
                <div>
                    <RemoveButtonContainer>
                        <Tooltip placement="left" title={<FormattedMessage id="remove" />}>
                            <Button kind="tertiary" size="small" onClick={() => deleteSingleNotification(id)}>
                                <FontAwesomeIcon icon={faTimes} />
                            </Button>
                        </Tooltip>
                    </RemoveButtonContainer>
                </div>
                <PriorityIconContainer>
                    {priority !== "LOW" && <FontAwesomeIcon icon={priorityIcon(priority)} />}
                </PriorityIconContainer>
                <Date>{formattedDate}</Date>
                <Tooltip placement="left" title={<FormattedMessage id={readTooltipId} />}>
                    <Button
                        kind="tertiary"
                        size="small"
                        onClick={() => {
                            updateSingleNotification(id, { read: !read });
                        }}
                    >
                        <ReadFlag read={read} />
                    </Button>
                </Tooltip>
                {note && (
                    <>
                        <NoteLabel>
                            <FormattedMessage id="general.note" />
                        </NoteLabel>
                        <Note>{note}</Note>
                    </>
                )}
            </Content>
        </Container>
    );
};

SingleNotification.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    date: PropTypes.string,
    note: PropTypes.string,
    priority: PropTypes.oneOf(["LOW", "MEDIUM", "HIGH"]),
    read: PropTypes.bool,
    deleteSingleNotification: PropTypes.func,
    updateSingleNotification: PropTypes.func
};

export default SingleNotification;
