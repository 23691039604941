import { API_URL } from "../../config";
import { deleteRestCall } from "../../utils/rest-api-call";
import { getRestCall } from "../../utils/rest-api-call";
import { postRestCall } from "../../utils/rest-api-call";
import { getAuthWithAppName } from "../auth";
import { getUserProfile } from "../../utils/state-utils/get-user-profile";

export const DELETE_LOGO_START = "DELETE_LOGO_START";
export const DELETE_LOGO_ERROR = "DELETE_LOGO_ERROR";
export const DELETE_LOGO_SUCCESS = "DELETE_LOGO_SUCCESS";
export const DELETE_LOGO_RESET = "DELETE_LOGO_RESET";

export const GET_LOGO_START = "GET_LOGO_START";
export const GET_LOGO_ERROR = "GET_LOGO_ERROR";
export const GET_LOGO_SUCCESS = "GET_LOGO_SUCCESS";
export const GET_LOGO_RESET = "GET_LOGO_RESET";

export const UPLOAD_LOGO_START = "UPLOAD_LOGO_START";
export const UPLOAD_LOGO_ERROR = "UPLOAD_LOGO_ERROR";
export const UPLOAD_LOGO_SUCCESS = "UPLOAD_LOGO_SUCCESS";
export const UPLOAD_LOGO_RESET = "UPLOAD_LOGO_RESET";

export const deleteLogo = itemId => {
    return async (dispatch, getState) => {
        dispatch({
            type: DELETE_LOGO_START
        });

        const auth = getAuthWithAppName(getState().auth);

        try {
            await deleteRestCall(
                `${API_URL}/items/${itemId}/logo`,
                auth,
                null,
                {},
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                itemId
            );

            dispatch({
                type: DELETE_LOGO_SUCCESS,
                payload: { itemId }
            });
        } catch (e) {
            dispatch({
                type: DELETE_LOGO_ERROR,
                error: e
            });
        }
    };
};

export const getLogo = itemId => {
    return async (dispatch, getState) => {
        dispatch({
            type: GET_LOGO_START
        });

        const auth = getAuthWithAppName(getState().auth);

        try {
            const { logoUrl } = await getRestCall(
                `${API_URL}/items/${itemId}/logo`,
                auth,
                null,
                null,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                itemId
            );

            dispatch({
                type: GET_LOGO_SUCCESS,
                payload: { itemId, logoUrl }
            });
        } catch (e) {
            dispatch({
                type: GET_LOGO_ERROR,
                error: e
            });
        }
    };
};

export const uploadLogo = (itemId, base64Logo) => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPLOAD_LOGO_START
        });

        const auth = getAuthWithAppName(getState().auth);

        try {
            await postRestCall(
                `${API_URL}/items/${itemId}/logo`,
                auth,
                null,
                { base64Logo },
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                itemId
            );

            dispatch({
                type: UPLOAD_LOGO_SUCCESS,
                payload: { itemId, logoUrl: base64Logo }
            });
        } catch (e) {
            dispatch({
                type: UPLOAD_LOGO_ERROR,
                error: e
            });
        }
    };
};

export const resetDeleteLogo = () => {
    return dispatch => dispatch({ type: DELETE_LOGO_RESET });
};

export const resetGetLogo = () => {
    return dispatch => dispatch({ type: GET_LOGO_RESET });
};

export const resetUploadLogo = () => {
    return dispatch => dispatch({ type: UPLOAD_LOGO_RESET });
};
