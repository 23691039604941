import { EDIT_ROLES_SUCCESS } from "../../actions/company/users";
import {
    GET_WORKSPACE_PERMISSIONS_ERROR,
    GET_WORKSPACE_PERMISSIONS_START,
    GET_WORKSPACE_PERMISSIONS_SUCCESS
} from "../../actions/services/workspace-permissions";

const defaultState = {
    status: { started: false, ended: false, error: false, errorInfo: {} },
    data: {}
};

export const workspacePermissions = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_WORKSPACE_PERMISSIONS_START:
            return {
                ...defaultState,
                status: {
                    ...defaultState.status,
                    started: true
                }
            };
        case GET_WORKSPACE_PERMISSIONS_ERROR:
            return {
                ...defaultState,
                status: {
                    ...defaultState.status,
                    error: true,
                    errorInfo: error
                }
            };
        case GET_WORKSPACE_PERMISSIONS_SUCCESS:
            return {
                status: {
                    ...defaultState.status,
                    ended: true
                },
                data: payload
            };
        case EDIT_ROLES_SUCCESS:
            if (
                payload.itemId === payload.selectedItem &&
                payload.localWorkspaces &&
                payload.localWorkspaces.length === 1
            ) {
                return {
                    ...defaultState,
                    data: {
                        enabled: payload.localWorkspaces[0].enabled
                    }
                };
            }
            return { ...defaultState };
        default:
            return state;
    }
};
