import styled from "styled-components";

import { Icon } from "antd";

export const AdditionalDataTitle = styled.div`
    background-color: ${props => (props.open ? "#ffffff" : "#f2f5f8")};
    color: #005075;
    font-size: 16px;
    grid-column-end: end;
    grid-column-start: 1;
    padding: 10px 0;
    border-bottom: 1px solid #c9d9e8;
    margin-top: 15px;

    &:hover {
        cursor: pointer;
    }
`;

export const IconContainer = styled(Icon)`
    margin: 0 12px;
`;

export const AdditionalDataElement = styled.div`
    display: ${props => (props.open ? "block" : "none")};
`;

export const LeftColumn = styled(AdditionalDataElement)`
    grid-column-start: 1;
    grid-column-end: 3;
    margin: 0 20px 5px 40px;
`;

export const CenterColumn = styled(AdditionalDataElement)`
    grid-column-start: 3;
    grid-column-end: 4;
    margin: 0 10px 5px 0;
`;

export const RightColumn = styled(AdditionalDataElement)`
    grid-column-start: 4;
    grid-column-end: 6;
    margin: 0 0 5px 10px;
`;

export const CustomColumn = styled(AdditionalDataElement)`
    grid-column-start: ${props => props.gridColumnStart};
    grid-column-end: ${props => props.gridColumnEnd};
`;
