import React from "react";
import PropTypes from "prop-types";
import ReactSVG from "react-svg";

import { TS_DIGITAL_IMAGES } from "../../../config";

const Logo = ({ className, height, white, withTs }) => (
    <ReactSVG
        beforeInjection={svg => {
            svg.style = `height: ${height}px; width: auto;`;
        }}
        src={`${TS_DIGITAL_IMAGES}logo-${withTs ? "with-ts-" : ""}${white ? "white" : "color"}.svg`}
    />
);

Logo.propTypes = {
    height: PropTypes.number
};

export default Logo;
