import groupBy from "lodash/groupBy";

import { getAuthWithAppName } from "../../auth.js";
import { API_URL, ENABLE_WEB_NOTIFICATIONS, NCS_ARRAY_CHANNELS } from "../../../config";
import { getRestCall, headRestCall } from "../../../utils/rest-api-call";
import { getUserProfile } from "../../../utils/state-utils/get-user-profile";

export const GET_NOTIFICATION_STATUS_ERROR = "GET_NOTIFICATION_STATUS_ERROR";
export const GET_NOTIFICATION_STATUS_SUCCESS = "GET_NOTIFICATION_STATUS_SUCCESS";

export function getNotificationsStatus(ncsId) {
    return async (dispatch, getState) => {
        if (ENABLE_WEB_NOTIFICATIONS) {
            const requestUrl = `${API_URL}/notifications/${ncsId}/web`;

            try {
                const authObj = getAuthWithAppName(getState().auth);
                await headRestCall(
                    requestUrl,
                    authObj,
                    { read: false },
                    dispatch,
                    authObj.refreshToken,
                    getUserProfile(getState()),
                    undefined,
                    false,
                    false
                );

                dispatch({
                    type: GET_NOTIFICATION_STATUS_SUCCESS,
                    payload: {
                        exist: true
                    }
                });
            } catch (error) {
                if ([404].includes(error.status)) {
                    dispatch({
                        type: GET_NOTIFICATION_STATUS_SUCCESS,
                        payload: {
                            exist: false
                        }
                    });
                } else {
                    dispatch({
                        type: GET_NOTIFICATION_STATUS_ERROR,
                        error
                    });
                }
            }
        }
    };
}

export const GET_NOTIFICATION_LIST_START = "GET_NOTIFICATION_LIST_START";
export const GET_NOTIFICATION_LIST_ERROR = "GET_NOTIFICATION_LIST_ERROR";
export const GET_NOTIFICATION_LIST_SUCCESS = "GET_NOTIFICATION_LIST_SUCCESS";

export function getNotificationsList(ncsId) {
    return async (dispatch, getState) => {
        if (ENABLE_WEB_NOTIFICATIONS) {
            dispatch({
                type: GET_NOTIFICATION_LIST_START,
                payload: { ncsId }
            });

            const requestUrl = `${API_URL}/notifications/${ncsId}/web`;
            const authObj = getAuthWithAppName(getState().auth);

            try {
                const response = await getRestCall(
                    requestUrl,
                    authObj,
                    null,
                    dispatch,
                    authObj.refreshToken,
                    getUserProfile(getState()),
                    undefined,
                    false
                );

                dispatch({
                    type: GET_NOTIFICATION_LIST_SUCCESS,
                    payload: {
                        ...response
                    }
                });
            } catch (e) {
                dispatch({
                    type: GET_NOTIFICATION_LIST_ERROR,
                    error: e,
                    payload: { ncsId }
                });
            }
        }
    };
}

export const GET_NCS_PREFERENCES_LIST_START = "GET_NCS_PREFERENCES_LIST_START";
export const GET_NCS_PREFERENCES_LIST_ERROR = "GET_NCS_PREFERENCES_LIST_ERROR";
export const GET_NCS_PREFERENCES_LIST_SUCCESS = "GET_NCS_PREFERENCES_LIST_SUCCESS";

export function getPreferencesList(ncsId, itemId = undefined, groupId = undefined) {
    return async (dispatch, getState) => {
        if (ENABLE_WEB_NOTIFICATIONS) {
            dispatch({
                type: GET_NCS_PREFERENCES_LIST_START
            });

            const queryString = Object.entries({ groupId })
                .filter(([_, value]) => value !== null && value !== undefined)
                .map(([name, value]) => `${name}=${value}`)
                .join("&");

            const requestUrl = `${API_URL}/notifications/${ncsId}/preferences?${queryString}`;
            const authObj = getAuthWithAppName(getState().auth);

            try {
                const result = await getRestCall(
                    requestUrl,
                    authObj,
                    null,
                    dispatch,
                    authObj.refreshToken,
                    getUserProfile(getState()),
                    itemId,
                    false
                );

                const applications = groupBy(result, "appCode");

                const payload = Object.keys(applications).reduce((acc, key) => {
                    const application = {
                        appCode: key,
                        preferences: applications[key].map(
                            ({ channels, availableChannels, lockedChannels, ...preference }) => {
                                const mappedChannels = NCS_ARRAY_CHANNELS.filter(channel => channel.visible).map(
                                    ({ type, ...channel }) => ({
                                        ...channel,
                                        type,
                                        enabled: channels.includes(type) && availableChannels.includes(type),
                                        locked: lockedChannels.includes(type) || !availableChannels.includes(type)
                                    })
                                );

                                return {
                                    ...preference,
                                    channels: mappedChannels
                                };
                            }
                        )
                    };

                    acc.push(application);
                    return acc;
                }, []);

                dispatch({
                    type: GET_NCS_PREFERENCES_LIST_SUCCESS,
                    payload
                });
            } catch (e) {
                dispatch({
                    type: GET_NCS_PREFERENCES_LIST_ERROR,
                    error: e,
                    payload: { ncsId }
                });
            }
        }
    };
}

export const GET_NCS_CHANNELS_LIST_START = "GET_NCS_CHANNELS_LIST_START";
export const GET_NCS_CHANNELS_LIST_ERROR = "GET_NCS_CHANNELS_LIST_ERROR";
export const GET_NCS_CHANNELS_LIST_SUCCESS = "GET_NCS_CHANNELS_LIST_SUCCESS";

export function getChannelsList(ncsId, groupId = undefined, itemId = undefined) {
    return async (dispatch, getState) => {
        if (ENABLE_WEB_NOTIFICATIONS) {
            dispatch({
                type: GET_NCS_CHANNELS_LIST_START
            });

            const queryString = Object.entries({ groupId })
                .filter(([_, value]) => value !== null && value !== undefined)
                .map(([name, value]) => `${name}=${value}`)
                .join("&");

            const requestUrl = `${API_URL}/notifications/${ncsId}/channels?${queryString}`;
            const authObj = getAuthWithAppName(getState().auth);

            try {
                const response = await getRestCall(
                    requestUrl,
                    authObj,
                    null,
                    dispatch,
                    authObj.refreshToken,
                    getUserProfile(getState()),
                    itemId,
                    false
                );

                dispatch({
                    type: GET_NCS_CHANNELS_LIST_SUCCESS,
                    payload: response
                });
            } catch (e) {
                dispatch({
                    type: GET_NCS_CHANNELS_LIST_ERROR,
                    error: e,
                    payload: { ncsId }
                });
            }
        }
    };
}
