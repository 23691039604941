import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../styled";

export const ContractGenerateCell = ({
    cell: { value },
    onClick,
    row: {
        original: { status, toBeFixed }
    }
}) => {
    let label = "";
    let disabled = false;
    let gainsightTrack = "";

    switch (status) {
        case "initialized":
            label = <FormattedMessage id="general.generate" />;
            disabled = false;
            gainsightTrack = "archive-generate-cta";
            break;
        case "downloaded":
        case "rejected":
            label = <FormattedMessage id="general.edit" />;
            disabled = false;
            gainsightTrack = "archive-edit-cta";
            break;
        case "uploaded":
        case "pending":
        case "correction":
            label = <FormattedMessage id="general.edit" />;
            disabled = true;
            gainsightTrack = "archive-edit-cta";
            break;
        case "validated":
            if (toBeFixed) {
                label = <FormattedMessage id="general.rettifica" />;
                disabled = false;
                gainsightTrack = "archive-edit-cta";
            } else {
                label = <FormattedMessage id="general.edit" />;
                disabled = true;
                gainsightTrack = "archive-edit-cta";
            }
            break;
        default:
            label = "";
            disabled = false;
            break;
    }

    return (
        <CustomButton
            kind="tertiary"
            disabled={disabled}
            onClick={() => {
                onClick(value);
            }}
            data-gainsight-track={gainsightTrack}
        >
            {label}
        </CustomButton>
    );
};

ContractGenerateCell.propTypes = {
    /** Current cell data */
    cell: PropTypes.shape({ value: PropTypes.any }).isRequired,
    /** Button click callback */
    onClick: PropTypes.func.isRequired,
    /** toBeFixed rdc state */
    toBeFixed: PropTypes.bool
};
