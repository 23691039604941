import React, { useState } from "react";
import PropTypes from "prop-types";

import { useIntl, FormattedMessage } from "react-intl";

import SearchBar from "../../../general/search-bar";
import { Item, ItemsContainer, Container } from "./styled";

const ItemList = ({ activeItem, onItemSelected, selectedItems, currentCompanyData }) => {
    const [itemFilter, setItemFilter] = useState("");

    const intl = useIntl();

    return (
        <Container>
            <SearchBar
                placeholder={intl.formatMessage({
                    id: "v-user-creation-flow.steps.set-permissions.sidebar.search-item-placeholder"
                })}
                onSearch={setItemFilter}
                data-cy="item-searchbar"
            />
            <ItemsContainer data-cy="items-container">
                <Item
                    selected={activeItem.id === currentCompanyData.itemId}
                    onClick={() =>
                        onItemSelected({
                            id: currentCompanyData.itemId,
                            uuid: currentCompanyData.itemUuid,
                            description: currentCompanyData.description,
                            workspaceId: currentCompanyData.workspaceId
                        })
                    }
                >
                    <FormattedMessage id="v-user-creation-flow.steps.set-permissions.selected-modal.table-my-studio" />
                </Item>
                {selectedItems.length > 1 ? (
                    <Item
                        key={`item-list-all`}
                        selected={activeItem.id === "all"}
                        onClick={() =>
                            onItemSelected({
                                id: "all",
                                uuid: "all",
                                workspaceId: "all",
                                description: intl.formatMessage({
                                    id: "v-user-creation-flow.steps.set-permissions.selected-modal.table-companies-total-label"
                                })
                            })
                        }
                        data-cy={activeItem.id === "all" ? "selected-item" : ""}
                        data-gainsight-track={"step3-select-item-all"}
                    >
                        <FormattedMessage
                            id="v-user-creation-flow.steps.set-permissions.selected-modal.table-companies-total-label-total"
                            values={{ count: selectedItems.length - 1 }}
                        />
                    </Item>
                ) : null}

                {selectedItems
                    .filter(item => {
                        if (item.itemId === currentCompanyData.itemId) return false;
                        if (itemFilter) {
                            return (
                                item.description.toLowerCase().includes(itemFilter.toLowerCase()) ||
                                item.itemId.toLowerCase().includes(itemFilter.toLowerCase()) ||
                                item.itemId === activeItem.id
                            );
                        }
                        return true;
                    })
                    .map(item => (
                        <Item
                            key={`item-list-${item.itemId}`}
                            selected={activeItem.id === item.itemId}
                            onClick={() =>
                                onItemSelected({
                                    id: item.itemId,
                                    uuid: item.itemUuid,
                                    description: item.description,
                                    workspaceId: item.workspaceId
                                })
                            }
                            data-cy={activeItem.id === item.itemId ? "selected-item" : ""}
                            data-gainsight-track={"step3-select-single-item"}
                        >
                            {item.description}
                        </Item>
                    ))}
            </ItemsContainer>
        </Container>
    );
};

ItemList.propTypes = {
    activeItem: PropTypes.object.isRequired,
    onItemSelected: PropTypes.func.isRequired,
    selectedItems: PropTypes.arrayOf(
        PropTypes.shape({
            classifier: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            itemId: PropTypes.string.isRequired,
            itemUuid: PropTypes.string.isRequired,
            taxId: PropTypes.string.isRequired,
            vatNumber: PropTypes.string.isRequired
        })
    ).isRequired
};

export default ItemList;
