/* eslint-disable */
//
// Autogenerated by Thrift Compiler (0.11.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
"use strict";

var thrift = require('browser-thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = module.exports = {};
var Auth = module.exports.Auth = function(args) {
    this.id = null;
    this.securityToken = null;
    this.appName = null;
    this.requestId = null;
    this.correlationId = null;
    if (args) {
        if (args.id !== undefined && args.id !== null) {
            this.id = args.id;
        }
        if (args.securityToken !== undefined && args.securityToken !== null) {
            this.securityToken = args.securityToken;
        }
        if (args.appName !== undefined && args.appName !== null) {
            this.appName = args.appName;
        }
        if (args.requestId !== undefined && args.requestId !== null) {
            this.requestId = args.requestId;
        }
        if (args.correlationId !== undefined && args.correlationId !== null) {
            this.correlationId = args.correlationId;
        }
    }
};
Auth.prototype = {};
Auth.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 1:
                if (ftype == Thrift.Type.STRING) {
                    this.id = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.STRING) {
                    this.securityToken = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 3:
                if (ftype == Thrift.Type.STRING) {
                    this.appName = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 4:
                if (ftype == Thrift.Type.STRING) {
                    this.requestId = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 5:
                if (ftype == Thrift.Type.STRING) {
                    this.correlationId = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

Auth.prototype.write = function(output) {
    output.writeStructBegin('Auth');
    if (this.id !== null && this.id !== undefined) {
        output.writeFieldBegin('id', Thrift.Type.STRING, 1);
        output.writeString(this.id);
        output.writeFieldEnd();
    }
    if (this.securityToken !== null && this.securityToken !== undefined) {
        output.writeFieldBegin('securityToken', Thrift.Type.STRING, 2);
        output.writeString(this.securityToken);
        output.writeFieldEnd();
    }
    if (this.appName !== null && this.appName !== undefined) {
        output.writeFieldBegin('appName', Thrift.Type.STRING, 3);
        output.writeString(this.appName);
        output.writeFieldEnd();
    }
    if (this.requestId !== null && this.requestId !== undefined) {
        output.writeFieldBegin('requestId', Thrift.Type.STRING, 4);
        output.writeString(this.requestId);
        output.writeFieldEnd();
    }
    if (this.correlationId !== null && this.correlationId !== undefined) {
        output.writeFieldBegin('correlationId', Thrift.Type.STRING, 5);
        output.writeString(this.correlationId);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var Status = module.exports.Status = function(args) {
    this.createdAt = null;
    this.createdBy = null;
    this.modifiedAt = null;
    this.modifiedBy = null;
    this.active = null;
    this.expired = null;
    this.finished = null;
    this.usable = null;
    if (args) {
        if (args.createdAt !== undefined && args.createdAt !== null) {
            this.createdAt = args.createdAt;
        }
        if (args.createdBy !== undefined && args.createdBy !== null) {
            this.createdBy = args.createdBy;
        }
        if (args.modifiedAt !== undefined && args.modifiedAt !== null) {
            this.modifiedAt = args.modifiedAt;
        }
        if (args.modifiedBy !== undefined && args.modifiedBy !== null) {
            this.modifiedBy = args.modifiedBy;
        }
        if (args.active !== undefined && args.active !== null) {
            this.active = args.active;
        }
        if (args.expired !== undefined && args.expired !== null) {
            this.expired = args.expired;
        }
        if (args.finished !== undefined && args.finished !== null) {
            this.finished = args.finished;
        }
        if (args.usable !== undefined && args.usable !== null) {
            this.usable = args.usable;
        }
    }
};
Status.prototype = {};
Status.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 1:
                if (ftype == Thrift.Type.I64) {
                    this.createdAt = input.readI64();
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.STRING) {
                    this.createdBy = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 3:
                if (ftype == Thrift.Type.I64) {
                    this.modifiedAt = input.readI64();
                } else {
                    input.skip(ftype);
                }
                break;
            case 4:
                if (ftype == Thrift.Type.STRING) {
                    this.modifiedBy = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 5:
                if (ftype == Thrift.Type.BOOL) {
                    this.active = input.readBool();
                } else {
                    input.skip(ftype);
                }
                break;
            case 6:
                if (ftype == Thrift.Type.BOOL) {
                    this.expired = input.readBool();
                } else {
                    input.skip(ftype);
                }
                break;
            case 7:
                if (ftype == Thrift.Type.BOOL) {
                    this.finished = input.readBool();
                } else {
                    input.skip(ftype);
                }
                break;
            case 8:
                if (ftype == Thrift.Type.BOOL) {
                    this.usable = input.readBool();
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

Status.prototype.write = function(output) {
    output.writeStructBegin('Status');
    if (this.createdAt !== null && this.createdAt !== undefined) {
        output.writeFieldBegin('createdAt', Thrift.Type.I64, 1);
        output.writeI64(this.createdAt);
        output.writeFieldEnd();
    }
    if (this.createdBy !== null && this.createdBy !== undefined) {
        output.writeFieldBegin('createdBy', Thrift.Type.STRING, 2);
        output.writeString(this.createdBy);
        output.writeFieldEnd();
    }
    if (this.modifiedAt !== null && this.modifiedAt !== undefined) {
        output.writeFieldBegin('modifiedAt', Thrift.Type.I64, 3);
        output.writeI64(this.modifiedAt);
        output.writeFieldEnd();
    }
    if (this.modifiedBy !== null && this.modifiedBy !== undefined) {
        output.writeFieldBegin('modifiedBy', Thrift.Type.STRING, 4);
        output.writeString(this.modifiedBy);
        output.writeFieldEnd();
    }
    if (this.active !== null && this.active !== undefined) {
        output.writeFieldBegin('active', Thrift.Type.BOOL, 5);
        output.writeBool(this.active);
        output.writeFieldEnd();
    }
    if (this.expired !== null && this.expired !== undefined) {
        output.writeFieldBegin('expired', Thrift.Type.BOOL, 6);
        output.writeBool(this.expired);
        output.writeFieldEnd();
    }
    if (this.finished !== null && this.finished !== undefined) {
        output.writeFieldBegin('finished', Thrift.Type.BOOL, 7);
        output.writeBool(this.finished);
        output.writeFieldEnd();
    }
    if (this.usable !== null && this.usable !== undefined) {
        output.writeFieldBegin('usable', Thrift.Type.BOOL, 8);
        output.writeBool(this.usable);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var ConfigRequest = module.exports.ConfigRequest = function(args) {
    this.ownerId = null;
    this.itemId = null;
    if (args) {
        if (args.ownerId !== undefined && args.ownerId !== null) {
            this.ownerId = args.ownerId;
        }
        if (args.itemId !== undefined && args.itemId !== null) {
            this.itemId = args.itemId;
        }
    }
};
ConfigRequest.prototype = {};
ConfigRequest.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 1:
                if (ftype == Thrift.Type.STRING) {
                    this.ownerId = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.STRING) {
                    this.itemId = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

ConfigRequest.prototype.write = function(output) {
    output.writeStructBegin('ConfigRequest');
    if (this.ownerId !== null && this.ownerId !== undefined) {
        output.writeFieldBegin('ownerId', Thrift.Type.STRING, 1);
        output.writeString(this.ownerId);
        output.writeFieldEnd();
    }
    if (this.itemId !== null && this.itemId !== undefined) {
        output.writeFieldBegin('itemId', Thrift.Type.STRING, 2);
        output.writeString(this.itemId);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var ConfigResponse = module.exports.ConfigResponse = function(args) {
    this.id = null;
    this.itemId = null;
    this.ownerId = null;
    this.status = null;
    if (args) {
        if (args.id !== undefined && args.id !== null) {
            this.id = args.id;
        }
        if (args.itemId !== undefined && args.itemId !== null) {
            this.itemId = args.itemId;
        }
        if (args.ownerId !== undefined && args.ownerId !== null) {
            this.ownerId = args.ownerId;
        }
        if (args.status !== undefined && args.status !== null) {
            this.status = new ttypes.Status(args.status);
        }
    }
};
ConfigResponse.prototype = {};
ConfigResponse.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 1:
                if (ftype == Thrift.Type.I64) {
                    this.id = input.readI64();
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.STRING) {
                    this.itemId = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 3:
                if (ftype == Thrift.Type.STRING) {
                    this.ownerId = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 4:
                if (ftype == Thrift.Type.STRUCT) {
                    this.status = new ttypes.Status();
                    this.status.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

ConfigResponse.prototype.write = function(output) {
    output.writeStructBegin('ConfigResponse');
    if (this.id !== null && this.id !== undefined) {
        output.writeFieldBegin('id', Thrift.Type.I64, 1);
        output.writeI64(this.id);
        output.writeFieldEnd();
    }
    if (this.itemId !== null && this.itemId !== undefined) {
        output.writeFieldBegin('itemId', Thrift.Type.STRING, 2);
        output.writeString(this.itemId);
        output.writeFieldEnd();
    }
    if (this.ownerId !== null && this.ownerId !== undefined) {
        output.writeFieldBegin('ownerId', Thrift.Type.STRING, 3);
        output.writeString(this.ownerId);
        output.writeFieldEnd();
    }
    if (this.status !== null && this.status !== undefined) {
        output.writeFieldBegin('status', Thrift.Type.STRUCT, 4);
        this.status.write(output);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var Pagination = module.exports.Pagination = function(args) {
    this.pageNumber = null;
    this.itemsPerPage = null;
    if (args) {
        if (args.pageNumber !== undefined && args.pageNumber !== null) {
            this.pageNumber = args.pageNumber;
        }
        if (args.itemsPerPage !== undefined && args.itemsPerPage !== null) {
            this.itemsPerPage = args.itemsPerPage;
        }
    }
};
Pagination.prototype = {};
Pagination.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 1:
                if (ftype == Thrift.Type.I64) {
                    this.pageNumber = input.readI64();
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.I64) {
                    this.itemsPerPage = input.readI64();
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

Pagination.prototype.write = function(output) {
    output.writeStructBegin('Pagination');
    if (this.pageNumber !== null && this.pageNumber !== undefined) {
        output.writeFieldBegin('pageNumber', Thrift.Type.I64, 1);
        output.writeI64(this.pageNumber);
        output.writeFieldEnd();
    }
    if (this.itemsPerPage !== null && this.itemsPerPage !== undefined) {
        output.writeFieldBegin('itemsPerPage', Thrift.Type.I64, 2);
        output.writeI64(this.itemsPerPage);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var ListConfigsRequest = module.exports.ListConfigsRequest = function(args) {
    this.ownerIds = null;
    this.pagination = null;
    if (args) {
        if (args.ownerIds !== undefined && args.ownerIds !== null) {
            this.ownerIds = Thrift.copyList(args.ownerIds, [null]);
        }
        if (args.pagination !== undefined && args.pagination !== null) {
            this.pagination = new ttypes.Pagination(args.pagination);
        }
    }
};
ListConfigsRequest.prototype = {};
ListConfigsRequest.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 1:
                if (ftype == Thrift.Type.LIST) {
                    var _size0 = 0;
                    var _rtmp34;
                    this.ownerIds = [];
                    var _etype3 = 0;
                    _rtmp34 = input.readListBegin();
                    _etype3 = _rtmp34.etype;
                    _size0 = _rtmp34.size;
                    for (var _i5 = 0; _i5 < _size0; ++_i5)
                    {
                        var elem6 = null;
                        elem6 = input.readString();
                        this.ownerIds.push(elem6);
                    }
                    input.readListEnd();
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.STRUCT) {
                    this.pagination = new ttypes.Pagination();
                    this.pagination.read(input);
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

ListConfigsRequest.prototype.write = function(output) {
    output.writeStructBegin('ListConfigsRequest');
    if (this.ownerIds !== null && this.ownerIds !== undefined) {
        output.writeFieldBegin('ownerIds', Thrift.Type.LIST, 1);
        output.writeListBegin(Thrift.Type.STRING, this.ownerIds.length);
        for (var iter7 in this.ownerIds)
        {
            if (this.ownerIds.hasOwnProperty(iter7))
            {
                iter7 = this.ownerIds[iter7];
                output.writeString(iter7);
            }
        }
        output.writeListEnd();
        output.writeFieldEnd();
    }
    if (this.pagination !== null && this.pagination !== undefined) {
        output.writeFieldBegin('pagination', Thrift.Type.STRUCT, 2);
        this.pagination.write(output);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var ListConfigsResponse = module.exports.ListConfigsResponse = function(args) {
    this.configs = null;
    this.totalItems = null;
    if (args) {
        if (args.configs !== undefined && args.configs !== null) {
            this.configs = Thrift.copyList(args.configs, [ttypes.ConfigResponse]);
        }
        if (args.totalItems !== undefined && args.totalItems !== null) {
            this.totalItems = args.totalItems;
        }
    }
};
ListConfigsResponse.prototype = {};
ListConfigsResponse.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 1:
                if (ftype == Thrift.Type.LIST) {
                    var _size8 = 0;
                    var _rtmp312;
                    this.configs = [];
                    var _etype11 = 0;
                    _rtmp312 = input.readListBegin();
                    _etype11 = _rtmp312.etype;
                    _size8 = _rtmp312.size;
                    for (var _i13 = 0; _i13 < _size8; ++_i13)
                    {
                        var elem14 = null;
                        elem14 = new ttypes.ConfigResponse();
                        elem14.read(input);
                        this.configs.push(elem14);
                    }
                    input.readListEnd();
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.I64) {
                    this.totalItems = input.readI64();
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

ListConfigsResponse.prototype.write = function(output) {
    output.writeStructBegin('ListConfigsResponse');
    if (this.configs !== null && this.configs !== undefined) {
        output.writeFieldBegin('configs', Thrift.Type.LIST, 1);
        output.writeListBegin(Thrift.Type.STRUCT, this.configs.length);
        for (var iter15 in this.configs)
        {
            if (this.configs.hasOwnProperty(iter15))
            {
                iter15 = this.configs[iter15];
                iter15.write(output);
            }
        }
        output.writeListEnd();
        output.writeFieldEnd();
    }
    if (this.totalItems !== null && this.totalItems !== undefined) {
        output.writeFieldBegin('totalItems', Thrift.Type.I64, 2);
        output.writeI64(this.totalItems);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var ActivateAppNoteSpeseRequest = module.exports.ActivateAppNoteSpeseRequest = function(args) {
    this.cf = null;
    this.companyName = null;
    this.zipCode = null;
    this.city = null;
    this.province = null;
    this.country = null;
    this.street = null;
    this.firstName = null;
    this.lastName = null;
    this.email = null;
    if (args) {
        if (args.cf !== undefined && args.cf !== null) {
            this.cf = args.cf;
        } else {
            throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field cf is unset!');
        }
        if (args.companyName !== undefined && args.companyName !== null) {
            this.companyName = args.companyName;
        } else {
            throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field companyName is unset!');
        }
        if (args.zipCode !== undefined && args.zipCode !== null) {
            this.zipCode = args.zipCode;
        } else {
            throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field zipCode is unset!');
        }
        if (args.city !== undefined && args.city !== null) {
            this.city = args.city;
        } else {
            throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field city is unset!');
        }
        if (args.province !== undefined && args.province !== null) {
            this.province = args.province;
        } else {
            throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field province is unset!');
        }
        if (args.country !== undefined && args.country !== null) {
            this.country = args.country;
        } else {
            throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field country is unset!');
        }
        if (args.street !== undefined && args.street !== null) {
            this.street = args.street;
        } else {
            throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field street is unset!');
        }
        if (args.firstName !== undefined && args.firstName !== null) {
            this.firstName = args.firstName;
        } else {
            throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field firstName is unset!');
        }
        if (args.lastName !== undefined && args.lastName !== null) {
            this.lastName = args.lastName;
        } else {
            throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field lastName is unset!');
        }
        if (args.email !== undefined && args.email !== null) {
            this.email = args.email;
        } else {
            throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field email is unset!');
        }
    }
};
ActivateAppNoteSpeseRequest.prototype = {};
ActivateAppNoteSpeseRequest.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 1:
                if (ftype == Thrift.Type.STRING) {
                    this.cf = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.STRING) {
                    this.companyName = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 3:
                if (ftype == Thrift.Type.STRING) {
                    this.zipCode = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 4:
                if (ftype == Thrift.Type.STRING) {
                    this.city = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 5:
                if (ftype == Thrift.Type.STRING) {
                    this.province = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 6:
                if (ftype == Thrift.Type.STRING) {
                    this.country = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 7:
                if (ftype == Thrift.Type.STRING) {
                    this.street = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 8:
                if (ftype == Thrift.Type.STRING) {
                    this.firstName = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 9:
                if (ftype == Thrift.Type.STRING) {
                    this.lastName = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 10:
                if (ftype == Thrift.Type.STRING) {
                    this.email = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

ActivateAppNoteSpeseRequest.prototype.write = function(output) {
    output.writeStructBegin('ActivateAppNoteSpeseRequest');
    if (this.cf !== null && this.cf !== undefined) {
        output.writeFieldBegin('cf', Thrift.Type.STRING, 1);
        output.writeString(this.cf);
        output.writeFieldEnd();
    }
    if (this.companyName !== null && this.companyName !== undefined) {
        output.writeFieldBegin('companyName', Thrift.Type.STRING, 2);
        output.writeString(this.companyName);
        output.writeFieldEnd();
    }
    if (this.zipCode !== null && this.zipCode !== undefined) {
        output.writeFieldBegin('zipCode', Thrift.Type.STRING, 3);
        output.writeString(this.zipCode);
        output.writeFieldEnd();
    }
    if (this.city !== null && this.city !== undefined) {
        output.writeFieldBegin('city', Thrift.Type.STRING, 4);
        output.writeString(this.city);
        output.writeFieldEnd();
    }
    if (this.province !== null && this.province !== undefined) {
        output.writeFieldBegin('province', Thrift.Type.STRING, 5);
        output.writeString(this.province);
        output.writeFieldEnd();
    }
    if (this.country !== null && this.country !== undefined) {
        output.writeFieldBegin('country', Thrift.Type.STRING, 6);
        output.writeString(this.country);
        output.writeFieldEnd();
    }
    if (this.street !== null && this.street !== undefined) {
        output.writeFieldBegin('street', Thrift.Type.STRING, 7);
        output.writeString(this.street);
        output.writeFieldEnd();
    }
    if (this.firstName !== null && this.firstName !== undefined) {
        output.writeFieldBegin('firstName', Thrift.Type.STRING, 8);
        output.writeString(this.firstName);
        output.writeFieldEnd();
    }
    if (this.lastName !== null && this.lastName !== undefined) {
        output.writeFieldBegin('lastName', Thrift.Type.STRING, 9);
        output.writeString(this.lastName);
        output.writeFieldEnd();
    }
    if (this.email !== null && this.email !== undefined) {
        output.writeFieldBegin('email', Thrift.Type.STRING, 10);
        output.writeString(this.email);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var ActivateAppNoteSpeseResponse = module.exports.ActivateAppNoteSpeseResponse = function(args) {
    this.cf = null;
    this.jwt = null;
    if (args) {
        if (args.cf !== undefined && args.cf !== null) {
            this.cf = args.cf;
        }
        if (args.jwt !== undefined && args.jwt !== null) {
            this.jwt = args.jwt;
        }
    }
};
ActivateAppNoteSpeseResponse.prototype = {};
ActivateAppNoteSpeseResponse.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 1:
                if (ftype == Thrift.Type.STRING) {
                    this.cf = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.STRING) {
                    this.jwt = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

ActivateAppNoteSpeseResponse.prototype.write = function(output) {
    output.writeStructBegin('ActivateAppNoteSpeseResponse');
    if (this.cf !== null && this.cf !== undefined) {
        output.writeFieldBegin('cf', Thrift.Type.STRING, 1);
        output.writeString(this.cf);
        output.writeFieldEnd();
    }
    if (this.jwt !== null && this.jwt !== undefined) {
        output.writeFieldBegin('jwt', Thrift.Type.STRING, 2);
        output.writeString(this.jwt);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var IdNotFoundException = module.exports.IdNotFoundException = function(args) {
    Thrift.TException.call(this, "IdNotFoundException");
    this.name = "IdNotFoundException";
    this.code = null;
    this.message = null;
    if (args) {
        if (args.code !== undefined && args.code !== null) {
            this.code = args.code;
        }
        if (args.message !== undefined && args.message !== null) {
            this.message = args.message;
        }
    }
};
Thrift.inherits(IdNotFoundException, Thrift.TException);
IdNotFoundException.prototype.name = 'IdNotFoundException';
IdNotFoundException.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 1:
                if (ftype == Thrift.Type.STRING) {
                    this.code = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.STRING) {
                    this.message = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

IdNotFoundException.prototype.write = function(output) {
    output.writeStructBegin('IdNotFoundException');
    if (this.code !== null && this.code !== undefined) {
        output.writeFieldBegin('code', Thrift.Type.STRING, 1);
        output.writeString(this.code);
        output.writeFieldEnd();
    }
    if (this.message !== null && this.message !== undefined) {
        output.writeFieldBegin('message', Thrift.Type.STRING, 2);
        output.writeString(this.message);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var GenericError = module.exports.GenericError = function(args) {
    Thrift.TException.call(this, "GenericError");
    this.name = "GenericError";
    this.code = null;
    this.message = null;
    if (args) {
        if (args.code !== undefined && args.code !== null) {
            this.code = args.code;
        }
        if (args.message !== undefined && args.message !== null) {
            this.message = args.message;
        }
    }
};
Thrift.inherits(GenericError, Thrift.TException);
GenericError.prototype.name = 'GenericError';
GenericError.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 1:
                if (ftype == Thrift.Type.STRING) {
                    this.code = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.STRING) {
                    this.message = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

GenericError.prototype.write = function(output) {
    output.writeStructBegin('GenericError');
    if (this.code !== null && this.code !== undefined) {
        output.writeFieldBegin('code', Thrift.Type.STRING, 1);
        output.writeString(this.code);
        output.writeFieldEnd();
    }
    if (this.message !== null && this.message !== undefined) {
        output.writeFieldBegin('message', Thrift.Type.STRING, 2);
        output.writeString(this.message);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

var AuthenticationException = module.exports.AuthenticationException = function(args) {
    Thrift.TException.call(this, "AuthenticationException");
    this.name = "AuthenticationException";
    this.code = null;
    this.message = null;
    if (args) {
        if (args.code !== undefined && args.code !== null) {
            this.code = args.code;
        }
        if (args.message !== undefined && args.message !== null) {
            this.message = args.message;
        }
    }
};
Thrift.inherits(AuthenticationException, Thrift.TException);
AuthenticationException.prototype.name = 'AuthenticationException';
AuthenticationException.prototype.read = function(input) {
    input.readStructBegin();
    while (true)
    {
        var ret = input.readFieldBegin();
        var fname = ret.fname;
        var ftype = ret.ftype;
        var fid = ret.fid;
        if (ftype == Thrift.Type.STOP) {
            break;
        }
        switch (fid)
        {
            case 1:
                if (ftype == Thrift.Type.STRING) {
                    this.code = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            case 2:
                if (ftype == Thrift.Type.STRING) {
                    this.message = input.readString();
                } else {
                    input.skip(ftype);
                }
                break;
            default:
                input.skip(ftype);
        }
        input.readFieldEnd();
    }
    input.readStructEnd();
    return;
};

AuthenticationException.prototype.write = function(output) {
    output.writeStructBegin('AuthenticationException');
    if (this.code !== null && this.code !== undefined) {
        output.writeFieldBegin('code', Thrift.Type.STRING, 1);
        output.writeString(this.code);
        output.writeFieldEnd();
    }
    if (this.message !== null && this.message !== undefined) {
        output.writeFieldBegin('message', Thrift.Type.STRING, 2);
        output.writeString(this.message);
        output.writeFieldEnd();
    }
    output.writeFieldStop();
    output.writeStructEnd();
    return;
};

ttypes.VERSION = '1.0.0';
