import styled from "styled-components";

export const NotificationsContainer = styled.div`
    overflow: auto;
    max-height: 100%;
`;

export const EmptyNotification = styled.div`
    padding: 16px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;

    & > a {
        width: 100%;

        & > button {
            width: 100%;
        }
    }
`;
