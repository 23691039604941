import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const EmptyImage = styled.img`
    max-width: 124px;
    margin: 32px;
`;

export const EmptyMessage = styled.div`
    color: rgb(0, 80, 117);
    font-size: 24px;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.18px;
`;

export const Suggestion = styled.div`
    color: #9da6ad;
    font-size: 14px;
    font-family: Roboto;
    letter-spacing: 0.25px;
`;
