import React, { createContext, useState } from "react";

export const UsersManagegementContext = createContext({});

export const UsersManagementContextProvider = ({ children }) => {
    const [currentItem, setCurrentItem] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [userCreationFlowFirstStep, setUserCreationFlowFirstStep] = useState();

    return (
        <UsersManagegementContext.Provider
            value={{
                currentItem,
                setCurrentItem,
                selectedUser,
                setSelectedUser,
                userCreationFlowFirstStep,
                setUserCreationFlowFirstStep
            }}
        >
            {children}
        </UsersManagegementContext.Provider>
    );
};
