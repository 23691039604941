import {
    DOWNLOAD_CONNECTIONS_DATA_ERROR,
    DOWNLOAD_CONNECTIONS_DATA_RESET,
    DOWNLOAD_CONNECTIONS_DATA_START,
    DOWNLOAD_CONNECTIONS_DATA_SUCCESS
} from "../../actions/connections/export";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function connectionsData(state = defaultState, { type, payload, error }) {
    switch (type) {
        case DOWNLOAD_CONNECTIONS_DATA_START:
            return {
                ...defaultState,
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case DOWNLOAD_CONNECTIONS_DATA_SUCCESS:
            return {
                ...defaultState,
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                payload
            };
        case DOWNLOAD_CONNECTIONS_DATA_ERROR:
            return {
                ...defaultState,
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: {
                        code: error.code,
                        message: error.message
                    }
                }
            };
        case DOWNLOAD_CONNECTIONS_DATA_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}
