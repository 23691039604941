import React, { useCallback, useEffect, useState, useMemo } from "react";

import moment from "moment";

import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Button } from "@ts-digital/vrc";
import { Modal } from "antd";

import { listUsers } from "../../../../actions/company/users";
import { getCctConfig, saveCctConfig, verifyCCTcredentials } from "../../../../actions/services/cct";
import {
    extendSmartProvisioning,
    fetchCctProvisioningInfo,
    fetchCctProvisioningInfoManaged,
    initializeManagedProvisioning,
    uploadCctProvisioningContract,
    resetExtendSmartProvisioning
} from "../../../../actions/services/cct/provisioning";
import { smartCctError, smartCctInitialized } from "../../../../actions/toastNotifications";
import {
    downloadProvisioningData,
    resetDownloadProvisioningData
} from "../../../../actions/services/cct/provisioning-export";

import { Container, Content, Title, ContentWrapper } from "../styled";
import { Spinner } from "../../../../components/general/spinner";
import ConfigureManagedItems from "../../../../components/storage/cct/provisioning/configure-managed-items";
import UploadPdfForm from "../../../../components/storage/cct/provisioning/upload-pdf-form";
import PageTitle from "../../../../components/general/page-title";
import { ConfigureManagedItemsTitle } from "../configure-managed-items-title";

const ConfigureManagedItemsView = ({
    isCctActive,
    configError,
    configLoaded,
    configLoading,
    contractUploading,
    cctExtedSmartStatus,
    displayErrorToast,
    downloadProvisioningData,
    extendSmartProvisioning,
    fetchCctProvisioningInfo,
    fetchCctProvisioningInfoManaged,
    getCctConfig,
    history,
    managedData,
    managedPages,
    managedProvisioning,
    match,
    provisioningData,
    provisioningInfo,
    resetDownloadProvisioningData,
    uploadCctProvisioningContract,
    resetExtendSmartProvisioning,
    smartCctInitialized,
    smartCctError,
    user
}) => {
    const [page, setPage] = useState(0);
    const [filters, setFilters] = useState({});
    const [initialized, setInitialized] = useState(false);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");
    const itemId = match.params.item;
    const pageSize = 5;

    const currentPage = useMemo(() => {
        const mappedPage =
            managedPages &&
            managedPages.pages[page] &&
            managedPages.pages[page].map(item => provisioningInfo.provisioningInfo[item]);

        return mappedPage || [];
    }, [managedPages, page, provisioningInfo]);

    const handlePageChange = useCallback(
        nextPage => {
            setPage(nextPage);
            fetchCctProvisioningInfoManaged(itemId, "EIP", nextPage, pageSize, filters);
        },
        [fetchCctProvisioningInfoManaged, itemId, filters]
    );

    const massiveExtendSmartCct = ids => {
        extendSmartProvisioning(itemId, ids, moment(new Date(new Date().getFullYear(), 11, 31)).format("YYYY-MM-DD"));
    };

    useEffect(() => {
        if (!configLoading && !configLoaded && !configError && isCctActive) {
            getCctConfig(itemId, itemId);
            fetchCctProvisioningInfo(itemId);
        }
    }, [configLoaded, configLoading, configError, fetchCctProvisioningInfo, getCctConfig, itemId, isCctActive]);

    useEffect(() => {
        if (!initialized) {
            fetchCctProvisioningInfoManaged(itemId, "EIP", 0, pageSize);
            setInitialized(true);
        }
    }, [fetchCctProvisioningInfoManaged, itemId, initialized]);

    useEffect(() => {
        if (managedProvisioning.ended) {
            setSelectedItem("");
        }
    }, [managedProvisioning.ended]);

    useEffect(() => {
        if (!contractUploading) {
            setSelectedItem("");
            setUploadModalOpen(false);
        }
    }, [contractUploading]);

    useEffect(() => {
        if (cctExtedSmartStatus.ended && !cctExtedSmartStatus.error) {
            smartCctInitialized();
            resetExtendSmartProvisioning();
        } else if (cctExtedSmartStatus.error && !cctExtedSmartStatus.ended) {
            smartCctError();
            resetExtendSmartProvisioning();
        }
    }, [
        cctExtedSmartStatus.ended,
        cctExtedSmartStatus.error,
        smartCctInitialized,
        smartCctError,
        resetExtendSmartProvisioning
    ]);

    const [fileTypeError, setFileTypeError] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [fileBase64, setFileBase64] = useState("");

    const handleSelectFile = useCallback((base64, file, error) => {
        setFileTypeError(error);
        setFileBase64(base64);
        setSelectedFile(file);
    }, []);

    const studioHasSmart =
        provisioningInfo &&
        provisioningInfo.provisioningInfo &&
        provisioningInfo.provisioningInfo[itemId] &&
        provisioningInfo.provisioningInfo[itemId].contractType === "PROVISIONING_SMART";

    const studioRdcSmartData = studioHasSmart && provisioningInfo.provisioningInfo[itemId].holder;

    return (
        <Spinner loading={configLoading || provisioningInfo.isLoading}>
            <PageTitle title={<FormattedMessage id="c-services-view.ARC.name" />} onBack={() => history.goBack()} />
            <Container>
                <Content>
                    <Title>
                        <FormattedMessage id="c-cct-title" />
                    </Title>
                    <ConfigureManagedItemsTitle
                        provisioningData={provisioningData}
                        displayErrorToast={displayErrorToast}
                        downloadProvisioningData={() => downloadProvisioningData(itemId)}
                        resetDownloadProvisioningData={resetDownloadProvisioningData}
                    />
                    <ContentWrapper>
                        <ConfigureManagedItems
                            loading={managedPages.isLoading}
                            managedData={managedData}
                            onDownloadPdf={itemId => {
                                history.push(
                                    `/${match.params.item}/services/storage/edit-cct/generate-document/${itemId}`
                                );
                            }}
                            onFilterChange={filters => {
                                filters.managedIds = filters.itemTaxId || undefined;
                                setFilters(filters);
                                setPage(0);
                                fetchCctProvisioningInfoManaged(itemId, "EIP", 0, pageSize, filters);
                            }}
                            onUploadPdf={itemId => {
                                setSelectedItem(itemId);
                                setUploadModalOpen(true);
                            }}
                            onPageChange={handlePageChange}
                            pageData={currentPage}
                            pageNumber={page}
                            pageSize={pageSize}
                            totalPages={Math.ceil(managedPages.totalElements / pageSize)}
                            user={user}
                            onRefresh={() => {
                                fetchCctProvisioningInfoManaged(itemId, "EIP", page, pageSize, filters);
                            }}
                            filters={filters}
                            onMassiveSmartExtend={ids => massiveExtendSmartCct(ids)}
                            studioHasSmart={studioHasSmart}
                            extendCctSmartLoading={cctExtedSmartStatus.started}
                            cctExtedSmartStatus={cctExtedSmartStatus}
                            resetExtendSmartProvisioning={resetExtendSmartProvisioning}
                            studioRdcSmartData={studioRdcSmartData}
                        />
                    </ContentWrapper>
                </Content>
            </Container>
            <Modal
                centered={true}
                closable={true}
                visible={uploadModalOpen}
                width="504px"
                title={<FormattedMessage id="c-cct-provisioning-upload-pdf-form.title" />}
                onCancel={() => setUploadModalOpen(false)}
                footer={[
                    <Button kind="secondary" onClick={() => setUploadModalOpen(false)}>
                        <FormattedMessage id="general.annulla" />
                    </Button>,
                    <Button
                        data-gainsight-track={"archive-contract-uploaded-cta"}
                        onClick={() => {
                            uploadCctProvisioningContract(match.params.item, fileBase64, selectedItem);
                        }}
                    >
                        <FormattedMessage id="c-cct-provisioning-upload-pdf-form.send-document" />
                    </Button>
                ]}
            >
                <UploadPdfForm
                    handleSelectFile={handleSelectFile}
                    fileTypeError={fileTypeError}
                    isLoading={contractUploading}
                    selectedFile={selectedFile}
                />
            </Modal>
        </Spinner>
    );
};

const mapStateToProps = (state, props) => ({
    isCctActive: state.services.active.services.includes("CCT-FLOW") && !state.services.active.services.includes("ARC"),
    cctConfig: state.services.cct.getCctConfig,
    configError: state.services.cct.getCctConfig.status.error,
    configLoading: state.services.cct.getCctConfig.status.started,
    configLoaded: state.services.cct.getCctConfig.status.ended,
    contractUploading: state.services.cct.provisioningContract.status.started,
    provisioningData: state.services.cct.provisioningData,
    managedData: state.companies.data,
    managedPages: state.services.cct.provisioningInfo.managedInfoPagination,
    managedProvisioning: state.services.cct.managedProvisioning,
    provisioningInfo: state.services.cct.provisioningInfo,
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    credentialsStatus: state.services.cct.verifyCct.status,
    user: state.user,
    cctExtedSmartStatus: state.services.cct.extendSmart.status
});

const actions = {
    downloadProvisioningData,
    extendSmartProvisioning,
    fetchCctProvisioningInfo,
    fetchCctProvisioningInfoManaged,
    getCctConfig,
    initializeManagedProvisioning,
    listUsers,
    saveCctConfig,
    uploadCctProvisioningContract,
    resetExtendSmartProvisioning,
    resetDownloadProvisioningData,
    smartCctInitialized,
    smartCctError,
    verifyCCTcredentials
};

export default connect(mapStateToProps, actions)(ConfigureManagedItemsView);
