import { WAITINGLIST_WRITE_URL } from "../../config";
import { postRestCall, deleteRestCall } from "../../utils/rest-api-call";
import { getUserProfile } from "../../utils/state-utils/get-user-profile";
import { getAuthWithAppName } from "../auth";

if (!WAITINGLIST_WRITE_URL) {
    throw new Error("Undefined env WAITINGLIST_WRITE_URL");
}

export const WAITINGLIST_SUBSCRIBE_START = "WAITINGLIST_SUBSCRIBE_START";
export const WAITINGLIST_SUBSCRIBE_ERROR = "WAITINGLIST_SUBSCRIBE_ERROR";
export const WAITINGLIST_SUBSCRIBE_SUCCESS = "WAITINGLIST_SUBSCRIBE_SUCCESS";

export function subscribeWaitinglist(itemId, appId) {
    return async (dispatch, getState) => {
        dispatch({
            type: WAITINGLIST_SUBSCRIBE_START,
            payload: { itemId, appId }
        });

        const request = { service: appId, item: itemId };
        const requestUrl = WAITINGLIST_WRITE_URL + "waitingList/";

        try {
            const auth = getAuthWithAppName(getState().auth);
            const response = await postRestCall(
                requestUrl,
                auth,
                null,
                request,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                undefined,
                true
            );

            dispatch({
                type: WAITINGLIST_SUBSCRIBE_SUCCESS,
                payload: response
            });
        } catch (e) {
            dispatch({
                type: WAITINGLIST_SUBSCRIBE_ERROR,
                payload: { error: e, itemId }
            });
        }
    };
}

export const WAITINGLIST_DELETE_START = "WAITINGLIST_DELETE_START";
export const WAITINGLIST_DELETE_ERROR = "WAITINGLIST_DELETE_ERROR";
export const WAITINGLIST_DELETE_SUCCESS = "WAITINGLIST_DELETE_SUCCESS";

export function deleteWaitinglist(itemId, appId) {
    return async (dispatch, getState) => {
        dispatch({
            type: WAITINGLIST_DELETE_START,
            payload: { itemId, appId }
        });

        const request = { service: appId, item: itemId };
        const requestUrl = WAITINGLIST_WRITE_URL + "waitingList/";

        try {
            const auth = getAuthWithAppName(getState().auth);
            const response = await deleteRestCall(
                requestUrl,
                auth,
                null,
                request,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                undefined,
                true
            );

            dispatch({
                type: WAITINGLIST_DELETE_SUCCESS,
                payload: response
            });
        } catch (e) {
            dispatch({
                type: WAITINGLIST_DELETE_ERROR,
                payload: { error: e, itemId, appId }
            });
        }
    };
}
