import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { InvoicesTextContainer, CreditContainer } from "../styled";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { UsageBar } from "../../../../../components/metering/usage-bar";
import { Segment } from "../../../../../components/metering/usage-bar/segment";

export const Credit = ({ usage, capacity }) => {
    const [remaining, setRemaining] = useState(0);
    const [usagePercentage, setUsagePercentage] = useState(0);

    useEffect(() => {
        setRemaining(capacity - usage);
        setUsagePercentage(capacity > 0 ? usage / capacity : 0);
    }, [usage, capacity]);

    return (
        <CreditContainer>
            <InvoicesTextContainer>
                <FormattedMessage
                    id="metering.billing.credit.remaining"
                    values={{
                        remaining: (
                            <b>
                                <FormattedNumber value={remaining} />
                            </b>
                        ),
                        used: (
                            <b>
                                <FormattedNumber value={usage} />
                            </b>
                        ),
                        capacity: (
                            <b>
                                <FormattedNumber value={capacity} />
                            </b>
                        ),
                        usagePercentage: (
                            <b>
                                <FormattedNumber
                                    value={usagePercentage}
                                    style={`percent`}
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                />
                            </b>
                        )
                    }}
                />
            </InvoicesTextContainer>
            <UsageBar totalPercentage={usagePercentage}>
                <Segment
                    variant="primary"
                    percent={usagePercentage * 100}
                    tooltipText={<FormattedMessage id="metering.billing.credit.tooltip" />}
                />
            </UsageBar>
        </CreditContainer>
    );
};

Credit.propTypes = {
    usage: PropTypes.number.isRequired,
    capacity: PropTypes.number.isRequired
};
