import React from "react";
import PropTypes from "prop-types";

import { useIntl } from "react-intl";

import ServiceCard from "../../components/general/service-card";
import { ServiceCardsContainer } from "../general/service-card/styled";

const Accounting = ({
    company,
    companyId,
    roles,
    services,
    getWaitingList,
    getWaitingListStatus,
    subscribeWaitinglist,
    deleteWaitinglist,
    waitinglistDeleteStatus,
    waitinglist,
    waitinglistSubscribeStatus,
    user
}) => {
    const { formatMessage } = useIntl();

    return (
        services && (
            <ServiceCardsContainer expanded={false}>
                <ServiceCard
                    button={{
                        active: false,
                        text: formatMessage({
                            id: "general.view"
                        })
                    }}
                    buyLink="https://www.teamsystem.com/store/fatturazione-e-contabilita/movimenti-bancari/prezzi/?utm_medium=referral&utm_source=app-agyo&utm_campaign=mb_sitelink-piattaforma"
                    category={{
                        title: formatMessage({
                            id: "c-services-view-accounting"
                        })
                    }}
                    description={formatMessage({
                        id: "c-accounting.description"
                    })}
                    display={roles.some(v => v.resourceId === companyId && v.appId == null)}
                    expanded={false}
                    packageInfo={{ active: false }}
                    ribbon={{
                        text: formatMessage({
                            id: "general.gratis"
                        }),
                        color: "green"
                    }}
                    ribbonActive={false}
                    title={formatMessage({
                        id: "c-accounting.title"
                    })}
                    serviceInfo={services.agyoServices.services.filter(ele => ele.id === "BANKLINK")[0]}
                    companyId={company.base.id}
                    getWaitingList={getWaitingList}
                    getWaitingListStatus={getWaitingListStatus}
                    waitinglist={waitinglist}
                    subscribeWaitinglist={subscribeWaitinglist}
                    deleteWaitinglist={deleteWaitinglist}
                    waitinglistDeleteStatus={waitinglistDeleteStatus}
                    waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                    user={user}
                />
            </ServiceCardsContainer>
        )
    );
};

Accounting.propTypes = {
    companyId: PropTypes.string.isRequired,
    roles: PropTypes.array.isRequired,
    services: PropTypes.object
};

export default Accounting;
