/* eslint-disable */
//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
"use strict";

var thrift = require('browser-thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = require('./a-fiscali-iva-flow-config_types');
//HELPER FUNCTIONS AND STRUCTURES

var AFiscaliIvaFlowConfig_health_args = function(args) {
};
AFiscaliIvaFlowConfig_health_args.prototype = {};
AFiscaliIvaFlowConfig_health_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AFiscaliIvaFlowConfig_health_args.prototype.write = function(output) {
  output.writeStructBegin('AFiscaliIvaFlowConfig_health_args');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AFiscaliIvaFlowConfig_health_result = function(args) {
  this.success = null;
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
  }
};
AFiscaliIvaFlowConfig_health_result.prototype = {};
AFiscaliIvaFlowConfig_health_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRING) {
        this.success = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AFiscaliIvaFlowConfig_health_result.prototype.write = function(output) {
  output.writeStructBegin('AFiscaliIvaFlowConfig_health_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRING, 0);
    output.writeString(this.success);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AFiscaliIvaFlowConfig_isActive_args = function(args) {
  this.auth = null;
  this.data = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.data !== undefined && args.data !== null) {
      this.data = new ttypes.AFiscaliIvaData(args.data);
    }
  }
};
AFiscaliIvaFlowConfig_isActive_args.prototype = {};
AFiscaliIvaFlowConfig_isActive_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.data = new ttypes.AFiscaliIvaData();
        this.data.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AFiscaliIvaFlowConfig_isActive_args.prototype.write = function(output) {
  output.writeStructBegin('AFiscaliIvaFlowConfig_isActive_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.data !== null && this.data !== undefined) {
    output.writeFieldBegin('data', Thrift.Type.STRUCT, 2);
    this.data.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AFiscaliIvaFlowConfig_isActive_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  this.idNotFoundException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.IdNotFoundException) {
    this.idNotFoundException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.idNotFoundException !== undefined && args.idNotFoundException !== null) {
      this.idNotFoundException = args.idNotFoundException;
    }
  }
};
AFiscaliIvaFlowConfig_isActive_result.prototype = {};
AFiscaliIvaFlowConfig_isActive_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.BOOL) {
        this.success = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.idNotFoundException = new ttypes.IdNotFoundException();
        this.idNotFoundException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AFiscaliIvaFlowConfig_isActive_result.prototype.write = function(output) {
  output.writeStructBegin('AFiscaliIvaFlowConfig_isActive_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.BOOL, 0);
    output.writeBool(this.success);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.idNotFoundException !== null && this.idNotFoundException !== undefined) {
    output.writeFieldBegin('idNotFoundException', Thrift.Type.STRUCT, 3);
    this.idNotFoundException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AFiscaliIvaFlowConfig_getConfig_args = function(args) {
  this.auth = null;
  this.data = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new ttypes.Auth(args.auth);
    }
    if (args.data !== undefined && args.data !== null) {
      this.data = new ttypes.AFiscaliIvaData(args.data);
    }
  }
};
AFiscaliIvaFlowConfig_getConfig_args.prototype = {};
AFiscaliIvaFlowConfig_getConfig_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.data = new ttypes.AFiscaliIvaData();
        this.data.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AFiscaliIvaFlowConfig_getConfig_args.prototype.write = function(output) {
  output.writeStructBegin('AFiscaliIvaFlowConfig_getConfig_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.data !== null && this.data !== undefined) {
    output.writeFieldBegin('data', Thrift.Type.STRUCT, 2);
    this.data.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AFiscaliIvaFlowConfig_getConfig_result = function(args) {
  this.success = null;
  this.genericError = null;
  this.authenticationException = null;
  this.idNotFoundException = null;
  if (args instanceof ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args instanceof ttypes.IdNotFoundException) {
    this.idNotFoundException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.AFiscaliIvaConfig(args.success);
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
    if (args.idNotFoundException !== undefined && args.idNotFoundException !== null) {
      this.idNotFoundException = args.idNotFoundException;
    }
  }
};
AFiscaliIvaFlowConfig_getConfig_result.prototype = {};
AFiscaliIvaFlowConfig_getConfig_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.AFiscaliIvaConfig();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.idNotFoundException = new ttypes.IdNotFoundException();
        this.idNotFoundException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AFiscaliIvaFlowConfig_getConfig_result.prototype.write = function(output) {
  output.writeStructBegin('AFiscaliIvaFlowConfig_getConfig_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 1);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 2);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  if (this.idNotFoundException !== null && this.idNotFoundException !== undefined) {
    output.writeFieldBegin('idNotFoundException', Thrift.Type.STRUCT, 3);
    this.idNotFoundException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AFiscaliIvaFlowConfigClient = exports.Client = function(output, pClass) {
    this.output = output;
    this.pClass = pClass;
    this._seqid = 0;
    this._reqs = {};
};
AFiscaliIvaFlowConfigClient.prototype = {};
AFiscaliIvaFlowConfigClient.prototype.seqid = function() { return this._seqid; };
AFiscaliIvaFlowConfigClient.prototype.new_seqid = function() { return this._seqid += 1; };
AFiscaliIvaFlowConfigClient.prototype.health = function(callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_health();
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_health();
  }
};

AFiscaliIvaFlowConfigClient.prototype.send_health = function() {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('health', Thrift.MessageType.CALL, this.seqid());
  var args = new AFiscaliIvaFlowConfig_health_args();
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

AFiscaliIvaFlowConfigClient.prototype.recv_health = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AFiscaliIvaFlowConfig_health_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('health failed: unknown result');
};
AFiscaliIvaFlowConfigClient.prototype.isActive = function(auth, data, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_isActive(auth, data);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_isActive(auth, data);
  }
};

AFiscaliIvaFlowConfigClient.prototype.send_isActive = function(auth, data) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('isActive', Thrift.MessageType.CALL, this.seqid());
  var args = new AFiscaliIvaFlowConfig_isActive_args();
  args.auth = auth;
  args.data = data;
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

AFiscaliIvaFlowConfigClient.prototype.recv_isActive = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AFiscaliIvaFlowConfig_isActive_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.idNotFoundException) {
    return callback(result.idNotFoundException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('isActive failed: unknown result');
};
AFiscaliIvaFlowConfigClient.prototype.getConfig = function(auth, data, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_getConfig(auth, data);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_getConfig(auth, data);
  }
};

AFiscaliIvaFlowConfigClient.prototype.send_getConfig = function(auth, data) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('getConfig', Thrift.MessageType.CALL, this.seqid());
  var args = new AFiscaliIvaFlowConfig_getConfig_args();
  args.auth = auth;
  args.data = data;
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

AFiscaliIvaFlowConfigClient.prototype.recv_getConfig = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AFiscaliIvaFlowConfig_getConfig_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.idNotFoundException) {
    return callback(result.idNotFoundException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('getConfig failed: unknown result');
};
var AFiscaliIvaFlowConfigProcessor = exports.Processor = function(handler) {
  this._handler = handler;
}
;
AFiscaliIvaFlowConfigProcessor.prototype.process = function(input, output) {
  var r = input.readMessageBegin();
  if (this['process_' + r.fname]) {
    return this['process_' + r.fname].call(this, r.rseqid, input, output);
  } else {
    input.skip(Thrift.Type.STRUCT);
    input.readMessageEnd();
    var x = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN_METHOD, 'Unknown function ' + r.fname);
    output.writeMessageBegin(r.fname, Thrift.MessageType.EXCEPTION, r.rseqid);
    x.write(output);
    output.writeMessageEnd();
    output.flush();
  }
}
;
AFiscaliIvaFlowConfigProcessor.prototype.process_health = function(seqid, input, output) {
  var args = new AFiscaliIvaFlowConfig_health_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.health.length === 0) {
    Q.fcall(this._handler.health)
      .then(function(result) {
        var result_obj = new AFiscaliIvaFlowConfig_health_result({success: result});
        output.writeMessageBegin("health", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("health", Thrift.MessageType.EXCEPTION, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.health(function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined')) {
        result_obj = new AFiscaliIvaFlowConfig_health_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("health", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("health", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AFiscaliIvaFlowConfigProcessor.prototype.process_isActive = function(seqid, input, output) {
  var args = new AFiscaliIvaFlowConfig_isActive_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.isActive.length === 2) {
    Q.fcall(this._handler.isActive, args.auth, args.data)
      .then(function(result) {
        var result_obj = new AFiscaliIvaFlowConfig_isActive_result({success: result});
        output.writeMessageBegin("isActive", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.IdNotFoundException) {
          result = new AFiscaliIvaFlowConfig_isActive_result(err);
          output.writeMessageBegin("isActive", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("isActive", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.isActive(args.auth, args.data, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.IdNotFoundException) {
        result_obj = new AFiscaliIvaFlowConfig_isActive_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("isActive", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("isActive", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AFiscaliIvaFlowConfigProcessor.prototype.process_getConfig = function(seqid, input, output) {
  var args = new AFiscaliIvaFlowConfig_getConfig_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.getConfig.length === 2) {
    Q.fcall(this._handler.getConfig, args.auth, args.data)
      .then(function(result) {
        var result_obj = new AFiscaliIvaFlowConfig_getConfig_result({success: result});
        output.writeMessageBegin("getConfig", Thrift.MessageType.REPLY, seqid);
        result_obj.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result;
        if (err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.IdNotFoundException) {
          result = new AFiscaliIvaFlowConfig_getConfig_result(err);
          output.writeMessageBegin("getConfig", Thrift.MessageType.REPLY, seqid);
        } else {
          result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("getConfig", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.getConfig(args.auth, args.data, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.GenericError || err instanceof ttypes.AuthenticationException || err instanceof ttypes.IdNotFoundException) {
        result_obj = new AFiscaliIvaFlowConfig_getConfig_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("getConfig", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("getConfig", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
