import React from "react";
import PropTypes from "prop-types";

import { Radio, Form } from "antd";

const FormRadio = ({ input, label, meta: { touched, error }, children, showSearch, ...custom }) => (
    <Form.Item
        hasFeedback={true}
        help={touched && error}
        label={label}
        validateStatus={touched && error ? "error" : ""}
    >
        <div>
            <Radio.Group placeholder={label} children={children} {...custom} {...input} />
        </div>
    </Form.Item>
);

FormRadio.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.array.isRequired]),
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    meta: PropTypes.object.isRequired,
    showSearch: PropTypes.bool
};

FormRadio.defaultProps = {
    showSearch: true
};

export default FormRadio;
