import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const StatusIconContainer = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    height: 40px;
    width: 40px;
`;

export const StatusIcon = styled(FontAwesomeIcon)`
    color: ${props =>
        props.status === "active" ? props.theme.colors.status.laSalleGreen : props.theme.colors.text.quickSilver};
`;
