import React from "react";
import PropTypes from "prop-types";
import { InitialsSpan } from "./styled";

export const Initials = ({ name }) => {
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();

    return <InitialsSpan>{initials}</InitialsSpan>;
};

Initials.propTypes = { name: PropTypes.string.isRequired };

export default Initials;
