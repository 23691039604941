import React, { useCallback, useEffect, useState, useReducer, useMemo } from "react";
import PropTypes from "prop-types";

import { Button, Toggle } from "@ts-digital/vrc";
import { Tooltip } from "antd";
import { Footer, SidebarTitle, Subtitle, Title } from "../styled";
import {
    MassiveActionContainer,
    OverallPermissionsSelect,
    InfoIcon,
    OverallPermissionsSelectLoaderContainer,
    CompanySelectionContainer,
    EditPermissionContainer,
    Container,
    SectionTitle
} from "./styled";

import { TitleAndToggleContainer, ToggleContainer, ToggleLabel } from "./styled";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import RolesTable from "../../user/roles-table";
import LoadingButton from "../../general/loading-button";
import Modal from "../../general/modal";
import ItemList from "./item-list";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectedCompanies from "./selected-items-table";
import {
    buildFormStatus,
    getOverallItemRolesDisabled,
    mapFormToRoles,
    getDefaultValues,
    getHighestRolesFromUpdatedValues,
    getMyRolesValues,
    mapAllSelectedItemsAssignableRoles,
    servicesPermissionSelectIsDisabled,
    applyButtonIsDisabled,
    userIsAdminForThatCompany,
    userIsManagerForThatCompany,
    getPermissionsSelectOptions,
    userIsAdminForAllCompanies,
    userIsManagerForAllCompanies,
    isMassiveCase,
    hasRolesSelected,
    getMassiveInactivePermissions,
    getMassiveServicesRole
} from "../../../utils/roles-mapper";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import LoadingRolesTable from "../../user/loading-roles-table";
import LoaderItem from "../../general/loader-item";

/**
 * Check to show or not confirmModal for massive, "mixed" case with both admin and manager items
 *
 * Modal will show if:
 * item['all'] is present in updatedvalues set ( is massive case )
 * I'm not admin of all companies selected ( mixed case )
 * in the updatedValues item ['all'] has at least one role setted
 * @param {Object} updatedValues
 * @returns
 */
export const showConfirmModal = updatedValues => {
    return !!(
        updatedValues["all"] &&
        !userIsAdminForAllCompanies(updatedValues) &&
        updatedValues["all"].inactivePermissions.some(
            ele => ele.role === "admin" || ele.children.some(child => child.role === "admin")
        )
    );
};

/**
 * Get the roles to be assigned when the 'imposta i miei stessi ruoli' button is clicked
 * @returns
 */
export const setCompanyCurrentRoles = (
    itemId,
    roles,
    newCurrentOverallRoles,
    newCurrentServicesRoles,
    updatedValues
) => {
    let newCompanyCurrentServicesRoles = undefined;
    let newCompanyCurrentOverallRoles = undefined;

    if (userIsAdminForThatCompany(updatedValues, itemId)) {
        newCompanyCurrentOverallRoles = roles.overallPermissions[0].role;
        newCompanyCurrentServicesRoles = roles.overallPermissions[0].role;
    } else if (
        (isMassiveCase(itemId) && userIsManagerForAllCompanies(updatedValues)) ||
        userIsManagerForThatCompany(updatedValues, itemId)
    ) {
        newCompanyCurrentServicesRoles = "manager";
    } else if (
        isMassiveCase(itemId) &&
        !userIsManagerForAllCompanies(updatedValues) &&
        !userIsAdminForAllCompanies(updatedValues)
    ) {
        newCompanyCurrentServicesRoles = "admin";
    } else if (isMassiveCase(itemId) && userIsAdminForAllCompanies(updatedValues)) {
        newCompanyCurrentServicesRoles = "admin";
        newCompanyCurrentOverallRoles = "admin";
    }

    return [
        {
            ...newCurrentOverallRoles,
            [itemId]: newCompanyCurrentOverallRoles
        },
        {
            ...newCurrentServicesRoles,
            [itemId]: newCompanyCurrentServicesRoles
        }
    ];
};

/**
 * Set newPermissionRole as the service or subservice role for a single services on a certain itemId
 * @param {String} newPermissionRole: the new role
 * @param {Object} permission: the old role side data
 * @returns
 */
const setServiceRole = (newPermissionRole, permission) => {
    if (
        (permission.permissions.length &&
            permission.permissions.length > 0 &&
            permission.permissions.includes(newPermissionRole.toUpperCase())) ||
        newPermissionRole === "noRole"
    ) {
        return {
            ...permission,
            role: newPermissionRole
        };
    } else {
        return { ...permission };
    }
};

/**
 * Set newPermissionRole as the service and subservices role for a single services on a certain itemId
 * @param {String} newPermissionRole: the new role
 * @param {Object} permission: the old role side data
 * @returns
 */
const setServiceAndSubservicesRole = (newPermissionRole, permission) => ({
    ...setServiceRole(newPermissionRole, permission),
    children: permission.children.map(subPermission => setServiceRole(newPermissionRole, subPermission))
});

/**
 * Set the new inactive permissions on a certain itemId and subsequently return the new updatedValues.
 * @returns
 */
export const setCompanyInactivePermissions = (currentUpdatedValues, newPermission, itemId, updatedValues) => ({
    ...currentUpdatedValues,
    [itemId]: {
        ...updatedValues[itemId],
        inactivePermissions: updatedValues[itemId].inactivePermissions.map(permission =>
            setServiceAndSubservicesRole(newPermission, permission)
        )
    }
});

const SetPermissions = ({
    assignableRoles,
    comingFromAddRoles,
    comingFromSetRoles,
    goBack,
    onSubmit,
    selectedItems,
    submitting,
    userEmail,
    currentCompanyData,
    isStudio
}) => {
    const [activeItem, setActiveItem] = useState({
        id: currentCompanyData.itemId,
        uuid: currentCompanyData.itemUuid,
        description: currentCompanyData.description,
        workspaceId: currentCompanyData.workspaceId
    });
    const [overallRoles, setOverallRoles] = useState({});
    const [servicesRoles, setServicesRoles] = useState({});
    const [currentOverallRoles, setCurrentOverallRoles] = useState({});
    const [currentServicesRoles, setCurrentServicesRoles] = useState({});
    const [updatedValues, setUpdatedValues] = useState({});
    const [localServices, setLocalServices] = useState({});
    const [propagateOwnRolesMap, updatePropagateOwnRolesMap] = useReducer((current, update) => ({
        ...current,
        ...update
    }));
    const [confirmModalOpened, setConfirmModalOpened] = useState(false);
    const [selectedCompaniesModalOpen, setSelectedCompaniesModalOpen] = useState(false);
    const [selectedCompaniesModal, setSelectedCompaniesModal] = useState([]);
    const [selectedCompaniesModalIds, setSelectedCompaniesModalIds] = useState([]);
    const [selectedServiceData, setSelectedServiceData] = useState({ role: "", serviceName: "" });

    const intl = useIntl();

    const permissionsOptions = updatedValues[activeItem.id]
        ? getPermissionsSelectOptions(updatedValues, activeItem.id)
        : [];

    const overallRolesSelectIsVisible =
        userIsAdminForThatCompany(updatedValues, activeItem.id) ||
        (isMassiveCase(activeItem.id) && userIsAdminForAllCompanies(updatedValues));

    const servicesRolesSelectIsVisible =
        updatedValues[activeItem.id] &&
        updatedValues[activeItem.id].inactivePermissions &&
        updatedValues[activeItem.id].inactivePermissions.length > 0;

    const openSelectedCompaniesModal = (itemIds, role, serviceName) => {
        // imposto la lista degli id degli item da mostrare nella popup
        setSelectedCompaniesModalIds(itemIds);
        // imposto i dati del servizio e ruolo che ho selezionato da mostrare nella popup
        setSelectedServiceData({ role, serviceName });
        // apro la popup
        setSelectedCompaniesModalOpen(true);
    };

    useEffect(() => {
        setSelectedCompaniesModal(selectedItems.filter(ele => selectedCompaniesModalIds.includes(ele.itemId)));
    }, [selectedItems, selectedCompaniesModalIds]);

    useEffect(() => {
        setActiveItem(prev => ({ ...prev, workspaceId: currentCompanyData.workspaceId }));
    }, [currentCompanyData]);

    const resetCurrentRoles = useCallback(
        itemId => {
            if (isMassiveCase(itemId)) {
                Object.keys(currentOverallRoles).forEach(itemId => {
                    currentOverallRoles[itemId] = "multipleRoles";
                });

                Object.keys(currentServicesRoles).forEach(itemId => {
                    currentServicesRoles[itemId] = "multipleRoles";
                });

                setCurrentOverallRoles(currentOverallRoles);
                setCurrentServicesRoles(currentServicesRoles);
            } else {
                setCurrentServicesRoles({ ...currentServicesRoles, [itemId]: "multipleRoles" });

                if (
                    userIsAdminForThatCompany(updatedValues, itemId) ||
                    (isMassiveCase(itemId) && userIsAdminForAllCompanies(updatedValues))
                ) {
                    setCurrentOverallRoles({ ...currentOverallRoles, [itemId]: "multipleRoles" });
                }
            }
        },
        [updatedValues, currentOverallRoles, currentServicesRoles]
    );

    const setMyRolesToUpdatedValues = settingMyRoles => {
        if (!settingMyRoles) {
            // in the case of massive operations I set the global role on all the selected companies by setting the flag to false, I reset the mask
            if (isMassiveCase(activeItem.id)) {
                // massive case I scroll through all the companies
                let newUpdatedValues = {};

                Object.keys(updatedValues).forEach(itemId => {
                    if (itemId !== "all") {
                        newUpdatedValues[itemId] = getDefaultValues(assignableRoles.roles[itemId]);
                        resetCurrentRoles(itemId);
                    }

                    updatePropagateOwnRolesMap({
                        [itemId]: settingMyRoles
                    });
                });
                const uuv = { ...newUpdatedValues };
                delete uuv[currentCompanyData.itemId];
                const allRoles = mapAllSelectedItemsAssignableRoles(uuv, true);

                newUpdatedValues["all"] = allRoles;
                resetCurrentRoles("all");

                setUpdatedValues(newUpdatedValues);
            } else {
                // unitary case work on a single company
                setUpdatedValues({
                    ...updatedValues,
                    [activeItem.id]: getDefaultValues(assignableRoles.roles[activeItem.id])
                });

                resetCurrentRoles(activeItem.id);
            }
        } else {
            let newCurrentServicesRoles = {};
            let newCurrentOverallRoles = {};

            // I'm setting the flag to true, setting the mask with my roles
            if (isMassiveCase(activeItem.id)) {
                // massive case I scroll through all the companies
                let newUpdatedValues = {};

                Object.keys(updatedValues).forEach(itemId => {
                    if (itemId !== "all") {
                        let myRolesValues = getMyRolesValues(assignableRoles.roles[itemId]);
                        newUpdatedValues[itemId] = myRolesValues;
                        [newCurrentOverallRoles, newCurrentServicesRoles] = setCompanyCurrentRoles(
                            itemId,
                            myRolesValues,
                            newCurrentOverallRoles,
                            newCurrentServicesRoles,
                            updatedValues
                        );
                    } else {
                        let highestRolesFromUpdatedValues = getHighestRolesFromUpdatedValues(
                            updatedValues[itemId],
                            true
                        );

                        newUpdatedValues[itemId] = highestRolesFromUpdatedValues;
                        [newCurrentOverallRoles, newCurrentServicesRoles] = setCompanyCurrentRoles(
                            itemId,
                            highestRolesFromUpdatedValues,
                            newCurrentOverallRoles,
                            newCurrentServicesRoles,
                            updatedValues
                        );
                    }

                    updatePropagateOwnRolesMap({
                        [itemId]: settingMyRoles
                    });
                });
                setUpdatedValues(newUpdatedValues);
            } else {
                // unitary case
                const myRolesValues = getMyRolesValues(assignableRoles.roles[activeItem.id]);

                // I'm setting the flag to true, setting the mask with my roles
                setUpdatedValues({
                    ...updatedValues,
                    [activeItem.id]: myRolesValues
                });

                [newCurrentOverallRoles, newCurrentServicesRoles] = setCompanyCurrentRoles(
                    activeItem.id,
                    myRolesValues,
                    newCurrentOverallRoles,
                    newCurrentServicesRoles,
                    updatedValues
                );
            }

            setCurrentOverallRoles({ ...currentOverallRoles, ...newCurrentOverallRoles });
            setCurrentServicesRoles({ ...currentServicesRoles, ...newCurrentServicesRoles });
        }
    };

    const overallRolesSelectValue = () => {
        if (!currentOverallRoles.hasOwnProperty(activeItem.id)) {
            setCurrentOverallRoles({ ...currentOverallRoles, [activeItem.id]: "multipleRoles" });

            return "multipleRoles";
        }

        return currentOverallRoles[activeItem.id];
    };

    const servicesRolesSelectValue = () => {
        if (!currentServicesRoles.hasOwnProperty(activeItem.id)) {
            setCurrentServicesRoles({ ...currentServicesRoles, [activeItem.id]: "multipleRoles" });

            return "multipleRoles";
        }

        return currentServicesRoles[activeItem.id];
    };

    useEffect(() => {
        let newUpdatedValues = {};

        for (const [itemId, appRoles] of Object.entries(assignableRoles.roles)) {
            newUpdatedValues = { ...newUpdatedValues, [itemId]: buildFormStatus(appRoles, {}, true) };
        }

        const uuv = { ...newUpdatedValues };

        delete uuv[currentCompanyData.itemId];

        if (Object.entries(assignableRoles.roles).length > 1) {
            const allRoles = mapAllSelectedItemsAssignableRoles(uuv);
            setUpdatedValues({ ...newUpdatedValues, all: allRoles });
        } else {
            setUpdatedValues({ ...newUpdatedValues });
        }
    }, [assignableRoles, currentCompanyData]);

    const onPermissionsUpdate = useCallback(
        (newInactivePermissions, operation, value, appRole, featureCodeRole) => {
            if (activeItem.id !== "all") {
                resetCurrentRoles(activeItem.id);
                // caso unitario lavoro su azienda singola
                setUpdatedValues(updatedValues => {
                    return {
                        ...updatedValues,
                        [activeItem.id]: {
                            inactivePermissions: newInactivePermissions,
                            activePermissions: [],
                            overallPermissions: [
                                { ...updatedValues[activeItem.id].overallPermissions[0], role: "multipleRoles" }
                            ]
                        }
                    };
                });
            } else {
                let newUpdatedRoles = {};

                // caso massivo le scorro tutte
                if (operation === "check") {
                    // in caso di semplice check del flag flaggo tutte le aziende che hanno quel servizio
                    Object.keys(updatedValues).forEach(itemId => {
                        resetCurrentRoles(itemId);
                        let newInactivePermission = updatedValues[itemId].inactivePermissions;

                        newInactivePermission.forEach(role => {
                            if (role.appId === appRole.appId) {
                                role.toggle = value;
                                if (!value) {
                                    role.role = "noRole";
                                    role.children.forEach(element => {
                                        element.role = "noRole";
                                    });
                                }
                            }
                        });

                        newUpdatedRoles[itemId] = {
                            inactivePermissions: newInactivePermission,
                            activePermissions: [],
                            overallPermissions: updatedValues[itemId].overallPermissions
                        };
                    });
                } else if (operation === "permission") {
                    // in caso di impostazione ruolo, lo imposto su tutte le aziende cel'hanno disponibile
                    Object.keys(updatedValues).forEach(itemId => {
                        resetCurrentRoles(itemId);
                        let newInactivePermission = updatedValues[itemId].inactivePermissions;

                        newInactivePermission.forEach(role => {
                            if (role.appId === appRole.appId) {
                                if (!featureCodeRole.featureCode) {
                                    // imposto ruolo a livello di servizio

                                    // verifico se sia possibile assegnare ruoli per l'elemento che sto analizzando
                                    if (role.permissions.length > 0) {
                                        // per servizi o sottoservizi per quali non sono admin, metto manager
                                        role.role =
                                            value === "admin" && role.permissions.indexOf("ADMIN") === -1
                                                ? "manager"
                                                : value;
                                    }

                                    // per servizi o sottoservizi per quali non sono admin, metto manager
                                    role.children.forEach(element => {
                                        // verifico se sia possibile assegnare ruoli per l'elemento che sto analizzando
                                        if (element.permissions.length > 0) {
                                            element.role =
                                                value === "admin" && element.permissions.indexOf("ADMIN") === -1
                                                    ? "manager"
                                                    : value;
                                        }
                                    });
                                } else {
                                    // imposto ruolo a livello di sotto servizio

                                    // per servizi o sottoservizi per quali non sono admin, metto manager
                                    role.children.forEach(element => {
                                        if (element.featureCode === featureCodeRole.featureCode) {
                                            // verifico se sia possibile assegnare ruoli per l'elemento che sto analizzando
                                            if (element.permissions.length > 0) {
                                                element.role =
                                                    value === "admin" && element.permissions.indexOf("ADMIN") === -1
                                                        ? "manager"
                                                        : value;
                                            }
                                        }
                                    });
                                }
                            }
                        });

                        newUpdatedRoles[itemId] = {
                            inactivePermissions: newInactivePermission,
                            activePermissions: [],
                            overallPermissions: [
                                { ...updatedValues[itemId].overallPermissions[0], role: "multipleRoles" }
                            ]
                        };
                    });
                }

                setUpdatedValues(newUpdatedRoles);
            }
        },
        [activeItem.id, updatedValues, resetCurrentRoles]
    );

    const onComplete = useCallback(() => {
        const rolesAsString = [];
        Object.keys(updatedValues).forEach(element => {
            // escludo l'item particolare 'all' delle azioni massive dalla creazione dell'utente
            if (element !== "all") {
                rolesAsString.push(
                    mapFormToRoles(
                        updatedValues[element],
                        selectedItems.find(i => i.itemId === element).itemUuid,
                        assignableRoles.roles[element]
                    )
                );
            }
        });

        onSubmit(rolesAsString.flat(), localServices);
    }, [onSubmit, selectedItems, updatedValues, assignableRoles, localServices]);

    const setInactivePermissions = () => {
        let newUpdatedValues = updatedValues;
        let newCurrentServicesRoles = { all: currentServicesRoles["all"] };

        if (isMassiveCase(activeItem.id)) {
            Object.keys(updatedValues).forEach(itemId => {
                // Do not set permissions on current studio
                if (itemId === currentCompanyData.itemId) return;

                newCurrentServicesRoles = {
                    ...newCurrentServicesRoles,
                    [itemId]: getMassiveServicesRole(updatedValues, itemId, currentServicesRoles["all"])
                };

                newUpdatedValues = setCompanyInactivePermissions(
                    newUpdatedValues,
                    getMassiveInactivePermissions(updatedValues, itemId, currentServicesRoles["all"]),
                    itemId,
                    updatedValues
                );
            });
        } else {
            newUpdatedValues = setCompanyInactivePermissions(
                newUpdatedValues,
                currentServicesRoles[activeItem.id],
                activeItem.id,
                updatedValues
            );
        }

        setCurrentServicesRoles({ ...currentServicesRoles, ...newCurrentServicesRoles });
        setServicesRoles({ ...servicesRoles, ...newCurrentServicesRoles });
        setUpdatedValues(newUpdatedValues);
    };

    const setOverallPermissions = () => {
        let newUpdatedValues = updatedValues;

        if (isMassiveCase(activeItem.id)) {
            // nel caso delle operazioni massive imposto il ruolo globale su tutte le aziende selezionate
            newUpdatedValues = {};

            let newCurrentOverallRoles = {};
            let newCurrentServicesRoles = {};
            let newOverallRoles = {};

            Object.keys(updatedValues).forEach(itemId => {
                if (itemId !== "all") {
                    newCurrentOverallRoles = {
                        ...newCurrentOverallRoles,
                        [itemId]: currentOverallRoles[activeItem.id]
                    };

                    if (currentOverallRoles[activeItem.id] !== "multipleRoles") {
                        newCurrentServicesRoles = {
                            ...newCurrentServicesRoles,
                            [itemId]: currentOverallRoles[activeItem.id]
                        };
                    }

                    newOverallRoles = { ...newOverallRoles, [itemId]: currentOverallRoles[activeItem.id] };

                    newUpdatedValues = {
                        ...newUpdatedValues,
                        [itemId]: {
                            activePermissions: [],
                            inactivePermissions: updatedValues[itemId].inactivePermissions.map(permission =>
                                setServiceAndSubservicesRole(currentOverallRoles[activeItem.id], permission)
                            ),
                            overallPermissions: updatedValues[itemId].overallPermissions.map(permission =>
                                setServiceRole(currentOverallRoles[activeItem.id], permission)
                            )
                        }
                    };
                }
            });

            setCurrentOverallRoles({ ...currentOverallRoles, ...newCurrentOverallRoles });

            setCurrentServicesRoles({ ...currentServicesRoles, ...newCurrentServicesRoles });
            setOverallRoles({ ...overallRoles, ...newOverallRoles });
        }

        setUpdatedValues({
            ...newUpdatedValues,
            [activeItem.id]: {
                activePermissions: [],
                inactivePermissions: updatedValues[activeItem.id].inactivePermissions.map(permission =>
                    setServiceAndSubservicesRole(currentOverallRoles[activeItem.id], permission)
                ),
                overallPermissions: updatedValues[activeItem.id].overallPermissions.map(permission =>
                    setServiceRole(currentOverallRoles[activeItem.id], permission)
                )
            }
        });
    };

    const resetAppliedRoles = id => {
        delete overallRoles[id];
        delete servicesRoles[id];
    };

    const onChangeCurrentOverallPermission = (role, id = activeItem.id) => {
        resetAppliedRoles(id);
        setCurrentOverallRoles({ ...currentOverallRoles, [id]: role });

        if (role !== "multipleRoles") {
            setCurrentServicesRoles({ ...currentServicesRoles, [id]: role });
        }
    };

    const onChangeCurrentServicesPermission = (role, id = activeItem.id) => {
        resetAppliedRoles(id);
        setCurrentServicesRoles({ ...currentServicesRoles, [id]: role });
    };

    const onClickApplyButton = () => {
        debugger;
        if (
            currentOverallRoles[activeItem.id] !== "multipleRoles" &&
            currentOverallRoles[activeItem.id] !== undefined
        ) {
            setOverallRoles({ ...overallRoles, [activeItem.id]: currentOverallRoles[activeItem.id] });
            setOverallPermissions();
        } else {
            setServicesRoles({ ...servicesRoles, [activeItem.id]: currentServicesRoles[activeItem.id] });
            setInactivePermissions();
        }
    };

    const handleLocalServiceTogleOnChange = () => {
        const map = { ...localServices };

        // If the current selected items is all, set all of the selected items to the correct value except current studio
        if (activeItem.workspaceId === "all") {
            const toggleValue = !isAllLocalServicesToggleActive();
            for (let item of selectedItems) {
                if (item.workspaceId !== currentCompanyData.workspaceId) {
                    map[item.workspaceId] = toggleValue;
                }
            }
        }

        const toggleValue = !map[activeItem.workspaceId];
        map[activeItem.workspaceId] = toggleValue;
        delete map["all"];
        setLocalServices(map);
    };

    const isAllLocalServicesToggleActive = useCallback(() => {
        return selectedItems.every(item => {
            if (item.workspaceId === currentCompanyData.workspaceId) return true;
            return localServices[item.workspaceId];
        });
    }, [localServices, selectedItems, currentCompanyData.workspaceId]);

    const isLocalServicesToggleActive = useMemo(() => {
        if (activeItem.workspaceId === "all") {
            return isAllLocalServicesToggleActive();
        }
        return localServices[activeItem.workspaceId] || false;
    }, [activeItem.workspaceId, localServices, isAllLocalServicesToggleActive]);

    /* verifico che ci sia almeno un ruolo impostato per gli item selezionati */
    const isCompleteDisabled = useCallback(() => {
        return !assignableRoles.status.ended
            ? true
            : !hasRolesSelected(updatedValues, selectedItems, assignableRoles, localServices);
    }, [selectedItems, updatedValues, assignableRoles, localServices]);

    let completeCtaGainsightAttribute = isMassiveCase(activeItem.id)
        ? "user-creation-flow-complete-button-from-item-all"
        : "user-creation-flow-complete-button-from-single-item";

    if (comingFromAddRoles) {
        completeCtaGainsightAttribute = completeCtaGainsightAttribute + "-add-roles";
    } else if (comingFromSetRoles) {
        completeCtaGainsightAttribute = completeCtaGainsightAttribute + "-set-roles";
    }

    return (
        <Container data-cy="set-permissions-step">
            {isStudio ? (
                <CompanySelectionContainer>
                    <div>
                        <SidebarTitle>
                            <FormattedMessage id="v-user-creation-flow.steps.set-permissions.sidebar.title" />
                        </SidebarTitle>
                        <Subtitle>
                            <FormattedHTMLMessage
                                id="v-user-creation-flow.steps.set-permissions.sidebar.subtitle"
                                values={{ email: userEmail }}
                            />
                        </Subtitle>
                    </div>
                    <ItemList
                        activeItem={activeItem}
                        onItemSelected={setActiveItem}
                        selectedItems={selectedItems}
                        currentCompanyData={currentCompanyData}
                    />
                </CompanySelectionContainer>
            ) : null}
            <EditPermissionContainer>
                <TitleAndToggleContainer>
                    <div>
                        <Title data-cy="step-title">
                            <FormattedHTMLMessage
                                id="v-user-creation-flow.steps.set-permissions.title"
                                values={{ itemDescription: activeItem.description }}
                            />
                        </Title>
                        <Subtitle data-cy="step-subtitle">
                            <FormattedMessage id="v-user-creation-flow.steps.set-permissions.subtitle" />
                        </Subtitle>
                    </div>
                    <ToggleContainer>
                        <Toggle
                            checked={(propagateOwnRolesMap && propagateOwnRolesMap[activeItem.id]) || false}
                            onChange={() => {
                                updatePropagateOwnRolesMap({
                                    [activeItem.id]: propagateOwnRolesMap ? !propagateOwnRolesMap[activeItem.id] : true
                                });
                                setMyRolesToUpdatedValues(
                                    propagateOwnRolesMap ? !propagateOwnRolesMap[activeItem.id] : true
                                );
                            }}
                            disabled={assignableRoles.status.started || !updatedValues[activeItem.id]}
                            data-gainsight-track={
                                propagateOwnRolesMap && propagateOwnRolesMap[activeItem.id]
                                    ? "togglemyroles-switch-to-off"
                                    : "togglemyroles-switch-to-on"
                            }
                        />
                        <ToggleLabel>
                            <FormattedMessage id="v-user-creation-flow.steps.set-permissions.toggle.set-my-roles" />
                            <Tooltip
                                placement="bottom"
                                title={
                                    <>
                                        <b>
                                            <FormattedMessage id="v-user-creation-flow.steps.set-permissions.toggle.set-my-roles.tooltip.title" />
                                        </b>
                                        <br />
                                        <br />
                                        <FormattedMessage id="v-user-creation-flow.steps.set-permissions.toggle.set-my-roles.tooltip" />
                                    </>
                                }
                            >
                                <InfoIcon icon={faInfoCircle} />
                            </Tooltip>
                        </ToggleLabel>
                    </ToggleContainer>
                </TitleAndToggleContainer>

                <SectionTitle>
                    {intl.formatMessage({ id: "v-user-creation-flow.steps.set-permissions.connected-services" })}
                </SectionTitle>

                {assignableRoles.status.started || !updatedValues[activeItem.id] ? (
                    <>
                        <MassiveActionContainer>
                            <OverallPermissionsSelectLoaderContainer>
                                <LoaderItem height="24px" width="100%" />
                            </OverallPermissionsSelectLoaderContainer>
                            <Button disabled={true}>
                                <FormattedMessage id={"c-user-roles-table.button.apply"} />
                            </Button>
                        </MassiveActionContainer>
                        <LoadingRolesTable showActivationDateColumn={false} showServiceStatusColumn={false} />
                    </>
                ) : (
                    <>
                        <MassiveActionContainer>
                            {overallRolesSelectIsVisible && (
                                <div>
                                    <div>
                                        <FormattedHTMLMessage id="c-registry-user.choose-roles-on-company" />{" "}
                                        <Tooltip
                                            placement="bottom"
                                            title={
                                                <FormattedHTMLMessage id="c-registry-user.choose-company-role.tooltip" />
                                            }
                                        >
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                        </Tooltip>
                                    </div>
                                    <OverallPermissionsSelect
                                        options={permissionsOptions}
                                        onChange={onChangeCurrentOverallPermission}
                                        value={overallRolesSelectValue()}
                                        disabled={getOverallItemRolesDisabled(
                                            activeItem.id,
                                            propagateOwnRolesMap && propagateOwnRolesMap[activeItem.id],
                                            updatedValues[activeItem.id].overallPermissions[0].permissions
                                        )}
                                    />
                                </div>
                            )}
                            {servicesRolesSelectIsVisible && (
                                <div>
                                    <div>
                                        <FormattedHTMLMessage id="c-registry-user.choose-roles-on-services" />{" "}
                                        <Tooltip
                                            placement="bottom"
                                            title={
                                                <FormattedHTMLMessage id="c-registry-user.choose-service-role.tooltip" />
                                            }
                                        >
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                        </Tooltip>
                                    </div>
                                    <OverallPermissionsSelect
                                        options={permissionsOptions}
                                        onChange={onChangeCurrentServicesPermission}
                                        value={servicesRolesSelectValue()}
                                        disabled={servicesPermissionSelectIsDisabled(
                                            currentOverallRoles[activeItem.id],
                                            propagateOwnRolesMap ? propagateOwnRolesMap[activeItem.id] : false
                                        )}
                                        data-cy="c-test-1"
                                    />
                                </div>
                            )}
                            <Button
                                onClick={onClickApplyButton}
                                data-cy="overall-apply-button"
                                disabled={applyButtonIsDisabled(
                                    currentOverallRoles[activeItem.id],
                                    currentServicesRoles[activeItem.id],
                                    overallRoles.hasOwnProperty(activeItem.id),
                                    servicesRoles.hasOwnProperty(activeItem.id),
                                    propagateOwnRolesMap ? propagateOwnRolesMap[activeItem.id] : false,
                                    userIsAdminForThatCompany(updatedValues, activeItem.id)
                                )}
                            >
                                <FormattedMessage id={"c-user-roles-table.button.apply.to.enabled.services"} />
                            </Button>
                        </MassiveActionContainer>
                        <RolesTable
                            onUpdate={onPermissionsUpdate}
                            totalCompanies={Object.keys(assignableRoles.roles).length}
                            showActivationDateColumn={false}
                            showServiceStatusColumn={false}
                            showCompaniesNumberColumn={isMassiveCase(activeItem.id)}
                            values={updatedValues[activeItem.id].inactivePermissions}
                            myRolesMap={propagateOwnRolesMap ? propagateOwnRolesMap[activeItem.id] : false}
                            openSelectedCompaniesModal={openSelectedCompaniesModal}
                            showManagerDescription={
                                activeItem.id !== "all"
                                    ? userIsManagerForThatCompany(updatedValues, activeItem.id)
                                    : userIsManagerForAllCompanies(updatedValues)
                            }
                        />
                    </>
                )}
                <SectionTitle>
                    {intl.formatMessage({ id: "v-user-creation-flow.steps.set-permissions.local-services" })}
                </SectionTitle>

                <ToggleContainer>
                    <Toggle checked={isLocalServicesToggleActive} onChange={handleLocalServiceTogleOnChange} />
                    <ToggleLabel>
                        {intl.formatMessage({ id: "v-user-creation-flow.steps.set-permissions.local-services" })}
                    </ToggleLabel>
                </ToggleContainer>
            </EditPermissionContainer>

            <Footer>
                <Button
                    disabled={submitting}
                    kind="secondary"
                    style={{ marginRight: 16 }}
                    onClick={goBack}
                    data-cy="go-back-button"
                >
                    <FormattedMessage id="general.goBack" />
                </Button>
                <LoadingButton
                    disabled={isCompleteDisabled()}
                    loading={submitting}
                    onClick={() => {
                        showConfirmModal(updatedValues) ? setConfirmModalOpened(true) : onComplete();
                    }}
                    data-cy="complete-button"
                    data-gainsight-track={completeCtaGainsightAttribute}
                >
                    <FormattedMessage id="general.complete" />
                </LoadingButton>
            </Footer>

            <Modal
                centered={true}
                closable={true}
                visible={confirmModalOpened}
                title={<FormattedMessage id={"v-user-creation-flow.steps.set-permissions.manager-modal.title"} />}
                onCancel={() => setConfirmModalOpened(false)}
                width="400px"
                paddingBottom="30px"
                footer={[
                    <Button key={1} kind="secondary" onClick={() => setConfirmModalOpened(false)}>
                        <FormattedMessage
                            id={"v-user-creation-flow.steps.set-permissions.manager-modal.back-to-edit"}
                        />
                    </Button>,
                    <LoadingButton
                        key={2}
                        loading={submitting}
                        dataGainsightTrack={"create-user-modal-confirm"}
                        onClick={() => {
                            onComplete();
                        }}
                    >
                        <FormattedMessage id={"v-user-creation-flow.steps.set-permissions.manager-modal.confirm"} />
                    </LoadingButton>
                ]}
            >
                <FormattedMessage
                    id={"v-user-creation-flow.steps.set-permissions.manager-modal.text"}
                    values={{ email: userEmail }}
                />
            </Modal>

            <Modal
                centered={true}
                closable={true}
                visible={selectedCompaniesModalOpen}
                title={<FormattedMessage id={"v-user-creation-flow.steps.set-permissions.selected-modal.title"} />}
                onCancel={() => setSelectedCompaniesModalOpen(false)}
                width="600px"
                footer={[
                    <Button key={1} kind="primary" onClick={() => setSelectedCompaniesModalOpen(false)}>
                        <FormattedMessage
                            id={"v-user-creation-flow.steps.set-permissions.selected-modal.back-to-edit"}
                        />
                    </Button>
                ]}
            >
                <>
                    <FormattedMessage
                        id={
                            selectedServiceData.role && selectedServiceData.role !== ""
                                ? "v-user-creation-flow.steps.set-permissions.selected-modal.text"
                                : "v-user-creation-flow.steps.set-permissions.selected-modal.text.no-role"
                        }
                        values={{ service: selectedServiceData.serviceName, role: selectedServiceData.role }}
                    />
                    <SelectedCompanies
                        selectedItems={selectedCompaniesModal}
                        totalCompanies={Object.keys(assignableRoles.roles).length - 1}
                    />
                </>
            </Modal>
        </Container>
    );
};

SetPermissions.propTypes = {
    assignableRoles: PropTypes.shape({
        call: PropTypes.func.isRequired,
        roles: PropTypes.objectOf(
            PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    appId: PropTypes.string,
                    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
                    children: PropTypes.arrayOf(
                        PropTypes.shape({
                            name: PropTypes.string.isRequired,
                            appId: PropTypes.string,
                            featureCode: PropTypes.string.isRequired,
                            permissions: PropTypes.arrayOf(PropTypes.string).isRequired
                        })
                    )
                })
            )
        ).isRequired,
        status: PropTypes.shape({
            started: PropTypes.bool.isRequired,
            ended: PropTypes.bool.isRequired,
            error: PropTypes.bool.isRequired
        }).isRequired
    }).isRequired,
    onSubmit: PropTypes.func,
    selectedItems: PropTypes.arrayOf(
        PropTypes.shape({
            classifier: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            itemId: PropTypes.string.isRequired,
            itemUuid: PropTypes.string.isRequired,
            taxId: PropTypes.string.isRequired,
            vatNumber: PropTypes.string.isRequired
        })
    ).isRequired,
    submitting: PropTypes.bool,
    userEmail: PropTypes.string
};

export default SetPermissions;
