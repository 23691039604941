import { Layout } from "antd";
import React, { useCallback } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router";
import { logout } from "../../../actions/auth";
import { getNotificationsList, getNotificationsStatus } from "../../../actions/ncs/read";
import {
    deleteNotificationsList,
    deleteSingleNotification,
    updateNotificationsList,
    updateSingleNotification
} from "../../../actions/ncs/write";
import { copyTokenToast } from "../../../actions/toastNotifications";
import { loadUser } from "../../../actions/user";
import Logo from "../../../components/general/logo";
import Header from "../../../components/header";
import ManageData from "../../../components/manage-data";
import { isUserWithTooManyItems } from "../../../utils/get-roles";

export const ItemSelectionView = ({
    auth,
    companies,
    getNotificationsList,
    deleteNotificationsList,
    deleteSingleNotification,
    getNotificationsStatus,
    logout,
    notificationsExist,
    notificationsList,
    notificationsListStatus,
    updateNotificationsList,
    updateSingleNotification,
    user
}) => {
    const history = useHistory();

    const handleGetNotificationsExist = useCallback(
        () => getNotificationsStatus(user.user.profile.ncsId),
        [getNotificationsStatus, user.user.profile.ncsId]
    );

    return (
        <Layout className="Root">
            <Layout.Header className="Root-header">
                <div className={"Root-header-text-container"}>
                    <Logo white={true} height={30} />
                </div>
                <Header
                    auth={auth}
                    onNotificationAccept={() => {}}
                    user={user}
                    logout={logout}
                    resetCompanyLoad={null}
                    notifications={[]}
                    notificationsExist={notificationsExist}
                    notificationsList={notificationsList}
                    notificationsListStatus={notificationsListStatus}
                    deleteSingleNotification={notificationId =>
                        deleteSingleNotification(user.user.profile.ncsId, notificationId)
                    }
                    deleteNotificationsList={() => deleteNotificationsList(user.user.profile.ncsId)}
                    getNotifications={() => getNotificationsList(user.user.profile.ncsId)}
                    getNotificationsExist={handleGetNotificationsExist}
                    updateNotificationsList={request => updateNotificationsList(user.user.profile.ncsId, request)}
                    updateSingleNotification={(notificationId, request) =>
                        updateSingleNotification(user.user.profile.ncsId, notificationId, request)
                    }
                    editUser={() => {}}
                    onNotificationReject={() => {}}
                    onReadNotification={() => {}}
                    onUnreadNotification={() => {}}
                    unreadNotifications={0}
                    showTour={false}
                    showNotification={false}
                    loadUser={loadUser}
                    copyTokenToast={copyTokenToast}
                    userEditStatus={{}}
                    updateUserPswStatus={{}}
                    updateUserPsw={() => {}}
                />
            </Layout.Header>
            <Layout.Content className="Root-content no-overflow">
                <ManageData
                    auth={auth}
                    companies={companies}
                    history={history}
                    isUserWithTooManyItems={isUserWithTooManyItems(user.user.roles)}
                    logout={logout}
                    user={user}
                />
            </Layout.Content>
        </Layout>
    );
};

const mapStateToProps = state => ({
    auth: state.auth,
    companies: state.companies,
    notificationsExist: state.ncs.notifications.exist,
    notificationsList: state.ncs.notifications.data,
    notificationsListStatus: state.ncs.notifications.status,
    user: state.user
});

const actions = {
    copyTokenToast,
    deleteSingleNotification,
    deleteNotificationsList,
    getNotificationsList,
    getNotificationsStatus,
    updateSingleNotification,
    updateNotificationsList,
    loadUser,
    logout
};

export default connect(mapStateToProps, actions)(ItemSelectionView);
