import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import { ActiveStatusDot } from "../styled";
import { Container } from "./styled";

export const NewCctServiceRow = ({ isItemProvisioned, rowTitle, name, specs }) => {
    return (
        <Container>
            <ActiveStatusDot active={specs && name.some(n => specs[n] && specs[n].active) && isItemProvisioned} />
            <FormattedMessage id={rowTitle} />
        </Container>
    );
};

NewCctServiceRow.propTypes = {
    isItemProvisioned: PropTypes.bool,
    rowTitle: PropTypes.string,
    name: PropTypes.arrayOf(PropTypes.string),
    specs: PropTypes.object
};
