import styled from "styled-components";
import { Button as ArcButton } from "@ts-digital/arc/antd/Button";

import { default as UnstyledLoadingButton } from "../../loading-button";

export const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-right: 100px;
`;

export const Button = styled(ArcButton)`
    width: 100px;
    margin: 0px 10px;
`;

export const LoadingButton = styled(UnstyledLoadingButton)`
    min-width: 100px;
    margin: 0px 10px;
`;
