import React, { forwardRef, useEffect } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks, faTrashAlt, faRedo, faCog } from "@fortawesome/pro-regular-svg-icons";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Drawer, Tooltip } from "antd";

import EmptyNotification from "./components/empty-notification";
import SingleNotification from "./components/single-notification";
import { Header, HeaderButton, NotificationsContainer, NotificationsContent } from "./styled";
import LoadingNotification from "./components/loading-notification";

const NotificationDrawer = forwardRef(
    (
        {
            deleteNotificationsList,
            deleteSingleNotification,
            getNotifications,
            notifications = [],
            notificationsCenterLink,
            notificationsListStatus,
            updateNotificationsList,
            updateSingleNotification,
            show = false,
            onClose
        },
        ref
    ) => {
        useEffect(() => {
            if (show) {
                getNotifications();
            }
        }, [show, getNotifications]);

        return (
            <Drawer
                placement="right"
                visible={show}
                size="large"
                closable={false}
                onClose={onClose}
                bodyStyle={{ padding: "0" }}
                width="526px"
            >
                <Header>
                    <div>
                        <FormattedMessage id="general.notifications" /> ({notifications.length})
                    </div>
                    <div>
                        <Tooltip placement="bottom" title={<FormattedMessage id="mark-all-as-read" />}>
                            <HeaderButton
                                kind="tertiary"
                                size="small"
                                disabled={notifications.length > 0 && !notifications.find(n => n.read === false)}
                                onClick={() => updateNotificationsList({ read: true })}
                            >
                                <FontAwesomeIcon icon={faTasks} />
                            </HeaderButton>
                        </Tooltip>
                        <Tooltip placement="bottom" title={<FormattedMessage id="remove-all" />}>
                            <HeaderButton
                                kind="tertiary"
                                size="small"
                                disabled={notifications.length === 0}
                                onClick={() => deleteNotificationsList()}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </HeaderButton>
                        </Tooltip>
                        <Tooltip placement="bottom" title={<FormattedMessage id="general.update" />}>
                            <HeaderButton kind="tertiary" size="small" onClick={() => getNotifications()}>
                                <FontAwesomeIcon icon={faRedo} />
                            </HeaderButton>
                        </Tooltip>
                        <Tooltip
                            placement="bottomRight"
                            arrowPointAtCenter={true}
                            title={<FormattedMessage id="notifications-settings" />}
                        >
                            <Link to={notificationsCenterLink} onClick={() => onClose()}>
                                <HeaderButton kind="tertiary" size="small">
                                    <FontAwesomeIcon icon={faCog} />
                                </HeaderButton>
                            </Link>
                        </Tooltip>
                    </div>
                </Header>
                <NotificationsContent>
                    {notifications.length ? (
                        <NotificationsContainer>
                            {notifications.map(notification => (
                                <SingleNotification
                                    key={notification.id}
                                    title={notification.notification.title}
                                    message={notification.notification.message}
                                    date={notification.createdDate}
                                    note={notification.note}
                                    priority={notification.priority}
                                    read={notification.read}
                                    id={notification.id}
                                    updateSingleNotification={updateSingleNotification}
                                    deleteSingleNotification={deleteSingleNotification}
                                />
                            ))}
                        </NotificationsContainer>
                    ) : (
                        <EmptyNotification />
                    )}
                    {notificationsListStatus.started && <LoadingNotification />}
                </NotificationsContent>
            </Drawer>
        );
    }
);

NotificationDrawer.defaultProps = {
    notificationsListStatus: { started: false }
};

NotificationDrawer.propTypes = {
    notifications: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            message: PropTypes.string,
            date: PropTypes.string,
            note: PropTypes.string,
            priority: PropTypes.oneOf(["LOW", "MEDIUM", "HIGH"]),
            read: PropTypes.bool,
            id: PropTypes.string
        })
    ),
    notificationsCenterLink: PropTypes.string.isRequired,
    notificationsListStatus: PropTypes.object,
    getNotifications: PropTypes.func,
    deleteNotificationsList: PropTypes.func,
    deleteSingleNotification: PropTypes.func,
    handleConfigureLinkClick: PropTypes.func,
    setNotificationsAsRead: PropTypes.func,
    show: PropTypes.bool
};

export default NotificationDrawer;
