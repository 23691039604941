import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";

import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const LoadingContainer = styled.div`
    height: 100%;
    width: 100%;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LoadingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const LoadingText = styled.div`
    color: rgb(21, 41, 53);
    font-size: 14px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 24px;
`;

const LoadingIcon = styled.div`
    width: 48px;
    height: 48px;
    color: rgb(0, 144, 209);
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LoadingNotification = () => (
    <LoadingContainer>
        <LoadingWrapper>
            <LoadingIcon>
                <FontAwesomeIcon icon={faCircleNotch} className="fa-spin fa-pulse" />
            </LoadingIcon>
            <LoadingText>
                <FormattedMessage id="notifications-web-loading" />
            </LoadingText>
        </LoadingWrapper>
    </LoadingContainer>
);

export default LoadingNotification;
