import React, { Component } from "react";
import PropTypes from "prop-types";

import { Modal } from "antd";
import "./style.css";
import EditUserData from "./edit";

class User extends Component {
    static propTypes = {
        auth: PropTypes.object.isRequired,
        editUser: PropTypes.func.isRequired,
        handleClose: PropTypes.func.isRequired,
        isOpen: PropTypes.bool.isRequired,
        loadUser: PropTypes.func.isRequired,
        updateUserPsw: PropTypes.func.isRequired,
        updateUserPswStatus: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        userEditStatus: PropTypes.object.isRequired
    };

    renderModal() {
        const {
            auth,
            isOpen,
            loadUser,
            handleClose,
            editUser,
            updateUserPsw,
            updateUserPswStatus,
            user,
            userEditStatus
        } = this.props;
        return (
            <div>
                <Modal
                    footer={null}
                    onCancel={handleClose}
                    width={"650px"}
                    title="Modifica dati utente"
                    visible={isOpen}
                >
                    <EditUserData
                        auth={auth}
                        editUser={editUser}
                        loadUser={loadUser}
                        updateUserPsw={updateUserPsw}
                        updateUserPswStatus={updateUserPswStatus}
                        user={user}
                        userEditStatus={userEditStatus}
                    />
                </Modal>
            </div>
        );
    }

    render() {
        return <div>{this.renderModal()}</div>;
    }
}

export default User;
