import styled from "styled-components";

export const FormContainer = styled.div`
    background-color: #fff;
    padding: 30px 170px;
`;

export const FormSeparator = styled.div`
    background: ${props => props.theme.colors.background.azureishWhite};
    height: 1px;
    margin-top: 24px;
    margin-bottom: 24px;
`;

export const FormTitle = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-family: Cairo;
    font-size: ${props => (props.size === "l" ? "24px" : "20px")};
    font-weight: ${props => (props.size === "l" ? "600" : "400")};
    height: 32px;
    letter-spacing: 0.18px;
    line-height: 32px;
    margin-bottom: 10px;
`;

export const SubTitle = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0.25px;
    line-height: 24px;
    margin-bottom: 24px;
`;

export const DataContainer = styled.div`
    display: flex;
    height: 95px;

    & > * {
        margin-right: 24px;
    }

    & > button {
        margin-top: 31px;
    }
`;

export const InputContainer = styled.div`
    width: 300px;
`;

export const WarningContainer = styled.div`
    max-width: 750px;
    border: 1px solid ${props => props.theme.colors.status.crayola};
    background-color: #fff;
    border-bottom: 5px solid ${props => props.theme.colors.status.crayola};
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
    margin-top: 24px;
    font-size: 24px;
`;

export const MessageContainer = styled.span`
    margin-left: 25px;
    margin-right: 30px;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.33;
`;

export const MessageUriUnderline = styled.a`
    text-decoration: underline;
`;

export const TsUserContainer = styled.div`
    max-width: 750px;
    background-color: ${props => props.theme.colors.background.whiteSmoke};
    padding: 15px;
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
`;

export const TsUserProfileContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    line-height: 1.33;
`;

export const TsUserProfileFullname = styled.span`
    font-weight: 500;
`;

export const TsUserProfileMail = styled.span`
    color: ${props => props.theme.colors.text.cadet};
    font-weight: normal;
`;

export const TsDescriptionContainer = styled.div`
    max-width: 750px;
    margin-bottom: 24px;
    font-weight: normal;
`;
