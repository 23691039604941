import styled from "styled-components";
import { Upload } from "antd";

export const ErrorMessageContainer = styled.div`
    color: ${props => props.theme.colors.status.maximumRed};
    display: flex;
    padding-top: 5px;
`;

export const UploadFileButtonWrapper = styled.div`
    margin-top: 8px;
    margin-bottom: 8px;
`;

export const CustomUpload = styled(Upload)`
    .ant-upload-list-item:hover .ant-upload-list-item-info {
        background-color: ${props => props.theme.colors.background.whiteSmoke};
    }

    .ant-upload-list-item-card-actions > a > i {
        color: ${props => props.theme.colors.status.maximumRed};
    }
`;
