import React from "react";
import PropTypes from "prop-types";

import { includes } from "ramda";
import { FormattedMessage } from "react-intl";

import ServiceCard from "../../components/general/service-card";
import { ServiceCardsContainer } from "../general/service-card/styled";

const InvoiceTrading = ({
    company,
    services,
    getWaitingList,
    getWaitingListStatus,
    subscribeWaitinglist,
    deleteWaitinglist,
    waitinglistDeleteStatus,
    waitinglist,
    waitinglistSubscribeStatus,
    user
}) => {
    return (
        services && (
            <ServiceCardsContainer expanded={false}>
                <ServiceCard
                    disable={includes("EIT", services)}
                    ribbonActive={includes("EIT", services)}
                    ribbon={{
                        text: <FormattedMessage id="general.coming-soon" />,
                        color: "grey"
                    }}
                    packageInfo={{
                        active: includes("EIT", services.active.services),
                        title: <FormattedMessage id="general.buy-package" />,
                        text: <FormattedMessage id="general.contact-to-buy" />
                    }}
                    button={{ active: false }}
                    display={true}
                    expanded={false}
                    title={<FormattedMessage id="c-services-view.EIT.name" />}
                    description={<FormattedMessage id="c-invoice-trading.description" />}
                    category={{
                        title: <FormattedMessage id="c-services-view.EIT.name" />
                    }}
                    serviceInfo={services.agyoServices.services.filter(ele => ele.id === "EIT")[0]}
                    companyId={company.base.id}
                    getWaitingList={getWaitingList}
                    getWaitingListStatus={getWaitingListStatus}
                    waitinglist={waitinglist}
                    subscribeWaitinglist={subscribeWaitinglist}
                    deleteWaitinglist={deleteWaitinglist}
                    waitinglistDeleteStatus={waitinglistDeleteStatus}
                    waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                    user={user}
                />
            </ServiceCardsContainer>
        )
    );
};

InvoiceTrading.propTypes = {
    services: PropTypes.object
};

export default InvoiceTrading;
