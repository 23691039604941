import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Title = styled.div`
    color: rgb(0, 80, 117);
    font-family: Cairo;
    font-size: 34px;
    font-weight: 600;
    line-height: 40px;
`;

export const Description = styled.div`
    color: rgb(21, 41, 53);
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 24px;
`;

export const TitleContainer = styled.div`
    display: table;
`;

export const TitleColumn = styled.div`
    display: table-cell;
    padding-left: 20px;

    vertical-align: middle;
`;

export const TitleRow = styled.div`
    display: table-row;
`;

export const StatusIcon = styled(FontAwesomeIcon)`
    color: ${props => (props.active === "true" ? "rgb(0, 170, 0)" : "rgb(253, 185, 39)")};
    font-size: 16px;
    text-align: center;
    width: 40px;
    margin-right: 8px;
    margin-top: ${props => props.margintop};
`;

export const StatusContainer = styled.div`
    margin-top: 15px;
`;

export const Divider = styled.div`
    background: rgb(201, 217, 232);
    border-radius: 0px;
    height: 1px;
    margin: 24px;
`;

export const CompanyDescriptionContainer = styled.div`
    margin-left: 24px;
`;

export const CompanyDescription = styled.div`
    color: rgb(0, 80, 117);
    font-family: Cairo;
    font-size: 20px;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0.15px;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const EditButtonContainer = styled.div`
    margin-top: 24px;
    padding-right: 24px;
    text-align: right;
`;
