import React from "react";
import PropTypes from "prop-types";

import { includes } from "ramda";
import { useIntl } from "react-intl";

import ServiceCard from "../../components/general/service-card";
import { Container } from "./styled";

const Budget = ({
    company,
    services,
    getWaitingList,
    getWaitingListStatus,
    subscribeWaitinglist,
    deleteWaitinglist,
    waitinglistDeleteStatus,
    waitinglist,
    waitinglistSubscribeStatus,
    user
}) => {
    const { formatMessage } = useIntl();

    return (
        services && (
            <Container>
                <ServiceCard
                    disable={includes("BDG", services)}
                    ribbonActive={includes("BDG", services)}
                    ribbon={{
                        text: formatMessage({
                            id: "general.coming-soon"
                        }),
                        color: "grey"
                    }}
                    packageInfo={{
                        active: includes("BDG", services.active.services),
                        title: formatMessage({
                            id: "general.buy-package"
                        }),
                        text: formatMessage({
                            id: "general.contact-to-buy"
                        })
                    }}
                    button={{ active: false }}
                    display={true}
                    expanded={false}
                    title={formatMessage({
                        id: "c-services-view.BDG.name"
                    })}
                    description={formatMessage({
                        id: "c-services-view.BDG.name"
                    })}
                    category={{
                        title: formatMessage({
                            id: "c-services-view.BDG.name"
                        }),
                        color: "#607d8b",
                        icon: "fas fa-key"
                    }}
                    serviceInfo={services.agyoServices.services.filter(ele => ele.id === "BDG")[0]}
                    companyId={company.base.id}
                    getWaitingList={getWaitingList}
                    getWaitingListStatus={getWaitingListStatus}
                    waitinglist={waitinglist}
                    subscribeWaitinglist={subscribeWaitinglist}
                    deleteWaitinglist={deleteWaitinglist}
                    waitinglistDeleteStatus={waitinglistDeleteStatus}
                    waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                    user={user}
                />
            </Container>
        )
    );
};

Budget.propTypes = {
    services: PropTypes.object
};

export default Budget;
