import React, { useCallback, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { submit } from "redux-form";

import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getCctConfig, verifyCCTcredentials } from "../../../../actions/services/cct";
import { fetchCctProvisioningInfo } from "../../../../actions/services/cct/provisioning";
import { Spinner } from "../../../../components/general/spinner";
import EditCct from "../../../../components/storage/cct";
import { mergeStatus } from "../../../../utils/state-utils/get-merged-status";
import PageTitle from "../../../../components/general/page-title";
import { Container } from "./styled";
import { getItemApps } from "../../../../actions/services/app";
import { showHowtoVideo } from "../../../../actions/gainsightEvents";
import { useFeature } from "../../../../hooks/use-feature";

const EditCctView = ({
    isCctActive,
    cctConfig,
    company,
    configError,
    configLoaded,
    configLoading,
    credentialsStatus,
    fetchCctProvisioningInfo,
    getCctConfig,
    getItemApps,
    history,
    itemApps,
    match,
    provisioningInfo,
    verifyCCTcredentials,
    user,
    showHowtoVideo,
    submit
}) => {
    const isCctHowToVideoEnabled = useFeature("IS_CCT_HOW_TO_VIDEO_ENABLED");

    const itemId = match.params.item;

    const handleCredentialsChanged = useCallback(
        (cctUser, cctPass) => {
            verifyCCTcredentials(cctUser, cctPass, itemId, itemId);
        },
        [itemId, verifyCCTcredentials]
    );

    useEffect(() => {
        !itemApps.status.ended && !itemApps.status.started && getItemApps(itemId, user.profile.id);
    }, [itemId, itemApps, getItemApps, user]);

    useEffect(() => {
        if (!configLoading && !configLoaded && !configError && isCctActive) {
            getCctConfig(itemId, itemId);
            fetchCctProvisioningInfo(itemId);
        }
    }, [configLoaded, configLoading, configError, fetchCctProvisioningInfo, getCctConfig, itemId, isCctActive]);

    return (
        <Spinner loading={configLoading || provisioningInfo.isLoading}>
            <PageTitle
                title={<FormattedMessage id="c-services-view.ARC.name" />}
                onBack={() => history.goBack()}
                onRightButtonClick={isCctHowToVideoEnabled && showHowtoVideo}
                rightButtonTitle={<FormattedMessage id="general.watch-video" />}
                rightButtonKind="tertiary"
                rightButtonIcon={<FontAwesomeIcon icon={faPlayCircle} />}
            />
            <Container>
                <EditCct
                    companyStatus={mergeStatus(company.base.status)}
                    credentialsChangeLoading={credentialsStatus.started}
                    credentialsChangeError={credentialsStatus.error}
                    itemType={company.base.details.classifier}
                    setCredentialActive={false}
                    cctConfig={cctConfig}
                    company={itemId}
                    onCredentialsChanged={handleCredentialsChanged}
                    provisioningInfo={provisioningInfo.provisioningInfo[itemId]}
                    itemApps={itemApps}
                    submitForm={submit}
                />
            </Container>
        </Spinner>
    );
};

const mapStateToProps = (state, props) => ({
    cctConfig: state.services.cct.getCctConfig,
    configError: state.services.cct.getCctConfig.status.error,
    configLoading: state.services.cct.getCctConfig.status.started,
    configLoaded: state.services.cct.getCctConfig.status.ended,
    isCctActive: state.services.active.services.includes("CCT-FLOW") && !state.services.active.services.includes("ARC"),
    provisioningInfo: state.services.cct.provisioningInfo,
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    credentialsStatus: state.services.cct.verifyCct.status,
    itemApps: state.services.itemApps,
    user: state.user.user
});

const actions = {
    getCctConfig,
    fetchCctProvisioningInfo,
    verifyCCTcredentials,
    getItemApps,
    showHowtoVideo,
    submit
};

export default connect(mapStateToProps, actions)(EditCctView);
