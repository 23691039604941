import styled from "styled-components";

export const Copyright = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 0.875rem;
    font-family: Roboto;
    font-weight: normal;
    margin-top: auto;
    letter-spacing: 0.47px;
    line-height: 0.75rem;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    width: 100%;
`;

export const A = styled.a`
    color: ${props => props.theme.colors.primary.richElectricBlue};
    font-size: 0.875rem;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0px;
`;
