export function getTags(services) {
    let tags = [];

    // recupero i tags in base alla appDescription per ogni servizio attivo
    if (services) {
        const activeServices = services.active.services;

        activeServices.forEach(ele => {
            const service = services.agyoServices.services.find(serv => serv.id === ele);
            if (service) {
                tags.push(service.appDescription.toLowerCase().replace(/\s/g, ""));
            }
        });

        tags = [...new Set(tags)];
    } else {
        tags = ["portale"];
    }

    return tags;
}
