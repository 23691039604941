export const UPDATED_VERSION = "UPDATED_VERSION";
export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const COPY_AUTH_TOKEN_SUCCESS = "COPY_AUTH_TOKEN_SUCCESS";
export const SERVICE_ACTIVE_SUCCESS = "SERVICE_ACTIVE_SUCCESS";
export const SERVICE_DISABLE_SUCCESS = "SERVICE_DISABLE_SUCCESS";
export const CCT_CREDENTIALS_EXPIRATION_NOTIFICATION = "CCT_CREDENTIALS_EXPIRATION_NOTIFICATION";
export const ENABLE_CCT_CONFIG_MANAGED_SUCCESS = "ENABLE_CCT_CONFIG_MANAGED_SUCCESS";
export const DISABLE_CCT_CONFIG_MANAGED_SUCCESS = "DISABLE_CCT_CONFIG_MANAGED_SUCCESS";
export const TSPAY_CONNECTION_REQUEST_SUCCESS = "TSPAY_CONNECTION_REQUEST_SUCCESS";
export const CCT_SMART_INITIALIZED = "CCT_SMART_INITIALIZED";
export const CCT_SMART_ERROR = "CCT_SMART_ERROR";

export function updatedVersion() {
    return {
        type: UPDATED_VERSION
    };
}

export function dismissNotification(id) {
    return {
        type: DISMISS_NOTIFICATION,
        payload: id
    };
}

export function forgotPasswordToast() {
    return {
        type: FORGOT_PASSWORD_SUCCESS
    };
}
export function copyTokenToast() {
    return {
        type: COPY_AUTH_TOKEN_SUCCESS
    };
}

export function serviceActiveToast(service) {
    return {
        type: SERVICE_ACTIVE_SUCCESS,
        payload: { service: service }
    };
}

export function serviceDisableToast(service) {
    return {
        type: SERVICE_DISABLE_SUCCESS,
        payload: { service: service }
    };
}

export function enableCctConfigManagedToast(service) {
    return {
        type: ENABLE_CCT_CONFIG_MANAGED_SUCCESS,
        payload: { service: service }
    };
}

export function disableCctConfigManagedToast(service) {
    return {
        type: DISABLE_CCT_CONFIG_MANAGED_SUCCESS,
        payload: { service: service }
    };
}

export function expiredCctCredentialsToast(expirationDate) {
    return {
        type: CCT_CREDENTIALS_EXPIRATION_NOTIFICATION,
        payload: { expirationDate: expirationDate }
    };
}

export function smartCctInitialized() {
    return {
        type: CCT_SMART_INITIALIZED
    };
}

export function smartCctError() {
    return {
        type: CCT_SMART_ERROR
    };
}
