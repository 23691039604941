import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { Table, Pagination } from "antd";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { getBillingUsageStatistics } from "../../../../actions/metering/billing/statistics";
import { Details } from "./details";
import { getBillingUsageStatisticsCsv } from "../../../../actions/metering/billing/statistics/csv";
import { FooterContainer } from "./styled";
import { Button } from "@ts-digital/arc/antd";
import { withRouter } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";

const columns = [
    {
        title: <FormattedMessage id="metering.billing.table.sender" />,
        dataIndex: "company",
        key: "company"
    },
    {
        title: <FormattedMessage id="metering.billing.table.issued" />,
        dataIndex: "sent",
        key: "sent",
        sorter: true,
        render: value => <FormattedNumber value={value} />
    },
    {
        title: <FormattedMessage id="metering.billing.table.received" />,
        dataIndex: "received",
        key: "received",
        sorter: true,
        render: value => <FormattedNumber value={value} />
    },
    {
        title: <FormattedMessage id="metering.billing.table.totalConsumption" />,
        dataIndex: "total",
        key: "total",
        sorter: true
    }
];

export const BasicHistory = ({
    getBillingUsageStatistics,
    itemId,
    userId,
    from,
    to,
    filteredItem,
    viewOldBillingCycles,
    getBillingUsageStatisticsCsv,
    data,
    loading,
    size
}) => {
    const [sortBy, setSortBy] = useState({
        key: "",
        order: ""
    });
    const [page, setPage] = useState(0);

    useEffect(() => {
        getBillingUsageStatistics(itemId, userId, page, 10, from, to, filteredItem, sortBy, viewOldBillingCycles);
    }, [filteredItem, from, getBillingUsageStatistics, itemId, page, sortBy, to, userId, viewOldBillingCycles]);

    const renderExpandedRow = useCallback(
        record => (
            <Details
                item={itemId}
                managed={record.key}
                from={from}
                to={to}
                userId={userId}
                viewOldBillingCycles={viewOldBillingCycles}
            />
        ),
        [from, itemId, to, userId, viewOldBillingCycles]
    );

    const handlePageChange = useCallback((newPage, itemsPerPage) => {
        setPage(newPage - 1);
    }, []);

    const handleTableChange = useCallback(
        (pagination, filters, sorter) => {
            const { columnKey, order } = sorter;
            if (sortBy && sortBy.key === columnKey && sortBy.order === order) {
                return;
            }
            setSortBy(columnKey && order ? { key: columnKey, order } : { key: "", order: "" });
        },
        [sortBy]
    );

    const handleCsvDownload = useCallback(() => {
        getBillingUsageStatisticsCsv(userId, itemId, from, to, viewOldBillingCycles);
    }, [from, getBillingUsageStatisticsCsv, itemId, to, userId, viewOldBillingCycles]);

    const renderFooter = useCallback(
        () => (
            <FooterContainer>
                <Button variant="secondary" onClick={handleCsvDownload} disabled={!size}>
                    <FormattedMessage id="metering.billing.history.csv.download" />
                </Button>
                <Pagination pageSize={10} total={size} onChange={handlePageChange} />
            </FooterContainer>
        ),
        [handleCsvDownload, handlePageChange, size]
    );

    return (
        <Table
            bordered
            columns={columns}
            dataSource={data}
            loading={loading}
            expandedRowRender={renderExpandedRow}
            onChange={handleTableChange}
            locale={{
                emptyText: <FormattedMessage id="metering.usage.billing.table.empty" />
            }}
            footer={renderFooter}
            pagination={false}
        />
    );
};

BasicHistory.propTypes = {
    data: PropTypes.array.isRequired,
    getBillingUsageStatistics: PropTypes.func.isRequired,
    getBillingUsageStatisticsCsv: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    filteredItem: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    viewOldBillingCycles: PropTypes.bool.isRequired
};

const mapStateToProps = (state, props) => ({
    itemId: props.match.params.item,
    userId: state.user.user.profile.id,
    data: state.metering.billing.statistics.data,
    size: state.metering.billing.statistics.size,
    loading: state.metering.billing.statistics.status.loading
});

const enhanceComponent = compose(
    withRouter,
    connect(mapStateToProps, { getBillingUsageStatistics, getBillingUsageStatisticsCsv })
);

export const History = enhanceComponent(BasicHistory);
