/**
 * Extracts all active services for the specified
 * item from the redux state.
 * If the item is not present in the state, an
 * empty array is returned.
 * @param {object} state The redux state
 * @param {string} itemId Id of the item
 */
export const getItemServices = (state, itemId) => {
    const itemServicesList = state.services.servicesSubscription.itemServices.services;

    if (itemServicesList[itemId]) {
        return itemServicesList[itemId];
    } else {
        return [];
    }
};

/**
 * Extracts data about the specified service for the specified
 * item from the redux state.
 * If either the item or the service are not present in the state,
 * the specified default value is returned.
 * @param {object} state The redux state
 * @param {string} itemId Id of the item
 * @param {serviceId} serviceId Id of the service
 * @param {*} def Default value to be returned on unexistent item or service
 */
export const getItemService = (state, itemId, serviceId, def = undefined) => {
    const itemServicesList = state.services.servicesSubscription.itemServices.services;

    let service;
    if (itemServicesList[itemId]) {
        const activeServices = itemServicesList[itemId].filter(s => !s.readonly && s.agyoService.id === serviceId);
        if (activeServices.length > 1) {
            const ownerService = activeServices.find(s => s.owner);
            service = ownerService || activeServices[0];
        } else {
            service = activeServices[0];
        }
    }

    return service ? service : def;
};

/**
 * Extract all connectable services
 * @param {object} state The redux state
 * @param {string} itemId Id of the item
 * @param {string} managedId Id of the managed item
 * @returns {object} list of connectable services
 */
export const getConnectableServices = (state, itemId, managedId) => {
    let serviceList = state.services.servicesSubscription.itemServices.services[itemId];

    const existingLink = state.connectionsNew.read.findOwnManagedConnections.content.filter(
        l => l.managerId === itemId && l.managedId === managedId
    )[0];

    let activeConnectedServices = [];

    if (existingLink) {
        activeConnectedServices = existingLink.connections
            .filter(c => c.status.active && !c.status.deleted)
            .map(c => c.serviceId);
    }

    return state.services.agyoServices.services.filter(
        s =>
            s.connectable &&
            (s.dependencies === undefined ||
                s.dependencies.length === 0 ||
                isValidDependency(s.dependencies, serviceList, activeConnectedServices))
    );
};

function isValidDependency(dependencies, services = [], connectedServices = []) {
    return dependencies.every(d => {
        let ser = services.find(s => s.agyoService.id === d.id);
        return (
            ser !== undefined &&
            ser.agyoService.connectable &&
            ser.availableToUser &&
            (!d.owner || ser.owner) &&
            (!d.connection || connectedServices.includes(d.id))
        );
    });
}
