import styled, { keyframes } from "styled-components";

const animationdBackground = keyframes`
0% {
    background: #F2F5F8;
}
100% {
    background: #E4EAF0;
}
`;

export const Container = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
    margin: ${props => props.margin}
    margin-top: ${props => props.marginTop};
    margin-bottom: ${props => props.marginBottom};
    animation-name: ${animationdBackground};
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
`;
