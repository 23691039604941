import styled from "styled-components";

export const PageContainer = styled.div`
    background-color: ${props => props.theme.colors.background.white};
    padding: 24px 0;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 1200px) {
        padding: 24px 24px;
    }
`;

export const PageContent = styled.div`
    width: 100%;
    align-self: flex-start;
`;

export const Title = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 24px;
    font-family: Cairo;
    font-weight: 600;
    line-height: 24px;
    margin: 32px 0 0;
`;

export const Section = styled.div`
    padding: 0;
    @media (min-width: 1200px) {
        padding: 0 24px;
    }
`;
