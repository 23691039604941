import { getRestCall } from "../../utils/rest-api-call";
import { API_URL } from "../../config";
import { getUserProfile } from "../../utils/state-utils/get-user-profile";
import { getAuthWithAppName } from "../auth";

export const GET_CATEGORIES_START = "GET_CATEGORIES_START";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_ERROR = "GET_CATEGORIES_ERROR";

export const getCategories = () => async (dispatch, getState) => {
    dispatch({ type: GET_CATEGORIES_START });

    try {
        const auth = getAuthWithAppName(getState().auth);
        const res = await getRestCall(
            `${API_URL}/services/categories`,
            auth,
            {},
            dispatch,
            auth.refreshToken,
            getUserProfile(getState())
        );
        dispatch({ type: GET_CATEGORIES_SUCCESS, payload: res });
    } catch (e) {
        dispatch({ type: GET_CATEGORIES_ERROR, error: e });
    }
};
