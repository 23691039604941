import styled from "styled-components";

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 16px;
    line-height: 40px;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: #152935;
`;

export const NotificationBadge = styled.div`
    text-align: center;
    width: 55px;
    cursor: pointer;

    display: ${props => (props.showNotification === false || !props.isItemAdmin ? "none" : "initial")};

    :hover {
        background-color: #5a6872;
    }
`;

export const FlagsBadge = styled.div`
    text-align: center;
    width: 55px;
    cursor: pointer;

    :hover {
        background-color: #5a6872;
    }
`;
