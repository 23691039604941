import React, { useEffect } from "react";

import { Button } from "antd";
import { FormattedMessage } from "react-intl";

import { downloadBase64 } from "../../../utils/download";

export const GenerateContract = ({
    actOfEntrustment,
    actOfEntrustmentStatus,
    docData,
    docName,
    generateActOfEntrustment,
    generateActOfEntrustmentReset
}) => {
    const generateDocument = docData => {
        generateActOfEntrustment(docData);
    };

    useEffect(() => {
        if (actOfEntrustmentStatus && actOfEntrustmentStatus.ended) {
            downloadBase64(actOfEntrustment, docName);
        }

        return () => generateActOfEntrustmentReset();
    }, [actOfEntrustment, actOfEntrustmentStatus, docName, generateActOfEntrustmentReset]);

    return (
        <div>
            <span className="conf-title">
                <FormattedMessage id="c-connections-settings-actions.service-load-affidamento" />
            </span>
            <div className="c-connection-settings-actions-button">
                <Button onClick={() => generateDocument(docData)}>
                    <FormattedMessage id="connections.layout.actions.generate-act-of-attachment" />
                </Button>
            </div>
        </div>
    );
};

export default GenerateContract;
