import {
    CREATE_PDF_START,
    CREATE_PDF_SUCCESS,
    CREATE_PDF_ERROR,
    CREATE_PDF_RESET,
    UPLOAD_SIGNED_PDF_START,
    UPLOAD_SIGNED_PDF_SUCCESS,
    UPLOAD_SIGNED_PDF_ERROR,
    ASSIGN_SIGNER_START,
    ASSIGN_SIGNER_SUCCESS,
    ASSIGN_SIGNER_ERROR,
    ASSIGN_SIGNER_RESET,
    RESET_PIN_START,
    RESET_PIN_SUCCESS,
    RESET_PIN_ERROR,
    RESET_PIN_RESET,
    CANCEL_START,
    CANCEL_SUCCESS,
    CANCEL_ERROR
} from "../actions/sign-api-write.js";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function createPdf(state = defaultState, { type, payload, error }) {
    switch (type) {
        case CREATE_PDF_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case CREATE_PDF_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case CREATE_PDF_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case CREATE_PDF_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export function uploadSignedPdf(state = defaultState, { type, payload, error }) {
    switch (type) {
        case UPLOAD_SIGNED_PDF_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case UPLOAD_SIGNED_PDF_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case UPLOAD_SIGNED_PDF_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}

export function assignSigner(state = defaultState, { type, payload, error }) {
    switch (type) {
        case ASSIGN_SIGNER_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case ASSIGN_SIGNER_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case ASSIGN_SIGNER_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case ASSIGN_SIGNER_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export function resetPin(state = defaultState, { type, payload, error }) {
    switch (type) {
        case RESET_PIN_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case RESET_PIN_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case RESET_PIN_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case RESET_PIN_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export function cancel(state = defaultState, { type, payload, error }) {
    switch (type) {
        case CANCEL_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case CANCEL_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case CANCEL_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
