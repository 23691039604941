import {
    VERIFY_STATUS_START,
    VERIFY_STATUS_SUCCESS,
    VERIFY_STATUS_ERROR,
    COMPANY_PEC_START,
    COMPANY_PEC_SUCCESS,
    COMPANY_PEC_ERROR
} from "../actions/contract.js";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function contract(state = defaultState, { type, payload, error }) {
    switch (type) {
        case VERIFY_STATUS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case VERIFY_STATUS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                }
            };
        case VERIFY_STATUS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { message: error }
                }
            };
        default:
            return state;
    }
}

export function pec(state = defaultState, { type, payload, error }) {
    switch (type) {
        case COMPANY_PEC_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case COMPANY_PEC_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                pecAddress: payload
            };
        case COMPANY_PEC_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { message: error }
                }
            };
        default:
            return state;
    }
}
