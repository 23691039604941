import styled from "styled-components";

export const Container = styled.div`
    background: linear-gradient(0deg, rgba(243, 246, 249, 0) 0%, rgba(255, 255, 255, 0.7) 100%);
    border-radius: 0.5rem;
    padding: 3rem;
    margin: 2rem;
    max-width: 869px;
    position: relative;
    padding-right: 200px;

    @media (max-width: 1199px) {
        padding-right: 3rem;
    }

    @media (min-width: 1200px) and (max-width: 1439px) {
        max-width: 720px;
        padding-right: 108px;
    }
`;

export const Title = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 2.125rem;
    font-family: Cairo;
    font-weight: 600;
    line-height: 3rem;
    margin-bottom: 1.5rem;

    @media (max-width: 1439px) {
        font-size: 1.5rem;
        letter-spacing: 0.18px;
        line-height: 2rem;
    }
`;

export const Description = styled.div`
    max-width: 454px;
    color: ${props => props.theme.colors.text.cadet};
    font-size: 1.125rem;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.5px;
    line-height: 1.625rem;

    @media (max-width: 1439px) {
        font-size: 1rem;
        letter-spacing: 0.44px;
        line-height: 1.5rem;
    }

    @media (min-width: 1440px) and (max-width: 1919px) {
        max-width: 406px;
    }
`;

export const CirclesContainer = styled.div`
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    margin-top: 2.5rem;

    @media (max-width: 1199px) {
        grid-gap: 2rem;
    }
`;

export const HighlightWord = styled.span`
    color: ${props => props.color};
`;

export const HumanImageContainer = styled.div`
    position: absolute;
    right: ${props => props.right};
    top: ${props => props.top};
    width: 357px;

    @media (max-width: 1199px) {
        display: none;
    }

    @media (min-width: 1200px) and (max-width: 1439px) {
        width: 264px;
    }
`;

export const HumanImage = styled.img`
    width: 100%;
`;
