import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { getFormValues } from "redux-form";

import TextField from "@ts-digital/arc/antd/TextField";
import { form } from "@ts-digital/conv-redux-form";
import { FormattedMessage } from "react-intl";

import { personProfileEditSchema } from "../../../../utils/form-schema/person-profile-edit";

import {
    Button,
    DataContainer,
    LeftFirstColumn,
    LeftSecondColumn,
    RightCentralColumn,
    FormTitle,
    LoadingButtonContainer,
    LabelText,
    LabelTitle
} from "./styled";
import DataAddress from "../data-address";
import DataPrivacy from "../data-privacy";

export class PersonEditForm extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        errorMsg: PropTypes.string,
        formValues: PropTypes.object,
        handleSubmit: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        taxId: PropTypes.string
    };

    render() {
        const { disabled, formValues, handleSubmit, isLoading, taxId } = this.props;

        return (
            <>
                <FormTitle>
                    <FormattedMessage id="general.personalData" />
                </FormTitle>
                <DataContainer>
                    <LeftFirstColumn>
                        <TextField
                            disabled={disabled}
                            label={<FormattedMessage id="general.firstName" />}
                            name={"firstName"}
                        />
                    </LeftFirstColumn>
                    <LeftSecondColumn>
                        <TextField
                            disabled={disabled}
                            label={<FormattedMessage id="general.lastName" />}
                            name={"lastName"}
                        />
                    </LeftSecondColumn>
                    <RightCentralColumn>
                        <LabelTitle>
                            <FormattedMessage id="general.codice-fiscale" />
                        </LabelTitle>
                        <LabelText>{taxId}</LabelText>
                    </RightCentralColumn>
                    <DataAddress disabled={disabled} formValues={formValues} />
                </DataContainer>

                <FormTitle>
                    <FormattedMessage id="c-manage-data-company-edit.privacy" />
                </FormTitle>
                <DataContainer>
                    <DataPrivacy disabled={disabled} person={true} />
                </DataContainer>

                <LoadingButtonContainer>
                    {!disabled && (
                        <Button htmlType="submit" loading={isLoading} onClick={handleSubmit} variant="primary">
                            <FormattedMessage id="general.save" />
                        </Button>
                    )}
                </LoadingButtonContainer>
            </>
        );
    }
}

const formDefinition = {
    form: "person-data-step",
    initialValues: { country: "undefined" },
    schema: personProfileEditSchema
};

function mapStateToProps(state) {
    return {
        formValues: getFormValues("person-data-step")(state)
    };
}

const composedHoc = compose(connect(mapStateToProps, {}), form(formDefinition));

export default composedHoc(PersonEditForm);
