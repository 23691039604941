import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Section = styled.div`
    padding: 40px 0 32px;
    border-bottom: 1px solid ${props => props.theme.colors.primary.columbiaBlue};

    &:last-of-type {
        border: 0;
    }
`;

export const SectionTitle = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-family: Cairo;
    font-weight: normal;
    line-height: 24px;
    margin: 0 0 16px;
`;

export const SectionDescription = styled.p`
    color: ${props => props.theme.colors.text.quickSilver};
    font-size: 14px;
    font-weight: normal;
    line-height: 12px;
    margin: 0 0 24px;
`;

export const FormContent = styled.div`
    display: flex;
    align-items: flex-start;
`;

export const FieldContainer = styled.div`
    width: 400px;
    margin-right: 16px;
`;

export const ButtonContainer = styled.div`
    margin: 31px 0 0;
`;

export const RecipientList = styled.div`
    margin: 32px 0 0;
`;

export const Recipient = styled.div`
    margin: 0 0 24px;
`;

export const RecipientData = styled.div`
    display: flex;
    align-items: center;
`;

export const RecipientContent = styled.div`
    min-width: 400px;

    @media (min-width: 870px) {
        min-width: 600px;
    }

    ${FieldContainer} {
        width: 300px;
        margin-right: 8px;
    }

    ${ButtonContainer} {
        margin: 4px;
    }
`;

export const RecipientName = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin: 0 0 8px;
`;

export const RecipientValue = styled.div`
    padding: 0 16px;
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 52px;
`;

export const Icon = styled(FontAwesomeIcon)`
    font-size: 16px;
`;

export const EmptyList = styled.p`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 32px;
`;
