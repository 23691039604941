import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Icon } from "antd";

export const MenuIcon = ({ iconName, offset, iconObject }) => (
    <Icon
        component={() =>
            iconObject ? <FontAwesomeIcon icon={iconObject} /> : <i className={`fas fa-${iconName} c-sideMenu-icon`} />
        }
        style={{ marginLeft: `${offset}px` }}
    />
);

MenuIcon.propTypes = {
    iconObject: PropTypes.object,
    iconName: PropTypes.string,
    offset: PropTypes.string
};

MenuIcon.defaultProps = {
    offset: "0",
    iconObject: null
};
