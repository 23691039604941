import { reset } from "redux-form";

import { getAuthWithAppName } from "../../auth.js";
import { patchRestCall, putRestCall, deleteRestCall } from "../../../utils/rest-api-call";
import { getNcsNotifications } from "../../../utils/state-utils/get-ncs-notifications";
import { getUserProfile } from "../../../utils/state-utils/get-user-profile";

import { API_URL, ENABLE_WEB_NOTIFICATIONS } from "../../../config";

export const UPDATE_NCS_SINGLE_NOTIFICATION_START = "UPDATE_NCS_SINGLE_NOTIFICATION_START";
export const UPDATE_NCS_SINGLE_NOTIFICATION_ERROR = "UPDATE_NCS_SINGLE_NOTIFICATION_ERROR";
export const UPDATE_NCS_SINGLE_NOTIFICATION_SUCCESS = "UPDATE_NCS_SINGLE_NOTIFICATION_SUCCESS";

export function updateSingleNotification(ncsId, notificationId, request) {
    return async (dispatch, getState) => {
        if (ENABLE_WEB_NOTIFICATIONS) {
            const prevNotifications = getNcsNotifications(getState());

            dispatch({
                type: UPDATE_NCS_SINGLE_NOTIFICATION_START,
                payload: { ncsId, notificationId, request }
            });

            const requestUrl = `${API_URL}/notifications/${ncsId}/web/${notificationId}`;
            const authObj = getAuthWithAppName(getState().auth);

            try {
                const response = await patchRestCall(
                    requestUrl,
                    authObj,
                    null,
                    request,
                    dispatch,
                    authObj.refreshToken,
                    getUserProfile(getState()),
                    undefined,
                    false
                );

                dispatch({
                    type: UPDATE_NCS_SINGLE_NOTIFICATION_SUCCESS,
                    payload: {
                        ...response
                    }
                });
            } catch (e) {
                dispatch({
                    type: UPDATE_NCS_SINGLE_NOTIFICATION_ERROR,
                    error: e,
                    payload: { ncsId, prevNotifications }
                });
            }
        }
    };
}

export const UPDATE_NCS_NOTIFICATIONS_LIST_START = "UPDATE_NCS_NOTIFICATIONS_LIST_START";
export const UPDATE_NCS_NOTIFICATIONS_LIST_ERROR = "UPDATE_NCS_NOTIFICATIONS_LIST_ERROR";
export const UPDATE_NCS_NOTIFICATIONS_LIST_SUCCESS = "UPDATE_NCS_NOTIFICATIONS_LIST_SUCCESS";

export function updateNotificationsList(ncsId, request) {
    return async (dispatch, getState) => {
        if (ENABLE_WEB_NOTIFICATIONS) {
            const prevNotifications = getNcsNotifications(getState());

            dispatch({
                type: UPDATE_NCS_NOTIFICATIONS_LIST_START,
                payload: { ncsId, request }
            });

            const requestUrl = `${API_URL}/notifications/${ncsId}/web`;
            const authObj = getAuthWithAppName(getState().auth);

            try {
                const response = await patchRestCall(
                    requestUrl,
                    authObj,
                    null,
                    request,
                    dispatch,
                    authObj.refreshToken,
                    getUserProfile(getState()),
                    undefined,
                    false
                );

                dispatch({
                    type: UPDATE_NCS_NOTIFICATIONS_LIST_SUCCESS,
                    payload: {
                        ...response
                    }
                });
            } catch (e) {
                dispatch({
                    type: UPDATE_NCS_NOTIFICATIONS_LIST_ERROR,
                    error: e,
                    payload: { ncsId, prevNotifications }
                });
            }
        }
    };
}

export const DELETE_NOTIFICATIONS_LIST_START = "DELETE_NOTIFICATIONS_LIST_START";
export const DELETE_NOTIFICATIONS_LIST_ERROR = "DELETE_NOTIFICATIONS_LIST_ERROR";
export const DELETE_NOTIFICATIONS_LIST_SUCCESS = "DELETE_NOTIFICATIONS_LIST_SUCCESS";

export function deleteNotificationsList(ncsId) {
    return async (dispatch, getState) => {
        if (ENABLE_WEB_NOTIFICATIONS) {
            const prevNotifications = getNcsNotifications(getState());

            dispatch({
                type: DELETE_NOTIFICATIONS_LIST_START,
                payload: { ncsId }
            });

            const requestUrl = `${API_URL}/notifications/${ncsId}/web`;
            const authObj = getAuthWithAppName(getState().auth);

            try {
                const response = await deleteRestCall(
                    requestUrl,
                    authObj,
                    null,
                    null,
                    dispatch,
                    authObj.refreshToken,
                    getUserProfile(getState()),
                    undefined,
                    false
                );

                dispatch({
                    type: DELETE_NOTIFICATIONS_LIST_SUCCESS,
                    payload: {
                        ...response
                    }
                });
            } catch (e) {
                dispatch({
                    type: DELETE_NOTIFICATIONS_LIST_ERROR,
                    error: e,
                    payload: { ncsId, prevNotifications }
                });
            }
        }
    };
}

export const DELETE_SINGLE_NOTIFICATION_START = "DELETE_SINGLE_NOTIFICATION_START";
export const DELETE_SINGLE_NOTIFICATION_ERROR = "DELETE_SINGLE_NOTIFICATION_ERROR";
export const DELETE_SINGLE_NOTIFICATION_SUCCESS = "DELETE_SINGLE_NOTIFICATION_SUCCESS";

export function deleteSingleNotification(ncsId, notificationId) {
    return async (dispatch, getState) => {
        if (ENABLE_WEB_NOTIFICATIONS) {
            const prevNotifications = getNcsNotifications(getState());

            dispatch({
                type: DELETE_SINGLE_NOTIFICATION_START,
                payload: { ncsId, notificationId }
            });

            const requestUrl = `${API_URL}/notifications/${ncsId}/web/${notificationId}`;
            const authObj = getAuthWithAppName(getState().auth);

            try {
                await deleteRestCall(
                    requestUrl,
                    authObj,
                    null,
                    null,
                    dispatch,
                    authObj.refreshToken,
                    getUserProfile(getState()),
                    undefined,
                    false
                );

                dispatch({
                    type: DELETE_SINGLE_NOTIFICATION_SUCCESS,
                    payload: {
                        notificationId
                    }
                });
            } catch (e) {
                dispatch({
                    type: DELETE_SINGLE_NOTIFICATION_ERROR,
                    error: e,
                    payload: { ncsId, prevNotifications }
                });
            }
        }
    };
}

export const UPDATE_NCS_SINGLE_PREFERENCE_START = "UPDATE_NCS_SINGLE_PREFERENCE_START";
export const UPDATE_NCS_SINGLE_PREFERENCE_ERROR = "UPDATE_NCS_SINGLE_PREFERENCE_ERROR";
export const UPDATE_NCS_SINGLE_PREFERENCE_SUCCESS = "UPDATE_NCS_SINGLE_PREFERENCE_SUCCESS";

export function updatePreference(ncsId, preference, itemId) {
    return async (dispatch, getState) => {
        if (ENABLE_WEB_NOTIFICATIONS) {
            dispatch({
                type: UPDATE_NCS_SINGLE_PREFERENCE_START,
                payload: preference
            });

            const requestUrl = `${API_URL}/notifications/${ncsId}/preferences`;
            const authObj = getAuthWithAppName(getState().auth);

            try {
                const response = await putRestCall(
                    requestUrl,
                    authObj,
                    null,
                    preference,
                    dispatch,
                    authObj.refreshToken,
                    getUserProfile(getState()),
                    itemId,
                    false
                );

                dispatch({
                    type: UPDATE_NCS_SINGLE_PREFERENCE_SUCCESS,
                    payload: response
                });
            } catch (e) {
                dispatch({
                    type: UPDATE_NCS_SINGLE_PREFERENCE_ERROR,
                    error: e,
                    payload: { ncsId, preference }
                });
            }
        }
    };
}

export const UPDATE_NCS_SINGLE_CHANNEL_START = "UPDATE_NCS_SINGLE_CHANNEL_START";
export const UPDATE_NCS_SINGLE_CHANNEL_SUCCESS = "UPDATE_NCS_SINGLE_CHANNEL_SUCCESS";
export const UPDATE_NCS_SINGLE_CHANNEL_ERROR = "UPDATE_NCS_SINGLE_CHANNEL_ERROR";

export function updateChannel(ncsId, channel, itemId) {
    return async (dispatch, getState) => {
        if (ENABLE_WEB_NOTIFICATIONS) {
            dispatch({
                type: UPDATE_NCS_SINGLE_CHANNEL_START,
                payload: channel
            });

            const requestUrl = `${API_URL}/notifications/${ncsId}/channels`;
            const authObj = getAuthWithAppName(getState().auth);

            try {
                const response = await putRestCall(
                    requestUrl,
                    authObj,
                    null,
                    channel,
                    dispatch,
                    authObj.refreshToken,
                    getUserProfile(getState()),
                    itemId,
                    false
                );

                dispatch({
                    type: UPDATE_NCS_SINGLE_CHANNEL_SUCCESS,
                    payload: response
                });

                // Reset form after successful submission
                dispatch(reset("ncs-add-email"));
            } catch (e) {
                dispatch({
                    type: UPDATE_NCS_SINGLE_CHANNEL_ERROR,
                    error: e,
                    payload: { ncsId, channel }
                });
            }
        }
    };
}

export const DELETE_NCS_SINGLE_CHANNEL_START = "DELETE_NCS_SINGLE_CHANNEL_START";
export const DELETE_NCS_SINGLE_CHANNEL_SUCCESS = "DELETE_NCS_SINGLE_CHANNEL_SUCCESS";
export const DELETE_NCS_SINGLE_CHANNEL_ERROR = "DELETE_NCS_SINGLE_CHANNEL_ERROR";

export function deleteChannel(ncsId, channelId) {
    return async (dispatch, getState) => {
        if (ENABLE_WEB_NOTIFICATIONS) {
            dispatch({
                type: DELETE_NCS_SINGLE_CHANNEL_START,
                payload: channelId
            });

            const requestUrl = `${API_URL}/notifications/${ncsId}/channels/${channelId}`;
            const authObj = getAuthWithAppName(getState().auth);

            try {
                await deleteRestCall(
                    requestUrl,
                    authObj,
                    null,
                    undefined,
                    dispatch,
                    authObj.refreshToken,
                    getUserProfile(getState()),
                    undefined,
                    false
                );

                dispatch({
                    type: DELETE_NCS_SINGLE_CHANNEL_SUCCESS,
                    payload: {
                        channelId
                    }
                });
            } catch (e) {
                dispatch({
                    type: DELETE_NCS_SINGLE_CHANNEL_ERROR,
                    error: e,
                    payload: { ncsId, channelId }
                });
            }
        }
    };
}
