import { PRIVACY_POLICY_URL } from "../config";

export default {
    "apps.expense-report.title": "Nota Spese in Cloud",
    "apps.TEL.title": " TS Fiscal Link",
    "apps.TEL.subtitle":
        "<b>Gestione automatizzata degli adempimenti telematici</b><br /><br /> Con Digital Fiscal Link automatizzi l’intero processo di gestione di Dati Fatture, Dati Liquidazione IVA ed Esterometro. Dalla preparazione dei dati, alla generazione dei file xml, all’apposizione della firma elettronica, all’invio allo SDI e al prelievo delle relative ricevute.",
    "apps.TEL.box1.title": "Processo sotto controllo",
    "apps.TEL.box1.desc":
        "Con un’apposita funzione, l’utente può controllare l’avanzamento degli adempimenti e verificare che abbiano superato le fasi intermedie per essere correttamente inoltrati all’Agenzia delle Entrate.",
    "apps.TEL.box2.title": "Firma automatica",
    "apps.TEL.box2.desc":
        "È possibile registrare i dati che permetteranno a Digital Fiscal Link di effettuare la firma digitale delle Comunicazioni. La piattaforma verificherà in automatico se è necessario firmare digitalmente gli adempimenti prima dell’invio.",
    "apps.TEL.box3.title": "Integrazione con i gestionali TeamSystem",
    "apps.TEL.box3.desc":
        "I collegamenti con i gestionali TeamSystem facilitano la gestione delle Comunicazioni Telematiche. È comunque possibile caricare manualmente i documenti.",
    "apps.TEL.quickdesc": "Invio di dati fattura e Liquidazioni iva",

    "apps.EIP.title": "TS Digital Invoice",
    "apps.EIP.subtitle":
        "<b>Gestisci al meglio l'intero processo di fatturazione e conservazione</b> <br /> <br /> Digital Invoice è la soluzione che ti aiuta nella gestione dell’intero processo: dall'emissione della fattura, al controllo della corretta trasmissione fino al suo invio in conservazione digitale.",
    "apps.EIP.box1.title": "Invio delle fatture elettroniche a norma",
    "apps.EIP.box1.desc":
        "Digital Invoice ti semplifica la vita, gestendo l’intero processo di fatturazione elettronica. Potrai dimenticare di controllare la casella di posta certificata per il monitoraggio delle notifiche di recapito fatture.",
    "apps.EIP.box2.title": "Gestione automatizzata dal tuo gestionale",
    "apps.EIP.box2.desc":
        "Con pochi click puoi inviare direttamente le fatture elettroniche al cliente. Il processo è completamente automatico, dalla registrazione all’invio in conservazione. Puoi monitorare lo stato delle tue fatture inviate, le relative notifiche e le fatture ricevute.",
    "apps.EIP.box3.title": "Sotto controllo tutte le fatture ricevute",
    "apps.EIP.box3.desc":
        "Tramite la console chiara ed intuitiva importi in automatico la fattura nel tuo gestionale integrato, eliminando il data entry e velocizzando la contabilizzazione. Hai sempre a disposizione il file XML e il relativo file leggibile (PDF).",
    "apps.EIP.box4.title": "Facilita la collaborazione tra Studio e Azienda",
    "apps.EIP.box4.desc":
        "Grazie a Digital Invoice il rapporto tra consulente fiscale e azienda è molto più semplice e diretto. Lo studio può importare automaticamente le fatture delle aziende clienti ed avere così immediatamente sotto controllo l’andamento del business.",

    "apps.SIG.title": "TS Digital Signature",
    "apps.SIG.subtitle":
        "<b>La firma elettronica più veloce, sicura e innovativa di sempre.</b> <br /> <br /> Scegli Digital Signature, la soluzione TeamSystem per firmare digitalmente in totale sicurezza e in completa mobilità con il telefono. Ideale per i professionisti e per le aziende. Con Digital Signature velocizzi i processi di firma e la gestione dei documenti, superando limiti e rischi legati alla gestione delle smart card e delle business key. I documenti da firmare sono automaticamente associati alla tipologia di firma più idonea.",
    "apps.SIG.box1.title": "Risparmi e sei più efficiente",
    "apps.SIG.box1.desc":
        "Elimina i costi della carta e del toner. Puoi amministrare l’intero processo di firma in pochi passaggi grazie al software semplice e intuitivo.",
    "apps.SIG.box2.title": "Sei a norma",
    "apps.SIG.box2.desc":
        "Il documento firmato digitalmente ha lo stesso valore giuridico di un documento cartaceo sottoscritto con firma autografa o di quello elettronico sottoscritto con business key.",
    "apps.SIG.box3.title": "Firmi ovunque, in ufficio e in mobilità",
    "apps.SIG.box3.desc":
        "Digital Signature ti permette di firmare i documenti ovunque tu sia. Quando sei fuori ufficio, puoi ricevere il documento via mail e sottoscrivere con una telefonata.",
    "apps.SIG.box4.title": "Conservi i documenti a norma",
    "apps.SIG.box4.desc":
        "Con Digital Signature puoi inviare i documenti elettronici sottoscritti in conservazione a norma, per il periodo previsto dalla normativa.",

    "apps.ARC.title": "Digital Archive",
    "apps.ARC.subtitle":
        "<b>Il servizio di Conservazione Cloud sicuro, veloce ed economico</b><br /><br />Digital Archive permette la conservazione a norma di legge dei tuoi documenti informatici (tra cui dichiarativi, messaggi PEC, fatture elettroniche, registri, bilanci, DDT, LUL, contratti ...). Il servizio garantisce massima sicurezza e qualità, grazie alle sue certificazioni ISO 27001 e ISO 9001 (massimo livello di sicurezza) e alla certificazione rilasciata dall’AgID (Agenzia per l’Italia Digitale).",
    "apps.ARC.box1.title": "Conservazione ed esibizione a norma di legge",
    "apps.ARC.box1.desc":
        "I documenti potranno essere reperiti e consultati anche nel tempo mantenendo sempre la loro assoluta validità legale. Massima accessibilità dei documenti in qualsiasi momento, grazie ad una semplice e veloce funzione di ricerca.",
    "apps.ARC.box2.title": "Integrazione con i gestionali TeamSystem",
    "apps.ARC.box2.desc":
        "Digital Archive è integrato con tutti i gestionali TeamSystem. Lo Studio può così inviare facilmente in conservazione tutta la documentazione dei clienti.",
    "apps.ARC.box3.title": "Delega del processo di conservazione a TeamSystem",
    "apps.ARC.box3.desc":
        "Con Digital Archive puoi delegare totalmente il processo di conservazione: nomina del Responsabile della conservazione, gestione del Manuale della conservazione, gestione degli archivi ed eventuali backup.",
    "apps.ARC.box4.title": "Meno spreco di tempo e denaro",
    "apps.ARC.box4.desc":
        "Grazie al servizio Digital Archive non dovrai più stampare documenti cartacei, occupare spazi per la loro archiviazione, perdere tempo nella loro riorganizzazione e, soprattutto, perdere tempo nella ricerca per la loro esibizione a norma.",

    "apps.EBS.title": "Digital Bank Link",
    "apps.EBS.subtitle":
        "<b>Importazione automatica dei movimenti bancari e riconciliazione</b><br /><br />Digital Bank Link è il servizio di importazione automatica degli estratti conto della banca nel gestionale TeamSystem e di riconciliazione dei singoli o più movimenti con le operazioni contabili. Il servizio permette anche la registrazione immediata e automatica dei movimenti in contabilità.",
    "apps.EBS.box1.title": "Più efficacia, meno spreco di tempo",
    "apps.EBS.box1.desc":
        "Le operazioni massive e automatiche di Digital Bank Link riducono le operazioni manuali di riconciliazione bancaria e lasciano maggiore spazio a quelle con più alto valore aggiunto.",
    "apps.EBS.box2.title": "Riduzione degli errori",
    "apps.EBS.box2.desc":
        "Con l’acquisizione automatica degli estratti conto si evitano gli errori di input e con l’aiuto della dashboard è possibile verificare lo stato di ogni riconciliazione e prevenire le errate associazioni.",
    "apps.EBS.box3.title": "Massima sicurezza dei dati",
    "apps.EBS.box3.desc":
        "Grazie alle più alte certificazioni di sicurezza i dati e la privacy dell’azienda sono protetti.",
    "apps.EBS.box4.title": "Controllo immediato",
    "apps.EBS.box4.desc":
        "Un cruscotto gestionale garantisce una panoramica completa dei movimenti con descrizione, stato (riconciliato, da contabilizzare, etc.), data di contabilizzazione e possibilità di contabilizzare i movimenti non ancora riconciliati.",

    "apps.DBX.title": "Digital Box",
    "apps.DBX.subtitle":
        "<b>Documenti e informazioni sempre a disposizione dei tuoi clienti</b><br /><br />Digital Box è il servizio che rappresenta l’estensione digitale del gestionale di Studio. Grazie all’App mobile lo Studio e i clienti possono scambiarsi informazioni e documenti in tempo reale. Il tutto con la massima sicurezza.",
    "apps.DBX.box1.title": "Fidelizzi i tuoi clienti",
    "apps.DBX.box1.desc":
        "Offri alle tue aziende clienti un servizio fondamentale per visualizzare i propri documenti (fatture elettroniche, dichiarazioni, visure, bandi di gara, etc.)",
    "apps.DBX.box2.title": "Risparmi tempo",
    "apps.DBX.box2.desc":
        "Faciliti la ricerca dei documenti e puoi dedicare meno tempo a telefonate e visite in Studio da parte del cliente per recuperare informazioni.",
    "apps.DBX.box3.title": "Dai un servizio più tempestivo",
    "apps.DBX.box3.desc":
        "Rendi organico il flusso di informazioni all’interno di uno spazio di condivisione dei documenti ordinato e sicuro.",
    "apps.DBX.box4.title": "Maggior controllo",
    "apps.DBX.box4.desc":
        "Puoi monitorare e condividere in tempo reale tutte le scadenze e i principali parametri relativi all’andamento del business dei tuoi clienti.",

    "apps.GDPR-LOG.title": "Digital GDPR Log",
    "apps.GDPR-LOG.subtitle":
        "<b>Il servizio di gestione e visualizzazione dei Log per il GDPR</b><br /><br />Digital GDPR Log consente agli utenti dei software gestionali TeamSystem di accedere alla console di visualizzazione dei log generati dagli applicativi, in conformità al Regolamento Europeo sulla protezione dei dati personali. Gli utenti abilitati dello Studio o dell’Azienda possono accedere ai log delle attività, sia per una verifica specifica, sia per ottenere informazioni utili durante una visita ispettiva o un audit.",
    "apps.GDPR-LOG.box1.title": "Profilazione utenti",
    "apps.GDPR-LOG.box1.desc":
        "Un’apposita procedura di autorizzazione permette al rappresentante aziendale di identificare e abilitare opportunamente gli utenti che possono accedere alla visualizzazione dei log.",
    "apps.GDPR-LOG.box2.title": "Dati sicuri e criptati",
    "apps.GDPR-LOG.box2.desc":
        "I log delle attività sono criptati e non editabili. Sono trasmessi alla piattaforma TeamSystem Digital in modo che siano disponibili in consultazione sulla console di Digital GDPR Log.",
    "apps.GDPR-LOG.box3.title": "Dashboard di controllo",
    "apps.GDPR-LOG.box3.desc":
        "Tramite l’app Digital GDPR Log è possibile filtrare i log per periodo, per utente, per prodotto, per azione. I dati così filtrati possono poi essere facilmente esportati.",

    "apps.EIT.title": "Incassa Subito",
    "apps.EIT.subtitle":
        "<b>La nuova soluzione che ti consente di incassare in anticipo fino al 90% dell’importo delle tue fatture.</b><br /><br />Supporta lo sviluppo del tuo business e svincolati dal rischio di mancati pagamenti da parte dei tuoi clienti!<br /><br />Incassa Subito si rivolge alle <b>Società di Persone e di Capitali</b>, costituite da almeno <b>24 mesi</b>, con almeno <b>un bilancio depositato</b> e fatturato annuo almeno pari a <b>250.000 €</b>. Le fatture cedute devono avere valore superiore a <b>1.000€</b> ed essere emesse verso <b>Società di Capitali</b> con fatturato annuo almeno pari a <b>1.000.000 €</b>.<br /><br /><b>Non sei sicuro di poter accedere al servizio o non sai quali tra le tue fatture sono cedibili?</b><br /><br /><b>Attiva senza costi Incassa Subito per verificare se i tuoi crediti sono eleggibili e contattaci per avere maggiori informazioni!</b>",
    "apps.EIT.box1.title": "Nessun costo fisso e nessun vincolo sui volumi!",
    "apps.EIT.box1.desc":
        "Sostieni soltanto solo costi relativi alla transazione: non vengono applicati costi fissi, di ingresso o di abbonamento. Inoltre, non esiste globalità nella cessione: puoi scegliere tu quali e quante fatture cedere.",
    "apps.EIT.box2.title": "Liquidità mediamente in 48h, comodamente dal tuo PC!",
    "apps.EIT.box2.desc":
        "Gestisci la richiesta direttamente dal tuo gestionale, attraverso un processo semplice, sicuro, veloce e senza burocrazia.",
    "apps.EIT.box3.title": "Nessuna segnalazione in Centrale Rischi!",
    "apps.EIT.box3.desc":
        "Usufruendo del servizio, la tua esposizione verso il sistema bancario non subisce alcun impatto. Inoltre, non è richiesta alcuna garanzia personale né reale.",
    "apps.EIT.launchData.messages.onboardingManualSendingInstructions": `
        <span style="display:block;">Hai selezionato la modalità di invio manuale.</span>
        <span style="display:block;">Collegati a TS Digital Invoice, seleziona le fatture e seleziona l’icona <img src={piggyBankIcon} type="image/svg+xml" width="16px"/> per inviarle a Incassa Subito, oppure seleziona l’invio automatico così le processeremo noi per te.</span>
        <span style="display:block;"><a href="https://digital.teamsystem.com/lp-digital-finance-n-a" target="_blank">Scopri di più</a> su Incassa Subito.</span>
    `,
    "apps.EIT.launchData.messages.manualSendingInstructions": `
        <span style="display:block;">Hai selezionato l’invio manuale.</span>
        <span style="display:block;">Da questo momento in poi non processeremo più noi le fatture per te.</span>
        <span style="display:block;">Dovrai collegarti a TS Digital Invoice, selezionare le fatture e selezionare l’icona <img src={piggyBankIcon} type="image/svg+xml" width="16px"/> per inviarle a Incassa Subito.</span>
    `,
    "apps.EIT.launchData.messages.onboardingManualSendingOptionInstructions": `
        <span>Seleziona le fatture una ad una dalla lista delle tue fatture disponibili su TS Digital Invoice e attendi la visualizzazione delle fatture conformi alle politiche di investimento degli utenti investitori</span>
    `,
    "apps.EIT.disclaimer": `Per attivare Incassa Subito è necessario acquistare un pacchetto di Digital Invoice. Per maggiori informazioni clicca <a href="https://www.teamsystem.com/store/fatturazione-elettronica/ts-digital-invoice/prezzi/?utm_source=Portale-TS-Digital&utm_medium=referral&utm_campaign=software-experience" target="_blank">qui</a>.`,

    "apps.ESM.title": "Cost Management",
    "apps.ESM.subtitle":
        "<b>Un tool integrato per l’analisi e la gestione efficiente dei costi e dei consumi aziendali</b><br /><br/><p>Scegli Cost Management, la soluzione TeamSystem capace di analizzare i costi e i consumi relativi ai servizi utilizzati in azienda, in real-time o fatturati, generando consapevolezza e favorendo l’ottimizzazione dei costi.</p><p>Cost Management offre una gestione ottimizzata della spesa, proteggendo l’azienda da costi e consumi anomali e fornendo report ad elevata leggibilità.</p>",
    "apps.ESM.box1.title": "Risparmi e sei più efficiente",
    "apps.ESM.box1.desc":
        "Riduzione di tempo e risorse coinvolte nella gestione della spesa aziendale. Puoi amministrare l’intero processo di governance e rendicontazione in pochi passaggi grazie al software semplice e intuitivo.",
    "apps.ESM.box2.title": "Trasparenza del dato<br/>&nbsp;",
    "apps.ESM.box2.desc":
        "Alta navigabilità e leggibilità del dato presente in fattura e semplificazione nella gestione e rendicontazione di esso.",
    "apps.ESM.box3.title": "Intelligence Avanzata<br/>&nbsp;",
    "apps.ESM.box3.desc":
        "Possibilità di fare analisi comparative dei costi e consumi tra differenti fornitori, tipologie di spesa presenti in fattura e periodi di fatturazione.",
    "apps.ESM.box4.title": "Convergenza della spesa real time e fatturata",
    "apps.ESM.box4.desc":
        "Gestione della spesa di servizi aziendali prima e dopo la fatturazione in un unico contenitore, qualunque sia il fornitore.",
    "apps.ESM.disclaimer":
        "<b>Attenzione: </b>attivando questo servizio si autorizza l’utilizzo delle fatture telefoniche a fini di reportistica funzionale al servizio richiesto.",

    "apps.CRAM.title": "Check Up d’Impresa",
    "apps.CRAM.subtitle":
        "<b>La soluzione semplice ed efficiente per gestire la nuova disciplina sulla crisi di Impresa e dell’insolvenza</b><br /><br/>Pensato per Imprese, Commercialisti, Sindaci e Revisori, ti accompagna passo dopo passo in tutte le attività, dall’acquisizione dei dati contabili, all’elaborazione degli indicatori di crisi, alla predisposizione di report e fascicolo finale.",
    "apps.CRAM.box1.title": "Cloud",
    "apps.CRAM.box1.desc":
        "TeamSystem Check Up d’Impresa è una soluzione in cloud utilizzabile in modalità indipendente oppure in modo integrato con le soluzioni gestionali TeamSystem.",
    "apps.CRAM.box2.title": "Un Percorso guidato",
    "apps.CRAM.box2.desc":
        "Attraverso un workflow a scorrimento orizzontale la soluzione accompagna l’utente in tutte le attività, dall’acquisizione dei dati contabili, all’elaborazione degli indicatori di crisi, alla predisposizione dei report e fascicolo finale.",
    "apps.CRAM.box3.title": "Bilanci annuali e situazioni contabili infrannuali",
    "apps.CRAM.box3.desc":
        "Il caricamento dei bilanci ufficiali avviene automaticamente importando i dati da: soluzioni gestionali TeamSystem, dalle Camere di Commercio di competenza, dai tracciati xbrl e da file esterni.",
    "apps.CRAM.box4.title": "Centrale Rischi",
    "apps.CRAM.box4.desc":
        "È possibile importare i report ottenuti dalla Centrale Rischi ed effettuare analisi immediate sulle eventuali segnalazioni.",
    "apps.CRAM.box5.title": "Elaborazione del rating",
    "apps.CRAM.box5.desc":
        "La soluzione consente di elaborare il rating dell’azienda in esame, svolgere un’analisi qualitativa e di Benchmarking ottenendo un confronto settoriale.",
    "apps.CRAM.box6.title": "Dashboard e grafici",
    "apps.CRAM.box6.desc":
        "Tutti gli indici e KPI sono elaborati anche in forma grafica, per aiutarti nella comparazione fra periodi differenti e visualizzare l’andamento nel tempo.",

    "apps.CRAM_PRO.title": "Check Up Impresa Pro",
    "apps.CRAM_PRO.subtitle":
        "<b>La soluzione semplice ed efficace per redigere approfondite analisi economico-finanziarie</b><br /><br />Pensato per Imprese, Commercialisti, Sindaci e Revisori che hanno già acquistato Check Up Impresa, è la soluzione che ti consente di effettuare analisi approfondite sullo stato di salute della tua azienda o delle tue aziende clienti, effettuare previsioni sui bilanci futuri, monitorare l'equilibrio economico finanziario di clienti e fornitori.",
    "apps.CRAM_PRO.box1.title": "Cloud",
    "apps.CRAM_PRO.box1.desc":
        "TeamSystem Check Up Impresa Pro è una soluzione in cloud utilizzabile in modalità indipendente oppure in modo integrato con le soluzioni gestionali TeamSystem.",
    "apps.CRAM_PRO.box2.title": "Analisi Pro",
    "apps.CRAM_PRO.box2.desc":
        "È disponibile una nuova tipologia di analisi che consente all’utente di monitorare sia gli indici della crisi stabiliti dal CNDCEC, sia l’andamento del proprio business tramite previsioni future e analisi di merito creditizio sugli stakeholders.",
    "apps.CRAM_PRO.box3.title": "Business Information",
    "apps.CRAM_PRO.box3.desc":
        "Tramite la soluzione è possibile sia ottenere informazioni su negatività, struttura societaria e partecipazioni dell’azienda in analisi, sia verificare se sussistono i requisiti per essere considerata PMI.",
    "apps.CRAM_PRO.box4.title": "Simulazione e stress test sui bilanci",
    "apps.CRAM_PRO.box4.desc":
        "La soluzione consente di valutare situazioni prospettiche e fornisce una valutazione sugli aspetti chiave del business elaborati sulla base della simulazione effettuata.",
    "apps.CRAM_PRO.box5.title": "Analisi Portafoglio",
    "apps.CRAM_PRO.box5.desc":
        "Il Credit Check sui clienti e fornitori avviene in automatico inserendo i dati degli stakeholders direttamente all’interno della soluzione o importandoli tramite file esterni.",
    "apps.CRAM_PRO.box6.title": "Commenti Automatici",
    "apps.CRAM_PRO.box6.desc":
        "Al fine di comprendere al meglio i fenomeni in atto, la soluzione elabora automaticamente i commenti automatici relativi ai principali aspetti economico-finanziari emersi dall’analisi.",

    "apps.AGRO.title": "Adempimenti Agroalimentare",
    "apps.AGRO.subtitle":
        "<b>La soluzione cloud che permette di seguire in modo armonico la roadmap di digitalizzazione delle imprese vitivinicole</b><br /><br/>La roadmap del Ministero delle politiche agricole ha definito diversi passaggi per la digitalizzazione delle imprese vitivinicole, tra cui il registro telematico del vino (operazioni di cantina, entrate e uscite, trattamenti enologici, vinificazione, etichettatura e condizionamento) e il Documento di accompagnamento dei prodotti vitivinicoli (MVV-E).<br /><br />Adempimenti Agroalimentare è la soluzione che risponde alle esigenze di gestione digitale dei documenti necessari nelle varie fasi della produzione e distribuzione dei prodotti vitivinicoli.",
    "apps.AGRO.box1.title": "Cloud",
    "apps.AGRO.box1.desc":
        "Adempimenti Agroalimentare è una soluzione in cloud utilizzabile in modalità indipendente oppure in modo integrato con le soluzioni gestionali TeamSystem.",
    "apps.AGRO.box2.title": "MVV-E a portata di click",
    "apps.AGRO.box2.desc":
        "Generazione e scambio documentale sono semplici anche per il piccolo produttore o il consulente che lavora per più cantine: uno strumento semplice e veloce per i documenti di accompagnamento.",
    "apps.AGRO.box3.title": "Semplificazione della filiera",
    "apps.AGRO.box3.desc":
        "Gestisce con semplicità tutti i documenti digitali necessari per il tracciamento delle attività all’interno della cantina: dal ricevimento al conferimento delle uve e dei mosti fino all’imbottigliamento e alla distribuzione.",
    "apps.AGRO.box4.title": "Risparmio di tempo e risorse",
    "apps.AGRO.box4.desc":
        "È possibile gestire tutte le operazioni sui documenti: dalla bozza e validazione, a cura dello speditore, fino all’accettazione (anche con riserva) o al rifiuto-annullamento da parte del destinatario.",
    "apps.AGRO.box5.title": "Registro telematico del vino",
    "apps.AGRO.box5.desc":
        "Offre uno strumento semplice ed efficace per la gestione completa del registro telematico vitivinicolo previsto dal MIPAAF. È possibile eseguire e tenere sotto controllo tutte le operazioni di cantina, le entrate e le uscite, i trattamenti enologici, la vinificazione, giacenze, etc.",

    "apps.NIUMABONUS.title": "TeamSystem Ecobonus",
    "apps.NIUMABONUS.subtitle": `<b>La nuova soluzione in cloud, facile e sicura che ti permette la gestione di tutte le tipologie di pratiche relative a Superbonus 110%, Ecobonus, Sismabonus, Bonus facciate e Bonus ristrutturazioni.</b><br /></br><b>TeamSystem Ecobonus</b> si rivolge a <b>Commercialisti</b>, <b>CAF</b> e <b>Amministratori di Condominio</b> che hanno bisogno di avere sotto controllo l’intero iter approvativo di tutte le pratiche e gestire in modo ordinato tutta la documentazione gestita.</br></br>Collabora con tutti gli attori convolti (Tecnici, Committenti, Amministratori di condominio e Imprese) durante la fase di raccolta e verifica documentale per ottenere il rilascio del visto di conformità e trasferire il credito fiscale maturato.<br/><b>Hai paura di commettere errori e perderti tra documenti e scadenze? Temi la normativa complessa e la poca comunicazione tra gli attori coinvolti?</b></br></br><b>Contattaci per avere maggiori informazioni e scopri tutti i vantaggi di TeamSystem Ecobonus!</b>`,
    "apps.NIUMABONUS.box1.title": "Un percorso documentale guidato",
    "apps.NIUMABONUS.box1.desc":
        "Attraverso un semplice e intuitivo workfow, individui la documentazione corretta da allegare per ogni tipologia di pratica",
    "apps.NIUMABONUS.box2.title": "Spazio documentale condiviso",
    "apps.NIUMABONUS.box2.desc":
        "Uno spazio di lavoro collaborativo, accessibile in maniera rapida per la corretta generazione del credito",
    "apps.NIUMABONUS.box3.title": "Sicurezza garantita",
    "apps.NIUMABONUS.box3.desc":
        "La piattaforma utilizza i più avanzati sistemi di sicurezza per garantire la corretta gestione dei dati e dei documenti caricati",
    "apps.NIUMABONUS.box4.title": "Firma digitale e conservazione",
    "apps.NIUMABONUS.box4.desc":
        "Generazione di documenti con possibilità di sottoscrizione con firma digitale attraverso TeamSystem Digital Signature e conservazione",

    "apps.TSPAY.title": "Ts Pay",
    "apps.TSPAY.subtitle":
        "<b>Modulo per l’integrazione dei servizi di pagamento di TS Pay</b><br /></br><b>L’integrazione dei servizi di pagamento della TS Pay nel tuo sistema gestionale ti permette di gestire all’interno delle procedure TeamSystem le attività di incasso, pagamento, accesso ai conti e riconciliazione dei movimenti bancari.</b><br /></br><b>Il nuovo modulo</b> si rivolge a <b>Commercialisti e Aziende</b> che hanno bisogno di avere sotto controllo il processo di incasso, pagamento senza utilizzare strumenti esterni alla piattaforma gestionale e senza fare uso di file excel o altre tipologie di tool per riconciliare i movimenti bancari.<br /><br />I servizi di pagamento TS Pay sono erogati da TeamSystem Payments, <b>Istituto di Pagamento</b> del gruppo TeamSystem, autorizzato e vigilato da Banca d’Italia.<br /></br><b>Sei stanco di dover gestire pagamenti e solleciti di pagamento attraverso piattaforme esterne al tuo gestionale? Vuoi che i movimenti bancari vengano riconciliati automaticamente?<b/><br /></br><b>Contattaci per avere maggiori informazioni e scopri tutti i vantaggi del modulo di integrazione con TS Pay!</b>",
    "apps.TSPAY.box1.title": "Incasso",
    "apps.TSPAY.box1.desc":
        "Incassa in maniera semplice e sicura, consentendo ai clienti di pagare con un click, attraverso carta di credito/debito/prepagata e/o servizi di addebito diretto pre-autorizzato SDD ricorrendo ad un unico fornitore di servizi, senza sottoscrivere contratti con soggetti terzi rispetto al gruppo TeamSystem. Il servizio di gestione dell’incasso è prestato da TeamSystem Payments.",
    "apps.TSPAY.box2.title": "Pagamento",
    "apps.TSPAY.box2.desc":
        "Semplifica le procedure aziendali e di studio, pagando i fornitori direttamente all’interno del gestionale, senza dover accedere al corporate banking o altri strumenti esterni. Inoltre, tutti i dati del pagamento saranno già precompilati e non servirà aprire nuovi conti correnti.",
    "apps.TSPAY.box3.title": "Riconciliazione dei movimenti bancari",
    "apps.TSPAY.box3.desc":
        "L’integrazione fra il tuo gestionale e TS Pay consente la lettura dei movimenti e dei saldi aggregati di tutti i conti correnti mediante un’unica interfaccia e ti permette di gestire la riconciliazione degli stessi in contabilità in maniera automatica.",

    "apps.CYBER.title": "Cyber Security",
    "apps.CYBER.subtitle":
        "TeamSystem Cybersecurity è lo strumento semplice e digitale che ti <b>permette di proteggere la tua identità, quella dei tuoi clienti e delle tue infrastrutture: verifica la sicurezza di e-mail, sito web, e-commerce, server di rete e qualsiasi altra risorsa digitale potenzialmente esposta a minacce informatiche.</b><br><br>TeamSystem Cybersecurity è una soluzione <b>semplice e intuitiva</b>, accessibile anche a chi non ha competenze tecniche specifiche. Lo strumento è costantemente aggiornato per affrontare le minacce emergenti.<br><br>Hai uno o più indirizzi e-mail aziendali? La nostra soluzione di check e-mail ti permette di <b>verificare se le tue caselle sono state violate</b>.Vuoi verificare se il tuo sito web aziendale è sicuro? Con il nostro servizio puoi <b>controllare se il tuo dominio ha subito attacchi</b>.Hai asset digitali esposti e potenzialmente a rischio? TeamSystem Cybersecurity <b>verifica per te il perimetro dei server DNS, FTP, AS, e-commerce e tutti gli altri dati sensibili</b>.<br><br><ol><li><b>Azioni di remediation</b> guidate dall’intelligenza artificiale, personalizzate per una protezione intuitiva e proattiva</li> <li><b>In linea con le normative europee</b> vigenti, per ridurre il rischio di sanzioni</li><li><b>Reportistica automatica e chiara</b>, in un linguaggio adatto e costruito sulle competenze dell’utenze</li></ol>",
    "apps.CYBER.box1.title": "Verifica delle identità e-mail",
    "apps.CYBER.box1.desc":
        "Semplicemente inserendo i tuoi indirizzi puoi verificare se le tue e-mail sono state coinvolte in una violazione di dati nel dark web.",
    "apps.CYBER.box2.title": "Test del dominio",
    "apps.CYBER.box2.desc":
        "Monitora la sicurezza del tuo sito web e del dominio di posta per verificare vulnerabilità e black list, con report e azioni correttive.",
    "apps.CYBER.box3.title": "Check degli asset",
    "apps.CYBER.box3.desc":
        "Monitoraggio completo di tutti i tuoi asset digitali, inclusi siti, server mail, DNS, FTP, con report approfonditi e analisi personalizzate per una protezione totale.",

    "apps.SPID.title": "SPID",
    "apps.SPID.subtitle":
        "<b>L’identità SPID per te e la tua attività professionale</b><br /></br>TeamSystem ID abilitato SPID rappresenta la credenziale unica SPID necessaria per accedere ai portali web della Pubblica Amministrazione e ai servizi dei fornitori privati aderenti al sistema. Richiedilo per te e la tua attività professionale.",
    "apps.SPID.box1.title": "Cosa puoi fare",
    "apps.SPID.box1.desc":
        "TeamSystem ID abilitato SPID rappresenta la credenziale unica SPID necessaria ad accedere ai portali web della Pubblica Amministrazione (INPS, INAIL, ecc.) e ai servizi dei fornitori privati aderenti al sistema.",
    "apps.SPID.box2.title": "A chi si rivolge",
    "apps.SPID.box2.desc":
        "TeamSystem ID abilitato SPID si rivolge a tutti i cittadini che necessitano di uno strumento di autenticazione unico, capace, se necessario, di comprovare la qualità di professionista o l’appartenenza di una persona all’organizzazione di una data azienda o di un ente.",
    "apps.SPID.box3.title": "Semplicità e sicurezza",
    "apps.SPID.box3.desc":
        "TeamSystem ID abilitato SPID, soddisfando i più rigidi requisiti normativi in materia di autenticazione digitale, permette al titolare di avere a disposizione credenziali SPID di livello 1 e 2 attraverso un semplice processo di richiesta, effettuabile da remoto o in presenza.",

    "apps.BIS.title": "Business Information",
    "apps.BIS.subtitle":
        "<b>La soluzione che ti consente di ottenere informazioni relative ad aspetti societari, finanziari, economici e settoriali di tutte le aziende di tuo interesse</b><br /><br />Pensato per Imprese, Commercialisti, Sindaci e Revisori che hanno bisogno di analizzare e conoscere nel dettaglio tutti gli aspetti di business di fornitori, clienti, collaboratori e competitors in completa autonomia.<br /><br /><br />Mediante il servizio Business Information è possibile acquisire visure ordinarie o storiche, bilanci, informazioni su negatività, struttura societaria, partecipazioni e report economico-finanziari in pochi e semplici click.",
    "apps.BIS.box1.title": "Cloud",
    "apps.BIS.box1.desc":
        "Business Information è una soluzione in cloud utilizzabile in modalità indipendente oppure in modo integrato con le soluzioni gestionali Teamsystem.",
    "apps.BIS.box2.title": "Documenti disponibili in pochi e semplici click!",
    "apps.BIS.box2.desc": "Acquisisci i documenti di cui hai bisogno in modo semplice e veloce.",
    "apps.BIS.box3.title": "Analisi economico-finanziarie complete e veloci",
    "apps.BIS.box3.desc":
        "Esamina rapidamente tutte le informazioni economico-finanziarie delle aziende senza alcuno sforzo",

    "apps.DRAG.title": "Drag & Tag",
    "apps.DRAG.subtitle":
        "La soluzione che semplifica <b>l'organizzazione dei documenti</b>, la loro selezione, elaborazione e <b>sintesi in uno spazio ordinato e personalizzabile</b>.<br /><br />TeamSystem Drag&Tag è attivabile <b>direttamente dal portale TeamSystem Digital</b> e permette  di  <b>semplificare  l’analisi  e  la  sintesi</b>  di una grande mole di documenti.",
    "apps.DRAG.box1.title": "Drag",
    "apps.DRAG.box1.desc":
        "L'utente ha a disposizione uno spazio di archiviazione strutturato in celle in cui i documenti/file possono essere trascinati, salvati e organizzati in base alle esigenze dell'utente.",
    "apps.DRAG.box2.title": "Tag",
    "apps.DRAG.box2.desc":
        "È possibile applicare un'etichetta ad un contenuto del documento/file in analisi. I tag apposti saranno consultabili ed estraibili in qualsiasi momento sia all'interno dell'ambiente di lavoro sia su un documento Word/PDF/Excel.",
    "apps.DRAG.box3.title": "Analisi",
    "apps.DRAG.box3.desc":
        "Archiviati i documenti e salvate le informazioni di interesse, è possibile sintetizzare commenti ed effettuare operazioni matematiche attraverso l'applicazione di formule, cosi da analizzare tutti i documenti disponibili in modo veloce e automatico.",
    "apps.DRAG.box4.title": "Benefici",
    "apps.DRAG.box4.desc":
        "La soluzione permette all’utente di utilizzare uno strumento di analisi facilmente interrogabile attraverso la selezione/creazione dei filtri di interesse direttamente dal documento in analisi. In questo modo è possibile rendere l’archivio dei documenti dinamico e capace di variare al mutare delle esigenze a cui si vuole far fronte, sintetizzando le informazioni più utili ",

    "apps.SCF.title": "TEAMSYSTEM CREDITI FISCALI",
    "apps.SCF.subtitle":
        "Con <b>TeamSystem Crediti Fiscali</b> hai a disposizione una piattaforma digitale e ad accesso gratuito in cui la domanda e l’offerta di crediti fiscali si incontrano: puoi <b>proporre in cessione oppure acquistare crediti di imposta</b> attraverso un processo guidato step-by-step, controllato, a prezzi trasparenti e completamente digitale",
    "apps.SCF.box1.title": "A CHI SI RIVOLGE",
    "apps.SCF.box1.desc":
        "Imprese che vogliono vendere o acquistare a sconto crediti d'imposta ottenuti da interventi legati a bonus edilizi o energeticiProfessionisti che possono supportare i loro clienti nella gestione e nella compravendita di crediti d'imposta, al fine di offrire servizi di consulenza avanzata",
    "apps.SCF.box2.title": "Acquista o vendi crediti senza rischi",
    "apps.SCF.box2.desc":
        "I crediti oggetto di compravendita sono analizzati dai nostri operatori specializzati al fine di fornire tutti i controlli fiscali e tecnici necessari",
    "apps.SCF.box3.title": "Gestione integrata del processo",
    "apps.SCF.box3.desc":
        "Grazie alla presenza di un mediatore creditizio e a un team di assistenza dedicato, vieni supportato in tutti gli aspetti formali: dalla registrazione fino al pagamento",
    "apps.SCF.box4.title": "Supporto formativo e fiscale da esperti",
    "apps.SCF.box4.desc":
        "Rimani aggiornato sul tema dei bonus fiscali, seguendo i momenti di formazione dedicati dei nostri esperti",

    "apps.invoice-list2": "LISTA FATTURE 2.0",
    "apps.EIP.quickdesc": "Invio delle fatture",

    "apps.ARC.quickdesc": "Conserva i tuoi documenti",

    "apps.gdpr.title": "GDPR Log",
    "apps.GDPR-LOG.quickdesc": "Per accedere ai Log ai sensi del GDPR",

    "apps.PRIVACY.quickdesc": "Per adempiere al Regolamento Europeo",

    "c-manage-data-company-create.classifier.STUDIO": "Voglio operare per diverse aziende (es. sono un professionista)",
    "c-manage-data-company-create.classifier.COMPANY": "Voglio operare solo per la mia azienda",

    "c-manage-data-company-edit.privacy": "Privacy",
    "c-manage-data-company-edit.text1":
        "Scegli se la tua azienda può essere trovata nella ricerca delle aziende, nella pagina delle connessioni, o se nasconderla.",
    "c-manage-data-company-edit.text2": "Vuoi nascondere la tua azienda nella ricerca della pagina delle connessioni?",
    "c-manage-data-company-edit.text1-person":
        "Scegli se la tua anagrafica può essere trovata nella ricerche, nella pagina delle connessioni, o se nasconderla.",
    "c-manage-data-company-edit.text2-person":
        "Vuoi nascondere la tua anagrafica nella ricerca della pagina delle connessioni?",

    "country.ZXXX": "Italia",
    "c-company-create-modal.create-company": "Crea azienda",
    "c-company-create-modal.title":
        "Inserisci i dati dell'azienda che vuoi creare. <br/><b>Attenzione</b>: se stai operando in qualità di studio commerciale, la creazione di un'azienda gestita deve essere fatta dall'apposita pagina connessioni.",
    "c-company-create-modal.success-message":
        "Richiesta inoltrata correttamente. Abbiamo preso in carico la creazione della tua nuova azienda. A breve sarà possibile visualizzarla nella tua lista delle aziende. Riceverai una mail con le credenziali",

    "c-company-dashboard.section-title.my-apps": "Le mie app",
    "c-company-dashboard.section-title.suggested-apps": "Ti potrebbero interessare",
    "c-company-dashboard.section-title.other-apps": "Altri prodotti in cloud",

    "c-error-boundary.error-message": "Si è verificato un errore nel caricamento della pagina.",

    "c-manage-data.add-company-button": "Crea nuova azienda",
    "c-user-create-modal.create-user": "Crea utenza multiaziendale",
    "c-user-create-modal.deselect-item": "Deseleziona",
    "c-user-create-modal.select-item": "Seleziona",
    "c-user-create-modal.not-validated": "Azienda non validata",
    "c-user-create-modal.company-selected": "Azienda selezionata",
    "c-user-create-modal.manager-warning":
        "<b>Attenzione:</b> selezionando l'opzione 'Manager' abiliterai all'utente che stai configurando i permessi per creare utenti a sua volta.",

    "c-manage-data.search-length-warning": "Inserire almeno 4 caratteri",
    "c-manage-data.search-placeholder": "Ricerca per partita iva o codice fiscale o ragione sociale e poi premi invio",
    "c-manage-data.settings.current-language": "Lingua: Italiano",
    "c-manage-data.settings.last-company-loaded": "Ultima Azienda: {lastCompanyLoaded}",
    "c-manage-data.user-edit-button": "Modifica dati utente",
    "c-manage-data.company-edit-button": "Modifica dati azienda",
    "c-manage-data.user-edit-button.popover.content": "Modifica dati utente",
    "c-manage-data.user-edit-button.popover.manage": "Vai alla lista aziende",
    "c-manage-data.user-notify-edit-button.popover.content": "Impostazioni notifiche utente",
    "c-manage-data.company-notify-edit-button.popover.content": "Impostazioni notifiche azienda",
    "c-manage-data.user.info.account-type": "Tipologia: {type}",
    "c-manage-data.user.info.company-search": "Cerca azienda per partita iva o codice fiscale...",

    "c-manage-data.multicompany-create.create-tech-user": "1. Crea utenza tecnica",
    "c-manage-data.multicompany-create.create-tech-user-description":
        "Inserisci i dati per creare una nuova utenza tecnica",
    "c-manage-data.multicompany-create.select-companies": "2. Seleziona aziende",
    "c-manage-data.multicompany-create.select-companies-description": "Seleziona le aziende per gestire i permessi",
    "c-manage-data.multicompany-create.userwithtoomanyitems.select-companies-description":
        "Avvia la ricerca dell'azienda per cui vuoi gestire i permessi",
    "c-manage-data.multicompany-create.select-roles": "3. Gestisci permessi",
    "c-manage-data.multicompany-create.select-roles-description": "Seleziona i permessi dell'utente",
    "c-manage-data.multicompany-create.already-registered": "L'email inserita risulta già associata a un'altra utenza.",
    "c-manage-data.multicompany-create.not-valid-email": "E-mail non valida.",
    "c-manage-data.multicompany-create.insert-name": "Inserisci nome.",
    "c-manage-data.multicompany-create.insert-surname": "Inserisci cognome.",
    "c-manage-data.multicompany-create.insert-email": "Inserisci e-mail.",
    "c-manage-data.multicompany-create.insert-description": "Inserisci descrizione",
    "c-manage-data.multicompany-create.name": "Nome",
    "c-manage-data.multicompany-create.surname": "Cognome",
    "c-manage-data.multicompany-create.email": "Email",
    "c-manage-data.multicompany-create.language": "Lingua",
    "c-manage-data.multicompany-create.forward": "Avanti",
    "c-manage-data.multicompany-create.atleast-company": "Seleziona almeno un'azienda.",
    "c-manage-data.multicompany-create.company": "Azienda",
    "c-manage-data.multicompany-create.piva": "P.iva",
    "c-manage-data.multicompany-create.fiscal-code": "Codice Fiscale",
    "c-manage-data.multicompany-create.search-company": "Cerca azienda",
    "c-manage-data.multicompany-create.available-companies": "Aziende disponibili:",
    "c-manage-data.multicompany-create.select-all-companies": "Seleziona tutte",
    "c-manage-data.multicompany-create.selected-companies": "Aziende selezionate:",
    "c-manage-data.multicompany-create.deselect-companies": "Deseleziona tutte",
    "c-manage-data.multicompany-create.disabled-service": "Servizio non abilitato",
    "c-manage-data.multicompany-create.roles": "Permessi",
    "c-manage-data.multicompany-create.select-all": "Seleziona tutti",
    "c-manage-data.multicompany-create.deselect-all": "Deseleziona tutti",
    "c-manage-data.multicompany-create.confirm": "Confermi l'inserimento dei dati?",
    "c-manage-data.multicompany-create.backfromsuccess": "Torna alla homepage",
    "c-manage-data.multicompany-tech-create.backfromsuccess":
        "L'operazione è stata presa in carico con successo. A breve potrai visualizzare la nuova utenza tecnica nella lista.",
    "c-manage-data.multicompany-create.congratulations": "Congratulazioni!",
    "c-manage-data.multicompany-create.user_success": "L'utente è stato creato con successo",
    "c-manage-data.multicompany-create.user_success_edit": "L'utente è stato modificato con successo",
    "c-manage-data.multicompany-edit.no-edit": "Nessuna modifica rilevata",
    "c-manage-data.multicompany-create.generic-error": "Errore in creazione utente",
    "c-manage-data.multicompany-edit.generic-error": "Errore in modifica utente",
    "c-manage-data.multicompany-create.same-email": "Inserisci un'email diversa da quella associata alla tua utenza",

    "c-manage-data.delete-api-key.company-not-validated":
        "Cancellazione applicativo non consentita per le aziende non validate",

    "c-manage-data.multicompany-edit.no-roles": "Configurare almeno un ruolo",
    "c-manage-data.company.search.title": "Per quale azienda vuoi operare?",
    "c-manage-data.getStudioTotal": "Hai a disposizione {totalStudio} studi",
    "c-manage-data.getOneStudio": "Di seguito puoi selezionare il tuo studio",
    "c-manage-data.totalItems-button-text": "Tutti",
    "c-manage-data.onlyStudio-button-text": "I miei studi",

    "c-manage-data.company.search.isUserWithTooManyItems.title":
        "Hai a disposizione {totalItems} aziende. Per migliorare le prestazioni le caricheremo dopo che hai avviato una ricerca per selezionare l'azienda con cui vuoi operare.",
    "c-manage-data.company.search.subtitle": "Clicca {icon} per selezionare l'azienda",
    "c-manage-data.company.search.notfound":
        "Non sono presenti aziende corrispondenti a {searchText}. Affina la ricerca per trovare l’azienda con cui vuoi operare",
    "c-manage-data.company.card.status": "Stato azienda:",
    "c-manage-data.company.card.person-status": "Stato:",
    "c-manage-data.company.card.taxId": "Codice fiscale:",
    "c-manage-data.company.card.vatNumber": "Partita iva:",
    "c-manage-data.company.card.type": "Tipologia:",
    "c-manage-data.company.card.company": "AZIENDA",
    "c-manage-data.company.card.studio": "STUDIO",
    "c-manage-data.company.card.building": "CONDOMINIO",
    "c-manage-data.company.card.person": "PERSONA",
    "c-manage-data.company.card.association": "ASSOCIAZIONE",
    "c-manage-data.company.card.editIcon": "Modifica i dati della tua azienda",
    "c-manage-data.company.card.modifyIcon": "Visualizza i dati dell'azienda",
    "c-manage-data.company.card.notificationsIcon": "Configura le notifiche",
    "c-manage-data.company.card.person-editIcon": "Modifica i dati anagrafici",
    "c-manage-data.company.card.person-modifyIcon": "Visualizza i dati anagrafici",
    "c-manage-data.company.modal.edit.title": "Modifica i dati anagrafici dell'azienda",
    "c-manage-data.company.modal.display.title": "Visualizza i dati anagrafici dell'azienda",
    "c-manage-data.company.page.companyCount": "{start}-{end} di {total} aziende",

    "c-manage-data.edit-details.title": "PROFILO UTENTE",
    "c-manage-data.edit-details.subtitle": "Gestisci i dati legati al tuo profilo utente",
    "c-manage-data.edit-details.registry-title": "Dati Anagrafici",
    "c-manage-data.edit-details.main-title": "Modifica dati del profilo utente",
    "c-manage-data.edit-details.tsid-title": "TeamSystem ID",
    "c-manage-data.edit-details.profile-title": "Informazioni personali",
    "c-manage-data.edit-details.profile-warning":
        "A breve sarà possibile effettuare l'accesso a TS Digital solo con il TeamSystem ID. Le credenziali personali verranno disabilitate.",
    "c-manage-data.edit-details.profile-open-info": `<a href="https://agyo.elevio.help/it/articles/246" target="_blank">Per saperne di più</a>`,
    "c-manage-data.edit-details.profile-description":
        "Il TeamSystem ID (TS ID) è un'utenza che ti permette di accedere a TS Digital e ai software di TeamSystem in totale sicurezza, con un unico account. Se desideri modificare le tue informazioni o la tua password <b>accedi all'area personale (MyIdentity) del TeamSystem ID</b>.",
    "c-manage-data.edit-details.upload-logo-button-text": "Carica foto",
    "c-manage-data.edit-details.delete-logo-button-text": "Rimuovi foto",
    "c-manage-data.edit-details.name-confirm": "Dati Anagrafici salvati con successo",
    "c-manage-data.edit-details.password-confirm": "Password modificata con successo",
    "c-manage-data.edit-details.password-error": "Errore in modifica password: {errMsg} ",
    "c-manage-data.edit-details.sso-confirm": "TSID collegato con successo",
    "c-manage-data.edit-details.sso-error": "Errore in collegamento TSID: {errMsg} ",
    "c-manage-data.edit-details.name-error": "Errore in modifica dati anagrafici",
    "c-manage-data.edit-details.too-large-image": "Immagine troppo grande.",
    "c-manage-data.edit-details.wrong-format": "Formato non accettato.",
    "c-manage-data.edit-details.max-large-image": "Dimensioni massime consentite: 1MB.",
    "c-manage-data.edit-details.accepted-format": "Formato accettati: .jpg, .png, .gif.",
    "c-manage-data.edit-details.personal-area": "Accedi all'area personale",

    "c-connections-warning.title": "Attenzione! Stai per creare una nuova azienda su TS Digital",
    "c-connections-warning.text":
        "Tale operazione permetterà di inviare / ricevere documenti per conto della stessa. Assicurati di disporre di tutti i permessi e di aggiornarli in caso di modifica. Puoi trovare maggiori informazioni consultando le nostre <a href='https://tc.teamsystem.com/AGYO.pdf' target='_blank' >condizioni generali.</a>",

    "c-billing.b2b-passive.title": "eRICEVI",
    "c-billing.b2b-passive.description":
        "Ricevi le fatture in formato elettronico dai tuoi fornitori iscritti a TS Digital e anche tramite il Sistema di Interscambio. Potrai vederle direttamente nel tuo gestionale o applicativo di studio integrato con TS Digital o in TS Digital Invoice. Puoi inoltre gestire le fatture ricevute tramite altri strumenti, come per es. la PEC.",
    "c-billing.b2b-passive.tooltip":
        "<b>Nota:</b><br />per ricevere le fatture elettroniche basterà comunicare ai propri fornitori che inviano tramite il Sistema di Interscambio il codice M5UXCR1 (non è necessario il CUU - Codice Univoco Ufficio)",
    "c-billing.crt.title": "CRT",
    "c-billing.crt.description": "Corrispettivi RT",
    "c-billing.crt.tooltip": "Corrispettivi RT",
    "c-billing.email-modal.title": "Configurazione servizio Email",
    "c-billing.sdi.description":
        "Dal tuo gestionale o applicativo di studio integrato con TS Digital o direttamente da TS Digital Invoice invii le fatture elettroniche a clienti privati e PA attraverso il Sistema di Interscambio dell’Agenzia delle Entrate.",
    "c-billing.sdi.tooltip": "SDI - Sistema di Interscambio",
    "c-billing.nso.description":
        "In conformità alla normativa sugli ordini elettronici della PA, gestisci tutti i flussi documentali sia attivi (invio dell’ordine da parte dell’azienda del SSN) che passivi (ricezione dell’ordine da parte del fornitore dell’azienda del SSN), oltre che la conservazione degli ordini e delle relative risposte.",
    "c-billing.nso.tooltip": "NSO – Nodo Smistamento Ordini",

    "c-create-form.vat-number-tax-id-not-valid": "Partiva iva o codice fiscale già registrati",

    "c-create-personal-user.select-different-email": "Inserisci un'email diversa da quella associata alla tua utenza",

    "c-createcompany-confirm.title": "Attenzione!",
    "c-createcompany-confirm.text":
        "Se sei uno studio e vuoi creare aziende da gestire vai alla pagina delle connessioni.",
    "c-createcompany-confirm.subText": "Proseguendo invece potrai creare un'azienda della tua holding/corporate.",

    "c-expired-password.title": "Password scaduta. Inserisci una nuova password.",
    "c-expired-password.must-change-password": "La password nuova non può essere uguale alla vecchia.",

    "c-edit-user.page.subtitle": "Visualizza e modifica i permessi già impostati o assegna nuovi ruoli all'utente",

    "c-edit-user.page.desc":
        "Qui trovi le aziende per cui l’utente <b>{user}</b> ha dei permessi. Puoi modificare i permessi attivi per ogni singola azienda e aggiungerne di nuovi.",

    "c-edit-user.page.edit.tab": "Modifica permessi per azienda",
    "c-edit-user.page.recap.tab": "Riepilogo permessi utente",

    "c-reset-password.title": "Inserisci la nuova password che vuoi utilizzare.",
    "c-reset-password.expiredPassword.label": "Password attuale",
    "c-reset-password.newPassword.label": "Nuova password",
    "c-reset-password.confirmNewPassword.label": "Conferma password",
    "c-reset-password.expiredPassword.placeholder": "Inserisci la password attuale",
    "c-reset-password.password.placeholder": "Inserisci la nuova password",
    "c-reset-password.confirmPassword.placeholder": "Inserisci nuovamente la password",
    "c-reset-password.confirm.button": "Conferma",
    "c-reset-password-error.wrong.password": "password attuale non corretta",
    "c-reset-password.save.label": "Cambia password",

    "c-reset-password-success.title": "Complimenti, password modificata con successo!",
    "c-reset-password-success.button": "Prosegui",
    "c-reset-password-success.loginFailed": "Email o password non valide",

    "c-change-password.title": "Modifica password",
    "c-change-password.password.placeholder": "Nuova Password",
    "c-change-password.confirmPassword.placeholder": "Conferma Password",
    "c-change-password.confirm.button": "Salva",
    "c-change-password.confirm.error": "Errore: {errMsg} ",
    "c-change-password.confirm.tooltip1": "La password deve essere lunga almeno 8 caratteri e deve contenere:",
    "c-change-password.confirm.tooltip2": "- almeno una lettera maiuscola: A-Z",
    "c-change-password.confirm.tooltip3": "- almeno una lettera minuscola: a-z",
    "c-change-password.confirm.tooltip4": "- almeno un numero: 0-9 ",
    "c-change-password.confirm.tooltip5": "- almeno un carattere speciale:  !@#£%?ç$%^&*)(+=._-",

    "c-accounting.title": "Movimenti bancari",
    "c-accounting.description":
        "Importa e riconcilia automaticamente i movimenti bancari tuoi o dei tuoi clienti nel tuo software gestionale. Puoi acquisire i dati in maniera completamente automatica con connessione sicura con il CBI grazie a NEXI o semi - automatica mediante importazione di file excel o file CBI (formato RH o txt) presente nell’home-banking.",

    "c-invoice-trading.description": "Incassa Subito",

    "c-bad-width.enlarge": "Aumenta le dimensioni dello schermo",
    "c-bad-width.turn": "Gira il tuo dispositivo",

    "c-user-connect.connect-tsid": "TeamSystem ID (TSID)",
    "c-user-connect.connect-tsid.subtitle":
        "Per collegare il tuo account utente a un TeamSystem ID è necessario verificare le tue credenziali.",
    "c-user-connect.tsid.label": "TeamSystem ID",
    "c-user-connect.tsid-placeholder": "Inserisci TeamSystem ID",
    "c-user-connect.tsid-password-placeholder": "Inserisci password TeamSystem ID",
    "c-user-connect.connect-label": "Collega TSID a questo account",
    "c-user-connect.disconnect": "Scollega TSID da questo account",
    "c-user.no-description": "Descrizione: Nessuna",

    "c-certificate-create.description": "Inserisci tutti i dati per poter creare un nuovo certificato.",
    "c-certificate-create.signer-data": "Dati Firmatario",
    "c-certificate.document-info": "Informazioni documento",
    "c-certificate.save-pin": "Vuoi che TS Digital salvi il tuo pin?",
    "c-certificate.confirmation-note":
        "Si ricorda che questa scelta è necessaria per poter gestire il processo di firma direttamente dall'applicativo integrato ad TS Digital Signature. Con questa scelta si autorizza TS Digital a memorizzare in maniera sicura ed utilizzare il codice PIN al solo fine di automatizzare le operazioni richieste dall'utente",

    "c-signature.pin-reset": "Reset PIN ",
    "c-signature.pin-reset-description":
        "Inserisci il codice PUK che hai ricevuto nell'email al momento della sottoscrizione.",
    "c-signature.pin-reset-error": "Errore durante il reset del pin.",
    "c-signature.pin-edit-ok": "Pin resettato correttamente.",
    "c-signature.delete-certificate-signer": "Sei sicuro di voler eliminare il certificato del firmatario?",
    "c-signature.send-pdf": "Invia PDF alla CA",
    "c-signature.instruction": "Clicca o trascina il file in questa area per caricarlo",
    "c-signature.instruction2": "Trovi il PDF da scaricare già precompilato nella schermata del certificato",
    "c-signature.remote-mass-sign": "Firma Remota Massiva",
    "c-signature.remote-secure-sign": "Firma Sicura Mobile",
    "c-signature.certificate-desc": "Scheda Certificato",
    "c-signature.certificate-of": "Certificato di ",
    "c-signature.signer-desc": "Scheda Firmatario",
    "c-signature.must-choice":
        "Si ricorda che questa scelta è necessaria per poter gestire il processo di firma direttamente dall'applicativo integrato ad TS Digital Signature. Con questa scelta si autorizza TS Digital a memorizzare in maniera sicura ed utilizzare il codice PIN al solo fine di automatizzare le operazioni richieste dall'utente",
    "c-signature.type-pin-fiscal-code": "Inserisci il Codice fiscale e il Pin del firmatario a cui vuoi collegarti.",
    "c-signature.create-error": "Errore durante la creazione del firmatario. ",
    "c-signature.create-success": "Firmatario creato correttamente.",
    "c-signature.link-certificate": "Collega certificato",
    "c-signature.read-guide":
        "Si prega di leggere la guida e scaricare tutta la documentazione in bozza prima di sottoscrivere condizioni generali e modulo di adesione cliccando ",
    "c-signature.new-certificate": "Nuovo certificato",
    "c-signature.new-certificate.con-delega": "Nuovo certificato con delega",
    "c-signature.download-sw-wacom": " Download SW Wacom",
    "c-signature.frq-title": "Firma Elettronica Qualificata Remota (FEQ Remota)",
    "c-signature.frq-desc":
        "Invii il documento da sottoscrivere tramite e-mail a ogni firmatario che lo consulta e lo sottoscrive con una telefonata, utilizzando una password temporanea.",
    "c-signature.fmq-title": "Firma Elettronica Qualificata Automatica (FEQ Automatica)",
    "c-signature.fmq-desc":
        "Sottoscrivi in automatico i documenti in maniera sincrona, utilizzando il certificato di Firma Remota Massiva precedentemente configurato.",
    "c-signature.fra-title": "Firma Elettronica Avanzata Remota (FEA Remota)",
    "c-signature.fra-desc":
        "Utilizza il telefono cellulare del firmatario come strumento di strong authentication e identification. I firmatari specificati riceveranno un’e-mail per la consultazione, la verifica e la firma del documento.",
    "c-signature.fga-title": "Firma Elettronica Avanzata Grafometrica (FEA Grafometrica)",
    "c-signature.fga-desc":
        "Per firmare i documenti utilizzando tablet compatibili o una tavoletta grafometrica, senza stampare fogli di carta.",
    "c-signature.fda-title": "Firma da applicativo",
    "c-signature.fda-desc":
        "Selezioni i tuoi documenti direttamente dal tuo applicativo di studio o dal tuo gestionale.",
    "c-signature.agyosign-title": "TS Digital Signature",
    "c-signature.agyosign-desc":
        "La tua applicazione in TS Digital per gestire e controllare i tuoi documenti da firmare con qualsiasi modalità.",

    "c-signature.contract.title": "CONDIZIONI GENERALI DI CONTRATTO",
    "c-signature.contract.definitions": "Definizioni",
    "c-signature.contract.text1":
        "Nelle presenti condizioni generali di contratto (le 'Condizioni Generali'), i termini e le espressioni di seguito elencati, quando riportati con iniziale maiuscola, devono intendersi con il significato ad essi attribuito nel presente paragrafo. I termini indicati al singolare si intendono anche al plurale e viceversa.",
    "c-signature.contract.agid": "AgID",
    "c-signature.contract.text2": ": significa l'Agenzia per l'Italia Digitale;",
    "c-signature.contract.aliaslab": "AliasLab",
    "c-signature.contract.text3":
        ": significa la società Aliaslab S.p.A. (C.F. e P.IVA n. 13152000157), con sede legale in via Durini n. 25, (20121) Milano;",
    "c-signature.contract.atto": "Atto di Affidamento",
    "c-signature.contract.text4": ": significa il modulo allegato alle presenti Condizioni Generali (",
    "c-signature.contract.text5": "Allegato 1",
    "c-signature.contract.text6":
        "), mediante il quale il Terzo Beneficiario affida a TeamSystem gli incarichi e le autorizzazioni necessarie al fine di permettere al Cliente di utilizzare i Servizi Hub in nome e per conto del Terzo Beneficiario medesimo;",
    "c-signature.contract.attodelega": "Atto di Delega",
    "c-signature.contract.text7":
        ": significa, nel Servizio TS Digital Archive, il modulo con il quale il Responsabile della Conservazione del Titolare dei Documenti delega a TeamSystem lo svolgimento di tutti gli adempimenti relativi al processo di conservazione dei Documenti di cui all'articolo 7, comma 1 del Regolamento Conservazione e in conformità a quanto concordato nell'Atto di Delega stesso;",
    "c-signature.contract.cad": "CAD",
    "c-signature.contract.text8":
        ": significa il codice dell'amministrazione digitale di cui al D.Lgs. n. 82 del 2005 e le sue successive modificazioni o integrazioni;",
    "c-signature.contract.ced-prest": "Cedente/Prestatore",
    "c-signature.contract.text9":
        ": significa il soggetto che effettua, rispettivamente, la cessione del bene o la prestazione del servizio ai sensi dell'art. 21, comma 1, D.P.R. n. 633/72;",
    "c-signature.contract.client": "Cliente",
    "c-signature.contract.text10":
        ": significa il soggetto, privato o pubblica amministrazione, identificato dalla procedura di certificazione nella Piattaforma Hub, che può richiedere l'attivazione dei Servizi Hub, per se stesso o, eventualmente, in favore di Terzi Beneficiari;",
    "c-signature.contract.privacy-code": "Codice della Privacy",
    "c-signature.contract.text11":
        " : significa il Decreto Legislativo 30 giugno 2003, n. 196, recante il Codice in materia di protezione dei dati personali e le sue successive modificazioni o integrazioni;",
    "c-signature.contract.anti-corruption": "Codice di Condotta Anti-Corruzione",
    "c-signature.contract.text12":
        ": significa il codice di condotta anti-corruzione adottato da TeamSystem e consultabile al sito 'teamsystem.com';",
    "c-signature.contract.conditions": "Condizioni del Certificatore",
    "c-signature.contract.text13": ": ha il significato di cui al paragrafo 1.6.2.",
    "c-signature.contract.general-conditions": "Condizioni Generali",
    "c-signature.contract.text14": ": significa i presenti termini e condizioni generali di contratto;",
    "c-signature.contract.connectivity": "Connettività",
    "c-signature.contract.text15":
        ": significa la connessione al Data Center effettuata dal Cliente mediante collegamento a una rete di telecomunicazioni o a internet;",
    "c-signature.contract.contract": "Contratto",
    "c-signature.contract.text16":
        ": significa le presenti Condizioni Generali, i relativi allegati, i documenti ivi richiamati e le procedure online per l'accreditamento alla Piattaforma Hub e l'attivazione dei Servizi Hub, ivi incluse le Schede Servizio e le istruzioni online per il relativo utilizzo;",
    "c-signature.contract.corrispettivi": "Corrispettivi",
    "c-signature.contract.text17":
        ": significa le somme, indicate nel modulo d'ordine o nel listino prezzi pubblicato nell'apposita sezione della Piattaforma Hub, che il Cliente corrisponderà a TeamSystem o al soggetto appartenente alla Rete Vendite TS per l'attivazione e l'utilizzo dei Servizi Hub e per gli eventuali ulteriori servizi a pagamento che il Cliente potrà richiedere a TeamSystem in relazione a ciascuno degli altri Servizi Hub;",
    "c-signature.contract.access-credential": "Credenziali di Accesso",
    "c-signature.contract.text18":
        ": significa il codice di identificazione e le chiavi di accesso forniti da TeamSystem, necessari per l'utilizzo della Piattaforma Hub e dei Servizi Hub attivati ed associati al Cliente;",
    "c-signature.contract.data-center": "Data Center",
    "c-signature.contract.text19":
        ": significa i centri servizi che ospitano i server interconnessi, di proprietà di TeamSystem o di terzi, che gestiscono ed archiviano le Fatture Elettroniche, i Documenti e i dati trasmessi dal Cliente in occasione dell'uso della Piattaforma Hub e dei Servizi Hub attivati;",
    "c-signature.contract.dip-coll": "Dipendenti o Collaboratori",
    "c-signature.contract.text20":
        ": significa, nel Servizio di Firma Digitale, i dipendenti o i collaboratori del Cliente, da quest'ultimo espressamente autorizzati e in favore dei quali viene richiesto l'emissione di un certificato qualificato al fine di fruire del Servizio di Firma Digitale;",
    "c-signature.contract.fea-doc": "Documentazione FEA",
    "c-signature.contract.text21":
        ": significa, nel Servizio di FEA, la documentazione disponibile nell'apposita sezione della Piattaforma Hub che il Cliente ha la facoltà di utilizzare allo scopo di ottenere il consenso del terzo all'utilizzo del Servizio di FEA ai fini della sottoscrizione dei documenti informatici trattati nell'ambito del rapporto tra il Cliente e il terzo;",
    "c-signature.contract.documents": "Documenti",
    "c-signature.contract.text22":
        ": significa, nel Servizio TS Digital Archive, i documenti informatici, i fascicoli informatici e le aggregazioni documentali informatiche (e i meta-dati ed essi associati) del Cliente o del Terzo Beneficiario, che costituiscono il pacchetto di versamento di input che viene poi trasformato secondo lo standard UNI SInCRO dal Servizio TS Digital Archive nel pacchetto di versamento di cui all'articolo 4, comma 1, lett. a) del Regolamento Conservazione;",
    "c-signature.contract.emissions-for-others": "Emissione per Conto Terzi",
    "c-signature.contract.text23":
        ": significa, nel Servizio B2B e nel Servizio SDI, l'affidamento a TeamSystem da parte del Cedente/Prestatore dell'emissione delle Fatture Elettroniche tramite l'apposizione della firma digitale, nelle modalità previste dall'articolo 21, terzo comma, del D.P.R. n. 633/72;",
    "c-signature.contract.ele-invoice": "Fattura Elettronica",
    "c-signature.contract.text24":
        ": significa la fattura scambiata in modalità elettronica da un Cedente/Prestatore con un destinatario/acquirente attraverso il Servizio B2B o il Servizio SDI, in formato XML secondo il tracciato definito nella relativa Scheda Servizio;",
    "c-signature.contract.fea": "FEA",
    "c-signature.contract.text25":
        ": significa firma elettronica avanzata, in conformità alla definizione di cui agli artt. 1, n. 11 e 26 del Regolamento eIDAS;",
    "c-signature.contract.fea-graph": "FEA Grafometrica",
    "c-signature.contract.text26":
        ": significa la soluzione di FEA basata sull'acquisizione dinamica dei dati della firma autografa del sottoscrittore mediante speciali tavolette di acquisizione ( tablet grafometrici) o dispositivi tablet equipaggiati con opportuni sensori e software;",
    "c-signature.contract.fea-mobility": "FEA in Mobilità",
    "c-signature.contract.text27":
        ": significa la soluzione di FEA basata sul sistema dell'inserimento di una OTP ( one time password ) nel corso di una chiamata ad un numero verde effettuata attraverso la SIM card del sottoscrittore;",
    "c-signature.contract.digital-sign": "Firma Digitale",
    "c-signature.contract.text28":
        ": significa il particolare tipo di firma qualificata basata su un sistema di chiavi crittografiche, una pubblica e una privata, correlate tra loro, che consente al titolare della firma, tramite la chiave privata, e al destinatario, tramite la chiave pubblica, di rendere manifesta e di verificare la provenienza e l'integrità di un documento informatico o di un insieme di documenti informatici, in conformità agli articoli 1 lett. s) e 24 del CAD;",
    "c-signature.contract.digital-sign-auto": "Firma Digitale Automatica",
    "c-signature.contract.text29":
        "significa la procedura di apposizione automatica di Firma Digitale su uno o più; documenti informatici senza la necessità; che tali documenti informatici siano sottoposti al firmatario prima dell'apposizione della firma, eseguita previa autorizzazione del titolare della firma che mantiene il controllo esclusivo delle proprie chiavi di firma, in assenza di presidio puntuale e continuo da parte di questo, ai sensi del combinato disposto di cui agli articoli 1 lett. r) del D.C.P.M. del 22 febbraio 2013 e 35, 3° comma del CAD;",
    "c-signature.contract.digital-sign-remote": "Firma Digitale Remota",
    "c-signature.contract.text30":
        ": significa la procedura di apposizione di Firma Digitale su un documento informatico nel quale la chiave privata del firmatario è conservata, unitamente al relativo certificato di firma, dal certificatore accreditato in un HSM, dispositivo remoto sicuro, e nel quale la Firma Digitale è generata dal firmatario, consentendo di garantire il controllo esclusivo delle chiavi private da parte dei titolari delle stesse, mediante l'utilizzo di strumenti di autenticazione indicati nella relativa Scheda Servizio o successivamente adottati da TeamSystem e comunicati al Cliente, ai sensi degli articoli 1 lett. q) e 3, comma 4 del D.C.P.M. del 22 febbraio 2013;",
    "c-signature.contract.incaricato-reg": "Incaricato alla Registrazione",
    "c-signature.contract.text31":
        ": significa, nel Servizio di Firma Digitale, il soggetto che effettua le operazioni di riconoscimento del richiedente e di inserimento dei dati necessari alla generazione dei certificati qualificati nell'apposita maschera messa a disposizione nell'ambito della Piattaforma Hub;",
    "c-signature.contract.storage-manual": "Manuale della Conservazione",
    "c-signature.contract.text32":
        ": significa, nel Servizio TS Digital Archive, il documento, redatto ai sensi del Regolamento Conservazione e della procedura di accreditamento AgID, che illustra l'organizzazione, i soggetti coinvolti e i ruoli svolti dagli stessi, il modello di funzionamento, la descrizione del processo, la descrizione delle architetture e delle infrastrutture utilizzate, le misure di sicurezza adottate e ogni altra informazione utile alla gestione e alla verifica del funzionamento, nel tempo, del sistema di conservazione e del Servizio TS Digital Archive di TeamSystem. TeamSystem mette a disposizione di tutti i Titolari dei Documenti il proprio Manuale della Conservazione nell'apposita sezione della Piattaforma Hub e sul sito internet di AgID, soggetto a variazioni sulla base delle esigenze tecniche e dell'evoluzione normativa. Sulla base di quanto espressamente previsto nelle Schede Servizio e nell'Atto di Delega, il Manuale della Conservazione di TeamSystem potrà essere oggetto di eventuale personalizzazione, a cura e sotto l'esclusiva responsabilità del Titolare dei Documenti, allo scopo di creare il Manuale della Conservazione del Cliente e/o del Terzo Beneficiario;",
    "c-signature.contract.organization-model": "Modello Organizzativo",
    "c-signature.contract.text33":
        ": significa il modello di organizzazione, gestione e controllo adottato da TeamSystem ai sensi del d.lgs. 231/2001 e consultabile al sito teamsystem.com/compliance;",
    "c-signature.contract.pa": "PA",
    "c-signature.contract.text34": ": significa la pubblica amministrazione;",
    "c-signature.contract.parti": "Parti",
    "c-signature.contract.text35": ": significa TeamSystem e il Cliente;",
    "c-signature.contract.partner": "Partner",
    "c-signature.contract.text36":
        ": significa il/i soggetto/i individuato/i da TeamSystem e che collabora/no con quest'ultima per la prestazione dei Servizi Hub, ivi inclusa, ma non limitatamente a, AliasLab quale soggetto che realizza le soluzioni di FEA oggetto del presente Contratto;",
    "c-signature.contract.pec": "PEC",
    "c-signature.contract.text37": ": significa posta elettronica certificata;",
    "c-signature.contract.platform": "Piattaforma Hub",
    "c-signature.contract.text38":
        ": significa la piattaforma online denominata 'TS Digital', di titolarità di TeamSystem, che costituisce il sistema centrale di gestione dei Servizi Hub erogati da TeamSystem;",
    "c-signature.contract.productor": "Produttore",
    "c-signature.contract.text39":
        ": significa, nel Servizio TS Digital Archive, il soggetto responsabile del trasferimento dei Documenti nel Data Center, secondo le modalità previste dal Manuale della Conservazione, dalla Scheda Servizio TS Digital Archive e dal Regolamento Conservazione;",
    "c-signature.contract.intel-prop": "Proprietà Intellettuale",
    "c-signature.contract.text40":
        ": significa ogni diritto di proprietà intellettuale e/o industriale, registrato o non registrato, in tutto o in parte, ovunque nel mondo, di titolarità di TeamSystem o di società appartenenti al medesimo gruppo o comunque collegate a TeamSystem ai sensi dell'articolo 2359 del codice civile, quali - a titolo esemplificativo e non esaustivo - marchi, brevetti, modelli di utilità, disegni e modelli, nomi a dominio, know-how, opere coperte dal diritto d'autore, database e software (ivi inclusi, ma non limitatamente a, le sue derivazioni, il codice sorgente, il codice oggetto e le interfacce);",
    "c-signature.contract.storage-rules": "Regolamento Conservazione",
    "c-signature.contract.text41":
        ": significa il D.C.P.M. del 3 dicembre 2013, 'Regole tecniche in materia di conservazione ai sensi degli articoli 20, commi 3 e 5-bis, 23-ter. Comma 4, 43, commi 1 e 3, 44, 44-bis e 71, comma 1, del Codice dell'amministrazione digitale di cui al decreto legislativo n. 82 del 2005';",
    "c-signature.contract.eidas-rules": "Regolamento eIDAS",
    "c-signature.contract.text42":
        ": significa il Regolamento (UE) n. 910/2014 del Parlamento Europeo e del Consiglio del 23 luglio 2014, in materia di identificazione elettronica e sevizi fiduciari per le transazioni elettroniche nel mercato interno che abroga la direttiva 1999/93/CE;",
    "c-signature.contract.reporting": "Reporting",
    "c-signature.contract.text43":
        ": significa la generazione, da parte della Piattaforma Hub, di un report  contenente lo stato delle Fatture Elettroniche riferite al Cliente e/o al Terzo Beneficiario nella Piattaforma Hub e lo stato di caricamento dei Documenti;",
    "c-signature.contract.storage-admin": "Responsabile della Conservazione",
    "c-signature.contract.text44":
        ": significa, nel Servizio TS Digital Archive, la persona fisica a tale scopo designata che opera presso il Titolare dei Documenti, la quale assume la responsabilità della gestione del sistema di conservazione ai sensi del Regolamento Conservazione;",
    "c-signature.contract.selling-network": "Rete Vendite TS",
    "c-signature.contract.text45":
        ": significa la rete di vendita di TeamSystem comprensiva di tutti i relativi canali di distribuzione, presenti e futuri, che TeamSystem utilizza per la commercializzazione di prodotti software (ivi inclusi, a titolo di esempio, la rete commerciale, l'e-commerce, gli agenti, i distributori e le società del Gruppo TeamSystem);",
    "c-signature.contract.service-desc": "Schede Servizio",
    "c-signature.contract.text46":
        ": significa i documenti contenenti le specifiche normative tecniche, funzionali e procedurali di ciascun Servizio Hub, pubblicati all'indirizzo www.agyo.io/it/schedeservizio, che il Cliente dichiara espressamente di aver visionato;",
    "c-signature.contract.sdi": "SDI",
    "c-signature.contract.text47":
        ": significa il sistema di interscambio di cui all'articolo 1, comma 211 della L. 24 dicembre 2007, n. 244 e al Decreto Ministeriale 13 aprile 2013, n. 55;",
    "c-signature.contract.b2b": "Servizio B2B",
    "c-signature.contract.text48":
        ": significa il servizio di fatturazione elettronica negli scambi commerciali tra operatori economici privati fornito da TeamSystem attraverso la Piattaforma Hub, attivabile attraverso apposita funzionalità di attivazione dal Cliente, il quale, come meglio specificato nella relativa Scheda Servizio, potrà richiedere l'attivazione del servizio per la ricezione e l'invio delle fatture (ciclo attivo e passivo) o solamente per l'invio delle medesime (ciclo attivo);",
    "c-signature.contract.cct": "Servizio TS Digital Archive",
    "c-signature.contract.text49":
        " : significa il servizio per la conservazione a norma dei Documenti ai sensi del CAD e del Regolamento Conservazione fornito da TeamSystem (in qualità di conservatore accreditato dall'AgID ai sensi dell'art. 44-bis del CAD e della circolare AgID n. 65 del 10 aprile 2014) attraverso la Piattaforma Hub, attivabile dal Cliente tramite apposita funzionalità di attivazione e previa sottoscrizione, ove richiesto da TeamSystem, del relativo contratto;",
    "c-signature.contract.exchange": "Servizio Exchange",
    "c-signature.contract.text50":
        ": significa la messa a disposizione del Cliente, nell'ambito della Piattaforma Hub, di servizi di trasmissione e ricezione di documenti (tra i quali, a titolo esemplificativo e non esaustivo, le fatture e gli ordinativi), mediante diversi sistemi individuati e descritti nella relativa Scheda Servizio, tra i quali, a titolo esemplificativo e non esaustivo, la posta elettronica ordinaria e certificata;",
    "c-signature.contract.fea-service": "Servizio di FEA",
    "c-signature.contract.text51":
        ": significa il servizio attraverso il quale il Cliente può consentire a terzi di apporre una FEA Grafometrica o una FEA in Mobilità ad uno o più documenti informatici trattati nell'ambito dei rapporti giuridici intercorrenti tra il Cliente ed il terzo, in conformità alla relativa Scheda Servizio o alle successive istruzioni fornite da TeamSystem e previa sottoscrizione, da parte del terzo, della Documentazione FEA;",
    "c-signature.contract.sign-service": "Servizio di Firma",
    "c-signature.contract.text52": " : significa, congiuntamente, il Servizio di FEA e il Servizio di Firma Digitale;",
    "c-signature.contract.digital-sign-service": "Servizio di Firma Digitale",
    "c-signature.contract.text53":
        ": significa il servizio attraverso il quale il Cliente (o il suo Dipendente e Collaboratore) può firmare digitalmente documenti informatici mediante l'apposizione di una Firma Digitale Remota o di una Firma Digitale Automatica e/o consentire a Terzi Beneficiari di firmare digitalmente documenti informatici mediante l'apposizione di una Firma Digitale Remota, in ogni caso previo rilascio di un certificato qualificato da parte del certificatore accreditato individuato ad esclusiva discrezione di TeamSystem, in conformità alla relativa Scheda Servizio;",
    "c-signature.contract.poste-service": "Servizio Poste",
    "c-signature.contract.text54":
        ": significa la messa a disposizione del Cliente, nell'ambito della Piattaforma Hub, del servizio di stampa ed invio cartaceo delle fatture fornito dalla società Postel S.p.A.;",
    "c-signature.contract.sdi-service": "Servizio SDI",
    "c-signature.contract.text55":
        ": significa il servizio di invio e/o ricezione, attraverso il SDI, di Fatture Elettroniche tra privati o con la PA, attivabile dal Cliente attraverso apposita funzionalità di attivazione della Piattaforma Hub, in conformità a quanto previsto dalla/e relativa/e Scheda/e Servizio;",
    "c-signature.contract.telematici-fiscali-service": "Servizio Telematici Fiscali",
    "c-signature.contract.text56":
        ": significa il servizio di invio all'Agenzia delle Entrate della 'comunicazione liquidazioni periodiche IVA' e/o dei 'dati delle fatture emesse e ricevute' del Cliente e/o del Terzo Beneficiario, in file formato XML sottoscritto dal Cliente con firma elettronica qualificata o digitale, nonchè; delle eventuali future comunicazioni o dichiarazioni indirizzabili in via telematica all'Agenzia delle Entrate alle quali TeamSystem potrà discrezionalmente estendere il servizio, attivabile dal Cliente attraverso apposita funzionalità di attivazione della Piattaforma Hub, in conformità a quanto previsto dalla relativa Scheda Servizio;",
    "c-signature.contract.hub-services": "Servizi Hub",
    "c-signature.contract.text57":
        ": significa, congiuntamente, il Servizio B2B, il Servizio SDI, il Servizio TS Digital Archive, il Servizio PEC, il Servizio Exchange, il Servizio di Firma e il Servizio Telematici Fiscali, nonchè gli eventuali futuri e ulteriori servizi attivabili dal Cliente mediante la Piattaforma Hub. Le presenti Condizioni Generali troveranno applicazione anche in relazione a ciascuno dei Servizi Hub eventualmente attivati;",
    "c-signature.contract.teamsystem": "TeamSystem",
    "c-signature.contract.text58":
        ": significa la società TeamSystem Service s.r.l. (C.F. e P. IVA n. 01641790702), in persona del legale rappresentante pro tempore, con sede legale in via Martiri della Repubblica Partenopea 2, 86025 - Ripalimosani (CB);",
    "c-signature.contract.third-ben": "Terzo Beneficiario",
    "c-signature.contract.text59":
        ": significa, alternativamente, (a) la persona fisica o giuridica cliente del Cliente o (b) la società controllata dal Cliente ai sensi dell'art. 2359, 1° comma, n. 1 c.c., in favore della quale il Cliente richiede l'attivazione dei Servizi Hub;",
    "c-signature.contract.document-admin": "Titolare dei Documenti (o Soggetto Produttore)",
    "c-signature.contract.text60":
        "significa il Cliente o il Terzo Beneficiario titolare dei Documenti oggetto del Servizio TS Digital Archive e tenuto, in base alla normativa vigente, alla conservazione dei Documenti medesimi;",
    "c-signature.contract.sign-admin": "Titolare di Firma",
    "c-signature.contract.text61":
        ": significa il soggetto Cliente o Terzo Beneficiario, in proprio, ove consentito dalla legge applicabile, e/o Dipendente o Collaboratore al quale è attribuita la Firma Digitale per il relativo utilizzo;",
    "c-signature.contract.secondary-user": "Utenze Secondarie",
    "c-signature.contract.text62":
        ": significa le utenze create e assegnate dal Cliente che permettono agli eventuali uffici di fatturazione facenti parte della struttura aziendale del Cliente medesimo di usufruire della Piattaforma Hub e dei Servizi Hub attivati con livelli di privilegio di volta in volta stabiliti dal Cliente.",
    "c-signature.contract.hub-services-11": "Art. 1 - Servizi Hub",
    "c-signature.contract.text63": "Fatturazione Elettronica tra Privati (Servizio B2B)",
    "c-signature.contract.text64": "1.1.1 Con l'attivazione da parte del Cliente del Servizio B2B, TeamSystem:",
    "c-signature.contract.text65":
        "(a) a fronte del pagamento dei Corrispettivi, concede al Cliente, che accetta, una licenza d'uso, non esclusiva, non cedibile e temporanea del Servizio B2B, ai termini e alle condizioni indicate nel Contratto e, in particolare, in conformità a quanto previsto nella relativa Scheda Servizio;",
    "c-signature.contract.text66":
        "(b) fermo restando quanto previsto dall'articolo 4.1(f) che segue, si impegna a concedere ai Terzi Beneficiari, su richiesta del Cliente, l'utilizzo del Servizio B2B.",
    "c-signature.contract.text67": "1.1.2 Con l'attivazione da parte del Cliente del Servizio B2B, il Cliente:",
    "c-signature.contract.text68":
        "(a) affida a TeamSystem, che accetta, l'incarico di Emissione per Conto Terzi delle Fatture Elettroniche per conto del Cliente e/o del Terzo Beneficiario, ferma restando la piena assunzione di responsabilità del Cedente/Prestatore circa la correttezza, la completezza e la veridicità di tutti i dati e informazioni contenuti nei file Fatture Elettroniche trasferiti al Servizio B2B;",
    "c-signature.contract.text69":
        "(b) autorizza TeamSystem, che accetta, a trasmettere e ricevere le Fatture Elettroniche rispettivamente a e da gli altri operatori economici certificati o validati sulla Piattaforma Hub e che utilizzano il Servizio B2B, nonchè a comunicare e rendere nota la disponibilità del Cliente medesimo e/o del Terzo Beneficiario a ricevere le Fatture Elettroniche da altri operatori economici mediante la Piattaforma Hub e il Servizio B2B.",
    "c-signature.contract.text70": "Servizio SDI",
    "c-signature.contract.text71": "Con l'attivazione da parte del Cliente del Servizio SDI, TeamSystem:",
    "c-signature.contract.text72":
        "(a) a fronte del pagamento dei Corrispettivi, concede al Cliente, che accetta, una licenza d'uso, non esclusiva, non cedibile e temporanea del Servizio SDI, ai termini e alle condizioni indicate nel Contratto e, in particolare, in conformità a quanto previsto nella/e relativa/e Scheda/e Servizio;",
    "c-signature.contract.text73":
        "(b) fermo restando quanto previsto dall'articolo 4.1(f) che segue, si impegna a concedere ai Terzi Beneficiari, su richiesta del Cliente, l'utilizzo del Servizio SDI.",
    "c-signature.contract.text74": "Con l'attivazione da parte del Cliente del Servizio SDI, il Cliente:",
    "c-signature.contract.text75":
        "(a) affida a TeamSystem, che accetta, l'incarico di Emissione per Conto Terzi delle Fatture Elettroniche per conto del Cliente e/o del Terzo Beneficiario, ferma restando la piena assunzione di responsabilità del Cedente/Prestatore circa la correttezza, la completezza e la veridicità di tutti i dati e informazioni contenuti nei file Fatture Elettroniche trasferiti al Servizio SDI;",
    "c-signature.contract.text76":
        "(b) conferisce a TeamSystem l'incarico di agire in qualità di intermediario verso il SDI per la gestione dei processi di generazione, emissione, trasmissione e/o ricezione delle Fatture Elettroniche e delle relative notifiche.",
    "c-signature.contract.text77": "Conservazione Cloud TeamSystem (Servizio TS Digital Archive)",
    "c-signature.contract.text78":
        "Con l'attivazione da parte del Cliente del Servizio TS Digital Archive, TeamSystem:",
    "c-signature.contract.text79":
        "(a) a fronte del pagamento dei Corrispettivi, concede al Cliente, che accetta, una licenza d'uso non esclusiva, non cedibile e temporanea del Servizio TS Digital Archive, ai termini e alle condizioni indicate nel Contratto e, in particolare, in conformità a quanto previsto nella relativa Scheda Servizio;",
    "c-signature.contract.text80":
        "(b) fermo restando quanto previsto dall'articolo 4.1(f) che segue, si impegna a concedere ai Terzi Beneficiari, su richiesta del Cliente, l'utilizzo del Servizio TS Digital Archive.",
    "c-signature.contract.text81":
        "Con l'attivazione da parte del Cliente del Servizio TS Digital Archive, il Cliente, in qualità di Titolare dei Documenti e/o Produttore:",
    "c-signature.contract.text82":
        "(a) affida a TeamSystem, che accetta, l'incarico di conservatore delegato dei Documenti ai sensi dell'art. 6, comma 7 e dell'art. 5 comma 2 lett. b del Regolamento Conservazione, affinchè TeamSystem provveda all'esecuzione del processo di conservazione dei Documenti ai termini e alle condizioni indicate nel Contratto e, in particolare, in conformità a quanto previsto nella relativa Scheda Servizio, nel Manuale della Conservazione e nel rispetto di quanto previsto dal CAD e dal Regolamento Conservazione. In ogni caso, resta inteso fra le Parti che la redazione, emissione, tenuta, generazione e copia dei Documenti prima del relativo caricamento nella Piattaforma Hub per mezzo del Servizio TS Digital Archive è e rimane di esclusiva responsabilità del Titolare dei Documenti;",
    "c-signature.contract.text83":
        "(b) si impegna, anche ai sensi dell'art. 1381 c.c., a far sì; che il Responsabile della Conservazione sottoscriva e trasmetta a TeamSystem, eventualmente per il tramite del Cliente medesimo, l'Atto di Delega.",
    "c-signature.contract.text84": "Servizio Exchange",
    "c-signature.contract.text85":
        "Con l'attivazione del Servizio Exchange, TeamSystem, a fronte del pagamento de Corrispettivi, si impegna a fornire al Cliente il Servizio Exchange, ai termini ed alle condizioni di cui alle presenti Condizioni Generali e alla relativa Scheda Servizio, al fine di permettere al Cliente, attraverso la piattaforma Hub, di trasmettere e ricevere varie tipologie di documenti (tra i quali, a titolo esemplificativo e non esaustivo, le fatture e gli ordinativi), mediante diversi sistemi individuati e descritti nella relativa Scheda Servizio, tra i quali, a titolo esemplificativo e non esaustivo, la posta elettronica ordinaria e certificata.",
    "c-signature.contract.text86":
        "Con l'attivazione del Servizio Poste, TeamSystem, a fronte del pagamento dei Corrispettivi, si impegna a fornire al Cliente il Servizio Poste, ai termini e alle condizioni di cui alle presenti Condizioni Generali e alla relativa Scheda Servizio, allo scopo di permettere al Cliente di usufruire del servizio di stampa ed invio cartaceo delle fatture fornito dalla società Postel S.p.A.",
    "c-signature.contract.text87": "Servizio Poste",
    "c-signature.contract.text88": "Servizio di Firma",
    "c-signature.contract.text89": "Servizio di Firma Digitale - ",
    "c-signature.contract.text90":
        "Con l'attivazione del Servizio di Firma Digitale, TeamSystem, a fronte del pagamento dei Corrispettivi, concede al Cliente, che accetta, una licenza d'uso, non esclusiva, non cedibile e temporanea del Servizio di Firma Digitale, ai temini e alle condizioni di cui al Contratto e in conformità alla relativa Scheda Servizio, allo scopo di consentire:",
    "c-signature.contract.text91":
        "(a) al Cliente (in proprio, ove consentito dalla normativa applicabile) e/o ai Dipendenti e Collaboratori di sottoscrivere documenti informatici mediante l'apposizione di una Firma Digitale Remota o di una Firma Digitale Automatica, e/o",
    "c-signature.contract.text92":
        "(b) ai Terzi Beneficiari di sottoscrivere documenti informatici mediante l'apposizione di una Firma Digitale Remota.",
    "c-signature.contract.text93":
        "Il Cliente prende atto e accetta che l'utilizzo della Firma Digitale Automatica nell'ambito del Servizio di Firma Digitale è espressamente riservata al Cliente medesimo e/o ai propri Dipendenti e Collaboratori.",
    "c-signature.contract.text94":
        "1.6.2 Il Cliente prende sin d'ora atto e accetta che il Servizio di Firma Digitale implica l'accettazione, da parte del Titolare di Firma, dei termini e delle condizioni predisposte dal certificatore accreditato che verrà comunicato da TeamSystem all'attivazione del Servizio di Firma Digitale (le ' Condizioni del Certificatore ').",
    "c-signature.contract.text95":
        "1.6.3 Il Cliente, nel caso in cui sia il Titolare di Firma, acconsente sin d'ora all'adozione della procedura di apposizione della Firma Digitale Automatica in relazione ai documenti informatici sottoscritti, ai sensi e per gli effetti dell'art. 35, 3° comma del CAD.",
    "c-signature.contract.text96":
        "1.6.4 Il Cliente prende atto e accetta che l'ottenimento della qualifica di Incaricato alla Registrazione è condizione necessaria per potere validamente richiedere al certificatore accreditato, comunicato da TeamSystem, l'emissione di certificati qualificati di Firma Digitale in favore di uno o più Terzi Beneficiari e/o di Dipendenti e Collaboratori. Pertanto, in tal caso, il Cliente si impegna a seguire integralmente le procedure pubblicate da TeamSystem nell'apposita sezione della Piattaforma Hub ai fini dell'ottenimento della qualifica di Incaricato alla Registrazione.",
    "c-signature.contract.text97":
        "1.6.5 Il Cliente che abbia ottenuto la qualifica di Incaricato alla Registrazione in conformità al paragrafo che precede e che intenda consentire:",
    "c-signature.contract.text98":
        "(a) ad un Terzo Beneficiario di divenire Titolare di Firma, si impegna a fare sì che quest'ultimo accetti le Condizioni del Certificatore;",
    "c-signature.contract.text99":
        "(b) ad un Dipendente o Collaboratore di divenire Titolare di Firma, si impegna a fare sì, anche ai sensi dell'art. 1381 c.c., che il Dipendente oCollaboratore (i) accetti le Condizioni del Certificatore, e (ii) consenta all'adozione della procedura di apposizione della Firma Digitale Automatica in relazione ai documenti informatici da quest'ultimo sottoscritti, ai sensi dell'art. 35, 3° comma CAD.",
    "c-signature.contract.text100":
        " 1.6.6 Servizio di FEA - Con l'attivazione del Servizio di FEA, TeamSystem, a fronte del pagamento dei Corrispettivi, concede al Cliente, che accetta, una licenza d'uso, non esclusiva, non cedibile e temporanea del Servizio di FEA, ai temini e alle condizioni di cui al Contratto e in conformità alla relativa Scheda Servizio, allo scopo di consentire al Cliente di fare sottoscrivere a terzi i documenti informatici trattati nell'ambito dei rapporti giuridici intercorrenti tra il Cliente ed il terzo mediante l'apposizione di una FEA Grafometrica o di una FEA in Mobilità.",
    "c-signature.contract.text101":
        "1.6.7 Il Cliente, prima di consentire al terzo di utilizzare il Servizio FEA per gli scopi di cui al paragrafo che precede, si impegna a ottenere il consenso scritto del terzo all'utilizzo del Servizio FEA, eventualmente avvalendosi della Documentazione FEA messa a disposizione da TeamSystem nell'apposita sezione della Piattaforma Hub. Il Cliente, in qualità di soggetto che eroga una soluzione di FEA al fine di utilizzarla nei rapporti intrattenuti con il terzo per motivi istituzionali, societari, professionali o commerciali, avvalendosi della soluzione di FEA, è consapevole di essere tenuto al rispetto (i) degli obblighi di cui all'art. 57 del DPCM 22 febbraio 2013, come espressamente identificati e descritti nella relativa Scheda Servizio, nonchè (ii) di tutti gli obblighi su di esso gravanti in qualità di titolare autonomo del trattamento dei dati personali del terzo, da quest'ultimo comunicati in occasione dell'adesione al Servizio di FEA, ivi inclusi, ma non limitatamente a, gli obblighi previsti dal Codice della Privacy e dai provvedimenti del Garante specificamente applicabili a ciascuna tipologia di FEA. Allo scopo di favorire l'assolvimento degli obblighi in materia di protezione dei dati personali, TeamSystem mette a disposizione del Cliente, nella Documentazione FEA, un modello di informativa sul trattamento dei dati personali, restando in ogni caso espressamente inteso che è specifico onere del Cliente validare il contenuto della predetta informativa e modificarne e/o integrarne il contenuto in base alle effettive caratteristiche del trattamento concretamente posto in essere.",
    "c-signature.contract.text102":
        " 1.6.8 Il Cliente prende atto e riconosce che l'individuazione e la gestione dei documenti informatici sottoscritti mediante il Servizio di Firma rimane di propria esclusiva competenza e responsabilità.",
    "c-signature.contract.text103": "Servizio Telematici Fiscali",
    "c-signature.contract.text104":
        "Con l'attivazione da parte del Cliente del Servizio Telematici Fiscali, TeamSystem a fronte del pagamento dei Corrispettivi, concede al Cliente, che accetta, una licenza d'uso, non esclusiva, non cedibile e temporanea del Servizio Telematici Fiscali, ai termini e alle condizioni indicate nel Contratto e in conformità a quanto previsto nella relativa Scheda Servizio.",
    "c-signature.contract.text105":
        "Ai fini della sottoscrizione dei documenti informatici in formato XML da trasmettere all'Agenzia delle Entrate, il Cliente ha la facoltà, ma non l'obbligo, di usufruire del servizio di Firma Digitale Automatica fornito da TeamSystem nell'ambito del Servizio Telematici Fiscali, previa sottoscrizione dell'apposito contratto con il certificatore accreditato indicato da TeamSystem e ai termini e alle condizioni specificate nel relativo modulo d'ordine o nel listino prezzi pubblicato nell'apposita sezione della Piattaforma Hub.",
    "c-signature.contract.text106":
        "Nel caso in cui intenda usufruire della facoltà di cui al paragrafo 1.7.2, il Cliente consente espressamente all'apposizione della Firma Digitale Automatica sui documenti informatici trattati nell'ambito del Servizio Telematici Fiscali ai sensi dell'art. 35, 3° comma del CAD.",
    "c-signature.contract.text107": "Art. 2 - Licenza della Piattaforma Hub",
    "c-signature.contract.text108":
        "Con il Contratto, TeamSystem concede al Cliente, che accetta, una licenza d'uso gratuita, non esclusiva, non cedibile e temporanea della Piattaforma Hub, al fine di permettere al Cliente di fruire dei Servizi Hub da quest'ultimo attivati.",
    "c-signature.contract.text109": "Art. 3 - Obblighi di TeamSystem in relazione alla Piattaforma Hub",
    "c-signature.contract.text110": "Con il Contratto, TeamSystem si impegna a:",
    "c-signature.contract.text111":
        "erogare i Servizi Hub attivati dal Cliente in conformità al Contratto, fermo restando che alcuni Servizi Hub, al momento della sottoscrizione del Contratto, potrebbero non essere immediatamente disponibili per motivi tecnici. In tal caso, i Servizi Hub non immediatamente disponibili saranno contrassegnati nella Piattaforma Hub dalla dicitura 'in arrivo' o da diversa espressione di significato analogo;",
    "c-signature.contract.text112":
        "adeguare la Piattaforma Hub e i Servizi Hub attivati ad eventuali intervenute esigenze di carattere tecnico e/o normativo, apportando le necessarie modifiche e gli eventuali correttivi;",
    "c-signature.contract.text113":
        "nell'eventualità in cui ciò sia necessario, effettuare interventi di manutenzione ordinaria e/o straordinaria, al fine di assicurare l'accesso e l'uso della Piattaforma Hub e dei Servizi Hub. Tali interventi saranno effettuati, salvo particolari casi di urgenza e/o forza maggiore, in tempi e con modalità tali da arrecare il minor disagio possibile all'operatività del Cliente;",
    "c-signature.contract.text114":
        "organizzare e amministrare il Data Center nel rispetto delle norme legislative e regolamentari applicabili in relazione alle misure di sicurezza, nonchè a dotarlo di appositi sistemi di protezione logica e fisica volti a impedire accessi non autorizzati;",
    "c-signature.contract.text115":
        "permettere al Cliente di creare ed assegnare Utenze Secondarie. Resta in ogni caso inteso fra le Parti che, anche in caso di creazione e assegnazione di Utenze Secondarie, il Cliente conserverà in ogni caso la facoltà di utilizzare i Servizi Hub anche in nome e per conto di ciascuna delle Utenze Secondarie;",
    "c-signature.contract.text116":
        "fornire al Cliente, dietro il versamento dei relativi Corrispettivi, il codice di integrazione necessario al fine di collegare il  software gestionale del Cliente alla Piattaforma Hub e usufruire dei Servizi Hub attivati attraverso il gestionale medesimo.",
    "c-signature.contract.text117": "Art. 4 - Obblighi del Cliente",
    "c-signature.contract.text118": "4.1 Con il Contratto, il Cliente si impegna a:",
    "c-signature.contract.text119":
        "pagare a TeamSystem o al soggetto appartenente alla Rete Vendite TS i Corrispettivi dovuti ai sensi dell'articolo 6;",
    "c-signature.contract.text120":
        "dotarsi autonomamente di materiale  hardware e software, nonchè, tramite un operatore di telecomunicazioni di sua esclusiva e libera scelta, di una Connettività adeguata al fine di poter accedere al Data Center e poter utilizzare la Piattaforma Hub e i Servizi Hub attivati;",
    "c-signature.contract.text121":
        "adeguare le caratteristiche dei propri sistemi informatici e della Connettività alle modifiche e ai correttivi eventualmente apportati da TeamSystem alla Piattaforma Hub e ai Servizi Hub successivamente alla conclusione del Contratto;",
    "c-signature.contract.text122":
        "fornire a TeamSystem tutte le informazioni necessarie per la piena fruibilità della Piattaforma Hub e dei Servizi Hub attivati, nonchè a comunicare immediatamente le eventuali successive variazioni, ivi inclusa qualsiasi variazione relativa alle Utenze Secondarie;",
    "c-signature.contract.text123":
        "verificare l'esito delle attività di Reporting generate dalla Piattaforma Hub su richiesta del Cliente medesimo;",
    "c-signature.contract.text124":
        "fare accettare e sottoscrivere a ciascun Terzo Beneficiario, che non sia già Cliente, l'Atto di Affidamento e a trasmetterlo, sottoscritto dal Terzo Beneficiario, prontamente a TeamSystem per mezzo dell'apposita funzione disponibile sulla Piattaforma Hub.",
    "c-signature.contract.text125": "Art. 5 - Durata",
    "c-signature.contract.text126":
        "Il Contratto ha durata di un anno a decorrere dalla sua conclusione e si intenderà automaticamente rinnovato di anno in anno, salvo disdetta da inviarsi, a mezzo raccomandata A/R e/o PEC, almeno 30 (trenta) giorni prima della scadenza.",
    "c-signature.contract.text127":
        "Il rinnovo tacito non si applica nel caso in cui il Cliente sia una Pubblica Amministrazione.",
    "c-signature.contract.text128": "Art. 6 - Corrispettivi",
    "c-signature.contract.text129":
        "A fronte della prestazione da parte di TeamSystem dei Servizi Hub attivati dal Cliente, quest'ultimo si impegna a corrispondere puntualmente a TeamSystem o al soggetto appartenente alla Rete Vendite TS i Corrispettivi indicati nel modulo d'ordine o nel listino prezzi pubblicato nell'apposita sezione della Piattaforma Hub, secondo le modalità previste.",
    "c-signature.contract.text130":
        "Tutti i Corrispettivi devono intendersi al netto di I.V.A. e degli eventuali altri oneri di legge.",
    "c-signature.contract.text131":
        "Il Cliente prende atto che la Piattaforma Hub e i Servizi Hub sono soggetti, per loro natura, ad una costante evoluzione tecnologica e normativa che richiede continue e onerose attività di aggiornamento e sviluppo necessarie al fine di garantire la funzionalità della Piattaforma Hub e la corretta e completa erogazione dei Servizi Hub. In ragione di quanto precede, TeamSystem avrà il diritto di modificare i Corrispettivi anche in misura superiore all'indice ISTAT con le modalità previste dall'articolo 16 che segue.",
    "c-signature.contract.text132":
        "Fermo restando quanto previsto al paragrafo 6.3 che precede, qualora, durante l'esecuzione del Contratto, dovessero verificarsi circostanze imprevedibili tali da rendere maggiormente onerosa l'esecuzione delle prestazioni da parte di TeamSystem, quest'ultima avrà diritto di percepire un equo compenso una tantum ovvero di modificare unilateralmente i Corrispettivi.",
    "c-signature.contract.text133":
        "In caso di mancato o ritardato pagamento di una qualsiasi somma dovuta ai sensi del Contratto, il Cliente decadrà automaticamente dal beneficio del termine e TeamSystem avrà facoltà di applicare sulle somme ad essa dovute interessi di mora nella misura prevista dal d.lgs. 231/2002.",
    "c-signature.contract.text134":
        "Il Cliente rinuncia a proporre eccezioni senza avere preventivamente adempiuto alle proprie obbligazioni di pagamento ai sensi del presente articolo 6.",
    "c-signature.contract.text135": "Art. 7 - Credenziali di Accesso",
    "c-signature.contract.text136":
        "Il Cliente potrà accedere alla Piattaforma Hub e usufruire dei Servizi Hub attivati mediante le Credenziali di Accesso.",
    "c-signature.contract.text137":
        "Il Cliente è consapevole del fatto che la conoscenza delle Credenziali di Accesso da parte di soggetti terzi consentirebbe a questi ultimi l'accesso alla Piattaforma Hub, ai dati e ai documenti ivi memorizzati, ivi incluse le Fatture Elettroniche, e ai Servizi Hub.",
    "c-signature.contract.text138":
        "Il Cliente è pertanto tenuto a custodire e a far sì che i propri dipendenti, collaboratori oconsulenti incaricati custodiscano le Credenziali di Accesso con la massima riservatezza e con la massima diligenza, obbligandosi a non cederle nè a consentirne l'uso a terzi non espressamente autorizzati.",
    "c-signature.contract.text139":
        "TeamSystem non potrà in alcun caso essere ritenuta responsabile di qualsiasi danno, diretto e/o indiretto, che dovesse derivare al Cliente o a terzi in conseguenza della mancata osservanza da parte del Cliente e/o dei propri dipendenti, collaboratori o consulenti incaricati delle previsioni di cui al presente paragrafo.",
    "c-signature.contract.text140": "Art. 8 - Riservatezza",
    "c-signature.contract.text141":
        "Fatto salvo quanto previsto all'articolo 1.1.2(b), è tassativamente vietata alle Parti ogni forma di comunicazione e/o divulgazione o comunque di utilizzazione, anche per interposta persona e/o ente, di qualsiasi notizia, informazione e documentazione comunque appresa e ottenuta in occasione dell'esecuzione del Contratto, da intendersi sin d'ora incondizionatamente riservata, anche ove non si tratti di veri e propri segreti industriali, tanto se attinente alle Parti, quanto se riguardante imprese loro clienti e/o fornitrici, salvo:",
    "c-signature.contract.text142": "quanto strettamente richiesto dall'esecuzione del Contratto;",
    "c-signature.contract.text143": "espressa autorizzazione per iscritto dell'altra Parte;",
    "c-signature.contract.text144":
        "quando le Parti siano a ciò obbligate per legge e/o per provvedimento dell'autorità amministrativa e/o giudiziaria.",
    "c-signature.contract.text145":
        "Il divieto di cui al precedente paragrafo resterà incondizionatamente fermo anche dopo la cessazione, per qualsiasi causa intervenuta, del Contratto per il successivo periodo di 5 (cinque) anni, ritenuto congruo da entrambe le Parti, fatta salva la caduta in pubblico dominio dell'informazione che non sia imputabile alle Parti.",
    "c-signature.contract.text146": "Art. 9 - Nomina del responsabile del trattamento",
    "c-signature.contract.text147":
        "Il titolare del trattamento dei dati (il Cliente e, se del caso, il Terzo Beneficiario) nomina TeamSystem quale responsabile del trattamento dei propri dati personali ai sensi dell'art. 29 del Codice della Privacy, in relazione ai trattamenti di dati personali eseguiti per finalità connesse all'esecuzione del presente Contratto.",
    "c-signature.contract.text148":
        "Il titolare del trattamento conferisce a TeamSystem l'incarico di responsabile del trattamento dei dati personali, ai sensi dell'art. 29 del d.lgs. n. 196/2003 (il 'Responsabile'), con riguardo alle operazioni di trattamento connesse all'esecuzione del Contratto e dei Servizi Hub. Nella qualità di responsabile del trattamento dei dati personali, TeamSystem sarà tenuta ad assicurare lo svolgimento dei seguenti compiti ed il rispetto delle seguenti istruzioni:",
    "c-signature.contract.text149":
        "il Responsabile è autorizzato ad eseguire esclusivamente le operazioni di trattamento necessarie per lo svolgimento dei servizi al medesimo affidati sulla base del Contratto;",
    "c-signature.contract.text150":
        "i dati personali devono essere trattati in modo lecito e secondo correttezza, limitatamente a quanto",
    "c-signature.contract.text151": "necessario rispetto alle finalità di esecuzione del Contratto;",
    "c-signature.contract.text152":
        " tutti i soggetti che trattano dati personali per conto del Responsabile devono aver ricevuto opportuna formazione circa il trattamento dei dati e nomina scritta quale incaricati del trattamento ai sensi dell'art. 30 del Codice della Privacy. La nomina deve indicare l'ambito di trattamento consentito e prevedere specifiche istruzioni per assicurare il rispetto da parte degli incaricati della normativa in materia di trattamento dei dati personali;",
    "c-signature.contract.text153":
        "il Responsabile dovrà assicurare l'adozione delle misure di sicurezza necessarie ad assicurare la protezione dei dati personali contro il rischio di perdita o distruzione, anche accidentale, di accesso non autorizzato o di trattamento non consentito o non conforme alla finalità della raccolta. Più precisamente, il Responsabile dovrà assicurare l'adozione delle misure minime di sicurezza previste dagli artt. 33 - 36",
    "c-signature.contract.text154":
        "D.lgs. 196/2003 e dal Disciplinare tecnico riportato in Allegato B al Codice della Privacy, nonchè le misure previste in tema di amministratori di sistema in relazione ai sistemi gestiti dal Responsabile ai fini della esecuzione del Contratto;",
    "c-signature.contract.text155":
        "il Responsabile dovrà collaborare con il titolare, nei limiti di quanto di propria competenza, per l'attuazione di eventuali misure che si rendano strettamente necessarie a garantire la conformità del trattamento dei dati personali alla normativa applicabile;",
    "c-signature.contract.text156":
        "il Responsabile dovrà informare tempestivamente il titolare in caso di richieste di informazioni, attività ispettive o provvedimenti delle autorità in relazione ai dati personali trattati per conto del titolare;",
    "c-signature.contract.text157":
        "il Responsabile dovrà inoltrare tempestivamente al titolare le eventuali richieste degli interessati, avanzate ai sensi dell'art. 7 del Codice, in merito alle finalità e modalità del trattamento, all'origine dei dati, all'aggiornamento, alla rettificazione, alla cancellazione e trasformazione dei dati, ecc. e la relativa documentazione, collaborando con il titolare al fine di assicurare il rispetto dei diritti degli interessati;",
    "c-signature.contract.text158":
        "in caso di cessazione del trattamento, il Responsabile dovrà procedere alla restituzione secondo le modalità previste dal Contratto ovvero alla loro distruzione.",
    "c-signature.contract.text159":
        "Devono ritenersi espressamente esclusi dai compiti assegnati al Responsabile ai sensi del presente incarico tutti i compiti diversi da quelli sopra indicati, tra cui, a titolo meramente esemplificativo e non esaustivo, gli obblighi di informativa e acquisizione del consenso da parte degli interessati.",
    "c-signature.contract.text160":
        "Il titolare del trattamento dei dati prende atto che i dati personali sono ospitati presso le infrastrutture Microsoft Azure collocate all'interno del territorio dell'Unione Europea (e più precisamente nei data center MS Azure West Europe e North Europe) ed autorizza sin d'ora il Responsabile ad avvalersi di ulteriori terzi per le attività di trattamento necessarie, sempre all'interno del territorio dell'Unione Europea.",
    "c-signature.contract.text161":
        "Resta, altresì, inteso che TeamSystem manterrà la qualifica di Responsabile del trattamento dei dati personali per tutta la durata del rapporto contrattuale intercorrente con il titolare. In caso di cessazione, per qualsivoglia motivo, del Contratto, dovrà intendersi, altresì, automaticamente revocata e cessata anche la presente designazione.",
    "c-signature.contract.text162":
        "Le Parti convengono che TeamSystem potrà procedere all'elaborazione e utilizzo di informazioni puramente statistiche, su base aggregata e previa anonimizzazione, raccolte in relazione all'utilizzo dei Servizi da parte del Cliente e del Terzo Beneficiario, ivi incluse informazioni relative ai meta-dati associati ai Documenti, a fini di studio e statistici, concedendo a tal fine a TeamSystem una licenza non esclusiva, perpetua, irrevocabile, valida in tutto il mondo e a titolo gratuito, ad utilizzare tali informazioni per dette finalità.",
    "c-signature.contract.text163": "Art. 10 - Trattamento dei dati personali",
    "c-signature.contract.text164":
        "Le Parti riconoscono e si danno reciprocamente atto che l'esecuzione dei Servizi Hub può comportare il trattamento da parte di TeamSystem di dati personali di soggetti terzi di cui il Cliente e il Terzo Beneficiario sono titolari ai sensi della normativa in materia di protezione dei dati personali (i 'Dati Personali di Terzi'). Al riguardo, il Cliente e il Terzo Beneficiario dichiarano e riconoscono di essere responsabili dell'adempimento nei confronti degli interessati di tutti gli obblighi previsti dal Codice della Privacy, dalle disposizioni emanate dal Garante per la protezione dei dati personali e da ogni altra normativa legale o regolamentare vigente in materia, salvi i compiti affidati a TeamSystem in virtù della nomina a responsabile del trattamento ricevuta ai sensi del precedente articolo 10. Pertanto, a titolo esemplificativo e non esaustivo, il Cliente si obbliga ad assicurare (ed a far sì che il Terzo Beneficiario assicuri): i) che gli interessati abbiano ricevuto un'adeguata informativa e abbiano prestato, ove necessario, idoneo consenso al loro trattamento anche attraverso i Servizi Hub; ii) che siano adottate tutte le misure di sicurezza idonee alla tutela dei Dati Personali di Terzi, fatte salve le misure delegate a TeamSystem in qualità di Responsabile del trattamento dei dati personali sulla base della nomina ricevuta ai sensi del presente Contratto; iii) che i Dati Personali di Terzi trasferiti o comunque trattati da TeamSystem nell'ambito dei Servizi Hub non contengano particolari categorie di datipersonali (ivi inclusi i dati sanitari o giudiziari) ai sensi del Codice della Privacy e della Normativa Vigente, salvo diverso accordo scritto con TeamSystem; iv) che i Dati Personali di Terzi siano trattati in modo lecito e secondo correttezza, nel rispetto di ogni ulteriore accorgimento previsto dal Codice della Privacy e delle ulteriori normative applicabili.",
    "c-signature.contract.text165":
        "Resta inteso che TeamSystem non assume alcuna responsabilità in merito alle conseguenze derivanti dall'inosservanza da parte del Cliente e/o del Terzo Beneficiario degli obblighi sui medesimi gravanti in qualità di titolari del trattamento ai sensi del precedente articolo 10.1. Il Cliente dovrà manlevare e tenere indenne TeamSystem da qualunque pregiudizio, onere, sanzione o pretesa che TeamSystem dovesse subire o ricevere in ragione della violazione da parte del Cliente e/o del Terzo Beneficiario di tali obblighi.",
    "c-signature.contract.text166":
        "In relazione ai dati personali del Cliente e del Terzo Beneficiario trattati da TeamSystem per finalità e con modalità proprie, e dunque in qualità di autonomo titolare del trattamento, sarà resa agli interessati idonea informativa ai sensi dell'art. 13 del Codice della Privacy.",
    "c-signature.contract.text167": "Art. 11 - Memorizzazione",
    "c-signature.contract.text168":
        "Le Fatture Elettroniche, i Documenti e, in generale, i dati immessi si intendono definitivamente acquisiti dalla Piattaforma Hub e dai relativi Servizi Hub nel momento in cui questi vengono compiutamente memorizzati ed accettati dalla Piattaforma Hub.",
    "c-signature.contract.text169":
        "è specifico onere del Cliente e/o di ciascun Terzo Beneficiario, attraverso le apposite funzionalità fornite dalla Piattaforma Hub (ivi inclusa la funzionalità di Reporting) , verificare autonomamente la corretta acquisizione da parte della Piattaforma Hub dei Documenti e delle Fatture Elettroniche, nonchè, con riferimento al Servizio B2B e al Servizio SDI, verificare il corretto andamento del procedimento di fatturazione elettronica.",
    "c-signature.contract.text170":
        "In caso di attivazione del Servizio TS Digital Archive, è specifico onere del Cliente o del Terzo Beneficiario, per il tramite del Produttore, verificare il buon esito del versamento tramite la presa visione del rapporto di versamento generato dal Servizio TS Digital Archive medesimo.",
    "c-signature.contract.text171":
        "Salvo i casi di dolo o colpa grave, TeamSystem non assume alcuna responsabilità per l'eventuale sottrazione, perdita, distruzione o smarrimento dei Documenti, delle Fatture Elettroniche",
    "c-signature.contract.text172":
        "e, più in generale, delle informazioni relative al Cliente prima che essi vengano definitivamente acquisiti e memorizzati dalla Piattaforma Hub.",
    "c-signature.contract.text173": "Art. 12 - Partner",
    "c-signature.contract.text174":
        "TeamSystem, nell'adempiere alle proprie obbligazioni di cui al Contratto, potrà avvalersi, a propria insindacabile discrezione, della cooperazione tecnica, organizzativa e commerciale di Partner, ai quali potrà affidare la prestazione di alcune attività elencate nelle presenti Condizioni Generali.",
    "c-signature.contract.text175":
        "A tal fine, TeamSystem s'impegna ad avvalersi di fornitori qualificati e diligenti, i quali dovranno a loro volta obbligarsi ad operare in accordo alle disposizioni delle presenti Condizioni Generali.",
    "c-signature.contract.text176": "Art. 13 - Responsabilità del Cliente",
    "c-signature.contract.text177":
        " Il Cliente si impegna a far sì che le disposizioni del Contratto siano rispettate da ciascun Terzo Beneficiario, da ciascun utilizzatore delle Utenze Secondarie e dai propri dipendenti, collaboratori, consulenti ed altri possibili aventi causa del Cliente stesso.",
    "c-signature.contract.text178":
        " Il Cliente è, inoltre, esclusivo responsabile della correttezza, completezza, aggiornamento e veridicità dei dati immessi nella Piattaforma Hub da ciascun utilizzatore anche delle Utenze Secondarie, da ogni Terzo Beneficiario nonchè dai propri dipendenti, collaboratori e consulenti, garantendo altresì il rispetto di tutte le normative applicabili, ivi incluse quelle in materia fiscale e civile.",
    "c-signature.contract.text179":
        "Anche ai sensi e per gli effetti di cui all'art. 1381 c.c., il Cliente è esclusivo responsabile dell'operato dei propri dipendenti, collaboratori o consulenti e degli Utenti Secondari, nonchè dell'autorizzazione a quest'ultimi concessa per utilizzare la Piattaforma Hub e i Servizi Hub in nome e per conto del Cliente.",
    "c-signature.contract.text180":
        "è fatto divieto di utilizzare la Piattaforma Hub e i Servizi Hub al fine di depositare, conservare, inviare, pubblicare, trasmettere e/o condividere dati, applicazioni o documenti informatici che:",
    "c-signature.contract.text181":
        "siano in contrasto o violino la Proprietà Intellettuale e/o i diritti di proprietà industriale e/o intellettuale di titolarità di terzi;",
    "c-signature.contract.text182": "abbiano contenuti diffamatori, calunniosi o minacciosi;",
    "c-signature.contract.text183":
        "contengano materiale pornografico, osceno o comunque contrario alla pubblica morale;",
    "c-signature.contract.text184":
        "contengano virus, worm, trojan horse o, comunque, altre caratteristiche di contaminazione o distruttive;",
    "c-signature.contract.text185": "costituiscano attività di spamming, phishing e/o simili;",
    "c-signature.contract.text186":
        "siano in ogni caso in contrasto con le disposizioni normative e/o regolamentari applicabili.",
    "c-signature.contract.text187":
        "TeamSystem si riserva il diritto di sospendere l'accesso al Cliente e, se del caso, a ciascun Terzo Beneficiario alla Piattaforma Hub e ai Servizi Hub, ovvero di impedire l'accesso ai dati e ai documenti ivi memorizzati, qualora venga a conoscenza di una violazione di quanto previsto nel presente articolo e/o venga avanzata espressa richiesta in tal senso da un organo giurisdizionale o amministrativo in base alle norme vigenti.",
    "c-signature.contract.text188":
        "Nelle ipotesi di cui al comma precedente, TeamSystem provvederà a comunicare a mezzo PEC al Cliente le motivazioni dell'adozione della sospensione e/o interdizione all'accesso, salva la facoltà di risolvere il Contratto ai sensi del successivo articolo 18.",
    "c-signature.contract.text189": "Art. 14 - Manleva",
    "c-signature.contract.text190":
        "Il Cliente si impegna a manlevare e tenere indenne TeamSystem da qualsiasi danno, pretesa, responsabilità e/o onere, diretti o indiretti e comprese le ragionevoli spese legali, che TeamSystem dovesse subire o sopportare in conseguenza dell'inadempimento da parte del Cliente e/o di ciascun Terzo Beneficiario di ciascuno degli obblighi previsti dal Contratto e, in particolare, di quanto previsto dagli articoli 1.1.2, 1.2.2, 1.3.2, 4, 7.2, 7.3, 11.2, 11.3, 13 e 20.",
    "c-signature.contract.text191": "Art. 15 - Responsabilità di TeamSystem",
    "c-signature.contract.text192":
        "Il Cliente prende atto e accetta che TeamSystem non si assume alcuna responsabilità ai fini degli adempimenti fiscali richiesti dalla normativa vigente ed eventuali successive modifiche.",
    "c-signature.contract.text193":
        "TeamSystem, inoltre, non rilascia dichiarazioni e garanzie espresse o implicite sul fatto che i software che costituiscono la Piattaforma Hub e i Servizi Hub siano adatti a soddisfare le esigenze del Cliente, che siano esenti da errori o che abbiano funzionalità non previste nelle specifiche tecniche e nella documentazione relativa.",
    "c-signature.contract.text194":
        "TeamSystem, salvo il caso di dolo o colpa grave, non potrà essere ritenuta responsabile per danni diretti o indiretti, di qualsiasi natura ed entità, che dovessero derivare al Cliente e/o a terzi, ivi inclusi gli eventuali Terzi Beneficiari, in conseguenza dell'uso (o del mancato uso) della Piattaforma Hub e/o dei Servizi Hub in maniera non conforme a quanto previsto dal Contratto e/o dalle leggi vigenti.",
    "c-signature.contract.text195":
        "TeamSystem, inoltre, salvo il caso di dolo o colpa grave, non potrà essere ritenuta responsabile in relazione a danni diretti o indiretti, di qualsiasi natura ed entità, che il Cliente e/o terzi dovessero subire per manomissioni della Piattaforma Hub e/o dei Servizi Hub o per interventi sulle apparecchiature effettuati dal Cliente o da terzi non autorizzati da TeamSystem.",
    "c-signature.contract.text196":
        "TeamSystem, salvo il caso in cui il Cliente abbia attivato il Servizio TS Digital Archive e salvi gli inderogabili limiti di legge, non assume alcun obbligo di conservazione dei messaggi PEC, email, delle Fatture Elettroniche e in generale dei dati immessi nella Piattaforma Hub, restando tale conservazione di esclusiva responsabilità del Cliente.",
    "c-signature.contract.text197":
        "TeamSystem non sarà in alcun modo responsabile, direttamente o indirettamente, per eventuali malfunzionamenti e/o per la mancata fruizione della Piattaforma Hub e dei Servizi Hub che derivino da una Connettività inadeguata rispetto alle relative caratteristiche tecniche.",
    "c-signature.contract.text198":
        "TeamSystem non potrà essere ritenuta responsabile per eventuali danni da lucro cessante, mancato guadagno o danni indiretti, perdita o danneggiamento di dati, fermo fabbrica, perdita di opportunità commerciali o di benefici di altro genere, pagamento di penali, ritardi o altre responsabilità del Cliente e/o del Terzo Beneficiario verso terzi che dovessero derivare, in tutto o in parte dall'uso o dal mancato uso della Piattaforma Hub e dei Servizi Hub.",
    "c-signature.contract.text199":
        "In ogni caso, salvo il caso di dolo o colpa grave, la responsabilità di TeamSystem non potrà mai eccedere l'ammontare del corrispettivo annuale pagato dal Cliente ai sensi del presente Contratto.",
    "c-signature.contract.text200": "Art. 16 - Modifiche Unilaterali",
    "c-signature.contract.text201":
        "Il Contratto potrà essere modificato da TeamSystem in qualsiasi momento, dandone semplice comunicazione scritta (anche via e-mail o con l'ausilio di programmi informatici) al Cliente.",
    "c-signature.contract.text202":
        "In tal caso, il Cliente avrà la facoltà di recedere dal Contratto con comunicazione scritta inviata a TeamSystem a mezzo raccomandata con ricevuta di ricevimento nel termine di 15 giorni dal ricevimento della comunicazione scritta da parte di TeamSystem di cui al paragrafo che precede.",
    "c-signature.contract.text203":
        "In mancanza di esercizio della facoltà di recesso da parte del Cliente, nei termini e nei modi sopra indicati, le modifiche al Contratto si intenderanno da quest'ultimo definitivamente conosciute e accettate e diverranno definitivamente efficaci e vincolanti.",
    "c-signature.contract.text204": "Art. 17 - Recesso",
    "c-signature.contract.text205":
        "TeamSystem si riserva il diritto di recedere dal presente Contratto in ogni momento, con comunicazione a mezzo PEC da inviarsi al Cliente con almeno 2 (due) mesi di preavviso.",
    "c-signature.contract.text206":
        "Nel caso in cui TeamSystem esercitasse il proprio diritto di recesso per motivi diversi rispetto a quelli di cui al paragrafo che segue, il Cliente avrà diritto alla restituzione della quota di corrispettivo per il periodo di mancato utilizzo della Piattaforma Hub e/o dei Servizi Hub, qualora essa sia già stata versata.",
    "c-signature.contract.text207":
        "TeamSystem si riserva altresì il diritto di recedere dal Contratto anche nell'ipotesi in cui il Cliente sia gravemente inadempiente con riferimento ad uno qualsiasi degli eventuali contratti conclusi tra il medesimo Cliente e (i) TeamSystem S.p.A., con sede legale in Pesaro (PU), via Gagarin 205, codice fiscale, partita IVA e iscrizione al registro delle Imprese di Pesaro e Urbino n. 01035310414, o (ii) una delle società controllate, direttamente o indirettamente, ai sensi dell'art. 2359, primo comma, c.c., da TeamSystem S.p.A.",
    "c-signature.contract.text208":
        "Resta in ogni caso inteso fra le Parti che la facoltà del Cliente di procedere alla mera disattivazione della propria utenza sulla Piattaforma Hub, mediante la funzione a tale scopo preposta, non può in nessun caso essere considerata come concessione al Cliente di un diritto di recesso dal presente Contratto.",
    "c-signature.contract.text209": "Art. 18 - Clausola risolutiva espressa e interdizione dalla Piattaforma Hub",
    "c-signature.contract.text210":
        "Fatto salvo il risarcimento del danno, TeamSystem si riserva il diritto di risolvere il Contratto ai sensi dell'art. 1456 c.c. a seguito di invio di semplice comunicazione scritta a mezzo PEC ovvero lettera raccomandata A/R in caso di mancato adempimento da parte del Cliente e/o del Terzo Beneficiario anche di una sola delle previsioni contenute nei seguenti articoli:",
    "c-signature.contract.text211": "4 (Obblighi del Cliente);",
    "c-signature.contract.text212": "6 (Corrispettivi);",
    "c-signature.contract.text213": "7 (Credenziali di Accesso);",
    "c-signature.contract.text214": "13 (Responsabilità del Cliente);",
    "c-signature.contract.text215": "20 (Codice di Condotta Anti-Corruzione e Modello Organizzativo).",
    "c-signature.contract.text216":
        "La risoluzione opererà di diritto al semplice ricevimento da parte del Cliente di una comunicazione scritta a mezzo PEC contenente la contestazione dell'inadempimento e l'intenzione di avvalersi della presente clausola risolutiva.",
    "c-signature.contract.text217":
        "Fermo restando l'obbligo per il Cliente di versare a TeamSystem i Corrispettivi di cui all'articolo 6, TeamSystem, in caso di inadempimento del Cliente e/o di ciascun Terzo Beneficiario ad una delle obbligazioni di cui al primo comma del presente articolo, si riserva altresì la facoltà di interdire in ogni momento al Cliente l'accesso alla Piattaforma Hub e ai Servizi Hub. In tale ipotesi, TeamSystem comunicherà al Cliente l'intenzione di interdire l'accesso alla Piattaforma Hub e ai Servizi Hub, invitando, ove possibile, il Cliente a porre rimedio all'inadempimento entro un determinato termine. Il Cliente rimane in ogni caso obbligato a versare quanto dovuto anche in caso di interdizione dalla Piattaforma Hub.",
    "c-signature.contract.text218": "Art. 19 - Effetti della cessazione del Contratto e restituzione",
    "c-signature.contract.text219":
        "In ogni caso di cessazione del Contratto, per qualsiasi causa intervenuta, TeamSystem interdirà definitivamente al Cliente e a ciascun Terzo Beneficiario l'accesso alla Piattaforma Hub e ai Servizi Hub, fatta salva la possibilità per il Cliente e per ciascun Terzo Beneficiario di effettuare il download delle Fatture Elettroniche, dei Documenti e, in generale, dei dati da esso caricati sulla Piattaforma Hub entro il termine di 60 (sessanta) giorni dalla data di cessazione del Contratto.",
    "c-signature.contract.text220":
        "In alternativa, la restituzione delle Fatture Elettroniche, dei Documenti e dei dati di cui al comma che precede potrà essere richiesta dal Cliente tramite modalità di consegna automatizzata da concordare ovvero su appositi supporti ottici a fronte del pagamento dei corrispettivi specificamente previsti.",
    "c-signature.contract.text221":
        "Fatto salvo quanto diversamente previsto dalle Parti e gli inderogabili limiti di legge, laddove il Cliente e/o ciascun Terzo Beneficiario non abbia scaricato o richiesto la restituzione delle Fatture Elettroniche, dei Documenti e dei dati di cui al primo comma, TeamSystem avrà la facoltà di cancellarli.",
    "c-signature.contract.text222":
        "Resta in ogni caso inteso che gli articoli 6 (Corrispettivi), 8 (Riservatezza), 11.4 (Memorizzazione), 13 (Responsabilità del Cliente), 14 (Manleva), 15 (Responsabilità di TeamSystem), 20 (Codice di Condotta Anti-Corruzione e Modello Organizzativo), 21 (Comunicazioni), 22 (Legge applicabile e foro esclusivo), 26 (Tolleranza) e 27 (Invalidità e inefficacia parziale) sopravvivranno alla cessazione del Contratto, per qualsiasi ragione intervenuta.",
    "c-signature.contract.text223": "Art. 20 - Codice di Condotta Anti-Corruzione e Modello Organizzativo",
    "c-signature.contract.text224":
        "Il Cliente dichiara di avere preso visione e di conoscere il contenuto del Codice di Condotta Anti-Corruzione e del Modello Organizzativo e si obbliga - anche ai sensi dell'art. 1381 c.c. per i propri eventuali dipendenti e collaboratori - a conformarsi alle regole ed alle procedure contenute nel Codice di Condotta Anti-Corruzione e nel Modello Organizzativo (laddove applicabili al Cliente) e ad informare tempestivamente TeamSystem di qualsiasi atto, fatto o comportamento di cui venga a conoscenza che possa integrare la violazione di uno dei principi previsti dal Codice di Condotta Anti-Corruzione e/o dal Modello Organizzativo.",
    "c-signature.contract.text225": "Art. 21 - Comunicazioni",
    "c-signature.contract.text226":
        "Tutte le comunicazioni al Cliente inerenti lo svolgimento del rapporto potranno essere effettuate all'indirizzo email comunicato dal Cliente medesimo in sede di certificazione. Le comunicazioni a TeamSystem andranno invece inviate via  email al seguente indirizzo info@agyo.io. Resta inteso che sarà cura e responsabilità del Cliente comunicare ogni variazione in relazione all'indirizzo email identificato dal Cliente per tutte le comunicazioni.",
    "c-signature.contract.text227": "Art. 22 - Legge applicabile e foro esclusivo",
    "c-signature.contract.text228":
        "Il presente contratto è regolato e deve essere interpretato in conformità alla legge italiana.",
    "c-signature.contract.text229":
        "Qualsiasi controversia inerente il, o derivante dal Contratto, fatta eccezione per i procedimenti d'ingiunzione di cui agli artt. 633 ss. c.p.c. e le relative fasi di opposizione, sarà devoluta alla cognizione di un collegio di tre arbitri, nominati in conformità al Regolamento della Camera Arbitrale di Milano, che deciderà secondo diritto.",
    "c-signature.contract.text230": "Art. 23 - Divieto di cessione",
    "c-signature.contract.text231":
        "Salva preventiva autorizzazione scritta dell'altra parte, è fatto divieto alle Parti di cedere in tutto o in parte il Contratto.",
    "c-signature.contract.text232":
        "Il Cliente acconsente sin da ora, ai sensi e per gli effetti dell'art. 1407 c.c., alla cessione, dal soggetto appartenente alla Rete Vendite TS a TeamSystem, del contratto avente ad oggetto i Servizi Hub intercorrente tra il Cliente e il soggetto appartenente alla Rete Vendite TS che abbia cessato, per qualsivoglia motivo, di rivestire tale qualifica.",
    "c-signature.contract.text233": "Art. 24 - Modifiche",
    "c-signature.contract.text234":
        "Le presenti Condizioni Generali potranno essere modificate da TeamSystem in qualsiasi momento. In tal caso, TeamSystem provvederà a comunicare le nuove condizioni generali al Cliente. Decorsi 15 (quindici) giorni dalla comunicazione, le nuove condizioni generali si intenderanno accettate e diventeranno vincolanti tra le Parti.",
    "c-signature.contract.text235": "Art. 25 - Effetto novativo",
    "c-signature.contract.text236":
        "E' escluso qualsiasi rilievo di eventuali precedenti accordi individuali tra le Parti, che s'intendono assorbiti ed esaustivamente superati dalla disciplina del Contratto.",
    "c-signature.contract.text237": "Art. 26 - Tolleranza",
    "c-signature.contract.text238":
        "L'eventuale omissione di far valere uno o più dei diritti previsti dal Contratto non potrà comunque essere intesa come definitiva rinuncia a tali diritti e non impedirà, quindi, di esigerne in qualsiasi altro momento il puntuale e rigoroso adempimento.",
    "c-signature.contract.text239": "Art. 27 - Invalidità ed inefficacia parziale",
    "c-signature.contract.text240":
        "L'eventuale invalidità o inefficacia di una qualsiasi delle pattuizioni del Contratto lascerà intatte le altre pattuizioni giuridicamente e funzionalmente indipendenti, salvo quanto previsto dall'art. 1419, primo comma, c.c.",
    "c-signature.contract.text241": "Accetti le condizioni generali di contratto per utilizzare il servizio?",

    "c-cram.placeholder": "Seleziona un'azienda",

    "general.featurecode-name": "Nome sottoservizio",
    "general.edit-users": "Modifica utente",
    "general.edit-profile": "Modifica profilo",
    "general.companies": "Aziende",
    "general.actions": "Azioni",
    "general.state": "Stato",
    "general.user-role": "Ruolo",
    "general.created-at": "Creazione",
    "general.role-level": "Livello del ruolo",
    "general.accept": "Accetto",
    "general.refuse": "Non accetto",
    "general.deny": "Nega",
    "general.generate": "Genera",
    "general.rettifica": "Rettifica",
    "general.update": "Aggiorna",
    "general.users-search": "Cerca utenti",
    "general.filters": "Filtri",
    "general.vatNumber": "Partita IVA",
    "general.taxId": "Codice Fiscale",
    "general.taxid.wrong": "Codice Fiscale errato",
    "general.month": "Mese",
    "general.man.short": "M",
    "general.women.short": "F",
    "general.day": "Giorno",
    "general.create": "CREA",
    "general.register": "Registrati",
    "general.adesioni": "Adesioni",
    "general.out-soon": "Presto disponibile",
    "general.here": "qui",
    "general.signer": "Firmatario",
    "general.reset": "Reset",
    "general.save-pin": "Salva il tuo pin",
    "general.certificate": "Certificato",
    "general.certification.AWAITING_APPROVAL": "In di verifica",
    "general.certification.AWAITING_UPLOAD": "In attesa contratto",
    "general.certification.CERTIFIED": "Certificata",
    "general.certification.VALIDATED": "Certificazione in corso",
    "general.certification.UNVERIFIABLE": "Non certificata",
    "general.upload-pdf": "Carica PDF",
    "general.document": "Documento",
    "general.download": "Scarica",
    "general.download-pdf": "Scarica PDF",
    "general.send-pdf": "Invia PDF",
    "general.gender": "Sesso",
    "general.pin-confirm": "Conferma pin",
    "general.pin": "Pin",
    "general.pin-code": "Codice pin",
    "general.mobile-phone": "Cellulare",
    "general.send-request": "Invia richiesta",
    "general.telephone-number": "N.Telefono",
    "general.competent-court": "Foro competente",
    "general.web-site": "Sito internet",
    "general.policy-number": "Numero Polizza",
    "general.language": "Lingua",
    "general.legalForm": "Forma giuridica",
    "general.place-date": "Luogo e Data",
    "general.role": "Carica",
    "general.name-surname": "Nome e Cognome",
    "general.save-progress": "Vuoi salvare le modifiche?",
    "general.quit": "Esci",
    "general.linked": "Collegato",
    "general.not-linked": "Non Collegato",
    "general.config-services": "Configura i servizi",
    "general.discover": "Scopri",
    "general.accedi": "ACCEDI",
    "general.year2k17": "Anno 2017",
    "general.activate-subscription": "Attiva sottoscrizione",
    "general.attention": "Attenzione",
    "general.congratulations": "Congratulazioni",
    "general.yes": "Si",
    "general.yes.maiusc": "SI",
    "general.no": "No",
    "general.no.maiusc": "NO",
    "general.back": "Indietro",
    "general.back-to-home": "Torna alla pagina principale",
    "general.coming-soon": "In arrivo",
    "general.continue": "Prosegui",
    "general.close": "Chiudi",
    "general.country": "Nazione",
    "general.delete": "Elimina",
    "general.delete-office": "Elimina ufficio",
    "general.certificate-delete": "Elimina certificato",
    "general.info-office": "Info ufficio",
    "general.email": "Email",
    "general.password": "Password",
    "general.confirm-password": "Conferma password",
    "general.end": "Fine",
    "general.forward": "Avanti",
    "general.goBack": "Torna Indietro",
    "general.info": "Info",
    "general.informations": "Informazioni",
    "general.next": "Prossimo",
    "general.preferences": "Preferenze",
    "general.settings": "Impostazioni",
    "general.edit": "Modifica",
    "general.retry": "Riprova",
    "general.roles.OWNR": "Titolare",
    "general.roles.LEGL": "Legale rappresentante",
    "general.roles.PROS": "Procuratore",
    "general.view": "Visualizza",
    "general.buy-package": "Acquista il pacchetto",
    "general.contact-to-buy": "Contatta il tuo referente TeamSystem",
    "general.contact-us": "Contattaci",
    "general.gratis": "Gratis",
    "generel.comingsoon": "In arrivo",
    "general.active": "Attiva",
    "general.active-free-version": "Attiva versione gratuita",
    "general.active-free": "Attiva gratuitamente",
    "general.manage-invoice-with-ts": "Gestisci fatturazione elettronica con TS",
    "general.disable": "Disattiva",
    "general.configures": "Configura",
    "general.ok": "OK",
    "general.annulla": "Annulla",
    "general.confirm": "Conferma",
    "general.servizi": "SERVIZI",
    "general.services": "Servizi",
    "general.anagrafica": "Anagrafica",
    "general.legal-info": "Informative Legali",
    "general.cookie": "Cookie",
    "general.servicesTab": "Scheda Servizi",
    "general.privacy": "Privacy",
    "general.privacy.disclaimer": "Informativa Privacy",
    "general.releasenotes": "Novità",
    "general.stato": "Stato",
    "general.codice-fiscale": "Codice fiscale",
    "general.name": "Nome",
    "general.lastname": "Cognome",
    "general.partita-iva": "Partita Iva",
    "general.type": "Tipologia",
    "general.tipo": "Tipo",
    "general.streetName": "Via",
    "general.streetNumber": "N. Civico",
    "general.city": "Città",
    "general.birth-city": "Città di nascita",
    "general.birth-place": "Luogo di nascita",
    "general.province": "Provincia",
    "general.comune": "Comune",
    "general.zipCode": "Cap",
    "general.taxRegime": "Regime Fiscale",
    "general.taxRegion": "Codice Nazione",
    "general.rea": "Numero rea",
    "general.cciaa": "Ufficio rea",
    "general.capitalStock": "Capitale sociale",
    "general.liquidationState": "Stato liquidazione",
    "general.pec": "PEC",
    "general.personalData": "Dati anagrafici",
    "general.additionalData": "Dati aggiuntivi",
    "general.gestisci": "Gestisci",
    "general.inizia-ora": "INIZIA ORA",
    "general.it-IT": "Italiano",
    "general.company.not-active": "Azienda non validata",
    "general.company.activateb2b": "Attiva il servizo B2B o SDI",
    "general.dashboard": "Dashboard",
    "general.upload": "Carica",
    "general.users": "Utenze",
    "general.applications": "Applicativi",
    "general.offices": "Uffici",
    "general.intermediari": "Intermediari",
    "general.connections": "Connessioni",
    "general.customer-management": "Gestione clienti",
    "general.apps": "Apps",
    "general.save": "Salva",
    "general.EBS.INFO": "TS Pay - Informative",
    "general.EBS.POST": "TS Pay - Dispositive",
    "general.EIP.ERCV": "E-RICEVI",
    "general.EIP.SDI": "SDI",
    "general.EIP.NSO": "NSO",
    "general.EIP.CRT": "Corrispettivi RT",
    "general.HR": "HR Paghe",
    "general.PRV": "PRIVACY",
    "general.PRIVACY": "PRIVACY",
    "general.DBX": "DIGITAL BOX",
    "general.DBX.CORE": "DIGITAL BOX",
    "general.DBX.BP": "DIGITAL BOX - Business Performance",
    "general.EIT": "INCASSA SUBITO",
    "general.CRAM.MODECRAM": "CHECK UP IMPRESA",
    "general.selectOne": "Seleziona",
    "general.copyAuthToken": "Copia token utente",
    "general.note": "Note",
    "general.notifications": "Notifiche",
    "general.convert": "Converti",
    "general.rememberLater": "Ricorda in seguito",
    "general.username": "Username",
    "general.select-file": "Seleziona documento",
    "general.email-delivery": "Recapito email",
    "general.watch-video": "Guarda il video",
    "general.vat-number.short": "P.iva",
    "general.tax-id.short": "C.F.",
    "general.see-more": "Scopri di più",

    "general.clienti": "Clienti",

    /* classifiers */
    "general.classifiers.BUILDING": "Condominio",
    "general.classifiers.COMPANY": "Azienda",
    "general.classifiers.PERSON": "Persona fisica",
    "general.classifiers.STUDIO": "Studio",
    "general.classifiers.ASSOCIATION": "Associazione",

    /*Company status */
    "general.status.VALIDATED": "VALIDATA",
    "general.status.UNVERIFIABLE": "NON VALIDATA",
    "general.status.UNVERIFIABLE_PENDING_VALIDATE": "IN ATTESA DI VALIDAZIONE",
    "general.status.REJECTED": "RIGETTATA",
    "general.status.REJECTED_PENDING_VALIDATE": "IN ATTESA DI VALIDAZIONE",

    /*Company certification status */
    "general.status.CERTIFIED": "CERTIFICATA",
    "general.status.AWAITING_UPLOAD": "IN ATTESA DI RICERTIFICAZIONE",
    "general.status.AWAITING_APPROVAL": "CERTIFICAZIONE IN ATTESA DI VERIFICA",

    "general.service.loading": "Caricamento...",
    "general.service.active": "Servizio attivo",
    "general.services.active": "Servizi attivi",
    "general.service.not-active": "Servizio non attivo",
    "general.service.not-allowed": "Ruoli insufficienti per visualizzare l'informazione",
    "general.search": "Cerca",
    "general.ragione-sociale": "Ragione sociale",
    "general.company-fiscal-code": "CF Azienda",
    "general.rdc-fiscal-code": "CF Responsabile",
    "general.rdc-description": "Responsabile TS Digital Archive",
    "general.stato-richiesta": "Stato Richiesta",
    "general.warning": "Avvisi",
    "general.CF": "CF",
    "general.PIVA": "P. IVA",
    "general.CF-value": "CF: {taxId} ",
    "general.PIVA-value": "P. IVA: {vatNumber} ",
    "general.no-result": "Nessun risultato",
    "general.description": "Descrizione",
    "general.action": "Azioni",
    "general.add": "Aggiungi",
    "general.forgot-password": "Password dimenticata?",
    "general.select": "Seleziona",
    "general.error": "Errore: {errMsg}",
    "general.simple-error": "Errore",
    "general.id": "Id",
    "general.date-time-creation": "Data/Ora Creazione",
    "general.soleShareholder": "Socio unico",
    "general.economicActivity": "Codice ATECO",
    "general.registerDescription": "Albo professionale",
    "general.registerCode": "Numero iscrizione Albo",
    "general.registerProvince": "Provincia Albo",
    "general.registerRegistrationDate": "Data iscrizione Albo",
    "general.creation-date": "Data Creazione",
    "general.creation-datetime": "Data/Ora Creazione",
    "general.modify-date": "Data Modifica",
    "general.govCode": "Codice ufficio fatturazione elettronica",
    "general.address": "Indirizzo",
    "general.selected-company-label": "azienda selezionata: ",
    "general.send-in-progress": "Invio in corso",
    "general.send-validation-request": "Invia richiesta di validazione",
    "general.birth-date": "Data di nascita",
    "general.paese": "Paese",
    "general.document-number": "N° documento",
    "general.released-by": "Rilasciato da",
    "general.expire-date": "Data di scadenza",
    "general.contacts": "Contatti",
    "general.user.master": "Utente master",
    "general.user.common": "Utente",
    "general.form.captcha-required": "Verifica di non essere un robot",
    "general.form.could-not-verify-user-existence": "Errore nella verifica dell'esistenza utente",
    "general.form.field-exact-length": "Il valore deve essere lungo {length} caratteri",
    "general.form.field-length-range": "Il campo deve avere una lunghezza compresa tra {min} e {max} caratteri",
    "general.form.field-required": "Campo obbligatorio",
    "general.form.invalid-data-format": "Formato dato non valido",
    "general.form.email-required": "L'indirizzo email digitato non è valido",
    "general.form.email-already-exists": "L'indirizzo email inserito è già presente in lista",
    "general.form.numbers-only": "Il campo deve contenere valori numerici",
    "general.form.password-requirements-tooltip":
        "La password deve essere lunga almeno 8 caratteri e deve contenere: <br/> - almeno una lettera maiuscola: A-Z <br/> - almeno una lettera minuscola: a-z <br/> - almeno un numero: 0-9 <br/>  - almeno un carattere speciale: !@#£%?ç$%^&*)(+=._-",
    "general.form.user-already-exists": "Utente già esistente",
    "general.form.weak-password": "La password non è abbastanza complessa",
    "general.form.wrong-password-confirmation": "Le password inserite non combaciano",
    "general.ts": "TeamSystem",
    "general.tsdigitaldomain": "teamsystemdigital.com",
    "general.firstName": "Nome",
    "general.lastName": "Cognome",
    "general.place": "Luogo",
    "general.date": "Data",
    "general.change-data": "Modifica dati",
    "general.new-user": "Nuovo utente",
    "general.create-new-user": "Crea nuovo utente",
    "general.create-or-add-new-user": "Crea o aggiungi utente",
    "general.invite-new-user": "Invita utente",

    "general.access-key-owned": "Hai già delle credenziali tecniche? Clicca qui.",

    "general.waitinglist.add": "Aggiungimi alla lista di attesa",
    "general.waitinglist.manage": "Gestisci lista di attesa",
    "general.waitinglist.modal-text":
        "Proseguendo la tua azienda verrà aggiunta alla lista di attesa per l'attivazione dell'applicazione selezionata.",
    "general.waitinglist.modal-text-remove":
        "Proseguendo la tua azienda verrà rimossa dalla lista di attesa per l'attivazione dell'applicazione selezionata.",
    "general.waitinglist.confirm-request": "Conferma richiesta",
    "general.waitinglist.confirm-request-remove": "Conferma rimozione",
    "general.waitinglist.manage-request.maintitle": "Gestisci lista di attesa",
    "general.waitinglist.manage-request.title": "Richiesta effettuata da:",
    "general.waitinglist.manage-request.date": "In data:",
    "general.waitinglist.manage-request.remove": "Rimuovimi dalla lista",
    "general.complete": "Completa",
    "general.validate": "Valida",

    "general.service-activation":
        "Richiesta presa in carico. A breve potrai accedere all'applicazione dalla sezione 'le mie app' della dashboard.",
    "general.post-rest-success": "Operazione correttamente presa in carico",

    "general.uservoice.accept-cookie-to-vote": "Per lasciarci il tuo feedback accedi e attiva i cookie profilanti",

    "user.account-type.PERSONALE": "Personale",
    "user.account-type.P": "Personale",

    "c-signature.description":
        "<p>Se non si ha un proprio sito web dove mettere a</p><p>disposizione la documentazione richiesta, si può</p><p>usare: https://www.agyo.io/it/manualetecnicofea.pdf</p>",

    "c-form-login.password-forgotten": "Password dimenticata?",
    "c-form-login.retrieve-credentials": "Recupera le mie credenziali",
    "c-form-login.ssoconnect.text": "Collega",
    "c-form-login.ssoaccess.text": "Accedi",
    "c-form-login.ssoaccess.expiredPwd": "Aggiorna password",
    "c-form-login.sso.insert-credential":
        "Inserisci le credenziali che utilizzavi per accedere ad TS Digital relative all'utente da collegare TeamSystem ID ",
    "c-form-login.button.text": "Accedi senza TeamSystem ID",
    "gdpr.set-role-association.success": "Il messaggio è stato correttamente inviato all’indirizzo: {email}",
    "gdpr.text1": "Dal <b>25 maggio 2018</b> acquista efficacia il nuovo",
    "gdpr.text2": "<b>Regolamento Europeo sulla protezione dei dati personali (GDPR).</b>",
    "gdpr.text4":
        "Per fruire dell'adeguamento <b>devi accedere con il TeamSystem ID</b>: l'identif" +
        "icativo universale per accedere alle tue piattaforme gestionali, nel rispetto de" +
        "i requisiti di sicurezza previsti dal GDPR.",
    "gdpr.popuptext":
        "Stai accedendo con un account tradizionale TS Digital; anche se hai già collegato un T" +
        "eamSystem Id questa modalità di accesso non è conforme alla nuova normativa Gdpr" +
        ". Al momento l'accesso è consentito ma non lo sarà più dal 25 maggio, data in cu" +
        "i entra pienamente in vigore la nuova normativa. Quindi l'invito è a provvedere " +
        "per tempo (se non già fatto) a creare una identificazione con TeamSystem Id, col" +
        "legarla all'utenza TS Digital tradizionale, e quindi usare la modalità di accesso con " +
        "TeamSystem Id.",
    "gdpr.popuptextcontinue": "Continua",
    "gdpr.norm-details": "Dettagli Normativa",
    "gdpr.faq": "FAQ GDPR",
    "gdpr.sign-contract": "Firma contratto",
    "gdpr.modal-title": "Adeguamento GDPR",

    "gdprlog.text1": "Non puoi accedere ai logs perchè non autorizzato.",
    "gdprlog.text2": "Non puoi accedere ai logs perchè non confermato",
    "gdprlog.text3": "Non è possibile accedere ai logs",

    "gdprlog.impossibile-use": "Impossibile utilizzare l'app GDPR Log",
    "gdprlog.general-error": "Non hai i permessi per completare l'azione",

    "c-form-sso.text1":
        "Con il Login Unico TS e, con un unico accesso, ogni utente potrà accedere ai diversi applicativi",
    "c-form-sso.text2":
        "dell'architettura TeamSystem, risolvendo il problema di ricordare credenziali diverse a seconda del sistema utilizzato.",
    "c-form-sso.text3":
        "Se non si dispone già di un account unico TS, si può creare inserendo username, password ed email.",
    "c-form-sso.text4":
        "L'utente dovrà creare l'account solo la prima volta che accede ad ogni applicativo, in modo da collegare le credenziali",
    "c-form-sso.text5": "uniche restituite da TeamSystem con la sua profilazione all'interno del singolo sistema.",
    "c-form-sso.text6": "In questo modo, l'utente, da qualunque applicativo effettui il login unico,",
    "c-form-sso.text7":
        " potrà accedere ai diversi sistemi che intende utilizzare, senza necessariamente fare il logout da un applicativo per poi 'loggarsi',",
    "c-form-sso.text8": "con credenziali diverse, ad un altro.",

    "c-form-sso.login": "Accedi con TeamSystem ID",
    "c-form-sso.login-text1": "Se non possiedi l'account unico",
    "c-form-sso.login-text2": " TeamSystem, crealo ora.",

    "c-form-sso.what-is": "Che cos'è un account unico TeamSystem?",

    "c-not-supported.title": "Siamo spiacenti, attualmente il browser in uso non è supportato.",
    "c-not-supported.body": "Per accedere al servizio ti consigliamo di utilizzare uno fra i seguenti browser: ",
    "c-not-supported.download-chrome": "Scarica Chrome",

    "c-sso.header.Row.Col.FormGdprLogin": "Accedi con il Teamsystem Id",
    "c-sso.header.Row.Col.text-divider": " Se non possiedi l 'account unico",
    "c-sso.header.Row.Col.text-divider2": "TeamSystem, crealo e poi collegalo al tuo account TS Digital.",
    "c-sso.header.Row.Col.Button": "Crea il Teamsystem ID",
    "c-sso.header.Row.Col.NonHaiAccountAgyo": "Non hai ancora un account TS Digital?  ",
    "c-sso.header.Row.Col.a": "Registrati adesso!",
    "c-sso.header.NiceError": " Le credenziali inserite non sono valide ",

    "profile.header.tsID_state": "Stato TSID: ",
    "profile.header.email": "Email: ",
    "profile.header.tsId": "TsId: ",

    "c-registry-view-api-key":
        "Utilizza i servizi TS Digital direttamente dal tuo gestionale: genera qui le credenzi" +
        "ali tecniche per configurare l’integrazione con i tuoi applicativi.",
    "c-registry-view-intermediary":
        "Configura qui gli intermediari in modo da gestire in maniera distinta i clienti " +
        "e i relativi documenti che transitano su TS Digital.",
    "c-registry-view-intermediary-disabled-creation":
        "La creazione di nuovi intermediari non è più consentita. La funzionalità verrà sostituita dalla gestione Uffici.",
    "c-registry-view-office":
        "Hai più sedi operative? Gestisci qui i tuoi uffici/sedi ottimizzando il tuo account TS Digital.",
    "c-registry-view-intermediary-disabled-edit":
        "La modifica degli intermediari non è più consentita. La funzionalità verrà sostituita dalla gestione Uffici.",
    "c-registry-view-intermediary-disabled-edit-title": "Modifica Intermediari",

    "c-registry-user.local-services": "Servizi locali",
    "c-registry-user-personal.delete.title": "Elimina utenza",
    "c-registry-user-personal.delete.confirm": "Sei sicuro di voler eliminare l'utenza {user} ?",
    "c-registry-user-personal.delete.error":
        "Impossibile eliminare l'utenza {user} perchè proprietario dell'azienda con cui stai lavorando.",
    "c-registry-user-personal.info.title": "Info utente ",

    "c-registry-user-tech.add.not-permitted-not-validated":
        "Non è possibile creare applicativi per le aziende non validate",
    "c-registry-user-tech.search.placeholder": "Ricerca per chiave tecnica o descrizione e premi invio",
    "c-registry-user-tech.delete.title": "Elimina applicativo",
    "c-registry-user-tech.delete.confirm": "Sei sicuro di voler eliminare l'applicativo {user} ?",
    "c-registry-user-tech.delete.error": "ATTENZIONE",
    "c-registry-user-tech.info.title": "Info applicativo",
    "c-registry-user-tech.add.title": "Aggiungi applicativo",
    "c-registry-user-tech.edit.title": "Modifica applicativo {user}",

    "c-registry-user-tech.login.error.notValid":
        "Le credenziali inserite non risultano essere valide. Controlla ID e Secret e riprova.",
    "c-registry-user-tech.login.error.generic":
        "Si è verificato un errore nel processo di controllo credenziali. Si prega di riprovare più tardi.",

    "c-registry-user.info.active": "Attivo",
    "c-registry-user.info.notactive": "Non attivo",
    "c-registry-user.info.detail": "Dettaglio Utente",
    "c-registry-user.info.back-to-users": "< Utenze",
    "c-registry-user.info.roles": "Permessi",

    "c-registry-user.info.service": "Servizio",
    "c-registry-user.info.featurecode": "Sotto servizio",
    "c-registry-user.info.company": "Azienda",
    "c-registry-user.info.role": "Ruolo",
    "c-registry-user.info.person": "Persona",

    "c-registry-user.info.id": "Identificativo: ",
    "c-registry-user.info.name": "Nome: ",
    "c-registry-user.info.surname": "Cognome: ",
    "c-registry-user.info.description": "Descrizione: ",
    "c-registry-user.info.type": "Tipologia: ",
    "c-registry-user.info.state": "Stato: ",
    "c-registry-user.info.name-surname": "Nome/Cognome: ",
    "c-registry-user.info.fiscal-code": "Codice Fiscale: ",
    "c-registry-user.info.gender": "Sesso: ",
    "c-registry-user.info.place-of-birth": "Luogo di nascita: ",
    "c-registry-user.info.date-of-birth": "Data di Nascita: ",
    "c-registry-user.info.address": "Indirizzo: ",
    "c-registry-user.info.email": "E-mail: ",
    "c-registry-user.info.phonenumber": "Numero di telefono: ",
    "c-registry-user.info.document-type": "Tipologia Documento: ",
    "c-registry-user.info.released-by": "Rilasciato da: ",
    "c-registry-user.info.document-number": "Numero documento: ",
    "c-registry-user.info.expire-date": "Data scadenza: ",

    "c-registry-user.title": "GESTIONE UTENTI E RUOLI",
    "c-registry-user.subtitle": "Gestisci i ruoli per gli utenti delle tue gestite",

    "c-registry-user.delete-user-invitation-success.toast-success.title": "Annulla invito",
    "c-registry-user.delete-user-invitation-success.toast-success.content": "l'invito è stato annullato correttamente.",

    "c-registry-user.delete-user-invitation-error.toast-error.title": "Annulla invito",
    "c-registry-user.delete-user-invitation-error.toast-error.content": "errore durante annullamento dell'invito.",

    "c-registry-user.invite-toast-success.title": "Utente invitato",
    "c-registry-user.invite-toast-success.content":
        "un'email di promemoria è stata inviata agli utenti selezionati per completare l'attivazione dell'account.",

    "c-registry-user.invite-toast-error.title": "Errore in invito utente",
    "c-registry-user.invite-toast-error.content":
        "si è verificato un errore durante l'invio del promemoria all'utente, riprova più tardi.",

    "c-registry-user.remove-roles.modal-title": "Rimuovere l'utente selezionato?",
    "c-registry-user.remove-roles.modal-content": "Stai per rimuovere dall'azienda l'utente {email}.",

    "c-registry-user.remove-roles.modal-title.delete-invitation": "Annullare l'invito per l'utente selezionato?",
    "c-registry-user.remove-roles.modal-content.delete-invitation":
        "Stai per annullare l'invito a collaborare per l'utente {email}.",

    "c-registry-user.remove-roles.toast-success.title": "Rimozione presa in carico",
    "c-registry-user.remove-roles.toast-success.content": "la rimozione dell'utenza dall'azienda è in elaborazione.",

    "c-registry-user.remove-roles.toast-error.title": "Errore nella rimozione",
    "c-registry-user.remove-roles.toast-error.content":
        "si è verificato un errore durante la rimozione dell'utenza, riprova più tardi.",

    "c-registry-user.company-title-company": "AZIENDA",
    "c-registry-user.company-title-studio": "STUDIO",
    "c-registry-user.change-company-button": "Cambia azienda",

    "c-registry-user.company.modal-validate-company.title": "Vuoi invitare un utente? Valida la tua azienda",
    "c-registry-user.company.modal-validate-company.description":
        "Al momento non puoi invitare nuovi utenti su questa azienda perché non l’hai ancora validata. Valida l’azienda per poter procedere.",

    "c-registry-user.company.modal-manage-connection.title":
        "Vuoi gestire i servizi di questa azienda? Vai alle connessioni",
    "c-registry-user.company.modal-manage-connection.description":
        "Non puoi invitare nuovi utenti su questa azienda perché è validata ma non certificata. Vai alle connessioni per gestire quelle attuali o eliminarle.",
    "c-registry-user.company.modal-manage-connection.continue": "Vai alle Connessioni",

    "c-registry-user.change-company.drawer.title": "CAMBIA AZIENDA",
    "c-registry-user.change-company.drawer.subtitle": "Scegli dalla lista delle tue aziende",
    "c-registry-user.change-company.drawer.item.set-permissions": "Imposta Permessi",

    "c-registry-user.change-company.drawer.description-title": "Scegli l'Azienda",
    "c-registry-user.change-company.drawer.description":
        "Scegli tra le Aziende disponibili quella per cui vuoi visualizzarne gli utenti e gestirne i permessi.",
    "c-registry-user.change-company.drawer.search-label": "Cerca Azienda",
    "c-registry-user.change-company.drawer.search-placeholder": "Cerca azienda per nome...",

    "c-registry-user.user-details.drawer.no-selected-user": "No selected user",
    "c-registry-user.user-details.drawer.title": "DETTAGLI UTENTE",
    "c-registry-user.user-details.drawer.subtitle": "Visualizza i ruoli dell'utente nell'azienda selezionata",

    "c-registry-user.description":
        "Configura al meglio l’accesso al tuo account TS Digital: genera qui le utenze per tut" +
        "to il personale che dovrà utilizzare TS Digital ed imposta per ciascuno i permessi, l" +
        "imitandone la visibilità e le azione disponibili in relazione alle tue esigenze.",

    "c-registry-user.users-manage-all": "Tutti",
    "c-registry-user.users-manage-admin": "Admin",
    "c-registry-user.users-manage-companyadmin": "Admin dell'azienda",
    "c-registry-user.users-manage-owner": "Owner",
    "c-registry-user.users-manage-companyowner": "Owner dell'azienda",
    "c-registry-user.users-manage-companyemployee": "Employee dell'azienda",
    "c-registry-user.users-manage-employee": "Employee",
    "c-registry-user.users-manage-manager": "Manager",
    "c-registry-user.users-manage-multipleRoles": "Ruoli Multipli",
    "c-registry-user.users-manage-noRole": "Nessun ruolo",
    "c-registry-user.users-manage-select": "Seleziona ruolo",
    "c-registry-user.users-manage-collaborator": "Collaborator",

    "c-registry-user.role-level-company": "Aziendale",
    "c-registry-user.role-level-service": "Su un servizio",
    "c-registry-user.role-level-services": "Su più servizi",
    "c-registry-user.role-level-featureCode": "Su un sottoservizio",
    "c-registry-user.role-level-featureCodes": "Su più sottoservizi",
    "c-registry-user.role-level-servicesFeatures": "Su più servizi e sotto servizi",

    "c-item-roles-table.item-actions-cell.edit-app-permissions-button.tooltip":
        "L’utente ha un ruolo a livello aziendale quindi non puoi modificare ruoli sui servizi, vai alla pagina di modifica permessi per l’azienda di riferimento se vuoi modificare il ruolo dell'utente sull'azienda.",

    "c-registry-user.manage-company": "Seleziona azienda",
    "c-registry-user.manage-company-placeholder": "Seleziona azienda",

    "c-registry-user.manage-company-description":
        "Gestisci le utenze per il personale che dovrà utilizzare TS Digital per l’azienda selezionata e modifica permessi o impostane di nuovi per ciascuno, limitandone la visibilità e le azioni disponibili in relazione alle tue esigenze.",

    "c-registry-user.apps-filter": "Filtra per servizi e sotto servizi",
    "c-registry-user.email-filter-placeholder": "Inserisci email",
    "c-registry-user.roles-filter": "Filtra per ruolo",

    "c-registry-user.combo-placeholder": "Scegli un'opzione",

    "c-registry-user.user-active": "Attivo",
    "c-registry-user.user-not-active": "In attesa",

    "c-registry-user.options.details": "Visualizza dettagli",
    "c-registry-user.options.edit": "Modifica permessi",
    "c-registry-user.options.delete": "Rimuovi utente",
    "c-registry-user.options.invite-again": "Invita nuovamente",
    "c-registry-user.options.delete-invitation": "Annulla invito",

    "c-registry-user.your-studio": "Il tuo studio",

    "c-registry-user.choose-roles-on-company": "Seleziona ruolo aziendale",
    "c-registry-user.choose-roles-on-services": "Seleziona ruolo sui servizi",
    "c-registry-user.choose-company-role.tooltip": `<b>Assegnare più ruoli</b><br/><br/>Selezionando l’opzione “Ruoli multipli” sarà possibile assegnare ruoli differenti a livello di servizi e sotto servizi. In alternativa puoi selezionare un ruolo specifico da applicare a livello aziendale che avrà valenza anche sui servizi che verranno attivati in futuro.`,
    "c-registry-user.choose-service-role.tooltip": `<b>Assegnare più ruoli</b><br/><br/>Selezionando l’opzione “Ruoli multipli” sarà possibile assegnare ruoli differenti a livello di servizi e sotto servizi. In alternativa puoi selezionare un ruolo specifico da applicare massivamente ai servizi`,
    "c-registry-user.active-roles": "Permessi attivi",
    "c-registry-user.active-roles.tooltip": "Permessi assegnati all’utente in fase di creazione utenza",
    "c-registry-user.add-roles": "Aggiungi permessi",
    "c-registry-user.add-roles.tooltip":
        "Servizi attivi dell’azienda per cui è possibile assegnare permessi all’utente.",

    "c-registry-user.choose-roles.tooltip": `<b>Assegnare più ruoli</b><br/><br/>Selezionando l’opzione “Ruoli multipli” sarà possibile assegnare ruoli differenti a livello di servizi e sotto servizi.In alternativa puoi selezionare un ruolo specifico da applicare a livello aziendale che avrà valenza anche sui servizi che verranno abilitati in futuro.`,

    "c-expense-report.config-text": "Configurazione Note Spese con invio della mail di attivazione.",
    "c-expense-report.send-email": "Invia email di attivazione",
    "c-expense-report.activation": "Attivazione App Note Spese",
    "c-expense-report.description": "Gestisci le note spese.",

    "c-services-view-accounting": "Contabilità",
    "c-services-view.BDG.name": "Bilancio SAAS",
    "apps.BDG.quickdesc": "Bilancio SAAS",
    "c-services-view.EIP.name": "TS Digital Invoice",
    "c-services-view-expense-reports": "Nota Spese in Cloud",
    "c-services-view.TEL.name": "TS Fiscal Link",
    "c-services-view.GDPR-LOG.name": "Gdpr log",
    "c-services-view.PRIVACY.name": "Privacy in Cloud",
    "c-services-view-privacy.not-bought": "Non hai acquistato il servizio Privacy in Cloud.",
    "c-services-view-privacy.impossible-use": "Impossibile utilizzare l'app privacy",
    "c-services-view.SIG.name": "TS Digital Signature",
    "c-services-view.ARC.name": "TS Digital Archive",
    "c-services-view.DBX.name": "TS Digital Box",
    "c-services-view.EBS.name": "Contabilità",
    "c-services-view.EXP.name": "Nota Spese in Cloud",
    "c-services-view.TSPAY.name": "TS PAY",
    "c-services-view.EIT.name": "Incassa Subito",
    "apps.EIT.quickdesc": "Incassa Subito",
    "apps.DBX.quickdesc": "Per accedere a TS Digital Box",
    "c-services-view-console-digitale.not-bought": "Non hai acquistato il servizio TS Digital Box.",
    "c-services-view-console-digitale.impossible-use": "Impossibile utilizzare l'app TS Digital Box",
    "c-services-view-cards-container.title": "TS Digital Box",
    "c-services-view-cards-container.description": "L'applicazione in TS Digital per collegarsi a TS Digital Box.",
    "c-services-view-people-app.not-bought": "Non hai acquistato il servizio TS HR People App.",
    "c-services-view-people-app.impossible-use": "Impossibile utilizzare l'app TS HR People App",
    "c-services-view.PPL.name": "TS HR People App",
    "apps.PPL.quickdesc": "Per accedere a TS HR People App",
    "c-services-view-cards-container-people-app.title": "TS HR People App",
    "c-services-view-cards-container-people-app.description":
        "L'applicazione in TS Digital per collegarsi a TS HR People App.",
    "c-services-view.cosa-aspetti": "La tua azienda non è ancora stata validata. Cosa aspetti?",
    "c-services-view.valida-ora": "Validala ora!",
    "c-services-view.stiamo-validando":
        "Stiamo procedendo alla validazione della tua azienda. Riceverai un'email di notifica al più presto.",
    "c-services-view.validata": "La tua azienda è correttamente configurata.",
    "c-services-view.certificata": "La tua azienda è correttamente configurata e certificata.",
    "c-services-view.ricertificazione.upload":
        "La tua azienda è correttamente configurata. Tuttavia ti è stato richiesto di validare nuovamente il contratto TS Digital.",
    "c-services-view.ricertificazione.inProgress":
        "La tua azienda è correttamente configurata. Tuttavia stiamo verificando il nuovo contratto che ci hai fornito.",
    "c-services-view-metering": "Consumi",
    "gdpr.card":
        "Il servizio per archiviare e consultare i log delle attività svolte sui software gestionali TeamSystem, conformemente al Regolamento Europeo sulla protezione dei dati personali.",
    "gdpr.card.button.email": "Invia email di conferma",
    "gdpr.card.title": "Indirizzo pec che validerà l'abilitazione",
    "gdpr.card.utenti.disponibili": "Seleziona gli utenti che vuoi abilitare alla visualizzazione del GDPR Log ",
    "gdpr.card.modal.title": " Conferma responsabili visualizzazione log",
    "gdpr.card.modal.message1": "<b>ATTENZIONE</b>",
    "gdpr.card.modal.message2":
        " Questa nuova impostazione degli accessi ad Agyo Log, una volta confermata, sost" +
        "ituirà completamente l'impostazione precedente.",
    "gdpr.card.modal.message3": " \nPremere OK per procedere all'invio via email della richiesta di conferma.",
    "gdpr.card.modal.operazione.non.consentita": " Operazione non consentita. Indirizzo PEC non impostato.",
    "gdpr.card.utenti.info":
        "Selezionando gli utenti tra quelli seguenti, potrai autorizzarli ad accedere in consultazione ai log attraverso la piattaforma TS Digital. Ti ricordiamo che l’abilitazione dovrà essere concessa unicamente a utenti debitamente autorizzati dall'azienda/studio. Per completare il processo autorizzativo, sarà trasmessa all’indirizzo dell'owner dell'azienda una email di validazione. La presente procedura dovrà essere utilizzata per ogni eventuale variazione successiva. Il cliente esonera TeamSystem da ogni responsabilità in merito all’accesso al portale TS Digital da parte degli utenti abilitati secondo la procedura descritta ",
    "gdpr.card.services.title": "Gdpr Log",
    "gdpr.card.column.TSID": "TS-ID",
    "gdpr.card.column.agyoId": "Utente TS Digital",
    "gdpr.card.column.name": "Nome",
    "gdpr.card.column.surname": "Cognome",
    "gdpr.card.column.status": "Stato",
    "gdpr.card.column.status.disabled": "Non abilitato alla visualizzazione dei logs",
    "gdpr.card.column.status.confirmed": "Abilitato alla consultazione dei logs",
    "gdpr.card.column.status.unconfirmed": "In attesa di conferma",

    "c-validate-privacy.manage-description": `Le tue informazioni e dati personali devono essere sempre a proprio agio. Per noi la privacy rappresenta un tema molto importante. Puoi visualizzare l'informativa sul trattamento dei dati a questo link ${PRIVACY_POLICY_URL} o modificare le preferenze di gestione dei tuoi dati.`,
    "c-validate-privacy.manage-description-long": `Dichiari di avere preso visione dell’informativa sul trattamento dei dati personali allegata alla Condizioni Generali e disponibile al seguente indirizzo ${PRIVACY_POLICY_URL} e di prestare il consenso al trattamento dei propri dati personali per finalità di marketing mediante invio di newsletter e comunicazioni commerciali (per posta, telefono,fax e/o SMS/MMS, notifiche push) relative a prodotti/servizi TeamSystem (e di suoi partner commerciali) che TeamSystem possa ritenere di suo interesse, nonché per analisi statistica, rilevazione del grado di soddisfazione e indagini di mercato`,
    "c-validate-privacy.declaration":
        "Dichiari di prestare il consenso al trattamento dei propri dati personali per finalità di profilazione e analisi delle proprie preferenze, interessi e abitudini.",
    "c-validate-privacy.step0": "Gestisci le tue opzioni di trattamento dei dati",
    "c-validate-privacy.step1": "Passo 1",
    "c-validate-privacy.step2": "Passo 2",
    "c-validate-privacy.step3": "Passo 3",

    "c-upload-field.choose-file": "Scegli il file da caricare",

    "c-validate.attention-message":
        "ATTENZIONE: dati azienda incompleti, per poter generare il contratto è necessario completare l'anagrafica azienda.",
    "c-validate.subtitle": "Compila i dati richiesti per poter generare il contratto precompilato.",
    "c-validate.description":
        "Generando il contratto dichiari di accettare la privacy secondo le modalità di gestione consigliate per poter sfruttare appieno la piattaforma TS Digital. ",
    "c-validate.quickdesc":
        "Una volta generate le condizioni generali ricarica il file firmato per proseguire la validazione",
    "c-validate.generate-contract": "Genera il contratto",
    "c-validate.ok-operation": "Operazione effettuata correttamente",
    "c-validate.checking": "Provvederemo al più presto alla verifica delle tue condizione generali.",

    "gdpr.login-form.invalid-email": "Inserisci una mail valida",
    "gdpr.login-form.email-placeholder": "Email Teamsystem Id",
    "gdpr.login-form.password-placeholder": "Password Teamsystem Id",

    "root.layout.benvenuto": "Ti diamo il benvenuto in TS Digital con cui potrai:",
    "root.layout.ricevere-fatture": "Ricevere fatture elettroniche",
    "root.layout.inviare-fatture": "Inviare fatture cartacee o in pdf",
    "root.layout.inviare-sdi": "Inviare i nuovi telematici fiscali al Sistema Di Interscambio",
    "root.layout.inviare-cct": "Inviare in conservazione tutti i documenti che gestisci con TS Digital",
    "root.layout.inserisci-azienda": "Inserisci i dati dell'azienda che vuoi creare.",
    "root.layout.creata-azienda": "Complimenti la tua azienda è stata creata correttamente",
    "root.layout.credenziali": "Riceverai presto una mail con delle credenziali che ti consentiranno di",
    "root.layout.utilizzare-agyo": "utilizzare i servizi di TS Digital direttamente dal tuo gestionale.",

    "root.privacy-modal.title": "Teamsystem protegge la tua privacy",
    "root.privacy-modal.paragraphs.1":
        'Abbiamo aggiornato la nostra <a href="{privacyPolicyUrl}" target="_blank">informativa sul trattamento dei dati personali</a> per fornirti in modo ancora più chiaro e trasparente ogni informazione che possa aiutarti a tutelare la tua privacy e a controllare l’utilizzo che viene fatto dei tuoi dati.',
    "root.privacy-modal.paragraphs.2":
        "TeamSystem Service S.r.l. tratta i tuoi dati, nel rispetto degli obblighi previsti dalle vigenti normative in materia di protezione dei dati personali, sia per finalità contrattuali che per finalità di miglioramento del servizio e per le altre finalità di interesse legittimo descritte nella nostra informativa. Con il tuo consenso, TeamSystem Service S.r.l. potrà trattare i tuoi dati anche per iniziative di marketing e commerciali targettizzate alle tue esigenze previa analisi dei tuoi dati, inclusi quelli relativi all'utilizzo del sito e dei prodotti e servizi Teamsystem.",
    "root.privacy-modal.paragraphs.3":
        'Cliccando su "Accetto" acconsenti al trattamento dei tuoi dati per tali finalità commerciali e di marketing secondo le modalità descritte alla nostra <a href="{privacyPolicyUrl}" target="_blank">Informativa sulla Privacy</a>. Potrai sempre cambiare idea e revocare il tuo consenso secondo le modalità descritte nell’informativa o scrivendo a <a href="mailto:{privacyContactEmail}" target="_blank">{privacyContactEmail}</a>.',
    "root.privacy-modal.step0.title": "Informativa Privacy",
    "root.privacy-modal.step0.content":
        '<p>Quando utilizzi o richiedi i nostri prodotti e servizi trattiamo i tuoi dati personali e siamo consapevoli della necessità di proteggerli in modo adeguato. Per questo motivo, continuiamo ad impegnarci per fornirti in modo chiaro le informazioni che possono servirti a controllare l\'utilizzo che viene fatto dei tuoi dati.</p><p>Leggi la nostra <a href="{privacyPolicyUrl}" target="_blank">informativa sul trattamento dei tuoi dati personali</a> per capire meglio quali dati trattiamo, perché li trattiamo e quali sono i tuoi diritti.</p>',
    "root.privacy-modal.step1.title": "Perché trattiamo i tuoi dati",
    "root.privacy-modal.step1.content":
        '<p>I tuoi dati possono essere trattati per dare esecuzione al servizio o prodotto che hai acquistato, per consentirti la navigazione sul nostro sito, per gestire le tue richieste e fornirti assistenza, nonché per le altre finalità contrattuali che puoi approfondire nella nostra <a href="{privacyPolicyUrl}" target="_blank">informativa</a>.</p><p>Nel rispetto degli obblighi normativi in materia di protezione dei dati personali, trattiamo i tuoi dati per migliorare e sviluppare i nostri prodotti e per le altre finalità di interesse legittimo descritte nella nostra informativa, come ad esempio per la valutazione della soddisfazione da parte tua dei prodotti acquistati oppure per la risoluzione di eventuali difficoltà e problematiche relative al loro utilizzo.</p><p>Con il tuo consenso, potremo trattare i tuoi dati anche per iniziative di marketing e commerciali targettizzate alle tue esigenze previa analisi dei dati, inclusi quelli relativi all\'utilizzo del sito e dei prodotti e servizi Teamsystem. Cliccando su "OK" acconsenti al trattamento dei tuoi dati per tali finalità commerciali e di marketing secondo le modalità descritte alla nostra Informativa sulla Privacy. Per negare il consenso all\'utilizzo dei tuoi dati per finalità commerciali e di marketing, clicca semplicemente su "Nega".</p><p>Il consenso espresso è da te sempre revocabile senza alcuna conseguenza rispetto ai rapporti contrattuali in essere. Potrai sempre cambiare idea e revocare il tuo consenso secondo le modalità descritte nell\'informativa o scrivendo a <a href="mailto:{privacyContactEmail}" target="_blank">{privacyContactEmail}</a>.</p>',
    "root.privacy-modal.step2.title": "Le tue preferenze",
    "root.privacy-modal.step2.content":
        '<p>Se lo desideri, potrai ampliare la tua conoscenza del mondo TeamSystem e decidere di ricevere comunicazioni relative a novità e offerte commerciali anche da parte delle altre società del Gruppo TeamSystem e dai partner della sua rete vendita, a cui i tuoi dati saranno comunicati.</p><p>Cliccando su "OK" acconsenti a comunicare i tuoi dati alle scocietà del Gruppo TeamSystem e ai suoi partner per tali finalità commerciali e di marketing. Per negare il consenso clicca semplicemente su "Nega".</p>',

    "root.general-terms-modal.title": "Condizioni generali del servizio",
    "root.general-terms-modal.content":
        "<p>Gentile Cliente,</p><p>abbiamo aggiornato le condizioni generali d’uso della piattaforma TeamSystem Digital. Le nuove condizioni generali sono consultabili al link <a href='https://tc.teamsystem.com/TSDigital.pdf' target='_blank'>https://tc.teamsystem.com/TSDigital.pdf</a>.</p><p> Ti informiamo che la presente comunicazione è una modifica unilaterale effettuata ai sensi dell’art. 16 delle condizioni generali.</p><p> Grazie per aver scelto TeamSystem.</p>",
    "root.general-terms-modal.ok": "Ho preso visione e vado avanti",

    //Connessioni
    "connections.layout.add.company": "aggiungi un'azienda",
    "connections.layout.actions.eliminate-connections": "elimina",
    "connections.layout.actions.connection-settings": "impostazioni",
    "connections.layout.actions.connection-status": "stato:",
    "connections.layout.actions.connection-service": "servizio:",
    //connessioni filtri
    "connections.layout.filter.pending-request": "in attesa",
    "connections.layout.filter.pending-validation": "validazione in attesa",
    "connections.layout.filter.request-rejected": "rifiutata",
    "connections.layout.filter.validated": "validata",
    "connections.layout.filter.unverified": "non verificata",
    "connections.layout.filter.validation-rejected": "validazione rifiutata",
    "connections.layout.filter.select-all": "seleziona tutte",
    "connections.layout.filter.only-active": "attive",
    "connections.layout.filter.only-no-active": "non attive",
    "connections.layout.switch-button.managed": "gestite",
    "connections.layout.switch-button.manager": "gestori",
    "connections.layout.actions.generate-act-of-attachment": "Genera atto di affidamento",
    //Creazione connessione
    "connections.create.back-button": "connessioni",
    "connections.create.path-after-button": "/ creazione nuova connessione",
    "connections.create.step-1.name": "1. Seleziona un'azienda",
    "connections.create.step-1.create.name": "1. Crea una nuova azienda",
    "connections.create.step-1.text-box-placeholder": "cerca azienda",

    "connections.create.step-2.activate-button-text": "Attiva con un click!",
    "connections.create.step-3.on-click-validation": "Attiva con un click",
    "connections.create.step-4.name": "2. Crea Connessione",
    "connections.create.step-4.text-info": "Seleziona la funzionalità da collegare",
    "connections.create.step-4.select-service-text": "servizio",
    "connections.create.step-4.select-role-text": "ruolo",
    "connections.create.step-4.create-connection-button": "Crea connessione",
    "connections.create.step-5.name": "Fine",
    "connections.create.step-5.text-info": "Connessione creata con successo!",
    "connections.create.step-5.back-to-list-button": "Torna alla lista",
    "connections.create.step-x.button-next-text": "prosegui",
    "connections.create.step-x.button-prev-text": "indietro",
    "connections.create.step-x.button-skip-text": "salta",

    "c-connection-switcher.manager-conn": "Aziende Gestite",
    "c-connection-switcher.managed-conn": "Gestori",

    //Maschera connessioni
    "c-connection-action-bar.title": "Connessioni",
    "c-connection-action-bar.history.title": "Storico aziende connesse",

    "c-connection-form.role.READ": "Lettura",
    "c-connection-form.role.WRITE": "Scrittura",
    "c-connection-form.role.READ_WRITE": "Lettura e Scrittura",
    "c-connection-form.featureCode.label": "Seleziona il sotto servizio",
    "c-connection-form.appId.label": "Seleziona il servizio",
    "c-connection-form.role.empty": "Selezionare un ruolo",
    "c-connection-form.role.label": "Ruolo per admin e owner",
    "c-connection-form.role.helper-text":
        "Viene assegnato anche agli utenti che hanno già un ruolo sui servizi locali di questa azienda.",
    "c-connection-form.role.placeholder": "Seleziona un ruolo",
    "c-connection-form.techUsers.label": "Aggiorna i ruoli anche per le seguenti utenze tecniche",
    "c-connection-form.techUsers.placeholder": "Clicca qui per selezionare le utente tecniche",

    "c-connections-filter-bar.label": "Cerca tra le aziende esistenti",
    "c-connections-filter-bar.subtitle":
        "Cerca una delle tue connessioni, crea una nuova connessione<br/>con un'<b>azienda esistente</b> oppure crea una <b>nuova azienda</b>",
    "c-connections-filter-bar.or": "Oppure",
    "c-link-list.add-connection.title": "Le mie connessioni",
    "c-link-list.download-connections.title": "Scarica le connessioni in formato csv.",
    "c-link-list.download-connections.error": "Impossibile scaricare il file. Riprova più tardi.",

    "c-connections-filter-bar.request-connection": "Crea azienda da connettere",
    "c-connections-filter-bar.view-connection": "Visualizza",
    "c-connections-filter.bar.placeholder": "Cerca per partita IVA o Codice fiscale",
    "c-connections-filter.bar.search-all": "Carica tutte le tue connessioni",
    "c-connections-filter.bar.error": "Inserire una partia iva o un codice fiscale valido",
    "c-connections-filter.bar.disabled": "Non è possibile creare connessioni con le aziende non validate",

    "c-connections-settings": "Gestisci connessione",
    "c-connections-delete": "Elimina connessione",

    "c-connections-settings.path-after-button": "/ settings",
    "c-connection-settings.alert.needs-contract":
        "Per utilizzare la connessione è necessario caricare un atto d'affidamento",
    "c-connections-settings.alert.managed-service-already-active":
        "Impossibile estendere il servizio: l'azienda gestita ha già il servizio attivo",
    "c-connections-settings.alert.manager-service-is-not-active":
        "Impossibile estendere il servizio in quanto non è attivo per la tua azienda",
    "c-connections-settings-warn.cant-extend":
        "Impossibile estendere il servizio in quanto ti sta venendo fornito da un'altra azienda",
    "c-connections-create.success-message": "OPERAZIONE AVVENUTA CON SUCCESSO!",
    "c-connections-create.success-message-subtitle":
        "L'operazione è stata presa in carico. A breve potrai visualizzare l'azienda tra le tue connessioni.",
    "c-connections-create.success-message-back": "Torna alle connessioni",

    "c-connections-settings-warn.both-companies-service-is-not-active-message":
        "Entrambe le aziende non possono condividere il servizio",
    "c-connections-settings-warn.only-one-company-service-is-not-active-message":
        "Il servizio dell'azienda {id} non è attivo",

    "c-connections-settings-actions.service-sharing": "Condivisione del servizio:",
    "c-connections-settings-actions.service-load-affidamento": "Caricamento atto di affidamento:",
    "c-connections-settings-actions.service-sharing.share-service":
        "Voglio che i consumi di questo servizio siano addebitati alla mia azienda",
    "c-connections-settings-actions.service-sharing.dont-share-service":
        "Voglio utilizzare il servizio dell'azienda connessa",
    "c-connections-settings-actions.service-sharing.activate-service": "Attiva il servizio all'azienda connessa",
    "c-connections-settings-actions.service-sharing.deactivate-service": "Disattiva il servizio all'azienda connessa",

    "c-connections-settings-actions.SDI-conf": "Configurazione dello SDI:",
    "c-connections-settings-actions.SDI-conf.check-text": "Firma TS",

    "c-connections-settings-summary.manager-description-label": "Gestore: {managerDescription}",
    "c-connections-settings-summary.manager-id-label": "Codice fiscale gestore: {managerId}",
    "c-connections-settings-summary.specific": "Specifiche:",
    "c-connections-settings-summary.specific.managed-service": "Servizio gestito: {featureCode}",

    "c-connections-settings-summary.specific.activated-time": "Attivata il: {activationDate}",
    "c-connections-settings-summary.specific.modified-time": "Modificata il: {lastEditDate}",
    "c-connections-settings-summary.specific.created-time": "Creata il: {creationDate}",
    "c-connections-settings-summary.specific.connection-status": "Stato delle connessione: {status}",
    "c-connections-settings-summary.specific.connection-message": "Causa rifiuto: {msg}",

    "c-connection-list.confirm-deletion.title": "Sei sicuro di voler eliminare questa connessione?",
    "c-connection-list.confirm-deletion.description":
        "Eliminandola, gli utenti con ruoli relativi a tale connessione verranno convertiti in utenti con ruoli su tutti i servizi gestiti in locale dal tuo studio. Potrai sempre gestire e modificare tali ruoli dalla sezione Utenze.",
    "c-connection-list.latest-edit": "Ultima modifica: {date}",
    "c-connection-list.deletedAt": "Rimozione connessione: {date}",
    "c-connection-list.status": "Stato connessione:",
    "c-connection-list.status.UNVERIFIED": "Non Convalidata",
    "c-connection-list.status.PENDING_REQUEST": "In attesa di accettazione",
    "c-connection-list.status.VALIDATED": "Convalidata",
    "c-connection-list.status.REQUEST_REJECTED": "Rifiutata",
    "c-connection-list.status.VALIDATION_REJECTED": "Validazione rifiutata",
    "c-connection-list.status.PENDING_VALIDATION": "In attesa di convalida",

    "c-connection-list.status.CERTIFIED": "Certificata",
    "c-connection-list.status.AWAITING_UPLOAD": "In attesa di certificazione",
    "c-connection-list.status.AWAITING_APPROVAL": "Certificazione in verifica",

    "c-connection-item-found": "Non ci sono ancora connessioni al momento per l'azienda {item}",
    "c-connection-item-not-found": "L'azienda che stai cercando non esiste",
    "c-connection-item-add": "Nuova connessione",

    "c-connection.default-sign": "Firma Predefinita:",
    "c-connection.no-sign": "Non Firmare",
    "c-connection.sign-with-ts": "Firmare con Teamsystem Service",

    "c-link-list.loading-connections": "Caricamento delle connessioni...",
    "c-link-list.no-connections-present": "Non sono presenti connessioni per questa azienda",
    "c-link-list.add-connection": "Nuovo servizio",

    "c-login.ts-id": "Accedi utilizzando l'account unico TeamSystem",
    "c-login.divider": "oppure",
    "c-login.signup-question": "Non hai ancora un account?",
    "c-login.signup-link": "Registrati adesso!",
    "login.login-to-teamsystem-digital": "Accedi a TeamSystem Digital",
    "login.login-or-signup-with-ts-id": "Accedi o registrati con TeamSystem ID",
    "login.login-or-signup-without-ts-id": "Accedi o registrati senza TeamSystem ID",
    "login.signup-to-teamsystem-id": "Non hai ancora il TeamSystem ID? Registrati adesso",
    "login.login-with-credential":
        "Se non disponi di un TeamSystem ID Inserisci le tue credenziali per effettuare l’accesso a TeamSystem Digital.",
    "login.signup": "Non hai ancora un Account? Registrati adesso",
    "login.login-tsid-info":
        " Inserisci il tuo TeamSystem ID (TSID) e la tua Password per effettuare l’accesso a TeamSystem Digital.",
    "login.retrieve-your-email": "Recupera la tua Email",
    "login.your-email": "La tua Email",

    // Retrieve credentials component
    "c-retrieve-credentials.title": "Recupera le tue credenziali",
    "c-retrieve-credentials.no-owner": "Non è stato possibile individuare l'utenza associata all'azienda specificata",
    "c-retrieve-credentials.company-data-step.title":
        "Inserisci il Codice fiscale, la Partita IVA e la Nazione associate all'account",
    "c-retrieve-credentials.company-data-step.taxId-placeholder": "Inserisci il codice fiscale",
    "c-retrieve-credentials.company-data-step.vatNumber-placeholder": "Inserisci la Partiva IVA",
    "c-retrieve-credentials.company-data-step.recover-credentials-button": "Recupera credenziali",
    "c-retrieve-credentials.tech-keys-step.title":
        "Per recuperare l’email di una tua utenza inserisci l'ID di una chiave tecnica da te creata e il secret ad essa associato.",
    "c-retrieve-credentials.tech-keys-step.techId-label": "ID chiave tecnica",
    "c-retrieve-credentials.tech-keys-step.techId-placeholder": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
    "c-retrieve-credentials.tech-keys-step.techSecret-label": "Secret",
    "c-retrieve-credentials.tech-keys-step.techSecret-placeholder": "Inserisci il secret",
    "c-retrieve-credentials.user-retrieved-step.title":
        "La procedura è andata a buon fine! Abbiamo individuato l'email associata ai dati inseriti:",
    "c-retrieve-credentials.user-retrieved-step.back-to-login-button": "Torna alla pagina di login",

    "c-notification.action-accept": "Conferma",
    "c-notification.action-reject": "Rifiuta",
    "c-notification-list.empty-list": "Nessuna richiesta di connessione.",
    "c-notification-list.list-button": "Vedi tutte le richieste di connessione",

    "c-notification.status-icon.tooltip.unread": "Segna come letto",
    "c-notification.status-icon.tooltip.read": "Segna come non letto",
    "c-notification.status-icon.tooltip.needsInteraction": "Azione necessaria sulla notifica",
    "c-notification.status.accepted": "Richiesta accettata",
    "c-notification.status.rejected": "Richiesta rifiutata",

    "c-notifications-view.title": "Richieste di connessione",

    "c-detailed-notification.no-notification-selected": "Nessuna richiesta di connessione selezionata",
    "c-detailed-notification.accepted": "ACCETTATA",
    "c-detailed-notification.rejected": "RIFIUTATA",

    "c-fulfilments.comunication-bought": "Comunicazioni CbCr acquistate: ",
    "c-fulfilments.comunication-available": "Comunicazioni CbCr disponibili: ",
    "c-fulfilments.comunication-generated": "Comunicazioni CbCr generate: ",
    "c-fulfilments.send": "Invio file telematici",
    "c-fulfilments.send-description":
        "Direttamente dal tuo gestionale o applicativo di studio integrato con TS Digital, invii i telematici al Sistema Ricevente dell’Agenzia dell’Entrate.",
    "c-fulfilments.mass-sign.notes":
        "<b>Nota:</b><br />per attivare la firma massiva è necessario sottoscrivere con la propria smart card/business key il modulo di richiesta alla certification authority (solo al primo utilizzo).",
    "c-fulfilments.sign": "Firma per adempimenti",
    "c-fulfilments.sign.description":
        "Potrai firmare elettronicamente ed in maniera automatica i tuoi telematici senza necessità di utilizzare la tua smart card o business key.",
    "c-fulfilments.agyo-fulfilments-title": "TS Digital Adempimenti",
    "c-fulfilments.agyo-fulfilments-description":
        "Generi, controlli il telematico, e monitori lo stato della trasmissione dei tuoi adempimenti all’Agenzia delle Entrate. Tutto dall’app TS Digital.",
    "c-fulfilments.cbcr-title": "Country-by-Country (CBCR)",
    "c-fulfilments.cbcr-description":
        "Per completare in maniera semplice la “Comunicazione CbCR” per la tua Azienda o per il tuo Cliente.",
    "c-fulfilments.multisend-title": "Invio multiplo",
    "c-fulfilments.multisend-description": "Invia i file telematici all’Agenzia delle Entrate per i tuoi clienti.",

    //notifiche
    "notification.NEW_MANAGER_LINK_NOTIFICATION_REQUEST":
        "L'azienda {sender} ({senderId}) ha richiesto di poter gestire il tuo servizio {service} con permesso di {permission}. Accettare la richiesta?",
    "notification.NEW_MANAGER_LINK_NOTIFICATION_REQUEST.title": "Richiesta di Connessione",
    "notification.NEW_MANAGER_LINK_NOTIFICATION_ACCEPT":
        "La tua richiesta di connessione verso {sender} è stata accettata.",
    "notification.NEW_MANAGER_LINK_NOTIFICATION_ACCEPT.title": "Connessione Accettata",
    "notification.NEW_MANAGER_LINK_NOTIFICATION_REJECT":
        "La tua richiesta di connessione verso {sender} è stata rifiutata.",
    "notification.NEW_MANAGER_LINK_NOTIFICATION_REJECT.title": "Connessione Rifiutata",
    "notification.CONNECTION_REMOVED_NOTIFICATION_REQUEST":
        "La connessione fra {sender} ({senderId}) e {recipient} ({recipientId}) per il servizio {service} è stata eliminata.",
    "notification.CONNECTION_REMOVED_NOTIFICATION_REQUEST.title": "Notifica di connessione eliminata",
    "notification.permission.WRITE": "scrittura",
    "notification.permission.READ_WRITE": "lettura e scrittura",
    "notification.permission.READ": "lettura",
    "notification.permission.SHARE": "condivisione",

    //impostazioni notifiche
    "notifications.title": "Richieste di connessione",
    "notification-settings.title": "Impostazioni richieste di connessione",
    "notification-settings.add-title": "Scegli le impostazioni delle tue richieste di connessione",
    "notification-settings.delete-title": "Elimina Impostazioni delle richiesta di connessione",
    "notification-settings.delete-message":
        "Sei sicuro di voler eliminare le impostazioni della richiesta di connessione?",
    "notification-settings.type": "Tipo richiesta",
    "notification-settings.channel": "Canale",
    "notification-settings.channel_EMAIL": "EMAIL",
    "notification-settings.info": "Email",
    "notification-settings.actions": "Azioni",
    "notification-settings.company": "Azienda",
    "notification-settings.webhook.type": "FAT - Fattura scartata/ricevuta",
    "notification-settings.details": "Email",
    "notification-settings.no-result": "Nessuna impostazione richiesta di connessione presente",
    "notification-settings.gestisci-notifiche": "Gestisci notifiche",

    //Notifiche web
    "notifications-settings": "Configurazione notifiche",
    "no-notification": "Nessuna notifica",
    "refresh-notification-suggestion": "Per visualizzare nuove notifiche prova ad aggiornare la sezione",
    "mark-all-as-read": "Segna tutte come lette",
    "mark-as-read": "Segna come letta",
    "mark-as-unread": "Segna come da leggere",
    "remove-all": "Rimuovi tutte",
    remove: "Rimuovi",
    "notifications-web-loading": "Caricamento in corso...",

    "v-retrieve-credentials.company-does-not-exist": "L'azienda specificata non esiste",
    "v-retrieve-credentials.retrieval-error":
        "Si è verificato un errore nel processo di recupero credenziali. Si prega di riprovare più tardi",
    "v-retrieve-credentials.unauthorized":
        "L'azienda selezionata non risulta essere collegata a questa utenza applicativa",

    "v-signup-view.title": "Registrati a TeamSystem Digital",

    "v-signup-success.message":
        "Utente registato correttamente, riceverai a breve una email per la conferma del tuo account.",

    "v-signup-error.message": "Errore in fase di registazione: {errorMessage}",

    "v-user-activation-error.message": "Errore in fase di attivazione.",
    "v-user-activation-success.message": "Utente {user} attivato.",

    /**  User creation Flow **/
    "v-user-creation-flow.title": "Crea o aggiungi utente",
    "v-user-creation-flow.subtitle": "Gestisci le utenze ed i ruoli per tutto il personale",
    "v-user-creation-flow.title.invite": "Invita utente",
    "v-user-creation-flow.subtitle.invite":
        "Invita un nuovo utente a collaborare sulle tue aziende e imposta i permessi",
    "v-user-creation-flow.title.manage-connections": "Gestisci connessioni",

    "v-user-creation-flow.title.setPermission": "AGGIUNGI PERMESSI A {name} {surname}",

    "v-user-creation-flow.buttons.next-step": "Vai avanti",
    "v-user-creation-flow.modals.content.error":
        "Si è verificato un errore durante il salvataggio dei permessi impostati. Clicca su Riprova per salvare le modifiche effettuate.",
    "v-user-creation-flow.modals.content.success": "Hai impostato correttamente i permessi per il nuovo utente!",
    "v-user-creation-flow.modals.content.success.invite": "Nuovo utente invitato e permessi impostati correttamente!",
    "v-user-creation-flow.modals.buttons.return-to-user-management": "Ritorna alla lista utenti",
    "v-user-creation-flow.modals.buttons.return-to-item-list": "Ritorna alla lista aziende",
    // Insert email step
    "v-user-creation-flow.steps.insert-email.tab-title": "Crea o aggiungi utente",
    "v-user-creation-flow.steps.insert-email.tab-title.invite": "Invita utente",
    "v-user-creation-flow.steps.insert-email.title": "Inserisci l’email dell'utente",
    "v-user-creation-flow.steps.insert-email.title.invite": "Invita utente a collaborare",
    "v-user-creation-flow.steps.insert-email.subtitle":
        "Inserisci la mail dell’utente. Se la mail è già associata ad un account TS Digital non ti verranno chiesti ulteriori dati.",
    "v-user-creation-flow.steps.insert-email.subtitle.invite":
        "Immetti l'indirizzo email dell'utente che vuoi invitare a collaborare su <b>{studio}.</b>",
    "v-user-creation-flow.steps.insert-email.buttons.verify-email": "Conferma",
    "v-user-creation-flow.steps.insert-email.buttons.edit-email": "Modifica email",
    "v-user-creation-flow.steps.insert-email.user-exists":
        "L’utente ha già un account TS Digital. Puoi procedere alla selezione delle Aziende.",
    "v-user-creation-flow.steps.insert-email.user-wants-to-edit-itself": "L’utente non può modificare i suoi ruoli",
    "v-user-creation-flow.steps.insert-email.user-has-roles":
        "L’utente ha già un account TS Digital e un ruolo sull’azienda selezionata e/o sulle relative aziende clienti. Annulla per tornare alla pagina di Gestione Utenti e Ruoli per modificare i permessi di questo utente.",
    "v-user-creation-flow.steps.insert-email.insert-user-data": "Inserisci i dati del nuovo utente",
    // Select items step
    "v-user-creation-flow.steps.select-items.tab-title": "Seleziona aziende",
    "v-user-creation-flow.steps.select-items.title": "Seleziona le Aziende ed imposta i permessi",
    "v-user-creation-flow.steps.select-items.subtitle":
        "Seleziona dalla lista delle tue Aziende tutte quelle per cui vuoi impostare permessi su <b>{userEmail}</b>. Negli step successivi potrai andare a modificare massivamente o puntualmente i permessi per ogni Azienda selezionata se necessario.",
    "v-user-creation-flow.steps.select-items.table-header.item-description": "Nome azienda",
    "v-user-creation-flow.steps.select-items.table-header.vat-number": "P. Iva",
    "v-user-creation-flow.steps.select-items.table-header.tax-id": "Codice Fiscale",
    "v-user-creation-flow.steps.select-items.table-header.classifier": "Tipologia",
    "v-user-creation-flow.steps.select-items.table-header.connected": "Conessione",
    "v-user-creation-flow.steps.select-items.table-footer.selected-item": "selezionata",
    "v-user-creation-flow.steps.select-items.table-footer.selected-items": "selezionate",
    "v-user-creation-flow.steps.select-items.table-footer.total-items": "Totale aziende",
    "v-user-creation-flow.steps.select-items.filters.searchbar.label": "Cerca azienda",
    "v-user-creation-flow.steps.select-items.filters.searchbar.placeholder": "Nome, p.iva, codice fiscale",
    "v-user-creation-flow.steps.select-items.filters.classifier.label": "Filtra per tipologia",
    "v-user-creation-flow.steps.select-items.filters.services.label": "Filtra servizi",
    "v-user-creation-flow.steps.select-items.disabledRow":
        "L'utente ha già ruoli su questa azienda. Vai nella pagina di modifica per aggiornare i permessi.",
    "v-user-creation-flow.steps.select-items.disabledRow-notCertified": "Validazione non effettuata.",
    "v-user-creation-flow.steps.select-items.disabledRow-noActiveServices": "Non ci sono servizi attivi.",
    "v-user-creation-flow.steps.select-items.disabled": "Non è possibile deselezionare",
    "v-user-creation-flow.steps.select-items.search-placeholder": "Cerca",
    // Clone roles step
    "v-user-creation-flow.steps.clone-roles.tab-title": "Clona ruoli",
    // Set permissions step
    "v-user-creation-flow.steps.set-permissions.tab-title": "Imposta permessi",
    "v-user-creation-flow.steps.set-permissions.sidebar.title": "Aziende selezionate",
    "v-user-creation-flow.steps.set-permissions.sidebar.subtitle":
        "Qui trovi le aziende che hai selezionato per l’utente <b>{email}</b>.<br />Puoi modificare i permessi per ogni singola Azienda.",
    "v-user-creation-flow.steps.set-permissions.sidebar.search-item-placeholder": "Cerca un’azienda",
    "v-user-creation-flow.steps.set-permissions.title": "Imposta i permessi per <b>{itemDescription}</b>",
    "v-user-creation-flow.steps.set-permissions.subtitle":
        "Puoi diversificare i ruoli dell'utente a livello di servizio o sottoservizio.",
    "v-user-creation-flow.steps.set-permissions.filter-label": "Filtri",
    "v-user-creation-flow.steps.set-permissions.table.label.service": "Servizio",
    "v-user-creation-flow.steps.set-permissions.table.label.role": "Ruolo",
    "v-user-creation-flow.steps.set-permissions.toggle.set-my-roles": "Imposta i miei stessi ruoli",
    "v-user-creation-flow.steps.set-permissions.toggle.set-my-roles.tooltip.title": "Imposta i miei stessi permessi",
    "v-user-creation-flow.steps.set-permissions.toggle.set-my-roles.tooltip":
        "Assegna al nuovo utente i tuoi stessi permessi relativi a servizi e sottoservizi, per l'azienda selezionata.",

    "v-user-creation-flow.steps.set-permissions.connected-services": "Servizi connessi",
    "v-user-creation-flow.steps.set-permissions.local-services": "Servizi locali",

    "v-user-creation-flow.steps.set-permissions.manager-modal.title": "Imposta permessi ruolo Manager",
    "v-user-creation-flow.steps.set-permissions.manager-modal.back-to-edit": "Torna alle modifiche",
    "v-user-creation-flow.steps.set-permissions.manager-modal.confirm": "Conferma",
    "v-user-creation-flow.steps.set-permissions.manager-modal.text":
        "Cliccando su Conferma, per tutti i servizi e sotto servizi relativi alle aziende gestite che hai selezionato, su cui hai assegnato il ruolo di Admin, all'utente {email} verrà automaticamente assegnato il ruolo di Manager.",

    "v-user-creation-flow.steps.set-permissions.selected-modal.title": "Lista aziende abilitate al servizio",
    "v-user-creation-flow.steps.set-permissions.selected-modal.back-to-edit": "Torna alle modifiche",
    "v-user-creation-flow.steps.set-permissions.selected-modal.text":
        "Lista delle aziende per cui stai impostando il ruolo di {role} sul servizio {service}",
    "v-user-creation-flow.steps.set-permissions.selected-modal.text.no-role":
        "Lista delle aziende per cui stai impostando il ruolo sul servizio {service}",
    "v-user-creation-flow.steps.set-permissions.selected-modal.table-companies": "{count} aziende",
    "v-user-creation-flow.steps.set-permissions.selected-modal.table-companies-total": "Totale aziende: {count}",

    "v-user-creation-flow.steps.set-permissions.selected-modal.table-companies-total-label":
        "tutte le aziende selezionate",

    "v-user-creation-flow.steps.set-permissions.selected-modal.table-companies-total-label-total":
        "Tutte le aziende selezionate ({count})",
    "v-user-creation-flow.steps.set-permissions.selected-modal.table-my-studio": "Il mio studio",

    "v-validate-company.step.insert-data.title":
        "1. Inserisci i tuoi dati e gestisci le tue opzioni di trattamento dei dati",
    "v-validate-company.step.insert-data.subtitle":
        "Compila i dati richiesti per poter generare il Modulo di sottoscrizione TS Digital precompilato",
    "v-validate-company.step.data-consent.subtitle":
        "Scegli i consensi che desideri rilasciare a TeamSystem Service: un consenso positivo ti permetterà di rimanere aggiornato sulle nostre promozioni. Ricordati che potrai cambiare in qualunque momento la tua scelta.",
    "v-validate-company.step.generate-document.title": "2. Genera il Modulo di sottoscrizione per TS Digital",
    "v-validate-company.step.generate-document.subtitle":
        "Genera il Modulo di sottoscrizione per TS Digital con i dati inseriti precedentemente.",
    "v-validate-company.step.upload-contract.title": "3. Carica il Modulo di sottoscrizione per TS Digital",
    "v-validate-company.step.upload-contract.subtitle": "Carica il file firmato per proseguire nella validazione.",
    "v-validate-company.stepper.submit": "Invia richiesta di validazione",

    "c-validate-company-data-form.office-select.custom": "Altro...",
    "c-validate-company-data-form.office-select.label": "Carica predefinita",
    "c-validate-company-data-form.office-select.placeholder": "Seleziona una carica predefinita",
    "c-validate-company-data-form.custom-office.label": "Carica Personalizzata",
    "c-validate-company-data-form.custom-office.placeholder": "Inserisci una carica",
    "c-validate-company-data-form.name-and-surname.label": "Nome e cognome",
    "c-validate-company-data-form.name-and-surname.placeholder": "Inserisci nome e cognome",
    "c-validate-company-data-form.company-name.label": "Ragione Sociale",
    "c-validate-company-data-form.company-name.placeholder": "Inserisci la ragione sociale",
    "c-validate-company-data-form.place-and-date.label": "Luogo e data",
    "c-validate-company-data-form.place-and-date.placeholder": "Inserisci luogo e data [Luogo, GG/MM/AAAA]",
    "c-validate-company-data-form.titolare": "Titolare ditta individuale",
    "c-validate-company-data-form.legale-rappresentante": "Legale rappresentante",
    "c-validate-company-data-form.procuratore": "Procuratore",

    "c-validate-company-contract-upload.generate-contract": "Genera il modulo di sottoscrizione",
    "c-validate-company-contract-upload.upload-contract": "Carica il modulo di sottoscrizione",

    "c-upload-company-contract.upload-contract": "Carica il tuo atto di affidamento",

    "c-user-activation-form.activate-account": "Attiva il tuo account",

    "c-data-consent-form.informative-text": `<a href="${PRIVACY_POLICY_URL}" target='_blank'>Clicca qui</a> per prendere visione dell'informativa sul trattamento dei tuoi dati personali.`,
    "c-data-consent-form.ts-notification-consent": `Autorizzo il trattamento dei miei dati personali, inclusi quelli relativi all'utilizzo del sito e dei prodotti e servizi TeamSystem, per ricevere via email, posta, messaggi istantanei, chat o al telefono aggiornamenti su novità e offerte commerciali di prodotti e servizi TeamSystem, ovvero per le altre iniziative di marketing indicate nell'informativa.`,
    "c-data-consent-form.ts-third-party-notification-consent": `Autorizzo la comunicazione dei miei dati personali alle altre società del gruppo TeamSystem e ai suoi partner per fini commerciali e di marketing.`,
    "c-data-consent-form.accept": "Sì, acconsento",
    "c-data-consent-form.refuse": "No, non acconsento",

    "toast.success": "Successo",
    "toast.info": "Info",
    "toast.warning": "Avvertimento",
    "toast.error": "Errore",

    "c-signup-form.create-account-button": "Crea il tuo account",

    "c-success.message.title.company.validate.success": "Richiesta inoltrata con successo!",
    "c-success.message.subtitle.company.validate.success": "La tua richiesta di validazione è stata inoltrata",
    "c-success.message.button.company.validate.success": "Torna alla homepage",

    "c-item.status.tag.VALIDATED": "Azienda Validata",
    "c-item.status.tag.UNVERIFIABLE": "Azienda Non Validata",
    "c-item.status.tag.VERIFIED_PENDING_VALIDATE": "Azienda In Attesa Di Validazione",
    "c-item.status.tag.UNVERIFIABLE_PENDING_VALIDATE": "Azienda In Attesa Di Validazione",
    "c-item.status.tag.REJECTED": "Azienda Rigettata",
    "c-item.status.tag.REJECTED_PENDING_VALIDATE": "Azienda In Attesa Di Validazione",
    "c-item.status.tag.item.connected": "Azienda connessa",

    //sdi
    "c-sdi-config.title": "Configurazione servizio SDI",
    "c-sdi-config.text": "Imposta la firma predefinita.",

    //ts-pay
    "tspay-service-name": "TS Pay",
    "tspay-configure": "Configura TS Pay",
    "tspay-connect-description":
        "Per utilizzare TS Pay su TS Digital è necessario ultimare la configurazione registrando la tua azienda su TS Pay. Una volta completata la registrazione, riceverai un'e-mail per autorizzare l'attivazione di TS Pay su TS Digital. Clicca su 'Registra azienda' per procedere con la registrazione. Se non completi la registrazione, potrai riprenderla in un secondo momento, senza perdere le modifiche effettuate.",
    "tspay-status-linked": "Stato: attivo",
    "tspay-status-onboarding": "Stato: registrazione azienda avviata",
    "tspay-status-not-linked": "Stato: non attivo",
    "tspay-status-to-be-confirmed": "Stato: in attesa di autorizzazione",
    "tspay-disconnect": "Scollega",
    "tspay-confirm-onboard": "Registra azienda",
    "tspay-confirm-onboarding": "Completa registrazione azienda",
    "tspay-confirm-new-onboard": "Avvia nuova registrazione",
    "tspay-connect-confirm-title": "Collega TS Digital a TS Pay",
    "tspay-connect-confirm-content":
        "Confermando il collegamento tra TS Digital e TS Pay, verrà inviata una mail al Rappresentate Legale dell’azienda in modo da poter approvare la richiesta.",
    "tspay-disconnect-confirm-title": "Conferma scollegamento",
    "tspay-disconnect-confirm-content": "Sei sicuro di voler rimuovere il collegamento al servizio?",
    "tspay-toast-confirm": "Richiesta di collegamento effetuata.",
    "tspay-configuration-warning-message": "per iniziare ad utilizzare TS Pay completa la configurazione del servizio.",
    "tspay-configure-button": "Configura TS Pay",
    "tspay-info-new-onboard":
        "La registrazione della tua azienda su TS Pay è stata avviata da un tuo collaboratore ma non è completa. Se vuoi configurare TS Pay puoi avviare una nuova registrazione. Una volta che avrai completato la registrazione il tuo collaboratore non potrà più effettuare modifiche sulla registrazione avviata o avviarne un'altra.",
    "tspay-resend-confirm": "Reinvia e-mail di autorizzazione",
    "tspay-tooltip-onboarding-title": "Registrazione azienda non completa",
    "tspay-tooltip-onboarding":
        "Il processo di registrazione della tua azienda su TS Pay è stato avviato ma non completato. Puoi completare la registrazione riprendendola da dove l'hai interrotta.",
    "tspay-tooltip-onboarding-other-user":
        "Il processo di registrazione della tua azienda su TS Pay è stato avviato ma non completato.",
    "tspay-tooltip-tobeconfirmed-title": "In attesa di autorizzazione",
    "tspay-tooltip-tobeconfirmed":
        "La tua azienda è stata correttamente registrata su TS Pay. Autorizza l'attivazione di TS Pay dall'e-mail che ti abbiamo inviato. Se non hai ricevuto l'email, clicca su 'Reinvia e-mail di autorizzazione'",
    "tspay-tooltip-active-title": "Attivo",
    "tspay-tooltip-active": "Il servizio TS Pay è attivo. Puoi disattivarlo accedendo alla piattaforma TS Pay.",
    "tspay-other-user-info": "Registrazione già avviata da un altro utente",

    "tspay-status-title": "TS Pay: ",
    "tspay-info-onboarding-tobeconfirmed-status":
        "azienda correttamente registrata. Controlla la tua casella di posta, ti abbiamo inviato una un'e-mail dalla quale puoi autorizzare l'attivazione di TS Pay su TS Digital.",

    "tspay-info-activated-status": "servizio attivato.",

    //cct
    "v-storage-cct-warning":
        "Se hai acquistato un pacchetto fatture non puoi ancora attivare la Conservazione Digitale. Presto potrai abilitare la conservazione TS Digital Archive. Ti ricordiamo che le fatture inviate o ricevute nel 2018 dovranno essere portate in conservazione entro il 31-01-2020.",
    "c-cct-edit-user-verify": "Verifica credenziali",
    "c-cct-edit-user-error": "Utente o password errati",
    "c-cct-edit-user-done": "Credenziali TS Digital Archive configurate correttamente",
    "c-cct-edit-user-desc": "Inserisci le credenziali che hai ricevuto tramite e-mail per configurare il servizio",
    "c-cct-edit-user-set.message": "TS Digital Archive Configurata per l'utente <b>{user}</b>.",
    "c-cct-edit-user-set.link": "Aggiorna Credenziali",
    "c-cct-edit-service-update":
        "Attiva e configura i servizi per i quali hai acquistato un pacchetto TS Digital Archive.",
    "c-cct-user": "Utenza TS Digital Archive",
    "c-cct-password": "Password TS Digital Archive",
    "c-cct-provisioning-configure-item.table-title": "Gestione documenti della tua azienda",
    "c-cct-eip-storage": "Fatturazione",
    "c-cct-tel-storage": "Telematici",
    "c-cct-sig-storage": "Firma",
    "c-cct-title": "Conservazione Digitale",
    "c-cct-description":
        "Conserva i tuoi documenti con un click: dalle fatture agli adempimenti... risparmi carta e toner e fai un favore all’ambiente!",
    "c-cct-dociq": "DOCIQ",
    "c-cct-dociq-desc": "Cataloga e ricerca in maniera intelligente ed immediata i tuoi documenti su TS Digital.",
    "c-cct-enable": "abilitato",
    "c-cct-disable": "disabilitato",
    "c-cct-status.filters": "Filtri",
    "c-cct-status.cf-search": "Cerca per CF Azienda",
    "c-cct-status.filter.any": "Tutti",
    "c-cct-status-initialized": "Non effettuata",
    "c-cct-status-downloaded": "In attesa documento",
    "c-cct-status-uploaded": "In validazione",
    "c-cct-status-rejected": "Rifiutate",
    "c-cct-status-validated": "Validata",
    "c-cct-status-blocked": "Bloccata",
    "c-cct-status-pending": "In elaborazione",
    "c-cct-status-correction": "Rettifica cf in corso",
    "c-cct-filter-status-validated": "Validate",
    "c-cct-status-active": "Attive",
    "c-cct-status-not-active": "Disattive",
    "c-cct-status-to-be-fixed": "Da rettificare",
    "c-cct-status-null": "Loading...",
    "c-cct-item-services-subtitle": "Gestisci i servizi per la tua azienda",
    "c-cct-item-services-section-subtitle": "Gestisci i documenti del tuo studio",
    "c-cct-item-services-section-title": "ARC – TS DIGITAL ARCHIVE",
    "c-cct-item-managed-items-personal": "Gestisci i servizi per la tua azienda",
    "c-cct-item-services-section-description":
        "Attiva e configura i servizi per i quali hai acquistato un pacchetto TS Digital Archive.",
    "c-cct-item-services-active-service": "Attiva servizio",
    "c-cct-item-managed-items-personal-link": "Configura per le aziende gestite",
    "c-cct-item-managed-items-personal-subtitle":
        "In questa sezione puoi abilitare le aziende gestite collegandole al tuo studio. In questo modo potrai procedere alla conservazione dei loro dati.",
    "c-cct-configure-managed-items-personal-title": "Abilita le aziende gestite del tuo studio",
    "c-cct-item-managed-items-registry-warning":
        "<b>Attenzione:</b> Si ricorda che al fine di conservare le fatture elettroniche per conto di un’azienda gestita sarà necessario attivare l’anagrafica del titolare sulla piattaforma TS Digital Archive.",
    "c-cct-configure-managed-items-personal-columns.status.active": "Abilitata",
    "c-cct-configure-managed-items-personal-columns.status.not-active": "Non abilitata",
    "c-cct-configure-managed-items-personal-columns.actions.enable": "Abilita",
    "c-cct-configure-managed-items-personal-columns.actions.disable": "Disabilita",
    "c-cct-configure-managed-items-personal-columns.actions.enable-all": "Abilita aziende",
    "c-cct-configure-managed-items-personal-columns.actions.disable-all": "Disabilita aziende",
    "c-cct-configure-managed-items-personal-columns.filter.active": "Aziende abilitate",
    "c-cct-configure-managed-items-personal-columns.filter.not-active": "Aziende non abilitate",

    "c-cct-provisioning-download-pdf-form.title": "Scarica il documento",
    "c-cct-provisioning-download-pdf-form.subtitle":
        "Per procedere con la delega, è necessario inserire i dati del Responsabile della Conservazione dell’azienda selezionata:",
    "c-cct-provisioning-download-pdf-form.fiscal-code": "Codice fiscale: {taxId}",
    "c-cct-provisioning-download-pdf-form.conservation-manager": "Delega del Responsabile della Conservazione",
    "c-cct-provisioning-download-pdf-form.registry-title":
        "(l’inserimento  dei  dati  è  una  dichiarazione  ai  sensi  del  D.P.R.  445/2000  si  raccomanda pertanto di inserirli correttamente)",
    "c-cct-provisioning-download-pdf-form.fields.balanceSheetDate": "Data di fine esercizio ",
    "c-cct-provisioning-download-pdf-form.fill-in-manager-data":
        "Precompila con i dati del Responsabile della Conservazione dello studio",
    "c-cct-provisioning-download-pdf-form.fill-in-manager-data-smart": "Utilizza i dati dello studio",
    "c-cct-provisioning-download-pdf-form.format-error":
        "Attenzione: formato e/o dimensioni documento non consentiti. Formato corretto: .pdf massimo 5 MB",
    "c-cct-provisioning-download-pdf-form.rdc-subtitle":
        "Per saperne di più sul ruolo di Responsabile della Conservazione ",
    "c-cct-provisioning-download-pdf-form.rdc-link": "scarica il PDF e consulta il Vademecum",
    "c-cct-provisioning-download-pdf-form.balanceSheetDate.info":
        "La data di fine esercizio è correttamente configurata dall'azienda.",
    "c-cct-provisioning-download-pdf-form.balanceSheetDate.warning":
        "Attenzione! La data di fine esercizio deve essere prima configurata dall’azienda.",
    "c-cct-provisioning-download-pdf-form.country": "Nazione di nascita",
    "c-cct-provisioning-download-pdf-form.archive-smart": "Scegli la delega veloce e smart",
    "c-cct-provisioning-download-pdf-form.archive-smart-declare":
        "L’utente dichiara ai sensi del D.P.R. 445/2000 di rappresentare il Legale Rappresentante a cui far ricoprire il ruolo  di  Responsabile  della  Conservazione  a  favore  dei  Titolari  dei  documenti  selezionati  nella  maschera “Gestione Deleghe”. Altresì dichiara di affidare l’esecuzione del processo di conservazione al Conservatore Teamsystem  Service  S.r.l.  e  al  suo  Responsabile  del  servizio  di  conservazione  delegando  a  quest’ultimi  le attività  e  i  compiti  previsti  dalle  regole  tecniche  in  materia  di  conservazione  così  come  individuati nell’apposito paragrafo della <a href='https://tc.teamsystem.com/SchedaServizio_DigitalArchive.pdf' target='_blank'>Scheda Servizio Digital Archive</a>.",
    "c-cct-provisioning-download-pdf-form.archive-smart-rdc-confirm": "Conferma e registra Responsabile",
    "c-cct-provisioning-download-pdf-form.rdc-email-address": "Recapito email del Responsabile della Conservazione",

    "c-cct-provisioning-download-pdf-form.insert-name": "Inserisci nome",
    "c-cct-provisioning-download-pdf-form.insert-lastname": "Inserisci cognome",
    "c-cct-provisioning-download-pdf-form.insert-birthplace": "Inserisi luogo di nascita",
    "c-cct-provisioning-download-pdf-form.insert-fiscal-code": "Inserisi codice fiscale",
    "c-cct-provisioning-download-pdf-form.insert-email": "Inserisci email",
    "c-cct-provisioning-download-pdf-form.insert-birthdate": "Inserisci data di nascita",
    "c-cct-provisioning-download-pdf-form.insert-country": "Inserisci nazione di nascita",

    "c-cct-provisioning-download-pdf-form.confirm-toast": "Richiesta presa in carico",

    "c-cct-provisioning-download-pdf-form.error-toast-1": "Ops! Qualcosa è andato storto...",
    "c-cct-provisioning-download-pdf-form.error-toast-2": "Riprova più tardi",
    "c-cct-provisioning-download-pdf-form.set-date": "Imposta 31 Dicembre",

    "c-cct-provisioning.rettifica": "Rettifica dati richiesta {errMsg}",
    "c-cct-provisioning.rejected": "Causa rifiuto",

    "c-cct-provisioning-upload-pdf-form.title": "Carica il documento",
    "c-cct-provisioning-upload-pdf-form.subtitle": "Carica il documento dopo averlo compilato e firmato. (.PDF)",
    "c-cct-provisioning-upload-pdf-form.selectfile": "Seleziona il documento",
    "c-cct-provisioning-upload-pdf-form.change-rdc": "Cambia responsabile",
    "c-cct-provisioning-upload-pdf-form.send-document": "Invia il documento",
    "c-cct-provisioning-upload-pdf-form.file-size-info":
        "Formati consentiti .pdf - 4000kb dimensione massima consentita.",

    "c-cct-provisioning.managed.columns.description": "Ragione Sociale",
    "c-cct-provisioning.managed.columns.itemTaxId": "CF Azienda",
    "c-cct-provisioning.managed.columns.holderTaxId": "Codice Fiscale Responsabile",
    "c-cct-provisioning.managed.columns.holderName": "Responsabile Conservazione",
    "c-cct-provisioning.managed.columns.certificationStatus": "Stato Certificazione",
    "c-cct-provisioning.managed.columns.requestStatus": "Stato Richiesta",
    "c-cct-provisioning.managed.columns.document": "Documento",
    "c-cct-provisioning.managed.smart-not-enabled": "Delega Smart non disponibile",
    "c-cct-provisioning.managed.no-roles": "Permessi non disponibili",

    "c-cct-provisioning-complete-information":
        "mancano delle informazioni per completare la configurazione del servizio TS Digital Archive.",
    "c-cct-provisioning-complete-information-button": "Procedi subito",
    "c-cct-provisioning-rdc-to-be-fixed-warning":
        "Per utilizzare al meglio tutte le funzionalità di Digital Archive è necessario modificare i dati del Responsabile della Conservazione.",

    "c-cct-edit-service-title": "Inserisci le credenziali TS Digital Archive",

    "c-new-cct-warning-message.can-certify.message":
        "Per poter utilizzare il servizio SDI è necessario effettuare la certificazione dell'azienda,",
    "c-new-cct-warning-message.can-certify.link": "Certifica azienda",

    "c-new-cct-warning-message.certification-in-progress":
        "Il servizio sarà configurabile al termine del processo di certificazione dell'azienda.",
    "c-new-cct-warning-message.item-validated":
        "La tua azienda è in fase di ricertificazione da parte di TS DIGITAL. La funzionalità sarà presto disponibile.",

    "c-new-cct-service-row.configure-company": "Configura delega aziendale",
    "c-new-cct-service-row.configure-studio": "Configura delega studio",
    "c-new-cct-service-row.configure-managed": "Configura deleghe gestite",

    "cct-exp-date-days-left":
        "La tua password scade {daysLeft}. Reimposta la password su TS Digital Archive e poi torna qui per aggiornare le tue credenziali.",
    "cct-exp-date-days-left-toast-message":
        "La password che hai impostato in TS Digital Archive scade tra {daysLeft}.<br />Per aggiornare le tue credenziali sono necessarie due operazioni:<br />1. Modifica la password su TS Digital Archive<br/>2. Aggiornala qui su TS Digital",
    "cct-expired-credential-toast-message":
        "La password che hai impostato in TS Digital Archive è scaduta.<br />Per aggiornare le tue credenziali sono necessarie due operazioni:<br />1. Modifica la password su TS Digital Archive<br/>2. Aggiornala qui su TS Digital",
    "cct-configure-digital-archive": "Configura TS Digital Archive",
    "cct-service-row-description":
        "Hai acquistato un servizio Digital comprensivo di conservazione? In questa sezione puoi configurare il tuo account Digital Archive ed abilitare l’invio dei documenti in conservazione automatica. Per procedere all’abilitazione dovrai scaricare, firmare e ricaricare la <i>Delega del Responsabile della Conservazione</i>. Ripeti il processo per tutte le tue aziende gestite ed attendi la validazione del documento da parte del nostro team.",
    "cct-delegation-status": "Stato delega",
    "cct-connect-your-archive-credentials": "Collega le tue credenziali Digital Archive",
    "cct-exist-archive-credentials-description":
        "Hai già le credenziali di accesso a Digital Archive ricevute via e-mail? Inseriscile qui per collegare TS Digital al tuo account Digital Archive e abilitare l’invio dei documenti in Conservazione Digitale.",
    "cct-expired-archive-credentials-message":
        "La password che hai impostato in TS Digital risulta essere scaduta. Ricorda che dopo averla modificata su TS Digital Archive, dovrai aggiornarla anche qui.",
    "cct-connect-ts-digital-archive": "Collega TS Digital Archive",
    "cct-reset-archive-credentials": "Reimposta credenziali",
    "cct-smart-info":
        "<b>Delega Smart:</b> se il Responsabile della Conservazione ricopre la carica di Legale Rappresentante, potrai registrarlo rapidamente senza dover scaricare e ricaricare il modulo firmato.",
    "cct-smart-info-managed":
        '<b>Delega Smart:</b> attivando questa opzione, il legale rappresentante configurato sullo studio verrà impostato sull\'azienda gestita "{description}" come Responsabile della Conservazione.',
    "cct-smart-more-info": "Scopri di più!",

    "c-configure-managed-items.title": "Gestione deleghe gestite del tuo studio",
    "cct-activate-service-configure-item-disabled-message":
        "Per poter attivare il servizio devi aver acquistato il pacchetto.",
    "c-cct-massive-set-smart-rdc": "Attiva delega smart per le gestite",
    "c-cct-massive-smart-rdc-selected": "selezionate",
    "c-configure-managed-items-downloads.title": "Scarica le deleghe gestite in formato csv.",

    "c-cct-massive-set-smart-rdc-confirm-modal.title":
        "Delega Smart del Responsabile della Conservazione delle aziende gestite",
    "c-cct-massive-set-smart-rdc-confirm-modal.confirm-button": "Conferma e registra responsabile",
    "c-cct-massive-set-smart-rdc-confirm-modal.description":
        "<b>Delega Smart:</b> confermando la presente procedura, il legale rappresente configurato sullo studio verrà impostato come Responsabile della Conservazione sulle aziende Gestite appena selezionate.",
    "c-cct-massive-set-smart-rdc-confirm-modal.rdc-description":
        "<b>Dati del reponsabile della conservazione</b><br/><i>(la conferma dei dati è una dichiarazione ai sensi del D.P.R. 445/2000 si raccomanda pertanto di verificarli)</i>",
    "c-cct-massive-set-smart-rdc-confirm-modal.date-alert":
        "<b>Attenzione:</b><br/>Per le aziende dove <b>non è stata ancora configurata</b> la data di fine di esercizio verrà impostata automaticamente al <b>31 Dicembre</b>.",

    "cct-generate-document.title": "TS DIGITAL ARCHIVE",
    "cct-generate-document.subtitle": "Gestisci i documenti del tuo studio",

    "logo-upload-modal.title": "Aggiungi logo aziendale",
    "logo-upload-modal.title-edit": "Modifica logo aziendale",
    "logo-upload-modal.confirm-delete.title": "Rimuovere l'immagine?",
    "logo.add-logo-text": "+ LOGO",
    "logo.add-logo-modal-text": "LOGO",

    //forgot-password
    "forgot-password.title": "Hai dimenticato la tua password?",
    "v-forgot-password-message":
        "Inserisci l’Email dell'utenza di cui non ti ricordi la password, ti invieremo una Email per poterla resettare.",
    "v-forgot-password-success-description":
        "Ti abbiamo inviato una mail con le istruzioni per il recupero della password.",
    "v-forgot-password-error-invalid-mail": "Inserisci una mail valida",
    "v-forgot-password-error-captcha": "Conferma che non sei un bot.",
    "metering.services.billing": "TS Digital Invoice",
    "metering.services.privacy": "Privacy in Cloud",
    "metering.usage.invoicing.searchbar.error": "Valore non valido.",
    "metering.usage.items.billing.error":
        "Si è verificato un errore nel recuperare le connessioni dell'azienda. Si prega di riprovare più tardi.",
    "metering.services.searchbar.placeholder": "Nome azienda...",
    "metering.usage.upgrade": "Upgrade",
    "metering.usage.upgrade.tooltip": "Clicca per effettuare l'upgrade del tuo abbonamento.",
    "metering.usage.detail.billing.error": "Si è verificato un errore. Si prega di riprovare più tardi.",
    "metering.usage.details.csv.billing.error":
        "Si è verificato un errore nel recuperare i dati in formato CSV. Si prega di riprovare più tardi.",
    "metering.usage.statistics.csv.billing.error":
        "Si è verificato un errore nel recuperare i dati in formato CSV. Si prega di riprovare più tardi.",
    "metering.usage.package.billing.error":
        "Si è verificato un errore nel recuperare le informazioni del pacchetto. Si prega di riprovare più tardi.",
    "metering.billing.history.details.csv.download": "Scarica CSV",
    "metering.billing.history.csv.download": "Scarica CSV",
    "metering.billing.invoices.remaining":
        "Totale documenti a disposizione: <b>{remaining}</b> (pacchetto utilizzato al <b>{percentage}%</b>).",
    "metering.billing.credit.remaining":
        "Credito a disposizione: {remaining}. Consumati {used} documenti su {capacity} ({usagePercentage}).",
    "metering.billing.credit.tooltip": "Credito relativo a promozioni e altre iniziative",
    "metering.billing.table.details.consumptionDetails": "Dettaglio consumo",
    "metering.usage.subscriptions.billing.error":
        "Errore nel recuperare le sottoscrizioni attive. Si prega di riprovare più tardi.",
    "metering.billing.invoices.overused":
        "Totale documenti a disposizione: 0 su <b>{capacity}</b> (<b>{extra}</b> extra-soglia)",
    "metering.billing.invoices.sent.tooltip": "{consumed} documenti inviati ({percentage}%)",
    "metering.billing.invoices.received.tooltip": "{consumed} documenti ricevuti ({percentage}%)",
    "metering.billing.table.details.invoiceNumber": "Numero Fattura",
    "metering.billing.table.details.date": "Data consumo",
    "metering.billing.table.details.documentId": "Identificativo documento",
    "metering.billing.table.details.flow": "Flusso",
    "metering.billing.table.details.type.sent": "Inviato",
    "metering.billing.table.details.type.received": "Ricevuto",
    "metering.billing.table.details.type": "Tipologia",
    "metering.billing.table.issued": "Inviati",
    "metering.billing.table.received": "Ricevuti",
    "metering.billing.table.sender": "Azienda",
    "metering.title": "Dettagli utilizzo pacchetto",
    "metering.subtitle": "Analizza i tuoi consumi",
    "metering.billing.table.service": "Servizio",
    "metering.billing.table.service.invoices": "Fatturazione",
    "metering.billing.table.service.orders": "Ordini",
    "metering.billing.table.totalConsumption": "Totale Consumi",
    "metering.services.invoices.searchbar.label": "Cerca:",
    "metering.services.invoices.period.label": "Periodo di riferimento:",
    "metering.usage.billing.table.empty": "Nessun consumo da mostrare.",
    "metering.billing.error.scope.limited.1":
        "Nella tabella non sono consultabili i dettagli dei consumi relativi alle aziende non connesse a cui è stato esteso il servizio a causa di permessi insufficienti.",
    "metering.billing.error.scope.limited.2": "Il totale dei consumi è comunque disponibile nella barra sottostante.",
    "metering.billing.table.details.flow.selfsend": "AUTO INVIO",
    "c-registry-office.modal.create.title": "Aggiungi ufficio",
    "c-registry-office.modal.create.subtitle": "Inserisci tutti i dati per creare un ufficio",
    "c-registry-office.modal.create.field.description": "Descrizione",
    "c-registry-office.modal.create.field.placeholder.description": "Inserisci Descrizione",
    "c-registry-office.modal.create.field.street": "Indirizzo",
    "c-registry-office.modal.create.field.placeholder.street": "Inserisci Indirizzo",
    "c-registry-office.modal.create.field.city": "Città",
    "c-registry-office.modal.create.field.placeholder.city": "Inserisci Città",
    "c-registry-office.modal.create.field.province": "Provincia",
    "c-registry-office.modal.create.field.placeholder.province": "Seleziona Provincia",
    "c-registry-office.modal.create.field.zipCode": "Cap",
    "c-registry-office.modal.create.field.placeholder.zipCode": "Inserisci Cap",
    "c-registry-office.modal.create.field.country": "Nazione",
    "c-registry-office.modal.create.field.placeholder.country": "Seleziona Nazione",
    "c-registry-office.modal.create.button": "Crea",
    "c-registry-office.modal.create.add-intermediaries": "Aggiungi intermediario",
    "c-registry-office.modal.create.delete-intermediaries": "Elimina intermediario",
    "c-registry-office.modal.create.delete-question": "Sei sicuro di voler eliminare l'intermediario ?",
    "c-registry-office.modal.create.intermediary-info": "Info intermediario",
    "c-registry-office.modal.create.intermediary-search": "Ricerca per descrizione o indirizzo e premi invio",

    "c-registry-office.modal.create.delete-question-office": "Sei sicuro di voler eliminare l'ufficio ?",
    "c-registry-office.modal.create.office-search": "Ricerca per descrizione o indirizzo e premi invio",

    "c-registry-office.modal.edit.title": "Modifica ufficio",
    "c-registry-office.modal.edit.subtitle": "Modifica l'ufficio {officeName}",
    "c-registry-office.modal.edit.button": "Modifica",

    "c-cookie-policy.title1": "Cookie Policy",
    "c-cookie-policy.subtitle1": "Cosa sono i cookie?",
    "c-cookie-policy.text1":
        "La presente <b>Cookie Policy</b> contiene <b>informazioni importanti</b> sulle diverse tipologie di cookie (piccoli file di testo) che potrebbero essere installati sui tuoi dispositivi (es. PC, smartphone, tablet) quando visiti il sito www.teamsystem.com.",
    "c-cookie-policy.text2":
        "Secondo il <b>Regolamento (UE) 2016/679</b> in materia di <b>protezione dei dati personali</b>, i cookie costituiscono dati personali quando possono essere associati a un dispositivo e, pertanto, ad una persona identificabile.",
    "c-cookie-policy.subtitle2": "Tipologie di cookie",
    "c-cookie-policy.text3":
        "Esistono <b>diversi tipi di cookie</b> che contengono informazioni differenti e svolgono funzioni diverse (es. esecuzione di autenticazioni informatiche, monitoraggio di sessioni, memorizzazione di preferenze per l'invio di messaggi pubblicitari mirati, etc). <b>Ogni cookie</b>, a sua volta, <b>contiene diversi dati</b> (es. il nome del server da cui proviene, un identificatore numerico, etc.). Tali dati possono rimanere nel dispositivo dell'utente per diversi periodi di tempo - una sessione del browser, qualche ora, diversi giorni o per periodi più lunghi.",
    "c-cookie-policy.table1": "Descrizione",
    "c-cookie-policy.table2": "Necessità di installazione sul dispositivo dell'utente",
    "c-cookie-policy.table3": "Consenso dell'utente richiesto?",
    "c-cookie-policy.table4": "Cookie tecnici",
    "c-cookie-policy.table5":
        "Cookie che servono a effettuare la navigazione o a fornire un servizio richiesto dall'utente. Non vengono utilizzati per scopi ulteriori.",
    "c-cookie-policy.table6":
        "I cookie analitici di terze parti possono essere assimilati ai cookie tecnici soltanto se utilizzati a fini di ottimizzazione del sito dal titolare del sito stesso, per raccogliere informazioni in forma aggregata sul numero degli utenti e su come questi visitano il sito.",
    "c-cookie-policy.table7":
        "Cookie utilizzati per tracciare la navigazione dell'utente in rete e creare profili sui suoi gusti, abitudini, scelte, ecc. Con questi cookie possono essere trasmessi al terminale dell'utente messaggi pubblicitari in linea con le preferenze manifestate dall'utente nella sua navigazione online.",
    "c-cookie-policy.table8": "Cookie analitici di terze parti utilizzati come cookie tecnici",
    "c-cookie-policy.table9":
        "I cookie tecnici sono necessari per il corretto funzionamento del sito. Senza il ricorso a tali cookie, alcune operazioni non potrebbero essere compiute o sarebbero più complesse e/o meno sicure. Pertanto, i cookie tecnici risultano indispensabili.",
    "c-cookie-policy.table10":
        "Quando sono utilizzati come cookie tecnici e quando sono adottate misure di anonimizzazione prescritte dal Garante, i cookie analitici sono necessari per permettere al titolare del sito di migliorare il funzionamento dello stesso e l'esperienza dell'utente sul sito.",
    "c-cookie-policy.table11":
        "I cookie di profilazione non sono strettamente necessari per il buon funzionamento del sito e, pertanto, l'utente può decidere se installare tali cookie o meno.",
    "c-cookie-policy.table12": "Cookie di profilazione",
    "c-cookie-policy.table13":
        "I cookie tecnici non richiedono il consenso dell'utente, in quanto sono necessari per il corretto funzionamento del sito.",
    "c-cookie-policy.table14":
        "Non è necessario il consenso dell'utente, in quanto TeamSystem ha attivato la funzione di anonimizzazione dell'indirizzo IP e utilizza i dati in forma aggregata.",
    "c-cookie-policy.table15":
        "Per l'installazione dei cookie di profilazione è necessario il consenso dell'utente, che può essere manifestato oppure revocato dall'utente",
    "c-cookie-policy.subtitle3": "Come manifestare la propria scelta riguardo ai cookie utilizzati dal sito",
    "c-cookie-policy.cookietext1": "Google Analytics (Cookie analitici di terze parti utilizzati come cookie tecnici)",
    "c-cookie-policy.cookietext2":
        "Se non desideri che i tuoi dati vengano utilizzati da Google Analytics, puoi installare il componente aggiuntivo del browser secondo le modalità descritte da Google ai seguenti indirizzi:",
    "c-cookie-policy.cookietext3":
        "L'installazione di questo componente aggiuntivo impedisce al codice JavaScript di Google Analytics (ga.js, analytics.js e dc.js), in esecuzione su un sito web, di condividere le informazioni con Google Analytics sulla tua attività quando visiti il nostro sito.",

    "validation.taxId.emptyOrNull": "Il codice fiscale non può essere vuoto",
    "validation.taxId.building.wrongLength": "La partita iva di un condominio deve avere lunghezza 11 caratteri",
    "validation.taxId.building.numbersOnly": "La partita iva di un condominio può contenere solo numeri",
    "validation.taxId.person.wrongLength": "Il codice fiscale deve avere lunghezza 16 caratteri",
    "validation.taxId.person.wrongFormat": "Il formato del codice fiscale è errato",
    "validation.taxId.person.wrongChecksum": "Carattere di controllo non valido",
    "validation.failed": "Il valore inserito non è valido",

    "validation.vatNumber.emptyOrNull": "La partita IVA non può essere nulla",
    "validation.vatNumber.wrongLength": "La partita IVA deve essere di 11 cifre",
    "validation.vatNumber.wrongFormat": "La partita IVA può contenere solo cifre",
    "validation.vatNumber.wrongChecksum": "Carattere di controllo non valido",

    "c-company-info.rejection-message": "(Motivazione: {rejectionMessage})",

    "c-maintenance.title": "Portale in manutenzione",
    "c-maintenance.text":
        "In questo momento stiamo lavorando per migliorare i nostri servizi, ci scusiamo per l’inconveniente. Riprova a connetterti più tardi.",

    "c-offices-success.title": "Operazione eseguita!",
    "c-offices-success.subtitle":
        "La tua richiesta è stata presa in carico. Potrebbero essere necessari alcuni minuti affinché le modifiche effettuate vengano correttamente visualizzate.",
    "c-offices-success.button": "Torna alla pagina degli uffici",

    "c-item-success.button": "Torna alla pagina principale",

    "c-info.status": "Stato",
    "c-info.active": "Attivo",
    "c-info.not-active": "Non attivo",
    "c-info.creating": "In creazione",

    "c-timed-toast.content": "E' necessario aggiornare la pagina per visualizzare le ultime modifiche.",
    "c-timed-toast.update": "Aggiorna.",

    "c-timed-toast.template.cctActiveService": "Servizio attivato con successo",
    "c-timed-toast.template.cctDisableService": "Servizio disattivato con successo",
    "c-timed-toast.template.expiringPassword":
        "La password scadrà tra {expiringDate} giorni. Aggiornala nella sezione modifica dati utente.",
    "c-timed-toast.template.expiringPassword.today":
        "La password scadrà oggi. Aggiornala nella sezione modifica dati utente.",
    "c-timed-toast.template.expiringPassword.tomorrow":
        "La password scadrà domani. Aggiornala nella sezione modifica dati utente.",
    "c-timed-toast.template.copyAuthToken": "Token dell'utente copiato correttamente.",
    "c-timed-toast.template.ncs.channels.title": "Lista recapiti: ",
    "c-timed-toast.template.ncs.channels.success": "lista recapiti modificata con successo.",
    "c-timed-toast.template.ncs.channels.error": "errore nel salvataggio delle modifiche.",

    "c-create-item.modal.warning": "Proseguendo perderai tutti i dati inseriti e tornerai alla schermata di login",

    "c-edit-item.form.placeholder.economicActivity": "Formati ammessi 99, 99.9, 99.99, 99.99.9, 99.99.99",

    "c-user-not-enable.signature-title": "Firma non disponibile",
    "c-user-not-enable.signature-service-error": "Non hai acquistato il servizio 'TS Digital Signature'.",
    "c-user-not-enable.item-not-valid": "Azienda non validata",
    "c-user-not-enable.go-to-apps": "Torna alle app",
    "c-user-not-enable.footer":
        "© Copyright 2019 - TeamSystem Service S.r.l.  società soggetta all’attività di direzione e coordinamento di TeamSystem S.p.A. - Cap. Soc. € 200.000 I.v. - C.C.I.A.A. di Campobasso n. 124222 - Partita Iva 01641790702 - Sede Legale e Operativa: Viale Giuseppe Ferro, snc - 86100 - Campobasso - Tutti i diritti riservati",

    "c-notifications-center.title1": "Centro configurazione notifiche",

    "c-notifications-center.companiesSelect.label": "Azienda",
    "c-notifications-center.companiesSelect.placeholder": "Seleziona un'azienda",

    "c-notifications-center.homepage.items-preferences-title":
        "Seleziona un'azienda per configurare le notifiche dei servizi TS Digital",
    "c-notifications-center.homepage.items-preferences-button": "Configura",
    "c-notifications-center.homepage.personal-preferences-title": "Notifiche personali",
    "c-notifications-center.homepage.personal-preferences-description":
        "In questa sezione puoi configurare o modificare le impostazioni relative alle notifiche del tuo account TS Digital. Potrai selezionare su quali canali renderle attive ed impostare nuovi recapiti per la ricezione.",
    "c-notifications-center.homepage.personal-preferences-button": "Configura",

    "c-notifications-center.preferences.title": "Configura le tue notifiche",
    "c-notifications-center.preferences.description":
        "In questa sezione puoi configurare le notifiche per i vari servizi attivi sull'azienda selezionata e scegliere, tra quelli disponibili, il canale sul quale desideri riceverle. Inoltre, attivando il canale EMAIL, puoi configurare i recapiti sui quali desideri ricevere la notifica.",

    "c-notifications-center.ts_digital_auth.name": "Notifiche personali",
    "c-notifications-center.ts_digital_registry.name": "Anagrafica Azienda / Studio",
    "c-notifications-center.ts_digital_metering.name": "Consumi",
    "c-notifications-center.ts_digital_eip_ercv.name": "Fatturazione - eRicevi",
    "c-notifications-center.ts_digital_eip_sdi.name": "Fatturazione - SDI",
    "c-notifications-center.digital-box.name": "Digital Box",
    "c-notifications-center.ts_digital_agro_cloud.name": "Adempimenti Agroalimentare",
    "c-notifications-center.ts_digital_pay_gateway.name": "TS Pay",
    "c-notifications-center.ts_digital_sig.name": "TS Digital Signature",
    "c-notifications-center.ts_digital_business_information.name": "Business Information",
    "c-notifications-center.ts_hotel_in_cloud.name": "TS Hospitality",

    "c-notifications-center.toggleChannel": 'Attiva/disattiva il canale "{channel}" per la notifica "{template}"',
    "c-notifications-center.configureLink.EMAIL": "Configura recapiti email",
    "c-notifications-center.configureLink.PUSH": "Configura dispositivi",
    "c-notifications-center.emptyPreferences":
        "Siamo spiacenti, non disponi dei privilegi necessari per modificare le preferenze di notifica per l'azienda selezionata.",

    "c-notifications-center.channels.email.addRecipient.title":
        "Aggiungi l'indirizzo email sul quale desideri ricevere la notifica:",
    "c-notifications-center.channels.email.addRecipient.description":
        "Non configurare alcuna email per continuare a ricevere le notifiche sulla tua email principale.",
    "c-notifications-center.channels.email.addRecipient.email.placeholder":
        "Inserisci l'email su cui ricevere le notifiche",
    "c-notifications-center.channels.email.addRecipient.name.label": "Nome recapito",
    "c-notifications-center.channels.email.addRecipient.name.placeholder":
        "Inserisci il nome con cui verrà visualizzato il recapito",

    "c-notifications-center.channels.email.editRecipients.title": "Lista email configurate:",
    "c-notifications-center.channels.email.editRecipients.emptyList":
        "Nessuna email configurata, riceverai le notifiche sulla tua email principale ({defaultRecipients}).",
    "c-notifications-center.channels.email.editRecipients.list.defaultName": "(Nessun nome recapito)",
    "c-notifications-center.channels.email.resetChannel.button": "Elimina tutte",
    "c-notifications-center.channels.email.resetChannel.modal.title": "Conferma eliminazione",
    "c-notifications-center.channels.email.resetChannel.modal.body":
        "Eliminando tutte le mail configurate riceverai le notifiche all'indirizzo email principale ({defaultRecipients}).",

    "c-notifications-center.migration.modal.title": "Processo di conversione",
    "c-notifications-center.migration.modal.title-loading": "Processo di conversione...",
    "c-notifications-center.migration.modal.started.body1":
        "E' online il nuovo Centro Notifiche per la configurazione delle notifiche di TeamSystem Digital e dei suoi servizi.",
    "c-notifications-center.migration.modal.started.body2":
        "Hai delle notifiche impostate per il servizio Digital Invoice.<br />Per continuare a riceverle dovrai procedere alla conversione delle configurazioni, così da trasferirle al nuovo Centro Notifiche.<br />In questo modo non perderai le notifiche già impostate e potrai configurarne di nuove.",
    "c-notifications-center.migration.modal.started.body3":
        "La conversione è obbligatoria e non è reversibile, ma potrai sempre modificare le configurazioni impostate dal nuovo Centro Notifiche.",
    "c-notifications-center.migration.modal.loading.body": "Conversione in corso...",
    "c-notifications-center.migration.modal.success.body": "Conversione avvenuta con successo",
    "c-notifications-center.migration.modal.success.button": "Vai al nuovo centro notifiche",
    "c-notifications-center.migration.modal.error.body":
        "Sono stati riscontrati errori durante il processo di conversione",

    "dashboard.appcard.TEL.shortdescription":
        "<b>Gestione degli adempimenti fiscali</b>: invio telematico dei Dati Fatture, dei Dati Liquidazione IVA e dell’Esterometro.",
    "dashboard.appcard.EIP.shortdescription":
        "Puoi facilmente gestire l’intero flusso delle fatture elettroniche, sia verso i privati che verso la PA.",
    "dashboard.appcard.ARC.shortdescription":
        "Conservi <b>a norma di legge</b> i documenti informatici: TeamSystem può gestire per te l’intero processo di conservazione.",
    "dashboard.appcard.SIG.shortdescription":
        "La firma elettronica, veloce e sicura, in linea con la normativa vigente. Puoi eliminare smart card e business key, i tuoi clienti possono firmare anche in mobilità.",
    "dashboard.appcard.DBX.shortdescription":
        "L’app che digitalizza la relazione fra Studio e aziende clienti. Permette lo scambio <b>di informazioni e documenti</b>.",
    "dashboard.appcard.GDPR-LOG.shortdescription":
        "Il servizio per archiviare e consultare i log delle attività svolte sui software gestionali TeamSystem, ai sensi del Regolamento Europeo sulla protezione dei dati personali.",
    "dashboard.appcard.EXP.shortdescription":
        "La soluzione mobile per la <b>gestione delle note spese</b>. Permette il passaggio automatico dei dati in contabilità generale e analitica.",
    "dashboard.appcard.PPL.shortdescription":
        "L‘app mobile per <b>rafforzare la relazione tra azienda e dipendenti</b>. Velocizza la condivisione di documenti, tra cui il cedolino e la CU.",
    "dashboard.appcard.PRIVACY.shortdescription":
        "La soluzione che <b>ti guida per essere in regola con il GDPR</b>: gestione adempimenti e documentazione, vigilanza dei dati personali e gestione dei data breach.",
    "dashboard.appcard.EIT.shortdescription":
        "Velocizza l’incasso delle tue fatture non scadute, senza costi fissi o canoni mensili. Scegli quali fatture cedere attraverso un processo semplice e senza burocrazia.",
    "dashboard.appcard.BDG.shortdescription":
        "Software in cloud per la gestione facile e completa dei bilanci aziendali.",
    "dashboard.appcard.ESM.shortdescription":
        "Un tool integrato per l’analisi e la gestione efficiente dei costi e dei consumi aziendali.",
    "dashboard.appcard.CRAM.shortdescription":
        "La soluzione per monitorare costantemente <b>l’equilibrio economico-finanziario</b> delle aziende, visualizzare <b>l’andamento del business</b>, condividere gli <b>indicatori di crisi</b>.",
    "dashboard.appcard.AGRO.shortdescription":
        "La soluzione per la gestione digitale dei <b>registri telematici del vino</b> e dei <b>documenti MVV-E di accompagnamento dei prodotti vitivinicoli</b>, sia in acquisto che in vendita.",
    "dashboard.appcard.NIUMABONUS.shortdescription":
        "La soluzione in cloud per gestire tutte le tipologie di pratiche relative a Superbonus 110%, Ecobonus, Sismabonus, Bonus facciate e Bonus ristrutturazioni",
    "dashboard.appcard.TSPAY.shortdescription":
        "Integra il tuo sistema gestionale con TS Pay per pagare, incassare e riconciliare più facilmente i movimenti bancari!",
    "dashboard.appcard.EBS.shortdescription":
        "Integra il tuo sistema gestionale con TS Pay per pagare, incassare e riconciliare più facilmente i movimenti bancari!",
    "dashboard.appcard.SPID.shortdescription":
        "<b>SPID</b> è la credenziale unica per accedere ai <b>portali della pubblica amministrazione</b> e dei privati convenzionati. Richiedilo per te e la tua attività <b>professionale</b>.",
    "dashboard.appcard.BIS.shortdescription":
        "La soluzione che ti consente di ottenere informazioni relative ad aspetti societari, finanziari, economici e settoriali di tutte le aziende di tuo interesse",
    "dashboard.appcard.DRAG.shortdescription":
        "La soluzione che semplifica l'organizzazione dei documenti, la loro selezione e la sintesi in uno spazio ordinato e personalizzabile.",
    "dashboard.appcard.ANA.shortdescription":
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "dashboard.appcard.AML.shortdescription":
        "Scopri <b> il nuovo TeamSystem Antiriciclaggio</b>, lo strumento che ti permette di essere compliant rispetto alla gestione degli adempimenti Antiriciclaggio",
    "dashboard.appcard.PECMANAGER.shortdescription":
        "Aumenta l’efficienza e migliora l’organizzazione monitorando in modo semplice e intuitivo tutte gli account PEC e i messaggi inviati e ricevuti ogni giorno.",
    "dashboard.appcard.INSIGHTS.shortdescription":
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "dashboard.appcard.DDL.shortdescription":
        "L’app che ti consente di gestire le tue scadenze. Amministra in pochi click incassi e pagamenti, riconcilia i movimenti bancari e tieni sotto controllo le tue finanze.",
    "dashboard.appcard.TSH.shortdescription":
        "<b>Il gestionale per hotel, b&b e case vacanze più digitali.</b> Aumenta le prenotazioni e ottieni il massimo dalla tua struttura con un software tutto in uno. ",
    "dashboard.appcard.SCF.shortdescription":
        "La piattaforma digitale per l'acquisto e la cessione dei crediti d'imposta certificati ed asseverati",
    "dashboard.appcard.CYBER.shortdescription":
        "La soluzione per l'analisi, la prevenzione e la protezione di e-mail, sito web e infrastrutture informatiche.",

    // Item
    "item.legalForm.AA": "Società in accomandita per azioni",
    "item.legalForm.AC": "Associazione",
    "item.legalForm.AE": "Società consortile in accomandita semplice",
    "item.legalForm.AF": "Altre forme",
    "item.legalForm.AI": "Associazione impresa",
    "item.legalForm.AL": "Azienda speciale di ente locale",
    "item.legalForm.AM": "Azienda municipale",
    "item.legalForm.AN": "Società consortile in nome collettivo",
    "item.legalForm.AP": "Azienda provinciale",
    "item.legalForm.AR": "Azienda regionale",
    "item.legalForm.AS": "Società in accomandita semplice",
    "item.legalForm.AT": "Azienda autonoma statale",
    "item.legalForm.AU": "Società  per azioni con socio unico",
    "item.legalForm.AZ": "Azienda speciale",
    "item.legalForm.CC": "Consorzio con attività esterna",
    "item.legalForm.CE": "Comunione ereditaria",
    "item.legalForm.CF": "Consorzio fidi",
    "item.legalForm.CI": "Società cooperativa a responsabilita illimitata",
    "item.legalForm.CL": "Società cooperativa a responsabilita limitata",
    "item.legalForm.CM": "Consorzio municipale",
    "item.legalForm.CN": "Società consortile",
    "item.legalForm.CO": "Consorzio",
    "item.legalForm.CR": "Consorzio intercomunale",
    "item.legalForm.CS": "Consorzio senza attività esterna",
    "item.legalForm.CZ": "Consorzio di cui al dlgs 267/2000",
    "item.legalForm.DI": "Impresa individuale",
    "item.legalForm.EC": "Ente pubblico commerciale",
    "item.legalForm.ED": "Ente diritto pubblico",
    "item.legalForm.EE": "Ente ecclesiastico",
    "item.legalForm.EI": "Ente impresa",
    "item.legalForm.EL": "Ente sociale",
    "item.legalForm.EM": "Ente morale",
    "item.legalForm.EN": "Ente",
    "item.legalForm.EP": "Ente pubblico economico",
    "item.legalForm.ER": "Ente ecclesiastico civilmente riconosciuto",
    "item.legalForm.ES": "Ente di cui alla l.r. 21-12-93, n.88",
    "item.legalForm.FI": "Fondazione impresa",
    "item.legalForm.FO": "Fondazione",
    "item.legalForm.GE": "Gruppo europeo di interesse economico",
    "item.legalForm.IC": "Istituto di credito",
    "item.legalForm.ID": "Istituto di credito di diritto pubblico",
    "item.legalForm.IF": "Impresa familiare",
    "item.legalForm.IR": "Istituto religioso",
    "item.legalForm.LL": "Azienda speciale di cui al dlgs 267/2000",
    "item.legalForm.MA": "Mutua assicurazione",
    "item.legalForm.OC": "Società cooperativa consortile",
    "item.legalForm.OO": "Cooperativa sociale",
    "item.legalForm.OS": "Società consortile cooperativa a responsabilità limitata",
    "item.legalForm.PA": "Associazione in partecipazione",
    "item.legalForm.PC": "Piccola società cooperativa",
    "item.legalForm.PF": "Persona fisica",
    "item.legalForm.PS": "Piccola società cooperativa a responsabilità limitata",
    "item.legalForm.RC": "Contratto di rete dotato di soggettività giuridica ",
    "item.legalForm.RR": "Società a responsabilità limitata a capitale ridotto",
    "item.legalForm.RS": "Società a responsabilità limitata semplificata",
    "item.legalForm.SA": "Società anonima",
    "item.legalForm.SC": "Società cooperativa",
    "item.legalForm.SD": "Società europea",
    "item.legalForm.SE": "Società semplice",
    "item.legalForm.SF": "Società di fatto",
    "item.legalForm.SG": "Società cooperativa europea",
    "item.legalForm.SI": "Società irregolare",
    "item.legalForm.SL": "Società consortile a responsabilità limitata",
    "item.legalForm.SM": "Società di mutuo soccorso",
    "item.legalForm.SN": "Società in nome collettivo",
    "item.legalForm.SO": "Società consortile per azioni",
    "item.legalForm.SP": "Società per azioni",
    "item.legalForm.SR": "Società a responsabilità limitata",
    "item.legalForm.SS": "Società costituita in base a leggi di altro stato",
    "item.legalForm.ST": "Soggetto estero",
    "item.legalForm.SU": "Società a responsabilità limitata con unico socio",
    "item.legalForm.SV": "Società tra professionisti",
    "item.legalForm.SZ": "Società non prevista dalla legislazione italiana",
    "item.legalForm.XX": "Non precisata",

    "c-user-roles-table.button.apply": "Applica a tutto",
    "c-user-roles-table.button.apply.to.enabled.services": "Applica ai servizi abilitati",
    "c-user-roles-table.button.invite": "Invita di nuovo",
    "c-user-roles-table.table.header.services": "Servizi e sottoservizi",
    "c-user-roles-table.table.header.role": "Ruolo",
    "c-registry-user.role-description.tooltip": `<b>Descrizione ruoli</b><br/><br/>Admin:<br/> può utilizzare il servizio, può aggiungere e gestire nuovi utenti.</b><br/><br/> Nessun ruolo(sotto servizio):<br/> scegli questa opzione se non vuoi applicare nessun ruolo, impedendo all'utente di utilizzare il servizio o sotto servizio.`,
    "c-registry-user.role-manager-description.tooltip": `<b>Descrizione ruoli</b><br/><br/>Manager:<br/>contiene i vecchi ruoli READ e WRITE relativi alla connessione con l’azienda selezionata. L’utente potrà utilizzare il servizio o sotto servizio,  aggiungere e gestire nuovi utenti se ha ruolo di Admin sullo studio.<br/><br/>Nessun ruolo:<br/>scegli questa opzione se non vuoi applicare nessun ruolo, impedendo all’utente di utilizzare il servizio o sotto servizio.`,
    "c-registry-user.selected-companies.tooltip": `<b>Aziende abilitate al servizio</b><br/><br/>Sono le aziende che hanno acquistato un pacchetto relativo al servizio o sotto servizio per cui vuoi assegnare dei ruoli tra quelle che hai selezionato nello step precedente.`,
    "c-registry-user.notActive-description.tooltip": `<b>Servizio momentaneamente disattivo.</b><br/><br/>Non hai rinnovato l'acquisto del pacchetto del servizio. Effettua l'acquisto per tornare a usufruire del servizio, i permessi già impostati sull'utente verranno ripristinati.`,
    "c-user-roles-table.table.header.descripion": "Descrizione",
    "c-user-roles-table.table.header.activationDate": "Data attivazione",
    "c-user-roles-table.table.header.activationDate.tooltip":
        "Data in cui è stato impostato il permesso all'utente sul servizio di riferimento.",
    "c-user-roles-table.table.header.serviceStatus": "Stato servizio",
    "c-user-roles-table.table.header.companiesNumber": "Aziende abilitate al servizio",

    "c-user-roles-table.selectedcompany": "{companies} azienda",
    "c-user-roles-table.selectedcompanies": "{companies} aziende",
    "c-user-roles-table.selectedcompanies.total": " su {totalCompanies} selezionate",
    "c-user-roles-table.selectedcompanies.all": "Tutte le aziende selezionate",

    "c-user-roles-table.description.title": "Modifica permessi",
    "c-user-roles-table.edit.success": "Nuovi permessi impostati correttamente",
    "c-user-roles-table.edit.error": "Impossibile impostare i permessi selezionati",

    "c-user-roles-table.description.admin": "Può utilizzare il servizio, può aggiungere e gestire nuovi utenti.",
    "c-user-roles-table.description.employee": "L’utente può utilizzare il servizio.",
    "c-user-roles-table.description.manager": "Utilizza il sotto servizio, gestisce nuovi utenti.",
    "c-user-roles-table.description.multipleRoles":
        "L’utente non ha ruoli specifici a livello di servizio ma solo a livello di sotto servizio.",
    "c-user-roles-table.description.noRole": "Nessun ruolo selezionato per l’utente.",

    "c-user-roles-table.option.value.noRole": "Nessun ruolo",

    "c-user-roles-table.description.admin.company": "Admin dell'azienda",
    "c-user-roles-table.description.admin.featureCode": "Admin su un sottoservizio",
    "c-user-roles-table.description.admin.featureCodes": "Admin su più sottoservizi",
    "c-user-roles-table.description.employee.company": "Employee dell'azienda",
    "c-user-roles-table.description.employee.featureCode": "Employee su un sottoservizio",
    "c-user-roles-table.description.employee.featureCodes": "Employee su più sottoservizi",
    "c-user-roles-table.description.manager.featureCode": "Manager su un sottoservizio",
    "c-user-roles-table.description.manager.featureCodes": "Manager su più sottoservizi",
    "c-user-roles-table.description.multipleRoles.featureCodes": "Ruoli multipli su più sottoservizi",
    "c-user-roles-table.description.multipleRoles.servicesFeatures": "Ruoli multipli su più servizi e sottoservizi",
    "c-user-roles-table.description.uneditableMultipleRoles": "Ruoli multipli su più sottoservizi",

    "c-user-roles-table.description.active": "Attivo",
    "c-user-roles-table.description.notActive": "Disattivo",
    "c-user-roles-table.description.noRoles": "Nessun ruolo",

    "c-user-summary.add-permissions": "Aggiungi permessi su altre aziende",
    "c-user-summary.items-roles-table.headers.description": "Servizio e sotto servizio",
    "c-user-summary.items-roles-table.headers.activation-date": "Data attivazione",
    "c-user-summary.items-roles-table.headers.search-placeholder":
        "Filtra per partita iva, codice fiscale o ragione sociale",

    "v-user-summary.role-edit-modal.title": "Modifica permessi servizio",

    //pre-login
    "v-pre-login.title": "Accedi o registrati a TeamSystem Digital",
    "v-pre-login.login-or-sign-up": "Accedi o registrati",
    "v-pre-login.privacy-policy": "Privacy Policy",
    "v-pre-login.cookie": "Cookie",
    "v-pre-login.copyright": "© {year} TeamSystem Service",

    "v-pre-login.advertising.intuitive.title":
        "La piattaforma <highlight> semplice e sostenibile</highlight> per la gestione dei processi digitali.",
    "v-pre-login.advertising.intuitive.description":
        "I servizi TS Digital ti aiutano ad elevare il tuo business e a rimanere costantemente aggiornato sulle nuove normative.",

    //invitation-prelogin
    "v-invitation-prelogin.login-button-text": "Log in or register",
    "v-invitation-prelogin.hi": "Hi,",
    "v-invitation-prelogin.subtitle": "Log in to TS Digital or register with TeamSystem ID",
    "v-invitation-prelogin.description":
        "The TeamSystem ID is a <b>unique user</b> that allows you to access the various TeamSystem software in complete security, without having to remember different access credentials.",
    "v-invitation-prelogin.info": "<b>Please note:</b> Your account will only be valid if you use the following email:",
    "v-invitation-prelogin.tabs.already-have-tsid": "Do you have a TeamSystem ID?",
    "v-invitation-prelogin.tabs.not-have-tsid": "Don't have a TeamSystem ID?",
    "v-invitation-prelogin.already-have-tsid.title": "Log in and start operating!",
    "v-invitation-prelogin.already-have-tsid.step1.subtitle": "Log in with your TeamSystem ID",
    "v-invitation-prelogin.already-have-tsid.step1.body-copy":
        "Enter your credentials and log in with your TeamSystem ID. Remember to use the following email: <b>{email}</b>. If your TeamSystem ID is not associated with the indicated email you will need to create another one.",
    "v-invitation-prelogin.already-have-tsid.step2.subtitle": "Enter TS Digital",
    "v-invitation-prelogin.already-have-tsid.step2.body-copy":
        "Once logged in <b>you will be redirected to your TS Digital account</b> where you can start operating and collaborating with colleagues.",
    "v-invitation-prelogin.not-have-tsid.title": "Sign up and start operating!",
    "v-invitation-prelogin.not-have-tsid.step1.subtitle": "Register your TeamSystem ID",
    "v-invitation-prelogin.not-have-tsid.step1.body-copy":
        "Access the TeamSystem ID page and click on <b>“Don't have a TeamSystem ID? Register”</b>then follow the process to register. Remember to use the following email to register: <b>{email}</b>.",
    "v-invitation-prelogin.not-have-tsid.step2.subtitle": "Enter TS Digital",
    "v-invitation-prelogin.not-have-tsid.step2.body-copy":
        "Log in for the first time with your new TeamSystem ID, <b>you will be automatically redirected to your new {appName} account</b> where you will find everything you need to operate and collaborate with colleagues.",
    "v-invitation-prelogin.not-have-tsid.step2.body-copy.appName.digitalBox": "Digital Box",
    "v-invitation-prelogin.not-have-tsid.step2.body-copy.appName.portale": "TS Digital"
};
