import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "antd";

export const Container = styled.div`
    padding-bottom: 30px;
`;

export const ChipsTitleContainer = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin-left: 24px;
`;

export const ChipsContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    margin-left: 24px;
`;

export const UpdateSearchContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    @media (max-width: 1480px) {
        margin-top: 16px;
    }
`;

export const UpdateContainer = styled.div`
    margin-right: 16px;
`;

export const SearchTitleContainer = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 4px;
`;

export const MarginIcon = styled(FontAwesomeIcon)`
    margin-right: 4px;
`;

export const LoadingIcon = styled(Icon)`
    margin-right: 8px;
`;

export const Chip = styled.div`
    background: ${props => (props.selected ? props.theme.colors.secondary.diamond : props.theme.colors.text.gainsboro)};

    border-radius: 16px;
    border-color: ${props =>
        props.selected ? props.theme.colors.secondary.diamond : props.theme.colors.text.gainsboro};
    border-width: 1px;
    border-style: solid;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 32px;
    width: fit-content;
    margin-right: 16px;
    margin-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;

    color: ${props => (props.selected ? props.theme.colors.primary.darkImperialBlue : props.theme.colors.text.cadet)};
    font-family: Roboto;
    letter-spacing: 0.25px;
    white-space: nowrap;
    cursor: pointer;

    :hover {
        background: ${props => props.theme.colors.secondary.diamond};
        color: ${props => props.theme.colors.primary.darkImperialBlue};
        border-color: ${props => props.theme.colors.primary.darkImperialBlue};
    }
`;
