import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";

import { errorBoundary } from "../../../components/general/error-boundary";
import Privacy from "../../../components/privacy";
import PageTitle from "../../../components/general/page-title";

import { getWaitingList } from "../../../actions/waitinglist/read";
import { subscribeWaitinglist, deleteWaitinglist } from "../../../actions/waitinglist/write";

import "../style.css";

class PrivacyView extends React.Component {
    static propTypes = {
        company: PropTypes.object.isRequired,
        history: PropTypes.object,
        services: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired
    };

    render() {
        const {
            company,
            history,
            services,
            user,
            getWaitingList,
            getWaitingListStatus,
            subscribeWaitinglist,
            deleteWaitinglist,
            waitinglistDeleteStatus,
            waitinglist,
            waitinglistSubscribeStatus
        } = this.props;
        return (
            <div className="service-view">
                <PageTitle
                    title={<FormattedMessage id="c-services-view.PRIVACY.name" />}
                    onBack={() => history.goBack()}
                />
                <Privacy
                    company={company}
                    services={services}
                    user={user}
                    getWaitingList={getWaitingList}
                    getWaitingListStatus={getWaitingListStatus}
                    subscribeWaitinglist={subscribeWaitinglist}
                    deleteWaitinglist={deleteWaitinglist}
                    waitinglistDeleteStatus={waitinglistDeleteStatus}
                    waitinglist={waitinglist}
                    waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    services: state.services,
    user: state.user,
    waitinglist: state.waitinglist.read.getWaitingList.waitinglist,
    waitinglistSubscribeStatus: state.waitinglist.write.subscribeWaitinglist.status,
    waitinglistDeleteStatus: state.waitinglist.write.deleteWaitinglist.status,
    getWaitingListStatus: state.waitinglist.read.getWaitingList.status
});

const composedHoc = compose(
    connect(mapStateToProps, { getWaitingList, subscribeWaitinglist, deleteWaitinglist }),
    errorBoundary
);

export default composedHoc(PrivacyView);
