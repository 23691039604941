import styled, { css } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    position: relative;
`;

export const Tab = styled.div`
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 24px;
    padding-left: 24px;
    color: ${props => props.theme.colors.text.cadet};
    font-size: 24px;
    font-family: Cairo;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.23px;
    line-height: 24px;
    position: relative;
    border-bottom: 4px solid transparent;
    cursor: default;
    flex-grow: ${props => (props.fullWidth ? 1 : 0)};

    :last-child {
        ::after {
            content: initial;
        }
    }

    ${props =>
        !props.newComponent &&
        props.completed &&
        css`
            background-color: ${props => props.theme.colors.background.whiteSmoke};
            border-bottom: 4px solid ${props => props.theme.colors.primary.richElectricBlue};
            z-index: 1;
        `}

    ${props =>
        !props.newComponent &&
        props.active &&
        css`
            color: ${props => props.theme.colors.primary.richElectricBlue};
            background-color: ${props => props.theme.colors.background.whiteSmoke};
            border-bottom: 4px solid ${props => props.theme.colors.primary.richElectricBlue};
            z-index: 1;
            clip-path: polygon(calc(100% - 24px) 0, 100% 50%, calc(100% - 24px) 100%, -1px 100%, -1px 0%);
            padding-right: 48px;

            /*::after {
                content: "";
                border-style: solid;
                border-width: 42px 0 42px 25px;
                border-color: ${props => props.theme.colors.background.white}
                    ${props => props.theme.colors.background.white} ${props => props.theme.colors.background.white}
                    ${props => props.theme.colors.background.whiteSmoke};
                position: absolute;
                right: -16px;
            }
            */
        `}

        ${props =>
        props.newComponent &&
        props.active &&
        css`
            color: ${props => props.theme.colors.primary.richElectricBlue};
            background-color: ${props => props.theme.colors.background.whiteSmoke};
            border-bottom: 4px solid ${props => props.theme.colors.primary.richElectricBlue};
            z-index: 1;
            padding-right: 48px;

            /*::after {
                    content: "";
                    border-style: solid;
                    border-width: 42px 0 42px 25px;
                    border-color: ${props => props.theme.colors.background.white}
                        ${props => props.theme.colors.background.white} ${props => props.theme.colors.background.white}
                        ${props => props.theme.colors.background.whiteSmoke};
                    position: absolute;
                    right: -16px;
                }
                */
        `}

        ${props =>
        !props.newComponent &&
        props.disabled &&
        css`
            color: ${props => props.theme.colors.text.cadet};
        `}

        ${props =>
        props.newComponent &&
        props.disabled &&
        css`
            color: #b9c7d0;
        `}
`;

export const TabUnderline = styled.div`
    position: absolute;
    bottom: 0;
    border-bottom: 1px solid ${props => props.theme.colors.primary.columbiaBlue};
    z-index: 0;
    width: 100%;
`;
