import React from "react";
import PropTypes from "prop-types";

import { Select } from "antd";

import { FormattedHTMLMessage } from "react-intl";

import "./style.css";

const ConnectionsSettingsSignatureType = ({ className, disabled, display, onChange, value }) =>
    display && (
        <div className={`c-connections-settings-signature-type ${className}`}>
            <div className="title">
                <FormattedHTMLMessage id="c-connection.default-sign" />
            </div>
            <Select defaultValue="NO_SIGN" disabled={disabled} onChange={onChange} value={value}>
                <Select.Option value="NO_SIGN">
                    <FormattedHTMLMessage id="c-connection.no-sign" />
                </Select.Option>
                <Select.Option value="TEAMSYSTEM">
                    <FormattedHTMLMessage id="c-connection.sign-with-ts" />
                </Select.Option>
            </Select>
        </div>
    );

ConnectionsSettingsSignatureType.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    display: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string
};

ConnectionsSettingsSignatureType.defaultProps = {
    className: "",
    disabled: true,
    display: false,
    onChange: () => {},
    value: ""
};

export default ConnectionsSettingsSignatureType;
