import styled from "styled-components";

import * as CircleSize from "./circles-size";

export const Container = styled.div`
    background-color: ${props => props.background};
    ${props => CircleSize[props.size]}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
`;

export const Value = styled.div`
    display: inline-block;
    color: ${props => props.color};
    font-size: 2.625rem;
    font-family: Cairo;
    font-weight: bold;
    letter-spacing: 0.31px;
    line-height: 3rem;

    @media (max-width: 1439px) {
        font-size: 2.125rem;
        letter-spacing: 0.25px;
        line-height: 2.5rem;
    }
`;

export const ValueType = styled.div`
    display: inline-block;
    color: ${props => props.color};
    font-size: 1.5rem;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 2rem;
    margin-left: 0.5rem;

    @media (max-width: 1439px) {
        font-size: 1.25rem;
        letter-spacing: 0.15px;
        line-height: 2rem;
    }
`;

export const Description = styled.div`
    color: ${props => props.color};
    font-size: 1rem;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.11px;
    line-height: 1.5rem;

    @media (max-width: 1439px) {
        font-size: 0.875rem;
        letter-spacing: 0px;
        line-height: 1.5rem;
    }
`;
