import styled from "styled-components";
import ChildAppComponent from "./child-app";

// Center the spinner
export const SpinnerContainer = styled.div`
    margin-top: 40vh;
    text-align: center;
`;

// Style and position the embedded iframe
export const ChildApp = styled(ChildAppComponent)`
    border: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
`;
