import React from "react";
import PropTypes from "prop-types";

import { Button } from "@ts-digital/vrc";
import { FormattedMessage } from "react-intl";

import { StyledModal } from "../styled";

const SuccessModal = ({ backButtonLabel, onBack, visible, enableInvitation }) => {
    return (
        <StyledModal
            closable={false}
            footer={
                <Button kind="primary" onClick={onBack}>
                    {backButtonLabel}
                </Button>
            }
            maskStyle={{
                background: "rgba(0, 80, 117, 0.32)"
            }}
            title={
                <>
                    <FormattedMessage id="general.congratulations" />!
                </>
            }
            visible={visible}
            width="496px"
        >
            <FormattedMessage
                id={
                    enableInvitation
                        ? "v-user-creation-flow.modals.content.success.invite"
                        : "v-user-creation-flow.modals.content.success"
                }
            />
        </StyledModal>
    );
};

SuccessModal.propTypes = {
    backButtonLabel: PropTypes.any.isRequired,
    onBack: PropTypes.func.isRequired,
    visible: PropTypes.bool
};

SuccessModal.defaultProps = {
    visible: false
};

export default SuccessModal;
