import { fieldRequired } from "@ts-digital/agyo-validations";

import { addressSchema } from "./address";
import { translateMessage } from "../../i18n";

export const personProfileEditSchema = {
    type: "object",
    properties: {
        firstName: {
            type: "string",
            validate: fieldRequired(translateMessage("general.form.field-required"))
        },
        lastName: {
            type: "string",
            validate: fieldRequired(translateMessage("general.form.field-required"))
        },
        privacyFlag: {
            type: "boolean"
        },
        ...addressSchema.properties
    }
};
