import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import { Table, Input } from "antd";

import "./style.css";

const Search = Input.Search;

class UserRolesTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resourceIdVal: undefined
        };
    }

    static propTypes = {
        selectedUser: PropTypes.object.isRequired,
        userResources: PropTypes.array.isRequired,
        intl: PropTypes.object.isRequired,
        agyoServices: PropTypes.array
    };

    getServiceDescription(id) {
        const { agyoServices } = this.props;

        for (let i in agyoServices) {
            if (agyoServices[i].appId === id) return agyoServices[i].appDescription;

            if (agyoServices[i].featureCode === id) {
                return agyoServices[i].description;
            }
        }

        return id;
    }

    getData() {
        const { resourceIdVal } = this.state;
        const { selectedUser, userResources } = this.props;

        let filters = resourceIdVal !== undefined ? [{ key: "resourceId", value: resourceIdVal }] : [];

        // filtro per la chiave di ricerca testuale
        let res = filters
            .reduce((state, filter) => {
                return state.filter(x => x[filter.key].includes(filter.value.toUpperCase()));
            }, selectedUser.roles)
            // filtro eleminando i ruoli registered, e quelli relativi alle aziende sulle quali non ho visibilità
            .filter(role => role.resourceId !== "REGISTERED" && userResources.includes(role.resourceId));

        // traduco gli id dei servizi e featurecode in descrizioni parlanti
        return res.map(role => {
            let newrole = { ...role };

            if (newrole.appId) newrole.appId = this.getServiceDescription(role.appId);

            if (newrole.featureCode) newrole.featureCode = this.getServiceDescription(role.featureCode);

            return newrole;
        });
    }

    render() {
        const { selectedUser, intl } = this.props;

        const columns = [
            {
                title: intl.formatMessage({
                    id: "c-registry-user.info.service"
                }),
                dataIndex: "appId"
            },
            {
                title: intl.formatMessage({
                    id: "c-registry-user.info.featurecode"
                }),
                dataIndex: "featureCode"
            },
            {
                title: intl.formatMessage({
                    id: "c-registry-user.info.company"
                }),
                dataIndex: "resourceId"
            },
            {
                title: intl.formatMessage({
                    id: "c-registry-user.info.role"
                }),
                dataIndex: "actionKey"
            }
        ];

        return (
            <div className="c-user-roles">
                <div className="c-role-table">
                    <Table
                        rowKey={record => selectedUser.roles.indexOf(record)}
                        columns={columns}
                        dataSource={this.getData()}
                        size="small"
                        bordered={false}
                        title={() => (
                            <div>
                                <Search
                                    placeholder={intl.formatMessage({
                                        id: "c-manage-data.user.info.company-search"
                                    })}
                                    onSearch={val => this.setState({ resourceIdVal: val })}
                                    enterButton
                                />
                            </div>
                        )}
                    />
                </div>
            </div>
        );
    }
}

export default injectIntl(UserRolesTable);
