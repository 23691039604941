import styled from "styled-components";
import { TS_DIGITAL_IMAGES } from "../../../config";

export const Container = styled.div`
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
`;

export const Header = styled.header`
    height: 4rem;
    background-color: ${props => props.theme.colors.text.yankeesBlue};
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
    position: relative;
    color: ${props => props.theme.colors.background.white};
    font-size: 1rem;
    font-family: Roboto;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 1rem;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
`;

export const ContentWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
`;

export const Sidebar = styled.div`
    width: 35%;
    min-height: calc(100vh - 4rem);
    background-color: ${props => props.theme.colors.background.white};
    padding: 3.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 991px) {
        width: 100%;
        padding: 2.5rem 1rem;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        width: 40%;
        padding: 2.5rem;
    }

    @media (min-width: 1200px) and (max-width: 1439px) {
        width: 35%;
        padding: 2.5rem;
    }

    @media (min-width: 1400px) and (max-width: 1919px) {
        width: 35%;
        padding: 3.5rem;
    }

    @media (min-width: 1920px) {
        width: 30%;
        padding: 3.5rem 5rem;
    }
`;

export const AdvertisingContainer = styled.div`
    width: 65%;
    height: calc(100vh - 4rem);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${TS_DIGITAL_IMAGES}prelogin/bgblue.svg);
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: 991px) {
        display: none;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        width: 60%;
    }

    @media (min-width: 1200px) and (max-width: 1439px) {
        width: 65%;
    }

    @media (min-width: 1400px) and (max-width: 1919px) {
        width: 65%;
    }

    @media (min-width: 1920px) {
        width: 70%;
    }
`;

export const LogoContainer = styled.div`
    width: 165px;

    @media (max-width: 992px) {
        margin: 0 auto;
    }
`;

export const Logo = styled.img`
    width: 100%;
`;

export const Title = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 2.5rem;
    font-family: Cairo;
    font-weight: bold;
    letter-spacing: 0.31px;
    line-height: 3rem;

    @media (max-width: 1439px) {
        font-size: 2rem;
        line-height: 2.5rem;
        letter-spacing: initial;
    }
`;

export const LoginContainer = styled.div`
    margin-top: 6rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @media (max-width: 992px) {
        max-width: 374px;
        margin-left: auto;
        margin-right: auto;
    }
`;
