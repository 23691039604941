import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { Redirect } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";

import SuccessPage from "../../../components/general/accordion-stepper/success-page";
import { errorBoundary } from "../../../components/general/error-boundary";

class OfficesSuccess extends Component {
    static propTypes = {
        createOfficeStatus: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        updateOfficeStatus: PropTypes.object.isRequired
    };

    render() {
        const { createOfficeStatus, history, match, updateOfficeStatus } = this.props;

        return createOfficeStatus.ended || updateOfficeStatus.ended ? (
            <Fragment>
                <SuccessPage
                    onBackButtonClick={() => {
                        history.goBack();
                    }}
                    title={"c-offices-success.title"}
                    subtitle={"c-offices-success.subtitle"}
                    buttonText={"c-offices-success.button"}
                />
            </Fragment>
        ) : (
            <Redirect to={`/${match.params.item}/dashboard`} />
        );
    }
}

const mapStateToProps = state => {
    return {
        createOfficeStatus: state.offices.createOffice.status,
        updateOfficeStatus: state.offices.updateOffice.status
    };
};

const composedHoc = compose(connect(mapStateToProps, {}), errorBoundary);

export default composedHoc(OfficesSuccess);
