import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button } from "@ts-digital/vrc";

import Initials from "../../../general/user-initials";
import { faCheckCircle, faClock } from "@fortawesome/free-solid-svg-icons";

import { RolesSection } from "./roles-section";

import {
    CompaniesContainer,
    CompanyDescription,
    CompanyDescriptionContainer,
    Description,
    EditButtonContainer,
    SingleCompanyContainer,
    StatusIcon,
    Title,
    TitleContainer
} from "./styled";
import { SpinnerContainer } from "../../../general/spinner/styled";
import { Spin } from "antd";

export const UserDetailsDrawerContent = ({
    onEditRoles,
    isLoggedUser,
    isOpen,
    isOwner,
    selectedUser,
    rolesSummary,
    fetchRolesSummary,
    rolesSummaryStatus,
    currentCompay
}) => {
    const intl = useIntl();
    useEffect(() => {
        if (selectedUser) {
            fetchRolesSummary(selectedUser.profile.uuid, 0, 10, undefined, currentCompay.base.uuid);
        }
    }, [selectedUser, fetchRolesSummary, currentCompay.base.uuid]);

    if (!selectedUser) {
        return <>{intl.formatMessage({ id: "c-registry-user.user-details.drawer.no-selected-user" })}</>;
    }

    if (rolesSummaryStatus.started) {
        return (
            <SpinnerContainer>
                <Spin size="large" />
            </SpinnerContainer>
        );
    }

    const data = rolesSummary[0] || [];

    let indexOfStudio = data.findIndex(obj => obj.itemId === currentCompay.base.uuid);

    // Move the object to the first position if found
    if (indexOfStudio !== -1) {
        // Splice the array to move the object to the first position
        data.unshift(data.splice(indexOfStudio, 1)[0]);
    }

    return (
        <>
            <TitleContainer>
                <Initials
                    size={"40px"}
                    name={`${selectedUser.profile.firstName} ${selectedUser.profile.lastName}`}
                    fontSize={"16px"}
                    newComponent={true}
                />
                <div>
                    <Title>{`${selectedUser.profile.firstName} ${selectedUser.profile.lastName}`}</Title>
                    <Description>{`${selectedUser.profile.id}`}</Description>
                    <div>
                        <StatusIcon
                            icon={selectedUser.status.active ? faCheckCircle : faClock}
                            active={selectedUser.status.active.toString()}
                        />
                        <FormattedMessage
                            id={`c-registry-user.user-${selectedUser.status.active ? "active" : "not-active"}`}
                        />
                    </div>
                </div>
            </TitleContainer>
            <CompaniesContainer>
                {data &&
                    data.map((item, i) => (
                        <SingleCompanyContainer key={i}>
                            <CompanyDescriptionContainer>
                                <Description>
                                    {intl.formatMessage({ id: "c-manage-data.company.card.company" })}
                                </Description>
                                <CompanyDescription>
                                    {item.itemClassifier === "STUDIO"
                                        ? `${intl.formatMessage({
                                              id: "v-user-creation-flow.steps.set-permissions.selected-modal.table-my-studio"
                                          })}: `
                                        : null}
                                    {item.description}
                                </CompanyDescription>
                            </CompanyDescriptionContainer>
                            <RolesSection
                                isDrawerOpen={isOpen}
                                enhancedRoles={item.enhancedRoles}
                                localServicesEnabled={item.localServicesEnabled}
                            />
                        </SingleCompanyContainer>
                    ))}
                <EditButtonContainer>
                    <Button
                        kind="primary"
                        data-cy={"manage-users-drawer-edit"}
                        onClick={() => {
                            onEditRoles(selectedUser.profile.uuid);
                        }}
                        disabled={isOwner || isLoggedUser}
                        data-gainsight-track={"user-list-edit-user-drawer"}
                    >
                        <FormattedMessage id="general.edit-users" />
                    </Button>
                </EditButtonContainer>
            </CompaniesContainer>
        </>
    );
};
