import styled, { css } from "styled-components";
import { Tooltip } from "antd";

export const ServiceCardsContainer = styled.div`
    display: grid;
    background-color: ${props => props.theme.colors.background.white};
    ${props =>
        !props.expanded &&
        css`
            @media (max-width: 1189px) {
                grid-template-columns: repeat(1, 1fr);
            }

            @media (min-width: 1190px) and (max-width: 1439px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (min-width: 1440px) {
                grid-template-columns: repeat(3, 1fr);
            }
        `}
    grid-auto-rows: auto;
    grid-gap: 16px;
    padding: 24px 16px;
`;

export const Container = styled.div`
    height: ${props => (props.expanded ? "100%" : "247px")};
    background-color: ${props => props.theme.colors.background.white};
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: ${props => (props.expanded ? "initial" : "space-between")};
    position: relative;
`;

export const Content = styled.div`
    margin: 24px;
`;

export const Footer = styled.div`
    background-color: ${props => props.theme.colors.background.whiteSmoke};
    padding: 8px;
    height: 48px;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > :last-child {
        margin-left: 16px;
    }
`;

export const TooltipIconContainer = styled.div`
    color: ${props => props.theme.colors.primary.richElectricBlue};
`;

export const Title = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 24px;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 32px;
    margin-right: 8px;
`;

export const Description = styled.div`
    color: ${props => props.theme.colors.text.cadet};
    font-size: 14px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 24px;
    max-height: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const CheckIconContainer = styled.div`
    position: absolute;
    right: 8px;
    top: 8px;
    color: ${props => props.theme.colors.status.laSalleGreen};
`;

export const CloseButtonContainer = styled.div`
    position: absolute;
    right: 8px;
    top: 8px;
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const ContactToBuyContainer = styled.div`
    color: ${props => props.theme.colors.text.cadet};
`;

export const CustomTooltip = styled(Tooltip)`
    color: ${props => props.theme.colors.primary.richElectricBlue};
`;
