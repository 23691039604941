import {
    fieldLength,
    fieldRequired,
    isTaxId,
    isVatNumber,
    taxIdExists,
    vatNumberExists
} from "@ts-digital/agyo-validations";

import combineValidators from "@ts-digital/agyo-validations/lib/utils/combineValidators";
import orValidators from "@ts-digital/agyo-validations/lib/utils/orValidators";

import { translateMessage, translateMessageWithParams } from "../../i18n/index";
import { addressSchema } from "./address";
import store from "../../reducers";

import { DOMAIN } from "../../config";

let userLocale = navigator.language;

store.subscribe(() => {
    if (store.getState().auth.loginAuth && store.getState().auth.loginAuth.locale !== userLocale) {
        userLocale = store.getState().auth.loginAuth.locale || navigator.language;
    }
});

export const companyProfileCreateSchema = {
    type: "object",
    properties: {
        ...addressSchema.properties,
        classifier: {
            type: "string",
            validate: fieldRequired(translateMessage("general.form.field-required"))
        },
        description: {
            type: "string",
            validate: combineValidators(
                fieldRequired(translateMessage("general.form.field-required")),
                fieldLength(translateMessageWithParams("general.form.field-length-range", { min: 1, max: 255 }), 1, 255)
            )
        },
        vatNumber: {
            type: "string",
            validate: isVatNumber("taxRegion", translateMessage),
            asyncValidate: vatNumberExists(
                translateMessage("c-create-form.vat-number-tax-id-not-valid"),
                DOMAIN,
                "PORTALE"
            )
        },
        taxRegime: {
            type: "string"
        },
        taxRegion: {
            type: "string",
            onChange: changeContext => {
                changeContext.actionCreators.change("taxId", "");
            }
        },
        taxId: {
            type: "string",
            validate: orValidators(
                isVatNumber("taxRegion", translateMessage),
                isTaxId("taxRegion", "classifier", translateMessage)
            ),
            asyncValidate: taxIdExists(translateMessage("c-create-form.vat-number-tax-id-not-valid"), DOMAIN, "PORTALE")
        }
    }
};
