import React from "react";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

const StepperAction = ({ prev, next, renderPrev, renderNext, nextButtonProps, prevButtonProps }) => (
    <div className="action-nav">
        {renderPrev ? (
            <Button onClick={() => prev()} {...prevButtonProps}>
                <FormattedMessage id="connections.create.step-x.button-prev-text" />
            </Button>
        ) : null}
        <Button onClick={() => next()} type="primary" {...nextButtonProps}>
            {nextButtonProps.text}
        </Button>
    </div>
);

StepperAction.propTypes = {
    next: PropTypes.func,
    nextButtonProps: PropTypes.object,
    prev: PropTypes.func,
    prevButtonProps: PropTypes.object,
    renderPrev: PropTypes.bool,
    renderNext: PropTypes.bool
};

StepperAction.defaultProps = {
    next: () => {},
    nextButtonProps: {
        text: <FormattedMessage id="connections.create.step-x.button-next-text" />
    },
    prev: () => {},
    prevButtonProps: {},
    renderPrev: false,
    renderNext: true
};

export default StepperAction;
