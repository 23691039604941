import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Tooltip, Spin } from "antd";
import { withRouter } from "react-router-dom";
import { Button } from "@ts-digital/arc/antd";

import { connectionsWithoutService } from "../../../../utils/constant";
import DateTime from "../../../datetime";
import ConnectionServiceTitle from "./connection-service-title";

export class ConnectionElement extends React.Component {
    static propTypes = {
        agyoServices: PropTypes.array,
        certification: PropTypes.object.isRequired,
        connection: PropTypes.object.isRequired,
        getCertification: PropTypes.func.isRequired,
        focus: PropTypes.string,
        history: PropTypes.object.isRequired,
        isAllowedToManage: PropTypes.bool,
        itemServicesEnded: PropTypes.bool,
        match: PropTypes.object.isRequired,
        services: PropTypes.array.isRequired,
        settingsPageEnabled: PropTypes.bool,
        showDeleteConfirm: PropTypes.func.isRequired
    };

    static defaultProps = {
        services: []
    };

    getServiceStatus() {
        const { isAllowedToManage } = this.props;

        return !isAllowedToManage ? "not-allowed" : this.isServiceActive();
    }

    isServiceActive() {
        const { connection, services } = this.props;

        return services && services.find(v => v.agyoService && v.agyoService.id === connection.serviceId) !== undefined
            ? "active"
            : "not-active";
    }

    isSettingButtonDisabled() {
        const { connection } = this.props;

        const isButtonDisabled =
            !connection.status.active &&
            connection.status.status !== "UNVERIFIED" &&
            connection.status.status !== "VALIDATION_REJECTED";

        const isButtonDisabledWhenDeleted =
            connection.appId !== "EIP" ||
            connection.status.certificationStatus === "CERTIFIED" ||
            !["VALIDATED", "UNVERIFIED", "VALIDATION_REJECTED"].includes(connection.status.status);

        return connection.status.deleted ? isButtonDisabledWhenDeleted : isButtonDisabled;
    }

    render() {
        const {
            agyoServices,
            certification,
            connection,
            getCertification,
            history,
            itemServicesEnded,
            match,
            settingsPageEnabled,
            showDeleteConfirm
        } = this.props;

        const connectionHasService = !connectionsWithoutService.includes(connection.serviceId);

        return (
            <div className={"c-connection-item"}>
                <div className={"connection-last-update"}>
                    {connection.status.deleted && (
                        <div>
                            <FormattedMessage
                                id="c-connection-list.deletedAt"
                                values={{
                                    date: <DateTime value={connection.status.deletedAt} displayTime={false} />
                                }}
                            />
                        </div>
                    )}
                    <div>
                        <FormattedMessage
                            id="c-connection-list.latest-edit"
                            values={{
                                date: <DateTime value={connection.status.modifiedAt} displayTime={false} />
                            }}
                        />
                    </div>
                    {!settingsPageEnabled && (
                        <React.Fragment>
                            <div>
                                <FormattedMessage
                                    id="c-connections-settings-summary.specific.created-time"
                                    values={{
                                        creationDate: (
                                            <DateTime value={connection.status.createdAt} displayTime={false} />
                                        )
                                    }}
                                />
                            </div>
                            <div>
                                <FormattedMessage
                                    id="c-connections-settings-summary.specific.activated-time"
                                    values={{
                                        activationDate: (
                                            <DateTime value={connection.status.activatedAt} displayTime={false} />
                                        )
                                    }}
                                />
                            </div>
                        </React.Fragment>
                    )}

                    <ConnectionServiceTitle
                        circleStatus={itemServicesEnded ? this.getServiceStatus() : undefined}
                        displayCircle={!connection.status.deleted && connectionHasService}
                        serviceName={
                            agyoServices
                                ? agyoServices.find(i => i.id === connection.serviceId).description
                                : connection.appId + "." + connection.featureCode
                        }
                    />
                </div>
                <div className={"connection-status-label"}>
                    <FormattedMessage id={`c-connection-list.status`} />
                </div>
                <div>
                    <div className={"connection-status"}>
                        <FormattedMessage
                            id={`c-connection-list.status.${
                                connection.status.status === "VALIDATED" && connection.status.certificationStatus
                                    ? connection.status.certificationStatus
                                    : connection.status.status
                            }`}
                        />
                    </div>
                    <div
                        className={"connection-info"}
                        style={{
                            display: connection.status.status === "VALIDATION_REJECTED" ? "initial" : "none"
                        }}
                    >
                        <Tooltip
                            placement="right"
                            title={
                                certification.certificationInfo && certification.certificationInfo.status.started ? (
                                    <Spin spinning={true} />
                                ) : (
                                    <FormattedMessage
                                        id="c-connections-settings-summary.specific.connection-message"
                                        values={{
                                            msg:
                                                certification.certificationInfo &&
                                                certification.certificationInfo[connection.id]
                                                    ? certification.certificationInfo[connection.id].message
                                                    : ""
                                        }}
                                    />
                                )
                            }
                        >
                            <i
                                className="fas fa-info-circle"
                                onMouseEnter={event => {
                                    if (connection) {
                                        if (
                                            !certification.certificationInfo ||
                                            (certification.certificationInfo &&
                                                !certification.certificationInfo[connection.id])
                                        ) {
                                            getCertification(connection.id);
                                        }
                                    }
                                }}
                            />
                        </Tooltip>
                    </div>
                </div>
                <div>
                    {settingsPageEnabled && (
                        <Button
                            className="connection-button"
                            disabled={this.isSettingButtonDisabled()}
                            onClick={() => history.push(`/${match.params.item}/connection/${connection.id}`)}
                            variant="primary"
                            key={`${connection.id}-settings`}
                        >
                            <FormattedMessage id="c-connections-settings" />
                        </Button>
                    )}
                </div>
                <div>
                    <Button
                        className="connection-button"
                        onClick={() => showDeleteConfirm(connection.id)}
                        disabled={connection.status.deleted}
                        key={`${connection.id}-delete`}
                        variant="secondary"
                    >
                        <FormattedMessage id="c-connections-delete" />
                    </Button>
                </div>
            </div>
        );
    }
}

export default withRouter(ConnectionElement);
