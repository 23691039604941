import { fieldRequired } from "@ts-digital/agyo-validations";
import combineValidators from "@ts-digital/agyo-validations/lib/utils/combineValidators";
import { EmailValidator } from "commons-validator-js";

import { translateMessage } from "../../i18n";

export const ncsChannelEmail = {
    type: "object",
    properties: {
        name: {
            type: "string",
            validate: fieldRequired(translateMessage("general.form.field-required"))
        },
        value: {
            type: "string",
            validate: combineValidators(
                fieldRequired(translateMessage("general.form.field-required")),
                value =>
                    !new EmailValidator().isValid(value ? value : "") &&
                    translateMessage("general.form.email-required"),

                (value, formValues) =>
                    formValues.existingRecipients.find(recipient => recipient === value) &&
                    translateMessage("general.form.email-already-exists")
            )
        }
    }
};
