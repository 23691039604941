import React from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import Form from "antd/lib/form";
import { FormattedMessage } from "react-intl";
import { field } from "@ts-digital/conv-redux-form";

import { Switch } from "antd";

/* FIXME Implementa e importa componente da designsystem */
export class WrappedSwitch extends React.PureComponent {
    render() {
        const { disabled } = this.props;

        return (
            <Form.Item>
                <Switch
                    checkedChildren={<FormattedMessage id="general.yes" />}
                    unCheckedChildren={<FormattedMessage id="general.no" />}
                    checked={this.props.input.value}
                    onChange={evt => {
                        this.props.input.onChange(evt);
                    }}
                    disabled={disabled}
                />
            </Form.Item>
        );
    }
}

class PrivacySwitch extends React.PureComponent {
    static propTypes = { name: PropTypes.string.isRequired };
    render() {
        return <Field {...this.props} component={WrappedSwitch} />;
    }
}

export default field({ type: "boolean" })(PrivacySwitch);
