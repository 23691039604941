import { fieldRequired, fieldLength } from "@ts-digital/agyo-validations";
import combineValidators from "@ts-digital/agyo-validations/lib/utils/combineValidators";

import { addressSchema } from "./address";
import { translateMessage, translateMessageWithParams } from "../../i18n";

export const cctCredentialsSchema = {
    type: "object",
    properties: {
        ...addressSchema.properties,
        cctUser: {
            type: "string",
            validate: desc =>
                combineValidators(
                    fieldRequired(translateMessage("general.form.field-required")),
                    fieldLength(
                        translateMessageWithParams("general.form.field-length-range", { min: 2, max: 255 }),
                        2,
                        255
                    )
                )(desc)
        },
        cctPass: {
            type: "string",
            validate: desc =>
                combineValidators(
                    fieldRequired(translateMessage("general.form.field-required")),
                    fieldLength(
                        translateMessageWithParams("general.form.field-length-range", { min: 2, max: 255 }),
                        2,
                        255
                    )
                )(desc)
        }
    }
};
