import React, { Component } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import { toNumber } from "../../../../utils/thrift";
import EditForm from "../edit-form";
import PersonEditForm from "../edit-form-person";
import PageTitle from "../../../general/page-title";
import { EditContainer } from "./styled";

class Edit extends Component {
    static propTypes = {
        company: PropTypes.object,
        editable: PropTypes.bool,
        history: PropTypes.object,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func.isRequired,
        status: PropTypes.object
    };

    getInitialValues() {
        const { company, logo, logoFinishedLoading } = this.props;

        return {
            capitalStock: company.base.details.economics.capitalStock,
            cciaa: company.base.details.economics.cciaa,
            city: company.base.details.addresses[0].city,
            country: company.base.details.addresses[0].country,
            description: company.base.details.description,
            enableConsole: company.preferences.enableConsole,
            liquidationState: company.base.details.economics.liquidationState,
            logo,
            logoFinishedLoading,
            name: company.base.details.description,
            province: company.base.details.addresses[0].province,
            rea: company.base.details.economics.rea,
            streetName: company.base.details.addresses[0].streetName,
            streetNumber: company.base.details.addresses[0].streetNumber,
            taxRegime: company.base.details.economics.taxRegime,
            zipCode: company.base.details.addresses[0].zipCode,
            soleShareholder: company.base.details.economics.soleShareholder,
            economicActivity:
                company.base.details.economics.economicActivities &&
                company.base.details.economics.economicActivities.mainActivity
                    ? company.base.details.economics.economicActivities.mainActivity.code
                    : "",
            registerDescription: company.base.details.professionalRegister.description,
            registerCode: company.base.details.professionalRegister.code,
            registerProvince: company.base.details.professionalRegister.province,
            registerRegistrationDate: toNumber(company.base.details.professionalRegister.registrationDate),
            balanceSheetDate: toNumber(company.base.details.economics.balanceSheetDate),
            privacyFlag: company.preferences.hidden,
            legalForm: company.base.details.legalForm && company.base.details.legalForm.code
        };
    }

    getPersonInitialValues() {
        const { company } = this.props;

        return {
            city: company.base.details.addresses ? company.base.details.addresses[0].city : "",
            country: company.base.details.addresses ? company.base.details.addresses[0].country : "",
            enableConsole: company.preferences.enableConsole,
            firstName: company.base.details.firstName,
            lastName: company.base.details.lastName,
            province: company.base.details.addresses ? company.base.details.addresses[0].province : "",
            streetName: company.base.details.addresses ? company.base.details.addresses[0].streetName : "",
            streetNumber: company.base.details.addresses ? company.base.details.addresses[0].streetNumber : "",
            zipCode: company.base.details.addresses ? company.base.details.addresses[0].zipCode : "",
            privacyFlag: company.preferences.hidden
        };
    }

    render() {
        const {
            company,
            editable,
            handleDeleteLogo,
            handleUploadLogo,
            history,
            logoEdit,
            onCancel,
            onSubmit,
            uploadLogoStatus,
            resetDeleteLogo,
            resetUploadLogo,
            deleteLogoStatus,
            status,
            user
        } = this.props;

        return (
            <>
                <PageTitle
                    title={
                        <FormattedMessage id={`c-manage-data.company.modal.${editable ? "edit" : "display"}.title`} />
                    }
                    onBack={() => history.goBack()}
                />
                <EditContainer>
                    {company.base.details.classifier === "PERSON" ? (
                        <PersonEditForm
                            taxId={company.base.identifier.taxId}
                            cancel={() => onCancel()}
                            disabled={!editable}
                            initialValues={this.getPersonInitialValues()}
                            isLoading={status.started && !status.ended}
                            onSubmit={onSubmit}
                        />
                    ) : (
                        <EditForm
                            logoEdit={logoEdit}
                            taxId={company.base.identifier.taxId}
                            vatNumber={company.base.identifier.vatNumber}
                            cancel={() => onCancel()}
                            disabled={!editable}
                            initialValues={this.getInitialValues()}
                            isLoading={status.started && !status.ended}
                            onSubmit={onSubmit}
                            handleDeleteLogo={handleDeleteLogo}
                            handleUploadLogo={handleUploadLogo}
                            uploadLogoStatus={uploadLogoStatus}
                            uuid={company.base.uuid}
                            classifier={company.base.details.classifier}
                            resetDeleteLogo={resetDeleteLogo}
                            resetUploadLogo={resetUploadLogo}
                            deleteLogoStatus={deleteLogoStatus}
                            user={user}
                        />
                    )}
                </EditContainer>
            </>
        );
    }
}

export default Edit;
