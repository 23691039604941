import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { getCctConfigManaged, enableCctConfigManaged, disableCctConfigManaged } from "../../../../actions/services/cct";
import { getItemApps } from "../../../../actions/services/app";
import PageTitle from "../../../../components/general/page-title";
import ConfigureManagedItemsPersonal from "../../../../components/storage/cct/configure-managed-items-personal";

export const ConfiguredManagedItemsPersonalView = ({
    cctConfigManaged,
    cctConfigManagedLoading,
    getCctConfigManaged,
    enableCctConfigManaged,
    disableCctConfigManaged,
    match,
    history
}) => {
    const ownerId = match.params.item;
    const [page, setPage] = useState(0);
    const [filters, setFilters] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);

    const handlePageChange = useCallback(
        nextPage => {
            setPage(nextPage);
            getCctConfigManaged(ownerId, null, null, nextPage, null);
        },
        [getCctConfigManaged, ownerId]
    );

    const handleRefresh = useCallback(() => {
        setPage(0);
        getCctConfigManaged(ownerId, null, null, null, null);
        setSelectedRows([]);
    }, [getCctConfigManaged, ownerId]);

    const handleFilterChange = useCallback(
        value => {
            const { itemId, active } = value;
            getCctConfigManaged(ownerId, itemId, active, null, null);
            setFilters(value);
            setSelectedRows([]);
        },
        [getCctConfigManaged, ownerId]
    );

    const handleDisableService = useCallback(
        (ownerId, itemIds) => {
            disableCctConfigManaged(ownerId, itemIds);
            setSelectedRows([]);
        },
        [disableCctConfigManaged]
    );

    const handleEnableService = useCallback(
        (ownerId, itemIds) => {
            enableCctConfigManaged(ownerId, itemIds);
            setSelectedRows([]);
        },
        [enableCctConfigManaged]
    );

    const handleSelectRow = useCallback(
        (checked, row) => {
            const itemId = row.original.itemId;
            setSelectedRows(checked ? [...selectedRows, itemId] : selectedRows.filter(e => e !== itemId));
        },
        [setSelectedRows, selectedRows]
    );

    const handleSelectAllRow = useCallback(
        checked => {
            const configs = cctConfigManaged ? cctConfigManaged.configs : [];
            setSelectedRows(checked ? configs.map(a => a.itemId) : []);
        },
        [setSelectedRows, cctConfigManaged]
    );

    useEffect(() => {
        getCctConfigManaged(ownerId, null, null, null, null);
    }, [ownerId, getCctConfigManaged]);
    return (
        <>
            <PageTitle
                title={<FormattedMessage id="c-services-view.ARC.name" />}
                subtitle={<FormattedMessage id="c-cct-item-managed-items-personal" />}
                onBack={() => history.goBack()}
            />
            <ConfigureManagedItemsPersonal
                page={page}
                loading={cctConfigManagedLoading}
                cctConfigManaged={cctConfigManaged}
                onPageChange={handlePageChange}
                onDisableService={handleDisableService}
                onEnableService={handleEnableService}
                onRefresh={handleRefresh}
                onSelectRow={handleSelectRow}
                onSelectAllRow={handleSelectAllRow}
                onFilterChange={handleFilterChange}
                filters={filters}
                selectedRows={selectedRows}
            />
        </>
    );
};

ConfiguredManagedItemsPersonalView.propTypes = {
    // Passed down from Route
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,

    // Obtained from redux state
    provisioningInfo: PropTypes.object
};

const mapStateToProps = (state, { match }) => {
    return {
        item: state.companies.data[match.params.item] ? state.companies.data[match.params.item].item : {},
        itemApps: state.services.itemApps,
        provisioningInfoStatus: state.services.cct.provisioningInfo.isLoading,
        cctConfigManaged: state.services.cct.getCctConfigManaged.cctConfigManaged,
        cctConfigManagedLoading: state.services.cct.getCctConfigManaged.status.started,
        user: state.user.user
    };
};

export default connect(mapStateToProps, {
    getCctConfigManaged,
    enableCctConfigManaged,
    disableCctConfigManaged,
    getItemApps
})(ConfiguredManagedItemsPersonalView);
