import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";

import "../style.css";

import Accounting from "../../../components/accounting";
import { errorBoundary } from "../../../components/general/error-boundary";
import PageTitle from "../../../components/general/page-title";

import { getWaitingList } from "../../../actions/waitinglist/read";
import { subscribeWaitinglist, deleteWaitinglist } from "../../../actions/waitinglist/write";

export const AccountingView = ({
    company,
    companyId,
    history,
    services,
    userRoles,
    getWaitingList,
    getWaitingListStatus,
    subscribeWaitinglist,
    deleteWaitinglist,
    waitinglistDeleteStatus,
    waitinglist,
    waitinglistSubscribeStatus,
    user
}) => (
    <div>
        <PageTitle title={<FormattedMessage id="c-services-view-accounting" />} onBack={() => history.goBack()} />
        <Accounting
            company={company}
            companyId={companyId}
            roles={userRoles}
            services={services}
            getWaitingList={getWaitingList}
            getWaitingListStatus={getWaitingListStatus}
            subscribeWaitinglist={subscribeWaitinglist}
            deleteWaitinglist={deleteWaitinglist}
            waitinglistDeleteStatus={waitinglistDeleteStatus}
            waitinglist={waitinglist}
            waitinglistSubscribeStatus={waitinglistSubscribeStatus}
            user={user}
        />
    </div>
);

AccountingView.propTypes = {
    companyId: PropTypes.string.isRequired,
    services: PropTypes.object.isRequired,
    userRoles: PropTypes.array.isRequired
};

const mapStateToProps = (state, props) => ({
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    companyId:
        state.companies.data[props.match.params.item] &&
        state.companies.data[props.match.params.item].item &&
        state.companies.data[props.match.params.item].item.base &&
        state.companies.data[props.match.params.item].item.base.id,
    services: state.services,
    userRoles: state.user && state.user.user && state.user.user.roles,
    waitinglist: state.waitinglist.read.getWaitingList.waitinglist,
    waitinglistSubscribeStatus: state.waitinglist.write.subscribeWaitinglist.status,
    waitinglistDeleteStatus: state.waitinglist.write.deleteWaitinglist.status,
    getWaitingListStatus: state.waitinglist.read.getWaitingList.status,
    user: state.user
});

const composedHoc = compose(
    connect(mapStateToProps, { getWaitingList, subscribeWaitinglist, deleteWaitinglist }),
    errorBoundary
);

export default composedHoc(AccountingView);
