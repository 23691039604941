import React from "react";
import { METERING_API_URL } from "../../../../../config";
import { getRestCall } from "../../../../../utils/rest-api-call";
import { displayErrorToast } from "../../../../overflowNotification";
import { FormattedMessage } from "react-intl";
import { downloadBase64 } from "../../../../../utils/download";
import { getUserProfile } from "../../../../../utils/state-utils/get-user-profile";
import { getAuthWithAppName } from "../../../../auth";

export const GET_BILLING_USAGE_DETAILS_CSV_START = "GET_BILLING_USAGE_DETAILS_CSV_START";
export const GET_BILLING_USAGE_DETAILS_CSV_END = "METERING_GET_BILLING_USAGE_DETAILS_CSV_END";
export const GET_BILLING_USAGE_DETAILS_CSV_SUCCESS = "METERING_GET_BILLING_USAGE_DETAILS_CSV_SUCCESS";
export const GET_BILLING_USAGE_DETAILS_CSV_ERROR = "METERING_GET_BILLING_USAGE_DETAILS_CSV_ERROR";

export const getBillingUsageDetailsCsvStart = () => ({
    type: GET_BILLING_USAGE_DETAILS_CSV_START
});

export const getBillingUsageDetailsCsvEnd = () => ({
    type: GET_BILLING_USAGE_DETAILS_CSV_END
});

export const getBillingUsageDetailsCsvSuccess = () => ({
    type: GET_BILLING_USAGE_DETAILS_CSV_SUCCESS
});

export const getBillingUsageDetailsCsvError = () => ({
    type: GET_BILLING_USAGE_DETAILS_CSV_ERROR
});

export const getBillingUsageDetailsCsv =
    (userId, item, managed, from, to, viewOldBillingCycles) => async (dispatch, getState) => {
        dispatch(getBillingUsageDetailsCsvStart());
        try {
            const auth = getAuthWithAppName(getState().auth);
            const base64Csv = await getRestCall(
                `${METERING_API_URL}/api/v1/usage/invoicing/${item}/details/csv`,
                auth,
                {
                    managed,
                    userId,
                    from,
                    to,
                    viewOldBillingCycles
                },
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                item
            );
            downloadBase64(base64Csv, "dettagli.csv");
            dispatch(getBillingUsageDetailsCsvSuccess());
        } catch (error) {
            const displayError = displayErrorToast(<FormattedMessage id="metering.usage.details.csv.billing.error" />);
            displayError(dispatch);
            dispatch(getBillingUsageDetailsCsvError());
        }
        dispatch(getBillingUsageDetailsCsvEnd());
    };
