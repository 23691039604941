import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";

import PeopleApp from "../../../components/people-app";
import { errorBoundary } from "../../../components/general/error-boundary";
import PageTitle from "../../../components/general/page-title";

import "../style.css";

class ConsultantView extends React.Component {
    static propTypes = {
        company: PropTypes.object.isRequired,
        history: PropTypes.object,
        services: PropTypes.object.isRequired
    };

    render() {
        const { company, history, services } = this.props;
        return (
            <div className="service-view">
                <PageTitle title={<FormattedMessage id="c-services-view.PPL.name" />} onBack={() => history.goBack()} />
                <PeopleApp company={company} services={services} />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    services: state.services
});

const composedHoc = compose(connect(mapStateToProps), errorBoundary);

export default composedHoc(ConsultantView);
