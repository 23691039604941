import styled from "styled-components";

export const LinkContainer = styled.span`
    color: #1890ff;
    cursor: pointer;
    margin-left: 5px;
    transition: color 0.3s;

    &:hover {
        color: #40a9ff;
    }
`;

export const ExpiredCctCredentialsMessageContainer = styled.div`
    margin: 16px 16px 8px 16px;
`;

export const ResetCredentialButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
`;

export const TsPayWarningContainer = styled.div`
    margin-top: 18px;
    margin-bottom: 18px;
    margin-left: 8px;
`;

export const TsPayButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
`;
