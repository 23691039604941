import React from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";
import { FormattedMessage } from "react-intl";

import { MessageContainer, WarningContainer } from "./styled.js";

const ConnectionSettingsWarn = ({
    isAllowedToManage,
    isSpinning,
    managedAlreadyHasService,
    managerOwnsService,
    managerServiceIsActive,
    needsContract
}) => {
    return !isSpinning
        ? (!isAllowedToManage || managedAlreadyHasService || !managerOwnsService || !managerServiceIsActive) && (
              <WarningContainer>
                  <span>
                      <Icon type="warning" />
                  </span>
                  <MessageContainer>
                      <FormattedMessage
                          id={getMessage(
                              isAllowedToManage,
                              managedAlreadyHasService,
                              managerOwnsService,
                              managerServiceIsActive,
                              needsContract
                          )}
                      />
                  </MessageContainer>
              </WarningContainer>
          )
        : null;
};

const getMessage = (
    isAllowedToManage,
    managedAlreadyHasService,
    managerOwnsService,
    managerServiceIsActive,
    needsContract
) => {
    if (needsContract) {
        return "c-connection-settings.alert.needs-contract";
    } else if (!isAllowedToManage) {
        return "general.service.not-allowed";
    } else if (managedAlreadyHasService) {
        return "c-connections-settings.alert.managed-service-already-active";
    } else if (!managerServiceIsActive) {
        return "c-connections-settings.alert.manager-service-is-not-active";
    } else if (!managerOwnsService) {
        return "c-connections-settings-warn.cant-extend";
    }
};

ConnectionSettingsWarn.propTypes = {
    isAllowedToManage: PropTypes.bool,
    isSpinning: PropTypes.bool,
    managerServiceIsActive: PropTypes.bool,
    managerOwnsService: PropTypes.bool,
    managedAlreadyHasService: PropTypes.bool
};

export default ConnectionSettingsWarn;
