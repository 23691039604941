import React from "react";
import PropTypes from "prop-types";

import { form } from "@ts-digital/conv-redux-form";
import { Button } from "@ts-digital/vrc";
import { Tooltip } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { passwordSchema } from "../../../../utils/form-schema/password";
import "./style.css";
import { ErrorMessage, Label, StyledInput } from "./styled";

export const ResetPasswordForm = ({ errorMsg, handleSubmit, title }) => {
    const { formatMessage } = useIntl();

    return (
        <form onSubmit={handleSubmit}>
            <Tooltip
                title={
                    <div>
                        <div>
                            <FormattedMessage id={"c-change-password.confirm.tooltip1"} />
                        </div>
                        <div>
                            <FormattedMessage id={"c-change-password.confirm.tooltip2"} />
                        </div>
                        <div>
                            <FormattedMessage id={"c-change-password.confirm.tooltip3"} />
                        </div>
                        <div>
                            <FormattedMessage id={"c-change-password.confirm.tooltip4"} />
                        </div>
                        <div>
                            <FormattedMessage id={"c-change-password.confirm.tooltip5"} />
                        </div>
                    </div>
                }
                placement="top"
                overlayStyle={{ maxWidth: "600px" }}
                trigger={["focus"]}
            >
                <Label>
                    <FormattedMessage id="c-reset-password.newPassword.label" />
                </Label>
                <StyledInput
                    name="password"
                    placeholder={formatMessage({
                        id: "c-reset-password.password.placeholder"
                    })}
                    type="password"
                />
            </Tooltip>
            <Label>
                <FormattedMessage id="c-reset-password.confirmNewPassword.label" />
            </Label>
            <StyledInput
                name="confirmPassword"
                placeholder={formatMessage({
                    id: "c-reset-password.confirmPassword.placeholder"
                })}
                type="password"
            />
            <ErrorMessage>
                {errorMsg && <FormattedMessage id="c-change-password.confirm.error" values={{ errMsg: errorMsg }} />}
            </ErrorMessage>
            <Button kind="primary" htmlType="submit" style={{ width: "100%" }}>
                <FormattedMessage id="c-reset-password.confirm.button" />
            </Button>
        </form>
    );
};

ResetPasswordForm.propTypes = {
    errorMsg: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    title: PropTypes.element
};

ResetPasswordForm.defaultProps = {
    title: <FormattedMessage id={"c-reset-password.title"} />
};

const formDefinition = {
    form: "reset-password-form",
    schema: passwordSchema
};

const formDef = form(formDefinition);

export default formDef(ResetPasswordForm);
