import styled from "styled-components";

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Title = styled.div`
    font-family: Cairo;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.18px;
`;

export const Date = styled.div`
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.4px;
    text-align: right;
    color: #5a6872;
`;

export const Content = styled.div`
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.4px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-content: center;

    & > :last-child {
        margin-left: 8px;
    }
`;
