import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import { ENABLE_GOV_CODE } from "../../../config";

import { InfoContainer } from "./styled";

class Info extends Component {
    static propTypes = {
        info: PropTypes.object,
        type: PropTypes.string
    };

    render() {
        const { info, type } = this.props;
        let infoData = info.base;

        let address = infoData.details.addresses === null ? "" : infoData.details.addresses[0].fullAddress;

        return (
            <InfoContainer>
                <div>
                    <FormattedMessage id="general.id" />
                    <span>{infoData.id}</span>
                </div>
                <div>
                    <FormattedMessage id="general.description" />
                    <span>{infoData.details.description}</span>
                </div>
                <div>
                    <FormattedMessage id="general.address" />
                    <span>{address}</span>
                </div>
                <div>
                    <FormattedMessage id="c-info.status" />
                    <span>
                        {infoData.inCreation ? (
                            <FormattedMessage id="c-info.creating" />
                        ) : infoData.status.active ? (
                            <FormattedMessage id="c-info.active" />
                        ) : (
                            <FormattedMessage id="c-info.not-active" />
                        )}
                    </span>
                </div>
                {type !== "intermediary" && ENABLE_GOV_CODE === "enable" && (
                    <div>
                        <FormattedMessage id="general.govCode" />
                        <span>{infoData.identifier.govCode}</span>
                    </div>
                )}
                <div>
                    <FormattedMessage id="general.creation-date" />
                    <span>{moment(new Date(Number(infoData.status.createdAt))).format("DD/MM/YYYY")}</span>
                </div>
                <div>
                    <FormattedMessage id="general.modify-date" />
                    <span>
                        {infoData.status.modifiedAt !== 0 && infoData.status.modifiedAt === infoData.status.createdAt
                            ? "-"
                            : moment(new Date(Number(infoData.status.modifiedAt))).format("DD/MM/YYYY")}
                    </span>
                </div>
            </InfoContainer>
        );
    }
}

export default Info;
