import React from "react";

import { FormattedMessage } from "react-intl";

import { Button } from "@ts-digital/vrc";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/pro-regular-svg-icons";
import { Input, Spin, Row, Col } from "antd";

import {
    MarginIcon,
    LoadingIcon,
    Chip,
    Container,
    ChipsTitleContainer,
    ChipsContent,
    UpdateSearchContainer,
    SearchTitleContainer,
    UpdateContainer
} from "./styled";

const Search = Input.Search;

export const CctFiltersBar = ({ onFilterChange, loading, onRefresh, options, filters }) => {
    const makeOption = (opt, idx) => {
        if (opt.filterType === "any") {
            return (
                <Chip
                    selected={
                        filters["status"] === undefined &&
                        filters["isActive"] === undefined &&
                        filters["rdcToBeFixed"] === undefined
                            ? true
                            : false
                    }
                    onClick={() => onFilterChange({})}
                    key={idx}
                >
                    {opt.label}
                </Chip>
            );
        } else {
            return (
                <Chip
                    selected={filters[opt.filterType] === opt.value ? true : false}
                    onClick={() => onFilterChange({ [opt.filterType]: opt.value })}
                    key={idx}
                >
                    {opt.label}
                </Chip>
            );
        }
    };

    return (
        <Container>
            <Row gutter={16} type="flex" align="middle">
                <Col xl={{ span: 14 }}>
                    <ChipsTitleContainer>
                        <MarginIcon icon={faFilter} />
                        <FormattedMessage id="c-cct-status.filters" />
                    </ChipsTitleContainer>
                    <ChipsContent>{options.map(makeOption)}</ChipsContent>
                </Col>
                <Col xl={{ span: 10 }}>
                    <UpdateSearchContainer>
                        <UpdateContainer>
                            <Button
                                kind="tertiary"
                                onClick={() => {
                                    onRefresh();
                                }}
                            >
                                <Spin indicator={<LoadingIcon type="loading" />} spinning={loading} />
                                <MarginIcon icon={faRedo} />
                                <FormattedMessage id="general.update" />
                            </Button>
                        </UpdateContainer>
                        <div>
                            <SearchTitleContainer>
                                <FormattedMessage id="c-cct-status.cf-search" />
                            </SearchTitleContainer>
                            <Search
                                enterButton
                                style={{ width: "300px" }}
                                size="large"
                                onSearch={value => onFilterChange({ itemTaxId: value ? [value] : null })}
                            />
                        </div>
                    </UpdateSearchContainer>
                </Col>
            </Row>
        </Container>
    );
};
