import React, { Component } from "react";
import ReactSVG from "react-svg";
import { Icon } from "antd";
import { FormattedMessage } from "react-intl";

import { PUBLIC_URL } from "../../config";

import { Content, MaintenancePageContainer, Title } from "./styled";

class Maintenance extends Component {
    render() {
        return (
            <MaintenancePageContainer>
                <Title>
                    <Icon type="info-circle" theme="filled" />
                    <FormattedMessage id="c-maintenance.title" />
                </Title>
                <Content>
                    <FormattedMessage id="c-maintenance.text" />
                </Content>
                <ReactSVG src={`${PUBLIC_URL}/maintenance_img.svg`} />
            </MaintenancePageContainer>
        );
    }
}

export default Maintenance;
