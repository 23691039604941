import React from "react";
import PropTypes from "prop-types";

import Initials from "../../general/user-initials";
import {
    FullName,
    InitialsContainer,
    Status,
    StatusContainer,
    StatusIcon,
    UserEmail,
    UserInfoContainer,
    UserInfoWrapper
} from "./styled";
import { FormattedMessage } from "react-intl";
import { faCheckCircle, faClock } from "@fortawesome/free-solid-svg-icons";
import { Skeleton } from "antd";

/**
 * Displays basic user data (a circle with initials, full name, email and activation status)
 */
const UserData = ({ active, email, fullName, loading }) => {
    return (
        <UserInfoWrapper>
            <InitialsContainer>
                <Initials size={"56px"} loading={loading} name={fullName || ""} fontSize={"24px"} newComponent={true} />
            </InitialsContainer>
            <UserInfoContainer>
                <FullName>
                    <Skeleton active={true} loading={loading} title={{ width: 300 }} paragraph={false}>
                        {fullName}
                    </Skeleton>
                </FullName>
                <UserEmail>
                    <Skeleton active={true} loading={loading} paragraph={{ rows: 1, width: 200 }} title={false}>
                        {email}
                    </Skeleton>
                </UserEmail>
                <Skeleton active={true} loading={loading} paragraph={{ rows: 1, width: 200 }} title={false}>
                    <StatusContainer>
                        <StatusIcon icon={active ? faCheckCircle : faClock} active={`${active}`} />
                        <Status>
                            <FormattedMessage id={`c-registry-user.user-${active ? "active" : "not-active"}`} />
                        </Status>
                    </StatusContainer>
                </Skeleton>
            </UserInfoContainer>
        </UserInfoWrapper>
    );
};

UserData.propTypes = {
    /** activation status of the user */
    active: PropTypes.bool,
    /** user email */
    email: PropTypes.string,
    /** full name (first name + last name) of the user */
    fullName: PropTypes.string,
    /** Indicates if the user data is still being loaded */
    loading: PropTypes.bool
};

export default UserData;
