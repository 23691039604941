import {
    GENERATE_ACT_OF_ENTRUSTMENT_ERROR,
    GENERATE_ACT_OF_ENTRUSTMENT_RESET,
    GENERATE_ACT_OF_ENTRUSTMENT_START,
    GENERATE_ACT_OF_ENTRUSTMENT_SUCCESS,
    GENERATE_CONTRACT_START,
    GENERATE_CONTRACT_ERROR,
    GENERATE_CONTRACT_SUCCESS,
    UPLOAD_CERT_START,
    UPLOAD_CERT_SUCCESS,
    UPLOAD_CERT_ERROR,
    UPLOAD_CERT_RESET,
    CERTIFICATION_INFO_START,
    CERTIFICATION_INFO_ERROR,
    CERTIFICATION_INFO_SUCCESS,
    UPLOAD_ATTO_ERROR,
    UPLOAD_ATTO_START,
    UPLOAD_ATTO_SUCCESS,
    GENERATE_CONTRACT_RESET
} from "../actions/certification.js";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function tscontract(state = defaultState, { type, payload }) {
    switch (type) {
        case GENERATE_CONTRACT_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GENERATE_CONTRACT_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                data: payload
            };
        case GENERATE_CONTRACT_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: payload.code, message: payload.message }
                }
            };
        case GENERATE_CONTRACT_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export function actOfEntrustment(state = defaultState, { type, payload }) {
    switch (type) {
        case GENERATE_ACT_OF_ENTRUSTMENT_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GENERATE_ACT_OF_ENTRUSTMENT_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                data: payload
            };
        case GENERATE_ACT_OF_ENTRUSTMENT_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: payload.code, message: payload.message }
                }
            };
        case GENERATE_ACT_OF_ENTRUSTMENT_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export function uploadCert(state = defaultState, { type, payload, error }) {
    switch (type) {
        case UPLOAD_CERT_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case UPLOAD_CERT_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                }
            };
        case UPLOAD_CERT_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case UPLOAD_CERT_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export function uploadAtto(state = defaultState, { type, payload, error }) {
    switch (type) {
        case UPLOAD_ATTO_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case UPLOAD_ATTO_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                }
            };
        case UPLOAD_ATTO_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false
                }
            };
        default:
            return state;
    }
}

export function certificationInfo(state = defaultState, { type, payload, error }) {
    switch (type) {
        case CERTIFICATION_INFO_START:
            return {
                ...state,
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case CERTIFICATION_INFO_SUCCESS:
            return {
                ...state,
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case CERTIFICATION_INFO_ERROR:
            return {
                ...state,
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case UPLOAD_CERT_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}
