import {
    GET_NCS_CHANNELS_LIST_START,
    GET_NCS_CHANNELS_LIST_ERROR,
    GET_NCS_CHANNELS_LIST_SUCCESS
} from "../../../actions/ncs/read";

import {
    DELETE_NCS_SINGLE_CHANNEL_START,
    DELETE_NCS_SINGLE_CHANNEL_SUCCESS,
    DELETE_NCS_SINGLE_CHANNEL_ERROR,
    UPDATE_NCS_SINGLE_CHANNEL_START,
    UPDATE_NCS_SINGLE_CHANNEL_SUCCESS,
    UPDATE_NCS_SINGLE_CHANNEL_ERROR
} from "../../../actions/ncs/write";

const defaultState = {
    status: {
        started: false,
        updating: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    },
    data: []
};

export const channels = (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case GET_NCS_CHANNELS_LIST_START:
            return {
                ...state,
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_NCS_CHANNELS_LIST_SUCCESS:
            return {
                ...state,
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                data: payload
            };
        case GET_NCS_CHANNELS_LIST_ERROR:
            return {
                ...state,
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case UPDATE_NCS_SINGLE_CHANNEL_START:
            return {
                ...state,
                status: {
                    started: false,
                    updating: true,
                    error: false,
                    ended: false
                }
            };
        case UPDATE_NCS_SINGLE_CHANNEL_SUCCESS:
            const existingChannel = state.data.find(channel => channel.id === payload.id);
            return {
                ...state,
                status: {
                    started: false,
                    updating: false,
                    error: false,
                    ended: true
                },
                data: existingChannel
                    ? state.data.map(channel => {
                          return existingChannel.id === channel.id
                              ? { ...channel, recipients: payload.recipients }
                              : channel;
                      })
                    : [...state.data, payload]
            };
        case UPDATE_NCS_SINGLE_CHANNEL_ERROR:
            return {
                ...state,
                status: {
                    started: false,
                    updating: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case DELETE_NCS_SINGLE_CHANNEL_START:
            return {
                ...state,
                status: {
                    started: false,
                    updating: true,
                    error: false,
                    ended: false
                }
            };
        case DELETE_NCS_SINGLE_CHANNEL_SUCCESS:
            return {
                ...state,
                status: {
                    started: false,
                    updating: false,
                    error: false,
                    ended: true
                },
                data: state.data.filter(channel => channel.id !== payload.channelId)
            };
        case DELETE_NCS_SINGLE_CHANNEL_ERROR:
            return {
                ...state,
                status: {
                    started: false,
                    updating: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
};
