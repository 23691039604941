import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { compose } from "redux";

import { createOffice, updateOffice, deleteOffice } from "../../../actions/company/offices";
import { errorBoundary } from "../../../components/general/error-boundary";
import Intermediaries from "../../../components/registry/intermediaries";

import { ENABLE_INTERMEDIARIES_SETTING } from "../../../config";

import "../style.css";
import PageTitle from "../../../components/general/page-title";

class IntermediariesView extends React.Component {
    static propTypes = {
        company: PropTypes.object.isRequired,
        createOffice: PropTypes.func,
        createOfficeStatus: PropTypes.object,
        deleteOffice: PropTypes.func.isRequired,
        deleteOfficeStatus: PropTypes.object.isRequired,
        updateOffice: PropTypes.func.isRequired,
        updateOfficeStatus: PropTypes.object
    };

    render() {
        const {
            company,
            createOffice,
            createOfficeStatus,
            deleteOffice,
            deleteOfficeStatus,
            updateOffice,
            updateOfficeStatus
        } = this.props;
        return (
            <div className="registry-view">
                <PageTitle
                    title={<FormattedMessage id="general.intermediari" />}
                    onBack={() => this.props.history.goBack()}
                    subtitle={<FormattedMessage id="c-registry-view-intermediary" />}
                />
                <div className="text">
                    <FormattedMessage id="c-registry-view-intermediary" />
                </div>

                <Intermediaries
                    company={company}
                    createOffice={createOffice}
                    createOfficeStatus={createOfficeStatus}
                    deleteOffice={deleteOffice}
                    deleteOfficeStatus={deleteOfficeStatus}
                    updateOffice={updateOffice}
                    updateOfficeStatus={updateOfficeStatus}
                    enableCreation={ENABLE_INTERMEDIARIES_SETTING === "enable"}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    createOfficeStatus: state.offices.createOffice.status,
    deleteOfficeStatus: state.offices.deleteOffice.status,
    users: state.user.user,
    updateOfficeStatus: state.offices.updateOffice.status
});

const actions = {
    createOffice,
    deleteOffice,
    updateOffice
};

const composedHoc = compose(connect(mapStateToProps, actions), errorBoundary);

export default composedHoc(IntermediariesView);
