import React from "react";
import PropTypes from "prop-types";

import { Field } from "redux-form";
import { field } from "@ts-digital/conv-redux-form";
import { Select } from "antd";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import { CustomFormItem, CustomSelect } from "../styled";

const { Option } = Select;

export const WrappedComboboxes = ({ disabled = false, input, label, meta }) => {
    let daysOptions = [];

    const monthOptions = moment.months().map((month, index) => {
        const monthNumber = index + 1;
        const key = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;

        return (
            <Option key={key} value={key}>
                {month.charAt(0).toUpperCase() + month.slice(1)}
            </Option>
        );
    });

    let curMonth = input.value && input.value.length > 2 ? input.value.split("-")[1] : null;

    if (curMonth) {
        // selecting 2019 where feb has 28 days
        const numberOfDays = moment("2019-" + curMonth, "YYYY-MM").daysInMonth();

        let daysArray = [];
        for (let i = 0; i < numberOfDays; i++) {
            daysArray.push((i + 1).toString());
        }

        daysArray.forEach(day => {
            daysOptions.push(
                <Option key={day} value={day}>
                    {day}
                </Option>
            );
        });
    } else {
        curMonth = (new Date().getMonth() + 1).toString();
        curMonth = curMonth.length === 1 ? "0" + curMonth : curMonth;
    }

    return (
        <CustomFormItem
            label={label}
            colon={false}
            validateStatus={meta.touched && meta.error ? "error" : ""}
            help={meta.touched && meta.error ? meta.error : ""}
        >
            <CustomSelect
                disabled={disabled}
                placeholder={<FormattedMessage id="general.month" />}
                style={{ width: 150 }}
                onChange={val => {
                    input.onChange(`${new Date().getFullYear().toString()}-${val}`);
                }}
                value={
                    input.value.length >= 7 ? input.value.split("-")[1] : input.value.length === 5 ? null : undefined
                }
            >
                {monthOptions}
            </CustomSelect>
            <CustomSelect
                disabled={disabled}
                placeholder={<FormattedMessage id="general.day" />}
                style={{ width: 100, marginLeft: 10 }}
                value={
                    input.value.length === 10 ? input.value.split("-")[2] : input.value.length === 7 ? null : undefined
                }
                onChange={val => {
                    input.onChange(
                        `${new Date().getFullYear().toString()}-${input.value.split("-")[1]}-${
                            val.length === 1 ? "0" + val : val
                        }`
                    );
                }}
            >
                {daysOptions}
            </CustomSelect>
        </CustomFormItem>
    );
};

const DaySelect = props => {
    return <Field {...props} component={WrappedComboboxes} />;
};

DaySelect.propTypes = { name: PropTypes.string.isRequired, label: PropTypes.object };

export default field({ type: "string" })(DaySelect);
