import styled, { css } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`;

export const ItemsContainer = styled.div`
    max-height: 400px;
    overflow-y: auto;

    @media (max-width: 1280px) {
        padding-bottom: 32px;
        margin-bottom: 24px;
    }
`;

export const Item = styled.div`
    height: 32px;
    line-height: 32px;
    border-radius: 0px;
    color: ${props => props.theme.colors.text.diamond};
    font-size: 14px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.25px;
    padding: 1px;
    padding-left: 32px;
    padding-right: 8px;
    cursor: pointer;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative;

    :hover {
        border: 1px solid ${props => props.theme.colors.primary.richElectricBlue};
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 31px;
    }

    ${props =>
        props.selected &&
        css`
            color: ${props => props.theme.colors.primary.richElectricBlue};
            font-weight: 500;
            background-color: ${props => props.theme.colors.secondary.azure};
            border-left: 4px solid ${props => props.theme.colors.primary.richElectricBlue};
            padding-left: 28px;

            :hover {
                padding-left: 28px;
                border-left-width: 4px;
            }
        `}
`;

export const ClassifierLabel = styled.div`
    color: var(--text-title, #004e75);
    font-family: Cairo;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
