import React from "react";

import { Radio } from "antd";
import { Field } from "redux-form";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

import FormRadio from "../../general/form-radio";

import { InformativeContainer, Label } from "./styled";

const DataConsent = ({ disabled }) => (
    <div>
        <InformativeContainer>
            <FormattedHTMLMessage id="c-data-consent-form.informative-text" />
        </InformativeContainer>
        <div>
            <Label>
                <FormattedMessage id="c-data-consent-form.ts-notification-consent" />
            </Label>
            <Field component={FormRadio} name="notificationConsent" label={""}>
                <Radio disabled={disabled} value={true}>
                    <FormattedMessage id="c-data-consent-form.accept" />
                </Radio>
                <Radio disabled={disabled} value={false}>
                    <FormattedMessage id="c-data-consent-form.refuse" />
                </Radio>
            </Field>
        </div>
        <div>
            <Label>
                <FormattedMessage id="c-data-consent-form.ts-third-party-notification-consent" />
            </Label>
            <Field component={FormRadio} name="thirdPartyNotificationConsent" label={""}>
                <Radio disabled={disabled} value={true}>
                    <FormattedMessage id="c-data-consent-form.accept" />
                </Radio>
                <Radio disabled={disabled} value={false}>
                    <FormattedMessage id="c-data-consent-form.refuse" />
                </Radio>
            </Field>
        </div>
    </div>
);

export default DataConsent;
