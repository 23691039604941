import React from "react";
import PropTypes from "prop-types";

import "./style.css";

import { Button, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";

import StatusTag from "../../general/status-tag";

const ConnectionOption = ({ elementName, validatedMessage, isLinked, linkedItemsSearchEnded }) =>
    linkedItemsSearchEnded && (
        <div className="c-option-container">
            <div className="c-option-name-container">
                <div className="name">{elementName}</div>
                {isLinked ? (
                    <div className="c-option-name-container name-label">
                        <StatusTag label="c-item.status.tag.item.connected" />
                    </div>
                ) : null}
            </div>
            <div className="c-option-action">
                <div className="c-option-button">
                    <Tooltip title={validatedMessage}>
                        {isLinked ? (
                            <Button type="secondary" disabled={validatedMessage !== null} block>
                                <FormattedMessage id="c-connections-filter-bar.view-connection" />
                            </Button>
                        ) : (
                            <Button type="primary" disabled={validatedMessage !== null} block>
                                <FormattedMessage id="c-connection-item-add" />
                            </Button>
                        )}
                    </Tooltip>
                </div>
            </div>
        </div>
    );

ConnectionOption.propTypes = {
    elementName: PropTypes.string.isRequired,
    isLinked: PropTypes.bool.isRequired,
    linkedItemsSearchEnded: PropTypes.bool.isRequired
};
export default ConnectionOption;
