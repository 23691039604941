import styled from "styled-components";

// Due to a bug in the VRC checkbox component, the checkbox square shrinks down in width
// if the text is too long. Applying flex-shrink fixes the problem. The component doesn't accept
// additional classes and/or styles, so there is no direct way of doing it.
export const CheckboxContainer = styled.div`
    & input ~ div {
        flex-shrink: 0;
        margin-top: 5px;
    }
`;
