import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Button } from "@ts-digital/vrc";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "antd";

import FormCredentialsCct from "./credentials";
import { Divider, AsideImage } from "./styled";
import LoadingButton from "../../general/loading-button";
import { TS_DIGITAL_IMAGES } from "../../../config";
import NewCctFlowSection from "./new-cct-flow-section";
import OldCctFlowSection from "./old-cct-flow-section";
import Modal from "../../general/modal";

export const FormEditCct = ({
    cctConfig,
    companyStatus,
    credentialsChangeError,
    credentialsChangeLoading,
    itemApps,
    itemType,
    onCredentialsChanged,
    provisioningInfo,
    submitForm
}) => {
    const [modalOpen, setModalOpen] = useState(false);

    const handleCredentialsSubmit = useCallback(
        ({ cctPass, cctUser }) => {
            onCredentialsChanged(cctUser, cctPass);
        },
        [onCredentialsChanged]
    );

    useEffect(() => {
        if (!credentialsChangeLoading && !credentialsChangeError) {
            setModalOpen(false);
        }
    }, [credentialsChangeError, credentialsChangeLoading]);

    const cctUser = !cctConfig.agyoFepa && cctConfig.cctUser;

    const oldCctFlowApps = Object.values(itemApps.data).filter(a => a.cctSpecsConfig.oldFlow);

    return (
        <div>
            <Row gutter={32}>
                <Col xs={0} xl={5}>
                    <AsideImage src={`${TS_DIGITAL_IMAGES}archive-aside.png`} />
                </Col>
                <Col xs={{ span: 22, offset: 1 }} xl={{ span: 16, offset: 1 }}>
                    {provisioningInfo && provisioningInfo.status && (
                        <NewCctFlowSection
                            companyStatus={companyStatus}
                            cctConfig={cctConfig}
                            itemApps={itemApps}
                            itemType={itemType}
                            provisioningInfo={provisioningInfo}
                        />
                    )}
                    {provisioningInfo && provisioningInfo.status && <Divider />}
                    {oldCctFlowApps && (
                        <OldCctFlowSection
                            cctConfig={cctConfig}
                            cctUser={cctUser}
                            handleModalOpen={() => setModalOpen(true)}
                            itemApps={itemApps}
                            provisioningInfo={provisioningInfo}
                        />
                    )}
                </Col>
            </Row>
            <Modal
                centered={true}
                closable={false}
                visible={modalOpen}
                width="560px"
                title={<FormattedMessage id="c-cct-edit-service-title" />}
                onCancel={() => setModalOpen(false)}
                footer={[
                    <Button kind="secondary" onClick={() => setModalOpen(false)}>
                        Annulla
                    </Button>,
                    <LoadingButton
                        loading={credentialsChangeLoading}
                        onClick={() => submitForm("form-credentials-cct")}
                    >
                        <FormattedMessage id="cct-connect-ts-digital-archive" />
                    </LoadingButton>
                ]}
            >
                <FormCredentialsCct onSubmit={handleCredentialsSubmit} />
            </Modal>
        </div>
    );
};

FormEditCct.propTypes = {
    cctConfig: PropTypes.shape({
        specs: PropTypes.object
    }),
    companyStatus: PropTypes.oneOf([
        "AWAITING_APPROVAL",
        "AWAITING_UPLOAD",
        "CERTIFIED",
        "REJECTED",
        "REJECTED_PENDING_VALIDATE",
        "UNVERIFIABLE",
        "UNVERIFIABLE_PENDING_VALIDATE",
        "VALIDATED"
    ]),
    credentialsChangeError: PropTypes.bool,
    credentialsChangeLoading: PropTypes.bool,
    itemType: PropTypes.oneOf(["BUILDING", "COMPANY", "STUDIO"]),
    itemApps: PropTypes.object,
    onCredentialsChanged: PropTypes.func.isRequired,
    submitForm: PropTypes.func,
    provisioningInfo: PropTypes.object
};

export default FormEditCct;
