import styled from "styled-components";

export const Content = styled.div`
    color: #000000;
    font-size: 24px;
    line-height: 1.67;
    margin: auto;
    max-width: 700px;
    text-align: center;
    padding: 30px 0;
`;

export const MaintenancePageContainer = styled.div`
    padding-top: 50px;
    text-align: center;
`;

export const Title = styled.div`
    color: #005075;
    font-size: 40px;

    & span {
        margin-left: 20px;
    }
`;
