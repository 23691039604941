import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import uuid from "uuid/v4";

import { updateChannel, deleteChannel } from "../../../actions/ncs/write";

import { Button } from "@ts-digital/vrc";
import { Col, Row } from "antd";
import { useIntl, FormattedMessage } from "react-intl";

import AddEmail from "./add-email";
import EditEmail from "./edit-email";
import Modal from "../../general/modal";

import { EmptyList, RecipientList, Section, SectionDescription, SectionTitle } from "./styled";

export const ChannelsEmail = ({
    deleteChannel,
    channel,
    defaultChannel,
    itemId,
    groupId,
    ncsId,
    preference,
    updateChannel,
    status
}) => {
    const { formatMessage } = useIntl();
    const { id: channelId, recipients = [] } = channel || {};

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const defaultRecipients = defaultChannel.recipients.map(recipient => recipient.value || recipient).join(", ");
    const recipientsValue = recipients.map(recipient => (recipient.name ? recipient.value : recipient));

    const updateSingleChannel = useCallback(
        recipients => {
            const { appCode, type: templateType } = preference;

            const channel = {
                type: "EMAIL",
                recipients,
                ...(templateType && { templateType }),
                ...(groupId && { groupId }),
                ...(appCode && { appCode })
            };

            updateChannel(ncsId, channel, itemId);
        },
        [ncsId, itemId, groupId, preference, updateChannel]
    );

    const deleteSingleChannel = useCallback(
        channelId => {
            deleteChannel(ncsId, channelId);
        },
        [ncsId, deleteChannel]
    );

    const addRecipient = ({ name, value }) => {
        updateSingleChannel([...new Set([...recipients, { name, value }])]);
    };

    const updateRecipient = ({ name, value }, index) => {
        const recipient = name ? { name, value } : value;
        recipients.splice(index, 1, recipient);
        updateSingleChannel(recipients);
    };

    const deleteRecipient = index => {
        recipients.splice(index, 1);

        if (recipients.length > 0) {
            updateSingleChannel(recipients);
        } else {
            deleteSingleChannel(channelId);
        }
    };

    const deleteRecipientsList = () => {
        deleteSingleChannel(channelId);
        setDeleteModalOpen(false);
    };

    return (
        <>
            <Row>
                <Col xs={{ span: 24 }} xxl={{ span: 22 }}>
                    <Section>
                        <SectionTitle>
                            <FormattedMessage id={`c-notifications-center.channels.email.addRecipient.title`} />
                        </SectionTitle>
                        <SectionDescription>
                            <FormattedMessage id={`c-notifications-center.channels.email.addRecipient.description`} />
                        </SectionDescription>
                        <AddEmail
                            onSubmit={values => addRecipient(values)}
                            status={status}
                            initialValues={{ existingRecipients: recipientsValue }}
                        />
                    </Section>

                    <Section>
                        <SectionTitle>
                            <FormattedMessage id={`c-notifications-center.channels.email.editRecipients.title`} />

                            <Button
                                kind="secondary"
                                size="small"
                                disabled={status.updating || !channelId}
                                onClick={() => setDeleteModalOpen(true)}
                            >
                                <FormattedMessage id="c-notifications-center.channels.email.resetChannel.button" />
                            </Button>
                        </SectionTitle>
                        {recipients.length > 0 ? (
                            <RecipientList>
                                {recipients.map((r, index) => {
                                    const recipientId = uuid();
                                    const recipient = {
                                        value: r.value || r,
                                        name: r.name || false,
                                        existingRecipients: recipientsValue
                                    };
                                    return (
                                        <EditEmail
                                            form={`ncs-edit-email-${recipientId}`}
                                            key={`email-${recipientId}`}
                                            initialValues={recipient}
                                            status={status}
                                            onSubmit={values => updateRecipient(values, index)}
                                            handleDelete={() =>
                                                recipients.length === 1
                                                    ? setDeleteModalOpen(true)
                                                    : deleteRecipient(index)
                                            }
                                        />
                                    );
                                })}
                            </RecipientList>
                        ) : (
                            <EmptyList>
                                <FormattedMessage
                                    id={`c-notifications-center.channels.email.editRecipients.emptyList`}
                                    values={{
                                        defaultRecipients
                                    }}
                                />
                            </EmptyList>
                        )}
                    </Section>
                </Col>
            </Row>

            <Modal
                type="danger"
                centered={true}
                closable={false}
                visible={deleteModalOpen}
                width="400px"
                title={formatMessage({
                    id: "c-notifications-center.channels.email.resetChannel.modal.title"
                })}
                footer={
                    <>
                        <Button colorScheme={"ghost"} kind="secondary" onClick={() => setDeleteModalOpen(false)}>
                            <FormattedMessage id="general.annulla" />
                        </Button>
                        <Button colorScheme={"alert"} onClick={() => deleteRecipientsList()}>
                            <FormattedMessage id="general.delete" />
                        </Button>
                    </>
                }
            >
                <FormattedMessage
                    id="c-notifications-center.channels.email.resetChannel.modal.body"
                    values={{
                        defaultRecipients
                    }}
                />
            </Modal>
        </>
    );
};

ChannelsEmail.defaultProps = {
    defaultChannel: {
        recipients: []
    }
};

ChannelsEmail.propTypes = {
    ncsId: PropTypes.string.isRequired,
    channel: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        recipients: PropTypes.array.isRequired,
        appCode: PropTypes.string,
        templateType: PropTypes.string,
        groupId: PropTypes.string
    }),
    defaultChannel: PropTypes.shape({
        type: PropTypes.string,
        recipients: PropTypes.array
    }),
    itemId: PropTypes.string,
    preference: PropTypes.shape({
        appCode: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    }),
    status: PropTypes.shape({
        started: PropTypes.bool,
        updating: PropTypes.bool,
        error: PropTypes.bool,
        ended: PropTypes.bool
    }),
    updateChannel: PropTypes.func.isRequired,
    deleteChannel: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    ncsId: state.user.user.profile.ncsId,
    status: state.ncs.channels.status
});

export default connect(mapStateToProps, {
    updateChannel,
    deleteChannel
})(ChannelsEmail);
