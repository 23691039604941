import { Button } from "@ts-digital/vrc";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const IconButton = styled(Button)`
    width: 32px;
    border-radius: 50%;

    &:hover {
        background-color: ${props => !props.disabled && props.theme.colors.secondary.azure};
    }
`;

export const OptionsTrigger = styled(Button)`
    width: 100%;
    height: 100%;

    &:disabled {
        pointer-events: none;
    }
`;

export const Icon = styled(FontAwesomeIcon)`
    font-size: 16px;
`;
