import React, { useEffect, useContext, useCallback } from "react";

import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { isUserWithTooManyItems } from "../../../../utils/get-roles";

import { listUsersV3, resendWelcomeEmail, resetResendWelcomeEmail } from "../../../../actions/company/users";
import { changePage } from "../../../../actions/companies";
import { displayErrorToast, displaySuccessToast } from "../../../../actions/overflowNotification";
import { fetchItemServices } from "../../../../actions/services/service-subscription-v2";
import {
    removeRoles,
    resetRemoveRoles,
    deleteUserInvitation,
    resetDeleteUserInvitation
} from "../../../../actions/user";

import PageTitle from "../../../../components/general/page-title";
import UsersManageTable from "../../../../components/registry/user-manage-table-old";

import { Container, Content, TitleWrapper } from "./styled";
import { listItemsRoleCreation, listItemsRoleCreationReset } from "../../../../actions/registry/read";
import { UsersManagegementContext } from "../../../../user-context";
import { useEmbed } from "../../../../hooks/use-embed";

const UsersManage = ({
    changePage,
    company,
    companiesData,
    companiesPagination,
    companiesStatus,
    displayErrorToast,
    displaySuccessToast,
    deleteUserInvitation,
    deleteUserInvitationStatus,
    fetchItemServices,
    history,
    itemServices,
    listItemsRoleCreation,
    listItemsRoleCreationReset,
    listUsersV3,
    loadingUsers,
    match,
    removeRoles,
    resetDeleteUserInvitation,
    removeRolesFromUserStatus,
    resendWelcomeEmail,
    resetRemoveRoles,
    resetResendWelcomeEmail,
    resendStatus,
    users,
    user,
    usersStatus
}) => {
    const { selectedUser } = useContext(UsersManagegementContext);
    const tooManyItems = isUserWithTooManyItems(user.user.roles);

    const { isEmbed } = useEmbed();

    useEffect(() => {
        if (resendStatus.ended) {
            displaySuccessToast(
                <FormattedMessage id="c-registry-user.invite-toast-success.content" />,
                <FormattedMessage id="c-registry-user.invite-toast-success.title" />
            );
            resetResendWelcomeEmail();
        }
    }, [displaySuccessToast, resendStatus.ended, resetResendWelcomeEmail]);

    useEffect(() => {
        if (resendStatus.error) {
            displayErrorToast(
                <FormattedMessage id="c-registry-user.invite-toast-error.content" />,
                <FormattedMessage id="c-registry-user.invite-toast-error.title" />
            );
            resetResendWelcomeEmail();
        }
    }, [displayErrorToast, resendStatus.error, resetResendWelcomeEmail]);

    useEffect(() => {
        if (removeRolesFromUserStatus.ended) {
            displaySuccessToast(
                <FormattedMessage id="c-registry-user.remove-roles.toast-success.content" />,
                <FormattedMessage id="c-registry-user.remove-roles.toast-success.title" />
            );
            resetRemoveRoles();
        }
    }, [displaySuccessToast, removeRolesFromUserStatus.ended, resetRemoveRoles]);

    useEffect(() => {
        if (removeRolesFromUserStatus.error) {
            displayErrorToast(
                <FormattedMessage id="c-registry-user.remove-roles.toast-error.content" />,
                <FormattedMessage id="c-registry-user.remove-roles.toast-error.title" />
            );
            resetRemoveRoles();
        }
    }, [displayErrorToast, removeRolesFromUserStatus.error, resetRemoveRoles]);

    useEffect(() => {
        if (deleteUserInvitationStatus.ended) {
            displaySuccessToast(
                <FormattedMessage id="c-registry-user.delete-user-invitation-success.toast-success.content" />,
                <FormattedMessage id="c-registry-user.delete-user-invitation-success.toast-success.title" />
            );
            resetDeleteUserInvitation();
        }
    }, [displaySuccessToast, deleteUserInvitationStatus.ended, resetDeleteUserInvitation]);

    useEffect(() => {
        if (deleteUserInvitationStatus.error) {
            displayErrorToast(
                <FormattedMessage id="c-registry-user.delete-user-invitation-error.toast-error.content" />,
                <FormattedMessage id="c-registry-user.delete-user-invitation-error.toast-error.title" />
            );
            resetDeleteUserInvitation();
        }
    }, [displayErrorToast, deleteUserInvitationStatus.error, resetDeleteUserInvitation]);

    const getCompaniesCallback = useCallback(
        (fullText, page, size) => listItemsRoleCreation(fullText, null, selectedUser, page, size),
        [listItemsRoleCreation, selectedUser]
    );

    const getCompaniesResetCallback = useCallback(() => listItemsRoleCreationReset(), [listItemsRoleCreationReset]);

    return (
        <>
            <PageTitle
                title={
                    <TitleWrapper>
                        <FormattedMessage id="c-registry-user.title" />
                    </TitleWrapper>
                }
                subtitle={<FormattedMessage id="c-registry-user.subtitle" />}
                onBack={isEmbed ? undefined : () => history.goBack()}
            />
            <Container>
                <Content>
                    <UsersManageTable
                        changePage={changePage}
                        company={company}
                        companiesData={companiesData}
                        companiesStatus={companiesStatus}
                        companiesPagination={companiesPagination}
                        fetchItemServices={fetchItemServices}
                        getCompanies={getCompaniesCallback}
                        getCompaniesResetCallback={getCompaniesResetCallback}
                        history={history}
                        isUserWithTooManyItems={tooManyItems}
                        itemServices={itemServices.services}
                        itemServicesStatus={itemServices.status}
                        listUsers={listUsersV3}
                        loading={
                            loadingUsers || removeRolesFromUserStatus.started || deleteUserInvitationStatus.started
                        }
                        match={match}
                        onDeleteRoles={(itemId, userId) => removeRoles(itemId, userId)}
                        onDeleteInvitation={(userId, itemId) => deleteUserInvitation(userId, itemId)}
                        onEditRoles={value => history.push(`/${match.params.item}/registry/user/${value}/edit`)}
                        onInviteAgain={value => resendWelcomeEmail(value)}
                        loggedUserId={user.user.profile.id}
                        users={users}
                        usersStatus={usersStatus}
                    />
                </Content>
            </Container>
        </>
    );
};

const mapStateToProps = (state, props) => ({
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    companiesStatus: state.registry.read.listItemsRoleCreation.status,
    companiesPagination: state.companies.pagination,
    companiesData: state.registry.read.listItemsRoleCreation.content,
    itemServices: state.services.itemServices,
    loadingUsers: state.usersv3.status.started,
    resendStatus: state.manageUser.resendEmail.status,
    users: state.usersv3.users,
    usersStatus: state.usersv3.status,
    user: state.user,
    removeRolesFromUserStatus: state.manageUser.removeRolesFromUser.status,
    deleteUserInvitationStatus: state.manageUser.deleteUserInvitation.status
});

const actions = {
    changePage,
    displayErrorToast,
    displaySuccessToast,
    deleteUserInvitation,
    fetchItemServices,
    listItemsRoleCreation,
    listItemsRoleCreationReset,
    listUsersV3,
    removeRoles,
    resendWelcomeEmail,
    resetRemoveRoles,
    resetDeleteUserInvitation,
    resetResendWelcomeEmail
};

export default connect(mapStateToProps, actions)(UsersManage);
