import React from "react";
import PropTypes from "prop-types";

import { Icon } from "antd";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

export const ConnectionListLink = () => {
    const params = useParams();

    return (
        <div className="connections-create-path">
            <p>
                <span className="link">
                    <Link to={`/${params.item}/connections`}>
                        <Icon type="left" />
                        <FormattedMessage id="connections.create.back-button" />
                    </Link>
                </span>
                <FormattedMessage id="connections.create.path-after-button" />
            </p>
        </div>
    );
};

ConnectionListLink.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            item: PropTypes.string.isRequired
        })
    })
};

export default ConnectionListLink;
