import React from "react";
import PropTypes from "prop-types";

import { Icon, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";

import { IconContainer } from "./styled";

const AddColumn = ({ disabled, onClick, tooltip }) => (
    <Tooltip placement="right" title={<FormattedMessage id={tooltip} />}>
        <IconContainer disabled={disabled}>
            <Icon onClick={disabled ? () => {} : onClick} style={{ cursor: "inherit" }} type="plus-circle" />
        </IconContainer>
    </Tooltip>
);

AddColumn.propTypes = {
    onClick: PropTypes.func.isRequired
};

export { AddColumn };
