import { fieldEqual, fieldNotEqual, fieldRequired, passwordStrength } from "@ts-digital/agyo-validations";
import { translateMessage } from "../../i18n";
import combineValidators from "@ts-digital/agyo-validations/lib/utils/combineValidators";

export const passwordSchema = {
    type: "object",
    properties: {
        oldPassword: {
            type: "string",
            validate: value =>
                combineValidators(
                    fieldRequired(translateMessage("general.form.field-required")),
                    fieldNotEqual(translateMessage("c-expired-password.must-change-password"), "password")
                )(value)
        },
        password: {
            type: "string",
            validate: password =>
                combineValidators(
                    fieldRequired(translateMessage("general.form.field-required")),
                    passwordStrength("La password non è abbastanza complessa")
                )(password)
        },
        confirmPassword: {
            type: "string",
            validate: fieldEqual(translateMessage("general.form.wrong-password-confirmation"), "password")
        }
    }
};
