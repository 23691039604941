import styled, { css } from "styled-components";

import { TS_DIGITAL_IMAGES } from "../../config";

export const GradientImage = styled.div`
    height: ${props => props.height};
    width: 100%;
    background-image: url(${TS_DIGITAL_IMAGES}talks-banner/gradient.png);
    background-size: contain;
`;

export const GhostButton = styled.div`
    border: 1px solid #fff;
    background-color: #fff;
    color: #1f92d0;
    border-radius: 2px;
    padding: 16px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    font-family: "LemonMilkBold", Fallback, sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 16px;
    flex-shrink: 0;

    :hover {
        color: #f7b133;
        border-color: #f7b133;
        box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.5);
    }

    @media (max-width: 1336px) {
        padding: 8px 24px;
    }

    ${props =>
        props.disabled &&
        css`
            pointer-events: none;
            border-color: #e4eaf0;
            color: #e4eaf0;
        `}
`;
