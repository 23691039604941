/* eslint-disable */
//
// Autogenerated by Thrift Compiler (0.11.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
"use strict";

var thrift = require('browser-thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = module.exports = {};
var Auth = module.exports.Auth = function(args) {
  this.id = null;
  this.securityToken = null;
  this.appName = null;
  this.requestId = null;
  this.correlationId = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.securityToken !== undefined && args.securityToken !== null) {
      this.securityToken = args.securityToken;
    }
    if (args.appName !== undefined && args.appName !== null) {
      this.appName = args.appName;
    }
    if (args.requestId !== undefined && args.requestId !== null) {
      this.requestId = args.requestId;
    }
    if (args.correlationId !== undefined && args.correlationId !== null) {
      this.correlationId = args.correlationId;
    }
  }
};
Auth.prototype = {};
Auth.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.securityToken = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.appName = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.requestId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.correlationId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Auth.prototype.write = function(output) {
  output.writeStructBegin('Auth');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.securityToken !== null && this.securityToken !== undefined) {
    output.writeFieldBegin('securityToken', Thrift.Type.STRING, 2);
    output.writeString(this.securityToken);
    output.writeFieldEnd();
  }
  if (this.appName !== null && this.appName !== undefined) {
    output.writeFieldBegin('appName', Thrift.Type.STRING, 3);
    output.writeString(this.appName);
    output.writeFieldEnd();
  }
  if (this.requestId !== null && this.requestId !== undefined) {
    output.writeFieldBegin('requestId', Thrift.Type.STRING, 4);
    output.writeString(this.requestId);
    output.writeFieldEnd();
  }
  if (this.correlationId !== null && this.correlationId !== undefined) {
    output.writeFieldBegin('correlationId', Thrift.Type.STRING, 5);
    output.writeString(this.correlationId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Status = module.exports.Status = function(args) {
  this.createdAt = null;
  this.createdBy = null;
  this.modifiedAt = null;
  this.modifiedBy = null;
  this.active = null;
  if (args) {
    if (args.createdAt !== undefined && args.createdAt !== null) {
      this.createdAt = args.createdAt;
    }
    if (args.createdBy !== undefined && args.createdBy !== null) {
      this.createdBy = args.createdBy;
    }
    if (args.modifiedAt !== undefined && args.modifiedAt !== null) {
      this.modifiedAt = args.modifiedAt;
    }
    if (args.modifiedBy !== undefined && args.modifiedBy !== null) {
      this.modifiedBy = args.modifiedBy;
    }
    if (args.active !== undefined && args.active !== null) {
      this.active = args.active;
    }
  }
};
Status.prototype = {};
Status.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.createdAt = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.createdBy = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I64) {
        this.modifiedAt = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.modifiedBy = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.BOOL) {
        this.active = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Status.prototype.write = function(output) {
  output.writeStructBegin('Status');
  if (this.createdAt !== null && this.createdAt !== undefined) {
    output.writeFieldBegin('createdAt', Thrift.Type.I64, 1);
    output.writeI64(this.createdAt);
    output.writeFieldEnd();
  }
  if (this.createdBy !== null && this.createdBy !== undefined) {
    output.writeFieldBegin('createdBy', Thrift.Type.STRING, 2);
    output.writeString(this.createdBy);
    output.writeFieldEnd();
  }
  if (this.modifiedAt !== null && this.modifiedAt !== undefined) {
    output.writeFieldBegin('modifiedAt', Thrift.Type.I64, 3);
    output.writeI64(this.modifiedAt);
    output.writeFieldEnd();
  }
  if (this.modifiedBy !== null && this.modifiedBy !== undefined) {
    output.writeFieldBegin('modifiedBy', Thrift.Type.STRING, 4);
    output.writeString(this.modifiedBy);
    output.writeFieldEnd();
  }
  if (this.active !== null && this.active !== undefined) {
    output.writeFieldBegin('active', Thrift.Type.BOOL, 5);
    output.writeBool(this.active);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ConfigRequest = module.exports.ConfigRequest = function(args) {
  this.itemId = null;
  if (args) {
    if (args.itemId !== undefined && args.itemId !== null) {
      this.itemId = args.itemId;
    }
  }
};
ConfigRequest.prototype = {};
ConfigRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.itemId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ConfigRequest.prototype.write = function(output) {
  output.writeStructBegin('ConfigRequest');
  if (this.itemId !== null && this.itemId !== undefined) {
    output.writeFieldBegin('itemId', Thrift.Type.STRING, 1);
    output.writeString(this.itemId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ConfigResponse = module.exports.ConfigResponse = function(args) {
  this.id = null;
  this.itemId = null;
  this.status = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.itemId !== undefined && args.itemId !== null) {
      this.itemId = args.itemId;
    }
    if (args.status !== undefined && args.status !== null) {
      this.status = new ttypes.Status(args.status);
    }
  }
};
ConfigResponse.prototype = {};
ConfigResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.id = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.itemId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.status = new ttypes.Status();
        this.status.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ConfigResponse.prototype.write = function(output) {
  output.writeStructBegin('ConfigResponse');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.I64, 1);
    output.writeI64(this.id);
    output.writeFieldEnd();
  }
  if (this.itemId !== null && this.itemId !== undefined) {
    output.writeFieldBegin('itemId', Thrift.Type.STRING, 2);
    output.writeString(this.itemId);
    output.writeFieldEnd();
  }
  if (this.status !== null && this.status !== undefined) {
    output.writeFieldBegin('status', Thrift.Type.STRUCT, 4);
    this.status.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RoleAssociationList = module.exports.RoleAssociationList = function(args) {
  this.gdprLogUsers = null;
  if (args) {
    if (args.gdprLogUsers !== undefined && args.gdprLogUsers !== null) {
      this.gdprLogUsers = Thrift.copyList(args.gdprLogUsers, [null]);
    }
  }
};
RoleAssociationList.prototype = {};
RoleAssociationList.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.gdprLogUsers = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new ttypes.RoleAssociation();
          elem6.read(input);
          this.gdprLogUsers.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RoleAssociationList.prototype.write = function(output) {
  output.writeStructBegin('RoleAssociationList');
  if (this.gdprLogUsers !== null && this.gdprLogUsers !== undefined) {
    output.writeFieldBegin('gdprLogUsers', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.gdprLogUsers.length);
    for (var iter7 in this.gdprLogUsers)
    {
      if (this.gdprLogUsers.hasOwnProperty(iter7))
      {
        iter7 = this.gdprLogUsers[iter7];
        iter7.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RoleAssociation = module.exports.RoleAssociation = function(args) {
  this.tsid = null;
  this.agyoId = null;
  this.firstName = null;
  this.lastName = null;
  this.pec = null;
  this.companyId = null;
  this.confirmed = null;
  if (args) {
    if (args.tsid !== undefined && args.tsid !== null) {
      this.tsid = args.tsid;
    }
    if (args.agyoId !== undefined && args.agyoId !== null) {
      this.agyoId = args.agyoId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field agyoId is unset!');
    }
    if (args.firstName !== undefined && args.firstName !== null) {
      this.firstName = args.firstName;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field firstName is unset!');
    }
    if (args.lastName !== undefined && args.lastName !== null) {
      this.lastName = args.lastName;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field lastName is unset!');
    }
    if (args.pec !== undefined && args.pec !== null) {
      this.pec = args.pec;
    }
    if (args.companyId !== undefined && args.companyId !== null) {
      this.companyId = args.companyId;
    }
    if (args.confirmed !== undefined && args.confirmed !== null) {
      this.confirmed = args.confirmed;
    }
  }
};
RoleAssociation.prototype = {};
RoleAssociation.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.tsid = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.agyoId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.firstName = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.lastName = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.pec = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.companyId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.BOOL) {
        this.confirmed = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RoleAssociation.prototype.write = function(output) {
  output.writeStructBegin('RoleAssociation');
  if (this.tsid !== null && this.tsid !== undefined) {
    output.writeFieldBegin('tsid', Thrift.Type.STRING, 1);
    output.writeString(this.tsid);
    output.writeFieldEnd();
  }
  if (this.agyoId !== null && this.agyoId !== undefined) {
    output.writeFieldBegin('agyoId', Thrift.Type.STRING, 2);
    output.writeString(this.agyoId);
    output.writeFieldEnd();
  }
  if (this.firstName !== null && this.firstName !== undefined) {
    output.writeFieldBegin('firstName', Thrift.Type.STRING, 3);
    output.writeString(this.firstName);
    output.writeFieldEnd();
  }
  if (this.lastName !== null && this.lastName !== undefined) {
    output.writeFieldBegin('lastName', Thrift.Type.STRING, 4);
    output.writeString(this.lastName);
    output.writeFieldEnd();
  }
  if (this.pec !== null && this.pec !== undefined) {
    output.writeFieldBegin('pec', Thrift.Type.STRING, 5);
    output.writeString(this.pec);
    output.writeFieldEnd();
  }
  if (this.companyId !== null && this.companyId !== undefined) {
    output.writeFieldBegin('companyId', Thrift.Type.STRING, 6);
    output.writeString(this.companyId);
    output.writeFieldEnd();
  }
  if (this.confirmed !== null && this.confirmed !== undefined) {
    output.writeFieldBegin('confirmed', Thrift.Type.BOOL, 7);
    output.writeBool(this.confirmed);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RequestFlowId = module.exports.RequestFlowId = function(args) {
  this.requestId = null;
  this.companyId = null;
  this.pec = null;
  if (args) {
    if (args.requestId !== undefined && args.requestId !== null) {
      this.requestId = args.requestId;
    }
    if (args.companyId !== undefined && args.companyId !== null) {
      this.companyId = args.companyId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field companyId is unset!');
    }
    if (args.pec !== undefined && args.pec !== null) {
      this.pec = args.pec;
    }
  }
};
RequestFlowId.prototype = {};
RequestFlowId.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.requestId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.companyId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.pec = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RequestFlowId.prototype.write = function(output) {
  output.writeStructBegin('RequestFlowId');
  if (this.requestId !== null && this.requestId !== undefined) {
    output.writeFieldBegin('requestId', Thrift.Type.STRING, 1);
    output.writeString(this.requestId);
    output.writeFieldEnd();
  }
  if (this.companyId !== null && this.companyId !== undefined) {
    output.writeFieldBegin('companyId', Thrift.Type.STRING, 2);
    output.writeString(this.companyId);
    output.writeFieldEnd();
  }
  if (this.pec !== null && this.pec !== undefined) {
    output.writeFieldBegin('pec', Thrift.Type.STRING, 3);
    output.writeString(this.pec);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RecipientData = module.exports.RecipientData = function(args) {
  this.ragioneSociale = null;
  this.companyId = null;
  this.pec = null;
  if (args) {
    if (args.ragioneSociale !== undefined && args.ragioneSociale !== null) {
      this.ragioneSociale = args.ragioneSociale;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field ragioneSociale is unset!');
    }
    if (args.companyId !== undefined && args.companyId !== null) {
      this.companyId = args.companyId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field companyId is unset!');
    }
    if (args.pec !== undefined && args.pec !== null) {
      this.pec = args.pec;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field pec is unset!');
    }
  }
};
RecipientData.prototype = {};
RecipientData.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.ragioneSociale = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.companyId = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.pec = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RecipientData.prototype.write = function(output) {
  output.writeStructBegin('RecipientData');
  if (this.ragioneSociale !== null && this.ragioneSociale !== undefined) {
    output.writeFieldBegin('ragioneSociale', Thrift.Type.STRING, 1);
    output.writeString(this.ragioneSociale);
    output.writeFieldEnd();
  }
  if (this.companyId !== null && this.companyId !== undefined) {
    output.writeFieldBegin('companyId', Thrift.Type.STRING, 2);
    output.writeString(this.companyId);
    output.writeFieldEnd();
  }
  if (this.pec !== null && this.pec !== undefined) {
    output.writeFieldBegin('pec', Thrift.Type.STRING, 3);
    output.writeString(this.pec);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var SetRoleRequest = module.exports.SetRoleRequest = function(args) {
  this.roleList = null;
  this.recipient = null;
  if (args) {
    if (args.roleList !== undefined && args.roleList !== null) {
      this.roleList = new ttypes.RoleAssociationList(args.roleList);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field roleList is unset!');
    }
    if (args.recipient !== undefined && args.recipient !== null) {
      this.recipient = new ttypes.RecipientData(args.recipient);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field recipient is unset!');
    }
  }
};
SetRoleRequest.prototype = {};
SetRoleRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.roleList = new ttypes.RoleAssociationList();
        this.roleList.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.recipient = new ttypes.RecipientData();
        this.recipient.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SetRoleRequest.prototype.write = function(output) {
  output.writeStructBegin('SetRoleRequest');
  if (this.roleList !== null && this.roleList !== undefined) {
    output.writeFieldBegin('roleList', Thrift.Type.STRUCT, 1);
    this.roleList.write(output);
    output.writeFieldEnd();
  }
  if (this.recipient !== null && this.recipient !== undefined) {
    output.writeFieldBegin('recipient', Thrift.Type.STRUCT, 2);
    this.recipient.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var IdNotFoundException = module.exports.IdNotFoundException = function(args) {
  Thrift.TException.call(this, "IdNotFoundException");
  this.name = "IdNotFoundException";
  this.code = null;
  this.message = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Thrift.inherits(IdNotFoundException, Thrift.TException);
IdNotFoundException.prototype.name = 'IdNotFoundException';
IdNotFoundException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IdNotFoundException.prototype.write = function(output) {
  output.writeStructBegin('IdNotFoundException');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GenericError = module.exports.GenericError = function(args) {
  Thrift.TException.call(this, "GenericError");
  this.name = "GenericError";
  this.code = null;
  this.message = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Thrift.inherits(GenericError, Thrift.TException);
GenericError.prototype.name = 'GenericError';
GenericError.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GenericError.prototype.write = function(output) {
  output.writeStructBegin('GenericError');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthenticationException = module.exports.AuthenticationException = function(args) {
  Thrift.TException.call(this, "AuthenticationException");
  this.name = "AuthenticationException";
  this.code = null;
  this.message = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Thrift.inherits(AuthenticationException, Thrift.TException);
AuthenticationException.prototype.name = 'AuthenticationException';
AuthenticationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthenticationException.prototype.write = function(output) {
  output.writeStructBegin('AuthenticationException');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.STRING, 1);
    output.writeString(this.code);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 2);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ttypes.VERSION = '1.0.0';
