import React from "react";
import styled from "styled-components";

import { Divider } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const RowContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const TableHeaders = styled.div`
    display: grid;
    grid-template-columns: repeat(
        ${props => props.showActivationDateColumn + props.showDescriptionColumn + props.showServiceStatusColumn + 2},
        1fr
    );
    height: 40px;
    grid-column-gap: 24px;
    align-items: center;

    @media (max-width: 968px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const HeaderLabel = styled.div`
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 16px;
    color: rgb(21, 41, 53);
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 24px;
`;

export const RemovableHeaderLabel = styled(HeaderLabel)`
    @media (max-width: 968px) {
        display: none;
    }
`;

export const ServiceRows = styled.div`
    display: grid;
    grid-template-columns: repeat(
        ${props => props.showActivationDateColumn + props.showDescriptionColumn + props.showServiceStatusColumn + 2},
        1fr
    );
    grid-column-gap: 24px;
    align-items: center;

    @media (max-width: 968px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const ServiceRow = styled.div`
    color: ${props => (props.disabled ? "#9da6ad" : "rgb(21, 41, 53)")};
    font-size: 16px;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.11px;
    line-height: 24px;
`;

export const RemovableServiceRow = styled(ServiceRow)`
    @media (max-width: 968px) {
        display: none;
    }
`;

export const SubServicesHeaders = styled.div`
    display: grid;
    grid-template-columns: repeat(
        ${props => props.showActivationDateColumn + props.showDescriptionColumn + props.showServiceStatusColumn + 2},
        1fr
    );
    align-items: center;
    grid-column-gap: 24px;
    grid-row-gap: 8px;

    @media (max-width: 968px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

export const SubServiceRowTitle = styled.div`
    font-size: 14px;
    font-family: Roboto;
    grid-column: 1;
    color: rgb(21, 41, 53);
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 24px;
`;

export const SubServiceRow = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 16px;
    grid-column: 2;
`;

export const StyledDivider = styled(Divider)`
    &.ant-divider-horizontal {
        margin: 0px;
    }
`;

export const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    grid-gap: 8px;
    & > :first-child {
        flex-shrink: 0;
    }
`;

export const ToggleLabel = styled.div`
    color: rgb(21, 41, 53);
    font-size: 16px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 24px;
`;

export const ServiceStatusIcon = styled(({ isActive, ...rest }) => <FontAwesomeIcon {...rest} />)`
    margin-right: 8px;

    color: ${props => (props.isActive ? props.theme.colors.status.laSalleGreen : props.theme.colors.text.quickSilver)};
`;

export const InfoIcon = styled(FontAwesomeIcon)`
    margin-left: 8px;
`;
