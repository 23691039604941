import React from "react";
import PropTypes from "prop-types";

import { useParams } from "react-router";
import { Link } from "react-router-dom";

import { Container, IconWithMargin } from "./styled";

export const UserRow = ({ text, column, isEnable, isEditable, value }) => {
    const params = useParams();

    if (column.title.props.id !== "general.action") {
        return <div>{text}</div>;
    } else {
        return (
            <Container>
                <div className="user-row">
                    {isEditable && (
                        <Link
                            to={{
                                pathname: `/${params.item}/registry/apikey/${value.profile.id}/edit`,
                                state: { selectUser: value }
                            }}
                        >
                            <IconWithMargin
                                color={isEnable.status ? "" : isEnable.color}
                                name="pencil-alt"
                                size="20px"
                                solid={true}
                            />
                        </Link>
                    )}
                    <Link
                        to={{
                            pathname: `/${params.item}/registry/userinfo/${value.profile.id}`,
                            state: { selectUser: value }
                        }}
                        className="detail"
                    >
                        <IconWithMargin color="#1890ff" name="angle-right" size="20px" solid={true} />
                    </Link>
                </div>
            </Container>
        );
    }
};

UserRow.propTypes = {
    column: PropTypes.object.isRequired,
    isEnable: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    text: PropTypes.string,
    value: PropTypes.object,
    isOwner: PropTypes.bool
};

export default UserRow;
