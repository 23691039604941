import styled from "styled-components";

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const InfoContainer = styled.div`
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #152935;
`;

export const LogoutContainer = styled.div`
    padding-top: 5px;

    & button {
        width: 100px;
    }
`;
