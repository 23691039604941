import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getBillingSubscriptions as getBillingSubscriptionsAction } from "../../../../actions/metering/billing/subscriptions";
import { Subscription } from "./subscription";
import { useRouteMatch } from "react-router-dom";
import { compose } from "redux";
import { SubscriptionsContainer } from "./styled";

export const BasicUsage = ({ userId, subscriptions, getBillingSubscriptions }) => {
    const {
        params: { item: itemId }
    } = useRouteMatch();

    useEffect(() => {
        getBillingSubscriptions(itemId, userId);
    }, [getBillingSubscriptions, itemId, userId]);

    return (
        <SubscriptionsContainer>
            {subscriptions.map(subscription => (
                <Subscription {...subscription} />
            ))}
        </SubscriptionsContainer>
    );
};

BasicUsage.propTypes = {
    subscriptions: PropTypes.array.isRequired,
    userId: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    constraints: PropTypes.object.isRequired,
    credits: PropTypes.object.isRequired,
    getBillingSubscriptions: PropTypes.func.isRequired,
    getCompany: PropTypes.func.isRequired,
    getConstraints: PropTypes.func.isRequired,
    getCredit: PropTypes.func.isRequired
};

BasicUsage.defaultProps = {
    subscriptions: []
};

const mapStateToProps = (state, props) => ({
    userId: state.user.user.profile.id,
    subscriptions: state.metering.billing.subscriptions.data
});

const enhanceComponent = compose(connect(mapStateToProps, { getBillingSubscriptions: getBillingSubscriptionsAction }));

export const Usage = enhanceComponent(BasicUsage);
