import styled from "styled-components";

export const FirstRowContainer = styled.div`
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const FlexContainer = styled.div`
    display: flex;
    width: 100%;
    & > :not(:last-child) {
        padding-right: 24px;
    }
`;

export const SubscriptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    & > :not(:last-child) {
        padding-bottom: 16px;
    }
`;

export const UsageContainer = styled.div`
    flex-grow: 4;
`;

export const CreditContainer = styled.div`
    flex-grow: 1;
`;
