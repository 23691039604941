import React, { useMemo, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import { faShield, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox } from "@ts-digital/vrc";

import { Tooltip } from "antd";
import { isNil } from "ramda";

import ElevioInfoCircle from "../../../../elevio-info-circle";
import { Table } from "../../../../general/table";
import Modal from "../../../../general/modal";
import LoadingButton from "../../../../general/loading-button";
import { CctStatusCell } from "./cct-status-cell";
import { ContractGenerateCell } from "../contract-generate-cell";
import { ContractUploadCell } from "../contract-upload-cell";
import { ContractOptionsCell } from "../contract-options-cell";
import { ContractWarningCell } from "../contract-warning-cell";
import { FiscalCodeCell } from "../fiscal-code-cell";
import { isItemAdmin, hasManagerRoles, hasAdminOwnerUser } from "../../../../../utils/get-roles";
import SmartConfirmModal from "./cct-smart-modal-confirm";

import { CctFiltersBar } from "./cct-filters";

import { SetSmartRdcButtonContainer, CheckContainer, NumberSelectedContainer } from "./styled";

export const ConfigureManagedItems = ({
    loading,
    managedData,
    onDownloadPdf,
    onFilterChange,
    onMassiveSmartExtend,
    onPageChange,
    onUploadPdf,
    pageData,
    pageNumber,
    pageSize,
    studioHasSmart,
    totalPages,
    onRefresh,
    filters,
    extendCctSmartLoading,
    cctExtedSmartStatus,
    resetExtendSmartProvisioning,
    studioRdcSmartData,
    user
}) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalConfirmDisabled, setModalConfirmDisabled] = useState(true);

    useEffect(() => {
        if (cctExtedSmartStatus.ended && !cctExtedSmartStatus.error) {
            resetExtendSmartProvisioning();
            setSelectedItems([]);
            setModalOpen(false);
        }
        if (!cctExtedSmartStatus.ended && cctExtedSmartStatus.error) {
            resetExtendSmartProvisioning();
            setModalOpen(false);
        }
    }, [cctExtedSmartStatus.ended, cctExtedSmartStatus.error, resetExtendSmartProvisioning, setSelectedItems]);

    // utility methods for managed smart

    const managedHasSmartEnabled = useCallback(
        item => studioHasSmart && (item.status === "initialized" || (item.status === "validated" && item.rdcToBeFixed)),
        [studioHasSmart]
    );

    const canSetBalanceSheetDate = useCallback(
        item =>
            isItemAdmin(item.itemTaxId, user) ||
            (!hasAdminOwnerUser(item.itemId, managedData[item.itemId] && managedData[item.itemId].users) &&
                hasManagerRoles(item.itemTaxId, user)),
        [managedData, user]
    );

    const isBalanceSheetDateSet = useCallback(item => !isNil(item.balanceSheetDate), []);

    const tableColumns = useMemo(
        () => [
            {
                accessor: "setRdcSelector",
                width: "40px",
                Header: props => {
                    return (
                        <Checkbox
                            {...props}
                            name="1"
                            onChange={() => {
                                if (selectedItems.length > 0) {
                                    setSelectedItems([]);
                                } else if (selectedItems.length === 0) {
                                    const selectableItemIds = pageData
                                        .filter(
                                            item =>
                                                managedHasSmartEnabled(item) &&
                                                (canSetBalanceSheetDate(item) || isBalanceSheetDateSet(item))
                                        )
                                        .map(item => item.itemTaxId);

                                    setSelectedItems(selectableItemIds);
                                }
                            }}
                            checked={selectedItems.length > 0}
                            indeterminate={selectedItems.length > 0 && selectedItems.length !== pageData.length}
                        />
                    );
                },
                Cell: props =>
                    props.cell.value.disabled ? (
                        <Tooltip
                            placement="topLeft"
                            title={
                                !props.cell.value.hasRoles ? (
                                    <FormattedMessage id="c-cct-provisioning.managed.no-roles" />
                                ) : (
                                    <FormattedMessage id="c-cct-provisioning.managed.smart-not-enabled" />
                                )
                            }
                        >
                            <span>
                                <Checkbox
                                    {...props}
                                    checked={props.cell.value.checked}
                                    name="1"
                                    disabled={true}
                                    onChange={() => {}}
                                />
                            </span>
                        </Tooltip>
                    ) : (
                        <Checkbox
                            {...props}
                            checked={props.cell.value.checked}
                            name="1"
                            disabled={false}
                            onChange={() => {
                                !props.cell.value.checked
                                    ? setSelectedItems(prevItems => [...prevItems, props.cell.value.taxId])
                                    : setSelectedItems(selectedItems.filter(item => item !== props.cell.value.taxId));
                            }}
                        />
                    )
            },
            {
                accessor: "itemTaxId",
                Header: <FormattedMessage id="c-cct-provisioning.managed.columns.itemTaxId" />,
                Cell: props => <FiscalCodeCell {...props} />
            },
            { accessor: "description", Header: <FormattedMessage id="general.ragione-sociale" /> },
            { accessor: "managerTaxId", Header: <FormattedMessage id="general.rdc-fiscal-code" /> },
            {
                accessor: "managerName",
                Header: <FormattedMessage id="c-cct-provisioning.managed.columns.holderName" />
            },
            {
                accessor: "warning",
                Header: <FormattedMessage id="general.warning" />,
                Cell: props => <ContractWarningCell {...props} />
            },
            {
                accessor: "status",
                Cell: CctStatusCell,
                Header: <FormattedMessage id="c-cct-provisioning.managed.columns.requestStatus" />
            },
            {
                accessor: "generate",
                Header: <FormattedMessage id="general.action" />,
                Cell: props => <ContractGenerateCell {...props} onClick={onDownloadPdf} />
            },
            {
                accessor: "upload",
                Cell: props => <ContractUploadCell {...props} onClick={onUploadPdf} isManaged={true} />
            },
            {
                accessor: "options",
                Header: "",
                Cell: props => <ContractOptionsCell onChangeRdc={onDownloadPdf} {...props} />
            }
        ],
        [
            canSetBalanceSheetDate,
            isBalanceSheetDateSet,
            managedHasSmartEnabled,
            onDownloadPdf,
            onUploadPdf,
            pageData,
            selectedItems,
            setSelectedItems
        ]
    );

    const tableData = useMemo(() => {
        return pageData.map(item => {
            return {
                setRdcSelector: {
                    checked: selectedItems && selectedItems.includes(item.itemTaxId),
                    disabled:
                        !managedHasSmartEnabled(item) ||
                        (!canSetBalanceSheetDate(item) && !isBalanceSheetDateSet(item)),
                    taxId: item.itemTaxId
                },
                generate: item.itemTaxId,
                upload: item.itemTaxId,
                options: item.itemTaxId,
                itemTaxId: item.itemTaxId,
                description: item.description,
                managerTaxId: (item.holder && item.holder.taxId) || "-",
                managerName:
                    (item.holder &&
                        item.holder.name &&
                        item.holder.surname &&
                        `${item.holder.name} ${item.holder.surname}`) ||
                    "-",
                itemCertified: item.itemCertificationStatus,
                itemId: item.itemId,
                status: item.status,
                statusMessage: item.statusMessage,
                toBeFixed: item.rdcToBeFixed,
                rejectedMsg: item.statusMessage,
                toBeFixedMsg: item.rdcToBeFixedDesc,
                isActive: item.active
            };
        });
    }, [canSetBalanceSheetDate, isBalanceSheetDateSet, managedHasSmartEnabled, pageData, selectedItems]);

    const footerContent =
        selectedItems.length > 0 ? (
            <>
                <CheckContainer>
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </CheckContainer>
                <td colSpan="100%">
                    <NumberSelectedContainer>
                        <b>{selectedItems.length}</b> <FormattedMessage id={`c-cct-massive-smart-rdc-selected`} />
                    </NumberSelectedContainer>
                </td>
            </>
        ) : (
            <td colSpan="100%"></td>
        );
    return (
        <>
            <CctFiltersBar
                onFilterChange={onFilterChange}
                loading={loading}
                onRefresh={onRefresh}
                options={[
                    { value: "", filterType: "any", label: <FormattedMessage id="c-cct-status.filter.any" /> },
                    { value: true, filterType: "isActive", label: <FormattedMessage id="c-cct-status-active" /> },
                    { value: false, filterType: "isActive", label: <FormattedMessage id="c-cct-status-not-active" /> },
                    {
                        value: "validated",
                        filterType: "status",
                        label: <FormattedMessage id={`c-cct-filter-status-validated`} />
                    },
                    {
                        value: "rejected",
                        filterType: "status",
                        label: <FormattedMessage id={`c-cct-status-rejected`} />
                    },
                    {
                        value: "downloaded",
                        filterType: "status",
                        label: <FormattedMessage id={`c-cct-status-downloaded`} />
                    },
                    {
                        value: "uploaded",
                        filterType: "status",
                        label: <FormattedMessage id={`c-cct-status-uploaded`} />
                    },
                    {
                        value: "correction",
                        filterType: "status",
                        label: <FormattedMessage id={`c-cct-status-correction`} />
                    },
                    {
                        value: true,
                        filterType: "rdcToBeFixed",
                        label: <FormattedMessage id={`c-cct-status-to-be-fixed`} />
                    }
                ]}
                filters={filters}
            />
            <SetSmartRdcButtonContainer>
                <Button
                    data-gainsight-track={"archive-confirm-smart-managed"}
                    disabled={selectedItems.length === 0}
                    kind={"tertiary"}
                    onClick={() => setModalOpen(true)}
                >
                    <FontAwesomeIcon icon={faShield}></FontAwesomeIcon>{" "}
                    <FormattedMessage id={`c-cct-massive-set-smart-rdc`} />
                </Button>
                <ElevioInfoCircle articleId="697" />
            </SetSmartRdcButtonContainer>

            <Table
                columns={tableColumns}
                data={tableData}
                loading={loading}
                onPageChange={onPageChange}
                page={pageNumber}
                pageSize={pageSize}
                totalPages={totalPages}
                useFooter={true}
                footerContent={footerContent}
            />

            <Modal
                centered={true}
                closable={true}
                visible={modalOpen}
                title={<FormattedMessage id={"c-cct-massive-set-smart-rdc-confirm-modal.title"} />}
                onCancel={() => setModalOpen(false)}
                width="950px"
                paddingBottom="30px"
                footer={[
                    <Button key={1} kind="secondary" onClick={() => setModalOpen(false)}>
                        <FormattedMessage id={"general.annulla"} />
                    </Button>,
                    <LoadingButton
                        key={2}
                        loading={extendCctSmartLoading}
                        dataGainsightTrack={"archive-massive-smart-confirm"}
                        onClick={() => {
                            onMassiveSmartExtend(selectedItems);
                        }}
                        disabled={modalConfirmDisabled}
                    >
                        <FormattedMessage id={"c-cct-massive-set-smart-rdc-confirm-modal.confirm-button"} />
                    </LoadingButton>
                ]}
            >
                <SmartConfirmModal
                    rdcData={studioRdcSmartData}
                    enableConfirmButton={setModalConfirmDisabled}
                    modalConfirmDisabled={modalConfirmDisabled}
                />
            </Modal>
        </>
    );
};

ConfigureManagedItems.propTypes = {
    /** If true, puts the child table into a loading state */
    loading: PropTypes.bool,
    /** Object containing the page's managed and their users */
    managedData: PropTypes.object,
    /** Callback invoked when any PDF download button is pressed; receives the item's ID as its only parameter */
    onDownloadPdf: PropTypes.func.isRequired,
    /** Callback invoked when any filter is changed. Receives all the filters and their values */
    onFilterChange: PropTypes.func.isRequired,
    /** Callback invoked when any PDF upload button is pressed; receives the item's ID as its only parameter */
    onUploadPdf: PropTypes.func.isRequired,
    /** Propagated to the child table, is invoked whenever the table's selected page changes */
    onPageChange: PropTypes.func.isRequired,
    /** Array containing the current page's data */
    pageData: PropTypes.arrayOf(
        PropTypes.shape({
            /** The item's description */
            description: PropTypes.string.isRequired,
            /** CCT holder data */
            holder: PropTypes.shape({
                /** The CCT holder's taxId */
                taxId: PropTypes.string,
                /** The CCT holder's name */
                name: PropTypes.string,
                /** The CCT holder's surname */
                surname: PropTypes.string
            }),
            /** The item's certification status */
            itemCertificationStatus: PropTypes.oneOf([
                "CERTIFIED",
                "VALIDATED",
                "AWAITING_APPROVAL",
                "AWAITING_UPLOAD",
                "UNVERIFIABLE",
                "VALIDATION_REJECTED"
            ]).isRequired,
            /** The item's ID */
            itemId: PropTypes.string.isRequired,
            /** The item's CCT provisioning status. Can be null */
            status: PropTypes.string
        })
    ),
    /** The current's page number, starting from 0 */
    pageNumber: PropTypes.number.isRequired,
    /** Maximum number of elements in a page */
    pageSize: PropTypes.number,
    /** RdcData for smart provisioning */
    studioRdcSmartData: PropTypes.object,
    /** The total number of available pages of data */
    totalPages: PropTypes.number.isRequired,
    /** Callback invoked when refresh button is pressed */
    onRefresh: PropTypes.func.isRequired
};

export default ConfigureManagedItems;
