import styled from "styled-components";

export const Container = styled.div`
    color: rgb(21, 41, 53);
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0.25px;
    line-height: 24px;

    max-width: 200px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
