import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { getFormValues } from "redux-form";

import TextField from "@ts-digital/arc/antd/TextField";
import { SelectStringField } from "@ts-digital/arc/antd/SelectField";
import { form } from "@ts-digital/conv-redux-form";
import { FormattedMessage } from "react-intl";
import { taxRegimeValues } from "../../../../utils/constant";

import { companyProfileEditSchema } from "../../../../utils/form-schema/company-profile-edit";

import { EditableItemLogo } from "../editable-item-logo/index";

import { useFeature } from "../../../../hooks/use-feature";

import {
    Button,
    DataContainer,
    LeftCentralColumn,
    RightCentralColumn,
    RightExtremeColumn,
    FormTitle,
    LoadingButtonContainer,
    LabelText,
    LabelTitle
} from "./styled";
import DataAdditional from "../data-additional";
import DataAddress from "../data-address";
import DataPrivacy from "../data-privacy";

export const EditForm = ({
    classifier,
    handleDeleteLogo,
    handleUploadLogo,
    disabled,
    formValues,
    logoEdit,
    handleSubmit,
    isLoading,
    taxId,
    uuid,
    uploadLogoStatus,
    resetDeleteLogo,
    resetUploadLogo,
    deleteLogoStatus,
    vatNumber,
    initialValues,
    user
}) => {
    const editLogoEnabled = useFeature("ADD_LOGO");

    return (
        <Fragment>
            {editLogoEnabled && (
                <EditableItemLogo
                    logoUrl={initialValues && initialValues.logo}
                    isLoading={initialValues ? !initialValues.logoFinishedLoading : false}
                    handleDeleteLogo={handleDeleteLogo}
                    handleUploadLogo={handleUploadLogo}
                    uploadLogoStatus={uploadLogoStatus}
                    resetDeleteLogo={resetDeleteLogo}
                    resetUploadLogo={resetUploadLogo}
                    deleteLogoStatus={deleteLogoStatus}
                    uuid={uuid}
                    classifier={classifier}
                    disabled={disabled}
                    logoEdit={logoEdit}
                    user={user}
                />
            )}
            <FormTitle>
                <FormattedMessage id="general.personalData" />
            </FormTitle>
            <DataContainer>
                <LeftCentralColumn>
                    <TextField
                        disabled={disabled}
                        name={"description"}
                        label={<FormattedMessage id="general.ragione-sociale" />}
                    />
                </LeftCentralColumn>
                <RightCentralColumn>
                    <LabelTitle>
                        <FormattedMessage id="general.codice-fiscale" />
                    </LabelTitle>
                    <LabelText>{taxId}</LabelText>
                </RightCentralColumn>
                <RightExtremeColumn>
                    <LabelTitle>
                        <FormattedMessage id="general.partita-iva" />
                    </LabelTitle>
                    <LabelText>{vatNumber}</LabelText>
                </RightExtremeColumn>
                <DataAddress disabled={disabled} formValues={formValues} />
                <LeftCentralColumn>
                    <SelectStringField
                        disabled={disabled}
                        getOptionKeyFromValue={value => {
                            return value === "" ? undefined : value;
                        }}
                        label={<FormattedMessage id="general.taxRegime" />}
                        name="taxRegime"
                        options={taxRegimeValues.map(p => {
                            return {
                                value: p.id,
                                label: p.name,
                                searchMatcherString: p.name
                            };
                        })}
                        showSearch={true}
                    />
                </LeftCentralColumn>
                <DataAdditional disabled={disabled} />
            </DataContainer>

            <FormTitle>
                <FormattedMessage id="c-manage-data-company-edit.privacy" />
            </FormTitle>
            <DataContainer>
                <DataPrivacy disabled={disabled} />
            </DataContainer>

            <LoadingButtonContainer>
                {!disabled && (
                    <Button htmlType="submit" loading={isLoading} onClick={handleSubmit} variant="primary">
                        <FormattedMessage id="general.save" />
                    </Button>
                )}
            </LoadingButtonContainer>
        </Fragment>
    );
};

EditForm.propTypes = {
    disabled: PropTypes.bool,
    errorMsg: PropTypes.string,
    formValues: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    handleDeleteLogo: PropTypes.func.isRequired,
    handleUploadLogo: PropTypes.func.isRequired,
    uploadLogoStatus: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    taxId: PropTypes.string,
    uuid: PropTypes.string,
    classifier: PropTypes.string,
    vatNumber: PropTypes.string
};

const formDefinition = {
    enableReinitialize: true,
    form: "company-data-step",
    initialValues: { country: "undefined" },
    schema: companyProfileEditSchema
};

function mapStateToProps(state) {
    return {
        formValues: getFormValues("company-data-step")(state)
    };
}

const composedHoc = compose(connect(mapStateToProps, {}), form(formDefinition));

export default composedHoc(EditForm);
