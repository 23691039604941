import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { LeftColumn } from "./styled";

import PrivacySwitch from "./switch";

class DataPrivacy extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        person: PropTypes.bool
    };

    render() {
        const { disabled, person } = this.props;

        return (
            <Fragment>
                <LeftColumn>
                    <FormattedMessage id={`c-manage-data-company-edit.text1${person ? "-person" : ""}`} />
                </LeftColumn>
                <LeftColumn>
                    <FormattedMessage id={`c-manage-data-company-edit.text2${person ? "-person" : ""}`} />
                </LeftColumn>
                <LeftColumn>
                    <PrivacySwitch name="privacyFlag" disabled={disabled} />
                </LeftColumn>
            </Fragment>
        );
    }
}

export default DataPrivacy;
