import axios from "axios";
import lodash from "lodash";

import { updatedVersion, UPDATED_VERSION } from "../actions/toastNotifications";
import { CURRENT_VERSION, CONFIG_URL } from "../config";

const throttled = lodash.throttle(
    async store => {
        const result = await axios.get(`${CONFIG_URL}`);
        if (result.data.currentVersion !== CURRENT_VERSION) {
            store.dispatch(updatedVersion());
        }
    },
    60000,
    { trailing: false }
);

const checkVersion = store => next => action => {
    if (
        UPDATED_VERSION !== action.type &&
        store.getState().timedNotifications.toast.filter(tn => tn.type === UPDATED_VERSION).length === 0
    ) {
        try {
            throttled(store);
        } catch (err) {
            console.error("Caught an exception!", err);
        }
    }

    next(action);
};

export default checkVersion;
