import styled from "styled-components";

export const CctSpecContainer = styled.div`
    display: grid;
    grid-template-columns: 500px 50px 350px;

    padding: 20px;
`;

export const Description = styled.div`
    color: rgb(21, 41, 53);
    font-family: Cairo;
    font-size: 20px;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0.15px;
    line-height: 24px;
    width: 121px;

    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;
`;

export const Toggle = styled.div`
    display: flex;
    flex-direction: column;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 2;
    padding: 5px;
`;

export const ServiceTitle = styled.div`
    color: rgb(21, 41, 53);
    font-family: Cairo;
    font-size: 24px;
    font-weight: 600;
    height: 32px;
    letter-spacing: 0.18px;
    line-height: 32px;

    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
`;

export const ConfigureButtonContainer = styled.div`
    flex-direction: column;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 2;
    margin-left: 32px;
`;
