import React from "react";
import PropTypes from "prop-types";

import "./style.css";
import { FormattedMessage, useIntl } from "react-intl";
import DateTime from "../../../components/datetime";

const ConnectionSummary = ({ certification, description, connection, isAllowedToManage }) => {
    const { formatMessage } = useIntl();

    const getConnection = connection.id
        ? connection
        : {
              id: 0,
              managerDescription: "",
              managerId: 0,
              featureCode: "",
              status: {
                  activatedAt: "",
                  modifiedAt: "",
                  createdAt: "",
                  status: ""
              }
          };
    const connectionId = connection.id;

    return (
        isAllowedToManage && (
            <div className="c-connection-summary">
                <div className="c-connection-summar-col">
                    <div className="title">
                        {connection.managedDescription}
                        <span className="subtitle">({connection.managedId})</span>
                    </div>
                    <div>
                        <FormattedMessage
                            id="c-connections-settings-summary.manager-description-label"
                            values={{
                                managerDescription: connection.managerDescription
                            }}
                        />
                        <br />
                        <FormattedMessage
                            id="c-connections-settings-summary.manager-id-label"
                            values={{
                                managerId: connection.managerId
                            }}
                        />
                    </div>
                </div>
                <div className="c-connection-summar-col">
                    <div className="title">
                        <FormattedMessage id="c-connections-settings-summary.specific" />
                    </div>
                    <div>
                        {getConnection.appId ? (
                            <div>
                                <FormattedMessage
                                    id="c-connections-settings-summary.specific.managed-service"
                                    values={{
                                        featureCode:
                                            description ||
                                            formatMessage({
                                                id: getConnection.featureCode
                                                    ? "general." + getConnection.appId + "." + getConnection.featureCode
                                                    : "general." + getConnection.appId
                                            })
                                    }}
                                />
                                <br />
                            </div>
                        ) : null}

                        {getConnection.status.activatedAt ? (
                            <div>
                                <FormattedMessage
                                    id="c-connections-settings-summary.specific.activated-time"
                                    values={{
                                        activationDate: (
                                            <DateTime value={getConnection.status.activatedAt} displayTime />
                                        )
                                    }}
                                />
                                <br />
                            </div>
                        ) : null}

                        {getConnection.status.modifiedAt ? (
                            <div>
                                <FormattedMessage
                                    id="c-connections-settings-summary.specific.modified-time"
                                    values={{
                                        lastEditDate: <DateTime value={getConnection.status.modifiedAt} displayTime />
                                    }}
                                />
                                <br />
                            </div>
                        ) : null}

                        {getConnection.status.createdAt ? (
                            <FormattedMessage
                                id="c-connections-settings-summary.specific.created-time"
                                values={{
                                    creationDate: <DateTime value={getConnection.status.createdAt} displayTime />
                                }}
                            />
                        ) : null}

                        {getConnection.status.status ? (
                            <div>
                                <FormattedMessage
                                    id="c-connections-settings-summary.specific.connection-status"
                                    values={{
                                        status: formatMessage({
                                            id: `c-connection-list.status.${
                                                getConnection.status.certificationStatus
                                                    ? getConnection.status.certificationStatus
                                                    : getConnection.status.status
                                            }`
                                        })
                                    }}
                                />
                                <br />
                            </div>
                        ) : null}

                        {certification.certificationInfo[connectionId] &&
                        certification.certificationInfo[connectionId].message &&
                        certification.certificationInfo[connectionId].message !== "" ? (
                            <FormattedMessage
                                id="c-connections-settings-summary.specific.connection-message"
                                values={{
                                    msg: certification.certificationInfo[connectionId].message
                                }}
                            />
                        ) : null}
                    </div>
                    <div />
                </div>
            </div>
        )
    );
};

ConnectionSummary.propTypes = {
    certification: PropTypes.object,
    connection: PropTypes.object,
    isAllowedToManage: PropTypes.bool
};

export default ConnectionSummary;
