import { getRestCall } from "../../../utils/rest-api-call";
import { API_URL, WORKSPACE_API_READ_URL } from "../../../config";
import { getUserProfile } from "../../../utils/state-utils/get-user-profile";
import { getAuthWithAppName } from "../../auth";

export const GET_ITEM_APPS_START = "GET_ITEM_APPS_START";
export const GET_ITEM_APPS_SUCCESS = "GET_ITEM_APPS_SUCCESS";
export const GET_ITEM_APPS_ERROR = "GET_ITEM_APPS_ERROR";
export const GET_WORKSPACE_START = "GET_WORKSPACE_START";
export const GET_WORKSPACE_SUCCESS = "GET_WORKSPACE_SUCCESS";
export const GET_WORKSPACE_ERROR = "GET_WORKSPACE_ERROR";

export const getItemApps = (itemId, userId) => async (dispatch, getState) => {
    dispatch({ type: GET_ITEM_APPS_START });

    try {
        const auth = getAuthWithAppName(getState().auth);
        const res = await getRestCall(
            `${API_URL}/services/${itemId}/app`,
            auth,
            {},
            dispatch,
            auth.refreshToken,
            getUserProfile(getState()),
            itemId,
            userId
        );
        dispatch({ type: GET_ITEM_APPS_SUCCESS, payload: res });
    } catch (e) {
        dispatch({ type: GET_ITEM_APPS_ERROR, error: e });
    }
};

export const getWorkspaceId = itemUuid => async (dispatch, getState) => {
    dispatch({ type: GET_WORKSPACE_START });
    try {
        const auth = getAuthWithAppName(getState().auth);
        const res = await getRestCall(
            `${WORKSPACE_API_READ_URL}/api/v1/workspaces/getBy?ownerId=${itemUuid}&itemId=${itemUuid}`,
            auth,
            {},
            dispatch,
            auth.refreshToken,
            getUserProfile(getState()),
            itemUuid
        );
        dispatch({ type: GET_WORKSPACE_SUCCESS, payload: res.id });
    } catch (e) {
        dispatch({ type: GET_WORKSPACE_ERROR, error: e });
    }
};
