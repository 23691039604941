import styled from "styled-components";

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px 16px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    grid-gap: 16px;
    justify-content: flex-end;
`;

export const HeaderContainer = styled.div`
    margin-bottom: 8px;
`;

export const TopHeaderContainer = styled.div`
    display: flex;
    gap: 16px;
`;

export const YourStudioContainer = styled.div`
    color: var(--text-title, #004e75);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 2px;
`;

export const CompanyTitleContainer = styled.div`
    color: #98aab3;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    height: 24px;
    text-transform: uppercase;
    flex: 1;
`;

export const CompanyDescriptionContainer = styled.div`
    color: #004e75;
    font-family: Cairo;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const Footer = styled.div`
    width: 100%;
    height: 72px;
    background: rgb(242, 245, 248);
    box-shadow: 0px -2px 8px 0px rgba(21, 41, 53, 0.32);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 16px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
`;
