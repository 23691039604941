import React from "react";
import { FormattedMessage } from "react-intl";

import { GenerateContainer, LoadingButtonStyled } from "./styled";

const ContractGenerate = ({ disabled, fillPdfStatus, onGenerate }) => (
    <GenerateContainer>
        <LoadingButtonStyled disabled={disabled} onClick={onGenerate} loading={fillPdfStatus.started}>
            <FormattedMessage id="c-validate-company-contract-upload.generate-contract" />
        </LoadingButtonStyled>
    </GenerateContainer>
);

export default ContractGenerate;
