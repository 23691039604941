import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl";

import { faEdit, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { form } from "@ts-digital/conv-redux-form";
import { Button } from "@ts-digital/vrc";
import { Field } from "redux-form";

import { ncsChannelEmail } from "../../../../utils/form-schema/ncs";

import TextInput from "../../../general/form-text-input";

import {
    ButtonContainer,
    FieldContainer,
    FormContent,
    Icon,
    Recipient,
    RecipientContent,
    RecipientData,
    RecipientName,
    RecipientValue
} from "../styled";

export const EditEmail = ({ form, initialValues, status, handleDelete, handleSubmit }) => {
    const { formatMessage } = useIntl();
    const [isEdit, setIsEdit] = useState(false);

    return (
        <Recipient>
            <RecipientName>
                {(initialValues && initialValues.name) ||
                    formatMessage({
                        id: "c-notifications-center.channels.email.editRecipients.list.defaultName"
                    })}
            </RecipientName>
            <RecipientData>
                <RecipientContent>
                    {isEdit ? (
                        <form onSubmit={handleSubmit}>
                            <FormContent>
                                <FieldContainer>
                                    <Field
                                        id={`email-${form}`}
                                        name="value"
                                        component={TextInput}
                                        placeholder={formatMessage({
                                            id: "c-notifications-center.channels.email.addRecipient.email.placeholder"
                                        })}
                                        disabled={status.updating}
                                        required={true}
                                        value={initialValues}
                                    />
                                </FieldContainer>
                                <ButtonContainer>
                                    <Button type="submit" kind="primary" disabled={status.updating}>
                                        <FormattedMessage id="general.save" />
                                    </Button>
                                </ButtonContainer>
                            </FormContent>
                        </form>
                    ) : (
                        <RecipientValue>{initialValues && initialValues.value}</RecipientValue>
                    )}
                </RecipientContent>
                <>
                    <Button kind={"tertiary"} size={"small"} onClick={() => setIsEdit(!isEdit)}>
                        <Icon icon={faEdit} />
                    </Button>
                    <Button kind={"tertiary"} size={"small"} colorScheme={"alert"} onClick={handleDelete}>
                        <Icon icon={faTrashAlt} />
                    </Button>
                </>
            </RecipientData>
        </Recipient>
    );
};

EditEmail.propTypes = {
    form: PropTypes.string.isRequired,
    initialValues: PropTypes.shape({
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
        value: PropTypes.string.isRequired
    }),
    status: PropTypes.shape({
        started: PropTypes.bool,
        updating: PropTypes.bool,
        error: PropTypes.bool,
        ended: PropTypes.bool
    }),
    handleDelete: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
};

const formDefinition = {
    schema: ncsChannelEmail
};

export default form(formDefinition)(EditEmail);
