import styled from "styled-components";

export const Container = styled.div`
    background-color: ${props => props.theme.colors.background.white};
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 80vh;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 24px;
    gap: 24px;
`;

export const TitleWrapper = styled.div`
    white-space: nowrap;
`;
