import React from "react";

import { useFeature } from "../../../hooks/use-feature";
import ConsoleFatturazioneChildApp from "./child-app";
import ConsoleFatturazionePostmate from "./postmate";

const ConsoleFatturazione = ({ match }) => {
    const isChildApp = useFeature("FATTURAZIONE_CHILD_APP");

    return isChildApp ? <ConsoleFatturazioneChildApp /> : <ConsoleFatturazionePostmate match={match} />;
};

export default ConsoleFatturazione;
