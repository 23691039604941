import React from "react";
import { METERING_API_URL } from "../../../../config";
import { getRestCall } from "../../../../utils/rest-api-call";
import { displayErrorToast } from "../../../overflowNotification";
import { FormattedMessage } from "react-intl";
import { getUserProfile } from "../../../../utils/state-utils/get-user-profile";
import { getAuthWithAppName } from "../../../auth";

export const GET_BILLING_USAGE_STATISTICS_START = "METERING_GET_BILLING_USAGE_STATISTICS_START";
export const GET_BILLING_USAGE_STATISTICS_END = "METERING_GET_BILLING_USAGE_STATISTICS_END";
export const GET_BILLING_USAGE_STATISTICS_SUCCESS = "METERING_GET_BILLING_USAGE_STATISTICS_SUCCESS";
export const GET_BILLING_USAGE_STATISTICS_ERROR = "METERING_GET_BILLING_USAGE_STATISTICS_ERROR";

export const getBillingUsageStatisticsStart = () => ({
    type: GET_BILLING_USAGE_STATISTICS_START
});

export const getBillingUsageStatisticsEnd = () => ({
    type: GET_BILLING_USAGE_STATISTICS_END
});

export const getBillingUsageStatisticsSuccess = (statistics, size) => ({
    type: GET_BILLING_USAGE_STATISTICS_SUCCESS,
    payload: {
        statistics,
        size
    }
});

export const getBillingUsageStatisticsError = error => ({
    type: GET_BILLING_USAGE_STATISTICS_ERROR,
    payload: {
        error
    }
});

export const getBillingUsageStatistics =
    (item, userId, page, itemsPerPage, from, to, filteredItem, sortBy, viewOldBillingCycles) =>
    async (dispatch, getState) => {
        dispatch(getBillingUsageStatisticsStart());
        try {
            const auth = getAuthWithAppName(getState().auth);
            const { statistics, size } = await getRestCall(
                `${METERING_API_URL}/api/v1/usage/invoicing/${item}/statistics`,
                auth,
                {
                    userId,
                    page,
                    itemsPerPage,
                    from,
                    to,
                    filteredItem,
                    sortBy: sortBy && sortBy.key && sortBy.order && `${sortBy.key}.${sortBy.order}`,
                    viewOldBillingCycles
                },
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                item
            );
            dispatch(getBillingUsageStatisticsSuccess(statistics, size));
        } catch (error) {
            const displayError = displayErrorToast(<FormattedMessage id="metering.usage.detail.billing.error" />);
            displayError(dispatch);
            dispatch(getBillingUsageStatisticsError(error));
        }
        dispatch(getBillingUsageStatisticsEnd());
    };
