import styled, { css } from "styled-components";
import { TS_DIGITAL_IMAGES } from "../../../../config";

import lemonMilkFont from "../../LEMONMILK-Regular.otf";
import lemonMilkFontBold from "../../LEMONMILK-Bold.otf";
import lemonMilkFontMedium from "../../LEMONMILK-Medium.otf";

export const Container = styled.div`
    @font-face {
        font-family: "LemonMilk";
        src: url(${lemonMilkFont}) format("woff");
        font-style: normal;
        font-weight: 400;
        font-display: Roboto;
    }

    @font-face {
        font-family: "LemonMilkBold";
        src: url(${lemonMilkFontBold}) format("woff");
        font-style: normal;
        font-weight: bold;
        font-display: Roboto;
    }

    @font-face {
        font-family: "LemonMilkMedium";
        src: url(${lemonMilkFontMedium}) format("woff");
        font-style: normal;
        font-weight: 600;
        font-display: Roboto;
    }

    background-color: #1f92d0;
    color: white;
    padding: 40px;
    background-image: url(${TS_DIGITAL_IMAGES}talks-banner/neon.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 60%;
    position: relative;

    @media (max-width: 1024px) {
        background-size: 70%;
    }
`;

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    grid-gap: 40px;

    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;

export const Logo = styled.div`
    width: 100%;
    height: 100%;
    max-width: 374px;

    @media (max-width: 1300px) {
        max-width: 274px;
    }
`;

export const Title = styled.div`
    color: rgb(255, 255, 255);
    font-size: 60px;
    font-family: "LemonMilkBold", Fallback, sans-serif;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 1;
`;

export const Subtitle = styled.div`
    color: rgb(255, 255, 255);
    font-size: 30px;
    font-family: "LemonMilk", Fallback, sans-serif;
    line-height: 1;
    font-weight: normal;
`;

export const WhenContainer = styled.div`
    color: rgb(255, 255, 255);
    font-size: 22px;
    font-family: "LemonMilk", Fallback, sans-serif;
    font-weight: normal;
    letter-spacing: 0.61px;
    line-height: 28px;
    margin-top: 24px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
`;

export const CloseButton = styled.div`
    cursor: pointer;
    width: 48px;
    height: 48px;
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0px;
    line-height: 15px;
    position: absolute;
    top: 8px;
    right: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
`;

export const UnstyledA = styled.a`
    font-style: initial;
    color: white;
    text-decoration: initial;
    background-color: initial;
    outline: none;
    cursor: pointer;

    ${props =>
        props.disabled &&
        css`
            pointer-events: none;
            cursor: not-allowed;
        `};
`;
