import { callWithRetry, getAuthWithAppName } from "../auth";
import { NOTE_SPESE_API_URL } from "../../config";

import noteSpeseConfig from "../../assets/thrift/notespese/NoteSpeseFlowConfig";
import noteSpeseTypes from "../../assets/thrift/notespese/notespese-flow-config_types";

import thrift from "browser-thrift";

const noteSpeseUrl = new URL(NOTE_SPESE_API_URL);

const noteSpeseClient = thrift.createXHRClient(
    noteSpeseConfig,
    thrift.createXHRConnection(noteSpeseUrl.hostname, 443, {
        useCors: true,
        path: noteSpeseUrl.pathname,
        https: noteSpeseUrl.protocol === "https:"
    })
);

export const NOTE_SPESE_CONFIG_START = "NOTE_SPESE_CONFIG_START";
export const NOTE_SPESE_CONFIG_ERROR = "NOTE_SPESE_CONFIG_ERROR";
export const NOTE_SPESE_CONFIG_SUCCESS = "NOTE_SPESE_CONFIG_SUCCESS";
export const NOTE_SPESE_CONFIG_RESET = "NOTE_SPESE_CONFIG_RESET";
export const NOTE_SPESE_COFINRM_START = "NOTE_SPESE_COFINRM_START";
export const NOTE_SPESE_COFINRM_ERROR = "NOTE_SPESE_COFINRM_ERROR";
export const NOTE_SPESE_COFINRM_SUCCESS = "NOTE_SPESE_COFINRM_SUCCESS";

/**
 * recupera la configurazione dato il CF dell'azienda per la verifica dei dati di configurazione/abilitazione
 * @param auth
 * @param fiscalCode
 * @returns {function(*=)}
 */
export function getNoteSpeseConfig(fiscalCode) {
    return (dispatch, getState) => {
        dispatch({
            type: NOTE_SPESE_CONFIG_START
        });

        const request = new noteSpeseTypes.ConfigRequest({
            ownerId: fiscalCode,
            itemId: fiscalCode
        });
        const codes = ["401"];

        const auth = getAuthWithAppName(getState().auth);
        callWithRetry(
            noteSpeseClient,
            noteSpeseClient.getConfig,
            auth,
            request,
            auth.refreshToken,
            dispatch,
            (error, response) => {
                if (error !== null) {
                    dispatch({
                        type: NOTE_SPESE_CONFIG_ERROR,
                        error: error
                    });
                } else {
                    dispatch({
                        type: NOTE_SPESE_CONFIG_SUCCESS,
                        payload: response
                    });
                }
            },
            codes
        );
    };
}

/**
 * esegue l'rpc thrift che definisce il token JWT e che manda la mail all'utente con l'url
 * @param auth
 * @param extraData contiene i dati della configurazione necessari per la generazione del JWT e per l'invio della mail
 * @returns {function(*)}
 */
export function activateAppNoteSpese(extraData) {
    return (dispatch, getState) => {
        dispatch({
            type: NOTE_SPESE_COFINRM_START
        });
        const request = new noteSpeseTypes.ActivateAppNoteSpeseRequest({
            cf: extraData.taxId,
            companyName: extraData.description,
            zipCode: extraData.zipCode,
            city: extraData.city,
            province: extraData.province,
            country: extraData.country,
            street: extraData.street,
            firstName: extraData.firstName,
            lastName: extraData.lastName,
            email: extraData.id
        });
        //TODO CAPIRE PERCHÈ DA UN ECCEZIONE

        const auth = getAuthWithAppName(getState().auth);
        callWithRetry(
            noteSpeseClient,
            noteSpeseClient.activateAppNoteSpese,
            auth,
            request,
            auth.refreshToken,
            dispatch,
            (error, response) => {
                if (error !== null) {
                    dispatch({
                        type: NOTE_SPESE_COFINRM_ERROR,
                        error: error
                    });
                } else {
                    dispatch({
                        type: NOTE_SPESE_COFINRM_SUCCESS,
                        payload: response
                    });
                }
            }
        );
    };
}

export function resetNoteSpeseConfig() {
    return {
        type: NOTE_SPESE_CONFIG_RESET
    };
}
