import styled from "styled-components";

export const Title = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 2.125rem;
    font-family: Cairo;
    font-weight: 600;
    line-height: 2.5rem;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
`;

export const Subtitle = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 1.5rem;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 2rem;
    margin-bottom: 8px;
`;

export const BodyCopy = styled.div`
    color: ${props => props.theme.colors.text.cadet};
    font-size: 1.125rem;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.5px;
    line-height: 1.625rem;
    margin-bottom: 2.5rem;
`;

export const ContentContainer = styled.div`
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 0 2rem;
    padding-right: 16rem;

    @media (max-width: 1240px) {
        padding-right: initial;
    }
`;

export const Step = styled.div`
    width: 2rem;
    height: 2rem;
    border: 0.125rem solid ${props => props.theme.colors.primary.richElectricBlue};
    border-radius: 100%;
    color: ${props => props.theme.colors.primary.richElectricBlue};
    font-size: 1rem;
    font-family: Roboto;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StepOneContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const VerticalLine = styled.div`
    height: 100%;
    width: 0.125rem;
    background-color: ${props => props.theme.colors.primary.richElectricBlue};
`;
