import styled from "styled-components";

export const StyledButtonTable = styled.div`
    padding: 16px;

    & button {
        width: 100%;
    }
`;

export const BorderedDiv = styled.div`
    border-top-color: #c9d9e8;
    border-top-style: solid;
    border-top-width: 1px;
    margin-top: 8px;
`;

export const StyledHeader = styled.div`
    margin-top: 5px;
    width: 384px;
`;
