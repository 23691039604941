import React from "react";
import PropTypes from "prop-types";

import { Container, Description, Value, ValueType } from "./styled";

const Circle = ({ description, size, themeColors, value, valueType }) => {
    return (
        <Container size={size} background={themeColors.background}>
            <div>
                <Value color={themeColors.color}>{value}</Value>
                {valueType && <ValueType color={themeColors.color}>{valueType}</ValueType>}
            </div>
            <Description color={themeColors.descriptionColor}>{description}</Description>
        </Container>
    );
};

Circle.propTypes = {
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    size: PropTypes.oneOf(["small", "medium", "large"]),
    themeColors: PropTypes.shape({
        background: PropTypes.string,
        color: PropTypes.string,
        descriptionColor: PropTypes.string
    }),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    valueType: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default Circle;
