import React from "react";
import PropTypes from "prop-types";

import { Icon } from "./styled";

const FontawesomeIcon = ({ className, color, name, onClick, size, solid }) => {
    const additionalClass = className ? className : "";
    const type = solid ? "fas" : "far";

    return <Icon className={`${type} fa-${name} ${additionalClass}`} color={color} onClick={onClick} size={size} />;
};

FontawesomeIcon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    size: PropTypes.string,
    solid: PropTypes.bool
};

FontawesomeIcon.defaultProps = {
    className: "",
    color: "#000",
    size: "12pt",
    solid: false
};

export default FontawesomeIcon;
