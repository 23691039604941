import React from "react";
import PropTypes from "prop-types";

import { Button } from "@ts-digital/arc/antd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const AddConnectionButton = ({ display, itemId, managedId }) =>
    display && (
        <Link to={`/${itemId}/connections/create/existingCompany/${managedId}`}>
            <Button variant="primary">
                <FormattedMessage id="c-link-list.add-connection" />
            </Button>
        </Link>
    );

AddConnectionButton.propTypes = {
    display: PropTypes.bool,
    itemId: PropTypes.string.isRequired,
    managedId: PropTypes.string.isRequired
};

export default AddConnectionButton;
