import {
    GET_BILLING_USAGE_DETAILS_START,
    GET_BILLING_USAGE_DETAILS_END,
    GET_BILLING_USAGE_DETAILS_SUCCESS,
    GET_BILLING_USAGE_DETAILS_ERROR
} from "../../../../actions/metering/billing/details";

const defaultBillingState = {};

export const detailsReducer = (state = defaultBillingState, { type, payload }) => {
    switch (type) {
        case GET_BILLING_USAGE_DETAILS_START: {
            const { id } = payload;
            const previousItemState = state[id] || {};
            return {
                ...state,
                [id]: {
                    ...previousItemState,
                    status: { loading: true }
                }
            };
        }
        case GET_BILLING_USAGE_DETAILS_END: {
            const { id } = payload;
            const previousItemState = state[id] || {};
            return {
                ...state,
                [id]: {
                    ...previousItemState,
                    status: { loading: false }
                }
            };
        }
        case GET_BILLING_USAGE_DETAILS_SUCCESS: {
            const { id, details, size } = payload;
            const previousItemState = state[id] || {};
            return {
                ...state,
                [id]: { ...previousItemState, details, size }
            };
        }
        case GET_BILLING_USAGE_DETAILS_ERROR: {
            const { id, error } = payload;
            const previousItemState = state[id] || {};
            return {
                ...state,
                [id]: {
                    ...previousItemState,
                    status: { error }
                }
            };
        }
        default: {
            return state;
        }
    }
};
