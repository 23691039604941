import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Menu, Badge } from "antd";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import {
    ARCHIVE_COOKIES_URL,
    ENABLE_COOKIE,
    NEW_COOKIE_POLICY_URL,
    ONE_TRUST_ENABLED,
    PRIVACY_POLICY_URL,
    RELEASENOTES_URL
} from "../../../config";
import { MenuIcon } from "../menu-icon";

import { version } from "../../../../package.json";

import { getTags } from "../../../utils/releasenotes";

import { MenuItemDisabled, ReleaseNoteLink, PrivacyLink } from "./styled";

export const BottomMenu = ({ isOpen, itemId, lastReleaseNoteDate, services }) => {
    const params = useParams();
    const [newsBadge, setNewsBadge] = useState(false);

    const menuAttr = isOpen ? { inlineCollapsed: true } : {};

    useEffect(() => {
        const actualTags = getTags(params && params.item ? services : null);

        let releaseNotes = [];

        actualTags.forEach(ele => {
            if (localStorage.getItem("lastRealeseNoteClick-" + ele))
                releaseNotes.push(localStorage.getItem("lastRealeseNoteClick-" + ele));
        });

        let highlight =
            actualTags.length > 0 &&
            (releaseNotes.length === 0 || releaseNotes.some(ele => Date.parse(ele) < lastReleaseNoteDate));

        setNewsBadge(highlight);
    }, [lastReleaseNoteDate, params, services]);

    return (
        <Badge dot={newsBadge} offset={[-20, 10]}>
            <Menu className="c-sideMenu-menu" mode="inline" theme="dark" {...menuAttr}>
                <Menu.SubMenu
                    key="legalInfo"
                    popupOffset={ENABLE_COOKIE === "enable" ? [5, -105] : [5, -65]}
                    title={
                        <div>
                            <MenuIcon iconName="shield-alt" />
                            <span>
                                <FormattedMessage id="general.legal-info" />
                            </span>
                        </div>
                    }
                >
                    <MenuItemDisabled disabled>{`TS Digital v${version}`}</MenuItemDisabled>
                    <Menu.Divider />
                    {ENABLE_COOKIE === "enable" && (
                        <Menu.Item key="cookies">
                            {ONE_TRUST_ENABLED === "true" ? (
                                <a href={NEW_COOKIE_POLICY_URL} target="_blank" rel="noopener noreferrer">
                                    <MenuIcon iconName="cookie-bite" />
                                    <span>
                                        <FormattedMessage id="general.cookie" />
                                    </span>
                                </a>
                            ) : (
                                <Link to={itemId ? `/${itemId}/cookiepolicy` : `/cookiepolicy`}>
                                    <MenuIcon iconName="cookie-bite" />
                                    <span>
                                        <FormattedMessage id="general.cookie" />
                                    </span>
                                </Link>
                            )}
                        </Menu.Item>
                    )}
                    <Menu.Item key="archiveCookies">
                        <PrivacyLink href={ARCHIVE_COOKIES_URL} target="_blank" rel="noopener noreferrer">
                            <MenuIcon iconName="file" />
                            <span>
                                <FormattedMessage id="general.servicesTab" />
                            </span>
                        </PrivacyLink>
                    </Menu.Item>
                    <Menu.Item key="privacyInfo">
                        <PrivacyLink href={PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
                            <MenuIcon iconName="file-pdf" />
                            <FormattedMessage id="general.privacy" />
                        </PrivacyLink>
                    </Menu.Item>
                    <Menu.Item key="releaseNotes">
                        <ReleaseNoteLink
                            href={
                                RELEASENOTES_URL +
                                (params.menuItem && params.menuItem.includes("apps/")
                                    ? "/tag" + params.menuItem.substring(params.menuItem.indexOf("apps/") + 4)
                                    : "")
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {
                                let tags = getTags(params && params.item ? services : null);

                                tags.forEach(ele => {
                                    localStorage.setItem("lastRealeseNoteClick-" + ele, new Date());
                                });

                                setNewsBadge(false);
                            }}
                        >
                            <Badge dot={newsBadge} offset={[10, 0]}>
                                <MenuIcon iconName="info-circle" />
                                <FormattedMessage id="general.releasenotes" />
                            </Badge>
                        </ReleaseNoteLink>
                    </Menu.Item>
                </Menu.SubMenu>
            </Menu>
        </Badge>
    );
};

BottomMenu.propTypes = {
    isOpen: PropTypes.bool,
    itemId: PropTypes.string,
    lastReleaseNoteDate: PropTypes.object,
    services: PropTypes.object,
    displayInfoToast: PropTypes.func
};

export default BottomMenu;
