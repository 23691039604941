import React from "react";
import PropTypes from "prop-types";

import { includes } from "ramda";
import { connect } from "react-redux";

import { listItems } from "../../actions/registry/read";
import ServiceCard from "../../components/general/service-card";
import { isAppAvailable } from "../../utils/app-availability";
import SdiConfig from "./sdi";
import { ServiceCardsContainer } from "../general/service-card/styled";

import { getWaitingList } from "../../actions/waitinglist/read";
import { subscribeWaitinglist, deleteWaitinglist } from "../../actions/waitinglist/write";

export class Billing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCard: 0
        };
    }

    static propTypes = {
        activateService: PropTypes.func.isRequired,
        updateConfigs: PropTypes.func.isRequired,
        company: PropTypes.object.isRequired,
        services: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        listItems: PropTypes.func,
        listItemStatus: PropTypes.object,
        items: PropTypes.array
    };

    handleClick(serviceKey) {
        this.setState({ selectedCard: serviceKey });
    }

    componentDidUpdate(prevProps) {
        const {
            services: { sdi },
            listItems,
            listItemStatus,
            items
        } = this.props;

        const itemIds = [];
        for (var config in sdi.configs) {
            itemIds.push(sdi.configs[config].itemId);
        }

        if ((!listItemStatus || !listItemStatus.started) && itemIds.length > 0 && !items) {
            listItems(itemIds);
        }
    }

    renderCards() {
        const {
            updateConfigs,
            company,
            items,
            services: { active, agyoServices, categories, sdi },
            user,
            getWaitingList,
            subscribeWaitinglist,
            deleteWaitinglist,
            waitinglistDeleteStatus,
            waitinglist,
            waitinglistSubscribeStatus,
            getWaitingListStatus
        } = this.props;

        const roles = user.user && user.user.roles ? user.user.roles : [];

        const { selectedCard } = this.state;

        let sdiIsActiveInAgyo = agyoServices.services.some(service => service.id === "SDI-FLOW" && service.active);
        let ericeviIsActiveInAgyo = agyoServices.services.some(
            service => service.id === "B2B-PASSIVE-FLOW" && service.active
        );
        let nsoIsActiveInAgyo = agyoServices.services.some(service => service.id === "NSO" && service.active);
        let crtIsActiveInAgyo = agyoServices.services.some(service => service.id === "CRT" && service.active);

        const rolesFiltered = roles.filter(r => r.featureCode === "SDI" && r.resourceId === company.base.id);
        const { ownerId, itemId } = sdi.configs[company.base.id] || {};

        const enableSdiSetting =
            sdiIsActiveInAgyo && rolesFiltered.length === 0 && ownerId && itemId && ownerId === itemId;

        const servicesConfig = [
            {
                active: includes("SDI-FLOW", active.services),
                disable: !sdiIsActiveInAgyo,
                buyLink:
                    "https://www.teamsystem.com/store/fatturazione-elettronica/agyo-fatturazione/prezzi/?utm_medium=referral&utm_source=app-agyo&utm_campaign=agyo-fatt-attive_sitelink-piattaforma#fatture-attive",
                display:
                    (selectedCard === 0 || selectedCard === 2) &&
                    isAppAvailable(
                        company.base.id,
                        roles,
                        "SDI-FLOW",
                        active.availableToUser,
                        categories.data["EIP"],
                        active.services
                    ),
                expanded: selectedCard === 2,
                expandedComponent: (
                    <SdiConfig
                        updateConfigs={updateConfigs}
                        sdi={sdi}
                        items={items}
                        handleClose={() => this.setState({ selectedCard: 0 })}
                    />
                ),
                title: "c-signature.contract.sdi",
                description: "c-billing.sdi.description",
                showConfigureButton:
                    company.base.status.status === "VALIDATED" &&
                    includes("SDI-FLOW", active.services) &&
                    enableSdiSetting,
                onConfigure: () => this.handleClick(2),
                onClose: () => this.setState({ selectedCard: 0 }),
                serviceInfo: agyoServices.services.filter(ele => ele.id === "SDI-FLOW")[0]
            },
            {
                active: includes("B2B-PASSIVE-FLOW", active.services),
                disable: !ericeviIsActiveInAgyo,
                showBuyButton: true,
                buyLink:
                    "https://www.teamsystem.com/store/fatturazione-elettronica/agyo-fatturazione/prezzi/?utm_medium=referral&utm_source=app-agyo&utm_campaign=agyo-fatt-ricez_sitelink-piattaforma#fatture-passive",
                tooltip: "c-billing.b2b-passive.tooltip",
                display:
                    (selectedCard === 0 || selectedCard === 3) &&
                    isAppAvailable(
                        company.base.id,
                        roles,
                        "B2B-PASSIVE-FLOW",
                        active.availableToUser,
                        categories.data["EIP"],
                        active.services
                    ),
                title: "c-billing.b2b-passive.title",
                description: "c-billing.b2b-passive.description",
                serviceInfo: agyoServices.services.filter(ele => ele.id === "B2B-PASSIVE-FLOW")[0]
            },
            {
                active: includes("NSO", active.services),
                disable: !nsoIsActiveInAgyo,
                display:
                    (selectedCard === 0 || selectedCard === 3) &&
                    isAppAvailable(
                        company.base.id,
                        roles,
                        "NSO",
                        active.availableToUser,
                        categories.data["EIP"],
                        active.services
                    ),
                title: "c-billing.nso.tooltip",
                description: "c-billing.nso.description",
                serviceInfo: agyoServices.services.filter(ele => ele.id === "NSO")[0],
                showContactToBuy: true
            },
            {
                active: includes("CRT", active.services),
                disable: !crtIsActiveInAgyo,
                display:
                    (selectedCard === 0 || selectedCard === 3) &&
                    isAppAvailable(
                        company.base.id,
                        roles,
                        "CRT",
                        active.availableToUser,
                        categories.data["EIP"],
                        active.services
                    ),
                title: "c-billing.crt.title",
                description: "c-billing.crt.description",
                serviceInfo: agyoServices.services.filter(ele => ele.id === "CRT")[0],
                showContactToBuy: true
            }
        ];

        return (
            active.services && (
                <ServiceCardsContainer expanded={selectedCard > 0}>
                    {servicesConfig.map((service, key) => (
                        <ServiceCard
                            key={key}
                            active={service.active}
                            buyLink={service.buyLink}
                            disable={service.disable}
                            expanded={service.expanded}
                            expandedComponent={service.expandedComponent}
                            title={service.title}
                            description={service.description}
                            display={service.display}
                            button={service.button}
                            tooltip={service.tooltip}
                            showActiveButton={service.showActiveButton}
                            onActive={service.onActive}
                            onConfigure={service.onConfigure}
                            onClose={service.onClose}
                            showBuyButton={service.showBuyButton}
                            showContactToBuy={service.showContactToBuy}
                            showConfigureButton={service.showConfigureButton}
                            serviceInfo={service.serviceInfo}
                            companyId={company.base.id}
                            getWaitingList={getWaitingList}
                            getWaitingListStatus={getWaitingListStatus}
                            waitinglist={waitinglist}
                            subscribeWaitinglist={subscribeWaitinglist}
                            waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                            waitinglistDeleteStatus={waitinglistDeleteStatus}
                            deleteWaitinglist={deleteWaitinglist}
                            user={user}
                        />
                    ))}
                </ServiceCardsContainer>
            )
        );
    }
    render() {
        return <div className="c-billing">{this.renderCards()}</div>;
    }
}

const mapStateToProps = (state, props) => ({
    items: state.registry.read.listItems.items,
    listItemStatus: state.registry.read.listItems.status,
    waitinglist: state.waitinglist.read.getWaitingList.waitinglist,
    waitinglistSubscribeStatus: state.waitinglist.write.subscribeWaitinglist.status,
    waitinglistDeleteStatus: state.waitinglist.write.deleteWaitinglist.status,
    getWaitingListStatus: state.waitinglist.read.getWaitingList.status
});

export default connect(mapStateToProps, {
    listItems,
    getWaitingList,
    subscribeWaitinglist,
    deleteWaitinglist
})(Billing);
