import React from "react";

import { useIntl } from "react-intl";
import { Container } from "./styled";

export const StatusCell = ({ cell }) => {
    const active = cell && cell.row.original ? cell.row.original.active : false;

    const intl = useIntl();

    const id = active
        ? "c-cct-configure-managed-items-personal-columns.status.active"
        : "c-cct-configure-managed-items-personal-columns.status.not-active";
    const message = intl.formatMessage({
        id: id
    });
    /*<ActiveStatusDot active={active} />*/
    return <Container>{message}</Container>;
};
