import styled, { css } from "styled-components";

export const Header = styled.div`
    width: 100%;
    height: 48px;
    background: rgb(242, 245, 248);
    display: grid;
    grid-template-columns: 48px 1fr;
    align-items: center;
    border-bottom: 1px solid #c9d9e8;
    cursor: pointer;
    color: #005075;
    font-size: 16px;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.11px;
    line-height: 24px;
`;

export const ArrowContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #005075;
    font-size: 16px;
    transition: transform 0.25s ease-in-out;

    ${props =>
        props.isOpen &&
        css`
            transform: rotate(90deg);
        `}
`;

export const ChildrenContainer = styled.div`
    opacity: ${props => (props.isOpen ? "1" : "0")};
    max-height: ${props => (props.isOpen ? "100%" : "0")};
    overflow: ${props => (props.isOpen ? "initial" : "hidden")};
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
