import React, { Component } from "react";
import PropTypes from "prop-types";
import { Collapse } from "antd";
import { getPanel } from "./accordion-step";
import EndButtonsContent from "./end-buttons-step";

import "./style.css";
export class AccordionStepper extends Component {
    static propTypes = {
        abortLabel: PropTypes.string,
        backRedirectUrl: PropTypes.string, // url di redirect per tornare indietro
        className: PropTypes.string,
        currentStep: PropTypes.number, // numero di step attivo
        displayEndButtons: PropTypes.bool,
        onConfirm: PropTypes.func, // callback per evento di conferma
        onLastBack: PropTypes.func, // callback per il bottone indietro della bottoniera fissa dopo l'ultimo step
        panels: PropTypes.array, // lista degli step da visualizzare; oggi di tipo accordionStep
        submitDisabled: PropTypes.bool,
        submitting: PropTypes.bool,
        submitLabel: PropTypes.string
    };

    static defaultProps = {
        displayEndButtons: true
    };

    render() {
        const {
            abortLabel,
            backRedirectUrl,
            children,
            className,
            currentStep,
            displayEndButtons,
            onConfirm,
            onLastBack,
            submitDisabled,
            submitting,
            submitLabel
        } = this.props;

        let activeKeys = [];
        // attivo tutti i passi dal primo fino a quello attuale
        for (var i = 0; i <= currentStep; i++) {
            activeKeys.push(String(i));
        }

        const buttonKey = "buttonsStep";
        activeKeys.push(buttonKey);

        return (
            <div className={`c-accordion-stepper ${className}`}>
                <Collapse defaultActiveKey={["0"]} activeKey={activeKeys}>
                    {children.map
                        ? children.map((child, index) => getPanel(child, index, index < currentStep))
                        : getPanel(children, 0, false)}
                    {displayEndButtons && (
                        <Collapse.Panel header={null} key={buttonKey} showArrow={false}>
                            <EndButtonsContent
                                abortLabel={abortLabel}
                                backRedirectUrl={backRedirectUrl}
                                showBack={currentStep >= children.length - 1}
                                endDisabled={!(currentStep >= children.length - 1) || submitDisabled}
                                loading={submitting}
                                onBack={onLastBack}
                                onConfirm={onConfirm}
                                submitLabel={submitLabel}
                            />
                        </Collapse.Panel>
                    )}
                </Collapse>
            </div>
        );
    }
}
