import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import { BodyCopy, ContentContainer, Step, StepOneContainer, Subtitle, Title, VerticalLine } from "../styled";
import { AdvertisingEmailWrapper } from "../../styled";

const AlreadyHaveTsid = ({ email }) => {
    return (
        <div>
            <Title>
                <FormattedMessage id="v-invitation-prelogin.already-have-tsid.title" />
            </Title>
            <ContentContainer>
                <StepOneContainer>
                    <Step>1</Step>
                    <VerticalLine />
                </StepOneContainer>
                <div>
                    <Subtitle>
                        <FormattedMessage id="v-invitation-prelogin.already-have-tsid.step1.subtitle" />
                    </Subtitle>
                    <BodyCopy>
                        <AdvertisingEmailWrapper>
                            <FormattedMessage
                                id="v-invitation-prelogin.already-have-tsid.step1.body-copy"
                                values={{
                                    b: chunks => <b>{chunks}</b>,
                                    email: email
                                }}
                            />
                        </AdvertisingEmailWrapper>
                    </BodyCopy>
                </div>
                <div>
                    <Step>2</Step>
                </div>
                <div>
                    <Subtitle>
                        <FormattedMessage id="v-invitation-prelogin.already-have-tsid.step2.subtitle" />
                    </Subtitle>
                    <BodyCopy>
                        <FormattedMessage
                            id="v-invitation-prelogin.already-have-tsid.step2.body-copy"
                            values={{
                                b: chunks => <b>{chunks}</b>
                            }}
                        />
                    </BodyCopy>
                </div>
            </ContentContainer>
        </div>
    );
};

AlreadyHaveTsid.propTypes = {
    email: PropTypes.string
};

export default AlreadyHaveTsid;
