import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    ConnectionsFilterBar,
    ModalConnectionWarning,
    LinkListTitle,
    LinkListPagination,
    LinkElement
} from "../../../components/connections-new";

import { Spinner } from "../../../components/general/spinner";

import { downloadConnectionsData, resetDownloadConnectionsData } from "../../../actions/connections/export";
import { displayErrorToast } from "../../../actions/overflowNotification";
import {
    areLinkedItems,
    findOwnManagedConnections,
    findOwnManagedConnectionsReset
} from "../../../actions/connections/read";
import { fetchItemServices, resetFetchItemServices } from "../../../actions/services/service-subscription-v2";
import { deleteLink } from "../../../actions/connections/write";
import { findItems, findItemsReset, listItems } from "../../../actions/registry/read";
import { getCompanies } from "../../../actions/companies";
import { certificationInfo } from "../../../actions/certification";

import { equals } from "ramda";

export class ConnectionsManaged extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {},
            modalConnectionWarningVisible: false,
            page: 0,
            selectedItem: undefined
        };
    }

    static propTypes = {
        agyoServices: PropTypes.array,
        areLinkedItems: PropTypes.func.isRequired,
        certification: PropTypes.object,
        certificationInfo: PropTypes.func,
        companies: PropTypes.object,
        company: PropTypes.object,
        connectionData: PropTypes.object,
        deleteLink: PropTypes.func,
        displayErrorToast: PropTypes.func,
        downloadConnectionsData: PropTypes.func,
        fetchItemServices: PropTypes.func,
        findItemsReset: PropTypes.func.isRequired,
        findOwnManagedConnections: PropTypes.func,
        findOwnManagedConnectionsStatus: PropTypes.object,
        foundItems: PropTypes.array,
        listItemsResult: PropTypes.array,
        history: PropTypes.object,
        itemServices: PropTypes.object,
        linkedItems: PropTypes.object,
        managedConnections: PropTypes.array,
        managedConnectionsTotalElements: PropTypes.number,
        match: PropTypes.object.isRequired,
        resetDownloadConnectionsData: PropTypes.func,
        resetFetchItemServices: PropTypes.func,
        user: PropTypes.object
    };

    static defaultProps = {
        foundItems: []
    };

    componentDidMount() {
        this.fetchAllConnections();
    }

    componentDidUpdate(prevProps) {
        const {
            areLinkedItems,
            company,
            fetchItemServices,
            foundItems,
            findOwnManagedConnectionsStatus,
            getCompanies,
            listItems,
            managedConnections
        } = this.props;

        let oldManagedOfManagedIds = prevProps.managedConnections.map(v => v.managedId);
        let managedOfManagedIds = managedConnections.map(v => v.managedId);

        if (
            findOwnManagedConnectionsStatus.ended &&
            prevProps.findOwnManagedConnectionsStatus.started &&
            !equals(oldManagedOfManagedIds, managedOfManagedIds)
        ) {
            getCompanies(null, 0, managedOfManagedIds.length, null, [managedOfManagedIds]);
            managedOfManagedIds.push(company.base.id);
            fetchItemServices(true, managedOfManagedIds);
            listItems(managedOfManagedIds, 6, 0);
        }

        if (!equals(prevProps.foundItems, foundItems)) {
            areLinkedItems(
                company.base.id,
                foundItems.map(v => v.item.base.id)
            );
        }
    }

    componentWillUnmount() {
        const { findOwnManagedConnectionsReset, resetFetchItemServices } = this.props;
        findOwnManagedConnectionsReset();
        resetFetchItemServices();
    }

    fetchAllConnections() {
        const params = {
            page: 0
        };

        const ids = this.getMyCompaniesIds();

        let filterManager = { deleted: false };
        filterManager.managerIds = ids;

        this.setState({ filter: filterManager, selectedItem: undefined });

        this.fetchConnection(filterManager, params);

        this.findItemsClean();
    }

    getMyCompaniesIds() {
        const { company } = this.props;

        let ids = [];
        if (company !== undefined && company.base !== undefined && company.base.id !== undefined) {
            ids.push(company.base.id);
        }

        return ids;
    }

    fetchConnection = (filter, params) => {
        const { findOwnManagedConnections } = this.props;

        findOwnManagedConnections(filter, params);
    };

    findItemsClean = () => {
        const { findItemsReset } = this.props;
        findItemsReset();
    };

    onChangePage = pageNumber => {
        const { filter } = this.state;
        this.setState({ page: pageNumber });

        const params = {
            page: pageNumber
        };

        this.fetchConnection(filter, params);
    };

    onDeleteConnection = id => {
        const { deleteLink } = this.props;
        deleteLink(id, "MANAGED");
    };

    handleFullText = fullText => {
        const { findItemsReset, findItems, findItemsStatus } = this.props;
        if (fullText !== null && (fullText.length === 11 || fullText.length === 16)) {
            findItems(fullText, true);
        } else if (findItemsStatus.ended || findItemsStatus.error) {
            findItemsReset();
        }
    };

    handleSelect(value) {
        const { filter } = this.state;
        this.setState({ selectedItem: value });

        let localFilter = {
            ...filter,
            fullText: value,
            deleted: false
        };
        const params = {
            page: 0
        };
        this.fetchConnection(localFilter, params);
    }

    handleWarningContinue() {
        const { history, match } = this.props;
        history.push(`/${match.params.item}/connections/create/newCompany`);
    }

    setWarningModalVisible(visible) {
        this.setState({ modalConnectionWarningVisible: visible });
    }

    render() {
        const {
            agyoServices,
            certification,
            certificationInfo,
            connectionData,
            companies,
            company,
            displayErrorToast,
            downloadConnectionsData,
            findOwnManagedConnectionsStatus,
            history,
            linkedItems,
            managedConnections,
            managedConnectionsTotalElements,
            match,
            foundItems,
            findItemsStatus,
            itemServices,
            user,
            listItemsResult,
            resetDownloadConnectionsData
        } = this.props;

        const { page, modalConnectionWarningVisible, selectedItem } = this.state;

        let connections = managedConnections;
        let totalConnections = managedConnectionsTotalElements;

        let isStudio = company.base.details.classifier === "STUDIO";
        var linkList = connections.filter(c => !selectedItem || c.managedId === selectedItem);

        linkList = linkList
            ? linkList.map((o, i) => {
                  const item = listItemsResult
                      ? listItemsResult.find(i => {
                            return i.item.base.uuid === o.managedUuid;
                        })
                      : null;
                  const identifier = item ? item.item.base.identifier : null;
                  const { taxId, vatNumber } = identifier ? identifier : {};
                  return { ...o, taxId, vatNumber };
              })
            : [];
        return (
            <div>
                <ModalConnectionWarning
                    handleClose={() => this.setState({ modalConnectionWarningVisible: false })}
                    handleContinue={this.handleWarningContinue.bind(this)}
                    visible={modalConnectionWarningVisible}
                />
                <ConnectionsFilterBar
                    isStudio={isStudio}
                    handleFullText={this.handleFullText}
                    handleSelect={item => this.handleSelect(item)}
                    history={history}
                    items={foundItems || []}
                    linkedItems={linkedItems}
                    resetFilter={this.findItemsClean}
                    onCreateItem={() => this.setWarningModalVisible(true)}
                    findItemsStatus={findItemsStatus}
                />
                <Spinner loading={findOwnManagedConnectionsStatus.started}>
                    <LinkListTitle
                        agyoServices={agyoServices}
                        connectionData={connectionData}
                        displayErrorToast={displayErrorToast}
                        downloadConnectionsData={() => downloadConnectionsData(company.base.uuid)}
                        resetDownloadConnectionsData={resetDownloadConnectionsData}
                    />
                    <div>
                        {linkList.map((link, i) => (
                            <LinkElement
                                agyoServices={agyoServices}
                                certification={certification}
                                description={link.managedDescription}
                                displayAddConnection={true}
                                getCertification={id => certificationInfo(id)}
                                history={history}
                                itemId={match.params.item}
                                itemServices={itemServices}
                                link={link}
                                key={"list-item" + i}
                                onDeleteConnection={this.onDeleteConnection}
                                roles={user ? user.user.roles : []}
                                settingsPageEnabled={true}
                                statusLabel={
                                    companies.data[link.managedId]
                                        ? companies.data[link.managedId].item.base.status.status
                                        : ""
                                }
                                id={link.managedId}
                                vatNumber={link.vatNumber}
                                taxId={link.taxId}
                            />
                        ))}
                    </div>
                    <LinkListPagination onChange={this.onChangePage} page={page} totalLinks={totalConnections} />
                </Spinner>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    agyoServices: state.services.agyoServices.services,
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    companies: state.companies,
    //GESTITE
    connectionData: state.connectionsNew.read.connectionsData,
    managedConnections: state.connectionsNew.read.findOwnManagedConnections.content,
    managedConnectionsTotalElements: state.connectionsNew.read.findOwnManagedConnections.totalElements,
    findOwnManagedConnectionsStatus: state.connectionsNew.read.findOwnManagedConnections.status,
    foundItems: state.registry.read.findItems.items || [],
    findItemsStatus: state.registry.read.findItems.status,
    listItemsResult: state.registry.read.listItems.items || [],
    linkedItems: state.connectionsNew.read.linkedItems,
    itemServices: state.services.itemServices,
    //CERTIFICATI
    certification: state.certification,
    user: state.user
});

export default connect(mapStateToProps, {
    areLinkedItems,
    certificationInfo,
    deleteLink,
    displayErrorToast,
    downloadConnectionsData,
    fetchItemServices,
    findItems,
    listItems,
    findItemsReset,
    findOwnManagedConnections,
    findOwnManagedConnectionsReset,
    getCompanies,
    resetDownloadConnectionsData,
    resetFetchItemServices
})(ConnectionsManaged);
