import React from "react";

import { Link } from "react-router-dom";
import { Icon, Layout, Button } from "antd";
import { FormattedHTMLMessage } from "react-intl";

import Logo from "../../../components/general/logo";

const CreateItemSuccess = () => (
    <Layout className="Root">
        <Layout.Content className="Root-content">
            <div className="Root-logo">
                <Logo className="Root-logo-img" />
            </div>
            <div
                style={{
                    textAlign: "center",
                    padding: "50px 10px",
                    background: "#fff",
                    borderRadius: "5px",
                    boxShadow: "rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px",
                    margin: "12% 27%"
                }}
            >
                <Icon
                    type="check-circle-o"
                    style={{
                        fontSize: "45px",
                        color: "#00a854"
                    }}
                />
                <br />
                <br />
                <span
                    style={{
                        fontSize: "17px",
                        fontWeight: "bold"
                    }}
                >
                    <FormattedHTMLMessage id="root.layout.creata-azienda" />
                </span>
                <br />
                <span>
                    <FormattedHTMLMessage id="root.layout.credenziali" />
                </span>{" "}
                <br />
                <span>
                    <FormattedHTMLMessage id="root.layout.utilizzare-agyo" />
                </span>{" "}
                <br />
                <br />
                <Link to="/">
                    <Button>
                        <FormattedHTMLMessage id="general.continue" />
                    </Button>
                </Link>
            </div>
        </Layout.Content>
    </Layout>
);

export default CreateItemSuccess;
