import { MANAGER_ENABLED } from "../../../../../config";

import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import EndButtonsContent from "../../../../general/accordion-stepper/end-buttons-step";

import {
    isCompanyManager,
    isItemAdmin,
    checkAppRoles,
    checkItemActiveServices,
    utilsCheckColumnChecked
} from "../../../../../utils/select-roles/select-roles-utils";

import "./style.css";

import { Button, Row, Col, Pagination, Checkbox, Table, Input, Tooltip, Form } from "antd";

const FormItem = Form.Item;
const Search = Input.Search;

class SelectRoles extends Component {
    static propTypes = {
        agyoServices: PropTypes.object,
        backDisabled: PropTypes.bool,
        backUrl: PropTypes.string,
        customRoles: PropTypes.object,
        forwardDisabled: PropTypes.bool,
        intl: PropTypes.any.isRequired,
        itemServices: PropTypes.object,
        onBack: PropTypes.func.isRequired,
        onSelectRoles: PropTypes.func.isRequired,
        selectedCompanies: PropTypes.array.isRequired,
        submissionError: PropTypes.node,
        userRoles: PropTypes.array
    };

    constructor(props) {
        super(props);

        const { customRoles } = this.props;

        this.state = {
            companyPage: 1,
            searchText: null,
            selectedCompanyPage: 1,
            customRoles: !customRoles ? {} : customRoles,
            tableColumns: [],
            tableData: [],
            selectedCompaniesData: [],
            parsedUserRoles: []
        };
    }

    componentDidMount() {
        const { userRoles } = this.props;

        if (userRoles) {
            this.parseUserRoles(userRoles);
        }
    }

    componentDidUpdate(prevProps) {
        const { userRoles, selectedCompanies, editMode, selectedUserInfo } = this.props;

        const { customRoles } = this.state;

        if (userRoles !== prevProps.userRoles) {
            this.parseUserRoles(userRoles);
        }

        // in caso di modifica, al primo caricamento precompilo customroles dai roles utente
        // per popolare la griglia con i permessi già configurati per l'utente
        if (
            editMode && // verifico di essere in modifica dei permessi
            selectedUserInfo && // verifico che i dati dell'utente da modificare siano caricati
            selectedUserInfo.profile &&
            selectedCompanies.some(company => !customRoles || customRoles[company.id] === undefined) // verifico che i ruoli siano già stati caricati per le aziende selezionate
        ) {
            let parsingCustomRoles = {};

            // scorro tutti i ruoli configurati per l'utente che sto modificando
            Object.values(selectedUserInfo.roles).forEach(role => {
                if (
                    role.resourceId !== "REGISTERED" &&
                    role.resourceId !== "BACKOFFICE" &&
                    selectedCompanies.map(company => company.id).includes(role.resourceId)
                ) {
                    // la struttura dei custom role è una lista di permessi
                    if (parsingCustomRoles[role.resourceId] === undefined) {
                        // se sto caricando i ruoli di azienda che non ha altri ruoli ancora configurati inizializzo la lista
                        parsingCustomRoles[role.resourceId] = [];
                    }
                    if (role.appId && !parsingCustomRoles[role.resourceId].includes(role.appId)) {
                        // se il ruolo che sto aggiungendo è relativo ad un applicativo e non l'ho già inserito, inserisco l'applicativo
                        parsingCustomRoles[role.resourceId].push(role.appId);
                    } else if (
                        !role.appId &&
                        (role.actionKey === "ADMIN" || role.actionKey === "WRITE" || role.actionKey === "READ")
                    ) {
                        // in casi di admin aziendale ( appid a null, e actionkey ADMIN ) inserisco il ruolo admin
                        // faccio la stessa cosa per i casi in cui ho solo permessi di read o write su azienda
                        parsingCustomRoles[role.resourceId].push("ADMIN");
                    } else if (!role.appId && role.actionKey === "OWNER") {
                        // in caso di owner aziendale ( appid a null, e actionkey OWNER ) inserisco il ruolo owner
                        parsingCustomRoles[role.resourceId].push("OWNER");
                    }
                }
            });

            // in caso di aggiunta nuovo utente esistente inizializzo nella raccolta dei ruoli le aziende per le quali l'utente non ha ruoli ma che sono state selezionate
            Object.values(selectedCompanies.filter(company => parsingCustomRoles[company.id] === undefined)).forEach(
                company => (parsingCustomRoles[company.id] = [])
            );

            this.setState({
                customRoles: parsingCustomRoles
            });
        }
    }

    parseUserRoles(userRoles) {
        let parsedUserRoles = userRoles
            .filter(element => {
                return (
                    element.appId == null &&
                    element.resourceId !== "BACKOFFICE" &&
                    (element.actionKey === "ADMIN" ||
                        element.actionKey === "OWNER" ||
                        element.actionKey === "READ" ||
                        element.actionKey === "WRITE")
                );
            })
            .map(element => {
                return element.resourceId;
            });
        this.setState({ parsedUserRoles });
    }

    onCheckRole(checkedVal, itemid) {
        let customRoles = this.state.customRoles;

        if (customRoles[itemid] && customRoles[itemid].length) {
            if (customRoles[itemid].indexOf(checkedVal) === -1) {
                if (checkedVal === "ADMIN") {
                    customRoles[itemid] = [checkedVal];
                } else {
                    customRoles[itemid].push(checkedVal);
                }
            } else {
                while (customRoles[itemid].indexOf(checkedVal) !== -1) {
                    customRoles[itemid].splice(customRoles[itemid].indexOf(checkedVal), 1);
                }
            }
        } else {
            customRoles[itemid] = [checkedVal];
        }

        this.setState({ customRoles: customRoles });
    }

    onCheckAllChange(item, itemapps) {
        let customRoles = this.state.customRoles;

        /* controllo se sono checked */
        if (customRoles[item.id] && customRoles[item.id].length > 0) {
            customRoles[item.id] = [];
        } else {
            customRoles[item.id] = itemapps;
        }

        this.setState({ customRoles: customRoles });
    }

    formatTableData(selectedCompanies, apps, services, itemServices, customRoles) {
        let data = [];
        const { parsedUserRoles } = this.state;
        const { intl, userRoles } = this.props;

        for (let company of selectedCompanies) {
            let mastercb_checked = false;
            // metto il masterchecked a true se ho checkate tutte le applicazioni ad esclusione del check di super user
            const itemapps = this.getItemActiveServices(itemServices[company.id] ? itemServices[company.id] : []);

            if (customRoles[company.id]) {
                const elementnumbers =
                    customRoles[company.id].includes("ADMIN") ||
                    customRoles[company.id].includes("OWNER") ||
                    customRoles[company.id].includes("WRITE") ||
                    customRoles[company.id].includes("READ")
                        ? itemapps.length + 1
                        : itemapps.length;
                if (customRoles[company.id].length > 0 && customRoles[company.id].length === elementnumbers) {
                    mastercb_checked = true;
                }
            }

            const mastercb_indeterminate =
                !mastercb_checked &&
                customRoles[company.id] &&
                customRoles[company.id].length !== apps.length &&
                customRoles[company.id].length !== 0;

            const mastercb_disabled = !itemapps || itemapps.length === 0 ? true : false;

            // nel caso di utente owner check admin flaggato e disabilitato
            const admincb_disabled = customRoles[company.id] && customRoles[company.id].includes("OWNER");

            let row = {
                key: company.id,
                mastercb: isItemAdmin(company.id, parsedUserRoles) ? (
                    <Checkbox
                        onChange={e => this.onCheckAllChange(company, itemapps)}
                        indeterminate={mastercb_indeterminate}
                        checked={mastercb_checked}
                        disabled={mastercb_disabled || admincb_disabled}
                    />
                ) : null,
                itemadmin: isItemAdmin(company.id, parsedUserRoles) ? (
                    <Checkbox
                        onChange={checkedVal => {
                            this.onCheckRole(checkedVal.target.value, company.id);
                        }}
                        checked={
                            customRoles[company.id] &&
                            (customRoles[company.id].includes("ADMIN") ||
                                customRoles[company.id].includes("OWNER") ||
                                customRoles[company.id].includes("WRITE") ||
                                customRoles[company.id].includes("READ"))
                        }
                        value="ADMIN"
                        disabled={admincb_disabled}
                    />
                ) : null,
                itemmanager:
                    MANAGER_ENABLED && isCompanyManager(company.id, userRoles, undefined) ? (
                        <Checkbox
                            onChange={checkedVal => {
                                this.onCheckRole(checkedVal.target.value, company.id);
                            }}
                            checked={customRoles[company.id] && customRoles[company.id].includes("MANAGER")}
                            value="MANAGER"
                            disabled={isItemAdmin(company.id, parsedUserRoles)}
                        />
                    ) : null,
                stub: `      `,
                description:
                    company.details.classifier === "PERSON"
                        ? `${company.details.firstName} ${company.details.lastName}`
                        : company.details.description
            };

            for (let app in apps) {
                // imposto checked, controllo se già impostati permessi per servizio:
                let app_checked = customRoles[company.id] && customRoles[company.id].indexOf(app) !== -1;

                let app_disabled = true;
                // disabilito check se: abilitato mastercheck su azienda
                if (
                    customRoles[company.id] === undefined ||
                    (customRoles[company.id] &&
                        !customRoles[company.id].includes("ADMIN") &&
                        !customRoles[company.id].includes("OWNER"))
                ) {
                    // disabilito servizio se disabilitato per azienda
                    if (itemServices[company.id]) {
                        app_disabled = !checkItemActiveServices(itemServices[company.id], app);
                    }
                } else {
                    // se ho abilitato l'admin i check vengono disabilitati ma si mostrano checkati
                    app_checked = true;
                }

                //controllo per visibilità check della app in base ai ruoli utente
                let app_visible = true;
                // verifico il ruolo per la app solo se non sono amministratore
                if (!isItemAdmin(company.id, parsedUserRoles)) {
                    // se non sono amministratore verifico il ruolo
                    if (!checkAppRoles(company.id, app, userRoles) || !isCompanyManager(company.id, userRoles, app)) {
                        app_visible = false;
                    }
                }

                const checkbox_node = (
                    <div
                        style={{
                            display: app_visible ? "initial" : "none"
                        }}
                    >
                        <Checkbox
                            checked={app_checked}
                            disabled={app_disabled}
                            value={app}
                            onChange={checkedVal => {
                                this.onCheckRole(checkedVal.target.value, company.id);
                            }}
                        />
                    </div>
                );

                row[app] = app_disabled ? (
                    <Tooltip
                        placement="top"
                        title={intl.formatMessage({
                            id: "c-manage-data.multicompany-create.disabled-service"
                        })}
                    >
                        {checkbox_node}
                    </Tooltip>
                ) : (
                    checkbox_node
                );
            }

            data.push(row);
        }

        return data;
    }

    getItemActiveServices(itemServices) {
        let services = [];
        Object.values(itemServices).forEach(function (itemservice) {
            if (itemservice.agyoService) {
                if (services.indexOf(itemservice.agyoService.appId) === -1 && itemservice.agyoService.appId !== "") {
                    services.push(itemservice.agyoService.appId);
                }
            }
        });

        return services;
    }

    onCheckColumn(app, checked) {
        const { selectedCompanies, itemServices, userRoles } = this.props;
        let { customRoles, parsedUserRoles } = this.state;

        Object.values(selectedCompanies).forEach(company => {
            if (app === "MANAGER") {
                // se sono admin di un azienda oppure non sono manager non permetto di operare sul manager check
                if (isItemAdmin(company.id, parsedUserRoles) || !isCompanyManager(company.id, userRoles)) {
                    return;
                }
                // altrimenti checko manager per l'azienda
                //*******************************  FIXME codice duplicato da sotto ***************** */
                if (!checked) {
                    if (
                        customRoles[company.id] &&
                        customRoles[company.id].length &&
                        customRoles[company.id].indexOf(app) === -1
                    ) {
                        customRoles[company.id].push(app);
                    } else {
                        customRoles[company.id] = [app];
                    }
                } else {
                    // altrimenti rimuovo i permessi per l'applicazione
                    if (customRoles[company.id] && customRoles[company.id].indexOf(app) !== -1) {
                        customRoles[company.id].splice(customRoles[company.id].indexOf(app), 1);
                    }
                }
                //********************************************************************************** */
            } else {
                // se sono owner di un azienda non permetto di disattivare l'admin check
                if (app === "ADMIN" && customRoles[company.id] && customRoles[company.id].includes("OWNER")) {
                    return;
                }

                // controllo che l'utente abbia i ruoli sull'applicazione per checkare
                const has_role = isItemAdmin(company.id, parsedUserRoles) || checkAppRoles(company.id, app, userRoles);

                // controlla se il check è disabilitato perchè è flaggato admin, in tutti i casi diversi dalla deselezione ADMIN
                const isAdminChecked =
                    customRoles[company.id] &&
                    app !== "ADMIN" &&
                    (customRoles[company.id].includes("ADMIN") || customRoles[company.id].includes("OWNER"));

                if (has_role && !isAdminChecked) {
                    if (!checked) {
                        // sto checkando aggiungo il ruolo se abilitato
                        if (checkItemActiveServices(itemServices[company.id], app) || app === "ADMIN") {
                            if (
                                customRoles[company.id] &&
                                customRoles[company.id].length &&
                                customRoles[company.id].indexOf(app) === -1
                            ) {
                                customRoles[company.id].push(app);
                            } else {
                                customRoles[company.id] = [app];
                            }
                        }
                    } else {
                        // altrimenti rimuovo i permessi per l'applicazione
                        if (customRoles[company.id] && customRoles[company.id].indexOf(app) !== -1) {
                            customRoles[company.id].splice(customRoles[company.id].indexOf(app), 1);
                        }
                    }
                }
            }
        });

        this.setState({ customRoles: customRoles });
    }

    getColumns(services) {
        const { intl, itemServices, selectedCompanies, userRoles } = this.props;
        const { customRoles, parsedUserRoles } = this.state;

        let apps = {};
        const agyoServices = services && services.services ? services.services : [];

        const admincheckedtest = utilsCheckColumnChecked(
            "ADMIN",
            selectedCompanies,
            itemServices,
            customRoles,
            parsedUserRoles,
            userRoles
        );

        let admin_cb_checked = false;
        let admin_cb_indeterminate = false;

        if (admincheckedtest === "indeterminate") {
            admin_cb_indeterminate = true;
        } else {
            admin_cb_checked = admincheckedtest;
        }
        const admin_title = (
            <Checkbox
                onChange={e => this.onCheckColumn("ADMIN", admin_cb_checked || admin_cb_indeterminate)}
                checked={admin_cb_checked}
                indeterminate={admin_cb_indeterminate}
                disabled={admin_cb_checked === null}
            >
                {"Admin"}
            </Checkbox>
        );

        const managercheckedtest = utilsCheckColumnChecked(
            "MANAGER",
            selectedCompanies,
            itemServices,
            customRoles,
            parsedUserRoles,
            userRoles
        );

        let manager_cb_checked = false;
        let manager_cb_indeterminate = false;

        if (managercheckedtest === "indeterminate") {
            manager_cb_indeterminate = true;
        } else {
            manager_cb_checked = managercheckedtest;
        }

        const manager_title = (
            <Checkbox
                onChange={e => this.onCheckColumn("MANAGER", manager_cb_checked || manager_cb_indeterminate)}
                checked={manager_cb_checked}
                indeterminate={manager_cb_indeterminate}
                disabled={manager_cb_checked === null}
            >
                {"Manager"}
            </Checkbox>
        );

        let columns = [
            {
                title: "",
                dataIndex: "mastercb",
                key: "mastercb",
                align: "center",
                fixed: "left"
            },
            {
                title: intl.formatMessage({
                    id: "c-manage-data.multicompany-create.company"
                }),
                dataIndex: "description",
                key: "description",
                align: "center",
                fixed: "left",
                className: "desc_column"
            },
            {
                title: admin_title,
                dataIndex: "itemadmin",
                key: "itemadmin",
                align: "center",
                className: "super-admin-col",
                fixed: "left"
            },
            {
                title: "",
                dataIndex: "stub",
                key: "stub",
                align: "center"
            }
        ];

        if (MANAGER_ENABLED) {
            columns.splice(3, 0, {
                title: manager_title,
                dataIndex: "itemmanager",
                key: "itemmanager",
                align: "center",
                fixed: "left"
            });
        }

        for (let obj of agyoServices) {
            if (!apps[obj.appId]) {
                //FIXME scarto servizi senza descrizione
                if (obj.appDescription && obj.appDescription !== "") {
                    // discard inactive and system applications
                    if (obj.active && !obj.system) {
                        apps[obj.appId] = obj.appDescription;

                        const checkedtest = utilsCheckColumnChecked(
                            obj.appId,
                            selectedCompanies,
                            itemServices,
                            customRoles,
                            parsedUserRoles,
                            userRoles
                        );
                        let col_cb_checked = false;
                        let col_cb_indeterminate = false;

                        if (checkedtest === "indeterminate") {
                            col_cb_indeterminate = true;
                        } else {
                            col_cb_checked = checkedtest;
                        }

                        const col_cb = (
                            <Checkbox
                                onChange={e => this.onCheckColumn(obj.appId, col_cb_checked || col_cb_indeterminate)}
                                checked={col_cb_checked}
                                indeterminate={col_cb_indeterminate}
                                disabled={col_cb_checked === null}
                            >
                                {obj.appDescription}
                            </Checkbox>
                        );

                        columns.push({
                            title: col_cb,
                            dataIndex: obj.appId,
                            key: obj.appId,
                            align: "center"
                        });
                    }
                }
            }
        }

        return { columns: columns, apps: apps };
    }

    filterSelected(element, index, array) {
        const { searchText } = this.state;

        if (!searchText) {
            return true;
        } else if (
            element.identifier.taxId.toUpperCase().indexOf(searchText.toUpperCase()) !== -1 ||
            element.identifier.vatNumber.toUpperCase().indexOf(searchText.toUpperCase()) !== -1 ||
            element.details.description.toUpperCase().indexOf(searchText.toUpperCase()) !== -1
        ) {
            return true;
        } else {
            return false;
        }
    }

    onSelectAll(apps, selectedCompanies) {
        const { itemServices, userRoles } = this.props;
        const { parsedUserRoles } = this.state;

        let customRoles = {};

        for (let company of selectedCompanies) {
            Object.keys(apps).forEach(function (app) {
                // controllo che l'utente abbia i ruoli sull'applicazione per checkare
                const has_role = isItemAdmin(company.id, parsedUserRoles) || checkAppRoles(company.id, app, userRoles);

                if (app === "ADMIN" || (has_role && checkItemActiveServices(itemServices[company.id], app))) {
                    if (customRoles[company.id] && customRoles[company.id].length) {
                        customRoles[company.id].push(app);
                    } else {
                        customRoles[company.id] = [app];
                    }
                }
            });
        }

        this.setState({ customRoles: customRoles });
    }

    deselectAll() {
        this.setState({ customRoles: {} });
    }

    render() {
        const {
            backUrl,
            selectedCompanies,
            forwardDisabled,
            agyoServices,
            itemServices,
            intl,
            backDisabled,
            endLoading,
            onBack,
            submissionError
        } = this.props;
        const { selectedCompanyPage, customRoles, searchText } = this.state;

        // se ho un filtro impostato filtro le aziende
        let filteredSelected = selectedCompanies;
        if (searchText) {
            filteredSelected = filteredSelected.filter(element => this.filterSelected(element));
        }
        let selectedCompaniesData = filteredSelected.slice(
            5 * (selectedCompanyPage - 1),
            5 * (selectedCompanyPage - 1) + 5
        );

        // recupero le colonne
        const columns_apps = this.getColumns(agyoServices);
        const tableColumns = columns_apps.columns;
        const apps = columns_apps.apps;

        // formatto i dati per la tabella
        const tableData = this.formatTableData(
            selectedCompaniesData,
            columns_apps.apps,
            agyoServices,
            itemServices,
            customRoles
        );

        return (
            <div>
                <div className="users-roles-table" style={{ display: forwardDisabled ? "none" : "initial" }}>
                    {MANAGER_ENABLED && (
                        <div className="warning-message-container">
                            <FontAwesomeIcon className="warning-icon" icon={faExclamationCircle} />
                            <FormattedHTMLMessage id="c-user-create-modal.manager-warning" />
                        </div>
                    )}

                    <Row gutter={12}>
                        <Col span={16} />
                        <Col span={6}>
                            <Search
                                enterButton
                                style={{ marginBottom: "20px" }}
                                size="large"
                                id="search-aziende"
                                placeholder={intl.formatMessage({
                                    id: "c-manage-data.multicompany-create.search-company"
                                })}
                                onSearch={value => {
                                    this.setState({
                                        searchText: value,
                                        selectedCompanyPage: 1
                                    });
                                }}
                            />
                        </Col>
                        <Col span={2} />
                    </Row>

                    <Row gutter={12}>
                        <Col span={2} />
                        <Col span={16}>
                            <span>
                                <h2>
                                    <FormattedMessage id="c-manage-data.multicompany-create.roles" />
                                </h2>
                            </span>
                        </Col>
                        <Col span={2}>
                            <Button
                                onClick={() => this.onSelectAll(apps, selectedCompanies)}
                                style={{ float: "right", marginRight: "30px" }}
                            >
                                <FormattedMessage id="c-manage-data.multicompany-create.select-all" />
                            </Button>
                        </Col>
                        <Col span={2}>
                            <Button onClick={() => this.deselectAll()} style={{ float: "right" }}>
                                <FormattedMessage id="c-manage-data.multicompany-create.deselect-all" />
                            </Button>
                        </Col>
                        <Col span={2} />
                    </Row>

                    <Row gutter={12}>
                        <Col span={2} />
                        <Col span={20}>
                            <Table
                                columns={tableColumns}
                                dataSource={tableData}
                                pagination={false}
                                scroll={{ x: "auto" }}
                            />
                        </Col>
                        <Col span={2} />
                    </Row>
                    <Row gutter={12}>
                        <Col span={18} />
                        <Col span={4}>
                            <Pagination
                                simple
                                defaultCurrent={1}
                                current={this.state.selectedCompanyPage}
                                total={filteredSelected.length}
                                style={{ float: "right", marginTop: "30px" }}
                                defaultPageSize={5}
                                showSizeChanger={false}
                                onChange={(page, pageSize) => {
                                    // imposto paginatore
                                    this.setState({
                                        selectedCompanyPage: page
                                    });
                                }}
                            />
                        </Col>
                        <Col span={2} />
                    </Row>
                </div>

                <div className="footerDiv">
                    <FormItem validateStatus={submissionError && "error"} help={submissionError}>
                        <EndButtonsContent
                            abortLabel="general.back"
                            backDisabled={backDisabled}
                            backRedirectUrl={backUrl}
                            endDisabled={forwardDisabled}
                            loading={endLoading}
                            onBack={onBack}
                            onConfirm={() => {
                                this.props.onSelectRoles(customRoles);
                            }}
                            showBack={true}
                            submitLabel="general.end"
                        />
                    </FormItem>
                </div>
            </div>
        );
    }
}

export default injectIntl(SelectRoles);
