import { displayErrorToast, displaySuccessToast } from "../actions/overflowNotification";
import { refreshToken } from "./auth-utils";
import cuid from "cuid";
import uuidV4 from "uuid/v4";

export async function retry(
    logout,
    thriftClient,
    fn,
    auth,
    request,
    refreshTokenParan,
    dispatch,
    callback,
    codes = ["401"],
    displayError = false,
    displaySuccess = false
) {
    try {
        auth.correlationId = uuidV4();
        auth.requestId = cuid();

        const result = await fn.call(thriftClient, auth, request);

        returnSuccess(callback, result, displaySuccess, dispatch);
    } catch (error) {
        if (codes.includes(error.code)) {
            try {
                const token = await refreshToken(auth, dispatch);

                auth.securityToken = token;
                auth.accessToken = token;
                const result = await fn.call(thriftClient, auth, request);

                returnSuccess(callback, result, displaySuccess, dispatch);
            } catch (e) {
                if (codes.includes(e.code)) {
                    logout()(dispatch);
                } else {
                    returnError(callback, error, displayError, dispatch);
                }
            }
        } else if (error.code === "403.1") {
            dispatch({
                type: "LOGIN_ERROR",
                error,
                errorInfo: error
            });
        } else {
            returnError(callback, error, displayError, dispatch);
        }
    }
}

function returnError(callback, error, displayError, dispatch) {
    callback(error);

    if (displayError) {
        displayErrorToast(error.message)(dispatch);
    }
}

function returnSuccess(callback, result, displaySuccess, dispatch) {
    callback(null, result);

    if (displaySuccess) {
        displaySuccessToast("Operazione correttamente presa in carico")(dispatch);
    }
}
