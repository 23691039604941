import React, { Component } from "react";

import { FormattedMessage } from "react-intl";

import "./style.css";

export const errorBoundary = WrappedComponent =>
    class ErrorBoundary extends Component {
        constructor(props) {
            super(props);
            this.state = {
                error: false
            };
        }

        componentDidCatch(error, info) {
            console.error(error, info);

            this.setState({ error: true });
        }

        render() {
            const { error } = this.state;

            return error ? (
                <div className="c-error-boundary">
                    <i className="fas fa-exclamation-triangle warning-icon" />
                    <div className="message-container">
                        <FormattedMessage id="c-error-boundary.error-message" />
                    </div>
                </div>
            ) : (
                <WrappedComponent {...this.props} />
            );
        }
    };
