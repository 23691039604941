import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Row, Col } from "antd";
import { useIntl, FormattedHTMLMessage, FormattedMessage } from "react-intl";
import ISO8601DayField from "@ts-digital/arc/antd/ISO8601DayField";
import { form } from "@ts-digital/conv-redux-form";
import { faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle as faInfoCircleSolid } from "@fortawesome/pro-solid-svg-icons";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { Field } from "redux-form";
import { Button } from "@ts-digital/vrc";
import { useHistory } from "react-router";

import ElevioInfoCircle from "../../../../elevio-info-circle";
import TextInput from "../../../../general/form-text-input";
import LoadingButton from "../../../../general/loading-button";
import { downloadCctPdf } from "../../../../../utils/form-schema/download-cct-pdf";
import DaySelect from "./daymonth";
import PecCheck from "./check";
import Checkbox from "./checkbox";
import GenderCombo from "./gendercombo";
import CountryCombo from "./countrycombo";
import { RDC_VADEMECUM_URL } from "../../../../../config";
import {
    Container,
    SubtitleContainer,
    ButtonContainer,
    VademecumContainer,
    BalanceSheetDateIcon,
    BalanceSheetDateMessage,
    SectionTitle,
    SectionSubtitle,
    StyledSwitch,
    BalanceSheetDateInfoContainer,
    BalanceSheetDateIconContainer,
    SectionTitleContainer,
    LastRow,
    InfoContainer,
    RegistryFormSubtitleContainer,
    SmartSetupSwitchContainer,
    SmartCheckboxContainer,
    SmartCheckboxLabelContainer,
    AutocompleteToggleContainer,
    SetDateButtonContainer,
    DaySelectContainer,
    BalanceSheetDateContainerTable,
    CalendarIcon
} from "./styled";
import { SmartInfoContainer, Description, StatusIcon } from "../../styled";

export const DownloadPdfForm = ({
    displayAutoCompleteToggle,
    handleSubmit,
    initialValues,
    isAdmin,
    isLoading,
    itemDescription,
    itemTaxId,
    onAutoCompleteToggleChange,
    smartSelected,
    smartIsEnabled,
    stateBalanceSheetDate,
    change
}) => {
    const { formatMessage } = useIntl();
    const [componentDisabled, setComponentDisabled] = useState(false);
    const [precompileRdCSelected, setPrecompileRdCSelected] = useState(false);
    const history = useHistory();
    const lastDayOfCurrentYear = moment(new Date(new Date().getFullYear(), 11, 31)).format("YYYY-MM-DD");

    let gainsightTrack = "";

    if (smartIsEnabled) {
        if (displayAutoCompleteToggle) {
            gainsightTrack = "archive-confirm-smart-1004";
        } else {
            gainsightTrack = "archive-confirm-smart-1000";
        }
    }

    return (
        <>
            <Container>
                <SubtitleContainer>
                    <div>
                        <FormattedMessage id="c-cct-provisioning-download-pdf-form.subtitle" />
                    </div>
                    <span>
                        <b>{itemDescription}</b>
                        {" - "}
                    </span>
                    <FormattedHTMLMessage
                        id="c-cct-provisioning-download-pdf-form.fiscal-code"
                        values={{ taxId: itemTaxId }}
                    />
                </SubtitleContainer>
                <Row gutter={16} type="flex" align="middle">
                    <Col xs={{ span: 14 }} xl={{ span: 12 }}>
                        <BalanceSheetDateContainerTable>
                            <DaySelectContainer>
                                <DaySelect
                                    name="balanceSheetDate"
                                    label={
                                        <FormattedMessage id="c-cct-provisioning-download-pdf-form.fields.balanceSheetDate" />
                                    }
                                    disabled={!isAdmin}
                                />
                            </DaySelectContainer>
                            <SetDateButtonContainer
                                disabled={!isAdmin || stateBalanceSheetDate === lastDayOfCurrentYear}
                            >
                                <Button
                                    kind="secondary"
                                    onClick={() => {
                                        change("balanceSheetDate", lastDayOfCurrentYear);
                                    }}
                                    disabled={!isAdmin || stateBalanceSheetDate === lastDayOfCurrentYear}
                                >
                                    <CalendarIcon icon={faCalendar}></CalendarIcon>
                                    <FormattedMessage id="c-cct-provisioning-download-pdf-form.set-date" />
                                </Button>
                            </SetDateButtonContainer>
                        </BalanceSheetDateContainerTable>
                    </Col>
                    <Col xs={{ span: 12 }} xl={{ span: 8 }}>
                        {!isAdmin && (
                            <BalanceSheetDateInfoContainer>
                                <BalanceSheetDateIconContainer>
                                    <BalanceSheetDateIcon
                                        color={!initialValues.balanceSheetDate ? "#d82829" : "#09822A"}
                                        icon={!initialValues.balanceSheetDate ? faExclamationCircle : faCheckCircle}
                                    />
                                </BalanceSheetDateIconContainer>
                                <BalanceSheetDateMessage>
                                    <FormattedMessage
                                        id={
                                            !initialValues.balanceSheetDate
                                                ? "c-cct-provisioning-download-pdf-form.balanceSheetDate.warning"
                                                : "c-cct-provisioning-download-pdf-form.balanceSheetDate.info"
                                        }
                                    />
                                </BalanceSheetDateMessage>
                            </BalanceSheetDateInfoContainer>
                        )}
                    </Col>
                </Row>

                <SectionTitleContainer>
                    <SectionTitle>
                        <FormattedMessage id="c-cct-provisioning-download-pdf-form.conservation-manager" />
                    </SectionTitle>
                </SectionTitleContainer>
                {smartIsEnabled && (
                    <Row gutter={6} type="flex">
                        <Col xs={{ span: 16 }} xl={{ span: 16 }}>
                            <InfoContainer>
                                {displayAutoCompleteToggle && (
                                    <>
                                        <div style={{ marginBottom: "16px" }}>
                                            <Description>
                                                <StatusIcon icon={faInfoCircleSolid} color="#005075" margintop="3px" />
                                                <FormattedHTMLMessage
                                                    id="cct-smart-info-managed"
                                                    values={{ description: itemDescription }}
                                                />
                                                <ElevioInfoCircle articleId="654" />
                                            </Description>
                                            <SmartCheckboxContainer>
                                                <Checkbox
                                                    name="smart"
                                                    onChange={(_, val) => {
                                                        onAutoCompleteToggleChange(val, val);
                                                        setPrecompileRdCSelected(val);
                                                        setComponentDisabled(val);
                                                    }}
                                                />
                                                <SmartCheckboxLabelContainer>
                                                    <FormattedHTMLMessage id="c-cct-provisioning-download-pdf-form.archive-smart-declare" />
                                                </SmartCheckboxLabelContainer>
                                            </SmartCheckboxContainer>
                                        </div>
                                        <div>
                                            <SmartSetupSwitchContainer>
                                                <StyledSwitch
                                                    checked={precompileRdCSelected}
                                                    disabled={smartSelected}
                                                    onChange={val => {
                                                        setPrecompileRdCSelected(val);
                                                        onAutoCompleteToggleChange(val, smartSelected);
                                                    }}
                                                />
                                            </SmartSetupSwitchContainer>
                                            <FormattedMessage
                                                id={"c-cct-provisioning-download-pdf-form.fill-in-manager-data"}
                                            />
                                        </div>
                                    </>
                                )}
                                {!displayAutoCompleteToggle && (
                                    <SmartInfoContainer>
                                        <Description>
                                            <StatusIcon icon={faInfoCircleSolid} color="#005075" margintop="3px" />
                                            <FormattedHTMLMessage
                                                id="cct-smart-info"
                                                values={{ description: itemDescription }}
                                            />
                                            <ElevioInfoCircle articleId="654" />
                                        </Description>
                                    </SmartInfoContainer>
                                )}
                            </InfoContainer>
                        </Col>
                    </Row>
                )}
                {!smartIsEnabled && displayAutoCompleteToggle && (
                    <AutocompleteToggleContainer>
                        <StyledSwitch onChange={checked => onAutoCompleteToggleChange(checked, false)} />
                        <FormattedMessage id={"c-cct-provisioning-download-pdf-form.fill-in-manager-data"} />
                    </AutocompleteToggleContainer>
                )}
                <SectionSubtitle>
                    <FormattedMessage id="c-manage-data.edit-details.registry-title" />
                    <RegistryFormSubtitleContainer>
                        <FormattedMessage id="c-cct-provisioning-download-pdf-form.registry-title" />
                    </RegistryFormSubtitleContainer>
                </SectionSubtitle>
                <VademecumContainer>
                    <FormattedMessage id="c-cct-provisioning-download-pdf-form.rdc-subtitle" />
                    <a href={RDC_VADEMECUM_URL} target="_blank" rel="noopener noreferrer">
                        <FormattedMessage id="c-cct-provisioning-download-pdf-form.rdc-link" />
                    </a>
                </VademecumContainer>
                <Row gutter={16} type="flex" align="top">
                    <Col xs={{ span: 12 }} xl={{ span: 4 }}>
                        <Field
                            id="name"
                            name="name"
                            label={formatMessage({ id: "general.name" })}
                            component={TextInput}
                            placeholder={formatMessage({ id: "c-cct-provisioning-download-pdf-form.insert-name" })}
                            disabled={componentDisabled}
                        />
                    </Col>
                    <Col xs={{ span: 12 }} xl={{ span: 4 }}>
                        <Field
                            id="surname"
                            name="surname"
                            label={formatMessage({ id: "general.lastname" })}
                            component={TextInput}
                            placeholder={formatMessage({ id: "c-cct-provisioning-download-pdf-form.insert-lastname" })}
                            disabled={componentDisabled}
                        />
                    </Col>
                    <Col xs={{ span: 12 }} xl={{ span: 4 }}>
                        <GenderCombo
                            label={formatMessage({ id: "general.gender" })}
                            name="gender"
                            disabled={componentDisabled}
                        />
                    </Col>
                </Row>
                <Row gutter={16} type="flex" align="top">
                    <Col xs={{ span: 12 }} xl={{ span: 4 }}>
                        <CountryCombo
                            label={formatMessage({ id: "c-cct-provisioning-download-pdf-form.country" })}
                            name="country"
                            placeholder={formatMessage({
                                id: "c-cct-provisioning-download-pdf-form.insert-country"
                            })}
                            disabled={componentDisabled}
                        />
                    </Col>
                    <Col xs={{ span: 12 }} xl={{ span: 4 }}>
                        <Field
                            id="birthPlace"
                            name="birthPlace"
                            label={formatMessage({ id: "general.birth-place" })}
                            component={TextInput}
                            placeholder={formatMessage({
                                id: "c-cct-provisioning-download-pdf-form.insert-birthplace"
                            })}
                            disabled={componentDisabled}
                        />
                    </Col>
                    <Col xs={{ span: 12 }} xl={{ span: 4 }}>
                        <ISO8601DayField
                            colon={false}
                            displayFormat={"DD/MM/YYYY"}
                            label={<FormattedMessage id="general.birth-date" />}
                            name="birthDate"
                            placeholder={formatMessage({
                                id: "c-cct-provisioning-download-pdf-form.insert-birthdate"
                            })}
                            disabled={componentDisabled}
                        />
                    </Col>
                    <Col xs={{ span: 12 }} xl={{ span: 4 }}>
                        <Field
                            id="taxId"
                            name="taxId"
                            label={formatMessage({ id: "general.codice-fiscale" })}
                            normalize={value => value.toUpperCase().trim()}
                            component={TextInput}
                            placeholder={formatMessage({
                                id: "c-cct-provisioning-download-pdf-form.insert-fiscal-code"
                            })}
                            disabled={componentDisabled}
                        />
                    </Col>
                </Row>
                {!displayAutoCompleteToggle && smartIsEnabled && (
                    <>
                        <SectionSubtitle>
                            <FormattedMessage id="c-cct-provisioning-download-pdf-form.archive-smart" />
                            <ElevioInfoCircle articleId="654" />
                        </SectionSubtitle>
                        <SmartCheckboxContainer>
                            <Checkbox name="smart" disabled={initialValues.smart} />
                            <SmartCheckboxLabelContainer>
                                <FormattedHTMLMessage id="c-cct-provisioning-download-pdf-form.archive-smart-declare" />
                            </SmartCheckboxLabelContainer>
                        </SmartCheckboxContainer>
                    </>
                )}
                <SectionSubtitle>
                    <FormattedMessage id="c-cct-provisioning-download-pdf-form.rdc-email-address" />
                </SectionSubtitle>
                <LastRow>
                    <Row gutter={16} type="flex" align="top">
                        <Col xs={{ span: 12 }} xl={{ span: 8 }}>
                            <Field
                                id="email"
                                name="email"
                                normalize={value => value.trim()}
                                label={formatMessage({ id: "general.email" })}
                                component={TextInput}
                                placeholder={formatMessage({
                                    id: "c-cct-provisioning-download-pdf-form.insert-email"
                                })}
                                disabled={componentDisabled}
                            />
                        </Col>
                        <Col xs={{ span: 12 }} xl={{ span: 4 }}>
                            <PecCheck
                                name="isPec"
                                label={formatMessage({ id: "general.pec" })}
                                disabled={componentDisabled}
                            />
                        </Col>
                    </Row>
                </LastRow>
            </Container>

            <ButtonContainer>
                <Button kind="secondary" onClick={() => history.goBack()}>
                    <FormattedMessage id="general.annulla" />
                </Button>
                <LoadingButton
                    disabled={!isAdmin && !initialValues.balanceSheetDate}
                    variant="primary"
                    loading={isLoading}
                    onClick={handleSubmit}
                    dataGainsightTrack={smartSelected ? gainsightTrack : ""}
                >
                    {smartSelected ? (
                        <FormattedMessage id="c-cct-provisioning-download-pdf-form.archive-smart-rdc-confirm" />
                    ) : (
                        <FormattedMessage id="c-cct-provisioning-download-pdf-form.title" />
                    )}
                </LoadingButton>
            </ButtonContainer>
        </>
    );
};

DownloadPdfForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    itemDescription: PropTypes.string.isRequired,
    itemTaxId: PropTypes.string.isRequired,
    smartSelected: PropTypes.bool.isRequired,
    smartIsEnabled: PropTypes.bool
};

const formDefinition = {
    enableReinitialize: true,
    form: "download-cct-pdf-form",
    schema: downloadCctPdf
};

export default form(formDefinition)(DownloadPdfForm);
