import {
    GET_WAITINGLIST_ERROR,
    GET_WAITINGLIST_START,
    GET_WAITINGLIST_SUCCESS,
    WAITINGLIST_RESET
} from "../../actions/waitinglist/read";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {}
    }
};

export function getWaitingList(state = defaultState, { type, payload }) {
    switch (type) {
        case GET_WAITINGLIST_START:
            return {
                status: {
                    ...defaultState.status,
                    started: true
                }
            };
        case GET_WAITINGLIST_ERROR:
            return {
                status: {
                    ...state.status,
                    started: false,
                    error: true,
                    errorInfo: payload.error
                }
            };
        case GET_WAITINGLIST_SUCCESS:
            return {
                status: {
                    ...defaultState.status,
                    ended: true
                },
                ...payload
            };
        case WAITINGLIST_RESET:
            return defaultState;
        default:
            return state;
    }
}
