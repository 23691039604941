import styled from "styled-components";

export const SectionTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
`;

export const Title = styled.div`
    font-family: Roboto;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.44px;
    color: #152935;
`;
