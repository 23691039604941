import React from "react";
import PropTypes from "prop-types";

import { Icon, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";

const RemoveColumn = ({ onClick }) => (
    <Tooltip placement="right" title={<FormattedMessage id="c-user-create-modal.deselect-item" />}>
        <Icon onClick={onClick} type="minus-circle" style={{ color: "#1890ff" }} />
    </Tooltip>
);

RemoveColumn.propTypes = {
    onClick: PropTypes.func.isRequired
};

export { RemoveColumn };
