import React from "react";
import PropTypes from "prop-types";

import { ActionButton, AppLevelButtonContainer, AppLevelEditButton } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faUserTimes } from "@fortawesome/pro-regular-svg-icons";
import { Tooltip } from "antd";
import { FormattedMessage } from "react-intl";

const ItemActionsCell = ({ onDelete, onEditItem, onEditApp, row }) => {
    if (row.depth === 0) {
        return (
            <>
                <ActionButton kind="tertiary" onClick={onEditItem} data-gainsight-track={"edit-user-from-summary"}>
                    <FontAwesomeIcon icon={faPen} />
                </ActionButton>

                <ActionButton kind="tertiary" onClick={onDelete} data-gainsight-track={"open-delete-from-summary"}>
                    <FontAwesomeIcon icon={faUserTimes} />
                </ActionButton>
            </>
        );
    }

    if (row.depth === 1) {
        // Only display tooltip when the user currently has a global role on the item
        // Passing a "visible" prop to the tooltip component, even if it is set to undefined, excludes the automatic mouse hover detection,
        // thus when we need the tooltip we must not provide a "visible" prop to the component. (workaround)
        const additionalTooltipProps = row.original.globalRole ? {} : { visible: false };
        return (
            <AppLevelButtonContainer>
                <Tooltip
                    title={
                        <FormattedMessage id="c-item-roles-table.item-actions-cell.edit-app-permissions-button.tooltip" />
                    }
                    {...additionalTooltipProps}
                >
                    <AppLevelEditButton
                        kind="tertiary"
                        onClick={onEditApp}
                        disabled={row.original.globalRole}
                        data-gainsight-track={"edit-role-from-summary"}
                    >
                        <FontAwesomeIcon icon={faPen} />
                    </AppLevelEditButton>
                </Tooltip>
            </AppLevelButtonContainer>
        );
    }

    return <></>;
};

ItemActionsCell.propTypes = {
    /** Invoked when the delete button is clicked */
    onDelete: PropTypes.func.isRequired,
    /** Invoked when the edit button is clicked in an app-level row */
    onEditApp: PropTypes.func.isRequired,
    /** Invoked when the edit button is clicked in an item-level row */
    onEditItem: PropTypes.func.isRequired,
    /** Provided by the table */
    row: PropTypes.shape({
        depth: PropTypes.number,
        original: PropTypes.shape({
            /** Indicates that the user has an app-level role */
            globalRole: PropTypes.bool
        })
    })
};

export default ItemActionsCell;
