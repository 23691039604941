import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { Form, Table, Modal, Button, Badge, Popover } from "antd";
import { includes } from "ramda";
import { RoleAssociationList, RoleAssociation } from "../../../assets/thrift/gdprlog-config/gdprlog_types";

const columns = [
    {
        title: <FormattedHTMLMessage id="gdpr.card.column.TSID" />,
        dataIndex: "tsid",
        key: "tsid"
    },
    {
        title: <FormattedHTMLMessage id="gdpr.card.column.agyoId" />,
        dataIndex: "agyoId",
        key: "agyoId"
    },
    {
        title: <FormattedHTMLMessage id="gdpr.card.column.name" />,
        dataIndex: "name",
        key: "name"
    },
    {
        title: <FormattedHTMLMessage id="gdpr.card.column.surname" />,
        dataIndex: "surname",
        key: "surname"
    },
    {
        title: <FormattedHTMLMessage id="gdpr.card.column.status" />,
        dataIndex: "confirmed",
        render: val =>
            val === true ? (
                <Badge status="success" text={<FormattedHTMLMessage id="gdpr.card.column.status.confirmed" />} />
            ) : val === false ? (
                <Badge status="warning" text={<FormattedHTMLMessage id="gdpr.card.column.status.unconfirmed" />} />
            ) : (
                <Badge status="error" text={<FormattedHTMLMessage id="gdpr.card.column.status.disabled" />} />
            )
    }
];

class GdprComponent extends Component {
    static propTypes = {
        company: PropTypes.object.isRequired,
        users: PropTypes.object.isRequired,
        usersGdprEnabled: PropTypes.object,
        gdprLogAssociation: PropTypes.func.isRequired,
        email: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            mConfirmGDPR: false,
            selectedRowKeys: []
        };
    }

    selectRow = record => {
        const selectedRowKeys = [this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.setState({ selectedRowKeys });
    };

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    confirmGDPRUsers() {
        const { gdprLogAssociation, company, users, email } = this.props;
        const { selectedRowKeys } = this.state;

        let roleAssociationList = new RoleAssociationList();
        roleAssociationList.gdprLogUsers = [];
        selectedRowKeys.forEach(function (value) {
            let roleAssociation = new RoleAssociation();
            roleAssociation["agyoId"] = value;
            if (users.users != null || users.users !== undefined) {
                users.users.forEach(val => {
                    if (value === val.user.profile.id) {
                        roleAssociation["tsid"] = val.user.profile.tsid;
                        roleAssociation["firstName"] = val.user.profile.firstName;
                        roleAssociation["lastName"] = val.user.profile.lastName;
                        roleAssociation["pec"] = email.pecAddress;
                        roleAssociation["companyId"] = company.base.id;
                        roleAssociation["confirmed"] = false;
                        roleAssociationList.gdprLogUsers.push(roleAssociation);
                    }
                });
            }
        });
        const recipientData = {
            ragioneSociale: company.base.details.description,
            companyId: company.base.uuid,
            pec: ""
        };
        gdprLogAssociation(roleAssociationList, recipientData);
        this.handleClose("mConfirmGDPR");
    }

    createTableRow(obj) {
        const { company, email } = this.props;
        let singleEnabled = {};
        singleEnabled["tsid"] = obj.tsid;
        singleEnabled["agyoId"] = obj.agyoId;
        singleEnabled["name"] = obj.firstName;
        singleEnabled["surname"] = obj.lastName;
        singleEnabled["pec"] = email.pecAddress;
        singleEnabled["aziendaId"] = company.base.id;
        singleEnabled["confirmed"] = obj.confirmed;
        return singleEnabled;
    }
    getData() {
        const { usersGdprEnabled, users } = this.props;
        let row = {};
        let listUsers = [];
        if (users.users !== null || users.users !== undefined) {
            users.users.forEach(value => {
                if (includes("@", value.user.profile.id)) {
                    if (
                        usersGdprEnabled.config &&
                        usersGdprEnabled.config.gdprLogUsers &&
                        usersGdprEnabled.config.gdprLogUsers.length > 0
                    ) {
                        let listGDPREnabled = usersGdprEnabled.config.gdprLogUsers;
                        let found = listGDPREnabled.find(o => o.agyoId === value.user.profile.id);
                        if (found) {
                            row = this.createTableRow(found);
                            listUsers.push(row);
                        } else {
                            value.user.profile.agyoId = value.user.profile.id;
                            row = this.createTableRow(value.user.profile);
                            listUsers.push(row);
                        }
                    } else {
                        value.user.profile.agyoId = value.user.profile.id;
                        row = this.createTableRow(value.user.profile);
                        listUsers.push(row);
                    }
                }
            });
        }

        return listUsers;
    }

    handleClose = modalToClose => {
        this.setState({ [modalToClose]: false });
    };

    renderModalConfirmUser() {
        const { mConfirmGDPR } = this.state;
        const { usersGdprEnabled } = this.props;
        let userEnabled =
            usersGdprEnabled.config &&
            usersGdprEnabled.config.gdprLogUsers &&
            usersGdprEnabled.config.gdprLogUsers.length > 0;
        return mConfirmGDPR ? (
            <div>
                <Modal
                    cancelText={<FormattedHTMLMessage id="general.annulla" />}
                    okText={<FormattedHTMLMessage id="general.ok" />}
                    onCancel={() => this.handleClose("mConfirmGDPR")}
                    visible={this.state.mConfirmGDPR}
                    onOk={() => this.confirmGDPRUsers()}
                    style={{ top: "20%" }}
                    title={<FormattedHTMLMessage id="gdpr.card.modal.title" />}
                >
                    {
                        <p>
                            <FormattedHTMLMessage id="gdpr.card.modal.message1" />
                            <span>
                                {userEnabled ? <FormattedHTMLMessage id="gdpr.card.modal.message2" /> : null}
                                <FormattedHTMLMessage id="gdpr.card.modal.message3" />
                            </span>
                        </p>
                    }
                </Modal>
            </div>
        ) : null;
    }
    renderUserEnabled() {
        const { selectedRowKeys } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps: record => ({
                disabled: record.tsid == null || record.tsid.length === 0, // Column configuration not to be checked
                tsid: record.tsid
            })
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <div>
                <Form>
                    <div style={{ textAlign: "center" }}>
                        <span style={{ fontWeight: "bold" }}>
                            {<FormattedMessage id="gdpr.card.utenti.disponibili" />}
                            <Popover content={<FormattedMessage id="gdpr.card.utenti.info" />}>
                                <i className="fas fa-info-circle" />
                            </Popover>
                        </span>
                    </div>
                    <br />
                    {
                        <Table
                            pagination={{ pageSize: 7 }}
                            rowSelection={rowSelection}
                            columns={columns}
                            dataSource={this.getData()}
                            bordered
                            rowKey={value => value.agyoId}
                        />
                    }
                    <div style={{ textAlign: "center" }}>
                        <span>
                            <Button
                                style={{ fontWeight: "bold" }}
                                disabled={!hasSelected}
                                onClick={() => this.setState({ mConfirmGDPR: true })}
                            >
                                {<FormattedMessage id="gdpr.card.button.email" />}
                            </Button>
                        </span>
                    </div>
                </Form>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderUserEnabled()}
                {this.renderModalConfirmUser()}
            </div>
        );
    }
}

export default GdprComponent;
