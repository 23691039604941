import React from "react";
import PropTypes from "prop-types";

import { Button } from "@ts-digital/vrc";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

import {
    CheckIconContainer,
    Container,
    Content,
    ContactToBuyContainer,
    CloseButtonContainer,
    Footer,
    Title,
    Description,
    TitleWrapper,
    CustomTooltip
} from "./styled";
import WaitingListButton from "../waitlist-button";

import "./style.css";

const ServiceCard = ({
    active,
    disable,
    description,
    display,
    expanded = false,
    expandedComponent,
    onActive,
    onActivateSubscription,
    onConfigure,
    onClose,
    buyLink,
    showActiveButton,
    showActivateSubscriptionButton,
    showConfigureButton,
    showContactToBuy,
    showBuyButton,
    title,
    tooltip,
    serviceInfo, // Informazioni dettagliate sul servizio ( oggetto da agyoServices )
    companyId, // id dell'item selezionato
    getWaitingList, // action per il recupero della waitinglist
    getWaitingListStatus, // stato della chiamata per lo stato della waitinglist
    waitinglist, // oggetto contenente la waitinglist recuperata
    subscribeWaitinglist, // action per la sottoscrizione alla waitinglist
    deleteWaitinglist, // action per la cancellazione dalla waitinglist
    waitinglistDeleteStatus, // stato della chiamata per la cancellazione della waitinglist
    waitinglistSubscribeStatus, // stato della chiamata alla subscribe della waiting list
    user
}) => {
    const serviceId = serviceInfo && serviceInfo.id;
    const earlyAccess = serviceInfo && serviceInfo.earlyAccess;
    const waitlistedapp = waitinglist && waitinglist.filter(ele => ele.serviceId === serviceInfo.id);
    const waitingListed = waitlistedapp && waitlistedapp.map(ele => ele.status === "PENDING")[0];

    let created_at = "";
    let created_by = "";
    if (waitingListed) {
        created_at = waitlistedapp[0].created_at;
        created_by = waitlistedapp[0].created_by;
    }

    return (
        display && (
            <Container expanded={expanded}>
                {active && !expanded && (
                    <CheckIconContainer>
                        <i className="fas fa-check-circle" />
                    </CheckIconContainer>
                )}
                {active && expanded && (
                    <CloseButtonContainer>
                        <Button kind="tertiary" onClick={onClose}>
                            <i className="fas fa-times" />
                        </Button>
                    </CloseButtonContainer>
                )}
                <Content>
                    <TitleWrapper>
                        {title && (
                            <Title>
                                <FormattedMessage id={title} />
                            </Title>
                        )}
                        {tooltip && (
                            <CustomTooltip title={<FormattedHTMLMessage id={tooltip} />} placement="bottom">
                                <i className="fas fa-info-circle" />
                            </CustomTooltip>
                        )}
                    </TitleWrapper>
                    {description && (
                        <Description>
                            <FormattedMessage id={description} />
                        </Description>
                    )}
                </Content>
                {expanded ? (
                    <Content>{expandedComponent}</Content>
                ) : (
                    <Footer>
                        {!disable && showConfigureButton && (
                            <Button size="small" onClick={onConfigure}>
                                <FormattedMessage id="general.configures" />
                            </Button>
                        )}
                        {!disable && active && showActivateSubscriptionButton && (
                            <Button size="small" onClick={onActivateSubscription}>
                                <FormattedMessage id="general.activate-subscription" />
                            </Button>
                        )}
                        {!disable && !active && showActiveButton && (
                            <Button size="small" onClick={onActive}>
                                <FormattedMessage id="general.active" />
                            </Button>
                        )}
                        {!disable && !active && earlyAccess && (
                            <WaitingListButton
                                companyId={companyId}
                                getWaitingList={getWaitingList}
                                getWaitingListStatus={getWaitingListStatus}
                                waitingListed={waitingListed}
                                waitingListedCreatedBy={created_by}
                                waitingListedCreatedAt={created_at}
                                subscribeWaitinglist={subscribeWaitinglist}
                                waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                                waitinglistDeleteStatus={waitinglistDeleteStatus}
                                deleteWaitinglist={deleteWaitinglist}
                                appName={serviceId}
                                buttonKind="secondary"
                                buttonSize="small"
                                user={user}
                            />
                        )}
                        {!disable && !active && showBuyButton && (
                            <a href={buyLink} target="_blank" rel="noopener noreferrer">
                                <Button size="small">
                                    <FormattedMessage id="general.buy-package" />
                                </Button>
                            </a>
                        )}
                        {!disable && !active && showContactToBuy && (
                            <ContactToBuyContainer>
                                <FormattedMessage id="general.contact-to-buy" />
                            </ContactToBuyContainer>
                        )}
                    </Footer>
                )}
            </Container>
        )
    );
};

ServiceCard.propTypes = {
    active: PropTypes.bool,
    disable: PropTypes.bool,
    expanded: PropTypes.bool,
    expandedComponent: PropTypes.object,
    onActive: PropTypes.func,
    onActivateSubscription: PropTypes.func,
    onConfigure: PropTypes.func,
    onClose: PropTypes.func,
    showActiveButton: PropTypes.bool,
    showActivateSubscriptionButton: PropTypes.bool,
    showConfigureButton: PropTypes.bool,
    showBuyButton: PropTypes.bool,
    showContactToBuy: PropTypes.bool,
    description: PropTypes.any,
    display: PropTypes.bool,
    title: PropTypes.any,
    tooltip: PropTypes.string,
    // props for waitinglist
    companyId: PropTypes.string,
    getWaitingList: PropTypes.func,
    waitinglist: PropTypes.array,
    subscribeWaitinglist: PropTypes.func,
    waitinglistSubscribeStatus: PropTypes.object,
    getWaitingListStatus: PropTypes.object,
    serviceInfo: PropTypes.object,
    user: PropTypes.object
};

export default ServiceCard;
