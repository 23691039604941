import React, { useEffect, useMemo } from "react";

import { ConfigProvider } from "antd";
import it_IT from "antd/lib/locale-provider/it_IT";
import moment from "moment";
import "moment/locale/it";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { Switch } from "react-router-dom";
import { compose } from "redux";

import { errorBoundary } from "../components/general/error-boundary";
import UserVoiceComp from "../components/general/user-voice/index";
import { getMessagesFromLocale } from "../i18n";
import TimedOverflow from "../views/timed-overflow";
import TimedToast from "../views/timed-toast";
import AuthenticatedRoutes from "./authenticated-routes";
import CustomRoute from "./custom-route";
import InvitationPrelogin from "./invitation-prelogin";
import Jump from "./jump";
import PreLogin from "./login/pre-login";
import ResetPassword from "./login/reset-password";
import resetPasswordSuccess from "./login/reset-password-success";
import UserActivation from "./user-activation";
import { UserActivationError } from "./user-activation-error";
import { UserActivationSuccess } from "./user-activation-success";
import UserActivationWithPassword from "./user-activation-with-password";

moment.locale("it");

const Routes = ({ user, userLocale }) => {
    const messages = useMemo(() => getMessagesFromLocale(userLocale), [userLocale]);

    // Set the language of cookie banner
    useEffect(() => {
        // Make sure OneTrust is globaly available
        if (window.OneTrust) {
            window.OneTrust.changeLanguage(userLocale.split("-")[0]);
        }
    }, [userLocale]);

    return (
        <ConfigProvider locale={it_IT}>
            <IntlProvider
                defaultLocale="it-IT"
                key={userLocale}
                locale={userLocale}
                messages={messages}
                timeZoneName="it-IT"
            >
                <div>
                    <Switch>
                        <CustomRoute component={PreLogin} exact path="/login" />
                        <CustomRoute component={InvitationPrelogin} exact path="/invitation-prelogin" />
                        <CustomRoute component={UserActivation} exact path="/user/:userId/activate" />
                        <CustomRoute component={UserActivationSuccess} exact path="/user/:userId/activate/success" />
                        <CustomRoute component={UserActivationError} exact path="/user/:userId/activate/error" />
                        <CustomRoute
                            component={UserActivationWithPassword}
                            exact
                            path="/user/:userId/activate/withPassword"
                        />
                        <CustomRoute
                            component={UserActivationSuccess}
                            exact
                            path="/user/:userId/activate/withPassword/success"
                        />
                        <CustomRoute
                            component={UserActivationError}
                            exact
                            path="/user/:userId/activate/withPassword/error"
                        />
                        <CustomRoute
                            component={ResetPassword}
                            exact
                            name="password-reset"
                            path="/resetPassword/utenza/:userId/token/:token"
                        />
                        <CustomRoute component={resetPasswordSuccess} exact path="/resetPassword/success" />
                        <CustomRoute path="/jump/(.*)?" component={Jump} />
                        <CustomRoute path="*" component={AuthenticatedRoutes} authenticatedRoute={true} />
                    </Switch>
                    <TimedOverflow />
                    <TimedToast />
                    <UserVoiceComp user={user.user} userVoiceScript={window.UserVoice} />
                </div>
            </IntlProvider>
        </ConfigProvider>
    );
};

const mapStateToProps = state => ({
    user: state.user,
    userLocale: state.user.user ? state.user.user.profile.language : navigator.language
});

const composedHoc = compose(errorBoundary, connect(mapStateToProps));

export default composedHoc(Routes);
