import React, { Component } from "react";
import PropTypes from "prop-types";

import { Spin } from "antd";
import { connect } from "react-redux";
import { compose } from "redux";
import { includes } from "ramda";

import { getAdempimentiConfig, resetAdempimentiConfig } from "../../actions/services/a-fiscali-iva";

import { getGdprLogRoleAssociation } from "../../actions/services/gdprlog";
import Apps from "../../components/apps";
import { errorBoundary } from "../../components/general/error-boundary";

class AppsView extends Component {
    static propTypes = {
        company: PropTypes.object.isRequired,
        getAdempimentiConfig: PropTypes.func.isRequired,
        getSignatureConfig: PropTypes.func.isRequired,
        getGdprLogRoleAssociation: PropTypes.func.isRequired,
        resetAdempimentiConfig: PropTypes.func.isRequired,
        services: PropTypes.object.isRequired,
        userRoles: PropTypes.array.isRequired
    };

    componentDidMount() {
        const { company, getAdempimentiConfig, getGdprLogRoleAssociation, resetAdempimentiConfig, services } =
            this.props;
        let companyData = company.base;

        if (services.active.services) {
            resetAdempimentiConfig();
            if (includes("A-FISCALI-IVA-FLOW", services.active.services)) {
                getAdempimentiConfig(companyData.id);
            }
            if (includes("GDPR-LOG", services.active.services)) {
                getGdprLogRoleAssociation(companyData.id);
            }
        }
    }

    render() {
        const { categories, company, services, userRoles } = this.props;

        return services.active.services ? (
            <div>
                <div>
                    <i className="fas fa-th-large" style={{ marginLeft: "20px", fontSize: "25px" }} />
                    <span style={{ fontSize: "25px", margin: "10px" }}>{"Apps"}</span>
                    <br />
                </div>
                <Apps categories={categories} company={company} services={services} userRoles={userRoles} />
            </div>
        ) : (
            <Spin />
        );
    }
}

function mapStateToProps(state, props) {
    return {
        categories: state.services.categories.data,
        company: state.companies.data[props.match.params.item]
            ? state.companies.data[props.match.params.item].item
            : {},
        services: state.services,
        userRoles: (state.user && state.user.user && state.user.user.roles) || []
    };
}

const actions = {
    getAdempimentiConfig,
    getGdprLogRoleAssociation,
    resetAdempimentiConfig
};

const composedHoc = compose(connect(mapStateToProps, actions), errorBoundary);

export default composedHoc(AppsView);
