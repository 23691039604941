import styled from "styled-components";

export const StyledButtonTable = styled.div`
    padding: 8px;

    & button {
        width: 100%;
    }
`;

export const StyledTextContainer = styled.div`
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 40px;
    font-size: 12px;
`;

export const DateTextContainer = styled.div`
    margin-top: 5px;
`;

export const BorderedDiv = styled.div`
    border-bottom-color: #c9d9e8;
    border-bottom-style: solid;
    border-bottom-width: 1px;
`;
