import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedHTMLMessage } from "react-intl";

import FontawesomeIcon from "../../../general/fontawesome-icon";

import { StyledButton, StyledIcon, StyledModal, SubText, Text, Title } from "./styled";

class CompanyCreateModalConfirm extends Component {
    static propTypes = {
        visibleState: PropTypes.bool,
        handleClose: PropTypes.func,
        handleContinue: PropTypes.func
    };
    render() {
        const { visibleState, handleClose, handleContinue } = this.props;

        return (
            <StyledModal visible={visibleState} onCancel={handleClose} width="65%" min-width="800px" footer={false}>
                <StyledIcon>
                    <FontawesomeIcon color="#fdb927" name="exclamation-circle" size="32px" solid={true} />
                </StyledIcon>
                <Title>
                    <FormattedHTMLMessage id="c-createcompany-confirm.title" />
                </Title>
                <Text>
                    <FormattedHTMLMessage id="c-createcompany-confirm.text" />
                </Text>
                <SubText>
                    <FormattedHTMLMessage id="c-createcompany-confirm.subText" />
                </SubText>

                <StyledButton onClick={() => handleContinue()} variant="secondary">
                    <FormattedHTMLMessage id="general.continue" />
                </StyledButton>
                <StyledButton onClick={() => handleClose()} variant="primary">
                    <FormattedHTMLMessage id="general.annulla" />
                </StyledButton>
            </StyledModal>
        );
    }
}

export default CompanyCreateModalConfirm;
