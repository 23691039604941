import React from "react";
import PropTypes from "prop-types";

import { includes } from "ramda";
import { connect } from "react-redux";
import { compose } from "redux";

import "../style.css";

import { getNoteSpeseConfig, activateAppNoteSpese } from "../../../actions/services/note-spese";
import { getWaitingList } from "../../../actions/waitinglist/read";
import { subscribeWaitinglist, deleteWaitinglist } from "../../../actions/waitinglist/write";

import ExpenseReports from "../../../components/expense-reports";
import { FormattedMessage } from "react-intl";
import { errorBoundary } from "../../../components/general/error-boundary";
import PageTitle from "../../../components/general/page-title";

class ExpenseReportsView extends React.Component {
    static propTypes = {
        activateAppNoteSpese: PropTypes.func.isRequired,
        company: PropTypes.object.isRequired,
        getNoteSpeseConfig: PropTypes.func.isRequired,
        history: PropTypes.object,
        services: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired
    };

    componentDidMount() {
        const { company, getNoteSpeseConfig, services } = this.props;
        if (services.active.services) {
            if (includes("NOTESPESE-FLOW", services.active.services)) {
                getNoteSpeseConfig(company.base.id);
            }
        }
    }

    render() {
        const {
            activateAppNoteSpese,
            company,
            history,
            services,
            user,
            getWaitingList,
            getWaitingListStatus,
            subscribeWaitinglist,
            deleteWaitinglist,
            waitinglistDeleteStatus,
            waitinglist,
            waitinglistSubscribeStatus
        } = this.props;
        return (
            <div className="service-view">
                <PageTitle
                    title={<FormattedMessage id="c-services-view-expense-reports" />}
                    onBack={() => history.goBack()}
                />
                <ExpenseReports
                    activateAppNoteSpese={activateAppNoteSpese}
                    company={company}
                    services={services}
                    user={user}
                    getWaitingList={getWaitingList}
                    getWaitingListStatus={getWaitingListStatus}
                    subscribeWaitinglist={subscribeWaitinglist}
                    deleteWaitinglist={deleteWaitinglist}
                    waitinglistDeleteStatus={waitinglistDeleteStatus}
                    waitinglist={waitinglist}
                    waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    services: state.services,
    user: state.user.user,
    waitinglist: state.waitinglist.read.getWaitingList.waitinglist,
    waitinglistSubscribeStatus: state.waitinglist.write.subscribeWaitinglist.status,
    waitinglistDeleteStatus: state.waitinglist.write.deleteWaitinglist.status,
    getWaitingListStatus: state.waitinglist.read.getWaitingList.status
});

const actions = {
    activateAppNoteSpese,
    getNoteSpeseConfig,
    getWaitingList,
    subscribeWaitinglist,
    deleteWaitinglist
};

const composedHoc = compose(connect(mapStateToProps, actions), errorBoundary);

export default composedHoc(ExpenseReportsView);
