import styled from "styled-components";

export const Container = styled.div`
    margin: 32px 0 80px;

    &:last-of-type {
        margin-bottom: 48px;
    }
`;

export const Title = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 24px;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.23px;
    line-height: 24px;
    margin: 0 0 8px;
`;

export const Description = styled.div`
    color: ${props => props.theme.colors.primary.yankeesBlue};
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 24px;
    margin: 0 0 40px;
`;

export const List = styled.div`
    margin: 0 0 0;
`;

export const ListColumn = styled.div`
    width: 48px;
    margin-left: 48px;
    display: flex;
    justify-content: center;
`;

export const ListHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0 0 8px;
`;

export const ListHeaderItem = styled.div`
    color: ${props => props.theme.colors.text.cadet};
    font-size: 16px;
    font-family: Cairo;
    font-weight: 600;
    text-align: center;
`;

export const ActionColumn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
`;
