import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage, useIntl } from "react-intl";
import TextField from "@ts-digital/arc/antd/TextField";
import { form } from "@ts-digital/conv-redux-form";

import { createApiKeySchema } from "../../../../../utils/form-schema/create-api-key";
import { StepButtons } from "../../../../general/accordion-stepper/step-buttons";
import { TextFieldContainer } from "./styled";

const CreateApiKey = ({ disabled, loading, handleSubmit, invalid }) => {
    const { formatMessage } = useIntl();

    return (
        <form>
            <TextFieldContainer>
                <TextField
                    disabled={disabled}
                    label={<FormattedMessage id="general.description" />}
                    name="description"
                    placeholder={formatMessage({
                        id: "c-registry-office.modal.create.field.placeholder.description"
                    })}
                />
            </TextFieldContainer>
            <StepButtons
                displayPrev={false}
                loading={loading}
                nextDisabled={invalid || disabled}
                onNext={handleSubmit}
            />
        </form>
    );
};
CreateApiKey.propTypes = {
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    // Injected by redux-form
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired
};

const formDefinition = {
    enableReinitialize: true,
    form: "create-api-key-form",
    schema: createApiKeySchema
};

export default form(formDefinition)(CreateApiKey);
