import React from "react";
import PropTypes from "prop-types";

import { faChevronDown, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChildrenContainer, Container, ExpansionIconContainer } from "./styled";

const ExpanderCell = ({ children, onExpansion, row }) => {
    return (
        <Container depth={row.depth}>
            {row.canExpand && (
                <ExpansionIconContainer
                    {...row.getToggleRowExpandedProps()}
                    onClick={e => {
                        row.getToggleRowExpandedProps().onClick(e);
                        onExpansion(!row.isExpanded, row);
                    }}
                >
                    <FontAwesomeIcon icon={row.isExpanded ? faChevronDown : faChevronRight} />
                </ExpansionIconContainer>
            )}
            <ChildrenContainer canExpand={row.canExpand}>{children}</ChildrenContainer>
        </Container>
    );
};

ExpanderCell.propTypes = {
    /** Any extra content to display besides the expansion arrow */
    children: PropTypes.any,
    /**
     * Invoked when the row is expanded with two parameters.
     * The first is a boolean indicating whether the row is being expanded (true) or reduced (false), the second is the entire row object.
     */
    onExpansion: PropTypes.func,
    /** Row prop received from the table */
    row: PropTypes.shape({
        canExpand: PropTypes.bool,
        getToggleRowExpandedProps: PropTypes.func,
        isExpanded: PropTypes.bool
    })
};

ExpanderCell.defaultProps = {
    onExpansion: () => {}
};

export default ExpanderCell;
