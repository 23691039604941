import { API_URL, REGISTRY_READ_REST } from "../../config";
import { getAuthWithAppName } from "../auth";
import { getRestCall } from "../../utils/rest-api-call";
import { getUserProfile } from "../../utils/state-utils/get-user-profile";

export const FIND_ITEM_START = "FIND_ITEM_START";
export const FIND_ITEM_SUCCESS = "FIND_ITEM_SUCCESS";
export const FIND_ITEM_ERROR = "FIND_ITEM_ERROR";
export const FIND_ITEM_RESET = "FIND_ITEM_RESET";

export function findItems(idRagSoc, displayErrors = false) {
    return async (dispatch, getState) => {
        const pagination = {
            pageNumber: 0,
            itemsPerPage: 5
        };

        const fullText = {
            keyWord: idRagSoc.trim(),
            classifiers: ["COMPANY", "STUDIO", "ASSOCIATION"]
        };

        const request = {
            packageType: "BASE",
            fullText,
            pagination
        };

        dispatch({
            type: FIND_ITEM_START,
            payload: request
        });

        try {
            const auth = getAuthWithAppName(getState().auth);
            const res = await getRestCall(
                `${REGISTRY_READ_REST}/items`,
                auth,
                request,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                undefined,
                displayErrors
            );

            dispatch({
                type: FIND_ITEM_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: FIND_ITEM_ERROR,
                error: e
            });
        }
    };
}

export function findItemsReset() {
    return dispatch => {
        dispatch({
            type: FIND_ITEM_RESET
        });
    };
}

export const LIST_ITEM_START = "LIST_ITEM_START";
export const LIST_ITEM_SUCCESS = "LIST_ITEM_SUCCESS";
export const LIST_ITEM_ERROR = "LIST_ITEM_ERROR";

export function listItems(itemIds, itemsPerPage = 1000, pageNumber = 0) {
    return async (dispatch, getState) => {
        dispatch({
            type: LIST_ITEM_START,
            payload: itemIds
        });

        const idList = itemIds.reduce((acc, curr) => {
            acc += "," + curr;
            return acc;
        });

        const getParams = {
            idList,
            packageType: "BASE",
            "pagination.itemsPerPage": itemsPerPage,
            "pagination.pageNumber": pageNumber
        };

        try {
            const auth = getAuthWithAppName(getState().auth);
            const res = await getRestCall(
                `${REGISTRY_READ_REST}/items`,
                getAuthWithAppName(auth),
                getParams,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState())
            );

            dispatch({
                type: LIST_ITEM_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: LIST_ITEM_ERROR,
                payload: { error: e, errorInfo: e }
            });
        }
    };
}

export const LIST_ITEMS_ROLE_CREATION_START = "LIST_ITEMS_ROLE_CREATION_START";
export const LIST_ITEMS_ROLE_CREATION_SUCCESS = "LIST_ITEMS_ROLE_CREATION_SUCCESS";
export const LIST_ITEMS_ROLE_CREATION_ERROR = "LIST_ITEMS_ROLE_CREATION_ERROR";
export const LIST_ITEMS_ROLE_CREATION_RESET = "LIST_ITEMS_ROLE_CREATION_RESET";

export const listItemsRoleCreation =
    (
        fullText,
        classifiers,
        targetUser,
        page = 0,
        size = 10,
        hideAlreadyAssignedTargetUserItems = false,
        getOnlyItemsOnWhichYouCanAssigneRoles = false,
        getOnlyCertifiedItems = false,
        hideItemsWithoutActiveServices = false,
        workspaceOwnerId = undefined
    ) =>
    async (dispatch, getState) => {
        dispatch({
            type: LIST_ITEMS_ROLE_CREATION_START,
            payload: { meta: { page, size } }
        });

        const auth = getAuthWithAppName(getState().auth);
        try {
            const res = await getRestCall(
                `${API_URL}/roles/available-items`,
                auth,
                // The API doesn't accept null as a valid value, so any nullable field must be mapped to undefined
                {
                    page,
                    size,
                    fullText: fullText || undefined,
                    classifiers: classifiers || undefined,
                    targetUser: targetUser || undefined,
                    hideAlreadyAssignedTargetUserItems,
                    getOnlyItemsOnWhichYouCanAssigneRoles,
                    getOnlyCertifiedItems,
                    hideItemsWithoutActiveServices,
                    workspaceOwnerId
                },
                dispatch,
                auth.refreshToken,
                getUserProfile(getState())
            );

            dispatch({
                type: LIST_ITEMS_ROLE_CREATION_SUCCESS,
                payload: { content: res.items, meta: { page, size, totalItems: res.totalItems } }
            });
        } catch (e) {
            dispatch({
                type: LIST_ITEMS_ROLE_CREATION_ERROR,
                payload: { error: e, errorInfo: e }
            });
        }
    };

export const listItemsRoleCreationReset = () => dispatch => dispatch({ type: "LIST_ITEMS_ROLE_CREATION_RESET" });

export const GET_HAS_STUDIO_START = "GET_HAS_STUDIO_START";
export const GET_HAS_STUDIO_SUCCESS = "GET_HAS_STUDIO_SUCCESS";
export const GET_HAS_STUDIO_ERROR = "GET_HAS_STUDIO_ERROR";
export const GET_HAS_STUDIO_RESET = "GET_HAS_STUDIO_RESET";

export const getHasStudio = () => async (dispatch, getState) => {
    dispatch({ type: "GET_HAS_STUDIO_START", payload: {} });
    const auth = getAuthWithAppName(getState().auth);
    try {
        const res = await getRestCall(
            `${API_URL}/items/studio`,
            auth,
            {},
            dispatch,
            auth.refreshToken,
            getUserProfile(getState())
        );
        dispatch({ type: "GET_HAS_STUDIO_SUCCESS", payload: res });
    } catch (e) {
        dispatch({ type: "GET_HAS_STUDIO_ERROR", error: e, errorInfo: e });
    }
};

export const getHasStudioReset = () => dispatch => dispatch({ type: "GET_HAS_STUDIO_RESET" });
