import React from "react";
import PropTypes from "prop-types";

import { injectIntl } from "react-intl";

import { isAppAvailable } from "../../utils/app-availability";
import ServiceCard from "../../components/general/service-card";
import { ServiceCardsContainer } from "../general/service-card/styled";

class Signature extends React.Component {
    static propTypes = {
        company: PropTypes.object.isRequired,
        services: PropTypes.object.isRequired
    };

    renderCards() {
        const {
            company,
            services: { active, categories, signature, agyoServices },
            user,
            intl,
            getWaitingList,
            getWaitingListStatus,
            subscribeWaitinglist,
            deleteWaitinglist,
            waitinglistDeleteStatus,
            waitinglist,
            waitinglistSubscribeStatus
        } = this.props;

        const roles = user.user && user.user.roles ? user.user.roles : [];

        let category = {
            title: intl.formatMessage({
                id: "c-services-view.SIG.name"
            })
        };
        let isActiveInAgyo = agyoServices.services.some(service => service.id === "SIGNATURE-FLOW" && service.active);

        return (
            active.availableToUser &&
            signature &&
            agyoServices && (
                <ServiceCardsContainer expanded={false}>
                    <ServiceCard
                        disable={!isActiveInAgyo}
                        ribbonActive={!isActiveInAgyo}
                        ribbon={{
                            text: intl.formatMessage({
                                id: "general.coming-soon"
                            }),
                            color: "grey"
                        }}
                        buyLink={
                            "https://www.teamsystem.com/store/firma-digitale/agyo-firma/?utm_medium=referral&utm_source=app-agyo&utm_campaign=agyo-privacy_sitelink-piattaforma"
                        }
                        packageInfo={{
                            active: signature.FRQ
                        }}
                        button={{ active: false }}
                        display={isAppAvailable(
                            company.base.id,
                            roles,
                            "SIGNATURE-FLOW",
                            active.availableToUser,
                            categories.data["SIG"],
                            active.services
                        )}
                        expanded={false}
                        title={intl.formatMessage({
                            id: "c-signature.frq-title"
                        })}
                        description={intl.formatMessage({
                            id: "c-signature.frq-desc"
                        })}
                        category={category}
                        serviceInfo={agyoServices.services.filter(ele => ele.id === "SIGNATURE-FLOW")[0]}
                        companyId={company.base.id}
                        getWaitingList={getWaitingList}
                        getWaitingListStatus={getWaitingListStatus}
                        waitinglist={waitinglist}
                        subscribeWaitinglist={subscribeWaitinglist}
                        deleteWaitinglist={deleteWaitinglist}
                        waitinglistDeleteStatus={waitinglistDeleteStatus}
                        waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                        user={user}
                    />
                    <ServiceCard
                        disable={!isActiveInAgyo}
                        ribbonActive={!isActiveInAgyo}
                        ribbon={{
                            text: intl.formatMessage({
                                id: "general.coming-soon"
                            }),
                            color: "grey"
                        }}
                        buyLink={
                            "https://www.teamsystem.com/store/firma-digitale/agyo-firma/?utm_medium=referral&utm_source=app-agyo&utm_campaign=agyo-privacy_sitelink-piattaforma"
                        }
                        packageInfo={{
                            active: signature.FMQ
                        }}
                        button={{ active: false }}
                        display={isAppAvailable(
                            company.base.id,
                            roles,
                            "SIGNATURE-FLOW",
                            active.availableToUser,
                            categories.data["SIG"],
                            active.services
                        )}
                        expanded={false}
                        title={intl.formatMessage({
                            id: "c-signature.fmq-title"
                        })}
                        description={intl.formatMessage({
                            id: "c-signature.fmq-desc"
                        })}
                        category={category}
                        serviceInfo={agyoServices.services.filter(ele => ele.id === "SIGNATURE-FLOW")[0]}
                        companyId={company.base.id}
                        getWaitingList={getWaitingList}
                        getWaitingListStatus={getWaitingListStatus}
                        waitinglist={waitinglist}
                        subscribeWaitinglist={subscribeWaitinglist}
                        deleteWaitinglist={deleteWaitinglist}
                        waitinglistDeleteStatus={waitinglistDeleteStatus}
                        waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                        user={user}
                    />
                    <ServiceCard
                        disable={!isActiveInAgyo}
                        ribbonActive={!isActiveInAgyo}
                        ribbon={{
                            text: intl.formatMessage({
                                id: "general.coming-soon"
                            }),
                            color: "grey"
                        }}
                        buyLink={
                            "https://www.teamsystem.com/store/firma-digitale/agyo-firma/?utm_medium=referral&utm_source=app-agyo&utm_campaign=agyo-privacy_sitelink-piattaforma"
                        }
                        packageInfo={{
                            active: signature.FRA
                        }}
                        button={{ active: false }}
                        display={isAppAvailable(
                            company.base.id,
                            roles,
                            "SIGNATURE-FLOW",
                            active.availableToUser,
                            categories.data["SIG"],
                            active.services
                        )}
                        expanded={false}
                        title={intl.formatMessage({
                            id: "c-signature.fra-title"
                        })}
                        description={intl.formatMessage({
                            id: "c-signature.fra-desc"
                        })}
                        category={category}
                        serviceInfo={agyoServices.services.filter(ele => ele.id === "SIGNATURE-FLOW")[0]}
                        companyId={company.base.id}
                        getWaitingList={getWaitingList}
                        getWaitingListStatus={getWaitingListStatus}
                        waitinglist={waitinglist}
                        subscribeWaitinglist={subscribeWaitinglist}
                        deleteWaitinglist={deleteWaitinglist}
                        waitinglistDeleteStatus={waitinglistDeleteStatus}
                        waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                        user={user}
                    />
                    <ServiceCard
                        disable={!isActiveInAgyo}
                        ribbonActive={!isActiveInAgyo}
                        ribbon={{
                            text: intl.formatMessage({
                                id: "general.coming-soon"
                            }),
                            color: "grey"
                        }}
                        buyLink={
                            "https://www.teamsystem.com/store/firma-digitale/agyo-firma/?utm_medium=referral&utm_source=app-agyo&utm_campaign=agyo-privacy_sitelink-piattaforma"
                        }
                        packageInfo={{
                            active: signature.FGA
                        }}
                        button={{ active: false }}
                        display={isAppAvailable(
                            company.base.id,
                            roles,
                            "SIGNATURE-FLOW",
                            active.availableToUser,
                            categories.data["SIG"],
                            active.services
                        )}
                        expanded={false}
                        title={intl.formatMessage({
                            id: "c-signature.fga-title"
                        })}
                        description={intl.formatMessage({
                            id: "c-signature.fga-desc"
                        })}
                        category={category}
                        serviceInfo={agyoServices.services.filter(ele => ele.id === "SIGNATURE-FLOW")[0]}
                        companyId={company.base.id}
                        getWaitingList={getWaitingList}
                        getWaitingListStatus={getWaitingListStatus}
                        waitinglist={waitinglist}
                        subscribeWaitinglist={subscribeWaitinglist}
                        deleteWaitinglist={deleteWaitinglist}
                        waitinglistDeleteStatus={waitinglistDeleteStatus}
                        waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                        user={user}
                    />
                    <ServiceCard
                        disable={!isActiveInAgyo}
                        ribbonActive={!isActiveInAgyo}
                        ribbon={{
                            text: intl.formatMessage({
                                id: "general.coming-soon"
                            }),
                            color: "grey"
                        }}
                        buyLink={
                            "https://www.teamsystem.com/store/firma-digitale/agyo-firma/?utm_medium=referral&utm_source=app-agyo&utm_campaign=agyo-privacy_sitelink-piattaforma"
                        }
                        packageInfo={{
                            active: signature.signatureErp
                        }}
                        button={{ active: false }}
                        display={isAppAvailable(
                            company.base.id,
                            roles,
                            "SIGNATURE-FLOW",
                            active.availableToUser,
                            categories.data["SIG"],
                            active.services
                        )}
                        expanded={false}
                        title={intl.formatMessage({
                            id: "c-signature.fda-title"
                        })}
                        description={intl.formatMessage({
                            id: "c-signature.fda-desc"
                        })}
                        category={category}
                        serviceInfo={agyoServices.services.filter(ele => ele.id === "SIGNATURE-FLOW")[0]}
                        companyId={company.base.id}
                        getWaitingList={getWaitingList}
                        getWaitingListStatus={getWaitingListStatus}
                        waitinglist={waitinglist}
                        subscribeWaitinglist={subscribeWaitinglist}
                        deleteWaitinglist={deleteWaitinglist}
                        waitinglistDeleteStatus={waitinglistDeleteStatus}
                        waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                        user={user}
                    />
                    <ServiceCard
                        disable={!isActiveInAgyo}
                        ribbonActive={!isActiveInAgyo}
                        ribbon={{
                            text: intl.formatMessage({
                                id: "general.coming-soon"
                            }),
                            color: "grey"
                        }}
                        buyLink={
                            "https://www.teamsystem.com/store/firma-digitale/agyo-firma/?utm_medium=referral&utm_source=app-agyo&utm_campaign=agyo-privacy_sitelink-piattaforma"
                        }
                        packageInfo={{
                            active: signature.signatureConsole
                        }}
                        display={isAppAvailable(
                            company.base.id,
                            roles,
                            "SIGNATURE-FLOW",
                            active.availableToUser,
                            categories.data["SIG"],
                            active.services
                        )}
                        expanded={false}
                        button={{ active: false }}
                        title={intl.formatMessage({
                            id: "c-signature.agyosign-title"
                        })}
                        description={intl.formatMessage({
                            id: "c-signature.agyosign-desc"
                        })}
                        category={category}
                        serviceInfo={agyoServices.services.filter(ele => ele.id === "SIGNATURE-FLOW")[0]}
                        companyId={company.base.id}
                        getWaitingList={getWaitingList}
                        getWaitingListStatus={getWaitingListStatus}
                        waitinglist={waitinglist}
                        subscribeWaitinglist={subscribeWaitinglist}
                        deleteWaitinglist={deleteWaitinglist}
                        waitinglistDeleteStatus={waitinglistDeleteStatus}
                        waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                        user={user}
                    />
                </ServiceCardsContainer>
            )
        );
    }
    render() {
        return <div className="c-signature">{this.renderCards()}</div>;
    }
}

export default injectIntl(Signature);
