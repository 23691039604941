import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { faExclamationCircle, faMinusCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import { StatusIcon, StatusMessageContainer, Hyperlink } from "../styled";

export const ProvisioningStatusMessage = ({
    canItemCertify,
    isItemCertified,
    isItemCertifying,
    isItemValidated,
    provisioningInfo
}) => {
    const { item } = useParams();

    const handleProvisioningStatusIcon = status => {
        switch (status) {
            case "validated":
                return { icon: faCheckCircle, color: "#09822a" };
            case "rejected":
                return { icon: faExclamationCircle, color: "#d82829" };
            case "uploaded":
            case "downloaded":
            case "initialized":
            case "correction":
            default:
                return { icon: faExclamationCircle, color: "#fdb927" };
        }
    };
    return !isItemCertified ? (
        <>
            {canItemCertify && (
                <StatusMessageContainer>
                    <StatusIcon icon={faMinusCircle} color="#d82829" />
                    <FormattedMessage id="c-new-cct-warning-message.can-certify.message" />{" "}
                    <Link to={`/${item}/company/validate`}>
                        <Hyperlink>
                            <FormattedMessage id="c-new-cct-warning-message.can-certify.link" />
                        </Hyperlink>
                    </Link>
                </StatusMessageContainer>
            )}
            {isItemCertifying && (
                <StatusMessageContainer>
                    <StatusIcon icon={faExclamationCircle} color="#fdb927" />
                    <FormattedMessage id="c-new-cct-warning-message.certification-in-progress" />
                </StatusMessageContainer>
            )}
            {isItemValidated && (
                <StatusMessageContainer>
                    <StatusIcon icon={faExclamationCircle} color="#fdb927" />
                    <FormattedMessage id="c-new-cct-warning-message.item-validated" />
                </StatusMessageContainer>
            )}
        </>
    ) : (
        <StatusMessageContainer>
            <StatusIcon
                icon={provisioningInfo && handleProvisioningStatusIcon(provisioningInfo.status).icon}
                color={provisioningInfo && handleProvisioningStatusIcon(provisioningInfo.status).color}
            />{" "}
            <FormattedMessage id="cct-delegation-status" />
            {": "}
            {provisioningInfo && <FormattedMessage id={`c-cct-status-${provisioningInfo.status}`} />}
        </StatusMessageContainer>
    );
};

ProvisioningStatusMessage.propTypes = {
    /** The item is not certified, but the user can generate/upload the document */
    canItemCertify: PropTypes.bool,
    /** The item is certified */
    isItemCertified: PropTypes.bool,
    /** The certification process is in progress */
    isItemCertifying: PropTypes.bool,
    /** The item is validated, but not certified */
    isItemValidated: PropTypes.bool,
    provisioningInfo: PropTypes.object
};

export default ProvisioningStatusMessage;
