import { getAuthWithAppName } from "../../auth.js";
import { API_URL } from "../../../config";

import { postRestCall, getRestCall } from "../../../utils/rest-api-call.js";
import { getUserProfile } from "../../../utils/state-utils/get-user-profile.js";

export const VERIFY_CCT_START = "VERIFY_CCT_START";
export const VERIFY_CCT_ERROR = "VERIFY_CCT_ERROR";
export const VERIFY_CCT_SUCCESS = "VERIFY_CCT_SUCCESS";
export const VERIFY_CCT_RESET = "VERIFY_CCT_RESET";

export function verifyCCTcredentials(cctUser, cctPass, itemId, ownerId) {
    return async (dispatch, getState) => {
        dispatch({
            type: VERIFY_CCT_START,
            payload: { cctUser, itemId, ownerId }
        });

        try {
            const auth = getAuthWithAppName(getState().auth);
            const result = await postRestCall(
                `${API_URL}/services/cct/${itemId}/credentials`,
                auth,
                {},
                { cctUser, cctPass, ownerId },
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                itemId,
                true,
                true
            );

            dispatch({ type: VERIFY_CCT_SUCCESS, payload: result });
        } catch (e) {
            dispatch({
                type: VERIFY_CCT_ERROR,
                payload: { cctUser, itemId, ownerId },
                error: e
            });
        }
    };
}

export const FETCH_CCT_CONFIG_START = "FETCH_CCT_CONFIG_START";
export const FETCH_CCT_CONFIG_ERROR = "FETCH_CCT_CONFIG_ERROR";
export const FETCH_CCT_CONFIG_SUCCESS = "FETCH_CCT_CONFIG_SUCCESS";

export function getCctConfig(itemId, ownerId) {
    ownerId = ownerId || itemId;
    return async (dispatch, getState) => {
        dispatch({
            type: FETCH_CCT_CONFIG_START
        });

        const extractedAuth = getAuthWithAppName(getState().auth);

        try {
            const response = await getRestCall(
                `${API_URL}/services/cct/${itemId}`,
                extractedAuth,
                { ownerId },
                dispatch,
                extractedAuth.refreshToken,
                getUserProfile(getState()),
                itemId
            );

            dispatch({ type: FETCH_CCT_CONFIG_SUCCESS, payload: response });
        } catch (e) {
            dispatch({ type: FETCH_CCT_CONFIG_ERROR, error: e, payload: { itemId, ownerId } });
        }
    };
}

export const SAVE_CCT_CONFIG_START = "SAVE_CCT_CONFIG_START";
export const SAVE_CCT_CONFIG_ERROR = "SAVE_CCT_CONFIG_ERROR";
export const SAVE_CCT_CONFIG_SUCCESS = "SAVE_CCT_CONFIG_SUCCESS";

export function saveCctConfig(itemId, ownerId, specs, cctUser = undefined, cctPass = undefined) {
    return async (dispatch, getState) => {
        dispatch({ type: SAVE_CCT_CONFIG_START, payload: { itemId, ownerId, specs, cctUser } });

        const extractedAuth = getAuthWithAppName(getState().auth);

        try {
            const result = await postRestCall(
                `${API_URL}/services/cct/${itemId}`,
                extractedAuth,
                {},
                { cctUser, cctPass, ownerId, cctSpecs: specs },
                dispatch,
                extractedAuth.refreshToken,
                getUserProfile(getState()),
                itemId
            );

            dispatch({ type: SAVE_CCT_CONFIG_SUCCESS, payload: result });
        } catch (e) {
            dispatch({ type: SAVE_CCT_CONFIG_ERROR, error: e, payload: { itemId, ownerId, specs, cctUser } });
        }
    };
}

export const FETCH_CCT_CONFIG_MANAGED_START = "FETCH_CCT_CONFIG_MANAGED_START";
export const FETCH_CCT_CONFIG_MANAGED_ERROR = "FETCH_CCT_CONFIG_MANAGED_ERROR";
export const FETCH_CCT_CONFIG_MANAGED_SUCCESS = "FETCH_CCT_CONFIG_MANAGED_SUCCESS";

export function getCctConfigManaged(ownerId, itemId, active, page, size) {
    return async (dispatch, getState) => {
        dispatch({ type: FETCH_CCT_CONFIG_MANAGED_START, payload: { ownerId, itemId, active, page, size } });

        try {
            const auth = getAuthWithAppName(getState().auth);
            const result = await getRestCall(
                `${API_URL}/services/cct/${ownerId}/managed`,
                auth,
                { itemId, active, page, size },
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                ownerId
            );
            dispatch({ type: FETCH_CCT_CONFIG_MANAGED_SUCCESS, payload: result });
        } catch (e) {
            dispatch({
                type: FETCH_CCT_CONFIG_MANAGED_ERROR,
                error: e,
                payload: { ownerId, itemId, active, page, size }
            });
        }
    };
}

export const ENABLE_CCT_CONFIG_MANAGED_START = "ENABLE_CCT_CONFIG_MANAGED_START";
export const ENABLE_CCT_CONFIG_MANAGED_ERROR = "ENABLE_CCT_CONFIG_MANAGED_ERROR";
export const ENABLE_CCT_CONFIG_MANAGED_SUCCESS = "ENABLE_CCT_CONFIG_MANAGED_SUCCESS";

export function enableCctConfigManaged(ownerId, itemIds) {
    return async (dispatch, getState) => {
        dispatch({ type: ENABLE_CCT_CONFIG_MANAGED_START, payload: { ownerId, itemIds } });

        try {
            const auth = getAuthWithAppName(getState().auth);
            const result = await postRestCall(
                `${API_URL}/services/cct/${ownerId}/managed/enable`,
                auth,
                {},
                { itemIds: itemIds },
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                ownerId,
                true,
                false
            );

            dispatch({ type: ENABLE_CCT_CONFIG_MANAGED_SUCCESS, payload: result });
        } catch (e) {
            dispatch({
                type: ENABLE_CCT_CONFIG_MANAGED_ERROR,
                error: e,
                payload: { ownerId, itemIds }
            });
        }
    };
}

export const DISABLE_CCT_CONFIG_MANAGED_START = "DISABLE_CCT_CONFIG_MANAGED_START";
export const DISABLE_CCT_CONFIG_MANAGED_ERROR = "DISABLE_CCT_CONFIG_MANAGED_ERROR";
export const DISABLE_CCT_CONFIG_MANAGED_SUCCESS = "DISABLE_CCT_CONFIG_MANAGED_SUCCESS";

export function disableCctConfigManaged(ownerId, itemIds) {
    return async (dispatch, getState) => {
        dispatch({ type: DISABLE_CCT_CONFIG_MANAGED_START, payload: { ownerId, itemIds } });

        try {
            const auth = getAuthWithAppName(getState().auth);
            const result = await postRestCall(
                `${API_URL}/services/cct/${ownerId}/managed/disable`,
                auth,
                {},
                { itemIds: itemIds },
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                ownerId,
                true,
                false
            );

            dispatch({ type: DISABLE_CCT_CONFIG_MANAGED_SUCCESS, payload: result });
        } catch (e) {
            dispatch({
                type: DISABLE_CCT_CONFIG_MANAGED_ERROR,
                error: e,
                payload: { ownerId, itemIds }
            });
        }
    };
}

export const FETCH_CCT_CONFIG_RESET = "FETCH_CCT_CONFIG_RESET";

export function resetCctConfig() {
    return {
        type: FETCH_CCT_CONFIG_RESET
    };
}
