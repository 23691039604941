import React from "react";
import PropTypes from "prop-types";

import { form } from "@ts-digital/conv-redux-form";
import TextField from "@ts-digital/arc/antd/TextField";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";

import { cctCredentialsSchema } from "../../../../utils/form-schema/cct-credentials";
import { Message, Label } from "./styled";

export const FormCredentialsCct = () => {
    const { formatMessage } = useIntl();

    return (
        <form>
            <Message>
                <FormattedHTMLMessage id="c-cct-edit-user-desc" />
            </Message>
            <Label>
                <FormattedMessage id="general.username" />
            </Label>
            <TextField
                name="cctUser"
                placeholder={formatMessage({
                    id: "c-cct-user"
                })}
                type="text"
            />
            <Label>
                <FormattedMessage id="general.password" />
            </Label>
            <TextField
                name="cctPass"
                placeholder={formatMessage({
                    id: "c-cct-password"
                })}
                type="password"
            />
        </form>
    );
};

FormCredentialsCct.propTypes = {
    handleSubmit: PropTypes.func.isRequired, // passed down by redux-form
    onSubmit: PropTypes.func.isRequired
};

const formDefinition = {
    form: "form-credentials-cct",
    schema: cctCredentialsSchema
};

export default form(formDefinition)(FormCredentialsCct);
