import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { FormattedMessage, useIntl, FormattedHTMLMessage } from "react-intl";

import { Select, Toggle } from "@ts-digital/vrc";
import { Tooltip } from "antd";

import Link from "../../../components/general/link";

import {
    TableHeaders,
    HeaderLabel,
    InfoIcon,
    ServiceRow,
    ServiceRows,
    SubServiceRow,
    SubServiceRowTitle,
    SubServicesHeaders,
    StyledDivider,
    ToggleContainer,
    ToggleLabel,
    ServiceStatusIcon,
    RowContainer,
    RemovableServiceRow,
    RemovableHeaderLabel
} from "./styled";
import { faCheckCircle, faPauseCircle } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";

const RolesTable = ({
    onUpdate,
    openSelectedCompaniesModal,
    values,
    showActivationDateColumn,
    showCompaniesNumberColumn,
    showDescriptionColumn,
    showManagerDescription,
    showServiceStatusColumn,
    myRolesMap,
    totalCompanies
}) => {
    const { formatMessage } = useIntl();

    const onToggleChange = useCallback(
        (val, isChecked) => {
            let newValues = values.map(v => ({ ...v, children: v.children.map(c => ({ ...c })) }));

            const idx = values.findIndex(v => v.appId === val.appId);
            newValues[idx].toggle = isChecked;
            if (!isChecked) {
                newValues[idx].role = "noRole";
                newValues[idx].children.forEach(element => {
                    element.role = "noRole";
                });
            }

            onUpdate(newValues, "check", isChecked, val);
        },
        [values, onUpdate]
    );

    const onChange = useCallback(
        (val, r, child = {}) => {
            let newValues = values.map(v => ({ ...v, children: v.children.map(c => ({ ...c })) }));

            const currentAppIdx = values.findIndex(v => v.appId === r.appId);
            const currentChildrenIdx =
                child.featureCode && values[currentAppIdx].children
                    ? values[currentAppIdx].children.findIndex(c => c.featureCode === child.featureCode)
                    : undefined;

            if (currentChildrenIdx === undefined) {
                newValues[currentAppIdx].role = val;
                newValues[currentAppIdx].children.forEach(element => {
                    element.role = val;
                });
            } else {
                newValues[currentAppIdx].children[currentChildrenIdx].role = val;

                if (newValues[currentAppIdx].role !== "uneditableMultipleRoles") {
                    newValues[currentAppIdx].role = "noRole";
                }
            }

            onUpdate(newValues, "permission", val, r, child);
        },
        [onUpdate, values]
    );

    const buildOptions = currentRow => {
        const options = [];

        options.push(
            ...currentRow.permissions.map(p => ({
                description: formatMessage({
                    id: `c-registry-user.users-manage-${p.toLowerCase()}`
                }),
                value: p.toLowerCase()
            })),
            {
                description: formatMessage({
                    id: `c-registry-user.users-manage-noRole`
                }),
                value: "noRole"
            }
        );

        if (currentRow.role === "collaborator") {
            options.push({
                description: formatMessage({
                    id: `c-registry-user.users-manage-collaborator`
                }),
                value: "collaborator"
            });
        }

        return options;
    };

    return (
        <>
            <div>
                <TableHeaders
                    showActivationDateColumn={showActivationDateColumn}
                    showDescriptionColumn={showDescriptionColumn}
                    showServiceStatusColumn={showServiceStatusColumn}
                >
                    <HeaderLabel>
                        <FormattedMessage id={"c-user-roles-table.table.header.services"} />
                    </HeaderLabel>
                    <HeaderLabel>
                        <FormattedMessage id={"c-user-roles-table.table.header.role"} />
                        <Tooltip
                            placement="bottom"
                            title={
                                <FormattedHTMLMessage
                                    id={
                                        showManagerDescription
                                            ? "c-registry-user.role-manager-description.tooltip"
                                            : "c-registry-user.role-description.tooltip"
                                    }
                                />
                            }
                        >
                            <InfoIcon icon={faInfoCircle} />
                        </Tooltip>
                    </HeaderLabel>
                    {showCompaniesNumberColumn ? (
                        <RemovableHeaderLabel>
                            <FormattedMessage id={"c-user-roles-table.table.header.companiesNumber"} />
                            <Tooltip
                                placement="bottom"
                                title={<FormattedHTMLMessage id="c-registry-user.selected-companies.tooltip" />}
                            >
                                <InfoIcon icon={faInfoCircle} />
                            </Tooltip>
                        </RemovableHeaderLabel>
                    ) : (
                        showDescriptionColumn && (
                            <RemovableHeaderLabel>
                                <FormattedMessage id={"c-user-roles-table.table.header.descripion"} />
                            </RemovableHeaderLabel>
                        )
                    )}
                    {showActivationDateColumn && (
                        <HeaderLabel>
                            <FormattedMessage id={"c-user-roles-table.table.header.activationDate"} />
                            <Tooltip
                                placement="bottom"
                                title={
                                    <FormattedHTMLMessage id="c-user-roles-table.table.header.activationDate.tooltip" />
                                }
                            >
                                <InfoIcon icon={faInfoCircle} />
                            </Tooltip>
                        </HeaderLabel>
                    )}
                    {showServiceStatusColumn && (
                        <RemovableHeaderLabel>
                            <FormattedMessage id={"c-user-roles-table.table.header.serviceStatus"} />
                        </RemovableHeaderLabel>
                    )}
                </TableHeaders>
                <StyledDivider />
            </div>
            {values.map(r => {
                return (
                    <RowContainer key={r.appId}>
                        <ServiceRows
                            showActivationDateColumn={showActivationDateColumn}
                            showDescriptionColumn={showDescriptionColumn}
                            showServiceStatusColumn={showServiceStatusColumn}
                        >
                            <ServiceRow>
                                <ToggleContainer>
                                    <Toggle
                                        checked={
                                            (!r.assignable &&
                                                (r.children.length > 0 || r.children.some(c => c.assignable))) ||
                                            r.toggle
                                        }
                                        disabled={
                                            (!r.assignable &&
                                                (r.children.length === 0 || !r.children.some(c => c.assignable))) ||
                                            r.role === "uneditableMultipleRoles" ||
                                            myRolesMap
                                        }
                                        onChange={() => onToggleChange(r, !r.toggle)}
                                    />
                                    <ToggleLabel>{r.name}</ToggleLabel>
                                </ToggleContainer>
                            </ServiceRow>
                            <ServiceRow>
                                {r.permissions.length > 0 && (
                                    <Select
                                        data-cy={`roles-table-${r.appId}-select`}
                                        options={buildOptions(r)}
                                        onChange={value => {
                                            onChange(value, r);
                                        }}
                                        value={r.role && r.role !== "uneditableMultipleRoles" ? r.role : "noRole"}
                                        disabled={
                                            !r.toggle ||
                                            (!r.assignable && !r.children.some(c => c.assignable)) ||
                                            r.role === "uneditableMultipleRoles" ||
                                            myRolesMap
                                        }
                                        placeholder={formatMessage({ id: `c-registry-user.users-manage-select` })}
                                    />
                                )}
                            </ServiceRow>
                            {showDescriptionColumn && !showCompaniesNumberColumn && (
                                <RemovableServiceRow
                                    disabled={
                                        !r.toggle ||
                                        !r.assignable ||
                                        (r.role &&
                                            !["noRole"].includes(r.role) &&
                                            !r.permissions.map(p => p.toLowerCase()).includes(r.role)) ||
                                        myRolesMap
                                    }
                                >
                                    {r.permissions.length > 0 && (
                                        <FormattedMessage
                                            id={`c-user-roles-table.description.${
                                                r.role && r.role !== "noRole"
                                                    ? r.role
                                                    : r.children.some(c => c.role !== undefined && c.role !== "noRole")
                                                    ? "multipleRoles"
                                                    : "noRole"
                                            }`}
                                        />
                                    )}
                                </RemovableServiceRow>
                            )}
                            {showCompaniesNumberColumn && (
                                <RemovableServiceRow
                                    disabled={
                                        showCompaniesNumberColumn
                                            ? false
                                            : !r.toggle ||
                                              !r.assignable ||
                                              (r.role &&
                                                  !["noRole"].includes(r.role) &&
                                                  !r.permissions.map(p => p.toLowerCase()).includes(r.role)) ||
                                              myRolesMap
                                    }
                                >
                                    {r.companiesCount.length === totalCompanies ? (
                                        <FormattedMessage id={"c-user-roles-table.selectedcompanies.all"} />
                                    ) : (
                                        <>
                                            <Link
                                                onClick={() => {
                                                    openSelectedCompaniesModal(r.companiesCount, r.role, r.name);
                                                }}
                                                content={
                                                    <FormattedMessage
                                                        id={
                                                            r.companiesCount.length === 1
                                                                ? "c-user-roles-table.selectedcompany"
                                                                : "c-user-roles-table.selectedcompanies"
                                                        }
                                                        values={{ companies: r.companiesCount.length }}
                                                    />
                                                }
                                            ></Link>
                                            <FormattedMessage
                                                id={"c-user-roles-table.selectedcompanies.total"}
                                                values={{ totalCompanies: totalCompanies - 1 }}
                                            />
                                        </>
                                    )}
                                </RemovableServiceRow>
                            )}
                            {showActivationDateColumn && (
                                <ServiceRow
                                    disabled={
                                        !r.toggle ||
                                        !r.assignable ||
                                        (r.role &&
                                            !["noRole"].includes(r.role) &&
                                            !r.permissions.map(p => p.toLowerCase()).includes(r.role)) ||
                                        myRolesMap
                                    }
                                >
                                    {moment(r.createdAt).format("DD/MM/YYYY")}
                                </ServiceRow>
                            )}
                            {showServiceStatusColumn &&
                                (r.assignable ? (
                                    <RemovableServiceRow>
                                        <ServiceStatusIcon isActive={true} icon={faCheckCircle} />
                                        {formatMessage({ id: "c-user-roles-table.description.active" })}
                                    </RemovableServiceRow>
                                ) : (
                                    <Tooltip
                                        placement="bottomLeft"
                                        title={
                                            <FormattedHTMLMessage
                                                id={"c-registry-user.notActive-description.tooltip"}
                                            />
                                        }
                                    >
                                        <RemovableServiceRow>
                                            <ServiceStatusIcon isActive={false} icon={faPauseCircle} />
                                            {formatMessage({ id: "c-user-roles-table.description.notActive" })}
                                        </RemovableServiceRow>
                                    </Tooltip>
                                ))}
                        </ServiceRows>
                        <SubServicesHeaders
                            showActivationDateColumn={showActivationDateColumn}
                            showDescriptionColumn={showDescriptionColumn}
                            showServiceStatusColumn={showServiceStatusColumn}
                        >
                            {r.children
                                .filter(c => c.assignable)
                                .map(c => (
                                    <React.Fragment key={c.name}>
                                        <SubServiceRowTitle>{c.name}</SubServiceRowTitle>
                                        <SubServiceRow>
                                            <Select
                                                data-cy={`roles-table-${c.appId}-${c.featureCode}-select`}
                                                options={buildOptions(c)}
                                                onChange={value => {
                                                    onChange(value, r, c);
                                                }}
                                                value={c.role ? c.role : "noRole"}
                                                disabled={
                                                    !r.toggle ||
                                                    (c.role &&
                                                        !["noRole"].includes(c.role) &&
                                                        !c.permissions.map(p => p.toLowerCase()).includes(c.role)) ||
                                                    myRolesMap
                                                }
                                                placeholder={formatMessage({
                                                    id: `c-registry-user.users-manage-select`
                                                })}
                                            />
                                        </SubServiceRow>
                                        {showCompaniesNumberColumn ? (
                                            <RemovableServiceRow
                                                disabled={
                                                    showCompaniesNumberColumn
                                                        ? false
                                                        : !r.toggle ||
                                                          (c.role &&
                                                              !["noRole"].includes(c.role) &&
                                                              !c.permissions
                                                                  .map(p => p.toLowerCase())
                                                                  .includes(c.role)) ||
                                                          myRolesMap
                                                }
                                            >
                                                {c.companiesCount.length === totalCompanies ? (
                                                    <FormattedMessage id={"c-user-roles-table.selectedcompanies.all"} />
                                                ) : (
                                                    <>
                                                        <Link
                                                            onClick={() =>
                                                                openSelectedCompaniesModal(
                                                                    c.companiesCount,
                                                                    c.role,
                                                                    c.name
                                                                )
                                                            }
                                                            content={
                                                                <FormattedMessage
                                                                    id={
                                                                        c.companiesCount.length === 1
                                                                            ? "c-user-roles-table.selectedcompany"
                                                                            : "c-user-roles-table.selectedcompanies"
                                                                    }
                                                                    values={{ companies: c.companiesCount.length }}
                                                                />
                                                            }
                                                        ></Link>
                                                        <FormattedMessage
                                                            id={"c-user-roles-table.selectedcompanies.total"}
                                                            values={{ totalCompanies: totalCompanies - 1 }}
                                                        />
                                                    </>
                                                )}
                                            </RemovableServiceRow>
                                        ) : (
                                            c.role === "manager" &&
                                            showDescriptionColumn && (
                                                <RemovableServiceRow>
                                                    {formatMessage({ id: "c-user-roles-table.description.manager" })}
                                                </RemovableServiceRow>
                                            )
                                        )}
                                    </React.Fragment>
                                ))}
                        </SubServicesHeaders>
                    </RowContainer>
                );
            })}
        </>
    );
};

RolesTable.propTypes = {
    onUpdate: PropTypes.func,
    showActivationDateColumn: PropTypes.bool,
    showDescriptionColumn: PropTypes.bool,
    showManagerDescription: PropTypes.bool,
    showServiceStatusColumn: PropTypes.bool,
    values: PropTypes.array,
    myRolesMap: PropTypes.bool
};

RolesTable.defaultProps = {
    onUpdate: () => {},
    values: [],
    showActivationDateColumn: true,
    showDescriptionColumn: true,
    showServiceStatusColumn: true,
    myRolesMap: false
};

export default RolesTable;
