import React from "react";
import PropTypes from "prop-types";

import { includes } from "ramda";
import { useHistory } from "react-router";

import { isAppAvailable } from "../../utils/app-availability";
import ServiceCard from "../../components/general/service-card";
import { ServiceCardsContainer } from "../general/service-card/styled";

const Storage = ({
    activeServices,
    agyoServices,
    availableServices,
    categories,
    itemId,
    userRoles,
    provisioningInfo
}) => {
    const history = useHistory();

    let isActiveInAgyo = agyoServices.services.some(service => service.id === "CCT-FLOW" && service.active);

    const servicesConfig = [
        {
            active: includes("CCT-FLOW", activeServices),
            disable: !isActiveInAgyo,
            display: isAppAvailable(
                itemId,
                userRoles,
                "CCT-FLOW",
                availableServices,
                categories.data["ARC"],
                activeServices
            ),
            title: "c-cct-title",
            description: "c-cct-description",
            showConfigureButton: includes("CCT-FLOW", activeServices) || (provisioningInfo && provisioningInfo.status),
            onConfigure: () => history.push("storage/edit-cct")
        }
    ];

    return (
        <ServiceCardsContainer expanded={false}>
            {servicesConfig.map((service, key) => (
                <ServiceCard
                    key={key}
                    active={service.active}
                    disable={service.disable}
                    expanded={service.expanded}
                    expandedComponent={service.expandedComponent}
                    title={service.title}
                    description={service.description}
                    display={service.display}
                    button={service.button}
                    tooltip={service.tooltip}
                    showActiveButton={service.showActiveButton}
                    onActive={service.onActive}
                    onConfigure={service.onConfigure}
                    showBuyButton={service.showBuyButton}
                    showContactToBuy={service.showContactToBuy}
                    showConfigureButton={service.showConfigureButton}
                />
            ))}
        </ServiceCardsContainer>
    );
};

Storage.propTypes = {
    activeServices: PropTypes.array.isRequired,
    agyoServices: PropTypes.object.isRequired,
    availableServices: PropTypes.object.isRequired,
    itemId: PropTypes.string.isRequired,
    userRoles: PropTypes.array.isRequired
};

export default Storage;
