import styled from "styled-components";

export const PlusFiltersLabel = styled.div`
    width: 25%;
`;

export const OptionContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const OptionChildrenContainer = styled.div`
    padding-left: 8px;
    padding-top: 1px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: rgb(21, 41, 53);
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0.25px;
    line-height: 24px;
`;

export const SelectedOption = styled.div`
    background-color: #def0f7;
    border-radius: 2px;
    min-width: 0;
    box-sizing: border-box;
    margin: 2px 8px;

    color: #005075;
    font-size: 85%;
    overflow: hidden;
    padding: 3px 6px;
    text-overflow: ellipsis;
    white-space: nowrap;

    width: 35%;
    text-align: center;
`;
