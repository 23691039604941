import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { Layout } from "antd";

import Header from "../../../../header";
import Logo from "../../../../general/logo";

class LayoutHeaderComponent extends Component {
    render() {
        const {
            auth,
            display,
            logout,
            user,
            company,
            editUser,
            loadUser,
            notificationsExist,
            notificationsList,
            notificationsListStatus,
            deleteSingleNotification,
            deleteNotificationsList,
            getNotifications,
            getNotificationsExist,
            updateSingleNotification,
            updateNotificationsList,
            updateUserPswStatus,
            updateUserPsw,
            userEditStatus,
            copyTokenToast
        } = this.props;

        return (
            auth.loginAuth &&
            display && (
                <Layout.Header className="Root-header">
                    <div className="Root-header-text-container">
                        <Logo white={true} height={30} />
                    </div>
                    <Header
                        auth={auth}
                        onNotificationAccept={() => {}}
                        company={company}
                        user={user}
                        logout={logout}
                        resetCompanyLoad={null}
                        notifications={[]}
                        notificationsExist={notificationsExist}
                        notificationsList={notificationsList}
                        notificationsListStatus={notificationsListStatus}
                        deleteSingleNotification={deleteSingleNotification}
                        deleteNotificationsList={deleteNotificationsList}
                        getNotifications={getNotifications}
                        getNotificationsExist={getNotificationsExist}
                        updateNotificationsList={updateNotificationsList}
                        updateSingleNotification={updateSingleNotification}
                        onNotificationReject={() => {}}
                        onReadNotification={() => {}}
                        onUnreadNotification={() => {}}
                        unreadNotifications={0}
                        showTour={false}
                        showNotification={false}
                        editUser={editUser}
                        loadUser={loadUser}
                        updateUserPsw={updateUserPsw}
                        updateUserPswStatus={updateUserPswStatus}
                        userEditStatus={userEditStatus}
                        copyTokenToast={copyTokenToast}
                    />
                </Layout.Header>
            )
        );
    }
}

export default injectIntl(LayoutHeaderComponent);
