import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";

import { Button } from "@ts-digital/vrc";

import { changePage } from "../../../actions/companies";
import { resendWelcomeEmail, resetResendWelcomeEmail } from "../../../actions/company/users";
import { editRoles, listUsersV3, resetEditRoles } from "../../../actions/company/users";
import { displayErrorToast, displaySuccessToast } from "../../../actions/overflowNotification";
import { fetchItemServices } from "../../../actions/services/service-subscription-v2";
import { fetchAssignableRoles, resetFetchAssignableRoles } from "../../../actions/roles";

import Drawer from "../../../components/general/drawer";
import SelectCompanyDrawerContent from "../../../components/registry/user-manage-table/select-company-drawer";
import RolesAccordion from "../../../components/user/roles-accordion";

import { useOutsideClickRef } from "../../../hooks/use-outside-click";
import { UsersManagegementContext } from "../../../user-context";

import { isUserWithTooManyItems } from "../../../utils/get-roles";
import { buildFormStatus, mapFormToRoles } from "../../../utils/roles-mapper";

import {
    CompanyDescriptionContainer,
    CompanyTitleContainer,
    ContentWrapper,
    Footer,
    ButtonsContainer,
    HeaderContainer,
    TopHeaderContainer,
    YourStudioContainer
} from "./styled";
import LoadingButton from "../../../components/general/loading-button";
import { isEmpty } from "ramda";
import { listItemsRoleCreation } from "../../../actions/registry/read";

const EditUser = ({
    assignableRoles,
    assignableRolesStatus,
    changePage,
    companies,
    currentUser,
    displayErrorToast,
    displaySuccessToast,
    editRoles,
    editUserStatus,
    fetchAssignableRoles,
    getUserV3Status,
    history,
    listItemsRoleCreation,
    listUsersStatus,
    listUsersV3,
    loadedUser,
    match,
    onRemoveUser,
    resetFetchAssignableRoles,
    resendStatus,
    resendWelcomeEmail,
    resetResendWelcomeEmail,
    loggedUser,
    users,
    selectedItem,
    listItemsProp,
    currentCompanyData
}) => {
    const [user, setUser] = useState();
    const [item, setItem] = useState({});
    const [isCompaniesDrawerOpen, setIsCompaniesDrawerOpen] = useState(false);
    const [initialFormState, setInitialFormState] = useState({
        overallPermissions: [{ permissions: [] }],
        activePermissions: [],
        inactivePermissions: []
    });
    const [modifiedRoles, setModifiedRoles] = useState([]);
    const [localServicesPermissions, setLocalServicesPermissions] = useState([]);
    const [initialRoles, setInitialRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { currentItem, setCurrentItem, setSelectedUser } = useContext(UsersManagegementContext);
    const showCompaniesDrawerRef = useOutsideClickRef(() => isCompaniesDrawerOpen && setIsCompaniesDrawerOpen(false));

    const tooManyItems = isUserWithTooManyItems(currentUser.user.roles);
    const enhancedRoles = useMemo(() => {
        return (user && user.enhancedRoles[item && item.itemUuid]) || {};
    }, [item, user]);
    const labels = (user && user.labels[item && item.itemUuid]) || {};

    const intl = useIntl();

    useEffect(() => {
        resetFetchAssignableRoles();
    }, [resetFetchAssignableRoles]);

    useEffect(() => {
        setIsLoading(
            getUserV3Status.started || listUsersStatus.started || assignableRolesStatus.started || listItemsProp.started
        );
    }, [getUserV3Status.started, listUsersStatus, assignableRolesStatus.started, listItemsProp.started]);

    useEffect(() => {
        if (user && user.profile && loggedUser && loggedUser.profile && user.profile.id === loggedUser.profile.id) {
            history.goBack();
        }
    }, [user, loggedUser, history]);

    useEffect(() => {
        if (currentItem && currentItem !== selectedItem.uuid) {
            const itemData = companies.content.find(c => c.itemUuid === selectedItem.uuid);

            if (itemData) {
                setItem({ ...itemData } || {});
            } else if (user && user.profile.id && !companies.status.started) {
                listItemsRoleCreation(
                    null,
                    null,
                    user && user.profile.id,
                    0,
                    10,
                    false,
                    true,
                    true,
                    true,
                    selectedItem.uuid
                );
            }
        } else {
            setCurrentItem(selectedItem.uuid);
            setItem({
                itemId: selectedItem.id,
                itemUuid: selectedItem.uuid,
                description: selectedItem.description,
                workspaceId: selectedItem.workspaceId,
                localServices: selectedItem.localServices
            });
        }
    }, [
        companies,
        currentItem,
        listItemsRoleCreation,
        setCurrentItem,
        setItem,
        user,
        selectedItem.uuid,
        selectedItem.id,
        selectedItem.description,
        selectedItem.workspaceId,
        selectedItem.localServices
    ]);

    useEffect(() => {
        if (
            item.itemId &&
            item.itemUuid &&
            ((!listUsersStatus.started && !listUsersStatus.ended) ||
                (listUsersStatus.ended && users && !users[item.itemUuid]))
        ) {
            listUsersV3(item.itemId, item.itemUuid, 0, 10, [], [], ["P"], null, null, null, null, null, true);
        }
    }, [item.itemId, item.itemUuid, listUsersV3, listUsersStatus.started, listUsersStatus.ended, users]);

    useEffect(() => {
        if (item.itemId && assignableRoles[item.itemId] === undefined && !assignableRolesStatus.started) {
            fetchAssignableRoles(item.itemId);
        }
    }, [fetchAssignableRoles, item.itemId, assignableRolesStatus.started, assignableRoles]);

    useEffect(() => {
        if (assignableRoles && assignableRoles[item.itemId]) {
            setInitialFormState(buildFormStatus(assignableRoles[item.itemId], enhancedRoles));
        }
    }, [assignableRoles, enhancedRoles, item.itemId]);

    useEffect(() => {
        if (loadedUser) {
            setUser(loadedUser);
        } else if (user && (!isEmpty(enhancedRoles) || !isEmpty(labels) || !isEmpty(user.roles))) {
            setUser({ ...user, enhancedRoles: {}, labels: {}, roles: [] });
        }
    }, [enhancedRoles, labels, loadedUser, user]);

    useEffect(() => {
        if (initialFormState && item.itemUuid && assignableRoles[item.itemId]) {
            setInitialRoles(mapFormToRoles(initialFormState, item.itemUuid, assignableRoles[item.itemId]));
        }
    }, [initialFormState, item, assignableRoles]);

    const onSelectCompany = useCallback(
        value => {
            if (user.roles.some(r => r.resourceUuid === value)) {
                setIsCompaniesDrawerOpen(false);
                setCurrentItem(value);
            } else {
                setSelectedUser(match.params.selectedUserId);
                setCurrentItem(value);
                history.push(`/${match.params.item}/registry/user/create`);
            }
        },
        [history, match.params, setCurrentItem, setIsCompaniesDrawerOpen, setSelectedUser, user]
    );

    const onUpdate = useCallback(
        (values, localServices) => {
            setLocalServicesPermissions(localServices);
            if (item.itemUuid && assignableRoles[item.itemId]) {
                setModifiedRoles(mapFormToRoles(values, item.itemUuid, assignableRoles[item.itemId]));
            }
        },
        [assignableRoles, setModifiedRoles, item.itemUuid, item.itemId]
    );

    const onSubmit = useCallback(() => {
        editRoles(user.profile.id, item.itemUuid, modifiedRoles, localServicesPermissions, currentCompanyData.itemUuid);
    }, [editRoles, modifiedRoles, user, item.itemUuid, localServicesPermissions, currentCompanyData.itemUuid]);

    useEffect(() => {
        if (resendStatus.ended) {
            displaySuccessToast(
                <FormattedMessage id="c-registry-user.invite-toast-success.content" />,
                <FormattedMessage id="c-registry-user.invite-toast-success.title" />
            );
            resetResendWelcomeEmail();
        }
    }, [displaySuccessToast, resendStatus.ended, resetResendWelcomeEmail]);

    useEffect(() => {
        if (resendStatus.error) {
            displayErrorToast(
                <FormattedMessage id="c-registry-user.invite-toast-error.content" />,
                <FormattedMessage id="c-registry-user.invite-toast-error.title" />
            );
            resetResendWelcomeEmail();
        }
    }, [displayErrorToast, resendStatus.error, resetResendWelcomeEmail]);

    const invite = useCallback(() => {
        if (user && user.profile) {
            resendWelcomeEmail(user.profile.id);
        }
    }, [resendWelcomeEmail, user]);

    /*
     * if currently the permissions for this user are the same of when you have opened the page, the
     * "Salva" button is disabled. Conversely if you change or remove some permissions, the button will
     * become enabled
     */
    const rolesAreUnchanged = (initialPermissions, currentPermissions) => {
        return (
            JSON.stringify(initialPermissions) === JSON.stringify(currentPermissions) &&
            (localServicesPermissions.length === 0 ||
                localServicesPermissions[0].enabled === selectedItem.localServices)
        );
    };

    const isStudio = selectedItem.classifier === "STUDIO";

    return (
        <>
            <ContentWrapper>
                <HeaderContainer>
                    <TopHeaderContainer>
                        <div style={{ flex: 1 }}>
                            <CompanyTitleContainer>
                                {item && <FormattedMessage id="general.classifiers.COMPANY" />}
                            </CompanyTitleContainer>
                            <CompanyDescriptionContainer>{item && item.description}</CompanyDescriptionContainer>
                        </div>
                        <ButtonsContainer>
                            {user && !user.status.active && (
                                <LoadingButton
                                    loading={resendStatus.started}
                                    kind="secondary"
                                    onClick={invite}
                                    data-gainsight-track={"invite-again-from-edit"}
                                >
                                    <FormattedMessage id={"c-user-roles-table.button.invite"} />
                                </LoadingButton>
                            )}
                            <Button kind="secondary" onClick={() => onRemoveUser(currentItem)}>
                                <FormattedMessage id={"c-registry-user.options.delete"} />
                            </Button>
                        </ButtonsContainer>
                    </TopHeaderContainer>
                    {isStudio && (
                        <YourStudioContainer>
                            {intl.formatMessage({ id: "c-registry-user.your-studio" })}
                        </YourStudioContainer>
                    )}
                </HeaderContainer>
                <RolesAccordion
                    formState={initialFormState}
                    isLoading={isLoading && !isCompaniesDrawerOpen}
                    onUpdate={onUpdate}
                    selectedItem={selectedItem}
                />
            </ContentWrapper>
            <Footer>
                <Button
                    kind="secondary"
                    onClick={() => history.goBack()}
                    style={{ marginRight: 16 }}
                    data-cy="go-back-button"
                >
                    <FormattedMessage id="general.annulla" />
                </Button>
                <LoadingButton
                    disabled={rolesAreUnchanged(initialRoles, modifiedRoles)}
                    onClick={onSubmit}
                    data-cy="next-step-button"
                    loading={editUserStatus.started}
                    data-gainsight-track={"edit-user-save"}
                >
                    <FormattedMessage id="general.save" />
                </LoadingButton>
            </Footer>
            <Drawer
                ref={showCompaniesDrawerRef}
                title={<FormattedMessage id="c-registry-user.change-company.drawer.title" />}
                subtitle={<FormattedMessage id="c-registry-user.change-company.drawer.subtitle" />}
                content={
                    <SelectCompanyDrawerContent
                        changePage={changePage}
                        companies={companies.content}
                        isOpen={isCompaniesDrawerOpen}
                        isUserWithTooManyItems={tooManyItems}
                        loading={companies.status.started}
                        onCompanySelect={onSelectCompany}
                        user={user}
                        flow="edit-user"
                    />
                }
                onClose={() => setIsCompaniesDrawerOpen(false)}
                isOpen={isCompaniesDrawerOpen}
            />
        </>
    );
};

const mapStateToProps = state => ({
    assignableRoles: state.manageUser.roles.assignableRoles,
    assignableRolesStatus: state.manageUser.roles.fetchAssignableRolesStatus,
    companies: state.registry.read.listItemsRoleCreation,
    currentUser: state.user, // logged user
    editUserStatus: state.manageUser.editUserV3.status,
    getUserV3Status: state.getUserV3.status,
    listUsersStatus: state.usersv3.status,
    loadedUser: state.getUserV3.user,
    loggedUser: state.user.user,
    resendStatus: state.manageUser.resendEmail.status,
    users: state.usersv3.users
});

const actions = {
    changePage,
    displayErrorToast,
    displaySuccessToast,
    editRoles,
    fetchAssignableRoles,
    fetchItemServices,
    listItemsRoleCreation,
    listUsersV3,
    resetFetchAssignableRoles,
    resendWelcomeEmail,
    resetEditRoles,
    resetResendWelcomeEmail
};

export default connect(mapStateToProps, actions)(EditUser);
