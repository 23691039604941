import styled from "styled-components";

export const TableTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const TableContainer = styled.div`
    & .ant-table-selection-column  {
        display: none;
    }
`;
