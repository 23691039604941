import styled from "styled-components";

export const LeftColumn = styled.div`
    grid-column-start: 1;
    grid-column-end: 6;
    margin: 0 20px 5px 0;
`;

export const SwitchLabel = styled.div`
    margin-left: 15px;
`;
