import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import UserInfo from "../../components/registry/user-info";

import { errorBoundary } from "../../components/general/error-boundary";

import { getUserInfo } from "../../actions/user";

class UserInfoView extends Component {
    static propTypes = {
        companies: PropTypes.object,
        userRoles: PropTypes.array
    };

    componentDidMount() {
        const { getUserInfo, getUserInfoState, match } = this.props;

        if (!getUserInfoState.user || getUserInfoState.user.profile.id !== match.params.userId) {
            getUserInfo(match.params.userId);
        }
    }

    render() {
        const { companies, userRoles, getUserInfoState, agyoServices } = this.props;

        return (
            <UserInfo
                companies={companies}
                userRoles={userRoles}
                selectedUser={getUserInfoState.user}
                goBack={this.props.history.goBack}
                agyoServices={agyoServices}
            />
        );
    }
}

function mapStateToProps(state, props) {
    let selectedUser = null;
    if (props.match.params.userId) {
        selectedUser =
            state.users.users && state.users.users.find(u => u.user.profile.id === props.match.params.userId);
    }

    return {
        companies: state.companies,
        userRoles: state.user.status.ended ? state.user.user.roles : [],
        selectedUser: selectedUser ? selectedUser.user : {},
        getUserInfoState: state.getUserInfo,
        agyoServices: state.services.agyoServices ? state.services.agyoServices.services : []
    };
}

const actions = {
    getUserInfo
};

const composedHoc = compose(connect(mapStateToProps, actions), errorBoundary);

export default composedHoc(UserInfoView);
