import { fieldRequired } from "@ts-digital/agyo-validations";
import { translateMessage } from "../../i18n";

export const createApiKeySchema = {
    type: "object",
    properties: {
        description: {
            type: "string",
            validate: fieldRequired(translateMessage("general.form.field-required"))
        }
    }
};
