import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Button, Toggle } from "@ts-digital/vrc";
import { FormattedMessage, useIntl } from "react-intl";
import LoadingButton from "../../general/loading-button";
import RolesTable from "../../user/roles-table";
import Modal from "../../general/modal";
import LoadingRolesTable from "../../user/loading-roles-table";
import { ToggleContainer, ToggleLabel } from "../../user/roles-table/styled";

const EditRolesModal = ({ appToEdit, loadingData, onClose, onConfirm, rolesToEdit, submitting, visible }) => {
    const [currentRolesStatus, setCurrentRolesStatus] = useState();

    const [localServiceEnabled, setLocalServiceEnabled] = useState(undefined);

    const updateCurrentRoleStatus = rolesToUpdate => {
        const currentRolesClone = [...currentRolesStatus];

        currentRolesClone.splice(
            currentRolesStatus.findIndex(ele => ele.appId === appToEdit),
            1,
            rolesToUpdate[0]
        );

        setCurrentRolesStatus(currentRolesClone);
    };

    useEffect(() => {
        setCurrentRolesStatus(rolesToEdit);
    }, [rolesToEdit]);

    const intl = useIntl();

    return (
        <Modal
            footer={
                <>
                    <Button colorScheme="ghost" kind="secondary" onClick={() => onClose()}>
                        <FormattedMessage id="general.annulla" />
                    </Button>
                    <LoadingButton
                        data-gainsight-track={"confirm-edit-roles-from-summary-modal"}
                        kind="primary"
                        loading={submitting}
                        onClick={() => onConfirm(currentRolesStatus, localServiceEnabled)}
                    >
                        <FormattedMessage id="general.confirm" />
                    </LoadingButton>
                </>
            }
            onCancel={onClose}
            title={<FormattedMessage id="v-user-summary.role-edit-modal.title" />}
            visible={visible}
        >
            {loadingData ? (
                <LoadingRolesTable
                    rowCount={1}
                    showActivationDateColumn={false}
                    showDescriptionColumn={false}
                    showServiceStatusColumn={false}
                />
            ) : appToEdit !== "$LOCAL_SERVICES$" ? (
                <RolesTable
                    onUpdate={updateCurrentRoleStatus}
                    values={currentRolesStatus && currentRolesStatus.filter(app => app.appId === appToEdit)}
                    showActivationDateColumn={false}
                    showDescriptionColumn={false}
                    showServiceStatusColumn={false}
                />
            ) : (
                <ToggleContainer>
                    <Toggle
                        checked={localServiceEnabled === true || localServiceEnabled === undefined}
                        onChange={value => setLocalServiceEnabled(value)}
                    />
                    <ToggleLabel>{intl.formatMessage({ id: "c-registry-user.local-services" })}</ToggleLabel>
                </ToggleContainer>
            )}
        </Modal>
    );
};

EditRolesModal.propTypes = {
    /** If specified only display roles for the specified app */
    appToEdit: PropTypes.string,
    /** Indicates if the table's data is still being loaded */
    loadingData: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    /** Invoked when the confirm button is pressed. Receives the updated roles as its only param */
    onConfirm: PropTypes.func.isRequired,
    /** The roles to be displayed in the modal */
    rolesToEdit: PropTypes.arrayOf(
        PropTypes.shape({
            appId: PropTypes.string.isRequired,
            assignable: PropTypes.bool.isRequired,
            children: PropTypes.arrayOf(
                PropTypes.shape({
                    appId: PropTypes.string.isRequired,
                    assignable: PropTypes.bool.isRequired,
                    name: PropTypes.string.isRequired,
                    permissions: PropTypes.arrayOf(PropTypes.string),
                    role: PropTypes.string.isRequired
                })
            ),
            name: PropTypes.string.isRequired,
            permissions: PropTypes.arrayOf(PropTypes.string),
            role: PropTypes.string.isRequired
        })
    ),
    /** If true, the modal is displayed */
    visible: PropTypes.bool
};

export default EditRolesModal;
