import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import { Typography } from "@ts-digital/vrc";
import { useExpanded, useFilters, usePagination as usePaginationPlugin, useTable } from "react-table";

import { Pagination } from "./pagination";
import { PlaceholderRows } from "./placeholder-rows";
import { PaginationContainer, TableStyles, FilterCell, HeaderCell, Cell, FooterRow, Row } from "./styled";
import { TextFilter } from "./text-filter";

/**
 * Basic table styled following Vapor's design guidelines (https://vapor.mondora.com/9wvncgrsz/p/59f664/b/603ed/i/1913296).
 * The table's rendering logic is implemented using the react-table library (API docs: https://github.com/tannerlinsley/react-table/blob/master/docs/api.md)
 */

export const Table = ({
    columns,
    data,
    enableFilters,
    footerContent,
    loading,
    page,
    pageSize = 10,
    totalPages,
    onPageChange = () => {},
    usePagination = true,
    useTopPagination = false,
    onFilterSubmit,
    useFooter,
    paginationType = "MANUAL",
    subRowsKeys = ["subRows"],
    ...rest
}) => {
    const defaultColumn = React.useMemo(
        () => ({
            disableFilters: !enableFilters,
            Filter: () => <></>
        }),
        [enableFilters]
    );

    const plugins = [useExpanded, useFilters, usePaginationPlugin];

    const getSubRows = useCallback(
        row => {
            const matchingKeys = row ? Object.keys(row).filter(key => subRowsKeys.includes(key)) : [];

            if (matchingKeys.length === 0) {
                return [];
            }

            return row[matchingKeys[0]];
        },
        [subRowsKeys]
    );

    const {
        getTableProps,
        gotoPage,
        headerGroups,
        page: tablePage,
        pageCount,
        prepareRow,
        state
    } = useTable(
        {
            columns,
            defaultColumn: defaultColumn,
            manualFilters: true,
            data,
            initialState: {
                pageIndex: page,
                pageSize
            },
            pageCount: totalPages,
            manualPagination: paginationType === "MANUAL",
            expandSubRows: true,
            getSubRows
        },
        ...plugins
    );

    useEffect(() => gotoPage(page), [gotoPage, page]);

    return (
        <>
            <TableStyles>
                {usePagination && useTopPagination && (
                    <PaginationContainer>
                        <Pagination
                            currentPage={state.pageIndex || 0}
                            onPageChange={onPageChange}
                            totalPages={pageCount}
                        />
                    </PaginationContainer>
                )}
                <table {...getTableProps()} {...rest}>
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr key={`header-container-${index}`} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <HeaderCell {...column.getHeaderProps()} width={column.width}>
                                        <div>
                                            <Typography variant="buttonsAndCtas">{column.render("Header")}</Typography>
                                        </div>
                                    </HeaderCell>
                                ))}
                            </tr>
                        ))}
                        {headerGroups.map((headerGroup, index) => (
                            <tr key={`filter-container-${index}`}>
                                {headerGroup.headers.map((column, i) => {
                                    return column.canFilter ? (
                                        <FilterCell key={`filter-container-${i}`}>
                                            <TextFilter
                                                key={`filter-container-${i}`}
                                                onFilterSubmit={e => {
                                                    onFilterSubmit(e, column.id);
                                                }}
                                                column={column}
                                            />
                                        </FilterCell>
                                    ) : enableFilters ? (
                                        <FilterCell key={`filter-container-${i}`}></FilterCell>
                                    ) : null;
                                })}
                            </tr>
                        ))}
                    </thead>
                    {useFooter && (
                        <FooterRow>
                            <tr>{footerContent}</tr>
                        </FooterRow>
                    )}
                    <tbody>
                        {loading ? (
                            <PlaceholderRows
                                columnCount={columns.length}
                                pageSize={pageSize}
                                data-cy="table-loading-placeholder"
                            />
                        ) : (
                            tablePage.map(
                                (row, i) =>
                                    prepareRow(row) || (
                                        <Row
                                            {...row.getRowProps()}
                                            isExpanded={row.isExpanded}
                                            isTopLevel={row.depth === 0}
                                            highlightFirstRow={rest.highlightFirstRow || false}
                                            rowNumber={i + state.pageIndex * pageSize}
                                        >
                                            {row.cells.map(cell => {
                                                return (
                                                    <Cell {...cell.getCellProps()}>
                                                        <div>{cell.render("Cell")}</div>
                                                    </Cell>
                                                );
                                            })}
                                        </Row>
                                    )
                            )
                        )}
                    </tbody>
                </table>
            </TableStyles>
            {usePagination && (
                <PaginationContainer>
                    <Pagination currentPage={state.pageIndex || 0} onPageChange={onPageChange} totalPages={pageCount} />
                </PaginationContainer>
            )}
        </>
    );
};

Table.propTypes = {
    /** Column definitions for the table. Must be memoized */
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            /** The key under which the column's data can be found or a function to be used to retrieve the value from the dataset */
            accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
            /** Unique Id for the column. Only required if accessor is a function */
            id: PropTypes.string,
            /** If specified, the column will be treated as a column group */
            columns: PropTypes.array,
            /** A boolean value (or a function returning a boolean value) used to specify if the column should be visible */
            show: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
            /** Used to specify this column's header cell's react component */
            Header: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
            /** Used to specify this column's cells' react component */
            Cell: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        })
    ).isRequired,
    /** The table's dataset. Must be memoized */
    data: PropTypes.array.isRequired,
    /** Content of the footer row */
    footerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** If true, the table's content will be replaced by a loading placeholder */
    loading: PropTypes.bool,
    /** The current page number, starting from 0 */
    page: PropTypes.number,
    /** Size of a single page, used to generate the loading placeholder rows */
    pageSize: PropTypes.number,
    /** The total number of available pages */
    totalPages: PropTypes.number,
    /** Callback invoked on table page change */
    onPageChange: PropTypes.func,
    /** Whether to use manual (server-side) or automatic (client-side) pagination */
    paginationType: PropTypes.oneOf(["AUTO", "MANUAL"]),
    /** Array of keys which, if present, contain a subrow. Defaults to ["subRows"] */
    subRowsKeys: PropTypes.arrayOf(PropTypes.string),
    /** Whether the pagination element should be displayed. Defaults to true */
    usePagination: PropTypes.bool,
    /** Whether the pagination element should be displayed also on top of the table. Defaults to false */
    useTopPagination: PropTypes.bool,
    /** Enable footer row */
    useFooter: PropTypes.bool
};
