import styled from "styled-components";

export const WarningContainer = styled.div`
    margin: 0 20px;
    background-color: rgb(224, 106, 106);
    border: 1px solid rgb(141, 0, 0);
    border-radius: 10px;
    padding: 10px;
    font-weight: 400;
    color: white;
`;

export const MessageContainer = styled.span`
    margin-left: 10px;
`;
