import styled from "styled-components";

import { Modal } from "antd";
import { Button } from "@ts-digital/arc/antd/Button";

export const FormContainer = styled.div`
    background: #ffffff;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
    margin: auto;
    min-width: 800px;
    /* padding: 40px; */
    width: 70%;

    & span {
        font-size: 15px;
    }
`;

export const FormHeader = styled.div`
    border-bottom: 2px solid #0090d1;
`;

export const FormContent = styled.div`
    padding: 24px;
    margin: auto;
`;

export const Title = styled.div`
    padding: 16px 24px;

    & span {
        background: #fff;
        color: #000000;
        font-size: 24px;
        font-weight: 400;
        color: #005075;
    }
`;

export const StyledIcon = styled.div`
    margin: 30px;
`;

export const StyledModal = styled(Modal)`
    text-align: center;
    & .ant-modal-close-x {
        color: #0090d1;
        font-size: 24px;
    }
`;

export const ModalButtonsContainer = styled.div`
    margin-top: 50px;
    display: flex;
    justify-content: space-evenly;
`;

export const ModalButton = styled(Button)`
    min-width: 200px;
`;

export const ModalText = styled.div`
    font-size: 24px;
    margin-top: 30px;
`;
