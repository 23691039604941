import {
    ARE_LINKED_ITEMS_ERROR,
    ARE_LINKED_ITEMS_START,
    ARE_LINKED_ITEMS_SUCCESS,
    FIND_OWN_MANAGED_CONNECTIONS_ERROR,
    FIND_OWN_MANAGED_CONNECTIONS_RESET,
    FIND_OWN_MANAGED_CONNECTIONS_START,
    FIND_OWN_MANAGED_CONNECTIONS_SUCCESS,
    FIND_OWN_MANAGER_CONNECTIONS_ERROR,
    FIND_OWN_MANAGER_CONNECTIONS_RESET,
    FIND_OWN_MANAGER_CONNECTIONS_START,
    FIND_OWN_MANAGER_CONNECTIONS_SUCCESS,
    GET_CONNECTION_ERROR,
    GET_CONNECTION_START,
    GET_CONNECTION_SUCCESS
} from "../../actions/connections/read";
import { DELETE_LINK_SUCCESS } from "../../actions/connections/write";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    },
    content: []
};

export function findOwnManagedConnections(state = defaultState, { type, payload, error }) {
    switch (type) {
        case FIND_OWN_MANAGED_CONNECTIONS_START:
            return {
                ...defaultState,
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case FIND_OWN_MANAGED_CONNECTIONS_SUCCESS:
            return {
                ...defaultState,
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case FIND_OWN_MANAGED_CONNECTIONS_ERROR:
            return {
                ...defaultState,
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: {
                        code: error.code,
                        message: error.message
                    }
                }
            };
        case FIND_OWN_MANAGED_CONNECTIONS_RESET:
            return {
                ...defaultState
            };
        case DELETE_LINK_SUCCESS:
            if (payload && payload.deleteActionContext === "MANAGED") {
                return {
                    ...state,
                    content: getConnectionsUpdatedContent(state, payload)
                };
            }
            return state;

        default:
            return state;
    }
}

export function findOwnManagerConnections(state = defaultState, { type, payload, error }) {
    switch (type) {
        case FIND_OWN_MANAGER_CONNECTIONS_START:
            return {
                ...defaultState,
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case FIND_OWN_MANAGER_CONNECTIONS_SUCCESS:
            return {
                ...defaultState,
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case FIND_OWN_MANAGER_CONNECTIONS_ERROR:
            return {
                ...defaultState,
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: {
                        code: error.code,
                        message: error.message
                    }
                }
            };
        case FIND_OWN_MANAGER_CONNECTIONS_RESET:
            return {
                ...defaultState
            };
        case DELETE_LINK_SUCCESS:
            if (payload && payload.deleteActionContext === "MANAGER") {
                return {
                    ...state,
                    content: getConnectionsUpdatedContent(state, payload)
                };
            }
            return state;
        default:
            return state;
    }
}

/**
 * Otmistic update when user invoke deleteLink action
 * @param {*} state
 * @param {*} payload
 */

const getConnectionsUpdatedContent = (state, payload) => {
    const linkIndex = state.content.findIndex(
        link => link.connections.findIndex(c => c.id === payload.connectionId) !== -1
    );
    let link = { ...state.content[linkIndex] };

    const deletedConnectionIndex = link.connections.findIndex(c => c.id === payload.connectionId);
    let deletedConnection = {
        ...link.connections[deletedConnectionIndex],
        status: {
            ...link.connections[deletedConnectionIndex].status,
            active: false,
            deleted: true
        }
    };

    link.connections[deletedConnectionIndex] = deletedConnection;

    let updatedContent = [...state.content];
    updatedContent[linkIndex] = link;
    return updatedContent;
};

const defaultGetConnectionState = {
    ...defaultState,
    result: {
        id: "",
        managerId: "",
        managedId: "",
        connections: []
    }
};

export function getConnection(state = defaultGetConnectionState, { type, payload, error }) {
    switch (type) {
        case GET_CONNECTION_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                },
                result: defaultGetConnectionState.result
            };
        case GET_CONNECTION_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                result: { ...payload }
            };
        case GET_CONNECTION_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: {
                        code: error.code,
                        message: error.message
                    }
                },
                result: defaultGetConnectionState.result
            };
        default:
            return state;
    }
}

const defaultLinkedItemsState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    },
    list: []
};

export function linkedItems(state = defaultLinkedItemsState, { type, payload, error }) {
    switch (type) {
        case ARE_LINKED_ITEMS_START:
            return {
                ...defaultLinkedItemsState,
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case ARE_LINKED_ITEMS_SUCCESS:
            return {
                ...defaultLinkedItemsState,
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                list: payload.content.map(v => v.managedId)
            };
        case ARE_LINKED_ITEMS_ERROR:
            return {
                ...defaultLinkedItemsState,
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: {
                        code: error.code,
                        message: error.message
                    }
                }
            };
        default:
            return state;
    }
}
