import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { Layout } from "antd";

import CookiePolicy from "../../components/cookie-policy";
import Logo from "../../components/general/logo";
import Header from "../../components/header";

import { logout } from "../../actions/auth";
import { loadUser } from "../../actions/user";
import { loadCompany } from "../../actions/company";
import { copyTokenToast } from "../../actions/toastNotifications";
import { getNotificationsList, getNotificationsStatus } from "../../actions/ncs/read";
import {
    deleteSingleNotification,
    deleteNotificationsList,
    updateSingleNotification,
    updateNotificationsList
} from "../../actions/ncs/write";

const Cookie = ({
    auth,
    user,
    notificationsExist,
    notificationsList,
    notificationListStatus,
    deleteSingleNotification,
    deleteNotificationsList,
    getNotificationsList,
    getNotificationsStatus,
    updateSingleNotification,
    updateNotificationsList,
    logout,
    loadUser,
    copyTokenToast
}) => {
    const getNcsNotificationsList = useCallback(() => {
        if (user.user) {
            getNotificationsList(user.user.profile.ncsId);
        }
    }, [getNotificationsList, user]);

    const getNcsNotificationsExist = useCallback(() => {
        if (user.user) {
            getNotificationsStatus(user.user.profile.ncsId);
        }
    }, [getNotificationsStatus, user]);

    return (
        <Layout className="Root">
            <Layout.Header className="Root-header">
                <div className="Root-header-text-container">
                    <Logo white={true} height={30} />
                </div>
                <Header
                    auth={auth}
                    onNotificationAccept={() => {}}
                    user={user}
                    logout={logout}
                    resetCompanyLoad={null}
                    notifications={[]}
                    notificationsExist={notificationsExist}
                    notificationsList={notificationsList}
                    notificationListStatus={notificationListStatus}
                    deleteSingleNotification={notificationId =>
                        deleteSingleNotification(user.user.profile.ncsId, notificationId)
                    }
                    deleteNotificationsList={() => deleteNotificationsList(user.user.profile.ncsId)}
                    getNotifications={getNcsNotificationsList}
                    getNotificationsExist={getNcsNotificationsExist}
                    updateNotificationsList={request => updateNotificationsList(user.user.profile.ncsId, request)}
                    updateSingleNotification={(notificationId, request) =>
                        updateSingleNotification(user.user.profile.ncsId, notificationId, request)
                    }
                    onNotificationReject={() => {}}
                    onReadNotification={() => {}}
                    onUnreadNotification={() => {}}
                    unreadNotifications={0}
                    showTour={false}
                    showNotification={false}
                    loadUser={loadUser}
                    copyTokenToast={copyTokenToast}
                    updateUserPswStatus={{}}
                    userEditStatus={{}}
                    updateUserPsw={() => {}}
                />
            </Layout.Header>
            <Layout.Content className="Root-content">
                <CookiePolicy />
            </Layout.Content>
        </Layout>
    );
};

Cookie.propTypes = {
    auth: PropTypes.object.isRequired,
    copyTokenToast: PropTypes.func.isRequired,
    deleteSingleNotification: PropTypes.func.isRequired,
    deleteNotificationsList: PropTypes.func.isRequired,
    getNotificationsList: PropTypes.func.isRequired,
    getNotificationsStatus: PropTypes.func.isRequired,
    updateSingleNotification: PropTypes.func.isRequired,
    updateNotificationsList: PropTypes.func.isRequired,
    notificationsExist: PropTypes.bool.isRequired,
    notificationsList: PropTypes.array,
    notificationListStatus: PropTypes.object,
    loadCompany: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    notificationsExist: state.ncs.notifications.exist,
    notificationsList: state.ncs.notifications.data,
    notificationListStatus: state.ncs.notifications.status,
    user: state.user
});

export default connect(mapStateToProps, {
    copyTokenToast,
    deleteSingleNotification,
    deleteNotificationsList,
    getNotificationsList,
    getNotificationsStatus,
    updateSingleNotification,
    updateNotificationsList,
    loadCompany,
    loadUser,
    logout
})(Cookie);
