import React from "react";
import PropTypes from "prop-types";

import { Popconfirm } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Button, Container, LoadingButton } from "./styled";

export const EndButtonsContent = ({
    abortLabel,
    backDisabled,
    backRedirectUrl,
    endDisabled,
    loading,
    onBack,
    onConfirm,
    showBack,
    submitLabel
}) => {
    const { formatMessage } = useIntl();

    return (
        <Container>
            <Link to={backRedirectUrl}>
                <Button disabled={loading} variant="secondary">
                    <FormattedMessage id="general.annulla" />
                </Button>
            </Link>

            {showBack && (
                <Button disabled={backDisabled || loading} onClick={onBack} variant="secondary">
                    <FormattedMessage id={abortLabel} />
                </Button>
            )}

            <Popconfirm
                placement="topRight"
                title={formatMessage({
                    id: "c-manage-data.multicompany-create.confirm"
                })}
                onConfirm={onConfirm}
                okText={formatMessage({
                    id: "general.yes"
                })}
                cancelText={formatMessage({
                    id: "general.no"
                })}
            >
                <LoadingButton disabled={endDisabled} loading={loading} variant="primary">
                    <FormattedMessage id={submitLabel} />
                </LoadingButton>
            </Popconfirm>
        </Container>
    );
};

EndButtonsContent.propTypes = {
    abortLabel: PropTypes.string,
    backRedirectUrl: PropTypes.string.isRequired, // url al quale rimandare alla pressione del back
    showBack: PropTypes.bool, // mostra bottone "indietro"
    backDisabled: PropTypes.bool, // disabilita bottone di indietro
    endDisabled: PropTypes.bool, // disabilita bottone di "fine"
    onBack: PropTypes.func, // callback per funzione "indietro"
    onConfirm: PropTypes.func, // callback per funzione "fine"
    submitLabel: PropTypes.string
};

EndButtonsContent.defaultProps = {
    abortLabel: "general.back",
    submitLabel: "general.end"
};

export default EndButtonsContent;
