import React from "react";
import PropTypes from "prop-types";

import PortaleChildApp from "..";
import { Iframe } from "./styled";
import { useCallback } from "react";
import { useEffect } from "react";

const MigratingChildApp = ({ customLaunchData, endpoint, hideElevioAndUservoice, iframeConfigs, migrated, url }) => {
    const handlePostMessage = useCallback(
        event => {
            if (event.data === "getToken") {
                document.getElementById(iframeConfigs.id).contentWindow.postMessage(iframeConfigs.data, url);
            }
        },
        [iframeConfigs.data, iframeConfigs.id, url]
    );

    useEffect(() => {
        window.addEventListener("message", handlePostMessage);

        return () => window.removeEventListener("message", handlePostMessage);
    }, [handlePostMessage, migrated]);

    const handleLoad = useCallback(() => {
        if (iframeConfigs.onLoad) {
            iframeConfigs.onLoad(document.getElementById(iframeConfigs.id));
        }

        return;
    }, [iframeConfigs]);

    return migrated ? (
        <PortaleChildApp
            customLaunchData={customLaunchData}
            hideElevioAndUservoice={hideElevioAndUservoice}
            url={endpoint ? `${url}/${endpoint}` : url}
        />
    ) : (
        <Iframe
            className={iframeConfigs.className || undefined}
            id={iframeConfigs.id}
            title={iframeConfigs.title}
            onLoad={handleLoad}
            src={endpoint ? `${url}/${endpoint}` : url}
        />
    );
};

MigratingChildApp.propTypes = {
    customLaunchData: PropTypes.object,
    endpoint: PropTypes.string,
    hideElevioAndUservoice: PropTypes.bool,
    iframeConfigs: PropTypes.shape({
        className: PropTypes.string,
        id: PropTypes.string.isRequired,
        data: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        onLoad: PropTypes.func
    }).isRequired,
    migrated: PropTypes.bool,
    url: PropTypes.string.isRequired
};

MigratingChildApp.defaultProps = {
    hideElevioAndUservoice: false,
    migrated: false
};

export default MigratingChildApp;
