import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { Table, Button, Modal, Popover, Tag, Icon } from "antd";
import { FormattedMessage } from "react-intl";
import { countryValueWithCodice } from "@ts-digital/agyo-validations/lib/common/constants";

import "./style.css";

import { downloadFilledDocument, getSignerData, listSigners } from "../../../actions/sign-api-read";
import {
    assignSigner,
    cancel,
    createPdf,
    resetPin,
    uploadSignedPdf,
    resetAssignSigner,
    resetStateResetPin,
    resetCreatePdf
} from "../../../actions/sign-api-write";

import SignatureInfoRow from "./row";
import SignatureInfo from "./info";
import AddSigner from "./create";
import AssignedSigner from "./assigned";

const locale = {
    emptyText: <FormattedMessage id="general.no-result" />
};
const columns = [
    {
        title: <FormattedMessage id="general.codice-fiscale" />,
        dataIndex: "cfAzienda",
        key: "cfAzienda"
    },
    {
        title: <FormattedMessage id="general.signer" />,
        dataIndex: "cfUtente",
        key: "cfUtente"
    },
    {
        title: <FormattedMessage id="general.stato" />,
        dataIndex: "extradata.status",
        key: "extradata.status"
    }
];

class Signature extends Component {
    constructor() {
        super();
        this.state = {
            mAddSigner: false,
            mAssignedSigner: false,
            mEditSigner: false,
            mShowInfo: false,
            signerSelected: null
        };
    }

    static propTypes = {
        assignSigner: PropTypes.func.isRequired,
        cancel: PropTypes.func.isRequired,
        cancelSigner: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
        createPdf: PropTypes.func.isRequired,
        documentFilled: PropTypes.object.isRequired,
        downloadFilledDocument: PropTypes.func.isRequired,
        getSignerData: PropTypes.func.isRequired,
        listSigners: PropTypes.func.isRequired,
        match: PropTypes.object.isRequired,
        rejectContract: PropTypes.func,
        resetAssignSigner: PropTypes.func.isRequired,
        resetCreatePdf: PropTypes.func.isRequired,
        resetPin: PropTypes.func.isRequired,
        resetStateResetPin: PropTypes.func.isRequired,
        servicesType: PropTypes.string.isRequired,
        signApiWrite: PropTypes.object,
        signatureType: PropTypes.string.isRequired,
        signerData: PropTypes.object,
        signers: PropTypes.object,
        uploadSignedPdf: PropTypes.func.isRequired
    };

    getData() {
        const { signers, signatureType } = this.props;
        let data = signers.signers;
        let filters = [];
        if (data !== null && data !== undefined) {
            filters = signers.signers.filter(function (el) {
                return el.certType === signatureType;
            });
        }
        return filters;
    }

    onClick(value) {
        const { downloadFilledDocument, getSignerData } = this.props;
        getSignerData(value.id.toString());
        downloadFilledDocument(value.id.toString());
        this.setState({
            mShowInfo: true,
            mAddSigner: false,
            signerSelected: value
        });
    }

    assignedSigner(signerData) {
        const { assignSigner, company, listSigners, servicesType } = this.props;
        let infoSigner = {
            certType: "FRM",
            cfUtente: signerData.signerFiscalCode,
            cfAzienda: company.base.identifier.taxId,
            serviceName: servicesType
        };

        assignSigner(infoSigner, company.base.id, signerData.pin).then(() => {
            this.setState({ mAssignedSigner: false });
            listSigners(company.base.id, servicesType);
        });
    }

    createPdfSigner(signerData) {
        const { createPdf, company, listSigners, servicesType, signatureType } = this.props;
        const { signerSelected } = this.state;

        let index = countryValueWithCodice.findIndex(x => x.id === signerData.signerBirthCountry);

        let infoSigner = {
            id: signerSelected !== null ? signerSelected.id.toString() : null,
            certType: signatureType,
            cfUtente: signerData.signerFiscalCode,
            cfAzienda: company.base.identifier.taxId,
            serviceName: servicesType
        };
        let signerDataCorrect = {
            ...signerData,
            signerBirthCountry: countryValueWithCodice[index].codice,
            signerFiscalCountry: "IT",
            signerBirthDate: `${new Date(signerData.signerBirthDate).getTime()}`,
            signerDocumentEndDate: `${new Date(signerData.signerDocumentEndDate).getTime()}`,
            signerDistrict: ""
        };
        createPdf(infoSigner, signerDataCorrect).then(() => {
            this.setState({ mAddSigner: false, mEditSigner: false });
            listSigners(company.base.id, servicesType);
        });
    }

    renderShowInfo() {
        const { mShowInfo, signerSelected, mEditSigner } = this.state;
        const {
            cancel,
            documentFilled,
            resetPin,
            signerData,
            uploadSignedPdf,
            servicesType,
            signApiWrite,
            cancelSigner,
            company,
            listSigners,
            signatureType,
            resetStateResetPin
        } = this.props;
        return (
            <SignatureInfo
                cancel={cancel}
                documentFilled={documentFilled}
                mEditSigner={mEditSigner}
                mShowInfo={mShowInfo}
                resetPin={resetPin}
                signer={signerSelected}
                signerData={signerData}
                uploadSignedPdf={uploadSignedPdf}
                signApiWrite={signApiWrite}
                cancelSigner={cancelSigner}
                company={company}
                listSigners={listSigners}
                signatureType={signatureType}
                resetStateResetPin={resetStateResetPin}
                handleSubmit={e => this.createPdfSigner(e)}
                servicesType={servicesType}
                editAction={() => this.setState({ mEditSigner: true, mShowInfo: false })}
                handleCloseEdit={() => this.setState({ mEditSigner: false })}
                handleCloseInfo={() => this.setState({ mShowInfo: false })}
            />
        );
    }

    renderAddSigner() {
        const { mAddSigner } = this.state;
        const { signatureType, company, servicesType, signApiWrite } = this.props;

        let initialValues = {
            cfAzienda: company.base.identifier.taxId,
            certType: signatureType,
            serviceName: servicesType
        };

        return mAddSigner ? (
            <div>
                <div className="Signature-add-signer">
                    <AddSigner
                        handleClose={() => this.setState({ mAddSigner: false })}
                        initialValues={initialValues}
                        onSubmit={e => this.createPdfSigner(e)}
                        signatureType={signatureType}
                        signApiWrite={signApiWrite}
                    />
                </div>
            </div>
        ) : null;
    }

    renderAddSignerStatus() {
        const {
            signApiWrite: { createPdf },
            resetCreatePdf
        } = this.props;
        return (
            <div>
                <Modal
                    footer={null}
                    onCancel={() => resetCreatePdf()}
                    visible={createPdf.status.error || createPdf.status.ended}
                    style={{ top: "20%" }}
                    width="600px"
                >
                    {createPdf.status.error ? (
                        <div style={{ textAlign: "center" }}>
                            <Icon
                                type="check-circle"
                                style={{
                                    fontSize: "30px",
                                    color: "#f44336",
                                    marginTop: "30px"
                                }}
                            />
                            <br />
                            <p style={{ fontSize: "16px" }}>
                                <FormattedMessage id="c-signature.create-error" />
                                {createPdf.status.errorInfo.message}
                            </p>
                            <br />
                            <Button onClick={() => resetCreatePdf()}>
                                <FormattedMessage id="general.retry" />
                            </Button>
                        </div>
                    ) : (
                        <div style={{ textAlign: "center" }}>
                            <Icon
                                type="check-circle"
                                style={{
                                    fontSize: "30px",
                                    color: "#00a854",
                                    marginTop: "30px"
                                }}
                            />
                            <br />
                            <p style={{ fontSize: "16px" }}>
                                <FormattedMessage id="c-signature.create-success" />
                            </p>
                            <br />
                            <Button onClick={() => resetCreatePdf()}>
                                <FormattedMessage id="general.close" />
                            </Button>
                        </div>
                    )}
                </Modal>
            </div>
        );
    }

    renderModalAssignedSigner() {
        const { mAssignedSigner } = this.state;
        const {
            signApiWrite: { assignSigner },
            resetAssignSigner
        } = this.props;
        return (
            <div>
                <Modal
                    footer={null}
                    onCancel={() => this.setState({ mAssignedSigner: false })}
                    visible={mAssignedSigner}
                    style={{ top: "20%" }}
                    width="600px"
                    title={<FormattedMessage id="c-signature.link-certificate" />}
                >
                    {assignSigner.status.error ? (
                        <div style={{ textAlign: "center" }}>
                            <p>{assignSigner.status.errorInfo.message}</p>
                            <Button onClick={() => resetAssignSigner()}>
                                <FormattedMessage id="general.retry" />
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <p>
                                <FormattedMessage id="c-signature.type-pin-fiscal-code" />
                            </p>
                            <br />
                            <AssignedSigner onSubmit={e => this.assignedSigner(e)} />
                        </div>
                    )}
                </Modal>
            </div>
        );
    }

    renderTable() {
        const { mAddSigner, mEditSigner, mShowInfo } = this.state;
        const { servicesType, signers, signatureType } = this.props;
        let signerCount = signers.signers !== undefined && signers.signers !== null && signers.signers.length === 1;

        let remaningCertified = 0;
        let totalCertified = 0;
        if (signers.status.ended) {
            remaningCertified =
                signatureType === "FRM" ? signers.remainingFRM.toString() : signers.remainingFSM.toString();
            totalCertified = signatureType === "FRM" ? signers.totalFRM.toString() : signers.totalFSM.toString();
        }

        return !mAddSigner && !mEditSigner ? (
            <div>
                <div className={mShowInfo ? "Signature-table" : "Signature-table bigger"}>
                    <div style={{ marginBottom: "20px" }}>
                        <Button
                            disabled={
                                (servicesType === "A-FISCALI-IVA-FLOW" && signerCount) ||
                                signers.status.started ||
                                (servicesType === "A-FISCALI-IVA-FLOW" ? false : remaningCertified === "0")
                            }
                            style={{ marginRight: "10px", marginTop: "10px" }}
                            onClick={() =>
                                this.setState({
                                    mAddSigner: true,
                                    mShowInfo: false,
                                    signerSelected: null
                                })
                            }
                        >
                            <FormattedMessage id="c-signature.new-certificate" />
                        </Button>
                        {signatureType === "FSM" ? (
                            <Popover content={<FormattedMessage id="general.out-soon" />} placement="bottom">
                                <Button
                                    disabled
                                    style={{
                                        marginRight: "10px",
                                        marginTop: "10px"
                                    }}
                                >
                                    <FormattedMessage id="c-signature.new-certificate.con-delega" />
                                </Button>
                            </Popover>
                        ) : null}
                        {servicesType === "A-FISCALI-IVA-FLOW" ? (
                            <Button
                                disabled={signerCount || signers.status.started}
                                onClick={() => this.setState({ mAssignedSigner: true })}
                            >
                                <FormattedMessage id="c-signature.link-certificate" />
                            </Button>
                        ) : null}
                        {servicesType === "A-FISCALI-IVA-FLOW" ? null : (
                            <Tag style={{ float: "right", marginTop: "10px" }}>
                                {remaningCertified + "/" + totalCertified}
                            </Tag>
                        )}
                    </div>
                    <Table
                        dataSource={this.getData()}
                        locale={locale}
                        rowKey={"cfUtente"}
                        loading={signers.status.started}
                        style={{ borderRadius: "5px" }}
                        bordered
                    >
                        {columns.map((column, index) => (
                            <Table.Column
                                dataIndex={column.dataIndex}
                                key={index}
                                render={(text, row) => (
                                    <SignatureInfoRow value={row} onClick={row => this.onClick(row)} text={text} />
                                )}
                                title={column.title}
                            />
                        ))}
                    </Table>
                </div>
            </div>
        ) : null;
    }

    render() {
        return (
            <div>
                {this.renderTable()}
                {this.renderShowInfo()}
                {this.renderAddSigner()}
                {this.renderAddSignerStatus()}
                {this.renderModalAssignedSigner()}
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        cancelSigner: state.signApiWrite.cancel,
        company: state.companies.data[props.match.params.item]
            ? state.companies.data[props.match.params.item].item
            : {},
        documentFilled: state.signApiRead.downloadFilledDocument,
        signApiWrite: state.signApiWrite,
        signerData: state.signApiRead.getSignerData,
        signers: state.signApiRead.listSigners
    };
}

function mapDispatchToProps(dispatch) {
    return {
        assignSigner: bindActionCreators(assignSigner, dispatch),
        cancel: bindActionCreators(cancel, dispatch),
        createPdf: bindActionCreators(createPdf, dispatch),
        downloadFilledDocument: bindActionCreators(downloadFilledDocument, dispatch),
        getSignerData: bindActionCreators(getSignerData, dispatch),
        listSigners: bindActionCreators(listSigners, dispatch),
        resetCreatePdf: bindActionCreators(resetCreatePdf, dispatch),
        resetAssignSigner: bindActionCreators(resetAssignSigner, dispatch),
        resetPin: bindActionCreators(resetPin, dispatch),
        resetStateResetPin: bindActionCreators(resetStateResetPin, dispatch),
        uploadSignedPdf: bindActionCreators(uploadSignedPdf, dispatch)
    };
}

const composedHOC = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));

export default composedHOC(Signature);
