import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";

import Cdc from "../../../components/console-digitale";
import { errorBoundary } from "../../../components/general/error-boundary";
import PageTitle from "../../../components/general/page-title";

import { getWaitingList } from "../../../actions/waitinglist/read";
import { subscribeWaitinglist, deleteWaitinglist } from "../../../actions/waitinglist/write";

import "../style.css";

class ConsultantView extends React.Component {
    static propTypes = {
        company: PropTypes.object.isRequired,
        history: PropTypes.object,
        services: PropTypes.object.isRequired
    };

    render() {
        const {
            company,
            history,
            services,
            getWaitingList,
            getWaitingListStatus,
            subscribeWaitinglist,
            deleteWaitinglist,
            waitinglistDeleteStatus,
            waitinglist,
            waitinglistSubscribeStatus
        } = this.props;
        return (
            <div className="service-view">
                <PageTitle title={<FormattedMessage id="c-services-view.DBX.name" />} onBack={() => history.goBack()} />
                <Cdc
                    company={company}
                    services={services}
                    getWaitingList={getWaitingList}
                    getWaitingListStatus={getWaitingListStatus}
                    subscribeWaitinglist={subscribeWaitinglist}
                    deleteWaitinglist={deleteWaitinglist}
                    waitinglistDeleteStatus={waitinglistDeleteStatus}
                    waitinglist={waitinglist}
                    waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    services: state.services,
    waitinglist: state.waitinglist.read.getWaitingList.waitinglist,
    waitinglistSubscribeStatus: state.waitinglist.write.subscribeWaitinglist.status,
    waitinglistDeleteStatus: state.waitinglist.write.deleteWaitinglist.status,
    getWaitingListStatus: state.waitinglist.read.getWaitingList.status
});

const composedHoc = compose(
    connect(mapStateToProps, { getWaitingList, subscribeWaitinglist, deleteWaitinglist }),
    errorBoundary
);

export default composedHoc(ConsultantView);
