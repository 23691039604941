import {
    GET_SDI_CONFIG_START,
    GET_SDI_CONFIG_ERROR,
    GET_SDI_CONFIG_SUCCESS,
    LIST_SDI_CONFIG_START,
    LIST_SDI_CONFIG_ERROR,
    LIST_SDI_CONFIG_SUCCESS,
    SDI_CONFIG_RESET
} from "../../actions/services/sdi";
import { combineReducers } from "redux";

const defaultStatus = {
    started: false,
    ended: false,
    error: false,
    errorInfo: {}
};

export const status = (state = defaultStatus, { error, type, payload }) => {
    switch (type) {
        case GET_SDI_CONFIG_START:
        case LIST_SDI_CONFIG_START:
            return {
                ...defaultStatus,
                started: true
            };
        case GET_SDI_CONFIG_ERROR:
        case LIST_SDI_CONFIG_ERROR:
            return {
                ...defaultStatus,
                error: true,
                errorInfo: error
            };
        case GET_SDI_CONFIG_SUCCESS:
        case LIST_SDI_CONFIG_SUCCESS:
            return {
                ...defaultStatus,
                ended: true
            };
        default:
            return state;
    }
};

export const configs = (state = {}, { error, payload, type }) => {
    switch (type) {
        case GET_SDI_CONFIG_SUCCESS:
            return {
                ...state,
                [payload.itemId]: payload
            };
        case LIST_SDI_CONFIG_SUCCESS:
            var outPayload = {};
            if (payload.configs) {
                payload.configs.forEach(config => {
                    outPayload[config.itemId] = config;
                });
            }
            return {
                ...state,
                ...outPayload
            };
        case SDI_CONFIG_RESET:
            return {};
        default:
            return state;
    }
};

export default combineReducers({
    configs,
    status
});
