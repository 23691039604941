import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { Button } from "@ts-digital/vrc";

import WaitingListButton from "../../general/waitlist-button";

import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { Redirect, Link } from "react-router-dom";

import { errorBoundary } from "../../general/error-boundary";
import FontawesomeIcon from "../../general/fontawesome-icon";

import { TS_DIGITAL_IMAGES } from "../../../config";
import { activateService, activateServiceReset } from "../../../actions/services/service-subscription";
import { getWaitingList } from "../../../actions/waitinglist/read";
import { subscribeWaitinglist, deleteWaitinglist } from "../../../actions/waitinglist/write";

import { esmContactUs, esmActivate } from "../../../actions/gainsightEvents";

import "./style.css";

import {
    InfoContainer,
    Info,
    MediaContainer,
    Title,
    Logo,
    LogoContainer,
    BoxContainer,
    BoxTitle,
    BoxDescription,
    ButtonsContainer,
    Disclaimer,
    DisclaimerIcon
} from "./styled.js";

import { appDetails } from "./app-details";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

export const Detail = ({
    activateService,
    activateServiceReset,
    activateServicesStatus,
    activeServices,
    company,
    deleteWaitinglist,
    esmActivate,
    esmContactUs,
    getWaitingList,
    getWaitingListStatus,
    history,
    match,
    servicesInfo,
    subscribeWaitinglist,
    user,
    waitinglist,
    waitinglistDeleteStatus,
    waitinglistSubscribeStatus
}) => {
    //fixa il problema della renderizzazione della pagina posizionata in base allo scroll precedente.
    useEffect(() => {
        document.getElementsByClassName("Root-content ant-layout-content")[0].scrollTo(0, 0);
    }, []);

    const handleActiveService = service => {
        switch (service) {
            case "TEL":
                activateService(company.base.id, "A-FISCALI-IVA-FLOW", {
                    sendIva: "true"
                });
                break;
            case "DBX":
                activateService(company.base.id, "DIGITALBOX");
                break;
            case "ESM":
                activateService(company.base.id, "HBLE-ESM", { RTL: "false", TEM: "false" });
                break;
            case "EIT":
                activateService(company.base.id, "EIT");
                break;
            case "SCF":
                activateService(company.base.id, "DEEP-TIER");
                break;
            default:
                return;
        }
    };

    useEffect(() => {
        if (activateServicesStatus.ended) {
            history.push(`/${match.params.item}/dashboard`);
        }

        return () => activateServiceReset();
    }, [activateServiceReset, activateServicesStatus.ended, history, match.params.item]);

    const details = appDetails[match.params.detail];
    const serviceInfo = servicesInfo && servicesInfo.filter(ele => details.name === ele.id);
    const earlyAccessEnabled = serviceInfo && serviceInfo[0] && serviceInfo[0].earlyAccess;

    const waitlistedapp = waitinglist && waitinglist.filter(ele => ele.serviceId === details.name);

    const waitinglisted = waitlistedapp && waitlistedapp.map(ele => ele.status === "PENDING")[0];

    let created_at = "";
    let created_by = "";
    if (waitinglisted) {
        created_at = waitlistedapp[0].created_at;
        created_by = waitlistedapp[0].created_by;
    }

    const contactLink =
        details && details.name === "ESM"
            ? `https://digital.teamsystem.com/ts-cost-management`
            : `https://digital.teamsystem.com/lp-richiedi-info-ts-digital?partita_iva_forms=${match.params.item}&prodotti_ts_digital=${details.contactName}`;

    let disclaimerText = null;
    if (details.disclaimer) {
        if (
            details.name !== "EIT" ||
            (details.name === "EIT" &&
                !(activeServices.includes("SDI-FLOW") || activeServices.includes("B2B-PASSIVE-FLOW")))
        ) {
            disclaimerText = details.disclaimer;
        }
    }

    return details ? (
        <>
            <InfoContainer>
                <Button kind="secondary" onClick={() => history.goBack()}>
                    <FormattedMessage id="general.back" />
                </Button>
                <Info>
                    {(details.video || details.img) && (
                        <MediaContainer>
                            {details.video && (
                                <video
                                    height="100%"
                                    width="100%"
                                    src={details.video}
                                    autoPlay={details.videoConfig.autoPlay}
                                    loop={details.videoConfig.loop}
                                    muted={details.videoConfig.muted}
                                    controls={details.videoConfig.controls}
                                />
                            )}
                            {details.img && (
                                <img
                                    src={details.img}
                                    style={{
                                        width: "100%"
                                    }}
                                    alt={details.name}
                                />
                            )}
                        </MediaContainer>
                    )}
                    <Title fullWidth={!details.video && !details.img}>
                        <LogoContainer>
                            <Logo
                                src={`${TS_DIGITAL_IMAGES}apps-logo/LOGO_Digital_${details.name}.png`}
                                alt={details.name}
                            />
                        </LogoContainer>
                        <p>
                            <FormattedHTMLMessage id={details.subtitle} />
                        </p>
                    </Title>
                </Info>
                <ButtonsContainer>
                    {!details.disableContactUs && (
                        <a href={contactLink} target="_blank" rel="noopener noreferrer">
                            <Button
                                kind="secondary"
                                onClick={() => {
                                    if (details.name === "ESM") {
                                        esmContactUs();
                                    }
                                }}
                            >
                                <FormattedMessage id="general.contact-us" />
                            </Button>
                        </a>
                    )}
                    {["TEL", "ESM", "SCF"].includes(details.name) && (
                        <Button
                            kind="primary"
                            onClick={() => {
                                if (details.name === "ESM") {
                                    esmActivate();
                                }
                                handleActiveService(details.name);
                            }}
                            className="activate-button"
                        >
                            <FormattedMessage
                                id={["ESM", "SCF"].includes(details.name) ? "general.active-free" : "general.active"}
                            />
                        </Button>
                    )}
                    {details.name === "EIT" && (
                        <>
                            <Button
                                kind="primary"
                                onClick={() => {
                                    handleActiveService(details.name);
                                }}
                                className="activate-button"
                                disabled={
                                    !activeServices.includes("SDI-FLOW") && !activeServices.includes("B2B-PASSIVE-FLOW")
                                }
                            >
                                <FormattedMessage id={"general.active-free"} />
                            </Button>
                        </>
                    )}
                    {details.name === "ARC" && (
                        <Link to={`/${match.params.item}/services/storage/edit-cct`}>
                            <Button kind="tertiary" className="access-key-owned-button">
                                <FormattedMessage id="general.access-key-owned" />
                            </Button>
                        </Link>
                    )}
                    {details.name === "CYBER" && (
                        <a
                            href={`https://mrk.teamsystem.com/ts-cybersecurity-contatto-sw`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Button kind="secondary">
                                <FormattedMessage id="general.contact-us" />
                            </Button>
                        </a>
                    )}
                    {details.buyLink && (
                        <a href={details.buyLink} target="_blank" rel="noopener noreferrer">
                            <Button className="buy-button">
                                <FormattedMessage id="general.buy-package" />
                                <FontawesomeIcon color="#FFF" name="cart-plus" solid={true} />
                            </Button>
                        </a>
                    )}
                    {earlyAccessEnabled && (
                        <WaitingListButton
                            companyId={company.base.id}
                            getWaitingList={getWaitingList}
                            getWaitingListStatus={getWaitingListStatus}
                            waitingListed={waitinglisted}
                            waitingListedCreatedBy={created_by}
                            waitingListedCreatedAt={created_at}
                            subscribeWaitinglist={subscribeWaitinglist}
                            waitinglistSubscribeStatus={waitinglistSubscribeStatus}
                            appName={details.name}
                            deleteWaitinglist={deleteWaitinglist}
                            waitinglistDeleteStatus={waitinglistDeleteStatus}
                            user={user}
                        />
                    )}
                </ButtonsContainer>
                {disclaimerText && (
                    <Disclaimer>
                        <DisclaimerIcon
                            iconColor={details.disclaimerIconColor ? details.disclaimerIconColor : "#fdb927"}
                            icon={details.disclaimerIcon ? details.disclaimerIcon : faExclamationCircle}
                        />{" "}
                        <FormattedHTMLMessage id={disclaimerText} />
                    </Disclaimer>
                )}
            </InfoContainer>
            <BoxContainer>
                {details.boxes.map((box, key) => (
                    <div key={key}>
                        <BoxTitle>
                            <FormattedHTMLMessage id={box.title} />
                        </BoxTitle>
                        <BoxDescription>
                            <FormattedHTMLMessage id={box.desc} />
                        </BoxDescription>
                    </div>
                ))}
            </BoxContainer>
        </>
    ) : (
        <Redirect to={`/${match.params.item}/dashboard`} />
    );
};

Detail.propTypes = {
    activateService: PropTypes.func,
    activateServicesStatus: PropTypes.object,
    company: PropTypes.object.isRequired,
    getWaitingList: PropTypes.func,
    history: PropTypes.object,
    match: PropTypes.object,
    servicesInfo: PropTypes.array,
    subscribeWaitinglist: PropTypes.func,
    waitinglist: PropTypes.array,
    waitinglistDeleteStatus: PropTypes.object,
    waitinglistSubscribeStatus: PropTypes.object
};

const mapStateToProps = (state, props) => ({
    activeServices: state.services.active.services,
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    activateServicesStatus: state.services.servicesSubscription.activated.status,
    servicesInfo: state.services.agyoServices.services,
    waitinglist: state.waitinglist.read.getWaitingList.waitinglist,
    waitinglistSubscribeStatus: state.waitinglist.write.subscribeWaitinglist.status,
    waitinglistDeleteStatus: state.waitinglist.write.deleteWaitinglist.status,
    getWaitingListStatus: state.waitinglist.read.getWaitingList.status,
    user: state.user
});

const actions = {
    activateService,
    activateServiceReset,
    esmContactUs,
    esmActivate,
    getWaitingList,
    subscribeWaitinglist,
    deleteWaitinglist
};

const composedHoc = compose(connect(mapStateToProps, actions), errorBoundary);

export default composedHoc(Detail);
