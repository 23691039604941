import { REGISTRY_WRITE_REST, REGISTRY_READ_REST } from "../config";
import { getAuthWithAppName } from "./auth.js";
import { postRestCall, putRestCall, getRestCall } from "../utils/rest-api-call";

import { getUserProfile } from "../utils/state-utils/get-user-profile";

export const GET_COMPANIES_START = "GET_COMPANIES_START";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_ERROR = "GET_COMPANIES_ERROR";
export const GET_COMPANIES_RESET = "GET_COMPANIES_RESET";
export const GET_COMPANIES_CHANGE_PAGE = "GET_COMPANIES_CHANGE_PAGE";

export const GET_COMPANIES_START_ADDROLESELECTION = "GET_COMPANIES_START_ADDROLESELECTION";
export const GET_COMPANIES_SUCCESS_ADDROLESELECTION = "GET_COMPANIES_SUCCESS_ADDROLESELECTION";
export const GET_COMPANIES_ERROR_ADDROLESELECTION = "GET_COMPANIES_ERROR_ADDROLESELECTION";

export const STORE_PAGE = "STORE_PAGE";

export function getCompanies(keyWord, pageNumber, itemsPerPage, roleSelection, idList) {
    const startAction = roleSelection ? GET_COMPANIES_START_ADDROLESELECTION : GET_COMPANIES_START;

    const errorAction = roleSelection ? GET_COMPANIES_ERROR_ADDROLESELECTION : GET_COMPANIES_ERROR;

    const successAction = roleSelection ? GET_COMPANIES_SUCCESS_ADDROLESELECTION : GET_COMPANIES_SUCCESS;

    return (dispatch, getState) => {
        const auth = getAuthWithAppName(getState().auth);

        getCompaniesCall(
            auth,
            keyWord,
            ["COMPANY", "STUDIO", "BUILDING", "ASSOCIATION", "PERSON"],
            pageNumber,
            itemsPerPage,
            dispatch,
            getState,
            startAction,
            successAction,
            errorAction,
            idList
        );
    };
}

export function changePage(page) {
    return dispatch => {
        dispatch({
            type: GET_COMPANIES_CHANGE_PAGE,
            payload: page
        });
    };
}

export function resetCompaniesFiltered() {
    return dispatch => {
        dispatch({
            type: GET_COMPANIES_RESET
        });
    };
}

export function getMyItems(res, userRoles) {
    const roles = userRoles.map(u => u.resourceId);

    return res.items
        .filter(v => {
            return roles.includes(v.item.base.id);
        })
        .reduce((accumulator, company) => {
            accumulator[company.item.base.id] = company;
            return accumulator;
        }, {});
}

export const GET_STUDIO_COMPANIES_START = "GET_STUDIO_COMPANIES_START";
export const GET_STUDIO_COMPANIES_SUCCESS = "GET_STUDIO_COMPANIES_SUCCESS";
export const GET_STUDIO_COMPANIES_ERROR = "GET_STUDIO_COMPANIES_ERROR";

export function getStudioCompanies(keyWord, idList) {
    return (dispatch, getState) => {
        const auth = getAuthWithAppName(getState().auth);
        getCompaniesCall(
            auth,
            keyWord,
            ["STUDIO"],
            0,
            10000,
            dispatch,
            getState,
            GET_STUDIO_COMPANIES_START,
            GET_STUDIO_COMPANIES_SUCCESS,
            GET_STUDIO_COMPANIES_ERROR,
            idList
        );
    };
}

export const GET_ALL_COMPANIES_ADDROLESELECTION_START = "GET_ALL_COMPANIES_ADDROLESELECTION_START";
export const GET_ALL_COMPANIES_ADDROLESELECTION_SUCCESS = "GET_ALL_COMPANIES_ADDROLESELECTION_SUCCESS";
export const GET_ALL_COMPANIES_ADDROLESELECTION_ERROR = "GET_ALL_COMPANIES_ADDROLESELECTION_ERROR";

export function getAllCompanies(keyWord, idList) {
    return (dispatch, getState) => {
        const auth = getAuthWithAppName(getState().auth);
        getCompaniesCall(
            auth,
            keyWord,
            ["COMPANY", "STUDIO", "BUILDING", "ASSOCIATION", "PERSON"],
            0,
            10000,
            dispatch,
            getState,
            GET_ALL_COMPANIES_ADDROLESELECTION_START,
            GET_ALL_COMPANIES_ADDROLESELECTION_SUCCESS,
            GET_ALL_COMPANIES_ADDROLESELECTION_ERROR,
            idList
        );
    };
}

async function getCompaniesCall(
    auth,
    keyWord,
    classifiers,
    pageNumber,
    itemsPerPage,
    dispatch,
    getState,
    startAction,
    successAction,
    errorAction,
    idList
) {
    dispatch({
        type: startAction
    });
    const fullText = {
        classifiers: classifiers
    };

    if (keyWord) {
        fullText.keyWord = keyWord;
    }

    const pagination = {
        pageNumber: pageNumber,
        itemsPerPage: itemsPerPage
    };

    const packageType = "BASE";

    const request = {
        fullText,
        packageType,
        idList,
        pagination,
        userId: !idList ? auth.id : undefined
    };

    try {
        const res = await getRestCall(
            `${REGISTRY_READ_REST}/items`,
            auth,
            request,
            dispatch,
            auth.refreshToken,
            getUserProfile(getState())
        );

        dispatch({
            type: successAction,
            payload: {
                ...res,
                items: res.items.reduce((accumulator, company) => {
                    accumulator[company.item.base.id] = company;
                    return accumulator;
                }, {}),
                page: pageNumber,
                size: itemsPerPage
            }
        });
    } catch (e) {
        dispatch({
            type: errorAction,
            error: e
        });
    }
}

export const CREATE_ITEM_START = "CREATE_ITEM_START";
export const CREATE_ITEM_SUCCESS = "CREATE_ITEM_SUCCESS";
export const CREATE_ITEM_ERROR = "CREATE_ITEM_ERROR";

export function createItemRest(itemValue, studioId = undefined, noKeys = true) {
    return async (dispatch, getState) => {
        dispatch({
            type: CREATE_ITEM_START
        });

        const request = { item: itemValue, noKeys, studioId };

        try {
            let authObj = getAuthWithAppName(getState().auth);
            const result = await postRestCall(
                `${REGISTRY_WRITE_REST}/items`,
                authObj,
                {},
                request,
                dispatch,
                authObj.refreshToken,
                getUserProfile(getState()),
                itemValue,
                true
            );

            dispatch({
                type: CREATE_ITEM_SUCCESS,
                payload: {
                    item: {
                        ...request.item,
                        base: {
                            ...request.item.base,
                            id: result.id,
                            status: {
                                active: true,
                                deleted: false,
                                status: "UNVERIFIABLE"
                            }
                        }
                    }
                }
            });
        } catch (e) {
            dispatch({
                type: CREATE_ITEM_ERROR,
                error: e
            });
        }
    };
}

export const CREATE_ITEM_RESET = "CREATE_ITEM_RESET";
export function resetCreateItem() {
    return dispatch => {
        dispatch({
            type: CREATE_ITEM_RESET
        });
    };
}

export const UPDATE_ITEM_START = "UPDATE_ITEM_START";
export const UPDATE_ITEM_SUCCESS = "UPDATE_ITEM_SUCCESS";
export const UPDATE_ITEM_ERROR = "UPDATE_ITEM_ERROR";
export const UPDATE_ITEM_RESET = "UPDATE_ITEM_RESET";

export function updateItem(itemValue) {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_ITEM_START
        });

        const identifier = { ...itemValue.base.identifier };

        let details = { ...itemValue.base.details };

        const base = {
            ...itemValue.base,
            identifier,
            details
        };

        const preferences = {
            ...itemValue.preferences,
            enableConsole: itemValue.preferences.enableConsole
        };

        const item = {
            ...itemValue,
            base,
            preferences
        };

        let authObj = getAuthWithAppName(getState().auth);
        authObj.accessToken = authObj.securityToken;

        try {
            await putRestCall(
                `${REGISTRY_WRITE_REST}/items/${item.base.id}`,
                authObj,
                null,
                item,
                dispatch,
                authObj.refreshToken,
                getUserProfile(getState()),
                itemValue.base.id
            );

            dispatch({
                type: UPDATE_ITEM_SUCCESS,
                payload: {
                    item: {
                        ...item,
                        base: {
                            ...item.base,
                            details: { ...item.base.details, legalForm: { code: item.base.details.legalForm } }
                        }
                    }
                }
            });
        } catch (e) {
            dispatch({
                type: UPDATE_ITEM_ERROR,
                error: e
            });
        }
    };
}

export function resetUpdateItem() {
    return dispatch => {
        dispatch({
            type: UPDATE_ITEM_RESET
        });
    };
}

export function storePage(pageSize = 0, currentPage = 0, searchText = "") {
    return dispatch => {
        dispatch({
            type: STORE_PAGE,
            payload: {
                pageSize: pageSize,
                currentPage: currentPage,
                searchText: searchText
            }
        });
    };
}
