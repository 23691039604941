import { Button } from "@ts-digital/vrc";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const AddPermissionsButton = styled(Button)`
    align-self: flex-end;
`;

export const AddPermissionsLink = styled(Link)`
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 24px;
    margin-top: 32px;
`;
