import React from "react";
import PropTypes from "prop-types";
import "./style.css";
import { Spin } from "antd";
import { isNil } from "ramda";
import { FormattedHTMLMessage, useIntl } from "react-intl";

const CbCr = ({ cbCr }) => {
    const { formatMessage } = useIntl();

    return cbCr.status.ended ? (
        <div className="Cbcr-info">
            <b>
                <FormattedHTMLMessage id="general.year2k17" />
            </b>
            <br />
            {formatMessage({
                id: "c-fulfilments.comunication-bought"
            }) +
                (!isNil(parseInt(cbCr.config.limit.usedSlots, 10) + parseInt(cbCr.config.limit.freeSlots, 10))
                    ? (parseInt(cbCr.config.limit.usedSlots, 10) + parseInt(cbCr.config.limit.freeSlots, 10)).toString()
                    : 0)}
            <br />
            {formatMessage({
                id: "c-fulfilments.comunication-generated"
            }) + (!isNil(cbCr.config.limit.usedSlots) ? cbCr.config.limit.usedSlots.toString() : 0)}
            <br />
            {formatMessage({
                id: "c-fulfilments.comunication-available"
            }) + (!isNil(cbCr.config.limit.freeSlots) ? cbCr.config.limit.freeSlots.toString() : 0)}
        </div>
    ) : (
        <div className="Cbcr-Spinner">
            <Spin size="large" />
        </div>
    );
};

CbCr.propTypes = {
    cbCr: PropTypes.object.isRequired
};

export default CbCr;
