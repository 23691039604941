import { combineReducers } from "redux";

import {
    FETCH_INVOICING_CONFIG_START,
    FETCH_INVOICING_CONFIG_SUCCESS,
    FETCH_INVOICING_CONFIG_ERROR
} from "../../actions/services/invoicing";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false
    }
};

export function getInvoicingConfig(state = defaultState, { type, payload, error }) {
    switch (type) {
        case FETCH_INVOICING_CONFIG_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case FETCH_INVOICING_CONFIG_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case FETCH_INVOICING_CONFIG_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}

export default combineReducers({
    getInvoicingConfig
});
