/* eslint-disable */
//
// Autogenerated by Thrift Compiler (0.9.3)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
var thrift = require('browser-thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;

var apiCommon_ttypes = require('./apiCommon_types')
var apiAuth_ttypes = require('./apiAuth_types')


var ttypes = require('./apiRead_types');
//HELPER FUNCTIONS AND STRUCTURES

SigninApiRead_health_args = function(args) {
};
SigninApiRead_health_args.prototype = {};
SigninApiRead_health_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiRead_health_args.prototype.write = function(output) {
  output.writeStructBegin('SigninApiRead_health_args');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiRead_health_result = function(args) {
  this.success = null;
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
  }
};
SigninApiRead_health_result.prototype = {};
SigninApiRead_health_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRING) {
        this.success = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiRead_health_result.prototype.write = function(output) {
  output.writeStructBegin('SigninApiRead_health_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRING, 0);
    output.writeString(this.success);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiRead_downloadFilledDocument_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new apiAuth_ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.DownloadFilledDocumentRequest(args.request);
    }
  }
};
SigninApiRead_downloadFilledDocument_args.prototype = {};
SigninApiRead_downloadFilledDocument_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new apiAuth_ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.DownloadFilledDocumentRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiRead_downloadFilledDocument_args.prototype.write = function(output) {
  output.writeStructBegin('SigninApiRead_downloadFilledDocument_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiRead_downloadFilledDocument_result = function(args) {
  this.success = null;
  this.validationException = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof apiCommon_ttypes.ValidationException) {
    this.validationException = args;
    return;
  }
  if (args instanceof apiCommon_ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof apiAuth_ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.DownloadFilledDocumentResponse(args.success);
    }
    if (args.validationException !== undefined && args.validationException !== null) {
      this.validationException = args.validationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
SigninApiRead_downloadFilledDocument_result.prototype = {};
SigninApiRead_downloadFilledDocument_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.DownloadFilledDocumentResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.validationException = new apiCommon_ttypes.ValidationException();
        this.validationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new apiCommon_ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new apiAuth_ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiRead_downloadFilledDocument_result.prototype.write = function(output) {
  output.writeStructBegin('SigninApiRead_downloadFilledDocument_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.validationException !== null && this.validationException !== undefined) {
    output.writeFieldBegin('validationException', Thrift.Type.STRUCT, 1);
    this.validationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 2);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 3);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiRead_downloadSignedDocument_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new apiAuth_ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.DownloadSignedDocumentRequest(args.request);
    }
  }
};
SigninApiRead_downloadSignedDocument_args.prototype = {};
SigninApiRead_downloadSignedDocument_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new apiAuth_ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.DownloadSignedDocumentRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiRead_downloadSignedDocument_args.prototype.write = function(output) {
  output.writeStructBegin('SigninApiRead_downloadSignedDocument_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiRead_downloadSignedDocument_result = function(args) {
  this.success = null;
  this.validationException = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof apiCommon_ttypes.ValidationException) {
    this.validationException = args;
    return;
  }
  if (args instanceof apiCommon_ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof apiAuth_ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.DownloadSignedDocumentResponse(args.success);
    }
    if (args.validationException !== undefined && args.validationException !== null) {
      this.validationException = args.validationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
SigninApiRead_downloadSignedDocument_result.prototype = {};
SigninApiRead_downloadSignedDocument_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.DownloadSignedDocumentResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.validationException = new apiCommon_ttypes.ValidationException();
        this.validationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new apiCommon_ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new apiAuth_ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiRead_downloadSignedDocument_result.prototype.write = function(output) {
  output.writeStructBegin('SigninApiRead_downloadSignedDocument_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.validationException !== null && this.validationException !== undefined) {
    output.writeFieldBegin('validationException', Thrift.Type.STRUCT, 1);
    this.validationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 2);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 3);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiRead_getProvisioningStatus_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new apiAuth_ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.ProvisioningStatusRequest(args.request);
    }
  }
};
SigninApiRead_getProvisioningStatus_args.prototype = {};
SigninApiRead_getProvisioningStatus_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new apiAuth_ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.ProvisioningStatusRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiRead_getProvisioningStatus_args.prototype.write = function(output) {
  output.writeStructBegin('SigninApiRead_getProvisioningStatus_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiRead_getProvisioningStatus_result = function(args) {
  this.success = null;
  this.validationException = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof apiCommon_ttypes.ValidationException) {
    this.validationException = args;
    return;
  }
  if (args instanceof apiCommon_ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof apiAuth_ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.ProvisioningStatusResponse(args.success);
    }
    if (args.validationException !== undefined && args.validationException !== null) {
      this.validationException = args.validationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
SigninApiRead_getProvisioningStatus_result.prototype = {};
SigninApiRead_getProvisioningStatus_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.ProvisioningStatusResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.validationException = new apiCommon_ttypes.ValidationException();
        this.validationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new apiCommon_ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new apiAuth_ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiRead_getProvisioningStatus_result.prototype.write = function(output) {
  output.writeStructBegin('SigninApiRead_getProvisioningStatus_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.validationException !== null && this.validationException !== undefined) {
    output.writeFieldBegin('validationException', Thrift.Type.STRUCT, 1);
    this.validationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 2);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 3);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiRead_getSignerData_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new apiAuth_ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.SignerDataRequest(args.request);
    }
  }
};
SigninApiRead_getSignerData_args.prototype = {};
SigninApiRead_getSignerData_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new apiAuth_ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.SignerDataRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiRead_getSignerData_args.prototype.write = function(output) {
  output.writeStructBegin('SigninApiRead_getSignerData_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiRead_getSignerData_result = function(args) {
  this.success = null;
  this.validationException = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof apiCommon_ttypes.ValidationException) {
    this.validationException = args;
    return;
  }
  if (args instanceof apiCommon_ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof apiAuth_ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.SignerDataResponse(args.success);
    }
    if (args.validationException !== undefined && args.validationException !== null) {
      this.validationException = args.validationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
SigninApiRead_getSignerData_result.prototype = {};
SigninApiRead_getSignerData_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.SignerDataResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.validationException = new apiCommon_ttypes.ValidationException();
        this.validationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new apiCommon_ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new apiAuth_ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiRead_getSignerData_result.prototype.write = function(output) {
  output.writeStructBegin('SigninApiRead_getSignerData_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.validationException !== null && this.validationException !== undefined) {
    output.writeFieldBegin('validationException', Thrift.Type.STRUCT, 1);
    this.validationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 2);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 3);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiRead_listSigners_args = function(args) {
  this.auth = null;
  this.request = null;
  if (args) {
    if (args.auth !== undefined && args.auth !== null) {
      this.auth = new apiAuth_ttypes.Auth(args.auth);
    }
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.ListSignerRequest(args.request);
    }
  }
};
SigninApiRead_listSigners_args.prototype = {};
SigninApiRead_listSigners_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.auth = new apiAuth_ttypes.Auth();
        this.auth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.ListSignerRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiRead_listSigners_args.prototype.write = function(output) {
  output.writeStructBegin('SigninApiRead_listSigners_args');
  if (this.auth !== null && this.auth !== undefined) {
    output.writeFieldBegin('auth', Thrift.Type.STRUCT, 1);
    this.auth.write(output);
    output.writeFieldEnd();
  }
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 2);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiRead_listSigners_result = function(args) {
  this.success = null;
  this.validationException = null;
  this.genericError = null;
  this.authenticationException = null;
  if (args instanceof apiCommon_ttypes.ValidationException) {
    this.validationException = args;
    return;
  }
  if (args instanceof apiCommon_ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args instanceof apiAuth_ttypes.AuthenticationException) {
    this.authenticationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.ListSignerResponse(args.success);
    }
    if (args.validationException !== undefined && args.validationException !== null) {
      this.validationException = args.validationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
    if (args.authenticationException !== undefined && args.authenticationException !== null) {
      this.authenticationException = args.authenticationException;
    }
  }
};
SigninApiRead_listSigners_result.prototype = {};
SigninApiRead_listSigners_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.ListSignerResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.validationException = new apiCommon_ttypes.ValidationException();
        this.validationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new apiCommon_ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticationException = new apiAuth_ttypes.AuthenticationException();
        this.authenticationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiRead_listSigners_result.prototype.write = function(output) {
  output.writeStructBegin('SigninApiRead_listSigners_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.validationException !== null && this.validationException !== undefined) {
    output.writeFieldBegin('validationException', Thrift.Type.STRUCT, 1);
    this.validationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 2);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  if (this.authenticationException !== null && this.authenticationException !== undefined) {
    output.writeFieldBegin('authenticationException', Thrift.Type.STRUCT, 3);
    this.authenticationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiRead_signerExist_args = function(args) {
  this.cfUtente = null;
  if (args) {
    if (args.cfUtente !== undefined && args.cfUtente !== null) {
      this.cfUtente = new apiCommon_ttypes.SignerRef(args.cfUtente);
    }
  }
};
SigninApiRead_signerExist_args.prototype = {};
SigninApiRead_signerExist_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.cfUtente = new apiCommon_ttypes.SignerRef();
        this.cfUtente.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiRead_signerExist_args.prototype.write = function(output) {
  output.writeStructBegin('SigninApiRead_signerExist_args');
  if (this.cfUtente !== null && this.cfUtente !== undefined) {
    output.writeFieldBegin('cfUtente', Thrift.Type.STRUCT, 1);
    this.cfUtente.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiRead_signerExist_result = function(args) {
  this.success = null;
  this.validationException = null;
  this.genericError = null;
  if (args instanceof apiCommon_ttypes.ValidationException) {
    this.validationException = args;
    return;
  }
  if (args instanceof apiCommon_ttypes.GenericError) {
    this.genericError = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
    if (args.validationException !== undefined && args.validationException !== null) {
      this.validationException = args.validationException;
    }
    if (args.genericError !== undefined && args.genericError !== null) {
      this.genericError = args.genericError;
    }
  }
};
SigninApiRead_signerExist_result.prototype = {};
SigninApiRead_signerExist_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.BOOL) {
        this.success = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.validationException = new apiCommon_ttypes.ValidationException();
        this.validationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.genericError = new apiCommon_ttypes.GenericError();
        this.genericError.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SigninApiRead_signerExist_result.prototype.write = function(output) {
  output.writeStructBegin('SigninApiRead_signerExist_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.BOOL, 0);
    output.writeBool(this.success);
    output.writeFieldEnd();
  }
  if (this.validationException !== null && this.validationException !== undefined) {
    output.writeFieldBegin('validationException', Thrift.Type.STRUCT, 1);
    this.validationException.write(output);
    output.writeFieldEnd();
  }
  if (this.genericError !== null && this.genericError !== undefined) {
    output.writeFieldBegin('genericError', Thrift.Type.STRUCT, 2);
    this.genericError.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SigninApiReadClient = exports.Client = function(output, pClass) {
    this.output = output;
    this.pClass = pClass;
    this._seqid = 0;
    this._reqs = {};
};
SigninApiReadClient.prototype = {};
SigninApiReadClient.prototype.seqid = function() { return this._seqid; }
SigninApiReadClient.prototype.new_seqid = function() { return this._seqid += 1; }
SigninApiReadClient.prototype.health = function(callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_health();
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_health();
  }
};

SigninApiReadClient.prototype.send_health = function() {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('health', Thrift.MessageType.CALL, this.seqid());
  var args = new SigninApiRead_health_args();
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

SigninApiReadClient.prototype.recv_health = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new SigninApiRead_health_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('health failed: unknown result');
};
SigninApiReadClient.prototype.downloadFilledDocument = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_downloadFilledDocument(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_downloadFilledDocument(auth, request);
  }
};

SigninApiReadClient.prototype.send_downloadFilledDocument = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('downloadFilledDocument', Thrift.MessageType.CALL, this.seqid());
  var args = new SigninApiRead_downloadFilledDocument_args();
  args.auth = auth;
  args.request = request;
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

SigninApiReadClient.prototype.recv_downloadFilledDocument = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new SigninApiRead_downloadFilledDocument_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.validationException) {
    return callback(result.validationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('downloadFilledDocument failed: unknown result');
};
SigninApiReadClient.prototype.downloadSignedDocument = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_downloadSignedDocument(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_downloadSignedDocument(auth, request);
  }
};

SigninApiReadClient.prototype.send_downloadSignedDocument = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('downloadSignedDocument', Thrift.MessageType.CALL, this.seqid());
  var args = new SigninApiRead_downloadSignedDocument_args();
  args.auth = auth;
  args.request = request;
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

SigninApiReadClient.prototype.recv_downloadSignedDocument = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new SigninApiRead_downloadSignedDocument_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.validationException) {
    return callback(result.validationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('downloadSignedDocument failed: unknown result');
};
SigninApiReadClient.prototype.getProvisioningStatus = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_getProvisioningStatus(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_getProvisioningStatus(auth, request);
  }
};

SigninApiReadClient.prototype.send_getProvisioningStatus = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('getProvisioningStatus', Thrift.MessageType.CALL, this.seqid());
  var args = new SigninApiRead_getProvisioningStatus_args();
  args.auth = auth;
  args.request = request;
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

SigninApiReadClient.prototype.recv_getProvisioningStatus = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new SigninApiRead_getProvisioningStatus_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.validationException) {
    return callback(result.validationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('getProvisioningStatus failed: unknown result');
};
SigninApiReadClient.prototype.getSignerData = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_getSignerData(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_getSignerData(auth, request);
  }
};

SigninApiReadClient.prototype.send_getSignerData = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('getSignerData', Thrift.MessageType.CALL, this.seqid());
  var args = new SigninApiRead_getSignerData_args();
  args.auth = auth;
  args.request = request;
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

SigninApiReadClient.prototype.recv_getSignerData = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new SigninApiRead_getSignerData_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.validationException) {
    return callback(result.validationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('getSignerData failed: unknown result');
};
SigninApiReadClient.prototype.listSigners = function(auth, request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_listSigners(auth, request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_listSigners(auth, request);
  }
};

SigninApiReadClient.prototype.send_listSigners = function(auth, request) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('listSigners', Thrift.MessageType.CALL, this.seqid());
  var args = new SigninApiRead_listSigners_args();
  args.auth = auth;
  args.request = request;
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

SigninApiReadClient.prototype.recv_listSigners = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new SigninApiRead_listSigners_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.validationException) {
    return callback(result.validationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.authenticationException) {
    return callback(result.authenticationException);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('listSigners failed: unknown result');
};
SigninApiReadClient.prototype.signerExist = function(cfUtente, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_signerExist(cfUtente);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_signerExist(cfUtente);
  }
};

SigninApiReadClient.prototype.send_signerExist = function(cfUtente) {
  var output = new this.pClass(this.output);
  output.writeMessageBegin('signerExist', Thrift.MessageType.CALL, this.seqid());
  var args = new SigninApiRead_signerExist_args();
  args.cfUtente = cfUtente;
  args.write(output);
  output.writeMessageEnd();
  return this.output.flush();
};

SigninApiReadClient.prototype.recv_signerExist = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new SigninApiRead_signerExist_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.validationException) {
    return callback(result.validationException);
  }
  if (null !== result.genericError) {
    return callback(result.genericError);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('signerExist failed: unknown result');
};
SigninApiReadProcessor = exports.Processor = function(handler) {
  this._handler = handler
}
SigninApiReadProcessor.prototype.process = function(input, output) {
  var r = input.readMessageBegin();
  if (this['process_' + r.fname]) {
    return this['process_' + r.fname].call(this, r.rseqid, input, output);
  } else {
    input.skip(Thrift.Type.STRUCT);
    input.readMessageEnd();
    var x = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN_METHOD, 'Unknown function ' + r.fname);
    output.writeMessageBegin(r.fname, Thrift.MessageType.EXCEPTION, r.rseqid);
    x.write(output);
    output.writeMessageEnd();
    output.flush();
  }
}

SigninApiReadProcessor.prototype.process_health = function(seqid, input, output) {
  var args = new SigninApiRead_health_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.health.length === 0) {
    Q.fcall(this._handler.health)
      .then(function(result) {
        var result = new SigninApiRead_health_result({success: result});
        output.writeMessageBegin("health", Thrift.MessageType.REPLY, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("health", Thrift.MessageType.EXCEPTION, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.health(function (err, result) {
      if (err == null) {
        var result = new SigninApiRead_health_result((err != null ? err : {success: result}));
        output.writeMessageBegin("health", Thrift.MessageType.REPLY, seqid);
      } else {
        var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("health", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
}

SigninApiReadProcessor.prototype.process_downloadFilledDocument = function(seqid, input, output) {
  var args = new SigninApiRead_downloadFilledDocument_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.downloadFilledDocument.length === 2) {
    Q.fcall(this._handler.downloadFilledDocument, args.auth, args.request)
      .then(function(result) {
        var result = new SigninApiRead_downloadFilledDocument_result({success: result});
        output.writeMessageBegin("downloadFilledDocument", Thrift.MessageType.REPLY, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        if (err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
          var result = new SigninApiRead_downloadFilledDocument_result(err);
          output.writeMessageBegin("downloadFilledDocument", Thrift.MessageType.REPLY, seqid);
        } else {
          var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("downloadFilledDocument", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.downloadFilledDocument(args.auth, args.request, function (err, result) {
      if (err == null || err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
        var result = new SigninApiRead_downloadFilledDocument_result((err != null ? err : {success: result}));
        output.writeMessageBegin("downloadFilledDocument", Thrift.MessageType.REPLY, seqid);
      } else {
        var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("downloadFilledDocument", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
}

SigninApiReadProcessor.prototype.process_downloadSignedDocument = function(seqid, input, output) {
  var args = new SigninApiRead_downloadSignedDocument_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.downloadSignedDocument.length === 2) {
    Q.fcall(this._handler.downloadSignedDocument, args.auth, args.request)
      .then(function(result) {
        var result = new SigninApiRead_downloadSignedDocument_result({success: result});
        output.writeMessageBegin("downloadSignedDocument", Thrift.MessageType.REPLY, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        if (err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
          var result = new SigninApiRead_downloadSignedDocument_result(err);
          output.writeMessageBegin("downloadSignedDocument", Thrift.MessageType.REPLY, seqid);
        } else {
          var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("downloadSignedDocument", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.downloadSignedDocument(args.auth, args.request, function (err, result) {
      if (err == null || err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
        var result = new SigninApiRead_downloadSignedDocument_result((err != null ? err : {success: result}));
        output.writeMessageBegin("downloadSignedDocument", Thrift.MessageType.REPLY, seqid);
      } else {
        var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("downloadSignedDocument", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
}

SigninApiReadProcessor.prototype.process_getProvisioningStatus = function(seqid, input, output) {
  var args = new SigninApiRead_getProvisioningStatus_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.getProvisioningStatus.length === 2) {
    Q.fcall(this._handler.getProvisioningStatus, args.auth, args.request)
      .then(function(result) {
        var result = new SigninApiRead_getProvisioningStatus_result({success: result});
        output.writeMessageBegin("getProvisioningStatus", Thrift.MessageType.REPLY, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        if (err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
          var result = new SigninApiRead_getProvisioningStatus_result(err);
          output.writeMessageBegin("getProvisioningStatus", Thrift.MessageType.REPLY, seqid);
        } else {
          var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("getProvisioningStatus", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.getProvisioningStatus(args.auth, args.request, function (err, result) {
      if (err == null || err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
        var result = new SigninApiRead_getProvisioningStatus_result((err != null ? err : {success: result}));
        output.writeMessageBegin("getProvisioningStatus", Thrift.MessageType.REPLY, seqid);
      } else {
        var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("getProvisioningStatus", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
}

SigninApiReadProcessor.prototype.process_getSignerData = function(seqid, input, output) {
  var args = new SigninApiRead_getSignerData_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.getSignerData.length === 2) {
    Q.fcall(this._handler.getSignerData, args.auth, args.request)
      .then(function(result) {
        var result = new SigninApiRead_getSignerData_result({success: result});
        output.writeMessageBegin("getSignerData", Thrift.MessageType.REPLY, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        if (err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
          var result = new SigninApiRead_getSignerData_result(err);
          output.writeMessageBegin("getSignerData", Thrift.MessageType.REPLY, seqid);
        } else {
          var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("getSignerData", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.getSignerData(args.auth, args.request, function (err, result) {
      if (err == null || err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
        var result = new SigninApiRead_getSignerData_result((err != null ? err : {success: result}));
        output.writeMessageBegin("getSignerData", Thrift.MessageType.REPLY, seqid);
      } else {
        var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("getSignerData", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
}

SigninApiReadProcessor.prototype.process_listSigners = function(seqid, input, output) {
  var args = new SigninApiRead_listSigners_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.listSigners.length === 2) {
    Q.fcall(this._handler.listSigners, args.auth, args.request)
      .then(function(result) {
        var result = new SigninApiRead_listSigners_result({success: result});
        output.writeMessageBegin("listSigners", Thrift.MessageType.REPLY, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        if (err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
          var result = new SigninApiRead_listSigners_result(err);
          output.writeMessageBegin("listSigners", Thrift.MessageType.REPLY, seqid);
        } else {
          var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("listSigners", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.listSigners(args.auth, args.request, function (err, result) {
      if (err == null || err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError || err instanceof apiAuth_ttypes.AuthenticationException) {
        var result = new SigninApiRead_listSigners_result((err != null ? err : {success: result}));
        output.writeMessageBegin("listSigners", Thrift.MessageType.REPLY, seqid);
      } else {
        var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("listSigners", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
}

SigninApiReadProcessor.prototype.process_signerExist = function(seqid, input, output) {
  var args = new SigninApiRead_signerExist_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.signerExist.length === 1) {
    Q.fcall(this._handler.signerExist, args.cfUtente)
      .then(function(result) {
        var result = new SigninApiRead_signerExist_result({success: result});
        output.writeMessageBegin("signerExist", Thrift.MessageType.REPLY, seqid);
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      }, function (err) {
        if (err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError) {
          var result = new SigninApiRead_signerExist_result(err);
          output.writeMessageBegin("signerExist", Thrift.MessageType.REPLY, seqid);
        } else {
          var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
          output.writeMessageBegin("signerExist", Thrift.MessageType.EXCEPTION, seqid);
        }
        result.write(output);
        output.writeMessageEnd();
        output.flush();
      });
  } else {
    this._handler.signerExist(args.cfUtente, function (err, result) {
      if (err == null || err instanceof apiCommon_ttypes.ValidationException || err instanceof apiCommon_ttypes.GenericError) {
        var result = new SigninApiRead_signerExist_result((err != null ? err : {success: result}));
        output.writeMessageBegin("signerExist", Thrift.MessageType.REPLY, seqid);
      } else {
        var result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("signerExist", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
}
