import { getRestCall } from "../../../utils/rest-api-call";
import { METERING_API_URL } from "../../../config";
import { getUserProfile } from "../../../utils/state-utils/get-user-profile";
import { getAuthWithAppName } from "../../auth";

export const GET_PERMISSIONS_START = "METERING_GET_PERMISSIONS_START";
export const GET_PERMISSIONS_END = "METERING_GET_PERMISSIONS_END";
export const GET_PERMISSIONS_SUCCESS = "METERING_GET_PERMISSIONS_SUCCESS";
export const GET_PERMISSIONS_ERROR = "METERING_GET_PERMISSIONS_ERROR";

export const getPermissionsStart = () => ({
    type: GET_PERMISSIONS_START
});

export const getPermissionsEnd = () => ({
    type: GET_PERMISSIONS_END
});

export const getPermissionsSuccess = permissions => ({
    type: GET_PERMISSIONS_SUCCESS,
    payload: {
        permissions
    }
});

export const getPermissionsError = error => ({
    type: GET_PERMISSIONS_ERROR,
    payload: {
        error
    }
});

export const getPermissions = itemUuid => async (dispatch, getState) => {
    dispatch(getPermissionsStart());
    try {
        const auth = getAuthWithAppName(getState().auth);
        const permissions = await getRestCall(
            `${METERING_API_URL}/api/v1/usage/${itemUuid}/permissions`,
            auth,
            null,
            dispatch,
            auth.refreshToken,
            getUserProfile(getState()),
            itemUuid
        );
        dispatch(getPermissionsSuccess(permissions));
    } catch (error) {
        dispatch(getPermissionsError(error));
    }
    dispatch(getPermissionsEnd());
};
