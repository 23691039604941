import styled from "styled-components";

import { Form } from "antd";
import LoadingButton from "../../../../components/general/loading-button";

export const FormTitle = styled.div`
    border-bottom: 1px solid #c9d9e8;
    line-height: 0.1em;
    margin: 30px 0 20px;
    padding-left: 40px;
    text-align: left;
    width: 100%;

    & span {
        background: #fff;
        color: #000000;
        font-size: 16px;
        padding: 0 10px;
    }
`;

export const DataContainer = styled(Form)`
    display: grid;
    grid-template-columns:
        calc(100% / 6) calc(100% / 6) calc(100% / 6) calc(100% / 6)
        calc(100% / 6) calc(100% / 6);
    justify-items: stretch;

    & .ant-form-item-label {
        line-height: 20px;
    }

    & .ant-form-item-label label:after {
        content: "";
    }

    & .ant-form-item {
        margin: 0px;
    }
`;

export const LeftCentralColumn = styled.div`
    grid-column-start: 1;
    grid-column-end: 4;
    margin: 0 10px 5px 0;
`;

export const RightCentralColumn = styled.div`
    grid-column-start: 4;
    grid-column-end: 5;
    margin: 0 0 5px 10px;
`;

export const RightExtremeColumn = styled.div`
    grid-column-start: 5;
    grid-column-end: 6;
    margin: 0 0 5px 10px;
`;

export const LabelText = styled.div`
    margin-top: 10px;
`;

export const LabelTitle = styled.div`
    color: rgba(0, 0, 0, 0.85);
`;

export const LoadingButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    text-align: right;
    margin-top: 30px;
`;

export const Button = styled(LoadingButton)`
    min-width: 200px;
    margin-left: 20px;
`;
