import React, { useEffect } from "react";

export const useInterval = (callback, timerDelay) => {
    useEffect(() => {
        callback();

        if (!timerDelay) {
            return;
        }

        const interval = setInterval(() => {
            callback();
        }, timerDelay);

        return () => clearInterval(interval);
    }, [callback, timerDelay]);
};

export const DemoUseInterval = ({ callback, timerDelay }) => {
    useInterval(callback, timerDelay);
    return <>Demo</>;
};
