import React, { useCallback, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Button } from "@ts-digital/vrc";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { ButtonsContainer, OptionsTrigger, Icon } from "./styled";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Popover } from "antd";
import { UsersManagegementContext } from "../../../../user-context";

import Modal from "../../../general/modal";

import "./style.css";

export const UserOptionsCell = ({
    cell: { value },
    itemId,
    row: {
        original: { state, isOwner, email, isLoggedUser, tsUserCode, tsid }
    },
    onCheckUserDetails,
    onDeleteRoles,
    onDeleteInvitation,
    onEditRoles,
    onInviteAgain
}) => {
    const [popOverVisible, setPopOverVisible] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [deleteInvitationConfirmModalOpen, setDeleteInvitationConfirmModalOpen] = useState(false);
    const { setSelectedUser, setUserCreationFlowFirstStep } = useContext(UsersManagegementContext);

    const onDelete = useCallback(() => {
        onDeleteRoles(value.companyId, value.id);
    }, [onDeleteRoles, value]);

    const onDeleteInvitationCallback = useCallback(() => {
        onDeleteInvitation(value.id, value.companyId);
    }, [onDeleteInvitation, value]);

    const showDeleteInvite = !state && tsid !== null && (tsUserCode === null || tsUserCode === undefined);

    return (
        <>
            <Modal
                type={"danger"}
                visible={confirmModalOpen}
                footer={
                    <>
                        <Button colorScheme={"ghost"} kind="secondary" onClick={() => setConfirmModalOpen(false)}>
                            <FormattedMessage id="general.annulla" />
                        </Button>
                        <Button
                            colorScheme={"alert"}
                            onClick={onDelete}
                            data-gainsight-track={"confirm-delete-from-user-list"}
                        >
                            <FormattedMessage id="c-registry-user.options.delete" />
                        </Button>
                    </>
                }
                onCancel={() => {
                    setConfirmModalOpen(false);
                }}
                width={"450px"}
                title={<FormattedMessage id="c-registry-user.remove-roles.modal-title" />}
            >
                <FormattedMessage id="c-registry-user.remove-roles.modal-content" values={{ email }} />
            </Modal>

            <Modal
                type={"danger"}
                visible={deleteInvitationConfirmModalOpen}
                footer={
                    <>
                        <Button
                            colorScheme={"ghost"}
                            kind="secondary"
                            onClick={() => setDeleteInvitationConfirmModalOpen(false)}
                        >
                            <FormattedMessage id="general.annulla" />
                        </Button>
                        <Button
                            colorScheme={"alert"}
                            onClick={onDeleteInvitationCallback}
                            data-gainsight-track={"confirm-delete-invitation-from-user-list"}
                        >
                            <FormattedMessage id="c-registry-user.options.delete-invitation" />
                        </Button>
                    </>
                }
                onCancel={() => {
                    setDeleteInvitationConfirmModalOpen(false);
                }}
                width={"450px"}
                title={<FormattedMessage id="c-registry-user.remove-roles.modal-title.delete-invitation" />}
            >
                <FormattedMessage
                    id="c-registry-user.remove-roles.modal-content.delete-invitation"
                    values={{ email }}
                />
            </Modal>

            <OptionsTrigger
                disabled={isOwner || isLoggedUser}
                kind={"tertiary"}
                size={"small"}
                data-cy={"manage-users-edit"}
                onClick={() => onEditRoles(email)}
                data-gainsight-track={"user-list-edit-user-pen-icon"}
            >
                <Icon icon={faPen} />
            </OptionsTrigger>
            <Popover
                placement="topRight"
                trigger="click"
                overlayClassName="users-options"
                onVisibleChange={setPopOverVisible}
                visible={popOverVisible}
                content={
                    <ButtonsContainer>
                        <Button
                            kind="tertiary"
                            onClick={() => {
                                setPopOverVisible(false);
                                onCheckUserDetails(value);
                            }}
                            data-cy={"manage-users-options-details"}
                        >
                            <FormattedMessage id="c-registry-user.options.details" />
                        </Button>
                        {!state && (
                            <Button
                                kind="tertiary"
                                onClick={() => {
                                    onInviteAgain(email);
                                }}
                                data-cy={"manage-users-options-invite-again"}
                                data-gainsight-track={"user-list-invite-again"}
                            >
                                <FormattedMessage id="c-registry-user.options.invite-again" />
                            </Button>
                        )}
                        {!isOwner && !isLoggedUser && (
                            <Link
                                to={`/${itemId}/registry/user/create`}
                                params={{ selectItemsStep: true }}
                                data-cy={"manage-users-options-add-roles-Link"}
                            >
                                <Button
                                    kind="tertiary"
                                    data-cy={"manage-users-options-add-roles-Button"}
                                    onClick={() => {
                                        setSelectedUser(email);
                                        setUserCreationFlowFirstStep("selectCompanies");
                                        return;
                                    }}
                                    data-gainsight-track={"user-list-add-roles"}
                                >
                                    <FormattedMessage id="c-registry-user.add-roles" />
                                </Button>
                            </Link>
                        )}
                        {showDeleteInvite ? (
                            <Button
                                kind="tertiary"
                                onClick={() => {
                                    setPopOverVisible(false);
                                    setDeleteInvitationConfirmModalOpen(true);
                                }}
                                data-cy={"manage-users-options-delete-invitation"}
                                disabled={isOwner || isLoggedUser}
                            >
                                <FormattedMessage id="c-registry-user.options.delete-invitation" />
                            </Button>
                        ) : (
                            <Button
                                kind="tertiary"
                                onClick={() => {
                                    setPopOverVisible(false);
                                    setConfirmModalOpen(true);
                                }}
                                data-cy={"manage-users-options-delete"}
                                disabled={isOwner || isLoggedUser}
                            >
                                <FormattedMessage id="c-registry-user.options.delete" />
                            </Button>
                        )}
                    </ButtonsContainer>
                }
            >
                <OptionsTrigger kind={"tertiary"} size={"small"} data-cy={"manage-users-options"}>
                    <Icon icon={faEllipsisV} />
                </OptionsTrigger>
            </Popover>
        </>
    );
};

UserOptionsCell.propTypes = {
    /** Current cell data */
    cell: PropTypes.shape({ value: PropTypes.any }).isRequired,
    /** Button click callbacks */
    onCheckUserDetails: PropTypes.func.isRequired,
    onDeleteRoles: PropTypes.func.isRequired,
    onEditRoles: PropTypes.func.isRequired,
    onInviteAgain: PropTypes.func.isRequired
};
