import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { resetPassword } from "../../../actions/auth";
import ResetPasswordForm from "../../../components/login/reset-password/form";
import AnimatedBackground from "../../../components/login/animated-background";
import LoginModal from "../../../components/login-modal";

export class ResetPassword extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        password: PropTypes.object.isRequired,
        resetPassword: PropTypes.func.isRequired
    };

    handleSubmit(e) {
        const { resetPassword, match } = this.props;

        resetPassword(decodeURIComponent(match.params.userId), match.params.token, e.password, "PORTALE");
    }

    render() {
        const { password } = this.props;
        const resetIsOk = password.reset.status.ended && !password.reset.status.error;

        return resetIsOk ? (
            <Redirect to="/resetPassword/success" />
        ) : (
            <AnimatedBackground>
                <LoginModal title={<FormattedMessage id="c-reset-password.title" />}>
                    <ResetPasswordForm
                        errorMsg={password.reset.status.errorInfo ? password.reset.status.errorInfo.message : undefined}
                        onSubmit={e => this.handleSubmit(e)}
                    />
                </LoginModal>
            </AnimatedBackground>
        );
    }
}

function mapStateToProps(state) {
    return {
        password: state.password
    };
}

export default connect(mapStateToProps, { resetPassword })(ResetPassword);
