import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const InfoContainer = styled.div`
    background: linear-gradient(to right bottom, #f1f5fa 50%, #fff 50%);
    margin-left: -15px;
    margin-right: -15px;
    margin-top: -15px;
    padding: 24px;
`;

export const Info = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: center;
    }
`;

export const LogoContainer = styled.div`
    max-width: 600px;
`;

export const Logo = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

export const Title = styled.div`
    max-width: ${props => (props.fullWidth ? "100%" : "50%")};
    font-family: Cairo;
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.1;
    letter-spacing: 1.88px;
    color: #04567c;

    @media (max-width: 1024px) {
        max-width: 100%;
    }
`;

export const BoxContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 384px));
    grid-gap: 32px;
    justify-content: center;
    margin: 24px;
`;

export const BoxTitle = styled.p`
    font-family: Cairo;
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.1;
    letter-spacing: 1.88px;
    color: #04567c;
`;

export const BoxDescription = styled.p`
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: 0.25px;
    color: #152935;
`;

export const MediaContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    margin: 32px;

    @media (max-width: 1024px) {
        width: initial;
        min-height: 500px;
    }
`;

export const Image = styled.img`
    width: 100%;
    border: 1px solid #ecf0f5;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;

    & > * {
        margin-right: 16px;
    }
`;

export const Disclaimer = styled.div`
    font-family: Roboto;
    font-size: 14px;
    color: #04567c;
    line-height: 1.5;
    margin-top: 24px;
`;

export const DisclaimerIcon = styled(FontAwesomeIcon)`
    color: ${props => props.iconColor};
`;
