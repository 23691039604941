import React from "react";

import { FormattedMessage } from "react-intl";

import { faCheckCircle, faClock } from "@fortawesome/free-solid-svg-icons";

import { Container, StatusIcon } from "./styled";

export const UserStatusCell = ({ cell: { value } }) => {
    const label = value ? (
        <FormattedMessage id="c-registry-user.user-active" />
    ) : (
        <FormattedMessage id="c-registry-user.user-not-active" />
    );

    return (
        <Container>
            <StatusIcon icon={value ? faCheckCircle : faClock} active={value.toString()} />
            {label}
        </Container>
    );
};
