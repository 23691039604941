import { API_URL, USER_WRITE_REST } from "../config";
import { getAuthWithAppName } from "./auth.js";

import {
    deleteRestCall,
    postRestCall,
    putRestCall,
    getRestCall,
    headRestCall,
    patchRestCall
} from "../utils/rest-api-call";
import { getUserProfile } from "../utils/state-utils/get-user-profile";

export const LOAD_USER_START = "LOAD_USER_START";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_ERROR = "LOAD_USER_ERROR";

export function loadUser(userId) {
    return async (dispatch, getState) => {
        dispatch({
            type: LOAD_USER_START
        });

        let authObj = getAuthWithAppName(getState().auth);
        authObj.accessToken = authObj.securityToken;

        try {
            const result = await getRestCall(
                `${API_URL}/users/${userId}`,
                authObj,
                null,
                dispatch,
                authObj.refreshToken,
                getUserProfile(getState()),
                userId
            );
            dispatch({
                type: LOAD_USER_SUCCESS,
                payload: {
                    user: {
                        ...result,
                        profile: {
                            ...result.profile,
                            language: getState().auth.loginAuth.locale
                                ? getState().auth.loginAuth.locale
                                : result.profile.language
                        }
                    }
                }
            });
        } catch (err) {
            dispatch({
                type: LOAD_USER_ERROR,
                error: { code: err.code, message: err.message },
                payload: { userId }
            });
        }
    };
}

export const LOAD_USER_RESET = "LOAD_USER_RESET";

export function resetUserLoad() {
    return dispatch => {
        dispatch({
            type: LOAD_USER_RESET
        });
    };
}

export const GET_USER_INFO_START = "GET_USER_INFO_START";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_ERROR = "GET_USER_INFO_ERROR";
export const GET_USER_INFO_RESET = "GET_USER_INFO_RESET";

export function getUserInfo(userId) {
    return async (dispatch, getState) => {
        dispatch({
            type: GET_USER_INFO_START
        });

        let authObj = getAuthWithAppName(getState().auth);
        authObj.accessToken = authObj.securityToken;

        try {
            const result = await getRestCall(
                `${API_URL}/users/${userId}`,
                authObj,
                {},
                dispatch,
                authObj.refreshToken,
                getUserProfile(getState()),
                userId
            );

            dispatch({
                type: GET_USER_INFO_SUCCESS,
                payload: { user: result }
            });
        } catch (err) {
            dispatch({
                type: GET_USER_INFO_ERROR,
                error: err
            });
        }
    };
}

export function resetUserInfo() {
    return dispatch => {
        dispatch({
            type: GET_USER_INFO_RESET
        });
    };
}

export const USER_EXISTS_START = "USER_EXISTS_START";
export const USER_EXISTS_SUCCESS = "USER_EXISTS_SUCCESS";
export const USER_EXISTS_ERROR = "USER_EXISTS_ERROR";
export const USER_EXISTS_RESET = "USER_EXISTS_RESET";

export function userExists(userId) {
    return async (dispatch, getState) => {
        dispatch({
            type: USER_EXISTS_START
        });

        try {
            await headRestCall(
                `${API_URL}/users/${userId}/exists`,
                null,
                null,
                dispatch,
                null,
                getUserProfile(getState()),
                userId
            );

            dispatch({
                type: USER_EXISTS_SUCCESS,
                payload: true
            });
        } catch (err) {
            if (err.status === 404) {
                dispatch({
                    type: USER_EXISTS_SUCCESS,
                    payload: false
                });
            } else {
                dispatch({
                    type: USER_EXISTS_ERROR,
                    error: err
                });
            }
        }
    };
}

export function resetUserExists() {
    return dispatch => dispatch({ type: USER_EXISTS_RESET });
}

export const ACTIVATE_USER_START = "ACTIVATE_USER_START";
export const ACTIVATE_USER_ERROR = "ACTIVATE_USER_ERROR";
export const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS";
export const ACTIVATE_USER_RESET = "ACTIVATE_USER_RESET";

export function activateUser(userId, activationToken, sha256UserPassword = undefined) {
    return async dispatch => {
        dispatch({
            type: ACTIVATE_USER_START
        });

        try {
            const res = await putRestCall(
                `${USER_WRITE_REST}/users/${userId}/activate`,
                undefined,
                {},
                { activationToken, sha256UserPassword },
                undefined,
                undefined,
                { id: userId }
            );

            dispatch({ type: ACTIVATE_USER_SUCCESS, payload: res });
        } catch (e) {
            dispatch({ type: ACTIVATE_USER_ERROR, error: e });
        }
    };
}

export function resetActivateUser() {
    return dispatch => dispatch({ type: ACTIVATE_USER_RESET });
}

export const CREATE_OR_ADD_ROLES_TO_USER_START = "CREATE_OR_ADD_ROLES_TO_USER_START";
export const CREATE_OR_ADD_ROLES_TO_USER_SUCCESS = "CREATE_OR_ADD_ROLES_TO_USER_SUCCESS";
export const CREATE_OR_ADD_ROLES_TO_USER_ERROR = "CREATE_OR_ADD_ROLES_TO_USER_ERROR";
export const CREATE_OR_ADD_ROLES_TO_USER_RESET = "CREATE_OR_ADD_ROLES_TO_USER_RESET";

export function createOrAddRolesToUser(email, roles, firstName, lastName, enableInvitation, localSerices) {
    return async (dispatch, getState) => {
        dispatch({
            type: CREATE_OR_ADD_ROLES_TO_USER_START
        });

        const auth = getAuthWithAppName(getState().auth);

        // The API doesn't accept null as a valid value, so any nullable field must be mapped to undefined
        const sanitizedRoles = roles.map(({ appId, featureCode, resourceId, resourceUuid, actionKey }) => ({
            appId: appId || undefined,
            featureCode: featureCode || undefined,
            resourceId,
            resourceUuid,
            actionKey
        }));

        try {
            const res = await postRestCall(
                `${API_URL}/roles/creation`,
                auth,
                {},
                { email, roles: sanitizedRoles, firstName, lastName, invitation: enableInvitation, localSerices },
                dispatch,
                auth.refreshToken,
                getUserProfile(getState())
            );

            dispatch({ type: CREATE_OR_ADD_ROLES_TO_USER_SUCCESS, payload: res });
        } catch (e) {
            dispatch({ type: CREATE_OR_ADD_ROLES_TO_USER_ERROR, error: e });
        }
    };
}

export function resetCreateOrAddRolesToUser() {
    return dispatch => dispatch({ type: CREATE_OR_ADD_ROLES_TO_USER_RESET });
}

export const REMOVE_ROLES_USER_START = "REMOVE_ROLES_USER_START";
export const REMOVE_ROLES_USER_SUCCESS = "REMOVE_ROLES_USER_SUCCESS";
export const REMOVE_ROLES_USER_ERROR = "REMOVE_ROLES_USER_ERROR";
export const REMOVE_ROLES_USER_RESET = "REMOVE_ROLES_USER_RESET";

export function removeRoles(itemId, userId) {
    return async (dispatch, getState) => {
        dispatch({
            type: REMOVE_ROLES_USER_START,
            payload: { itemId, userId }
        });

        try {
            const auth = getAuthWithAppName(getState().auth);
            await deleteRestCall(
                `${API_URL}/users/${itemId}/${userId}`,
                auth,
                {},
                {},
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                itemId
            );

            dispatch({
                type: REMOVE_ROLES_USER_SUCCESS,
                payload: { itemId, userId }
            });
        } catch (error) {
            dispatch({
                type: REMOVE_ROLES_USER_ERROR,
                payload: { itemId, userId },
                error: error
            });
        }
    };
}

export function resetRemoveRoles() {
    return dispatch => {
        dispatch({
            type: REMOVE_ROLES_USER_RESET
        });
    };
}

export const DELETE_USER_INVITATION_START = "DELETE_USER_INVITATION_START";
export const DELETE_USER_INVITATION_SUCCESS = "DELETE_USER_INVITATION_SUCCESS";
export const DELETE_USER_INVITATION_ERROR = "DELETE_USER_INVITATION_ERROR";
export const DELETE_USER_INVITATION_RESET = "DELETE_USER_INVITATION_RESET";

export function deleteUserInvitation(userId, itemId) {
    return async (dispatch, getState) => {
        dispatch({
            type: DELETE_USER_INVITATION_START,
            payload: { itemId, userId }
        });

        try {
            const auth = getAuthWithAppName(getState().auth);
            await deleteRestCall(
                `${API_URL}/invitation/${userId}`,
                auth,
                {},
                {},
                dispatch,
                auth.refreshToken,
                getUserProfile(getState())
            );

            dispatch({
                type: DELETE_USER_INVITATION_SUCCESS,
                payload: { userId, itemId }
            });
        } catch (error) {
            dispatch({
                type: DELETE_USER_INVITATION_ERROR,
                payload: { userId, itemId },
                error: error
            });
        }
    };
}

export function resetDeleteUserInvitation() {
    return dispatch => {
        dispatch({
            type: DELETE_USER_INVITATION_RESET
        });
    };
}

export const UPDATE_USER_LANGUAGE_START = "UPDATE_USER_LANGUAGE_START";
export const UPDATE_USER_LANGUAGE_ERROR = "UPDATE_USER_LANGUAGE_ERROR";
export const UPDATE_USER_LANGUAGE_SUCCESS = "UPDATE_USER_LANGUAGE_SUCCESS";

export function updateUserLanguage(language) {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_USER_LANGUAGE_START
        });

        const auth = getState().auth;

        const requestUrl = `${API_URL}/users/${auth.loginAuth.id}/update`;
        const request = {
            language
        };

        try {
            const response = await patchRestCall(
                requestUrl,
                auth,
                null,
                request,
                dispatch,
                auth.refreshToken,
                getUserProfile(getState()),
                undefined,
                false
            );

            dispatch({
                type: UPDATE_USER_LANGUAGE_SUCCESS,
                payload: response
            });
        } catch (e) {
            dispatch({
                type: UPDATE_USER_LANGUAGE_ERROR,
                payload: { error: e }
            });
        }
    };
}
