import React from "react";
import PropTypes from "prop-types";
import "./style.css";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

const Infobox = ({ selectedCompany }) => {
    const { vatNumber, taxId } = selectedCompany && selectedCompany.identifier ? selectedCompany.identifier : {};

    return (
        <div>
            <div className="connection-create-info-box">
                <p className="business-name">
                    <FormattedHTMLMessage id="general.selected-company-label" />
                    {selectedCompany.details.description}
                </p>
                <p>
                    <FormattedMessage id="general.CF-value" values={{ taxId }} />
                    &nbsp;&nbsp;
                    {vatNumber ? <FormattedMessage id="general.PIVA-value" values={{ vatNumber }} /> : null}
                </p>
            </div>
        </div>
    );
};

Infobox.defaultProps = {
    selectedCompany: { details: {} }
};

Infobox.propTypes = {
    selectedCompany: PropTypes.object
};

export default Infobox;
