import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CompanyName = styled.div`
    font-family: Cairo;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.18px;
    color: #152935;
`;

export const CompanyWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

export const Container = styled.div`
    padding: 8px 16px;
    width: 384px;
`;

export const Status = styled.div`
    font-family: Roboto;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: #5a6872;
`;

export const Info = styled.div`
    font-family: Roboto;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: #152935;
    margin-top: 8px;
    margin-right: 8px;
`;

export const CopyIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
`;
