import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { Button } from "@ts-digital/vrc";
import { FormattedMessage } from "react-intl";

import { loginWithSha, resetUpdateUserPassword } from "../../../actions/auth";
import LoginModal from "../../../components/login-modal";
import AnimatedBackground from "../../../components/login/animated-background";

class ResetPasswordSuccess extends Component {
    static propTypes = {
        auth: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        loginWithSha: PropTypes.func.isRequired,
        resetUpdateUserPassword: PropTypes.func.isRequired
    };

    componentDidUpdate(prevProps) {
        const { auth } = this.props;
        if (prevProps.auth.status.started && auth.status.ended) {
            this.props.history.push("/");
        }
    }

    componentWillUnmount() {
        const { resetUpdateUserPassword } = this.props;
        resetUpdateUserPassword();
    }

    render() {
        const { location, loginWithSha } = this.props;

        return (
            <AnimatedBackground>
                <LoginModal title={<FormattedMessage id="c-reset-password-success.title" />}>
                    <br />
                    <br />
                    <Button
                        style={{ width: "100%" }}
                        onClick={() => {
                            if (location.state && location.state.userId && location.state.userNewPwd) {
                                loginWithSha("PORTALE", location.state.userId, location.state.userNewPwd);
                            } else {
                                this.props.history.push("/");
                            }
                        }}
                        kind="primary"
                    >
                        <FormattedMessage id="c-reset-password-success.button" />
                    </Button>
                </LoginModal>
            </AnimatedBackground>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, { loginWithSha, resetUpdateUserPassword })(ResetPasswordSuccess);
