import {
    LIST_USERS_START,
    LIST_USERS_SUCCESS,
    LIST_USERS_ERROR,
    LIST_USERS_RESET,
    CREATE_API_KEY_START,
    CREATE_API_KEY_SUCCESS,
    CREATE_API_KEY_ERROR,
    CREATE_API_KEY_RESET,
    CREATE_USER_START,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    CREATE_USER_RESET,
    EDIT_USER_START,
    EDIT_USER_SUCCESS,
    EDIT_USER_ERROR,
    EDIT_USER_RESET,
    USER_RESET,
    ADD_ROLES_START,
    ADD_ROLES_ERROR,
    ADD_ROLES_SUCCESS,
    REMOVE_ROLES_START,
    REMOVE_ROLES_ERROR,
    REMOVE_ROLES_SUCCESS,
    ADD_ROLES_RESET,
    REMOVE_ROLES_RESET,
    USER_RESET_ROLE_MANAGE,
    REMOVE_THEN_ADD_ROLES_START,
    LIST_USERS_V3_START,
    LIST_USERS_V3_SUCCESS,
    LIST_USERS_V3_ERROR,
    RESEND_EMAIL_START,
    RESEND_EMAIL_SUCCESS,
    RESEND_EMAIL_ERROR,
    RESEND_EMAIL_RESET,
    EDIT_ROLES_START,
    EDIT_ROLES_SUCCESS,
    EDIT_ROLES_ERROR,
    EDIT_ROLES_RESET,
    GET_USER_V3_START,
    GET_USER_V3_SUCCESS,
    GET_USER_V3_ERROR,
    GET_USER_V3_RESET
} from "../../actions/company/users";

import {
    CREATE_OR_ADD_ROLES_TO_USER_SUCCESS,
    REMOVE_ROLES_USER_SUCCESS,
    DELETE_USER_INVITATION_SUCCESS
} from "../../actions/user.js";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function users(state = defaultState, { type, payload, error }) {
    switch (type) {
        case LIST_USERS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case LIST_USERS_SUCCESS:
            const res = payload.reduce((acc, curr) => curr.res, {});
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                users:
                    res.totalItems > 0
                        ? res.users.map(u => ({
                              ...u,
                              user: {
                                  ...u.user,
                                  profile: {
                                      ...u.user.profile,
                                      firstName: u.user.profile.firstName || "",
                                      lastName: u.user.profile.lastName || ""
                                  }
                              }
                          }))
                        : []
            };
        case LIST_USERS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case LIST_USERS_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export function usersv3(state = defaultState, { type, payload, error }) {
    switch (type) {
        case LIST_USERS_V3_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                },
                users: { ...state.users }
            };
        case LIST_USERS_V3_SUCCESS:
            const itemId = payload.itemId;

            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                users: {
                    ...state.users,
                    [itemId]: {
                        ...payload.res,
                        users: payload.res.users.map(u => ({
                            ...u,
                            profile: {
                                ...u.profile,
                                firstName: u.profile.firstName || "",
                                lastName: u.profile.lastName || ""
                            }
                        }))
                    }
                }
            };
        case LIST_USERS_V3_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case EDIT_ROLES_SUCCESS:
            if (state.users) {
                const index = state.users[payload.itemId].users.findIndex(u => u.profile.id === payload.userId);
                state.users[payload.itemId].users[index] = {
                    ...state.users[payload.itemId].users[index],
                    roles: payload.updatedRoles.roles,
                    enhancedRoles: payload.updatedRoles.enhancedRoles[payload.itemId],
                    labels: payload.updatedRoles.labels[payload.itemId]
                };

                if (state.users[payload.itemId].users[index].roles.length === 0) {
                    delete state.users[payload.itemId].users[index];
                }

                return {
                    ...state,
                    users: {
                        ...state.users,
                        [payload.itemId]: {
                            ...state.users[payload.itemId]
                        }
                    }
                };
            }
            return state;
        case REMOVE_ROLES_USER_SUCCESS:
        case DELETE_USER_INVITATION_SUCCESS:
            /* optimistic update */
            if (state.users && state.users[payload.itemId]) {
                const idx = state.users[payload.itemId].users.findIndex(u => u.profile.id === payload.userId);
                state.users[payload.itemId].users.splice(idx, 1);

                return {
                    ...state,
                    users: {
                        ...state.users,
                        [payload.itemId]: {
                            ...state.users[payload.itemId],
                            totalItems: state.users[payload.itemId].totalItems - 1
                        }
                    }
                };
            }
            return state;

        case CREATE_OR_ADD_ROLES_TO_USER_SUCCESS:
            const itemIds = Object.keys(payload.groupedRoles);
            itemIds.forEach(id => {
                if (state.users && state.users[id]) {
                    const index = state.users[id].users.findIndex(u => u.profile.id === payload.id);
                    if (index >= 0) {
                        state.users[id].users[index] = {
                            ...state.users[id].users[index],
                            roles: payload.updatedRoles.roles,
                            enhancedRoles: payload.updatedRoles.enhancedRoles[id],
                            labels: payload.updatedRoles.labels[id]
                        };
                    } else {
                        state.users[id].users.unshift({
                            profile: {
                                id: payload.id,
                                firstName: payload.firstName,
                                lastName: payload.lastName,
                                tsid: payload.tsid
                            },
                            status: { active: false },
                            roles: payload.groupedRoles[id],
                            enhancedRoles: payload.enhancedRoles[id],
                            labels: payload.labels[id]
                        });
                        state.users[id].totalItems = state.users[id].totalItems + 1;
                    }
                }
            });
            return state;
        default:
            return state;
    }
}

export function getUserV3(state = defaultState, { type, payload, error }) {
    const user = state.user;

    switch (type) {
        case GET_USER_V3_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_USER_V3_SUCCESS:
            const itemId = payload.itemId;
            let companyUsers = {};
            companyUsers[itemId] = payload.res;

            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                user: {
                    ...payload.user,
                    profile: {
                        ...payload.user.profile,
                        firstName: payload.user.profile.firstName || "",
                        lastName: payload.user.profile.lastName || ""
                    }
                }
            };
        case GET_USER_V3_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case EDIT_ROLES_SUCCESS:
            if (payload.userId === user.profile.id) {
                const isCurrentRolesUpdatingWithNewOne = (userRole, updatingRoles) => {
                    return updatingRoles.some(
                        updatedRole =>
                            updatedRole.resourceId === userRole.resourceUuid && updatedRole.appId === userRole.appId
                    );
                };

                const oldRolesWithoutUpdatedRoles = user.roles
                    ? user.roles.filter(
                          userRole => !isCurrentRolesUpdatingWithNewOne(userRole, payload.updatedRoles.roles)
                      )
                    : {};
                user.roles = [...oldRolesWithoutUpdatedRoles, ...payload.updatedRoles.roles];
                user.enhancedRoles = { ...user.enhancedRoles, ...payload.updatedRoles.enhancedRoles };
                user.labels = { ...payload.updatedRoles.labels, ...user.labels };
            }

            return {
                ...state,
                user
            };
        case REMOVE_ROLES_USER_SUCCESS:
        case DELETE_USER_INVITATION_SUCCESS:
            /* optimistic update */
            if (user && payload.userId === user.profile.id) {
                delete user.enhancedRoles[payload.itemId];
                delete user.labels[payload.itemId];
                user.roles = user.roles.filter(r => r.resourceUuid !== payload.itemId);
            }

            return {
                ...state,
                user
            };
        case GET_USER_V3_RESET:
            return { ...defaultState };
        default:
            return state;
    }
}

export function editUserV3(state = defaultState, { type, payload, error }) {
    switch (type) {
        case EDIT_ROLES_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case EDIT_ROLES_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                }
            };
        case EDIT_ROLES_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case EDIT_ROLES_RESET:
            return { ...defaultState };
        default:
            return state;
    }
}

export function createUser(state = defaultState, { type, payload, error }) {
    switch (type) {
        case CREATE_API_KEY_START:
        case CREATE_USER_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false,
                    errorInfo: {}
                }
            };
        case CREATE_API_KEY_SUCCESS:
        case CREATE_USER_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true,
                    errorInfo: {}
                },
                ...payload
            };
        case CREATE_API_KEY_ERROR:
        case CREATE_USER_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case CREATE_API_KEY_RESET:
        case CREATE_USER_RESET:
        case USER_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export function editUser(state = defaultState, { type, payload, error }) {
    switch (type) {
        case EDIT_USER_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case EDIT_USER_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case EDIT_USER_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case EDIT_USER_RESET:
        case USER_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export function addRoles(state = defaultState, { type, payload, error }) {
    switch (type) {
        case ADD_ROLES_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false,
                    errorInfo: {}
                },
                user: null
            };
        case ADD_ROLES_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true,
                    errorInfo: {}
                },
                ...payload
            };
        case ADD_ROLES_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case USER_RESET_ROLE_MANAGE:
        case USER_RESET:
        case ADD_ROLES_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export function removeRoles(state = defaultState, { type, payload, error }) {
    switch (type) {
        case REMOVE_ROLES_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                },
                user: null
            };
        case REMOVE_ROLES_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case REMOVE_ROLES_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case USER_RESET_ROLE_MANAGE:
        case USER_RESET:
        case REMOVE_ROLES_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export const removeThenAddRoles = (state = defaultState.status, { type, payload, error }) => {
    switch (type) {
        case REMOVE_THEN_ADD_ROLES_START:
            return {
                ...defaultState.status,
                started: true,
                addRoles: payload.addRoles,
                removeRoles: payload.removeRoles
            };
        case ADD_ROLES_SUCCESS:
            return {
                ...defaultState.status,
                addRoles: false,
                removeRoles: state.removeRoles,
                ended: true // if addRoles has ended the process is over
            };
        case REMOVE_ROLES_SUCCESS:
            return {
                ...defaultState.status,
                removeRoles: false,
                addRoles: state.addRoles,
                started: state.addRoles,
                ended: !state.addRoles // only true if there is no add to perform
            };
        case ADD_ROLES_ERROR:
        case REMOVE_ROLES_ERROR:
            return {
                ...defaultState.status,
                error: true,
                errorInfo: { code: error.code, message: error.message }
            };
        default:
            return state;
    }
};

export function resendEmail(state = defaultState, { type, payload, error }) {
    switch (type) {
        case RESEND_EMAIL_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false,
                    errorInfo: {}
                }
            };
        case RESEND_EMAIL_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true,
                    errorInfo: {}
                },
                ...payload
            };
        case RESEND_EMAIL_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case RESEND_EMAIL_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}
