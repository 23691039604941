import styled from "styled-components";

export const IconContainer = styled.span`
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
    text-align: center;
`;

export const SearchbarContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-right: 100px;
`;

export const TableTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const TablesContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin: 0px 100px;
    margin-top: 20px;

    & > div {
        flex: 1;
    }

    & > div:first-child {
        margin-right: 60px;
    }

    @media (max-width: 1270px) {
        flex-direction: column;

        & > div {
            width: 100%;
            margin: 10px 0px;
        }
    }
`;

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-left: 100px;
    margin-bottom: 50px;
    margin-right: 100px;

    & > div {
        display: flex;
        flex: 1;
        justify-content: flex-end;
    }
`;
