import { combineReducers } from "redux";

import { getNotification, listNotifications, results } from "./read";
import { acceptNotification, readNotification, rejectNotification, unreadNotification } from "./write";

const read = combineReducers({ getNotification, listNotifications, results });
const write = combineReducers({
    acceptNotification,
    readNotification,
    rejectNotification,
    unreadNotification
});

export default combineReducers({ read, write });
