import createHmac from "create-hmac";

import {
    LOAD_USER_START,
    LOAD_USER_ERROR,
    LOAD_USER_SUCCESS,
    LOAD_USER_RESET,
    GET_USER_INFO_START,
    GET_USER_INFO_SUCCESS,
    GET_USER_INFO_ERROR,
    GET_USER_INFO_RESET,
    USER_EXISTS_START,
    USER_EXISTS_SUCCESS,
    USER_EXISTS_ERROR,
    USER_EXISTS_RESET,
    ACTIVATE_USER_START,
    ACTIVATE_USER_SUCCESS,
    ACTIVATE_USER_ERROR,
    ACTIVATE_USER_RESET,
    CREATE_OR_ADD_ROLES_TO_USER_START,
    CREATE_OR_ADD_ROLES_TO_USER_RESET,
    CREATE_OR_ADD_ROLES_TO_USER_SUCCESS,
    CREATE_OR_ADD_ROLES_TO_USER_ERROR,
    REMOVE_ROLES_USER_ERROR,
    REMOVE_ROLES_USER_START,
    REMOVE_ROLES_USER_SUCCESS,
    REMOVE_ROLES_USER_RESET,
    DELETE_USER_INVITATION_START,
    DELETE_USER_INVITATION_SUCCESS,
    DELETE_USER_INVITATION_ERROR,
    DELETE_USER_INVITATION_RESET,
    UPDATE_USER_LANGUAGE_START,
    UPDATE_USER_LANGUAGE_SUCCESS,
    UPDATE_USER_LANGUAGE_ERROR
} from "../actions/user.js";

import { CREATE_ITEM_SUCCESS } from "../actions/companies";
import { EDIT_USER_SUCCESS } from "../actions/company/users.js";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function user(state = defaultState, { type, payload, error }) {
    switch (type) {
        case LOAD_USER_START:
            return {
                user: null,
                ...state,
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case LOAD_USER_SUCCESS:
            let elevioAccountSecret = "593910444bb82";

            const hmac = createHmac("sha256", Buffer.from(elevioAccountSecret));
            hmac.update(payload.user.profile.id);
            let elevioUserHash = hmac.digest("hex");

            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload,
                user: {
                    ...payload.user,
                    profile: {
                        ...payload.user.profile,
                        firstName: payload.user.profile.firstName || "",
                        lastName: payload.user.profile.lastName || ""
                    },
                    elevioUserHash: elevioUserHash
                }
            };
        case LOAD_USER_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case LOAD_USER_RESET:
            return {
                ...defaultState
            };
        case CREATE_ITEM_SUCCESS:
            if (state.user) {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        roles: [
                            ...state.user.roles,
                            {
                                actionKey: "ADMIN",
                                appId: null,
                                featureCode: null,
                                resourceId: payload.item.base.id
                            }
                        ]
                    }
                };
            } else {
                return state;
            }
        case EDIT_USER_SUCCESS:
            if (state.user) {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        profile: {
                            ...state.user.profile,
                            ...payload
                        }
                    }
                };
            } else {
                return state;
            }
        default:
            return state;
    }
}

export function getUserInfo(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_USER_INFO_START:
            return {
                ...state,
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_USER_INFO_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case GET_USER_INFO_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case GET_USER_INFO_RESET:
            return {
                user: null,
                ...defaultState
            };
        default:
            return state;
    }
}

export function userExists(state = defaultState, { type, payload, error }) {
    switch (type) {
        case USER_EXISTS_START:
            return {
                status: {
                    ...defaultState.status,
                    started: true
                }
            };
        case USER_EXISTS_SUCCESS:
            return {
                status: {
                    ...defaultState.status,
                    success: true
                },
                exists: payload
            };
        case USER_EXISTS_ERROR:
            return {
                status: {
                    ...defaultState.status,
                    error: true,
                    errorInfo: error
                }
            };
        case USER_EXISTS_RESET:
            return { ...defaultState };
        default:
            return state;
    }
}

export function activateUser(state = defaultState.status, { type, payload, error }) {
    switch (type) {
        case ACTIVATE_USER_START:
            return {
                ...defaultState.status,
                started: true
            };
        case ACTIVATE_USER_SUCCESS:
            return {
                ...defaultState.status,
                ended: true
            };
        case ACTIVATE_USER_ERROR:
            return {
                ...defaultState.status,
                error: true,
                errorInfo: error
            };
        case ACTIVATE_USER_RESET:
            return defaultState.status;
        default:
            return state;
    }
}

export function createOrAddRolesToUser(state = defaultState.status, { type, payload, error }) {
    switch (type) {
        case CREATE_OR_ADD_ROLES_TO_USER_START:
            return {
                ...defaultState.status,
                started: true
            };
        case CREATE_OR_ADD_ROLES_TO_USER_SUCCESS:
            return {
                ...defaultState.status,
                ended: true
            };
        case CREATE_OR_ADD_ROLES_TO_USER_ERROR:
            return {
                ...defaultState.status,
                error: true,
                errorInfo: error
            };
        case CREATE_OR_ADD_ROLES_TO_USER_RESET:
            return defaultState.status;
        default:
            return state;
    }
}

export function removeRolesFromUser(state = defaultState, { type, payload, error }) {
    switch (type) {
        case REMOVE_ROLES_USER_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false,
                    errorInfo: {}
                }
            };
        case REMOVE_ROLES_USER_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true,
                    errorInfo: {}
                },
                ...payload
            };
        case REMOVE_ROLES_USER_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        case REMOVE_ROLES_USER_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export function deleteUserInvitation(state = defaultState, { type, payload, error }) {
    switch (type) {
        case DELETE_USER_INVITATION_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false,
                    errorInfo: {}
                },
                ...payload
            };
        case DELETE_USER_INVITATION_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true,
                    errorInfo: {}
                },
                ...payload
            };
        case DELETE_USER_INVITATION_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                },
                ...payload
            };
        case DELETE_USER_INVITATION_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export function updateUserLanguage(state = defaultState, { type, payload, error }) {
    switch (type) {
        case UPDATE_USER_LANGUAGE_START:
            return {
                ...state,
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case UPDATE_USER_LANGUAGE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ...payload
            };
        case UPDATE_USER_LANGUAGE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { error }
                }
            };
        default:
            return state;
    }
}
