import React, { Component } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";

import { updateOffice } from "../../../actions/company/offices";

import { errorBoundary } from "../../../components/general/error-boundary";
import OfficeForm from "../../../components/office-form";

import { ENABLE_GOV_CODE } from "../../../config";
import { OfficesCreateContainer, Subtitle, Title } from "../create/styled";

export class OfficesEdit extends Component {
    constructor() {
        super();
        this.state = {
            officeUpdated: false
        };
    }

    static propTypes = {
        company: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        updateOffice: PropTypes.func.isRequired,
        updateOfficeStatus: PropTypes.object.isRequired
    };

    componentDidUpdate(prevProps) {
        const { updateOfficeStatus } = this.props;

        if (prevProps.updateOfficeStatus.started && updateOfficeStatus.ended && !updateOfficeStatus.error) {
            this.setState({ officeUpdated: true });
        }
    }

    updateOffice(e) {
        const { company, updateOffice } = this.props;
        e.classifier = "OFFICE";
        updateOffice(company.base.id, this.props.match.params.id, e);
    }

    render() {
        const { company, match, updateOfficeStatus, history } = this.props;
        const { officeUpdated } = this.state;

        const currentOffice = company.layers.find(i => i.base.id === this.props.match.params.id);

        const officeAddress =
            currentOffice.base.details.addresses && currentOffice.base.details.addresses[0]
                ? currentOffice.base.details.addresses[0]
                : {};

        return officeUpdated ? (
            <Redirect to={`/${match.params.item}/registry/office/${this.props.match.params.id}/edit/success`} />
        ) : (
            <OfficesCreateContainer>
                <Title>
                    <FormattedMessage id="c-registry-office.modal.edit.title" />
                </Title>
                <Subtitle>
                    <FormattedMessage
                        id="c-registry-office.modal.edit.subtitle"
                        values={{
                            officeName: currentOffice.base.details.description
                        }}
                    />
                </Subtitle>
                <OfficeForm
                    errorMsg={updateOfficeStatus.error && updateOfficeStatus.errorInfo.message}
                    initialValues={{
                        description: currentOffice.base.details.description,
                        streetName: officeAddress.streetName,
                        streetNumber: officeAddress.streetNumber,
                        city: officeAddress.city,
                        province: officeAddress.province,
                        zipCode: officeAddress.zipCode,
                        country: officeAddress.country,
                        govCode: currentOffice.base.identifier.govCode
                    }}
                    isGovCodeEnabled={
                        company.layers && !currentOffice.base.id.endsWith("-000") && ENABLE_GOV_CODE === "enable"
                    }
                    isLoading={updateOfficeStatus.started}
                    onCancel={() => history.goBack()}
                    onSubmit={data => this.updateOffice(data)}
                    submitButtonLabel={<FormattedMessage id="general.edit" />}
                    unavailableGovCodes={
                        company.layers
                            ? company.layers.reduce((acc, currentOffice) => {
                                  if (currentOffice.base.identifier.govCode !== null) {
                                      acc.push(currentOffice.base.identifier.govCode);
                                  }
                                  return acc;
                              }, [])
                            : []
                    }
                />
            </OfficesCreateContainer>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        company: state.companies.data[props.match.params.item]
            ? state.companies.data[props.match.params.item].item
            : {},
        updateOfficeStatus: state.offices.updateOffice.status
    };
};

const composedHoc = compose(connect(mapStateToProps, { updateOffice }), errorBoundary);

export default composedHoc(OfficesEdit);
