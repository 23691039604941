import { combineReducers } from "redux";
import {
    FETCH_ASSIGNABLE_ROLES_ERROR,
    FETCH_ASSIGNABLE_ROLES_RESET,
    FETCH_ASSIGNABLE_ROLES_START,
    FETCH_ASSIGNABLE_ROLES_SUCCESS,
    FETCH_ROLES_SUMMARY_ERROR,
    FETCH_ROLES_SUMMARY_RESET,
    FETCH_ROLES_SUMMARY_START,
    FETCH_ROLES_SUMMARY_SUCCESS
} from "../actions/roles";

import { REMOVE_ROLES_USER_SUCCESS } from "../actions/user.js";

const initialStatus = {
    started: false,
    ended: false,
    error: false,
    errorInfo: {}
};

const fetchAssignableRolesStatus = (state = initialStatus, { type, payload, error }) => {
    switch (type) {
        case FETCH_ASSIGNABLE_ROLES_START:
            return { ...initialStatus, started: true };
        case FETCH_ASSIGNABLE_ROLES_ERROR:
            return { ...state, started: false, error: true, errorInfo: error };
        case FETCH_ASSIGNABLE_ROLES_SUCCESS:
            return { ...state, started: false, ended: true };
        case FETCH_ASSIGNABLE_ROLES_RESET:
            return { ...initialStatus };
        default:
            return state;
    }
};

const assignableRoles = (state = {}, { type, payload }) => {
    switch (type) {
        case FETCH_ASSIGNABLE_ROLES_SUCCESS:
            return { ...state, ...payload };
        case FETCH_ASSIGNABLE_ROLES_RESET:
            return {};
        default:
            return state;
    }
};

const rolesSummaryInitialState = { status: { ...initialStatus }, userId: null, summary: { pages: [], totalItems: 0 } };
const summary = (state = rolesSummaryInitialState, { type, payload, error }) => {
    switch (type) {
        case FETCH_ROLES_SUMMARY_START:
            if (state.userId !== payload.userId) {
                return {
                    ...rolesSummaryInitialState,
                    status: { ...initialStatus, started: true },
                    userId: payload.userId
                };
            }
            return { ...state, status: { ...initialStatus, started: true } };
        case FETCH_ROLES_SUMMARY_ERROR:
            return { ...state, status: { ...initialStatus, error: true, errorInfo: error } };
        case FETCH_ROLES_SUMMARY_SUCCESS:
            const updatedPages = [...state.summary.pages];
            updatedPages[payload.page] = payload.rolesSummary.items;
            return {
                ...state,
                status: { ...initialStatus, ended: true },
                summary: { pages: updatedPages, totalItems: payload.rolesSummary.totalItems }
            };
        case FETCH_ROLES_SUMMARY_RESET:
            return { ...rolesSummaryInitialState };
        case REMOVE_ROLES_USER_SUCCESS:
            if (payload.itemId && state.summary && state.summary.pages && state.summary.pages[0]) {
                let pageIndex = 0;
                state.summary.pages.forEach((page, index) => {
                    if (page.some(ele => ele.itemId === payload.itemId)) {
                        pageIndex = index;
                    }
                });
                const newPage = state.summary.pages[pageIndex].filter(ele => ele.itemId !== payload.itemId);

                let newPages = state.summary.pages;
                newPages[pageIndex] = newPage;

                return {
                    ...state,
                    summary: { pages: newPages, totalItems: state.summary.totalItems - 1 }
                };
            }
            return state;

        default:
            return state;
    }
};

export default combineReducers({ fetchAssignableRolesStatus, assignableRoles, summary });
