import React from "react";

import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";

import { errorBoundary } from "../../../components/general/error-boundary";
import { FIRMA_CONSOLE } from "../../../config";
import { getCurrentCookieConsents } from "../../../utils/cookie-consent-utils";
import { useFeature } from "../../../hooks/use-feature";
import MigratingChildApp from "../../portale-child-app/migrating";

const FirmaView = ({ auth, company, language, user }) => {
    const enableChildApp = useFeature("FIRMA_CHILD_APP");

    return (
        <MigratingChildApp
            customLaunchData={{ oldUserId: user.profile.id }}
            iframeConfigs={{
                data: {
                    token: auth.loginAuth.securityToken,
                    itemId: company.base.id,
                    itemUuid: company.base.uuid,
                    language,
                    route: "/",
                    cookieConsents: getCurrentCookieConsents()
                },
                id: "firma",
                title: "Firma"
            }}
            migrated={enableChildApp}
            url={FIRMA_CONSOLE}
        />
    );
};

function mapStateToProps(state, props) {
    return {
        auth: state.auth,
        company: state.companies.data[props.match.params.item]
            ? state.companies.data[props.match.params.item].item
            : {},
        language: state.user.user ? state.user.user.profile.language : undefined,
        user: state.user.user
    };
}
const composedHoc = compose(injectIntl, connect(mapStateToProps, []), errorBoundary);

export default composedHoc(FirmaView);
